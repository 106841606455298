import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {Col, Container, Row} from 'react-grid-system';
import ListComplaintSeller from './ListComplaintSeller';
import FormPerformAction from './FormPerformAction'
import FormStats from './FormStats';
import FormSearchHeader from './FormSearchHeader';
import {url} from './../../../../systems/routing/index';
import * as GlobalConstants from './../../../../systems/globalContant/actionContant';
import {COMPLAINT_SELLER_STATUS} from "./../../../../systems/globalContant/actionContant";
import Pagination from './../../../common/components/Pagination';
import FormSearch from "./FormSearch";
import {processResponse} from "../../../../systems/api";
import apiService from "../../../Order/apiService";
import {notification} from "antd";
import moment from 'moment';

const currentDay = moment('23:59:59', 'HH:mm:ss')
const threeMonthsAgo = moment('00:00:00', 'HH:mm:ss').subtract(3, 'M')

const defaultState = {
    code: '',
    order_seller_name: '',
    product_return: '',
    seller_source: [],
    id_processor: [],
    account_purchaser_id: [],
    order_seller_source: [],
    refund_status: '',
    time_type: 'EXPIRE_TIME',
    log_status_to: currentDay.format('YYYY-MM-DD 23:59:59'),
    log_status_from: threeMonthsAgo.format('YYYY-MM-DD 00:00:00'),
    orderBy: 'expire_time',
    sort: 'asc',
    id_agency: [],
    complaint_cost_to: '',
    complaint_cost_from: '',
    renewal_count: '',
    renewal_count_operation: ''
};


let timeOptions = lodash.values(COMPLAINT_SELLER_STATUS).map(Item => {
    return {label: Item.toLowerCase(), value: Item}
});
timeOptions.push({label: 'expire_time', value: 'EXPIRE_TIME'});

class ComplaintSeller extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            seller_source: []
        }
    }

    componentDidMount() {
        const {t, purchasers, complaintSellerReasons, accountPurchasersOptions} = this.props;
        if (complaintSellerReasons.length === 0) {
            this.props.fetchComplaintSellerReasons({status: GlobalConstants.OPTION_INPUT_API.ON});
        }

        if (purchasers.length === 0) {
            this.props.fetchUsers();
        }

        if (accountPurchasersOptions.length === 0) {
            this.props.getListAccountPurchaserAction({});
        }
        this.props.setPageTitle(t('breadcrumb.complaint_seller'));
        this.props.setPageDescription(t('complaintSeller:list.list'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.complaint_seller',
                'link': url.to('complaint-seller')
            },
        ]);
        this.props.setMenuActive("complaint-seller");
        this.fetchSellerSources();
    }
    fetchSellerSources = () => {
        let {t} = this.props;
        processResponse(apiService.fetchSellerSource())
            .then(res => {
                this.setState({seller_source: res.data});
            })
            .catch(() => {
                notification.error(t('message.cant_fetch_data'));
            });
    };

    /**
     * handle action change filter
     * @param filter
     */
    onSearch(filter) {
        filter = this.customFilter(filter);
        // orderCode mã đơn hàng được gửi theo url tới khi search hoặc chuyển trang mong muốn replace nếu có trong url
        this.props.onSearch({...filter, page: 1, orderCode: ''});
    }

    /**
     * custom lại filter để search ở trạng thái nào thì thời gian sẽ theo trang thái đó
     * @param filter
     * @returns {*}
     */
    customFilter(filter) {
        if (typeof  filter.orderBy === 'undefined' || filter.orderBy === '') {
            filter.orderBy = 'log_time_status_PROCESSING';
        }
        return filter;
    }

    onSubmitForm(formData) {
        this.props.createComplaintSeller(formData);
    }

    /**
     * handle change page of list
     * @param page
     */
    onChangePage(page) {
        let {filter} = this.props;
        window.scrollTo(0, 0);
        this.setState({page: page});
        // orderCode mã đơn hàng được gửi theo url tới khi search hoặc chuyển trang mong muốn replace nếu có trong url
        this.props.onSearch({...filter, page: page, orderCode: ''});
    }

    /**
     * handle việc đóng popup form lại
     */
    closeModalForm = () => {
        this.props.closeModalForm();
    };

    render() {
        let {
            complaintSellerReasons, complaintSeller, filter, stats = [],
            countWaitingComplaintSeller, purchasers, isEndProcess,
            accountPurchasersOptions, createErrors, isLoadingData, getMeSelector,
            beginProcesscreateComplaintSeller, clearErrorCreateComplaintSeller, pagination, isLoading
        } = this.props;
        let formStatsParams = ['status'];
        let formSearchParams = [
            "code", "order_seller_source", "product_return", "refund_status", "complaint_seller_reason_id",
            "order_seller_name", "orderBy", "time_type", "log_status_from",
            "log_status_to", "id_processor", "account_purchaser_id", "is_important"
        ];
        
        return (
            <React.Fragment>
                <div>
                    <Container fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} className="a-content--center ">
                                <FormSearch
                                    filter={filter}
                                    onSearch={this.onSearch.bind(this)}
                                    complaintSellerReasons={complaintSellerReasons}
                                    siteItems={this.state.seller_source}
                                    timeOptions={timeOptions}
                                    purchasers={purchasers}
                                    accountPurchasersOptions={accountPurchasersOptions}
                                />

                                <FormStats
                                    inputVersion={typeof filter._i === "undefined" ? 0 : parseInt(filter._i, 10)}
                                    countStatusChanged={{[COMPLAINT_SELLER_STATUS.PENDING]: countWaitingComplaintSeller}}
                                    onSubmit={(input) => this.onSearch({...filter, ...input})}
                                    status={lodash.pick(filter, formStatsParams)}
                                    statsOrder={stats}
                                    filter={filter}
                                />

                                <div className="a-content--box-shadow a__complaint__content_list">
                                    <FormPerformAction
                                        onClear={(input) =>
                                            this.onSearch({...input, ...{_i: filter._i}})
                                        }
                                        filter={filter}
                                        errors={createErrors}
                                        isLoadingData={isLoadingData}
                                        isEndProcess={isEndProcess}
                                        beginProcesscreateComplaintSeller={beginProcesscreateComplaintSeller}
                                        clearErrorCreateComplaintSeller={clearErrorCreateComplaintSeller}
                                        inputVersion={typeof filter._i === "undefined" ? 0 : parseInt(filter._i, 10)}
                                        input={lodash.pick(filter, formSearchParams)}
                                        defaultState={defaultState}
                                        complaintSellerReasons={complaintSellerReasons}
                                        onSubmit={(input) => this.onSearch({...filter, ...input})}
                                        onSubmitForm={(input) =>
                                            this.onSubmitForm(input)}
                                    />
                                    <FormSearchHeader
                                        inputVersion={typeof filter._i === "undefined" ? 0 : parseInt(filter._i, 10)}
                                        complaintSellerReasons={complaintSellerReasons}
                                        onSubmit={(input) => this.onSearch({...filter, ...input})}
                                        input={lodash.pick(filter, formSearchParams)}
                                        purchasers={purchasers}
                                        defaultState={defaultState}
                                        siteItems={this.state.seller_source}
                                        timeOptions={timeOptions}
                                        permissions={lodash.get(getMeSelector,'user.permissions',[])}
                                        accountPurchasersOptions={accountPurchasersOptions}
                                        filter={filter}
                                    />
                                    <ListComplaintSeller
                                        complaintSellerReasons={complaintSellerReasons}
                                        complaintSeller={complaintSeller}
                                        isLoading={isLoading}
                                        onClear={(input) =>
                                            this.onSearch({...input, ...{_i: filter._i}})
                                        }
                                    />
                                    <div className="a-group--item a_navigation mt-5 pb-5 d-flex ">
                                        <Pagination
                                            value={parseInt(pagination.current_page, 10)}
                                            total={pagination.page_total}
                                            onChange={page => this.onChangePage(page)}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

ComplaintSeller.defaultProps = {
    list: [],
    onSearch: () => {
    }
}

ComplaintSeller.protoType = {
    list: PropTypes.array,
    onSearch: PropTypes.func,
};

export default translate('translations')(ComplaintSeller);