import React from 'react';
import {translate} from 'react-i18next';
import {get, isEmpty} from 'lodash';
import {trans} from "../../../../../systems/i18n";

const PACKAGE_ACTION = {
    CHANGE_TRANSPORT_STATUS: "PACKAGE.CHANGE_TRANSPORT_STATUS",
    UPDATE_DAMAGED_STATUS: "PACKAGE.UPDATE_DAMAGED_STATUS",
    UPDATE_NOTE_STATUS: "PACKAGE.UPDATE_NOTE_STATUS",
    UPDATE_RETURN_ADDRESS: "PACKAGE.UPDATE_RETURN_ADDRESS",
    UPDATE_RETURN_INFO: "PACKAGE.UPDATE_RETURN_INFO"
};

function PackageLogMessage(props) {
    const {log} = props;
    const {payload, action} = log;
    const return_address = get(payload, "change_fields.return_address", {});
    const note_status = get(payload, "change_fields.note_status", {});
    const status_damaged = get(payload, "change_fields.status_damaged", {});
    const status_transport = get(payload, "change_fields.status_transport", {});
    const return_tracking_bill = get(payload, "change_fields.return_tracking_bill", get(payload, "change_fields.tracking_bill", {}));
    const return_fee = get(payload, "change_fields.return_fee", {});
    let newValue = "";
    let oldValue = "";

    function renderMessage() {
        switch (action) {

            case PACKAGE_ACTION.CHANGE_TRANSPORT_STATUS:
                oldValue = get(status_transport, "old", null);
                newValue = get(status_transport, "new", null);
                return <span>{trans('complaintSeller:package.log.CHANGE_TRANSPORT_STATUS', {
                        from: <b>{trans('complaintSeller:status_transport.' + oldValue)}</b>,
                        to: <b>{trans('complaintSeller:status_transport.' + newValue)}</b>
                    }
                )}</span>;
            case PACKAGE_ACTION.UPDATE_DAMAGED_STATUS:
                oldValue = get(status_damaged, "old", null);
                newValue = get(status_damaged, "new", null);
                return <span>{trans('complaintSeller:package.log.UPDATE_DAMAGED_STATUS', {
                    from: <b>{trans('complaintSeller:status_damaged.' + oldValue)}</b>,
                    to: <b>{trans('complaintSeller:status_damaged.' + newValue)}</b>
                })}</span>;
            case PACKAGE_ACTION.UPDATE_NOTE_STATUS:
                oldValue = get(note_status, "old", null);
                newValue = get(note_status, "new", null);
                if (isEmpty(oldValue)) {
                    return <span>{trans('complaintSeller:package.log.ADD_NOTE_STATUS', {
                        to: <b>{newValue}</b>
                    })}</span>;
                }
                return <span>{trans('complaintSeller:package.log.UPDATE_NOTE_STATUS', {
                    from: <b>{oldValue}</b>,
                    to: <b>{newValue}</b>
                })}</span>;
            case PACKAGE_ACTION.UPDATE_RETURN_ADDRESS:
                oldValue = get(return_address, "old", null);
                newValue = get(return_address, "new", null);
                if (isEmpty(oldValue)) {
                    return <span>{trans('complaintSeller:package.log.ADD_RETURN_ADDRESS', {
                        to: <b>{newValue}</b>
                    })}</span>;
                }
                return <span>{trans('complaintSeller:package.log.UPDATE_RETURN_ADDRESS', {
                    from: <b>{oldValue}</b>,
                    to: <b>{newValue}</b>
                })}</span>;
            case PACKAGE_ACTION.UPDATE_RETURN_INFO:
                const return_address_old = get(return_address, "old", null);
                const return_address_new = get(return_address, "new", null);
                const return_tracking_bill_old = get(return_tracking_bill, "old", null);
                const return_tracking_bill_new = get(return_tracking_bill, "new", null);
                const return_fee_old = get(return_fee, "old", null);
                const return_fee_new = get(return_fee, "new", null);
                const content = [];
                if (!isEmpty(return_address)) {
                    if (return_address_old) {
                        content.push(
                            <span>{trans('complaintSeller:package.log.UPDATE_RETURN_ADDRESS', {
                                from: <b>{return_address_old}</b>,
                                to: <b>{return_address_new}</b>
                            })}</span>
                        );
                    } else {
                        content.push(
                            <span>{trans('complaintSeller:package.log.ADD_RETURN_ADDRESS', {
                                to: <b>{return_address_new}</b>
                            })}</span>);
                    }
                }
                if (!isEmpty(return_tracking_bill)) {
                    if (return_tracking_bill_old) {
                        content.push(
                            <span>{trans('complaintSeller:package.log.UPDATE_RETURN_TRACKING_BILL', {
                                from: <b>{return_tracking_bill_old}</b>,
                                to: <b>{return_tracking_bill_new}</b>
                            })}</span>
                        );
                    } else {
                        content.push(
                            <span>{trans('complaintSeller:package.log.ADD_RETURN_TRACKING_BILL', {
                                to: <b>{return_tracking_bill_new}</b>
                            })}</span>
                        );
                    }
                }
                if (!isEmpty(return_fee)) {
                    if (return_fee_old) {
                        content.push(
                            <span>{trans('complaintSeller:package.log.UPDATE_RETURN_FEE', {
                                from: <b>{return_fee_old}</b>,
                                to: <b>{return_fee_new}</b>
                            })}</span>
                        );
                    } else {
                        content.push(
                            <span>{trans('complaintSeller:package.log.ADD_RETURN_FEE', {
                                to: <b>{return_fee_new}</b>
                            })}</span>);
                    }
                }
                if(content.length > 0) {
                    return content;
                }else {
                    return <span>{trans('complaintSeller:package.log.UPDATE_RETURN_INFO')}</span>
                }
            default:
                return action;
        }
    }

    return (
        renderMessage()
    )
}

export default translate()(PackageLogMessage);
