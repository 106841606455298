import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import ComplaintAlipays from './components/ComplaintAlipays';
import * as actions from './actions';

const mapStateToProps = (state, props) => {
    let {complaint, alipays} = props;
    let complaints = lodash.get(state, 'ComplaintSeller.ComplaintAlipays.complaints', []);

    return {
        ...lodash.find(complaints, {id: complaint.id}) || {},
        alipays,
        canMapping: complaint.canMappingAlipay,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    let {complaint} = props;

    return {
        onAttach: (alipayId) => {
            dispatch(actions.attachAlipay(complaint.id, alipayId));
        },
        onDetach: (alipayId) => {
            dispatch(actions.detachAlipay(complaint.id, alipayId));
        },
    };
};

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(ComplaintAlipays);

Container.defaultProps = {
    complaint: {},
    alipays: [],
};

Container.propTypes = {
    complaint: PropTypes.object.isRequired,
    alipays: PropTypes.array.isRequired,
};

export default Container;
