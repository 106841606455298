import React from "react";
import {Modal, Input, notification, Select} from "antd";
import {translate} from "react-i18next";
import apiService from "../../apiService";
import {processResponse} from "../../../../systems/api";
import lodash from "lodash";
import {currentFormatter} from "../../../common/services/format";

const {Option} = Select;

class AddTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            transactionCode: "",
            loading: false,
            errors: {}
        };
    }

    onClick = e => {
        this.setState({
            visible: true
        });
        return false;
    }

    onOK = e => {
        let {transactionCode} = this.state;
        let {complaint} = this.props;

        let process = processResponse(apiService.addComplaintTransaction(complaint.id, {transaction_code: transactionCode}));
        process.then(res => {
            notification.success({message: "Thêm giao dịch thành công"});
            this.props.pushTransaction(res.data);
            this.props.addTransactionCode(res);
            this.setState({
                visible: false,
                transactionCode: ""
            });
        });
        process.catch(error => {
            if (error.code === "INPUT_INVALID") {
                this.setState({
                    errors: lodash.get(error, "data.transaction_code", {})
                });
            } else {
                notification.error({message: "Thêm giao dịch không thành công"});
            }
        });
    }

    renderErrors = () => {
        let {errors} = this.state;

        if (lodash.isEmpty(errors))
            return "";

        if (errors.hasOwnProperty("ALREADY_EXIST"))
            return "Mã giao dịch đã tồn tại";

        if (errors.hasOwnProperty("INVALID"))
            return "Giao dịch phải là giao dịch hoàn";

        return ""
    }

    onCancel = e => {
        this.setState({
            visible: false,
            errors: {},
            transactionCode: ""
        });
    }

    onChange = e => {
        this.setState({
            transactionCode: e.target.value,
            errors: {}
        });
    }

    onClickTransactionCode = transactionCode => {
        this.setState({
            transactionCode: transactionCode,
            errors: {}
        });
    }

    render() {
        let {transactionCode} = this.state;
        let transactions = lodash.get(this.props, "order.transactions", []);

        while (transactionCode.indexOf(" ") > -1)
            transactionCode = transactionCode.replace(" ", "");

        return (
            <>
                <a
                    href="#"
                    onClick={this.onClick}
                    className="a-text--fz-14"
                >THÊM MÃ GD</a>
                <Modal
                    title="THÊM MỚI GIAO DỊCH"
                    visible={this.state.visible}
                    onOk={this.onOK}
                    onCancel={this.onCancel}
                    cancelText="Hủy"
                    okText={"Thêm mới"}
                    width={520}
                    okButtonProps={{loading: this.state.loading, disabled: transactionCode.length === 0}}
                >
                    <Input
                        onChange={this.onChange}
                        placeholder="Nhập mã giao dịch"
                        value={transactionCode}
                        onPressEnter={this.onOK}
                    />
                    <p className="a--error a-text--fz-12 mt-1">{this.renderErrors()}</p>
                    {
                        transactions.length > 0 &&
                            <>
                                <p className="mt-2 mb-2 a-text--bold">Danh sách mã GD hoàn trên đơn:</p>
                                <ul>
                                    {
                                        transactions.map(transaction => {
                                            return (
                                            <li
                                                className="option_transaction_cs mt-1 mb-1 a-text--pointer"
                                                onClick={() => this.onClickTransactionCode(transaction.code)}
                                            >
                                                <p className="w_80 a-text--color-primary">{transaction.code}</p>
                                                <span className="w_20 a-text--right">{'¥'+currentFormatter.toLocaleStringCurrency(transaction.amount, 2)}</span>
                                            </li>
                                            );
                                        })
                                    }
                                </ul>
                            </>
                    }
                </Modal>
            </>
        );
    }
}

export default translate()(AddTransaction);