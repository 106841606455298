import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {translate} from 'react-i18next';

const FormError = ({attribute, errors, showAll, t}) => {
    if (!errors || !lodash.keys(errors).length) {
        return null;
    }

    let rules = lodash.keys(errors);

    if (!showAll) {
        rules = rules.slice(0, 1);
    }

    return (
        <ul className="list-unstyled invalid-feedback d-block mb-0">
            {rules.map(rule => (
                <li key={rule}>{t('validation:' + rule, {...errors[rule], attribute})}</li>
            ))}
        </ul>
    );
};

FormError.defaultProps = {
    showAll: false,
};

FormError.propTypes = {
    attribute: PropTypes.string,
    errors: PropTypes.object,
    showAll: PropTypes.bool,
};

export default translate('translations')(FormError);