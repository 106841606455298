import './Pusher.lib';

export default class Pusher {
    constructor(params) {
        this.host = params.host || 'localhost';
        this.hostSendMessage = params.hostSendMessage || 'localhost';
        this.port = params.port || '';
        this.prefix = params.prefix || '/ws';
        this.channel = params.channel || null;
        this.debug = params.debug || false;
        this.useSSL = params.useSSL || false;
        this.uri = params.uri || {};
        this.uriSend = params.uriSend || {};
        this.uriOrigin = params.uri;
        this.uri = Object.keys(this.uri).map(key => key + '=' + this.uri[key]).join('&');
        this.uriSend = Object.keys(this.uriSend).map(key => key + '=' + this.uriSend[key]).join('&');
        this.state = "";
        this._context = params._context;

        this.init();
    }

    init() {
        let _self = this;

        // Muốn debug thì bật cái này
        if (this.debug) {
            window.PushStream.LOG_LEVEL = 'info';
        }

        this.pushstream = new window.PushStream({
            host: this.host,
            port: this.port,
            modes: "websocket",
            useSSL: this.useSSL,
            urlPrefixWebsocket: this.prefix,
            reconnectOnTimeoutInterval: 300,
            reconnectOnChannelUnavailableInterval: 5000,
            autoReconnect: true,
            extraParams: function () {
                return _self.uriOrigin;
            }
        });

        this.pushstream.onmessage = function (text, id, channel) {
            if (typeof _self._context._pusherOnMessage !== 'undefined') {
                _self._context._pusherOnMessage(text, id, channel);
            } else {
                console.warn('Pusher: Not found callback for WebSocket onmessage');
            }
        };
        this.pushstream.onerror = function (error) {
            if (typeof _self._context._pusher !== 'undefined') {
                _self._context._pusher(error);
            } else {
                console.warn('Pusher: Not found callback for WebSocket onerror');
            }
        };
        this.pushstream.onstatuschange = function (state) {
            if (typeof _self._context._pusherOnStatusChange !== 'undefined') {
                _self._context._pusherOnStatusChange(state);
                _self.state = state;
            } else {
                console.warn('Pusher: Not found callback for WebSocket onstatuschange');
            }
        };
    }

    addChannel(channel) {
        this.pushstream.addChannel(channel);
    }

    getChannel() {
        return this.pushstream.getChannel();
    }

    sendMessage(url, message, successCallback, errorCallback) {
        this.pushstream.sendMessage(url, message, successCallback, errorCallback);
    }

    start() {
        this.pushstream.connect();
    }

    stop() {
        this.pushstream.close();
    }

    removeChannel(channel) {
        this.pushstream.removeChannel(channel);
    }
}