import {connect} from 'react-redux';
import HomeMadeNavbar from './components';
import lodash from 'lodash';
import * as UserSelector from "./../../modules/User/selectors";
import * as GlobalConstant from './../../systems/globalContant'
import * as CommonAction from './../../modules/common/actions';

const mapStateToProps = (state) => {
    return {
        menu_active: lodash.get(state, "Common.menu_active", ""),
        me: UserSelector.getMeSelector(state),
        channels: UserSelector.getCreateOrderChannel(state),
        countWaitingBuyOrder: lodash.get(state, "Common.countWaitingBuyOrder", 0),
        permissions: lodash.get(state, 'User.me.user.permissions', []),
        globalSetting: lodash.get(state, "User.me.user.settings", []),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pusherCreateNewOrder: (orderData) => {
            dispatch(CommonAction.pusherCreateNewOrder(orderData));
        },
        fireMenuStatus: (isOpen) => {
            dispatch(CommonAction.fireMenuStatus(isOpen));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeMadeNavbar)