import React from 'react';
import {Container, Row, Col} from 'react-grid-system';
import AtlakitNavbar from './AtlakitNavbarContainer';
import SettingMenu from '../modules/Settings/SettingMenu';

export default function Setting({children}) {
    return (
        <AtlakitNavbar>
            <Container fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="a-content--center">
                        <div className="a-content__is_sidebar">
                            <SettingMenu/>
                            <div className="a-content__right__center settings__body">
                                {children}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </AtlakitNavbar>
    );
}
