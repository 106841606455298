import React from 'react';
import {translate} from 'react-i18next';
import Link from "../../../../../systems/routing/Link";
import onClickOutside from "react-onclickoutside";
import CancelFragment from "../CancelProcessComponents/CancelFragment";
import OutOfStock from "../OutOfStockComponents/OutOfStock";
import AddLinkProduct from "../UpdateProduct/AddLinkProduct";
import lodash from 'lodash';
import authService from '../../../../Auth/authService';
import { SELF_NEGOTIATE_UPDATE_PRODUCT } from '../../../../Permission/permissions';
class ActionList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    componentDidUpdate(prevProps, PrevState) {
        if (this.props.isOpen !== prevProps.isOpen) {
            this.setState({isOpen: this.props.isOpen})
        }
    }

    handleClickOutside = evt => {
        this.setState({
            isOpen: false
        })
    };

    handleClick(type, event) {
        event.preventDefault();
        this.props.onClickOpenFormEdit(type, event)
    }

    render() {

        let {
            t,
            order_item_checked,
            nextOrderId,
            order,
            isLoadingCancelOrder,
            beginProcessCancelOrder,
            isLoadingOutOfStockOrder,
            beginProcessOutOfStock,
            onCancel,
            isEndProcess,
            onOutOfStock,
            getPurchasingOrderDetailAction
        } = this.props;
        let {isOpen} = this.state;
        const orderDetail = lodash.get(order, "orderDetail", {})
        const self_negotiate = lodash.get(orderDetail, "self_negotiate", false)

        return (
            <div className="action-list">
                <ul className={`d-flex ${isOpen ? 'active' : ''}`}>
                    {
                        order.canUpdateOutOfStock  &&
                        <li className="a-purchasod__link">
                            <a href=""
                               className={`${order_item_checked.length ? '' : 'a-disable'}`}
                               onClick={this.handleClick.bind(this, 'isOpenConfirmOutOfStock')}>
                                {t('order_detail.OUT_OF_STOCK')} {order_item_checked.length ? `(${order_item_checked.length})` : ""}
                            </a>
                        </li>
                    }
                    {
                        ((!!self_negotiate && authService.can(SELF_NEGOTIATE_UPDATE_PRODUCT)) || !self_negotiate) &&
                        <>
                            <li className="a-purchasod__link">
                                <a href=""
                                className={`${order_item_checked.length ? '' : 'a-disable'}`}
                                onClick={this.handleClick.bind(this, 'isOpenConfirmUpdatePrice')}>{t('order_detail.edit_price')} {order_item_checked.length ? `(${order_item_checked.length})` : ""}</a>
                            </li>
                            <li className="a-purchasod__link">
                                <a href=""
                                className={`${order_item_checked.length ? '' : 'a-disable'}`}
                                onClick={this.handleClick.bind(this, 'isOpenConfirmUpdateQuantityItems')}>{t('order_detail.edit_quantity')} {order_item_checked.length ? `(${order_item_checked.length})` : ""}</a>
                            </li>
                        </>
                    }
                    
                    {/*<li className="a-purchasod__link">*/}
                    {/*    <Link isNewTab={!!nextOrderId} to={nextOrderId ? "purchase.buying.detail" : null}*/}
                    {/*          params={{id: nextOrderId}}*/}
                    {/*          className={`a-text--font-primary ${nextOrderId ? '' : 'a-disable'}`}*/}
                    {/*    >*/}
                    {/*        {t('order_detail.next_order')}*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {
                        order.canCancel ?
                            <li className="a-purchasod__link">
                                <CancelFragment
                                    code={order.code}
                                    canCancel={order.canCancel}
                                    onCancel={onCancel}
                                    id={order.id}
                                    isModalProcessing={!isEndProcess.cancelOrder}
                                    isLoadingCancelOrder={isLoadingCancelOrder}
                                    beginProcessCancelOrder={beginProcessCancelOrder}/>
                            </li> : null
                    }

                    {
                        order.canUpdateOutOfStock ?
                            <li className="a-purchasod__link">
                                <OutOfStock
                                    code={order.code}
                                    canUpdateOutOfStock={order.canUpdateOutOfStock}
                                    onOutOfStock={onOutOfStock}
                                    id={order.id}
                                    isModalProcessing={!isEndProcess.outOfStock}
                                    isLoadingOutOfStockOrder={isLoadingOutOfStockOrder}
                                    beginProcessOutOfStock={beginProcessOutOfStock}
                                />
                            </li> : null
                    }

                    {
                        order.canAddOrderItem &&
                        <AddLinkProduct
                            getPurchasingOrderDetailAction={getPurchasingOrderDetailAction}
                            orderInfo={order}
                        />
                    }

                </ul>
            </div>
        );
    }
}

ActionList.defaultProps = {};

ActionList.propTypes = {};


export default translate('translations')(onClickOutside(ActionList));