import {takeLatest} from 'redux-saga/effects';

import {
    FETCH_PURCHASING_ORDER_DETAIL, UPDATE_ORDER_ITEM_PRICE,
    UPDATE_PAID_ORDER_DETAIL, CHANGE_MUTIPLE_QUANTITY,
    UPDATE_ORDER_INVOICE_CODE, CONFIRM_CUSTOMER_ORDER_BUYING,
    UPDATE_SHIPPING_FEE, UPDATE_ORDER_SERVICE,
    POST_INVOICE_CODE,
    DELETE_INVOICE_CODE,
    PAYMENT_ORDER,
    UPDATE_ORDER_GROUP_ORDER,
    UPDATE_ORDER_ACCOUNT_PURCHASER,
    UPDATE_PAID_ORDER_STATUS,
    UPDATE_OUT_STOCK_ITEMS,
    UPDATE_PRICE_ITEMS,
    CANCEL_ORDER, OUT_OF_STOCK_ORDER,
    UPDATE_QUANTITY_ITEMS, GET_SHIPPING_FEE_HISTORY,
    APPROVAL_ORDER_SERVICE, ADD_FREIGHT_BILL, DELETE_FREIGHT_BILL, CHANGE_RATE_DEPOSIT,
    SYNC_ORDER_BILL_NO, WAITING_PAYMENT_ORDER, UPDATE_ORDER_FEATURE, UPDATE_TOTAL_DISCOUNT,
    SET_BATCH_ORDER
} from './constants';
import * as saga from './saga';

export default function* () {
    yield takeLatest(FETCH_PURCHASING_ORDER_DETAIL.REQUEST, saga.getPurchaserOrderDetail);
    yield takeLatest(UPDATE_PAID_ORDER_DETAIL.REQUEST, saga.updatePaidOrderDetail);
    yield takeLatest(UPDATE_ORDER_ACCOUNT_PURCHASER.REQUEST, saga.updateOrderAccountPurchaser);
    yield takeLatest(UPDATE_ORDER_INVOICE_CODE.REQUEST, saga.updateOrderInvoiceCode);
    yield takeLatest(UPDATE_SHIPPING_FEE.REQUEST, saga.updateShippingFee);
    yield takeLatest(UPDATE_TOTAL_DISCOUNT.REQUEST, saga.updateTotalDiscountOrder);
    yield takeLatest(POST_INVOICE_CODE.REQUEST, saga.postInvoiceCode);
    yield takeLatest(DELETE_INVOICE_CODE.REQUEST, saga.deleteInvoiceCode);
    yield takeLatest(PAYMENT_ORDER.REQUEST, saga.paymentOrder);
    yield takeLatest(PAYMENT_ORDER.SUCCESS, saga.recallDetail);
    yield takeLatest(PAYMENT_ORDER.FAILED, saga.recallOnConfirm);
    yield takeLatest(UPDATE_ORDER_GROUP_ORDER.REQUEST, saga.updateOrderGroupOrder);
    yield takeLatest(UPDATE_PAID_ORDER_STATUS.REQUEST, saga.updatePaidOrderStatus);
    yield takeLatest(CANCEL_ORDER.REQUEST, saga.cancelOrder);
    yield takeLatest(OUT_OF_STOCK_ORDER.REQUEST, saga.outOfStocklOrder);
    yield takeLatest(UPDATE_PAID_ORDER_STATUS.SUCCESS, saga.recallDetail);
    yield takeLatest(UPDATE_PAID_ORDER_STATUS.FAILED, saga.recallOnConfirmPaid);
    yield takeLatest(ADD_FREIGHT_BILL.REQUEST, saga.addFreightBill);
    yield takeLatest(DELETE_FREIGHT_BILL.REQUEST, saga.deleteFreightBill);
    yield takeLatest(CHANGE_RATE_DEPOSIT.REQUEST, saga.changeRateDeposit);
    yield takeLatest(SYNC_ORDER_BILL_NO.REQUEST, saga.syncOrderBillNo);
    yield takeLatest(GET_SHIPPING_FEE_HISTORY.REQUEST, saga.getShippingFeeHistory);
    yield takeLatest(UPDATE_PAID_ORDER_DETAIL.SUCCESS, saga.getPurchaserOrderDetailAfterUpdateItem);
    yield takeLatest(WAITING_PAYMENT_ORDER.REQUEST, saga.waitingPaymentOrder);
    yield takeLatest(WAITING_PAYMENT_ORDER.SUCCESS, saga.recallDetail);
    yield takeLatest(WAITING_PAYMENT_ORDER.FAILED, saga.recallOnConfirm);
    yield takeLatest(SET_BATCH_ORDER.REQUEST, saga.setBatchOrder);
    yield takeLatest(SET_BATCH_ORDER.SUCCESS, saga.recallDetail);
    /*
        Phần fetch log sau khi xử lý thành công action
     */
    yield takeLatest(POST_INVOICE_CODE.SUCCESS, saga.fetchLog);
    yield takeLatest(DELETE_INVOICE_CODE.SUCCESS, saga.fetchLog);
    yield takeLatest(ADD_FREIGHT_BILL.SUCCESS, saga.fetchLog);
    yield takeLatest(DELETE_FREIGHT_BILL.SUCCESS, saga.fetchLog);
    yield takeLatest(UPDATE_ORDER_GROUP_ORDER.SUCCESS, saga.fetchLog);
    yield takeLatest(UPDATE_ORDER_ACCOUNT_PURCHASER.SUCCESS, saga.fetchLog);
    yield takeLatest(UPDATE_SHIPPING_FEE.SUCCESS, saga.fetchLog);
    yield takeLatest(UPDATE_TOTAL_DISCOUNT.SUCCESS, saga.fetchLog);
    yield takeLatest(UPDATE_ORDER_ITEM_PRICE.SUCCESS, saga.fetchLog);
    yield takeLatest(CANCEL_ORDER.SUCCESS, saga.fetchLog);
    yield takeLatest(OUT_OF_STOCK_ORDER.SUCCESS, saga.fetchLog);
    yield takeLatest(CHANGE_MUTIPLE_QUANTITY.REQUEST, saga.changeMutipleQuantity);
    yield takeLatest(CONFIRM_CUSTOMER_ORDER_BUYING.REQUEST, saga.confirmCustomerOrderBuying);
    yield takeLatest(CHANGE_MUTIPLE_QUANTITY.SUCCESS, saga.handleReControlChangeQuantitySuccess);
    yield takeLatest(UPDATE_ORDER_SERVICE.REQUEST, saga.updateOrderServiceSaga);
    yield takeLatest(APPROVAL_ORDER_SERVICE.REQUEST, saga.approvalOrderServiceSaga);
    yield takeLatest(UPDATE_ORDER_ITEM_PRICE.REQUEST, saga.updateOrderItemPrice);
    yield takeLatest(UPDATE_OUT_STOCK_ITEMS.REQUEST, saga.changeOutOfStockItems);
    yield takeLatest(UPDATE_PRICE_ITEMS.REQUEST, saga.updatePriceItems);
    yield takeLatest(UPDATE_QUANTITY_ITEMS.REQUEST, saga.updateQuantityItems);
    yield takeLatest(FETCH_PURCHASING_ORDER_DETAIL.SUCCESS, saga.fetchOrderGroups);
    yield takeLatest(FETCH_PURCHASING_ORDER_DETAIL.SUCCESS, saga.fetchServiceGroups);
    yield takeLatest(FETCH_PURCHASING_ORDER_DETAIL.SUCCESS, saga.fetchFeaturesGroups);
    yield takeLatest(UPDATE_ORDER_FEATURE.REQUEST, saga.updateOrderFeatureSaga);
}
