/*
 * Tài khoản mua hàng
 */
export const ACCOUNT_PURCHASER_VIEW = 'ACCOUNT_PURCHASER:VIEW' // Xem thông tin tài khoản mua hàng
export const ACCOUNT_PURCHASER_MANAGE = 'ACCOUNT_PURCHASER:MANAGE' // Quản lý tài khoản mua hàng

/*
 * Địa chỉ kho TQ
 */
export const PURCHASE_ADDRESS_VIEW = 'PURCHASE_ADDRESS:VIEW' // Xem thông tin địa chỉ kho TQ
export const PURCHASE_ADDRESS_MANAGE = 'PURCHASE_ADDRESS:MANAGE' // Quản lý địa chỉ kho TQ

/*
 * Nhóm ngành hàng
 */
export const ORDER_GROUP_VIEW = 'ORDER_GROUP:VIEW' // Xem thông tin nhóm ngành hàng
export const ORDER_GROUP_MANAGE = 'ORDER_GROUP:MANAGE' // Quản lý nhóm ngành hàng

/*
 * Giao dịch viên
 */
export const PURCHASER_MANAGE = 'PURCHASER:MANAGE' // Quản lý giao dịch viên

/*
 * Đại lý (agency)
 */
export const SETTING_AGENCY = 'SETTING:AGENCY' // Quản lý đại lý (agency)

/*
 * Đơn hàng
 */
export const ORDER_VIEW = 'ORDER:VIEW' // Xem tất cả các đơn
export const ORDER_FINANCIAL_VIEW = 'ORDER:FINANCIAL:VIEW' //Xem chi tiết tài chính đơn
export const ORDER_VIEW_ASSIGNED = 'ORDER:VIEW_ASSIGNED' // Xem các đơn mình được phân
export const ORDER_DISTRIBUTE = 'ORDER:DISTRIBUTE' // Phân đơn & Thu hồi đơn cho GDV
export const ORDER_SELF_CLAIM = 'ORDER:SELF_CLAIM' //Tự phân đơn cho mình
export const ORDER_PAID = 'ORDER:PAID' // Paid đơn
export const ORDER_PAY = 'ORDER:PAY' // Thanh toán đơn
export const ORDER_CANCEL = 'ORDER:CANCEL' // Hủy đơn
export const ORDER_UPDATE_ACCOUNT_PURCHASER = 'ORDER:UPDATE_ACCOUNT_PURCHASER' // Update acc mua hàng của đơn
export const ORDER_UPDATE_ORDER_GROUP = 'ORDER:UPDATE_ORDER_GROUP' // Update nhóm ngành hàng của đơn
export const ORDER_UPDATE_INTERNAL_SHIPPING_FEE = 'ORDER:UPDATE_INTERNAL_SHIPPING_FEE' // Update phí vận chuyển nội địa của đơn
export const ORDER_UPDATE_SERVICE = 'ORDER:UPDATE_SERVICE' // Update dịch vụ của đơn
export const ORDER_ADD_SOURCE_INVOICE = 'ORDER:ADD_SOURCE_INVOICE' // Thêm mã hóa đơn gốc của đơn
export const ORDER_REMOVE_SOURCE_INVOICE = 'ORDER:REMOVE_SOURCE_INVOICE' // Xóa mã hóa đơn gốc của đơn
export const ORDER_UPDATE_ITEM_QUANTITY = 'ORDER:UPDATE_ITEM_QUANTITY' // Update số lượng link sản phẩm
export const ORDER_UPDATE_ITEM_PRICE = 'ORDER:UPDATE_ITEM_PRICE' // Update đơn giá link sản phẩm
export const ORDER_CONFIRM_ITEM_CHANGES = 'ORDER:CONFIRM_ITEM_CHANGES' // Xác nhận những thay đổi trên link sản phẩm
export const ORDER_SCAN = 'ORDER:SCAN' // Quét thông tin đơn bằng công cụ
export const TOOLS_EXPORT_ACTUAL_AMOUNT = 'TOOLS:EXPORT:ACTUAL_AMOUNT' // Quyền xuất dữ liệu danh sách tài chính thực chi
export const TOOLS_EXPORT_ORDER_TRANSACTION = 'TOOLS:EXPORT:ORDER_TRANSACTIONS' //Quyền xuất dữ liệu đơn hàng
export const ORDER_MARK_VIOLATION = 'ORDER:MARK_VIOLATION' // Quyền đánh dấu đơn hàng sai phạm
export const ORDER_UNMARK_VIOLATION = 'ORDER:UNMARK_VIOLATION' // Quyền bỏ đánh dấu đơn hàng sai phạm
export const ORDER_FINANCIAL_RESOLVE = 'ORDER:FINANCIAL:RESOLVE' // Quyền bỏ đánh dấu đơn có vấn đề
export const ORDER_VIEW_ONLY_ACCEPTED = 'ORDER:VIEW_ONLY_ACCEPTED' //Quyền chỉ xem các đơn đã tiếp nhận
export const ORDER_PAY_SPECIAL_TELLER = 'ORDER:PAY_SPECIAL_TELLER' //Xử lý yêu cầu thanh toán của mình tiếp nhận cho đơn hàng ngoài
export const ORDER_PAY_SPECIAL = 'ORDER:PAY_SPECIAL' //Xử lý yêu cầu thanh toán cho tất cả đơn hàng ngoài,
export const SUPPLIER_WARNING = 'SUPPLIER:WARNING' //Cho phép ghi chú NCC
export const ORDER_PURCHASER_DISTRIBUTE = 'ORDER:PURCHASER_DISTRIBUTE' //chọn các đơn để tự phân đơn cho chính mình
/*
 * Đơn vi phạm chính sách
 */
export const VIOLATED_ORDER_VIEW = 'VIOLATED_ORDER:VIEW' // Xem đơn vi pham chính sách
export const VIOLATED_ORDER_UPDATE_REASON = 'VIOLATED_ORDER:UPDATE_REASON' // Update lý do vi phạm cho đơn

//Khiếu nại người bán
export const COMPLAINT_SELLER_VIEW = 'COMPLAINT_SELLER:VIEW' // xem khiếu nại người bán
export const COMPLAINT_SELLER_MANAGE = 'COMPLAINT_SELLER:MANAGE' // thêm và sửa KNNB
export const COMPLAINT_SELLER_CREATE = 'COMPLAINT_SELLER:CREATE' // thêm KNNB
export const COMPLAINT_SELLER_ASSIGN = 'COMPLAINT_SELLER:ASSIGN' // assign tất cả các KNNB
export const COMPLAINT_SELLER_RECEIVE = 'COMPLAINT_SELLER:RECEIVE' // tự tiếp nhận KNNB
export const COMPLAINT_SELLER_CHANGE_FAILURE = 'COMPLAINT_SELLER:CHANGE_FAILURE' // chuyển KNNB sang thất bại
export const COMPLAINT_SELLER_CHANGE_FAILURE_ME = 'COMPLAINT_SELLER:CHANGE_FAILURE_ME' // chuyển KNNB của mình sang thất bại
export const COMPLAINT_SELLER_CREATE_PACKAGE = 'COMPLAINT_SELLER:CREATE_PACKAGE' // Tạo kiện o chi tiet KNNB

export const ORDER_SYNC_BILL_NO_SYNC = 'ORDER_SYNC_BILL_NO:SYNC' // Đồng bộ mã vận đơn
export const ORDER_SYNC_BILL_NO_SYNC_TELLER = 'ORDER_SYNC_BILL_NO:SYNC_TELLER' // Đồng bộ mã vận đơn đối với teller

/*
 * Yêu cầu thanh toán
 */
export const PAYMENT_REQUEST_VIEW = 'PAYMENT_REQUEST:VIEW' // Xem yêu cầu thanh toán
export const PAYMENT_REQUEST_VIEW_ALL = 'PAYMENT_REQUEST:VIEW:ALL' // Cho phép Xem được các yêu cầu thanh toán đã tiếp nhận của người khác
export const PAYMENT_REQUEST_PROCESS = 'PAYMENT_REQUEST:PROCESS' // Xử lý yêu cầu thanh toán
export const PAYMENT_REQUEST_PROCESS_ALL = 'PAYMENT_REQUEST:PROCESS:ALL' // Cho phép xử lý các yêu cầu thanh toán của tất cả
export const ORDER_EXTERNAL_PAYMENT_REQUEST = 'ORDER:EXTERNAL:PAYMENT_REQUEST' // tạo yêu cầu thanh toán cho đơn hàng ngoài
export const PAYMENT_REQUEST_APPROVE = 'PAYMENT_REQUEST:APPROVE' // Duyệt YCTT đơn hàng ngoài ở trạng thái chờ duyệt
export const PAYMENT_REQUEST_APPROVE_CHANGES = 'PAYMENT_REQUEST:APPROVE_CHANGES' // Duyệt YCTT đơn hàng thường ở trạng thái chờ duyệt
export const PAYMENT_REQUEST_VIEW_GROUP = 'PAYMENT_REQUEST:VIEW:GROUP' //Xem yctt trong nhom
export const PAYMENT_REQUEST_VIEW_GROUP_ALL = 'PAYMENT_REQUEST:VIEW:GROUP:ALL' //Xem yctt trong tất cả các nhóm
export const PAYMENT_REQUEST_QR_CODE_UPDATE = 'PAYMENT_REQUEST:QR_CODE_UPDATE' //Edit hay xoá ảnh qr code

/*
 * Quyền cấu hình hệ thống
 */
export const SETTING_GENERAL = 'SETTING:GENERAL'
export const SETTING_BUYING = 'SETTING:BUYING'
export const SETTING_TRANSACTIONS = 'SETTING:TRANSACTIONS'
export const SETTING_VIOLATION_CAUSE = 'SETTING:VIOLATION:CAUSE' // Cấu hình lý do mua hàng chậm để phân loại đơn mua hàng chậm

export const PURCHASE_REPORT_VIEW = 'PURCHASE_REPORT:VIEW' // Xem thông tin báo cáo mua hàng
export const PURCHASE_REPORT_MANAGE = 'PURCHASE_REPORT:MANAGE' // Quản lý báo cáo mua hàng

export const TRANSACTION_MANAGE_FILE = 'TRANSACTION:MANAGE_FILE'
export const TRANSACTION_DOWNLOAD_FILE = 'TRANSACTION:DOWNLOAD_FILE'
export const TRANSACTION_UPLOAD_FILE = 'TRANSACTION:UPLOAD_FILE'
export const ORDER_TRANSACTION = 'ORDER:ORDER_TRANSACTION' //  Kiểm soát lợi nhuận đơn
export const SUPPLIER_TRANSACTION_LIST = 'SUPPLIER_TRANSACTION:LIST' // Xem list giao dịch NCC bất thường mà có tài khoản của mình
export const SUPPLIER_TRANSACTION_LIST_NORMAL = 'SUPPLIER_TRANSACTION:LIST_NORMAL' // Xem tất cả list giao dịch NCC
export const SUPPLIER_TRANSACTION_LIST_ALL = 'SUPPLIER_TRANSACTION:LIST_ALL' // Xem tất cả list giao dịch NCC bất thường ko phụ thuộc tài khoản
export const SUPPLIER_TRANSACTION_VIEW = 'SUPPLIER_TRANSACTION:VIEW' // Xem chi tiết giao dịch NCC
export const SUPPLIER_TRANSACTION_RESOLVE = 'SUPPLIER_TRANSACTION:RESOLVE' // Resolve
export const SUPPLIER_TRANSACTION_MARK_UNUSUAL = 'SUPPLIER_TRANSACTION:MARK_UNUSUAL' // Đánh dấu giao dịch là bất thường hoăc bình thường
export const SUPPLIER_TRANSACTION_EDIT = 'SUPPLIER_TRANSACTION:EDIT' // Đánh dấu giao dịch là bất thường hoăc bình thường
export const SETTING_ACCESS_TOKEN_ALIBABA = 'SETTING:ACCESS_TOKEN:ALIBABA' // Quản lý token của tài khoản mua hàng
export const TRANSACTION_EXPORT = 'TRANSACTION:EXPORT' // Xem giao dịch NCC
export const TRANSACTION_MANUAL_ALIPAY_CREATE = 'TRANSACTION:MANUAL_ALIPAY:CREATE' // Tự tạo mã gd

// Phương án trả hàng ~
export const PRODUCT_RETURN_VIEW = 'COMPLAINT_SELLER_RETURN:VIEW'
export const PRODUCT_RETURN_VIEW_ME = 'COMPLAINT_SELLER_RETURN:VIEW_ME'

//tài khoản ngân hàng
export const SETTING_BANK_ACCOUNTS = 'SETTING:BANK_ACCOUNTS' //quản lý tài khoản ngân hàng

// duyệt đề nghị thay đổi MHĐG
export const ORDER_UPDATE_INVOICE_APPROVAL = 'ORDER:UPDATE_INVOICE_APPROVAL' // Duyệt đề nghị thay đổi MHĐG
export const ORDER_ADD_SOURCE_INVOICE_TELLER = 'ORDER:ADD_SOURCE_INVOICE_TELLER' // Thêm đề nghị thay đổi MHĐG
export const ORDER_REMOVE_SOURCE_INVOICE_TELLER = 'ORDER:REMOVE_SOURCE_INVOICE_TELLER' // Xóa đề nghị thay đổi MHĐG

// quyền xem báo cáo
export const SYSTEM_ADMIN = 'SYSTEM:ADMIN'

//quyền quản lý nhóm khi xử lý YCTT mới được tạo/sửa/xóa/xem danh sách nhóm
export const PAYMENT_REQUEST_GROUP_MANAGE = 'PAYMENT_REQUEST:GROUP_MANAGE'

//nhóm quyền quản lý các chức năng đơn hàng từ site TQ
export const TAOBAO_GET_PURCHASE_ORDER = 'TAOBAO:GET_PURCHASE_ORDER' //xem danh sách đơn
export const TAOBAO_CREATE_PURCHASE_ORDER = 'TAOBAO:CREATE_PURCHASE_ORDER' //tạo đơn
export const TAOBAO_BATCH_PAY_PURCHASE_ORDER = 'TAOBAO:BATCH_PAY_PURCHASE_ORDER' //thanh toán đơn

//quyền quản lý xác nhận tài chính khách âm
export const ORDER_CONFIRM_NEGATIVE_BALANCE_CHANGES = 'ORDER:CONFIRM_NEGATIVE_BALANCE_CHANGES'
export const ORDER_VIEW_SALE_CONTACT = 'ORDER:VIEW_SALE_CONTACT' //Quyền xem được thông tin nhân viên CSKH/Sale
//
export const TAOBAO_ACCOUNT_REMOVE = 'TAOBAO:ACCOUNT:REMOVE' //Xoa ket noi account TBDN

export const CREATE_PURCHASE_ORDER_1688 = '1688:CREATE_PURCHASE_ORDER' //Xoa ket noi account TBDN

//quyền cho phép sửa thông tin giá, số lượng, chi phí, tick chọn đơn ngoài, tỷ lệ đặt cọc đơn tự deal
export const SELF_NEGOTIATE_UPDATE_PRODUCT = 'SELF_NEGOTIATE:UPDATE_PRODUCT'
export const SELF_NEGOTIATE_ASSIGN_ORDER = 'SELF_NEGOTIATE:ASSIGN_ORDER'
