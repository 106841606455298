import {takeEvery} from 'redux-saga/effects';
import * as ACTION from './constants';
import * as saga from './saga';

export default function*() {
    yield takeEvery(ACTION.ATTACH_PURCHASER.REQUEST, saga.attachPurchaser);
    yield takeEvery(ACTION.ATTACH_PURCHASER.SUCCESS, saga.onAttachPurchaserSuccess);
    yield takeEvery(ACTION.ATTACH_PURCHASER.FAILED, saga.onAttachPurchaserFailed);
    yield takeEvery(ACTION.DETACH_PURCHASER.REQUEST, saga.detachPurchaser);
    yield takeEvery(ACTION.DETACH_PURCHASER.SUCCESS, saga.onDetachPurchaserSuccess);
    yield takeEvery(ACTION.DETACH_PURCHASER.FAILED, saga.onDetachPurchaserFailed);
}