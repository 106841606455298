import React from "react";
import {currentFormatter} from "../../../modules/common/services/format";

export default function PromotionItem({orderItem}) {

    return (
        <div className="promotion-item">
            <div>
                <a href={orderItem.link} target="_blank"><img src={orderItem.image} alt={orderItem.title}/></a>
            </div>
            <h3><a href={orderItem.link} target="_blank">{orderItem.title}</a></h3>
            <p className="a-text--red a-text--bold">¥{currentFormatter.toLocaleStringCurrency(parseFloat(orderItem.unit_price_origin), 5)}</p>
        </div>
    );
}
