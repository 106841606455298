import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Select, Button} from "antd";
import {translate} from "react-i18next";
import apiService from "../../PaymentRequest/apiService";
import {processResponse} from "../../../systems/api";
import lodash from "lodash";
import notification from '../../../systems/notification';

const Option = Select.Option;

class ProblemConfirmation extends Component {
    constructor(props) {
        super(props);

        this.defaultType = this.props.type;

        this.state = {
            type: this.defaultType,
            reason_id: undefined,
            problemSolved: false
        }
    }

    componentDidMount() {

    }

    confirmProblem(value) {
        if (this.state.loading) {
            return;
        }

        let {t, requestId, order} = this.props;

        this.setState({loading: true});

        let payload = lodash.pickBy({
            type: this.state.type,
            reason_id: value
        }, lodash.identity());
        let res = apiService.updateProblem(requestId, order.id, {...payload})
            .finally(() => this.setState({loading: false}));

        let process = processResponse(res);

        process.then(res => {
            let code = lodash.get(res, 'code', '');
            let status = lodash.get(res, 'data.status', '');
            switch(code) {
                case 200:
                    notification.success(t('paymentRequest:message.problem_resolved', {order: order.code}));
                    status !== 'NONE' && notification.success(t('paymentRequest:message.payment_request_confirmed'));
                    this.setState({
                        problemSolved: true
                    });
                    break;
                case 'INPUT_INVALID':
                    let error = lodash.get(res, 'data', {});
                    if (error.hasOwnProperty('idOrder')) {
                        notification.error(t('paymentRequest:message.order_not_exist'))
                    }
                    let invoice_codes = lodash.get(res, 'data.errors.invoice_codes', []);
                    if (invoice_codes.length !== 0) {
                        let invoice_codes_text = lodash.join(invoice_codes, ', ');
                        notification.error(t('paymentRequest:message.invoice_code_invalid', {invoice_codes: invoice_codes_text}))
                    }
                    break;
                default:
                    notification.success(t('paymentRequest:message.payment_request_confirmed'));
            }
        });

        process.catch((err) => {
            let error = lodash.get(err, 'data', {});
            if (error.hasOwnProperty('idOrder')) {
                notification.error(t('paymentRequest:message.order_not_exist'))
            }
            let invoice_codes = lodash.get(err, 'data.errors.invoice_codes', []);
            if (invoice_codes.length !== 0) {
                let invoice_codes_text = lodash.join(invoice_codes, ', ');
                notification.error(t('paymentRequest:message.invoice_code_invalid', {invoice_codes: invoice_codes_text}))
            }
        });
    }

    onChangeReason(param, value) {
        this.setState({
            [param]: value
        });
        this.confirmProblem(value);
    }

    render() {
        let {t, violateReasons, requestId, orderId, type} = this.props;
        let {loading} = this.state;
        return (
            type === 'policy' ?
                <React.Fragment>
                    <span className="mr-3">{t('paymentRequest:confirm_action.policy')}</span>
                    <Select
                        value={this.state.reason_id}
                        style={{minWidth: '300px'}}
                        optionFilterProp={'children'}
                        showSearch={true}
                        disabled={this.state.problemSolved}
                        placeholder={t('paymentRequest:placeholder.policy')}
                        onChange={this.onChangeReason.bind(this, 'reason_id')}
                    >
                        {
                            violateReasons.map(reason =>
                                <Option key={reason.id} value={reason.id}>
                                    {reason.title}
                                </Option>
                            )
                        }
                    </Select>
                </React.Fragment> :
                <React.Fragment>
                    <span className="mr-3">{t('paymentRequest:confirm_problem.balance')}</span>
                    <Button disabled={this.state.problemSolved} loading={loading} type={"primary"} onClick={this.confirmProblem.bind(this, null)} icon={this.state.problemSolved ? 'check' : null}>Xác nhận</Button>
                </React.Fragment>
        );
    }
}

ProblemConfirmation.propTypes = {};

export default translate()(ProblemConfirmation);
