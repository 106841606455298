import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { translate } from "react-i18next";

class LogProblemOrder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {scroll: false};
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scroll = window.pageYOffset > 74;

        if (this.state.scroll !== scroll) {
            this.setState({scroll});
        }
    };

    render() {
        let {logs, t} = this.props;
        /*if (logs.length > 0) {
            let li_tags =  logs.map(log => {
                return <li className="a-log--problem_order__item">
                    <p className="time-log">{log.creator.username} - {log.time_format}</p>
                    <p className="detail-log"><LogMessage log={log} /></p>
                </li>;
            });

            return <div className={`a-log--problem_order ${this.state.scroll ? 'scroll' :''}`}>
                <div className="a-log--problem_order__title">
                    <span className="title">{t("problem_order.log_title")}</span>
                </div>
                <ul className="a-log--problem_order__body">
                    {li_tags}
                </ul>
            </div>;
        }*/

        return <div className={`a-log--problem_order ${this.state.scroll ? 'scroll' :''}`}>
            <div className="a-log--problem_order__title">
                <span className="title">{t("problem_order.log_title")}</span>
            </div>
            <ul className="a-log--problem_order__body">
                {/*<li className="a-log--problem_order__item text-center">
                    <Icon type="loading" style={{fontSize: '1rem'}} />
                </li>*/}
            </ul>
        </div>;
    }
}

LogProblemOrder.defaultProps = {
    logs: []
}

LogProblemOrder.propTypes = {
    logs: PropTypes.object.isRequired
}

export default translate()(connect()(LogProblemOrder));
