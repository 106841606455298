import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Pagination, Select, Spin, Icon} from 'antd';

const {Option} = Select;

class PaginationFull extends React.Component {
    getPageSize() {
        return this.props.pageSize || 20;
    }

    render() {
        let pageSize = this.getPageSize();
        
        if (this.props.total <= pageSize) {
            return null;
        }

        return (
            <div className={this.props.className || 'a-pagination a-pagination--fixed'}>
                {this.props.loading ? <Spin indicator={<Icon type="loading" spin />} className="mr-5"/> : null}
                <Pagination
                    current={this.props.page}
                    pageSize={pageSize}
                    total={this.props.total}
                    hideOnSinglePage={true}
                    disabled={this.props.disabled}
                    className="a-pagination--detail"
                    onChange={this.props.onChange}
                />
                {this.props.showSizeChanger ? this.renderSizeChanger() : null}
            </div>
        )
    }

    renderSizeChanger() {
        return (
            <div className="a-pagination--select-number-item">
                <span className="text-show mr-2">{this.props.t('pagination.show_view')}</span>
                <Select
                    value={this.getPageSize()}
                    disabled={this.props.disabled}
                    className="a-select--select-number-show"
                    onSelect={pageSize => this.props.onChange(1, pageSize)}
                    getPopupContainer={trigger => trigger.parentNode}
                >
                    {this.props.pageSizeOptions.map(size => <Option key={size} value={size}>{size}</Option>)}
                </Select>
            </div>
        );
    }
}

PaginationFull.defaultProps = {
    page: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: [20, 30, 40, 50],
    loading: false,
    disabled: false,
    className: null,
    onChange: (page, pageSize) => {},
};

PaginationFull.propTypes = {
    page: PropTypes.number,
    pageSize: PropTypes.number,
    total: PropTypes.number,
    showSizeChanger: PropTypes.bool,
    pageSizeOptions: PropTypes.array,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

export default translate()(PaginationFull);
