import React, { useState, useEffect } from "react";
import moment from 'moment';
import {Col, Tooltip, Input, Icon, notification} from "antd";
import {translate} from "react-i18next";
import apiService from "../../apiService";
import {processResponse} from "../../../../systems/api";
import SynchronizeComplaint from "./SynchronizeComplaint";
import Link from "../../../../systems/routing/Link";
import lodash from "lodash";

class OriginCode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            originCode: lodash.get(this.props, "complaint.origin_code", "")
        };
    }

    onClick = e => {
        this.setState({isEdit: true});
    }

    onChange = e => {
        this.setState({originCode: e.target.value})
    };

    onBlur = e => {
        this.setState({
            isEdit: true,
            originCode: lodash.get(this.props, "complaint.origin_code", "")
        });
    };

    updateOriginCode = () => {
        let {t, complaint, order, updateOriginCodeComplaint} = this.props;
        let { originCode } = this.state;
        if (complaint.origin_code === originCode) {
            this.setState({isEdit: false});
            return ;
        }

        let process = processResponse(apiService.updateOriginCode(complaint.id, {origin_code: originCode}));
        process.then(res => {
            updateOriginCodeComplaint(res);
            this.setState({isEdit: false});
            notification.success({message: "Cập nhật mã KN site gốc thành công"});
        });

        process.catch(error => {
            notification.error({message: "Cập nhật mã KN site gốc không thành công"});
        })
    }

    renderComplainInfo = () => {
        let {complaint} = this.props;
        let {origin_code, origin_delivery_code, origin_amount, origin_expired_at, origin_invoice_code, origin_status} = complaint;

        return (
            <div style={{ fontSize: 13 }}>
                <div style={{ whiteSpace: "nowrap" }}>Thời gian gia hạn KN: {origin_expired_at && moment(origin_expired_at).format("HH:mm DD/MM/YYYY")}</div>
                <div>Mã KN site gốc: {origin_code}</div>
                <div>Số tiền KN: {origin_amount && `¥${origin_amount}`}</div>
                <div>Trạng thái KN: {origin_status}</div>
                <div>Mã vận đơn trả hàng: {origin_delivery_code}</div>
                <div>Mã HĐG: {origin_invoice_code}</div>
            </div>
        )
    };

    render() {
        let { isEdit, originCode } = this.state;
        let {t, complaint, order} = this.props;

        let origin_link = "";
        if (complaint.origin_code){
            switch (order.seller_source) {
                case "taobao":
                    origin_link = "https://refund2.taobao.com/dispute/detail.htm?disputeId=" + complaint.origin_code;
                    break;
                case "tmall":
                    origin_link = "https://refund2.tmall.com/dispute/detail.htm?type=1&disputeId=" + complaint.origin_code;
                    break;
                case "1688":
                    origin_link = "https://dispute.1688.com/refund/assureRefundDetail.htm?refundId=" + complaint.origin_code;
                    break;
            }
        }

        return (
            <>
                <Col span={8} className="cs-order-row">
                    <label className="a-text--font-b">{t('complaintSeller:detail.origin_code')}:</label>
                </Col>
                <Col span={16} className="d-block cs-order-row">
                    {
                        isEdit ?
                            <Input
                                size="small"
                                onPressEnter={this.updateOriginCode}
                                onChange={this.onChange}
                                onBlur={this.onBlur}
                                value={originCode}
                            /> :
                            <>
                                {
                                    origin_link &&
                                    <Tooltip placement="top" title={this.renderComplainInfo()}>
                                        <Link isNewTab={true} href={origin_link}>
                                            <span style={{wordBreak: "break-all"}}>{complaint.origin_code}</span>
                                        </Link>
                                    </Tooltip>
                                }

                                <span
                                    className="a-text--pointer a-text--color-primary a-text--fz-12 ml-2 mr-2"
                                    onClick={this.onClick}
                                >
                                {
                                    (complaint.origin_code) ?
                                        <Icon type="edit" /> :
                                        <><Icon type="edit" /> Cập nhật</>

                                }
                            </span>
                                <SynchronizeComplaint
                                    complaint={complaint}
                                    order={order}
                                    updateComplaintDetailAgain={this.props.updateComplaintDetailAgain}
                                />
                            </>
                    }
                </Col>
            </>
        );
    }
}

export default translate()(OriginCode);
