
export const FAILED_COMPLAINT = {
    REQUEST: 'COMPLAINT_SELLER.FAILED_COMPLAINT.REQUEST',
    SUCCESS: 'COMPLAINT_SELLER.FAILED_COMPLAINT.SUCCESS',
    FAILED: 'COMPLAINT_SELLER.FAILED_COMPLAINT.FAILED',
};

export const RESET_PARAM_ERROR = 'COMPLAINT_SELLER.FAILED_COMPLAINT.RESET_PARAM_ERROR';

export const CLEAR_STATE = 'COMPLAINT_SELLER.FAILED_COMPLAINT.CLEAR_STATE';
