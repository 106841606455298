import {connect} from 'react-redux';
import lodash from 'lodash';
import DetailComplaintSeller from './components';
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from '../../common/actions';
import * as Selector from './../selectors';
import * as ProductReturnSelector from './../../ComplaintProductReturn/selectors';
import {
    fetchComplaintDetail,
    clearComplaintDetail,
    openModalChangeSuccessForm,
    expireUpdateComplaintDetail,
    expireClearErrorComplaintDetail,
    fetchComplaintSellerReasons,
    rejectedComplaintSeller,
    updateComplaintSellerReason,
    updateOriginCodeComplaint,
    addCommentProduct,
    addTransactionCode,
    updateComplaintDetailAgain
} from "../actions";
import {clearErrorComplaintSellerSuccess} from '../CompleteComplaint/actions';
import {fetchUsersComplaintSeller} from '../../User/actions';
import  {fetchListProductReturns} from "../../ComplaintProductReturn/actions";

const mapStateToProps = (state) => {
    return {
        complaintSellerReasons: Selector.getComplaintSellerReason(state),
        complaint: Selector.getComplaintSellerInfo(state),
        canUpdateRenew: lodash.get(state, 'ComplaintSeller.complaintDetail.permissions.can_update_renew', false),
        permissions: Selector.getState(state, 'permissions', {}),
        alipays: Selector.getAlipays(state),
        loading: Selector.getState(state, 'loading'),
        order: Selector.getOrderInfo(state),
        renewReasons: lodash.get(state, 'ComplaintSeller.complaintDetail.renewReasons', false),
        purchasers: Selector.getUsersComplaintSellerReceive(state),
        error: lodash.get(state, 'ComplaintSeller.complaintDetail.error', {}),
        listProductReturns: ProductReturnSelector.getListProductReturn(state),
        isModalChangeSuccessProcessing: lodash.get(state, 'ComplaintSeller.completeComplaint.isModalProcessing', false),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        fetchComplaintDetail: (param) => {
            dispatch(fetchComplaintDetail(param));
        },
        fetchProductReturns: (filters) => {
            filters = {...filters, ...{scope: 'basic'}};
            dispatch(fetchListProductReturns(filters));
        },
        expireUpdateComplaintDetail: (params) => {
            dispatch(expireUpdateComplaintDetail(params));
        },
        updateComplaintDetailAgain: (params) => {
            dispatch(updateComplaintDetailAgain(params));
        },
        expireClearErrorComplaintDetail: (params) => {
            dispatch(expireClearErrorComplaintDetail(params));
        },
        openModalChangeSuccessForm: () => {
            dispatch(openModalChangeSuccessForm());
        },
        clearErrorComplaintSellerSuccess: () => {
            dispatch(clearErrorComplaintSellerSuccess());
        },
        clearComplaintDetail: () => {
            dispatch(clearComplaintDetail());
        },
        fetchUsersComplaintSeller: () => {
            dispatch(fetchUsersComplaintSeller({pagination: 0,scope: 'basic'}));
        },
        fetchComplaintSellerReasons: (filters) => {
            filters = {...filters, ...{status: 1, scope: 'basic'}};
            dispatch(fetchComplaintSellerReasons(filters));
        },
        rejectedComplaintSellers: (data) => {
            dispatch(rejectedComplaintSeller(data));
        },
        updateComplaintSellerReason: (data) => {
            dispatch(updateComplaintSellerReason(data));
        },
        updateOriginCodeComplaint: (data) => {
            dispatch(updateOriginCodeComplaint(data));
        },
        addCommentProduct: (data) => {
            dispatch(addCommentProduct(data));
        },
        addTransactionCode: data => dispatch(addTransactionCode(data))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DetailComplaintSeller)
