import {call, put} from 'redux-saga/effects'
import * as Constants from './constants';
import service from './apiService';
import bootbox from "../../common/components/Bootbox/bootbox";
import {t} from '../../../systems/i18n/index.js';
import * as GlobalConstants from "../../../systems/globalContant/index";

export function* fetchTransaction(action) {
    try {
        let response = yield call(service.getListTransaction, action.payload);
        if (response) {
            if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({type: Constants.GET_LIST_TRANSACTION.SUCCESS, payload: response.data});
            } else if (response.data.code === GlobalConstants.GLOBAL_CONTANT.PERMISSION_DENIED) {
                yield put({type: Constants.GET_LIST_TRANSACTION.FAILED, payload: response.data});
            } else {
                bootbox.error(t('message.system_errors'));
                yield put({type: Constants.GET_LIST_TRANSACTION.FAILED, payload: response.data});
            }
        }
    } catch (err) {
        yield put({type: Constants.GET_LIST_TRANSACTION.FAILED, payload: err});
        bootbox.error(t('message.system_errors'));
    }
}
