import React, {Component} from "react";
import {Divider, Input, Modal} from "antd";
import {translate} from "react-i18next";
import apiService from "./apiService";
import {processResponse} from "../../../systems/api";
import {events} from "../../../systems/events";
import notification from "../../../systems/notification";
import ORDER_EVENT from "../../Order/events";
import lodash from "lodash";
import {dateFormatter} from "../../common/services/format";
import {Icon} from "antd";
import authService from "../../Auth/authService";
import * as PERMISSION from "../../Permission/permissions";
import "./style.scss";

const ModalTitle = function () {
    return <>
        <Icon type="question-circle" className="a-text--color-warning a-font--18 mr-1" /> Bỏ đánh dấu đơn có vấn đề đã được xử lý
    </>;
}

class UnresolveOrder extends Component {

    constructor({props}) {
        super(props);
        this.state = {
            visible: false,
            loading: false
        };
    }

    showModal = (e) => {
        e.preventDefault();
        this.setState({visible: true});
    }

    handleOk = e => {
        e.preventDefault();
        this.setState({loading: true});
        let { order, t } = this.props;

        let res = apiService.unresolve(order.id);
        res.finally(() => {
            this.setState({visible: false, loading: false});
        });

        let process = processResponse(res);

        process.then(res => {
            notification.success('Bỏ đánh dấu đơn có vấn đề đã được xử lý thành công');
            events.dispatch(ORDER_EVENT.UNRESOLVED_ORDER, {
                id: order.id,
                data: {
                    is_resolved: lodash.get(res, "data.orderDetail.is_resolved"),
                    ...lodash.pick(res.data.orderDetail, ['problemResolveCreator', 'problem_resolve_creator_id', 'problem_resolve_note', 'problem_resolve_time'])
                }
            });
        });

        process.catch(() => {
            notification.error('Bỏ đánh dấu đơn có vấn đề đã được xử lý không thành công');
        });
    }

    handleCancel = e => {
        this.setState({visible: false});
    }

    render() {
        let {order, t, hideResolve} = this.props;
        let problem_resolve_note = lodash.get(order, "orderDetail.problem_resolve_note", order.problem_resolve_note);
        let problem_resolve_time = lodash.get(order, "orderDetail.problem_resolve_time", order.problem_resolve_time);
        let problemResolveCreator = lodash.get(order, "orderDetail.problemResolveCreator", order.problemResolveCreator);
        return <div className="text-black-50">
            <p className="a-text--gray-new">
                <span className="a-text--bold"><Icon type="check-circle" className="a-text--success" /> Resolved</span>
                <Divider type="vertical"/>
                { !lodash.isEmpty(problem_resolve_note) &&
                    <>
                        <span>{t('transaction:label.resolve_reason')}: {problem_resolve_note}</span>
                        <Divider type="vertical"/>
                    </>
                }
                <span>{t('transaction:label.resolver')}: {lodash.get(problemResolveCreator, 'name')}</span>
                <Divider type="vertical"/>
                <span>{dateFormatter.dateTime(problem_resolve_time)}</span>
                { authService.can(PERMISSION.ORDER_FINANCIAL_RESOLVE) && !hideResolve &&
                    <>
                        <Divider type="vertical"/>
                        <a href="#" onClick={this.showModal}>Unresolve</a>
                    </>
                }
            </p>
            <Modal
                centered={true}
                title={<ModalTitle/>}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                cancelText="Hủy"
                okText="Unresolve"
                okButtonProps={{loading: this.state.loading}}
                wrapClassName="unresolve-order-modal"
                closable={false}
                width={440}
            ></Modal>
        </div>;
    }
}

export default translate()(UnresolveOrder);
