import Dispatcher from './Dispatcher';
import WithEventFactory from './WithEventFactory';

let events = new Dispatcher(process.env.NODE_ENV !== 'production');
let withEventFactory = new WithEventFactory(events);

const withEvent = withEventFactory.make.bind(withEventFactory);

export {
    events,
    withEvent,
};
