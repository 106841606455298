import React from "react";
import {createSelector} from 'reselect';
import lodash from 'lodash';
import {dateFormatter, currentFormatter} from '../../common/services/format';
import {trans} from './../../../systems/i18n';
import {url} from "../../../systems/routing";
import Link from "../../../systems/routing/Link";

const listTransactions = (_, props) => lodash.get(props, "dataOrder.transactions", []);

export const getListTransactions = createSelector(
    listTransactions,
    transactions => transactions.map(transaction => {
        transaction = {...transaction};
        transaction.transaction_time_format = (transaction.transaction_time === '0000-00-00 00:00:00' || transaction.transaction_time === '-0001-11-30 00:00:00') ? '---' : dateFormatter.dateTime(transaction.transaction_time);
        transaction.code_format =
            <Link
                  href={url.to('transaction.detail', {id: transaction.id})}
                  className="link_item">
                {transaction.code}
            </Link>;
        transaction.type_format = trans('transaction:type.' + transaction.type);
        transaction.amount_format = '¥' + currentFormatter.toLocaleStringCurrency(parseFloat(transaction.amount), 5);
        return transaction;
    })
)