import React, {useState} from 'react';
import lodash from 'lodash';
import {notification, Select, Spin, Modal, Form, Button, Tooltip} from 'antd';
import apiService from "../../apiService";
import {t} from "../../../../../systems/i18n";
import {processResponse} from "../../../../../systems/api";

const {Option} = Select;
export default function UpdateHSCodeMultipleItems(props) {
    const {orderId, items, updateHSCodeOrderDetail, onClearCheckAll, updateItemsOrderDetail} = props;
    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [value, setValue] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    function isNumber(n) {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    }

    const fetchHSCode = lodash.debounce((value) => {
        setData([]);
        if (value) {
            const isSearch = isNumber(value) ? value.length >= 2 : value.length >= 4;
            if (isSearch) {
                setFetching(true);
                processResponse(apiService.searchHSCode({code: value}))
                    .then(res => {
                        setData(lodash.get(res, "data", []));
                    }).catch(err => {
                    notification.error({message: t("orderDetail:hscode.get_fail")});
                }).finally(() => {
                    setFetching(false);
                })
            }
        }
    }, 500);

    function handleChange(value) {
        setFetching(false);
        setValue(value);
    };

    function handleUpdateHSCode() {
        setVisible(true);
    }

    function handleOk() {

        setLoading(true);
        const result = processResponse(apiService.updateHSCodeOrderItem(orderId, {code: value, item_ids: items}));
        result.then(res => {
            updateItemsOrderDetail(res);
            updateHSCodeOrderDetail(res);
            onClearCheckAll();
            setVisible(false);
            setValue(undefined);
            notification.success({message: t("orderDetail:hscode.update_multi_success")})
        }).catch(err => {
            notification.error({message: t("orderDetail:hscode.update_multi_success")})
        }).finally(() => setLoading(false))
    }

    function handleCancel() {
        setVisible(false);
        setFetching(false);
        setLoading(false);
    }

    return (
        <>
            <span className={props.seperate ? "seperate-v" : ""}>
                <a className={(items.length ? "" : "a-disable")} onClick={handleUpdateHSCode}>
                    {t('orderDetail:hscode.update_hscode_items')}
                </a>
            </span>
            {
                visible &&
                <Modal
                    title={t("orderDetail:hscode.title_update_hscode_items")}
                    onCancel={handleCancel}
                    visible={visible}
                    width={"40%"}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            {t("btn.cancel")}
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={handleOk}
                        >
                            {t("btn.ok")}
                        </Button>
                    ]}
                >
                    <Form.Item
                        label="HSCode"
                        labelCol={{span: 4}}
                        wrapperCol={{span: 18}}
                    >
                        <Select
                            allowClear={true}
                            showSearch
                            value={value}
                            placeholder={t("orderDetail:hscode.placeholder_search")}
                            notFoundContent={fetching ? <Spin size="small"/> : null}
                            filterOption={false}
                            onSearch={(value) => fetchHSCode(value)}
                            onChange={(value) => handleChange(value)}
                            style={{width: '100%'}}
                        >
                            {
                                data.map(hscode => (
                                    <Option key={hscode.code_8_digit}>
                                        <Tooltip title={hscode.code_8_digit + " - " + hscode.name_8_digit}>
                                            {hscode.code_8_digit + " - " + hscode.name_8_digit}
                                        </Tooltip>
                                    </Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Modal>
            }
        </>
    )
};

