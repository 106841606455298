import {call, put} from 'redux-saga/effects';
import * as Constants from './constants';
import * as GlobalConstants from '../../../systems/globalContant';
import service from './apiService';
import bootbox from './../../common/components/Bootbox/bootbox';
import {t} from './../../../systems/i18n/index.js';
import lodash from "lodash"
import { getPurchasingOrderDetailAction } from '../OrderDetail/actions';
import { dispatch } from '../../../systems/store';

export function* updateRealPaymentAmount(action) {
    try {

        let response = yield call(service.updateRealPaymentAmount, action.payload);
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.UPDATE_REAL_PAYMENT_AMOUNT_ORDER.SUCCESS, payload: response.data});
            bootbox.success(t('newLocals:order.message.update_real_payment_success'));
        } else {
            yield put({type: Constants.UPDATE_REAL_PAYMENT_AMOUNT_ORDER.FAILED, payload: response.data});

            if(typeof response !== 'undefined' && response.data.code === "INPUT_INVALID" ){
                Object.entries(response.data.data).forEach(([key,value])=>{
                    bootbox.error(t(`newLocals:order.message.real_payment.${key}.${lodash.keys(value)}`));
                })

            }else{
                bootbox.error(t('newLocals:order.message.update_real_payment_fail'));
            }
        }
        dispatch(getPurchasingOrderDetailAction({id:lodash.get(action, 'payload.id')}))
    } catch (err) {
        bootbox.error(t("messages.system_errors") + " : " + err.message);
    }
}

export function* updateRealRefundAmount(action) {
    try {
        let response = yield call(service.updateRealRefundAmount, action.payload);
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.UPDATE_REAL_REFUND_AMOUNT_ORDER.SUCCESS, payload: response.data});
            bootbox.success(t('newLocals:order.message.update_real_refund_success'));
        } else {
            yield put({type: Constants.UPDATE_REAL_REFUND_AMOUNT_ORDER.FAILED, payload: response.data});

            if(typeof response !== 'undefined' && response.data.code === "INPUT_INVALID" ){
                Object.entries(response.data.data).forEach(([key,value])=>{
                    bootbox.error(t(`newLocals:order.message.refund_payment.${key}.${lodash.keys(value)}`));
                })
            }else{
                 bootbox.error(t('newLocals:order.message.update_real_refund_fail'));
            }

           
        }
        dispatch(getPurchasingOrderDetailAction({id:lodash.get(action, 'payload.id')}))
    } catch (err) {
        bootbox.error(t("messages.system_errors") + " : " + err.message);
    }
}


