import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

import { Button, Col, DatePicker, Form, Icon, Input, Row, Checkbox, Select } from 'antd'
import FormProcessing from '../../modules/common/components/FormProcessing'
import moment from 'moment'
import lodash from 'lodash'
import SelectAccountPurchasers from '../../modules/AccountPurchaser/SelectAccountPurchasers'
import SelectTeller from '../../modules/User/SelectTellers'
import FormStatuses from './FormStatuses'
import authService from '../../modules/Auth/authService'
import * as PERMISSIONS from '../../modules/Permission/permissions'
import { hasPermission, permissions } from '../../modules/Permission'
import { SUCCESS_FROM } from '../../modules/PaymentRequest/constans'
import {SOURCE_ACCOUNT_PURCHASER} from "../../modules/ComplaintSeller/constants";

const { RangePicker } = DatePicker
const dateFormat = 'YYYY/MM/DD'

const currentDay = moment('23:59:59', 'HH:mm:ss')
const oneMonthsAgo = moment('00:00:00', 'HH:mm:ss').subtract(1, 'M')
class FormSearch extends FormProcessing {
    constructor(props) {
        super(props)
        this.defaultInput = {
            order_code: '',
            invoice_code: '',
            seller_source: '',
            id_account_purchaser: '',
            id_sender_request: '',
            created_at_from: oneMonthsAgo.format('YYYY-MM-DD 00:00:00'),
            created_at_to: currentDay.format('YYYY-MM-DD 23:59:59'),
            external_order: 0,
            not_me: '',
            id: '',
            success_from: '',
        }
        this.state = {
            input: { ...this.defaultInput },
            inputVersion: 0,
            changedParams: [],
        }
    }

    onSelectStatus = filter => {
        this.changeInput(filter)
        if (!this.props.loading) this.props.onSearch({ ...this.state.input, ...filter })
    }

    onChangeText(param, event) {
        this.handleUpdateCSVFilter({ [param]: event.target.value })
        this.onChangeInput(param, event)
    }

    onChangeSelect(name, value) {
        this.changeInput({ [name]: value })
        this.handleUpdateCSVFilter({ [name]: value })
    }

    onCheckBox = (param, e) => {
        let input = this.changeInput({ [param]: e.target.checked ? 1 : '' })
        if (!this.props.loading) this.props.onSearch(input)
    }

    onChangeRequestTime(date) {
        const created_at_from = date[0] ? date[0].format('YYYY-MM-DD') : ''
        const created_at_to = date[1] ? date[1].format('YYYY-MM-DD') : ''
        this.changeInput({
            created_at_from,
            created_at_to,
        })
        this.handleUpdateCSVFilter({ created_at_from, created_at_to })
    }

    onChangeLastTime(date) {
        const last_state_time_from = date[0] ? date[0].format('YYYY-MM-DD') : ''
        const last_state_time_to = date[1] ? date[1].format('YYYY-MM-DD') : ''
        this.changeInput({
            last_state_time_from,
            last_state_time_to,
        })
        this.handleUpdateCSVFilter({ last_state_time_from, last_state_time_to })
    }

    onSubmit(event) {
        let { input } = this.state
        event.preventDefault()
        Object.keys(input).map(key => (input[key] = typeof input[key] == 'string' ? input[key].trim() : input[key]))

        if (!this.props.loading && this.props.canSearch) {
            this.props.onSearch({ ...this.state.input, page: 1 })
        }
    }

    onReset(event) {
        event.preventDefault()

        if (!this.props.loading) {
            this.changeInput(this.defaultInput)
            this.props.onSearch({
                ...this.defaultInput,
                status: 'WAITING',
            })
            this.props.changeCSVFilter({
                ...this.defaultInput,
                status: 'WAITING',
            })
        }
    }

    handleUpdateCSVFilter(filter) {
        this.props.changeCSVFilter({ ...this.state.input, ...filter })
    }

    render() {
        let { t, errors, groups } = this.props
        let { input } = this.state
        let { external_order, payment_request_group_id, success_from, self_negotiate} = input
        return (
            <div className="a-list--form-search mr-0 ml-0">
                <Row gutter={24}>
                    <Form
                        labelcol={{ span: 12 }}
                        onSubmit={this.onSubmit.bind(this)}>
                        <Col
                            lg={6}
                            xl={6}
                            xxl={6}
                            className="mb-2">
                            <Form.Item
                                label={t('paymentRequest:label.id')}
                                className="a-form--ant-item mb-0">
                                <Input
                                    className="_payment-request-list-search-payment-request-id"
                                    type={'text'}
                                    value={input.id || ''}
                                    placeholder={t('paymentRequest:placeholder.id')}
                                    onChange={this.onChangeText.bind(this, 'id')}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={6}
                            xl={6}
                            xxl={6}
                            className="mb-2">
                            <Form.Item
                                label={t('paymentRequest:label.code')}
                                className="a-form--ant-item mb-0">
                                <Input
                                    className="_payment-request-list-search-order-code"
                                    type={'text'}
                                    value={input.order_code || ''}
                                    placeholder={t('paymentRequest:placeholder.code')}
                                    onChange={this.onChangeText.bind(this, 'order_code')}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={6}
                            xl={6}
                            xxl={6}
                            className="mb-2">
                            <Form.Item
                                label={t('paymentRequest:label.invoice_code')}
                                className="a-form--ant-item mb-0">
                                <Input
                                    className="_payment-request-list-search-invoice-code"
                                    type={'text'}
                                    value={input.invoice_code || ''}
                                    placeholder={t('paymentRequest:placeholder.invoice_code')}
                                    onChange={this.onChangeText.bind(this, 'invoice_code')}
                                />
                            </Form.Item>
                        </Col>

                        <Col lg={6} xl={6} xxl={6} className="mb-2">
                            <Form.Item label={t("paymentRequest:label.seller_source")} className="a-form--ant-item  mb-0">
                                <Select
                                    showSearch={true}
                                    value={input.seller_source}
                                    placeholder={t("paymentRequest:placeholder.seller_source")}
                                    className="single-react"
                                    noOptionsMessage={({inputValue: string}) => t('message.no_record')}
                                    onSelect={this.onChangeSelect.bind(this, 'seller_source')}
                                    optionFilterProp="children"
                                >
                                    {SOURCE_ACCOUNT_PURCHASER.map(type => (
                                        <Select.Option
                                            key={type.value}
                                            value={type.value}
                                        >{type.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        {hasPermission(permissions.PAYMENT_REQUEST_VIEW_GROUP_ALL) && groups.length > 0 && (
                            <Col
                                lg={6}
                                xl={6}
                                xxl={6}
                                className="mb-2">
                                <Form.Item
                                    label="Nhóm YCTT"
                                    className="a-form--ant-item  mb-0">
                                    <Select
                                        showSearch={true}
                                        value={payment_request_group_id ? parseInt(payment_request_group_id) : undefined}
                                        placeholder="Chọn nhóm YCTT"
                                        className="single-react"
                                        onSelect={this.onChangeSelect.bind(this, 'payment_request_group_id')}
                                        optionFilterProp="children">
                                        {groups.map((item, index) => {
                                            return (
                                                <Select.Option
                                                    key={index}
                                                    value={item.id}>
                                                    {item.code} - {item.name}
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                        <Col
                            lg={6}
                            xl={5}
                            xxl={5}
                            className="mb-2">
                            <Form.Item
                                label={t('paymentRequest:label.account_purchaser')}
                                className="a-form--ant-item  mb-0">
                                <SelectAccountPurchasers
                                    className="_payment-request-list-search-account-purchaser"
                                    showName
                                    allOption
                                    allAccount={true}
                                    placeholder={t('transaction:placeholder.account')}
                                    loading={this.props.loading}
                                    onChange={this.onChangeSelect.bind(this, 'id_account_purchaser')}
                                    value={input.id_account_purchaser}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={6}
                            xl={5}
                            xxl={5}
                            className="mb-2">
                            <Form.Item
                                label={t('paymentRequest:label.request_creator')}
                                className="a-form--ant-item  mb-0">
                                <SelectTeller
                                    className="w-100 _payment-request-list-search-request-creator"
                                    showName
                                    allOption
                                    value={input.id_sender_request}
                                    showArrow={false}
                                    placeholder={t('transaction:placeholder.resolver')}
                                    loading={this.props.loading}
                                    onChange={this.onChangeSelect.bind(this, 'id_sender_request')}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={8}
                            xl={8}
                            xxl={8}
                            className="mb-2">
                            <Form.Item
                                label={t('paymentRequest:label.request_time')}
                                className="a-form--ant-item  mb-0">
                                <RangePicker
                                    className="_payment-request-list-search-request-time"
                                    placeholder={[t('transaction:placeholder.begin'), t('transaction:placeholder.end')]}
                                    format="DD/MM/YYYY"
                                    value={[
                                        input['created_at_from'] ? moment(input['created_at_from'], dateFormat) : null,
                                        input['created_at_to'] ? moment(input['created_at_to'], dateFormat) : null,
                                    ]}
                                    style={{ width: '100%' }}
                                    onChange={this.onChangeRequestTime.bind(this)}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={8}
                            xl={8}
                            xxl={8}
                            className="mb-2">
                            <Form.Item
                                label={t('paymentRequest:label.last_time')}
                                className="a-form--ant-item mb-0">
                                <RangePicker
                                    className="_payment-request-list-search-last-time"
                                    placeholder={[t('transaction:placeholder.begin'), t('transaction:placeholder.end')]}
                                    format="DD/MM/YYYY"
                                    value={[
                                        input['last_state_time_from'] ? moment(input['last_state_time_from'], dateFormat) : null,
                                        input['last_state_time_to'] ? moment(input['last_state_time_to'], dateFormat) : null,
                                    ]}
                                    style={{ width: '100%' }}
                                    onChange={this.onChangeLastTime.bind(this)}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={8}
                            xl={8}
                            xxl={8}
                            className="mb-2">
                            <Form.Item
                                label={t('paymentRequest:label.amount')}
                                className="a-form--ant-item mb-0">
                                <Input.Group compact>
                                    <Input
                                        style={{ width: '45%' }}
                                        className="text-center border-right-0 _payment-request-list-search-amount-from"
                                        type={'text'}
                                        value={input.amount_from || ''}
                                        placeholder={t('paymentRequest:placeholder.from')}
                                        onChange={this.onChangeText.bind(this, 'amount_from')}
                                    />
                                    <Input
                                        style={{ width: '10%', pointerEvents: 'none', backgroundColor: '#fff' }}
                                        className="text-center border-left-0"
                                        placeholder="-"
                                        disabled
                                    />
                                    <Input
                                        style={{ width: '45%' }}
                                        className="text-center border-left-0 _payment-request-list-search-amount-to"
                                        type={'text'}
                                        value={input.amount_to || ''}
                                        placeholder={t('paymentRequest:placeholder.to')}
                                        onChange={this.onChangeText.bind(this, 'amount_to')}
                                    />
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col
                            lg={4}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('paymentRequest:label.success_from')}
                                className="a-form--ant-item mb-0">
                                <Select
                                    showSearch={true}
                                    value={success_from ? success_from : ''}
                                    placeholder={t('paymentRequest:placeholder.success_from')}
                                    className="single-react"
                                    onSelect={this.onChangeSelect.bind(this, 'success_from')}
                                    optionFilterProp="children">
                                    <Select.Option key={'all'} value={""}>Tất cả</Select.Option>
                                    {SUCCESS_FROM.map((item, index) => {
                                        return (
                                            <Select.Option
                                                key={index}
                                                value={item}>
                                                {t(`paymentRequest:success_from.${item}`)}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            lg={4}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item label={t('order:label.type_order')} className="a-form--ant-item mb-0">
                                <Select
                                    value={self_negotiate ? self_negotiate : ''}
                                    allowClear={true}
                                    placeholder={t('order:placeholder.type_order')}
                                    onChange={this.onChangeSelect.bind(this,'self_negotiate')}>
                                    <Select.Option key="" value="">Tất cả</Select.Option>
                                    <Select.Option key="YES" value="YES">Đơn tự deal</Select.Option>
                                    <Select.Option key="NO" value="NO">Đơn thường</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 24 }}
                            xl={{ span: 24 }}
                            className="mt-2 pt-5 border-top-dashed">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="a-list--action-search flex-row">
                                    <Button
                                        loading={this.props.loading && parseInt(external_order) === 0}
                                        disabled={!lodash.isEmpty(errors)}
                                        onClick={e => this.onSelectStatus({ external_order: 0, status: 'WAITING' })}
                                        className={
                                            'a-text--uppercase a-btn--primary-ant mx-1 _payment-request-list-search-internal-order' +
                                            (parseInt(external_order) === 0 ? '' : ' ghost')
                                        }>
                                        YCTT đơn hàng thường
                                    </Button>
                                    <Button
                                        loading={this.props.loading && parseInt(external_order) === 1}
                                        disabled={!lodash.isEmpty(errors)}
                                        onClick={e => this.onSelectStatus({ external_order: 1, status: 'PENDING' })}
                                        className={
                                            'a-text--uppercase a-btn--primary-ant mx-1 _payment-request-list-search-external-order' +
                                            (parseInt(external_order) === 1 ? '' : ' ghost')
                                        }>
                                        {t('paymentRequest:btn.external_order')}
                                    </Button>
                                    {authService.canAny([PERMISSIONS.PAYMENT_REQUEST_VIEW_ALL, PERMISSIONS.PAYMENT_REQUEST_VIEW_GROUP_ALL]) && (
                                        <Checkbox
                                            onChange={e => this.onCheckBox('not_me', e)}
                                            checked={input.not_me ? true : false}
                                            className="ml-2 mt-1">
                                            Hiển thị YCTT không phải bạn phụ trách
                                        </Checkbox>
                                    )}
                                </div>
                                <div className="a-list--action-search flex-row">
                                    <a
                                        href="#"
                                        onClick={this.onReset.bind(this)}
                                        className="link-reset mr-5">
                                        <Icon type="sync" />
                                        {t('btn.reset_search')}
                                    </a>
                                    <Button
                                        loading={this.props.loading}
                                        // disabled={!lodash.isEmpty(init_errors)}
                                        className="a-text--uppercase a-btn--padding-large a-btn--primary-ant _payment-request-list-search-button-search"
                                        type="primary"
                                        htmlType="submit">
                                        {t('btn.search')}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 24 }}
                            xl={{ span: 24 }}
                            className="mt-2 pt-5">
                            <FormStatuses
                                t={this.props.t}
                                input={this.props.input}
                                listStatus={this.props.listStatus}
                                loading={this.props.loading}
                                loadingCount={this.props.loadingCount}
                                statsCount={this.props.statsCount}
                                status={this.props.status}
                                filter={this.props.filter}
                                newPENDINGPaymentRequest={this.props.newPENDINGPaymentRequest}
                                newWAITINGPaymentRequest={this.props.newWAITINGPaymentRequest}
                                onSearch={this.props.onSearch.bind(this)}
                                checkNewPaymentRequest={this.props.checkNewPaymentRequest}
                            />
                        </Col>
                    </Form>
                </Row>
            </div>
        )
    }
}
FormSearch.defaultProps = {
    input: {},
    inputVersion: null,
    loading: false,
    canSearch: true,
    onSearch: input => {},
    changeCSVFilter: () => {},
}
FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.any,
    loading: PropTypes.bool,
    canSearch: PropTypes.bool,
    onSearch: PropTypes.func,
    changeCSVFilter: PropTypes.func,
}
export default translate()(FormSearch)
