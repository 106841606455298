import React from 'react';
import {Form, Row, Col, Input, Select, DatePicker, Button, Icon} from 'antd';

const Search = Input.Search;
const Option = Select.Option;
const {RangePicker} = DatePicker;
const InputGroup = Input.Group;

class FormSearch extends React.Component {
    render() {
        return (
            <Row gutter={32} className="a-list--form-search mr-0 ml-0">
                <Form labelCol={{span: 12}}>
                    <Col xs={6} sm={6} md={5} lg={5} xl={4} className="pl-0">
                        <Form.Item label="Mã đơn" className="a-form--ant-item  mb-0">
                            <Search/>
                        </Form.Item>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={4}>
                        <Form.Item label="Lợi nhuận mặc cả " className="a-form--ant-item  mb-0">
                            <InputGroup compact className="a-group--input">
                                <Input  placeholder="Từ mức" className="input-first"/>
                                <Input

                                    placeholder="-"
                                    disabled
                                    className={"input-disable"}
                                />
                                <Input className="input-second" placeholder="Đến mức" />
                            </InputGroup>

                        </Form.Item>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={4}>
                        <Form.Item label="Lợi nhuận" className="a-form--ant-item  mb-0">
                            <InputGroup compact className="a-group--input">
                                <Input  placeholder="Từ -%" className="input-first"/>
                                <Input
                                    style={{
                                        width: 30,
                                        borderLeft: 0,
                                        pointerEvents: 'none',
                                        backgroundColor: '#fff',
                                    }}
                                    placeholder="-"
                                    disabled
                                />
                                <Input className="input-second" placeholder="Đến -%" />
                            </InputGroup>

                        </Form.Item>
                    </Col>
                    <Col xs={{span: 24}} lg={{span: 24}} xl={{span: 24}} className="pr-0 pl-0 mt-4 pt-5 border-top-dashed">
                    <div className="d-flex justify-content-between align-items-center">
                            <div className="a-list--action-search">
                                <Button className="a-text--uppercase a-btn--primary-ant ghost">Lọc các đơn có vấn đề  </Button>
                            </div>
                            <div className="a-list--action-search flex-row">
                                <a href="#" className="link-reset mr-5"><Icon type="sync"/>Làm mới bộ lọc</a>
                                <Button className="a-text--uppercase a-btn--padding-large a-btn--primary-ant">Tìm
                                    kiếm</Button>
                            </div>
                    </div>
                    </Col>
                </Form>
            </Row>
        )
    }
}

export default FormSearch;