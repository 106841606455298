import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as Constants from './../constants';
import {ASSIGN_PURCHASER_ORDERS,
    ASSIGN_PURCHASER,
    UNASSIGN_PURCHASER,
    DISTRIBUTE_STATUS
} from '../DistributeOrder/constants';
import {ORDER_STATUS} from './../../Order/constants'
import {CONFIRM_CUSTOMER_ORDER_BUYING} from './../../PurchaseBuying/OrderDetail/constants';

const updateOrderInOrders = (orders, orderIds, data) => {
    orderIds = lodash.isArray(orderIds) ? orderIds : [orderIds];

    return orders.map(order => orderIds.includes(order.id) ? {...order, ...data} : order);
};

const list = (state = [], action) => {
    let data = lodash.get(action, 'payload.data', {});
    let order = {};
    let orders = [];
    switch (action.type) {
        case Constants.FETCH_ORDERS.SUCCESS:
            orders = lodash.get(action, 'payload.data.orders', []);
            orders = orders.map(orderItem=> {
                return {
                    ...orderItem,
                    ...{link_favicons: 'https://www.google.com/s2/favicons?domain=' + lodash.get(orderItem, 'agency_domain', orderItem.id)}
                }
            });
            return [...orders];

        case ASSIGN_PURCHASER_ORDERS.INIT:
        case ASSIGN_PURCHASER_ORDERS.FAILED:
            return state.map(order => lodash.omit(order, ['distribute_status', 'distribute_error']));

        case ASSIGN_PURCHASER_ORDERS.BEGIN:
            return updateOrderInOrders(state, action.payload.orderIds, {
                distribute_status: DISTRIBUTE_STATUS.PENDING,
            });

        case ASSIGN_PURCHASER.REQUEST:
        case UNASSIGN_PURCHASER.REQUEST:
            return updateOrderInOrders(state, action.payload.orderId, {
                distribute_status: DISTRIBUTE_STATUS.LOADING,
                distribute_error: null,
            });

        case ASSIGN_PURCHASER.FAILED:
        case UNASSIGN_PURCHASER.FAILED:
            return updateOrderInOrders(state, action.request.orderId, {
                distribute_status: DISTRIBUTE_STATUS.FAILED,
                distribute_error: action.payload,
            });

        case ASSIGN_PURCHASER.SUCCESS: {
            let {orderId} = action.request;
            let {order, teller} = action.payload.data;
            return updateOrderInOrders(state, orderId, {
                distribute_status: DISTRIBUTE_STATUS.SUCCESS,
                status: order.status,
                id_teller: teller.id,
                teller_name: teller.name,
                teller_username: teller.username,
                time_teller: order.time_teller,
            });
        }

        case ASSIGN_PURCHASER_ORDERS.REQUEST: {
            return updateOrderInOrders(state, action.payload.orderIds, {
                distribute_status: DISTRIBUTE_STATUS.LOADING,
                distribute_error: null,
            });
        }

        case ASSIGN_PURCHASER_ORDERS.SUCCESS: {
            let {orders, teller} = action.payload.data;

            return state.map(order => {
                let res = lodash.find(orders, {order_id: order.id});

                if (!res) {
                    return order;
                }

                if (res.error) {
                    return {
                        ...order,
                        distribute_status: DISTRIBUTE_STATUS.FAILED,
                        distribute_error: action.payload,
                    };
                }

                return {
                    ...order,
                    distribute_status: DISTRIBUTE_STATUS.SUCCESS,
                    status: res.order.status,
                    id_teller: teller.id,
                    teller_name: teller.name,
                    teller_username: teller.username,
                    time_teller: res.order.time_teller,
                };
            });
        }

        case CONFIRM_CUSTOMER_ORDER_BUYING.SUCCESS:
        {
            data = action.payload.data;
            orders = state.map(orderItem=> {
                if (data.id === orderItem.id) {
                    return {
                        ...orderItem,
                        ...{note_buyer_confirm: order.note_buyer_confirm ? order.note_buyer_confirm : []}
                    }
                }
                return orderItem;
            });
            return orders;
        }

        case CONFIRM_CUSTOMER_ORDER_BUYING.WARNING:
            data = lodash.get(action, 'payload', {});
            orders = state.map(orderItem=> {
                if (orderItem.id === data.id) {
                    return {
                        ...orderItem,
                        ...{note_buyer_confirm: []}
                    }
                }
                return orderItem;
            });
            return orders;

        case UNASSIGN_PURCHASER.SUCCESS:
            data = lodash.get(action, 'payload.data.order', {});
            return updateOrderInOrders(state, action.request.orderId, {
                distribute_status: DISTRIBUTE_STATUS.SUCCESS,
                id_teller: 0,
                teller_name: null,
                status: lodash.get(data, 'status', ORDER_STATUS.WAITING_BUY),
                teller_username: null,
            });

        default:
            return state;
    }
};

const count = (state = {}, action) => {
    switch (action.type) {
        case Constants.FETCH_ORDER_COUNT.REQUEST:
            return {};

        case Constants.FETCH_ORDER_COUNT.SUCCESS:
            return lodash.get(action.payload, 'data.stats', {});

        default:
            return state;
    }
};

const stats = (state = {}, action) => {
    switch (action.type) {
        case Constants.FETCH_ORDER_STATS.SUCCESS:
            return lodash.get(action.payload, 'data.stats', {});
        default:
            return state;
    }
};

/**
 * Pagination list order reducer
 */
const pagination = (state = {}, action) => {
    let {payload} = action;

    switch (action.type) {
        case Constants.FETCH_ORDERS.SUCCESS:
            return lodash.get(payload, 'data.pagination', {from: 0, per_page: 20, total: 0});
        default:
            return state;
    }
};

/**
 * loading order reducer
 */
const loading = (state = false, action) => {
    switch (action.type) {
        case Constants.FETCH_ORDERS.REQUEST:
            return true;
        case Constants.FETCH_ORDERS.SUCCESS:
        case Constants.FETCH_ORDERS.FAILED:
            return false;
        default:
            return state;
    }
};

const partner = (state = {}, action) => {
    switch (action.type) {
        case Constants.FETCH_ORDERS.SUCCESS:
            return lodash.get(action.payload, 'data.partner', {});
        case Constants.FETCH_ORDERS.FAILED:
            return {};
        default:
            return state;
    }
};

export default combineReducers({
    list,
    count,
    stats,
    loading,
    pagination,
    partner
});
