import React from 'react';
import FormSearch from './FormSearch';
import ListFileUpload from './ListTransactionOrder';
import {Layout} from 'antd';
import {url} from "../../../../systems/routing/index";
import {translate} from 'react-i18next';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import TRANSACTION_EVENT from "../../events";
import {withEvent} from "../../../../systems/events";
import moment from 'moment'

const {Content} = Layout;

const currentDay = moment().format("YYYY-MM-DD")
const monthAgo = moment().subtract(1, 'M').add(1, 'd').format('YYYY-MM-DD')
class ListTransactionOrder extends React.Component {
    constructor(props) {
        super(props);
        this.defaultFilter = {
            is_problem: "",
            code: "",
            id_purchaser: "",
            status: "",
            profit_from: "",
            profit_to: "",
            profit_percent_from: "",
            profit_percent_to: "",
            bought_at_from: '',
            bought_at_to: '',
            deposited_at_from: monthAgo,
            deposited_at_to: currentDay,
        };
        this.state = {
            csvFilter: {...this.defaultFilter},
            errors: {},
        };
    }
    componentDidMount() {
        let {t} = this.props;

        this.props.setPageTitle(t('transaction:label.transaction_order'));
        this.props.setPageDescription(t('transaction:label.transaction_order_desc'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.transaction-order',
                'link': url.to('transaction.files.order')
            },
        ]);
        this.props.setMenuActive("transaction.files.order");
        this.props.on({
            [TRANSACTION_EVENT.EXPORT_FAILED]: (event, payload) => this.getErrors(payload),
            [TRANSACTION_EVENT.RESET_ERROR]: (event, payload) => this.resetError(),
        });

        if (this.props.complaintSellerReasons.length === 0)
            this.props.fetchComplaintSellerReasons();
    }

    getErrors(errors) {
        let {t} = this.props;
        lodash.mapValues(errors.data, (rules, param) => {
            rules = lodash.mapKeys(rules, function(value, key) {
                key = key === 'GREATER' ? 'greater_than_1_month' : key;
                return lodash.toLower(key);
            });
            let attribute = t('order:label.' + param);
            let messages = lodash.mapValues(rules, (data, rule) => {
                let messageKey = 'order:error.' + rule;
                return t(messageKey, {...data, attribute});
            });
            this.setState({
                ...this.state,
                errors: {
                    [param]: lodash.first(lodash.values(messages))
                }
            })
        });
    };

    resetError() {
        this.setState({
            ...this.state,
            errors: {}
        })
    }
    setCSVFilter(filter) {
        this.setState({
            ...this.state,
            csvFilter: {
                ...this.state.csvFilter,
                ...filter
            }
        })
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    render() {
        return (
            <Layout>
                <Content className="a-content--ant-layout ">
                    <FormSearch
                        listStatus={this.props.listStatus}
                        external_errors={this.state.errors}
                        filters={this.props.filters}
                        input={this.props.filter}
                        inputVersion={lodash.toInteger(this.props.filter._i)}
                        loading={this.props.loading}
                        onSearch={this.props.onSearch}
                        setCSVFilter={this.setCSVFilter.bind(this)}
                        problemTypes={this.props.problemTypes}
                        listScanningStatuses={this.props.listScanningStatuses}
                    />
                    <ListFileUpload
                        csvFilter={this.state.csvFilter}
                        filters={this.props.filters}
                        filter={this.props.filter}
                        orders={this.props.orders}
                        loading={this.props.loading}
                        pagination={this.props.pagination}
                        onSearch={this.props.onSearch}
                        downloadFile={this.props.downloadFile}
                        complaintSellerReasons={this.props.complaintSellerReasons}
                    />
                </Content>
            </Layout>
        )
    }
}

ListTransactionOrder.defaultProps = {
    orders: [],
    pagination: {},
    filter: {},
    loading: false,
    onSearch: (filter) => {},
    complaintSellerReasons: [],
    fetchComplaintSellerReasons: () => {}
};

ListTransactionOrder.propTypes = {
    orders: PropTypes.array,
    pagination: PropTypes.object,
    filter: PropTypes.object,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
    clearState: PropTypes.func,
    downloadFile: PropTypes.func,
    complaintSellerReasons: PropTypes.array,
    fetchComplaintSellerReasons: PropTypes.func
};

export default translate('translations')(withEvent(ListTransactionOrder));
