import React from 'react';
import BreadCrumbContainer from '../../Breadcrumb/BreadcurmbContainer';
import PropTypes from 'prop-types';
import lodash from "lodash";
import UserInfo from "../../UserInfo";

class Header extends React.PureComponent {
    render() {
        let {dataOrder} = this.props;
        let agencyName = dataOrder.agency.name;
        let titleStyle = {
            color: lodash.get(dataOrder, "agency.color", "#000")
        };
        return (
            <div
                className={this.props.menu_active === "purchase-address-detail" && typeof this.props.dataOrder.id !== "undefined" ? "pageHeader purchase_buying" : "pageHeader"}>
                <div className="pageHeader-title pageHeader--breadcrumbs ">
                    <h2 style={titleStyle}>{agencyName ? agencyName : this.props.title}</h2>
                    <span>{this.props.subDesc}</span>
                </div>
                <BreadCrumbContainer/>
                <div className="ml-auto p-2">
                    <UserInfo/>
                </div>
            </div>
        );
    }
}

Header.defaultProps = {
    subDesc: "",
    title: "",
};

Header.propTypes = {
    title: PropTypes.string.isRequired,
    subDesc: PropTypes.string.isRequired,
};

export default Header;
