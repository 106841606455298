import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {translate} from 'react-i18next';
import {DEFAULT_NUMBER_COMMENT} from "./../../constants";
import AutoLinkText from 'react-autolink-text2';
import {Tooltip} from "antd";

class OrderItemComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    /**
     * convert number 2 datetime
     * @param inputtext
     * @returns {string}
     */
    convertDatetime(inputtext) {
        inputtext = inputtext * 1;
        if ((inputtext >= 100000000000000) || (inputtext <= -100000000000000)) {
            inputtext = Math.round(inputtext / 1000);
        } else {
            inputtext = (inputtext * 1000);
        }
        let datum = new Date(inputtext);
        return datum.toUTCString();
    }

    /**
     * handle action click expand/ collapse
     */
    expandComemnt() {
        let {isOpen} = this.state;
        this.setState({isOpen: !isOpen});
    }

    render() {
        let {t, item} = this.props;
        let {isOpen} = this.state;
        let comment = item.note !== null ? [...item.note] : [];
        if (comment.length > DEFAULT_NUMBER_COMMENT && !isOpen) {
            comment = comment.slice(0, DEFAULT_NUMBER_COMMENT);
        }

        return (
            <React.Fragment>
                <div className={comment.length > 0 ? "block__comment" : "hidden"}>
                    {comment.map((comment, keyComment) => (
                        <div className={"block__comment_wapper"} key={keyComment}>
                            <div>
                                <Tooltip title={comment.username}><span className={(comment.type !== "user" ? "a-text--red" : "" ) + " block__comment_title"}>{comment.name}</span></Tooltip>
                                <span className="block__comment_time ml-2">
                                    <Moment format="HH:mm DD/MM/YYYY">{comment.time}</Moment>
                                </span>
                            </div>
                            <div
                                className={"block__comment_contend"}>
                                <AutoLinkText
                                    text={comment.content}
                                    linkProps={{target: '_blank', rel: 'nofollow'}}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                {item.note !== null && item.note.length > 3 ?
                    <div className={"block__extend_comment"} onClick={this.expandComemnt.bind(this)}>
                        {isOpen ? t("order_detail.collapse_commend") : t("order_detail.expand_commend", {count: item.note.length - DEFAULT_NUMBER_COMMENT})}
                    </div>
                    : null
                }
            </React.Fragment>
        );
    }
}

OrderItemComment.defaultProps = {
    items: {},
};

OrderItemComment.propTypes = {
    item: PropTypes.object
};

export default translate('translations')(OrderItemComment);
