import React from 'react';
import lodash from 'lodash';
import {translate} from 'react-i18next';
import {trans} from '../../../../systems/i18n';
import FormFilter from './FormFilter';
import {Spin, Icon} from 'antd';
import Log from './Log';
import FormComment from '../FormCommentContainer';
import LogMessage from "./LogMessage";
import moment from "moment";

class OrderChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Xử lý hành động thay đổi các filter
     *
     * @param {Object} filter
     */
    onChangeFilter(filter) {
        this.props.onChangeFilter(filter);
        // Nếu thay đổi type mà load logs đang bị lỗi thì load lại
        if (filter.type && this.props.logsError) {
            this.props.fetchLogs();
        }
    }

    /**
     * xử lý việc load lại logs khi có lỗi xảy ra
     *
     * @param {Object} event
     */
    onReloadLogs(event) {
        event.preventDefault();
        this.props.fetchLogs();
    }

    onReloadComments(event) {
        event.preventDefault();
        this.props.fetchComments();
    }

    /**
     * Xử lý khi thực hiện đóng mở collapsed
     */
    onToogleCollapsedPublic() {
        let {collapsedPublic} = this.state;
        this.setState({collapsedPublic: !collapsedPublic});
        return collapsedPublic;
    }

    onToogleCollapsedPrivate() {
        let {collapsedPrivate} = this.state;
        this.setState({collapsedPrivate: !collapsedPrivate});
        return collapsedPrivate;
    }

    render() {
        let {logs, dataOrder, filter} = this.props;
        let {collapsedPublic, collapsedPrivate} = this.state;
        let logPublic = logs.filter(log => log.is_public === 1 || log.is_public === 2);
        let logPrivate = logs.filter(log => log.is_public === 0 || log.is_public === 2);
        return <React.Fragment>
            <div className="a-chat">
                <div className="a-chat__top">
                    <FormFilter
                        filter={this.props.filter}
                        onChange={this.onChangeFilter.bind(this)}
                        commentInput={ this.props.commentInput.is_public === true? this.props.commentInput: {}}
                        inputVersion={this.props.commentVersion}
                        error={this.props.commentError}
                        loading={this.props.commentLoading}
                        disabled={lodash.get(this.props, 'filter.type', 'LOG') === 'LOG'}
                        onComment={this.props.onComment}
                        collapsed={collapsedPublic}
                        onToogleCollapsedPublic={this.onToogleCollapsedPublic.bind(this)}
                    />
                    <FormComment
                        object={this.props.object}
                        objectId={this.props.objectId}
                        canComment={this.props.canComment}
                        isPublic={true}
                    />
                </div>
                <div className="text-right ml-2 mr-2">
                    {
                        (!this.props.commentsError && !this.props.commentsLoading) &&
                        <a onClick={this.onReloadComments.bind(this)} className="a--cursor-poiter a-text--blue a-text--fz-11"><Icon type="reload" className="mr-1"/>Tải lại</a>
                    }
                </div>
                <div className={"a-chat__body public"+(collapsedPublic? "collapsed":"")}>
                    {
                        this.renderResult(logPublic)
                    }

                    {
                        dataOrder.note && filter.type !== "LOG" &&
                        <div className="a-chat__block">
                            <div className='a-chat__info--content'>
                                <div className='a-chat__info'>
                                    <p className='a-chat__info--author customer'>
                                        <img className={'a-chat__info--customer-icon'} src={require('../../../../resources/img/crown.png')} alt=""/>
                                        <strong className="a-text--red">KH.{dataOrder.buyer_name}</strong><span>, {moment(dataOrder.created_at).format('h:mm DD/MM/YYYY')}</span>
                                    </p>
                                </div>

                                <div className="a-chat__comment customer">
                                    <p><div>{dataOrder.note}</div></p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className={'a-chat'}>
                <div className={'a-chat__top'}>
                    <FormComment
                        object={this.props.object}
                        objectId={this.props.objectId}
                        canComment={this.props.canComment}
                        isPublic={false}
                    />
                </div>
                <div className="text-right ml-2 mr-2">
                    {
                        (!this.props.commentsError && !this.props.commentsLoading) &&
                        <a onClick={this.onReloadComments.bind(this)} className="a--cursor-poiter a-text--blue a-text--fz-11"><Icon type="reload" className="mr-1"/>Tải lại</a>
                    }
                </div>
                <div className={"a-chat__body private "+(collapsedPrivate? "collapsed":"")}>
                    {this.renderResult(logPrivate)}
                </div>
            </div>
        </React.Fragment>
    }

    renderResult(logs) {
        let {t} = this.props;

        return (
            <React.Fragment>
                {
                    (this.props.logsError && !this.props.logsLoading) &&
                    <div className="alert alert-warning">
                        <i className="fa fa-exclamation-triangle mr-1"/>
                        {t('log:error.cant_load')}
                        <a href=""
                           onClick={this.onReloadLogs.bind(this)}
                           className="activity-log-reload ml-1">{t('log:btn.reload')}</a>
                    </div>
                }
                {
                    (this.props.commentsError && !this.props.commentsLoading) &&
                    <div className="alert alert-warning">
                        <i className="fa fa-exclamation-triangle mr-1"/>
                        {t('log:error.cant_load_comments')}
                        <a href=""
                           onClick={this.onReloadComments.bind(this)}
                           className="activity-log-reload ml-1">{t('log:btn.reload')}</a>
                    </div>
                }
                {!logs.length && (this.props.logsLoading || this.props.commentsLoading) && (
                    <div className="text-center"><Icon type="loading" style={{fontSize: '1rem'}}/></div>
                )}

                {logs.map(log => {
                    if (this.props.filter.filter_public === null) {
                        return <Log
                            key={log.id}
                            log={log}
                            filter={this.props.filter}
                            onCollapse={() => this.props.onCollapseLog(log.id, !log.collapse)}
                        />
                    }
                    if (this.props.filter.filter_public === true && log.is_public) {
                        return <Log
                            key={log.id}
                            log={log}
                            filter={this.props.filter}
                            onCollapse={() => this.props.onCollapseLog(log.id, !log.collapse)}
                        />
                    }
                    if (this.props.filter.filter_public === false && log.is_public === false) {
                        return <Log
                            key={log.id}
                            log={log}
                            filter={this.props.filter}
                            onCollapse={() => this.props.onCollapseLog(log.id, !log.collapse)}
                        />
                    }

                    return null;
                })}
            </React.Fragment>
        );
    }
}

export default translate()(OrderChat);
