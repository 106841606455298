import {createSelector} from "reselect";
import lodash from "lodash";
import {currentFormatter} from "../../../modules/common/services/format";
import moment from "moment";
import React from "react";

const complaintSelector = complaints => lodash.get(complaints, "data.complaintSellers", []);
export function formartComplaint(complaint) {
    let receiver_name = lodash.get(complaint, "receiver.username", "---"),
        reason_title = lodash.get(complaint, "reason.title", "--"),
        currencySymbol = lodash.get(complaint, "order.currencySymbol", '¥'),
        amount = complaint.amount !== '' ? currentFormatter.toLocaleStringCurrency(parseFloat(complaint.amount, 5), 5) : '---',
        amount_earned = complaint.amount_earned !== '' ? currentFormatter.toLocaleStringCurrency(parseFloat(complaint.amount_earned, 10), 5) : '---';
    let origin_link = "";
    if (complaint.origin_code) {
        switch (complaint.order.seller_source) {
            case "taobao":
                origin_link = "https://refund2.taobao.com/dispute/detail.htm?disputeId=" + complaint.origin_code;
                break;
            case "tmall":
                origin_link = "https://refund2.tmall.com/dispute/detail.htm?type=1&disputeId=" + complaint.origin_code;
                break;
            case "1688":
                origin_link = "https://dispute.1688.com/refund/assureRefundDetail.htm?refundId=" + complaint.origin_code;
                break;
        }
    }

    return {
        ...complaint,
        amount,
        receiver_name,
        reason_title,
        origin_link,
        currencySymbol,
        amount_earned
    };
}
export const getComplaints = createSelector(
    complaintSelector,
    complaints => complaints.map(complaint => formartComplaint(complaint))
);
