import translations from './vn.json';
import validation from './validation.json';
import log from './logs.json';
import order from './order.json';
import orderDetail from './orderDetail.json';
import purchaser from './purchaser.json';
import violate from './violate.json';
import complaintSeller from './complaintSeller.json';
import setting from './setting.json';
import user from './user.json';
import sale from './sale.json';
import productReturn from './productReturn.json';
import service from './service.json';
import newLocals from './new.json';
import transaction from './transaction.json';
import paymentRequest from './paymentRequest';
import changeInvoiceRequest from './changeInvoiceRequest';
import group from './group';
import promotion from './promotion';

export default {
    productReturn,
    complaintSeller,
    sale,
    translations,
    setting,
    validation,
    log,
    orderDetail,
    order,
    purchaser,
    violate,
    user,
    transaction,
    service,
    newLocals,
    paymentRequest,
    changeInvoiceRequest,
    group,
    promotion
}
