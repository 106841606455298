import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import Spinner from '@atlaskit/spinner';

class Paid extends React.Component {
    onClickPaid(event) {
        let {id, isLoading} = this.props;
        if (!isLoading) {
            this.props.onPaid({id});
        }
    }

    render() {
        let {t, canChangePaid, isLoading} = this.props;
        return (
            <React.Fragment>
                <button
                    type="submit"
                    className={`a-btn a-btn--primary a-btn--height-35 a-btn--uppercase
                    ${canChangePaid ? '' : ' a-btn--disable-action '}
                    ${isLoading ? ' a-btn--disable-action ':''} `}
                    onClick={this.onClickPaid.bind(this)}
                >
                    <span className={isLoading ? "pt-1" : "hidden"}>
                        <Spinner invertColor  size="small" isCompleting={false}/>&nbsp;&nbsp;
                    </span> {t('order_detail.has_paid')}
                </button>
            </React.Fragment>
        );
    }
}

Paid.defaultProps = {
    canChangePaid: false,
    isLoading: false,
    onPaid: () => {
    },
    id: 0,
};

Paid.propTypes = {
    canChangePaid: PropTypes.bool,
    isLoading: PropTypes.bool,
    onPaid: PropTypes.func,
    id: PropTypes.number,
};

export default translate('translations')(Paid);
