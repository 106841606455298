import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import moment from "moment";

const parseStateToValue = state => moment(state, 'YYYY-MM-DD');
const formatValueToState = value => value.format('YYYY-MM-DD');

class DatetimePicker extends PureComponent {

    handleChange(name, date) {
        this.props.onSubmit({[name]: date ? formatValueToState(date) : ''});
    }

    render() {
        let {placeholderText, name, date, className, isFutureOnly, isClearable, disabled} = this.props;
        return (
            <React.Fragment>
                <DatePicker
                    className={`input input--datetime ${ className}`}
                    placeholderText={placeholderText}
                    selected={!isNaN(Date.parse(date)) && date ? parseStateToValue(date) : null}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.handleChange.bind(this, name)}
                    isClearable={isClearable}
                    disabledKeyboardNavigation={true}
                    disabled={disabled}
                    minDate={isFutureOnly ? moment() : null}
                />
            </React.Fragment>
        );
    }
}

DatetimePicker.defaultProps = {
    isFutureOnly: false,
    disabled: false,
    name: '',
    onSubmit: input => {
    },
    isClearable: true,
};

DatetimePicker.propTypes = {
    isFutureOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    isClearable: PropTypes.bool,
};


export default DatetimePicker;
