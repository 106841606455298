import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Select} from 'antd';

const {Option} = Select;

class SelectScanningStatus extends React.PureComponent {
    render() {
        let {t, listScanningStatus, loading} = this.props;

        return (
            <Select
                value={!loading ? this.props.value : undefined}
                mode={this.props.multiple ? 'multiple' : 'default'}
                placeholder={this.props.placeholder}
                isLoading={loading}
                onChange={this.props.onChange}
            >
                {this.props.allOption && <Option value="">{t('label.all')}</Option>}
                {listScanningStatus.map(status => <Option key={status} value={status}>{t('order:scanning_status.' + status)}</Option>)}
            </Select>
        );
    }
}

SelectScanningStatus.defaultProps = {
    value: null,
    allOption: false,
    multiple: false,
    filter: value => true,
    onChange: value => {},
    listScanningStatus: [],
};

SelectScanningStatus.propTypes = {
    value: PropTypes.any,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    filter: PropTypes.func,
    onChange: PropTypes.func,
    listScanningStatus: PropTypes.array,
};

export default translate()(SelectScanningStatus);
