import React from "react";
import {Link} from "../../../systems/routing";
import OrderStatus from "../../../modules/Order/components/OrderStatus";
import lodash from "lodash";
import {Affix, Col, Table} from "antd";
import {translate} from "react-i18next";
import SettingColumns from "../../../modules/Settings/SettingColumns";
import PaginationFull from '../../../modules/common/components/PaginationFull';
import ExportCSV from "../components/ExportCSV";
import bootbox from "../../../modules/common/components/Bootbox/bootbox";
import {CopyToClipboard} from "react-copy-to-clipboard";
import SortByColumn from "./SortByColumn";
import SiteIcon from "../../../modules/Image/SiteIcon";

const CURRENCY_COLUMN = {
    VIETNAM: 'vietnam_dong',
    CHINA: 'china_yuan'
}

class ListSale extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedColumns: []
        };
    }

    getColumns() {
        let {t} = this.props;
        let {selectedColumns} = this.state;
        let isDong = selectedColumns.includes(CURRENCY_COLUMN.VIETNAM);
        let isYuan = selectedColumns.includes(CURRENCY_COLUMN.CHINA);

        let columns = [
            {
                key: 'id',
                className: "a-text--fz-12",
                render: (text, order) => {
                    return (
                        <div className="d-flex">
                            <Link isnewtab={'true'} to="purchase.buying.detail" params={{id: order.id}}>
                                <img style={{height: "45px", width: "45px"}} src={order.order_item_image || require('../../../resources/img/default-thumbnail.jpg')}
                                     onError={(e) => {
                                         e.target.onerror = null;
                                         e.target.src = require('../../../resources/img/default-thumbnail.jpg')
                                     }}
                                     alt="Avatar"/>
                            </Link>
                            <div>
                                <div className="d-flex align-item-center">
                                    <Link
                                        href={'/purchase-buying/' + order.id}
                                        className="a-text--nowrap ml-2 mr-1"
                                    >{order.code}</Link>
                                    <CopyToClipboard
                                        text={order.code}
                                        onCopy={() => { bootbox.success(t("message.copied") + ": " + order.code) }}>
                                        <i className="far fa-copy copy a-text--pointer a-text--blue a-text--color-black-45"/>
                                    </CopyToClipboard>
                                </div>
                                <div className="ml-2">
                                    <SiteIcon
                                        width="14px"
                                        src={order.seller_source}
                                        alt="avatar_seller"
                                    />
                                    <span style={{textTransform: "capitalize"}} className="ml-1 a-text--fz-12">{order.seller_source}</span>
                                </div>
                            </div>
                        </div>
                    );
                }
            },
            {
                key: 'id_agency',
                className: "a-text--nowrap a-text--fz-12",
                render: (text, order) => {
                    let agency = lodash.get(order, "agency", {});
                    return !lodash.isEmpty(agency) ? agency.name : '--';
                }
            },
            {
                key: 'bought_at',
                className: "a-text--nowrap a-text--fz-12"
            },
            {
                key: 'id_teller',
                className: 'a-text--fz-12',
                render: (text, order) => {
                    let teller_name = lodash.get(order, "teller.name", "--");
                    return <p className="text-nowrap">{teller_name}</p>;
                }
            },
            {
                key: 'rate_exchange',
                className: "a-text--right a-text--nowrap a-text--fz-12",
            },
            {
                key: 'grand_total_bought', // Tiền hàng
                className: "a-text--right a-text--fz-12",
                render: (text, order) => {
                    return (
                        <>
                            { isDong && <p className="text-nowrap">{order.grand_total_bought_converted}</p> }
                            { isYuan && <p className="text-nowrap">{text}</p> }
                        </>
                    );
                }
            },
            {
                key: 'total_shipping_fee_inland', // Vận chuyển nội địa
                className: "a-text--right a-text--nowrap a-text--fz-12",
                render: (text, order) => {
                    return (
                        <>
                            { isDong && <p className="text-nowrap">{order.total_shipping_fee_inland_converted}</p> }
                            { isYuan && <p className="text-nowrap">{text}</p> }
                        </>
                    );
                }
            },
            {
                key: 'grand_total', // Tổng thu khách
                className: "a-text--right a-text--fz-12",
                render: (text, order) => {
                    return (
                        <>
                            { isDong && <p className="text-nowrap">{order.grand_total_converted}</p> }
                            { isYuan && <p className="text-nowrap">{text}</p> }
                        </>
                    );
                }
            },
            {
                key: 'spend_amount', // Tổng chi alipay
                className: "a-text--right a-text--fz-12",
                render: (text, order) => {
                    return (
                        <>
                            { isDong && <p className="text-nowrap">{order.spend_amount_converted}</p> }
                            { isYuan && <p className="text-nowrap">{text}</p> }
                        </>
                    );
                }
            },
            {
                key: 'collect_amount', // Tổng hoàn alipay
                className: "a-text--right a-text--fz-12",
                render: (text, order) => {
                    return (
                        <>
                            { isDong && <p className="text-nowrap">{order.collect_amount_converted}</p> }
                            { isYuan && <p className="text-nowrap">{text}</p> }
                        </>
                    );
                }
            },
            {
                key: 'real_payment_amount', // Thanh toán thực (user điền)
                className: "a-text--right a-text--fz-12",
                render: (text, order) => {
                    return (
                        <>
                            { isDong && <p className="text-nowrap">{order.real_payment_amount_converted}</p> }
                            { isYuan && <p className="text-nowrap">{text}</p> }
                        </>
                    );
                }
            },
            {
                key: 'real_refund_amount', // Hoàn thực (user điền)
                className: "a-text--right a-text--fz-12",
                render: (text, order) => {
                    return (
                        <>
                            { isDong && <p className="text-nowrap">{order.real_refund_amount_converted}</p> }
                            { isYuan && <p className="text-nowrap">{text}</p> }
                        </>
                    );
                }
            },
            {
                key: 'order_profit', // Doanh thu mặc cả
                className: "a-text--right a-text--nowrap a-text--fz-12",
                render: (text, order) => {
                    return (
                        <>
                            { isDong && <p className="text-nowrap">{order.order_profit_converted}</p> }
                            { isYuan && <p className="text-nowrap">{text}</p> }
                        </>
                    );
                }
            },
            {
                key: 'status',
                className: 'a-text--fz-12',
                render: (text, order) => {
                    return (
                        <OrderStatus status={order.status} />
                    );
                }
            }
        ];

        return columns.map(column => ({
            dataIndex: column.key,
            title: !['bought_at', 'grand_total', 'grand_total_bought', 'total_shipping_fee_inland', 'spend_amount', 'collect_amount', 'real_payment_amount', 'real_payment_amount', 'real_refund_amount', 'order_profit'].includes(column.key) ?
                t('order:sale_report.column.' + column.key) :
                <SortByColumn
                    column={column.key}
                    filter={this.props.filter}
                    onSearch={this.props.onSearch}
                />,
            ...column
        }));
    }

    onChangePage(page, pageSize) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            });
        }
    }

    getSettingColumns() {
        let {t} = this.props;
        let origin_columns = this.getColumns()
            .map(column => ({
                key: column.key,
                title: t('order:sale_report.column.' + column.key),
                selected: true,
            }));
        let currency_column = [
            {
                key: CURRENCY_COLUMN.VIETNAM,
                title: t('order:sale_report.column.' + CURRENCY_COLUMN.VIETNAM),
                selected: true
            },
            {
                key: CURRENCY_COLUMN.CHINA,
                title: t('order:sale_report.column.' + CURRENCY_COLUMN.CHINA),
                selected: true
            }
        ];
        return origin_columns.concat(currency_column);
    }

    getTableColumns() {
        return this.getColumns().filter(column => {
            return this.state.selectedColumns.includes(column.key);
        });
    }

    render() {
        let {
            loading,
            orders,
            pagination,
            t
        } = this.props;

        return (
            <div className="a-list mt-5">
                <Affix>
                    <div className="a-list__top a-flex">
                        <h2 className="a-list--title a-text--uppercase">Danh sách doanh số ({!lodash.isEmpty(pagination) ? pagination.total : 0})</h2>
                        <div className="ml-auto">
                            <ExportCSV
                                filter={this.props.filter}
                                csvFilter={this.props.csvFilter}
                                loading={this.props.loading}
                                t={t}
                            />
                            <SettingColumns
                                storageKey="sale.report.columns"
                                columns={this.getSettingColumns()}
                                onChange={selectedColumns => this.setState({selectedColumns})}
                            />
                        </div>
                    </div>
                </Affix>
                <div className="a-content--is-pagination-fixed" style={{padding: "16px 24px 0 24px"}}>
                    <Table
                        className="a-table--antd a-table--description a-text--color-black-65"
                        columns={this.getTableColumns()}
                        dataSource={orders}
                        loading={loading}
                        scroll={{x: 'fit-content'}}
                        pagination={false}
                    />
                    <PaginationFull
                        page={lodash.toInteger(pagination.current_page) || 1}
                        pageSize={lodash.toInteger(pagination.per_page)}
                        total={pagination.total}
                        pageSizeOptions={[20, 30, 50, 80, 100]}
                        onChange={this.onChangePage.bind(this)}
                        className="a-pagination a-pagination--fixed"
                    />
                </div>
            </div>
        );
    }
}

export default translate()(ListSale);
