import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Button} from 'antd';
import {withEvent} from '../../../systems/events';
import INVOICE_EVENT from '../events';
import SourceInvoiceCrawler from '../services/SourceInvoiceCrawler';

class SyncListSourceInvoice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.crawler = new SourceInvoiceCrawler();
    }

    componentDidMount() {
        this.crawler.boot();
        this.props.on([
            INVOICE_EVENT.CRAWL_START,
            INVOICE_EVENT.CRAWL_FINISH,
            INVOICE_EVENT.CRAWL_CANCEL,
        ], this.invoiceEventListener.bind(this));
    }

    componentWillUnmount() {
        this.crawler.terminate();
    }

    crawl() {
        if (!this.state.loading) {
            this.setState({loading: true});
            this.crawler.crawl(this.props.orders);
        }
    };

    cancel() {
        if (!this.state.loading) {
            return;
        }

        this.setState({loading: false});
        this.crawler.cancel();
    };

    invoiceEventListener(event, payload) {
        if (payload.request !== this.crawler.request) {
            return;
        }

        switch (event) {
            case INVOICE_EVENT.CRAWL_START: {
                return this.setState({loading: true});
            }

            case INVOICE_EVENT.CRAWL_FINISH: {
                this.setState({loading: false});
                this.crawlNextOrders();
            }

            case INVOICE_EVENT.CRAWL_CANCEL: {
                this.setState({loading: false});
            }
        }
    }

    crawlNextOrders() {
        let nextOrders = this.props.getNextOrders();

        if (nextOrders) {
            nextOrders.then(orders => this.crawler.crawl(orders));
        }
    }

    render() {
        let {t} = this.props;

        return (
            <React.Fragment>
                <Button
                    className="a-font--12 aphz-24 a-btn--primary-ant mr-4"
                    size={"small"}
                    icon="sync"
                    onClick={this.crawl.bind(this)}
                    disabled={this.props.disabled || this.state.loading || !this.props.orders.length}
                    loading={this.state.loading}
                >{t('order:scan.btn.sync')}</Button>
                <Button
                    className=" a-font--12 a-btn--primary-ant a-btn--link a-text--red"
                    size={"small"}
                    onClick={this.cancel.bind(this)}
                    disabled={this.props.disabled || !this.state.loading}
                >{t('btn.cancel')}</Button>
            </React.Fragment>
        );
    }
}

SyncListSourceInvoice.defaultProps = {
    orders: [],
    disabled: false,
    getNextOrders: () => {},
};

SyncListSourceInvoice.propTypes = {
    orders: PropTypes.array,
    disabled: PropTypes.bool,
    getNextOrders: PropTypes.func,
};

export default translate()(withEvent(SyncListSourceInvoice));