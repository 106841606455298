import {apiWithToken} from '../../../systems/api/index';
import {API_URL} from './../../../systems/globalContant';

export default {
    getOrderGroup: (filters = {}) => apiWithToken.get(API_URL.DEFAULT + '/order-groups',
        {params: filters},
        {loading: false}),
    createOrderGroup: (params = {}) => apiWithToken.post(API_URL.DEFAULT + '/order-groups', params),
    fetchDetailOrderGroup: (filters = {}) => apiWithToken.get(API_URL.DEFAULT + '/order-groups/' + filters.id,
        {filters}, {loading: false}
    ),
    updateOrderGroup: (params = {}) => apiWithToken.put(API_URL.DEFAULT + '/order-groups/' + params.id, params),
    updateStatusOrderGroup: (params = {}) => apiWithToken.put(API_URL.DEFAULT + `/order-groups/${params.id}/status`, params),
}
