import React from "react";
import PusherComponent from "../../modules/common/components/Pusher";
import * as GlobalConstant from "../../systems/globalContant";
import qs from "qs";
import notification from '../../systems/notification';
import {translate} from "react-i18next";
import {trans} from "../../systems/i18n";
import lodash from 'lodash';
import {events} from "../../systems/events";
import PAYMENT_REQUEST_EVENT from "../../modules/PaymentRequest/events";

class SubscribePaymentRequest extends PusherComponent {
    _pusherOnMessage(text, id, channel) {
        if (channel.startsWith(GlobalConstant.CHANNELS.PAYMENT_REQUEST_CREATE)) {
            let response = qs.parse(text, {plaintObject: true});
            this.props.getNewPaymentRequest({...response});
        }
        if (channel.startsWith(GlobalConstant.CHANNELS.ORDER_PAY_ERROR_BY_PAYMENT_REQUEST)) {
            let response = qs.parse(text, {plaintObject: true});
            let reasons = lodash.omit(response.errors, 'order_code');
            let reasonKeys = lodash.keys(reasons);
            let reasonText = '';
            reasonKeys.map((key, index) => {
                index === reasonKeys.length - 1
                    ? reasonText += trans(`paymentRequest:reason.${key}`)
                    : reasonText += trans(`paymentRequest:reason.${key}`) + ', '
            });

            notification.error(trans('paymentRequest:message.not_auto_change_state', {
                order: <a href={`/purchase-buying/${lodash.get(response, 'errors.order_code', '')}`}
                          className="a-text--bold"
                          target={"_blank"}>{lodash.get(response, 'errors.order_code', '')}</a>,
                reason: <span className="a-text--bold">{reasonText}</span>
            }));
        }

        if (channel.startsWith(GlobalConstant.CHANNELS.TAOBAO_BATCH_PAY_PURCHASE_ORDER_ERROR)) {
            let response = qs.parse(text, {plaintObject: true});
            const purchaseOrderIdList = lodash.get(response, "purchaseOrderIdList", []);
            if (purchaseOrderIdList.length === 0) {
                purchaseOrderIdList.push(lodash.get(response, "order_code", ""));
            }
            events.dispatch(PAYMENT_REQUEST_EVENT.FAILED_FROM_SOURCE_SITE, {paymentIds: purchaseOrderIdList});
        }

        if (channel.startsWith(GlobalConstant.CHANNELS.TAOBAO_BATCH_PAY_PURCHASE_ORDER_SUCCESS)) {
            let response = qs.parse(text, {plaintObject: true});
            events.dispatch(PAYMENT_REQUEST_EVENT.SUCCESS_FROM_SOURCE_SITE, response);
        }
    }


    render() {
        return null;
    }
}

export default translate()(SubscribePaymentRequest);
