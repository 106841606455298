import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Button, Icon, Divider} from 'antd';
import {translate} from "react-i18next";
import lodash from "lodash";
import authService from "../../../../../modules/Auth/authService";
import * as PERMISSION from "../../../../../modules/Permission/permissions";
import DropdownSelectCheckbox from "../../../../../modules/common/components/DropdownSelectCheckbox";
import {getState} from "../../../../../systems/store";
import {TIME_OUT_LOADING_REQUEST_ORDER} from "../../../../../systems/globalContant/actionContant";
import CreatePaymentRequestBySBOrder from "../../../../../modules/PaymentRequest/CreatePaymentRequestBySBOrder";

const ButtonGroup = Button.Group;

class PerformAction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isCheckedAll: false,
            loadingRequestOrder: false,
        }
    }

    componentDidMount() {
        this.getLoadingRequestOrder();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {getLoadingRequestOrder} = this.props;
        if (prevProps.getLoadingRequestOrder !== getLoadingRequestOrder) {
            let distributorOwnerTime = localStorage.getItem("distributorOwner." + authService.user("id"));
            const lastTimeRequestOrder = lodash.isEmpty(distributorOwnerTime) ? Date.now() : distributorOwnerTime;
            this.setState({
                ...this.state,
                loadingRequestOrder: !((Date.now() - lastTimeRequestOrder) >= TIME_OUT_LOADING_REQUEST_ORDER)
            })
        }
    }

    getLoadingRequestOrder() {
        let distributorOwnerTime = localStorage.getItem("distributorOwner." + authService.user("id"));
        const lastTimeRequestOrder = lodash.isEmpty(distributorOwnerTime) ? 0 : distributorOwnerTime;
        this.setState({
            ...this.state,
            loadingRequestOrder: !((Date.now() - lastTimeRequestOrder) >= TIME_OUT_LOADING_REQUEST_ORDER)
        })
    }

    handleReset = () => {
        this.props.onSearch({reset: true});
    }

    handleChange = e => {
        this.props.onCheckAllOrders(e.target.checked);
    }

    handleSort = value => {
        this.props.onSearch({sort: value});
    }

    handleClick = () => {
        this.props.onCreateRequestPayment();
    }

    onRequestDistributor() {
        let {item_checked} = this.props;
        item_checked = item_checked.map(function (value) {
            return lodash.parseInt(value);
        });
        this.props.onRequestDistributor({id_warehouse: item_checked});
    }

    /**
     * Làm đẹp Box select kho, trường hợp có 1 thì Text không cần show đủ
     * Trường hợp danh sách địa chỉ kho chỉ có 1 thì cần gì show mã + label.
     */
    prettyWarehouseChoice(warehouses) {
        let arr = [...warehouses];
        let {t} = this.props;
        if (warehouses.length === 1) {
            arr = warehouses.map(function (warehouse) {
                warehouse.label = t('order:list.default_warehouse');

                return warehouse;
            });
        }

        return arr;
    }

    render() {
        let {
            checkedOrders, orders, filter, t, pagination, checkedOrderIds,
            isUsingOrderDistributorSetting,
            isDistributiveWarehouseSetting,
            requiredChooseAddress,
            clearDistributeOrder,
            warehouses,
            item_checked,orderDistributorRequireWarehouse,
            orderDistributorType
        } = this.props;
        const {status} = filter;

        warehouses = this.prettyWarehouseChoice(warehouses);

        // Các đơn được phép tạo YCTT ngoài
        let ordersCanBeCreate = orders.filter(order => order.canCreatePaymentRequest);

        return (
            <div className="d-flex justify-content-between align-items-center py-2 px-4"
                 style={{borderTop: '1px solid #ced4da '}}>
                <div className="d-flex align-item-center">
                    <Checkbox
                        className="a-text--fz-12"
                        style={{marginLeft: "0.7rem"}}
                        onChange={this.handleChange}
                        checked={(orders.length && checkedOrders.length === ordersCanBeCreate.length && ordersCanBeCreate.length > 0) ? true : false}
                    >Chọn tất cả</Checkbox>
                    <Button
                        size="small"
                        className="a-text--fz-12 mr-2"
                        onClick={this.handleClick}
                    ><Icon type="arrow-right"/> Tạo YCTT</Button>
                    <Divider type="vertical" className="mt-1 mr-3"/>
                    {
                        this.props.canCreatePaymentRequestSB && ['PAID', 'WAITING_PAY'].includes(status) &&
                        <>
                            <CreatePaymentRequestBySBOrder orderIds={checkedOrderIds} violateReasons={this.props.violateReasons} />
                            <Divider type="vertical" className="mt-1 mr-3"/>
                        </>
                    }

                    <p className="a-font--12">
                        {t('order:list.total_order_result')}: <strong>{lodash.isNumber(pagination.total) ? pagination.total : '--'}</strong>
                    </p>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <span className="a--cursor-poiter" onClick={this.handleReset}>
                        <Icon type="sync" className="mr-2"/>
                        <span className="a-text--fz-12">Làm mới bộ lọc</span>
                    </span>

                    <div className={isUsingOrderDistributorSetting ? "flex-end" : "hidden"}>
                        <div className={'group_btn_purchase'}>
                            {
                                authService.can(PERMISSION.ORDER_SELF_CLAIM) &&
                                <div className={"d-flex"}>
                                    <div className="mr-2 ml-2">
                                       { orderDistributorType !== "equally" && <DropdownSelectCheckbox
                                            t={t}
                                            item_checked={item_checked}
                                            options={warehouses}
                                            clearDistributeOrder={clearDistributeOrder}
                                            title_button={isDistributiveWarehouseSetting ? t('order:list.choose_warehouse') : t('order:list.choose_address_receive_china')}
                                            placeholder_search={t('order:list.search_address_receive_china')}
                                            onCheckItem={this.props.onCheckItem}
                                            onCheckAll={this.props.onCheckAll}
                                        />}
                                    </div>
                                   
                                    <Button
                                        loading={this.state.loadingRequestOrder}
                                        appearance="primary"
                                        disabled={ !orderDistributorRequireWarehouse ? false : !(item_checked.length &&  orderDistributorType !== "equally" ? orderDistributorRequireWarehouse: true)}
                                        onClick={this.onRequestDistributor.bind(this)}
                                        style={{paddingTop: "1px"}}
                                        className={"a-font--12 aphz-24 a-btn--primary-ant btn-active " }
                                    
                                        >
                                        <i className={"fab fa-mixcloud ml-1 mr-1"}/>
                                        {t("order:list.request_order")}
                                    </Button>
                                </div>
                            }

                        </div>
                    </div>

                    <p className="align-item-center ml-3">
                        <ButtonGroup>
                            <Button size="small" type="link" className={filter.sort === "desc" ? "opacity_4" : ""}
                                    onClick={() => this.handleSort("asc")}>Cũ trước</Button>
                            <Button size="small" type="link" className={filter.sort === "desc" ? "" : "opacity_4"}
                                    onClick={() => this.handleSort("desc")}>Mới trước</Button>
                        </ButtonGroup>
                    </p>
                </div>
            </div>
        );
    }
}

PerformAction.defaultProps = {
    isDistributiveWarehouseSetting: false,
    isUsingOrderDistributorSetting: false,
    requiredChooseAddress: true,
    clearDistributeOrder: () => {
    },
    item_checked: [],
    warehouses: []
};

PerformAction.propTypes = {
    isDistributiveWarehouseSetting: PropTypes.bool,
    isUsingOrderDistributorSetting: PropTypes.bool,
    requiredChooseAddress: PropTypes.bool,
    clearDistributeOrder: PropTypes.func,
    item_checked: PropTypes.array,
    warehouses: PropTypes.array
};

export default translate()(PerformAction);