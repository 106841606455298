import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {notification, Select} from 'antd';
import {processResponse} from "../../../systems/api";
import apiService from "../apiService";

const {Option} = Select;

class SelectOrderSources extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            sellerSources: []
        }
    }

    componentDidMount() {
        this.fetchSellerSources();
    }

    fetchSellerSources = () => {
        let {t} = this.props;
        processResponse(apiService.fetchSellerSource())
            .then(res => {
                this.setState({sellerSources: res.data});
            })
            .catch(() => {
                notification.error(t('message.cant_fetch_data'));
            });
    };

    render() {
        let {t} = this.props;
        let {sellerSources} = this.state;
        return (
            <Select
                value={this.props.value}
                showSearch={true}
                mode={this.props.multiple ? 'multiple' : 'default'}
                onChange={this.props.onChange}
                className={this.props.className}
                placeholder={this.props.placeholder}
            >
                {this.props.allOption && <Option value="">{t('label.all')}</Option>}
                {sellerSources.map(source => <Option key={source} value={source}>{source}</Option>)}
            </Select>
        );
    }
}

SelectOrderSources.defaultProps = {
    value: undefined,
    allOption: false,
    multiple: false,
    onChange: value => {},
    className: '',
    placeholder: ''
};

SelectOrderSources.propTypes = {
    value: PropTypes.any,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string
};

export default translate()(SelectOrderSources);
