import * as Constants from './constants';
import lodash from 'lodash';
import {combineReducers} from 'redux';
import complaintDetail from './ComplaintSellerDetail/reducer';
import {COMPLAINT_SELLER_STATUS} from "./../../systems/globalContant/actionContant";
import {router} from './../../../src/systems/routing/index';
import queryString from 'query-string';
import complaintAssign from './ComplaintAssign/reducer';
import completeComplaint from './CompleteComplaint/reducer';
import complaintReception from './ComplaintReception/reducer';
import failedComplaint from './FailedComplaint/reducer';
import createProductReturn from './CreateProductReturn/reducer';
import complaintNote from './ComplaintNote/reducer';
import complaintMarkImportant from './ComplaintMarkImportant/reducer';
import {COMPLAINT_MARK_IMPORTANT} from './ComplaintMarkImportant/constants';
import {updateCollectionItem} from "../common/helps";
import {actions} from "react-redux-toastr";

/**
 * state quản lý việc mở popup edit/ create complaint seller
 * @param state
 * @param action
 * @returns {boolean}
 */
const isModalProcessing = (state = false, action) => {
    switch (action.type) {
        case Constants.CREATE_COMPLAINT_SELLER.REQUEST:
        case Constants.UPDATE_COMPLAINT_SELLER.REQUEST:
        case Constants.CREATE_COMPLAINT_SELLER.FAILED:
        case Constants.UPDATE_COMPLAINT_SELLER.FAILED:
        case Constants.UPDATE_COMPLAINT_SELLER.ERROR:
        case Constants.CREATE_COMPLAINT_SELLER.ERROR:
        case Constants.OPEN_MODAL_COMPLAINT_SELLER:
            return true;
        case Constants.UPDATE_COMPLAINT_SELLER.SUCCESS:
        case Constants.FETCH_COMPLAINT_SELLER.SUCCESS:
        case Constants.CREATE_COMPLAINT_SELLER.SUCCESS:
        case Constants.CLOSE_MODAL_COMPLAINT_SELLER:
            return false;
        default:
            return state;
    }
}

const listComplaintSellerReason = (state = [], action) => {
    let {payload} = action || {};
    switch (action.type) {
        case Constants.FETCH_COMPLAINT_SELLER_REASONS.SUCCESS:
            return lodash.get(payload, 'data', []);
        case Constants.FETCH_COMPLAINT_SELLER_REASONS.FAILED:
            return lodash.get(payload, 'data', []);
        default:
            return state;
    }
};



const listComplaintSeller = (state = [], action) => {
    let {payload} = action || {};
    let stateClone = {...state};
    let complaintSellers = [];
    let is_important = 0;
    let id = null;
    switch (action.type) {
        case Constants.FETCH_COMPLAINT_SELLER.REQUEST:
            return [];
        case Constants.FETCH_COMPLAINT_SELLER.SUCCESS:
            return lodash.get(payload, 'data', []);
        case Constants.CREATE_COMPLAINT_SELLER.SUCCESS:
            let filter =  queryString.parse(router.location.search, {arrayFormat: 'bracket'});
            let complaintSellerNew = lodash.get(action, 'payload.data.complaintSeller', {});
            if (!lodash.isEmpty(complaintSellerNew)) {
                if (stateClone.complaintSellers && stateClone.pagination) {
                    complaintSellers = stateClone.complaintSellers;
                    if( !filter.status || filter.status === COMPLAINT_SELLER_STATUS.WAITING ){
                        if( complaintSellers.length === 20 ){
                            complaintSellers = complaintSellers.slice(0, -1);
                        }
                        complaintSellers.unshift(complaintSellerNew);
                    }
                    stateClone = {
                        ...stateClone,
                        complaintSellers: complaintSellers,
                        pagination: {...stateClone.pagination, ...{total: stateClone.pagination.total + 1}},
                        stats: {
                            ...stateClone.stats,
                            [COMPLAINT_SELLER_STATUS.WAITING]: lodash.get(stateClone.stats, COMPLAINT_SELLER_STATUS.WAITING, 0) + 1
                        }
                    }
                }
            }
            return stateClone;

        case COMPLAINT_MARK_IMPORTANT.REQUEST:
        {
            is_important = lodash.get(payload, 'params.params.is_important', 0);
            id = lodash.get(payload, 'params.id', 0);
            complaintSellers = lodash.get(stateClone,'complaintSellers',[]).map(stateCloneItem=>{
                if( stateCloneItem.id === id ){
                    stateCloneItem = {...stateCloneItem, ...{is_important: is_important ? 1 : 0}}
                }

                return stateCloneItem;
            });
            return {...stateClone,...{complaintSellers}};
        }

        case COMPLAINT_MARK_IMPORTANT.SUCCESS:
        {
            is_important = lodash.get(payload, 'params.params.is_important', 0);
            id = lodash.get(payload, 'params.params.id', 0);
            complaintSellers = lodash.get(stateClone,'complaintSellers',[]).map(stateCloneItem=>{
                if( stateCloneItem.id === id ){
                    stateCloneItem = {...stateCloneItem, ...{is_important: is_important ? 1 : 0}}
                }
                return stateCloneItem;
            });
            return {...stateClone,...{complaintSellers}};
        }
        case COMPLAINT_MARK_IMPORTANT.FAILED:
        case COMPLAINT_MARK_IMPORTANT.ERROR:
        {
            is_important = lodash.get(payload, 'params.is_important', 0);
            id = lodash.get(payload, 'params.id', 0);
            complaintSellers = lodash.get(stateClone,'complaintSellers',[]).map(stateCloneItem=>{
                if( stateCloneItem.id === id ){
                    stateCloneItem = {...stateCloneItem, ...{is_important: is_important ? 0 : 1}}
                }
                return stateCloneItem;
            });
            return {...stateClone,...{complaintSellers}};
        }
        case Constants.COMPLAINT_SELLERS_REJECTED:
            let complaintSellerOld = lodash.get(action, 'payload.data.complaint', {});
            if (!lodash.isEmpty(complaintSellerOld)) {
                complaintSellers = stateClone.complaintSellers;
                complaintSellers = lodash.remove(complaintSellers, function(complaint) {
                    return complaint.id !== complaintSellerOld.id;
                });
                stateClone = {
                    ...stateClone,
                    complaintSellers: complaintSellers,
                    pagination: {...stateClone.pagination, ...{total: stateClone.pagination.total - 1}},
                    stats: {
                        ...stateClone.stats,
                        [COMPLAINT_SELLER_STATUS.WAITING]: lodash.get(stateClone.stats, COMPLAINT_SELLER_STATUS.WAITING, 0) - 1,
                        [COMPLAINT_SELLER_STATUS.FAILURE]: lodash.get(stateClone.stats, COMPLAINT_SELLER_STATUS.FAILURE, 0) + 1,
                    }
                };
            }
            return stateClone;
        default:
            return state;
    }
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
const createErrors = (state = {}, action) => {
    const {payload} = action;
    let newState = {...state};
    switch (action.type) {
        case Constants.CREATE_COMPLAINT_SELLER.REQUEST:
        case Constants.CREATE_COMPLAINT_SELLER.SUCCESS:
        case Constants.IS_BEGIN_PROCESS_CREATE_COMPLAINT_SELLER:
            return {};
        case Constants.CREATE_COMPLAINT_SELLER.FAILED:
        case Constants.CREATE_COMPLAINT_SELLER.ERROR:
            return lodash.get(payload, 'data', {});
        case Constants.CLEAR_ERROR_COMPLAINT_SELLER:
            switch (payload){
                case 'id_order':
                    if (lodash.has(newState, 'id_order')) {
                        newState = lodash.omit(newState, 'id_order');
                    }
                    break;
                case 'complaint_seller_reason_id':
                    if (lodash.has(newState, 'complaint_seller_reason_id')) {
                        newState = lodash.omit(newState, 'complaint_seller_reason_id');
                    }
                    break;
                default:
                    return newState;
            }
            return newState;
        default:
            return state;
    }
};


const defaultIsLoading = {
    postComplaintSellerCreate: false,
};

/**
 * State quản lý việc có hệ thống khi thực hiện các thao tác xử lý tạo mới cập nhật
 * @param state
 * @param action
 */
const isLoadingData = (state = defaultIsLoading, action) => {
    switch (action.type) {
        case Constants.CREATE_COMPLAINT_SELLER.REQUEST:
            return {...state, ...{postComplaintSellerCreate: true}};
        case Constants.CREATE_COMPLAINT_SELLER.SUCCESS:
        case Constants.CREATE_COMPLAINT_SELLER.FAILED:
        case Constants.CREATE_COMPLAINT_SELLER.ERROR:
            return {...state, ...{postComplaintSellerCreate: false}};

        default:
            return state;
    }
};

/**
 * State quản lý việc thao tác lấy danh sách KNNB
 * @param state
 * @param action
 */
const isLoading = (state = false, action) => {
    switch (action.type) {
        case Constants.FETCH_COMPLAINT_SELLER.SUCCESS:
        case Constants.FETCH_COMPLAINT_SELLER.FAILED:
            return false;
        case Constants.FETCH_COMPLAINT_SELLER.REQUEST:
            return true;
        default:
            return state;
    }
}

const defaultIsEndProcess = {
    postComplaintSellerCreate: false,
};

/**
 * State quản lý việc có hệ thống đã kết thúc tiến trình post
 * @param state
 * @param action
 */
const isEndProcess = (state = defaultIsEndProcess, action) => {
    switch (action.type) {
        case Constants.CREATE_COMPLAINT_SELLER.SUCCESS:
            return {...state, postComplaintSellerCreate: true}
        case Constants.CREATE_COMPLAINT_SELLER.REQUEST:
        case Constants.CREATE_COMPLAINT_SELLER.FAILED:
        case Constants.CREATE_COMPLAINT_SELLER.ERROR:
        case Constants.IS_BEGIN_PROCESS_CREATE_COMPLAINT_SELLER:
            return {...state, postComplaintSellerCreate: false}
        default:
            return state;
    }
};

export default combineReducers({
    createErrors,
    isLoadingData,
    isLoading,
    complaintNote,
    complaintMarkImportant,
    isEndProcess,
    listComplaintSellerReason,
    listComplaintSeller,
    complaintDetail,
    completeComplaint,
    complaintReception,
    failedComplaint,
    complaintAssign,
    createProductReturn,
    isModalProcessing
});
