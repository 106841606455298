import {call, put} from 'redux-saga/effects'
import lodash from 'lodash';
import * as Constants from './constants';
import service from './apiService';
import bootbox from "../../common/components/Bootbox/bootbox";
import {t} from '../../../systems/i18n/index.js';
import * as GlobalConstants from "../../../systems/globalContant/index";

export function* fetchTransactionFile(action) {
    try {
        let response = yield call(service.getListTransactionFile, action.payload);
        if (response) {
            if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({type: Constants.GET_LIST_TRANSACTION_FILE.SUCCESS, payload: response.data});
            } else {
                yield put({type: Constants.GET_LIST_TRANSACTION_FILE.FAILED, payload: response.data});
            }
        }
    } catch (err) {
        yield put({type: Constants.GET_LIST_TRANSACTION_FILE.FAILED, payload: err});
        bootbox.error(t('message.system_errors'));
    }
}


export function* downloadFile(action) {
    try {
        let response = yield call(service.downloadFile, action.payload);
        let url = lodash.get(response, 'data.data.url');

        if (url) {
            return window.open(url);
        }
    } catch (err) {
        bootbox.error(t('message.system_errors'));
    }
}
