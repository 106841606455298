import React from 'react'
import Spinner from '@atlaskit/spinner';
import lodash from "lodash";
import {translate} from 'react-i18next';
import {Checkbox} from "antd";

class FeatureItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            onChecked: false,
            isActionLoading: false,
            idFeatureItem: 0
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        const {featureItem} = nextProps;

        if (JSON.stringify(featureItem) !== JSON.stringify(prevState.feature)) {
            if (featureItem.confirm && featureItem.isChecked && prevState.onChecked) {
                state.isOpen = true;
                state.onChecked = false;
            } else {
                state.isOpen = false;
            }
            state.isActionLoading = false;
            state.feature = featureItem;
        }
        return state;
    }

    onHandleChecked(checked) {
        this.setState({onChecked: checked})
    }

    render() {
        const {featureItem, onChangeCheckbox} = this.props;
        return (
            <div className={"ml-2 " + ( featureItem.show ? "" : "hidden")}>
                {
                    !featureItem.loading ?
                        <Checkbox
                            key={featureItem.id}
                            checked={!!featureItem.isChecked}
                            disabled={featureItem.isDisabled}
                            onChange={event => {
                                let checked = event.target.checked;
                                if (checked) {
                                    this.onHandleChecked(checked);
                                }
                                onChangeCheckbox(event, featureItem.id)
                            }}
                        /> :
                        <Spinner size="small"/>
                }
                <i className={ "ml-2 mr-1 " + (
                    lodash.get(featureItem, 'icon', null) ?
                        lodash.get(featureItem, 'icon', 'ion-android-globe') : 'ion-android-globe')}
                />
                <span>{featureItem.name}</span>
            </div>
        )
    }
}

export default translate('translations')(FeatureItem);
