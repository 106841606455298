import React, {useState} from "react";
import {Col, Row, Spin} from "antd";
import OrderItemProduct from "../components/OrderItemProduct";
import {translate} from "react-i18next";
import lodash from "lodash";
import {t} from "../../../../systems/i18n";
import ListProductByFreghtBill from "./ListProductByFreghtBill";

function ListMissedProducts(props) {
    const {order, addCommentProduct} = props;
    const [items, setItems] = useState(lodash.get(order, "items", []));
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(t("complaintSeller:label.list_miss_product"))
    return (
        <div className="complaint-seller-box a-content--box-shadow p-0 h-100 mt-3">
            <div className="cs-box-title d-flex justify-content-between align-item-center p-3 pt-2 pb-2 a-text--bold">
                <p className="a-text--fz-14 text-uppercase">
                    {title + " (" + (items.length > 0 ? items.length : 0) + ")"}
                </p>
                <ListProductByFreghtBill setItems={setItems} order={order} setLoading={setLoading} setTitle={setTitle}/>
            </div>
            <div className="a-table__scroll-x border-top">
                <div className="a-purchasod__table__order">
                    <Row className="p-2 a-text--bold">
                        <Col span={22} offset={2}>
                            <Row>
                                <Col span={12}>{t("complaintSeller:detail.products")}</Col>
                                <Col span={6}>{t('order_detail.quantity')}</Col>
                                <Col span={6}>
                                    <span>{t('order_detail.unit_price')}</span>
                                    <span className="seperate-v">{t('order_detail.sum')}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr className="m-0"/>
                    <div className="p-2">
                        <table width="100%">
                            <tbody>
                            {
                                loading ?
                                    <div className="text-center pt-2">
                                        <Spin/>
                                    </div>
                                    :
                                    items.map((item, index) => (
                                        (<tr key={item.id}>
                                            {index === 0 ? null : <hr className="m-0"/>}
                                            <OrderItemProduct
                                                item={item}
                                                order={order}
                                                addCommentProduct={addCommentProduct}
                                            />
                                        </tr>)
                                    ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {!items.length ?
                <React.Fragment key={order.id}>
                    <div>
                        <p style={{marginTop: '15px', textAlign: 'center'}}>
                            {t('orderDetail:order_detail.not_found_data')}
                        </p>
                    </div>
                </React.Fragment>
                :
                null
            }
        </div>
    )
}

export default translate()(ListMissedProducts);