import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import LogMessage from './../LogMessage';
import lodash from 'lodash';
import {LOG_TYPE} from './../../constants';

class Log extends React.Component {
    render() {
        let {log = {}} = this.props;
        let arrIncludeComment = [LOG_TYPE.PRODUCT_RETURN_COMMENT_IMAGE, LOG_TYPE.PRODUCT_RETURN_COMMENT];
        return lodash.includes(arrIncludeComment, log.action) ? this.renderComment() : this.renderLog();
    }

    /**
     * Hiển thị comment
     */
    renderComment() {
        let {log} = this.props;
        let {creator = {}} = log;
        let is_admin = lodash.get(creator, 'is_admin', null);
        return (
            <div className="a-chat__block">
                <div className={'a-chat__info'}>
                    <p className={'a-chat__info--author '+(is_admin?"":"customer")}>{creator.name}</p>
                    <div className={'a-chat__info--time'}>
                        <i className={'ion-android-time'}></i>
                        <span>{log.time_format}</span>
                    </div>
                </div>

                <div className="a-chat__comment">
                    <p><LogMessage log={log}/></p>
                </div>
            </div>
        );
    }

    /**
     * Hiển thị log
     */
    renderLog() {
        let {log} = this.props;
        let creator = lodash.get(log, "creator", {});
        let is_admin = lodash.get(creator, 'is_admin', null);
        return (
            <div className={(creator.username === null || creator.username === "" ? "system" : "") + " a-chat__block"}>
                <div className={'a-chat__info'}>
                    <p className={'a-chat__info--author '+(is_admin?"":"customer")}>
                        {creator.username === null || creator.username === "" ? "System" : creator.name}</p>
                    <div className={'a-chat__info--time'}>
                        <i className={'ion-android-time'}/>
                        <span>{log.time_format}</span>
                    </div>
                </div>
                <div className="a-chat__comment ">
                    <p className={"system"}><LogMessage log={log}/></p>
                </div>
            </div>
        );
    }
}

Log.defaultProps = {
    log: {},
    onCollapse: () => {
    },
};

Log.propTypes = {
    log: PropTypes.object.isRequired,
    onCollapse: PropTypes.func,
};

export default translate()(Log);
