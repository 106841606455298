import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {trans} from './../../../../systems/i18n';
import {
    ORDER_STATUS,
    ORDER_STATUS_REAL,
    PRODUCT_RETURN_STATUS,
    COMPLAINT_SELLER_STATUS
} from "./../../../../systems/globalContant/actionContant";
import FormProcessing from './../../../common/components/FormProcessing';
import {Row, Col} from "antd";

const stateDefault = {
    code: '',
    complaint_seller_reason_id: '',
    order_seller_name: '',
    is_important: 'all',
    seller_source: [],
    id_processor: [],
    account_purchaser_id: [],
    order_seller_source: [],
    refund_status: '',
    time_type: COMPLAINT_SELLER_STATUS.WAITING,
    log_status_to: '',
    log_status_from: '',
    sortBy: '',
    sort: 'desc',

};

class FormSearchHeader extends FormProcessing {

    constructor(props) {
        super(props);
        this.state = {
            input: stateDefault,
            inputVersion: 0,
            changedParams: [],
            isSortAmount: false,
            isSortTime: false,
            isShowSortAmount: false,
            isShowSortTime: true,
            isShowSortExpire: false
        }
    }

    sortAmount() {
        let input = '';
        let {filter} = this.props;
        if (filter.sort === 'asc') {
            input = this.changeInput({orderBy: 'amount', sort: 'desc'});
        } else {
            input = this.changeInput({orderBy: 'amount', sort: 'asc'});
        }
        this.setState({isSortAmount: !this.state.isSortAmount});
        this.props.onSubmit(input);
    }

    showSort(select) {
        switch (select) {
            case 'amount':
                this.setState({isShowSortAmount: true, isShowSortTime: false, isShowSortExpire: false});
                this.sortAmount();
                return;
            case 'time':
                this.setState({isShowSortTime: true, isShowSortAmount: false, isShowSortExpire: false});
                this.sortTime();
                return;
            case 'expire':
                this.setState({isShowSortExpire: true, isShowSortAmount: false, isShowSortTime: false});
                this.sortExpire();
                return;
            default:
                return false;
        }
    }

    sortExpire() {
        let {filter} = this.props;
        let input = '';
        if (filter.sort === 'asc') {
            input = this.changeInput({
                orderBy: "expire_time",
                sort: 'desc'
            });
        } else {
            input = this.changeInput({
                orderBy: "expire_time",
                sort: 'asc'
            });
        }
        this.setState({isSortTime: !this.state.isSortTime});
        this.props.onSubmit(input);
    }

    sortTime() {
        let {filter} = this.props;
        let input = '';
        if (filter.sort === 'asc') {
            input = this.changeInput({
                orderBy: "created_at",
                sort: 'desc',
            });
        } else {
            input = this.changeInput({
                orderBy: "created_at",
                sort: 'asc',
            });
        }
        this.setState({isSortTime: !this.state.isSortTime});
        this.props.onSubmit(input);
    }

    render() {
        let {t, filter} = this.props;
        let {
            isShowSortAmount,
            isShowSortTime,
            isShowSortExpire
        } = this.state;

        return (
            <div className="a-item__complaint search__header a-text--fz-12 a-text--bold">
                <Row gutter={10}>
                    <Col span={5}><span style={{marginLeft: "28px"}}>Mã đơn</span></Col>
                    <Col span={3}><span>Lý do</span></Col>
                    <Col span={3}><span>Nguồn</span></Col>
                    <Col span={3}>
                        <span>
                            {t('complaintSeller:list.complaint_amount')}
                            {!isShowSortAmount ?
                                <i onClick={this.showSort.bind(this, 'amount')}
                                   className="fas fa-sort ml-2 a--cursor-poiter _complaint-seller-sort-complaint-amount"/> :
                                <i onClick={this.sortAmount.bind(this)}
                                   className={filter.sort === 'asc' ?
                                       "fas fa-sort-amount-up ml-2 a--cursor-poiter _complaint-seller-sort-complaint-amount-type" :
                                       "fas fa-sort-amount-down ml-2 a--cursor-poiter _complaint-seller-sort-complaint-amount-type"}/>
                            }
                        </span>
                    </Col>
                    <Col span={4}>
                        <span>{t('complaintSeller:list.time')}</span>
                        {
                            !isShowSortTime ?
                                <i onClick={this.showSort.bind(this, 'time')}
                                   className="fas fa-sort ml-2 a--cursor-poiter _complaint-seller-sort-complaint-time"/> :
                                <i onClick={this.sortTime.bind(this)}
                                   className={filter.sort === 'asc' ?
                                       "fas fa-sort-amount-up ml-2 a--cursor-poiter _complaint-seller-sort-complaint-time-type" :
                                       "fas fa-sort-amount-down ml-2 a--cursor-poiter _complaint-seller-sort-complaint-time-type"}>
                                </i>
                        }
                    </Col>
                    <Col span={3} className="a-text--red">
                        <span>{t('complaintSeller:list.processing_time')}</span>
                        {
                            !isShowSortExpire ?
                                <i onClick={this.showSort.bind(this, 'expire')}
                                   className="fas fa-sort ml-2 a--cursor-poiter _complaint-seller-sort-complaint-expire"/> :
                                <i onClick={this.sortExpire.bind(this)}
                                   className={filter.sort === 'asc' ?
                                       "fas fa-sort-amount-up ml-2 a--cursor-poiter _complaint-seller-sort-complaint-expire-type"
                                       : "fas fa-sort-amount-down ml-2 a--cursor-poiter _complaint-seller-sort-complaint-expire-type"}>
                                </i>
                        }
                    </Col>
                    <Col span={3}><span>{t('complaintSeller:list.reciever')}</span></Col>
                </Row>
            </div>
        );
    }
}

FormSearchHeader.defaultProps = {
    onClearCheckAll: () => {
    },
    filter: {},
};

FormSearchHeader.propTypes = {
    onClearCheckAll: PropTypes.func,
    filter: PropTypes.object,
};

export default translate()(FormSearchHeader);
