import {Button} from "antd";
import React from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import apiService from "../ListTransaction/apiService";
import {processResponse} from "../../../systems/api";
import {events} from "../../../systems/events";
import notification from '../../../systems/notification';
import TRANSACTION_EVENT from "../events";
import {dispatch} from "../../../systems/store";
import {resetError} from "../ListTransaction/actions";

export default class ExportCSV extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }
    exportCSV() {
        if (this.state.loading) {
            return;
        }

        this.setState({loading: true});

        let {t, target, csvFilter, filter} = this.props;
        let exportFilter = {};
        if (lodash.isEmpty(csvFilter)) {
            exportFilter = {
                ...exportFilter,
                ...filter,
            };
        } else {
            exportFilter = {
                ...exportFilter,
                ...csvFilter,
            };
        }
        if (exportFilter.is_resolved) {
            exportFilter = {
                ...exportFilter,
                is_resolved: parseInt(exportFilter.is_resolved)
            };
        }
        exportFilter = lodash.omitBy(exportFilter, value => value === '');
        let res;
        switch (target) {
            case "transaction":
                res = apiService.exportCSV({...exportFilter})
                    .finally(() => this.setState({loading: false}));
                break;
            case "transaction_order":
                res = apiService.exportTransactionOrder({...exportFilter})
                    .finally(() => this.setState({loading: false}));
        }
        let process = processResponse(res);

        process.then(res => {
            target === "transaction" && this.props.resetError('transaction_time');
            events.dispatch(TRANSACTION_EVENT.RESET_ERROR);
            dispatch(resetError('time'));
            notification.success(t('transaction:message.export_csv_success'));
        });
        process.catch((error) => {
            events.dispatch(TRANSACTION_EVENT.EXPORT_FAILED, {...error});
        });
    }
    render() {
        let {t} = this.props;
        return (
            <Button
                loading={this.state.loading}
                onClick={this.exportCSV.bind(this)}
                className=" a-font--12 aphz-50 a-btn--primary-ant ghost mr-4 _transaction-button-export-csv"
                size={"small"}>{t('transaction:btn.export')}</Button>
        )
    }
}
ExportCSV.defaultProps = {
    loading: false,
    csvFilter: {},
};

ExportCSV.propTypes = {
    csvFilter: PropTypes.object,
    loading: PropTypes.bool,
    t: PropTypes.func,
    resetError: PropTypes.func,
};
