export default {
    RESOLVED: 'TRANSACTION.RESOLVED',
    UNRESOLVED: 'TRANSACTION.UNRESOLVED',
    MARKED: 'TRANSACTION.MARKED',
    UNMARKED: 'TRANSACTION.UNMARKED',
    NOTE_UPDATED: 'TRANSACTION.NOTE_UPDATED',
    EXPORT_FAILED: 'TRANSACTION_EXPORT_FAILED',
    RESET_ERROR: 'TRANSACTION_RESET_ERROR',
    ADD_RELATED_TRANSACTION: 'ADD_RELATED_TRANSACTION',
    DELETE_RELATED_TRANSACTION: 'DELETE_RELATED_TRANSACTION',
    REFUND: 'REFUND'
};
