import React, {useState} from "react";
import {translate} from "react-i18next";
import {trans} from "../../../../systems/i18n";
import lodash from "lodash";
import {Button, Dropdown, Icon, Menu, notification, Select} from "antd";
import apiService from "../../apiService";
import {processResponse} from "../../../../systems/api";

const {Option} = Select;

function ListProductByFreghtBill(props) {
    const {order, setItems, setLoading, setTitle} = props;
    const freightBills = lodash.get(order, "freightBills", []);
    const [selectedKey, setSelectedKey] = useState(undefined);

    function fetchProducts(freightBill) {
        setLoading(true);
        const response = apiService.getProductsByFreightBill(order.id, freightBill);
        const resultResponse = processResponse(response);
        resultResponse.then((res) => {
            const data = lodash.get(res, "data", []);
            setItems(convertData(data));
        }).catch(() => {
            notification.error({message: trans("message.cant_fetch_data", {attribute: trans("complaintSeller:label.list_product_by_freight_bill")})})
        }).finally(() => setLoading(false));
    }

    function convertData(data) {
        const items = [];
        data.map((item) => {
            const unit_price = lodash.get(item, "unit_price_origin", 0);
            const qty_bought = lodash.get(item, "purchase_quantity", 0);
            const option = lodash.map(lodash.get(item, "variant_properties", []), "value").join(" - ");
            return items.push({
                list_by: "freight_bill",
                affiliate_link: lodash.get(item, "url", ""),
                link: lodash.get(item, "url", ""),
                partner_order_item_id: lodash.get(item, "code_item", ""),
                image: lodash.get(item, "product_image", ""),
                qty: lodash.get(item, "order_quantity", ""),
                qty_bought: lodash.get(item, "purchase_quantity", ""),
                qty_received: lodash.get(item, "received_quantity", ""),
                unit_price: unit_price,
                total: unit_price * qty_bought,
                item_id: lodash.get(item, "id", ""),
                category_name: lodash.get(item, "category_name", ""),
                option: option,
                title: lodash.get(item, "translated_name", "")
            })
        })
        return items;
    }

    function handleOnChange(value) {
        const {key} = value;
        if (selectedKey === key) {
            cancelFreightBill(key);
        } else {
            setSelectedKey(key);
            setTitle(trans("complaintSeller:label.list_product_by_freight_bill") + " " + key)
            fetchProducts(key);
        }
    }

    function cancelFreightBill(value) {
        if (selectedKey === value) {
            setSelectedKey(undefined);
            setTitle(trans("complaintSeller:label.list_miss_product"))
            setItems(lodash.get(order, "items", []))
        }
    }

    const renderSelectFreightBill = (
        <Menu onClick={handleOnChange} selectedKeys={[selectedKey]}>
            {
                freightBills.map(item => {
                    return <Menu.Item key={item.freight_bill} value={item.freight_bill}>
                        <div className="d-flex justify-content-between">
                            <p>{item.freight_bill}</p>
                            {
                                item.freight_bill === selectedKey &&
                                <div>
                                    <Icon type="close-circle" style={{color: "gray"}}
                                          onClick={() => cancelFreightBill(item.freight_bill)}/>
                                </div>
                            }
                        </div>
                    </Menu.Item>
                })
            }
        </Menu>
    )


    return (
        <>
            {
                freightBills.length > 0 &&
                <Dropdown overlay={renderSelectFreightBill}>
                    <Button type={selectedKey ? "primary" : ""}>
                        {
                            trans("complaintSeller:placeholder.watch_by_freight_bill")
                        }
                        <Icon type="down"/>
                    </Button>
                </Dropdown>
            }
        </>
    )
}

export default translate()(ListProductByFreghtBill);