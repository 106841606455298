import React from 'react';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import ModalConfirmDelete from './ModalConfirmDelete';
import {Link} from '../../../../../src/systems/routing';

class PackageItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {index, packageInfo, onRemovePackage, deletingPackage, canUpdateIntoStatus, t} = this.props;
        return (
            <React.Fragment>
                <tr>
                    <td>{index}</td>
                    <td>

                        <p><Link isNewTab={true} style={{"text-decoration-line":"none"}} href={packageInfo.logistic_url_package ? packageInfo.logistic_url_package:'#'}> {packageInfo.logisticPackageBarcode}</Link></p>
                        <p style={{'margin-top':'4px'}}>{packageInfo.status ? t('order:status.'+packageInfo.status) :'---'}</p>
                    </td>
                    <td><p>{packageInfo.warehouseStatus ? t('productReturn:warehouse.'+packageInfo.warehouseStatus) :'---'}</p>
                        <p style={{'margin-top':'4px'}}>{packageInfo.currentDivision ? '('+packageInfo.currentDivision+')':''}</p>
                    </td>
                    <td>{packageInfo.logisticType ? t('productReturn:logisticType.'+packageInfo.logisticType) :'---'}</td>
                    <td>
                        <ModalConfirmDelete
                            isModalProcessing={this.props.isModalProcessing}
                            packageInfo={lodash.get(packageInfo,'logisticPackageBarcode','---')}
                            canUpdateIntoStatus={canUpdateIntoStatus}
                            loading={deletingPackage}
                            onBeginRemovePackage={this.props.onBeginRemovePackage}
                            onRemovePackage={onRemovePackage}/>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

PackageItem.defaultProps = {
    index: 0,
    canUpdateIntoStatus: false,
    deletingPackage: false,
    packageItem: {},
    onRemovePackage: ()=> {
    },
};

PackageItem.propTypes = {
    index: PropTypes.number,
    canUpdateIntoStatus: PropTypes.bool,
    deletingPackage: PropTypes.bool,
    packageItem: PropTypes.object,
    onRemovePackage: PropTypes.func,
};

export default translate('translations')(PackageItem);
