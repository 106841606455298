import React from "react";
import lodash from "lodash";
import {Affix, notification, Table, Button, Modal} from "antd";
import {translate} from "react-i18next";
import PaginationFull from '../../../modules/common/components/PaginationFull';
import {getState} from "../../../systems/store";
import {processResponse} from "../../../systems/api";
import apiService from "../apiService";
import moment from "moment";
import Link from "../../../systems/routing/Link";

class List extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payLoading: false,
            selectedRowKeys: [],
        };
    }

    onSelectChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };


    onChangePage(page, pageSize) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page_no: page,
                page_size: pageSize,
            });
        }
    }

    async handlePayPurchaseOrder(purchaseIds) {

        if (purchaseIds.length > 0) {
            this.setState({payLoading: true});
            await processResponse(apiService.payTaobaoPurchaseOrders({purchaseOrderIdList: purchaseIds})).then(res => {
                const pay_failure_purchase_order_ids = lodash.get(res, "data.data.pay_failure_purchase_order_ids", []);
                const will_pay_purchase_order_ids = lodash.get(res, "data.data.will_pay_purchase_order_ids", []);
                this.setState({
                    payLoading: false,
                });
                Modal.info({
                    title: 'Kết quả thanh toán đơn hàng',
                    content: (
                        <div>
                            <p> - Thanh toán không thành
                                công: <b>{pay_failure_purchase_order_ids.length > 0 ? `Đơn hàng có ID ${pay_failure_purchase_order_ids.join(", ")}` : "0 Đơn hàng"}</b>
                            </p>
                            <p> - Sẽ tiến hành thanh
                                toán: <b>{will_pay_purchase_order_ids.length > 0 ? `Đơn hàng có ID ${will_pay_purchase_order_ids.join(", ")}` : "0 Đơn hàng"}</b>
                            </p>
                        </div>
                    ),
                });
            }).catch(error => {
                notification.error({message: "Có lỗi trong quá trình xử lý"});
                this.setState({loading: false});
            });
        } else {
            notification.error({message: "Vui lòng chọn đơn cần thanh toán"})
        }
    }

    render() {
        let {
            loading,
            orders,
            pagination,
            t,
        } = this.props;
        const {selectedRowKeys, payLoading} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [

            {
                title: 'ID đơn TBG',
                dataIndex: "purchase_id",
                key: 'purchase_id',
            },
            {
                title: 'ID đơn hệ thống',
                dataIndex: "outer_purchase_id",
                key: 'outer_purchase_id',
            },
            {
                title: 'Mã đơn hàng',
                dataIndex: ["order", "code"],
                key: "order_code",
                render: (text, record) => {
                    const order = lodash.get(record, "order", {});
                    return lodash.isEmpty(order) ? " " : <Link
                        isNewTab={true}
                        className="mr-2 ml-2 a-text--bold"
                        to="purchase.buying.detail"
                        params={{id: order.id}}>
                        {text}
                    </Link>
                }
            },
            {
                title: "Mã vận đơn",
                dataIndex: "sub_purchase_orders",
                key: 'freight_bills',
                render: (text) => {
                    const logistic_numbers = [];
                    return lodash.map(text, item => {
                        const logistic_number = lodash.get(item, "logistic_number", "");
                        if (!logistic_numbers.includes(logistic_number)) {
                            logistic_numbers.push(logistic_number);
                            return <p>{logistic_number}</p>
                        }
                    })
                }
            },
            {
                title: "MHĐG TBG",
                dataIndex: "sub_purchase_orders",
                key: 'invoice_code',
                render: (text) => {
                    const invoice_codes = [];
                    return lodash.map(text, item => {
                        const invoice_code = lodash.get(item, "purchase_order_outer_id", "");
                        if (!invoice_codes.includes(invoice_code)) {
                            invoice_codes.push(invoice_code);
                            return <p>{invoice_code}</p>
                        }
                    });
                }
            },
            {
                title: "MHĐG Hệ thống",
                dataIndex: "order_invoice_codes",
                key: 'order_invoice_codes',
                render: (text) => {
                    const invoice_codes = [];
                    return lodash.map(text, item => {
                        const invoice_code = lodash.get(item, "invoice_code", "");
                        if (!invoice_codes.includes(invoice_code)) {
                            invoice_codes.push(invoice_code);
                            return <p>{invoice_code}</p>
                        }
                    });
                }
            },
            {
                title: "Tiền đơn hàng",
                dataIndex: "purchase_amount",
                key: 'purchase_amount',
                render: (text, record) => {
                    const currency = lodash.get(record, "purchase_currency", "");
                    return <p>{`${text} ${currency}`}</p>
                }
            },
            {
                title: "Phí nội địa",
                dataIndex: "domestic_post_fee",
                key: 'domestic_post_fee',
                render: (text, record) => {
                    const currency = lodash.get(record, "purchase_currency", "");
                    return <p>{`${text} ${currency}`}</p>
                }
            },
            {
                title: "Đơn hàng âm",
                dataIndex: "negative",
                key: 'negative',
                render: (text) => {
                    return text ? <span style={{color: "red", fontWeight:  500}}>Đơn hàng âm</span> : ""
                }
            },
            {
                title: "Trạng thái đơn",
                dataIndex: "status",
                key: 'status',
                render: (text) => <p>{t(`order:ORDER_TAOBAO_STATUS.${text}`)}</p>
            },
            {
                title: "Ngày tạo",
                dataIndex: "_created_time",
                key: '_created_time',
                render: text => <p>{moment(text).format('DD/MM/YYYY HH:mm')}</p>
            },
            {
                title: "Ngày cập nhật",
                dataIndex: "_modify_time",
                key: '_modify_time',
                render: text => <p>{moment(text).format('DD/MM/YYYY HH:mm')}</p>
            },
        ];
        let user = getState('User.me.user', {});
        const canPayPurchaseOrderTaobao = lodash.get(user, "canPayPurchaseOrderTaobao", false);
        return (
            <div className="a-list mt-5">
                <Affix>
                    <div className="a-list__top d-flex justify-content-between align-item-center">
                        <h2 className="a-list--title a-text--uppercase">Danh sách đơn hàng
                            ({!lodash.isEmpty(pagination) ? pagination.results_total : 0})</h2>
                        {
                            canPayPurchaseOrderTaobao &&
                            <Button type="primary" loading={payLoading} onClick={() => this.handlePayPurchaseOrder(selectedRowKeys)}>Thanh
                                toán đơn</Button>
                        }

                    </div>
                </Affix>
                <div className="a-content--is-pagination-fixed" style={{padding: "16px 24px 0 24px"}}>
                    <Table
                        rowKey={record => record.purchase_id}
                        className="a-table--antd a-table--description a-text--color-black-65"
                        columns={columns}
                        dataSource={orders}
                        loading={loading}
                        scroll={{x: 'fit-content'}}
                        pagination={false}
                        rowSelection={rowSelection}
                        locale={{emptyText: t("message.no_data")}}
                    />
                    <PaginationFull
                        page={lodash.toInteger(pagination.page_no) || 1}
                        pageSize={lodash.toInteger(pagination.page_size)}
                        total={pagination.results_total}
                        pageSizeOptions={[20, 30, 50, 80, 100]}
                        onChange={this.onChangePage.bind(this)}
                        className="a-pagination a-pagination--fixed"
                    />
                </div>
            </div>
        );
    }
}

export default translate()(List);
