import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Form, Row, Col, Input, Button, Icon, Select, DatePicker} from 'antd';
import {orderStatus} from '../../../modules/Order/services';
import {ORDER_STATUS} from '../../../modules/Order/constants';
import FormProcessing from '../../../modules/common/components/FormProcessing';
import DateRange from '../../../modules/common/components/DateTime/DateRange';
import SelectAgencies from '../../../modules/Agency/SelectAgencies';
import SelectOrderSources from '../../../modules/Order/SelectOrderSources';
import SelectOrderStatus from '../../../modules/Order/SelectOrderStatus';
import SelectAccountPurchasers from '../../../modules/AccountPurchaser/SelectAccountPurchasers';
import SelectScanningStatus from '../../../modules/Order/SelectScanningStatus';
import lodash from "lodash";
import {ORDER_TIME_TYPE} from "../../../systems/globalContant/actionContant";
import {trans} from "../../../systems/i18n";
import moment from "moment";
import SelectTellersV2 from "../../../modules/User/SelectTellersV2";
import Super from "../../../modules/common/components/Input/Super";
import authService from "../../../modules/Auth/authService";
import * as PERMISSION from "../../../modules/Permission/permissions";

const InputGroup = Input.Group;
const {Option} = Select;

let {RangePicker} = DatePicker;

let timeOptions = lodash.values(ORDER_TIME_TYPE).map(Item => {
    return {label: trans("order:type_type." + Item), value: Item}
});

class FormSearch extends FormProcessing {
    constructor(props) {
        super(props);

        this.defaultInput = {
            code: "",
            status: "",
            id_agency: "",
            id_teller: "",
            seller_source: "",
            order_profit_from: "",
            order_profit_to: "",
            bought_at_from: '',
            bought_at_to: ''
        };

        this.state = {
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
        };
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.props.onSearch({...this.state.input, page: 1});
            this.props.setCSVFilter({...this.state.input});
        }
    }


    onReset(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.changeInput(this.defaultInput);
            this.props.onSearch(this.defaultInput);
            this.props.setCSVFilter({...this.defaultInput})
        }
    }

    onChangeNumber(value, param) {
        if (param.includes("from") || param.includes("to"))
            value = parseFloat(value);

        this.changeInput({[param]: value});
        this.props.setCSVFilter({[param]: value})
    }

    onChangeText(param, event) {
        this.onChangeInput(param, event);
        this.props.setCSVFilter({[param]: event.target.value})
    }

    onChangeSelect(value, param) {
        this.changeInput({[param]: value});
        this.props.setCSVFilter({[param]: value})
    }

    onChangeTimeRange(param1, param2, date) {
        this.changeInput({
            [param1]: date[0] ? date[0].format('YYYY-MM-DD') : '',
            [param2]: date[1] ? date[1].format('YYYY-MM-DD') : '',
        });
        this.props.setCSVFilter({
            [param1]: date[0] ? date[0].format('YYYY-MM-DD') : '',
            [param2]: date[1] ? date[1].format('YYYY-MM-DD') : '',
        })
    }

    render() {
        let {t, listStatus} = this.props;
        let {input} = this.state;

        return (
            <div className="a-list--form-search mr-0 ml-0">
                <Row gutter={32}>
                    <Form onSubmit={this.onSubmit.bind(this)} labelCol={{span: 12}}>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.order_code')} className="a-form--ant-item  mb-0">
                                <Input
                                    value={input.code || ''}
                                    placeholder={t('order:list.order_code_placeholder')}
                                    onChange={this.onChangeText.bind(this, 'code')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.agency')} className="a-form--ant-item  mb-0">
                                <SelectAgencies
                                    value={input.id_agency || ''}
                                    allOption={true}
                                    placeholder="Chọn đại lý"
                                    allowClear={true}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    onChange={id_agency => this.onChangeSelect(id_agency, 'id_agency')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.seller_source')} className="a-form--ant-item  mb-0">
                                <SelectOrderSources
                                    value={input.seller_source || ''}
                                    allOption={true}
                                    allowClear={true}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    onChange={seller_source => this.onChangeSelect(seller_source, 'seller_source')}
                                />
                            </Form.Item>
                        </Col>
                        {/*<Col lg={8} xl={6} xxl={6}>*/}
                        {/*    <Form.Item label={t('complaintSeller:list.choose_time')} className="a-form--ant-item  mb-0">*/}
                        {/*        <Select*/}
                        {/*            value={input.time_type || ""}*/}
                        {/*            placeholder={t('order:list.choose_time')}*/}
                        {/*            onChange={time_type => this.changeInput({time_type})}*/}
                        {/*        >*/}
                        {/*            <Option value="">{t('label.all')}</Option>*/}
                        {/*            {timeOptions.map(type_time => <Option key={type_time.value} value={type_time.value}>{type_time.label}</Option>)}*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {
                            authService.can(PERMISSION.PURCHASE_REPORT_MANAGE) &&
                                <Col lg={8} xl={6} xxl={6}>
                                    <Form.Item label="Giao dịch viên" className="a-form--ant-item  mb-0">
                                        <SelectTellersV2
                                            allOption
                                            value={input.id_teller}
                                            allowClear={true}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            placeholder="Chọn giao dịch viên"
                                            onChange={id_teller => this.onChangeSelect(id_teller, 'id_teller')}
                                        />
                                    </Form.Item>
                                </Col>
                        }
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.order_status')} className="a-form--ant-item  mb-0">
                                <SelectOrderStatus
                                    value={input.status || []}
                                    multiple={true}
                                    placeholder={"Chọn trạng thái đơn"}
                                    allowClear={true}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filter={status => listStatus.includes(status)}
                                    onChange={status => this.onChangeSelect(status, 'status')}
                                    listStatus={this.props.listStatus}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={'Chọn thời gian mua'} className="a-form--ant-item  mb-0">
                                <RangePicker placeholder={[t("transaction:placeholder.begin"), t("transaction:placeholder.end")]}
                                             format='DD/MM/YYYY'
                                             value={[
                                                 input['bought_at_from'] ? moment(input['bought_at_from'], 'YYYY/MM/DD') : null,
                                                 input['bought_at_to'] ? moment(input['bought_at_to'], 'YYYY/MM/DD') : null,
                                             ]}
                                             onChange={this.onChangeTimeRange.bind(this, 'bought_at_from', 'bought_at_to')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={<span>{t("order:label.profit_percent")} <span style={{ color: '#ccc'}}>(Đơn vị: CNY)</span></span>} className="a-form--ant-item  mb-0"
                            >
                                <InputGroup compact className="a-group--input">
                                    <Super
                                        negative={true}
                                        value={input.order_profit_from || ''}
                                        placeholder={"Từ"}
                                        className={'a-input--number input-first'}
                                        onChange={value => this.onChangeNumber(value, "order_profit_from")}
                                    />
                                    <Input
                                        placeholder="-"
                                        disabled
                                        className={"input-disable"}
                                    />
                                    <Super
                                        negative={true}
                                        value={input.order_profit_to || ''}
                                        placeholder={"Đến"}
                                        className={'a-input--number input-second'}
                                        onChange={value => this.onChangeNumber(value, "order_profit_to")}
                                    />
                                </InputGroup>
                            </Form.Item>
                        </Col>
                        <Col lg={24} xl={24} xxl={24} className="mt-4 pt-5 border-top-dashed">
                            <div className="a-list--action-search flex-row justify-content-end">
                                <a href="#"
                                   className="link-reset mr-5"
                                   onClick={this.onReset.bind(this)}
                                ><Icon type="sync"/>{t('btn.reset')}</a>

                                <Button
                                    htmlType="submit"
                                    className="a-text--uppercase a-btn--padding-large a-btn--primary-ant"
                                    loading={this.props.loading}
                                    disabled={!this.props.canSearch}
                                >{t('btn.search')}</Button>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </div>

        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: null,
    loading: false,
    canSearch: true,
    onSearch: (input) => {},
    listScanningStatus: [],
    filterLongTime: []
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.any,
    loading: PropTypes.bool,
    canSearch: PropTypes.bool,
    onSearch: PropTypes.func,
    listScanningStatus: PropTypes.array,
    filterLongTime: PropTypes.array
};

export default translate()(FormSearch);
