import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Icon } from "antd";
import { t } from "../../../../systems/i18n";
import apiService from "../apiService";
import { get, includes, isEmpty } from "lodash";
import ModalOrderList from "./ModalOrderList";
import notification from "../../../../systems/notification";
import { connect } from "react-redux";
import { updateTransaction } from "../actions";

const RefundAmount = (props) => {
	const { transactionId, dispatch, on } = props;
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [visibleConfirm, setVisibleConfirm] = useState(false);
	const [orderPayments, setOrderPayment] = useState([]);

	const handleRefundAmount = async () => {
		setLoading(true);
		try {
			const res = await apiService.refundAmount(transactionId, {});
			const { data } = res;

			const orderPaymentList = get(res, "data.data.select_order_payment.order_payments");

			if (!isEmpty(orderPaymentList)) {
				setOrderPayment(orderPaymentList);
				setVisible(true);
			} else {
				renderNotice(data);
			}
			setVisibleConfirm(false);
		} catch (error) {
			notification.error(t("transaction:message.create_transaction_completed_failed"));
		} finally {
			setLoading(false);
		}
	};

	const showConfirm = () => {
		setVisibleConfirm(true);
	};

	const handleHideModal = () => {
		setVisible(false);
	};

	const refetchList = (data) => {
		dispatch(updateTransaction(transactionId, data));
	};

	const renderNotice = (res) => {
		const responseData = get(res, "data");
		const codeSuccess = get(res, "code");

		const dataError = get(res, "data.response.data");
		const statusError = get(res, "data.response.status");

		if (codeSuccess === 200) {
			refetchList(responseData);
			return notification.success(t("transaction:message.create_transaction_completed_success"));
		}

		if (!statusError) {
			if (includes(dataError, "amount_over_total_paid")) {
				return notification.error(t("transaction:message.amount_over_total_paid"));
			} else {
				return notification.error(t("transaction:message.create_transaction_completed_failed"));
			}
		}
	};
    
	return (
		<>
			<Button className="a-font--12 aphz-16 a-btn--primary-ant ghost mt-2" size={"small"} onClick={showConfirm}>
				{t("transaction:btn.yctt_refunds")}
			</Button>
			{visibleConfirm && (
				<Modal
					title="Thông báo"
					visible={true}
					onCancel={() => setVisibleConfirm(false)}
					width={600}
					okText={t("btn.ok")}
					cancelText={t("btn.cancel")}
					okButtonProps={{ loading }}
					onOk={handleRefundAmount}
				>
					<p className="a-text--fz-16">
						<Icon type="exclamation-circle" theme="filled" className="mr-2" style={{ color: "#faad14" }} />
						{t("transaction:message.confirm_refund_yctt")}
					</p>
				</Modal>
			)}

			<ModalOrderList
				renderNotice={renderNotice}
				handleHideModal={handleHideModal}
				orderPayments={orderPayments}
				transactionId={transactionId}
				visible={visible}
			/>
		</>
	);
};

RefundAmount.propTypes = {
	transactionId: PropTypes.number,
};

export default connect()(RefundAmount);
