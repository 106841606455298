import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Form, Row, Col, Input, Button, Icon, Select} from 'antd';
import {orderStatus} from '../../../modules/Order/services';
import {ORDER_STATUS} from '../../../modules/Order/constants';
import FormProcessing from '../../../modules/common/components/FormProcessing';
import DateRange from '../../../modules/common/components/DateTime/DateRange';
import SelectAgencies from '../../../modules/Agency/SelectAgencies';
import SelectOrderSources from '../../../modules/Order/SelectOrderSources';
import SelectOrderStatus from '../../../modules/Order/SelectOrderStatus';
import SelectAccountPurchasers from '../../../modules/AccountPurchaser/SelectAccountPurchasers';
import SelectScanningStatus from '../../../modules/Order/SelectScanningStatus';
import lodash from "lodash";
import {ORDER_TIME_TYPE} from "../../../systems/globalContant/actionContant";
import {trans} from "../../../systems/i18n";
import moment from "moment";
import SelectTellersV2 from "../../../modules/User/SelectTellersV2";
import DateRangeCustomize from "../../../modules/common/components/DateTime/DateRangeCustomize";

let {Option} = Select;

let timeOptions = lodash.values(ORDER_TIME_TYPE).map(Item => {
    return {label: trans("order:type_type." + Item), value: Item}
});

class FormSearch extends FormProcessing {
    constructor(props) {
        super(props);

        this.defaultInput = {
            code: '',
            id_agency: '',
            seller_source: '',
            id_purchaser: undefined,
            status: [ORDER_STATUS.BOUGHT],
            'log_status_from': '',
            'log_status_to': '',
            scanning_status: '',
        };

        this.state = {
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
        };
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.props.loading && this.props.canSearch) {
            this.props.onSearch({...this.state.input, page: 1});
        }
    }

    changeDeliveryLongTime = () => {
        let {input, filterLongTime} = this.props;
        let deliveryLongTime = lodash.get(filterLongTime, 'deliveryLongTime.day') || 0;
        let days = moment().subtract(deliveryLongTime * 24, 'h').format('YYYY-MM-DD 23:59:59');
        this.props.onSearch({
            ...input,
            status: ["BOUGHT"],
            time_type: "BOUGHT",
            log_status_from: '',
            log_status_to: days
        });
    }

    changeReceivedLongTime = () => {
        let {input, filterLongTime} = this.props;
        let receivedLongTime = lodash.get(filterLongTime, 'receivedLongTime.day') || 0;
        let days = moment().subtract(receivedLongTime * 24, 'h').format('YYYY-MM-DD 23:59:59');
        this.props.onSearch({
            ...input,
            status: ["SELLER_DELIVERY"],
            time_type: "SELLER_DELIVERY",
            log_status_from: '',
            log_status_to: days
        });
    }

    onReset(event) {
        event.preventDefault();

        if (!this.props.loading && this.props.canSearch) {
            this.changeInput(this.defaultInput);
            this.props.onSearch(this.defaultInput);
        }
    }

    render() {
        let {t, listScanningStatus, filterLongTime} = this.props;
        let deliveryLongTime = lodash.get(filterLongTime, 'deliveryLongTime.day') || 0;
        let receivedLongTime = lodash.get(filterLongTime, 'receivedLongTime.day') || 0;
        let {input} = this.state;
        return (
            <div className="a-list--form-search mr-0 ml-0">
                <Row gutter={32}>
                    <Form onSubmit={this.onSubmit.bind(this)} labelCol={{span: 12}}>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.order_code')} className="a-form--ant-item  mb-0">
                                <Input
                                    value={input.code || ''}
                                    placeholder={t('order:list.order_code_placeholder')}
                                    onChange={this.onChangeInput.bind(this, 'code')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.agency')} className="a-form--ant-item  mb-0">
                                <SelectAgencies
                                    value={input.id_agency || ''}
                                    allOption={true}
                                    placeholder="Chọn đại lý"
                                    onChange={id_agency => this.changeInput({id_agency})}
                                />
                            </Form.Item>
                        </Col>
                        {/*<Col lg={8} xl={6} xxl={6}>*/}
                        {/*    <Form.Item label={t('order:label.seller_source')} className="a-form--ant-item  mb-0">*/}
                        {/*        <SelectOrderSources*/}
                        {/*            value={input.seller_source || ''}*/}
                        {/*            allOption={true}*/}
                        {/*            onChange={seller_source => this.changeInput({seller_source})}*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('complaintSeller:list.choose_time')} className="a-form--ant-item  mb-0">
                                <Select
                                    value={input.time_type || ""}
                                    placeholder={t('order:list.choose_time')}
                                    onChange={time_type => this.changeInput({time_type})}
                                >
                                    <Option value="">{t('label.all')}</Option>
                                    {timeOptions.map(type_time => <Option key={type_time.value}
                                                                          value={type_time.value}>{type_time.label}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.choose_time_period')} className="a-form--ant-item  mb-0">
                                <DateRangeCustomize
                                    from={input['log_status_from']}
                                    to={input['log_status_to']}
                                    format='DD/MM/YYYY HH:mm:ss'
                                    valueFormat='YYYY-MM-DD HH:mm:ss'
                                    showTime={{format: 'HH:mm:ss'}}
                                    onChange={(from, to) => this.changeInput({
                                        'log_status_from': from,
                                        'log_status_to': to,
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label="Giao dịch viên" className="a-form--ant-item  mb-0">
                                <SelectTellersV2
                                    allOption
                                    value={input.id_teller}
                                    allowClear={true}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    placeholder="Chọn giao dịch viên"
                                    onChange={id_teller => this.changeInput({id_teller})}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.purchaser_account')} className="a-form--ant-item  mb-0">
                                <SelectAccountPurchasers
                                    value={input.id_purchaser || ''}
                                    allOption={true}
                                    placeholder={"Chọn tài khoản mua hàng"}
                                    multiple={true}
                                    onChange={id_purchaser => this.changeInput({id_purchaser})}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.order_status')} className="a-form--ant-item  mb-0">
                                <SelectOrderStatus
                                    value={input.status || []}
                                    multiple={true}
                                    placeholder={"Chọn trạng thái đơn"}
                                    filter={status => [
                                        ORDER_STATUS.BOUGHT,
                                        ORDER_STATUS.SELLER_DELIVERY,
                                        ORDER_STATUS.RECEIVED,
                                    ].includes(status)}
                                    onChange={status => this.changeInput({status})}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.scanning_status')} className="a-form--ant-item  mb-0">
                                <SelectScanningStatus
                                    loading={this.props.loading}
                                    value={input.scanning_status || ''}
                                    placeholder={t("order:placeholder.scanning_status")}
                                    listScanningStatus={listScanningStatus}
                                    allOption={true}
                                    onChange={scanning_status => this.changeInput({scanning_status})}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{span: 24}} lg={{span: 12}} xl={{span: 12}} className="mt-4 pt-5 border-top-dashed ">
                            <Button
                                onClick={this.changeDeliveryLongTime}
                                className="a-text--uppercase a-btn--primary-ant mx-1 ghost">
                                {deliveryLongTime + ' ngày chưa phát hàng'}
                            </Button>
                            <Button
                                onClick={this.changeReceivedLongTime}
                                className="a-text--uppercase a-btn--primary-ant mx-1 ghost">
                                {receivedLongTime + ' ngày chưa nhận hàng'}
                            </Button>
                        </Col>
                        <Col xs={{span: 24}} lg={{span: 12}} xl={{span: 12}} className="mt-4 pt-5 border-top-dashed ">
                            <div className="a-list--action-search flex-row justify-content-end">
                                <a href="#"
                                   className="link-reset mr-5"
                                   onClick={this.onReset.bind(this)}
                                ><Icon type="sync"/>{t('btn.reset')}</a>

                                <Button
                                    htmlType="submit"
                                    className="a-text--uppercase a-btn--padding-large a-btn--primary-ant"
                                    loading={this.props.loading}
                                    disabled={!this.props.canSearch}
                                >{t('btn.search')}</Button>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </div>

        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: null,
    loading: false,
    canSearch: true,
    onSearch: (input) => {
    },
    listScanningStatus: [],
    filterLongTime: []
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.any,
    loading: PropTypes.bool,
    canSearch: PropTypes.bool,
    onSearch: PropTypes.func,
    listScanningStatus: PropTypes.array,
    filterLongTime: PropTypes.array
};

export default translate()(FormSearch);
