import React from "react";
import PropTypes from "prop-types";
import {Col, Icon, Row, Switch} from "antd";
import lodash from "lodash";
import {translate} from "react-i18next";

class ComplaintSellerByAgency extends React.Component {

    constructor(props) {
        super(props);
        let {complaintSellerCreate} = this.props;

        this.state = {
            agencies: lodash.split(complaintSellerCreate.value, ",")
        };
    }

    onChangeSwitch(agencyCode, value) {
        let {agencies} = this.state;
        let {complaintSellerCreate} = this.props;

        if (value)
            agencies.push(agencyCode);
        else {
            let index = agencies.indexOf(agencyCode);
            agencies.splice(index, 1);
        }

        this.setState({agencies}, () => {
            let {agencies} = this.state;
            this.props.onChange({code: complaintSellerCreate.code, value: agencies.join()});
        });
    }

    render() {
        let {t, configSetting, isUsing} = this.props;
        let {agencies} = this.state;
        let agenciesArr = lodash.get(configSetting, "agencies", []);

        return (
            <Col xs={{span: 12}} md={{span: 12}}>
                <div className="a-content--box-shadow p-0 mb-5">
                    <div className="a-setting__title p-3">
                        <p>TỰ ĐỘNG TẠO KNNB theo agency</p>
                    </div>

                    <div className="a-setting__body p-3">
                        <Row className="d-flex setting-line">
                            <Col span={18} className="a-text--bold">Agency</Col>
                            <Col span={6} className="a-text--bold a-text--right">Trạng thái</Col>
                        </Row>
                        {
                            agenciesArr.map((item, index) => {
                                return (
                                    <Row className="d-flex setting-line" key={index}>
                                        <Col span={18}><p className="a-font--13">{item.name}</p></Col>
                                        <Col span={6} className="a-text--right">
                                            <Switch
                                                checkedChildren={<Icon type="check" />}
                                                unCheckedChildren={<Icon type="close" />}
                                                checked={agencies.includes(item.code)}
                                                onChange={value => this.onChangeSwitch(item.code, value)}
                                                size="small"
                                                disabled={!isUsing}
                                            />
                                        </Col>
                                    </Row>
                                );
                            })
                        }
                    </div>
                </div>
            </Col>
        );
    }
}

ComplaintSellerByAgency.defaultProps = {
    updateConfigSystem: (params) => {},
    configSetting: {}
};

ComplaintSellerByAgency.propTypes = {
    updateConfigSystem: PropTypes.func,
    configSetting: PropTypes.object
};

export default translate()(ComplaintSellerByAgency);