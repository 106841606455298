import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Col, Container, Row} from 'react-grid-system';
import {Button, Icon, Divider, Switch} from "antd";
import {router, url} from './../../../../systems/routing/index';
import ListOrderGroup from './../components/ListOrderGroup';
import FormOrderGroup from './../components/FormOrderGroup';
import FormGroup from "./FormGroup";
import {hasPermission, permissions} from "../../../Permission";
import SettingMenu from '../../../Settings/SettingMenu';
import {setActiveSettingItem} from '../../../Settings/SettingMenu/actions';
import AgencyBox from "./AgencyBox/";
import './style.scss';

class OrderGroup extends React.Component {
    constructor(props) {
        super(props);
        this.inputFocus = new React.createRef();
        this.isFocus = false;
    }

    componentDidMount() {
        const {t} = this.props;
        this.props.setPageTitle(t('order_group.list'));
        this.props.setPageDescription(t('order_group.description_order_group'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.order_group',
                'link': url.to('orderGroup.manager')
            },
        ]);
        this.props.setMenuActive("OrderGroup");
        setActiveSettingItem('order-group');

        if (this.props.data.length === 0)
            this.props.onFetchOrderGroup();

        if (this.props.agencies.length === 0)
            this.props.onFetchAgency();
    }

    componentDidUpdate() {
        if (this.isFocus) {
            this.isFocus = false;
            this.inputFocus.focus();
        }
    }
    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        this.setState(state => ({input: {...state.input, ...input}}));
    }

    /**
     * Thực hiện reset form
     * @param event
     */
    onResetHandler(event) {
        event.preventDefault();
        this.props.onCancelFormOrderGroup();
        router.redirect(url.to('orderGroup.manager'));
        this.isFocus = true;
    }
    
    render() {
        let {dataDetail={}, agencies, data}= this.props;
        let inputVersion = lodash.get(dataDetail,'id',null)+'_'+lodash.get(dataDetail,'status',null);
        let permission = hasPermission(permissions.ORDER_GROUP_MANAGE);
        return (
            <div className="order-group">
                <Container fluid>
                    <div className="a-content__is_sidebar justify-content-start">
                        <SettingMenu/>
                        <div className="a-content__right__center width-fixed settings__body">
                            <div className="a-content__right w-100">
                                <div className="a-content--box-shadow">
                                    <Row>
                                        <Col span={12} className="d-flex align-item-center">
                                            <p className="a-text--bold a-text--color-black">DANH SÁCH NHÓM NGÀNH HÀNG ({data.length})</p>
                                        </Col>
                                        <Col span={12}>
                                            <p className="a-text--right">
                                                <FormGroup
                                                    {...this.props}
                                                    permission={permission}
                                                />
                                            </p>
                                        </Col>
                                    </Row>
                                    <Divider className="mt-3 mb-0" />
                                    {
                                        agencies.length > 0 && data.length > 0 &&
                                        <AgencyBox
                                            agency={{name: "Chung", code: "chung", alias: "CH", color: "#ccc"}}
                                            onUpdateStatusOrderGroup={this.props.onUpdateStatusOrderGroup}
                                            groups={data.filter(item => item.id_agency === 0)}
                                            permission={permission} />
                                    }

                                    {
                                        agencies.length > 0 && data.length > 0 &&
                                        agencies.map((agency, index) => {
                                            let groups = data.filter(item => agency.id === item.id_agency);
                                            return (groups.length === 0)
                                                ? null
                                                : <AgencyBox
                                                    key={index}
                                                    agency={agency}
                                                    onUpdateStatusOrderGroup={this.props.onUpdateStatusOrderGroup}
                                                    groups={groups}
                                                    permission={permission} />;
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

OrderGroup.defaultProps = {
    data: [],
    dataDetail: {},
    dataError: {},
    agencies: []
};

OrderGroup.propTypes = {
    data: PropTypes.array,
    dataDetail: PropTypes.object,
    dataError: PropTypes.object,
    agencies: PropTypes.array
};

export default translate()(OrderGroup);
