import {connect} from 'react-redux';
import lodash from 'lodash';
import BuyingSettingComponent from './index';
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from '../../../common/actions';
import {fetchConfigSystem, updateConfigSystem} from "../actions";

const mapStateToProps = (state) => {
    return {
        configSetting: lodash.get(state, 'Setting.ConfigSetting.global', {}),
        permissions: lodash.get(state, 'User.me.user.permissions', []),
        loading: lodash.get(state, 'Setting.ConfigSetting.loading', null),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        fetchConfigSystem: (menu) => {
            dispatch(fetchConfigSystem(menu))
        },
        updateConfigSystem: (params) => {
            dispatch(updateConfigSystem(params))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BuyingSettingComponent)