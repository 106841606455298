import {combineReducers} from 'redux';
import * as ACTION from './constants';
import {setCollectionItem} from '../../common/services/helps';

let productReturns = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.CREATE_PRODUCT_RETURN.REQUEST:
            return setCollectionItem(state, payload.id, {loading: true, error: null});

        case ACTION.CREATE_PRODUCT_RETURN.SUCCESS:
            return setCollectionItem(state, action.id, {loading: false});

        case ACTION.CREATE_PRODUCT_RETURN.FAILED:
            return setCollectionItem(state, action.id, {loading: false, error: payload});

        default:
            return state;
    }
};

let isLoading = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.CREATE_PRODUCT_RETURN.REQUEST:
            return true;
        case ACTION.CREATE_PRODUCT_RETURN.SUCCESS:
        case ACTION.CREATE_PRODUCT_RETURN.FAILED:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    productReturns,
    isLoading,
});
