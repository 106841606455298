import {createSelector} from 'reselect';
import lodash from 'lodash';

const listAgency = state => lodash.get(state, "Agency.list", []);
export const getListAgency = createSelector(
    listAgency,
    agencies => lodash.orderBy(agencies, ['id'], ['name']).map(agency => {
        return {
            value: agency.id,
            label: agency.name,
            className:'react-select--agency__option'
        }
    })
);

