export default {
    UNMARKED_VIOLATION: 'ORDER.UNMARKED_VIOLATION',
    MARKED_VIOLATION: 'ORDER.MARKED_VIOLATION',
    EXPORT_DATA_REQUEST: 'ORDER.EXPORT_DATA_REQUEST',
    EXPORT_DATA_SUCCESS: 'ORDER.EXPORT_DATA_SUCCESS',
    EXPORT_DATA_FAILED: 'ORDER.EXPORT_DATA_FAILED',
    RESOLVED_ORDER: 'ORDER.RESOLVED_ORDER',
    UNRESOLVED_ORDER: 'ORDER.UNRESOLVED_ORDER',
    RESET_FILTER: 'ORDER.RESET_FILTER'
};
