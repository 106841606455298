import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import lodash from 'lodash';
import * as actions from './actions';
import * as selectors from './selectors';
import Log from './components/index';

class LogContainer extends React.Component {
    constructor(props) {
        super(props);
        this.reloadCommentsInterval = null;
    }

    componentDidMount() {
        this.props.fetchLogs();
        this.props.fetchComments();

        // auto reload comments every 10s
        // if (this.props.canComment) {
        //     this.reloadCommentsInterval = setInterval(this.autoReloadComments.bind(this), 10000);
        // }
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.object !== nextProps.object
            || this.props.objectId !== nextProps.objectId
        ) {
            this.onChangeObject(nextProps);
        }
    }

    onChangeObject(props) {
        props.clearState();
        props.fetchLogs();
        props.fetchComments();
    }

    componentWillUnmount() {
        this.props.clearState();

        if (this.reloadCommentsInterval) {
            clearInterval(this.reloadCommentsInterval);
        }
    }

    autoReloadComments() {
        // Hiện tại chỉ auto reload comments trên đơn
        if (
            this.props.object === 'order'
            && !this.props.commentsLoading
            && !this.props.commentsError
        ) {
            this.props.fetchComments();
        }
    }

    render() {
        return <Log {...this.props}/>
    }
}

const mapStateToProps = (state, props) => ({
    ...state.CommonLog,
    logs: selectors.getLogs(state),
    canComment: props.canComment,
});


const mapDispatchToProps = (dispatch, props) => ({
    fetchLogs: (filter) => {
        dispatch(actions.fetchLogs(props.object, props.objectId, filter));
    },
    fetchComments: () => {
        if (props.canComment) {
            dispatch(actions.fetchComments(props.object, props.objectId));
        }
    },
    onChangeFilter: (filter) => {
        dispatch(actions.updateFilter(filter));

        if (lodash.has(filter, 'collapse')) {
            dispatch(actions.collapseAllLogs(filter.collapse));
        }
    },
    onCollapseLog: (logId, collapse) => {
        dispatch(actions.collapseLog(logId, collapse));
    },
    onComment: (input) => {
        if (props.canComment) {
            dispatch(actions.comment(props.object, props.objectId, input));
        }
    },
    clearState: () => {
        dispatch(actions.clearState());
    },
});

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(LogContainer);

Container.defaultProps = {
    object: null,
    objectId: null,
    canComment: true,
};

Container.propTypes = {
    object: PropTypes.string.isRequired,
    objectId: PropTypes.any.isRequired,
    canComment: PropTypes.bool,
};

export default Container;
