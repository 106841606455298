import React from 'react';
import moment from 'moment';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import JavascriptTimeAgo from 'javascript-time-ago';
import vi from 'javascript-time-ago/locale/vi';
import en from 'javascript-time-ago/locale/en';
import ReactTimeAgo from 'react-time-ago';
import ModalDialog, {ModalTransition} from '@atlaskit/modal-dialog';
import {DEFAULT_NUMBER_NOTICE} from "./../../../PurchaseBuying/OrderDetail/constants";

JavascriptTimeAgo.locale(vi);
JavascriptTimeAgo.locale(en);


class NoticeConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isOpenWaitConfirm: false,
            active: true,
            isToggle: false
        }
    }

    onConfirm(event) {
        let {order} = this.props;
        this.props.onConfirmCustomerOrderBuying({id: order.id});
    }

    onClose(event) {
        event.preventDefault();
        this.setState({isOpen: false});
    }

    showModal() {
        this.setState({isOpen: true})
    }

    /**
     * handle action click expand/ collapse
     */
    expandReadmore() {
        let {isOpenWaitConfirm} = this.state;
        this.setState({isOpenWaitConfirm: !isOpenWaitConfirm});
    }

    expandToggle() {
        let {isToggle} = this.state;
        this.setState({isToggle: !isToggle});
    }

    /**
     * thay dổi format để không bị lôi trên trình duyệt safari
     * @param input
     * @returns {Date}
     * @private
     */
    _parseDate(input) {
        if (typeof input === 'string') {
            let format = "yyyy-mm-dd hh:MM:ss";
            let parts = input.match(/(\d+)/g),
                i = 0, fmt = {};
            // extract date-part indexes from the format
            format.replace(/(yyyy|dd|mm|hh|MM|ss)/g, function (part) {
                fmt[part] = i++;
            });
            return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']], parts[fmt['hh']], parts[fmt['MM']], parts[fmt['ss']]);
        } else {
            return input;
        }
    }

    render() {
        let {updateQuantity, t, onClose} = this.props;
        const {isOpen, isOpenWaitConfirm, isToggle} = this.state;

        updateQuantity.map(updateQuantityItem => {
            return updateQuantityItem.messages.length;
        });

        let count = 0;
        return (
            <React.Fragment>
                <ModalTransition>
                    {isOpen && (
                        <ModalDialog
                            heading={t('orderDetail:order_detail.confirmation')}
                            onClose={onClose}
                            actions={[
                                {
                                    text: t('orderDetail:order_detail.confirmation'),
                                    onClick: this.onConfirm.bind(this)
                                },
                                {text: t('orderDetail:order_detail.cancel'), onClick: this.onClose.bind(this)}
                            ]}
                        >
                            <p>{t('orderDetail:order_detail.you_are_confirm_verify_customer')}</p>
                        </ModalDialog>
                    )}
                </ModalTransition>
                <div className="a-text--center mb-2 a-alert--purchase--list">
                    <div
                        className={"a-alert--log a-alert-warning a-text--left a-alert--log-purchase-buying a-alert--purchase--list__body "
                        + (isToggle ? 'collapsed' : '')}>
                        <div className={"a-alert-warning__icon item " + (isToggle ? '' : 'hidden')}>
                            <i className="fas fa-exclamation-triangle"/>
                        </div>

                        <div
                            className={"a-alert__body a-alert--log-purchase-buying__body a-alert--purchase--list__main "}>
                            <div
                                className={"a-alert--log-purchase-buying__body--collapse  a-alert--purchase--list__main--collapse"}>
                                <div>
                                    <i className={"fas fa-exclamation-triangle a-waring  " + (isToggle ? 'hidden' : '')}/>
                                    <span className={(isToggle ? 'hidden' : '')}>
                                                    {t('order:list.order_wait_confirm')}
                                                </span>
                                </div>
                                <i className={"fas fa-angle-double-down a-collaped a--cursor-poiter  " + (isToggle ? 'hidden' : '')}
                                   onClick={this.expandToggle.bind(this)}/>
                            </div>
                            {updateQuantity.map((updateQuantityItem, groupUser) => {
                                count++;
                                return (
                                    <React.Fragment key={count}>
                                        <span className={' ' + (isToggle ? '' : 'hidden')}>
                                            {t('orderDetail:order_detail.order_teller')}
                                            <a href="">@{updateQuantityItem.username}</a>)&nbsp;
                                            {t('orderDetail:order_detail.changed_wait_confirm_customer')}:
                                        </span>
                                        <div className={'' + (isToggle ? '' : 'hidden')}>
                                            <ul>
                                                {updateQuantityItem.messages.map((itemChange, index) => {
                                                    count++;
                                                    return (
                                                        <React.Fragment key={count + index}>
                                                            <li key={itemChange.id + index}
                                                                className={"" + (!isOpenWaitConfirm && count > DEFAULT_NUMBER_NOTICE ? 'hidden' : '')}>
                                                                <span>
                                                                -{t('orderDetail:order_detail.content_change', {
                                                                    type: t('orderDetail:order_detail.' + itemChange.type)
                                                                })
                                                                }&nbsp;
                                                                    <a>#{itemChange.id}</a>&nbsp;
                                                                    {t('orderDetail:order_detail.from')}&nbsp;
                                                                    {itemChange.old}&nbsp;
                                                                    {t('orderDetail:order_detail.to')}&nbsp;
                                                                    {itemChange.new}
                                                                </span>
                                                            </li>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </ul>
                                            {groupUser === (updateQuantity.length - 1) ? (
                                                <React.Fragment>
                                                    <div className={'a-alert--log-purchase-buying--action'}>
                                                        {count > DEFAULT_NUMBER_NOTICE ? (
                                                            <span className={'read-more a--cursor-poiter'}
                                                                  style={{display: 'block'}}
                                                                  onClick={this.expandReadmore.bind(this)}>
                                                            {!isOpenWaitConfirm && count >= DEFAULT_NUMBER_NOTICE
                                                                ? t('orderDetail:order_detail.show_more')
                                                                : t('orderDetail:order_detail.show_hide')
                                                            }
                                                </span>
                                                        ) : null}
                                                        <a className="a-alert--log-purchase-buying__submit item"
                                                        >
                                                            <span className="a--cursor-poite"
                                                                  style={{cursor: 'pointer'}}
                                                                  onClick={event => this.showModal(event)}>
                                                                {t('orderDetail:order_detail.confirmation')}&nbsp;
                                                            </span>
                                                            <span className="a-alert--log-purchase-buying--time-ago">
                                                        (
                                                        <ReactTimeAgo
                                                            tooltip={false}
                                                            locale={localStorage.getItem('i18nextLng') || 'en'}>
                                                        {new Date(this._parseDate(updateQuantityItem.timeAgo) || moment.now())}
                                                        </ReactTimeAgo>
                                                        )
                                                    </span>
                                                        </a>
                                                        <i onClick={this.expandToggle.bind(this)}
                                                           className="fas fa-angle-double-up a-collapsing a--cursor-poiter "/>
                                                    </div>
                                                </React.Fragment>) : null}
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

NoticeConfirm.defaultProps = {
    dataOrder: {}
};

NoticeConfirm.propTypes = {
    dataOrder: PropTypes.object
};

export default translate('translations')(NoticeConfirm);
