import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import PackageItem from './PackageItem';

class ListPackages extends React.Component {

    render() {
        let {t, productReturn, onRemovePackage, canUpdateIntoStatus} = this.props;
        let packages = lodash.get(productReturn, 'packages', []);
        if (!packages) {
            packages = [];
        }
        return (
            <React.Fragment>
                <table className="table a-table--complaint_solution">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{t('complaintSeller:product_return.package')}</th>
                        <th>{t('complaintSeller:product_return.status_warehouse')}</th>
                        <th>{t('complaintSeller:product_return.status')}</th>
                        <th>{''}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {

                        packages.length ? (
                                <React.Fragment>
                                    {packages.map((packageInfo, index) => {
                                        return <PackageItem
                                            isModalProcessing={this.props.isModalProcessing}
                                            key={index + 1}
                                            index={index + 1}
                                            packageInfo={packageInfo}
                                            canUpdateIntoStatus={canUpdateIntoStatus}
                                            deletingPackage={this.props.deletingPackage}
                                            onRemovePackage={onRemovePackage}
                                            onBeginRemovePackage={this.props.onBeginRemovePackage}
                                        />
                                    })}
                                </React.Fragment>
                            )
                            :
                            <tr>
                                <td colSpan="4" className="text-center">
                                    {t('complaintSeller:product_return.not_found_data')}
                                </td>
                            </tr>
                    }
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

ListPackages.defaultProps = {
    canUpdateIntoStatus: false,
    productReturn: {},
    onRemovePackage: () => {
    },
};

ListPackages.propTypes = {
    canUpdateIntoStatus: PropTypes.bool,
    productReturn: PropTypes.object,
    onRemovePackage: PropTypes.func,
};

export default translate('translations')(ListPackages);
