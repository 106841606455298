import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import Spinner from '@atlaskit/spinner';
import FormProcessing from './../../../common/components/FormProcessing';
import {Row, Col, Button, Icon} from "antd";

class ComplaintNote extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                note: ''
            },
            inputVersion: 0,
            changedParams: [],
            formData: {
                openUpdateNote: false,
            }
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (lodash.get(nextProps, "isEndProcess", false) === true) {
            nextState = {...nextState, ...{formData: {openUpdateNote: false}, input: {note: lodash.get(nextProps, 'complaint.note', '')}}}
        }

        return nextState;
    }
    
    /**
     * Cập nhật dữ liệu thay đổi của input
     * @param event
     */
    onHandleChange(event) {
        let input = this.state.input;
        let target = event.target;
        let value = target.value;
        let name = target.name;
        this.changeInput({[name]: value});
        this.updateFormData({[name]: value});
    }

    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        let formData = this.state.formData;
        formData = {...formData, ...input};
        this.setState({formData});
    }

    onSubmitForm() {
        let {input} = this.state;
        let {isLoading, onUpdateNote} = this.props;
        let note = input.note;
        note = note.toString().trim();
        input = {...input, ...{note}};
        this.changeInput({note: note});
        if (!isLoading) {
            onUpdateNote(input);
        }
    }

    /**
     * handle open update complaint note
     * @param page
     */
    onOpenUpdateNote() {
        let {formData} = this.state;
        let openUpdateNote = lodash.get(formData, 'openUpdateNote', false);
        this.updateFormData({openUpdateNote: !openUpdateNote});
        this.props.clearUpdateNote();
    }

    /**
     * handle việc đóng form lại
     */
    closeUpdateNote = () => {
        this.updateFormData({openUpdateNote: false});
        this.props.clearUpdateNote();
    };

    render() {
        let {complaint, canUpdateNote, isLoading, t} = this.props;
        let {formData, input, inputVersion} = this.state;
        let openUpdateNote = lodash.get(formData, 'openUpdateNote', false);

        return (
            <Row>
                <Col span={8} className="cs-order-row">
                    <label className="a-text--font-b label__special_note__complaint a-text--font-b">{t('complaintSeller:list.note')}:</label>
                </Col>
                <Col span={16}>
                    <div className="value__special_note__complaint " key={inputVersion}>
                        {
                            !openUpdateNote ?
                                <div className="mt-2">
                                    { complaint.note ? <p className="mb-2">{complaint.note}</p> : null }
                                    <a
                                        className="a-text--font-blue amount-required a-font--12 a--cursor-poiter"
                                        onClick={this.onOpenUpdateNote.bind(this)}
                                    >
                                        {
                                            canUpdateNote ?
                                            <>
                                                {
                                                    complaint.note ?
                                                        <>{t('complaintSeller:detail.edit_note')} <Icon type="edit" /></> :
                                                        <><Icon type="plus" />&nbsp;{t('complaintSeller:detail.add_note')}</>
                                                }
                                            </> : null
                                        }
                                    </a>
                                </div> :
                                <React.Fragment>
                                    <div className="a-form__block_input mt-3">
                                        <textarea
                                            key={inputVersion}
                                            className="a-textarea"
                                            rows="5"
                                            name="note"
                                            onChange={this.onHandleChange.bind(this)}
                                            value={input.note || ''}
                                        >{""}</textarea>
                                        <div>
                                            <Button
                                                className={"action__save "+(isLoading?'a-disable':'')}
                                                onClick={this.onSubmitForm.bind(this)}
                                            >
                                                {isLoading ? <Spinner size="small"/> : <i className="ion-checkmark-round"/>}
                                            </Button>
                                            <Button
                                                className="action__cancel ml-1"
                                                onClick={this.closeUpdateNote.bind(this)}
                                            >
                                                <i className="ion-close-round"/>
                                            </Button>
                                        </div>
                                    </div>
                                </React.Fragment>
                        }
                    </div>
                </Col>
            </Row>
        );
    }
}

ComplaintNote.defaultProps = {
    complaint: {},
    canUpdateNote: false,
    onUpdateNote: () => {
    }
};

ComplaintNote.protoType = {
    complaint: PropTypes.object,
    canUpdateNote: PropTypes.bool,
    onUpdateNote: PropTypes.func,
};

export default translate('translations')(ComplaintNote);
