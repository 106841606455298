import React from 'react';
import {connect} from 'react-redux';
import ComplaintSeller from './../ComplaintList/components';
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from './../../common/actions';
import lodash from 'lodash';
import {COMPLAINT_SELLER_STATUS} from "./../../../systems/globalContant/actionContant";
import * as Selector from './../selectors';
import {
    fetchComplaintSellerReasons,
    fetchComplaintSeller,
    openModalForm,
    closeModalForm,
    createComplaintSeller,
    clearErrorCreateComplaintSeller,
    beginProcesscreateComplaintSeller,
    rejectedComplaintSellers,
} from './../../ComplaintSeller/actions';
import SearchableComponent from "./../../common/SearchableComponent";
import {fetchUsers} from './../../User/actions';
import {getListAccountPurchaserAction} from './../../AccountPurchaser/actions'
import {getListAccountPurchaserOptions} from './../../AccountPurchaser/selectors'
import {getMeSelector} from './../../User/selectors'
import {COMPLAINT_SELLER_REJECT} from "../events";
import {withEvent} from "../../../systems/events";
import PropTypes from "prop-types";
import moment from 'moment';

const currentDay = moment('23:59:59', 'HH:mm:ss')
const threeMonthsAgo = moment('00:00:00', 'HH:mm:ss').subtract(3, 'M')

const mapStateToProps = (state) => {
    return {
        stats: Selector.getStats(state),
        complaintSellerReasons: Selector.getComplaintSellerReason(state),
        complaintSeller: Selector.getComplaintSeller(state),
        isModalProcessing: lodash.get(state, "ComplaintSeller.isModalProcessing", false),
        createErrors: lodash.get(state, "ComplaintSeller.createErrors", {}),
        isLoadingData: lodash.get(state, "ComplaintSeller.isLoadingData", {}),
        isLoading: lodash.get(state, "ComplaintSeller.isLoading", false),
        isEndProcess: lodash.get(state, "ComplaintSeller.isEndProcess", {}),
        countWaitingComplaintSeller: lodash.get(state, "Common.countWaitingComplaintSeller", 0),
        purchasers: Selector.getPurchasers(state),
        accountPurchasersOptions: getListAccountPurchaserOptions(state),
        getMeSelector: getMeSelector(state),
        pagination: lodash.get(state, "ComplaintSeller.listComplaintSeller.pagination", {
            current_page: 1,
            page_total: 1,
            per_page: 20,
            total: 0
        })
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        fetchComplaintSellerReasons: () => dispatch(fetchComplaintSellerReasons({status: 1, scope: 'basic'})),
        fetchComplaintSeller: (filters) => {
            if (!lodash.get(filters, 'status', null)) {
                filters = {...filters, ...{status: [COMPLAINT_SELLER_STATUS.WAITING]}};
            }
            dispatch(fetchComplaintSeller(filters));
        },
        fetchUsers: () => {
            dispatch(fetchUsers({pagination: 0, scope: 'basic'}));
        },
        getListAccountPurchaserAction: (filters) => {
            filters = {...filters, ...{status: "ACTIVE", scope: 'basic'}};
            dispatch(getListAccountPurchaserAction(filters))
        },
        openModalForm: () => {
            dispatch(openModalForm());
        },
        closeModalForm: () => {
            dispatch(closeModalForm());
        },
        createComplaintSeller: (complaintSellerDatas) => {
            dispatch(createComplaintSeller(complaintSellerDatas));
        },
        beginProcesscreateComplaintSeller: (data) => {
            dispatch(beginProcesscreateComplaintSeller(data));
        },
        clearErrorCreateComplaintSeller: (data) => {
            dispatch(clearErrorCreateComplaintSeller(data));
        },
        rejectedComplaintSellers: (data) => {
            dispatch(rejectedComplaintSellers(data));
        }
    };
};

class ComplaintSellerContainer extends SearchableComponent {
    constructor(props) {
        super(props);
        this.defaultFilter = {
            status: [COMPLAINT_SELLER_STATUS.WAITING],
            time_type: 'EXPIRE_TIME',
            orderBy: 'expire_time',
            sort: 'asc',
            log_status_to: currentDay.format('YYYY-MM-DD 23:59:59'),
            log_status_from: threeMonthsAgo.format('YYYY-MM-DD 00:00:00'),
        };
    }

    componentDidMount() {
        super.componentDidMount();
        let {complaintSellerReasons} = this.props;

        this.props.on({
            [COMPLAINT_SELLER_REJECT.SUCCESS]: (event, payload) => this.props.rejectedComplaintSellers(payload)
        });

        if (complaintSellerReasons.length === 0)
            this.props.fetchComplaintSellerReasons();
    }

    onChangeFilter(filter) {
        this.props.fetchComplaintSeller(filter);
    }

    onSearch(filter) {
        if (filter.renewal_count && !filter.renewal_count_operation)
            filter = {...filter, renewal_count_operation: '>'}
        this.pushFilter({
            ...filter,
            _i: parseInt(filter._i || 0, 0) + 1,
        });
    }

    getCurrentFilter() {
        return {
            status: [COMPLAINT_SELLER_STATUS.WAITING],
            time_type: 'EXPIRE_TIME',
            // code: '',
            // is_important: '',
            // order_seller_name: '',
            // product_return: '',
            // seller_source: [],
            // id_processor: [],
            // refund_status: '',
            // account_purchaser_id: [],
            // order_seller_source: [],
            // log_status_to: '',
            // log_status_from: '',
            log_status_to: currentDay.format('YYYY-MM-DD 23:59:59'),
            log_status_from: threeMonthsAgo.format('YYYY-MM-DD 00:00:00'),
            orderBy: 'expire_time',
            sort: 'asc',
            ...this.getFilterFromLocation(this.props.location),
        };
    }

    render() {
        let filter = this.getCurrentFilter();
        return <ComplaintSeller {...{
            ...this.props,
            filter: filter,
            filterVersion: parseInt(filter._i, 0),
            onSearch: this.onSearch.bind(this),
        }}/>
    }
}

ComplaintSellerContainer.defaultProps = {
    complaintSellerReasons: []
};

ComplaintSellerContainer.propTypes = {
    complaintSellerReasons: PropTypes.array
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withEvent(ComplaintSellerContainer));
