import React from 'react';
import PropTypes from 'prop-types';
import authService from '../../Auth/authService';
import settingService from './settingService';
import lodash from 'lodash';
import ListTransactionSetting from './components/ListTransactionSetting';
import { TRANSACTION_COLUMN_DISPLAY } from './constants'

class ListTransactionSettingContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            setting: [],
            page : props.page,
            options: settingService.options.filter(item => ![TRANSACTION_COLUMN_DISPLAY.SERI_NUMBER, TRANSACTION_COLUMN_DISPLAY.SERVICE_FEE].includes(item.key)),
        };
    }

    componentDidMount() {
        let setting = settingService.getUserSetting(this.getUserId(), this.getPage(), this.props.object);
        this.handleOnChange(setting);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {setting} = this.state;
        if (!lodash.isEmpty(prevProps.permission) && JSON.stringify(prevProps.permission) !== JSON.stringify(this.props.permission)) {
            const {canUseGlobal, canUseMbill} = this.props.permission;
            if (canUseGlobal || canUseMbill) {
                const options = lodash.map(settingService.options, "key")
                const getPageSetting = settingService.getUserSetting(this.getUserId(), this.getPage(), this.props.object);
                const columnsShow = [];
                getPageSetting.map(item => {
                    if (options.includes(item)) {
                        return columnsShow.push(item)
                    }
                })
                this.setState({options: settingService.options,  setting: lodash.uniq([...setting, ...columnsShow])})
            }
        }
        if (JSON.stringify(prevState.options) !== JSON.stringify(this.state.options)) {
            const options = lodash.map(this.state.options, "key")
            const newSetting = lodash.filter(setting, item => options.includes(item))
            this.props.onChange(newSetting)
        }
    }

    onChangeSetting(setting) {
        settingService.updateUserSetting(this.getUserId(), setting, this.getPage(), this.props.object);
        this.setState({setting});
        this.props.onChange(setting);
    }

    handleOnChange(setting) {
        const options = lodash.map(this.state.options, "key")
        const newSetting = lodash.filter(setting, item => options.includes(item))
        this.setState({setting: newSetting});
        this.props.onChange(newSetting);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (nextState.page !== nextProps.page) {
            nextState.page = nextProps.page;
            settingService.getOption(nextProps.page, nextProps.object);
            let setting = settingService.getPageSetting(authService.user('id'), nextProps.page, nextProps.object);
            nextProps.onChange(setting);
        }
        return nextState
    }

    getUserId() {
        return authService.user('id');
    }


    getPage() {
        return this.props.page
    }

    render() {
        return <ListTransactionSetting
            options={this.state.options}
            values={this.state.setting}
            onChange={this.onChangeSetting.bind(this)}
            object={this.props.object}
            filter={this.props.filter}
            isOpenListSetting={this.props.isOpenListSetting}
            onOpenListSetting={this.props.onOpenListSetting}
            isCurrentPage={this.props.isCurrentPage}

        />
    }
}

ListTransactionSettingContainer.defaultProps = {
    onChange: (setting) => {
    },
};

ListTransactionSettingContainer.propTypes = {
    onChange: PropTypes.func,
};

export default ListTransactionSettingContainer;
