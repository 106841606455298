import React from 'react';
import FormSearch from './components/FormSearch';
import ListFileUpload from './components/ListFileUpload';
import {Layout} from 'antd';

const {Content} = Layout;

class UploadCsvAlipayContainer extends React.Component {
    render() {
        return (
            <Layout>
                <Content className="a-content--ant-layout ">
                    <FormSearch/>
                    <ListFileUpload/>
                </Content>
            </Layout>
        )
    }
}

export default UploadCsvAlipayContainer;