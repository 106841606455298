import * as Constants from './constants';
import * as ComplaintConstants from './../constants';
import lodash from 'lodash';
import {combineReducers} from 'redux';
/**
 * state quản lý việc mở popup thay đổi trạng thái complaint seller --> thành công
 * @param oldState
 * @param action
 * @returns {boolean}
 */
const isModalProcessing = (state = false, action) => {
    switch (action.type) {
        case Constants.UPDATE_COMPLAINT_SELLER_SUCCESS.REQUEST:
        case Constants.UPDATE_COMPLAINT_SELLER_SUCCESS.FAILED:
        case Constants.UPDATE_COMPLAINT_SELLER_SUCCESS.ERROR:
        case ComplaintConstants.OPEN_MODAL_CHANGE_COMPLAINT_SUCCESS:
            return true;
        case Constants.UPDATE_COMPLAINT_SELLER_SUCCESS.SUCCESS:
        case ComplaintConstants.CLOSE_MODAL_CHANGE_COMPLAINT_SUCCESS:
            return false;
        default:
            return state;
    }
};
/**
 *
 * @param oldState
 * @param action
 * @returns {*}
 */
const updateErrors = (oldState = {}, action) => {
    const {payload} = action;
    switch (action.type) {
        case Constants.UPDATE_COMPLAINT_SELLER_SUCCESS.SUCCESS:
        case Constants.CLEAR_ERROR_COMPLAINT_SELLER_SUCCESS:
            return {};
        case Constants.UPDATE_COMPLAINT_SELLER_SUCCESS.FAILED:
            return lodash.get(payload, 'data', {});
        default:
            return oldState;
    }
};

/**
 * State quản lý việc có hệ thống khi thực hiện các thao tác xử lý tạo mới cập nhật
 * @param oldState
 * @param action
 */
const isLoadingData = (oldState = false, action) => {
    switch (action.type) {
        case Constants.UPDATE_COMPLAINT_SELLER_SUCCESS.REQUEST:
            return true;
        case Constants.UPDATE_COMPLAINT_SELLER_SUCCESS.SUCCESS:
        case Constants.UPDATE_COMPLAINT_SELLER_SUCCESS.FAILED:
        case Constants.UPDATE_COMPLAINT_SELLER_SUCCESS.ERROR:
            return false;
        default:
            return oldState;
    }
};

export default combineReducers({
    isModalProcessing,
    updateErrors,
    isLoadingData
});
