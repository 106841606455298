import {connect} from 'react-redux';
import UserAccessToken from './components/UserAccessToken';
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from './../../common/actions';
import * as actions from './actions';
import lodash from "lodash";

const mapStateToProps = (state) => {
  return {
    token: state.User.AccessToken.token,
    permissions: lodash.get(state, 'User.me.user.permissions', [])
}
};


const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        onGenerateToken: () => {
            dispatch(actions.generateToken());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAccessToken)
