import React from 'react';
import lodash from 'lodash';
import * as PropTypes from "prop-types";
import {translate} from 'react-i18next';
import onClickOutside from "react-onclickoutside";
import ModalConfirmCancelRequest from "./Modal/ModalConfirmCancelRequest";

class ProgressBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenConfirmCancelRequest: false,
            isOpen: false
        }
    }

    /**
     * Xử lý Bật tắt confirm sửa số lượng, hết hàng, sửa giá
     * @param event
     */
    onClickOpenFormEdit(type, event) {
        event.preventDefault();
        let isOpenConfirmCancelRequest = this.state.isOpenConfirmCancelRequest;
        switch (type) {
            case 'isOpenConfirmCancelRequest':
                this.setState({isOpenConfirmCancelRequest: !isOpenConfirmCancelRequest});
                break;
            default:
                return null;
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (lodash.get(nextProps, "isEndProcess.postCreateRequest", false) === true) {
            nextState.isOpenConfirmCancelRequest = false;
        }

        return nextState;
    }

    /**
     * Hàm thực hiện hủy yêu cầu tạo report
     */
    performCancelRequest() {
        let {report} = this.props;
        this.props.createCancelReports(report);
        this.setState({isOpenConfirmCancelRequest: false});
    }

    /**
     * Open khi có nhiều user
     * @param event
     */
    handleOpen(event) {
        event.preventDefault();
        this.setState({isOpen: !this.state.isOpen})
    }

    /**
     * Ẩn danh sách user liên quan
     * @param evt
     */
    handleClickOutside = evt => {
        this.setState({
            isOpen: false
        })
    };

    /**
     * handle action create new report
     * @param report
     * @param event
     */
    newRequestReport(report, event){
        this.props.newRequestReport(report);
    }

    render() {
        let {t, report}  = this.props;
        let {isOpenConfirmCancelRequest, isOpen} = this.state;
        let purchasers = lodash.get(report, 'purchasers', []);
        let purchaserFirst = lodash.head(purchasers);
        let progress = lodash.get(report, 'progress', 0);
        let purchaserses = lodash.slice(purchasers, 1, purchasers.length);
         
        return (
            <div className="progress__group">
                {
                    isOpenConfirmCancelRequest ?
                        <ModalConfirmCancelRequest title={t('sale:form.label_title_confirm')}
                                                   message={t('sale:form.label_title_confirm_cancel')}
                                                   onSubmitForm={this.performCancelRequest.bind(this)}
                                                   onClose={this.onClickOpenFormEdit.bind(this,'isOpenConfirmCancelRequest')}
                        />
                        : null
                }

                <div className="progress__group__top">
                  <div className="progress__total_person">
                      <a>
                          {lodash.get(report, 'all_purchaser', false) ?
                              (<React.Fragment>
                                  {t('sale:form.all_purchaser')}
                                  {purchasers.length > 0 ?
                                      (
                                          <React.Fragment>
                                                    <span onClick={this.handleOpen.bind(this)}>
                                                        &nbsp;(+{purchasers.length})
                                                    </span>
                                          </React.Fragment>
                                      ) : null}
                              </React.Fragment>)
                              :
                              (<React.Fragment>
                                      {lodash.get(purchaserFirst, 'username', '---')}
                                      {purchaserses.length > 0 ?
                                          (
                                              <React.Fragment>
                                                    <span onClick={this.handleOpen.bind(this)}>
                                                        &nbsp;(+{purchaserses.length})
                                                    </span>
                                              </React.Fragment>
                                          ) : null}
                                  </React.Fragment>
                              )}
                      </a>
                      <ul className={"progress__total_person__detail "+(isOpen? "":"hidden")}>
                          {
                              lodash.get(report, 'all_purchaser', false) ?
                                  (<React.Fragment>
                                      {purchasers.map(purchaserItem => (
                                          <li key={lodash.get(report,'id', '')+'_'+purchaserItem.id}>
                                              <span>{purchaserItem.username}</span>
                                          </li>)
                                      )
                                      }
                                  </React.Fragment>)
                                  :
                                  (<React.Fragment>
                                      {purchaserses.map(purchaserItem => (
                                          <li key={lodash.get(report,'id', '')+'_'+purchaserItem.id}>
                                              <span>{purchaserItem.username}</span>
                                          </li>)
                                      )
                                      }
                                  </React.Fragment>)
                          }
                      </ul>
                  </div>
                  {lodash.get(report,'status', '') === 'COMPLETED' ?
                    <span className="process__text">
                            <React.Fragment>
                                <span className="a-text--uppercase text-success text-progress-success">
                                    {t('sale:create.completed')}
                                </span>
                            </React.Fragment>
                    </span>
                    :
                    <span className="process__text percent">
                          <React.Fragment>{lodash.get(report, 'progress', 0)}%</React.Fragment>
                    </span>
                  }
                </div>
                <div className="d-flex align-items-center max-width-progress">
                    <div className="progress a-progress w-100 ">
                        <div style={{width: progress+"%"}}>
                            <div className="a-progress__bar h-100">
                                <div className="progress-bar  progress-bar-striped progress-bar-animated bg--blue  "
                                     role="progressbar"
                                     aria-valuenow="25"
                                     aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                        </div>
                    </div>
                    {lodash.get(report,'status', '') === 'COMPLETED' ?
                        null
                        :
                        (<React.Fragment>
                            <i className={"fas fa-stop-circle ml-2 icon-progress-stop "+(lodash.get(report,'id', 0)? "":"hidden")}
                               onClick={this.onClickOpenFormEdit.bind(this,'isOpenConfirmCancelRequest')}/>
                        </React.Fragment>)
                    }
                </div>
                <div className="progress__group__bottom">
                    <a>
                        {
                            report.file !== '' && lodash.get(report,'status', '') === 'COMPLETED' ?
                                (
                                    <React.Fragment>
                                        <span onClick={()=> window.open(report.file, "_blank")}>
                                            {t('sale:create.download')}
                                            <i className="fas fa-cloud-download-alt"/>
                                        </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;
                                        <span className="a-link mt-2 ml-2"
                                              onClick={this.newRequestReport.bind(this, report)}>
                                            <i className="fas fa-plus"/>&nbsp;
                                            {t('sale:create.create_new_request')}
                                        </span>
                                    </React.Fragment>
                                )
                                : null
                        }
                    </a>
                </div>
            </div>
        );
    }
}

ProgressBar.defaultProps = {
    isLoading: false,
    isEndProcess: false,
    newRequestReport: ()=>{}
};

ProgressBar.propTypes = {
    isLoading: PropTypes.bool,
    isEndProcess: PropTypes.bool,
    newRequestReport: PropTypes.func,
};

export default translate('translations')(onClickOutside(ProgressBar));
