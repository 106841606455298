import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import { t } from '../../../systems/i18n'
import { currentFormatter } from '../../../modules/common/services/format'
import lodash, { get } from 'lodash'
import apiService from '../apiService'
import notification from '../../../systems/notification'
import { isArray } from 'lodash'

const CreateOrder1688 = ({ orderId, orderItems, getPurchasingOrderDetailAction, orderPreviewResuslt, orderPreview }) => {
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)

    const handleShow = () => {
        setVisible(true)
    }
    const handleHide = () => {
        setVisible(false)
    }

    const handleCreateOrder = async () => {
        setLoading(true)
        try {
            const res = await apiService.createOrder1688(orderId)
            const code = get(res, 'data.code', false)
            const data = get(res, 'data.data', false)
            if (code === 'error') {
                notification.error(renderErrorCreateOrder(data))
            } else {
                getPurchasingOrderDetailAction({ id: orderId })
                notification.success(t('order:message.create_order_1688_success'))
            }
        } catch (error) {
            const errorKey = get(error, 'response.data.error')
            notification.error(errorKey)
        } finally {
            setLoading(false)
        }
    }

    /**
     * @param products
     * @returns {string}
     */
    const renderErrorMessageOutOfStock = (products) => {
        return "Sản phẩm " + products +" có số lượng đặt vượt quá số lượng mua cho phép, vui lòng kiểm tra và điều chỉnh số lượng.";
    }

    /**
     * @param errorMsg
     * @returns {string[]}
     */
    const getItemListFromErrorMsg = (errorMsg) => {
        let errorMsgArr  = errorMsg.split("[");
        errorMsgArr      = (errorMsgArr[1]) ? errorMsgArr[1].split("]") : [];
        let items= (errorMsgArr[0]) ? errorMsgArr[0] : '';
        return items.split(",");
    }

    /**
     * @param data
     * @returns {*|string}
     */
    const renderErrorCreateOrder = (data) => {
        const createOrderErrorArr = data.split("-");
        const createOrderErrorCode   = (createOrderErrorArr[0]) ? createOrderErrorArr[0] : '';
        const createOrderMsg    = (createOrderErrorArr[1]) ? createOrderErrorArr[1] : '';
        if(createOrderErrorCode == '500_004') { //case lỗi này do preview lỗi lên trả về lỗi của preview luôn
            return renderErrorOrderPrewview(createOrderErrorCode, createOrderMsg);
        }
        if(
            createOrderErrorCode &&
            createOrderMsg &&
            createOrderErrorCode.trim() == 'BOOKED_BEYOND_THE_MAX_QUANTITY'
        ) {
            const items   = getItemListFromErrorMsg(createOrderMsg);
            if(!items) {
                return data;
            }

            let products = "";
            for (const item of items) {
                const itemId = item.trim();
                for (const orderItem of orderItems) {
                    if(itemId == lodash.get(orderItem, 'item_id')) {
                        products += lodash.get(orderItem, 'title') + ", ";
                    }
                }
            }

            return renderErrorMessageOutOfStock(products);
        }

        return data;
    }

    /**
     * @param errorCode
     * @param errorMsg
     * @returns {string}
     */
    const renderErrorOrderPrewview = (errorCode, errorMsg) => {
        if(errorCode !== '500_004') {
            return errorCode + " - " + errorMsg;
        }

        const items= getItemListFromErrorMsg(errorMsg);
        if(!items) {
            return errorCode + " - " + errorMsg;
        }

        let products = "";
        for (let item of items) {
            item = item.trim();
            const itemObject = item.split("_");
            const itemId = (itemObject[0]) ? itemObject[0] : '';
            const specId = (itemObject[1]) ? itemObject[1] : '';

            for (const orderItem of orderItems) {
                if(
                    itemId == lodash.get(orderItem, 'item_id') &&
                    specId == lodash.get(orderItem, 'property_value')
                ) {
                    products += "#" + lodash.get(orderItem, 'partner_order_item_id') + ", ";
                }
            }
        }
        return renderErrorMessageOutOfStock(products);
    }

    const renderContent = () => {
        const errorCode = get(orderPreview , 'errorCode')
        const errorMsg  = get(orderPreview , 'errorMsg', '')

        if(errorCode){
           return renderErrorOrderPrewview(errorCode, errorMsg);
        }

        return (
            isArray(orderPreviewResuslt) &&
            orderPreviewResuslt.map((item, index) => (
                <div key={index}>
                    {index !== 0 && <hr className="mt-2 mb-2" />}
                    <div className="font-weight-bold">
                        {t('violate:list.order')} #{index + 1}
                    </div>

                    <div className="mb-1">
                        - {t('order:label.product')}:
                        <span className="font-weight-bold">{currentFormatter.toLocaleStringCurrency(get(item, 'sumPaymentNoCarriage', 0) / 100)} ¥</span>
                    </div>
                    <div className="mb-1">
                        - {t('order:label.shipping_fee')}:
                        <span className="font-weight-bold">{currentFormatter.toLocaleStringCurrency(get(item, 'sumCarriage', 0) / 100)} ¥</span>
                    </div>
                    <div className="mb-1">
                        - {t('order:label.total')}:
                        <span className="font-weight-bold">{currentFormatter.toLocaleStringCurrency(get(item, 'sumPayment', 0) / 100)} ¥</span>
                    </div>
                </div>
            ))
        )
    }

    return (
        <>
            <Button
                size={'small'}
                type="primary"
                loading={loading}
                icon="plus"
                onClick={handleShow}
                className="mr-2">
                {t('order:btn.create_order_1688')}
            </Button>


            {visible && (
                <Modal
                    width={400}
                    title={t('order:sale_report.column.grand_total_converted')}
                    visible={visible}
                    onCancel={() => setVisible(false)}
                    footer={[
                        <Button
                            key="back"
                            onClick={handleHide}>
                            {t('btn.cancel')}
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={handleCreateOrder}>
                            {t('order:btn.create_order_1688')}
                        </Button>,
                    ]}>
                    {renderContent(orderPreviewResuslt)}
                </Modal>
            )}
        </>
    )
}

export default CreateOrder1688
