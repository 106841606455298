import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Select, Form, Input, DatePicker, Button } from 'antd'
import CollapsedItem from './CollapsedItem'
import SelectPurchaseAddress from '../../../../../../modules/PurchaseAddress/component/SelectPurchaseAddress'
import SelectAgencies from '../../../../../../modules/Agency/SelectAgencies'
import { translate } from 'react-i18next'
import SelectAccountPurchasers from '../../../../../../modules/AccountPurchaser/SelectAccountPurchasers'
import SelectTeller from '../../../../../../modules/User/SelectTellers'
import lodash from 'lodash'
import { ORDER_TIME_TYPE } from '../../../../../../systems/globalContant/actionContant'
import { trans } from '../../../../../../systems/i18n'
import moment from 'moment'
import { dateFormatter } from '../../../../../../modules/common/services/format'
import ORDER_EVENTS from '../../../../../../modules/Order/events'
import SelectTellersV2 from '../../../../../../modules/User/SelectTellersV2'
import { withEvent } from '../../../../../../systems/events'
import FormProcessing from '../../../../../../modules/common/components/FormProcessing'
import SelectDistributionWarehouse from '../../../../../../modules/Order/SelectDistributionWarehouse'
import { STATUS_LIST } from '../../../../../ForeignOrder/constants'
import { hasPermission, permissions } from '../../../../../../modules/Permission'
import SelectFin from '../../../../../Order/ListOrder/components/FormSearch/SelectFin'

const { Option } = Select
const { RangePicker } = DatePicker
const InputGroup = Input.Group

let timeOptions = lodash.values(ORDER_TIME_TYPE).map(Item => {
    return { label: trans('order:type_type.' + Item), value: Item }
})

timeOptions = lodash.compact(timeOptions)

class CollapsedFormSearch extends FormProcessing {
    constructor(props) {
        super(props)
        this.state = {
            currentId: 0,
            input: {},
            inputVersion: 1,
            changedParams: [],
        }
    }

    changeCurrentId = id => {
        this.setState({ currentId: id })
    }

    componentDidMount() {
        this.setState({ input: { ...this.props.filter } })
        let resetFc = (event, payload) => this.setState({ input: { ...payload } })
        this.props.on({
            [ORDER_EVENTS.RESET_FILTER]: resetFc,
        })

        document.querySelector('body').addEventListener('click', this.onClickOutsideSearch)
    }

    onClickOutsideSearch = e => {
        let { target } = e,
            checkedTemp = false,
            checkedClassName = ['form-search-col', 'ant-select-dropdown-menu', 'ant-calendar', 'a-filter--group-collapsed']

        const positionFc = item => target.parentNode.className.indexOf(item) > -1

        while (target.parentNode && !checkedTemp) {
            if (target.parentNode.nodeName === 'BODY' || (!lodash.isEmpty(target.parentNode.className) && checkedClassName.some(positionFc))) checkedTemp = true
            target = target.parentNode
        }

        if (target.nodeName === 'BODY') this.setState({ currentId: 0 })
    }

    componentDidUpdate(prevProps, prevState) {
        let { resetFilter, defaultFilter } = this.props

        if (prevProps.resetFilter !== resetFilter && resetFilter === true) this.setState({ input: { ...defaultFilter } })
    }

    onChangeRangePicker = dates => {
        if (!lodash.isEmpty(dates))
            this.props.onSearch(
                this.changeInput({
                    log_status_from: dateFormatter.formatDate(dates[0], 'YYYY-MM-DD'),
                    log_status_to: dateFormatter.formatDate(dates[1], 'YYYY-MM-DD'),
                    page: 1,
                })
            )
        else
            this.props.onSearch(
                this.changeInput({
                    log_status_from: '',
                    log_status_to: '',
                    page: 1,
                })
            )
    }

    onSearchBlur = (param, e) => {
        let { input } = this.state
        let { filter } = this.props

        if ((input[param] === undefined || input[param] === '') && e.target.value === '' && (filter[param] === undefined || filter[param] === '')) return false

        if (filter[param] === e.target.value) return false

        this.props.onSearch({ ...input, page: 1 })
    }

    onSearchSelect = (param, value) => {
        let { filter } = this.props

        if ((value === '' || value === undefined) && (filter[param] === undefined || filter[param] === '')) return false

        let dataFilter = this.changeInput({ [param]: value, page: 1 })
        this.props.onSearch(dataFilter)
    }

    onSearchInput = (param, e) => {
        this.onChangeInput(param, e)
        if (e.target.value === '') e.target.focus()
    }

    render() {
        let { t, sellerSource, canUsingFintech } = this.props
        let { input } = this.state
        let totalItemLinkOperations = ['>', '<']

        return (
            <Form>
                <Row className="a-list--form-search m-0 p-0 border-bottom">
                    <Col span={6}>
                        <CollapsedItem
                            itemId={1}
                            currentId={this.state.currentId}
                            changeCurrentId={this.changeCurrentId}
                            active={true}
                            title="Bộ lọc khách hàng">
                            <Form.Item
                                label="Mã đơn hàng"
                                className="a-form--ant-item mb-0">
                                <Input
                                    type="text"
                                    allowClear
                                    value={input.code || undefined}
                                    placeholder={t('order:list.order_code_placeholder')}
                                    onChange={e => this.onSearchInput('code', e)}
                                    onBlur={e => this.onSearchBlur('code', e)}
                                    onPressEnter={e => this.onSearchBlur('code', e)}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Mã hóa đơn gốc"
                                className="a-form--ant-item mb-0">
                                <Input
                                    type="text"
                                    allowClear
                                    value={input.invoice_code || undefined}
                                    placeholder="Nhập mã hóa đơn gốc"
                                    onChange={e => this.onSearchInput('invoice_code', e)}
                                    onBlur={e => this.onSearchBlur('invoice_code', e)}
                                    onPressEnter={e => this.onSearchBlur('invoice_code', e)}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Mã vận đơn"
                                className="a-form--ant-item mb-0">
                                <Input
                                    type="text"
                                    allowClear
                                    value={input.freight_bill || undefined}
                                    placeholder={'Nhập mã vận đơn'}
                                    onChange={e => this.onSearchInput('freight_bill', e)}
                                    onBlur={e => this.onSearchBlur('freight_bill', e)}
                                    onPressEnter={e => this.onSearchBlur('freight_bill', e)}
                                />
                            </Form.Item>
                            {hasPermission(permissions.TAOBAO_GET_PURCHASE_ORDER) && (
                                <Form.Item
                                    label={t('order:label.order_st_status')}
                                    className="a-form--ant-item mb-0">
                                    <Select
                                        mode={'multiple'}
                                        value={input.order_st_status ? input.order_st_status : []}
                                        allowClear={true}
                                        placeholder={t('order:placeholder.order_st_status')}
                                        onChange={value => this.onSearchSelect('order_st_status', value)}>
                                        {STATUS_LIST.map(source => (
                                            <Option
                                                key={source}
                                                value={source}>
                                                {t(`order:ORDER_TAOBAO_STATUS.${source}`)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                        </CollapsedItem>
                    </Col>
                    <Col span={6}>
                        <CollapsedItem
                            itemId={2}
                            currentId={this.state.currentId}
                            changeCurrentId={this.changeCurrentId}
                            className="border-left"
                            title="Bộ lọc tài khoản">
                            <Form.Item
                                label="Địa chỉ nhận hàng TQ"
                                className="a-form--ant-item mb-0">
                                <SelectPurchaseAddress
                                    value={input.id_purchase_address}
                                    multiple={true}
                                    allOption={false}
                                    placeholder="Chọn kho nhận"
                                    onChange={value => this.onSearchSelect('id_purchase_address', value)}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t('order:label.agencies')}
                                className="a-form--ant-item mb-0">
                                <SelectAgencies
                                    value={input.id_agency}
                                    multiple={true}
                                    allOption={false}
                                    allowClear={true}
                                    placeholder={t('order:placeholder.agencies')}
                                    onChange={value => this.onSearchSelect('id_agency', value)}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t('order:list.site')}
                                className="a-form--ant-item  mb-0">
                                <Select
                                    value={input.seller_source}
                                    allowClear={true}
                                    mode="multiple"
                                    placeholder="Chọn nguồn"
                                    showSearch={true}
                                    optionFilterProp="children"
                                    onChange={value => this.onSearchSelect('seller_source', value)}>
                                    {sellerSource.map(source => (
                                        <Option
                                            key={source}
                                            value={source}>
                                            {t(`order:ORDER_SOURCE.${source}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Kho phân phối"
                                className="a-form--ant-item  mb-0">
                                <SelectDistributionWarehouse
                                    value={input.destination_warehouse}
                                    multiple={true}
                                    allOption={false}
                                    placeholder="Chọn kho phân phối"
                                    onChange={value => this.onSearchSelect('destination_warehouse', value)}
                                    className="_ordering_form_search_id_warehouse_distribution"
                                />
                            </Form.Item>

                            <Form.Item label={t('order:label.self_transport')} className="a-form--ant-item mb-0">
                                <Select
                                    value={input.is_cn_address ? input.is_cn_address : ''}
                                    allowClear={true}
                                    placeholder={t('order:placeholder.self_transport')}
                                    onChange={value => this.onSearchSelect('is_cn_address', value)}>
                                    <Option key="" value="">Tất cả</Option>
                                    <Option key="YES" value="YES">Đơn tự vận chuyển</Option>
                                    <Option key="NO" value="NO">Đơn không tự vận chuyển</Option>
                                </Select>
                            </Form.Item>
                        </CollapsedItem>
                    </Col>

                    <Col span={6}>
                        <CollapsedItem
                            itemId={3}
                            currentId={this.state.currentId}
                            changeCurrentId={this.changeCurrentId}
                            className="border-left"
                            title="Bộ lọc sản phẩm">
                            <Form.Item
                                label="Số lượng link"
                                className="a-form--ant-item  mb-0">
                                <InputGroup compact>
                                    <Select
                                        value={input.total_item_link_operation || totalItemLinkOperations[0]}
                                        allowClear={true}
                                        optionFilterProp="children"
                                        onChange={value => this.onSearchSelect('total_item_link_operation', value)}
                                        style={{ width: '65%' }}>
                                        {totalItemLinkOperations.map(operation => (
                                            <Option
                                                key={operation}
                                                value={operation}>
                                                {t('operation.' + operation)}
                                            </Option>
                                        ))}
                                    </Select>
                                    <Input
                                        type="number"
                                        min={0}
                                        max={10}
                                        style={{ width: '35%', paddingRight: '5px' }}
                                        value={input.total_item_link}
                                        onChange={e => this.onChangeInput('total_item_link', e)}
                                        onBlur={e => this.onSearchBlur('total_item_link', e)}
                                        onPressEnter={e => this.onSearchBlur('total_item_link', e)}
                                    />
                                </InputGroup>
                            </Form.Item>

                            <Form.Item
                                label={t('order:list.KNNB')}
                                className="a-form--ant-item  mb-0">
                                <Select
                                    value={input.complaint_seller ? input.complaint_seller : ''}
                                    allowClear={true}
                                    placeholder={t('order:list.KNNB')}
                                    onChange={value => this.onSearchSelect('complaint_seller', value)}>
                                    <Option value="">{t('label.all')}</Option>
                                    <Option value={'ALL'}>{t('order:list.has_complaint_seller')}</Option>
                                    <Option value={'ACTIVE'}>{t('order:list.has_complaint_seller_active')}</Option>
                                    <Option value={'NONE'}>{t('order:list.has_not_complaint_seller')}</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={t('order:label.wrong_process_search')}
                                className="a-form--ant-item  mb-0">
                                <Select
                                    value={input.wrong_process ? parseInt(input.wrong_process) : ''}
                                    allowClear={true}
                                    placeholder={t('order:label.wrong_process_search')}
                                    onChange={value => this.onSearchSelect('wrong_process', value)}>
                                    <Option value="">{t('label.all')}</Option>
                                    <Option value={0}>{t('order:label.process_ok')}</Option>
                                    <Option value={1}>{t('order:label.process_wrong')}</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label={t('order:label.batch_order')} className="a-form--ant-item  mb-0">
                                <Select
                                    value={input.is_batch_order ? input.is_batch_order : ''}
                                    allowClear={true}
                                    placeholder={t('order:label.batch_order')}
                                    onChange={value => this.onSearchSelect('is_batch_order', value)}>
                                    <Option value="">{t('label.all')}</Option>
                                    <Option value="NO">{t('order:label.is_not_batch_order')}</Option>
                                    <Option value="YES">{t('order:label.is_batch_order')}</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label={t('order:label.type_order')} className="a-form--ant-item mb-0">
                                <Select
                                    value={input.self_negotiate ? input.self_negotiate : ''}
                                    allowClear={true}
                                    placeholder={t('order:placeholder.type_order')}
                                    onChange={value => this.onSearchSelect('self_negotiate', value)}>
                                    <Option key="" value="">Tất cả</Option>
                                    <Option key="YES" value="YES">Đơn tự deal</Option>
                                    <Option key="NO" value="NO">Đơn thường</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label={t('order:detail.order_group')} className="a-form--ant-item  mb-0">
                                <Select
                                    value={input.has_order_group ? input.has_order_group : ''}
                                    placeholder={t('order:detail.order_group')}
                                    onChange={value => this.onSearchSelect('has_order_group', value)}>
                                    <Option value="">{t('label.all')}</Option>
                                    <Option value="NO">{t('order:label.not_has_order_group')}</Option>
                                    <Option value="YES">{t('order:label.has_order_group')}</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label={t('order:list.customs_info')} className="a-form--ant-item  mb-0">
                                <Select
                                    value={input.is_full_customs_info ? input.is_full_customs_info : ''}
                                    placeholder={t('order:list.customs_info')}
                                    onChange={value => this.onSearchSelect('is_full_customs_info', value)}>
                                    <Option value="">{t('label.all')}</Option>
                                    <Option value="1">{t('order:label.order_full_customs_info')}</Option>
                                    <Option value="0">{t('order:label.order_not_full_customs_info')}</Option>
                                </Select>
                            </Form.Item>

                            {canUsingFintech && (
                                <Form.Item
                                    label={t('order:label.loan_bifiin')}
                                    className="a-form--ant-item mb-0">
                                    <SelectFin
                                        onChange={value => this.onSearchSelect('fintech', value)}
                                        value={lodash.get(input, 'fintech')}
                                    />
                                </Form.Item>
                            )}
                        </CollapsedItem>
                    </Col>

                    <Col span={6}>
                        <CollapsedItem
                            itemId={4}
                            currentId={this.state.currentId}
                            changeCurrentId={this.changeCurrentId}
                            className="border-left"
                            title="Bộ lọc thời gian">
                            <Form.Item
                                label="Loại thời gian"
                                className="a-form--ant-item  mb-0">
                                <Select
                                    value={input.time_type ? input.time_type : ''}
                                    placeholder={t('order:list.choose_time')}
                                    onChange={value => this.onSearchSelect('time_type', value)}>
                                    {timeOptions.map(item => (
                                        <Option
                                            key={item.value}
                                            value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Thời gian"
                                className="a-form--ant-item  mb-0">
                                <RangePicker
                                    className="w-100"
                                    placeholder={['Bắt đầu', 'Kết thúc']}
                                    format="DD/MM/YYYY"
                                    value={
                                        input.log_status_from && input.log_status_to ? [moment(input.log_status_from), moment(input.log_status_to)] : undefined
                                    }
                                    onChange={this.onChangeRangePicker}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t('order:label.comment')}
                                className="a-form--ant-item  mb-0">
                                <Select
                                    value={input.read_comment || input.read_comment === 0 ? parseInt(input.read_comment) : ''}
                                    allowClear={true}
                                    placeholder={t('order:label.read_comment')}
                                    onChange={value => this.onSearchSelect('read_comment', value)}>
                                    <Option value="">{t('label.all')}</Option>
                                    <Option value={0}>{t('order:label.has_comment')}</Option>
                                </Select>
                            </Form.Item>
                        </CollapsedItem>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        lg={{ span: 24 }}
                        xl={{ span: 24 }}
                        className="p-3 border-top-dashed">
                        <Row>
                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 8 }}
                                xl={{ span: 8 }}>
                                <Input
                                    type="text"
                                    allowClear
                                    value={input.code || undefined}
                                    placeholder={t('order:list.order_code_placeholder')}
                                    onChange={e => this.onSearchInput('code', e)}
                                    onBlur={e => this.onSearchBlur('code', e)}
                                    onPressEnter={e => this.onSearchBlur('code', e)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default translate()(withEvent(CollapsedFormSearch))
