import React from 'react';
import lodash from 'lodash';
import {translate} from 'react-i18next';
import {Col} from "react-grid-system";
import Button from '@atlaskit/button';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatetimePicker from "./../../common/components/DatetimePicker";
import FormProcessing from './../../common/components/FormProcessing';
import Input from './../../common/components/Input/Super';
import Link from "../../../systems/routing/Link";
import {dateFormatter} from "./../../common/services/format";

class OrderInfo extends FormProcessing {

    constructor(props) {
        super(props);
        this.state = {
            input: {
                id:'',
                id_complaint_seller: '',
                id_order: '',
                invoice_code: '',
                code_order: '',
                buyer_username: '',
                receiver: '',
                phone: '',
                address: '',
                note: '',
                return_date: '',
                cost: '',
                paid_cost: '',
                shipping_bill_codes: '',
                packages: ''
            },
            inputVersion: 0,
            changedParams: [],
        };
    }

    /**
     * Cập nhật dữ liệu thay đổi của input
     * @param event
     */
    onHandleChange(event) {
        let target = event.target;
        let value = target.value;
        let name = target.name;
        this.changeInput({[name]: value});
        this.updateFormData({[name]: value});
        this.props.resetError({[name]: value});
    }

    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        this.setState(state => ({input: {...state.input, ...input}}));
    }

    /**
     * xử lý hành động thay đổi datepicker
     */

    onChangeDate(data) {
        this.changeInput(data);
        this.props.resetError(data);
    }

    /**
     * xử lý hành động keyup convert lại giá trị của amount
     * submit amount
     * @param event
     */

    onKeyUpAmount(event) {
        let amount = event.target.value === "" ?  null: parseFloat(event.target.value);
        this.changeInput({[event.target.name]: amount});
    }

    /**
     * Thực hiện render lỗi thông báo ra bên ngoài
     * @param attrName
     * @returns {null}
     */
    renderError(attrName) {
        let {errors, t} = this.props;
        let errorItem = lodash.get(errors, attrName, {});

        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('complaintSeller:product_return.error_update_product_return.' + Object.keys(errorItem)[0], {name: t('complaintSeller:product_return.' + attrName)});
                case 'CODE_USED':
                    return t('complaintSeller:product_return.error_update_product_return.' + Object.keys(errorItem)[0], {name: t('complaintSeller:product_return.' + attrName)});
                case 'max.string':
                    let objectmax = lodash.get(errorItem, 'max.string', null);
                    let overLength = lodash.get(objectmax, 'max', null);
                    return t('complaintSeller:product_return.error_update_product_return.max_string', {
                        name: t('order_group.' + attrName),
                        value: overLength
                    });
                case 'after_or_equal':
                    return t('complaintSeller:product_return.error_update_product_return.min_numeric', {
                            name: t('complaintSeller:product_return.return_date'),
                            value: dateFormatter.formatDate(moment(), "DD/MM/YYYY")
                        }
                    );
                default:
                    return null;
            }
        }
    }

    /**
     * Thuc hien luu du lieu
     * @param param
     * @param event
     */
    onSubmitForm(param, event) {
        let {updateInfoProductReturn,isLoadingData} = this.props;
        param = lodash.pick(param, [
            'id',
            'receiver',
            'phone',
            'address',
            'note',
            'return_date',
            'cost',
            'paid_cost']);
        let return_date = moment(lodash.get(param, "return_date", ""));
        return_date = return_date.isValid() ? dateFormatter.formatDate(return_date,'YYYY-MM-DD') : "";
        param = {...param, ...{return_date}};
        let isLoading = lodash.get(isLoadingData, 'postUpdateInfoProductReturn', false);

        if(!isLoading ){
            /**
             * Xử lý trim data trước khi gửi lên server
             * @type {{}}
             */
            let paramsObject = {};
            Object.keys(param).map(function (key) {
                paramsObject = {...paramsObject, ...{[key]: param[key] !== null ? (param[key]).toString().trim() : null}}
                return key;
            });
            this.setState({input: paramsObject});
            updateInfoProductReturn(paramsObject);
        }
    }

    render() {
        let {t, productReturn, isLoadingData, canUpdateIntoStatus} = this.props;
        let none_data = '---';
        let {input, inputVersion} = this.state;
        let return_date = moment(lodash.get(input, "return_date", ""));
        return_date = return_date.isValid() ? dateFormatter.standa(return_date) : "";
        input = {...input, ...{return_date}};
        let isLoadingUpdateInfoProductReturn = lodash.get(isLoadingData, 'postUpdateInfoProductReturn', false);
        
        return (
            <Col xs={12} sm={12} md={12} lg={12} xl={6} key={inputVersion}>
                <div className="a-content--box-shadow">
                    <div className="a_complaint_solution__form_group">
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <label
                                className="a_complaint_solution__label">{t('complaintSeller:product_return.order')}</label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <p className="a_complaint_solution__value ">
                                {productReturn.order
                                    ? <Link isNewTab={true}
                                            to={"purchase.buying.detail"}
                                            params={{id: productReturn.id_order}}
                                            className="a_complaint_solution__link"
                                        >{productReturn.order.code}</Link>
                                    : none_data}
                            </p>
                        </Col>
                    </div>
                    <div className="a_complaint_solution__form_group">
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <label
                                className="a_complaint_solution__label">{t('complaintSeller:product_return.account_buyer')}</label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <p className="a_complaint_solution__value">
                                <b>{productReturn.account_purchaser || none_data}</b>
                            </p>
                        </Col>
                    </div>
                    <div className="a_complaint_solution__form_group">
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <label
                                className="a_complaint_solution__label">{t('complaintSeller:product_return.invoice_codes')}</label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <p className="a_complaint_solution__value value__code_bill">
                                <b>{lodash.join(productReturn.source_invoices, ', ') || none_data}</b>
                            </p>
                        </Col>
                    </div>
                    <div className={"a_complaint_solution__form_group"}>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <label
                                className="a_complaint_solution__label">{t('complaintSeller:product_return.receiver')}
                                <span className="a-text--red">*</span>
                            </label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <input type="text" className={"input input--fullwidth input--complaint_solution "+(canUpdateIntoStatus? "":"disable")}
                                   autoComplete="off" name="receiver" value={input.receiver || ''}
                                   onChange={this.onHandleChange.bind(this)} disabled={canUpdateIntoStatus? false:true}/>
                            <div className="validatorMessage a--error active-error">{this.renderError("receiver")}</div>
                        </Col>
                    </div>
                    <div className="a_complaint_solution__form_group">
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <label className="a_complaint_solution__label">{t('complaintSeller:product_return.phone')}
                                <span className="a-text--red">*</span></label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <input type="text" className={"input input--fullwidth input--complaint_solution "+(canUpdateIntoStatus? "":"disable")}
                                   name="phone" disabled={canUpdateIntoStatus? false:true}
                                   onChange={this.onHandleChange.bind(this)} value={input.phone || ''}/>
                            <div className="validatorMessage a--error active-error">{this.renderError("phone")}</div>
                        </Col>
                    </div>
                    <div className="a_complaint_solution__form_group">
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <label className="a_complaint_solution__label">{t('complaintSeller:product_return.address')}
                                <span className="a-text--red">*</span>
                            </label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <textarea className={"a-textarea a-textarea--complaint_solution "+(canUpdateIntoStatus? "":"disable")} rows="3" name="address"
                                      onChange={this.onHandleChange.bind(this)}
                                      value={input.address || ''} disabled={canUpdateIntoStatus? false:true}>{""}</textarea>
                            <div className="validatorMessage a--error active-error">{this.renderError("address")}</div>
                        </Col>
                    </div>
                    <div className="a_complaint_solution__form_group">
                        <Col xs={12} sm={12} md={4} lg={4} className="d-flex align-items-center">
                            <label
                                className="a_complaint_solution__label">{t('complaintSeller:product_return.fee_product_return')}</label>
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3}>
                            <Input
                                negative={false}
                                className={"input input--fullwidth input--complaint_solution mt-2 "+(canUpdateIntoStatus? "":"disable")}
                                name={"cost"}
                                unit_price={true}
                                disabled={canUpdateIntoStatus? false:true}
                                value={input.cost !==''? input.cost: ''}
                                onKeyUp={this.onKeyUpAmount.bind(this)}
                                placeholder={''}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={2} lg={2} className="d-flex align-items-center">
                            <label
                                className="a_complaint_solution__label">{t('complaintSeller:product_return.paid_cost')}</label>
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3}>
                            <Input
                                negative={false}
                                className={"input input--fullwidth input--complaint_solution mt-2 "+(canUpdateIntoStatus? "":"disable")}
                                name={"paid_cost"}
                                unit_price={true}
                                disabled={canUpdateIntoStatus? false:true}
                                value={input.paid_cost !== ''? input.paid_cost : ''}
                                onKeyUp={this.onKeyUpAmount.bind(this)}
                                placeholder={''}
                            />
                        </Col>
                    </div>
                    <div className="a_complaint_solution__form_group">
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <label
                                className="a_complaint_solution__label">{t('complaintSeller:product_return.note')}</label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <textarea className={"a-textarea a-textarea--complaint_solution "+(canUpdateIntoStatus? "":"disable")} rows="3" name="note"
                                      onChange={this.onHandleChange.bind(this)}
                                      value={input.note || ''} disabled={canUpdateIntoStatus? false:true}>{""}</textarea>
                        </Col>
                    </div>
                    <div className="a_complaint_solution__form_group">
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <label
                                className="a_complaint_solution__label">{t('complaintSeller:product_return.return_date')}
                                <span className="a-text--red">*</span></label>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} className={"a-datetime--complantseller"}>
                            <DatetimePicker
                                className={canUpdateIntoStatus? false:"disable"}
                                name={'return_date'}
                                isFutureOnly={true}
                                placeholderText={t("complaintSeller:detail.renew_placeholder")}
                                date={return_date}
                                disabled={canUpdateIntoStatus? false:true}
                                onSubmit={this.onChangeDate.bind(this)}
                            />
                            <div className="validatorMessage a--error active-error">{this.renderError("return_date")}</div>
                        </Col>
                    </div>
                    <div className="a_complaint_solution__body__action">
                        <Button
                            className="a-btn a-btn--gray a-btn--complaint_solution"
                            appearance="primary"
                            isLoading={isLoadingUpdateInfoProductReturn}
                            onClick={this.onSubmitForm.bind(this, input)}
                            isDisabled={canUpdateIntoStatus? false:true}
                        >
                            <i className="fas fa-download"/>&nbsp;{t('complaintSeller:product_return.save_information')}
                        </Button>
                    </div>
                </div>
            </Col>
        );
    }
}

OrderInfo.defaultProps = {
    errors:{},
    canUpdateIntoStatus:false,
    isEndProcess:{},
    resetError: ()=>{},
};

OrderInfo.propTypes = {
    errors: PropTypes.object,
    canUpdateIntoStatus: PropTypes.bool,
    isEndProcess: PropTypes.object,
    resetError: PropTypes.func,
};

export default translate()(OrderInfo);
