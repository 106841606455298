import {createAction} from 'redux-actions';
import * as Constants from './constants';
import {fetchLogs} from '../common/Log/actions';

export const fetchProductReturnLogs = (id) => fetchLogs('product_return', id);
export const fetchProductReturn = createAction(Constants.FETCH_COMPLAINT_PRODUCT_RETURN.REQUEST, (param) => (param));
export const fetchListProductReturns = createAction(Constants.FETCH_LIST_PRODUCT_RETURNS.REQUEST, (filters) => (filters));
export const onUpdateStatus = createAction(Constants.UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.REQUEST, (status) => (status));
export const clearProductReturn = createAction(Constants.CLEAR_PRODUCT_RETURN);
export const updateInfoProductReturn = createAction(Constants.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.REQUEST, (param) => (param));
export const resetErrorUpdateInfoProductReturn = createAction(Constants.CLEAR_INFO_COMPLAINT_PRODUCT_RETURN, (params = {}) => ({params}));
