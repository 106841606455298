import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import FormProcessing from '../../../common/components/FormProcessing';
import Select from '@atlaskit/select';
import lodash from 'lodash';

class FormSearchAccountItem extends FormProcessing {
    constructor(props) {
        super(props);

        this.state = {
            input: {
                id_purchaser: '',
                seller_source: [],
                id_teller: [],
            },
            inputVersion: 0,
            changedParams: [],
        };
    }

    onBlurInput() {
        this.props.onSubmit(this.state.input);
    }

    onChangeSelect(param, select) {
        let input = this.changeInput({[param]: lodash.get(select, 'value', '')});
        this.props.onSubmit(input);
    }

    makeValueMultiSelect(select) {
        let {accountPurchase} = this.props;
        select = select.map((value) => lodash.parseInt(value));
        return accountPurchase.filter((account) => {
            return select.includes(account.value);
        });
    }

    makeValueSelect(select) {
        let {siteItems} = this.props;
        select = select.map((value) => value);
        return siteItems.filter((siteItem) => {
            return select.includes(siteItem.value);
        });
    }

    onChangeMultiSelect(param, select) {
        let value = select.map((selectItem) => {
            return selectItem.value;
        });
        let input = this.changeInput({[param]: value});
        this.props.onSubmit(input);
    }

    makeValueMultiSelectPurchaser(select) {
        let {purchasers} = this.props;
        purchasers = purchasers ? purchasers.map(purchaser => {
            let label = lodash.get(purchaser, 'label');
            label = label ? label.trim() : '';
            label = label.length > 20 ? label.substr(0, 20) + '...' : label;
            return {label: label, value: purchaser.value};
        }) : '';
        select = select ? select.map((value) => lodash.parseInt(value)): [];
        return purchasers ? purchasers.filter((purchaser) => {
            return select.includes(purchaser.value);
        }) : '';
    }

    render() {
        let {t, accountPurchase, siteItems, purchasers} = this.props;
        let {input} = this.state;

        return (
            <div className={'a-form--dropdown--atlaskit'}>
                <div className={'a-form--dropdown--atlaskit__block a-form--input--border-b mt-3 pb-4'}>
                    <div className={'a-form--dropdown--atlaskit__block__title'}>
                        <p className="a-text--font-primary">{t('order:list.account_purchase')}</p>
                    </div>
                    <Select
                        noOptionsMessage={() => t("system.no_option")}
                        value={this.makeValueMultiSelect(input.id_purchaser)}
                        placeholder={t('order:list.account_purchase_placeholder')}
                        options={accountPurchase}
                        onChange={this.onChangeMultiSelect.bind(this, 'id_purchaser')}
                        className="single-react"
                        classNamePrefix="react-select--filter_item_multi"
                        isMulti
                    />
                </div>
                <div className={'a-form--dropdown--atlaskit__block pt-3'}>
                    <div className={'a-form--dropdown--atlaskit__block__title'}>
                        <p className="a-text--font-primary">{t('order:list.site')}</p>
                    </div>
                    <Select
                        noOptionsMessage={() => t("system.no_option")}
                        isClearable={true}
                        value={this.makeValueSelect(input.seller_source)}
                        placeholder={t('order:list.site')}
                        options={siteItems}
                        onChange={this.onChangeMultiSelect.bind(this, 'seller_source')}
                        className="single-react"
                        classNamePrefix="react-select--filter_item_multi"
                        isMulti
                    />
                </div>
                <div className={'a-form--dropdown--atlaskit__block pt-3'}>
                    <div className={'a-form--dropdown--atlaskit__block__title'}>
                        <p className="a-text--font-primary">{t('order:list.id_teller')}</p>
                    </div>
                    <Select
                        style={{overflow: 'hidden !important'}}
                        noOptionsMessage={({inputValue: string}) => t('message.no_record')}
                        value={this.makeValueMultiSelectPurchaser(input.id_teller)}
                        onChange={this.onChangeMultiSelect.bind(this, 'id_teller')}
                        placeholder={t('order:list.enter_id_teller')}
                        options={purchasers}
                        className="single-react"
                        classNamePrefix="react-select--filter_item_multi"
                        isMulti
                    />
                </div>
            </div>
        );
    }
}

FormSearchAccountItem.defaultProps = {
    input: {},
    inputVersion: 0,
    onSubmit: input => {
    },
};

FormSearchAccountItem.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    onSubmit: PropTypes.func,
};

export default translate()(FormSearchAccountItem);
