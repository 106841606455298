import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import UpdateShippingBill from './components/UpdateShippingBill';
import * as actions from './actions';

const mapStateToProps = (state, props) => {
    let {productReturn} = props;
    let codes = productReturn.shipping_bill_codes || null;
    let data = lodash.get(state, 'complainProductReturn.updateShippingBill') || {};

    return {
        input: {codes: codes ? codes.split(',') : []},
        inputVersion: productReturn.id,
        loading: data.loading,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    let {productReturn} = props;

    return {
        onSubmit: ({codes}) => {
            dispatch(actions.updateShippingBill(productReturn.id, codes));
        },
    };
};

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateShippingBill);

Container.defaultProps = {
    productReturn: {},
};

Container.propTypes = {
    productReturn: PropTypes.object.isRequired,
};

export default Container;
