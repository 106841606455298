import React, { useEffect, useState } from 'react'
import { translate } from 'react-i18next'
import lodash from 'lodash'
import { Select } from 'antd'
import notification from '../../systems/notification'
import { processResponse } from '../../systems/api'
import { t } from '../../systems/i18n'
import apiService from '../Services/apiService'
const { Option, OptGroup } = Select

function SelectOrderGroupService(props) {
    const { id_agency, multiple, placeholder, className, onChange, allowClear, value } = props
    const [loading, setLoading] = useState(false)
    const [services, setServices] = useState([])

    useEffect(() => {
        if (id_agency) {
            fetchService()
        } else {
            setServices([])
        }
    }, [id_agency])
    function fetchService() {
        setLoading(true)
        processResponse(apiService.getServices({ status: 1, scope: 'basic', id_agency, getByAgencyOrPartner: true }))
            .then(res => {
                setServices(lodash.get(res, 'data', []))
                setLoading(false)
            })
            .catch(() => {
                notification.error(t('message.cant_fetch_data'))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function getValue() {
        if (!value || value.length === 0) return undefined

        if (multiple) {
            return Array.isArray(value) ? value.map(item => parseInt(item)) : [parseInt(value)]
        } else return parseInt(value)
    }

    return (
        <Select
            value={loading ? undefined : getValue()}
            mode={multiple ? 'multiple' : 'default'}
            loading={loading}
            onChange={onChange}
            placeholder={placeholder}
            showSearch={true}
            optionFilterProp="children"
            allowClear={allowClear}
            className={className}>
            {services.map(serviceGroup => (
                <OptGroup label={serviceGroup.name} key={serviceGroup.id}>
                    {serviceGroup.services.map(serviceItem => (
                        <Option
                            key={serviceItem.id}
                            value={serviceItem.id}>
                            {serviceItem.name}
                        </Option>
                    ))}
                </OptGroup>
            ))}
        </Select>
    )
}

export default translate()(SelectOrderGroupService)
