import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Form, Row, Col, Input, Button, Icon, Select, DatePicker} from 'antd';
import {orderStatus} from '../../../modules/Order/services';
import {ORDER_STATUS} from '../../../modules/Order/constants';
import FormProcessing from '../../../modules/common/components/FormProcessing';
import DateRange from '../../../modules/common/components/DateTime/DateRange';
import SelectAgencies from '../../../modules/Agency/SelectAgencies';
import SelectOrderSources from '../../../modules/Order/SelectOrderSources';
import SelectOrderStatus from '../../../modules/Order/SelectOrderStatus';
import SelectAccountPurchasers from '../../../modules/AccountPurchaser/SelectAccountPurchasers';
import SelectScanningStatus from '../../../modules/Order/SelectScanningStatus';
import lodash from "lodash";
import {ORDER_TIME_TYPE} from "../../../systems/globalContant/actionContant";
import {trans} from "../../../systems/i18n";
import moment from "moment";
import SelectTellersV2 from "../../../modules/User/SelectTellersV2";
import Super from "../../../modules/common/components/Input/Super";
import authService from "../../../modules/Auth/authService";
import * as PERMISSION from "../../../modules/Permission/permissions";

const InputGroup = Input.Group;
const {Option} = Select;

let {RangePicker} = DatePicker;

let timeOptions = lodash.values(ORDER_TIME_TYPE).map(Item => {
    return {label: trans("order:type_type." + Item), value: Item}
});

class FormSearch extends FormProcessing {
    constructor(props) {
        super(props);

        this.state = {
            input: {...props.input},
            inputVersion: 0,
            changedParams: [],
        };
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.props.loading) {
            let {input} = this.state;
            this.props.onSearch({...input, page: 1});
        }
    }

    onReset(event) {
        event.preventDefault();
        let {defaultInput} = this.props;

        if (!this.props.loading) {
            this.changeInput(defaultInput);
            this.props.onSearch(defaultInput);
        }
    }

    onChangeText(param, event) {
        this.onChangeInput(param, event);
    }

    onChangeTimeRange(param1, param2, date) {
        this.changeInput({
            [param1]: date[0] ? date[0].format('YYYY-MM-DD') : '',
            [param2]: date[1] ? date[1].format('YYYY-MM-DD') : '',
        });
    }

    render() {
        let {t, listStatus} = this.props;
        let {input} = this.state;

        return (
            <div className="a-list--form-search mr-0 ml-0">
                <Row gutter={32}>
                    <Form onSubmit={this.onSubmit.bind(this)} labelCol={{span: 12}}>
                        <Col lg={8} xl={5} xxl={5}>
                            <Form.Item label={t('order:label.code')} className="a-form--ant-item  mb-0">
                                <Input
                                    value={input.code || ''}
                                    placeholder={t('order:list.order_code_placeholder')}
                                    onChange={this.onChangeText.bind(this, 'code')}
                                    allowClear={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={'Chọn thời gian đề nghị'} className="a-form--ant-item  mb-0">
                                <RangePicker placeholder={[t("transaction:placeholder.begin"), t("transaction:placeholder.end")]}
                                             format='DD/MM/YYYY'
                                             value={[
                                                 input['created_at_from'] ? moment(input['created_at_from'], 'YYYY/MM/DD') : null,
                                                 input['created_at_to'] ? moment(input['created_at_to'], 'YYYY/MM/DD') : null,
                                             ]}
                                             onChange={this.onChangeTimeRange.bind(this, 'created_at_from', 'created_at_to')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={'Chọn thời gian xử lý'} className="a-form--ant-item  mb-0">
                                <RangePicker placeholder={[t("transaction:placeholder.begin"), t("transaction:placeholder.end")]}
                                             format='DD/MM/YYYY'
                                             value={[
                                                 input['processing_time_from'] ? moment(input['processing_time_from'], 'YYYY/MM/DD') : null,
                                                 input['processing_time_to'] ? moment(input['processing_time_to'], 'YYYY/MM/DD') : null,
                                             ]}
                                             onChange={this.onChangeTimeRange.bind(this, 'processing_time_from', 'processing_time_to')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={7} xxl={7} className="mt-2 pt-5">
                            <div className="a-list--action-search flex-row justify-content-end">
                                <a href="#"
                                   className="link-reset mr-5"
                                   onClick={this.onReset.bind(this)}
                                ><Icon type="sync"/>{t('btn.reset')}</a>

                                <Button
                                    htmlType="submit"
                                    className="a-text--uppercase a-btn--padding-large a-btn--primary-ant"
                                    loading={this.props.loading}
                                    disabled={!this.props.canSearch}
                                >{t('btn.search')}</Button>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </div>

        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: null,
    loading: false,
    canSearch: true,
    onSearch: (input) => {},
    listScanningStatus: [],
    filterLongTime: []
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.any,
    loading: PropTypes.bool,
    canSearch: PropTypes.bool,
    onSearch: PropTypes.func,
    listScanningStatus: PropTypes.array,
    filterLongTime: PropTypes.array
};

export default translate()(FormSearch);
