import React from 'react';
import {translate} from 'react-i18next';
import Button from '@atlaskit/button';
import PropTypes from 'prop-types';
import {trans} from '../../../../../systems/i18n';
import lodash from 'lodash';
import {currentFormatter} from './../../../../common/services/format';
import Input from '../../../../common/components/Input/Super';
import ModalGeneral from './../../../../common/components/ModalGeneral';

class ModalConfirmUpdateQuantitytems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            qty_bought: null,
            isLoading: false,
            errorDuplicate: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        state.isLoading = nextProps.isLoading;

        if (lodash.get(nextProps, "item.id", 0) !== prevState.id) {
            state.id = lodash.get(nextProps, "item.id", 0);
            state.qty_bought = 0;
            state.isLoading = false;
        }

        return state;
    }

    /**
     * handle action thay đổi value của các input trong form
     * @param event
     */
    onChangeInput(event) {
        let {target} = event;
        if (this.renderError(target.name) !== null) {
            this.props.clearError([target.name]);
        }
        this.setState({
            [target.name]: target.value
        });
        this.props.beginClearErrorUpdatePriceItems({[target.name]: target.value });
    }

    onKeyUpPrice(event) {
        let {target} = event;
        let unit_qty_bought = lodash.get(this.props, 'item.qty_bought', 0);
        unit_qty_bought = parseFloat(unit_qty_bought);
        let qty_bought = parseFloat(event.target.value);
        this.setState({
            qty_bought: qty_bought,
            errorDuplicate: unit_qty_bought === qty_bought
        });
        this.props.beginClearErrorUpdatePriceItems({[target.name]: qty_bought });
    }

    /**
     * handle action thay đổi value của các unit price
     * @param value
     */
    onChangePrice(value) {
        this.props.clearError(["qty_bought"]);
        this.setState({
            "qty_bought":  parseFloat(value)
        });
    }

    /**
     * clear error unit price
     */
    onClearPriceError() {
        this.props.clearError(["qty_bought"]);
    }

    /**
     * xử lý hành động submit form
     */
    onSubmitForm() {
        let {order, order_item_checked} = this.props;
        let formData = Object.assign(this.state, {});
        let submitFormData = {
            id: order.id,
            item_ids: order_item_checked,
            qty_bought: formData.qty_bought,
        };
        this.props.onSubmitForm(submitFormData);
    }

    /**
     * Enter submit
     * @param event
     */
    onKeyPress(event) {
        if (event.key === 'Enter') {
            this.onSubmitForm();
        }
    }

    /**
     * Thực hiện render lỗi thông báo ra bên ngoài
     * @param attrName
     * @returns {null}
     */
    renderError(attrName) {
        let {errorUnitPriceItems, t} = this.props;
        let errorItem = lodash.get(errorUnitPriceItems, attrName, {});
        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('orderDetail:order_detail.error.' + Object.keys(errorItem)[0], {name: t('orderDetail:order_detail.' + attrName)});
                case 'unique':
                    return t('orderDetail:order_detail.error.' + Object.keys(errorItem)[0], {name: t('orderDetail:order_detail.' + attrName)});
                case 'max.string':
                    let objectmax = lodash.get(errorItem, 'max.string', null);
                    let overLength = lodash.get(objectmax, 'max', null);
                    return t('orderDetail:order_detail.error.max_string', {
                        name: t('orderDetail:order_detail.' + attrName),
                        value: overLength
                    });
                case 'integer':
                    let objectmaxNumber = lodash.get(errorItem, 'max.numeric', null);
                    let overLengthNumber = lodash.get(objectmaxNumber, 'max', 0);
                    overLengthNumber = lodash.toInteger(overLengthNumber);
                    overLengthNumber = currentFormatter.toLocaleStringCurrency(overLengthNumber);
                    return t('orderDetail:order_detail.error.max_numeric', {
                        name: t('orderDetail:order_detail.' + attrName),
                        value: overLengthNumber
                    });
                case 'max.numeric':
                    let objectmaxNumberQuantity = lodash.get(errorItem, 'max.numeric', null);
                    let overLengthNumberQuantity = lodash.get(objectmaxNumberQuantity, 'max', 0);
                    overLengthNumberQuantity = lodash.toInteger(overLengthNumberQuantity);
                    overLengthNumberQuantity = currentFormatter.toLocaleStringCurrency(overLengthNumberQuantity);
                    return t('orderDetail:order_detail.error.max_numeric', {
                        name: t('orderDetail:order_detail.' + attrName),
                        value: overLengthNumberQuantity
                    });
                case 'min.string':
                    let objectmin = lodash.get(errorItem, 'min.string', null);
                    let length = lodash.get(objectmin, 'min', null);
                    return t('orderDetail:order_detail.error.min_string', {
                        name: t('orderDetail:order_detail.' + attrName),
                        value: length
                    });
                case "status_invalid":
                    return t('orderDetail:order_detail.error.status_invalid');
                default:
                    return null;
            }
        }
        return null;
    }

    existError(attrName){
        let {errorUnitPriceItems} = this.props;
        return lodash.has(errorUnitPriceItems,attrName);
    }

    render() {
        let {onClose, t, order_item_checked, isLoading} = this.props;
        return (
            <ModalGeneral>
                <div className={"a-modal__title"}>
                    <p>
                        {trans("orderDetail:order_detail.edit_qty_bought_items", {
                            number: <span
                                className={"a-text--font-blue a-text--bold"}>#{order_item_checked.length}</span>
                        })}
                    </p>
                </div>
                <div className={"row"}>
                    <div className={"col col-sm-12 col-md-6 col-lg-6"}>
                        <div className={'a-group'}>
                            <label className="label label--required a-label ">
                                {t("orderDetail:order_detail.qty_bought")}
                            </label>
                            <br/>
                            <Input
                                numberNotZero={true}
                                onKeyUp={this.onKeyUpPrice.bind(this)}
                                className={"input input--fullwidth mt-2 input--normal "
                                +(this.existError('qty_bought') ? "input--error" : "")}
                                name={"qty_bought"}
                                placeholder={t("orderDetail:order_detail.qty_bought")}
                                onChange={this.onClearPriceError.bind(this)}
                                onBlur={this.onChangePrice.bind(this)}
                                onKeyPress={event => this.onKeyPress(event)}
                            />
                            <span className={"a-text--red a-font--10 "+(this.existError('qty_bought')?"":"hidden")}>
                                {this.renderError("qty_bought")}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="a--group--inline-end mt-2 mb-3 a--user-group-btn">
                    <Button appearance="primary" type="submit"
                            className={"mr-3 a-btn--submit "}
                            onClick={this.onSubmitForm.bind(this)}
                            isLoading={isLoading}
                    >
                        {trans('account_purchaser.btn_action_confirm')}
                    </Button>
                    <a appearance="subtle" onClick={onClose}
                       className={(isLoading ? "hidden " : "") + "a-btn a-btn--none a-btn-link "}>
                        {trans('account_purchaser.btn_action_cancel')}
                    </a>
                </div>
            </ModalGeneral>
        );
    }
}

ModalConfirmUpdateQuantitytems.defaultProps = {
    item: {
        item_id: 0,
        qty_bought_text: '¥ 0',
        qty_bought: 0,
        partner_order_item_id: 0
    },
    isLoading: false,
    error: {}
};

ModalConfirmUpdateQuantitytems.propTypes = {
    item: PropTypes.object,
    onSubmitForm: PropTypes.func,
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.object
};

export default translate('translations')(ModalConfirmUpdateQuantitytems);
