import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Menu} from "./MenuConfig";
import {router} from './../../../systems/routing';
import lodash from 'lodash';
import PusherComponent from "../../../modules/common/components/Pusher/index";
import * as GlobalConstant from "../../../systems/globalContant/index";
import queryString from 'query-string';
import withSizes from 'react-sizes';
import {hasPermissionOrder} from "../../../modules/Permission";
import LogoutContainer from "../../logout/LogoutContainer";
import {MENU_TYPE} from "../../AtlakitComponents/MenuBar/components/MenuConfig";
import Link from "../../../systems/routing/Link";
import {getValue, settings} from "../../../modules/Settings/UserSetting/ulti";
import {Tooltip} from "antd";
import MenuGroup from "./MenuGroup";


const mapSizesToProps = ({width}) => ({
    isMobile: width < 1024,
});

class HomeMadeNavbar extends PusherComponent {

    constructor(props) {
        super(props);
        this.state = {
            list_menu: [],
            permissions: [],
            width: this.props.width,
            menu_active: "",
            countWaitingBuyOrder: 0,
            isOpen: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (this.props.countWaitingBuyOrder !== prevProps.countWaitingBuyOrder) {
            this.setState({
                countWaitingBuyOrder: this.props.countWaitingBuyOrder
            })
        }

        if (this.props.menu_active !== prevProps.menu_active) {

            this.setState({
                menu_active: this.props.menu_active
            })
        }

        if (Array.isArray(this.props.channels) && this.props.channels.length > 0) {
            this._addChannels(this.props.channels);
            if (!lodash.isEmpty(this.pusher) && this.pusher.state !== 2) {
                this.pusher.start();
            }
        }

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        let nextState = {...prevState};

        if (JSON.stringify(prevState.permissions) !== JSON.stringify(nextProps.permissions)) {
            nextState.permissions = nextProps.permissions;
            nextState.list_menu = HomeMadeNavbar.renderMenu(Menu, nextProps.permissions);
        }

        return nextState;
    }

    static renderMenu(list_menu, permissions) {
        if (typeof list_menu !== 'undefined' && list_menu !== null) {
            return list_menu.map(menu => {
                if (lodash.includes([MENU_TYPE.GROUP, MENU_TYPE.MULTI], menu.type) &&
                    (typeof menu.disabled === 'undefined' || (typeof menu.disabled !== 'undefined' && menu.disabled === false))) {
                    menu.sub_menu = menu.sub_menu.filter(subMenu => {
                        return !(subMenu.permission && !hasPermissionOrder(subMenu.permission, permissions));
                    });

                    return menu;
                }

                return null;
            }).filter(menu => menu);
        }
        return [];
    }

    /**
     * handle hành động nhận message
     * @param text
     * @param id
     * @param channel
     * @private
     */
    _pusherOnMessage(text, id, channel) {
        let dataObject = queryString.parse(text, {arrayFormat: 'bracket'});
        if (channel.startsWith(GlobalConstant.CHANNELS.ORDER_CREATE_NEW)) {
            this.props.pusherCreateNewOrder(dataObject);
        }
    }

    /**
     * handle action comeback ... not done yet
     * @returns {*}
     */
    goBackHome = (stack) => {
        router.redirect("/");
    };

    handleOpenMenu() {
        this.setState({isOpen: !this.state.isOpen});
        this.props.fireMenuStatus({isOpen: !this.state.isOpen});
    }

    closeMenu() {
        this.setState({isOpen: false});
    }

    openMenu() {
        this.setState({isOpen: true});
    }

    openNewTab(url, event) {
        event.preventDefault();
        window.open(url);
    }

    /**
     * Kiểm tra xem render lại có thay đổi channel k thì add lại
     * @param channels
     * @private
     */
    _addChannels(channels) {
        if (!lodash.isEmpty(this.pusher, true) && channels.length > 0) {
            let objCurrentChannel = this.pusher.getChannel();
            channels.map((channel) => {
                if (!objCurrentChannel[channel]) {
                    this.pusher.addChannel(channel);
                    console.info('Pusher add chancel: ', channel);
                }

                return channel;
            });

        }
    }

    render() {
        let {t, globalSetting} = this.props;
        let logoSettings = getValue(settings.UPDATE_LOGO, globalSetting);this._addChannels(this.props.channels);
        let {menu_active, isOpen, countWaitingBuyOrder, list_menu} = this.state;
        let showNumber = countWaitingBuyOrder > 10 ? "9+" : countWaitingBuyOrder;
        return (
            <div className={`a_sidebar ${isOpen ? "" : "collapsed"}`}>
                <div className="a_sidebar__fixed">
                    <div className='a_sidebar__logo'>
                        <img src={logoSettings || require('./../lALPAuoR5ZXpjaDNBcbNBgw_1548_1478.png')} alt={"avatar"}/>
                        <h2>KOMODO</h2>
                        <div className="a_sidebar__toggle" onClick={this.handleOpenMenu.bind(this)}>
                            <span></span>
                        </div>
                    </div>
                    <div className="a_sidebar__body">

                        <div className="a_sidebar__body__list">
                            <div className="a_sidebar__body__overflow">
                                <div className="a_sidebar__body__group">
                                    {
                                        list_menu.map((menu_group, index) => {
                                            return <MenuGroup
                                                key={index}
                                                t={t}
                                                showNumber={showNumber}
                                                menu_group={menu_group}
                                                isOpen={isOpen}
                                                menu_active={menu_active}
                                                closeMenu={this.closeMenu.bind(this)}
                                                openMenu={this.openMenu.bind(this)} />;
                                        })
                                    }
                                </div>
                            </div>
                            <div className="a_sidebar__body__group a-fixed-botom">
                                {/*{
                                <Tooltip content={t("theme.menu.log_bug")} position="right">
                                    <li className="a_sidebar__item not-icon-hover"
                                        onClick={this.openNewTab.bind(this, 'https://support.gobiz.vn/#/tickets/create?product=komodo&type=bug')}>
                                        <a href=""
                                           target="blank" className="a_sidebar__link">
                                            <i className="fas fa-info-circle" ></i>
                                            <span>{t("theme.menu.log_bug")}</span>
                                        </a>
                                    </li>
                                </Tooltip>}*/}

                                <Tooltip placement="right" title={t('theme.menu.help')}>
                                    <p className="a_sidebar__item not-icon-hover"
                                        onClick={this.openNewTab.bind(this, 'https://docs.google.com/document/d/1Q8GP1Hivq0_Ywnk33MxywVlsID37_NHhTXPl_1-33_E/edit')}>
                                        <a href=""
                                           target="blank"
                                           className="a_sidebar__link"
                                        >
                                            <i className="far fa-question-circle" ></i>
                                            <span>{t('theme.menu.help')}</span>
                                        </a>
                                    </p>
                                </Tooltip>
                                <LogoutContainer/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


HomeMadeNavbar.defaultProps = {
    menu_active: "",
};

HomeMadeNavbar.propTypes = {
    menu_active: PropTypes.string.isRequired,
};

export default translate('translations')(withSizes(mapSizesToProps)(HomeMadeNavbar));
