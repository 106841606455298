import {connect} from 'react-redux';
import UpdateWrongProcess from './components';
import {
    updateWrongProcessOrder,
} from './actions';
import {paymentOrder} from "../OrderDetail/actions";

const mapStateToProps = (state, props) => {
    return {
        dataOrder: props.dataOrder,
        loading: props.loading,
        canChangeQuantity:  props.canChangeQuantity
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateWrongProcessOrder: (datas) => {
            dispatch(updateWrongProcessOrder(datas));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateWrongProcess)
