import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {LOG_TYPE} from '../constants';
import ComplaintLogMessage from './LogMessages/ComplaintLogMessage';
import ProductReturnLogMessage from './LogMessages/ProductReturnLogMessage';
import OrderLogMessage from './LogMessages/OrderLogMessage';
import OrderCommentMessage from './LogMessages/OrderCommentMessage';
import ComplaintCommentMessage from "./LogMessages/ComplaintCommentMessage";
import ProductReturnCommentMessage from "./LogMessages/ProductReturnCommentMessage";
import TransactionLogMessage from "./LogMessages/TransactionLogMessage";

class LogMessage extends React.Component {
    render() {
        let {log} = this.props;

        if (lodash.startsWith(log.action, 'COMPLAINT.')) {
            return <ComplaintLogMessage log={log}/>
        }

        if (lodash.startsWith(log.action, 'PRODUCT_RETURN.')) {
            return <ProductReturnLogMessage log={log}/>
        }

        if (lodash.startsWith(log.action, 'ORDER.') ) {
            return <OrderLogMessage log={log}/>
        }

        if (lodash.startsWith(log.action, 'TRANSACTION.') ) {
            return <TransactionLogMessage log={log}/>
        }

        if (log.action === LOG_TYPE.COMMENT) {
            return <OrderCommentMessage log={log}/>
        }

        if (log.action === LOG_TYPE.COMPLAINT_COMMENT) {
            return <ComplaintCommentMessage log={log}/>
        }

        if (log.action === LOG_TYPE.PRODUCT_RETURN_COMMENT) {
            return <ProductReturnCommentMessage log={log}/>
        }

        return log.action;
    }
}

LogMessage.defaultProps = {
    log: {},
};

LogMessage.propTypes = {
    log: PropTypes.object,
};

export default translate()(LogMessage);
