import {createAction} from 'redux-actions';
import {
    FETCH_PURCHASING_ORDER_DETAIL,
    CLEAR_ERROR_WHILE_POST_COMMENT_ITEM,
    UPDATE_PAID_ORDER_DETAIL,
    UPDATE_ORDER_GROUP_ORDER,
    UPDATE_SHIPPING_FEE,
    POST_INVOICE_CODE,
    IS_BEGIN_PROCESS_POST_INVOICE_CODE,
    IS_BEGIN_PROCESS_DELETE_INVOICE_CODE,
    DELETE_INVOICE_CODE,
    PAYMENT_ORDER,
    UPDATE_ORDER_ACCOUNT_PURCHASER,
    CHANGE_MUTIPLE_QUANTITY,
    CONFIRM_CUSTOMER_ORDER_BUYING,
    PURCHASER_UPDATE_CHANGE_CLEAR,
    UPDATE_PAID_ORDER_STATUS,
    CLEAR_ERROR_WHILE_UPDATE_PRICE,
    UPDATE_ORDER_ITEM_PRICE,
    UPDATE_ORDER_SERVICE,
    APPROVAL_ORDER_SERVICE,
    CLEAR_ERROR_WHILE_POST_INVOICE_CODE,
    IS_BEGIN_PROCESS_CHANGE_PRICE,
    UPDATE_OUT_STOCK_ITEMS,
    GET_SHIPPING_FEE_HISTORY,
    IS_BEGIN_PROCESS_UPDATE_OUT_STOCK_ITEMS,
    UPDATE_PRICE_ITEMS,
    CLEAR_ORDER_DETAIL_STATE,
    IS_BEGIN_PROCESS_UPDATE_PRICE_ITEMS,
    CLEAR_IS_AFTER_SUCCESS_UPDATE_PRICE_ITEMS,
    KEYUP_CLEAR_ERROR_PROCESS_UPDATE_PRICE_ITEMS,
    UPDATE_QUANTITY_ITEMS,
    IS_BEGIN_PROCESS_OUT_OF_STOCK_ORDER,
    IS_BEGIN_PROCESS_UPDATE_QUANTITY_ITEMS,
    CHANGE_RATE_DEPOSIT,
    CLEAR_FREIGHT_BILL_DUPLICATE,
    IS_BEGIN_PROCESS_CANCEL_ORDER,
    SORT_ORDER_ITEMS,
    ADD_FREIGHT_BILL,
    DELETE_FREIGHT_BILL,
    CANCEL_ORDER,
    OUT_OF_STOCK_ORDER,
    SYNC_ORDER_BILL_NO,
    UPDATE_FRAGILE_ORDER_DETAIL,
    UPDATE_AFFILIATE_LINK_ORDER_DETAIL,
    WAITING_PAYMENT_ORDER,
    UPDATE_ITEMS_ORDER_DETAIL,
    UPDATE_ECOMMERCE_SHIPPING_ORDER_DETAIL,
    UPDATE_HSCODE_ORDER_DETAIL,
    UPDATE_ORDER_FEATURE, UPDATE_PURCHASE_ADDRESS, UPDATE_AFTER_UDPATE_SHOP_NOTE, UPDATE_TOTAL_DISCOUNT,
    SET_BATCH_ORDER
} from './constants';

export const updateOutOfStockItems = createAction(
    UPDATE_OUT_STOCK_ITEMS.REQUEST, (params) => ({params})
);

export const updatePriceItems = createAction(
    UPDATE_PRICE_ITEMS.REQUEST, (params) => ({params})
);

export const updateQuantityItems = createAction(
    UPDATE_QUANTITY_ITEMS.REQUEST, (params) => ({params})
);

export const getPurchasingOrderDetailAction = createAction(
    FETCH_PURCHASING_ORDER_DETAIL.REQUEST, (filters) => (filters)
);
export const updatePaidOrderDetail = createAction(
    UPDATE_PAID_ORDER_DETAIL.REQUEST, (params) => ({params})
);
export const updateShippingFee = createAction(
    UPDATE_SHIPPING_FEE.REQUEST, (params) => ({params})
);

export const postInvoiceCode = createAction(POST_INVOICE_CODE.REQUEST, (datas) => (datas));
export const updateInvoiceCode = createAction(POST_INVOICE_CODE.SUCCESS, (datas) => (datas));
export const getShippingFeeHistory = createAction(GET_SHIPPING_FEE_HISTORY.REQUEST, (datas) => (datas));
export const deleteInvoiceCode = createAction(DELETE_INVOICE_CODE.REQUEST, (datas) => (datas));
export const beginProcessPostInvoiceCode = createAction(IS_BEGIN_PROCESS_POST_INVOICE_CODE, () => ({}));
export const beginProcessDeleteInvoiceCode = createAction(IS_BEGIN_PROCESS_DELETE_INVOICE_CODE, () => ({}));
export const beginProcessUpdateOutStock = createAction(IS_BEGIN_PROCESS_UPDATE_OUT_STOCK_ITEMS, () => ({}));
export const beginProcessUpdatePriceItems = createAction(IS_BEGIN_PROCESS_UPDATE_PRICE_ITEMS, () => ({}));
export const beginProcessCancelOrder = createAction(IS_BEGIN_PROCESS_CANCEL_ORDER, () => ({}));
export const beginProcessOutOfStock = createAction(IS_BEGIN_PROCESS_OUT_OF_STOCK_ORDER, () => ({}));
export const beginProcessUpdateQuantityItems = createAction(IS_BEGIN_PROCESS_UPDATE_QUANTITY_ITEMS, () => ({}));
export const beginClearSuccessUpdatePriceItems = createAction(CLEAR_IS_AFTER_SUCCESS_UPDATE_PRICE_ITEMS, () => ({}));
export const beginClearErrorUpdatePriceItems = createAction(KEYUP_CLEAR_ERROR_PROCESS_UPDATE_PRICE_ITEMS, (data) => ({data}));
export const beginProcessChangePrice = createAction(IS_BEGIN_PROCESS_CHANGE_PRICE, () => ({}));
export const clearErrorWhileUpdatePrice = createAction(CLEAR_ERROR_WHILE_UPDATE_PRICE, (param) => ({param}));
export const clearErrorWhilePostInvoiceCode = createAction(CLEAR_ERROR_WHILE_POST_INVOICE_CODE, () => ({}));
export const clearErrorWhilePostCommentItem = createAction(CLEAR_ERROR_WHILE_POST_COMMENT_ITEM, (idItem) => ({idItem}));
export const clearFreightBillDuplicate = createAction(CLEAR_FREIGHT_BILL_DUPLICATE, (id_order) => ({id_order}));
export const paymentOrder = createAction(PAYMENT_ORDER.REQUEST, (params) => (params));
export const cancelOrder = createAction(CANCEL_ORDER.REQUEST, (orderId) => (orderId));
export const outOfStockOrder = createAction(OUT_OF_STOCK_ORDER.REQUEST, (orderId) => (orderId));
export const cancelPaymentOrder = createAction(PAYMENT_ORDER.CANCEL);
export const updateOrderGroupOrder = createAction(UPDATE_ORDER_GROUP_ORDER.REQUEST, (filters) => (filters));
export const updateOrderAccountPurchaser = createAction(
    UPDATE_ORDER_ACCOUNT_PURCHASER.REQUEST, (params) => ({params})
);

export const updatePaidOrderStatus = createAction(UPDATE_PAID_ORDER_STATUS.REQUEST, (params) => (params));

export const changeMutipleQuantity = createAction(
    CHANGE_MUTIPLE_QUANTITY.REQUEST, (params) => ({params})
);
export const confirmCustomerOrderBuying = createAction(
    CONFIRM_CUSTOMER_ORDER_BUYING.REQUEST, (params) => ({params})
);

export const clearPurchaserBuyingChangeDataState = createAction(
    PURCHASER_UPDATE_CHANGE_CLEAR, (params) => ({params})
);

export const updateOrderItemPriceAction = createAction(
    UPDATE_ORDER_ITEM_PRICE.REQUEST, (params) => ({params})
);

/**
 * Update order service action
 */
export const updateOrderServiceAction = createAction(
    UPDATE_ORDER_SERVICE.REQUEST, (params) => ({params})
);

/**
 * Approval order service action
 */
export const approvalOrderServiceAction = createAction(
    APPROVAL_ORDER_SERVICE.REQUEST, (params) => ({params})
);

export const sortOrderItems = createAction(SORT_ORDER_ITEMS, (direction) => ({direction}));

/**
 * Handle freight bill
 */
export const addFreightBill = createAction(ADD_FREIGHT_BILL.REQUEST, (data) => (data));
export const deleteFreightBill = createAction(DELETE_FREIGHT_BILL.REQUEST, (data) => (data));

/**
 * Change order rate deposit
 */
export const changeRateDeposit = createAction(CHANGE_RATE_DEPOSIT.REQUEST, (data) => (data));
/**
 * Sync order bill no
 */
export const syncOrderBillNo = createAction(SYNC_ORDER_BILL_NO.REQUEST, (params) => (params));

/**
 * khi unmount orderDetail component thì phải xoá toàn bộ state liên quan đến trang order detail đi
 */
export const clearOrderDetailState = createAction(CLEAR_ORDER_DETAIL_STATE, () => ({}));

// Khi cập nhật lại thuộc tính orderDetail.fragile sau khi đánh dấu đơn là Hàng dễ vỡ
export const updateFragileOrderDetail = createAction(UPDATE_FRAGILE_ORDER_DETAIL, (params) => (params));

// Khi cập nhật lại link affiliate
export const updateAffiliateLink = createAction(UPDATE_AFFILIATE_LINK_ORDER_DETAIL, (params) => (params));

//Khi change waiting payment
export const waitingPaymentOrder = createAction(WAITING_PAYMENT_ORDER.REQUEST, (params) => (params));

export const cancelWaitingPaymentOrder = createAction(WAITING_PAYMENT_ORDER.CANCEL);

export const updateItemsOrderDetail = createAction(UPDATE_ITEMS_ORDER_DETAIL, (data) => (data));

export const updateEcommerceShippingOrderDetail = createAction(UPDATE_ECOMMERCE_SHIPPING_ORDER_DETAIL, (params) => (params));

export const updateHSCodeOrderDetail = createAction(UPDATE_HSCODE_ORDER_DETAIL, (params) => (params));

export const updateOrderFeatureAction = createAction(
    UPDATE_ORDER_FEATURE.REQUEST, (params) => ({params})
);

export const updatePurchaseAddress = createAction(
    UPDATE_PURCHASE_ADDRESS, (params) => ({...params})
);

export const updateSellerNote = createAction(
    UPDATE_AFTER_UDPATE_SHOP_NOTE, (data) => (data)
);

export const updateTotalDiscount = createAction(
    UPDATE_TOTAL_DISCOUNT.REQUEST, (data) => ({data})
);

export const setBatchOrder = createAction(SET_BATCH_ORDER.REQUEST, (params) => (params));