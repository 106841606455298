import {takeEvery} from 'redux-saga/effects';
import * as ACTION from './constants';
import * as saga from './saga';

export default function*() {
    yield takeEvery(ACTION.ASSIGN_PURCHASER_ORDERS.BEGIN, saga.assignPurchaserNextOrder);
    yield takeEvery(ACTION.ASSIGN_PURCHASER_ORDERS.REQUEST, saga.assignPurchaserOrders);
    yield takeEvery(ACTION.ASSIGN_PURCHASER_ORDERS.FAILED, saga.onAssignPurchaserOrdersFailed);
    yield takeEvery(ACTION.ASSIGN_PURCHASER.REQUEST, saga.assignPurchaser);
    yield takeEvery(ACTION.ASSIGN_PURCHASER.SUCCESS, saga.onAssignPurchaserSuccess);
    yield takeEvery(ACTION.ASSIGN_PURCHASER.FAILED, saga.onAssignPurchaserFailed);
    yield takeEvery(ACTION.UNASSIGN_PURCHASER.REQUEST, saga.unassignPurchaser);
    yield takeEvery(ACTION.UNASSIGN_PURCHASER.SUCCESS, saga.onUnassignPurchaserSuccess);
    yield takeEvery(ACTION.UNASSIGN_PURCHASER.FAILED, saga.onUnassignPurchaserFailed);
}