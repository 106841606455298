import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Icon, Modal, Input, Button} from 'antd';
import {processResponse} from '../../../systems/api';
import {events} from '../../../systems/events';
import notification from '../../../systems/notification';
import ORDER_EVENT from '../events';
import apiService from '../apiService';
import authService from '../../Auth/authService';
import * as PERMISSION from '../../Permission/permissions';
import lodash from "lodash";
import {dateFormatter} from "../../common/services/format";

class MarkViolationOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            openModal: false,
            reason: '',
        };
    }

    openModal() {
        this.setState({openModal: true});
    }

    onUnmarkViolation() {
        if (this.state.loading) {
            return;
        }

        let {order, t} = this.props;

        this.setState({loading: true});

        let res = apiService.unmarkViolationOrder(order.id, {note: this.state.reason})
            .finally(() => this.setState({loading: false, openModal: false}));

        let process = processResponse(res);

        process.then(res => {
            notification.success(t('order:message.unmark_violation_order_success'));

            events.dispatch(ORDER_EVENT.UNMARKED, {
                order: {...order, ...(res.data || {})},
            });

        });

        process.catch(() => {
            notification.error(t('order:message.unmark_violation_order_failed'));
        });
    }

    onCancel() {
        if (!this.state.loading) {
            this.setState({openModal: false});
        }
    }

    render() {
        let {t, order} = this.props;
        return (
            <div className={"description-trade"}>
                    <span className="a-font--12 a-text--gray-new a-text--bold mr-2 ">
                        <Icon type="file-text" className="mr-2 "/>
                        {t('order:label.violation')}
                    </span>
                    <span className=" a-font--12 a-text--gray-new">
                            {t('transaction:label.resolve_reason')}: {order.orderDetail.violation_note} | {t('transaction:label.resolver')}: {lodash.get(order, 'orderDetail.violationCreator.name')} | {dateFormatter.dateTime(order.orderDetail.violation_time)}
                    </span>

            </div>
        );
    }
}

MarkViolationOrder.defaultProps = {
    order: {},
};

MarkViolationOrder.propTypes = {
    order: PropTypes.object,
};

export default translate()(MarkViolationOrder);
