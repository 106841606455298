import React from 'react';
import {translate} from 'react-i18next';
import Tooltip from '@atlaskit/tooltip';

class ServicesItem extends React.Component {

    render() {
        let {t, services} = this.props;
        return (
            <div className="a-item__bottom__sale ">
                <p>{t("order:list.service")}</p>
                {services.map((service) => {
                    return <Tooltip content={service.name} mousePosition="top" key={service.id}>
                        <i
                            className={`${service.icon ? service.icon : "ion-android-globe"} ml-2`}/>
                    </Tooltip>;
                })}

            </div>
        )
            ;
    }
}

ServicesItem.defaultProps = {
    services: [],
}
;

export default translate('translations')(ServicesItem);