import React from 'react';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import ModalConfirm from './../../../../common/components/ModalConfirm';
import ModalConfirmUpdatePriceItems from './../MoreInfoComponents/ModalConfirmUpdatePriceItems';
import ModalConfirmUpdateQuantitytems from './../MoreInfoComponents/ModalConfirmUpdateQuantitytems';
import PropTypes from 'prop-types';
import {Checkbox} from "antd";
import ActionList from "./ActionList";
import ActionExtend from "./ActionExtend";

class QuickSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenConfirmOutOfStock: false,
            isOpenConfirmUpdatePrice: false,
            isOpenConfirmUpdateQuantityItems: false,
            isOpen: false,
        }
    }

    /**
     * Xử lý Bật tắt confirm sửa số lượng, hết hàng, sửa giá
     * @param event
     */
    onClickOpenFormEdit(type, event) {
        event.preventDefault();
        let isOpenConfirmOutOfStock = this.state.isOpenConfirmOutOfStock;
        let isOpenConfirmUpdatePrice = this.state.isOpenConfirmUpdatePrice;
        let isOpenConfirmUpdateQuantityItems = this.state.isOpenConfirmUpdateQuantityItems;
        switch (type) {
            case 'isOpenConfirmOutOfStock':
                this.props.beginProcessUpdateOutStock();
                this.setState({isOpenConfirmOutOfStock: !isOpenConfirmOutOfStock});
                if( isOpenConfirmOutOfStock ){
                    this.props.onClearCheckAll();
                }
                break;
            case 'isOpenConfirmUpdatePrice':
                this.props.beginProcessUpdatePriceItems();
                this.setState({isOpenConfirmUpdatePrice: !isOpenConfirmUpdatePrice});
                if( isOpenConfirmUpdatePrice ){
                    this.props.onClearCheckAll();
                }
                break;
            case 'isOpenConfirmUpdateQuantityItems':
                this.props.beginProcessUpdateQuantityItems();
                this.setState({isOpenConfirmUpdateQuantityItems: !isOpenConfirmUpdateQuantityItems});
                if( isOpenConfirmUpdateQuantityItems ){
                    this.props.onClearCheckAll();
                }
                break;
            default:
                return null;
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (lodash.get(nextProps, "isEndProcess.postUpdateOutStock", false) === true) {
            nextState.isOpenConfirmOutOfStock = false;
        }
        if (lodash.get(nextProps, "isEndProcess.postUpdatePriceItems", false) === true) {
            nextState.isOpenConfirmUpdatePrice = false;
        }
        if (lodash.get(nextProps, "isEndProcess.postUpdateQuantityItems", false) === true) {
            nextState.isOpenConfirmUpdateQuantityItems = false;
        }

        if (lodash.get(nextProps, "isEndProcess.postUpdateQuantityItems", false) === true) {
            nextState.isOpenConfirmUpdateQuantityItems = false;
        }

        return nextState;
    }

    /**
     * Xử lý hết hàng nhiều sản phẩm
     */
    updateOutOfStockItems(){
        let {order, order_item_checked} = this.props;
        this.props.updateOutOfStockItems({id: order.id,item_ids:order_item_checked});
    }

    /**
     * Xử lý cập nhật giá nhiều sản phẩm
     */
    updatePriceItems(dataForm) {
        this.props.updatePriceItems(dataForm);
    }

    /**
     * Xử lý cập nhật số lượng nhiều sản phẩm
     */
    updateQuantityItems(dataForm){
        this.props.updateQuantityItems(dataForm);
    }

    /**
     * Xử lý hành động open/close dropdown
     */
    handleOpenDropdown() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        let {
            t, order, defaultChecked, items,
            order_item_checked, check_all,
            isLoading, isEndProcess,
            errorUnitPriceItems, onCheckAll,
            isLoadingCancelOrder,
            beginProcessCancelOrder,
            isLoadingOutOfStockOrder,
            beginProcessOutOfStock,
            onCancel,
            onOutOfStock,
            getPurchasingOrderDetailAction
        } = this.props;
        let {
            isOpenConfirmOutOfStock,
            isOpenConfirmUpdatePrice,
            isOpenConfirmUpdateQuantityItems,
            isOpen,
        } = this.state;
        let nextOrderId = lodash.get(order,'next_order.id');
        return (
            <div className="quick_search">
                <label>
                    <Checkbox
                        onChange={onCheckAll}
                        isDisabled={items.length? false: true}
                        key={"chk_all" + defaultChecked}
                        defaultChecked={defaultChecked}
                    />
                    <span>Chọn tất cả</span>
                </label>

                <div onClick={this.handleOpenDropdown.bind(this)} className="d-flex">
                    <ActionList
                        getPurchasingOrderDetailAction={getPurchasingOrderDetailAction}
                        order={order}
                        nextOrderId={nextOrderId}
                        order_item_checked={order_item_checked}
                        isOpen={isOpen}
                        check_all={check_all}
                        onClickOpenFormEdit={this.onClickOpenFormEdit.bind(this)}
                        isLoadingCancelOrder={isLoadingCancelOrder}
                        beginProcessCancelOrder={beginProcessCancelOrder}
                        isLoadingOutOfStockOrder={isLoadingOutOfStockOrder}
                        beginProcessOutOfStock={beginProcessOutOfStock}
                        isEndProcess={isEndProcess}
                        onOutOfStock={onOutOfStock}
                        onCancel={onCancel}
                    />
                    <ActionExtend
                        order={order}
                        nextOrderId={nextOrderId}
                        order_item_checked={order_item_checked}
                        isOpen={isOpen}
                        check_all={check_all}
                        onClickOpenFormEdit={this.onClickOpenFormEdit.bind(this)}
                        isLoadingCancelOrder={isLoadingCancelOrder}
                        beginProcessCancelOrder={beginProcessCancelOrder}
                        isLoadingOutOfStockOrder={isLoadingOutOfStockOrder}
                        beginProcessOutOfStock={beginProcessOutOfStock}
                        isEndProcess={isEndProcess}
                        onOutOfStock={onOutOfStock}
                        onCancel={onCancel}
                        getPurchasingOrderDetailAction={getPurchasingOrderDetailAction}
                    />
                    {
                        isOpenConfirmOutOfStock && !isEndProcess.postUpdateOutStock ?
                            <ModalConfirm title={t('order:detail.confirm')}
                                          isLoading={isLoading}
                                          onClearCheckAll={this.props.onClearCheckAll}
                                          notice={t('order:detail.notice_out_stock')}
                                          onSubmitForm={this.updateOutOfStockItems.bind(this)}
                                          onClose={this.onClickOpenFormEdit.bind(this,'isOpenConfirmOutOfStock')}/>
                            : null
                    }
                    {
                        isOpenConfirmUpdatePrice && !isEndProcess.postUpdatePriceItems ?
                            <ModalConfirmUpdatePriceItems
                                isLoading={isLoading}
                                order={order}
                                clearError={this.props.clearErrorWhileUpdatePrice}
                                order_item_checked={order_item_checked}
                                onClearCheckAll={this.props.onClearCheckAll}
                                beginClearErrorUpdatePriceItems={this.props.beginClearErrorUpdatePriceItems}
                                errorUnitPriceItems={errorUnitPriceItems}
                                onSubmitForm={
                                    dataForm => this.updatePriceItems(dataForm)
                                }
                                onClose={this.onClickOpenFormEdit.bind(this,'isOpenConfirmUpdatePrice')}/>
                            : null
                    }
                    {
                        isOpenConfirmUpdateQuantityItems && !isEndProcess.postUpdateQuantityItems ?
                            <ModalConfirmUpdateQuantitytems isLoading={isLoading}
                                                            order={order}
                                                            clearError={this.props.clearErrorWhileUpdatePrice}
                                                            order_item_checked={order_item_checked}
                                                            onClearCheckAll={this.props.onClearCheckAll}
                                                            beginClearErrorUpdatePriceItems={this.props.beginClearErrorUpdatePriceItems}
                                                            errorUnitPriceItems={errorUnitPriceItems}
                                                            onSubmitForm={
                                                                dataForm => this.updateQuantityItems(dataForm)
                                                            }
                                                            onClose={this.onClickOpenFormEdit.bind(this,'isOpenConfirmUpdateQuantityItems')}
                            />
                            : null
                    }
                </div>
            </div>
        );
    }
}

QuickSearch.defaultProps = {
    order: {},
    errorUnitPriceItems: {},
    isLoading: false,
    isEndProcess: {},
    order_item_checked: [],
    onClearCheckAll:()=>{}
};

QuickSearch.propTypes = {
    order: PropTypes.object,
    errorUnitPriceItems: PropTypes.object,
    isLoading: PropTypes.bool,
    isEndProcess: PropTypes.object,
    order_item_checked: PropTypes.array,
    onClearCheckAll: PropTypes.func
};

export default translate('translations')(QuickSearch);
