import React from 'react'
import lodash from 'lodash'
import moment from 'moment'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import JavascriptTimeAgo from 'javascript-time-ago'
import vi from 'javascript-time-ago/locale/vi'
import en from 'javascript-time-ago/locale/en'
import ReactTimeAgo from 'react-time-ago'
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog'
import { DEFAULT_NUMBER_NOTICE } from './../constants'

JavascriptTimeAgo.locale(vi)
JavascriptTimeAgo.locale(en)

class NoticeConfirm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            isOpenWaitConfirm: false,
            active: true,
        }
    }

    onConfirm(event) {
        let { dataOrder } = this.props
        this.props.onConfirmCustomerOrderBuying({ id: dataOrder.id })
    }

    onClose(event) {
        event.preventDefault()
        this.setState({ isOpen: false })
    }

    showModal() {
        this.setState({ isOpen: true })
    }

    /**
     * handle action click expand/ collapse
     */
    expandReadmore() {
        let { isOpenWaitConfirm } = this.state
        this.setState({ isOpenWaitConfirm: !isOpenWaitConfirm })
    }

    /**
     * thay dổi format để không bị lôi trên trình duyệt safari
     * @param input
     * @returns {Date}
     * @private
     */
    _parseDate(input) {
        if (typeof input === 'string') {
            let format = 'yyyy-mm-dd hh:MM:ss'
            let parts = input.match(/(\d+)/g),
                i = 0,
                fmt = {}
            // extract date-part indexes from the format
            format.replace(/(yyyy|dd|mm|hh|MM|ss)/g, function (part) {
                fmt[part] = i++
            })
            return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']], parts[fmt['hh']], parts[fmt['MM']], parts[fmt['ss']])
        } else {
            return input
        }
    }

    render() {
        let { updateQuantity, t, onClose } = this.props
        const { isOpen, isOpenWaitConfirm } = this.state

        updateQuantity.map(updateQuantityItem => {
            return updateQuantityItem.messages.length
        })

        let count = 0
        let lastUpdateQuantity = lodash.last(updateQuantity)
        lastUpdateQuantity.timeAgo = this._parseDate(lastUpdateQuantity.timeAgo)
        return (
            <div className={'a-text--center width-conent-purchasod'}>
                <ModalTransition>
                    {isOpen && (
                        <ModalDialog
                            onClose={onClose}
                            actions={[
                                {
                                    text: t('orderDetail:order_detail.confirmation'),
                                    onClick: this.onConfirm.bind(this),
                                },
                                { text: t('orderDetail:order_detail.cancel'), onClick: this.onClose.bind(this) },
                            ]}>
                            <div className={'a-modal__title'}>
                                <p>{t('orderDetail:order_detail.confirmation')}</p>
                            </div>
                            <p>{t('orderDetail:order_detail.you_are_confirm_verify_customer')}</p>
                        </ModalDialog>
                    )}
                </ModalTransition>

                <div className="a-alert--log a-alert-warning a-text--left a-alert--log-purchase-buying purchasebuying ">
                    <div className="a-alert-warning__icon">
                        <i className="fas fa-exclamation-triangle" />
                    </div>
                    <div className="a-alert__body a-alert--log-purchase-buying__body">
                        {updateQuantity.map((updateQuantityItem, groupUser) => {
                            count++
                            return (
                                <React.Fragment key={updateQuantityItem.username}>
                                    <span className={'' + (!isOpenWaitConfirm && count > DEFAULT_NUMBER_NOTICE ? 'hidden' : '')}>
                                        {t('orderDetail:order_detail.order_teller')}
                                        <a href="">@{updateQuantityItem.username}</a>)&nbsp;
                                        {t('orderDetail:order_detail.changed_wait_confirm_customer')}:
                                    </span>
                                    <ul>
                                        {updateQuantityItem.messages.map((itemChange, index) => {
                                            count++
                                            return (
                                                <React.Fragment key={index}>
                                                    <li
                                                        key={itemChange.id + index}
                                                        className={'' + (!isOpenWaitConfirm && count > DEFAULT_NUMBER_NOTICE ? 'hidden' : '')}>
                                                        <span>
                                                            -
                                                            {t('orderDetail:order_detail.content_change', {
                                                                type: t('orderDetail:order_detail.' + itemChange.type),
                                                            })}
                                                            &nbsp;
                                                            <a>#{itemChange.id}</a>&nbsp;
                                                            {t('orderDetail:order_detail.from')}&nbsp;
                                                            {itemChange.old}&nbsp;
                                                            {t('orderDetail:order_detail.to')}&nbsp;
                                                            {itemChange.new}
                                                        </span>
                                                    </li>
                                                </React.Fragment>
                                            )
                                        })}
                                    </ul>
                                </React.Fragment>
                            )
                        })}
                        <React.Fragment>
                            <ul>
                                {count > DEFAULT_NUMBER_NOTICE ? (
                                    <li style={{ height: '30px', lineHeight: '30px' }}>
                                        <span
                                            className={'read-more a--cursor-poiter'}
                                            style={{ float: 'right', display: 'block' }}
                                            onClick={this.expandReadmore.bind(this)}>
                                            {!isOpenWaitConfirm && count >= DEFAULT_NUMBER_NOTICE
                                                ? t('orderDetail:order_detail.show_more')
                                                : t('orderDetail:order_detail.show_hide')}
                                        </span>
                                    </li>
                                ) : null}
                            </ul>
                            <div className='d-flex flex--center'>
                                <a
                                    onClick={event => this.showModal(event)}
                                    className={'a-alert--log-purchase-buying__submit a--cursor-poiter'}>
                                    {t('orderDetail:order_detail.confirmation')}&nbsp;
                                </a>
                                <div className="a-alert--log-purchase-buying--time-ago d-flex flex--center">
                                    (
                                    <ReactTimeAgo
                                        tooltip={false}
                                        locale={localStorage.getItem('i18nextLng') || 'en'}>
                                        {new Date(lastUpdateQuantity.timeAgo || moment.now())}
                                    </ReactTimeAgo>
                                    )
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </div>
            </div>
        )
    }
}

NoticeConfirm.defaultProps = {
    dataOrder: {},
}

NoticeConfirm.propTypes = {
    dataOrder: PropTypes.object,
}

export default translate('translations')(NoticeConfirm)
