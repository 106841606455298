import React from 'react';
 import { translate } from 'react-i18next';
class Error extends React.Component {
    componentDidMount() {
        let {t} = this.props;
        this.props.setPageTitle(t('breadcrumb.error404'));
        this.props.setPageDescription(t('layout.description.error404'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
        ]);
    }
    render() {
        let {t} = this.props;
        return (
            <div className="a-notfound">
                <img src={ require('./download.svg') } alt="" />
                <h1>
                    {t('theme.error.404_title')}
                </h1>
                <p>{t('theme.error.404_content')}</p>
            </div>
        );
    }
}

export default translate('translations')(Error);
