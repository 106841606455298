import lodash from 'lodash';
import {auth} from '../../systems/api';
import {router} from '../../systems/routing';
import {getState} from '../../systems/store';
import authConfig from '../../config/auth';

class AuthService {
    /**
     * Kiểm tra client đã login hay chưa
     *
     * @return {boolean}
     */
    isLogged() {
        return !!auth.getToken();
    }

    /**
     * Thực hiện login
     */
    login() {
        auth.removeToken();
        router.redirect('/login');
    }

    /**
     * Thực hiện logout
     */
    logout() {
        auth.removeToken();
        window.location = authConfig.logoutUrl;
    }

    /**
     * Lấy thông tin user hiện tại
     *
     * @param {string|null} key
     * @param {*} defaultValue
     * @return {*}
     */
    user(key = null, defaultValue = null) {
        let user = getState('User.me.user');
        return key === null ? user : lodash.get(user, key, defaultValue);
    }

    /**
     * Trả về true nếu user hiện tại được phân TÁT CẢ các permissions truyền vào
     * VD: auth.can('GET'), auth.can(['GET', 'CREATE'])
     *
     * @param {string|array} permissions
     * @return {boolean}
     */
    can(permissions) {
        permissions = lodash.isArray(permissions) ? permissions : [permissions];
        let userPermissions = this.user('permissions') || [];
        if (lodash.get(userPermissions, 0, '') === '*') {
            return true;
        }

        return lodash.every(permissions, permission => userPermissions.includes(permission));
    }

    /**
     * Trả về true nếu user hiện tại được phân MỘT TRONG các permissions truyền vào
     * VD: auth.can('GET'), auth.can(['GET', 'CREATE'])
     *
     * @param {string|array} permissions
     * @return {boolean}
     */
    canAny(permissions) {
        permissions = lodash.isArray(permissions) ? permissions : [permissions];
        let userPermissions = this.user('permissions') || [];

        return lodash.some(permissions, permission => userPermissions.includes(permission));
    }

    /**
     * Gửi token cho tool
     */
    authForTool() {
        setTimeout(function () {
            window.postMessage({
                type: 'GOBIZ_M5.AUTH',
                payload: {token: auth.getToken()},
            }, "*");
        }, 3000);
    }
}

let authService = new AuthService();

export default authService;
