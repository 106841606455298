export default {
    UNASSIGNED: 'ACCOUNT_PURCHASER.UNASSIGNED',
    ASSIGNED: 'ACCOUNT_PURCHASER.ASSIGNED',
    UPDATED: 'ACCOUNT_PURCHASER.UPDATED',
    CREATED: 'ACCOUNT_PURCHASER.CREATED',
    TOKEN_UPDATE: {
        SUCCESS: 'TOKEN_UPDATE_SUCCESS',
        FAILED: 'TOKEN_UPDATE_FAILED',
    }
};
