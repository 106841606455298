import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Form, Row, Col, Input, Button, Icon, Select} from 'antd';
import FormProcessing from '../../../../modules/common/components/FormProcessing';
import SelectOrderSources from '../../../../modules/Order/SelectOrderSources';
import SelectTellersV2 from "../../../../modules/User/SelectTellersV2";
import DateRange from "../../../../modules/common/components/DateTime/DateRange";
import SelectAccountPurchasers from "../../../../modules/AccountPurchaser/SelectAccountPurchasers";
import lodash from "lodash";
import DateRangeCustomize from "../../../../modules/common/components/DateTime/DateRangeCustomize";
const Option = Select.Option;
class FormSearch extends FormProcessing {
    constructor(props) {
        super(props);

        this.defaultInput = {
            code: "",
            account_purchase_id: [],
            order_teller: [],
            order_seller_source: [],
            orderBy: "",
            log_status_to: "",
            log_status_from: "",
            enough_origin_info: "",
            sort: "asc",
        };

        this.state = {
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
        };
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.props.onSearch({...this.state.input, page: 1});
        }
    }

    onReset(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.changeInput(this.defaultInput);
            this.props.onSearch(this.defaultInput);
        }
    }

    onChangeText(param, event) {
        this.onChangeInput(param, event);
    }

    onChangeSelect(value, param) {
        this.changeInput({[param]: value});
    }

    onChangeRangePicker = (dates) => {
        if (!lodash.isEmpty(dates))
            this.changeInput({
                created_at_from: dates.created_at_from,
                created_at_to: dates.created_at_to,
                page: 1
            });
        else
            this.changeInput({
                created_at_from: "",
                created_at_to: "",
                page: 1
            });
    };


    render() {
        let {t} = this.props;
        let {input} = this.state;
        return (
            <div className="a-list--form-search mr-0 ml-0">
                <Row gutter={32}>
                    <Form onSubmit={this.onSubmit.bind(this)} labelcol={{span: 12}}>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.order_code')} className="a-form--ant-item  mb-0">
                                <Input
                                    value={input.code || ''}
                                    placeholder={t('order:list.order_code_placeholder')}
                                    onChange={this.onChangeText.bind(this, 'code')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.seller_source')} className="a-form--ant-item  mb-0">
                                <SelectOrderSources
                                    value={input.order_seller_source}
                                    allowClear={true}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    multiple={true}
                                    placeholder={t('order:placeholder.seller_source')}
                                    onChange={value => this.onChangeSelect(value, "order_seller_source")}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.order_teller')} className="a-form--ant-item  mb-0">
                                <SelectTellersV2
                                    value={input.order_teller}
                                    multiple={true}
                                    allowClear={true}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    placeholder={t('order:placeholder.order_teller')}
                                    onChange={value => this.onChangeSelect(value, "order_teller")}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('purchaser:label.id_account_purchaser')} className="a-form--ant-item  mb-0">
                                <SelectAccountPurchasers
                                    value={input.account_purchaser_id}
                                    placeholder={t('purchaser:placeholder.account_purchaser')}
                                    allowClear={true}
                                    multiple={true}
                                    onChange={value => this.onChangeSelect(value, "account_purchaser_id")}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.enough_origin_info')} className="a-form--ant-item  mb-0">
                                <Select
                                    value={input.enough_origin_info}
                                    onChange={value => this.onChangeSelect(value, "enough_origin_info")}
                                >
                                    <Option value="">{t('label.all')}</Option>
                                    <Option value="0">{t('order:enough_origin_info.no')}</Option>
                                    <Option value="1">{t('order:enough_origin_info.yes')}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={8} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.time_create_complaint_seller')} className="a-form--ant-item  mb-0">
                                <DateRangeCustomize
                                    from={input.created_at_from}
                                    to={input.created_at_to}
                                    format = 'DD/MM/YYYY'
                                    valueFormat = 'YYYY-MM-DD'
                                    onChange={(from, to) => this.onChangeRangePicker({
                                        'created_at_from': from,
                                        'created_at_to': to,
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={24} xl={24} xxl={24} className="mt-4 pt-5 border-top-dashed">
                            <div className="a-list--action-search flex-row justify-content-end">
                                <a href="#"
                                   className="link-reset mr-5"
                                   onClick={this.onReset.bind(this)}
                                ><Icon type="sync"/>{t('btn.reset')}</a>

                                <Button
                                    htmlType="submit"
                                    className="a-text--uppercase a-btn--padding-large a-btn--primary-ant"
                                    loading={this.props.loading}
                                    disabled={!this.props.canSearch}
                                >{t('btn.search')}</Button>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </div>

        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: null,
    loading: false,
    canSearch: true,
    onSearch: (input) => {},
    listScanningStatus: [],
    filterLongTime: []
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.any,
    loading: PropTypes.bool,
    canSearch: PropTypes.bool,
    onSearch: PropTypes.func,
    listScanningStatus: PropTypes.array,
    filterLongTime: PropTypes.array
};

export default translate()(FormSearch);
