import {createSelector} from "reselect";
import lodash from "lodash";
import moment from "moment";
import {dateFormatter, currentFormatter} from "../../modules/common/services/format/index";
import {DEFAULT_EMPTY_NUMBER_DATA} from "../../modules/Order/constants";
import {trans} from "../../systems/i18n";

const ordersSelector = orders => lodash.get(orders, 'data.orders', []);

function formatOrder(order) {
    let grand_total = lodash.get(order, "grand_total", DEFAULT_EMPTY_NUMBER_DATA),
        grand_total_converted = lodash.get(order, "grand_total_converted", DEFAULT_EMPTY_NUMBER_DATA),
        grand_total_bought = lodash.get(order, "grand_total_bought", DEFAULT_EMPTY_NUMBER_DATA),
        total_shipping_fee_inland = lodash.get(order, "total_shipping_fee_inland", DEFAULT_EMPTY_NUMBER_DATA),
        collect_amount = lodash.get(order, "collect_amount", DEFAULT_EMPTY_NUMBER_DATA),
        spend_amount = lodash.get(order, "spend_amount", DEFAULT_EMPTY_NUMBER_DATA),
        real_payment_amount = lodash.get(order, "real_payment_amount", DEFAULT_EMPTY_NUMBER_DATA),
        real_refund_amount = lodash.get(order, "real_refund_amount", DEFAULT_EMPTY_NUMBER_DATA),
        order_profit = lodash.get(order, "order_profit", DEFAULT_EMPTY_NUMBER_DATA),
        rate_exchange = lodash.get(order, "rate_exchange", DEFAULT_EMPTY_NUMBER_DATA),
        items = lodash.get(order, "items", []),
        currencySymbol = '¥';//lodash.get(order, "currencySymbol",  '¥');

    return {
        ...order,
        bought_at: order.bought_at ? dateFormatter.formatDate(order.bought_at, 'HH:mm DD/MM/YYYY') : '--',
        grand_total: grand_total ? (currentFormatter.toLocaleStringCurrency(parseFloat(grand_total), 5) + ' ' + currencySymbol) : '--',
        grand_total_converted: grand_total_converted ? (currentFormatter.toLocaleStringCurrencyVND(parseFloat(grand_total_converted))) + " " + trans("order:vnd") : "--",
        grand_total_bought: grand_total_bought ? (currentFormatter.toLocaleStringCurrency(parseFloat(grand_total_bought), 5) + ' ' + currencySymbol) : '--',
        grand_total_bought_converted: grand_total_bought ? (currentFormatter.toLocaleStringCurrencyVND(parseFloat(grand_total_bought*rate_exchange))) + " " + trans("order:vnd") : "--",
        total_shipping_fee_inland: total_shipping_fee_inland ? (currentFormatter.toLocaleStringCurrency(parseFloat(total_shipping_fee_inland), 5) + ' ' + currencySymbol) : '--',
        total_shipping_fee_inland_converted: total_shipping_fee_inland ? (currentFormatter.toLocaleStringCurrencyVND(parseFloat(total_shipping_fee_inland*rate_exchange))) + " " + trans("order:vnd") : "--",
        collect_amount: collect_amount ? (currentFormatter.toLocaleStringCurrency(parseFloat(collect_amount), 5) + ' ' + currencySymbol) : '--',
        collect_amount_converted: collect_amount ? (currentFormatter.toLocaleStringCurrencyVND(parseFloat(collect_amount*rate_exchange))) + " " + trans("order:vnd") : "--",
        spend_amount: spend_amount ? (currentFormatter.toLocaleStringCurrency(parseFloat(spend_amount), 5) + ' ' + currencySymbol) : '--',
        spend_amount_converted: spend_amount ? (currentFormatter.toLocaleStringCurrencyVND(parseFloat(spend_amount*rate_exchange))) + " " + trans("order:vnd") : "--",
        real_payment_amount: real_payment_amount ? (currentFormatter.toLocaleStringCurrency(parseFloat(real_payment_amount), 5) + ' ' + currencySymbol) : '--',
        real_payment_amount_converted: real_payment_amount ? (currentFormatter.toLocaleStringCurrencyVND(parseFloat(real_payment_amount*rate_exchange))) + " " + trans("order:vnd") : "--",
        real_refund_amount: real_refund_amount ? (currentFormatter.toLocaleStringCurrency(parseFloat(real_refund_amount), 5) + ' ' + currencySymbol) : '--',
        real_refund_amount_converted: real_refund_amount ? (currentFormatter.toLocaleStringCurrencyVND(parseFloat(real_refund_amount*rate_exchange))) + " " + trans("order:vnd") : "--",
        order_profit: order_profit ? (currentFormatter.toLocaleStringCurrency(parseFloat(order_profit), 5) + ' ' + currencySymbol) : '--',
        order_profit_converted: order_profit ? (currentFormatter.toLocaleStringCurrencyVND(parseFloat(order_profit*rate_exchange))) + " " + trans("order:vnd") : "--",
        rate_exchange: rate_exchange ? (currentFormatter.toLocaleStringCurrencyVND(parseFloat(rate_exchange)) + ' ' + trans("order:vnd")) : '--',
        order_item_image: items[0].image || null,
    };
}

export const getOrders = createSelector(
        ordersSelector,
        orders => orders.map(order => formatOrder(order))
    );