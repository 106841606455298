import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Icon, Modal, Input, Button} from 'antd';
import {processResponse} from '../../../systems/api';
import {events} from '../../../systems/events';
import notification from '../../../systems/notification';
import ORDER_EVENT from '../../Order/events';
import apiService from '../apiService';
import authService from '../../Auth/authService';
import * as PERMISSION from '../../Permission/permissions';
import lodash from "lodash";
import {dateFormatter} from "../../common/services/format";

class MarkViolationOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            openModal: false,
            reason: '',
        };
    }

    openModal() {
        this.setState({openModal: true});
    }

    onMarkViolation() {
        if (this.state.loading) {
            return;
        }

        let {order, t} = this.props;

        this.setState({loading: true});

        let res = apiService.markViolationOrder(order.id, {note: this.state.reason})
            .finally(() => this.setState({loading: false, openModal: false}));

        let process = processResponse(res);

        process.then(res => {
            notification.success(t('order:message.mark_violation_order_success'));

            events.dispatch(ORDER_EVENT.MARKED_VIOLATION, {
                order: {...order, ...(res.data || {})},
            });

        });

        process.catch(() => {
            notification.error(t('order:message.mark_violation_order_failed'));
        });
    }

    onCancel() {
        if (!this.state.loading) {
            this.setState({openModal: false});
        }
    }

    render() {
        let {t, order} = this.props;
        return (
            <React.Fragment>
                <div className={"description-trade text-right"}>
                    {authService.can(PERMISSION.ORDER_MARK_VIOLATION) && (
                        <a href="#"
                           className="a-text--green-new ml-2 a-font--12 btn"
                           onClick={(event) => {
                               event.preventDefault();
                               this.openModal();
                           }}
                        ><Icon type="file-text" className="mr-2"/>{t('order:label.marking_violation_order')}</a>
                    )}

                    <Modal
                        width={360}
                        title={t('order:label.marking_violation_order')}
                        visible={this.state.openModal}
                        onOk={this.onMarkViolation.bind(this)}
                        onCancel={this.onCancel.bind(this)}
                        footer={false}
                        centered={true}
                        maskClosable={false}
                        className={"a-modal--list-trade"}
                    >
                        <span>{t('transaction:label.resolve_reason')} <span className="a-text--red">*</span></span>
                        <Input.TextArea
                            value={this.state.reason}
                            onChange={event => this.setState({reason: event.target.value})}
                            placeholder={t('transaction:placeholder.enter_reason')}
                            rows={"3"}
                            className="mt-1"
                            autosize={{minRows: 3, maxRows: 3}}
                            disabled={this.state.loading}
                        />
                        <div className={"amt-24 d-flex justify-content-end"}>

                            <Button
                                disabled={this.state.loading}
                                className=" a-font--12 a-btn--primary-ant ghost "
                                onClick={this.onCancel.bind(this)}
                            >{t('btn.cancel')}</Button>
                            <Button
                                loading={this.state.loading}
                                className=" a-font--12 a-btn--primary-ant aphz-24 ml-3"
                                disabled={!this.state.reason}
                                onClick={this.onMarkViolation.bind(this)}
                            >{t('btn.submit')}</Button>
                        </div>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}

MarkViolationOrder.defaultProps = {
    order: {},
};

MarkViolationOrder.propTypes = {
    order: PropTypes.object,
};

export default translate()(MarkViolationOrder);
