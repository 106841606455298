import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@atlaskit/button';
import Textfield from '@atlaskit/textfield';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import bootbox from './../../../common/components/Bootbox/bootbox';
import {setActiveSettingItem} from '../../../Settings/SettingMenu/actions';

class UserAccessToken extends React.Component {
    componentDidMount() {
        this.props.setPageTitle(this.props.t('user:access_token.page.title'));
        this.props.setPageDescription(this.props.t('user:access_token.page.description'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'user:access_token.page.title',
                'link': '/user/access_token/'
            },
        ]);
        this.props.setMenuActive("user_access_token");
        setActiveSettingItem('user_access_token');
    }

    onGenerateToken() {
        if (!this.props.loading) {
            this.props.onGenerateToken();
        }
    }

    render() {
        let {t, token, loading} = this.props;

        return (
            <div className="a-content__right a-content--box-shadow">
                <div className="d-flex access_token__max-width">
                    <Button
                        appearance="primary"
                        isLoading={loading}
                        onClick={this.onGenerateToken.bind(this)}
                    >Tạo Aceess Token</Button>

                    <div className="flex-grow-1 ml-2">
                        <Textfield
                            value={token || ''}
                            isReadOnly={true}
                            isCompact={true}
                        />
                    </div>

                    {token ? (
                        <CopyToClipboard
                            text={token}
                            onCopy={() => bootbox.success(t('message.copied'))}
                        >
                            <Button appearance="default" className="ml-2">Copy</Button>
                        </CopyToClipboard>
                    ) : null}
                </div>
            </div>
        );
    }
}

UserAccessToken.defaultProps = {
    token: null,
    onGenerateToken: () => {},
};

UserAccessToken.propTypes = {
    token: PropTypes.string,
    onGenerateToken: PropTypes.func,
};

export default translate()(UserAccessToken);
