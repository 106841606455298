import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {Icon} from "antd";

class TimeLines extends React.Component {

    render() {
        let {complaint, t} = this.props;
        let historyTimeText = lodash.get(complaint, 'historyTimeText', []);
        return (
            <ul className="a__complaint__detail__time_block">
                {historyTimeText.map((historyTimeItem, index) => (
                    <li key={index}>
                        <label>
                            {historyTimeItem.icon? (<Icon type="calendar" style={{marginTop: "1px"}} className="mr-1 a-text--fz-14" />): null}
                            {t('complaintSeller:timeLine.' + historyTimeItem.status.toLowerCase())}:
                        </label>
                        <p>
                            {historyTimeItem.dateFormat}
                        </p>
                    </li>

                ))}
            </ul>
        );
    }
}

TimeLines.defaultProps = {
    complaint: {}
};

TimeLines.propTypes = {
    complaint: PropTypes.object
};

export default translate()(TimeLines);
