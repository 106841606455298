import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../actions';
import * as selectors from '../selectors';
import SelectUser from './components/SelectUser';
import lodash from 'lodash';
import {getState} from './../../../systems/store';

const mapStateToProps = (state, props) => {
    return {
        ...props,
        users: selectors.getAllUser(state),
        loading: lodash.get(props, "loading", false) || getState('User.users.loading.detail', false),
    }
};

const mapDispatchToProps = (dispatch) => ({
    fetchUsers: () => dispatch(actions.fetchUsers({pagination: false, scope: "BASIC"})),
});

class SelectUserContainer extends React.PureComponent {
    componentDidMount() {
        if (!this.props.users.length) {
            this.props.fetchUsers();
        }
    }

    render() {
        return <SelectUser {...this.props}/>
    }
}

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectUserContainer);

Container.defaultProps = {
    value: null,
    autoSelect: false,
    onChange: value => {
    },
};

Container.propTypes = {
    autoSelect: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default Container;
