import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Select} from 'antd';

const {Option} = Select;

class IncludedAllOptionSelect extends React.PureComponent {
    render() {
        let {t, list, loading, translatePrefix} = this.props;

        return (
            <Select
                value={!loading ? this.props.value : undefined}
                mode={this.props.multiple ? 'multiple' : 'default'}
                placeholder={this.props.placeholder}
                showSearch={this.props.showSearch}
                optionFilterProp={this.props.optionFilterProp || 'children'}
                isLoading={loading}
                onChange={this.props.onChange}
                className={this.props.className}
            >
                {this.props.allOption && <Option value="">{t('label.all')}</Option>}
                {list.map(status => <Option key={status} title={t(`${translatePrefix}.${status}`)} value={status}>{t(`${translatePrefix}.${status}`)}</Option>)}
            </Select>
        );
    }
}

IncludedAllOptionSelect.defaultProps = {
    value: null,
    allOption: false,
    multiple: false,
    filter: value => true,
    onChange: value => {},
    list: [],
    translatePrefix: ''
};

IncludedAllOptionSelect.propTypes = {
    value: PropTypes.any,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    filter: PropTypes.func,
    onChange: PropTypes.func,
    list: PropTypes.array,
    translatePrefix: PropTypes.string,
};

export default translate()(IncludedAllOptionSelect);
