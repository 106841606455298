import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Icon, Modal} from 'antd';
import lodash from 'lodash';
import {processResponse} from '../../../systems/api';
import {events} from '../../../systems/events';
import notification from '../../../systems/notification';
import TRANSACTION_EVENT from '../events';
import apiService from '../apiService';
import {dateFormatter} from '../../common/services/format';
import authService from '../../Auth/authService';
import * as PERMISSION from '../../Permission/permissions';

class UnresolveTransaction extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
        };

        this.modal = null;
    }

    confirm() {
        this.modal = Modal.confirm({
            title: 'Unresolve',
            content: 'Bạn có chắc chắn muốn Unresolve giao dịch này ?',
            okText: 'Unresolve',
            onOk: this.unresolve.bind(this),
            cancelText: 'Hủy',
            zIndex: 1050,
            icon: <Icon type="question-circle" theme="filled"/>,
            className: 'a-modal--confirm-trade',
        });
    }

    unresolve() {
        if (this.state.loading) {
            return;
        }

        let {transaction, t} = this.props;

        this.setState({loading: true});

        this.modal.update({
            okButtonProps: {loading: true},
            cancelButtonProps: {disabled: true},
            keyboard: false,
        });

        let res = apiService.unresolve(transaction.id)
            .finally(() => this.setState({loading: false}));

        let process = processResponse(res);

        process.then(res => {
            notification.success(t('transaction:message.unresolve_transaction_success'));

            events.dispatch(TRANSACTION_EVENT.UNRESOLVED, {
                transaction: {...transaction, ...(res.data || {})},
            });
        });

        process.catch(() => {
            notification.error(t('transaction:message.unresolve_transaction_failed'));
        });

        return res;
    }

    render() {
        let {transaction, t} = this.props;

        return (
            <div className={"description-trade"}>
                <span className="a-font--12 a-text--gray-new a-text--bold mr-2 ">
                    <Icon type="check-circle" theme="filled" className="a-text--success mr-1"/>
                    {t('transaction:label.resolved')}
                </span>
                <span className=" a-font--12 a-text--gray-new">
                    {t('transaction:label.resolve_reason')}: {transaction.note_resolved} | {t('transaction:label.resolver')}: {lodash.get(transaction, 'resolved_creator.name')} | {dateFormatter.dateTime(transaction.resolved_time)}
                </span>

                {authService.can(PERMISSION.SUPPLIER_TRANSACTION_RESOLVE) && (
                    <a href="#"
                       className="a-text--green-new ml-2 a-text--decoration-underline a-font--12"
                       onClick={(event) => {
                           event.preventDefault();
                           this.confirm();
                       }}
                    >{t('transaction:btn.unresolve')}</a>
                )}
            </div>
        );
    }
}

UnresolveTransaction.defaultProps = {
    transaction: {},

};

UnresolveTransaction.propTypes = {
    transaction: PropTypes.object,
};

export default translate()(UnresolveTransaction);
