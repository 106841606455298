import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import FormProcessing from "../../../common/components/FormProcessing";
import Select from '@atlaskit/select';
import lodash from "lodash";
import {VIOLATE_REASON_TYPE} from '../../../../systems/globalContant/actionContant';
import moment from 'moment';
import {DatePicker} from 'antd';

const {RangePicker} = DatePicker;
const dateFormat = 'YYYY/MM/DD';

function disabledDate(current) {// Can not select days before today and today
    return current && current > moment().endOf('day');
}

const stateDefault = {
    code: '',
    id_teller: '',
    id_paid_staff: '',
    violate_reason: '',
    policy_status: '',
    policy_violate_reasons_id: '',
    policy_violate_reasons_type: '',
    log_status_from: '',
    log_status_to: '',
};

class FormFilter extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            input: stateDefault,
            inputVersion: 0,
            changedParams: [],
        }
    }

    onKeyPressInput(input, event) {
        if (event.key === 'Enter') {
            this.props.onSubmit(input);
        }
    }

    onBlurInput() {

    }

    onChangeCreatedTime(date) {
        this.changeInput({
            'log_status_from': date[0] ? date[0].format('YYYY-MM-DD') : '',
            'log_status_to': date[1] ? date[1].format('YYYY-MM-DD') : '',
        });

    }

    handleReset() {
        this.changeInput(stateDefault);
        this.props.onSubmit(stateDefault);
    }

    onChangeSelect(param, select) {
        this.changeInput({[param]: lodash.get(select, 'value', '')});
    }

    makeValueSelect(select, options) {

        return options.filter((option) => {
            return "" + select === "" + option.value;
        });
    }

    makeReasonOptions() {
        return lodash.keys(VIOLATE_REASON_TYPE).map(type => ({
            label: this.props.t('violate:list.' + type),
            options: this.props.violateReasons
                .filter(reason => reason.type === type)
                .map(reason => ({value: reason.id, label: reason.title})),
        }));
    }

    getCurrentReasonOption() {
        let reason = lodash.find(this.props.violateReasons, {id: parseInt(this.state.input.policy_violate_reasons_id, 10)});

        return reason ? {value: reason.id, label: reason.title} : null;
    }

    render() {
        let {t, accounts, status} = this.props;
        let {input} = this.state;
        return (
            <div className="a-tab-order">
                <div className="a-tab-order__title a-filter--violate d-flex justify-content-start">
                    <div className="a-filter--violate--form flex--column  ">
                        <label className="a-text">{t('violate:list.order_code')}</label>
                        <input
                            className={'input input--violate--filter-code'}
                            value={input.code || ""}
                            type="text"
                            placeholder={t('violate:list.order_code_placeholder')}
                            onBlur={this.onBlurInput.bind(this)}
                            onChange={this.onChangeInput.bind(this, 'code')}
                            onKeyPress={this.onKeyPressInput.bind(this, input)}
                            spellCheck={'false'}
                        />
                    </div>

                    <div className="a-filter--violate--form  ">
                        <label htmlFor="" className="a-text mb-2">{t('violate:list.id_teller')}</label>
                        <Select
                            noOptionsMessage={() => t("system.no_option")}
                            classNamePrefix="react-select--violate-policy"
                            isClearable={true}
                            isSearchable={true}
                            value={this.makeValueSelect(input.id_teller, accounts)}
                            placeholder={t('violate:list.id_teller_placeholder')}
                            options={accounts}
                            onBlur={this.onBlurInput.bind(this)}
                            onChange={this.onChangeSelect.bind(this, 'id_teller')}
                        />
                    </div>

                    <div className="a-filter--violate--form   ">
                        <label htmlFor="" className="a-text mb-2">{t('violate:list.id_paid_staff')}</label>
                        <Select
                            noOptionsMessage={() => t("system.no_option")}
                            classNamePrefix="react-select--violate-policy"
                            isClearable={true}
                            isSearchable={true}
                            value={this.makeValueSelect(input.id_paid_staff, accounts)}
                            placeholder={t('violate:list.id_paid_staff_placeholder')}
                            options={accounts}
                            onBlur={this.onBlurInput.bind(this)}
                            onChange={this.onChangeSelect.bind(this, 'id_paid_staff')}
                        />
                    </div>
                    <div className="a-filter--violate--form  ">
                        <label htmlFor="" className="a-text mb-2">{t('violate:list.violate_reason')}</label>
                        <Select
                            noOptionsMessage={() => t("system.no_option")}
                            classNamePrefix="react-select--violate-policy"
                            isClearable={true}
                            isSearchable={true}
                            formatGroupLabel={(group) => {
                                return <p className={"react-select--violate--cus_gr"}>{group.label}</p>
                            }}
                            formatOptionLabel={(menu) => {
                                return <p className={'react-select--violate--cus_op'}>{menu.label}</p>
                            }}

                            value={this.getCurrentReasonOption()}
                            placeholder={t('violate:list.violate_reason_placeholder')}
                            options={this.makeReasonOptions()}
                            onBlur={this.onBlurInput.bind(this)}
                            onChange={this.onChangeSelect.bind(this, 'policy_violate_reasons_id')}
                        />
                    </div>

                    <div className="a-filter--violate--form ">
                        <label htmlFor="" className="a-text mb-2">{t('violate:list.status')}</label>
                        <Select
                            noOptionsMessage={() => t("system.no_option")}
                            classNamePrefix="react-select--violate-policy"
                            isClearable={true}
                            isSearchable={true}
                            value={this.makeValueSelect(input.policy_status, status)}
                            placeholder={t('violate:list.status_placeholder')}
                            options={status}
                            onBlur={this.onBlurInput.bind(this)}
                            onChange={this.onChangeSelect.bind(this, 'policy_status')}
                        />
                    </div>

                    <div className="a-filter--violate--form ">
                        <label htmlFor="" className="a-text mb-2">{t('violate:list.deposit_time')}</label>
                        <RangePicker placeholder={['Bắt đầu', 'Kết thúc']}
                                     format='DD/MM/YYYY'
                                     className="adatepicker--violate-search"
                                     value={[
                                         input['log_status_from'] ? moment(input['log_status_from'], dateFormat) : null,
                                         input['log_status_to'] ? moment(input['log_status_to'], dateFormat) : null,
                                     ]}
                                     onChange={this.onChangeCreatedTime.bind(this)}
                                     disabledDate={disabledDate}
                        />
                    </div>

                    <div className="flex-start a-filter--violate--action ">
                        <button className={'sort '}
                                onClick={() => this.props.onSubmit(this.state.input)}>{t('violate:list.filter')}</button>
                        <a className={'reset'} onClick={this.handleReset.bind(this)}>{t('violate:list.refresh')}</a>
                    </div>
                </div>
            </div>
        );
    }
}

FormFilter.defaultProps = {
    input: {},
    inputVersion: 0,
    violateReasons: [],
    onSubmit: input => {
    },

};

FormFilter.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    onSubmit: PropTypes.func,
};


export default translate('translations')(FormFilter);
