import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {notification} from 'antd';
import Spinner from '@atlaskit/spinner';
import FormProcessing from '../../../common/components/FormProcessing';
import ReactFileReader from 'react-file-reader';

class FormComment extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                message: '',
                images: [],
            },
            inputVersion: 0,
            changedParams: [],
        };
    }

    /**
     * Xử lý submit form
     */
    onSubmit(event) {
        event.preventDefault();
        this.submitForm();
    }

    /**
     * Thực hiện submit form
     */
    submitForm() {
        if (this.canSubmit()) {
            this.props.onSubmit(this.state.input);
        }
    }

    /**
     * Kiểm tra có cho phép submit form hay không
     */
    canSubmit() {
        let {input} = this.state;
        let images = input.images || [];

        return (
            !this.props.loading
            && this.props.canComment
            && (input.message || images.length)
        );
    }

    /**
     * Event khi pase file vào input
     *
     * @param event
     */
    onPasteInput(event) {
        let files = this.getFilesFromClipboardData(event.clipboardData);

        if (!files) {
            return;
        }

        this.changeInput({images: []});

        files.forEach(file => {
            if (this.validateFile(file)) {
                this.loadFile(file);
            }
        });
    }

    /**
     * Lấy thông tin file đã được copy từ clipboard
     *
     * @param clipboardData
     * @return {Array|null}
     */
    getFilesFromClipboardData(clipboardData) {
        return (
            lodash.includes(clipboardData.types, 'Files')
            && clipboardData.items.length
        ) ? clipboardData.items : null;
    }

    /**
     * Validate file
     *
     * @param {File} file
     * @return {boolean}
     */
    validateFile(file) {
        const {t} = this.props;
        const allowedAttachFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/webp'];
        const allowedAttachFileSize = 5*1024*1024; // 5MB

        if (!lodash.includes(allowedAttachFileTypes, file.type)) {
            notification.error({message: t('log:error.file_type_invalid')});
            return false;
        }

        if (file.size > allowedAttachFileSize) {
            notification.error({message: t('log:error.file_size_invalid', {size: '5M'})});
            return false;
        }

        return true;
    }

    loadFile(file) {
        let attachFile = file.getAsFile();
        let reader = new FileReader();

        // Bắt đầu đọc file
        reader.readAsDataURL(attachFile);

        // Chờ file load xong tạm trong DOM mới lấy đươc base64 data
        reader.onload = event => {
            let images = [...this.state.input.message, event.target.result];
            this.changeInput({images});
        };
    }

    /**
     * Xử lý khi browse file
     *
     * @param file
     */
    handleFiles(file) {
        let errorFile = [...file.fileList].find(file => !this.validateFile(file));

        if (!errorFile) {
            this.changeInput({images: file.base64});
            setTimeout(() => this.submitForm(), 10);
        }
    }

    render() {
        let {t, loading, canComment} = this.props;
        let {input} = this.state;

        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit.bind(this)} className="flex--center a-chat__form_comment">
                    <div className={'a-chat__input'}>
                        <input
                            className={'input--chat a-chat__input--chat'}
                            spellCheck={'false'}
                            placeholder={this.props.isPublic ? t('log:comment_placeholder') : t('log:internal_placeholder')}
                            value={input.message || ''}
                            disabled={!canComment}
                            autoComplete="off"
                            onPaste={this.onPasteInput.bind(this)}
                            onChange={this.onChangeInput.bind(this, 'message')}
                        />

                        <div className={'a-chat__input--file'}>
                            <ReactFileReader
                                handleFiles={this.handleFiles.bind(this)}
                                fileTypes={["image/*"]}
                                multipleFiles={true}
                                base64={true}
                                disabled={!canComment}
                            >
                                <i className="fas fa-camera p-1"
                                   style={{cursor:'pointer'}}
                                   title={t('log:upload')}
                                />
                            </ReactFileReader>
                        </div>
                        {
                            loading ? <div className="icon-spinner a-chat__input__icon_loading"><Spinner/></div> : null
                        }
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

FormComment.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    canComment: true,
    onSubmit: input => {},
};

FormComment.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    loading: PropTypes.bool,
    canComment: PropTypes.bool,
    onSubmit: PropTypes.func,
};

export default translate()(FormComment);
