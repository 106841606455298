import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {Button, Modal, Form} from "antd";
import SelectSearchTellers from '../../User/SelectSearchTellers';
import PropTypes from 'prop-types';
import {events} from "../../../systems/events";
import ACCOUNT_PURCHASER from "../events";
import SelectAccountPurchasers from '../../AccountPurchaser/SelectAccountPurchasers';
import apiService from '../apiService';
import {notification} from "antd";
import lodash from 'lodash';
import {processResponse} from '../../../systems/api';

class AssignAccountPurchaser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            id_account_purchaser: undefined,
            id_user: [],
            errors: {},
            loading: false
        };
    }

    AssignPurchaser = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk(event){
        event.preventDefault();
        let {teller, t} = this.props;
        let data = lodash.omit({...this.state}, ['visible', 'errors', 'loading']);
        data = lodash.omitBy(data, value => value === undefined || value.length === 0);
        this.setState({
            loading: true
        });
        if(lodash.isEmpty(teller)){
            let res = apiService.assignAccountPurchaserMulti(data).finally(() => this.setState({loading: false}));
            let process = processResponse(res);
            process.then(() => {
                notification.success({message: t('purchaser:message.assign_account_purchaser_success')});
                this.setState({
                    visible: false,
                    id_account_purchaser: undefined,
                    id_user: [],
                    errors: {}
                })
                events.dispatch(ACCOUNT_PURCHASER.CREATED);
            });

            process.catch(res => {
                this.getErrors(res);
            });

        } else {
            data.id = lodash.get(this.props.teller, 'id', 0);
            let res = apiService.assignAccountPurchaser(data).finally(() => this.setState({loading: false}));
            let process = processResponse(res);
            
            process.then(() => {
                notification.success({message: t('purchaser:message.assign_account_purchaser_success')});
                this.setState({
                    visible: false,
                    id_account_purchaser: undefined,
                    id_user: [],
                    errors: {}
                })
                events.dispatch(ACCOUNT_PURCHASER.CREATED);
            });

            process.catch(res => {
                this.getErrors(res);
            });
        }
    };

    getErrors(errors) {
        let {t} = this.props;
        lodash.mapValues(errors.data, (rules, param) => {
            rules = lodash.mapKeys(rules, function(value, key) {
                return lodash.toLower(key);
            });
            let attribute = t('purchaser:label.' + param);
            let messages = lodash.mapValues(rules, (data, rule) => {
                let messageKey = 'purchaser:error.' + rule;
                return t(messageKey, {...data, attribute});
            });;
            this.setState({
                errors: {
                    ...this.state.errors,
                    [param]: lodash.first(lodash.values(messages))
                }
            })
        });
    };


    handleCancel(){
        this.setState({
            visible: false,
            id_account_purchaser: undefined,
            id_user: [],
            errors: {},
        });
    };

    onChangeSelect(param, value) {
        this.setState({
            [param]: value,
            errors: {
                ...this.state.errors,
                account_purchaser: undefined,
                [param]: undefined
            }
        })
    }

    setIdUser(selectedTellers){
        const idUser = selectedTellers.map(item => item.id);
        this.setState({
            id_user: idUser
        })
    }

    clearIdUserError(){
        this.setState({
            errors: {
                ...this.state.errors,
                id_user: undefined
            }
        })
    }

    render() {
        /* eslint-disable */
        let {isMultiple, teller, t} = this.props;
        let {visible, id_account_purchaser, errors, loading} = this.state;
        return (
            <React.Fragment>
                <Button className="a-font--12 aphz-50 a-btn--primary-ant ghost mr-4"
                        style={{marginBottom:'10px'}}
                        onClick={this.AssignPurchaser}
                >{t("purchaser:label.attach_account_purchaser")}</Button>
                <Modal
                    width={400}
                    title={t("purchaser:label.attach_account_purchaser").toUpperCase()}
                    visible={visible}
                    closable={false}
                    onOk={this.handleOk.bind(this)}
                    onCancel={this.handleCancel.bind(this)}
                    footer={false}
                    centered={true}
                    className={"a-modal--list-trade"}
                >
                    <Form labelCol={{span: 12}} onSubmit={this.handleOk.bind(this)} className="a-form--antd">
                        {isMultiple ?
                            <Form.Item className="a-form--antd__item" required={true} labelCol={{span: 24}} label={t("purchaser:label.purchaser")} help={errors.id_user} validateStatus={errors.id_user ? 'error' : null}>
                                <SelectSearchTellers
                                    setSelectedTellers={this.setIdUser.bind(this)}
                                    clearTellersError={this.clearIdUserError.bind(this)}
                                    visible={visible}
                                    placeholder={t("purchaser:placeholder.purchaser")}
                                />
                            </Form.Item> :
                            <p>
                                <span className={"a-text--black-65 a-text--fz-12"}>{t("purchaser:label.tellers") + ': '}</span>
                                <span className={"a-text--bold a-text--black-85"}>{lodash.get(teller, "username", "--")}</span>
                            </p>
                        }
                        <Form.Item className="a-form--antd__item" required={true} labelCol={{span: 24}} label={t("purchaser:label.account_purchaser")} help={errors.id_account_purchaser || errors.account_purchaser} validateStatus={errors.id_account_purchaser || errors.account_purchaser ? 'error' : null}>
                            <SelectAccountPurchasers
                                placeholder={t("purchaser:placeholder.account")}
                                className="w-100 mt-02"
                                value={id_account_purchaser}
                                onChange={this.onChangeSelect.bind(this, "id_account_purchaser")}
                            />
                        </Form.Item>
                        <div className={"amt-24 d-flex justify-content-end"}>
                            <Button
                                disabled={loading}
                                key="back"
                                onClick={this.handleCancel.bind(this)}
                                className=" a-font--12 a-btn--default-ant ghost "
                            >{t("purchaser:btn.cancel")}</Button>
                            <Button
                                loading={loading}
                                key="submit"
                                htmlType="submit"
                                className=" a-font--12 a-btn--primary-ant aphz-24 ml-3"
                            >{t("purchaser:btn.ok")}</Button>
                        </div>
                    </Form>
                </Modal>
            </React.Fragment>
        )
    }
}

AssignAccountPurchaser.defaultProps = {
    teller: {},
    isMultiple: false
};

AssignAccountPurchaser.propTypes = {
    teller: PropTypes.object,
    isMultiple: PropTypes.bool
};

export default translate()(AssignAccountPurchaser);
