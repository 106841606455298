import React from 'react';
import {Divider, Icon, Modal} from "antd";
import apiService from "../apiService";
import {processResponse} from "../../../systems/api";
import notification from '../../../systems/notification';
import {events} from "../../../systems/events";
import PAYMENT_REQUEST_EVENT from "../events";
import {translate} from "react-i18next";
import lodash from 'lodash';
import authService from "../../Auth/authService";
import * as PERMISSIONS from '../../Permission/permissions';
import SuccessPaymentConfirmation from "../SuccessPaymentConfirmation";
import {url} from '../../../systems/routing';
import ForwardPaymentRequest from "../../../screens/PaymentRequest/components/ForwardPaymentRequest";

class ProcessPaymentRequest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    confirm(oldStatus, status) {
        let {t} = this.props;
        Modal.confirm({
            className: "a-modal--confirm-trade",
            title: t(`paymentRequest:modal.title.${status}`),
            content: t(`paymentRequest:modal.content.${status}`),
            cancelText: 'Hủy',
            okText: 'Đồng ý',
            onOk: this.processPaymentRequest.bind(this, oldStatus, status),
            zIndex: 1050,
            icon: <Icon type="question-circle" theme="filled"/>,
            okButtonProps: {type: "primary", className: `_payment-request-action-reject-button-ok`},
            cancelButtonProps: {className: `_payment-request-action-reject-button-cancel`}
        });
    }

    processPaymentRequest = (oldStatus, status) => {
        let {t, request} = this.props;
        this.setState({loading: true});

        let res = apiService.changeStatePaymentRequest(request.id, {status})
            .finally(() => this.setState({loading: false}));

        let process = processResponse(res);

        process.then(res => {
            notification.success(t(`paymentRequest:message.CHANGED_STATE_${status}`));
            events.dispatch(lodash.get(PAYMENT_REQUEST_EVENT, `${status}`, ''), {
                request: {...res.data},
                oldStatus
            });
        });

        process.catch((res) => {
            let {code, data} = res;
            
            if (data.hasOwnProperty('UNAUTHORIZED')) {
                notification.error(t('paymentRequest:error.unauthorized'))
            }
            if (code === "INPUT_INVALID") {
                let hasKey = false;
                if (data.hasOwnProperty("order_sb_negative")) {
                    hasKey = true;
                    notification.error(t('paymentRequest:confirm_payment.ORDER_SB_NEGATIVE'))
                }
                if (data.hasOwnProperty("purchase_order_status_closed")) {
                    hasKey = true;
                    notification.error(t('paymentRequest:confirm_payment.purchase_order_status_closed'))
                }
                if (data.hasOwnProperty("real_payment_amount")) {
                    hasKey = true;
                    notification.error(t('paymentRequest:unique_error.real_payment_amount'))
                }
                if (!hasKey ) {
                    notification.error(t('paymentRequest:confirm_payment.INVALID'))
                }
            } else {
                notification.error(t('message.cant_update_data'))
            }

        });
        return process;
    };

    confirmPayment = () => {
        const {t} = this.props;
        Modal.confirm({
            title: t("paymentRequest:modal.title.confirm_process_payment_sb_order"),
            content: t("paymentRequest:modal.content.confirm_process_payment_sb_order"),
            onOk: this.processPaymentRequest.bind(this, 'PROCESSING', 'SUCCESS'),
            cancelText: t("btn.cancel"),
            okText: t("btn.continue")
        });
    };

    renderAction() {
        let {t, request, user, groups} = this.props;
        const is_order_sb = lodash.get(request, "is_order_sb", false);

        const listCanChangeState = lodash.get(request, "listCanChangeState", []);
        const canChangeGroup = lodash.get(request, "canChangeGroup", false);
        switch (request.status) {
            case 'PENDING':
                return (
                    <div className="text-nowrap">
                        {
                            lodash.includes(listCanChangeState, 'WAITING') &&
                            <span className="a-text--color-primary a--cursor-poiter _payment-request-action-approve"
                                  onClick={this.processPaymentRequest.bind(this, 'PENDING', 'WAITING')}>
                                {t(`paymentRequest:btn.approve`)}
                            </span>

                        }
                        {
                            lodash.includes(listCanChangeState, 'CANCELLED') &&
                            <>
                                <Divider type='vertical'/>
                                <span
                                    className="a-text--color-warning a--cursor-poiter _payment-request-action-reject"
                                    onClick={this.confirm.bind(this, 'PENDING', 'CANCELLED')}>
                                    {t(`paymentRequest:btn.reject`)}
                                </span>
                            </>
                        }
                    </div>
                );
            case 'WAITING':
                return (
                    <div className="text-nowrap">
                        {
                            lodash.includes(listCanChangeState, 'PROCESSING') &&
                            <span
                                className="a-text--color-primary a--cursor-poiter _payment-request-action-approve"
                                onClick={this.processPaymentRequest.bind(this, 'WAITING', 'PROCESSING')}>
                                    {t(`paymentRequest:btn.process`)}
                            </span>
                        }
                        {
                            lodash.includes(listCanChangeState, 'CANCELLED') &&
                            <>
                                <Divider type='vertical'/>
                                <span
                                    className="a-text--color-warning a--cursor-poiter _payment-request-action-reject"
                                    onClick={this.confirm.bind(this, 'WAITING', 'CANCELLED')}>
                                    {t(`paymentRequest:btn.reject`)}
                                </span>
                            </>
                        }
                    </div>
                );
            case 'PROCESSING':
                return (
                    <div className="text-nowrap">
                        {
                            lodash.includes(listCanChangeState, 'SUCCESS') &&
                            <>
                                {
                                    is_order_sb ? <span
                                            className={`a-text--color-primary a--cursor-poiter _payment-request-action-approve` + (this.state.loading ? "disabled" : "")}
                                            onClick={this.confirmPayment}>
                                                {t(`paymentRequest:btn.success`)}
                                        </span> :
                                        request.external_order === 1 ?
                                            <SuccessPaymentConfirmation request={request}>
                                                {t(`paymentRequest:btn.success`)}
                                            </SuccessPaymentConfirmation> :
                                            <span
                                                className={`a-text--color-primary a--cursor-poiter _payment-request-action-approve` + (this.state.loading ? "disabled" : "")}
                                                onClick={this.processPaymentRequest.bind(this, 'PROCESSING', 'SUCCESS')}>
                                                {t(`paymentRequest:btn.success`)}
                                        </span>
                                }
                            </>
                        }
                        {
                            lodash.includes(listCanChangeState, 'CANCELLED') &&
                            <>
                                <Divider type='vertical'/>
                                <span
                                    className="a-text--color-warning a--cursor-poiter _payment-request-action-failed"
                                    onClick={this.confirm.bind(this, 'PROCESSING', 'CANCELLED')}>
                                {t(`paymentRequest:btn.failed`)}
                                </span>
                            </>
                        }
                        {
                            canChangeGroup &&
                            <>
                                {
                                    (lodash.includes(listCanChangeState, 'CANCELLED') || lodash.includes(listCanChangeState, 'SUCCESS')) &&
                                    <Divider type='vertical'/>
                                }
                                <ForwardPaymentRequest
                                    request={request}
                                    user={user}
                                    groups={groups}
                                    oldStatus={"PROCESSING"}
                                    status={"WAITING"}
                                />
                            </>
                        }
                    </div>
                );
            case "NONE":
                return (
                    <>
                        {
                            lodash.includes(listCanChangeState, 'WAITING') &&
                            <a href={url.to("payment-request.confirmation") + "?id=" + request.id} target={"_blank"}
                               className="a-text--color-primary a--cursor-poiter _payment-request-action-approve"
                            >
                                {t(`paymentRequest:action.confirm`)}
                            </a>
                        }
                    </>
                );
            default:
                return null;
        }
    }

    render() {
        return this.renderAction();
    }
}

export default translate()(ProcessPaymentRequest);
