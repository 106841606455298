import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import Spinner from '@atlaskit/spinner';
import FormProcessing from './../../../common/components/FormProcessing';

class ComplaintMarkImportant extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                id: '',
                is_important: 0
            },
            inputVersion: 0,
            changedParams: [],
        };
    }

    /**
     * Cập nhật dữ liệu thay đổi khi đánh dấu quan trọng
     * @param event
     */
    onHandleChange(value, event) {
        let {isLoading, onUpdateMarkImportant, complaint} = this.props;
        this.changeInput({is_important: value});
        if (!lodash.includes(isLoading, complaint.id)) {
            onUpdateMarkImportant({is_important: value});
        }
    }

    render() {
        let {isLoading, complaint, showLoading, canMark} = this.props;
        let {input, inputVersion} = this.state;
        return (
            <React.Fragment>
                <div key={inputVersion}
                    className={"mark_important ml-2 a-text--fz-12"+(lodash.includes(isLoading,complaint.id) || !canMark ? 'a-disable':'')}>
                    {lodash.get(input, 'is_important', false)
                        ?
                        <i className="fas fa-star" onClick={this.onHandleChange.bind(this, 0)}/>
                        :
                        <i className="far fa-star unmark" onClick={this.onHandleChange.bind(this, 1)}/>
                    }
                    {lodash.includes(isLoading, complaint.id) && showLoading ?
                        <React.Fragment>&nbsp;<Spinner size="small"/></React.Fragment> : ""}
                </div>
            </React.Fragment>
        );
    }
}

ComplaintMarkImportant.defaultProps = {
    isLoading: [],
    showLoading: false,
    canMark: false,
    onUpdateMarkImportant: () => {
    }
};

ComplaintMarkImportant.protoType = {
    isLoading: PropTypes.array,
    showLoading: PropTypes.bool,
    canMark: PropTypes.bool,
    onUpdateMarkImportant: PropTypes.func,
};

export default translate('translations')(ComplaintMarkImportant);
