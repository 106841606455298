import React from "react";
import {Icon, Modal, Input} from "antd";
import apiService from "../../apiService";
import {processResponse} from "../../../../../systems/api";
import notification from '../../../../../systems/notification';
import {translate} from "react-i18next";
import lodash from "lodash";

const {TextArea} = Input;

class DislikeButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            note: "",
            noteIsValid: false,
            loading: false
        }
    }

    onClick = e => {
        this.setState({visible: true});
    }

    onOK = e => {
        this.setState({
            loading: true
        })
        let {seller, t, updateOrderDetail} = this.props;

        let res = apiService.dislikeSeller(seller.id, {vote: 0, note: this.state.note});
        processResponse(res)
            .then(res => {
                updateOrderDetail(res);
                notification.success("Đánh dấu NCC xấu thành công");
                this.resetState();
            })
            .catch(err => {
                notification.error("Đánh dấu NCC xấu không thành công");
                this.resetState();
            });
    }

    onCancel = e => {
        this.resetState();
    }

    resetState = () => {
        this.setState({
            visible: false,
            note: "",
            noteIsValid: false,
            loading: false
        })
    }

    onChange = e => {
        let value = e.target.value;

        while (value.indexOf(" ") >= 0)
            value = value.replace(" ", "");

        this.setState({
            note: e.target.value,
            noteIsValid: value.length > 0 ? true : false
        });
    }

    render() {
        let {visible, noteIsValid, note, loading} = this.state;
        let {seller} = this.props;
        let votes = lodash.get(seller, 'votes', []);

        return (
            <div>
                <a onClick={this.onClick}><Icon type="dislike" className="a-text--fz-10" /> Đánh dấu ({votes.length})</a>
                <Modal
                    title="Lý do đánh dấu"
                    centered={true}
                    visible={visible}
                    onOk={this.onOK}
                    onCancel={this.onCancel}
                    okText="Đánh dấu"
                    cancelText="Hủy"
                    okButtonProps={{disabled: !noteIsValid, loading}}
                >
                    <TextArea
                        placeholder="Nhập lý do đánh dấu"
                        value={note}
                        onChange={this.onChange}
                        style={{height: "100px"}}
                    />
                </Modal>
            </div>
        );
    }
}

export default translate()(DislikeButton);