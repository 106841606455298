import React from "react";
import PropTypes from "prop-types";
import {Col, Icon, Row, Switch} from "antd";
import lodash from "lodash";
import {translate} from "react-i18next";
import { t } from "../../../../systems/i18n";

class SellerSourceByPartner extends React.Component {

    constructor(props) {
        super(props);
        let {systemSellerSources} = this.props;
        this.state = {
            sellerSources: lodash.get(systemSellerSources, 'value', []).split(",")
        }
    }

    onChangeSwitch(source, value) {
        let {sellerSources} = this.state;
        let {systemSellerSources} = this.props;
        sellerSources = sellerSources.filter(item => item);

        if (value)
            sellerSources.push(source);
        else {
            let index = sellerSources.indexOf(source);
            sellerSources.splice(index, 1);
        }

        this.setState({sellerSources}, () => {
            let {sellerSources} = this.state;
            this.props.onChange({code: systemSellerSources.code, value: sellerSources.join()});
        });
    }

    render() {
        let {configSetting} = this.props;
        let {sellerSources} = this.state;
        let listSellerSources = lodash.get(configSetting, "sellerSources", []);

        return (
            <Col xs={{span: 12}} md={{span: 12}}>
                <div className="a-content--box-shadow p-0 mb-5">
                    <div className="a-setting__title p-3">
                        <p>NGUỒN HÀNG</p>
                    </div>

                    <div className="a-setting__body p-3">
                        <Row className="d-flex setting-line">
                            <Col span={18} className="a-text--bold">Nguồn hàng</Col>
                            <Col span={6} className="a-text--bold a-text--right">Trạng thái</Col>
                        </Row>
                        {
                            listSellerSources.map((item, index) => {
                                return (
                                    <Row className="d-flex setting-line" key={index}>
                                        <Col span={18}><p className="a-font--13">{t(`order:ORDER_SOURCE.${item}`)}</p></Col>
                                        <Col span={6} className="a-text--right">
                                            <Switch
                                                checkedChildren={<Icon type="check" />}
                                                unCheckedChildren={<Icon type="close" />}
                                                checked={sellerSources.includes(item)}
                                                onChange={value => this.onChangeSwitch(item, value)}
                                                size="small"
                                            />
                                        </Col>
                                    </Row>
                                );
                            })
                        }
                    </div>
                </div>
            </Col>
        );
    }
}

SellerSourceByPartner.defaultProps = {
    updateConfigSystem: (params) => {},
    configSetting: {}
};

SellerSourceByPartner.propTypes = {
    updateConfigSystem: PropTypes.func,
    configSetting: PropTypes.object
};

export default translate()(SellerSourceByPartner);