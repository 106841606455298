
import cookies from 'react-cookies';

/**
 *
 * Middleware dùng để pub token cho công cụ autopai sử dụng
 *
 * @param payload
 * @param next
 * @returns {*}
 */
let pubToken = (payload, next) => {
    let token = cookies.load('token_customer');
    if(token) {
        let data = { type: "SEND_USER_TOKEN", token: token};
        window.postMessage(data, "*");
    }
    return (next(payload));
};

export default pubToken;