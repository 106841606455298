import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {Select} from 'antd';
import {allAccountPurchaserService} from '../services';

const {Option} = Select;

class SelectAccountPurchasers extends React.PureComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            accounts: [],
            loading: false,
        };
    }
    
    componentDidMount() {
        this.fetchAccountPurchasers();
    }
    
    fetchAccountPurchasers() {
        this.setState({loading: true});

        allAccountPurchaserService.listActive()
            .then(accounts => this.setState({accounts}))
            .finally(() => this.setState({loading: false}));
    }

    getValue() {
        let {value} = this.props;

        if (!value) {
            return value;
        }

        return lodash.isArray(value)
            ? value.map(val => parseInt(val, 0))
            : parseInt(value, 0);
    }

    render() {
        let {t} = this.props;
        return (
            <Select
                showSearch={true}
                value={this.props.value}
                mode={this.props.multiple ? 'multiple' : 'default'}
                loading={this.state.loading}
                onChange={this.props.onChange}
            >
                {this.props.allOption && <Option value="">{t('label.all')}</Option>}
                {this.state.accounts.map(account => <Option key={account.id} value={account.account}>{account.account}</Option>)}
            </Select>
        );
    }
}

SelectAccountPurchasers.defaultProps = {
    value: null,
    allOption: false,
    multiple: false,
    onChange: value => {},
};

SelectAccountPurchasers.propTypes = {
    value: PropTypes.any,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
};

export default translate()(SelectAccountPurchasers);
