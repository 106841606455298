import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from './actions';
import InputAddInvoiceCode from './components';
import lodash from 'lodash';

const mapStateToProps = (state, props) => {
    return {
        loading: props.loading  === true || lodash.get(state, 'Transaction.InputAddInvoiceCode.loading', false)
    }
};

const mapDispatchToProps = (dispatch) => ({
    addInvoiceCode2Transaction: (datas) => dispatch(actions.addInvoiceCode2Transaction(datas)),
    clearState: () => dispatch(actions.clearState()),
});

class InputAddInvoiceCodeWapper extends React.PureComponent {
    render() {
        return <InputAddInvoiceCode {...this.props}/>
    }
}

let InputAddInvoiceCodeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(InputAddInvoiceCodeWapper);

InputAddInvoiceCodeContainer.defaultProps = {
    loading: false,
    idTransaction: 0,
};

InputAddInvoiceCodeContainer.propTypes = {
    loading: PropTypes.bool,
    idTransaction: PropTypes.number,
    callbackOnChange: PropTypes.func,
};

export default InputAddInvoiceCodeContainer;
