import React, {Component} from 'react';
import {translate} from "react-i18next";
import {withEvent} from "../../systems/events";
import {dispatch} from "../../systems/store";
import {setBreadCrumb, setMenuActive, setPageDescription, setPageTitle} from "../../modules/common/actions";
import {setActiveSettingItem} from "../../modules/Settings/SettingMenu/actions";
import {Button, Col, Divider, Row, Spin, Table, Switch, Icon, notification} from "antd";
import FormUpdateGroup from "./components/FormUpdateGroup";
import {processResponse} from "../../systems/api";
import apiService from "../../modules/PaymentRequest/apiService";
import lodash from 'lodash';
import {Link, url} from "../../systems/routing";


class GroupManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            loading: false,
            visible: false,
            groupInfo: {}

        };
    }

    componentDidMount() {
        this.getListGroup();
        this.getPageInfo();
    }

    getListGroup = () => {
        const {t} = this.props;
        this.setState({
            loading: true
        });
        processResponse(apiService.getPaymentRequestGroups())
            .then(res => {
                this.setState({groups: lodash.get(res, "data")});
            }).catch(() => {
            notification.error({message: t('message.cant_fetch_data')});
        }).finally(() => {
            this.setState({loading: false})
        });
    };

    getPageInfo() {
        let {t} = this.props;

        dispatch(setPageTitle(t('group:page.title')));
        dispatch(setPageDescription(t('group:page.description')));
        dispatch(setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'group:page.title',
                'link': '/group-manage'
            },
        ]));
        dispatch(setMenuActive('group_manage'));
        setActiveSettingItem('group_manage');
    }

    onChangeStatus = (groupId, status) => {
        const {t} = this.props;
        const {groups} = this.state;
        let groupsNew = groups.slice();
        const indexOfGroupId = lodash.findIndex(groupsNew, ["id", groupId]);
        processResponse(apiService.changeStatusPaymentRequestGroup(groupId, {status: status ? 0 : 1}))
            .then(res => {
                if (indexOfGroupId > -1) {
                    groupsNew[indexOfGroupId] = lodash.get(res, "data.paymentRequestGroup");
                    this.setState({groups: groupsNew});
                    notification.success({message: t('group:messages.change_status_success')});
                }
            }).catch(() => {
            notification.error({message: t('message.cant_update_data')});
        })
    };

    getColumns = () => {
        const {t} = this.props;
        return ([
            {
                title: t("group:title.group.code"),
                dataIndex: 'code',
                key: 'code',
                render: (text, record) => <p>
                    <Link href={url.to('group_manage_detail', {groupId: record.id})}
                          className="link_item">{record.code}</Link>
                </p>
            },
            {
                title: t("group:title.group.name"),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: t("group:title.group.description"),
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: t("group:title.group.status"),
                key: 'status',
                render: (text, record) => {
                    return (
                        <Switch checked={!!record.status}
                                onChange={() => this.onChangeStatus(record.id, record.status)}/>
                    );
                }
            },
            {
                key: 'action',
                render: (text, record) => <a className="ml-4" onClick={() => this.openModal("edit", record)}><Icon
                    type="edit"/></a>
            }
        ]);
    };

    openModal = (action = "add", groupInfo = {}) => {
        this.setState({
            visible: true,
            groupInfo
        })
    };

    closeModal = () => {
        this.setState({
            visible: false,
            groupInfo: {}
        })
    };

    render() {
        const {t} = this.props;
        const {groups, loading, groupInfo, visible} = this.state;
        return (
            <div className="a-content__right">
                <div className="a-content--box-shadow">
                    <Row>
                        <Col span={12} className="d-flex align-item-center">
                            <p className="a-text--bold a-text--color-black">{t("group:label.list").toUpperCase()} ({groups.length})</p>
                        </Col>
                        <Col span={12}>
                            <p className="a-text--right">
                                <Button type="primary" icon="plus-square"
                                        onClick={this.openModal}>{t("btn.add")}</Button>
                            </p>
                        </Col>
                    </Row>
                    <Divider className="mt-3 mb-0"/>
                    <Table
                        dataSource={groups}
                        columns={this.getColumns()}
                        pagination={false}
                        rowKey="id"
                        loading={loading}
                        className="pt-4"
                    />
                </div>
                {
                    visible &&
                    <FormUpdateGroup
                        groupInfo={groupInfo}
                        visible={visible}
                        closeModal={this.closeModal}
                        getListGroup={this.getListGroup}/>
                }
            </div>

        );
    }
}

export default translate()(withEvent(GroupManage));