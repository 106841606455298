import {put} from 'redux-saga/effects';
import lodash from 'lodash';
import {processApiRequest} from '../../common/saga';
import * as ACTION from './constants';
import apiService from '../apiService';
import {t} from '../../../systems/i18n';
import bootbox from './../../common/components/Bootbox/bootbox';
import {dispatch} from './../../../systems/store';
import {fetchComplaintDetail, fetchComplaintLogs} from '../actions';

export function* failedComplaint(action) {
    yield processApiRequest(
        ACTION.FAILED_COMPLAINT,
        () => apiService.failedComplaint(action.payload.id, action.payload.input),
        action.payload
    );
}

export function* onFailedComplaintSuccess(action) {
    let {id} = action.request;
    yield put(fetchComplaintDetail({id}));
    setTimeout(() => dispatch(fetchComplaintLogs(id)), 2000);
    bootbox.success(t('complaintSeller:failed.message_set_failed_success'));
}

export function* onFailedComplaintFailed(action) {
    let errors = action.payload.data || {};

    if (lodash.isEmpty(errors) || errors.note_failure) {
        return;
    }

    if (errors.status) {
        return yield bootbox.error(t('complaintSeller:failed.message_status_invalid'));
    }

    return yield bootbox.error(t('complaintSeller:failed.message_status_invalid'));
}
