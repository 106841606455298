import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {translate} from 'react-i18next';
import {Form, Row, Col, Input, Button, Icon, Select} from 'antd';
import FormProcessing from '../../../modules/common/components/FormProcessing';
import {STATUS_LIST} from "../constants";

const {Option} = Select;

class FormSearch extends FormProcessing {
    constructor(props) {
        super(props);

        this.defaultInput = {
            outer_purchase_id: "",
            purchase_ids: "",
            order_code: "",
            status: "",
        };

        this.state = {
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
        };
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.props.onSearch({...this.state.input, page_no: 1});
        }
    }


    onReset(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.changeInput(this.defaultInput);
            this.props.onSearch(this.defaultInput);
        }
    }

    onChangeText(param, event) {
        const {value} = event.target;
        if (param === 'purchase_ids') {
            const value_array = value ? lodash.split(value, ' ') : undefined;
            this.changeInput({[param]: value_array});
        } else {
            this.onChangeInput(param, event);
        }
    }

    onChangeSelect(param, value) {
        this.changeInput({[param]: value});
    }

    render() {
        let {t} = this.props;
        let {input} = this.state;

        return (
            <div className="a-list--form-search mr-0 ml-0">
                <Row gutter={32}>
                    <Form onSubmit={this.onSubmit.bind(this)} labelCol={{span: 12}}>
                        <Col lg={6} xl={6} xxl={5}>
                            <Form.Item label={t('order:label.outer_purchase_id')} className="a-form--ant-item  mb-0">
                                <Input
                                    value={input.outer_purchase_id || ''}
                                    placeholder={t('order:placeholder.outer_purchase_id')}
                                    onChange={this.onChangeText.bind(this, 'outer_purchase_id')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={6} xxl={4}>
                            <Form.Item label={t('order:label.order_code')} className="a-form--ant-item  mb-0">
                                <Input
                                    value={input.order_code || ''}
                                    placeholder={t('order:placeholder.code')}
                                    onChange={this.onChangeText.bind(this, 'order_code')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={6} xxl={4}>
                            <Form.Item label={t('order:label.invoice_code')} className="a-form--ant-item  mb-0">
                                <Input
                                    value={input.invoice_code || ''}
                                    placeholder={t('order:placeholder.invoice_code')}
                                    onChange={this.onChangeText.bind(this, 'invoice_code')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={6} xxl={5}>
                            <Form.Item label={t('order:label.purchase_ids_taobao')} className="a-form--ant-item  mb-0">
                                <Input
                                    value={lodash.isArray(input.purchase_ids) ? input.purchase_ids.join(' ') : undefined}
                                    placeholder={t('order:placeholder.purchase_ids_taobao')}
                                    onChange={this.onChangeText.bind(this, 'purchase_ids')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={6} xxl={6}>
                            <Form.Item label={t('order:label.status')} className="a-form--ant-item  mb-0">
                                <Select
                                    value={input.status}
                                    showSearch={true}
                                    placeholder={t("order:placeholder.status")}
                                    onChange={this.onChangeSelect.bind(this, "status")}
                                >
                                    <Option value="">{t('label.all')}</Option>
                                    {STATUS_LIST.map(source => <Option key={source}
                                                                       value={source}>{t(`order:ORDER_TAOBAO_STATUS.${source}`)}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={24} xl={24} xxl={24} className="mt-4 pt-5 border-top-dashed">
                            <div className="a-list--action-search flex-row justify-content-end">
                                <a href="#"
                                   className="link-reset mr-5"
                                   onClick={this.onReset.bind(this)}
                                ><Icon type="sync"/>{t('btn.reset')}</a>

                                <Button
                                    htmlType="submit"
                                    className="a-text--uppercase a-btn--padding-large a-btn--primary-ant"
                                    loading={this.props.loading}
                                >{t('btn.search')}</Button>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </div>

        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: null,
    loading: false,
    onSearch: (input) => {
    },
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.any,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

export default translate()(FormSearch);
