import lodash from "lodash";
import {translate} from "react-i18next";
import {Select, Switch, Icon, Col, Row, Checkbox, Input, Radio} from "antd";
import React from 'react';
import DatetimePicker from "../../../common/components/DatetimePicker";
import UploadLogo from "./UploadLogo";
import InputNumber from '../../../common/components/Input/Super';
import PropTypes from 'prop-types';
import {
    UPLOAD_LOGO,
    TRANSACTION_TYPE_FINALI_ORDER,
    ORDER_DISTRIBUTOR_MAX_WAITING_ITEM_STATUS,
    SYSTEM_CAN_EXPORTED_TOKEN,
    ORDER_DISTRIBUTOR_TYPE
} from "../constants";

const Option = Select.Option;

const PROFIT_TYPES = {
    'MIN': 'Dựa theo số nhỏ hơn (Doanh số = MIN ([1], [2]))',
    'ALIPAY': 'Dựa theo số giao dịch alipay (Doanh số = Tổng thu khách (tức = tiền hàng + phí VCNĐ) - Alipay chi + Alipay hoàn [2])',
    'USER': 'Dựa theo số user điền (Doanh số = Tổng thu khách (tức = tiền hàng + phí VCNĐ) - Thực trả (user điền) + Thực hoàn (user điền) [1])'
}

class GroupItem extends React.Component {
    constructor(props) {
        super(props);
        let {groupItem} = this.props;

        let dateTime = {start: "", end: ""};
        let value = groupItem.data_type !== 'array' ? groupItem.value : groupItem.value === "" ? [] : groupItem.value.split(',');

        if (this.isJson(groupItem.value)) {
            dateTime = JSON.parse(groupItem.value);
        }

        this.state = {
            checked: !!parseInt(groupItem.value, 10),
            value: value,
            start: dateTime.start,
            end: dateTime.end,
            dateTime: dateTime,
            isUserSetting: groupItem.is_user_setting,
            invalidDateError: '',
        }
    }

    /**
     * Check string is valid json
     * @param string
     * @returns {boolean}
     */
    isJson(string) {
        try {
            JSON.parse(string);
        } catch (e) {
            return false;
        }
        return true;
    }

    /**
     * Update value checkbox
     * @param name
     * @param event
     */
    onClickCheckbox(name, value) {
        this.props.onChange({code: name, value: value ? 1 : 0});
    }

    onChangeInput(name, value) {
        this.setState({value: value});
        this.props.onChange({code: name, value: value});
    }

    onChangeUserSetting(name, event) {
        let isUserSetting = event.target.checked;
        this.setState({isUserSetting: isUserSetting});
        this.props.onChange({code: name, is_user_setting: isUserSetting ? 1 : 0});
    }

    onChangeMultiSelect(name, value) {
        this.setState({value});
        value = lodash.join(value);
        this.props.onChange({code: name, value: value})
    }

    onChangeDate(name, date) {
        let {dateTime} = this.state;
        this.setState({...date, dateTime: {...dateTime, ...date}});
        let updatedDateTime = {...dateTime, ...date};
        if (this.validateTimeRange(updatedDateTime, name)) {
            this.props.onChange({code: name, value: updatedDateTime});
        }
    }

    onChangRadio = (e) => {
        let {groupItem} = this.props;
        this.setState({value: e.target.value});
        this.props.onChange({code: groupItem.code, value: e.target.value})
    };

    onChangeSelect(name, value) {
        this.setState({value: value});
        this.props.onChange({code: name, value: value});
    }

    /**
     * validate time start to end
     * @param updatedDateTime
     * @param code
     */
    validateTimeRange(updatedDateTime, code) {
        let {start, end} = updatedDateTime;
        let hasError = false;
        if ((!start && end) || (start && !end)) {
            this.setState({
                invalidDateError: "lack"
            });
            this.props.setError(code);
            hasError = true;
        }

        if (start > end && start && end) {
            this.setState({
                invalidDateError: 'invalid'
            });
            this.props.setError(code);
            hasError = true;
        }
        if (hasError) {

            return false;
        } else {
            this.setState({
                invalidDateError: ''
            });
            this.props.setError('');
            return true;
        }

    }

    onChangeFormatAddress(code, e) {
        const value = e.target.value;
        this.setState({value});
        this.props.onChange({code, value: value});
    }

    render() {
        let {value, start, end, invalidDateError} = this.state;
        let {
            groupItem,
            t,
            onChange,
            isUsing,
            configSetting,
            statusUsing // statusUsing: thuộc tính này cho biết 1 nhóm cấu hình có được active ko để cho phép cập nhật các cấu hình liên quan đến nhóm này
        } = this.props;
        let dataType = lodash.get(groupItem, 'data_type');
        let elements;
        if (dataType === 'array') {
            elements = lodash.get(groupItem, 'elements', []);
        }
        let applyUserSetting = lodash.get(groupItem, 'apply_user_setting', false);
        let isUserSetting = lodash.get(groupItem, 'is_user_setting', false);
        let profit_types = lodash.get(configSetting, 'orderProfitTypes', []);
        let listStatusBeforeBought = lodash.get(configSetting, 'beforeStatusBought', []);
        let autoTestClassname = `_config-setting-${(groupItem.code ? groupItem.code : "").replace(":", "_").toLowerCase()}`;
        return (
            <>
                {
                    (() => {
                        switch (groupItem.code) {
                            case UPLOAD_LOGO:
                                return (
                                    <Row className="d-flex setting-line">
                                        <Col span={24}>
                                            <UploadLogo
                                                onChange={onChange}
                                                isUsing={isUsing}
                                                code={groupItem.code}
                                                value={groupItem.value}
                                            />
                                        </Col>
                                    </Row>
                                );
                            case "PROFIT:TYPES":
                                return (
                                    <>
                                        <Row className="d-flex setting-line align-item-center">
                                            <Col span={24}>
                                                <p className="a-font--13">
                                                    {t(`setting:description.${groupItem.code}`).split("<br/>").map((title, index) =>
                                                        <p key={index}>{title}</p>)}
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Radio.Group
                                                    value={value}
                                                    disabled={!isUsing}
                                                    defaultValue={'MIN'}
                                                    onChange={this.onChangRadio}
                                                >
                                                    {
                                                        profit_types.map((type, index) => <Radio key={index} style={{
                                                            whiteSpace: "normal",
                                                            marginTop: "5px"
                                                        }} value={type}>{PROFIT_TYPES[type]}</Radio>)
                                                    }
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                    </>
                                );
                            case ORDER_DISTRIBUTOR_MAX_WAITING_ITEM_STATUS:
                                return (
                                    <Row className="d-flex setting-line align-item-center">
                                        <Col span={16}>
                                            <div className="a-font--13">
                                                {t(`setting:description.${groupItem.code}`).split("<br/>").map((title, index) =>
                                                    <p key={index}>{title}</p>)}
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <Select
                                                style={{width: '100%'}}
                                                placeholder={t(`setting:placeholder.${groupItem.code}`)}
                                                value={value ? value : undefined}
                                                onChange={this.onChangeSelect.bind(this, groupItem.code)}
                                                optionLabelProp="label"
                                                showSearch={true}
                                                optionFilterProp={"children"}
                                                className={autoTestClassname}
                                            >
                                                {
                                                    listStatusBeforeBought.map((item) =>
                                                        <Option key={item} value={item}
                                                                label={t(`order:status.${item}`)}>
                                                            {t(`order:status.${item}`)}
                                                        </Option>)
                                                }
                                            </Select>
                                        </Col>
                                    </Row>

                                );
                                case ORDER_DISTRIBUTOR_TYPE:
                                    return (
                                        <Row className="d-flex setting-line align-item-center">
                                            <Col span={16}>
                                                <div className="a-font--13">
                                                    {t(`setting:description.${groupItem.code}`).split("<br/>").map((title, index) =>
                                                        <p key={index}>{title}</p>)}
                                                </div>
                                            </Col>
                                            <Col span={8}>
                                                <Select
                                                    style={{width: '100%'}}
                                                    placeholder={t(`setting:placeholder.${groupItem.code}`)}
                                                    value={value ? value : undefined}
                                                    onChange={this.onChangeSelect.bind(this, groupItem.code)}
                                                    optionLabelProp="label"
                                                    showSearch={true}
                                                    optionFilterProp={"children"}
                                                    className={autoTestClassname}
                                                >
                                                    
                                                    <Option  
                                                        value={'random'}
                                                        label= {t(`setting:random`)}
                                                    >
                                                         {t(`setting:random`)}
                                                    </Option>

                                                    <Option  
                                                        value={'equally'}
                                                        label= {t(`setting:equally`)}
                                                    >
                                                         {t(`setting:equally`)}
                                                    </Option>
                                                         
                                                    
                                                </Select>
                                            </Col>
                                        </Row>
    
                                    );
                            case "INTERNATIONAL_WAREHOUSE:FORMAT_ADDRESS":
                            case "TAOBAO:FORMAT_ADDRESS":
                            case "TAOBAO:FORMAT_RECEIVER_NAME":
                                return <Row className="d-flex setting-line align-item-center">
                                    <Col span={8}>
                                        <div className="a-font--13">
                                            {t(`setting:description.${groupItem.code}`).split("<br/>").map((title, index) =>
                                                <p key={index}>{title}</p>)}
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Input
                                            name={'settings'}
                                            value={value}
                                            style={{width: "100%"}}
                                            placeholder={t('setting:placeholder.' + groupItem.code)}
                                            onChange={(e) => this.onChangeFormatAddress(groupItem.code, e)}
                                        />
                                    </Col>
                                </Row>;
                            default:
                                return (
                                    <Row className="d-flex setting-line align-item-center">
                                        <Col span={20}>
                                            <div className="a-font--13">
                                                {t(`setting:description.${groupItem.code}`).split("<br/>").map((title, index) =>
                                                    <p key={index}>{title}</p>)}
                                            </div>
                                        </Col>
                                        <Col span={4} className="a-text--right">
                                            {
                                                dataType === "bool" ?
                                                    <Switch
                                                        checkedChildren={<Icon type="check"/>}
                                                        unCheckedChildren={<Icon type="close"/>}
                                                        checked={parseInt(groupItem.value) ? true : false}
                                                        onChange={value => this.onClickCheckbox(groupItem.code, value)}
                                                        size="small"
                                                        disabled={!statusUsing ? !isUsing : false}
                                                        className={autoTestClassname}
                                                    /> : null
                                            }
                                            {
                                                dataType === "int" ?
                                                    <InputNumber
                                                        integer
                                                        disabled={!isUsing}
                                                        className={`ant-input ${isUsing ? "" : "disable"} ${autoTestClassname}`}
                                                        name={'settings'}
                                                        value={value}
                                                        style={{width: "100%"}}
                                                        onChange={this.onChangeInput.bind(this, groupItem.code)}
                                                    /> : null
                                            }
                                            {
                                                dataType === "float" ?
                                                    <InputNumber
                                                        disabled={!isUsing}
                                                        className={`ant-input ${isUsing ? "" : "disable"} ${autoTestClassname}`}
                                                        name={'settings'}
                                                        value={value}
                                                        style={{width: "100%"}}
                                                        onChange={this.onChangeInput.bind(this, groupItem.code)}
                                                    /> : null
                                            }
                                            {
                                                (dataType === "string" && (groupItem.code !== UPLOAD_LOGO && groupItem.code !== SYSTEM_CAN_EXPORTED_TOKEN)) ?
                                                    <Input
                                                        disabled={!isUsing}
                                                        name={'settings'}
                                                        value={value}
                                                        style={{width: "100%"}}
                                                        placeholder={t('setting:placeholder.' + groupItem.code)}
                                                        onChange={this.onChangeInput.bind(this, groupItem.code)}
                                                        className={autoTestClassname}
                                                    /> : null
                                            }

                                        </Col>
                                    </Row>
                                );
                        }
                    })()
                }
                {
                    applyUserSetting ?
                        <Row className="d-flex setting-line">
                            <Col span={20} className="d-flex align-item-center">
                                <p className={`a-font--13 ${isUsing ? "" : "disable-title"} `}>{t('setting:apply')}</p>
                            </Col>
                            <Col span={4} className="d-flex align-item-center flex-end">
                                <Checkbox
                                    defaultChecked={isUserSetting}
                                    disabled={!isUsing}
                                    checked={this.state.isUserSetting}
                                    onChange={e => this.onChangeUserSetting(groupItem.code, e)}
                                    className={autoTestClassname}
                                />
                            </Col>
                        </Row> : null
                }
                {/*{*/}
                {/*    dataType === "period" ?*/}
                {/*        <React.Fragment>*/}
                {/*            <Col xs={12} sm={12} md={12} lg={8} xl={8}*/}
                {/*            >*/}
                {/*                <div className={"d-flex justify-content-start align-items-center "}>*/}
                {/*                    <div className=" width-input-setting ">*/}
                {/*                        <DatetimePicker*/}
                {/*                            className={`${isUsing ? "" : "disable" }`}*/}
                {/*                            name={'start'}*/}
                {/*                            date={start}*/}
                {/*                            placeholderText={t('setting:placeholder.start')}*/}
                {/*                            onSubmit={this.onChangeDate.bind(this, groupItem.code)}*/}
                {/*                        />*/}
                {/*                    </div>*/}

                {/*                    <i className="fas fa-long-arrow-alt-right ml-2 mr-2"></i>*/}
                {/*                    <div className="width-input-setting ">*/}
                {/*                        <DatetimePicker*/}
                {/*                            className={`${isUsing ? "" : "disable" }`}*/}
                {/*                            name={'end'}*/}
                {/*                            date={end}*/}
                {/*                            placeholderText={t('setting:placeholder.end')}*/}
                {/*                            onSubmit={this.onChangeDate.bind(this, groupItem.code)}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <p className={`text-error mt-1 ${invalidDateError ? "" : "hidden" }`}>{t('setting:datetime.' + invalidDateError)}</p>*/}
                {/*                <p className={"a-font--11 mt-2 "}>{t(`setting:description.${groupItem.code}`)}</p>*/}
                {/*            </Col>*/}

                {/*        </React.Fragment> : null*/}
                {/*}*/}
                {/*{*/}
                {/*    dataType === "array" ?*/}
                {/*        <Row className="setting-line">*/}
                {/*            <Col span={24} className="d-flex align-item-center">*/}
                {/*                <p className="a-font--13 mb-2">{t(`setting:description.${groupItem.code}`)}</p>*/}
                {/*            </Col>*/}
                {/*            <Col span={24} className="a-text--right d-flex align-item-center">*/}
                {/*                <Select*/}
                {/*                    mode="multiple"*/}
                {/*                    style={{ width: '100%' }}*/}
                {/*                    placeholder="Chọn loại giao dịch"*/}
                {/*                    disabled={!isUsing}*/}
                {/*                    value={value}*/}
                {/*                    onChange={this.onChangeMultiSelect.bind(this, groupItem.code)}*/}
                {/*                    optionLabelProp="label"*/}
                {/*                    showSearch={true}*/}
                {/*                    optionFilterProp={"children"}*/}
                {/*                >*/}
                {/*                    {*/}
                {/*                        elements.map(element =>*/}
                {/*                            <Option value={element.key} label={element.value}>*/}
                {/*                                {element.value}*/}
                {/*                            </Option>)*/}
                {/*                    }*/}
                {/*                </Select>*/}
                {/*            </Col>*/}
                {/*        </Row> : null*/}
                {/*}*/}
            </>
        );
    }
}

GroupItem.defaultProps = {
    onChange: () => {
    },
    onBlur: () => {
    },
    setError: () => {
    },
    input: {},
    statusUsing: false
};

GroupItem.propTypes = {
    statusUsing: PropTypes.bool
};


export default translate('translations')(GroupItem);
