import React from 'react'
import { Button, Layout, Modal } from 'antd'
import { translate } from 'react-i18next'
import lodash from 'lodash'
import notification from '../../systems/notification'

import FormSearch from './FormSearch'
import PaymentRequestList from './PaymentRequestList'
import SearchableComponent from '../../modules/common/SearchableComponent'

import { processResponse } from '../../systems/api'
import apiService from '../../modules/PaymentRequest/apiService'
import { withEvent } from '../../systems/events'
import { getState } from '../../systems/store'

import { dispatch } from '../../systems/store'
import { setBreadCrumb, setMenuActive, setPageDescription, setPageTitle } from '../../modules/common/actions'
import PAYMENT_REQUEST_EVENT from '../../modules/PaymentRequest/events'
import SubscribePaymentRequest from './SubscribePaymentRequest'
import * as GlobalConstant from '../../systems/globalContant'
import { trans } from '../../systems/i18n'
import moment from 'moment'

const { Content } = Layout

const currentDay = moment('23:59:59', 'HH:mm:ss')
const oneMonthsAgo = moment('00:00:00', 'HH:mm:ss').subtract(1, 'M')
class PaymentRequest extends SearchableComponent {
    constructor(props) {
        super(props)

        this.state = {
            canExport: false,
            csvFilter: {},
            paymentRequests: [],
            pagination: {},
            listStatus: [],
            statsCount: {},
            loading: false,
            loadingCount: false,
            newPENDINGPaymentRequest: false,
            newWAITINGPaymentRequest: false,
            groups: [],
            visible: false,
            paymentIds: [],
            errorMessage: '',
        }

        this.defaultFilter = {
            page: 1,
            per_page: 20,
            created_at_from: oneMonthsAgo.format('YYYY-MM-DD 00:00:00'),
            created_at_to: currentDay.format('YYYY-MM-DD 23:59:59'),
        }

        this.changeCSVFilter = this.changeCSVFilter.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.getListGroupPaymentRequest()
        this.setPageInfo()

        this.props.on({
            [PAYMENT_REQUEST_EVENT.WAITING]: (event, payload) => {
                this.updateProcessRequest(payload)
            },
            [PAYMENT_REQUEST_EVENT.PROCESSING]: (event, payload) => {
                this.updateProcessRequest(payload)
            },
            [PAYMENT_REQUEST_EVENT.SUCCESS]: (event, payload) => {
                this.updateProcessRequest(payload)
            },
            [PAYMENT_REQUEST_EVENT.CANCELLED]: (event, payload) => {
                this.updateProcessRequest(payload)
            },
            [PAYMENT_REQUEST_EVENT.FAILED_FROM_SOURCE_SITE]: (event, payload) => {
                this.setState({
                    visible: true,
                    paymentIds: lodash.get(payload, 'paymentIds', []),
                    errorMessage: lodash.get(payload, 'errorMessage', []),
                })
            },
            [PAYMENT_REQUEST_EVENT.SUCCESS_FROM_SOURCE_SITE]: (event, payload) => {
                const filter = this.getFilter();
                this.pushFilter({
                    ...filter,
                    i: parseInt(filter.i || 0, 0) + 1,
                })
            },
        })
    }

    getListGroupPaymentRequest = () => {
        const { t } = this.props
        processResponse(apiService.getPaymentRequestGroups({ scope: 'basic' }))
            .then(res => {
                this.setState({ groups: lodash.get(res, 'data', []) })
            })
            .catch(() => {
                notification.error(t('message.cant_fetch_data'))
            })
    }

    updateProcessRequest(payload) {
        this.setState({
            ...this.state,
            statsCount: {
                ...this.state.statsCount,
                [payload.oldStatus]: lodash.get(this.state.statsCount, `${payload.oldStatus}`, '') - 1,
                [payload.request.status]: lodash.get(this.state.statsCount, `${payload.request.status}`, '') + 1,
            },
            paymentRequests: this.state.paymentRequests.filter(request => {
                return request.id !== payload.request.id
            }),
        })
    }
    setPageInfo() {
        let { t } = this.props

        dispatch(setPageTitle(t('paymentRequest:page.title')))
        dispatch(setPageDescription(t('paymentRequest:page.description')))
        dispatch(setMenuActive('payment_requests'))
        dispatch(
            setBreadCrumb([
                {
                    label: 'breadcrumb.home',
                    link: '/',
                },
                {
                    label: 'paymentRequest:page.title',
                    link: '/payment-requests',
                },
            ])
        )
    }

    onSearch = filter => {
        if (this.state.loading) {
            return
        }

        filter = filter.code ? lodash.pick(filter, ['i', 'code']) : filter
        if (filter.last_state_time_from || filter.last_state_time_to) {
            filter = { ...filter, status: 'SUCCESS' }
        }
        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        })
    }

    onChangeFilter(filter) {
        if (this.state.loading || this.state.crawling) {
            return
        }
        this.fetchPaymentRequest(filter)
        this.fetchCount(filter)
        this.changeCSVFilter(filter)
    }

    fetchCount(filter) {
        let { t } = this.props
        this.setState({ loadingCount: true })

        let res = apiService.getPaymentRequestCount({ ...filter }).finally(() => this.setState({ loadingCount: false }))

        let process = processResponse(res)

        process.then(res => {
            this.setState({
                statsCount: lodash.get(res, 'data.stats', {}),
            })
        })

        process.catch(() => {
            notification.error(t('message.cant_fetch_data'))
        })

        return process
    }

    fetchPaymentRequest(filter) {
        let { t } = this.props
        this.setState({ loading: true })

        let res = apiService.getPaymentRequests({ ...filter }).finally(() => this.setState({ loading: false }))

        let process = processResponse(res)

        process.then(res => {
            this.setState({
                canExport: lodash.get(res, 'data.canExport', false),
                paymentRequests: lodash.get(res, 'data.paymentRequests', []),
                pagination: lodash.get(res, 'data.pagination', {}),
                listStatus: lodash.get(res, 'data.listStatus', []),
            })
        })

        process.catch(() => {
            notification.error(t('message.cant_fetch_data'))
        })

        return process
    }

    getNewPaymentRequest = payload => {
        this.setState({
            ...this.state,
            statsCount: {
                ...this.state.statsCount,
                [payload.status]: lodash.get(this.state.statsCount, payload.status) + 1,
            },
            [`new${payload.status}PaymentRequest`]: true,
        })
    }

    checkNewPaymentRequest = status => {
        this.setState({
            [`new${status}PaymentRequest`]: false,
        })
    }

    changeCSVFilter(filter) {
        const csvFilter = lodash.pickBy({ ...this.getFilter(), ...filter }, lodash.identity)
        this.setState({ csvFilter })
    }

    onCloseModal = () => {
        this.setState({
            visible: false,
            paymentId: undefined,
            errorMessage: '',
        })
    }

    render() {
        let user = getState('User.me.user', {})
        const { groups, visible, paymentIds, errorMessage } = this.state
        let channels = [
            GlobalConstant.CHANNELS.PAYMENT_REQUEST_CREATE + '_' + user.id_partner,
            GlobalConstant.CHANNELS.ORDER_PAY_ERROR_BY_PAYMENT_REQUEST + '_' + user.id,
            GlobalConstant.CHANNELS.TAOBAO_BATCH_PAY_PURCHASE_ORDER_ERROR + '_' + user.id,
            GlobalConstant.CHANNELS.TAOBAO_BATCH_PAY_PURCHASE_ORDER_SUCCESS + '_' + user.id,
        ]
        let filter = this.getFilter()
        let formStatsParams = ['status']
        let groupsNotMe = lodash.filter(groups, item => {
            return item.id !== user.payment_request_group_id && item.status
        })
        return (
            <Layout>
                <Content className="a-content--ant-layout ">
                    <SubscribePaymentRequest channels={channels} paymentRequest={this.state.paymentRequests} getNewPaymentRequest={this.getNewPaymentRequest} />
                    <FormSearch
                        input={filter}
                        inputVersion={filter.i || 0}
                        loading={this.state.loading}
                        loadingCount={this.state.loadingCount}
                        onSearch={this.onSearch}
                        listStatus={this.state.listStatus}
                        status={lodash.pick(filter, formStatsParams)}
                        statsCount={this.state.statsCount}
                        newPENDINGPaymentRequest={this.state.newPENDINGPaymentRequest}
                        newWAITINGPaymentRequest={this.state.newWAITINGPaymentRequest}
                        checkNewPaymentRequest={this.checkNewPaymentRequest}
                        filter={filter}
                        changeCSVFilter={this.changeCSVFilter}
                        groups={this.state.groups}
                        defaultFilter={this.defaultFilter} 
                    />
                    <PaymentRequestList
                        paymentRequests={this.state.paymentRequests}
                        pagination={this.state.pagination}
                        filter={filter}
                        loading={this.state.loading}
                        onSearch={this.onSearch}
                        user={user}
                        csvFilter={this.state.csvFilter}
                        canExport={this.state.canExport}
                        groups={groupsNotMe}
                        fetchPaymentRequest={this.fetchPaymentRequest}
                    />
                    {visible && (
                        <Modal
                            title={trans('paymentRequest:modal.title.payment_sb_order_fail')}
                            maskClosable={false}
                            footer={[<Button onClick={this.onCloseModal}>{trans('btn.ok')}</Button>]}
                            visible={visible}>
                            <p style={{ color: 'red', fontWeight: 500 }}>
                                {trans('paymentRequest:modal.content.payment_sb_order_fail', { id: paymentIds.join(', '), errorMessage })}
                            </p>
                        </Modal>
                    )}
                </Content>
            </Layout>
        )
    }
}
export default translate()(withEvent(PaymentRequest))
