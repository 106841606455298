import {connect} from 'react-redux';
import OrderGroup from './components/';
import * as Selector from './selectors';
import * as SelectorAgency from '../../Agency/selectors';
import lodash from 'lodash';
import {
    fetchOrderGroup,
    createOrderGroup,
    fetchDetailOrderGroup,
    updateOrderGroup,
    updateStatusOrderGroup,
    cancelFormOrderGroup,
    resetErrorFormOrderGroup} from './../ListOrderGroup/actions';
import {setPageTitle, setPageDescription, setBreadCrumb, setMenuActive} from './../../common/actions';
import {getListAgencyAction} from '../../Agency/actions';

const mapStateToProps = (state) => {
    return {
        data: Selector.orderGroups(state),
        agencies: lodash.get(state, "Agency.list", []),
        dataDetail: Selector.dataOrderGroup(state),
        dataError: lodash.get(state, 'orderGroup.orderGroups.dataError', {}),
        permissionsList: lodash.get(state, 'User.me.user.permissions', [])
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchOrderGroup: data => dispatch(fetchOrderGroup(data)),
        onFetchAgency: data => dispatch(getListAgencyAction(data)),
        createOrderGroup: (data) => {
            dispatch(createOrderGroup(data));
        },
        onFetchDetailOrderGroup: (data) => {
            dispatch(fetchDetailOrderGroup(data));
        },
        onUpdateOrderGroup: (data) => {
            dispatch(updateOrderGroup(data));
        },
        onUpdateStatusOrderGroup: (data) => {
            dispatch(updateStatusOrderGroup(data));
        },
        onCancelFormOrderGroup: (data)=> {
            dispatch(cancelFormOrderGroup(data))
        },
        resetErrorFormOrderGroup: (data)=> {
            dispatch(resetErrorFormOrderGroup(data))
        },
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderGroup)
