import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {scroller} from 'react-scroll'
import AutoLinkText from 'react-autolink-text2';
import {trans} from '../../../../../systems/i18n';
import {Link} from '../../../../../systems/routing';

const PRODUCTION_ACTION = {
    COMMENT: 'PRODUCT_RETURN_COMMENT',
};

class ProductReturnCommentMessage extends React.Component {
    scrollTo(param) {
        scroller.scrollTo(param, {
            duration: 500,
            delay: 0,
            offset: -140,
            isDynamic: true,
            smooth: 'easeInOutQuart'
        })
    }

    render() {
        let {log} = this.props;
        switch (log.action) {

            case PRODUCTION_ACTION.COMMENT:
                let {message, collapse} = log;
                let type = lodash.get(log, 'payload.type', 'comment');
                let maxLength = 60; // con số 60 là áng chừng cho hiển thị ở màn hình có độ phân giải ngang = 1024
                let trimmedMessage = message.substr(0, maxLength);
                if (collapse && message.length > maxLength) {
                    trimmedMessage = trimmedMessage.substr(0, Math.min(trimmedMessage.length, trimmedMessage.lastIndexOf(" "))) + " ..."
                } else {
                    trimmedMessage = message;
                }
                if (type === 'image') {
                    return <a target="_blank" href={trimmedMessage}>
                        <img alt={'camera-icon'} style={{height: '50px'}} src={trimmedMessage}/>
                    </a>
                }
                return <AutoLinkText
                    text={trimmedMessage}
                    linkProps={{target: '_blank', rel: 'nofollow'}}
                />;

            default:
                return log.action;
        }
    }

    /**
     *
     * @param param
     * @returns {XML}
     * @private
     */
    _renderMessageCommentAddNoteItem(param = {}) {
        let {log} = this.props;
        let payload = param;
        return trans('order:log.ADD_NOTE_ITEM', {
            order: this.linkToOrder(lodash.get(payload, 'order')),
            item: <span className={"text-primary font-weight-bold"}>
                      <a className="a--cursor-poiter"
                         onClick={() => this.scrollTo(lodash.get(payload, 'order_item.partner_order_item_id'), null)}>
                          {'#' + lodash.get(payload, 'order_item.partner_order_item_id')}
                      </a>
                 </span>,
            note: lodash.get(log, 'message', null),
        });
    }

    linkToOrder({id, code} = {}) {
        return <Link to="purchase.buying.detail" params={{id}}>{code}</Link>;
    }
}

ProductReturnCommentMessage.defaultProps = {
    log: {},
};

ProductReturnCommentMessage.propTypes = {
    log: PropTypes.object,
};

export default translate()(ProductReturnCommentMessage);
