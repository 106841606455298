import React from 'react';
import {Form, Row, Col, Input, Select, DatePicker, Button, Icon} from 'antd';

const Search = Input.Search;
const Option = Select.Option;
const {RangePicker} = DatePicker;


class FormSearch extends React.Component {
    render() {
        return (
            <div className="a-list--form-search mr-0 ml-0">
                <Row gutter={32}>
                    <Form labelCol={{span: 12}}>
                        <Col lg={6} xl={5} xxl={4}>
                            <Form.Item label="Mã GD / Nhà CC / Mã đơn gốc" className="a-form--ant-item  mb-0">
                                <Search/>
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={4} xxl={4}>
                            <Form.Item label="Tài khoản giao dịch" className="a-form--ant-item  mb-0">
                                <Select placeholder="Lựa chọn tài khoản">
                                    <Option value="jack">Jack</Option>
                                    <Option value="lucy">Lucy</Option>
                                    <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={4} xxl={4}>
                            <Form.Item label="Loaị giao dịch" className="a-form--ant-item  mb-0">
                                <Select placeholder="Lựa chọn loại giao dịch">
                                    <Option value="jack">Jack</Option>
                                    <Option value="lucy">Lucy</Option>
                                    <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={4} xxl={4}>
                            <Form.Item label="Người xử lý" className="a-form--ant-item  mb-0">
                                <Select placeholder="Chọn người xử lý">
                                    <Option value="jack">Jack</Option>
                                    <Option value="lucy">Lucy</Option>
                                    <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={7} xl={6} xxl={4}>
                            <Form.Item label="Thời gian giao dịch" className="a-form--ant-item  mb-0">
                                <RangePicker placeholder={['Từ ngày', 'Đến ngày']}/>
                            </Form.Item>
                        </Col>
                        <Col lg={7} xl={6} xxl={4}>
                            <Form.Item label="Thời gian xử lý" className="a-form--ant-item  mb-0">
                                <RangePicker placeholder={['Từ ngày', 'Đến ngày']}/>
                            </Form.Item>
                        </Col>
                        <Col xs={{span: 24}} lg={{span: 24}} xl={{span: 24}} className="mt-4 pt-5 border-top-dashed">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="a-list--action-search flex-row">
                                    <Button className=" a-font--12 a-btn--primary-ant ghost mr-5" size={"small"}>Giao dịch
                                        bất thường chưa xử lý</Button>
                                    <Button className="a-font--12 a-btn--primary-ant" size={"small"}>Giao dịch bất thường đã xử
                                        lý</Button>
                                </div>
                                <div className="a-list--action-search flex-row">
                                    <a href="#" className="link-reset mr-5"><Icon type="sync"/>Làm mới bộ lọc</a>
                                    <Button className="a-text--uppercase a-btn--padding-large a-btn--primary-ant">Tìm
                                        kiếm</Button>
                                </div>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </div>

        )
    }
}

export default FormSearch;