import lodash from 'lodash';
import {TRANSACTION_COLUMN_DISPLAY} from './constants';

const SETTING_OPTIONS = [
    {
        key: TRANSACTION_COLUMN_DISPLAY.ACCOUNT,
        selected: true,
        disabled: true,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.CODE,
        selected: true,
        disabled: true,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.SERI_NUMBER,
        selected: true,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.AMOUNT,
        selected: true,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.SERVICE_FEE,
        selected: true,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.TRANSACTION_TIME,
        selected: true,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.ORDER_CODE,
        selected: true,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.TYPE,
        selected: true,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.SHOP,
        selected: true,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.CODE_PROVIDER,
        selected: true,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.CREATED_AT,
        selected: false,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.RESOLVED_TIME,
        selected: false,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.CSV_FILE,
        selected: false,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.RELATED_TRANSACTION,
        selected: true,
    },
    {
        key: TRANSACTION_COLUMN_DISPLAY.RELATED_ACCOUNT,
        selected: false,
    },
];

class SettingSingleService {
    constructor(options) {
        this.options = options;
    }

    getSetting(page) {
        let setting = localStorage.getItem(page + '_setting');
        return setting ? JSON.parse(setting) : {};
    }

    updateSetting(setting, page) {
        localStorage.setItem(page + '_setting', JSON.stringify(setting));
    }

    getOption(page, type) {
        let options = SETTING_OPTIONS;
        this.options = options;
    }

    getPageSetting(userId, page, type) {
        let defaultSetting = lodash.get(this.getSetting(page), userId, this.getDefaultSetting());
        let settings = [];
        lodash.each(SETTING_OPTIONS, setting => {
            if (defaultSetting.includes(setting.key)) {
                settings.push(setting.key);
            }
        });
        return settings;
    }

    getDefaultSetting() {
        return this.options.filter(option => option.selected).map(option => option.key);
    }

    getUserSetting(userId, page, type) {
        this.getOption(page, type);
        return this.getPageSetting(userId, page, type);
    }

    updateUserSetting(userId, setting, page, type) {
        this.getOption(page, type);
        this.updateSetting({...this.getSetting(page), [userId]: setting}, page);
    }
}

let settingSingleService = new SettingSingleService(SETTING_OPTIONS);

export default settingSingleService;
