import React from "react";
import PropTypes from "prop-types";
import AddressItem from "../AddressItem";
import lodash from "lodash";

class AgencyBox extends React.Component {
    render() {
        let {data, agency, purchaseAddress, permission, updateState} = this.props;

        /**
         * Sắp xếp lại thứ tự theo độ ưu tiên
         */
        return <div className="agency-box mt-3">
            <div className="agency-box-title d-flex p-3 pb-3 pl-3 pl-3">
                <p style={{ background: agency.color }}
                   className={"a-font--12 header_partner mr-1 header_partner--" + agency.code}>
                    {agency.alias}
                </p>
                <span className="a-text--color-black a-text--bold">{agency.name} ({purchaseAddress.length})</span>
            </div>
            <div className="agency-box-body pl-4" style={{paddingRight: "1rem"}}>
                {
                    purchaseAddress.map((address, index) => <AddressItem
                        key={index}
                        data={data}
                        address={address}
                        permission={permission}
                        updateState={updateState}
                        purchaseAddress={purchaseAddress}
                        onUpdateStatusPurchaseAddress={this.props.onUpdateStatusPurchaseAddress} />)
                }
            </div>
        </div>;
    }
}

AgencyBox.defaultProps = {
    purchaseAddress: [],
    agency: {}
}

AgencyBox.propTypes = {
    purchaseAddress: PropTypes.array.isRequired,
    agency: PropTypes.object.isRequired
}

export default AgencyBox;
