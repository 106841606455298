import {combineReducers} from 'redux';
import * as Constant from './constants'
import lodash from 'lodash';
import * as AddInvoiceCodeConstant from './../InputAddInvoiceCode/constants';
import * as DeleteInvoiceCode from './../ModalManageInvoiceCode/constants';
import {updateCollectionItem} from '../../common/helps';

const list = (state = [], action) => {
    switch (action.type) {
        case Constant.GET_LIST_TRANSACTION.SUCCESS:
            return [...lodash.get(action, "payload.data.transactions", [])];
        case Constant.GET_LIST_TRANSACTION.REQUEST:
        case Constant.TRANSACTION_LIST_FILE_CLEAR_STATE:
            return [];
        case AddInvoiceCodeConstant.ADD_INVOICE_CODE_2_TRANSACTION.SUCCESS:
            return state.map(transaction => {
                if (transaction.id === lodash.get(action, 'payload.data.id_transaction', 0)) {
                    if (Array.isArray(transaction.transaction_invoice_code)) {
                        transaction.transaction_invoice_code.push(lodash.get(action, 'payload.data', {}));
                    } else {
                        transaction.transaction_invoice_code = [{...lodash.get(action, 'payload.data', {})}];
                    }
                }
                return transaction;
            });
        case DeleteInvoiceCode.DELETE_INVOICE_CODE_FROM_TRANSACTION.SUCCESS:
            return state.map(transaction => {
                if (transaction.id === lodash.get(action, 'payload.data.id_transaction', 0)) {
                    let {transaction_invoice_code} = transaction;
                    transaction.transaction_invoice_code = transaction_invoice_code.filter(invoiceCode => {
                        return invoiceCode.id !== lodash.get(action, 'payload.data.id', 0);
                    });
                }
                return transaction;
            });
        case Constant.UPDATE_TRANSACTION:
            return updateCollectionItem(state, action.payload.id, action.payload.data, 'id');
        default:
            return state;
    }
};

const loading = (state = false, action) => {
    switch (action.type) {
        case Constant.GET_LIST_TRANSACTION.SUCCESS:
        case Constant.GET_LIST_TRANSACTION.FAILED:
        case Constant.TRANSACTION_LIST_FILE_CLEAR_STATE:
            return false;
        case Constant.GET_LIST_TRANSACTION.REQUEST:
            return true;
        default:
            return state;
    }
};

const pagination = (state = {}, action) => {
    switch (action.type) {
        case Constant.GET_LIST_TRANSACTION.SUCCESS:
            return {...lodash.get(action, "payload.data.pagination", {})};
        case Constant.GET_LIST_TRANSACTION.REQUEST:
        case Constant.TRANSACTION_LIST_FILE_CLEAR_STATE:
            return {};
        default:
            return state;
    }
};
const invoiceCodeMax = (state = {}, action) => {
    switch (action.type) {
        case Constant.GET_LIST_TRANSACTION.SUCCESS:
            return {...lodash.get(action, "payload.data.invoice_code_max", {})};
        case Constant.GET_LIST_TRANSACTION.REQUEST:
        case Constant.TRANSACTION_LIST_FILE_CLEAR_STATE:
            return {};
        default:
            return state;
    }
};
const unusualTypes = (state = {}, action) => {
    switch (action.type) {
        case Constant.GET_LIST_TRANSACTION.SUCCESS:
            return {...lodash.get(action, "payload.data.unusual_types", {})};
        case Constant.GET_LIST_TRANSACTION.REQUEST:
        case Constant.TRANSACTION_LIST_FILE_CLEAR_STATE:
            return {};
        default:
            return state;
    }
};
const errors = (state = {}, action) => {
    switch (action.type) {
        case Constant.TAKE_ERRORS:
            return {...action.payload.data};
        case Constant.RESET_ERROR:
            return {...state, data: lodash.omit(state.data, action.payload.params)};
        default:
            return state;
    }
};

const permission = (state = {}, action) => {
    switch (action.type) {
        case Constant.GET_LIST_TRANSACTION.SUCCESS:
            const canUseGlobal = lodash.get(action, "payload.data.canUseGlobal", false);
            const canUseMbill =  lodash.get(action, "payload.data.canUseMbill", false);
            return {canUseGlobal, canUseMbill}
        case Constant.GET_LIST_TRANSACTION.REQUEST:
        case Constant.TRANSACTION_LIST_FILE_CLEAR_STATE:
            return {canUseGlobal: false, canUseMbill: false};
        default:
            return state
    }
};

export default combineReducers({
    list,
    loading,
    pagination,
    permission,
    errors,
    invoiceCodeMax,
    unusualTypes
});
