import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {Select} from 'antd';
import notification from "../../../../systems/notification";
import apiService from "../../apiService";
import {processResponse} from "../../../../systems/api";
import {fetchComplaintSellerRenewReasons} from "../../actions";

const {Option} = Select;

class SelectRenewReason extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
        let {reasons} = this.props;
        if (this.props.reasons.length === 0)
            this.props.fetchComplaintSellerRenewReasons();
    }

    getValue() {
        let {value, multiple} = this.props;
        if (!value)
            return undefined;

        if (multiple) {
            return Array.isArray(value) ? value.map(item => parseInt(item)) : [parseInt(value)];
        } else
            return parseInt(value);
    }

    render() {
        let {t, reasons} = this.props;

        return (
            <Select
                {...this.props}
                value={this.getValue()}
                mode={this.props.multiple ? 'multiple' : 'default'}
                onChange={this.props.onChange}
                placeholder={this.props.placeholder}
                showSearch={true}
                optionFilterProp="children"
                allowClear={this.props.allowClear}
            >
                {reasons.map(reason => <Option key={reason.id} value={reason.id}>{reason.title}</Option>)}
            </Select>
        );
    }
}

SelectRenewReason.defaultProps = {
    value: null,
    placeholder: '',
    allOption: false,
    multiple: false,
    onChange: value => {},
    reasons: []
};

SelectRenewReason.propTypes = {
    value: PropTypes.any,
    placeholder: PropTypes.string,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    reasons: PropTypes.array
};

const mapStateToProps = (state) => {
    return {
        reasons: lodash.get(state, 'ComplaintSeller.complaintDetail.renewReasons', [])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchComplaintSellerRenewReasons: () => {
            dispatch(fetchComplaintSellerRenewReasons({status: 1}));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SelectRenewReason));
