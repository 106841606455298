import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Select} from 'antd';
import {orderStatus} from '../services';

const {Option} = Select;

class SelectOrderStatus extends React.PureComponent {
    render() {
        let {t} = this.props;
        let statuses = orderStatus.all().filter(this.props.filter);
        if (this.props.listStatus && this.props.listStatus.length > 0){
            statuses = this.props.listStatus;
        }

        return (
            <Select
                value={this.props.value}
                mode={this.props.multiple ? 'multiple' : 'default'}
                onChange={this.props.onChange}
                {...this.props}
            >
                {this.props.allOption && <Option value="">{t('label.all')}</Option>}
                {statuses.map(status => <Option key={status} value={status}>{t('order:status.' + status)}</Option>)}
            </Select>
        );
    }
}

SelectOrderStatus.defaultProps = {
    value: null,
    allOption: false,
    multiple: false,
    filter: value => true,
    onChange: value => {},
};

SelectOrderStatus.propTypes = {
    value: PropTypes.any,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    filter: PropTypes.func,
    onChange: PropTypes.func,
};

export default translate()(SelectOrderStatus);
