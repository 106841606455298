import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import ReactFileReader from "react-file-reader";
import {Col} from "react-grid-system";

class UploadLogo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inputAttachFile: this.props.value || "",
        }
    }

    handleFiles(code, files) {
        this.setState({inputAttachFile: files.base64});
        this.props.onChange({code: code, value: files.base64});
    }


    render() {
        let {t, isUsing, code} = this.props;
        let {inputAttachFile} = this.state;
        return (
            <div className="d-flex align-item-center">
                <div className="show_img">
                    <img src={inputAttachFile} width={`15`} height={`15`} alt={'input-attach-file'}/>
                </div>
                <div>
                    <p className="mb-1">Hình ảnh đại diện</p>
                    <ReactFileReader
                        multipleFiles={false}
                        title=""
                        disabled={isUsing ? false : true}
                        handleFiles={this.handleFiles.bind(this, code)}
                        fileTypes={["image/*"]} base64={true}
                    >
                        <label className={`a-btn--primary a-text--fz-12 a-radius-3 a--cursor-poiter ${isUsing ? '' : 'disable'}`} style={{justifyContent: "center", height: "24px"}}>
                            {t('setting:upload_logo')}
                        </label>
                    </ReactFileReader>
                </div>
            </div>
        );
    }
}

UploadLogo.defaultProps = {
    onChange: () => {
    },
    isUsing: false,
    code: '',
    value: '',

};

UploadLogo.propTypes = {
    isUsing: PropTypes.bool,
    code: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
};


export default translate('translations')(UploadLogo);