import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {Checkbox} from '@atlaskit/checkbox';
import {translate} from 'react-i18next';
import FormConmentPublic from './FormConmentPublic';

class FormFilter extends React.Component {
    /**
     * Xử lý khi thay đổi giá trị của type
     */
    onChangeType(type) {
        this.props.onChange({type: (type === 'ALL') ? null : type});
    }

    render() {
        let {t, filter} = this.props;
        return (
            <div className="a-chat__top">
                <div className="flex--center mb-2">
                    {['COMMENT', 'LOG'].map(type => (
                        <div key={type+(lodash.get(filter,'type',null) || "ALL")}
                             className={'a-chat__group flex--center mr-2'}
                             onClick={this.onChangeType.bind(this, type)}
                        >
                            <Checkbox style={{cursor:'pointer'}}
                                      value="Checked by default"
                                      name="checkbox-checked"
                                      defaultChecked={ (lodash.get(filter,'type',null) || "ALL") === type }
                            />
                            <label style={{cursor:'pointer'}}
                                   className={'a-text--uppercase label-checkbox'}>
                                {t('log:type.' + type)}
                            </label>
                        </div>
                    ))}
                </div>
                <FormConmentPublic
                    collapsed={this.props.collapsed}
                    inputVersion={this.props.inputVersion}
                    input={this.props.commentInput}
                    error={this.props.commentError}
                    loading={this.props.loading && this.props.commentInput.is_public}
                    disabled={lodash.get(this.props, 'filter.type', 'LOG') === 'LOG'}
                    onSubmit={this.props.onComment}
                    onToogleCollapsedPublic={this.props.onToogleCollapsedPublic}
                />
            </div>
        );
    }
}

FormFilter.defaultProps = {
    input: {},
    loading: false,
    onChange: input => {},
    onToogleCollapsedPublic: input => {},
};

FormFilter.propTypes = {
    input: PropTypes.object,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onToogleCollapsedPublic: PropTypes.func,
};

export default translate()(FormFilter);
