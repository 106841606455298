import React from 'react';
import {translate} from 'react-i18next';
import Select from "@atlaskit/select/dist/esm/Select";
import Button from "@atlaskit/button/dist/esm/components/Button";
import DatetimePicker from "../../../common/components/DatetimePicker";
import ModalGeneral from './../../../common/components/ModalGeneral';

class UpdateComplaint extends React.Component {

    render() {
        return (
            <ModalGeneral>
                <div className={"a-modal__title"}>
                    <p className="order-assign">
                        CẬP NHẬT KHIẾU NẠI
                    </p>
                </div>
                <div className="d-flex flex-wrap">
                    <div className="d-flex w-100">
                        <div className='a-group w-50 pr-1'>
                            <label htmlFor="site_name"
                                   className="label a-label">Lý do</label>
                            <Select
                                options={[]}
                                name="site_name"
                                isMulti
                                placeholder={''}
                                className="single-react mt-2"
                                classNamePrefix="react-select--normal"
                            />
                            {/*<div className={"a--error"}>{this.renderError("site_name")}</div>*/}
                        </div>
                        <div className='a-group complaint__seller w-50 pl-1'>
                            <label htmlFor="name"
                                   className="label a-label ">Hạn xử lý</label>
                            <div className="mt-2">
                                <DatetimePicker
                                    name={'log_status_from'}
                                    date={''}
                                    placeholderText={''}
                                />
                            </div>

                            {/*<div className={"a--error"}>{this.renderError("account")}</div>*/}
                        </div>
                    </div>
                    <div className="d-flex w-100">
                        <div className='a-group w-50 pr-1'>
                            <label htmlFor="name"
                                   className="label a-label "> Yêu cầu đòi
                                (¥)</label>
                            <input
                                spellCheck="false"
                                className="input input--fullwidth input--height-30 mt-2"
                                type="text" name={"account"}
                                placeholder={''}
                                value={''}
                            />
                            {/*<div className={"a--error"}>{this.renderError("account")}</div>*/}
                        </div>
                        <div className='a-group w-50 pl-1'>
                            <label htmlFor="site_name"
                                   className="label a-label">Tình trạng </label>
                            <Select
                                options={[]}
                                name="site_name"
                                isMulti
                                placeholder={''}
                                className="single-react mt-2"
                                classNamePrefix="react-select--normal"
                            />
                            {/*<div className={"a--error"}>{this.renderError("site_name")}</div>*/}
                        </div>
                    </div>
                    <div className='a-group w-100 '>
                        <label htmlFor="name"
                               className="label a-label ">Comment </label>
                                                            <textarea
                                                                spellCheck="false"
                                                                className="input input--fullwidth  mt-2"
                                                                type="text" name={"account"}
                                                                placeholder={''}
                                                                rows={4}
                                                                value={''}
                                                            />
                        {/*<div className={"a--error"}>{this.renderError("account")}</div>*/}
                    </div>

                    <div className="d-flex justify-content-end p-2 w-100">
                        <Button appearance="primary" type="submit"
                                className=" mr-3 a-btn--submit a-text--bold"

                        >
                            THỰC HIỆN
                        </Button>
                        <a appearance="subtle"
                           className={('' ? "hidden " : "") + "a-btn a-btn--none a-btn-link "}>
                            Hủy
                        </a>
                    </div>
                </div>

            </ModalGeneral>
        );
    }
}

UpdateComplaint.defaultProps = {};

UpdateComplaint.propTypes = {};

export default translate()(UpdateComplaint);
