import React, {Component} from 'react';
import {translate} from "react-i18next";
import {Input, Form, Modal, Switch, Col, Row, notification} from "antd";
import lodash from 'lodash';
import {processResponse} from "../../../systems/api";
import apiService from "../../../modules/PaymentRequest/apiService";

class FormUpdateGroup extends Component {
    constructor(props) {
        super(props);
        this.initValues = {
            code: props.groupInfo.code || undefined,
            name: props.groupInfo.name || undefined,
            description: props.groupInfo.description || undefined,
            status: props.groupInfo.status || undefined
        };
        this.state = {
            loading: false,
            errors: {},
            input: {
                ...this.initValues
            }
        }
    }

    handleCancel = () => {
        this.setState({
            errors: {},
            input: this.initValues
        });
        this.props.closeModal();
    };
    renderError = (name) => {
        const {errors} = this.state;
        return lodash.get(errors, name, null);
    };

    handleOnChange = (e) => {
        const {input, errors} = this.state;
        let value = e.target.value;
        const name = e.target.name;
        this.setState({
            errors: {
                ...errors,
                [name]: undefined
            }
        });
        if (name === "code") {
            value = value.trim().replace(/\s/g, '');
        }
        this.setState({
            input: {
                ...input,
                [name]: value
            }
        })
    };

    handleOnChangeSwitch = (name, value) => {
        const {input} = this.state;
        this.setState({
            input: {
                ...input,
                [name]: value ? 1 : 0
            }
        })
    };

    checkData = () => {
        const {t} = this.props;
        const {errors, input} = this.state;
        const pattern = /^[a-z0-9_\-]+$/i;
        let isValid = true;
        if (!input.code){
            errors["code"] = t("group:messages.INPUT_INVALID.required", {name: t("group:title.group.code")});
            isValid = false;
        } else {
            if (!pattern.test(input.code)){
                errors["code"] = t("group:messages.group_code_valid");
                isValid = false;
            }
        }
        if (!input.name){
            isValid = false;
            errors["name"] = t("group:messages.INPUT_INVALID.required", {name: t("group:title.group.name")});
        }
        this.setState({
            errors
        });
        return isValid;
    };

    handleUpdateGroup = (values) => {
        const {groupInfo} = this.props;
        const isEdit = !lodash.isEmpty(groupInfo);
        if (isEdit) {
            return processResponse(apiService.editPaymentRequestGroup(groupInfo.id, values));
        } else {
            return processResponse(apiService.addPaymentRequestGroup(values));
        }
    };

    handleOk = () => {
        const {groupInfo, t} = this.props;
        const {errors, input} = this.state;
        const isEdit = !lodash.isEmpty(groupInfo);
        const isValid = this.checkData();
        if (isValid) {
            this.setState({loading: true});
            const response = this.handleUpdateGroup(input);
            response.then(res => {
                this.handleCancel();
                this.props.getListGroup();
                notification.success({message: isEdit ? t("group:messages.edit_group_success") : t("group:messages.add_group_success")});
            }).catch(err => {
                const errorCode = lodash.get(err, "code", "");
                if (errorCode === "INPUT_INVALID") {
                    const data = lodash.get(err, "data", {});
                    for (let field in data) {
                        for (let errorCode in data[field]) {
                            errors[field] = t("group:messages.INPUT_INVALID." + errorCode, {name: t("group:title.group." + field)});
                        }
                    }
                    this.setState({errors})
                } else {
                    notification.error({message: isEdit ? t("group:messages.edit_group_fail") : t("group:messages.add_group_fail")})
                }
            }).finally(() => this.setState({loading: false}))
        }
    };

    render() {
        const {t, groupInfo} = this.props;
        const {loading, input} = this.state;
        const isEdit = !lodash.isEmpty(groupInfo);
        return (
            <Modal
                title={(isEdit ? t("group:title.group_edit") : t("group:title.group_add")).toUpperCase()}
                visible={this.props.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                confirmLoading={loading}
                okButtonProps={{loading}}
            >
                <Form className="form-order-group">
                    <Form.Item
                        label={t("group:title.group.code")}
                        className="a-form--ant-item mb-0 required"
                    >
                        <Input
                            disabled={isEdit}
                            placeholder={t("group:placeholder.group.code")}
                            name="code"
                            value={input.code}
                            onChange={this.handleOnChange}
                        />
                        <div
                            className={"validatorMessage a--error " + (this.renderError("code") ? 'active-error' : 'inactive-error')}>
                            {this.renderError("code")}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label={t("group:title.group.name")}
                        className="a-form--ant-item mb-0 required"
                    >
                        <Input
                            placeholder={t("group:placeholder.group.name")}
                            name="name"
                            value={input.name}
                            onChange={this.handleOnChange}
                        />
                        <div
                            className={"validatorMessage a--error " + (this.renderError("name") ? 'active-error' : 'inactive-error')}>
                            {this.renderError("name")}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label={t("group:title.group.description")}
                        className="a-form--ant-item mb-0"
                    >
                        <Input.TextArea
                            placeholder={t("group:placeholder.group.description")}
                            name="description"
                            value={input.description}
                            onChange={this.handleOnChange}
                            autosize={{ minRows: 3, maxRows: 5 }}
                        />
                        <div
                            className={"validatorMessage a--error " + (this.renderError("description") ? 'active-error' : 'inactive-error')}>
                            {this.renderError("description")}
                        </div>
                    </Form.Item>
                    {
                        isEdit &&
                        <Form.Item className="m-0">
                            <Row gutter={0}>
                                <Col span={12} style={{fontSize: 12}}>{t("group:title.group.status")}</Col>
                                <Col span={12} className="a-text--right">
                                    <Switch checked={!!input.status} onChange={(value) => this.handleOnChangeSwitch("status", value)}/>
                                </Col>
                            </Row>

                        </Form.Item>
                    }
                </Form>
            </Modal>
        );
    }

}

export default translate()

(
    FormUpdateGroup
)
;