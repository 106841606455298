import React from 'react';
import {translate} from 'react-i18next';
import {Container, Row, Col} from 'react-grid-system';
import PropTypes from 'prop-types';
import List from './List';
import Pagination from './../../../common/components/Pagination';
import FormFilter from "./FormFilter";

class ViolatePolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        }
    }

    componentDidMount() {
        this.props.setPageTitle(this.props.t('layout.title.violate_policy_orders'));
        this.props.setPageDescription(this.props.t('layout.description.violate_policy_orders'));
        this.props.setMenuActive("ViolatePolicy");
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.violate_policy_orders',
                'link': '/violate_policy_orders/'
            },
        ]);
        this.props.fetchViolateReasons();
        this.props.fetchAccount();

    }

    /**
     * handle action change filter
     * @param filter
     */
    onSearch(filter) {
        this.props.onSearch({...filter, page: 1});
    }

    /**
     * handle change page of list
     * @param page
     */
    onChangePage(page) {
        let {filter} = this.props;
        window.scrollTo(0, 0);
        this.setState({page: page});
        this.props.onSearch({...filter, page: page});
    }

    render() {
        let {orders, status, filter, pagination, accounts, violateReasons, t, updateReason} = this.props;
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className="a-content--center">
                            <div className="a-content--box-shadow">
                            <FormFilter
                                input={filter}
                                status={status}
                                violateReasons={violateReasons}
                                accounts={accounts}
                                onSubmit={this.onSearch.bind(this)}
                                inputVersion={typeof filter._i === "undefined" ? 0 : parseInt(filter._i, 10)}
                            />
                            </div>
                          <div className="a-content--box-shadow mt-3">
                            <div className="a-group--item">
                                <List orders={orders}
                                      key={orders.id}
                                      violateReasons={violateReasons}
                                      updateReason={updateReason}
                                />
                                {
                                    orders.length === 0 ?
                                        <div class="a-group--item mt-5">
                                            <p>{t("violate:list.no_record")}</p>
                                        </div> : null
                                }
                            </div>
                            <div className="a-group--item a_navigation mt-5 d-flex ">
                                <Pagination
                                    value={parseInt(pagination.current_page, 10)}
                                    total={pagination.page_total}
                                    onChange={page => this.onChangePage(page)}
                                />
                            </div>
                          </div>
                        </Col>
                    </Row>

                </Container>
            </React.Fragment>
        );
    }
}

ViolatePolicy.defaultProps = {
    input: {},
    partner: {id: 0, code: "", domain: ""},
    inputVersion: 0,
    filter: {},
    filterVersion: 0,
    orders: [],
    onSearch: filter => {
    },
};

ViolatePolicy.propTypes = {
    input: PropTypes.object,
    partner: PropTypes.object,
    onSubmit: PropTypes.func,
    filter: PropTypes.object,
    filterVersion: PropTypes.number,
    onSearch: PropTypes.func,
    orders: PropTypes.array,
};


export default translate('translations')(ViolatePolicy);
