import React from 'react';
import {translate} from 'react-i18next';
import Button from '@atlaskit/button';
import PropTypes from 'prop-types';
import {trans} from './../../../../systems/i18n';
import * as Constant from './../../constants';
import lodash from 'lodash';
import Select from '@atlaskit/select';
import DatetimePicker from "./../../../common/components/DatetimePicker";
import ModalGeneral from './../../../common/components/ModalGeneral';

class ModalForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            order_code: "",
            id_reason: "",
            date_time: "",
            request_seller: "",
            isLoading: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        state.isLoading = nextProps.isLoading;

        if (lodash.get(nextProps, "accountPurchaser.id", 0) !== prevState.id) {
            state.id = lodash.get(nextProps, "accountPurchaser.id", 0);
            state.account = lodash.get(nextProps, "accountPurchaser.account", "");
            let siteName = lodash.get(nextProps, "accountPurchaser.site_name", null);
            state.site_name = [];
            if (siteName) {
                state.site_name = Constant.SOURCE_ACCOUNT_PURCHASER.filter(source => {
                    if (siteName.includes(source.value)) {
                        return source;
                    }

                    return null;
                });
            }

            state.status = lodash.get(nextProps, "accountPurchaser.status", Constant.STATUS_ACCOUNT_PURCHASER[0].value);
        }

        return state;
    }

    /**
     * handle action thay đổi value của các input trong form
     * @param event
     */
    onChangeInput(event) {
        // this.props.clearError();
        let {target} = event;
        this.setState({
            [target.name]: target.value.trim()
        });
    }

    onSelectMulti(param, values) {
        this.setState({[param] : values});
    }

    /**
     * xử lý hành động submit form
     */
    onSubmitForm() {
        let formData = lodash.merge({}, this.state);
        this.setState({isLoading: true});
        let siteNameSeleted = [];

        if (formData.site_name && formData.site_name.length) {
            formData.site_name.map(site => siteNameSeleted.push(site.value))
        }
        formData.site_name = siteNameSeleted;
        this.props.onSubmitForm(formData);
    }

    /**
     * Submit form when enter input
     * @param event
     */
    onKeyPress(event) {
        if (event.key === 'Enter') {
            this.onSubmitForm();
        }
    }

    /**
     * Thực hiện render lỗi thông báo ra bên ngoài
     * @param attrName
     * @returns {null}
     */
    renderError(attrName) {
        let {error, t} = this.props;
        let errorItem = lodash.get(error, attrName, {});
        attrName = attrName === "account" ? "account_label" : attrName;
        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('account_purchaser.error.' + Object.keys(errorItem)[0], {name: t('account_purchaser.' + attrName)});
                case 'unique':
                    return t('account_purchaser.error.' + Object.keys(errorItem)[0], {name: t('account_purchaser.' + attrName)});
                case 'max.string':
                    let objectmax = lodash.get(errorItem, 'max.string', null);
                    let overLength = lodash.get(objectmax, 'max', null);
                    return t('account_purchaser.error.max_string', {
                        name:   t('account_purchaser.' + attrName),
                        value: overLength
                    });
                default:
                    return null;
            }
        }
    }

    render() {
        let {onClose, t, isLoading, complaintSellerReasons} = this.props;
        let {account, site_name} = this.state;

        return (
            <ModalGeneral>
                <div className={"a-modal__title"}>
                    <p >
                        CẬP NHẬT (THÀNH CÔNG/THẤT BẠI/TRẢ HÀNG) KHIẾU NẠI

                    </p>
                </div>
                <div className='a-group'>

                    <div className="row ml-0 mr-0">
                        <div className="col pl-0">
                            <label htmlFor="name"
                                   className="label label--required a-label ">Ma don hang</label>
                            <Select
                                onChange={values => this.onSelectMulti("site_name", values)}
                                onKeyDown={event => this.onKeyPress(event)}
                                options={complaintSellerReasons}
                                value={site_name}
                                name="site_name"
                                placeholder={t("account_purchaser.site_name")}
                                className="single-react mt-2"
                                classNamePrefix="react-select--multi"
                            />
                        </div>

                        <div className="col pl-0">
                            <label htmlFor="name"
                                   className="label label--required a-label ">Ma don hang</label>
                            <DatetimePicker
                                name={'log_status_from'}
                                placeholderText={t("order:list.log_status_placeholder")}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="name"
                                   className="label label--required a-label ">Ma don hang</label>
                            <input
                                type="text"
                                name={'grand_total_to'}
                                className="input input--fullwidth input--search_item"
                                min={0}
                                placeholder={t('order:list.to')}
                            />
                        </div>
                    </div>
                    <div className={"a--error"}>{this.renderError("account")}</div>
                </div>
                <div className='a-group'>
                    <label htmlFor="name"
                           className="label label--required a-label ">Yêu cầu người bán: (¥):</label>
                    <input
                        spellCheck="false"
                        className="input input--fullwidth input--insert_acount mt-2"
                        type="text" name={"account"}
                        placeholder={t("account_purchaser.account_placeholder")}
                        onChange={this.onChangeInput.bind(this)}
                        onKeyPress={event => this.onKeyPress(event)}
                        value={account}
                    />
                    <div className={"a--error"}>{this.renderError("account")}</div>
                </div>
                <div className="a--group--inline-end p-2 a--user-group-btn">
                    <Button appearance="primary" type="submit" className=" mr-3 a-btn--submit"
                            onClick={this.onSubmitForm.bind(this)}
                            isLoading={isLoading}
                    >
                        {trans('account_purchaser.btn_action_confirm')}
                    </Button>
                    <a appearance="subtle" onClick={onClose}
                       className={(isLoading ? "hidden " : "") + "a-btn a-btn--none a-btn-link "}>
                        {trans('account_purchaser.btn_action_cancel')}
                    </a>
                </div>
            </ModalGeneral>
        );
    }
}

ModalForm.defaultProps = {
    accountPurchaser: {
        id: 0,
        account: "",
        site: "Taobao",
        status: 'ACTIVE'
    },
    isLoading: false,
    error: {}
}

ModalForm.propTypes = {
    accountPurchaser: PropTypes.object,
    onSubmitForm: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.object
}

export default translate('translations')(ModalForm);
