import React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Modal, Form, Input, Row, Col, Switch, Select, notification, Tooltip} from "antd";
import FormProcessing from "../../../modules/common/components/FormProcessing";
import {translate} from 'react-i18next';
import lodash from "lodash";
import apiService from "../../../modules/Agency/apiService";
import {processResponse} from "../../../systems/api";

const {TextArea} = Input
const defaultInput = {
    id: '',
    name: '',
    code: '',
    alias: '',
    color: '',
    note: '',
    caresoft_access_token: '',
    caresoft_domain: '',
    invoice_code_alphabet: 0,
    freight_bill_alphabet: 0
};

class FormAgency extends FormProcessing {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            inputVersion: 0,
            changedParams: [],
            input: {...defaultInput},
            dataError: {},
            loading: false
        }
    }

    componentDidMount() {
        this.setState({input: {...defaultInput}});
    }

    componentDidUpdate(prevProps, prevState) {
        let old_updatedAgency = prevProps.updatedAgency;
        let {updatedAgency, input} = this.props;

        if (lodash.isEmpty(old_updatedAgency) && !lodash.isEmpty(updatedAgency))
            this.setState({input: {...input, ...updatedAgency}})
    }

    handleOk = e => {
        this.onUpdateAgency();
    }

    handleCancel = () => {
        this.onResetHandler();
    }

    onUpdateAgency() {
        let {input} = this.state;
        let process = processResponse(apiService.updateAgency(input));
        process.then(res => {
            notification.success({message: "Cập nhật thông tin agency thành công"});
            this.props.onUpdateData(res.data);
            this.onResetHandler();
        });

        process.catch(error => {
            if (error.code === "INPUT_INVALID")
                this.setState({dataError: error.data});
            else
                notification.error({message: "Cập nhật thông tin agency không thành công"});
        });
    }

    /**
     * Cập nhật dữ liệu thay đổi của input
     * @param field
     * @param event
     */
    onHandleChange = (field, event) => {
        let value = event.target.value;

        this.clearError(field);

        if (field === 'code') {
            value = value.trim();
            value = value.replace(/\s/g, '');
        }

        this.changeInput({[field]: value});
    };

    onHandleSwitchChange = (field, value) => {
        value = value ? 1 : 0;
        this.changeInput({[field]: value});
    };

    /**
     * Thực hiện reset form
     * @param event
     */
    onResetHandler = () => {
        this.props.onSetUpdatedAgency({});
        this.setState({
            input: {...defaultInput},
            dataError: {}
        });
    }

    /**
     * Xóa các lỗi nếu có trong dataError
     */
    clearError = (attr) => {
        let {dataError} = this.state;
        if (dataError[attr]) {
            delete dataError[attr];
            this.setState({dataError});
        }
    }

    /**
     * Thực hiện render lỗi thông báo ra bên ngoài
     * @param attrName
     * @returns {null}
     */
    renderError = attrName => {
        let {t} = this.props;
        let {dataError} = this.state;
        let errorItem = lodash.get(dataError, attrName, {});

        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('agency.error.' + Object.keys(errorItem)[0], {name: t('agency.' + attrName)});
                case 'CODE_USED':
                    return t('agency.error.' + Object.keys(errorItem)[0], {name: t('agency.' + attrName)});
                case 'max.string':
                    let objectmax = lodash.get(errorItem, 'max.string', null);
                    let overLength = lodash.get(objectmax, 'max', null);
                    return t('agency.error.max_string', {
                        name: t('agency.' + attrName),
                        value: overLength
                    });
                default:
                    return null;
            }
        }
    }

    render() {
        let {input} = this.state;
        let {agencies} = this.props;

        return <>
            <Modal
                title={"CẬP NHẬT THÔNG TIN AGENCY"}
                visible={input.id !== ""}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                cancelText="Hủy"
                okText={input.id ? "Cập nhật" : "Thêm mới"}
                width={420}
                okButtonProps={{loading: this.state.loading}}
            >
                <Form className="form-order-group">
                    <Form.Item label="Tên agency" className="a-form--ant-item mb-0 required">
                        <Input
                            type="text"
                            placeholder="Tên agency"
                            allowClear
                            value={input.name || undefined}
                            onChange={e => this.onHandleChange("name", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("name") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("name")}
                        </div>
                    </Form.Item>

                    <Form.Item label="Ký hiệu" className="a-form--ant-item mb-0 required">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Kí hiệu"
                            value={input.alias || undefined}
                            onChange={e => this.onHandleChange("alias", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("alias") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("alias")}
                        </div>
                    </Form.Item>

                    <Form.Item label="Mã agency" className="a-form--ant-item mb-0 required">
                        <Input
                            type="text"
                            placeholder="Mã agency"
                            value={input.code || undefined}
                            onChange={e => this.onHandleChange("code", e)}
                            disabled={true}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("code") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("code")}
                        </div>
                    </Form.Item>

                    <Form.Item label="Mã màu sắc" className="a-form--ant-item mb-0 required">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Mã màu sắc"
                            value={input.color || undefined}
                            onChange={e => this.onHandleChange("color", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("color") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("color")}
                        </div>
                    </Form.Item>

                    <Form.Item label="Caresoft domain" className="a-form--ant-item mb-0">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Mã agency"
                            value={input.caresoft_domain || undefined}
                            onChange={e => this.onHandleChange("caresoft_domain", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("caresoft_domain") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("caresoft_domain")}
                        </div>
                    </Form.Item>

                    <Form.Item label="Caresoft token" className="a-form--ant-item mb-0">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Mã agency"
                            value={input.caresoft_access_token || undefined}
                            onChange={e => this.onHandleChange("caresoft_access_token", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("caresoft_access_token") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("caresoft_access_token")}
                        </div>
                    </Form.Item>

                    <Form.Item label={
                        <span>MHĐG&nbsp;
                            <Tooltip title="Cho phép nhập MHĐG có chứa ký tự Alphabet hay không">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>} className="a-form--ant-item mb-0">
                        <Switch
                            checkedChildren={<Icon type="check" />}
                            unCheckedChildren={<Icon type="close" />}
                            checked={input.invoice_code_alphabet}
                            onChange={value => this.onHandleSwitchChange("invoice_code_alphabet", value)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("note") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("note")}
                        </div>
                    </Form.Item>
                    <Form.Item label={
                        <span>Mã vận đơn&nbsp;
                            <Tooltip title="Cho phép nhập mã vận đơn có chứa ký tự Alphabet hay không">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>} className="a-form--ant-item mb-0">
                        <Switch
                            checkedChildren={<Icon type="check" />}
                            unCheckedChildren={<Icon type="close" />}
                            checked={input.freight_bill_alphabet}
                            onChange={value => this.onHandleSwitchChange("freight_bill_alphabet", value)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("note") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("note")}
                        </div>
                    </Form.Item>

                    <Form.Item label="Ghi chú" className="a-form--ant-item mb-0">
                        <TextArea
                            style={{height: "80px"}}
                            placeholder="Ghi chú agency"
                            value={input.note || undefined}
                            onChange={e => this.onHandleChange("note", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("note") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("note")}
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }
}

FormAgency.defaultProps = {
    agencies: [],
    dataDetail: {}
}

FormAgency.propTypes = {
    agencies: PropTypes.array.isRequired,
    dataDetail: PropTypes.object
}

export default translate()(FormAgency);
