import {call, put} from 'redux-saga/effects';
import * as Constants from './constants';
import * as GlobalConstants from '../../systems/globalContant';
import service from './apiService';
import {auth} from '../../systems/api';
import {router} from '../../systems/routing';

export function* login(action) {
    try {
        let response = yield call(service.login, action.payload.datas);
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            auth.setToken(response.data.data.token);
            localStorage.setItem('username', response.data.data.username);
            localStorage.setItem('userid', response.data.data.id);
            yield put({type: Constants.LOGIN.SUCCESS, payload: response});
            router.redirect("/");
        } else {
            yield put({type: Constants.LOGIN.FAILED, payload: response.data});
        }
    } catch (err) {
        yield put({type: Constants.LOGIN.FAILED, payload: {message: "system.error"}});
    }
}