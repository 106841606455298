import React from "react";
import {Empty, Spin} from "antd";
import notification from '../../../systems/notification';
import lodash from "lodash";
import {dateFormatter} from "../../common/services/format";
import apiService from "../apiService";
import {processResponse} from "../../../systems/api";
import {translate} from "react-i18next";
import PaymentRequestLogMessage from "./PaymentRequestLogMessage";
import PropTypes from "prop-types";

class PaymentRequestLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            loading: false
        }
    }

    componentDidMount() {
        this.getLogs(this.props.request_id);
    }

    getLogs(id) {
        let {t} = this.props;
        if (this.state.loading) {
            return;
        }
        if (id) {
            this.setState({loading: true});

            let res = apiService.getLogs(id)
                .finally(() => this.setState({loading: false}));

            let process = processResponse(res);

            process.then(res => {
                this.setState({
                    logs: lodash.get(res, 'data', []),
                });
            });

            process.catch(() => {
                notification.error(t('message.cant_fetch_data'));
            });
            return process;
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.request_id && this.props.request_id !== prevProps.request_id) {
            this.getLogs(this.props.request_id);
        }
    }

    render() {
        let {t} = this.props;
        let logs = this.state.logs.filter(log => {
            return log.action !== 'USER.ASSIGN_TELLER';
        });
        return (
            <div>
                {
                    this.state.loading ?
                        <div className="text-center"><Spin size="large"/></div> :
                        logs.length === 0 ?
                            <Empty
                                className="pt-2"
                                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                description={<span>{t("transaction:label.no_data")}</span>}
                            >
                            </Empty> :
                            <ul className="a-log--transaction__body p-0">
                                {logs.map(log => {
                                    return <React.Fragment>
                                        <li className="a-log--transaction__item a-text--fz-14 pt-2 pb-4 border-bottom" key={log.id}>
                                            <div className="d-flex justify-content-between mb-2">
                                                    <span className="creator-log">
                                                        <b>
                                                            {lodash.get(log, 'creator.name')}
                                                        </b>
                                                    </span>
                                                <span className="time-log">
                                                    {
                                                        dateFormatter.dateTime(lodash.get(log, 'time') * 1000)
                                                    }
                                                    </span>
                                            </div>
                                            {/*Component hiển thị log hành động đơn lẻ, truyền vào props log*/}
                                            <PaymentRequestLogMessage log={log}/>
                                        </li>
                                    </React.Fragment>
                                })}
                            </ul>
                }
            </div>
        )
    }
}
PaymentRequestLogs.defaultProps = {
    request_id: 0,
};

PaymentRequestLogs.propTypes = {
    request_id: PropTypes.number,
};
export default translate()(PaymentRequestLogs);
