import React, {useState} from "react";
import {t} from "../../../../systems/i18n";
import {Modal, Select, Input, Form, Icon} from "antd";
import apiService from "../../apiService";
import {processResponse} from "../../../../systems/api";
import notification from "../../../../systems/notification";

const {Option} = Select;
const DEFAULT_ERROR_STATUS = "return";

function ClearIcon() {
    return <Icon type="close" className="_complaint-seller-detail-button-clear-create-package" />;
}

function CreatePackage({form, complaint, fetchPackages}) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const {getFieldDecorator} = form;

    function _onClick(e) {
        e.preventDefault();
        setVisible(true);
    }

    function _onOK() {
        form.validateFields((err, values) => {
            setLoading(true)
            if (!err) {
                processResponse(apiService.returnPackage(complaint.id, {...values}))
                    .then(res => {
                        fetchPackages();
                        setVisible(false);
                        notification.success(t('complaintSeller:message.create_return_package_success'));
                    })
                    .catch(err => {
                        notification.error(t('complaintSeller:message.create_return_package_fail'));
                    }).finally(() => setLoading(false));
            }
        });
    }

    function _onCancel() {
        form.resetFields();
        setVisible(false);
    }

    function _onChange() {
        form.setFields({
            return_address: {
                value: form.getFieldValue("return_address"),
                errors: null
            }
        });
    }

    return (
        <>
            <a onClick={_onClick} className="a-text--fz-14 a-text--uppercase _complaint-seller-detail-button-create-package">{t("complaintSeller:label.create_package")}</a>
            <Modal
                title={t("complaintSeller:label.create_package_title").toUpperCase()}
                onOk={_onOK}
                onCancel={_onCancel}
                cancelText={t("btn.cancel")}
                okText={t("btn.ok")}
                okButtonProps={{className: '_complaint-seller-detail-button-ok-create-package', loading}}
                cancelButtonProps={{className: '_complaint-seller-detail-button-cancel-create-package'}}
                visible={visible}
            >
                <Form>
                    <Form.Item
                        label="Trạng thái lỗi của kiện"
                        className="mb-2"
                    >
                        {
                            getFieldDecorator('error_status', {
                                initialValue: DEFAULT_ERROR_STATUS,
                                rules: [{ required: true, message: 'Trạng thái lỗi của kiện là bắt buộc.' }]
                            })(
                                <Select
                                    className={"_complaint-seller-detail-choose-package-package-code"}
                                    style={{width: '100%'}}
                                    placeholder={t("complaintSeller:placeholder.choose_package_status")}
                                    allowClear={true}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    onChange={_onChange}
                                    clearIcon={<ClearIcon />}
                                >
                                    {
                                        ["liquidate", "return"].map(status => {
                                            return <Option key={status} value={status}>{t(`complaintSeller:status_damaged.${status}`)}</Option>
                                        })
                                    }
                                </Select>
                            )
                        }
                    </Form.Item>

                    {
                        form.getFieldValue("error_status") === DEFAULT_ERROR_STATUS &&
                        <Form.Item
                            label="Địa chỉ trả hàng"
                            className="mb-2 mt-2"
                        >
                            {
                                getFieldDecorator('return_address', {
                                    rules: [{ required: form.getFieldValue("error_status") === DEFAULT_ERROR_STATUS ? true : false, message: 'Địa chỉ trả hàng là bắt buộc.' }]
                                })(
                                    <Input.TextArea
                                        placeholder={t("complaintSeller:placeholder.type_address")}
                                        style={{height: "100px"}}
                                    />
                                )
                            }
                        </Form.Item>
                    }
                </Form>
            </Modal>
        </>
    );
}

export default Form.create()(CreatePackage);
