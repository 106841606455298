import React, {Component} from "react";
import {translate} from "react-i18next";
import {url} from "../../../systems/routing";
import {setActiveSettingItem} from "../../../modules/Settings/SettingMenu/actions";
import {Col, Row, Spin, Table} from "antd";
import apiService from "../../../modules/BankAccount/apiService";
import {processResponse} from "../../../systems/api";
import lodash from 'lodash'
import notification from "../../../systems/notification"
import FormBankAccount from "./FormBankAccount";

const COLUMNS = {
    ACCOUNT_NAME: "account_name",
    OWNER_NAME: "owner_name",
    ACCOUNT_NUMBER: "account_number",
    BANK_NAME: "bank_name"
};

class BankAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: []
        }
    }

    componentDidMount() {
        let {t} = this.props;

        this.props.setPageTitle(t('bank_account.title'));
        this.props.setPageDescription(t('bank_account.description'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.bank_account',
                'link': url.to('bank-account')
            },
        ]);
        this.props.setMenuActive("bank_account");
        setActiveSettingItem('bank_account');
        this.fetchListBankAccount();

    }

    fetchListBankAccount = () => {
        const {t} = this.props;
        this.setState({
            loading: true
        });
        processResponse(apiService.getListBankAccount()).then((res) => {
            this.setState({
                data: lodash.get(res, "data"),
            })
        }).catch(error => {
            notification.error(t("message.cant_fetch_data"))
        }).finally(() => this.setState({loading: false}));
    };

    getColumns = () => {
        const {t} = this.props;
        let columns = [
            {
                key: COLUMNS.ACCOUNT_NAME
            },
            {
                key: COLUMNS.OWNER_NAME
            },
            {
                key: COLUMNS.ACCOUNT_NUMBER
            },
            {
                key: COLUMNS.BANK_NAME
            },
        ];
        return columns.map(column => ({
            dataIndex: column.key,
            title: t('bank_account.' + column.key),
            ...column
        }));
    };
    getDataSource = () => {
        const {data} = this.state;
        return data.map(bank_account => {
            return {
                key: bank_account.id,
                [COLUMNS.ACCOUNT_NAME]: bank_account.account_name,
                [COLUMNS.OWNER_NAME]: bank_account.owner_name,
                [COLUMNS.ACCOUNT_NUMBER]: bank_account.account_number,
                [COLUMNS.BANK_NAME]: bank_account.bank_name,
            }

        });
    };

    updateState = (input) => {
        this.setState(state => ({...state, ...input}));
    };

    render() {
        const {t} = this.props;
        const {data, loading} = this.state;
        return (

            <div className="a-content__right">
                <div className="a-content--box-shadow">
                    <Row>
                        <Col span={12} className="d-flex align-item-center">
                            <p className="a-text--bold a-text--color-black">{t("bank_account.list").toUpperCase()} ({data.length})</p>
                        </Col>
                        <Col span={12}>
                            <p className="a-text--right" key="form-bank-account">
                                <FormBankAccount updateState={this.updateState} data={data}/>
                            </p>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <Spin tip={"Loading..."} spinning={loading}>
                            <div className="agency-box-body pl-4" style={{paddingRight: "1rem"}}>
                                <Table
                                    dataSource={this.getDataSource()}
                                    columns={this.getColumns()}
                                    pagination={false}
                                />
                            </div>
                        </Spin>
                    </div>

                </div>
            </div>

        );
    }
}

export default translate()(BankAccount);