import {put, call} from 'redux-saga/effects';
import * as Constants from './constants';
import apiService from './apiService';
import * as GlobalConstants from './../../systems/globalContant';
import bootbox from './../common/components/Bootbox/bootbox';
import {t} from './../../systems/i18n/index.js';

export function* getServices(action) {

    try {
        let response = yield call(apiService.getServices, action.payload.filter);

        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.FETCH_SERVICES.SUCCESS, payload: response.data});
        } else {
            yield put({type: Constants.FETCH_SERVICES.FAILED, payload: response.data});
        }
    } catch (err) {
        bootbox.error(t(err.message) || t('system.error'));
        yield put({type: Constants.FETCH_SERVICES.FAILED, payload: {}});
    }
}
