import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { AutoComplete } from 'antd';
import { processResponse } from '../../../../../../systems/api';
import apiService from '../../../apiService';

const getArrayUniqValue = (arr = []) => [...new Set(arr)];

class AutoCompleteCategory extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inputValue: null,
			dataSource: [],
		};

		this._isMounted = false;
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChange = lodash.debounce((textSearch) => {
		this.setState(
			{
				inputValue: textSearch,
				dataSource: !!textSearch ? [textSearch] : [' '],
			},
			() => {
				const { inputValue } = this.state;
				const { type } = this.props;
				if (!!inputValue) {
					processResponse(
						apiService.searchInfoProduct({
							type,
							key: inputValue,
						})
					).then((response) => {
						let result = lodash.get(response, 'data', []);
						result = result.map((item) => lodash.get(item, 'name', ''));
						result = getArrayUniqValue([inputValue, ...result]);
						if (this._isMounted) {
							this.setState({
								dataSource: result,
							});
						}
					});
				}
			}
		);
	}, 200);

	handleBlur = (value) => {
		this.handleSelect(value);
		this.props.onBlur();
	};

	handleSelect = (value) => {
		this.props.onSelect(value);
	};

	render() {
		const { defaultValue, placeholder, width, autoFocus, onFocus } = this.props;
		const { dataSource } = this.state;
		return (
			<AutoComplete
				placeholder={placeholder}
				defaultValue={defaultValue}
				dataSource={dataSource}
				onSelect={this.handleSelect}
				// onChange={(value) => this.handleChange(value)}
				onBlur={this.handleBlur}
				autoFocus={autoFocus}
				onFocus={onFocus}
				style={{ width }}
				disabled={this.props.disabled || false}
			/>
		);
	}
}
export default AutoCompleteCategory;

AutoCompleteCategory.defaultProps = {
	placeholder: '',
	type: '',
	width: 'unset',
	onSelect: () => {},
	onBlur: () => {},
	autoFocus: false,
};

AutoCompleteCategory.propTypes = {
	placeholder: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	onSelect: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	autoFocus: PropTypes.bool,
	width: PropTypes.string,
};
