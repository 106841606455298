import React from 'react';
import {connect} from 'react-redux';
import ListPurchases from './components';
import * as Selector from './../selectors';
import {
    fetchPurchases, requestOrdersForBuying,
    pusherAssignOrder, pusherUnassignOrder,
    pusherReadComment,clearDistributeOrder, beginDistributeOrder,
    clearCountStatusChanged, clearAllCountStatusChanged
} from "../actions";
import SearchableComponent from "../../common/SearchableComponent";
import lodash from "lodash";
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from './../../common/actions';
import {ORDER_STATUS} from "../../../systems/globalContant/actionContant";
import * as SelectorCustomer from "../../User/selectors";
import {
    confirmCustomerOrderBuying
} from './../../PurchaseBuying/OrderDetail/actions';
import * as PurchaseAddressSelector from './../../PurchaseAddress/selectors';
import * as WarehouseSelector from './../../Warehouse/selectors';
import {getPurchaseAddressList} from "./../../PurchaseAddress/actions";
import {getListWarehouseAction} from "./../../Warehouse/actions";
import {getListAgencyAction} from "./../../Agency/actions";
import * as AgencySelector from "./../../Agency/selectors";

const mapStateToProps = (state) => {
    return {
        channels: SelectorCustomer.getChannelOrder(state),
        orders: Selector.getOrders(state),
        stats: Selector.getStats(state),
        countStatusChanged: lodash.get(state, "Purchase.ListOrder.countStatusChanged", false),
        endDistributeOrder: lodash.get(state, "Purchase.ListOrder.endDistributeOrder", false),
        accountPurchase: Selector.getAccountPurchase(state),
        loading: lodash.get(state, "Purchase.ListOrder.loading", false),
        partner: lodash.get(state, "Purchase.ListOrder.partner", {id: 0, code: "", domain: ""}),
        pagination: lodash.get(state, "Purchase.ListOrder.pagination", {
            current_page: 1,
            page_total: 1,
            per_page: 20,
            total: 0
        }),
        isDistributiveWarehouseSetting: SelectorCustomer.getDistributiveWarehouseSetting(state),
        isUsingOrderDistributorSetting: SelectorCustomer.getOrderDistributorUsingSetting(state),
        user: SelectorCustomer.getMeSelector(state),
        purchaseAddresses: PurchaseAddressSelector.getListPurchaserAddressOptions(state),
        warehouses: WarehouseSelector.getListAccountPurchaser(state),
        agencies: AgencySelector.getListAgency(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        fetchPurchases: (filter) => {
            if (lodash.isEmpty(filter)) {
                filter = {...filter, ...{status: ORDER_STATUS.WAITING_PAI}}
            }
            dispatch(fetchPurchases({
                ...filter
            }));
        },
        onConfirmCustomerOrderBuying: (params) => {
            dispatch(confirmCustomerOrderBuying(params));
        },
        pusherAssignOrder: (orderData) => {
            dispatch(pusherAssignOrder(orderData));
        },
        pusherUnassignOrder: (orderData) => {
            dispatch(pusherUnassignOrder(orderData));
        },
        pusherReadComemnt: (orderData) => {
            dispatch(pusherReadComment(orderData));
        },
        clearCountStatusChanged: (status) => {
            dispatch(clearCountStatusChanged(status));
        },
        clearAllCountStatusChanged: () => {
            dispatch(clearAllCountStatusChanged());
        },
        clearDistributeOrder: () => {
            dispatch(clearDistributeOrder());
        },
        beginDistributeOrder: () => {
            dispatch(beginDistributeOrder());
        },
        requestOrdersForBuying(params) {
            dispatch(requestOrdersForBuying(params));
        },
        getPurchaseAddressList: (filter) => {
            filter.scope = 'basic';
            dispatch(getPurchaseAddressList(filter));
        },
        getListWarehouse: (filter) => {
            filter.scope = 'basic';
            dispatch(getListWarehouseAction(filter));
        },
        getListAgency: (filter) => {
            dispatch(getListAgencyAction(filter));
        },
    };
};

class ListPurchaseContainer extends SearchableComponent {
    componentDidMount() {
        super.componentDidMount();
    }

    onChangeFilter(filter) {
        this.props.fetchPurchases(filter);
    }

    onSearch(filter) {
        // Nếu search theo code thì reset filter status
        filter = lodash.mapValues(filter, (value, param) => {
            return (param === 'status' && filter.code) ? ORDER_STATUS.ALL : value;
        });

        if( !filter.status ){
            filter = {...filter, ...{status: ORDER_STATUS.WAITING_PAI}};
        }
        this.pushFilter({
            ...filter,
            _i: parseInt(filter._i || 0, 0) + 1,
        });
    }


    getCurrentFilter() {
        return {
            status: ORDER_STATUS.WAITING_PAI,
            code: '',
            invoice_code: '',
            seller_source: [],
            sort: 'asc',
            id_purchase_address: [],
            id_agency: [],
            ...this.getFilterFromLocation(this.props.location),
        };
    }

    render() {
        let filter = this.getCurrentFilter();
        return <ListPurchases {...{
            ...this.props,
            filter: filter,
            filterVersion: parseInt(filter._i, 0),
            onSearch: this.onSearch.bind(this),
        }}/>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListPurchaseContainer)
