import {createAction} from 'redux-actions';
import * as Constants from './constants';

export const fetchPurchases = createAction(Constants.FETCH_PURCHASES.REQUEST);
export const fetchPurchaserOrderStats = createAction(Constants.FETCH_PURCHASER_ORDER_STATS.REQUEST, filter => filter);
export const fetchServices = createAction(Constants.FETCH_SERVICES.REQUEST);
export const pusherAssignOrder = createAction(Constants.PUSHER_ASSIGN_ORDER, (orderData) => (orderData));
export const pusherUnassignOrder = createAction(Constants.PUSHER_UN_ASSIGN_ORDER, (orderData) => (orderData));
export const pusherReadComment = createAction(Constants.TELLER_READ_COMMENT, (orderData) => (orderData));
export const requestOrdersForBuying = createAction(Constants.CREATE_DISTRIBUTOR_OWNER.REQUEST, (datas) => ({datas}));
export const clearCountStatusChanged = createAction(Constants.CLEAR_COUNT_STATUS_CHANGED, (status) => (status));
export const clearAllCountStatusChanged = createAction(Constants.CLEAR_ALL_COUNT_STATUS_CHANGED, () => ({}));
export const clearDistributeOrder = createAction(Constants.CREATE_DISTRIBUTOR_OWNER.CLEAR, () => ({}));
export const beginDistributeOrder = createAction(Constants.CREATE_DISTRIBUTOR_OWNER.BEGIN, () => ({}));
