import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {Select} from 'antd';
import notification from "../../../systems/notification";
import apiService from "../apiService";
import {processResponse} from "../../../systems/api";

const {Option} = Select;

class SelectAgencies extends React.PureComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            agencies: [],
            loading: false,
        };
    }
    
    componentDidMount() {
        this.fetchAgencies();
    }
    
    fetchAgencies() {
        let {t} = this.props;
        this.setState({loading: true});

        processResponse(apiService.getListAgency())
            .then(res => {
                this.setState({agencies: lodash.get(res, "data", []), loading: false});
            })
            .catch(() => {
                notification.error(t('message.cant_fetch_data'));
                this.setState({loading: false});
            });
    }

    getValue() {
        let {value, multiple} = this.props;
        if (!value || value.length === 0)
            return undefined;

        if (multiple) {
            return Array.isArray(value) ? value.map(item => parseInt(item)) : [parseInt(value)];
        } else
            return parseInt(value);
    }

    render() {
        let {t} = this.props;

        return (
            <Select
                value={this.state.loading ? undefined : this.getValue()}
                mode={this.props.multiple ? 'multiple' : 'default'}
                loading={this.state.loading}
                onChange={this.props.onChange}
                placeholder={this.props.placeholder}
                showSearch={true}
                optionFilterProp="children"
                allowClear={this.props.allowClear}
                className={this.props.className}
            >
                {this.props.allOption && <Option value="">{t('label.all')}</Option>}
                {this.state.agencies.map(agency => <Option key={agency.id} value={agency.id}>{agency.name}</Option>)}
            </Select>
        );
    }
}

SelectAgencies.defaultProps = {
    value: null,
    placeholder: '',
    allOption: false,
    multiple: false,
    onChange: value => {},
};

SelectAgencies.propTypes = {
    value: PropTypes.any,
    placeholder: PropTypes.string,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
};

export default translate()(SelectAgencies);
