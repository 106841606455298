import notification from "../../../systems/notification";
import {t} from '../../../systems/i18n/index.js';
import lodash from 'lodash';

export function* uploadFileSuccess(action) {
    yield  notification.success(t('transaction:message.upload_success', {filename: lodash.get(action, 'payload.file.name', '')}));
}

export function* uploadFileFailed(action) {
    let responseFile = lodash.get(action, "payload.file.response.data.file", "");
    if (responseFile === "file is edited"){
        return yield  notification.error(t('transaction:message.upload_failed_edited', {filename: lodash.get(action, 'payload.file.name', '')}));
    }
    if (lodash.startsWith(responseFile, 'The file must be')) {
        return yield  notification.error(t('transaction:message.upload_failed_extension'));
    }

    if (lodash.get(action, "payload.file.response.data.filename", "") === "INVALID"){
        return yield  notification.error(t('transaction:message.upload_failed_filename', {filename: lodash.get(action, 'payload.file.name', '')}));
    }

    if (lodash.get(action, "payload.file.response.data.account", "") === "INVALID"){
        return yield  notification.error(t('transaction:message.upload_failed_account'));
    }

    let account_invalid = lodash.get(action, "payload.file.response.data.account_invalid", "");
    if (account_invalid){
        return yield  notification.error(t('transaction:message.upload_failed_account', {account: account_invalid}));
    }

    if (lodash.get(action, "payload.file.response.data.open_zip", "") === "INVALID"){
        return yield  notification.error(t('transaction:message.upload_failed_open_zip'));
    }

    if (lodash.get(action, "payload.file.response.data.num_files", "") === "INVALID"){
        return yield  notification.error(t('transaction:message.upload_failed_num_files'));
    }

    if (lodash.get(action, "payload.file.response.data.extension", "") === "INVALID"){
        return yield  notification.error(t('transaction:message.upload_failed_extension'));
    }

    if (lodash.get(action, "payload.file.response.data.file_zip", "") === "extract_error"){
        return yield  notification.error(t('transaction:message.upload_failed_extract_error'));
    }
    return yield  notification.error(t('error_page.permission_not_allow', {filename: lodash.get(action, 'payload.file.name', '')}));
}
