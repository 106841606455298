import { Col, Row, Spin } from "antd";
import get from "lodash/get";
import React from "react";
import { translate } from "react-i18next";

import { currentFormatter } from "../../../modules/common/services/format";
import { t } from "../../../systems/i18n";

const KEY_STATS_REPORT = ["grand_total_bought", "grand_total", "real_payment_amount", "real_refund_amount", "order_profit", ];

function FormStats({ stats, loading }) {
	return (
		<Row className="a-list mt-5 d-flex justify-content-between ">
			{KEY_STATS_REPORT.map((item, index) => {
				const value = get(stats, item, "");
				const value_converted = get(stats, item + "_converted", "");

				return (
					<Col span={4} key={index} className="dashboard-box">
						<div className="dashboard_stats d-flex flex-column">
							<span className="a-text--bold a-text--color-black a-text--fz-16">
								{t(`sale:stats.${item}`)}
							</span>
							<Spin spinning={loading}>
								<p className="text-secondary a-text--uppercase a-text--fz-11">
									{value_converted
										? currentFormatter.toLocaleStringCurrencyVND(parseFloat(value_converted)) + "đ"
										: "---"}
								</p>
								<p className="text-secondary a-text--uppercase a-text--fz-11">
									{value
										? currentFormatter.toLocaleStringCurrency(parseFloat(value), 5) + "¥"
										: "---"}
								</p>
							</Spin>
						</div>
					</Col>
				);
			})}
		</Row>
	);
}

export default translate()(FormStats);
