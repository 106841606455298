import {combineReducers} from 'redux';
import * as ACTION from './constants';
import * as LogItemConstant from "./../../LogItem/constants";
import lodash from "lodash";

let defaultFilter = {
    type: ACTION.LOG_TYPE.COMMENT,
    collapse: null,
    filter_public: null,
};

let filter = (state = {...defaultFilter}, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.UPDATE_FILTER:
            return {...state, ...payload.filter};

        case ACTION.CLEAR_STATE:
            return {...defaultFilter};

        default:
            return state;
    }
};

let logs = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case LogItemConstant.GET_PUSHER_COMMENT:
            return [action.payload, ...state];
        case LogItemConstant.GET_PUSHER_COMMENT_ITEM:
            return state;
        case ACTION.FETCH_LOGS.SUCCESS:
            return payload.data;

        case ACTION.COLLAPSE_ALL_LOGS:
            return state.map(log => ({...log, collapse: payload.collapse}));

        case ACTION.COLLAPSE_LOG:
            let {logId, collapse} = payload;

            return state.map(log => {
                return log.id === logId ? {...log, collapse} : log;
            });
        case ACTION.COMMENT.SUCCESS:
            let actionComment = lodash.get(action, 'payload.data.action', '');
            if (actionComment === 'COMPLAINT_COMMENT' || actionComment === 'PRODUCT_RETURN_COMMENT') {
                return [action.payload.data, ...state];
            }

            return  [...state];
        case ACTION.CLEAR_STATE:
            return [];
        case LogItemConstant.POST_LOG_ITEM.SUCCESS:
            return state;

        default:
            return state;
    }
};

let logsError = (state = null, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_LOGS.FAILED:
            return payload;

        case ACTION.FETCH_LOGS.SUCCESS:
        case ACTION.COMMENT.SUCCESS:
        case ACTION.CLEAR_STATE:
            return null;

        default:
            return state;
    }
};

let logsLoading = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.FETCH_LOGS.REQUEST:
            return true;

        case ACTION.FETCH_LOGS.SUCCESS:
        case ACTION.FETCH_LOGS.FAILED:
        case ACTION.CLEAR_STATE:
            return false;

        default:
            return state;
    }
};

let defaultCommentInput = {
    message: '',
};

let commentInput = (state = {...defaultCommentInput}, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.COMMENT.REQUEST:
            return {...state, ...payload.comment};

        case ACTION.COMMENT.SUCCESS:
            return {...state, message: ''};

        case ACTION.CLEAR_STATE:
            return {...defaultCommentInput};

        default:
            return state;
    }
};

let commentVersion = (state = 1, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.COMMENT.SUCCESS:
            return state + 1;

        default:
            return state;
    }
};

let commentError = (state = null, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.COMMENT.FAILED:
            return payload;

        case ACTION.COMMENT.SUCCESS:
        case ACTION.CLEAR_STATE:
            return null;

        default:
            return state;
    }
};

let commentLoading = (state = {}, action) => {
    let {type} = action;
    switch (type) {
        case ACTION.COMMENT.REQUEST:
            return {...state, [lodash.get(action, 'payload.comment.is_public') ? 'public' : 'private']: true};

        case ACTION.COMMENT.SUCCESS:
        case ACTION.COMMENT.FAILED:
            return {...state, [lodash.get(action, 'request.comment.is_public') ? 'public' : 'private']: false};

        case ACTION.CLEAR_STATE:
            return {};

        default:
            return state;
    }
};

/**
 * State lưu comments (hiện tại chỉ lưu order comments)
 *
 * @param state
 * @param action
 * @return {*}
 */
let comments = (state = [], action) => {
    let {type, payload} = action;

    if (lodash.get(action, 'request.object') !== 'order') {
        return state;
    }

    switch (type) {
        case ACTION.FETCH_COMMENTS.SUCCESS:
            return payload.data;

        case ACTION.COMMENT.SUCCESS:
            return [payload.data, ...state];

        case ACTION.CLEAR_STATE:
            return [];

        default:
            return state;
    }
};

let commentsError = (state = null, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.FETCH_COMMENTS.FAILED:
            return payload;

        case ACTION.FETCH_COMMENTS.SUCCESS:
        case ACTION.CLEAR_STATE:
            return null;

        default:
            return state;
    }
};

let commentsLoading = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.FETCH_COMMENTS.REQUEST:
            return true;

        case ACTION.FETCH_COMMENTS.SUCCESS:
        case ACTION.FETCH_COMMENTS.FAILED:
        case ACTION.CLEAR_STATE:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    filter,
    logs,
    logsError,
    logsLoading,
    commentInput,
    commentVersion,
    commentError,
    commentLoading,
    comments,
    commentsError,
    commentsLoading,
});
