import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@atlaskit/button';

class CreateProductReturn extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    onCreateProductReturn() {
        let {isLoading = false, createProductReturn} = this.props;
        if (!isLoading) {
            createProductReturn();
        }
    }

    render() {
        let {t, isLoading, canProductReturn} = this.props;
        return (
            <div className="cs-box-title d-flex align-item-center p-3 pt-2 pb-2 a-text--bold">
                <p className="a-text--fz-14 d-flex align-item-center a-text--uppercase">
                    {t('complaintSeller:product_return.request_product_return')}
                </p>
                {
                    canProductReturn ?
                    <a href="#" className={"" + (isLoading ? "a-disable" : "")}
                       onClick={this.onCreateProductReturn.bind(this)}>
                        <i className="ion-plus-round"/>&nbsp;
                        {t('complaintSeller:detail.create_product_return')}
                    </a> : null
                }
            </div>
        );
    }
}

CreateProductReturn.defaultProps = {
    canProductReturn: false,
    isLoading: false,
    createProductReturn: ()=> {
    },
};

CreateProductReturn.propTypes = {
    canProductReturn: PropTypes.bool,
    isLoading: PropTypes.bool,
    createProductReturn: PropTypes.func
};

export default translate()(CreateProductReturn);
