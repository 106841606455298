import React from "react";
import {Button} from "antd";
import {events, withEvent} from "../../../../systems/events";
import {translate} from "react-i18next";
import {CRAWL_EVENTS} from "../../RelatedTransaction/components/RelatedTransactionScanningTool/constans";

const DEFAULT_INDEX = -1;

class SynchronizingButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentIndex: DEFAULT_INDEX,
            isCrawling: false,
            transactions: []
        }
    }

    componentDidMount() {
        this.setState({transactions: this.props.transactions});
        this.props.on({
            [CRAWL_EVENTS.STOP_SYNCHRONIZING]: () => this.reset(),
            [CRAWL_EVENTS.GO_TO_NEXT_ONE]: () => this.next()
        });
    }

    onClick() {
        let {isCrawling} = this.state;
        if (!isCrawling) {
            this.setState({isCrawling: true}, () => this.next());
        }
    }

    next() {
        let { currentIndex, isCrawling } = this.state;
        let { transactions } = this.props;
        let nextIndex = currentIndex+1;

        if (!isCrawling)
            return;
        this.setState({currentIndex: nextIndex});
        if (nextIndex < transactions.length) {
            // Bắn sự kiện kèm thông tin của GD để quét
            events.dispatch(CRAWL_EVENTS.DISPATCH_TRANSACTION, {transaction: transactions[nextIndex]});
        } else {
            this.reset();
        }
    }

    reset() {
        this.setState({isCrawling: false, currentIndex: DEFAULT_INDEX});
    }

    onCancel() {
        this.reset();
    }

    render() {
        let {t} = this.props;
        let {isCrawling} = this.state;

        return (
            <>
                <Button
                    className="a-font--12 aphz-24 a-btn--primary-ant ml-4 mr-4"
                    size={"small"}
                    icon="sync"
                    onClick={this.onClick.bind(this)}
                    disabled={isCrawling}
                    loading={isCrawling}
                >Đồng bộ GD liên quan</Button>
                <Button
                    className=" a-font--12 a-btn--primary-ant a-btn--link a-text--red"
                    size={"small"}
                    onClick={this.onCancel.bind(this)}
                    disabled={!isCrawling}
                >{t('btn.cancel')}</Button>
            </>
        );
    }

}

export default translate()(withEvent(SynchronizingButton));
