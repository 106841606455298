import React from 'react';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {withEvent} from '../../../systems/events';
import {processResponse} from '../../../systems/api';
import {dispatch, getState} from '../../../systems/store';
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from '../../../modules/common/actions';
import notification from '../../../systems/notification';
import apiService from '../../../modules/Order/apiService';
import SearchableComponent from '../../../modules/common/SearchableComponent';
import {setActiveSettingItem} from '../../../modules/Settings/SettingMenu/actions';
import INVOICE_EVENT from '../../../modules/SourceInvoice/events';
import {TOOL_CRAWL_INVOICE_EVENT, TOOL_CRAWL_INVOICE_ERROR} from '../../../modules/SourceInvoice/constans';
import * as reducers from './reducers';
import FormSearch from './FormSearch';
import ListOrder from './ListOrder';
import ToolAlert from './ToolAlert';
import {Layout} from 'antd';
import {CHANNELS} from "../../../systems/globalContant";
const Content = Layout.Content;

class ScanOrderScreen extends SearchableComponent {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            pagination: {},
            listScanningStatus: [],
            loading: false,
            crawling: false,
            filterLongTime: []
        };

        this.defaultFilter = {
            page: 1,
            per_page: 50,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.setPageInfo();

        this.props.on(INVOICE_EVENT.CRAWL_ALL, (event, payload) => {
            let state = {};
            lodash.forIn(reducers, (reducer, param) => {
                let nextValue = reducer(this.state[param], {type: event, payload});

                if (nextValue !== this.state[param]) {
                    state[param] = nextValue;
                }
            });

            if (!lodash.isEmpty(state)) {
                this.setState(state);
            }

            // Hiên thị lỗi khi bị anti-bot
            if (
                event === INVOICE_EVENT.CRAWL_CANCEL
                && lodash.includes([TOOL_CRAWL_INVOICE_ERROR.UNAUTHORIZED, TOOL_CRAWL_INVOICE_ERROR.CAPTCHA, TOOL_CRAWL_INVOICE_ERROR.ACCOUNT], lodash.get(payload, 'error.code'))
            ) {
                notification.error(lodash.get(payload, 'error.message'));
            }
        });
    }

    setPageInfo() {
        let {t} = this.props;

        dispatch(setPageTitle(t('order:scan.page.title')));
        dispatch(setPageDescription(t('order:scan.page.description')));
        dispatch(setMenuActive('orders_scan'));
        setActiveSettingItem('orders_scan');
        dispatch(setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'order:scan.page.title',
                'link': '/orders/scan'
            },
        ]));
    }

    onSearch(filter) {
        if (this.state.loading || this.state.crawling) {
            return;
        }

        filter = filter.code ? lodash.pick(filter, ['i', 'code']) : filter;

        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    }

    onChangeFilter(filter) {
        if (this.state.loading || this.state.crawling) {
            return;
        }

        this.fetchOrders(filter);
    }

    fetchOrders(filter) {
        let {t} = this.props;

        this.setState({loading: true});

        let res = apiService.scanableOrders({...filter, time_type: filter.time_type ? filter.time_type : 'DEPOSITED'})
            .finally(() => this.setState({loading: false}));

        let process = processResponse(res);

        process.then(res => {
            this.setState({
                orders: lodash.get(res, 'data.orders') || [],
                pagination: lodash.get(res, 'data.pagination') || {},
                listScanningStatus: lodash.get(res, 'data.listScanningStatus') || [],
                filterLongTime : lodash.get(res, 'data.filterLongTime') || []
            });
        });

        process.catch(() => {
            notification.error(t('message.cant_fetch_data'));
        });

        return process;
    }

    reloadOrderWhenCrawlSuccess(orders) {
        this.setState({orders})
    }

    render() {
        let filter = this.getFilter();
        const user = getState('User.me.user', {});
        const channels = [CHANNELS.ORDER_CRAWL + "_" + user.id];
        return (
            <Layout>
                <Content className="a-content--ant-layout">
                    <ToolAlert/>
                    <FormSearch
                        input={filter}
                        inputVersion={filter.i || 0}
                        loading={this.state.loading}
                        listScanningStatus={this.state.listScanningStatus}
                        filterLongTime={this.state.filterLongTime}
                        canSearch={!this.state.crawling}
                        onSearch={this.onSearch.bind(this)}
                    />
                    <ListOrder
                        orders={this.state.orders}
                        pagination={this.state.pagination}
                        filter={filter}
                        loading={this.state.loading}
                        canSearch={!this.state.crawling}
                        onSearch={this.onSearch.bind(this)}
                        fetchOrders={this.fetchOrders.bind(this)}
                        channels={channels}
                        reloadOrderWhenCrawlSuccess={this.reloadOrderWhenCrawlSuccess.bind(this)}
                    />
                </Content>
            </Layout>
        );
    }
}

export default translate()(withEvent(ScanOrderScreen));


