import React from 'react'
import { translate } from 'react-i18next'
import lodash from 'lodash'
import ModalConfirm from './../../../../common/components/ModalConfirm'

import ModalConfirmUpdatePriceItems from './ModalConfirmUpdatePriceItems'
import ModalConfirmUpdateQuantitytems from './ModalConfirmUpdateQuantitytems'
import PropTypes from 'prop-types'
import ModalUpdateCustoms from './ModalUpdateCustoms'
import UpdateHSCodeMultipleItems from '../HSCode/UpdateHSCodeMultipleItems'
import { Icon, Modal, Tooltip, notification } from 'antd'
import apiService from '../../apiService'
import queryString from 'query-string'
import { t } from '../../../../../systems/i18n'
class UpdateMultipleItems extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpenConfirmOutOfStock: false,
            isOpenConfirmUpdatePrice: false,
            isOpenConfirmUpdateQuantityItems: false,
        }
    }

    /**
     * Xử lý Bật tắt confirm sửa số lượng, hết hàng, sửa giá
     * @param event
     */
    onClickOpenFormEdit(type, event) {
        event.preventDefault()
        let isOpenConfirmOutOfStock = this.state.isOpenConfirmOutOfStock
        let isOpenConfirmUpdatePrice = this.state.isOpenConfirmUpdatePrice
        let isOpenConfirmUpdateQuantityItems = this.state.isOpenConfirmUpdateQuantityItems
        switch (type) {
            case 'isOpenConfirmOutOfStock':
                this.props.beginProcessUpdateOutStock()
                this.setState({ isOpenConfirmOutOfStock: !isOpenConfirmOutOfStock })
                if (isOpenConfirmOutOfStock) {
                    this.props.onClearCheckAll()
                }
                break
            case 'isOpenConfirmUpdatePrice':
                this.props.beginProcessUpdatePriceItems()
                this.setState({ isOpenConfirmUpdatePrice: !isOpenConfirmUpdatePrice })
                if (isOpenConfirmUpdatePrice) {
                    this.props.onClearCheckAll()
                }
                break
            case 'isOpenConfirmUpdateQuantityItems':
                this.props.beginProcessUpdateQuantityItems()
                this.setState({ isOpenConfirmUpdateQuantityItems: !isOpenConfirmUpdateQuantityItems })
                if (isOpenConfirmUpdateQuantityItems) {
                    this.props.onClearCheckAll()
                }
                break
            default:
                return null
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = { ...prevState }
        if (lodash.get(nextProps, 'isEndProcess.postUpdateOutStock', false) === true) {
            nextState.isOpenConfirmOutOfStock = false
        }
        if (lodash.get(nextProps, 'isEndProcess.postUpdatePriceItems', false) === true) {
            nextState.isOpenConfirmUpdatePrice = false
        }
        if (lodash.get(nextProps, 'isEndProcess.postUpdateQuantityItems', false) === true) {
            nextState.isOpenConfirmUpdateQuantityItems = false
        }

        if (lodash.get(nextProps, 'isEndProcess.postUpdateQuantityItems', false) === true) {
            nextState.isOpenConfirmUpdateQuantityItems = false
        }

        return nextState
    }

    /**
     * Xử lý hết hàng nhiều sản phẩm
     */
    updateOutOfStockItems() {
        let { order, order_item_checked } = this.props
        this.props.updateOutOfStockItems({ id: order.id, item_ids: order_item_checked })
    }

    /**
     * Xử lý cập nhật giá nhiều sản phẩm
     */
    updatePriceItems(dataForm) {
        this.props.updatePriceItems(dataForm)
    }

    /**
     * Xử lý cập nhật số lượng nhiều sản phẩm
     */
    updateQuantityItems(dataForm) {
        this.props.updateQuantityItems(dataForm)
    }

    updateMultiNameAndUnit = event => {
        event.preventDefault()
        const { classifyProduct, items, match } = this.props

        Modal.confirm({
            className: 'a-modal--confirm-trade',
            title: 'Xác nhận thông tin hải Tên VN và Đơn vị VN tất cả sản phẩm',
            content: '',
            cancelText: 'Hủy',
            okText: 'Đồng ý',
            zIndex: 1050,
            icon: (
                <Icon
                    type="question-circle"
                    theme="filled"
                />
            ),
            onOk: () => {
                const itemWithId = items.map(element => {
                    classifyProduct.forEach(item => {
                        if (lodash.replace(item.title || '', /\s/g, '') === lodash.replace(element.title || '', /\s/g, '')) {
                            element.labels = item.labels
                            element.units = item.units
                        }   
                    })  
                    return element
                })

                // const itemWithId = classifyProduct.map(item => {
                //     items.forEach(element => {
                //         if (lodash.replace(item.title || '', /\s/g, '') === lodash.replace(element.title || '', /\s/g, '')) {
                //             item.id = element.id
                //         }
                //     })
                //     return item
                // })

                const data = itemWithId.map(item => {
                    return {
                        name: 'data',
                        id: item.id,
                        value: [
                            {
                                name: 'customs_info_name_vn',
                                value: lodash.get(item, 'labels.0'),
                            },
                            {
                                name: 'unit_vn',
                                value: lodash.get(item, 'units.0'),
                            },
                        ],
                    }
                })

                return Promise.all(data.map(item => apiService.updateCustomsInfo(item.id, item)))
                    .then(res => {
                        const firstError = lodash.get(res, '0.data.code', {})

                        if (firstError === 'INPUT_INVALID') {
                            res.forEach(val => {
                                if (firstError === 'INPUT_INVALID') {
                                    const data = lodash.get(val, 'data.data', {})
                                    if (data) {
                                        const connect_error = lodash.get(data, 'connect_error', '')
                                        if (!lodash.isEmpty(connect_error)) {
                                            notification.error({
                                                message: 'Xác nhận thông tin hải Tên VN và Đơn vị VN tất cả sản phẩm không thành công, vui lòng thử lại',
                                            })
                                        } else {
                                            Object.keys(data).map(key => {
                                                notification.error({
                                                    message: t(`validation:${Object.keys(data[key])[0].toLowerCase()}`, { attribute: t(`order:label.${key}`) }),
                                                })
                                            })
                                        }
                                    } else {
                                        notification.error({ message: `Xác nhận thông tin hải Tên VN và Đơn vị VN tất cả sản phẩm không thành công` })
                                    }
                                }
                            })
                        } else {
                            let filters = { ...queryString.parse(window.location.search), ...match.params }
                            this.props.getPurchasingOrderDetailAction(filters)
                            notification.success({ message: `Xác nhận thông tin hải Tên VN và Đơn vị VN tất cả sản phẩm thành công` })
                        }
                    })
                    .catch(err => {})
            },
            onCancel:()=>{
            }
        })
    }

    render() {
        let { t, order, order_item_checked, isLoading, isEndProcess, errorUnitPriceItems, items, classifyProduct } = this.props
        let { isOpenConfirmOutOfStock, isOpenConfirmUpdatePrice, isOpenConfirmUpdateQuantityItems } = this.state
        const isAcceptAllNameAndUnit = lodash.every(items, item=>item.unit_vn && item.customs_info_name_vn)

        return (
            <div className={'a-purchasod__action-pr__left '}>
                {order.canUpdateOutOfStock && (
                    <span>
                        <a
                            href=""
                            className={'' + (order_item_checked.length && order.canChangeQuantity && items.length ? '' : 'a-disable')}
                            onClick={this.onClickOpenFormEdit.bind(this, 'isOpenConfirmOutOfStock')}>
                            {t('orderDetail:order_detail.out_stock')}
                        </a>
                    </span>
                )}
                <span className="seperate-v">
                    <a
                        href=""
                        className={'' + (order_item_checked.length && order.canChangePrice && items.length ? '' : 'a-disable')}
                        onClick={this.onClickOpenFormEdit.bind(this, 'isOpenConfirmUpdatePrice')}>
                        {t('orderDetail:order_detail.edit_prirce')}
                    </a>
                </span>
                <span className="seperate-v">
                    <a
                        href=""
                        className={'' + (order_item_checked.length && order.canChangeQuantity && items.length ? '' : 'a-disable')}
                        onClick={this.onClickOpenFormEdit.bind(this, 'isOpenConfirmUpdateQuantityItems')}>
                        {t('orderDetail:order_detail.edit_quantity')}
                    </a>
                </span>
                <ModalUpdateCustoms
                    order={order}
                    order_item_checked={order_item_checked}
                    items={items}
                    updateItemsOrderDetail={this.props.updateItemsOrderDetail}
                    onClearCheckAll={this.props.onClearCheckAll}
                    classifyProduct={classifyProduct}
                    match={this.props.match}
                    getPurchasingOrderDetailAction={this.props.getPurchasingOrderDetailAction}
                />
               {!isAcceptAllNameAndUnit && <span className="seperate-v">
                    {' '}
                    <Tooltip title={'Xác nhận thông tin hải Tên VN và Đơn vị VN tất cả sản phẩm'}>
                        <a
                            href=""
                            onClick={this.updateMultiNameAndUnit}>
                            <Icon
                                type="check"
                                className="ml-1"
                            />
                        </a>{' '}
                    </Tooltip>
                </span>}

                <UpdateHSCodeMultipleItems
                    orderId={order.id}
                    items={order_item_checked}
                    updateItemsOrderDetail={this.props.updateItemsOrderDetail}
                    onClearCheckAll={this.props.onClearCheckAll}
                    seperate={true}
                    updateHSCodeOrderDetail={this.props.updateHSCodeOrderDetail}
                />
                {isOpenConfirmOutOfStock && !isEndProcess.postUpdateOutStock ? (
                    <ModalConfirm
                        title={t('order:detail.confirm')}
                        isLoading={isLoading}
                        onClearCheckAll={this.props.onClearCheckAll}
                        notice={t('order:detail.notice_out_stock')}
                        onSubmitForm={this.updateOutOfStockItems.bind(this)}
                        onClose={this.onClickOpenFormEdit.bind(this, 'isOpenConfirmOutOfStock')}
                    />
                ) : null}
                {isOpenConfirmUpdatePrice && !isEndProcess.postUpdatePriceItems ? (
                    <ModalConfirmUpdatePriceItems
                        isLoading={isLoading}
                        order={order}
                        clearError={this.props.clearErrorWhileUpdatePrice}
                        order_item_checked={order_item_checked}
                        onClearCheckAll={this.props.onClearCheckAll}
                        beginClearErrorUpdatePriceItems={this.props.beginClearErrorUpdatePriceItems}
                        errorUnitPriceItems={errorUnitPriceItems}
                        onSubmitForm={dataForm => this.updatePriceItems(dataForm)}
                        onClose={this.onClickOpenFormEdit.bind(this, 'isOpenConfirmUpdatePrice')}
                    />
                ) : null}
                {isOpenConfirmUpdateQuantityItems && !isEndProcess.postUpdateQuantityItems ? (
                    <ModalConfirmUpdateQuantitytems
                        isLoading={isLoading}
                        order={order}
                        clearError={this.props.clearErrorWhileUpdatePrice}
                        order_item_checked={order_item_checked}
                        onClearCheckAll={this.props.onClearCheckAll}
                        beginClearErrorUpdatePriceItems={this.props.beginClearErrorUpdatePriceItems}
                        errorUnitPriceItems={errorUnitPriceItems}
                        onSubmitForm={dataForm => this.updateQuantityItems(dataForm)}
                        onClose={this.onClickOpenFormEdit.bind(this, 'isOpenConfirmUpdateQuantityItems')}
                    />
                ) : null}
            </div>
        )
    }
}

UpdateMultipleItems.defaultProps = {
    order: {},
    errorUnitPriceItems: {},
    isLoading: false,
    isEndProcess: {},
    order_item_checked: [],
    onClearCheckAll: () => {},
}

UpdateMultipleItems.propTypes = {
    order: PropTypes.object,
    errorUnitPriceItems: PropTypes.object,
    isLoading: PropTypes.bool,
    isEndProcess: PropTypes.object,
    order_item_checked: PropTypes.array,
    onClearCheckAll: PropTypes.func,
}

export default translate('translations')(UpdateMultipleItems)
