import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {Tooltip as TooltipAntd, Spin} from "antd";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {translate} from 'react-i18next';
import {url} from './../../../../systems/routing/index';
import FormProcessing from './../../../common/components/FormProcessing';
import {ModalTransition} from '@atlaskit/modal-dialog';
import ModalUpdateForm from './ModalUpdateForm';
import MenuExecute from './MenuExecute';
import {dateFormatter} from "../../../common/services/format";
import {COMPLAINT_SELLER_STATUS} from "../../../../systems/globalContant/actionContant";
import Link from "../../../../systems/routing/Link";
import bootbox from './../../../common/components/Bootbox/bootbox';
import Tooltip from '@atlaskit/tooltip';
import MarkImportantComplaint from '../../ComplaintMarkImportant/ComplaintSellerMarkImportantContainer';
import authService from "../../../Auth/authService";
import * as PERMISSION from "../../../Permission/permissions";
import RejectComplaint from "../../ComplaintRejection/components/RejectComplaint";
import {Row, Col} from "antd";
import SiteIcon from "../../../Image/SiteIcon";

/**
 * Dữ liệu tìm kiếm mặc định
 */
const stateDefault = {
    code: '',
    buyer_username: '',
    complaint_seller_reason_id: [],
    seller_source: '',
    total_item_link: '',
    real_amount_compare: '',
    grand_total_to: '',
    grand_total_from: '',
    log_status_to: '',
    log_status_from: '',
    operation: '',
    sort: 'asc'
};

class ListComplaintSeller extends FormProcessing {
    constructor(props) {
        super(props);

        this.state = {
            input: stateDefault,
            isOpenConfirmStatus: false,
            isOpenForm: false,
            isOpenUpdateForm: false,
            inputVersion: 0,
            changedParams: [],
        };
    }

    /**
     * handle việc đóng popup form lại
     */
    closeModalForm = () => {
        this.props.closeModalForm();
        this.setState({isOpenForm: false, isOpenUpdateForm: false});
    }

    /**
     * handle việc thay đổi dữ liệu select
     */
    onChangeSelect(param, select) {
        let value = select.map((selectItem) => {
            return selectItem.value;
        });
        let input = this.changeInput({[param]: value});
        this.props.onSubmit(input);
    }

    /**
     * handle việc enter thì tìm kiếm dữ liệu
     */
    onKeyPressInput(input, event) {
        if (event.key === 'Enter') {
            this.props.onSubmit(input);
        }
    }


    /**
     * Xử lý submit ở các subform
     *
     * @param {Object} data
     */
    onSubmitSubForm(data) {
        let input = this.changeInput(data);
        this.props.onSubmitForm(input);
    }

    /**
     * Handle việc mở modal form
     */
    openModalForm = () => {
        this.setState({isOpenForm: true, accountPurchaser: {}});
    };

    render() {
        let {
            t,
            complaintSellerReasons,
            complaintSeller,
            isLoading
        } = this.props;
        let {isOpenUpdateForm} = this.state;

        return (
            <React.Fragment>
                {complaintSeller.map(complaintSeller => {
                    let priority = lodash.get(complaintSeller, "time_remaining.priority", '');
                    let time = lodash.get(complaintSeller, "time_remaining.time", '');
                    let status = lodash.get(complaintSeller, "status", '');
                    let currencySymbol = lodash.get(complaintSeller, "order.currencySymbol",  '¥');
                    let expirationColor;
                    if(status !== "WAITING" && status !== "PROCESSING") {
                        expirationColor = ""
                    } else {
                        if (time === 0) {
                            expirationColor = "a-text--red"
                        } else {
                            switch (priority) {
                                case "high":
                                    expirationColor = "a-text--danger";
                                    break;
                                case "medium":
                                    expirationColor = "a-text--yellow";
                                    break;
                                case "low":
                                    expirationColor = "a-text--success";
                                    break;
                                default:
                                    expirationColor = "";
                            }
                        }
                    }
                    let origin_link = "";
                    if (complaintSeller.origin_code){
                        switch (complaintSeller.order.seller_source) {
                            case "taobao":
                            case "tmall":
                                origin_link = "https://refund2.taobao.com/dispute/detail.htm?disputeId=" + complaintSeller.origin_code;
                                break;
                            case "1688":
                                origin_link = "https://dispute.1688.com/refund/assureRefundDetail.htm?refundId=" + complaintSeller.origin_code;
                                break;
                        }
                    }

                    return (
                        <div className="a-item__complaint" key={complaintSeller.id}>
                            <Row gutter={10}>
                                <Col span={5} className="item_info">
                                    <MarkImportantComplaint
                                        complaint={complaintSeller}
                                        view={true}
                                        input={lodash.pick(complaintSeller, ['id', 'is_important'])}
                                        permissions={lodash.get(complaintSeller, 'complaintSellerPermissions', {})}
                                        inputVersion={(complaintSeller.id + complaintSeller.is_important)}
                                    />&nbsp;
                                    <div>
                                        <div className="flex-start link_item">
                                            <Link isNewTab={true}
                                                  href={url.to('purchase.buying.detail', {id: complaintSeller.id_order})}
                                            >
                                                {complaintSeller.order_code}
                                            </Link>
                                            <CopyToClipboard
                                                text={complaintSeller.order_code}
                                                onCopy={() => bootbox.success(t("message.copied") + ": " + complaintSeller.order_code)}
                                            >
                                                <i className="far fa-copy copy"/>
                                            </CopyToClipboard>
                                            <p className="ml-2 more_info__action text-nowrap">
                                                <Link isNewTab={true}
                                                      href={url.to('complaint-seller.detail', {id: complaintSeller.id})}
                                                      className="link_item">
                                                    {t('complaintSeller:list.detail')}
                                                </Link>
                                                {
                                                    complaintSeller.status === 'WAITING' &&
                                                    authService.canAny([PERMISSION.COMPLAINT_SELLER_ASSIGN, (PERMISSION.COMPLAINT_SELLER_RECEIVE)]) &&
                                                    authService.canAny([PERMISSION.COMPLAINT_SELLER_CHANGE_FAILURE, (PERMISSION.COMPLAINT_SELLER_CHANGE_FAILURE_ME)]) &&
                                                    <RejectComplaint onList complaint={complaintSeller}/>
                                                }
                                            </p>
                                        </div>

                                        <p>
                                        {
                                            origin_link &&
                                            <TooltipAntd title="Mã KN site gốc">
                                                <Link isNewTab={true} href={origin_link}>
                                                            <span className="complaint-origin-code">
                                                                {complaintSeller.origin_code}
                                                            </span>
                                                </Link>
                                            </TooltipAntd>
                                        }
                                        </p>
                                    {lodash.get(complaintSeller, 'source_invoice', []).map(sourceInvoice => (
                                        <p className="more_info__item"
                                           key={sourceInvoice.invoice_code + "_" + complaintSeller.id}>
                                            {sourceInvoice.invoice_code}
                                        </p>
                                    ))}</div>
                                </Col>
                                <Col span={3} className="item_reason">
                                    <div className="group__inline">
                                        <span className="reason__text">{complaintSeller.reason_title}</span>
                                    </div>
                                    <div className="group__inline">
                                        {
                                            complaintSeller.financial_valid ?
                                                <span className="a-text--color-green a-text--fz-12">Tài chính hợp lệ</span> :
                                                <span className="a-text--light-red a-text--fz-12">Tài chính không hợp lệ</span>
                                        }
                                    </div>
                                </Col>
                                <Col span={3} className="item_ubought">
                                    <div className="group__inline">
                                        {complaintSeller.order_seller_source ? (
                                            <span className="a-font--12">
                                                 <SiteIcon
                                                     className="mr-2 img-shop"
                                                     src={complaintSeller.order_seller_source}
                                                     alt={complaintSeller.order_seller_source}
                                                 />
                                            </span>
                                        ) : null}
                                        <span className="a-font--12">{complaintSeller.order_seller_name}</span>
                                    </div>
                                        {
                                            complaintSeller.has_transaction === 0 &&
                                            <div className="group__inline a-text--fz-12">Thiếu giao dịch</div>
                                        }
                                </Col>
                                <Col span={3} className="item_cost">
                                    <Tooltip
                                        content={complaintSeller.status === "SUCCESS" ?
                                            t('complaintSeller:list.amount_earned_success') : t('complaintSeller:list.amount_earned_alipay')}>
                                        <span className="a-text--red a--cursor-poiter"
                                              role="button">{complaintSeller.amountEarned}{currencySymbol}</span>
                                        </Tooltip>
                                        &nbsp;/&nbsp;
                                        <Tooltip content={t('complaintSeller:list.amount_want_to_earn')}>
                                        <span className="a--cursor-poiter">
                                            {complaintSeller.amountFormat}{currencySymbol}
                                        </span>
                                        </Tooltip>
                                </Col>
                                <Col span={4} className="item_time">
                                    <div className="group__inline">
                                        <label className="group__inline__label label__time">
                                            {t('complaintSeller:list.created_time')}:
                                        </label>
                                        <span className="group__inline__value">
                                            {lodash.get(complaintSeller, 'log_time_status_WAITING', '---')}
                                        </span>
                                    </div>
                                    {
                                        complaintSeller.status !== COMPLAINT_SELLER_STATUS.WAITING ?
                                            (<div className="group__inline">
                                                <label className="group__inline__label label__time">
                                                    {t('complaintSeller:list.process_time')}:
                                                </label>
                                                <span
                                                    className="group__inline__value">{lodash.get(complaintSeller, 'log_time_status_PROCESSING', null)
                                                || '---'}</span>
                                            </div>) : null
                                    }
                                    {
                                        complaintSeller.status === COMPLAINT_SELLER_STATUS.FAILURE ?
                                            (<div className="group__inline">
                                                <label className="group__inline__label label__time">
                                                    {t('complaintSeller:failed.btn_failed')}:
                                                </label>
                                                <span className="group__inline__value">
                                                {lodash.get(complaintSeller, 'log_time_status_FAILURE', null) || '---'}
                                            </span>
                                            </div>) : null
                                    }
                                    {complaintSeller.status === COMPLAINT_SELLER_STATUS.SUCCESS ? (
                                        <div className="group__inline">
                                            <label className="group__inline__label label__time">
                                                {t('complaintSeller:list.success_time')}:
                                            </label>
                                            <span
                                                className="group__inline__value">{lodash.get(complaintSeller, 'log_time_status_SUCCESS', null)
                                            || '---'}</span>
                                        </div>
                                    ) : null}
                                </Col>
                                <Col span={3} className="item_time">
                                    <div className="group__inline">
                                        <label className="group__inline__label label__date">
                                            {t('complaintSeller:list.expired_time')}:
                                        </label>
                                        <span
                                            className={`group__inline__value ${expirationColor}`}>{dateFormatter.date(lodash.get(complaintSeller, 'expire_time', null))
                                        || '---'}</span>
                                    </div>
                                    <div className="group__inline">
                                        <label className="group__inline__label label__date">
                                            {t('complaintSeller:detail.number_extension')}:
                                        </label>
                                        <span className="group__inline__value a-text--red">
                                        {complaintSeller.renewal_count}
                                    </span>
                                    </div>
                                </Col>
                                <Col span={3} className="item_user">
                                    <span className="a-text--font-blue">
                                        {complaintSeller.receiver_username ?
                                            complaintSeller.receiver_username : '---'}
                                        <br/>
                                        {complaintSeller.account_purchaser_code ?
                                            complaintSeller.account_purchaser_code : '---'}
                                    </span>
                                </Col>
                                <MenuExecute key={complaintSeller.id} complaintSeller={complaintSeller}/>
                            </Row>
                            <ModalTransition>
                                {isOpenUpdateForm && (
                                    <ModalUpdateForm
                                        title={t('account_purchaser.header_modal_form')}
                                        complaintSellerReasons={complaintSellerReasons}
                                        onSubmitForm={this.props.onSubmitForm}
                                        onClose={this.closeModalForm}
                                    />
                                )}
                            </ModalTransition>
                        </div>
                    )})}
                {
                    isLoading ?
                    <div>
                        <p style={{marginTop: '15px', textAlign: 'center'}}>
                            <Spin />
                        </p>
                    </div>
                    :
                    (
                        !complaintSeller.length &&
                            <div>
                                <p style={{marginTop: '15px', textAlign: 'center'}}>
                                    {t('orderDetail:order_detail.not_found_data')}
                                </p>
                            </div>
                    )
                }
            </React.Fragment>
        );
    }
}


ListComplaintSeller.propTypes = {
    onRef: PropTypes.func,
    onUpdate: PropTypes.func

};

export default translate('translations')(ListComplaintSeller);
