import {getConfig} from '../systems/support/helpers';

export default {
    /**
     * The pusher host
     */
    host: getConfig('PUSHER_HOST', 'localhost:28083'),

    /**
     * The subscribe app key
     */
    subAppKey: getConfig('PUSHER_SUB_APP_KEY'),

    /**
     * The subscribe secret
     */
    subSecret: getConfig('PUSHER_SUB_SECRET'),

    /**
     * The publish app key
     */
    pubAppKey: getConfig('PUSHER_PUB_APP_KEY'),

    /**
     * The publish secret
     */
    pubSecret: getConfig('PUSHER_PUB_SECRET'),

    /**
     * Using ssl
     */
    useSSL: getConfig('PUSHER_USE_SSL', false),
};