import React, {Component} from 'react';
import {translate} from "react-i18next";
import {withEvent} from "../../systems/events";
import {dispatch} from "../../systems/store";
import {setBreadCrumb, setMenuActive, setPageDescription, setPageTitle} from "../../modules/common/actions";
import {setActiveSettingItem} from "../../modules/Settings/SettingMenu/actions";
import {url} from "../../systems/routing";
import {processResponse} from "../../systems/api";
import apiService from "../../modules/PaymentRequest/apiService";
import {Button, Col, Divider, Icon, Row, Spin, Table, Tooltip, Switch, notification} from "antd";
import lodash from "lodash";
import AddMemberGroup from "./components/AddMemberGroup";
import FormUpdateGroup from './components/FormUpdateGroup';

class GroupManageDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupInfo: {},
            loading: false,
            visible: false
        }

    }

    componentDidMount() {
        this.getGroupInfo();
        this.getPageInfo();
    }

    getGroupInfo = () => {
        let {t, match: {params}} = this.props;
        this.setState({
            loading: true
        });
        processResponse(apiService.getDetailPaymentRequestGroup(params.groupId))
            .then(res => {
                this.setState({groupInfo: lodash.get(res, "data.paymentRequestGroup")});
            }).catch(() => {
            notification.error({message: t('message.cant_fetch_data')});
        }).finally(() => {
            this.setState({loading: false});
        });
    };


    getPageInfo() {
        let {t, match: {params}} = this.props;
        dispatch(setPageTitle(t('group:page.detail_title')));
        dispatch(setPageDescription(t('group:page.detail_description')));
        dispatch(setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'group:page.title',
                'link': '/group-manage'
            },
            {
                'label': t('group:page.detail_title'),
                'link': url.to('group_manage_detail', {groupId: params.groupId})
            },

        ]));
        dispatch(setMenuActive('group_manage'));
        setActiveSettingItem('group_manage');
    }

    removeMember = (memberId) => {
        let {t, match: {params}} = this.props;
        processResponse(apiService.deleteMemberPaymentRequestGroup(params.groupId, memberId))
            .then(res => {
                this.getGroupInfo();
                notification.success({message: t('group:messages.remove_member_success')});
            }).catch(() => {
            notification.error({message: t('group:messages.remove_member_fail')});
        });
    };

    getColumns = () => {
        const {t} = this.props;
        return ([
            {
                title: t("group:title.username"),
                dataIndex: 'username',
                key: 'username',
            },
            {
                title: t("group:title.name"),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: t("group:title.email"),
                dataIndex: 'email',
                key: 'email',
            },
            {
                key: 'action',
                render: (text, record) => <Tooltip title={t("group:title.remove_member")}>
                    <a className="ml-4" onClick={() => this.removeMember(record.id)}>
                        <Icon type="delete" style={{fontSize: 16, color: "red"}}/>
                    </a>
                </Tooltip>
            }
        ]);
    };

    openModal = () => {
        this.setState({
            visible: true
        })
    };

    closeModal = () => {
        this.setState({
            visible: false
        })
    };

    render() {
        const {loading, groupInfo, visible} = this.state;
        const {t} = this.props;
        const members = lodash.get(groupInfo, "members", []);
        return (
            <div className="a-content__right">
                <div className="a-content--box-shadow">
                    <div className="a-purchasod__first a-content--box-shadow">
                        <Row>
                            <Col span={12} className="d-flex align-item-center">
                                <p className="a-text--bold a-text--color-black">{t("group:title.group_detail")}</p>
                            </Col>
                            <Col span={12}>
                                <p className="a-text--right">
                                    <Button type="primary" icon="plus-square"
                                            onClick={this.openModal}>{t("group:btn.group_edit")}</Button>
                                </p>
                            </Col>
                        </Row>
                        <Divider className="mt-3 mb-0"/>
                        <Row className="p-3 pl-4">
                            <Col span={8}><span>{t("group:title.group.code")}:</span></Col>
                            <Col span={16}><strong>{groupInfo.code}</strong></Col>
                        </Row>
                        <Row className="p-3">
                            <Col span={8}><span>{t("group:title.group.name")}:</span></Col>
                            <Col span={16}><strong>{groupInfo.name}</strong></Col>
                        </Row>
                        <Row className="p-3">
                            <Col span={8}><span>{t("group:title.group.description")}:</span></Col>
                            <Col span={16}><strong>{groupInfo.description}</strong></Col>
                        </Row>
                        <Row className="p-3">
                            <Col span={8}><span>{t("group:title.group.status")}:</span></Col>
                            <Col span={16}><Switch checked={!!groupInfo.status}/></Col>
                        </Row>
                    </div>
                    <div className="mt-3 a-purchasod__first a-content--box-shadow">
                        <Row>
                            <p className="a-text--bold a-text--color-black">{t("group:title.list_member_group")}</p>
                        </Row>
                        <Divider className="mt-3 mb-0"/>
                        {
                            groupInfo.id &&
                            <AddMemberGroup groupId={groupInfo.id} getGroupInfo={this.getGroupInfo}/>
                        }

                        <Table
                            className="pt-4"
                            dataSource={members}
                            columns={this.getColumns()}
                            pagination={false}
                            rowKey="id"
                            loading={loading}
                        />

                    </div>
                    {
                        !lodash.isEmpty(groupInfo) &&
                        <FormUpdateGroup
                            groupInfo={groupInfo}
                            visible={visible}
                            closeModal={this.closeModal}
                            getListGroup={this.getGroupInfo}/>
                    }
                </div>
            </div>
        );
    }
}

export default translate()(withEvent(GroupManageDetail));