import {createAction} from 'redux-actions';
import * as Constants from './constants';

export const getMe = createAction(Constants.GET_ME.REQUEST);
export const updateIpPhone = createAction(Constants.IPPHONE.UPDATE);
export const getPurcharsers = createAction(Constants.GET_LIST_PURCHASER.REQUEST, (filters) => ({filters}));
export const fetchAccount = createAction(Constants.FETCH_ACCOUNT.REQUEST, (filters) => (filters));
export const fetchUsers = createAction(Constants.FETCH_USERS.REQUEST, (filter) => ({filter}));
export const fetchUsersComplaintSeller = createAction(Constants.FETCH_USERS_COMPLAINT_SELLER.REQUEST, (filter) => ({filter}));
export const logout = createAction(Constants.LOGOUT.REQUEST);
