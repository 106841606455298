import {call, put} from 'redux-saga/effects'
import service from './apiService'
import {FETCH_VIOLATE_REASONS} from './constants';

export function* fetchViolateReasons(action) {
    try {

        let response = yield call(service.fetchViolateReasons, action.payload);
        yield put({type: FETCH_VIOLATE_REASONS.SUCCESS, payload: response.data});

    } catch (err) {
        yield put({type: FETCH_VIOLATE_REASONS.FAILED, err});
    }
}
