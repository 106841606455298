import {connect} from 'react-redux';
import Header from './components';
import lodash from 'lodash';
import * as Selector from './../../../modules/PurchaseBuying/OrderDetail/selectors';
const mapStateToProps = (state) => {
    return {
        title: lodash.get(state, "Common.title", ""),
        subDesc: lodash.get(state, "Common.subDesc", ""),
        menu_active: lodash.get(state, "Common.menu_active", ""),
        dataOrder: Selector.purchasingOrderDetail(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header)