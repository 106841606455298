import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import Spinner from '@atlaskit/spinner';
import FormProcessing from './../../../components/FormProcessing';
import ReactFileReader from 'react-file-reader';

class FormConmentPublic extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                message: '',
                is_public: true,
            },
            inputAttachFile: '',
            inputVersion: 0,
            changedParams: [],
        };
    }

    /**
     * Xử lý submit form
     */
    onSubmit(event) {
        event.preventDefault();
        let {input, inputAttachFile} = this.state;
        input = {...input, ...{is_public: true}};
        if (this.canSubmit()) {
            if (input.message && input.message.trim()) {
                this.props.onSubmit(input);
            }
            // gửi anh nên server
            if (inputAttachFile.trim()) {
                input = {...input, ...{is_public: true, message: inputAttachFile}};
                this.props.onSubmit(input);
                this.setState({inputAttachFile: ''});
            }
        }
    }

    /**
     * Kiểm tra có cho phép submit form hay không
     */
    canSubmit() {
        return (
            !this.props.loading
            && !this.props.disabled
        );
    }

    /**
     * Lấy error hiện tại của form
     */
    getErrorMessage() {
        let {t, error} = this.props;

        if (error) {
            return t('log:error.post_comment_failed');
        }
    }

    /**
     * Kiểm tra message có hợp lệ hay không
     */
    isValidMessage(message = '') {
        return message.length <= this.props.maxMessageLength;
    }

    onPasteInput(event) {
        const _self = this;
        const clipboardData = event.clipboardData;
        const allowedAttachFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
        const allowedAttachFileSize = 5120000; // bytes => 5MB

        // Kiểm tra xem clipboard có phải là dạng Files ko đã, không phải thì ko xử lý gì
        if (lodash.includes(clipboardData.types, 'Files')) {
            let files = clipboardData.items;

            if (files.length > 0) {
                lodash.forEach(files, function (file, index) {
                    if (!lodash.includes(allowedAttachFileTypes, file.type)) {
                        // @TODO Thông báo định dạng file không cho phép
                        return true;
                    }

                    if (file.size > allowedAttachFileSize) {
                        // @TODO Thông báo kích thước file không vượt quá 5Mb
                        return;
                    }

                    let attachFile = file.getAsFile();
                    let reader = new FileReader();
                    // Bắt đầu đọc file
                    reader.readAsDataURL(attachFile);

                    // Chờ file load xong tạm trong DOM mới lấy đươc base64 data
                    reader.onload = function (evt) {
                        _self.setState({inputAttachFile: evt.target.result});
                    };

                });
            }
        }
    }

    onRemoveAttachFile(event) {
        this.setState({inputAttachFile: ''});
    }

    handleFiles = files => {
        const _self = this;
        _self.setState({inputAttachFile: files.base64});
    }

    render() {
        let {t, loading, disabled} = this.props;
        let {input, inputVersion} = this.state;
        let errorMessage = this.getErrorMessage();
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit.bind(this)} className="flex--center">
                    <div className={'a-chat__input'}>
                        <div
                            style={{'display': (this.state.inputAttachFile ? 'block' : 'none')}}
                            className={`a-chat__input__attach_file `}
                            onClick={() => {

                            }}
                        >
                            <img alt={"icon-camera"} src={this.state.inputAttachFile} width={`15`} height={`15`}/>
                            <i
                                className={`fas fa-times-circle a-chat__input__attach_file__icon_close`}
                                onClick={this.onRemoveAttachFile.bind(this)}>
                            </i>
                        </div>
                        <input
                            type="text"
                            className={"input--chat"} spellCheck={'false'}
                            placeholder={t('log:internal_placeholder')}
                            value={input.message || ''}
                            disabled={disabled}
                            autoComplete="off"
                            onPaste={this.onPasteInput.bind(this)}
                            onChange={this.onChangeInput.bind(this, 'message')}
                        />
                        {loading ? (<div className="icon-spinner a-chat__input__icon_loading">
                            <Spinner/>
                        </div>) : null}
                    </div>
                    <div className={'a-chat--group flex--center'}>
                        {/*<i className="fas fa-globe-asia ml-2 a-chat__icon--public"></i>*/}
                        <ReactFileReader handleFiles={this.handleFiles} key={inputVersion + '_'}
                                         fileTypes={["image/*"]} base64={true}
                        >
                            <i className="fas fa-camera ml-2" style={{cursor: 'pointer'}} title={t('log:upload')}></i>
                        </ReactFileReader>
                    </div>
                </form>
                {errorMessage && <div className="invalid-feedback d-block">{errorMessage}</div>}
            </React.Fragment>
        );
    }
}

FormConmentPublic.defaultProps = {
    input: {},
    inputVersion: 0,
    error: null,
    loading: false,
    disabled: false,
    maxMessageLength: 1000,
    onSubmit: input => {
    },
};

FormConmentPublic.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    error: PropTypes.any,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    maxMessageLength: PropTypes.number,
    onSubmit: PropTypes.func,
};

export default translate()(FormConmentPublic);
