import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {scroller} from 'react-scroll'
import AutoLinkText from 'react-autolink-text2';
import {trans} from '../../../../../systems/i18n';
import {Link} from '../../../../../systems/routing';

const ORDER_ACTION = {
    COMMENT_ADD_NOTE_ITEM: 'COMMENT.ADD_NOTE_ITEM',
    COMMENT: 'COMMENT',
};

class ComplaintCommentMessage extends React.Component {
    scrollTo(param) {
        scroller.scrollTo(param, {
            duration: 500,
            delay: 0,
            offset: -140,
            isDynamic: true,
            smooth: 'easeInOutQuart'
        })
    }

    render() {
        let {log} = this.props;
        let {payload} = log;
        switch (log.action) {

            case ORDER_ACTION.COMMENT:
                let {message} = log;
                let images = log.images || [];

                return (
                    <div>
                        <AutoLinkText
                            text={message}
                            linkProps={{target: '_blank', rel: 'nofollow'}}
                        />
                        <div className="d-flex flex-wrap">
                            {images.map(image => (
                                <div className="m-2">
                                    <a target="_blank" href={image.origin_url}>
                                        <img src={image.thumb_url} alt="camera-icon" style={{height: '50px'}}/>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                );

            case ORDER_ACTION.COMMENT_ADD_NOTE_ITEM:
                return this._renderMessageCommentAddNoteItem(payload);

            default:
                return log.action;
        }
    }

    /**
     *
     * @param param
     * @returns {XML}
     * @private
     */
    _renderMessageCommentAddNoteItem(param = {}) {
        let {log} = this.props;
        let payload = param;
        return trans('order:log.ADD_NOTE_ITEM', {
            order: this.linkToOrder(lodash.get(payload, 'order')),
            item: <span className={"text-primary font-weight-bold"}>
                      <a className="a--cursor-poiter"
                         onClick={() => this.scrollTo(lodash.get(payload, 'order_item.partner_order_item_id'), null)}>
                          {'#' + lodash.get(payload, 'order_item.partner_order_item_id')}
                      </a>
                 </span>,
            note: lodash.get(log, 'message', null),
        });
    }

    linkToOrder({id, code} = {}) {
        return <Link to="purchase.buying.detail" params={{id}}>{code}</Link>;
    }
}

ComplaintCommentMessage.defaultProps = {
    log: {},
};

ComplaintCommentMessage.propTypes = {
    log: PropTypes.object,
};

export default translate()(ComplaintCommentMessage);
