import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';
import Spinner from '@atlaskit/spinner';
import FormProcessing from '../../../common/components/FormProcessing';

class UpdateShippingBill extends FormProcessing {
    constructor(props) {
        super(props);

        this.state = {
            input: {
                codes: [],
            },
            inputVersion: 0,
            changedParams: [],
            code: '',
        };

        this.element = null;
    }

    onChangeCodes(codes) {
        this.changeInput({codes});
    }

    onChangeCodeInput(code) {
        this.setState({code: code});
    }

    pasteSplit(codes) {
        return codes.split(',').map(code => code);
    }

    onSubmit() {
        if (this.props.loading) {
            return;
        }

        this.element.accept();
        setTimeout(() => this.props.onSubmit(this.state.input), 0);
    }

    render() {
        let {t, loading, canUpdateIntoStatus } = this.props;

        return (
            <div className="a_complaint_solution__body__block block__border_bottom">
                <p className="a_complaint_solution__title__block_right">
                    {t('complaintSeller:product_return.freight_bill_product_return')}
                    <span className="ml-1">Mỗi vận đơn cách nhau dấu ","</span>
                </p>
                <div className="d-flex">
                    <TagsInput
                        value={this.state.input.codes}
                        inputValue={this.state.code}
                        addOnPaste={true}
                        onlyUnique={true}
                        disabled={!canUpdateIntoStatus || loading ? true: false}
                        addKeys={[9, 13, 188]} // [tab, enter, ',']
                        className={"react-tagsinput-tag1 input--tagsinput w-100 "+(!canUpdateIntoStatus || loading ? 'disable-tagsinput': false)}
                        classNameRemove="react-tagsinput-remove"
                        inputProps={{placeholder: t('complaintSeller:product_return.placeholder_freight_bill_product_return')}}
                        pasteSplit={this.pasteSplit.bind(this)}
                        onChange={this.onChangeCodes.bind(this)}
                        onChangeInput={this.onChangeCodeInput.bind(this)}
                        ref={element => (this.element = element)}
                    />
                    <button
                        className={"action_save__code "+(!canUpdateIntoStatus || loading ? 'disable': false)}
                        onClick={this.onSubmit.bind(this)}
                    >{loading ? <Spinner size="medium"/> : t('btn.save')}</button>
                </div>
            </div>
        );
    }
}

UpdateShippingBill.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    canUpdateIntoStatus: false,
    onSubmit: input => {},
};

UpdateShippingBill.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.any,
    loading: PropTypes.bool,
    canUpdateIntoStatus: PropTypes.bool,
    onSubmit: PropTypes.func,
};

export default translate()(UpdateShippingBill);
