import React from 'react'
import {translate} from 'react-i18next';
import Filter from './Filter'
import Item from "./Item"
import lodash from 'lodash'
import Spinner from "@atlaskit/spinner";
import queryString from 'query-string'
import qs from 'qs'
import PusherComponent from "../../common/components/Pusher";
import * as GlobalConstant from "../../../systems/globalContant/index";
import {dispatch} from "../../../systems/store";
import {ORDER_SYNC_BILL_NO} from "../constants";
import Pagination from './../../common/components/Pagination';
import {setActiveSettingItem} from '../../Settings/SettingMenu/actions';

class ListOrderBillNo extends PusherComponent {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            formData: {
                check_all: false,
                order_item_checked: [],
                code: null
            },
            syncing: false,
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.props.fetchAccountPurchase({});
        this.props.setPageTitle(this.props.t('layout.title.order_bill_no'));
        this.props.setPageDescription(this.props.t('layout.description.order_bill_no'));
        this.props.setMenuActive("order_bill_no");
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.order_bill_no',
                'link': '/order-bill-no-syncs/'
            },
        ]);
        setActiveSettingItem('order_bill_no');
    }

    /**
     * handle hành động nhận message
     * @param text
     * @param id
     * @param channel
     * @private
     */
    _pusherOnMessage(text, id, channel) {

        if (channel.startsWith(GlobalConstant.CHANNELS.ORDER_SYNC_BILL_NO)) {
            console.log(text);
            let response = qs.parse(text, {plaintObject: true});
            dispatch({type: ORDER_SYNC_BILL_NO.SUCCESS, payload: response})
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        const {orders} = nextProps;

        if (JSON.stringify(orders) !== JSON.stringify(prevState.orders)) {
            state.syncing = false;
            state.orders = orders;
        }
        return state;
    }

    /**
     * Submit form
     * @param filters
     */
    onSearch(filters) {
        const {history} = this.props;
        history.push('/order-bill-no-syncs-old?' + queryString.stringify(filters));
        this.props.fetchOrders(filters);
        this.updateFormData({order_item_checked: [], check_all: false});
    }

    /**
     *
     * @param event
     */
    onClearFilter(event) {
        const {history} = this.props;
        history.push('/order-bill-no-syncs-old?page=1');
        this.updateFormData({order_item_checked: [], check_all: false});
        this.props.fetchOrders({});
    }

    /**
     * Hàm thực hiện đánh dấu chọn hoặc bỏ chọn tích đơn
     *
     * @param event
     */
    onCheckItem(event) {
        let target = event.target;
        let {orders} = this.props;
        let value = lodash.toInteger(target.value);
        let checked = target.checked;
        let check_all = false;
        let order_item_checked = lodash.get(this.state, 'formData.order_item_checked', []);
        // Nếu bỏ tích chọn
        if (!checked) {
            order_item_checked = lodash.difference(order_item_checked, [value]);
        }
        // Nếu checkbox được tích chọn
        if (checked) {
            order_item_checked = lodash.concat(order_item_checked, [value]);
            order_item_checked = lodash.uniq(order_item_checked);
        }
        if (order_item_checked.length === (orders.length)) {
            check_all = true;
        }

        this.updateFormData({order_item_checked: order_item_checked, check_all: check_all});
    }

    /**
     * Hàm thực hiện đánh dấu chọn hoặc bỏ chọn tất cả
     *
     * @param event
     */
    onCheckAll(event) {
        let {orders} = this.props;
        let OrderIds = [];
        let target = event.target;
        let checked = target.checked;
        let order_item_checked = [];
        let check_all = false;
        // Nếu checkbox được tích chọn
        if (checked) {
            OrderIds = orders.map(order => {
                return order.id;
            });
            OrderIds = lodash.compact(OrderIds);
            check_all = true;
            order_item_checked = lodash.concat(order_item_checked, OrderIds);
        }

        this.updateFormData({order_item_checked: order_item_checked, check_all: check_all});
    }

    /**
     * Clear check all
     */
    onClearCheckAll() {
        this.updateFormData({order_item_checked: [], check_all: false});
    }

    /**
     *
     * @param page
     */
    onChangePage(page) {
        let {location, history} = this.props;
        window.scrollTo(0, 0);
        this.setState({page: page});

        let filters = {...queryString.parse(location.search), page: page};
        history.push('/order-bill-no-syncs?' + queryString.stringify(filters));
        this.props.fetchOrders(filters);
        this.onClearCheckAll();
    }

    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        let formData = this.state.formData;
        formData = {...formData, ...input};
        this.setState({formData});
    }

    onOrderSyncs(event) {
        const {orderBillNoSyncs, orders} = this.props;
        const {formData} = this.state;

        this.setState({syncing: true});

        if (formData.order_item_checked.length > 0) {
            orderBillNoSyncs({
                id_orders: this.state.formData.order_item_checked
            })
        } else {
            // Khi không check all, bấm đồng bộ sẽ auto đồng bộ tất cả
            let orderIds = lodash.map(orders, 'id');
            this.updateFormData({order_item_checked: orderIds, check_all: true});
            orderBillNoSyncs({
                id_orders: orderIds
            })
        }
    }

    render() {
        const {orders, pagination, t, history} = this.props;
        const {syncing, formData} = this.state;
        return (
            <div className="a-content__right ">
                <div className="a-content--box-shadow">
                    <Filter
                        fetchOrders={this.props.fetchOrders}
                        purchaserOptions={this.props.purchasers}
                        history={history}
                        onSearch={this.onSearch.bind(this)}
                        onClearFilter={this.onClearFilter.bind(this)}
                    />
                    <div className="a_orbill">
                        <div className="notify__orbill">
                            <p>
                                Lưu ý: việc lấy mã vận đơn tự động ở trang này chỉ phục vụ cho
                                các đơn từ tài khoản
                                <span> Super Buyer 1688</span>
                            </p>
                        </div>
                        <a href="javascript:;"
                           className={`a-btn--syncs ${(syncing || orders.length === 0) ? 'isDisabled' : ''}`}
                           onClick={event => this.onOrderSyncs(event)}>
                            {syncing ?
                                <Spinner size="small"/>
                                :
                                <i className="fas fa-retweet"></i>
                            }
                            {t('order:list.bill_no.sync')}
                        </a>
                    </div>

                    <div className="a-group--item mt-5 d-flex justify-content-center">
                        {(pagination && pagination.page_total > 1) &&
                        <Pagination
                            value={parseInt(pagination.current_page, 10)}
                            total={pagination.page_total}
                            onChange={page => this.onChangePage(page)}
                        />
                        }
                    </div>

                    <div className="table-responsive mt-5">
                        <table className="table a-table--order_bill_no_sync">
                            <thead>
                            <tr>
                                <th>
                                    <input type="checkbox" checked={formData.check_all}
                                           onChange={this.onCheckAll.bind(this)}/>
                                </th>
                                <th>
                                    {t('order:list.bill_no.order')}
                                </th>
                                <th></th>
                                <th>
                                    {t('order:list.bill_no.bill_no')}
                                </th>
                                <th>
                                    {t('order:list.bill_no.actions')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders.map(( order, index )=>
                                <Item
                                    order={order}
                                    key={index+'_'+order.id}
                                    onCheckItem={this.onCheckItem.bind(this)}
                                    syncing={this.state.syncing && formData.order_item_checked.includes(order.id)}
                                    syncOrderBillNo={this.props.syncOrderBillNo}
                                    isChecked={formData.order_item_checked.includes(order.id)}
                                />
                            )}
                            </tbody>
                        </table>
                    </div>
                    <div
                        className="a-group--item a_navigation mt-5 d-flex justify-content-center w-100">
                        {(pagination && pagination.page_total > 1) &&
                        <Pagination
                            value={parseInt(pagination.current_page, 10)}
                            total={pagination.page_total}
                            onChange={page => this.onChangePage(page)}
                        />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default translate()(ListOrderBillNo);
