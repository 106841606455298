import React from "react";
import SearchableComponent from "../../../modules/common/SearchableComponent";
import {dispatch} from "../../../systems/store";
import lodash from "lodash";
import {setBreadCrumb, setMenuActive, setPageDescription, setPageTitle} from "../../../modules/common/actions";
import {Layout, notification} from "antd";
import ListComplaintSeller from "./components/ListComplaintSeller";
import {translate} from "react-i18next";
import {processResponse} from "../../../systems/api";
import apiService from "../../../modules/ComplaintSeller/apiService";
import FormSearch from "./components/FormSearch";
import * as Selectors from "./selectors";
import {updateCollectionItem} from "../../../modules/common/helps";

const Content = Layout.Content;

class ScanComplaintSeller extends SearchableComponent {

    constructor(props) {
        super(props);

        this.defaultFilter = {
            enough_origin_info: "",
            seller_source: "",
            sort: "asc",
            page: 1,
            per_page: 50
        };

        this.defaultState = {
            complaints: [],
            pagination: {},
            loading: false,
            isSynchronizing: false
        };

        this.state = {
            ...this.defaultState,
            errors: {},
            isCrawling: false
        };
    }

    // Thiết lập trạng thái đang đồng bộ KNNB
    setIsCrawling(status, callback = () => {}) {
        this.setState({isCrawling: status}, callback);
    }

    componentDidMount() {
        super.componentDidMount();
        this.setPageInfo();
    }

    updateComplaints(complaint, callback = () => {}) {
        if (!lodash.isEmpty(complaint)) {
            this.setState(state => {
                return {complaints: updateCollectionItem(state.complaints, complaint.id, Selectors.formartComplaint(complaint))};
            }, callback);
        }
    }

    setPageInfo() {
        let {t} = this.props;
        dispatch(setPageTitle(t('layout.title.scan_complaint_seller')));
        dispatch(setPageDescription(t('layout.description.scan_complaint_seller')));
        dispatch(setMenuActive('scan_complaint_seller'));
        dispatch(setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            }, {
                'label': 'breadcrumb.scan_complaint_seller',
                'link': '/scan-complaint-seller/'
            },
        ]));
    }

    onChangeFilter(filter) {
        if (this.state.loading)
            return;

        this.fetchComplaintSellers(filter);
    }

    fetchComplaintSellers(filter) {
        let {t} = this.props;
        this.setState({loading: true});
        processResponse(apiService.getListComplaintSellerSync({...filter}))
            .then(res => {
                this.setState({
                    loading: false,
                    complaints: Selectors.getComplaints(res),
                    pagination: lodash.get(res, 'data.pagination', {})
                });
            })
            .catch(error => {
                notification.error({message: t('message.cant_fetch_data')});
            });
    }

    getCurrentFilter() {
        return {
            ...this.defaultFilter,
            ...this.getFilterFromLocation(this.props.location),
        };

    }

    onSearch(filter) {
        if (this.state.loading)
            return;

        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    }

    render() {
        let {
            complaints,
            loading,
            pagination,
            isCrawling
        } = this.state;

        let filter = this.getFilter();

        return (
            <Layout>
                <Content className="a-content--ant-layout sale-report">
                    <FormSearch
                        input={filter}
                        inputVersion={filter.i || 0}
                        loading={this.state.loading}
                        onSearch={this.onSearch.bind(this)}
                    />

                    <ListComplaintSeller
                        complaints={complaints}
                        loading={loading}
                        pagination={pagination}
                        onSearch={this.onSearch.bind(this)}
                        filter={filter}
                        updateComplaints={this.updateComplaints.bind(this)}
                        isCrawling={isCrawling}
                        setIsCrawling={this.setIsCrawling.bind(this)}
                    />
                </Content>
            </Layout>
        );
    }
}

export default translate()(ScanComplaintSeller);
