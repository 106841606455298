import {apiWithToken} from '../../systems/api';
import {API_URL} from '../../systems/globalContant';
import {COMPLAINT_STATUS} from './constants';

export default {
    fetchComplaintDetail: (filter) => {
        return apiWithToken.get(API_URL.FETCH_COMPLAINT_DETAIL + filter.id, {params: filter}, {loading: false})
    },
    getListComplaintSellerReasons: (filter) => apiWithToken.get(API_URL.GET_LIST_COMPLAINT_SELLER_REASONS, {params: filter}, {loading: false}),
    getListComplaintSeller: (filters) => apiWithToken.get(API_URL.GET_LIST_COMPLAINT_SELLER, {params: filters}, {loading: false}),
    exportComplaintSeller: (filters) => apiWithToken.get(API_URL.GET_LIST_COMPLAINT_SELLER + 'export', {params: filters}, {loading: false}),
    getListComplaintSellerSync: (filters) => apiWithToken.get(API_URL.GET_LIST_COMPLAINT_SELLER + 'need-sync', {params: filters}, {loading: false}),
    getComplaintSellerRenewReasons: (filter) => apiWithToken.get(API_URL.DEFAULT + '/complaint-seller-renew-reasons', {params: filter}, {loading: false}),
    createComplaintSeller: (datas) => apiWithToken.post(API_URL.GET_LIST_COMPLAINT_SELLER, {...datas}, {loading: false}),
    expireUpdateComplaintDetail: (params) => apiWithToken.put(API_URL.GET_LIST_COMPLAINT_SELLER + params.id + '/renew', {...params}, {loading: false}),
    noteUpdateComplaintDetail: (params) => apiWithToken.put(API_URL.GET_LIST_COMPLAINT_SELLER + params.id + '/update-note', {...params.params}, {loading: false}),
    markImportUpdateComplaintDetail: (params) => apiWithToken.put(API_URL.GET_LIST_COMPLAINT_SELLER + params.id + '/mark', {...params.params}, {loading: false}),
    updateComplaintSellerCompleted: (params) =>
        apiWithToken.put(API_URL.GET_LIST_COMPLAINT_SELLER, {...params}, {loading: false}),
    receiveComplaint: (id) => apiWithToken.put(API_URL.DEFAULT + `/complaint-sellers/${id}/receive`, {}, {loading: false}),
    assignComplaint: (params) => apiWithToken.put(API_URL.DEFAULT + `/complaint-sellers/${params.id}/assign`, {...params}, {loading: false}),
    failedComplaint: (id, input) => apiWithToken.put(
        API_URL.DEFAULT + `/complaint-sellers/${id}/change-state`,
        {...input, next_state: COMPLAINT_STATUS.FAILURE},
        {loading: false}
    ),
    attachAlipay: (complaintId, alipayId) => apiWithToken.post(`${API_URL.DEFAULT}/complaint-sellers/${complaintId}/alipay-transactions`, {id_alipay: alipayId}, {loading: false}),
    detachAlipay: (complaintId, alipayId) => apiWithToken.delete(`${API_URL.DEFAULT}/complaint-sellers/${complaintId}/alipay-transactions/${alipayId}`, {}, {loading: false}),
    createProductReturn: (params) => apiWithToken.post(`${API_URL.DEFAULT}/complaint-sellers/${params.id_complaint_seller}/product-returns`, {...params}, {loading: false}),
    rejectComplaintSeller: (params) => apiWithToken.put(`${API_URL.DEFAULT}/complaint-sellers/${params.id}/cancel`, {...params}, {loading: false}),
    updateComplaintSellerReason: (params) => apiWithToken.put(`${API_URL.DEFAULT}/complaint-sellers/${params.complaint_id}/update-reason/${params.reason_id}`, {}, {loading: false}),
    addComplaintTransaction: (complaint_id, params) => apiWithToken.post(API_URL.FETCH_COMPLAINT_DETAIL + `${complaint_id}/transaction`, params, {loading: false}),
    deleteComplaintTransaction: (transaction_id) => apiWithToken.delete(API_URL.FETCH_COMPLAINT_DETAIL + `${transaction_id}/transaction`, {}, {loading: false}),
    updateOriginCode: (complaint_id, params) => apiWithToken.put(API_URL.FETCH_COMPLAINT_DETAIL + `${complaint_id}/origin-code`, params, {loading: false}),
    getComplaintServices: (order_id) => apiWithToken.get(API_URL.FETCH_COMPLAINT_DETAIL + `${order_id}/complaint-services`, {}, {loading: false}),
    getPackages: (complaint_id, params) => apiWithToken.get(API_URL.FETCH_COMPLAINT_DETAIL + `${complaint_id}/packages`, {params}, {loading: false}),
    returnPackage: (complaint_id, data) => apiWithToken.post(API_URL.FETCH_COMPLAINT_DETAIL + `${complaint_id}/return-packages`, data, {loading: false}),
    choosePackage: (complaint_id, data) => apiWithToken.post(API_URL.FETCH_COMPLAINT_DETAIL + `${complaint_id}/packages`, data, {loading: false}),
    changePackageStatus: (complaint_id, data) => apiWithToken.put(API_URL.FETCH_COMPLAINT_DETAIL + `${complaint_id}/change-package-status`, data),
    getPackageLog: (complaint_id, params) => apiWithToken.get(API_URL.FETCH_COMPLAINT_DETAIL + `${complaint_id}/packages-damaged-logs`, {params}, {
        singleRequest: true,
        loading: false
    }),
    getProductsByFreightBill: (orderId, freightBill) => apiWithToken.get(API_URL.ORDER_FREIGHT_BILL.replace("{id}", orderId) + `/${freightBill}/items`,
        {
            loading: false,
            sigleRequest: true
        }),
    changePackageInfo: (complaint_id, data) => apiWithToken.put(API_URL.FETCH_COMPLAINT_DETAIL + `${complaint_id}/change-package-info`, data)
};
