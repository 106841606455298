import React from "react";
import {translate} from "react-i18next";
import {Row, Col} from "antd";
import ReasonBox from "./ReasonBox"
import apiService from "../../../modules/ViolateReason/apiService";
import {processResponse} from "../../../systems/api";
import lodash from "lodash";
import notification from "../../../systems/notification";
import {updateCollectionItem} from "../../../modules/common/helps";
import {setActiveSettingItem} from "../../../modules/Settings/SettingMenu/actions";


class SlowPurchasingReason extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            titleChangeType: true
        }
    }

    componentDidMount() {
        this.setPage();
        this.fetchReason();
    }

    addReason = reason => {
        this.setState(state => {
            let oldState = state;
            let data = oldState.data;
            data.push(reason);
            return {...oldState, data: data};
        });
    }

    updateReasons = (reason, titleChangeType = false) => {
        let {data} = this.state;
        let tmp_data = updateCollectionItem(data, reason.id, reason);
        this.setState({
            data: tmp_data,
            titleChangeType: titleChangeType
        }, () => {
            console.log("tmp_data", tmp_data);
        });
    }

    setTitleChangeType = () => {
        this.setState({titleChangeType: false});
    }

    fetchReason() {
        let {t} = this.props;
        let res = apiService.fetchViolateReasons();
        let process = processResponse(res);
        process.then(res => {
            this.setState({data: lodash.get(res, "data", [])})
        });

        process.catch(err => {
            notification.error(t('message.cant_fetch_data'));
        });
    }

    /**
     * Thiết lập thông tin Title, Description, Menu, BreadCrumb của trang
     */
    setPage = () => {
        let {t} = this.props;
        this.props.setPageTitle(t('layout.title.slow_purchasing_reason'));
        this.props.setPageDescription(t('layout.description.slow_purchasing_reason'));
        this.props.setMenuActive("slow-purchasing-reason");
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            }, {
                'label': 'breadcrumb.slow_purchasing_reason',
                'link': '/slow-purchasing-reason/'
            },
        ]);
        setActiveSettingItem('slow-purchasing-reason');
    }

    render() {
        let {data} = this.state;

        let OBJECTIVITY_data = lodash.sortBy(data.filter(item => item.type === "OBJECTIVITY"), ["priority"]);
        let SUBJECTIVITY_data = lodash.sortBy(data.filter(item => item.type === "SUBJECTIVITY"), ["priority"]);

        return lodash.isEmpty(data) ? null : <>
            <div className="a-content__right w-100 slow-purchasing-reason">
                <Row gutter={15}>
                    <Col span={12}>
                        <ReasonBox
                            key={"OBJECTIVITY"}
                            type="OBJECTIVITY"
                            reasons={OBJECTIVITY_data}
                            addReason={this.addReason}
                            updateReasons={this.updateReasons}
                            titleChangeType={this.state.titleChangeType}
                            setTitleChangeType={this.setTitleChangeType}
                        />
                    </Col>
                    <Col span={12}>
                        <ReasonBox
                            key={"SUBJECTIVITY"}
                            type="SUBJECTIVITY"
                            reasons={SUBJECTIVITY_data}
                            addReason={this.addReason}
                            updateReasons={this.updateReasons}
                            titleChangeType={this.state.titleChangeType}
                            setTitleChangeType={this.setTitleChangeType}
                        />
                    </Col>
                </Row>
            </div>
        </>;
    }
}

export default translate()(SlowPurchasingReason);