import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

class Alert extends React.Component {
    constructor(props) {
        super(props);
        this.btnClose = null;
    }

    componentDidUpdate() {
        if (this.props.isOpen && this.btnClose) {
            this.btnClose.focus();
        }
    }

    render() {
        const {t, title, message} = this.props;

        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={() => this.props.onClose()}
            >
                <ModalHeader>{title ? title : t('modal.alert.title')}</ModalHeader>
                <ModalBody>{message ? message : t('modal.alert.message')}</ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-primary"
                        onClick={() => this.props.onClose()}
                        ref={el => this.btnClose = el}
                    >{t('modal.close')}</button>
                </ModalFooter>
            </Modal>
        )
    }
}

Alert.defaultProps = {
    isOpen: false,
    onClose: () => {},
};

Alert.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
};

export default translate()(Alert);