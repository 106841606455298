import React from 'react';
import {Upload, Affix, message} from 'antd';

const Dragger = Upload.Dragger;
const props = {
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
        const status = info.file.status;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};

class UploadCsv extends React.Component {
    render() {
        return (
            <Affix>
                <div className="a-upload-csv">
                    <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                            <img src={require('../../../resources/img/ImageUpload.png')} alt=""/>
                        </p>
                        <p className="ant-upload-text">Click để chọn tệp trên máy tính <br/> hoặc kéo thả tệp bạn muốn
                            upload vào đây</p>
                    </Dragger>
                </div>
            </Affix>
        )
    }
}

export default UploadCsv;