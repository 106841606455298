import translations from './en.json';
import validation from './validation.json';
import log from './logs.json';
import order from './order.json';
import violate from './violate.json';
import orderDetail from './orderDetail.json';
import purchaser from './purchaser.json';
import complaintSeller from './complaintSeller.json';
import setting from './setting.json';
import user from './user.json';
import sale from './sale.json';
import transaction from  './transaction.json';
import productReturn from './productReturn.json';
import service from './service.json';
import newLocals from './new.json';
import changeInvoiceRequest from './changeInvoiceRequest.json';
import group from './group';

export default {
    transaction,
    productReturn,
    complaintSeller,
    sale,
    translations,
    setting,
    validation,
    order,
    orderDetail,
    log,
    purchaser,
    violate,
    user,
    service,
    newLocals,
    changeInvoiceRequest,
    group
}
