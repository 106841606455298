import {apiWithToken} from '../../systems/api';
import {API_URL} from './../../systems/globalContant';

export default {
    /**
     * Get list purchase address
     * @returns {*}
     */
    getPurchaseAddressList: (filter) => apiWithToken.get(API_URL.PURCHASE_ADDRESS_API, {params: filter}),

    /**
     * Get detail purchase address
     * @returns {*}
     */
    getPurchaseAddress: (id, filter) => apiWithToken.get(API_URL.PURCHASE_ADDRESS_API + '/' + id, {params: filter}),

    /**
     * Create purchase address
     * @returns {AxiosPromise<any> | * | void}
     */
    createPurchaseAddress: (params) => apiWithToken.post(API_URL.PURCHASE_ADDRESS_API, params),

    /**
     * Update purchase address
     * @returns {AxiosPromise<any> | void | PutEffectDescriptor<any> | ChannelPutEffectDescriptor<any> | IDBRequest | Promise<void>}
     */
    updatePurchaseAddress: (id, params) => apiWithToken.put(API_URL.PURCHASE_ADDRESS_API + '/' + id, params),
};