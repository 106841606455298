import {combineReducers} from 'redux';
import * as Constant from './constants';
import lodash from 'lodash';

const list = (state = [], action) => {
    switch (action.type) {
        case Constant.GET_LIST_ACCOUNT_PURCHASER.SUCCESS:
            return [...action.payload.data];
        case Constant.CHANGE_STATUS_ACCOUNT_PURCHASER.SUCCESS:
        case Constant.UPDATE_ACCOUNT_PURCHASER.SUCCESS:
            let accountPurchaserChanged = lodash.get(action, 'payload.data', {});
            state = state.map(stateItem => {
                if (stateItem.id === accountPurchaserChanged.id) {
                    return accountPurchaserChanged;
                }
                return stateItem
            });
            return state;
        case Constant.CREATE_ACCOUNT_PURCHASER.SUCCESS:
            let accountPurchaserNew = lodash.get(action, 'payload.data', {});
            return [accountPurchaserNew, ...state];
        case Constant.GET_LIST_ACCOUNT_PURCHASER.FAILED:
            return [];
        default:
            return state;
    }
};

const error = (state = {}, action) => {
    switch (action.type) {
        case Constant.CREATE_ACCOUNT_PURCHASER.FAILED:
        case Constant.UPDATE_ACCOUNT_PURCHASER.FAILED:
            return {...action.payload.data};
        case Constant.GET_LIST_ACCOUNT_PURCHASER.SUCCESS:
        case Constant.CREATE_ACCOUNT_PURCHASER.SUCCESS:
        case Constant.CREATE_ACCOUNT_PURCHASER.REQUEST:
        case Constant.UPDATE_ACCOUNT_PURCHASER.REQUEST:
        case Constant.UPDATE_ACCOUNT_PURCHASER.SUCCESS:
        case Constant.CLEAR_ERROR_ACCOUNT_PURCHASER:
            return {};
        default:
            return state;
    }
};

/**
 * state quản lý việc mở popup edit/ create account purchaser
 * @param state
 * @param action
 * @returns {boolean}
 */
const isModalProcessing = (state = false, action) => {
    switch (action.type) {
        case Constant.CREATE_ACCOUNT_PURCHASER.REQUEST:
        case Constant.UPDATE_ACCOUNT_PURCHASER.REQUEST:
        case Constant.CREATE_ACCOUNT_PURCHASER.FAILED:
        case Constant.UPDATE_ACCOUNT_PURCHASER.FAILED:
        case Constant.UPDATE_ACCOUNT_PURCHASER.ERROR:
        case Constant.CREATE_ACCOUNT_PURCHASER.ERROR:
        case Constant.OPEN_MODAL_ACCOUNT_PURCHASER:
            return true;
        case Constant.UPDATE_ACCOUNT_PURCHASER.SUCCESS:
        case Constant.GET_LIST_ACCOUNT_PURCHASER.SUCCESS:
        case Constant.CREATE_ACCOUNT_PURCHASER.SUCCESS:
        case Constant.CLOSE_MODAL_ACCOUNT_PURCHASER:
            return false;
        default:
            return state;
    }
}

/**
 * State quản lý việc có hệ thống có đang đợi post request lên server hay không
 * @param state
 * @param action
 * @returns {boolean}
 */
const isLoading = (state = false, action) => {
    switch (action.type) {
        case Constant.CREATE_ACCOUNT_PURCHASER.REQUEST:
        case Constant.UPDATE_ACCOUNT_PURCHASER.REQUEST:
        case Constant.GET_LIST_ACCOUNT_PURCHASER.REQUEST:
            return true;
        case Constant.UPDATE_ACCOUNT_PURCHASER.SUCCESS:
        case Constant.GET_LIST_ACCOUNT_PURCHASER.SUCCESS:
        case Constant.CREATE_ACCOUNT_PURCHASER.SUCCESS:
        case Constant.CREATE_ACCOUNT_PURCHASER.FAILED:
        case Constant.UPDATE_ACCOUNT_PURCHASER.FAILED:
        case Constant.UPDATE_ACCOUNT_PURCHASER.ERROR:
        case Constant.CREATE_ACCOUNT_PURCHASER.ERROR:
            return false;
        default:
            return state;
    }
}

export default combineReducers({
    list,
    error,
    isModalProcessing,
    isLoading
})