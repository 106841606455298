import React from 'react';
import lodash from 'lodash';
import {translate} from 'react-i18next';
import FormFilter from './FormFilter';
import Log from './Log';

class ComplaintChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Xử lý hành động thay đổi các filter
     *
     * @param {Object} filter
     */
    onChangeFilter(filter) {
        this.props.onChangeFilter(filter);
        // Nếu thay đổi type mà load logs đang bị lỗi thì load lại
        if (filter.type && this.props.logsError) {
            this.props.fetchLogs();
        }
    }

    /**
     * xử lý việc load lại logs khi có lỗi xảy ra
     *
     * @param {Object} event
     */
    onReloadLogs(event) {
        event.preventDefault();
        this.props.fetchLogs();
    }

    /**
     * Xử lý khi thực hiện đóng mở collapsed
     */
    onToogleCollapsedPublic() {
        let {collapsedPublic} = this.state;
        this.setState({collapsedPublic: !collapsedPublic});
        return collapsedPublic;
    }

    render() {
        return <div>
            <div className="a-chat">
                <FormFilter
                    filter={this.props.filter}
                    onChange={this.onChangeFilter.bind(this)}
                    commentInput={ this.props.commentInput.is_public === true? this.props.commentInput: {}}
                    inputVersion={this.props.commentVersion}
                    error={this.props.commentError}
                    loading={!!lodash.get(this.props.commentLoading, 'public')}
                    disabled={lodash.get(this.props, 'filter.type', 'LOG') === 'LOG'}
                    onComment={this.props.onComment}
                    collapsed={false}
                    onToogleCollapsedPublic={this.onToogleCollapsedPublic.bind(this)}
                />
                <div className={"a-chat__body complaint-seller"}>
                    {this.renderResult(this.props.logs)}
                </div>
            </div>
        </div>
    }

    renderResult(logs) {
        let {t} = this.props;
        if (this.props.logsError) {
            return (
                <div className="alert alert-warning">
                    <i className="fa fa-exclamation-triangle mr-1"/>
                    {t('log:error.cant_load')}
                    <a href=""
                       onClick={this.onReloadLogs.bind(this)}
                       className="activity-log-reload ml-1">{t('log:btn.reload')}</a>
                </div>
            );
        }

        return (
            <React.Fragment>
                {logs.map(log => <Log
                    key={log.id}
                    log={log}
                    onCollapse={() => this.props.onCollapseLog(log.id, !log.collapse)}
                />)}
            </React.Fragment>
        );
    }
}

export default translate()(ComplaintChat);
