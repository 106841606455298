import React from 'react';
import {Button, Icon} from "antd";
import Link from "../../../systems/routing/Link";
import {translate} from "react-i18next";
import {trans} from "../../../systems/i18n";
import lodash from 'lodash';
import authService from "../../Permission/authService";
import * as PERMISSION from '../../Permission/permissions';
import {url} from '../../../systems/routing';
class CreateComplaintSeller extends React.Component {
    render() {
        let {order} = this.props;
        return (
            (order.canCreatePaymentRequest && authService.can(PERMISSION.ORDER_EXTERNAL_PAYMENT_REQUEST)) ?
                <div className="ml-4">
                    {
                        this.props.children ?
                            <Link href={url.to('payment-request.create-external', {idOrder: order.code})}>
                                {this.props.children}
                            </Link> :
                            <Button className="px-2 d-flex align-items-center" style={{backgroundColor: '#0034de', fontSize: '1em', color: '#fff'}} size={"small"}>
                                <Link href={url.to('payment-request.create-external', {idOrder: order.code})}>
                                    <div className="d-flex align-items-center">
                                        <Icon type="plus-square" theme={"filled"} style={{fontSize: '1em'}}/>
                                        <span className="font-weight-bold ml-2 a-text--white">Tạo YCTT</span>
                                    </div>
                                </Link>
                            </Button>
                    }

                </div> : null
        );
    }
}

export default translate()(CreateComplaintSeller);
