import React from "react";
import Link from "../../../systems/routing/Link";
import {url} from "../../../systems/routing";
import {Icon, Tooltip} from "antd";
import {translate} from "react-i18next";
import RelatedTransactionModal from "./components/RelatedTransactionModal";
import lodash from "lodash";

class RelatedTransaction extends React.Component{

    constructor(props) {
        super(props)
        this.state = {isShown: false};
    }

    showModal = (e) => {
        e.preventDefault();
        this.setState({isShown: true});
    }

    hideModal = () => this.setState({isShown: false})

    render() {
        const {t, transaction} = this.props;
        const transactionRelated = lodash.get(transaction, "transactionRelated", []);
        const transactionRelatedTo = lodash.get(transaction, "transactionRelatedTo", {});
        return <>
            <div className="related_transaction">
                { !lodash.isEmpty(transactionRelated) && transactionRelated.map((item, index) => {
                        return <p key={index}>
                            <Link
                                isNewTab={true}
                                href={url.to('transaction.detail', {id: item.id})}
                                className="link_item">
                                {item.code}
                            </Link>
                            { index === 0 && transaction.canUpdateRelatedTransaction &&
                                <Tooltip placement="right" title="Map thủ công GD liên quan">
                                    <a className="edit_btn pl-1" href="#" onClick={this.showModal}><Icon type="edit" /></a>
                                </Tooltip>
                            }
                        </p>
                    })
                }

                { !lodash.isEmpty(transactionRelatedTo) &&
                    <p>
                        <Link
                            isNewTab={true}
                            href={url.to('transaction.detail', {id: transactionRelatedTo.id})}
                            className="link_item">
                            {transactionRelatedTo.code}
                        </Link>
                    </p>
                }

                { lodash.isEmpty(transactionRelated) && lodash.isEmpty(transactionRelatedTo) && transaction.canUpdateRelatedTransaction &&
                    <p>
                        <Tooltip placement="right" title="Map thủ công GD liên quan">
                            <a
                                className="edit_btn"
                                href="#"
                                onClick={this.showModal}>
                                <Icon type="edit" />
                            </a>
                        </Tooltip>
                    </p>
                }
            </div>
            <RelatedTransactionModal
                isShown={this.state.isShown}
                hideModal={this.hideModal}
                transaction={this.props.transaction}
            />
        </>;
    }
}

export default translate()(RelatedTransaction);