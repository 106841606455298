import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {Icon, Modal, Checkbox} from 'antd';
import {t} from "../../../../systems/i18n";
import {withEvent} from "../../../../systems/events";
import WRONG_PROCESS from "../events";

class UpdateWrongProcess extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.on({
            [WRONG_PROCESS.CONFIRM]: (event, payload) => this.confirm()
        });
    }

    onChangeValue = (e) => {
        let {dataOrder} = this.props;
        let title_modal = !dataOrder.wrong_process ? `Đánh dấu đơn hàng ngoài` : `Bỏ đánh dấu đơn hàng ngoài`;
        let message_modal = !dataOrder.wrong_process ? `Bạn chắc chắn muốn đánh dấu đơn hàng này là đơn hàng ngoài ?` : `Bạn chắc chắn muốn bỏ đánh dấu đơn hàng ngoài cho đơn hàng này?`

        Modal.confirm({
            className: "a-modal--confirm-trade",
            title: t(title_modal),
            content: t(message_modal),
            cancelText: 'Hủy',
            okText: 'Xác nhận',
            onOk: () => {
                this.props.updateWrongProcessOrder({
                    id: dataOrder.id,
                    status: !dataOrder.wrong_process
                });
            },
            zIndex: 1050,
            icon: <Icon type="question-circle" theme="filled"/>,
        });
    };

    onConfirm() {
        let {dataOrder} = this.props;
        this.props.updateWrongProcessOrder({id: dataOrder.id, status: !dataOrder.wrong_process, confirm : 1});
    }

    confirm() {
        Modal.confirm(
            {
                className: "a-modal--confirm-trade",
                title: t(`Bỏ đánh dấu đơn ngoài`),
                content: t(`Đơn hàng đang có YCTT chưa thanh toán. Bạn có chắc chắn xác nhận nếu muốn bỏ đánh dấu đơn hàng ngoài và hủy các YCTT không?`),
                cancelText: 'Hủy',
                okText: 'Xác nhận',
                onOk: this.onConfirm.bind(this),
                zIndex: 1050,
                icon: <Icon type="question-circle" theme="filled"/>,
            }
        )
    }
    render() {
        let {t, isLoading, dataOrder, canChangeQuantity} = this.props;
        return (
            <div className="block-wrong-process">
                <div className={'d-flex align-items-center'}>
                    <Checkbox
                           disabled={!dataOrder.canWrongProcess || isLoading || !canChangeQuantity}
                           onChange={this.onChangeValue.bind(this)}
                           checked={dataOrder.wrong_process}
                           className={'checkbox--service'}
                    />
                    <p className={"ml-1 a-text--uppercase a-text--nowrap "}>{t('order:label.wrong_process')}</p>
                </div>
            </div>
        );
    }
}

UpdateWrongProcess.defaultProps = {
    dataOrder: {},
    isLoading: false,
    canChangeQuantity: false
};

UpdateWrongProcess.protoType = {
    dataOrder: PropTypes.object,
    isLoading: PropTypes.bool,
    updateWrongProcessOrder: PropTypes.func,
    canChangeQuantity: PropTypes.bool,
};

export default translate('translations')(withEvent(UpdateWrongProcess));
