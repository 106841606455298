import {createSelector} from 'reselect';
import lodash from 'lodash';

const listWarehouse = state => lodash.get(state, "Warehouse.list", []);

export const getListAccountPurchaser = createSelector(
    listWarehouse,
    warehouses => lodash.orderBy(warehouses, ['id'], ['desc']).map(warehouse => {
        let alias = warehouse.code  ? warehouse.code + ' - ' : warehouse.alias + ' - ';
        return {
            value: warehouse.id,
            label: alias + warehouse.name,
            className:'react-select--purchaseaddress__option'
        }
    })
);

