import {call, put} from 'redux-saga/effects';
import * as Constants from './constants';
import * as GlobalConstants from '../../systems/globalContant';
import service from './apiService';
import bootbox from './../common/components/Bootbox/bootbox';
import {t} from './../../systems/i18n/index.js';
import lodash from 'lodash';

export function* postItemLog(action) {
    try {
        let response = yield call(service.postItemLog, action.payload.datas);
        if (typeof response === 'undefined' || response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({
                type: Constants.POST_LOG_ITEM.SUCCESS,
                payload: {...response.data, id: lodash.get(action, "payload.datas.id", -1)}
            });
        } else {
            yield put({
                type: Constants.POST_LOG_ITEM.FAILED,
                payload: {...response.data, id: lodash.get(action, "payload.datas.id", -1)}
            });
        }
    } catch (err) {
        yield put({
            type: Constants.POST_LOG_ITEM.ERROR,
            payload: {...err, id: lodash.get(action, "payload.datas.id", -1)}
        });
        bootbox.error(t("messages.system_errors") + " : " + err.message);
    }
}