import React from 'react'
import { translate } from 'react-i18next'
import onClickOutside from 'react-onclickoutside'
import CancelFragment from '../CancelProcessComponents/CancelFragment'
import OutOfStock from '../OutOfStockComponents/OutOfStock'
import { Divider } from 'antd'
import Link from '../../../../../systems/routing/Link'
import AddLinkProduct from '../UpdateProduct/AddLinkProduct'

class ActionExtend extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
        }
    }

    /**
     * Xử lý hành động open/close dropdown
     */
    handleOpenDropdown() {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    handleClickOutside = evt => {
        this.setState({
            isOpen: false,
        })
    }

    handleClick(type, event) {
        event.preventDefault()
        this.props.onClickOpenFormEdit(type, event)
    }

    render() {
        let {
            t,
            order_item_checked,
            nextOrderId,
            order,
            isLoadingCancelOrder,
            beginProcessCancelOrder,
            isLoadingOutOfStockOrder,
            beginProcessOutOfStock,
            onCancel,
            isEndProcess,
            onOutOfStock,
            getPurchasingOrderDetailAction,
        } = this.props
        let { isOpen } = this.state
        return (
            <div className="action-extend" onClick={this.handleOpenDropdown.bind(this)}>
                <span className="icon_option"></span>
                <ul className={isOpen ? 'active' : ''}>
                    {order.canUpdateOutOfStock && (
                        <li className="a-purchasod__link">
                            <a
                                href=""
                                className={`${order_item_checked.length ? '' : 'a-disable'}`}
                                onClick={this.handleClick.bind(this, 'isOpenConfirmOutOfStock')}>
                                {t('order_detail.OUT_OF_STOCK')} {order_item_checked.length ? `(${order_item_checked.length})` : ''}
                            </a>
                        </li>
                    )}
                    <li className="a-purchasod__link">
                        <a
                            href=""
                            className={`${order_item_checked.length ? '' : 'a-disable'}`}
                            onClick={this.handleClick.bind(this, 'isOpenConfirmUpdatePrice')}>
                            {t('order_detail.edit_price')} {order_item_checked.length ? `(${order_item_checked.length})` : ''}
                        </a>
                    </li>
                    <li className="a-purchasod__link">
                        <a
                            href=""
                            className={`${order_item_checked.length ? '' : 'a-disable'}`}
                            onClick={this.handleClick.bind(this, 'isOpenConfirmUpdateQuantityItems')}>
                            {t('order_detail.edit_quantity')} {order_item_checked.length ? `(${order_item_checked.length})` : ''}
                        </a>
                    </li>
                    {/*<li className="a-purchasod__link">*/}
                    {/*    <hr className="m-0 pb-2"/>*/}
                    {/*    <Link isNewTab={!!nextOrderId} to={nextOrderId ? "purchase.buying.detail" : null}*/}
                    {/*          params={{id: nextOrderId}}*/}
                    {/*          className={`a-text--font-primary ${nextOrderId ? '' : 'a-disable'}`}*/}
                    {/*    >*/}
                    {/*        {t('order_detail.next_order')}*/}
                    {/*    </Link>*/}
                    {/*</li>*/}

                    {order.canAddOrderItem && (
                        <li className="a-purchasod__link">
                            <hr className="m-0 pb-2" />
                            <AddLinkProduct type="link" getPurchasingOrderDetailAction={getPurchasingOrderDetailAction} orderInfo={order} />
                        </li>
                    )}

                    {order.canCancel ? (
                        <li className="a-purchasod__link">
                            <hr className="m-0 pb-2" />
                            <CancelFragment
                                code={order.code}
                                canCancel={order.canCancel}
                                onCancel={onCancel}
                                id={order.id}
                                isModalProcessing={!isEndProcess.cancelOrder}
                                isLoadingCancelOrder={isLoadingCancelOrder}
                                beginProcessCancelOrder={beginProcessCancelOrder}
                            />
                        </li>
                    ) : null}

                    {order.canUpdateOutOfStock ? (
                        <li className="a-purchasod__link">
                            <hr className="m-0 pb-2" />
                            <OutOfStock
                                code={order.code}
                                canUpdateOutOfStock={order.canUpdateOutOfStock}
                                onOutOfStock={onOutOfStock}
                                id={order.id}
                                isModalProcessing={!isEndProcess.outOfStock}
                                isLoadingOutOfStockOrder={isLoadingOutOfStockOrder}
                                beginProcessOutOfStock={beginProcessOutOfStock}
                            />
                        </li>
                    ) : null}
                </ul>
            </div>
        )
    }
}

ActionExtend.defaultProps = {}

ActionExtend.propTypes = {}

export default translate('translations')(onClickOutside(ActionExtend))
