import {connect} from 'react-redux';
import UserSettingComponent from './components';
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from '../../common/actions';
import {fetchAllConfigUser, updatePrivateSetting} from "./actions";
import lodash from 'lodash';

const mapStateToProps = (state) => {
    return {
        listConfig: lodash.get(state, 'Setting.UserSetting.list', {}),
        permissions: lodash.get(state, 'User.me.user.permissions', []),
        isLoading:lodash.get(state, 'Setting.UserSetting.isLoading', true),
        isEndProcess: lodash.get(state, 'Setting.UserSetting.isEndProcess', true),

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        fetchAllConfigUser: (menu) => {
            dispatch(fetchAllConfigUser(menu))
        },
        updatePrivateSetting: (params) => {
            dispatch(updatePrivateSetting(params))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSettingComponent);