import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import Checkbox from '../../../../common/components/Checkbox/index';
import CopyPure from '../../../../common/components/CopyPure';
import {CopyToClipboard} from 'react-copy-to-clipboard';
//import Tooltip from '@atlaskit/tooltip';
import OrderItemStatus from './OrderItemStatus';
import OrderItemComment from "./OrderItemComment";
import Spinner from '@atlaskit/spinner';
import OrderItemQuantity from './OrderItemQuantity';
import {ModalTransition} from '@atlaskit/modal-dialog';
import ModalConfirmChangePrice from './ModalConfirmChangePrice';
import {orderStatus} from './../../../../Order/services/index';
import {ORDER_STATUS} from './../../../../Order/constants';
import AutoPaiButton from "./AutoPaiButton";
import {Row, Col, Icon, Tooltip} from "antd";
import UpdateAffiliateLink from "./UpdateAffiliateLink";
import ProductName from "./ProductName";
import CanExport from "../CanExport/CanExport";
import UpdateHSCode from "../HSCode/UpdateHSCode";
import InternalCode from "../InternalCode";
import { currentFormatter } from '../../../../common/services/format'
import authService from '../../../../Auth/authService';
import { SELF_NEGOTIATE_UPDATE_PRODUCT } from '../../../../Permission/permissions';

const defaultItem = {
    item_id: 0,
    unit_price_text: '¥ 0',
    unit_price: 0,
    partner_order_item_id: 0
};

class OrderItemProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            isShowModalChangeUnitPrice: false,
            item2ChangeUnitPrice: defaultItem
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (lodash.get(nextProps, "isEndProcess.updateUnitPrice", false) === true) {
            nextState.isShowModalChangeUnitPrice = false;
        }

        return nextState;
    }

    /**
     * Handle action post comment for item
     * @param event
     */
    handlePostLog(event) {
        let {target} = event;
        let {item} = this.props;
        if (this.generateError() !== null) {
            this.props.clearErrorWhilePostCommentItem(item.id);
        }
        if (event.key === 'Enter' && target.value.trim() !== "") {
            this.props.postItemLog({
                "message": target.value.trim(),
                "id": "" + item.id
            });
            this.setState({
                comment: ""
            });
        } else {
            this.setState({
                comment: target.value
            });
        }
    }

    cancelEditQuantity() {
        let {order} = this.props;
        return orderStatus.isBefore(order.status, ORDER_STATUS.BOUGHT);
    }

    /**
     * handle action click into unit price
     */
    handleClickUnitPrice() {
        this.setState({isShowModalChangeUnitPrice: true, item2ChangeUnitPrice: this.props.item});
        this.props.beginProcessChangePrice()
    }

    /**
     * handle action close modal form
     */
    hiddenConfirmForm() {
        this.setState({isShowModalChangeUnitPrice: false, item2ChangeUnitPrice: defaultItem});
        this.props.clearErrorWhileUpdatePrice(["reason", "unit_price"]);
    }

    /**
     * handle action click submit form change unit price
     */
    onSubmitFormChangeUnitPrice(itemData) {
        this.props.updateOrderItemPrice(itemData);
        this.props.clearErrorWhileUpdatePrice(["reason", "unit_price"]);
    }

    /**
     * Clear error while change unit price
     */
    onClearErrorChangePrice(param) {
        this.props.clearErrorWhileUpdatePrice(param);
    }

    /**
     * generate the errors while post comment
     * @returns {*}
     */
    generateError() {
        let {errorCommentItem, item, t} = this.props;
        if (typeof errorCommentItem[item.id] !== "undefined") {
            let errorItem = lodash.get(errorCommentItem[item.id], "message", {});
            if (!lodash.isEmpty(errorItem)) {
                switch (Object.keys(errorItem)[0]) {
                    case 'required':
                        return t('orderDetail:order_detail.error.' + Object.keys(errorItem)[0], {name: t('orderDetail:order_detail.comment')});
                    case 'unique':
                        return t('orderDetail:order_detail.error.' + Object.keys(errorItem)[0], {name: t('orderDetail:order_detail.comment')});
                    case 'max.string':
                        let objectmax = lodash.get(errorItem, 'max.string', null);
                        let overLength = lodash.get(objectmax, 'max', null);
                        return t('orderDetail:order_detail.error.max_string', {
                            name: t('orderDetail:order_detail.comment'),
                            value: overLength
                        });
                    case 'min.string':
                        let objectmin = lodash.get(errorItem, 'min.string', null);
                        let length = lodash.get(objectmin, 'min', null);
                        return t('orderDetail:order_detail.error.min_string', {
                            name: t('orderDetail:order_detail.comment'),
                            value: length
                        });
                    case "status_invalid":
                        return t('orderDetail:order_detail.error.status_invalid');
                    default:
                        return null;
                }
            }
        }

        return null;
    }

    convertDataAutoPai(data) {
        if (!data) {
            return "";
        }

        return JSON.stringify(data.split(";").filter((prop) => prop !== ""));
    }

    render() {
        let {
            t, item, order, isLoadingUpdateData, clearPurchaserBuyingChangeDataState,
            isLoadingData
        } = this.props;
        let inputVersion = item.id + '_' + item.is_pai;
        let {comment, isShowModalChangeUnitPrice} = this.state;
        let error = this.generateError();
        let postLogItem = lodash.get(isLoadingData, 'postLogItem', []),
            link_text   = (item.affiliate_link) ? item.affiliate_link : item.link;
        let can_exported = lodash.get(item, "can_exported", null);

        const showCurrencyVND = lodash.get(order, 'showCurrencyVND', false)
        const orderDetail = lodash.get(order, 'orderDetail', {})
        const self_negotiate = lodash.get(orderDetail, "self_negotiate", false)

        return (
            <>
                <Row id={lodash.get(item, 'partner_order_item_id', null)} className="mb-3 mt-3">
                    {/* box-checkbox-item, name="check_item" sử dụng cho autopai extension, dont change it pls. */}
                    <Col span={1} className="box-checkbox-item">
                        <Checkbox
                            value={item.id}
                            onChange={this.props.onCheckItem}
                            name="check_item"
                            key={"chk_all" + this.props.defaultChecked}
                            defaultChecked={this.props.defaultChecked}
                        />
                    </Col>
                    <Col xl={{span: 2}} lg={{span: 2}} md={{span: 2}} className="a-purchasod__table__order__infos__left">
                        <a href={link_text} target="_blank">
                            <img src={item.image} alt="Avatar" />
                        </a>
                    </Col>
                    <Col xl={{span: 11}} lg={{span: 11}} md={{span: 9}}>
                        <Row>
                            <Col xl={{span: 14}} md={{span: 10}}>
                                <span className="a--cursor-poiter ml-3 a-text--bold a-text--font-blue">
                                    #{lodash.get(item, 'partner_order_item_id', null)}
                                </span>
                                <CanExport can={can_exported} />
                            </Col>
                            <Col xl={{span: 10}} md={{span: 14}}>
                                <OrderItemQuantity key={item.id + item.qty_bought} item={item}
                                                   clearPurchaserBuyingChangeDataState={clearPurchaserBuyingChangeDataState}
                                                   isLoadingUpdateData={isLoadingUpdateData}
                                                   order={order}
                                                   input={{id: item.id, qty_bought: item.qty_bought, id_partner: order.id_partner}}
                                                   changeMutipleQuantity={this.props.changeMutipleQuantity}/>
                            </Col>
                        </Row>
                        {
                            !lodash.isEmpty(item) &&
                            <InternalCode item={item} order={order}/>
                        }
                        <UpdateHSCode
                            defaultValue={item.hs_code}
                            type="order_item"
                            orderId={order.id}
                            items={[item.id]}
                            code={item.partner_order_item_id}
                            updateHSCodeOrderDetail={this.props.updateHSCodeOrderDetail}
                            hsCodes={order.hsCodes}
                        />
                        <ProductName {...this.props} />
                    </Col>

                    <Col xs={{span: 12, offset: 0}} lg={{span: 9, offset: 1}}>
                        <div className="d-flex justify-content-between">
                            <div>
                                <span className={order.canChangePrice === true ? '' : 'disabled'}>
                                    <ModalTransition>
                                        {isShowModalChangeUnitPrice ? <ModalConfirmChangePrice
                                            item={item}
                                            onSubmitForm={this.onSubmitFormChangeUnitPrice.bind(this)}
                                            onClose={this.hiddenConfirmForm.bind(this)}
                                            error={this.props.errorUnitPrice}
                                            clearError={this.onClearErrorChangePrice.bind(this)}
                                            isLoading={this.props.isLoading}
                                        /> : null
                                        }
                                    </ModalTransition>
                                </span>

                                <div className={`d-flex ${showCurrencyVND ? "mb-0" : "mb-2"} align-item-center`}>
                                    <CopyPure contentCopy={item.unit_price_copy}
                                              contentText={item.unit_price_text}
                                              classText="a--cursor-poiter">
                                    </CopyPure>
                                    &nbsp;/&nbsp;
                                    <CopyPure contentCopy={item.sum_qty_unit_price_copy}
                                              contentText={" " + item.sum_qty_unit_price_text}
                                              classText="a--cursor-poiter">
                                    </CopyPure>&nbsp;
                                    {
                                        ((!!self_negotiate && authService.can(SELF_NEGOTIATE_UPDATE_PRODUCT)) || !self_negotiate) &&
                                        <Icon
                                            type="edit"
                                            className={"ml-2 icon-edit-cost a-text--fz-12 a-text--font-blue " + (order.canChangePrice === true ? '' : 'a-not-active')}
                                            onClick={this.handleClickUnitPrice.bind(this)}
                                            title={t('orderDetail:order_detail.click_here_update_unit_price')}
                                        />
                                    }
                                    
                                </div>
                                {
                                    showCurrencyVND &&
                                    <div className="mb-2 a-text--fz-10">
                                        <CopyPure contentCopy={item.unit_price_vnd}
                                                  contentText={`${currentFormatter.toLocaleStringCurrencyVND(item.unit_price_vnd)}₫`}
                                                  classText="a--cursor-poiter">
                                        </CopyPure>
                                        &nbsp;/&nbsp;
                                        <CopyPure contentCopy={item.total_vnd}
                                                  contentText={`${currentFormatter.toLocaleStringCurrencyVND(item.total_vnd)}₫`}
                                                  classText="a--cursor-poiter">
                                        </CopyPure>&nbsp;
                                    </div>
                                }

                                {
                                    item.voucher_link ?
                                        <div className="w-100 d-flex justify-content-end">
                                            <a target="_blank" href={item.voucher_link}  className='order-item-link a-text--uppercase' style={{textDecoration:'none'}}>
                                                <i className="fas fa-gift mr-1"></i>
                                                {t('orderDetail:order_detail.get_voucher_link')}
                                            </a>
                                        </div> : ''
                                }

                            </div>

                            <div className="d-flex mb-2">
                                <AutoPaiButton
                                    link={link_text}
                                    isPai={item.is_pai}
                                    property={this.convertDataAutoPai(item.property)}
                                    property_value={this.convertDataAutoPai(item.property_value)}
                                    qty_bought={item.qty_bought}
                                    seller_source={order.seller_source}
                                    canChangePaid={order.canAutoPai}
                                    item_id={item.id}
                                    sku={item.sku}
                                    biz_item_id={item.item_id}
                                />
                                <OrderItemStatus
                                    item={item}
                                    key={item.is_pai}
                                    onUpdatePaidOrderDetail={this.props.onUpdatePaidOrderDetail}
                                    input={item}
                                    inputVersion={inputVersion}
                                />
                            </div>
                        </div>

                        <div className="">
                            {
                                order.canOrderItemComment === true &&
                                <input
                                    type="text"
                                    className="input__chat_product"
                                    onKeyPress={this.handlePostLog.bind(this)}
                                    onChange={this.handlePostLog.bind(this)}
                                    placeholder={t("order_detail.internal_chat_about_the_product")}
                                    value={comment}
                                />
                            }
                            <span className={(postLogItem.length && lodash.includes(postLogItem, item.id)) ? "ml-2 pt-1" : "hidden"} >
                                <Spinner size="small" isCompleting={false}/>
                            </span>
                            <span className={error === null ? "hidden" : "a-text--red a-font--10 mt-1"}>{error}</span>
                            <OrderItemComment item={item}/>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

OrderItemProduct.defaultProps = {
    order: {},
    isLoadingUpdateData: {},
    items: {},
    errorUnitPrice: {},
    errorCommentItem: {}
};

OrderItemProduct.propTypes = {
    order: PropTypes.object,
    isLoadingUpdateData: PropTypes.object,
    errorCommentItem: PropTypes.object,
    errorUnitPrice: PropTypes.object,
    updateOrderItemPrice: PropTypes.func,
    beginProcessChangePrice: PropTypes.func,
    item: PropTypes.object
};

export default translate('translations')(OrderItemProduct);
