import React, {useState, useEffect} from "react";
import {Icon, Modal} from "antd";
import {translate} from "react-i18next";
import lodash from "lodash";
import divider from "@atlaskit/icon/glyph/editor/divider";
import moment from "moment";

function ShopTitle(props) {
    let {t, order} = props;

    return (
        <div>
            <img
                className="mr-2 img-shop"
                src={"http://nhaphang.com/vp-assets/images/favicons_" + order.seller_source + ".png"}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = require('./../../resource/u32.png')
                }}
                alt="Avatar"
            />
            <span className="mr-2">{order.seller_name}</span>
            <a target="_blank"
               href={'http://www.taobao.com/webww/ww.php?ver=3&touid=' +
               (order.seller_aliwang) + '&siteid=cntaobao&status=1&charset=utf-8'}
            >
                <img border="0"
                     src={"http://amos.alicdn.com/realonline.aw?v=2&uid=" + order.seller_aliwang + "&site=cntaobao&s=1&charset=utf-8"}
                     title={t('order_detail.chat_with_sell')}
                     alt={"avatar"}
                />
            </a>
        </div>
    );
}

const ShopTitleTrans = translate()(ShopTitle);

function ListCommentsAboutShop(props) {
    let {order} = props;
    let [isVisible, setIsVisible] = useState(false);
    let votes = lodash.get(order, "seller.votes", []);

    function onClick() {
        setIsVisible(true);
        return false;
    }

    function onCancel() {
        setIsVisible(false);
    }

    return (
        <div className="comments-shop-cs">
            <p><a onClick={onClick} className="a-text--pointer"><Icon type="message" /> Xem đánh giá shop</a></p>
            <Modal
                title={<ShopTitleTrans order={order}/>}
                visible={isVisible}
                onCancel={onCancel}
                footer={false}
                width={580}
            >
                {
                    !lodash.isEmpty(votes) &&
                        votes.map((vote, index) => {
                            let {creator} = vote;
                            return (
                                <div className={`line-comments-shop-cs a-text--fz-13 ${index > 0 ? 'mt-2 pt-2' : ''}`}>
                                    <p><strong className="a-text--color-black-65">{creator.name}</strong>, {moment(vote.created_at).format("HH:mm DD/MM/YYYY")}</p>
                                    <div className="a-text--color-black-65">{vote.note}</div>
                                </div>
                            );
                        })
                }
            </Modal>
        </div>
    );
}

export default ListCommentsAboutShop;