import {router} from './../routing';
import isLogin from './../ulti/isLogin';
import {apiWithoutToken} from './../api/';
import {API_URL} from './../globalContant';
import bootbox from './../../modules/common/components/Bootbox/bootbox';
import {t} from './../i18n/index.js';
import url from 'url';
import lodash from "lodash";
import authService from "../../modules/Auth/authService";

/**
 *
 * middle này sẽ call API để redirect sang trang quản lý user nếu check hệ thống chưa có token <br />
 *
 * @param payload
 * @param next
 * @returns {*}
 */
export const checkIsLogin = (payload, next) => {
    if (!isLogin()) {
        let fullUrl = window.location.href;
        let infoDomain = url.parse(fullUrl);
        let pathname = lodash.get(payload, "request.location.pathname", "/");
        let search = lodash.get(payload, "request.location.search", "");

        sessionStorage.setItem('auth.url_return', pathname + search);

        apiWithoutToken.get(API_URL.LOGIN + "?domain=" + infoDomain.hostname, {loading: false})
            .then(function (response) {
                let urlRedirect = lodash.get(response, "data.data.url", "");
                if (urlRedirect !== "") {
                    window.location = urlRedirect;
                } else {
                    bootbox.error(t('messages.system_errors'));
                }
            })
            .catch(function (error) {
                bootbox.error(error.message);
            });
        return;
    }

    // Send token to tool
    authService.authForTool();

    return next(payload);
}

/**
 *
 * middle này sẽ redicect về trang home nếu check hệ thống đã có token <br />
 *
 * @param payload
 * @param next
 * @returns {*}
 */
export const checkIsNotLogin = (payload, next) => {
    if (isLogin()) {
        router.redirect("/");
        return;
    }

    return next(payload);
}