import React, {Component} from 'react';
import {translate} from "react-i18next";
import {Input, Modal, Form, Button, notification, Icon} from "antd";
import lodash from 'lodash';
import {processResponse} from "../../../systems/api";
import apiService from "../../../modules/BankAccount/apiService";

const defaultInput = {
    account_name: "",
    owner_name: "",
    account_number: "",
    bank_name: "",
};

class FormBankAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            input: defaultInput,
            dataError: {},
        }

    }

    handleCancel = () => {
       this.onResetHandler();
    };

    resetError = (field) => {
        this.setState(state => {
            let {dataError} = this.state;
            delete dataError[field];
            return {...state, dataError}
        });
    };

    onHandleChange = (event) => {
        let {value, name} = event.target;
        let {input} = this.state;
        input = {
            ...input,
            [name]: value
        };
        this.setState({
            ...this.state,
            input
        });
        this.resetError(name);
    };

    handleOk = () => {
        this.setState({loading: true});
        this.createBankAccount();
    };

    onResetHandler = () => {
        this.setState({
            visible: false,
            loading: false,
            input: defaultInput,
            dataError: {}
        });
    };

    createBankAccount = () => {
        let {input} = this.state;
        let {t, data, updateState} = this.props;
        processResponse(apiService.createBankAccount(input))
            .then(res => {
                data.unshift(lodash.get(res, "data.bank"));
                updateState({data});
                this.onResetHandler();
                notification.success({message: t('bank_account.created_success')});
            })
            .catch(error => {
                if (error.code === "INPUT_INVALID") {
                    this.setState({
                        loading: false,
                        dataError: error.data
                    });
                } else
                    notification.error({message: t('bank_account.created_fail')});
            });
    };

    renderError = attrName => {
        let {t} = this.props;
        let {dataError} = this.state;
        let errorItem = lodash.get(dataError, attrName, {});

        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('bank_account.error.' + Object.keys(errorItem)[0], {name: t('bank_account.' + attrName)});
                case 'unique':
                    return t('bank_account.error.' + Object.keys(errorItem)[0], {name: t('bank_account.' + attrName)});
                case 'max.string':
                    let objectmax = lodash.get(errorItem, 'max.string', null);
                    let overLength = lodash.get(objectmax, 'max', null);
                    return t('bank_account.error.max_string', {
                        name: t('bank_account.' + attrName),
                        value: overLength
                    });
                default:
                    return null;
            }
        }
    };

    handleOpenModal = () => {
        this.setState({
            visible: true
        })
    };

    render() {
        const {input, visible, loading} = this.state;
        const {t} = this.props;
        return [
            <Button key="1" type="primary" onClick={this.handleOpenModal}><Icon
                type="plus-circle"/>{t("bank_account.button.add")}</Button>,
            <Modal
                key="2"
                title={t("bank_account.title_add").toUpperCase()}
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okButtonProps={{loading}}
                cancelText="Hủy"
                okText={t("bank_account.text_add")}
                width={500}
            >
                <Form className="form-order-group">
                    <Form.Item label={t("bank_account.account_name")} className="a-form--ant-item mb-0 required">
                        <Input
                            type="text"
                            placeholder={t("bank_account.account_name")}
                            value={input.account_name || undefined}
                            onChange={e => this.onHandleChange(e)}
                            name="account_name"
                            maxLength={50}
                        />
                        <div className={"validatorMessage a--error "
                        + (this.renderError("account_name") ? 'active-error' : 'inactive-error')}>
                            {this.renderError("account_name")}
                        </div>
                    </Form.Item>
                    <Form.Item label={t("bank_account.owner_name")} className="a-form--ant-item mb-0 required">
                        <Input
                            type="text"
                            placeholder={t("bank_account.owner_name")}
                            value={input.owner_name || undefined}
                            onChange={e => this.onHandleChange(e)}
                            name="owner_name"
                            maxLength={50}
                        />
                        <div className={"validatorMessage a--error "
                        + (this.renderError("owner_name") ? 'active-error' : 'inactive-error')}>
                            {this.renderError("owner_name")}
                        </div>
                    </Form.Item>
                    <Form.Item label={t("bank_account.account_number")} className="a-form--ant-item mb-0 required">
                        <Input
                            type="text"
                            placeholder={t("bank_account.account_number")}
                            value={input.account_number || undefined}
                            onChange={e => this.onHandleChange(e)}
                            name="account_number"
                            maxLength={50}
                        />
                        <div className={"validatorMessage a--error "
                        + (this.renderError("account_number") ? 'active-error' : 'inactive-error')}>
                            {this.renderError("account_number")}
                        </div>
                    </Form.Item>
                    <Form.Item label={t("bank_account.bank_name")} className="a-form--ant-item mb-0 required">
                        <Input
                            type="text"
                            placeholder={t("bank_account.bank_name")}
                            value={input.bank_name || undefined}
                            onChange={e => this.onHandleChange(e)}
                            name="bank_name"
                            maxLength={255}
                        />
                        <div className={"validatorMessage a--error "
                        + (this.renderError("bank_name") ? 'active-error' : 'inactive-error')}>
                            {this.renderError("bank_name")}
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        ];
    }
}

export default translate()(FormBankAccount)