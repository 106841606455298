import React, {useState, useEffect} from "react";
import {notification, Steps} from "antd";
import moment from "moment";
import withSizes from "react-sizes";
import apiService from "../../apiService";
import {processResponse} from "../../../../../systems/api";
import lodash from "lodash";

const { Step } = Steps;

const mapSizesToProps = ({width}) => ({
    isDesktopSmall: width < 1400,
    screenWidth: width
});

function Timeline(props) {
    let {screenWidth, order} = props;
    let [isLoading, setLoading] = useState(false);
    let [milestones, setMilestones] = useState([]);

    // Thiết lập số lượng step hiển thị theo kích thước màn hình
    let step_numbers = 8;
    if (screenWidth < 560)
        step_numbers = 3
    else if (screenWidth < 780)
        step_numbers = 4
    else if (screenWidth < 992)
        step_numbers = 5
    else
        step_numbers = 8;

    function fetchTimeline() {
        let {order} = props;
        setLoading(true);

        let process = processResponse(apiService.fetchTimeline(order.id));
        process.then(res => {
            setMilestones(lodash.get(res, "data.milestones", []));
        });

        process.catch(error => {
            notification.error({message: "Tải dữ liệu timeline của đơn không thành công"});
        });

        process.then(() => {
            //setLoading(false);
        });
    }

    let loadMilestones = lodash.get(order, 'loadMilestones', false);
    useEffect(() => {
        if (!isLoading && loadMilestones)
            fetchTimeline();
    });
    const milestonesInfo = lodash.sortBy(milestones, "timestamp", "asc");
    return milestonesInfo.length > 0 ? (
        <div className={`a-purchasod_steps ${milestonesInfo.length > step_numbers ? 'a-purchasod_steps_scroll' : ''}`}>
            <hr className="m-0"/>
            <div className="pt-3">
                <Steps progressDot current={milestonesInfo.length} size={"small"}>
                    {

                        milestonesInfo.map((milestone, index) => {
                            let status = lodash.get(milestone, "_status", "");
                            let step_date = moment(milestone._timestamp);
                            return <Step key={index} title={status} description={step_date.isValid() ? step_date.format('DD/MM/YYYY HH:mm') : ""} />
                        })
                    }
                </Steps>
            </div>
        </div>
    ) : null;
}

export default withSizes(mapSizesToProps)(Timeline);