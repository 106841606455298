import lodash from 'lodash';
import {t} from '../i18n';
import notification from '../notification';
import Pipeline from '../pipeline/Pipeline';

export default class ResponseProcessor {
    constructor(middleware = []) {
        this.middleware = middleware;
    }

    process(promise) {
        return new Promise((resolve, reject) => {
            return (new Pipeline())
                .send(promise)
                .through(this.middleware)
                .then(promise => this.processResponse(promise, resolve, reject));
        });
    }

    processResponse(promise, resolve, reject) {
        promise.then(res => {
            let data = lodash.get(res, 'data') || {};

            switch (data.code) {
                case 200:
                    return resolve(data);
                case 401:
                case 403:
                    return;
                default:
                    return reject(data);
            }
        });

        promise.catch((res) => {
            if (res.message != undefined)
                notification.error(t('message.system_errors'));
        });
    }
}
