import {combineReducers} from 'redux';
import {FETCH_VIOLATE_REASONS} from './constants'


const list = (state = [], action) => {
    switch (action.type) {
        case FETCH_VIOLATE_REASONS.SUCCESS:
            return action.payload.data;
        case FETCH_VIOLATE_REASONS.FAILED:
            return action.payload.data;
        case FETCH_VIOLATE_REASONS.REQUEST:
        default:
            return state;
    }
};

export default combineReducers({
    list,
})
