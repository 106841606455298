import React from 'react';
import Link from "../../../../systems/routing/Link";
import {Icon} from "antd";
import notification from '../../../../systems/notification';
import {translate} from 'react-i18next';
import LogTransaction from './LogTransaction';
import InfoDetail from "./InfoDetail";
import ListOrderTransaction from "./ListOrderTransaction";
import queryString from "query-string";
import {url} from "../../../../systems/routing";
import apiService from "../apiService";
import {processResponse} from "../../../../systems/api";
import lodash from "lodash";
class DetailTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            loading: false,
            collapse : false
        }
    }

    collapseInfoTransaction = () => {
        this.setState({collapse: !this.state.collapse});

    };

    componentDidMount() {
        let {t, location, match} = this.props;
        let filter = {...queryString.parse(location.search), ...match.params};
        this.props.getTransactionDetail(filter);
        this.getLogs(filter);
        this.props.setPageTitle(t('breadcrumb.transaction_detail'));
        this.props.setPageDescription(t('transaction.detail'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.transaction_detail',
                'link': url.to('transaction.detail', {id: filter.id})
            },
        ]);
        this.props.setMenuActive("transaction.list");
    }
    componentWillUnmount() {
        this.props.clearState();
    }

    getLogs(filter) {
        let {t} = this.props;
        if (this.state.loading) {
            return;
        }
        if (filter.id) {
            this.setState({loading: true});

            let res = apiService.getTransactionLogs({id: filter.id})
                .finally(() => this.setState({loading: false}));

            let process = processResponse(res);

            process.then(res => {
                this.setState({
                    logs: lodash.get(res, 'data', []),
                });
            });

            process.catch(() => {
                notification.error(t('message.cant_fetch_data'));
            });
            return process;
        }
    }
    render() {
        let {transaction, orders} = this.props;
        return (
            <React.Fragment>
                <div className="a-content--detail-transaction">
                    <div className="d-flex justify-content-between">
                        <span className="a-text--fz-12 a-text--pointer a-text--color-rgba-45"><Link href={url.to('transaction.list')}><Icon type="arrow-left"/> Quay lại</Link></span>
                        <span className="a-text--color-primary a-text--fz-12 a-text--pointer" onClick={this.collapseInfoTransaction}>
                        <Icon type={this.state.collapse ? 'arrows-alt' : 'shrink'} className="mr-1"/> {this.state.collapse ? 'Mở rộng thông tin' : 'Thu gọn thông tin'}
                        </span>
                    </div>
                    <InfoDetail collapse={this.state.collapse} {...this.props} transaction={this.props.transaction}/>
                    <ListOrderTransaction collapse={this.state.collapse} orders={orders}/>
                </div>
                <LogTransaction
                    logs={this.state.logs}
                    loading={this.state.loading}
                />
            </React.Fragment>
        );
    }
}
export default translate('translations')(DetailTransaction);
