import React from 'react';
import {translate} from 'react-i18next';
import Button from '@atlaskit/button';
import PropTypes from 'prop-types';
import {trans} from '../../../../systems/i18n';
import ModalGeneral from './../../../common/components/ModalGeneral';

class ModalConfirmDelete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openModel: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        console.log("getDerivedStateFromProps ==>", prevState.openModel, nextProps.isModalProcessing );
        if ((prevState.openModel === true ) && nextProps.isModalProcessing === false) {
            nextState.openModel = false;
        }

        return nextState;
    }

    onOpenModal() {
        let {openModel} = this.state;
        this.setState({openModel: !openModel});
        this.props.onBeginRemovePackage();
    }

    onClose(){
        if (!this.props.loading) {
            this.setState({openModel: false});
        }
    }

    onRemovePackage(){
        if (!this.props.loading) {
            let {onRemovePackage, packageInfo} = this.props;
            onRemovePackage(packageInfo);
        }
    }

    render() {
        let {t, packageInfo, loading, canUpdateIntoStatus} = this.props;
        let {openModel} = this.state;

        return (
            <React.Fragment>
                <a href="#" disabled={canUpdateIntoStatus? false:true} className={"a-table--complaint_solution__remove_package "+(canUpdateIntoStatus? "":"a-disable")}
                   onClick={this.onOpenModal.bind(this)}>
                    <i className="fas fa-times"/>
                </a>
                {openModel ? <ModalGeneral>
                    <div className={"a-modal__title"}>
                        <p>{trans('modal.title_model_alert')}</p>
                    </div>
                    <div style={{wordBreak: "break-all"}}>
                        {trans('complaintSeller:product_return.confirm_delete_package', {package: <b>{packageInfo}</b>})}
                    </div>
                    <div className="a--group--inline-end mb-3 a--user-group-btn">
                        <Button
                            appearance="primary"
                            className="a-btn--submit"
                            isLoading={loading}
                            onClick={this.onRemovePackage.bind(this)}
                        >{t('order_detail.btn_action_confirm')}</Button>
                        <Button
                            appearance="subtle"
                            className="a-btn a-btn--none a-btn-link ml-3"
                            isDisabled={loading}
                            onClick={this.onClose.bind(this)}
                        >{t('order_detail.btn_action_cancel')}</Button>
                    </div>
                </ModalGeneral> : null}

            </React.Fragment>

        );
    }
}

ModalConfirmDelete.defaultProps = {
    loading: false,
    canUpdateIntoStatus: false,
    onRemovePackage: packageCode => {},
};

ModalConfirmDelete.propTypes = {
    loading: PropTypes.bool,
    canUpdateIntoStatus: PropTypes.bool,
    onRemovePackage: PropTypes.func
};

export default translate('translations')(ModalConfirmDelete);
