import {call, put} from 'redux-saga/effects'
import * as Constants from './constants';
import service from './apiService';
import * as GlobalConstants from "../../systems/globalContant/index";
import bootbox from "../../modules/common/components/Bootbox/bootbox";
import {t} from './../../systems/i18n/index.js';

/**
 * Tạo mới report
 * @param action
 */
export function* createReports(action) {
    try {
        let response = yield call(service.createReports, action.payload);
            if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({type: Constants.CREATE_EXPORT_REPORT.SUCCESS, payload: response.data});
            } else {
                yield put({type: Constants.CREATE_EXPORT_REPORT.FAILED, payload: response.data});
            }
    } catch (err) {
        yield put({type: Constants.CREATE_EXPORT_REPORT.FAILED, payload: err.message});
        bootbox.error(t("message.system_errors") + " : " + err.message);
    }
}


/**
 * Thực hiện thêm mới yêu cầu xuất doanh số
 * @param action
 */
export function* newRequestReport(action) {
    try {
        let response = yield call(service.newRequestReport, action.payload);
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.NEW_REQUEST_REPORT.SUCCESS, payload: response.data});
        } else {
            yield put({type: Constants.NEW_REQUEST_REPORT.FAILED, payload: response.data});
            bootbox.error(t("sale:error.newRequestFailed"));
        }
    } catch (err) {
        yield put({type: Constants.NEW_REQUEST_REPORT.FAILED, payload: err.message});
        bootbox.error(t("message.system_errors") + " : " + err.message);
    }
}

/**
 * Lấy danh sách lịch sử report
 * @param action
 */
export function* getListReports(action) {
    try {
        let response = yield call(service.getListReports, action.payload);
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.GET_LIST_REPORTS.SUCCESS, payload: response.data});
        } else {
            yield put({type: Constants.GET_LIST_REPORTS.FAILED, payload: response.data});
            bootbox.error(t("sale:error.listError"));
        }
    } catch (err) {
        yield put({type: Constants.GET_LIST_REPORTS.FAILED, payload: err.message});
        bootbox.error(t("message.system_errors") + " : " + err.message);
    }
}

/**
 * Lấy report đang thực hiện
 * @param action
 */
export function* getCurrentReports(action) {
    try {
        let response = yield call(service.getCurrentReports, action.payload);
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.GET_CURRENT_REPORT.SUCCESS, payload: response.data});
        } else {
            yield put({type: Constants.GET_CURRENT_REPORT.FAILED, payload: response.data});
            bootbox.error(t("sale:error.getCurrentReport"));
        }
    } catch (err) {
        yield put({type: Constants.GET_CURRENT_REPORT.FAILED, payload: err.message});
        bootbox.error(t("message.system_errors") + " : " + err.message);
    }
}

/**
 * Thực hiện hủy tạo mới report
 * @param action
 */
export function* createCancelReports(action) {
    try {
        let response = yield call(service.createCancelReports, action.payload);
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.CANCEL_EXPORT_REPORT.SUCCESS, payload: response.data});
            bootbox.success(t("sale:form.cancel_success"));
        } else {
            yield put({type: Constants.CANCEL_EXPORT_REPORT.FAILED, payload: response.data});
            if( response.data.code === 'REPORT_CAN_NOT_CANCEL' ){
                bootbox.error(t("sale:error.REPORT_CAN_NOT_CANCEL"));
            }
        }
    } catch (err) {
        yield put({type: Constants.CANCEL_EXPORT_REPORT.FAILED, payload: err.message});
        bootbox.error(t("message.system_errors") + " : " + err.message);
    }
}
