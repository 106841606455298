import React, {Component} from "react";
import {translate} from 'react-i18next';
import {Icon, Modal, Input, Button, Tooltip} from "antd";
import InputSuper from '../../../../common/components/Input/Super'
import lodash from "lodash";
import apiService from "../../../../Order/apiService";
import {processResponse} from "../../../../../systems/api";
import bootbox from "../../../../common/components/Bootbox/bootbox";

const {TextArea} = Input;
const defaultState = {
    listToAdd: [],
    listToDelete: [],
    reason: "",
    errors: {},
    invoice_code: "",
    loading: false,
};

class ModalProposedChangesInvoiceCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...defaultState,
            listInvoiceCode: lodash.map(lodash.get(this.props.order, "invoiceCodes", []), "invoice_code"),
        }
    }

    handleOnConfirmModal = () => {
        const {listToAdd, listToDelete, reason, errors} = this.state;
        const {order, t} = this.props;
        this.setState({
            loading: true
        });

        if (!reason) {
            this.setState({
                loading: false,
                errors: {
                    ...this.state.errors,
                    reason: t("orderDetail:errors.reason.empty")
                }
            });
            return;
        }
        if (listToDelete.length === 0 && listToAdd.length === 0) {
            this.setState({
                loading: false,
                errors: {
                    ...this.state.errors,
                    action: t("orderDetail:errors.action")
                }
            });
            return;
        }
        let params = {reason};
        if (order.canApprovalAddInvoiceCode) {
            params.add = listToAdd;
        }
        if (order.canApprovalDeleteInvoiceCode) {
            params.delete = listToDelete;
        }
        processResponse(apiService.addInvoiceCodeApprovals(order.id, params)).then(res => {
            this.setState({
                ...defaultState,
                listToAdd: [],
                listToDelete: [],
                loading: false,
            });
            bootbox.success(t("orderDetail:proposed_change_original_invoice.success"));
            this.props.onCloseModal();
        }).catch(error => {
            this.setState({
                loading: false,
            });
            let code = lodash.get(error, "code", "");
            if (code === "INPUT_INVALID") {
                let validate_error = lodash.get(error, "data.invoice_code.validate_error", []);
                let approval_pending = lodash.get(error, "data.invoice_code.approval_pending", []);
                let errors_message = "";
                if (validate_error.length > 0) {
                    validate_error.map((item) => {
                        if (!approval_pending.includes(item.invoice_code)) {
                            let errors_invoice_code = lodash.get(item, "errors.invoice_code", {});
                            for (let error_code in errors_invoice_code) {
                                if (item.invoice_code) {
                                    errors_message += t(`orderDetail:errors.validate_message.${error_code}`, {invoice_code: item.invoice_code}) + "\n";
                                }
                            }
                        }
                    })
                }
                if (approval_pending.length > 0) {
                    if (approval_pending.length > 0) errors_message += t("orderDetail:errors.approval_pending", {invoice_code: approval_pending.join(", ")}) + "\n";
                }
                this.setState({
                    errors: {
                        ...errors,
                        action: errors_message
                    }
                })

            } else {
                bootbox.error(t("orderDetail:proposed_change_original_invoice.fail"));
            }
        });

    };

    handleOnCancelModal = (e) => {
        this.setState({
            ...defaultState,
            listToAdd: [],
            listToDelete: [],
        });
        this.props.onCloseModal();
    };

    handleOnChangeInvoiceCode = (value) => {
        let {errors} = this.state;
        if (value.length > 0) {
            errors = {...errors, invoice_code: '', action: ''};
            this.setState({
                invoice_code: value,
                errors
            })
        }
    };
    onChangeInput = (e) => {
        let {value, name} = e.target;
        let {errors} = this.state;
        errors = {...errors, [name]: '', action: ''};
        this.setState({
            [name]: value,
            errors
        })
    };
    handleOnBlur = (value) => {
        this.addInvoiceCode(value);
    };

    handleOnKeyPress = (e) => {
        if (e.key === 'Enter') {
            let value = e.target.value.trim();
            this.addInvoiceCode(value);
        }
    };

    addInvoiceCode = (value) => {
        let {listToAdd, listInvoiceCode} = this.state;
        let {t} = this.props;
        if (value) {
            value = value.trim();
            if (value.length > 0) {
                if (!listToAdd.includes(value) && !listInvoiceCode.includes(value)) {
                    listToAdd.push(value);
                    this.setState({
                        invoice_code: "",
                        listToAdd
                    })
                } else {
                    this.setState({
                        errors: {
                            invoice_code: t("orderDetail:errors.invoice_code.unique")
                        }
                    })
                }
            }

        }
    };

    removeInvoiceCode = (code, type) => {
        let {listToAdd, listToDelete} = this.state;
        if (type === "delete") listToDelete.push(code);
        if (type === "add") {
            let indexAdd = listToAdd.indexOf(code);
            if (indexAdd >= 0) {
                listToAdd.splice(indexAdd, 1);
            }
        }
        this.setState({
            errors: {
                ...this.state.errors,
                action: ''
            },
            listToDelete,
            listToAdd
        })
    };
    undoInvoiceCode = (code) => {
        let {listToDelete} = this.state;
        let indexDelete = listToDelete.indexOf(code);
        if (indexDelete >= 0) {
            listToDelete.splice(indexDelete, 1);
        }
        this.setState({
            listToDelete
        })
    };


    render() {
        const {visible, t, order, invoice_status} = this.props;
        let {listInvoiceCode, invoice_code, errors, loading, listToDelete, reason, listToAdd} = this.state;
        let invoice_code_alphabet = lodash.get(order.agency, "invoice_code_alphabet", 0);
        let invoiceCodesInfo = lodash.get(order, "invoiceCodes", []);
        return (
            <Modal
                title={t("orderDetail:title.proposed_change_original_invoice")}
                visible={visible}
                onOk={this.handleOnConfirmModal}
                onCancel={this.handleOnCancelModal}
                footer={[
                    <Button key="back" onClick={(e) => this.handleOnCancelModal(e)}>{t("btn.cancel")}</Button>,
                    <Button key="submit" type="primary" loading={loading}
                            onClick={(e) => this.handleOnConfirmModal(e)}>{t("btn.submit")}</Button>,
                ]}
            >
                {
                    listInvoiceCode.length > 0 &&
                    <div className='a-group'>
                        <label className="label a-label ">{t('orderDetail:label.list_invoice_code')}: </label>
                        <br/>
                        <div className="d-inline-flex a-purchasod__group--bill-code__input mt-2"
                             style={{flexFlow: "wrap"}}>
                            {
                                listInvoiceCode.map((code) => {
                                    let checkDelete = order.canApprovalDeleteInvoiceCode && listToDelete.includes(code);
                                    let invoiceCode_status = lodash.get(lodash.find(invoiceCodesInfo, ['invoice_code', code]), "status", "");
                                    return (
                                        <span className="a-purchasod__group--code pl-2 pr-2 mr-3"
                                              style={{color: `${checkDelete ? "darkgrey" : ""}`}}
                                              key={code}
                                        >{code}
                                            {
                                                invoiceCode_status &&
                                                <span
                                                    className="ml-1 font-weight-normal a-text--color-black-45">[{invoice_status[invoiceCode_status]}]</span>
                                            }
                                            {
                                                order.canApprovalDeleteInvoiceCode &&
                                                <>
                                                    {
                                                        listToDelete.includes(code) ?
                                                            <a onClick={(e) => this.undoInvoiceCode(code)}>
                                                                <Tooltip title="Undo"><Icon type="undo"/></Tooltip>
                                                            </a>
                                                            :
                                                            <a onClick={(e) => this.removeInvoiceCode(code, "delete")}>
                                                                <Tooltip title="Xóa MHĐG đã có của đơn">
                                                                    <Icon type="close"/>
                                                                </Tooltip>
                                                            </a>
                                                    }
                                                </>
                                            }
                                        </span>
                                    );
                                })
                            }
                        </div>
                    </div>
                }

                {
                    order.canApprovalAddInvoiceCode &&
                    <div className='a-group'>
                        <label className="label a-label ">{t('orderDetail:label.list_invoice_code_add')}: </label>
                        <br/>
                        {
                            listToAdd.length > 0 &&
                            <div className="d-inline-flex a-purchasod__group--bill-code__input mt-2"
                                 style={{flexFlow: "wrap"}}>
                                {
                                    listToAdd.map((code) => {
                                        return (
                                            <span className="a-purchasod__group--code pl-2 pr-2 mr-3 " key={code}>{code}
                                                {
                                                    order.canApprovalAddInvoiceCode && listToAdd.includes(code) &&
                                                    <a onClick={(e) => this.removeInvoiceCode(code, "add")}>
                                                        <Tooltip title="Xóa MHĐG vừa thêm"><Icon
                                                            type="close"/></Tooltip>
                                                    </a>
                                                }
                                    </span>
                                        );
                                    })
                                }
                            </div>
                        }
                        <div className="a-purchasod__group--bill-code__input mt-2">
                            <InputSuper
                                integerHasBlank={!invoice_code_alphabet}
                                regexInput={invoice_code_alphabet ? /^\S*$/ : false}
                                focus
                                notReplaceZeroFirst
                                placeholder={t("orderDetail:placeholder.add_invoice_code")}
                                onChange={event => this.handleOnChangeInvoiceCode(event)}
                                onBlur={event => this.handleOnBlur(event)}
                                onKeyPress={event => this.handleOnKeyPress(event)}
                                value={invoice_code}
                                name="invoice_code"
                            />
                            {
                                errors.invoice_code &&
                                <div className="a--error" style={{whiteSpace: "pre-line", fontSize: "14px"}}>
                                    {errors.invoice_code}
                                </div>
                            }
                        </div>
                    </div>

                }
                <div className='a-group'>
                    <label className="label label--required a-label">{t('orderDetail:label.reason')}</label>
                    <TextArea
                        className="mt-2" type="text" autoComplete="off" name="reason"
                        placeholder={t('orderDetail:placeholder.reason',
                            {title: t('orderDetail:title.proposed_change_original_invoice').toLowerCase()}
                        )}
                        rows={3} onChange={(e) => this.onChangeInput(e)} value={reason}
                        maxLength={255}
                    />
                    {
                        errors.reason && <div className="a--error" style={{fontSize: "14px"}}>{errors.reason}</div>
                    }
                </div>
                {
                    errors.action &&
                    <div className="a--error" style={{fontSize: "14px", whiteSpace: "pre-line"}}>
                        {errors.action}
                    </div>
                }
            </Modal>
        );
    }

}

export default translate('translations')(ModalProposedChangesInvoiceCode);