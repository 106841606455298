import {call, put} from 'redux-saga/effects'
import * as Constants from './constants';
import service from './apiService';
import bootbox from "../../modules/common/components/Bootbox/bootbox";
import {t} from "../../systems/i18n";
import lodash from 'lodash';
import * as GlobalConstants from "../../systems/globalContant";
import {processApiRequest} from '../common/saga';
import {getState} from "../../systems/store";

export function* fetchPurchases(action) {
    try {
        let response = yield call(service.fetchPurchases, action.payload);
        if(!response) {
            return;
        }
        if (response) {
            if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({type: Constants.FETCH_PURCHASES.SUCCESS, payload: response.data});
                yield put({type: Constants.FETCH_PURCHASER_ORDER_STATS.REQUEST, payload: action.payload});
            } else {
                yield put({type: Constants.FETCH_PURCHASES.FAILED, payload: response.data});
            }
        }
    } catch (err) {
        bootbox.error(t('global.error'));
    }
}

export function* fetchPurchaserOrderStats(action) {
    yield processApiRequest(Constants.FETCH_PURCHASER_ORDER_STATS, () => service.getPurchaserOrderStats(action.payload), action.payload);
}

export function* createDistributorOrder(action) {
    try {
        let response = yield call(service.createDistributorOrder, action.payload.datas);
        if(!response) {
            return;
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.CREATE_DISTRIBUTOR_OWNER.SUCCESS, payload: response.data});
            let orders = lodash.get(response, 'data.data.orderDistributor', []);
            if (orders.length > 0) {
                const user = getState('User.me.user', {});
                localStorage.setItem("distributorOwner." + user.id, Date.now());
                bootbox.success(t('order:list.distributor.success'));
            } else {
                bootbox.warning(t('order:list.distributor.out_stock'));
            }
        } else {
            yield put({type: Constants.CREATE_DISTRIBUTOR_OWNER.FAILED, payload: response.data});
            let errors = lodash.get(response, 'data.data', {});
            if (response.data.code !== GlobalConstants.GLOBAL_CONTANT.PERMISSION_DENIED) {
                Object.keys(errors).map(error => {
                    Object.keys(errors[error]).map(key => {
                        bootbox.error(t(`order:list.distributor.errors.${error}.${key}`));
                        return key;
                    });

                    return error;
                });
            }
        }
    } catch (err) {
        bootbox.error(t("messages.system_errors") + " : " + err.message);
        yield put({type: Constants.CREATE_DISTRIBUTOR_OWNER.ERROR, payload: err});
    }
}
