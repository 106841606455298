import React from "react";
import {Icon, notification, Tooltip} from "antd";
import lodash from 'lodash';
import {CRAWL_EVENTS, ORIGIN_COMPLAINT_LINK} from "../../constants";

class SynchronizeComplaint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            synchronizing: false
        };
        this.handMessage = this.handMessage.bind(this);
    }

    componentDidMount() {
        window.addEventListener("message", this.handMessage, false);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handMessage, false);
    }

    handMessage(event) {
        let {data} = event;
        switch (data.type) {
            case CRAWL_EVENTS.SYNCHRONIZE_RESPONSE: {
                let res = lodash.get(event, 'data.response', {});
                if (res.code === 200) {
                    this.props.updateComplaintDetailAgain(res);
                    notification.success({message: "Đồng bộ thông tin KNNB thành công!"});
                } else {
                    notification.error({message: "Xảy ra lỗi trong khi gửi thông tin đồng bộ KNNB lên hệ thống!"});
                }
                this.setState({synchronizing: false});
                return;
            }
            case CRAWL_EVENTS.DONT_LOGIN_SITE: {
                notification.error({message: "Vui lòng đăng nhập site gốc để đồng bộ thông tin KNNB!"});
                return;
            }
            case CRAWL_EVENTS.CRAWL_COMPLAINT_FAIL: {
                notification.error({message: "Không đồng bộ được thông tin KNNB site gốc. Vui lòng kiểm tra lại Mã KN site gốc!"});
                this.setState({synchronizing: false});
                return;
            }
        }
    }

    handleClick(e) {
        e.preventDefault();
        let {complaint, order} = this.props;
        this.setState({synchronizing: true});

        // Bắn message đồng bộ KNNB với site gốc
        window.postMessage({
            type: CRAWL_EVENTS.MESSAGE_CODE,
            payload: {
                complaint_id: complaint.id,
                origin_code: complaint.origin_code,
                link: ORIGIN_COMPLAINT_LINK[order.seller_source] + complaint.origin_code
            }
        });
    }

    render() {
        let origin_code = lodash.get(this.props, 'complaint.origin_code', '');
        if (!origin_code) return null;

        return (
            <>
                {
                    this.state.synchronizing ?
                        <Icon type="sync" spin /> :
                        <a href="#" onClick={this.handleClick.bind(this)}>
                            <Tooltip title="Đồng bộ KNNB với site gốc"><Icon type="sync" /></Tooltip>
                        </a>
                }
            </>
        );
    }

}

export default SynchronizeComplaint;
