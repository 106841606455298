import {combineReducers} from 'redux'
import * as Constants from './constants';
import {ATTACH_PURCHASER, DETACH_PURCHASER} from './ManagePurchasers/constants';
import {updateCollectionItem} from '../common/services/helps';
import AccessToken from './AccessToken/reducer';
import lodash from "lodash";

// current customer logging
const me = (state = {}, action) => {
    switch (action.type) {
        case Constants.GET_ME.SUCCESS:
            return action.payload.data;
        case Constants.IPPHONE.UPDATE:
            return {
                ...state,
                user: {...state.user, ...action.payload}
            };
        default:
            return state;
    }
};

const purchasers = (state = [], action) => {
    switch (action.type) {
        case Constants.GET_LIST_PURCHASER.SUCCESS:
        case Constants.FETCH_ACCOUNT.SUCCESS:
            return action.payload.data;
        default:
            return state;
    }
};

/*
 * Danh sách user
 */
const users = (state = [], action) => {
    switch (action.type) {
        case Constants.FETCH_USERS.SUCCESS:
            return [...action.payload.data];

        case ATTACH_PURCHASER.REQUEST:
        case DETACH_PURCHASER.REQUEST:
            return updateCollectionItem(state, action.payload.userId, {
                manage_purchaser_loading: true,
                manage_purchaser_error: null,
            });

        case ATTACH_PURCHASER.FAILED:
        case DETACH_PURCHASER.FAILED:
            return updateCollectionItem(state, action.request.userId, {
                manage_purchaser_loading: false,
                manage_purchaser_error: action.payload,
            });

        case ATTACH_PURCHASER.SUCCESS:
        case DETACH_PURCHASER.SUCCESS:
            return updateCollectionItem(state, action.request.userId, {
                is_teller: action.type === ATTACH_PURCHASER.SUCCESS,
                manage_purchaser_loading: false,
            });

        default:
            return state;
    }
};

let loading = (state = {detail: false}, action) => {
    switch (action.type) {
        case Constants.FETCH_USERS.REQUEST:
            return {detail: true};
        case Constants.FETCH_USERS.SUCCESS:
        case Constants.FETCH_USERS.FAILED:
            return {detail: false};
        default:
            return state;
    }
};

const users_complaint_seller = (state = [], action) => {
    switch (action.type) {
        case Constants.FETCH_USERS_COMPLAINT_SELLER.SUCCESS:
            return action.payload.data;
        default:
            return state;
    }
};

export default combineReducers({
    users,
    purchasers,
    me,
    users_complaint_seller,
    AccessToken,
    loading,
});
