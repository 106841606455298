import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import ModalConfirmSellerComplete from './components/ModalConfirmSellerComplete';
import * as actions from './actions';

const mapStateToProps = (state, props) => {
    let {complaint} = props;
    let completeComplaints = lodash.get(state, 'ComplaintSeller.completeComplaint.complaints', []);
    let completeComplaint = lodash.find(completeComplaints, {id: complaint.id}) || {};
    
    return {
        completeComplaint:completeComplaint,
        complaint: complaint,
        canComplete: lodash.get(complaint, 'permissions.can_receive'),
        errors: lodash.get(state, 'ComplaintSeller.completeComplaint.updateErrors', []),
        isLoading: lodash.get(state, 'ComplaintSeller.completeComplaint.isLoadingData', false),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onSubmitForm: (param) => {
            dispatch(actions.updateComplaintSellerSuccess(param));
        },
        closeModalChangeSuccess: () => {
            dispatch(actions.closeModalChangeSuccess());
        },
        clearErrorComplaintSellerSuccess: () => {
            dispatch(actions.clearErrorComplaintSellerSuccess());
        }
    };
};

let CompleteComplaintContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalConfirmSellerComplete);

CompleteComplaintContainer.defaultProps = {
    complaint: {},
};

CompleteComplaintContainer.propTypes = {
    complaint: PropTypes.object.isRequired,
};

export default CompleteComplaintContainer;
