import React from 'react'
import { t } from '../../../../systems/i18n'
import { Checkbox, Icon, Modal } from 'antd'
import { get } from 'lodash'
import { setBatchOrder } from '../actions'
import { dispatch } from '../../../../systems/store'
import EstimatedBatchOrderInfo from './EstimatedBatchOrderInfo'

function ChangeBatchOrder(props) {
    const { dataOrder } = props
    const isBatchOrder = get(dataOrder, 'orderDetail.is_batch_order', false)
    const canUnSetBatchOrder = get(dataOrder, 'canUnSetBatchOrder', false)
    const canSetBatchOrder = get(dataOrder, 'canSetBatchOrder', false)
    const canUpdateBatchOrder = get(dataOrder, 'canUpdateBatchOrder', false)
    const orderId = get(dataOrder, 'id')

    const onChange = () => {
        let title_modal = isBatchOrder && canUnSetBatchOrder ? `Bỏ đánh dấu đơn hàng lô` : `Đánh dấu đơn hàng lô`
        let message_modal =
            isBatchOrder && canUnSetBatchOrder
                ? `Bạn chắc chắn muốn bỏ đánh dấu đơn hàng lô cho đơn hàng này?`
                : `Bạn chắc chắn muốn đánh dấu đơn hàng này là đơn hàng lô ?`

        Modal.confirm({
            className: 'a-modal--confirm-trade',
            title: t(title_modal),
            content: t(message_modal),
            cancelText: 'Hủy',
            okText: 'Xác nhận',
            onOk: () => {
                handleUpdate({
                    status: !isBatchOrder,
                })
            },
            zIndex: 1050,
            icon: (
                <Icon
                    type="question-circle"
                    theme="filled"
                />
            ),
        })
    }

    const handleUpdate = data => {
        dispatch(setBatchOrder({ orderId, data }))
    }

    return (
        <div className="block-wrong-process">
            <div className={'d-flex align-items-center'}>
                <Checkbox
                    disabled={(isBatchOrder && !canUnSetBatchOrder) || !canSetBatchOrder}
                    checked={isBatchOrder}
                    className={'checkbox--service'}
                    onChange={onChange}
                />
                <p className={'ml-1 a-text--uppercase a-text--nowrap '}>{t('order:label.batch_order')}</p>
                {!!isBatchOrder && !!canUpdateBatchOrder && <EstimatedBatchOrderInfo dataOrder={dataOrder} />}
            </div>
        </div>
    )
}
export default ChangeBatchOrder
