import React from 'react';
import {translate} from 'react-i18next';
import ComplaintChat from './ComplaintChat/index';
import ProductReturnChat from './ProductReturnChat/';
import OrderChat from './OrderChat';

class LogPage extends React.Component {

    render() {
        switch (this.props.object) {
            case 'complaint_seller':
                return <ComplaintChat  {...this.props}/>;
            case 'product_return':
                return <ProductReturnChat  {...this.props}/>;
            default:
                return <OrderChat  {...this.props}/>;
        }
    }
}

export default translate()(LogPage);
