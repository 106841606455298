import React from 'react';
import {Icon, Input, Modal, Button, Popconfirm, Col, Row} from "antd";
import OrderTransaction from './OrderTransaction';

class ListOrderTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }


    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    showModalEidtCode = () => {
        this.setState({
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        let collapse =this.props.collapse;
        let {invoices, orders} = this.props;
        return (
            orders.length !== 0 &&
            <div className="mt-5">
                <div className="d-flex justify-content-between">
                    <h3 className="a-text--fz-16 a-text--bold a-text--color-black">Đơn hàng của giao dịch</h3>
                    {/*<a href="#" className="a-text--color-primary" onClick={this.showModalEidtCode}><Icon type={"edit"} className="mr-1" />Chỉnh sửa mã hoá đơn gốc</a>*/}
                </div>
                {/*<Row gutter={{lg: 24, xl: 32}} className="mt-2">*/}
                {/*    <Col md={12} lg={8} xl={9} xxl={7}>*/}
                {/*       <Input placeholder="Nhập mã hoá đơn gốc để khớp với giao dịch"/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {
                    orders.map(order => {
                        return (
                            <OrderTransaction status={'default'} collapse ={collapse} order={order}/>
                        )
                    })
                }
                <Modal
                    width={360}
                    title=" MÃ ĐƠN GỐC"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={false}
                    centered={true}
                    className={"a-modal--list-trade"}
                >
                    <p>Mã đơn gốc </p>
                    <ul className="mb-5 mt-3">
                        <li className="d-flex justify-content-between mb-2">
                            <span className={"a-text--bold"}>A183949459</span>
                            <Popconfirm
                                title="Bạn có chắc chắn muốn xoá mã đơn gốc"
                                okText="Đồng ý"
                                cancelText="Huỷ bỏ"
                            >
                                <a href="javascript:;" className="a-text--green-new">Xoá mã</a>
                            </Popconfirm>
                        </li>
                        <li className="d-flex justify-content-between mb-2">
                            <span className={"a-text--bold"}>A183949459</span>
                            <Popconfirm
                                title="Bạn có chắc chắn muốn xoá mã đơn gốc"
                                okText="Đồng ý"
                                cancelText="Huỷ bỏ"
                            >
                                <a href="javascript:;" className="a-text--green-new">Xoá mã</a>
                            </Popconfirm>
                        </li>
                    </ul>
                    <span className="mt-3">Thêm mã đơn gốc</span>
                    <Input placeholder="Nhập mã đơn gốc" className="mt-1"/>
                    <div className={"amt-24"}>
                        <Button className=" a-font--12 a-btn--primary-ant aphz-24">Thêm</Button>
                        <Button className=" a-font--12 a-btn--primary-ant ghost ml-3">Huỷ</Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default ListOrderTransaction;
