import React from 'react'
import { translate } from 'react-i18next'
import YuanInput from '../../../common/components/Input/YuanInput'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { Form } from 'antd'
import notification from '../../../../systems/notification'
import { t } from '../../../../systems/i18n'

class ChangePaymentInput extends React.Component {
    constructor(prop) {
        super(prop)
        this.state = {
            isEdit: false,
            id: 0,
            amount: 0,
            isError: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = { ...prevState }
        let { dataOrder, label } = nextProps
        if (!lodash.isNil(dataOrder.id) && dataOrder.id !== prevState.id) {
            nextState.id = dataOrder.id
            nextState.amount = parseFloat(dataOrder[label])
        }

        return nextState
    }

    onChangePayment(event) {
        let { target } = event
        this.setState({ [target.name]: parseFloat(target.value) })
    }

    handSubmitChangePayment(event) {
        let { target } = event
        let { dataOrder, label } = this.props
        let value = lodash.toFinite(target.value.trim())

        if (target.value === "") {
            this.setState({ isError: true })
            return
        } else {
            this.setState({ isError: false })
        }

        if (event.key === 'Enter' && (lodash.toFinite(dataOrder[label]) !== value || dataOrder[label] === null)) {
            this.props.handleUpdate({ id: dataOrder.id, amount: value })
            this.setState({
                isEdit: false,
            })
        }
    }

    render() {
        let { isLoading, t, label, disableUpdateRealPaymentAmount } = this.props
        let { amount, isEdit, isError } = this.state

        return (
            <div className="d-flex flex-column a-purchasod__group--code--input">
                <span className="a-purchasod__group--label a-purchasod__group--label--invoice">{t('newLocals:order.label.' + label)}</span>
                <span>
                    <Form.Item
                        validateStatus={isError ? 'error' : undefined}
                        help={isError ? t('orderDetail:order_detail.error.required', { name: t(`orderDetail:label.${label}`) }) : ''}
                    >
                        <YuanInput
                            unit_price={true}
                            value={amount}
                            placeholder={t('newLocals:order.placeholder.enter_amount')}
                            disabled={isLoading || disableUpdateRealPaymentAmount}
                            numberNotZero={true}
                            onKeyUp={this.onChangePayment.bind(this)}
                            name="amount"
                            onKeyPress={this.handSubmitChangePayment.bind(this)}
                        />
                    </Form.Item>
                </span>
            </div>
        )
    }
}

ChangePaymentInput.defaultProps = {
    dataOrder: {},
    label: '',
    isLoading: false,
}

ChangePaymentInput.protoType = {
    dataOrder: PropTypes.object,
    isLoading: PropTypes.bool,
    handleUpdate: PropTypes.func,
    label: PropTypes.string,
}

export default translate('translations')(ChangePaymentInput)
