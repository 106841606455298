import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import Tooltip from '@atlaskit/tooltip';
import onClickOutside from "react-onclickoutside";
import ShippingFee from "./Fee/ShippingFee";
import Link from "../../../../systems/routing/Link";
import {currentFormatter} from './../../../common/services/format';
import lodash from "lodash";
import authService from '../../../Auth/authService';
import { SELF_NEGOTIATE_UPDATE_PRODUCT } from '../../../Permission/permissions';

class TotalItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            shippingFeeHistory: [],
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (JSON.stringify(prevState.shippingFeeHistory) !== JSON.stringify(nextProps.shippingFeeHistory) && prevState.isOpen === true) {
            nextState.shippingFeeHistory = nextProps.shippingFeeHistory;
        }

        return nextState;
    }

    handleOpen(event) {
        event.preventDefault();
        let {order} = this.props;
        this.setState({isOpen: !this.state.isOpen});
        if (this.state.shippingFeeHistory.length === 0) {
            this.props.getShippingFeeHistory({id: order.id});
        }
    }

    handleClickOutside = evt => {
        this.setState({
            isOpen: false
        })
    };

    render() {
        let {t, order, onUpdateShippingFee} = this.props;
        let currencySymbol = lodash.get(order, "currencySymbol",  '¥');
        let shippingFeeHistory = this.props.shippingFeeHistory;
        let {isOpen} = this.state;
        const orderDetail = lodash.get(order, "orderDetail", {})
        const self_negotiate = lodash.get(orderDetail, "self_negotiate", false)

        return (
            <React.Fragment>
                <div className="a-purchasod__total">

                    <div className="d-flex a-purchasod__total__detail justify-content-between">

                        <div className="a-purchasod__group__shipping_fee">
                            <span className={'a-purchasod__group__shipping_fee__title d-flex a--text-bold'}>{t("order_detail.total_shipping_fee_inland") + "   "}
                                <Tooltip content={t('order_detail.order_free')}>
                                    <i className="fas fa-info-circle ml-2"/>
                                </Tooltip>
                            </span>

                            <div className="shipping_fee_history">
                                <span className="seperate-v">
                                    <a href="" onClick={this.handleOpen.bind(this)}>{t('order_detail.history_fee')}<i
                                    className="fas fa-history"/></a>
                                </span>
                                <div className={`shipping_fee_history__detail ${isOpen ? 'active' : ''}`}>
                                    <div className="d-flex align-items-center mb-2">
                                        <p className="a-text--bold pr-1">{t('order_detail.shop')}:</p>
                                        <img
                                            className="mr-2 img-shop"
                                            src={"http://nhaphang.com/vp-assets/images/favicons_" + order.seller_source + ".png"}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = require('../resource/u32.png')
                                            }}
                                            alt="Avatar"/>
                                        <span>{order.seller_name}</span>
                                    </div>
                                    <span
                                        className="a-font--10 a-text--red mb-1">{t('order_detail.shiping_fee_note')}</span>
                                    <ul className="w-100 mt-2">

                                        {
                                            shippingFeeHistory && shippingFeeHistory.length ? shippingFeeHistory.map((shippingFee) => {
                                                let total_shipping_fee_inland_format = shippingFee.total_shipping_fee_inland ? currencySymbol + currentFormatter.toLocaleStringCurrency(parseFloat(shippingFee.total_shipping_fee_inland)) : '';
                                                return (
                                                    <li className="d-flex justify-content-between">
                                                        <Link isNewTab={true}
                                                              to="purchase.buying.detail"
                                                              params={{id: shippingFee.id}}
                                                              className="a-text--font-blue a-text--bold">
                                                            {shippingFee.code}
                                                        </Link>
                                                        <span
                                                            className="a-text--font-primary a-text--bold">{!shippingFee.free_ship && total_shipping_fee_inland_format ? total_shipping_fee_inland_format : t('order_detail.free')}</span>
                                                    </li>
                                                )
                                            }) : null
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="a-purchasod__group__shipping_fee d-flex justify-content-end h-100">
                            {order.canChangeShippingFee && ((!!self_negotiate && authService.can(SELF_NEGOTIATE_UPDATE_PRODUCT)) || !self_negotiate) && <ShippingFee
                                onUpdateShippingFee={onUpdateShippingFee}
                                order={order}
                            />}
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

TotalItem.defaultProps = {
    order: {},
    shippingFeeHistory: [],
};

TotalItem.propTypes = {
    order: PropTypes.object,
    shippingFeeHistory: PropTypes.array,
    getShippingFeeHistory: PropTypes.func
};

export default translate('translations')(onClickOutside(TotalItem));
