import React from "react";
import {Row, Col, Icon, notification, Modal} from "antd";
import {translate} from "react-i18next";
import AddTransaction from "./AddTransaction";
import {currentFormatter} from "../../../common/services/format";
import lodash from "lodash";
import {processResponse} from "../../../../systems/api";
import apiService from "../../apiService";
import Link from "../../../../systems/routing/Link";

const { confirm } = Modal;

class ListTransactions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            transactionList: []
        }
    }

    componentDidMount() {
        let {complaint} = this.props;
        this.setState({transactionList: complaint.transactionCodes});
    }

    pushTransaction = (transaction) => {
        let {transactionList} = this.state;
        transactionList.push(transaction);
        this.setState({transactionList});
    }

    deleteTransaction = (transaction_id) => {
        confirm({
            title: 'Bạn chắc chắn muốn xóa giao dịch này?',
            icon: <Icon type="exclamation-circle" />,
            content: 'Click vào nút Xác Nhận để xóa giao dịch.',
            onOk: () => {
                let {transactionList} = this.state;
                let process = processResponse(apiService.deleteComplaintTransaction(transaction_id));
                process.then(res => {
                    notification.success({message: "Xóa giao dịch thành công"});
                    this.setState({transactionList: transactionList.filter(transaction => transaction.id !== transaction_id)})
                });
                process.catch(error => {
                    notification.error({message: "Xóa giao dịch không thành công"});
                });
            },
            okText: "Xác nhận",
            cancelText: "Hủy",
            onCancel() {},
        });
    }

    render() {
        let {complaint, permissions, order, addTransactionCode} = this.props;
        let {transactionList} = this.state;
        let total = 0;

        return (
            <div className="complaint-seller-box a-content--box-shadow p-0 h-100">
                <div className="cs-box-title d-flex align-item-center p-3 pt-2 pb-2 a-text--bold">
                    <p className="a-text--fz-14">DANH SÁCH GIAO DỊCH</p>
                    {
                        permissions.can_add_transaction == 1 &&
                        <AddTransaction
                            complaint={complaint}
                            pushTransaction={this.pushTransaction}
                            order={order}
                            addTransactionCode={addTransactionCode}
                        />
                    }
                </div>
                <div className="a-text--fz-12">
                    <div className="cs-box-list-col p-3 pt-2 pb-2 a-text--bold">
                        <Row gutter={10}>
                            <Col xs={2}>STT</Col>
                            <Col xs={15}>Mã giao dịch</Col>
                            <Col xs={7} className="a-text--right">Số tiền</Col>
                        </Row>
                    </div>
                    <div className="cs-box-list-row p-3 pt-0 pb-0">
                        {
                            transactionList.length > 0 &&
                            transactionList.map((transaction, index) => {
                                let transaction_amount = lodash.get(transaction, "transaction_amount", 0);
                                let id_transaction = lodash.get(transaction, "id_transaction", 0);
                                total += transaction_amount;

                                return (
                                    <Row gutter={10} className={`pt-2 pb-2 ${index > 0 ? 'border-top' : ''}`}>
                                        <Col xs={2} className="a-text--center">{index+1}</Col>
                                        <Col xs={16}>
                                            {
                                                id_transaction === 0 ?
                                                    <span style={{wordBreak: "break-word"}}>{transaction.transaction_code}</span> :
                                                    <Link
                                                        isNewTab={true}
                                                        style={{wordBreak: "break-word"}}
                                                        to="transaction.detail"
                                                        params={{id: id_transaction}}>
                                                        {transaction.transaction_code}
                                                    </Link>
                                            }
                                            {
                                                transaction.wrong_order === 1 ? <span className="a-text--light-red"> [Nhầm đơn]</span> : ""
                                            }
                                            <span
                                                className="ml-1 a-text--fz-11 a-text--light-red a--cursor-poiter"
                                                onClick={e => this.deleteTransaction(transaction.id)}
                                            >
                                                <Icon type="close"/>
                                            </span>
                                        </Col>
                                        <Col xs={6} className="a-text--right">{transaction_amount > 0 ? '¥' + currentFormatter.toLocaleStringCurrency(transaction_amount, 5) : '---'}</Col>
                                    </Row>
                                );
                            })
                        }
                        {
                            transactionList.length > 0 &&
                            <Row gutter={10} className={`pt-2 pb-2 border-top`}>
                                <Col xs={18} className="a-text--right a-text--bold">Tổng giá trị:</Col>
                                <Col xs={6} className="a-text--right">{total > 0 ? '¥' + currentFormatter.toLocaleStringCurrency(total, 5) : '---'}</Col>
                            </Row>
                        }
                    </div>
                    <div className={`a-text--center a-text--bold p-2 ${transactionList.length > 0 ? 'border-top' : ''}`}>
                        {
                            complaint.financial_valid ?
                                <p className="a-text--color-green">Tài chính hợp lệ</p> :
                                <p className="a-text--light-red">Tài chính không hợp lệ</p>
                        }
                    </div>
                </div>
            </div>
        );
    }

}

export default translate()(ListTransactions);