import {combineReducers} from 'redux';
import * as PurchaseAddressConstant from './constants'
import lodash from "lodash";

/**
 * List purchase address
 *
 * @param state
 * @param action
 * @returns {*}
 */
const list = (state = [], action) => {
    const {payload} = action;
    let params = lodash.get(payload, 'params', {});
    switch (action.type) {
        case PurchaseAddressConstant.PURCHASE_ADDRESS.SUCCESS:
            return action.response.data.data;
        case PurchaseAddressConstant.PURCHASE_ADDRESS_CREATE.SUCCESS:
            let purchaseAddress = lodash.get(payload, 'data.purchaseAddress', {});
            let stateClone = state;
            if (!lodash.isEmpty(purchaseAddress)) {
                stateClone.unshift(purchaseAddress);
            }
            return stateClone;

        case PurchaseAddressConstant.PURCHASE_ADDRESS_UPDATE.SUCCESS:
            let dataDetail = lodash.get(payload, 'data.purchaseAddress', {});
            state = state.map(stateItem=> {
                if (stateItem.id === dataDetail.id) {
                    return dataDetail;
                }
                if( dataDetail.default ){
                    return {...stateItem, ...{default: 0}}
                }
                return stateItem
            });
            return state;

        case PurchaseAddressConstant.UPDATE_STATUS_ADDRESS_PURCHASE.REQUEST:
            state = state.map(stateItem=> {
                if (stateItem.id === params.id) {
                    return params;
                }
                return stateItem
            });
            return state;
        case PurchaseAddressConstant.UPDATE_STATUS_ADDRESS_PURCHASE.FAILED:
            state = state.map(stateItem=> {
                if (stateItem.id === payload.id) {
                    return {...payload, ...{status: payload.status ? 0 : 1}};
                }
                return stateItem
            });
            return state;

        default:
            return state;
    }
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
const createErrors = (state = [], action) => {
    const {payload} = action;

    switch (action.type) {
        case PurchaseAddressConstant.PURCHASE_ADDRESS_CREATE.REQUEST:
        case PurchaseAddressConstant.PURCHASE_ADDRESS_CREATE.SUCCESS:
        case PurchaseAddressConstant.PURCHASE_ADDRESS_UPDATE.REQUEST:
        case PurchaseAddressConstant.PURCHASE_ADDRESS_UPDATE.SUCCESS:
        case PurchaseAddressConstant.PURCHASE_ADDRESS_DETAIL.CLEAR:
        case PurchaseAddressConstant.PURCHASE_ADDRESS.REQUEST:
            return [];
        case PurchaseAddressConstant.PURCHASE_ADDRESS_CREATE.FAILED:
        case PurchaseAddressConstant.PURCHASE_ADDRESS_UPDATE.FAILED:
            return lodash.get(payload, 'data', {});
        case PurchaseAddressConstant.RESET_ERROR_INPUT_PURCHASE_ADDRESS.REQUEST:
            let params = lodash.get(payload, 'params', {});
            let purchaseAddressError = state;
            if ( lodash.has(purchaseAddressError, 'address')
                && lodash.get(params, 'address', '').length > 0)
            {
                purchaseAddressError = lodash.omit(purchaseAddressError, 'address');
            }
            return purchaseAddressError;
        default:
            return state;
    }
};

/**
 * Detail purchase address
 * @param state
 * @param action
 * @returns {*}
 */
const detail = (state = {status: 1, default: 1}, action) => {
    let {payload} = action || {};
    let params = lodash.get(payload, 'params', {});
    switch (action.type) {
        case PurchaseAddressConstant.PURCHASE_ADDRESS_DETAIL.SUCCESS:
        case PurchaseAddressConstant.PURCHASE_ADDRESS_CREATE.SUCCESS:
            return action.payload.data.purchaseAddress;
        case PurchaseAddressConstant.PURCHASE_ADDRESS_DETAIL.CLEAR:
        case PurchaseAddressConstant.PURCHASE_ADDRESS.REQUEST:
            return {status: 1, default: 0};
        case PurchaseAddressConstant.UPDATE_STATUS_ADDRESS_PURCHASE.REQUEST:
            if (!lodash.isEmpty(state) && params.id === state.id) {
                return params;
            }
            return {status: 1, default: 0};
        case PurchaseAddressConstant.UPDATE_STATUS_ADDRESS_PURCHASE.FAILED:
            if (!lodash.isEmpty(state)) {
                return {...payload, ...{status: payload.status ? 0 : 1}}
            }
            return state ;
        case PurchaseAddressConstant.PURCHASE_ADDRESS_UPDATE.SUCCESS:
            params = lodash.get(payload, 'data.purchaseAddress', {});
            if (!lodash.isEmpty(params)) {
                return {
                    ...state,
                    ...{status: params.status, default: params.default, address: params.address, alias: params.alias}
                }
            }
            return state;
        default:
            return state;
    }
};

export default combineReducers({
    list, createErrors, detail
})
