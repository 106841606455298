import {handleActions} from 'redux-actions';
import * as Constants from './constants';

export default handleActions({
        [Constants.GET_LIST_POST_DEMO.SUCCESS]: (prevState, action) => {
            let {data} = action.payload;
            return {
                ...prevState,
                list: data,
            };
        },

    },
    {
        list: [],
    });