import { combineReducers } from 'redux'
import * as Constant from './constants'
import { ACCEPT } from './../../common/Modal/constants'
import * as LogItemConstant from './../../LogItem/constants'
import lodash from 'lodash'
import { currentFormatter } from './../../common/services/format'
import { updateCollectionItem } from './../../common/services/helps'
import moment from 'moment'
import { dateFormatter } from '../../common/services/format'
import { ORDER_STATUS } from './../../../systems/globalContant/actionContant'
import { UPDATE_REAL_PAYMENT_AMOUNT_ORDER, UPDATE_REAL_REFUND_AMOUNT_ORDER } from './../UpdateFinanliOrder/constants'
import { UPDATE_WRONG_PROCESS_ORDER } from './../UpdateWrongProcess/constants'
import * as GlobalConstants from '../../../systems/globalContant'

const order = (state = {}, action) => {
    let params = lodash.get(action, 'payload.params', {})
    let data = lodash.get(action, 'payload.data', {})
    let orderItems = {}
    let items = []
    let item_ids = lodash.get(action, 'payload.item_ids', [])
    let changePurchaseAddress = lodash.get(action, 'payload.data.changePurchaseAddress', false)
    switch (action.type) {
        case UPDATE_REAL_PAYMENT_AMOUNT_ORDER.SUCCESS:
            return { ...state, real_payment_amount: lodash.get(action, 'payload.data.real_payment_amount', 0) }
        case UPDATE_REAL_REFUND_AMOUNT_ORDER.SUCCESS:
            return { ...state, real_refund_amount: lodash.get(action, 'payload.data.real_refund_amount', 0) }
        case UPDATE_WRONG_PROCESS_ORDER.SUCCESS:
            return {
                ...state,
                wrong_process: lodash.get(data, 'wrong_process', 0),
                rate_deposit: lodash.get(data, 'rate_deposit', 0) || 0,
            }
        case Constant.FETCH_PURCHASING_ORDER_DETAIL.SUCCESS:
            const orders = lodash.get(action, 'payload.data.orders', {})
            const servicesWarning = lodash.get(action, 'payload.servicesWarning', {})
            const propertiesWarning = lodash.get(action, 'payload.propertiesWarning', {})
            const isOpenUpdateBatchOrder = lodash.get(action, 'payload.isOpenUpdateBatchOrder', false)
            if (!lodash.isEmpty(servicesWarning) || !lodash.isEmpty(propertiesWarning)) {
                return { ...orders, servicesWarning, propertiesWarning, isOpenUpdateBatchOrder }
            }
            return {...orders, isOpenUpdateBatchOrder}
        case Constant.UPDATE_ORDER_SERVICE.FAILED:
            return { ...state, ...{ _i: new Date() } }
        case Constant.UPDATE_AFFILIATE_LINK_ORDER_DETAIL:
            let { items } = state
            let item = lodash.get(action, 'payload.data', {})
            return { ...state, items: updateCollectionItem(items, item.id, item) }
        case Constant.UPDATE_ORDER_SERVICE.UNCHECK:
            let newOrderServices = [...lodash.get(state, 'services', [])].filter(service => service.service_id !== action.payload.service_id)
            return { ...state, ...{ services: newOrderServices } }
        case Constant.UPDATE_ORDER_SERVICE.SUCCESS:
        case Constant.APPROVAL_ORDER_SERVICE.SUCCESS:
            let services = lodash.get(action, 'payload.data.services', {})
            let newOrder = lodash.merge({}, state)
            newOrder.services = services
            newOrder.changePurchaseAddress = changePurchaseAddress ? 'service' : false
            return { ...state, ...newOrder }
        case Constant.UPDATE_ORDER_ACCOUNT_PURCHASER.SUCCESS:
            let orderUpdateAccountPurchaser = lodash.get(action, 'payload.data.order', {})
            return {
                ...state,
                ...{
                    id_purchaser: lodash.get(orderUpdateAccountPurchaser, 'id_purchaser', null),
                    purchaser: lodash.get(orderUpdateAccountPurchaser, 'purchaser', null),
                },
            }
        case Constant.UPDATE_PAID_ORDER_DETAIL.REQUEST:
            items = lodash.get(state, 'items', [])
            orderItems = items.map(orderItem => {
                if (orderItem.id === params.id) {
                    return params
                }
                return orderItem
            })
            state = { ...state, ...{ items: orderItems } }
            return state
        case Constant.CHANGE_MUTIPLE_QUANTITY.SUCCESS:
            data = lodash.get(action, 'payload.data', {})
            items = lodash.get(state, 'items', [])
            orderItems = items.map(orderItem => {
                if (orderItem.id === data.id) {
                    return { ...orderItem, ...{ qty_bought: data.qty_bought } }
                }
                return orderItem
            })
            state = {
                ...state,
                ...{
                    items: orderItems,
                    note_buyer_confirm: lodash.get(data, 'order.note_buyer_confirm', []),
                    grand_total_bought: lodash.get(data, 'order.grand_total_bought', 0),
                    grand_total: lodash.get(data, 'order.grand_total', 0),
                    total_item_qty_bought: lodash.get(data, 'order.total_item_qty_bought', 0),
                },
            }
            return state
        case Constant.UPDATE_OUT_STOCK_ITEMS.SUCCESS:
            item_ids = lodash.get(action, 'payload.item_ids', [])
            items = lodash.get(state, 'items', [])
            let itemsClone = items.map(orderItem => {
                if (item_ids.includes(orderItem.id)) {
                    return { ...orderItem, ...{ qty_bought: data.qty_bought } }
                }
                return orderItem
            })
            state = {
                ...state,
                ...{
                    items: itemsClone,
                    buyer_confirm: lodash.get(action, 'payload.order.buyer_confirm', ''),
                    note_buyer_confirm: lodash.get(action, 'payload.order.note_buyer_confirm', []),
                    grand_total_bought: lodash.get(action, 'payload.order.grand_total_bought', 0),
                    grand_total: lodash.get(action, 'payload.order.grand_total', 0),
                    total_item_qty_bought: lodash.get(action, 'payload.order.total_item_qty_bought', 0),
                },
            }
            return state
        case Constant.UPDATE_PRICE_ITEMS.SUCCESS:
            item_ids = lodash.get(action, 'payload.item_ids', [])
            let itemPrices = lodash.get(action, 'payload.order.items', [])
            let itemsCurrentPrice = lodash.get(state, 'items', [])
            let itemsCloneUpdatePrice = itemsCurrentPrice.map(orderItem => {
                if (item_ids.includes(orderItem.id)) {
                    let oneItemPrice = itemPrices.filter(itemsProduct => itemsProduct.id === orderItem.id)
                    oneItemPrice = lodash.head(oneItemPrice)
                    if (!lodash.isEmpty(oneItemPrice)) {
                        return { ...orderItem, ...{ unit_price: lodash.get(oneItemPrice, 'unit_price', 0) } }
                    }
                    return orderItem
                }
                return orderItem
            })
            state = {
                ...state,
                ...{
                    items: itemsCloneUpdatePrice,
                    buyer_confirm: lodash.get(action, 'payload.order.buyer_confirm', ''),
                    note_buyer_confirm: lodash.get(action, 'payload.order.note_buyer_confirm', []),
                    grand_total_bought: lodash.get(action, 'payload.order.grand_total_bought', 0),
                    grand_total: lodash.get(action, 'payload.order.grand_total', 0),
                    total_item_qty_bought: lodash.get(action, 'payload.order.total_item_qty_bought', 0),
                },
            }
            return state
        case Constant.UPDATE_QUANTITY_ITEMS.SUCCESS:
            item_ids = lodash.get(action, 'payload.item_ids', [])
            let item_list = lodash.get(action, 'payload.order.items', [])
            let itemsCurrent = lodash.get(state, 'items', [])
            let itemsCloneUpdateQuantity = itemsCurrent.map(orderItem => {
                if (item_ids.includes(orderItem.id)) {
                    let oneItem = item_list.filter(itemsProduct => itemsProduct.id === orderItem.id)
                    oneItem = lodash.head(oneItem)
                    if (!lodash.isEmpty(oneItem)) {
                        return { ...orderItem, ...{ qty_bought: lodash.get(oneItem, 'qty_bought', 0) } }
                    }
                    return orderItem
                }
                return orderItem
            })
            state = {
                ...state,
                ...{
                    items: itemsCloneUpdateQuantity,
                    buyer_confirm: lodash.get(action, 'payload.order.buyer_confirm', ''),
                    note_buyer_confirm: lodash.get(action, 'payload.order.note_buyer_confirm', []),
                    grand_total_bought: lodash.get(action, 'payload.order.grand_total_bought', 0),
                    grand_total: lodash.get(action, 'payload.order.grand_total', 0),
                    total_item_qty_bought: lodash.get(action, 'payload.order.total_item_qty_bought', 0),
                },
            }
            return state
        case Constant.CONFIRM_CUSTOMER_ORDER_BUYING.SUCCESS:
            data = lodash.get(action, 'payload.data', {})
            items = lodash.get(state, 'items', [])
            orderItems = items.map(orderItem => {
                if (orderItem.id === data.id) {
                    return { ...orderItem, ...{ qty_bought: data.qty_bought } }
                }
                return orderItem
            })
            state = {
                ...state,
                ...{
                    items: orderItems,
                    buyer_confirm: null,
                    note_buyer_confirm: lodash.get(data, 'order.note_buyer_confirm', []),
                },
            }
            return state
        case Constant.CONFIRM_CUSTOMER_ORDER_BUYING.WARNING:
            data = lodash.get(action, 'payload.data', {})
            items = lodash.get(state, 'items', [])
            orderItems = items.map(orderItem => {
                if (orderItem.id === data.id) {
                    return { ...orderItem, ...{ qty_bought: data.qty_bought } }
                }
                return orderItem
            })
            return {
                ...state,
                ...{
                    items: orderItems,
                    note_buyer_confirm: lodash.get(data, 'order.note_buyer_confirm', []),
                },
            }
        case Constant.UPDATE_SHIPPING_FEE.SUCCESS:
            let orderUpdateShippingFee = lodash.get(action, 'payload.data.order', {})
            return {
                ...state,
                ...{
                    grand_total_bought: lodash.get(orderUpdateShippingFee, 'grand_total_bought', 0),
                    grand_total: lodash.get(orderUpdateShippingFee, 'grand_total', 0),
                    total_item_qty_bought: lodash.get(orderUpdateShippingFee, 'total_item_qty_bought', 0),
                    total_shipping_fee_inland: orderUpdateShippingFee.total_shipping_fee_inland,
                    free_ship: orderUpdateShippingFee.free_ship,
                },
            }
        case Constant.UPDATE_TOTAL_DISCOUNT.SUCCESS:
            let orderUpdateTotalDiscount = lodash.get(action, 'payload.data.order', {})
            return {
                ...state,
                ...{
                    discount_amount: orderUpdateTotalDiscount.discount_amount,
                    grand_total_bought: lodash.get(orderUpdateTotalDiscount, 'grand_total_bought', 0),
                    grand_total: lodash.get(orderUpdateTotalDiscount, 'grand_total', 0),
                },
            }
        case Constant.UPDATE_PAID_ORDER_DETAIL.FAILED:
            items = lodash.get(state, 'items', [])
            params = lodash.get(action, 'payload', {})
            params = { ...params, ...{ is_pai: !params.is_pai } }
            orderItems = items.map(orderItem => {
                if (orderItem.id === params.id) {
                    return params
                }
                return orderItem
            })
            state = { ...state, ...{ items: orderItems } }
            return state
        case Constant.UPDATE_PAID_ORDER_DETAIL.SUCCESS: {
            let item = action.payload.data
            let orderItem = lodash.get(action, 'payload.data.order', {})
            return {
                ...state,
                status: orderItem.status,
                items: updateCollectionItem(state.items || [], item.id, lodash.pick(item, ['is_pai', 'time_pai'])),
            }
        }
        case LogItemConstant.GET_PUSHER_COMMENT_ITEM:
            let newState = { ...state }
            items = lodash.get(newState, 'items', [])
            items.map(item => {
                if (parseInt(lodash.get(action, 'payload.payload.order_item.id'), 10) === item.id) {
                    if (item.note === null) {
                        item.note = []
                    }

                    item.note = [
                        {
                            content: lodash.get(action, 'payload.message', ''),
                            name: lodash.get(action, 'payload.creator.name', ''),
                            username: lodash.get(action, 'payload.creator.username', ''),
                            time: dateFormatter.local(moment.unix(lodash.get(action, 'payload.time', ''))),
                            type: parseInt(lodash.get(action, 'payload.creator.is_admin', 1), 10) === 1 ? 'user' : 'customer',
                        },
                        ...item.note,
                    ]
                }
                return item
            })
            return newState
        case LogItemConstant.CHANGE_ORDER_ITEM_QUANTITY:
            return {
                ...state,
                ...{
                    buyer_confirm: false,
                },
            }
        case Constant.POST_INVOICE_CODE.SUCCESS:
            let newStateForInvoiceCode = { ...state }
            newStateForInvoiceCode.invoiceCodes = lodash.get(state, 'invoiceCodes', [])
            newStateForInvoiceCode.invoiceCodes = [
                ...newStateForInvoiceCode.invoiceCodes,
                {
                    ...lodash.get(action, 'payload.data.orderSourceInvoice', {}),
                    is_deleted: 0,
                },
            ]
            newStateForInvoiceCode.isExitedInOtherParner = lodash.get(action, 'payload.data.isExitedInOtherParner', false)
            return newStateForInvoiceCode
        case Constant.DELETE_INVOICE_CODE.SUCCESS:
            let newState2DeleteInvoiceCode = { ...state }
            let invoiceCodes = lodash.get(state, 'invoiceCodes', [])
            let invoiceCodeIsDeleted = lodash.get(action, 'payload.data.orderSourceInvoice', { id: 0 })
            lodash.remove(invoiceCodes, function (invoiceCode) {
                return invoiceCode.id === invoiceCodeIsDeleted.id
            })
            newState2DeleteInvoiceCode.invoiceCodes = [...invoiceCodes]
            return newState2DeleteInvoiceCode
        // add freight bill
        case Constant.SYNC_ORDER_BILL_NO.SUCCESS:
            newState = lodash.merge({}, state)
            let newFreightBills = lodash.get(action, 'payload.data.orderFreightBill', [])
            newState.status = lodash.get(action, 'payload.data.order.status', null) || newState.status
            if (newState.freightBills) {
                newState.freightBills.map(freightBill => {
                    if (newFreightBills.filter(item => item.id === freightBill.id).length === 0) {
                        newFreightBills.push(freightBill)
                    }
                    return freightBill
                })
            }
            newState.isSyncBillNo = newState.isSyncBillNo ? newState.isSyncBillNo + 1 : 1
            newState.freightBills = newFreightBills
            return newState
        case Constant.SYNC_ORDER_BILL_NO.FAILED:
        case Constant.SYNC_ORDER_BILL_NO.ERROR:
            newState = lodash.merge({}, state)
            newState.isSyncBillNo = newState.isSyncBillNo ? newState.isSyncBillNo + 1 : 1
            return newState
        case Constant.ADD_FREIGHT_BILL.SUCCESS:
            newState = { ...state }
            let orderAddFreightBill = lodash.get(action, 'payload.data.order', {})

            newState.freightBills = [
                {
                    ...lodash.get(action, 'payload.data.orderFreightBill', {}),
                    is_deleted: 0,
                },
                ...newState.freightBills,
            ]
            newState.status = orderAddFreightBill.status

            if (orderAddFreightBill && orderAddFreightBill.canCreatePaymentRequest)
                newState.canCreatePaymentRequest = orderAddFreightBill.canCreatePaymentRequest
            else newState.canCreatePaymentRequest = false

            return newState
        // delete freight bill
        case Constant.DELETE_FREIGHT_BILL.SUCCESS:
            newState = lodash.merge({}, state)
            let freightBills = [...newState.freightBills]
            let freightBillList = []
            let freightBillDeleted = lodash.get(action, 'payload.data.orderFreightBill', { id: 0 })
            freightBillList = freightBills.filter(freightBill => {
                return freightBill.id !== freightBillDeleted.id
            })
            newState.freightBills = freightBillList
            return newState
        case Constant.UPDATE_ORDER_ITEM_PRICE.SUCCESS:
            let updatePriceState = { ...state }
            items = lodash.get(updatePriceState, 'items', [])
            items.map(item => {
                if (parseInt(lodash.get(action, 'payload.data.id', -1), 10) === item.id) {
                    item.unit_price = parseFloat(lodash.get(action, 'payload.data.unit_price', -1))
                    item.unit_price_text = '¥' + currentFormatter.toLocaleStringCurrency(item.unit_price, 5)
                }
                return item
            })
            return {
                ...updatePriceState,
                note_buyer_confirm: lodash.get(action, 'payload.data.order.note_buyer_confirm', []),
                grand_total_bought: lodash.get(action, 'payload.data.order.grand_total_bought', 0),
                grand_total: lodash.get(action, 'payload.data.order.grand_total', 0),
                total_item_qty_bought: lodash.get(action, 'payload.data.order.total_item_qty_bought', 0),
            }
        case Constant.POST_INVOICE_CODE.REQUEST:
        case Constant.DELETE_INVOICE_CODE.REQUEST:
            return { ...state, isExitedInOtherParner: false }
        case Constant.UPDATE_ORDER_GROUP_ORDER.SUCCESS:
            return {
                ...state,
                order_group_id: lodash.get(action, 'payload.data.order.order_group_id', 0),
                order_group_info: lodash.get(action, 'payload.data.order.order_group_info', ''),
                orderGroup: lodash.get(action, 'payload.data.orderGroup', ''),
            }
        case Constant.SORT_ORDER_ITEMS: {
            let { direction } = action.payload
            let items = lodash.get(state, 'items') || []
            let field = direction ? 'time_pai' : 'id'

            let sortedItems = lodash.sortBy(items, item => {
                if (field === 'time_pai') {
                    return item.is_pai ? item.time_pai || '' : ''
                }

                return item.id
            })

            return {
                ...state,
                items: direction === 'desc' ? sortedItems.reverse() : sortedItems,
            }
        }
        case Constant.CANCEL_ORDER.SUCCESS:
            return {
                ...state,
                //invoiceCodes: [],
                canCancel: false,
                canChangeAccountPurchaser: false,
                // canChangeShippingFee: false,
                canAddInvoiceCodes: false,
                canRemoveInvoiceCodes: false,
                canChangeFreightBill: false,
                canChangePay: false,
                canChangeService: false,
                canChangeQuantity: false,
                canChangePrice: false,
                canChangePaid: false,
                canUpdateOutOfStock: false,
                canChangeOrderGroup: false,
                canAddFreightBill: false,
                canRemoveFreightBill: false,
                status: ORDER_STATUS.CANCELLED,
            }
        case Constant.OUT_OF_STOCK_ORDER.SUCCESS:
            return {
                ...state,
                canCancel: false,
                canChangeAccountPurchaser: false,
                // canChangeShippingFee: false,
                canChangeInvoiceCodes: false,
                canChangeFreightBill: false,
                canChangePay: false,
                canChangeService: false,
                canChangeQuantity: false,
                canChangePrice: false,
                canChangePaid: false,
                canUpdateOutOfStock: false,
                status: ORDER_STATUS.OUT_OF_STOCK,
            }
        case Constant.CHANGE_RATE_DEPOSIT.SUCCESS:
            newState = lodash.merge({}, state)
            let orderChangeRateDeposit = lodash.get(action, 'payload.data.order', {})
            return {
                ...newState,
                rate_deposit: lodash.get(orderChangeRateDeposit, 'rate_deposit', 0),
                rate_deposit_backup: lodash.get(orderChangeRateDeposit, 'rate_deposit', 0),
            }
        case Constant.CHANGE_RATE_DEPOSIT.REQUEST:
            newState = lodash.merge({}, state)
            data = lodash.get(action, 'payload', {})
            let rate_deposit = lodash.get(data, 'rate_deposit', 0)
            rate_deposit = parseFloat(rate_deposit)
            if (rate_deposit) {
                rate_deposit = rate_deposit / 100
            }
            return {
                ...newState,
                rate_deposit_backup: lodash.get(newState, 'rate_deposit', 0),
                rate_deposit: rate_deposit,
            }
        case Constant.CHANGE_RATE_DEPOSIT.FAILED:
            newState = lodash.merge({}, state)
            return {
                ...newState,
                rate_deposit: lodash.get(newState, 'rate_deposit_backup', 0),
            }
        case Constant.UPDATE_PAID_ORDER_STATUS.REQUEST:
            return {
                ...state,
                canChangePaid: false,
            }
        case Constant.UPDATE_PAID_ORDER_STATUS.FAILED:
            return {
                ...state,
                canChangePaid: true,
            }
        case Constant.PAYMENT_ORDER.REQUEST:
            return {
                ...state,
                canChangePay: false,
            }
        case Constant.PAYMENT_ORDER.FAILED:
            return {
                ...state,
                canChangePay: true,
            }
        case Constant.CLEAR_ORDER_DETAIL_STATE:
            return {}
        case Constant.UPDATE_AFTER_VOTING_SHOP:
            return {
                ...state,
                seller: { ...data.seller, sellerNote: state.seller.sellerNote },
            }
        case Constant.UPDATE_FRAGILE_ORDER_DETAIL:
            let { orderDetail } = state
            return {
                ...state,
                orderDetail: {
                    ...orderDetail,
                    fragile: lodash.get(action, 'payload.data.orderDetail.fragile', 0),
                },
            }
        case Constant.UPDATE_ITEMS_ORDER_DETAIL:
            return { ...state, items: lodash.get(action, 'payload.data.items', []) }
        case Constant.WAITING_PAYMENT_ORDER.REQUEST:
            return {
                ...state,
                canChangeWaitingPay: false,
            }
        case Constant.WAITING_PAYMENT_ORDER.FAILED:
            return {
                ...state,
                canChangeWaitingPay: true,
            }
        case Constant.UPDATE_ECOMMERCE_SHIPPING_ORDER_DETAIL:
            return {
                ...state,
                orderDetail: {
                    ...state.orderDetail,
                    ecommerce_shipping: lodash.get(action, 'payload.data.orderDetail.ecommerce_shipping', 0),
                },
            }
        case Constant.UPDATE_HSCODE_ORDER_DETAIL:
            return {
                ...state,
                hsCodes: lodash.get(action, 'payload.data.hsCodes', []),
            }
        case Constant.UPDATE_ORDER_FEATURE.SUCCESS:
            let properties = lodash.get(action, 'payload.data.properties', {})
            const canCancelTaobaoPurchaseOrder = lodash.get(action, 'payload.data.canCancelTaobaoPurchaseOrder', false)
            return { ...state, properties: properties, changePurchaseAddress: changePurchaseAddress ? 'feature' : false, canCancelTaobaoPurchaseOrder }
        case Constant.UPDATE_PURCHASE_ADDRESS:
            let purchase_address = lodash.get(action, 'payload.purchase_address', {})
            return { ...state, purchase_address }
        case Constant.UPDATE_AFTER_UDPATE_SHOP_NOTE:
            return {
                ...state,
                seller: {
                    ...state.seller,
                    sellerNote: data.sellerNote,
                },
            }
        default:
            return state
    }
}

const error = (state = {}, action) => {
    switch (action.type) {
        case Constant.FETCH_PURCHASING_ORDER_DETAIL.FAILED:
            return { ...action.payload.data }
        case Constant.UPDATE_PAID_ORDER_STATUS.FAILED:
            return { paidOrder: action.payload.data }
        case Constant.FETCH_PURCHASING_ORDER_DETAIL.REQUEST:
        case ACCEPT:
            return {}
        default:
            return state
    }
}

/**
 * State quản lý việc có hệ thống có đang đợi post request lên server hay không
 * @param state
 * @param action
 * @returns {boolean}
 */
const isLoading = (state = false, action) => {
    switch (action.type) {
        case Constant.FETCH_PURCHASING_ORDER_DETAIL.REQUEST:
        case LogItemConstant.POST_LOG_ITEM.REQUEST:
        case Constant.POST_INVOICE_CODE.REQUEST:
        case Constant.DELETE_INVOICE_CODE.REQUEST:
        case Constant.UPDATE_ORDER_ITEM_PRICE.REQUEST:
        case Constant.UPDATE_OUT_STOCK_ITEMS.REQUEST:
        case Constant.UPDATE_PRICE_ITEMS.REQUEST:
        case Constant.UPDATE_QUANTITY_ITEMS.REQUEST:
        case Constant.ADD_FREIGHT_BILL.REQUEST:
            return true
        case Constant.UPDATE_ORDER_ITEM_PRICE.SUCCESS:
        case Constant.UPDATE_ORDER_ITEM_PRICE.FAILED:
        case Constant.UPDATE_ORDER_ITEM_PRICE.ERROR:
        case Constant.FETCH_PURCHASING_ORDER_DETAIL.SUCCESS:
        case Constant.FETCH_PURCHASING_ORDER_DETAIL.FAILED:
        case LogItemConstant.POST_LOG_ITEM.SUCCESS:
        case LogItemConstant.POST_LOG_ITEM.FAILED:
        case Constant.POST_INVOICE_CODE.SUCCESS:
        case Constant.POST_INVOICE_CODE.FAILED:
        case Constant.POST_INVOICE_CODE.ERROR:
        case Constant.DELETE_INVOICE_CODE.SUCCESS:
        case Constant.DELETE_INVOICE_CODE.FAILED:
        case Constant.DELETE_INVOICE_CODE.ERROR:
        case Constant.UPDATE_OUT_STOCK_ITEMS.SUCCESS:
        case Constant.UPDATE_OUT_STOCK_ITEMS.FAILED:
        case Constant.UPDATE_PRICE_ITEMS.SUCCESS:
        case Constant.UPDATE_PRICE_ITEMS.FAILED:
        case Constant.UPDATE_QUANTITY_ITEMS.SUCCESS:
        case Constant.UPDATE_QUANTITY_ITEMS.FAILED:
        case Constant.ADD_FREIGHT_BILL.SUCCESS:
        case Constant.ADD_FREIGHT_BILL.FAILED:
        case Constant.ADD_FREIGHT_BILL.ERROR:
            return false
        default:
            return state
    }
}

const defaultIsLoading = {
    postLogItem: [],
    postPaidOrderStatus: false,
    postInvoiceCode: false,
    postPaymentOrder: false,
    postWaitingPaymentOrder: false,
}

/**
 * State quản lý việc có hệ thống khi thực hiện các thao tác xử lý cập nhật order
 * @param state
 * @param action
 */
const isLoadingData = (state = defaultIsLoading, action) => {
    let postLogItem = []
    let id = null
    switch (action.type) {
        case LogItemConstant.POST_LOG_ITEM.REQUEST:
            id = lodash.get(action, 'payload.datas.id')
            id = lodash.toInteger(id)
            postLogItem = lodash.concat(state.postLogItem, id)
            return { ...state, ...{ postLogItem: postLogItem } }

        case Constant.UPDATE_PAID_ORDER_STATUS.REQUEST:
            return { ...state, ...{ postPaidOrderStatus: true } }

        case Constant.POST_INVOICE_CODE.REQUEST:
            return { ...state, ...{ postInvoiceCode: true } }

        case Constant.PAYMENT_ORDER.REQUEST:
            return { ...state, ...{ postPaymentOrder: true } }

        case LogItemConstant.POST_LOG_ITEM.SUCCESS:
        case LogItemConstant.POST_LOG_ITEM.FAILED:
            postLogItem = state.postLogItem
            id = lodash.get(action, 'payload.id')
            id = lodash.toInteger(id)
            postLogItem = lodash.difference(postLogItem, [id])
            return { ...state, ...{ postLogItem: postLogItem } }

        case Constant.UPDATE_PAID_ORDER_STATUS.SUCCESS:
        case Constant.UPDATE_PAID_ORDER_STATUS.FAILED:
            return { ...state, ...{ postPaidOrderStatus: false } }

        case Constant.POST_INVOICE_CODE.SUCCESS:
        case Constant.POST_INVOICE_CODE.FAILED:
            return { ...state, ...{ postInvoiceCode: false } }

        case Constant.PAYMENT_ORDER.SUCCESS:
        case Constant.PAYMENT_ORDER.FAILED:
        case Constant.PAYMENT_ORDER.CANCEL:
            return { ...state, ...{ postPaymentOrder: false } }
        case Constant.WAITING_PAYMENT_ORDER.REQUEST:
            return { ...state, ...{ postWaitingPaymentOrder: true } }
        case Constant.WAITING_PAYMENT_ORDER.SUCCESS:
        case Constant.WAITING_PAYMENT_ORDER.FAILED:
        case Constant.WAITING_PAYMENT_ORDER.CANCEL:
            return { ...state, ...{ postWaitingPaymentOrder: false } }
        default:
            return state
    }
}

const defaultIsEndProcess = {
    postInvoiceCode: false,
    deleteInvoiceCode: false,
    updateUnitPrice: false,
    postUpdateOutStock: false,
    postUpdatePriceItems: false,
    postUpdateQuantityItems: false,
    outOfStock: false,
    cancelOrder: false,
}

/**
 * State quản lý việc có hệ thống đã kết thúc tiến trình post, delete invoice_code
 * @param state
 * @param action
 */
const isEndProcess = (state = defaultIsEndProcess, action) => {
    switch (action.type) {
        case Constant.UPDATE_ORDER_ITEM_PRICE.SUCCESS:
            return { ...state, updateUnitPrice: true }
        case Constant.UPDATE_OUT_STOCK_ITEMS.SUCCESS:
        case Constant.IS_CLEAR_ERROR_PROCESS_UPDATE_OUT_STOCK_ITEMS:
            return { ...state, postUpdateOutStock: true }
        case Constant.CANCEL_ORDER.SUCCESS:
        case Constant.CANCEL_ORDER.FAILED:
            return { ...state, cancelOrder: true }
        case Constant.CANCEL_ORDER.REQUEST:
        case Constant.IS_BEGIN_PROCESS_CANCEL_ORDER:
            return { ...state, cancelOrder: false }
        case Constant.OUT_OF_STOCK_ORDER.SUCCESS:
        case Constant.OUT_OF_STOCK_ORDER.FAILED:
            return { ...state, outOfStock: true }
        case Constant.OUT_OF_STOCK_ORDER.REQUEST:
        case Constant.IS_BEGIN_PROCESS_OUT_OF_STOCK_ORDER:
            return { ...state, outOfStock: false }
        case Constant.UPDATE_PRICE_ITEMS.SUCCESS:
        case Constant.IS_CLEAR_ERROR_PROCESS_UPDATE_PRICE_ITEMS:
            return { ...state, postUpdatePriceItems: true }
        case Constant.UPDATE_QUANTITY_ITEMS.SUCCESS:
        case Constant.IS_CLEAR_ERROR_PROCESS_UPDATE_QUANTITY_ITEMS:
            return { ...state, postUpdateQuantityItems: true }
        case Constant.UPDATE_ORDER_ITEM_PRICE.REQUEST:
        case Constant.UPDATE_ORDER_ITEM_PRICE.FAILED:
        case Constant.UPDATE_ORDER_ITEM_PRICE.ERROR:
        case Constant.IS_BEGIN_PROCESS_CHANGE_PRICE:
            return { ...state, updateUnitPrice: false }
        case Constant.POST_INVOICE_CODE.SUCCESS:
            return {
                ...state,
                postInvoiceCode: true,
                deleteInvoiceCode: false,
            }
        case Constant.DELETE_INVOICE_CODE.SUCCESS:
        case Constant.DELETE_INVOICE_CODE.FAILED:
        case Constant.DELETE_INVOICE_CODE.ERROR:
            return {
                ...state,
                postInvoiceCode: false,
                deleteInvoiceCode: true,
            }
        case Constant.IS_BEGIN_PROCESS_POST_INVOICE_CODE:
        case Constant.POST_INVOICE_CODE.REQUEST:
        case Constant.POST_INVOICE_CODE.FAILED:
        case Constant.POST_INVOICE_CODE.ERROR:
        case Constant.DELETE_INVOICE_CODE.REQUEST:
        case Constant.IS_BEGIN_PROCESS_DELETE_INVOICE_CODE:
            return { ...state, postInvoiceCode: false, deleteInvoiceCode: false }
        case Constant.UPDATE_OUT_STOCK_ITEMS.REQUEST:
        case Constant.UPDATE_OUT_STOCK_ITEMS.FAILED:
        case Constant.IS_BEGIN_PROCESS_UPDATE_OUT_STOCK_ITEMS:
            return { ...state, postUpdateOutStock: false }
        case Constant.UPDATE_PRICE_ITEMS.REQUEST:
        case Constant.IS_BEGIN_PROCESS_UPDATE_PRICE_ITEMS:
            return { ...state, postUpdatePriceItems: false }
        case Constant.UPDATE_QUANTITY_ITEMS.REQUEST:
        case Constant.IS_BEGIN_PROCESS_UPDATE_QUANTITY_ITEMS:
            return { ...state, postUpdateQuantityItems: false }
        default:
            return state
    }
}

/**
 * State quản lý việc có hệ thống có đang đợi post request cancel order lên server hay không
 * @param state
 * @param action
 * @returns {boolean}
 */
const isLoadingCancelOrder = (state = false, action) => {
    switch (action.type) {
        case Constant.CANCEL_ORDER.REQUEST:
            return true
        case Constant.CANCEL_ORDER.SUCCESS:
        case Constant.CANCEL_ORDER.FAILED:
            return false
        default:
            return state
    }
}

/**
 * State quản lý việc có hệ thống có đang đợi post request out of stock order lên server hay không
 * @param state
 * @param action
 * @returns {boolean}
 */
const isLoadingOutOfStockOrder = (state = false, action) => {
    switch (action.type) {
        case Constant.OUT_OF_STOCK_ORDER.REQUEST:
            return true
        case Constant.OUT_OF_STOCK_ORDER.SUCCESS:
        case Constant.OUT_OF_STOCK_ORDER.FAILED:
            return false
        default:
            return state
    }
}

/**
 * Quản lý state khi thêm mới invoice_code
 * @param state
 * @param action
 * @returns {{}}
 */
const errorInvoiceCode = (state = {}, action) => {
    switch (action.type) {
        case Constant.POST_INVOICE_CODE.FAILED:
        case Constant.DELETE_INVOICE_CODE.FAILED:
            if (action.payload.code !== GlobalConstants.GLOBAL_CONTANT.PERMISSION_DENIED) {
                return { ...action.payload.data }
            } else {
                return {}
            }
        case Constant.POST_INVOICE_CODE.REQUEST:
        case Constant.POST_INVOICE_CODE.SUCCESS:
        case Constant.POST_INVOICE_CODE.ERROR:
        case Constant.DELETE_INVOICE_CODE.REQUEST:
        case Constant.DELETE_INVOICE_CODE.SUCCESS:
        case Constant.DELETE_INVOICE_CODE.ERROR:
        case Constant.IS_BEGIN_PROCESS_DELETE_INVOICE_CODE:
        case Constant.IS_BEGIN_PROCESS_POST_INVOICE_CODE:
        case Constant.PAYMENT_ORDER.SUCCESS:
        case Constant.PAYMENT_ORDER.FAILED:
        case Constant.WAITING_PAYMENT_ORDER.SUCCESS:
        case Constant.WAITING_PAYMENT_ORDER.FAILED:
        case Constant.CLEAR_ERROR_WHILE_POST_INVOICE_CODE:
            return {}
        default:
            return state
    }
}

/**
 * Quản lý state khi thêm mới invoice_code
 * @param state
 * @param action
 * @returns {{}}
 */
const freightBillErrors = (state = {}, action) => {
    switch (action.type) {
        case Constant.ADD_FREIGHT_BILL.FAILED:
        case Constant.DELETE_FREIGHT_BILL.FAILED:
            return { ...action.payload.data }
        case Constant.ADD_FREIGHT_BILL.REQUEST:
        case Constant.ADD_FREIGHT_BILL.ERROR:
        case Constant.ADD_FREIGHT_BILL.SUCCESS:
        case Constant.DELETE_FREIGHT_BILL.SUCCESS:
        case Constant.DELETE_FREIGHT_BILL.REQUEST:
        case Constant.DELETE_FREIGHT_BILL.ERROR:
        case Constant.CLEAR_FREIGHT_BILL_ERRORS:
            return {}
        default:
            return state
    }
}

/**
 * Quản lý state khi update unit price
 * @param state
 * @param action
 * @returns {{}}
 */
const errorUnitPrice = (state = {}, action) => {
    switch (action.type) {
        case Constant.UPDATE_ORDER_ITEM_PRICE.FAILED:
            return { ...action.payload.data }
        case Constant.CLEAR_ERROR_WHILE_UPDATE_PRICE:
            let params = lodash.get(action, 'payload.param', [])
            let newErrorState = { ...state }
            params.map(param => {
                if (typeof newErrorState[param] !== 'undefined') {
                    delete newErrorState[param]
                }

                if (param === 'unit_price' && typeof newErrorState['status'] !== 'undefined') {
                    delete newErrorState['status']
                }

                return param
            })
            return { ...newErrorState }
        case Constant.UPDATE_ORDER_ITEM_PRICE.REQUEST:
        case Constant.UPDATE_ORDER_ITEM_PRICE.SUCCESS:
        case Constant.UPDATE_ORDER_ITEM_PRICE.ERROR:
            return {}
        default:
            return state
    }
}

/**
 * Quản lý state khi update unit price items
 * @param state
 * @param action
 * @returns {{}}
 */
const errorUnitPriceItems = (state = {}, action) => {
    let data = lodash.get(action, 'payload.data', {})

    switch (action.type) {
        case Constant.UPDATE_PRICE_ITEMS.FAILED:
        case Constant.UPDATE_QUANTITY_ITEMS.FAILED:
            return { ...action.payload.data }
        case Constant.UPDATE_PRICE_ITEMS.REQUEST:
        case Constant.UPDATE_PRICE_ITEMS.SUCCESS:
        case Constant.UPDATE_QUANTITY_ITEMS.REQUEST:
        case Constant.UPDATE_QUANTITY_ITEMS.SUCCESS:
        case Constant.IS_BEGIN_PROCESS_UPDATE_QUANTITY_ITEMS:
        case Constant.IS_BEGIN_PROCESS_UPDATE_PRICE_ITEMS:
            return {}
        case Constant.KEYUP_CLEAR_ERROR_PROCESS_UPDATE_PRICE_ITEMS:
            if (data.unit_price) {
                state = lodash.omit(state, ['unit_price'])
            }
            if (data.reason) {
                state = lodash.omit(state, ['reason'])
            }
            if (data.qty_bought) {
                state = lodash.omit(state, ['qty_bought'])
            }
            return state
        default:
            return state
    }
}

/**
 * Quản lý state khi update unit price items success
 * @param state
 * @param action
 * @returns {{}}
 */
const successUpdateItems = (state = {}, action) => {
    switch (action.type) {
        case Constant.UPDATE_PRICE_ITEMS.SUCCESS:
        case Constant.UPDATE_OUT_STOCK_ITEMS.SUCCESS:
        case Constant.UPDATE_QUANTITY_ITEMS.SUCCESS:
            return true
        case Constant.UPDATE_PRICE_ITEMS.FAILED:
        case Constant.UPDATE_OUT_STOCK_ITEMS.FAILED:
        case Constant.UPDATE_QUANTITY_ITEMS.FAILED:
        case Constant.CLEAR_IS_AFTER_SUCCESS_UPDATE_PRICE_ITEMS:
            return false
        default:
            return state
    }
}

/**
 * State quản lý việc có hệ thống có đang đợi post request lên server hay không
 * @param state
 * @param action
 * @returns {boolean}
 */
const isLoadingConfirmCustomer = (state = false, action) => {
    switch (action.type) {
        case Constant.CONFIRM_CUSTOMER_ORDER_BUYING.REQUEST:
            return true
        case Constant.CONFIRM_CUSTOMER_ORDER_BUYING.SUCCESS:
        case Constant.CONFIRM_CUSTOMER_ORDER_BUYING.FAILED:
            return false
        default:
            return state
    }
}

const isLoadingUpdateData = (state = {}, action) => {
    switch (action.type) {
        case Constant.CHANGE_MUTIPLE_QUANTITY.REQUEST:
            return { ...state, ...{ qty_bought: true, showEdit: true, id: action.payload.params.id } }
        case Constant.CHANGE_MUTIPLE_QUANTITY.SUCCESS:
            return { ...state, ...{ qty_bought: false, showEdit: false, id: action.payload.data.id } }
        case Constant.CHANGE_MUTIPLE_QUANTITY.FAILED:
            return { ...state, ...{ qty_bought: false, showEdit: true, id: action.payload.id } }
        case Constant.PURCHASER_UPDATE_CHANGE_CLEAR:
            return { ...state, ...{ qty_bought: false, showEdit: true } }
        default:
            return state
    }
}

/**
 * State dùng để quản lý action khi nào cập nhật lại servers hiển thị trên đơn
 * @param state
 * @param action
 * @returns {boolean}
 */
const isFetchNewService = (state, action) => {
    switch (action.type) {
        case Constant.UPDATE_ORDER_SERVICE.SUCCESS:
        case Constant.APPROVAL_ORDER_SERVICE.SUCCESS:
        case Constant.UPDATE_ORDER_SERVICE.FAILED:
        case Constant.APPROVAL_ORDER_SERVICE.FAILED:
            return true
        case Constant.APPROVAL_ORDER_SERVICE.REQUEST:
        case Constant.UPDATE_ORDER_SERVICE.REQUEST:
        case Constant.UPDATE_ORDER_SERVICE.ERROR:
        case Constant.APPROVAL_ORDER_SERVICE.ERROR:
            return false
        default:
            return false
    }
}

/**
 * State quản lý error khi post comment
 * @param state
 * @param action
 * @returns {{}}
 */
const errorCommentItem = (state = {}, action) => {
    switch (action.type) {
        case LogItemConstant.POST_LOG_ITEM.FAILED:
            let idItem = lodash.get(action, 'payload.id', -1)
            return { ...state, [idItem]: action.payload.data }
        case Constant.CLEAR_ERROR_WHILE_POST_COMMENT_ITEM:
            let newState = { ...state }
            delete newState[lodash.get(action, 'payload.idItem', -1)]
            return newState
        default:
            return state
    }
}

/**
 * State quản lý
 * @param state
 * @param action
 * @returns {boolean}
 */
const isLoadingWhilePostItemComment = (state = {}, action) => {
    switch (action.type) {
        case LogItemConstant.POST_LOG_ITEM.REQUEST:
            return { ...state, [lodash.get(action, 'payload.datas.id')]: true }
        case LogItemConstant.POST_LOG_ITEM.SUCCESS:
        case LogItemConstant.POST_LOG_ITEM.FAILED:
        case LogItemConstant.POST_LOG_ITEM.ERROR:
            return { ...state, [lodash.get(action, 'payload.id')]: false }
        default:
            return state
    }
}

/**
 * State quản lý mã vận đơn trùng nhau
 * @param state
 * @param action
 * @returns {boolean}
 */
const freightBillDuplicateInOrders = (state = {}, action) => {
    let stateClone = {}
    switch (action.type) {
        case Constant.ADD_FREIGHT_BILL.FAILED:
            stateClone = state
            let orderFreightBill = lodash.get(action, 'payload.data.freight_bill_in_others', [])
            stateClone = { ...stateClone, ...{ freight_bill_in_others: orderFreightBill } }
            return stateClone

        case Constant.ADD_FREIGHT_BILL.REQUEST:
            stateClone = state
            let freight_bill = lodash.get(action, 'payload.freight_bill', null)
            stateClone = { ...stateClone, ...{ freight_bill: freight_bill } }
            return stateClone

        case Constant.ADD_FREIGHT_BILL.SUCCESS:
        case Constant.ADD_FREIGHT_BILL.ERROR:
        case Constant.CLEAR_FREIGHT_BILL_DUPLICATE:
            return {}
        default:
            return state
    }
}

/**
 * state hiển thị phần lịch sử các phí vc của các đơn liên quan
 * @param preState
 * @param action
 * @returns {*}
 */
const shippingFeeHistory = (preState = [], action) => {
    switch (action.type) {
        case Constant.GET_SHIPPING_FEE_HISTORY.FAILED:
        case Constant.GET_SHIPPING_FEE_HISTORY.REQUEST:
        case Constant.FETCH_PURCHASING_ORDER_DETAIL.REQUEST:
            return []
        case Constant.GET_SHIPPING_FEE_HISTORY.SUCCESS:
            return lodash.get(action, 'payload', [])
        default:
            return preState
    }
}

const isFetchNewFeature = (state, action) => {
    switch (action.type) {
        case Constant.UPDATE_ORDER_FEATURE.SUCCESS:
        case Constant.UPDATE_ORDER_FEATURE.FAILED:
            return true
        case Constant.UPDATE_ORDER_FEATURE.REQUEST:
        case Constant.UPDATE_ORDER_FEATURE.ERROR:
            return false
        default:
            return false
    }
}

export default combineReducers({
    order,
    error,
    isLoadingData,
    freightBillDuplicateInOrders,
    errorUnitPriceItems,
    successUpdateItems,
    isEndProcess,
    errorInvoiceCode,
    isLoading,
    isLoadingConfirmCustomer,
    isLoadingWhilePostItemComment,
    errorUnitPrice,
    isFetchNewService,
    errorCommentItem,
    isLoadingUpdateData,
    isLoadingCancelOrder,
    isLoadingOutOfStockOrder,
    shippingFeeHistory,
    freightBillErrors,
    isFetchNewFeature,
})
