import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import OrderItemProduct from './OrderItem/OrderItemProduct';
// import {Checkbox} from '@atlaskit/checkbox';
import lodash from 'lodash';
import Tooltip from '@atlaskit/tooltip';
import {Collapse} from 'react-collapse';
import {Row, Col, Icon, Checkbox, notification} from "antd";
import PusherComponent from "../../../common/components/Pusher";
import * as GlobalConstant from "../../../../systems/globalContant/index";
import UpdateMultipleItems from './MoreInfoComponents/UpdateMultipleItems';
import apiService from "../apiService";
import {processResponse} from "../../../../systems/api";
import BargainHistory from "./BargainHistory";
import authService from '../../../Auth/authService';
import { SELF_NEGOTIATE_UPDATE_PRODUCT } from '../../../Permission/permissions';

class OrderList extends PusherComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            sortDirection: null,
            classifyProduct: []
        }
    }

    /**
     * Xủ lý khi click icon sắp xếp
     *
     * @param {String} sortDirection
     */
    onSort(sortDirection) {
        this.setState({sortDirection});
        this.props.onSortOrderItems(sortDirection);
    }

    componentDidMount() {
        super.componentDidMount();
        let {order} = this.props;
        if (this.pusher) {
            setTimeout(() => {
                this._pusherOnSendMessage("start auto pai");
            }, 2000);
        }
        this.onGetProductSuggest()
        window.postMessage({type: "STARTED_AUTO_PAI" });
    }

    compareItems(prevItems, currentItems) {
        return lodash.differenceBy(prevItems, currentItems, 'qty_bought').length !== 0;
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        let {order} = this.props;
        let prevItems = lodash.get(prevProps, 'order.items', []);
        let currentItems = lodash.get(order, 'items', []);

        if (this.pusher && this.compareItems(prevItems, currentItems)) {
            setTimeout(() => {
                this._pusherOnSendMessage("start auto pai");
            }, 2000);
        }
    }

    componentWillUnmount() {
        let {channels} = this.props;
        if (typeof channels !== "undefined") {
            channels.map((channel) => {
                if (channel !== null) {
                    this.pusher.removeChannel(channel);
                }
                return channel;
            });
        }
    }

    /**
     * handle hành động nhận message
     * @param text
     * @param id
     * @param channel
     * @private
     */
    _pusherOnMessage(text, id, channel) {
        if (channel.startsWith(GlobalConstant.CHANNELS.ORDER_LOG)) {
            let messageObj = JSON.parse(text.replace("message=", ""));
            let creatorName = lodash.get(messageObj, "creator.name", "");
            messageObj.creator.name = creatorName === null ? "" : creatorName.replace(/\+/g, ' ');
            messageObj.message = messageObj.message.replace(/\+/g, ' ');
            if (lodash.get(messageObj, "action", "") === "COMMENT") {
                this.props.getPusherComment(messageObj);
            }
        }

        if (channel.startsWith(GlobalConstant.CHANNELS.ORDER_NOTE_ITEM)) {
            let messageObj = JSON.parse(text.replace("message=", ""));
            let creatorName = lodash.get(messageObj, "creator.name", "");
            messageObj.creator.name = creatorName === null ? "" : creatorName.replace(/\+/g, ' ');
            messageObj.message = messageObj.message.replace(/\+/g, ' ');
            if (lodash.get(messageObj, "action", "") === "COMMENT.ADD_NOTE_ITEM") {
                this.props.getPusherCommentItem(messageObj);
            }
            if (lodash.get(messageObj, "action", "") === "ORDER.CONFIRM_QUANTITY") {
                this.props.changeOrderItemQuantity(messageObj);
            }
        }
    }

    onToggleItems(event) {
        event.preventDefault();
        this.setState(state => ({isOpen: !state.isOpen}));
    }

    onToggleFragile = (e) => {
        let {t, order} = this.props;
        let res = processResponse(apiService.toggleFragile(order.id, {status: e.target.checked ? 1 : 0}));
        res.then(res => {
            this.props.updateFragileOrderDetail(res);
            notification.success({message: t('order:message.toggle_fragile_success')});
        });
        res.catch(error => {
            notification.error({message: t('order:message.toggle_fragile_fail')});
        });
    }
    onToggleEcommerceShipping = (e) => {
        let {t, order} = this.props;
        let res = processResponse(apiService.onToggleEcommerceShipping(order.id, {status: e.target.checked ? 1 : 0}));
        res.then(res => {
            this.props.updateEcommerceShippingOrderDetail(res);
            notification.success({message: t('order:message.toggle_ecommerce_shipping_success')});
        });
        res.catch(error => {
            notification.error({message: t('order:message.toggle_ecommerce_shipping_fail')});
        });
    };

    onGetProductSuggest() {
        const { items } = this.props

        processResponse(
            apiService.searchClassifyProduct({
                titles: lodash.map(items, 'title', []),
                limit: 10,
            })
        )
            .then(response => {
                let result = lodash.get(response, 'data', [])
                const labels = lodash.get(result, 'title_labels', [])
                this.setState({
                    classifyProduct: labels,
                })
            })
           
    }

    render() {
        let {
            t, order, isLoadingUpdateData, defaultChecked,
            clearPurchaserBuyingChangeDataState,
            postItemLog, isLoadingData, items, isFilterByPai,
            isFilterCustomerNote,
            changeFilterCustomerNote
        } = this.props;

        let {isOpen, classifyProduct} = this.state;
        let fragile = lodash.get(order, "orderDetail.fragile", 0);
        let ecommerceShipping = lodash.get(order, "orderDetail.ecommerce_shipping", 0);
        const orderDetail = lodash.get(order, 'orderDetail', {})
        const self_negotiate = lodash.get(orderDetail, "self_negotiate", false)

        return (
            <div>
                <div className="a-purchasod__product_title w-100" style={{padding: ".5rem .75rem"}}>
                    <div className="a-purchasod__product_title__left">
                        <strong>Sản phẩm ({order.total_item_link})</strong>
                        {
                            ((!!self_negotiate && authService.can(SELF_NEGOTIATE_UPDATE_PRODUCT)) || !self_negotiate) && 
                             <UpdateMultipleItems
                                order={order}
                                items={items}
                                beginProcessUpdateOutStock={this.props.beginProcessUpdateOutStock}
                                beginProcessUpdatePriceItems={this.props.beginProcessUpdatePriceItems}
                                beginProcessUpdateQuantityItems={this.props.beginProcessUpdateQuantityItems}
                                isLoading={this.props.isLoading}
                                isLoadingData={isLoadingData}
                                successUpdateItems={this.props.successUpdateItems}
                                isEndProcess={this.props.isEndProcess}
                                order_item_checked={this.props.order_item_checked}
                                beginClearErrorUpdatePriceItems={this.props.beginClearErrorUpdatePriceItems}
                                onClearCheckAll={this.props.onClearCheckAll.bind(this)}
                                errorUnitPriceItems={this.props.errorUnitPriceItems}
                                updateOutOfStockItems={this.props.updateOutOfStockItems}
                                updatePriceItems={this.props.updatePriceItems}
                                updateQuantityItems={this.props.updateQuantityItems}
                                clearErrorWhileUpdatePrice={this.props.clearErrorWhileUpdatePrice}
                                updateItemsOrderDetail={this.props.updateItemsOrderDetail}
                                updateHSCodeOrderDetail={this.props.updateHSCodeOrderDetail}
                                classifyProduct={classifyProduct}
                                getPurchasingOrderDetailAction={this.props.getPurchasingOrderDetailAction}
                                match={this.props.match}
                            />
                        }
                        
                        {/* 2 input hidden này dùng cho autopai không được xóa */}
                        <input type="hidden" name="a-purchasod_list_hidden" value={Math.floor(Date.now() / 1000)}/>
                        <input type="hidden" name="a-purchasod_total_item" value={items.length}/>
                    </div>
                    <div className={'a-purchasod__product_title__number'}>
                        <BargainHistory className="mr-4" order={order}/>
                        {/*<div className="mr-4">*/}
                            {/*<label className={ecommerceShipping === 1 ? 'is_ecommerceShipping' : ''}><Checkbox disabled={!order.canEcommerceShipping} checked={ecommerceShipping === 1} onChange={this.onToggleEcommerceShipping} /> {t("order:label.ecommerce_shipping")}</label>*/}
                        {/*</div>*/}

                        {/*<div className="mr-4">*/}
                            {/*<label className={fragile === 1 ? 'is_fragile' : ''}><Checkbox disabled={!order.canFragile} checked={fragile === 1 ? true : false} onChange={this.onToggleFragile} /> {t("order:label.fragile_product")}</label>*/}
                        {/*</div>*/}
                        <div className="flex--center pr_paided">
                            <Tooltip content={t('orderDetail:tooltip.total_customer_comment')}>
                                <div
                                    onClick={changeFilterCustomerNote}
                                    className={(isFilterCustomerNote ? "a-text--font-blue " : "") + "flex--center a--cursor-poiter"}
                                >
                                    <i className={'ion-ios-email-outline pr_icon '}/>
                                    {order.total_customer_comment()}
                                </div>
                            </Tooltip>&nbsp;/&nbsp;
                            <Tooltip content={t('orderDetail:tooltip.total_link')}>
                                <span>{order.total_link}</span>
                            </Tooltip>
                        </div>
                        <div className="flex--center pr_paided">
                            &nbsp;&nbsp;&nbsp;<i className="fas fa-link pr_icon pr_icon--link"/>&nbsp;
                            <Tooltip content={t('orderDetail:tooltip.total_qty_product')}>
                                <span>{order.total_qty()}</span>
                            </Tooltip>
                            <span>/</span>
                            <Tooltip content={t('orderDetail:tooltip.total_qty_bought_product')}>
                                <span className="a-text--font-blue a-text--bold">{order.total_qty_bought()}</span>
                            </Tooltip>
                            <span>/</span>
                            <Tooltip content={t('orderDetail:tooltip.total_qty_received_product')}>
                                <span>{ order.canShowQuantityReceived ? order.total_item_qty_received : '---'}</span>
                            </Tooltip>
                        </div>
                    </div>
                </div>

                <hr className="m-0"/>

                <Collapse isOpened={isOpen}>
                    <div className={"a-purchasod__block__body list_item "}>
                        <div className={'a-table__scroll-x'}>
                            {/*table-list-items class sử dụng cho autopai extension, dont change it pls! */}
                            <div className="a-purchasod__table__order table-list-items">
                                <Row className="pt-1 pb-1" style={{paddingRight: ".75rem", paddingLeft: ".75rem"}}>
                                    <Col span={1}>
                                        <Checkbox onChange={this.props.onCheckAll}
                                                  key={"chk_all" + defaultChecked}
                                                  isDisabled={items.length? false: true}
                                                  defaultChecked={defaultChecked}/>
                                    </Col>
                                    <Col span={4}>Sản phẩm</Col>
                                    <Col span={7}>
                                        <Row><Col xl={{span: 10, offset: 14}} md={{span: 14, offset: 10}} className="pl-3">{t('order_detail.quantity')}</Col></Row>
                                    </Col>
                                    <Col span={5} offset={3}>
                                        <span>{t('order_detail.unit_price')}</span>
                                        <span className="seperate-v">{t('order_detail.sum')}</span>
                                    </Col>
                                    <Col span={4} className="d-flex flex-end">
                                        {this.renderIconSort()}
                                        <span className="ml-2">Auto PAI</span>
                                    </Col>
                                </Row>
                                <hr className="m-0"/>
                                <div style={{padding: ".75rem"}}>
                                    <table width="100%">
                                        <tbody>
                                            {
                                                items.map((item, index) => (
                                                    (
                                                        ((isFilterByPai === "pai_fail" && item.is_pai !== 1) ||
                                                            (isFilterByPai === "pai_success" && item.is_pai === 1) ||
                                                            (isFilterByPai === ""))
                                                        && ((!isFilterCustomerNote) || (isFilterCustomerNote &&
                                                            item.note !== null &&
                                                            Array.isArray(item.note) &&
                                                            item.note.length > 0))
                                                    )
                                                        ?
                                                        (<tr key={item.id}>
                                                            { index === 0 ? null : <hr className="m-0"/> }
                                                            <OrderItemProduct
                                                                onCheckAll={this.props.onCheckAll}
                                                                onCheckItem={this.props.onCheckItem}
                                                                check_all={this.props.check_all}
                                                                defaultChecked={this.props.order_item_checked.includes(item.id)}
                                                                item={item}
                                                                clearPurchaserBuyingChangeDataState={clearPurchaserBuyingChangeDataState}
                                                                isLoadingUpdateData={isLoadingUpdateData}
                                                                onUpdatePaidOrderDetail={this.props.onUpdatePaidOrderDetail}
                                                                changeMutipleQuantity={this.props.changeMutipleQuantity}
                                                                order={order}
                                                                postItemLog={postItemLog}
                                                                updateOrderItemPrice={this.props.updateOrderItemPrice}
                                                                isEndProcess={this.props.isEndProcess}
                                                                isLoading={this.props.isLoading}
                                                                isLoadingData={isLoadingData}
                                                                clearErrorWhileUpdatePrice={this.props.clearErrorWhileUpdatePrice}
                                                                errorUnitPrice={this.props.errorUnitPrice}
                                                                beginProcessChangePrice={this.props.beginProcessChangePrice}
                                                                errorCommentItem={this.props.errorCommentItem}
                                                                clearErrorWhilePostCommentItem={this.props.clearErrorWhilePostCommentItem}
                                                                isLoadingWhilePostItemComment={this.props.isLoadingWhilePostItemComment}
                                                                updateAffiliateLink={this.props.updateAffiliateLink}
                                                                updateItemsOrderDetail={this.props.updateItemsOrderDetail}
                                                                getPurchasingOrderDetailAction={this.props.getPurchasingOrderDetailAction}
                                                                updateHSCodeOrderDetail={this.props.updateHSCodeOrderDetail}
                                                                classifyProduct={classifyProduct}
                                                            />
                                                        </tr>)
                                                        : null
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {!items.length ?
                            <React.Fragment key={order.id}>
                                <div>
                                    <p style={{marginTop: '15px', textAlign: 'center'}}>
                                        {t('orderDetail:order_detail.not_found_data')}
                                    </p>
                                </div>
                            </React.Fragment>
                            :
                            null
                        }
                    </div>
                </Collapse>
                <div className="a-purchasod__table__order__show_hide a--cursor-poiter">
                    <hr className="m-0"/>
                    <a onClick={this.onToggleItems.bind(this)}>
                        { isOpen ?
                            <><Icon type="shrink"/> {t("orderDetail:order_detail.collapse")}</> :
                            <><Icon type="arrows-alt"/> {t("orderDetail:order_detail.explain")}</>
                        }
                    </a>
                </div>
            </div>
        );
    }

    renderIconSort() {
        let {t} = this.props;
        let {sortDirection} = this.state;
        let nextDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        let tooltip = t(`orderDetail:tooltip.fillter_by_pai_time_${nextDirection === 'asc' ? 'up' : 'down'}`);
        let icon = lodash.get({
            asc: 'fa-sort-amount-up',
            desc: 'fa-sort-amount-down',
        }, sortDirection, 'fa-sort');

        return (
            <Tooltip content={tooltip}>
                <i onClick={this.onSort.bind(this, nextDirection)}
                   className={`fas ${icon} pr_sort a--cursor-poiter`}
                />
            </Tooltip>
        );
    }
}

OrderList.defaultProps = {
    isFilterByPai: "",
    isFilterCustomerNote: false,
    clearPurchaserBuyingChangeDataState: () => {
    },
    order: {},
    isLoadingData: {}
};

OrderList.propTypes = {
    isFilterByPai: PropTypes.string,
    isFilterCustomerNote: PropTypes.bool,
    changeFilterCustomerNote: PropTypes.func,
    clearPurchaserBuyingChangeDataState: PropTypes.func,
    getPusherCommentItem: PropTypes.func,
    order: PropTypes.object,
    isLoadingData: PropTypes.object
};

export default translate('translations')(OrderList);
