import {call, put} from 'redux-saga/effects';
import * as Constants from './constants';
import * as GlobalConstants from '../../../systems/globalContant';
import service from './apiService';
import {events} from "../../../systems/events";
import bootbox from './../../common/components/Bootbox/bootbox';
import {t} from './../../../systems/i18n/index.js';
import lodash from 'lodash';
import WRONG_PROCESS from './events';

export function* updateWrongProcess(action) {
    try {
        let response = yield call(service.updateWrongProcessOrder, action.payload);
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.UPDATE_WRONG_PROCESS_ORDER.SUCCESS, payload: response.data});
            if (lodash.get(response, "data.data.wrong_process", false)) {
                bootbox.success(t('order:message.update_wrong_process_ok_success'));
            } else {
                bootbox.success(t('order:message.update_wrong_process_not_ok_success'));
            }
        } else {
            if (response.data.data.hasOwnProperty('payment_request')) {
                lodash.forEach(response.data.data.payment_request, function (errValue, errKey) {
                    if (errKey === 'confirm') {
                        events.dispatch(WRONG_PROCESS.CONFIRM)
                    } else {
                        bootbox.error(t(`order:message.payment_request_${errKey}`));
                    }
                })
            } else if (response.data.data.hasOwnProperty('invoice_code_exist')) {
                lodash.mapKeys(response.data.data.invoice_code_exist, function (value, key) {
                    bootbox.error(t(`order:message.invoice_code_exist`, {invoiceCode: key}))
                })

            } else {
                bootbox.error(t('order:message.update_wrong_process_fail'));
            }
        }
    } catch (err) {
        bootbox.error(t("messages.system_errors") + " : " + err.message);
    }
}



