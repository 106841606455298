export const CREATE_PACKAGE = {
    REQUEST: 'PRODUCT_RETURN.CREATE_PACKAGE.REQUEST',
    SUCCESS: 'PRODUCT_RETURN.CREATE_PACKAGE.SUCCESS',
    FAILED: 'PRODUCT_RETURN.CREATE_PACKAGE.FAILED',
};

export const DELETE_PACKAGE = {
    REQUEST: 'PRODUCT_RETURN.DELETE_PACKAGE.REQUEST',
    SUCCESS: 'PRODUCT_RETURN.DELETE_PACKAGE.SUCCESS',
    FAILED: 'PRODUCT_RETURN.DELETE_PACKAGE.FAILED',
};

export const BEGIN_DELETE_PACKAGE = 'BEGIN_DELETE_PACKAGE';
