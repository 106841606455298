import {permissions} from "../../../modules/Permission";
import * as PERMISSION from "../../../modules/Permission/permissions";

export const MENU_TYPE = {
    SINGLE: "single",
    MULTI: "multi",
    GROUP: "group"
}

/**
 * type của item có 3 dạng:
 * single: menu chỉ có 1 cấp,
 * multi: menu có cấp con, các menu con được khai báo trong thuộc tính sub_menu,
 * group: là title chứa các menu con các menu con cũng được khai báo trong thuộc tính sub_menu
 *
 * @type {[null,null,null]}
 */

export const Menu = [
    {
        is_open: false,
        key: "OrderGroup",
        type: MENU_TYPE.GROUP,
        text: 'theme.menu.group_order',
        icon: {
            class_name: 'fas fa-clipboard-list',
        },
        sub_menu: [
            {
                is_open: false,
                key: "ListPurchase",
                href: '/list_purchase',
                text: 'theme.menu.list-purchase',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-clipboard-list',
                },
                permission: [permissions.ORDER_VIEW, permissions.ORDER_VIEW_ASSIGNED, permissions.ORDER_VIEW_ONLY_ACCEPTED]
            },
            {
                is_open: true,
                key: "ListOrder",
                href: '/ordering',
                text: 'theme.menu.list-order',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-list-alt',
                },
                permission: [permissions.ORDER_VIEW, permissions.ORDER_VIEW_ASSIGNED, permissions.ORDER_VIEW_ONLY_ACCEPTED]
            },
            {
                is_open: true,
                key: "ListForeignOrder",
                href: '/foreign-order',
                text: 'theme.menu.foreign-order',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-list-alt',
                },
                permission: [permissions.TAOBAO_GET_PURCHASE_ORDER]
            },
            {
                is_open: true,
                key: "ViolatePolicy",
                href: '/violate-policy/list',
                text: 'theme.menu.violate-policy',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-flag',
                },
                permission: [permissions.VIOLATED_ORDER_VIEW]
            },
            {
                is_open: true,
                key: "order_bill_no",
                href: '/order-bill-no-syncs?seller_source=1688&status[]=BOUGHT',
                text: 'theme.menu.order_bill_no',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-flag',
                },
                permission: [permissions.ORDER_SYNC_BILL_NO_SYNC]
            },
            {
                is_open: true,
                key: "orders_scan",
                href: '/orders/scan',
                text: 'theme.menu.orders_scan',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-flag',
                },
                permission: [permissions.ORDER_SCAN]
            },
            {
                is_open: true,
                key: "sale_figures",
                href: '/sales',
                text: 'theme.menu.sale_figures',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-flag',
                },
                permission: [permissions.PURCHASE_REPORT_VIEW]
            },
            {
                is_open: true,
                key: "orders_export",
                href: '/orders/export',
                text: 'theme.menu.orders_export',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-flag',
                },
                permission: [permissions.TOOLS_EXPORT_ORDER_TRANSACTION, permissions.TOOLS_EXPORT_ACTUAL_AMOUNT]
            },
            {
                is_open: true,
                key: "payment_requests",
                href: '/payment-requests?status=WAITING&external_order=0',
                text: 'theme.menu.payment_requests',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-flag',
                },
                permission: [permissions.PAYMENT_REQUEST_VIEW, permissions.PAYMENT_REQUEST_VIEW_ALL, permissions.PAYMENT_REQUEST_VIEW_GROUP, permissions.PAYMENT_REQUEST_VIEW_GROUP_ALL]
            },
            {
                is_open: true,
                key: "sale_report",
                href: '/sale-report',
                text: 'theme.menu.sale_report',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-flag',
                },
                permission: [permissions.PURCHASE_REPORT_VIEW, permissions.PURCHASE_REPORT_MANAGE]
            },
            {
                is_open: true,
                key: "performance_report",
                href: '/performance-report',
                text: 'theme.menu.performance_report',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-flag',
                },
                permission: [permissions.SYSTEM_ADMIN]
            },
            {
                is_open: true,
                key: "invoice_code_approvals",
                href: '/invoice-code-approvals',
                text: 'theme.menu.invoice_code_approvals',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-flag',
                },
                permission: [permissions.ORDER_UPDATE_INVOICE_APPROVAL]
            },
            {
                is_open: true,
                key: "bank_account",
                href: '/bank-account',
                text: 'theme.menu.bank-account',
                permission: []
            }
        ],
    },
    {
        is_open: false,
        key: "Complaint",
        type: MENU_TYPE.GROUP,
        text: 'theme.menu.complaint',
        icon: {
            class_name: 'fab fa-buysellads',
        },
        sub_menu: [
            {
                is_open: true,
                key: "complaint-seller",
                href: '/complaint-seller',
                text: 'theme.menu.complaint_sellers',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fab fa-buysellads',
                },
                sub_menu: [],
                permission: [permissions.COMPLAINT_SELLER_VIEW]
            },
            {
                is_open: true,
                key: "scan_complaint_seller",
                href: '/scan-complaint-seller',
                text: 'theme.menu.scan_complaint_seller',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fab fa-buysellads',
                },
                sub_menu: [],
                permission: [permissions.COMPLAINT_SELLER_VIEW]
            }
        ],
    },
    {
        is_open: false,
        key: "Transaction",
        type: MENU_TYPE.GROUP,
        text: 'theme.menu.transaction',
        icon: {
            class_name: "fas fa-chart-line"
        },
        sub_menu: [
            {
                is_open: true,
                key: "transaction.files.list",
                href: '/transaction/files',
                text: 'theme.menu.transaction_files',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-money-bill-wave-alt',
                },
                sub_menu: [],
                permission: [permissions.TRANSACTION_MANAGE_FILE]
            },
            {
                is_open: true,
                key: "transaction.list",
                href: '/transactions?time_type=transaction_time',
                text: 'theme.menu.transactions',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: "fas fa-chart-line"
                },
                sub_menu: [],
                permission: [permissions.SUPPLIER_TRANSACTION_LIST, permissions.SUPPLIER_TRANSACTION_LIST_NORMAL, permissions.SUPPLIER_TRANSACTION_LIST_ALL]
            },
            {
                is_open: true,
                key: "transaction.files.order",
                href: '/transaction/orders',
                text: 'theme.menu.transaction_orders',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: "fas fa-money-check-alt"
                },
                sub_menu: [],
                permission: [permissions.ORDER_TRANSACTION]
            },
        ],
    },
    {
        is_open: false,
        key: "Purchaser",
        type: MENU_TYPE.GROUP,
        text: 'theme.menu.purchaser',
        icon: {
            class_name: "fas fa-money-check-alt"
        },
        sub_menu: [
            {
                is_open: true,
                key: "purchasers",
                href: '/purchasers',
                text: 'theme.menu.purchasers',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: 'fas fa-money-bill-wave-alt',
                },
                sub_menu: [],
                permission: [permissions.PURCHASER_MANAGE]
            },
            {
                is_open: true,
                key: "purchaser_account_purchaser",
                href: '/purchasers/assign-account-purchaser',
                text: 'theme.menu.purchaser_account_purchaser',
                type: MENU_TYPE.SINGLE,
                icon: {
                    class_name: "fas fa-money-check-alt"
                },
                sub_menu: [],
                permission: [permissions.PURCHASER_MANAGE]
            },
        ],
    },
    {
        is_open: false,
        key: "Configuration",
        type: MENU_TYPE.GROUP,
        text: 'theme.menu.group_configuration',
        icon: {
            class_name: 'fas fa-cog',
        },
        sub_menu: [
            {
                is_open: true,
                key: "setting-partner",
                href: '/setting-partner',
                text: 'theme.menu.setting-partner',
                type: MENU_TYPE.SINGLE,
                permission: [permissions.SETTING_GENERAL]
            },
            {
                is_open: true,
                key: "setting-buying",
                href: '/setting-buying',
                text: 'theme.menu.setting-buying',
                type: MENU_TYPE.SINGLE,
                permission: [permissions.SETTING_BUYING]
            },
            {
                is_open: true,
                key: "setting-transaction",
                href: '/setting-transaction',
                text: 'theme.menu.setting-transaction',
                type: MENU_TYPE.SINGLE,
                permission: [permissions.SETTING_TRANSACTIONS]
            },
            {
                is_open: true,
                key: "setting-private",
                href: '/setting-private',
                text: 'theme.menu.setting-private',
                type: MENU_TYPE.SINGLE,
            },
            {
                is_open: true,
                key: "account_purchaser",
                href: '/account-purchaser',
                text: 'theme.menu.account_purchaser',
                permission: [PERMISSION.ACCOUNT_PURCHASER_VIEW]
            },
            {
                is_open: true,
                key: "purchase-address",
                href: '/purchase-address',
                text: 'theme.menu.purchase-address',
                permission: [PERMISSION.PURCHASE_ADDRESS_VIEW]
            },
            {
                is_open: true,
                key: 'slow-purchasing-reason',
                href: '/slow-purchasing-reason',
                text: 'theme.menu.slow-purchasing-reason',
                permission: [permissions.SETTING_VIOLATION_CAUSE],
            },
            {
                is_open: true,
                key: "order-group",
                href: '/order-group',
                text: 'theme.menu.order-group',
                permission: [PERMISSION.ORDER_GROUP_VIEW]
            },
            {
                is_open: true,
                key: 'user_access_token',
                href: '/user/access-token',
                text: 'theme.menu.user_access_token'
            },
            {
                is_open: true,
                key: "account_purchaser_token",
                href: '/account-purchaser-token',
                text: 'theme.menu.account_purchaser_token',
                permission: [PERMISSION.SETTING_ACCESS_TOKEN_ALIBABA]
            },
            {
                is_open: true,
                key: "agency",
                href: '/agency',
                text: 'theme.menu.agency',
                permission: [PERMISSION.SETTING_AGENCY]
            },
            {
                is_open: true,
                key: 'bank_account',
                href: '/bank-account',
                text: 'theme.menu.bank_account',
                permission: [PERMISSION.SETTING_BANK_ACCOUNTS]
            },
            {
                is_open: true,
                key: "group_manage",
                href: '/group-manage',
                text: 'theme.menu.group_manage',
                permission: [PERMISSION.PAYMENT_REQUEST_GROUP_MANAGE]
            },
            // {
            //     is_open: true,
            //     key: 'user.access_token',
            //     href: '/user/access-token',
            //     text: 'theme.menu.user_access_token',
            //     type: MENU_TYPE.SINGLE,
            //     icon: {
            //         class_name: 'fas fa-lock',
            //     },
            //     sub_menu: []
            // },
            // {
            //     is_open: true,
            //     key: 'salefigures',
            //     href: '/sales',
            //     text: 'theme.menu.sale_figures',
            //     type: MENU_TYPE.SINGLE,
            //     icon: {
            //         class_name: 'fas fa-file-download',
            //     },
            //     sub_menu: [],
            //     permission: [permissions.PURCHASE_REPORT_MANAGE, permissions.PURCHASE_REPORT_VIEW]
            // },
        ],
    },


    {
        disabled: true,
        is_open: true,
        key: "Personal",
        type: MENU_TYPE.GROUP,
        text: 'theme.menu.group_personal',
        sub_menu: [
            {
                is_open: true,
                key: "Profile",
                href: ';',
                type: "single",
                text: 'theme.menu.profile',
                icon: {
                    class_name: 'fas fa-user-shield',
                },
                sub_menu: []
            },
        ],
    }
];
