import React, {useState} from 'react';
import {translate} from "react-i18next";
import {t} from "../../../../systems/i18n";
import {Modal, Input, notification, Tooltip, Icon} from "antd";
import {processResponse} from "../../../../systems/api";
import apiService from "../../apiService";
import {get} from 'lodash';

function UpdatePackageReturnInfo({complaintId, updateListPackages, packageInfo, className}) {
    const packageCode = get(packageInfo, "code", undefined);
    const initialValue = {
        return_tracking_bill: get(packageInfo, "return_tracking_bill", undefined),
        return_fee: get(packageInfo, "return_fee", 0),
        return_address: get(packageInfo, "return_address", undefined),
    };
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(initialValue);

    function updatePackageInfo() {
        setLoading(true);
        const response = apiService.changePackageInfo(complaintId, {...data, packages: [packageCode]});
        const process = processResponse(response);
        process.then(() => {
            notification.success({message: t("complaintSeller:message.update_package_info_success", {package_code: packageCode})})
            updateListPackages();
            setVisible(false);
        }).catch(() => {
            notification.error({message: t("message.cant_update_data")})
        }).finally(() => setLoading(false))
    }

    function handleCancel() {
        setData(initialValue);
        setVisible(false);
    }

    const onChangeInput = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const onChangeFee = (e) => {
        const {value, name} = e.target;
        const regex = /^[]?\d*\.?\d{0,5}$/;
        if (!(regex.test(value) || value === "")) return;
        setData({...data, [name]: value});
    };

    return (
        <>
            <Tooltip title={t("complaintSeller:modal.update_package_info")}>
                <Icon className={className ? className : ""} type="edit" onClick={() => setVisible(true)}/>
            </Tooltip>
            {
                (visible) &&
                <Modal
                    title={t("complaintSeller:modal.update_package_info", {package_code: get(packageInfo, "code", "")})}
                    visible={visible}
                    onOk={updatePackageInfo}
                    onCancel={handleCancel}
                    cancelText={t("btn.cancel")}
                    okText={t("btn.ok")}
                    okButtonProps={{
                        loading: loading,
                        className: "_edit-package-info-button-handle-ok"
                    }}
                    cancelButtonProps={{className: "_edit-package-info-button-handle-cancel"}}
                    width={520}
                >

                    <div className='a-group'>
                        <label
                            className="label">{t("complaintSeller:label.return_tracking_bill")}</label>
                        <Input
                            name="return_tracking_bill"
                            value={get(data, "return_tracking_bill", undefined)}
                            placeholder={t("complaintSeller:placeholder.return_tracking_bill")}
                            onChange={onChangeInput}
                            className="_edit-package-info-return-tracking-bill"
                        />
                    </div>
                    <div className='a-group'>
                        <label
                            className="label">{t("complaintSeller:label.return_fee")}</label>
                        <Input
                            name="return_fee"
                            value={get(data, "return_fee", undefined)}
                            placeholder={t("complaintSeller:placeholder.return_fee")}
                            onChange={onChangeFee}
                            className="_edit-package-info-return-fee"
                        />
                    </div>
                    <div className='a-group'>
                        <label
                            className="label">{t("complaintSeller:label.return_address")}</label>
                        <Input
                            name="return_address"
                            value={get(data, "return_address", undefined)}
                            placeholder={t("complaintSeller:placeholder.return_address")}
                            onChange={onChangeInput}
                            className="_edit-package-info-return-address"
                        />
                    </div>
                </Modal>
            }
        </>

    )
}

export default translate()(UpdatePackageReturnInfo);