import {matchPath} from 'react-router';
import queryString from 'query-string';
import lodash from 'lodash';

export default class UrlGenerator {
    constructor(router) {
        this.router = router;
    }

    to(routeName, params = {}) {
        let route = this.router.findRoute(routeName);

        if (!route) {
            return null;
        }

        return !lodash.isEmpty(params) ? this.bindParamsToUrl(route.path, params) : route.path;
    }

    redirect(location) {
        this.router.redirect(location);
    }

    redirectTo(routeName, params = {}, query = {}) {
        let url = this.to(routeName, params);

        if (!lodash.isEmpty(query)) {
            url += '?' + queryString.stringify(query, {arrayFormat: 'bracket'});
        }

        this.router.redirect(url);
    }

    bindParamsToUrl(url, params) {
        Object.keys(params).forEach(key => url = url.replace(':' + key, params[key]));

        return url;
    }

    location() {
        return this.router.location;
    }

    getQuery(key = null, defaultValue = null) {
        let query = this.router.getLocationQuery();

        return key === null ? query : lodash.get(query, key, defaultValue);
    }

    setQuery(query) {
        return this.router.setLocationQuery(query);
    }

    isCurrent(path, options = {}) {
        options = options === true ? {exact: true} : options;

        return this.router.location ? !!matchPath(this.router.location.pathname, {...options, path}) : false;
    }
}