import React from 'react';
import Button from '@atlaskit/button';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Form, {FormSection, FormFooter} from '@atlaskit/form';
import {translate} from 'react-i18next';
import {ToggleStateless} from '@atlaskit/toggle';
import {Col} from 'react-grid-system';
import {router, url} from './../../../../systems/routing/index';
import FormProcessing from '../../../common/components/FormProcessing';
import Input from '../../../common/components/Input/Super'
import { Switch } from 'antd';

/**
 * Dữ liệu mặc định của form thêm mới
 * @type {{input: {id: string, name: string, code: string, description: string, status: number}}}
 */

const defaultState = {
    input: {
        id: '',
        name: '',
        code: '',
        description: '',
        priority: 0,
        status: 1
    },
    inputVersion: 0,
    changedParams: [],
};

class FormOrderGroup extends FormProcessing {

    constructor(props) {
        super(props);
        this.state = defaultState;
    }


    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        this.setState(state => ({input: {...state.input, ...input}}));
    }

    /**
     * Cập nhật dữ liệu thay đổi của input
     * @param event
     */
    onHandleChange(event) {
        let input = this.state.input;
        let target = event.target;
        let value = target.value;
        let name = target.name;
        if (name === 'status') {
            value = input.status ? 0 : 1;
        }
        if (name === 'code') {
            value = value.trim();
            value = value.replace(/\s/g, '');
        }

        this.changeInput({[name]: value});
        this.props.resetErrorFormOrderGroup({[name]: value});
    }

    /**
     * Cập nhật dữ liệu thay đổi của Switch
     * @param status
     * @param name
     */
    onHandleChangeSwitch(name, status) {
        let value = status ? 1 : 0;
        this.changeInput({[name]: value});
        this.props.resetErrorFormOrderGroup({[name]: value});
    }

    /**
     * bắt hành động sửa priority
     * @param value
     */
    onHandleChangePriority(value) {
        this.changeInput({priority: value});
        this.props.resetErrorFormOrderGroup({priority: value});
    }

    componentDidMount(nextProps) {
        const {location, match} = this.props;
        let filters = {...queryString.parse(location.search), ...match.params};
        this.props.onFetchOrderGroup({});
        if (lodash.get(filters, 'id', null)) {
            this.props.onFetchDetailOrderGroup(filters);
        }
    }


    /**
     * Gọi khi submit form thêm mới, sửa dữ liệu
     * @param e
     */
    onSubmitHandler(e) {
        e.preventDefault();
        let input = this.state.input;
        if (lodash.get(input, 'id', null)) {
            this.props.onUpdateOrderGroup(input);
        } else {
            this.props.createOrderGroup(input)
        }
    };

    /**
     * Thực hiện reset form
     * @param event
     */
    onResetHandler(event) {
        event.preventDefault();
        this.updateFormData(defaultState.input);
        this.props.onCancelFormOrderGroup({});
        router.redirect(url.to('orderGroup.manager'));
    }

    /**
     * Render dữ liệu tên nhóm ngành
     * @param props
     * @returns {XML}
     * @constructor
     */
    Description(props) {
        return (
            <div name={props.id} onClick={() => {
                router.redirect(url.to('orderGroup.manager') + '?id=' + props.id);
                props.onFetchDetailOrderGroup(props);
            }} className="description-order-group">
                {props.name}
            </div>)
    };

    /**
     * Thực hiện render lỗi thông báo ra bên ngoài
     * @param attrName
     * @returns {null}
     */
    renderError(attrName) {
        let {dataError, t} = this.props;
        let errorItem = lodash.get(dataError, attrName, {});

        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('order_group.error.' + Object.keys(errorItem)[0], {name: t('order_group.' + attrName)});
                case 'CODE_USED':
                    return t('order_group.error.' + Object.keys(errorItem)[0], {name: t('order_group.' + attrName)});
                case 'max.string':
                    let objectmax = lodash.get(errorItem, 'max.string', null);
                    let overLength = lodash.get(objectmax, 'max', null);
                    return t('order_group.error.max_string', {
                        name: t('order_group.' + attrName),
                        value: overLength
                    });
                default:
                    return null;
            }
        }
    }

    render() {
        const {t, onRef, permission} = this.props;
        let {input = {}} = this.state;

        return (

            <Col xs={12} sm={12} md={12} lg={4} xl={4} className="column-right pl-0">
                <div className="a-content--box-shadow">
                <Form name="order-6"
                      onSubmit={this.onSubmitHandler.bind(this)}
                      onReset={this.onResetHandler.bind(this)}
                >
                    <FormSection>
                        <div className='a-group'>
                            <label className="label label--required">
                                {t("order_group.name")}
                            </label>
                            <input type="text"
                                   name="name"
                                   autoComplete='off'
                                   placeholder={t("order_group.name_placeholder")}
                                   onChange={this.onHandleChange.bind(this)}
                                   value={input.name || ""}
                                   className={"mt-2 input input--normal"}
                                   ref={(input) => {
                                       if (onRef) {
                                           onRef(input)
                                       }
                                   }}
                            />
                            <div className={"validatorMessage a--error "
                            + ( this.renderError("name") ? 'active-error' : 'inactive-error' )}>
                                {this.renderError("name")}
                            </div>
                        </div>

                        <div className='a-group'>
                            <label className="label label--required">
                                {t("order_group.code")}
                            </label>
                            <input type="text"
                                   name="code"
                                   autoComplete='off'
                                   disabled={input.id ? true : false}
                                   placeholder={t("order_group.code_placeholder")}
                                   onChange={this.onHandleChange.bind(this)}
                                   value={input.code || ""}
                                   className={"mt-2 input input--normal"}
                            />
                            <div className={"validatorMessage a--error "
                            + ( this.renderError("code") ? 'active-error' : 'inactive-error' )}>
                                {this.renderError("code")}
                            </div>
                        </div>
                        <div className='a-group'>
                            <label className="label label--required">
                                {t("order_group.priority")}
                            </label>
                            <Input
                                integer
                                type="text"
                                name="priority"
                                autoComplete='off'
                                placeholder={t("order_group.priority_placeholder")}
                                onChange={event => this.onHandleChangePriority(event)}
                                value={input.priority}
                                className={"mt-2 input input--normal"}
                            />
                            <div className={"validatorMessage a--error "
                            + ( this.renderError("priority") ? 'active-error' : 'inactive-error' )}>
                                {this.renderError("priority")}
                            </div>
                        </div>
                        <div className='a-group'>
                            <label className="label">
                                {t("order_group.description")}
                            </label>
                            <textarea rows="4" cols="100"
                                      name="description"
                                      style={{width: '100%'}}
                                      onChange={this.onHandleChange.bind(this)}
                                      placeholder={t("order_group.description_placeholder")}
                                      className={"mt-2"}
                                      value={input.description || ""}>
                            </textarea>
                        </div>
                        <div className='a-group'>
                            <label className="label">
                                {t("order_group.status")}
                            </label>
                            <div className="order-group-status">
                               <Switch
                                    checked={input.status}
                                    onChange={this.onHandleChangeSwitch.bind(this, 'status')}
                                    className="gtoogle"
                                />
                            </div>
                        </div>

                    </FormSection>

                    <FormFooter>
                        {permission &&
                        <div>
                            <Button
                                appearance="primary"
                                type="submit">
                                {t("btn.save")}
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                appearance="subtle"
                                onClick={this.onResetHandler.bind(this)}
                            >
                                {t("btn.cancel")}
                            </Button>
                        </div>
                        }
                    </FormFooter>
                </Form>
                </div>
            </Col>
        );
    }
}

FormOrderGroup.defaultProps = {
    dataDetail: {},
    dataError: {},
};

FormOrderGroup.propTypes = {
    dataDetail: PropTypes.object,
    dataError: PropTypes.object,
    onRef: PropTypes.func
};

export default translate('translations')(FormOrderGroup);
