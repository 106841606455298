import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';

class FormFilter extends React.Component {
  onReset(event) {
    event.preventDefault();

    this.props.onChange({
      username: '',
      group: '',
    });
  }

  render() {
    let {t, input, onChange} = this.props;

    return (
      <div>
        <div className="row">
            <div className="col-auto form-group t-form-search-user">
              <div className="mb-2 t-label__form-user">{t('purchaser:label.filter_account')}</div>
              <input
                type="text"
                className="form-control form-control-sm"
                value={input.username || ''}
                onChange={event => onChange({username: event.target.value})}
              />
            </div>
            <div className="col-auto form-group d-flex flex-column t-form-search-user">
              <div className="mb-2 t-label__form-user">{t('purchaser:label.filter_group')}</div>
              <div className="flex-grow-1 d-flex align-items-center t-form-select-user">
                {['purchaser', 'user'].map(group => (
                  <label key={group} className="mr-3 d-flex align-items-center">
                    <input
                      type="radio"
                      name="group"
                      checked={input.group === group}
                      onChange={event => onChange({group: group})}
                      className="mr-1"
                    />
                    {t('purchaser:label.group_option_' + group)}
                  </label>
                ))}

                <a href=""
                   className="a-link ml-3"
                   onClick={this.onReset.bind(this)}
                >{t('purchaser:btn.reset_filter')}</a>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

FormFilter.defaultProps = {
  input: {},
  onChange: (input) => {
  },
};

FormFilter.propTypes = {
  input: PropTypes.object,
  onChange: PropTypes.func,
};

export default translate()(FormFilter);
