import React from 'react';
import {Table, Spin, Modal, Icon, Tooltip} from "antd";
import lodash from 'lodash';
import Pagination from "../../../common/Pagination";
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import UploadFileCSVContainer from "../../UploadFileCSV/UploadFileCSVContainer";
import UploadFileCSVGlobalContainer from "../../UploadFileCSVGlobal/UploadFileCSVGlobalContainer";
import UploadFileCSVResultContainer from './../../UploadFileCSVResult/UploadFileCSVResultContainer';
import {ReactComponent as IconDownload} from '../../../../resources/img/ic_file_download_48px.svg';
import {permissions} from '../../../Permission';
import authService from '../../../../modules/Auth/authService';
import Link from "../../../../systems/routing/Link";
import {trans} from '../../../../systems/i18n';
import UploadFileCSVMBillContainer from "../../UploadFileCSVMBill/UploadFileCSVMBillContainer";
import UploadFileWFCSVContainer from '../../UploadFileWFCSV/UploadFileCSVContainer';

const columns = [
    {
        title: 'Mã giao dịch',
        dataIndex: 'code',
        key: 'code',
        render: text => <span className="a-text--bold">{text}</span>,
    },
    {
        title: 'Mô tả lỗi',
        dataIndex: 'description',
        key: 'description',
    },
];

class ListFileUpload extends React.Component {
    state = {isShowModal: false, errors: []};

    showModal = (errors) => {
        if (errors !== []) {
            this.setState({
                visible: true,
                errors: errors
            });
        }
    };

    handleDownload(id, filename) {
        this.props.downloadFile(id, filename);
    }

    onChangePage(page, pageSize) {
        this.setState({
            visible: false,
        });
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            });
        }
    }

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };


    getColumn() {
        let {t} = this.props;
        let downloadPermission = authService.can(permissions.TRANSACTION_DOWNLOAD_FILE);
        return [
            {
                title: t("transaction:label.filename"),
                dataIndex: 'filename',
                key: 'filename',
                render: (text, record) => {
                    return <React.Fragment>
                                            <span
                                                className={"a-text--nowrap name-file" + (record.errorDisplay.length !== 0 ? " a-text--red" : "")}
                                                onClick={record.errorDisplay.length !== 0 ? this.showModal.bind(this, record.errorDisplay) : undefined}>
                        {record.total_transaction === 0 && <Icon type="loading" className="a-text--font-b"/>}
                                                {record.total_transaction !== 0 && record.total_transaction === (record.total_transaction_success + record.total_transaction_fail) && record.errorDisplay.length === 0 &&
                                                <Icon type="check-circle" className="a-text--success"/>}
                                                {record.errorDisplay.length !== 0 &&
                                                <Icon type="warning" className={"a-text--red"}/>}
                                                {record.total_transaction !== 0 && record.total_transaction !== (record.total_transaction_success + record.total_transaction_fail) &&
                                                <Icon type="sync" spin className="a-text--font-b"/>}
                                                <a>
                                                    <Tooltip placement="right"
                                                             title={record.type ? this.props.t("transaction:title." + record.type) : "Giao dịch"}>
                                                    {
                                                        (record.type === "alipay" || record.type === "apilay") ? <Icon type ="alipay-circle" className="ml-2"/> : ((record.type === "alipayGlobal") ?<Icon type="global" className="ml-2" /> : <Icon type="dollar" className="ml-2"/>)
                                                    }

                                                    </Tooltip>
                                                </a>
                                                <span className="ml-1">{text}</span>
                        </span>
                        <Link href={`/transactions?id_transaction_file=${record.id}`}>
                            <Icon
                                className="action-row-show-hover a--cursor-poiter ml-3"
                                type="profile"
                                theme="outlined"
                                style={{fontSize: '1rem', color: '#4287f5'}}
                                title={trans('transaction:label.list_transaction_by_file')}
                            />
                        </Link>
                    </React.Fragment>

                },
            }, {
                title: t("transaction:column.account"),
                dataIndex: 'account',
                key: 'account',
                className: "a-text--nowrap",
            },
            {
                title: t("transaction:label.id_creator"),
                dataIndex: 'creatorDisplay',
                key: 'creatorDisplay',
                className: "a-text--nowrap",
            }, {
                title: t("transaction:label.created_at"),
                dataIndex: 'created',
                key: 'created',
                render: text => <span className="a-text--nowrap">{text}</span>,
            }, {
                title: t("transaction:label.total_transaction"),
                dataIndex: 'total_transaction',
                key: 'total_transaction',
                className: 'a-text--right',
                render: text => <span className="a-text--nowrap">{text}</span>,
            }, {
                title: t("transaction:label.total_transaction_success"),
                dataIndex: 'total_transaction_success',
                key: 'total_transaction_success',
                className: 'a-text--right',
                render: text => <span className="a-text--nowrap">{text}</span>,
            },
            {
                title: t("transaction:label.collect_amount"),
                children: [
                    {
                        title: t("transaction:label.total_transaction_collect"),
                        dataIndex: 'total_transaction_collect',
                        key: 'total_transaction_collect',
                        className: 'a-text--right',
                        render: text => <span className="a-text--nowrap">{text}</span>,
                    },
                    {
                        title: t("transaction:label.transaction_collect_amount"),
                        dataIndex: 'transaction_collect_amount',
                        key: 'transaction_collect_amount',
                        className: 'a-text--right',
                        render: text => <span className="a-text--nowrap">{text}¥</span>,
                    }
                ],

            },
            {
                title: t("transaction:label.spend_amount"),
                children: [
                    {
                        title: t("transaction:label.total_transaction_spend"),
                        dataIndex: 'total_transaction_spend',
                        key: 'total_transaction_spend',
                        className: 'a-text--right',
                        render: text => <span className="a-text--nowrap">{text}</span>,
                    },
                    {
                        title: t("transaction:label.transaction_spend_amount"),
                        dataIndex: 'transaction_spend_amount',
                        key: 'transaction_spend_amount',
                        className: 'a-text--right',
                        render: text => <span className="a-text--nowrap">{text}¥</span>,
                    }
                ],

            },
            {
                title: t("transaction:label.total_transaction_fail"),
                dataIndex: 'total_transaction_fail',
                key: 'total_transaction_fail',
                className: 'a-text--right',
                render: text => <span className="a-text--nowrap">{text}</span>,
            }, {
                title: '',
                dataIndex: 'deal',
                key: 'deal',
                className: 'a-text--right',
                render: (_, record) => downloadPermission && <span
                    onClick={this.handleDownload.bind(this, record.id, record.filename)}
                    className="action-row-show-hover a--cursor-poiter"><IconDownload/></span>
            }, {
                title: t("transaction:label.transaction_time_start"),
                dataIndex: 'transaction_time_start',
                key: 'transaction_time_start',
                className: 'a-text--right',
                render: text => <span className="a-text--nowrap">{text}</span>,
            }, {
                title: t("transaction:label.transaction_time_end"),
                dataIndex: 'transaction_time_end',
                key: 'transaction_time_end',
                className: 'a-text--right',
                render: text => <span className="a-text--nowrap">{text}</span>,
            }
        ];
    }

    render() {
        let {pagination, filter, transactionFiles, loading, t, permission} = this.props;
        let uploadPermission = authService.can(permissions.TRANSACTION_UPLOAD_FILE);

        const {canUseWorldFirst, canUseGlobal, canUseMbill} =permission

        return (
            <div className="a-list mt-5">
                <div className="a-list__top a-flex">
                    <h2 className="a-list--title a-text--uppercase">{t("transaction:label.list_transaction_file")}
                        ({pagination.total})</h2>
                    <div className="ml-auto">
                        {
                            uploadPermission &&
                            <>
                                {canUseWorldFirst && <UploadFileWFCSVContainer loading={loading}/>}
                                <UploadFileCSVContainer loading={loading}/>
                                {
                                    canUseGlobal &&
                                    <UploadFileCSVGlobalContainer loading={loading} />
                                }
                                {
                                    canUseMbill &&
                                    < UploadFileCSVMBillContainer loading={loading}/>
                                }
                                 
                            </>
                        }
                    </div>
                </div>
                <div
                    className={"a-content--upload-csv " + (pagination.total < lodash.toInteger(filter.per_page) ? "" : " a-content--is-pagination-fixed")}>
                    <div className="a-list__body upload-csv ">
                        <Spin spinning={loading}>
                            <div className="a-table--responsive">
                                <Table
                                    rowKey="id"
                                    className={"a-table--antd"}
                                    columns={this.getColumn()}
                                    dataSource={transactionFiles}
                                    rowClassName={(record) => `row-show-item-hover ${record.errorDisplay.length !== 0 ? "error-deal" : ''}`}
                                    pagination={false}
                                />
                            </div>
                            <Pagination
                                page={lodash.toInteger(filter.page) || 1}
                                pageSize={lodash.toInteger(filter.per_page)}
                                total={pagination.total}
                                loading={this.props.loading}
                                onChange={this.onChangePage.bind(this)}
                            />
                            <UploadFileCSVResultContainer loading={loading}/>
                        </Spin>
                    </div>
                </div>
                <Modal
                    title="Danh sách dòng bị lỗi"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={false}
                    className={"a-modal--list-error-upload-file"}
                    width={800}
                >
                    <Table columns={columns} dataSource={this.state.errors} pagination={false}
                           className="a-table--antd"/>
                    {/*<span dangerouslySetInnerHTML={{__html: this.state.errors}}/>*/}
                </Modal>
            </div>
        )
    }
}

ListFileUpload.defaultProps = {
    filter: {page: 1, per_page: 20},
    loading: false,
    pagination: {
        total: 0
    },
    permission: {}
};

ListFileUpload.propTypes = {
    filter: PropTypes.object,
    loading: PropTypes.bool,
    pagination: PropTypes.object,
    onSearch: PropTypes.func,
    downloadFile: PropTypes.func,
    permission: PropTypes.object
};

export default translate()(ListFileUpload);
