import React from 'react';
import {translate} from 'react-i18next';
import Button from '@atlaskit/button';
import PropTypes from 'prop-types';
import {trans} from './../../../../systems/i18n';
import ModalGeneral from './../../../common/components/ModalGeneral';

class ModalConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        state.isLoading = nextProps.isLoading;
        return state;
    }


    /**
     * xử lý hành động submit form
     */
    onSubmitForm() {
        this.setState({isLoading: true});
        this.props.onSubmitForm();
    }

    render() {
        let {onClose, title, message, isLoading} = this.props;
        return (
            <ModalGeneral>
                <div className={"a-modal__title"}>
                    <p>
                        {title}
                    </p>
                </div>
                <div className={"row"}>
                    <div className={"col col-sm-12 col-md-12 col-lg-12 p-0"}>
                        <div className={'a-modal--cfprice--left'}>
                            <p>{message}</p>
                        </div>
                    </div>
                </div>

                <div className="a--group--inline-end mt-2 mb-3 a--user-group-btn">
                    <Button appearance="primary" type="submit"
                            className={"mr-3 a-btn--submit "}
                            onClick={this.onSubmitForm.bind(this)}
                            isLoading={isLoading}
                    >
                        {trans('sale:form.label_title_yes')}
                    </Button>
                    <a appearance="subtle" onClick={onClose}
                       className={(isLoading ? "hidden " : "") + "a-btn a-btn--none a-btn-link "}>
                        {trans('order_detail.btn_action_cancel')}
                    </a>
                </div>
            </ModalGeneral>
        );
    }
}

ModalConfirm.defaultProps = {
    onSubmitForm: () => {
    },
    onClose: () => {
    },
    isLoading: false,
    error: []
};

ModalConfirm.propTypes = {
    onSubmitForm: PropTypes.func,
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.array
};

export default translate('translations')(ModalConfirm);
