import {put, call} from 'redux-saga/effects';
import lodash from 'lodash';
import apiService from './apiService';
import {t} from '../../../systems/i18n';
import bootbox from './../../common/components/Bootbox/bootbox';
import * as Constants from './constants';
import * as GlobalConstants from './../../../systems/globalContant';
import {dispatch} from './../../../systems/store';
import {fetchProductReturnLogs} from '../actions';

/**
 * Hàm lấy xử lý thêm mới kiện hàng
 * @param action
 */
export function* createPackage(action) {
    try {
        let response = yield call(apiService.createPackage, action.payload);
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.CREATE_PACKAGE.SUCCESS, payload: response.data, request: action.payload});
            setTimeout(() => dispatch(fetchProductReturnLogs(action.payload.id)), 2000);
            bootbox.success(t('complaintSeller:product_return.message_product_return_create_package_success'));
        } else {
            yield put({type: Constants.CREATE_PACKAGE.FAILED, payload: response.data, request: action.payload});
        }
    } catch (err) {
        yield put({type: Constants.CREATE_PACKAGE.FAILED, payload: {}});
        bootbox.error(t(err.message) || t('system.error'));
    }
}

export function* onCreatePackageFailed(action) {
    let error = action.payload.data;

    if (action.payload.code !== GlobalConstants.GLOBAL_CONTANT.PERMISSION_DENIED) {
        if (lodash.get(error, 'package_code.ALREADY_EXIST')) {
            return yield bootbox.error(t('complaintSeller:product_return.message_package_attached'));
        } else if (lodash.get(error, 'status.status_invalid', false)) {
            yield bootbox.error(t('complaintSeller:product_return.message_status_change'));
        } else {
            return yield bootbox.error(t('complaintSeller:product_return.message_product_return_create_package_faild'));
        }
    }
}

/**
 * Hàm lấy xử lý xóa kiện hàng
 * @param action
 */
export function* removePackage(action) {
    try {
        let response = yield call(apiService.removePackage, action.payload);
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.DELETE_PACKAGE.SUCCESS, payload: response.data});
            setTimeout(() => dispatch(fetchProductReturnLogs(action.payload.id)), 2000);
            bootbox.success(t('complaintSeller:product_return.message_product_return_remove_package_success'));
        } else {
            let error = response.data;
            if (error.code !== GlobalConstants.GLOBAL_CONTANT.PERMISSION_DENIED) {
                if (lodash.get(error, 'status.status_invalid', false)) {
                    yield bootbox.error(t('complaintSeller:product_return.message_status_change'));
                } else {
                    bootbox.error(t('complaintSeller:product_return.message_product_return_remove_package_failed'));
                }
            }

            yield put({
                type: Constants.DELETE_PACKAGE.FAILED, payload: {...response.data.data, ...action.payload}
            });
        }
    } catch (err) {
        yield put({type: Constants.DELETE_PACKAGE.FAILED, payload: {}});
        bootbox.error(t(err.message) || t('system.error'));
    }
}