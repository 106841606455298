import React from "react";
import {Icon} from "antd";

const typeTitle = {
    valid: {
        message: "Giao dịch được thêm thành công",
        icon: "check-circle",
        className: "pt-1 a-text--success"
    },
    invalid: {
        message: "Giao dịch tồn tại trên hệ thống nhưng loại GD không hợp lệ",
        icon: "close-circle",
        className: "pt-1 a-text--red"
    },
    not_exist: {
        message: "Giao dịch không tồn tại trên hệ thống",
        icon: "close-circle",
        className: "pt-1 a-text--red"
    },
    already_exist: {
        message: "Giao dịch liên quan đã được quét",
        icon: "warning",
        className: "pt-1 a-text--warning"
    }
};

class ListMessageType extends React.Component {
    render() {
        const styleEle = typeTitle[this.props.type];
        if (this.props.data.length === 0) return null;

        return <div className="a-font--12 pt-2">
            <p className={styleEle.className}><Icon type={styleEle.icon} theme="filled" /> {styleEle.message}</p>
            <ul>
                {this.props.data.map((item, index) => (this.props.type === "valid") ? <li key={index}>{item.code}</li> : <li key={index}>{item}</li>)}
            </ul>
        </div>;
    }
}

export default ListMessageType;