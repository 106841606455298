export const INVOICE_CODE_UPLOAD_FILE = {
    REQUEST: 'INVOICE_CODE_UPLOAD_FILE_REQUEST',
    SUCCESS: 'INVOICE_CODE_UPLOAD_FILE_SUCCESS',
    FAILED: 'INVOICE_CODE_UPLOAD_FILE_FAILED',
};

export const TRANSACTION_UPLOAD_FILE_CLEAR_STATE = 'TRANSACTION_UPLOAD_FILE_CLEAR_STATE';
export const STATUS_UPLOAD_UPLOADING = 'uploading';
export const STATUS_UPLOAD_DONE = 'done';
export const STATUS_UPLOAD_ERROR = 'error';
