import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button, Icon} from "antd";

class ReceiveComplaint extends React.Component {
    componentWillUnmount() {
        this.props.onReset();
    }

    onReceive() {
        if (!this.props.loading) {
            this.props.onReceive();
        }
    }

    render() {
        return (
            <Button
                className="action-btn-cs recieve-btn-cs ml-2"
                type="primary"
                isLoading={this.props.loading}
                onClick={this.onReceive.bind(this)}
            >
                <Icon type="check" />
                {this.props.t('complaintSeller:reception.btn_receive')}
            </Button>
        );
    }
}

ReceiveComplaint.defaultProps = {
    loading: false,
    onReceive: () => {},
    onReset: () => {},
};

ReceiveComplaint.propTypes = {
    loading: PropTypes.bool,
    onReceive: PropTypes.func,
    onReset: PropTypes.func,
};

export default translate()(ReceiveComplaint);
