import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import Tooltip from '@atlaskit/tooltip';
import QuickSearch from "./QuickSearch";
import QuickView from "./QuickView";
import lodash from "lodash";

class ActionBottom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFilterCustomerNote: false,
            isFilterByPai: "",
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (nextProps.isFilterByPai !== prevState.isFilterByPai) {
            nextState.isFilterByPai = nextProps.isFilterByPai;
        }

        if (prevState.isFilterCustomerNote !== nextProps.isFilterCustomerNote) {
            nextState.isFilterCustomerNote = nextProps.isFilterCustomerNote;
        }

        return nextState;
    }

    /**
     * handle action change field fillter
     * @param field
     */
    changeFilterBy = (field, event) => {
        let {isFilterByPai} = this.state;
        if (isFilterByPai !== "" && isFilterByPai === field) {
            field = "";
        }
        this.props.changeFilterBy(field);
    }

    render() {

        let {
            t, order, error, items,
            onPaid, onPayment, defaultChecked,
            isLoadingCancelOrder,
            beginProcessCancelOrder,
            isLoadingOutOfStockOrder,
            beginProcessOutOfStock,
            onCancel,
            onConfirm,
            beginProcessUpdateOutStock,
            beginProcessUpdatePriceItems,
            beginProcessUpdateQuantityItems,
            isLoading,
            isLoadingData,
            successUpdateItems,
            isEndProcess,
            order_item_checked,
            check_all,
            onCheckAll,
            beginClearErrorUpdatePriceItems,
            onClearCheckAll,
            errorUnitPriceItems,
            updateOutOfStockItems,
            updateQuantityItems,
            clearErrorWhileUpdatePrice,
            onOutOfStock,
            updatePriceItems,
            isOpenMenuBar,
            onWaitingPayment,
            getPurchasingOrderDetailAction
        } = this.props;
        return (
            <React.Fragment>
                <div className={`a-purchasod__bottom ${isOpenMenuBar? "collapsed": ""}`}>
                    <div className="a-purchasod__bottom__block">
                        <QuickSearch
                            order={order}
                            items={items}
                            check_all={check_all}
                            defaultChecked={defaultChecked}
                            onCheckAll={onCheckAll}
                            beginProcessUpdateOutStock={beginProcessUpdateOutStock}
                            beginProcessUpdatePriceItems={beginProcessUpdatePriceItems}
                            beginProcessUpdateQuantityItems={beginProcessUpdateQuantityItems}
                            isLoading={isLoading}
                            isLoadingData={isLoadingData}
                            successUpdateItems={successUpdateItems}
                            isEndProcess={isEndProcess}
                            order_item_checked={order_item_checked}
                            beginClearErrorUpdatePriceItems={beginClearErrorUpdatePriceItems}
                            onClearCheckAll={onClearCheckAll}
                            errorUnitPriceItems={errorUnitPriceItems}
                            updateOutOfStockItems={updateOutOfStockItems}
                            updatePriceItems={updatePriceItems}
                            updateQuantityItems={updateQuantityItems}
                            clearErrorWhileUpdatePrice={clearErrorWhileUpdatePrice}
                            beginProcessOutOfStock={beginProcessOutOfStock}
                            onOutOfStock={onOutOfStock}
                            onCancel={onCancel}
                            isLoadingCancelOrder={isLoadingCancelOrder}
                            beginProcessCancelOrder={beginProcessCancelOrder}
                            isLoadingOutOfStockOrd={isLoadingOutOfStockOrder}
                            getPurchasingOrderDetailAction={getPurchasingOrderDetailAction}
                        />
                        <QuickView
                            order={order}
                            error={error}
                            onPaid={onPaid}
                            onPayment={onPayment}
                            onConfirm={onConfirm}
                            isLoadingData={isLoadingData}
                            changeFilterBy={this.changeFilterBy}
                            isFilterByPai={this.state.isFilterByPai}
                            changeFilterCustomerNote={this.props.changeFilterCustomerNote}
                            isFilterCustomerNote={this.state.isFilterCustomerNote}
                            onWaitingPayment={onWaitingPayment}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ActionBottom.defaultProps = {
    isFilterByPai: "",
    isFilterCustomerNote: false,
    isLoadingData: {},
};

ActionBottom.propTypes = {
    isFilterByPai: PropTypes.string,
    isFilterCustomerNote: PropTypes.bool,
    changeFilterCustomerNote: PropTypes.func,
    changeFilterBy: PropTypes.func,
    isLoadingData: PropTypes.object,
};


export default translate('translations')(ActionBottom);
