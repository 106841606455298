import React, {Component} from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {Icon, Input} from 'antd';
import apiService from "../../AccountPurchaser/apiService";
import {translate} from "react-i18next";
import {processResponse} from "../../../systems/api";
import {events} from "../../../systems/events";
import ACCOUNT_PURCHASER_EVENT from "./../events";
import notification from '../../../systems/notification';

class EditAccountPurchaserToken extends Component {
    constructor(props) {
        super(props);

        this.state = {
            access_token: '',
            showInput: false,
            loading: false,
        }
    }

    onChangeInput(param, event) {
        this.setState({
            [param]: event.target.value
        })
    }
    componentDidMount() {
        let token = lodash.get(this.props, 'token.access_token', '');
        this.setState({
            access_token: token,
            showInput: token === ''
        })
    }

    onEdit() {
        if (this.state.loading) {
            return;
        }

        this.setState({loading: true});
        let token = lodash.get(this.props, 'token.access_token', '');
        let {t, account} = this.props;
        let res = apiService.updateAccountPurchaserToken(account.id, {access_token: this.state.access_token})
            .finally(() => this.setState({loading: false}));

        let process = processResponse(res);

        process.then(res => {
            let access_token = lodash.get(res, 'data.account_purchase_token.access_token', '');
            events.dispatch(ACCOUNT_PURCHASER_EVENT.TOKEN_UPDATE.SUCCESS, {...res.data});
            notification.success(t('purchaser:message.token_edit_success'));
            this.onClose(access_token);
        });

        process.catch((error) => {
            events.dispatch(ACCOUNT_PURCHASER_EVENT.TOKEN_UPDATE.FAILED, {...error});
            this.onClose(token);
        });
    }

    onPressEsc = (e) => {
        e.key === 'Escape' && this.handleClose();
    };

    handleClose() {
        let token = lodash.get(this.props, 'token.access_token', '');
        this.onClose(token);
    }

    onClose(token) {
        !this.state.loading && this.setState({
            showInput: false,
            access_token: token
        })
    }

    handleEdit() {
        this.setState({
            showInput: true
        })
    }

    copyToClipboard = (e) => {
        let {t} = this.props;
        navigator.clipboard.writeText(this.state.access_token);
        notification.success(t('message.copied'))
    };
    render() {
        let {access_token, showInput} = this.state;

        let {account} = this.props;
        let token = lodash.get(this.props.token, 'access_token', '');
        return (
            <div className="d-flex flex-row align-items-center">
                {
                    token === '' || showInput
                        ?
                        <Input
                            onKeyUp={this.onPressEsc.bind(this)}
                            onPressEnter={this.onEdit.bind(this)}
                            onChange={this.onChangeInput.bind(this, 'access_token')}
                            disabled={this.state.loading}
                            placeholder={access_token === '' && 'Nhập access token'}
                            value={access_token}
                            type={'text'}
                        />
                        : <span
                            className="a--cursor-poiter a-text--primary"
                            onClick={this.copyToClipboard}>{access_token}</span>
                }
                {
                    showInput && token !== '' && <Icon
                        onClick={this.handleClose.bind(this)}
                        disabled={this.state.loading}
                        type="close"
                        className="a--cursor-poiter ml-3"
                    />
                }
                {
                    (!showInput && access_token !== '') &&
                    <Icon
                        type={"edit"}
                        disabled={this.state.loading}
                        className="ml-3"
                        onClick={this.handleEdit.bind(this)}
                    />
                }
            </div>
        );
    }
}

EditAccountPurchaserToken.propTypes = {};

export default translate()(EditAccountPurchaserToken);
