import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {Select} from 'antd';
import {USER_SOURCE} from '../constants';

const {Option} = Select;

class SelectOrderSources extends React.PureComponent {

    render() {
        let {t} = this.props;
        return (
            <Select
                value={this.props.value}
                mode={this.props.multiple ? 'multiple' : 'default'}
                onChange={this.props.onChange}
                placeholder={t("transaction:label.site_name")}
                noOptionsMessage={({inputValue: string}) => t('message.no_record')}
            >
                {this.props.allOption && <Option value="">{t('label.all')}</Option>}
                {lodash.values(USER_SOURCE).map(source => <Option key={source} value={source}>{source}</Option>)}
            </Select>
        );
    }
}

SelectOrderSources.defaultProps = {
    value: null,
    allOption: false,
    multiple: false,
    onChange: value => {},
};

SelectOrderSources.propTypes = {
    value: PropTypes.any,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
};

export default translate()(SelectOrderSources);
