import React from 'react';
import {Button, Table, Pagination, Select, Affix, Menu, Dropdown, Checkbox, Icon, Modal,Input} from "antd";

const Option = Select.Option;
const TextArea =Input.TextArea;
const menu = (
    <Menu className="a-dropdown--set-field__menu">
        <div className="close-field-menu">
            <Icon type="close"/>
        </div>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Thời gian </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Hành động</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Kho đích</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Nội dung quét </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Đơn hàng</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
    </Menu>
);
function CustomExpandIcon(props) {
    let text;
    if (props.expanded) {
        text = '';
    } else {
        text = '';
    }
    return (
        <a
            className="expand-row-icon"
            onClick={e => props.onExpand(props.record, e)}
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ color: 'blue', cursor: 'pointer' }}
        />
    );
}
class ListOrder extends React.Component {
    state = { visible: false };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };
    render() {

        const columns = [
            {
                title: 'Mã đơn',
                dataIndex: 'code',
                key: 'code',
                render: text => <a href="#" className="a-text--nowrap a-text--green-new">{text}</a>,
            },
            {
                title: 'Tiền hàng thu khác',
                dataIndex: 'money',
                key: 'money',
                className: "a-text--nowrap",
            }, {
                title: 'Phí VC thu khách',
                dataIndex: 'feecustomer',
                key: 'feecustomer',
                render: text => <span className="a-text--nowrap">{text}</span>,
            }, {
                title: 'Tổng thu',
                dataIndex: 'totalmoney',
                key: 'totalmoney',
                render: text => <span className="a-text--nowrap">{text}</span>,

            },{
                title: 'Lợi nhuận mặc cả',
                dataIndex: 'haggleprofit',
                key: 'haggleprofit ',
                render: text => <span className="a-text--nowrap a-text--red">{text}</span>,

            },{
                title: 'Lợi nhuận(%)',
                dataIndex: 'profit',
                key: 'profit',
                render: text => <span className="a-text--nowrap">{text}</span>,

            },{
                title: '',
                dataIndex: 'action',
                key: 'action',
                render: text => <a href="#" className="a-text--nowrap a-text--green-new action-row-show-hover">{text}</a>,

            }];
        const data = [];
        for (let i = 1; i < 20; i++) {
            data.push({
                code: `KABCDE${i}`,
                money: `$10.00`,
                feecustomer: `$10.00`,
                totalmoney: `$10.00`,
                haggleprofit: `$11.00`,
                profit:`22%`,
                action:`Tạo khiếu nại`,
                description: <div><Icon type="exclamation-circle" theme="filled" className="a-text--red  mr-1" /> <span className="a-text--red">Đơn này có lợi nhuận mặc cả âm</span>
                    <a href="#" className="a-text--green-new ml-2 a-text--decoration-underline" onClick={this.showModal}>Resolve</a>
                </div>
            });
        }
        return (
            <div className="a-list mt-5">
                <Affix>
                    <div className="a-list__top a-flex">
                        <h2 className="a-list--title a-text--uppercase">Danh sách đơn (200)</h2>
                        <div className="ml-auto">
                            <Button className=" a-font--12 aphz-50 a-btn--primary-ant ghost mr-4" size={"small"}>Xuất
                                CSV</Button>
                            <Dropdown overlay={menu} trigger={['click']} overlayClassName={'a-dropdown--set-field'}>
                                <Button className=" a-font--12 aphz-50 a-btn--primary-ant ghost " size={"small"}>Thiết lập cột dữ liệu</Button>
                            </Dropdown>
                        </div>
                    </div>
                </Affix>
                <div className="a-content--is-pagination-fixed">
                    <Table className={"a-table--antd a-table--description"} columns={columns} dataSource={data}
                           rowClassName="row-show-item-hover"
                           expandedRowRender={record => record.description}
                           defaultExpandAllRows={true}
                           expandIcon={CustomExpandIcon}
                           expandIconAsCell={false}
                           pagination={false}/>
                    <div className="a-pagination a-pagination--fixed ">
                        <Pagination defaultCurrent={5} total={200} className="a-pagination--detail"/>
                        <div className="a-pagination--select-number-item">
                            <span className="text-show mr-2">Hiển thị</span>
                            <Select defaultValue="10" className="a-select--select-number-show"
                                    getPopupContainer={trigger => trigger.parentNode}
                            >
                                <Option value="10">10</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                            </Select>
                        </div>
                    </div>
                </div>

                <Modal
                    width={360}
                    title="RESOLVE"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={false}
                >
                    <span>Giải thích lý do</span>
                    <TextArea />
                    <div className={"mt-2"}>
                        <Button className=" a-font--12 a-btn--primary-ant " >Resolve</Button>
                        <Button className=" a-font--12 a-btn--primary-ant ghost ml-3" >Huỷ</Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default ListOrder;