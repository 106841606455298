import {takeLatest} from 'redux-saga/effects';
import * as constants from './constants';
import * as saga from './saga';

export default function*() {
    yield takeLatest(constants.CREATE_EXPORT_REPORT.REQUEST, saga.createReports);
    yield takeLatest(constants.GET_LIST_REPORTS.REQUEST, saga.getListReports);
    yield takeLatest(constants.GET_CURRENT_REPORT.REQUEST, saga.getCurrentReports);
    yield takeLatest(constants.CANCEL_EXPORT_REPORT.REQUEST, saga.createCancelReports);
    yield takeLatest(constants.NEW_REQUEST_REPORT.REQUEST, saga.newRequestReport);
}
