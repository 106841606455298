import React from 'react';
import {connect} from 'react-redux';
import {t} from '../../../systems/i18n';
import {url} from '../../../systems/routing';
import {setPageTitle, setPageDescription, setBreadCrumb, setMenuActive} from '../../common/actions';
import {fetchUsers} from '../../User/actions';
import {attachPurchaser, detachPurchaser} from './actions';
import * as userSelector from './../../User/selectors';
import ManagePurchasers from './components/ManagePurchasers';
import lodash from "lodash";
import {setActiveSettingItem} from '../../Settings/SettingMenu/actions';

class ManagePurchasersContainer extends React.Component {
    componentDidMount() {
        this.props.setPageTitle(t('purchaser:page.title'));

        this.props.setPageDescription(t('purchaser:page.description'));

        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'purchaser:breadcrumb.list',
                'link': url.to('purchasers.list')
            },
        ]);

        this.props.setMenuActive('purchasers');

        this.props.fetchUsers();
        setActiveSettingItem('purchasers');
    }

    render() {
        return <ManagePurchasers {...this.props}/>;
    }
}

const mapStateToProps = (state) => {
    return {
        users: userSelector.getAllUser(state),
        permissions: lodash.get(state, 'User.me.user.permissions', [])
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAttachPurchaser: (userId) => {
            dispatch(attachPurchaser(userId));
        },
        onDetachPurchaser: (userId) => {
            dispatch(detachPurchaser(userId));
        },
        fetchUsers: () => {
            dispatch(fetchUsers({pagination: 0}));
        },
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManagePurchasersContainer)
