import {ORDER_STATUS} from '../constants';

export default class OrderStatus {
    constructor() {
        this.statuses = [
            ORDER_STATUS.WAITING_BUY,
            ORDER_STATUS.WAITING_PAI,
            ORDER_STATUS.PAID,
            ORDER_STATUS.WAITING_PAY,
            ORDER_STATUS.BOUGHT,
            ORDER_STATUS.SELLER_DELIVERY,
            ORDER_STATUS.RECEIVED,
            ORDER_STATUS.CANCELLED,
            ORDER_STATUS.OUT_OF_STOCK,
            ORDER_STATUS.TRANSPORTING,
            ORDER_STATUS.WAITING_DELIVERY,
            ORDER_STATUS.DELIVERING,
            ORDER_STATUS.DELIVERED
        ];
    }

    all() {
        return this.statuses;
    }

    has(status) {
        return this.statuses.includes(status);
    }

    isBefore(currentStatus, status) {
        if (!this.has(currentStatus) || !this.has(status)) {
            return false;
        }

        return this.indexOf(currentStatus) < this.indexOf(status);
    }

    isBeforeOrEqual(currentStatus, status) {
        return this.isBefore(currentStatus, status) || status === currentStatus;
    }

    isAfter(currentStatus, status) {
        return !this.isBeforeOrEqual(currentStatus, status);
    }

    isAfterOrEqual(currentStatus, status) {
        return !this.isBefore(currentStatus, status);
    }

    indexOf(status) {
        return this.statuses.indexOf(status);
    }
}