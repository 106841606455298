import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';

class CustomerInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            active: true
        }
    }

    /**
     * Function toggle block
     */
    onHandleClick() {
        this.setState({
            isOpen: !this.state.isOpen,
            active: !this.state.active,
        })
    }

    onOpenNewTab(url, event) {
        event.preventDefault();
        window.open(url, "_blank");
    }

    render() {
        let {t, order} = this.props;
        return (
            <div className={"a-purchasod__block__body customer"}>
                <div className="a-purchasod__group">
                        <span className="a-purchasod__group--label">
                            {t("order_detail.username")}
                        </span>
                    <span className="a-purchasod__group--value a-text--font-blue ">
                            {order.buyer_name} /
                                <a href={order.partner_url_user_detail}
                                   style={{textDecoration: 'none'}}
                                   onClick={this.onOpenNewTab.bind(this, order.partner_url_user_detail)}
                                >
                                   @{order.buyer_username}
                                </a>
                        </span>
                </div>
                <div className="a-purchasod__group">
                    <span className="a-purchasod__group--label">{t("order_detail.phone")} </span>
                    <span className="a-purchasod__group--value ">
                            {order.buyer_phone_mark}
                        </span>
                </div>
                <div className="a-purchasod__group">
                    <span className="a-purchasod__group--label">{t("order_detail.warehouse")} </span>
                    <span className="a-purchasod__group--value a-text--bold ">
                            {order.destination_warehouse}
                        </span>
                </div>

            </div>
        );
    }
}

CustomerInfo.defaultProps = {
    order: {}
};

CustomerInfo.propTypes = {
    order: PropTypes.object
};

export default translate('translations')(CustomerInfo);
