import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Col} from 'react-grid-system';
import MediaServicesRectangleIcon from '@atlaskit/icon/glyph/media-services/rectangle';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import {router, url} from './../../../../systems/routing/index';
import {Item} from '@atlaskit/navigation-next';

class ListOrderGroup extends React.Component {
    /**
     * Render dữ liệu tên nhóm ngành
     * @param props
     * @returns {XML}
     * @constructor
     */
    Description(props) {
        return (
            <p title={props.name || ""}
               name={props.id}
               onClick={() => {
                   router.redirect(url.to('orderGroup.manager') + '?id=' + props.id);
                   props.onFetchDetailOrderGroup(props);
               }}
               className="description-order-group">
                {props.name}
            </p>)
    };

    /**
     * Cập nhật trạng thái
     * @param orderGroup
     * @constructor
     */
    UpdateStatus(orderGroup) {
        orderGroup = {...orderGroup, ...{status: orderGroup.status ? 0 : 1}};
        this.props.onUpdateStatusOrderGroup(orderGroup);
    }

    render() {
        const {t, data = [], dataDetail = {}, permission} = this.props;
        let dataOrderGroups = data.map(dataItem => {
            return {
                ...dataItem, ...{
                    onClick: (event, eventClick = 'true') => {
                        // if (eventClick === null) {
                        //     this.UpdateStatus(dataItem);
                        // }
                    },
                    before: MediaServicesRectangleIcon
                }
            }
        });

        let dataList = [
            {
                title: 'order-group',
                items: dataOrderGroups,
            }];

        return (
            <Col xs={12} sm={12} md={12} lg={8} xl={8} className="column-left">
              <div className="a-content--box-shadow">
              {permission &&
                <a href={url.to('orderGroup.manager')}
                   title={t("order_group.create_order_group")}
                   onClick={this.props.onResetHandler}
                   className="nav-link nav-link-link order-group-link-create pl-0">
                    <EditorAddIcon/>
                    <span className="create_order_group">
                       {t("order_group.create_order_group")}
                   </span>
                </a>}
                {dataList.map(
                    ({title, items, itemComponent: ItemComponent = Item}) => (
                        <div className={'order_group--list'} key={title}>
                            {items.map((item) => {
                                let classNameDIv = 'item-order-group ';
                                classNameDIv += item.status ? 'active' : 'inactive';
                                if (item.id === lodash.get(dataDetail, 'id', null)) {
                                    classNameDIv += ' selected-item';
                                }
                                return (
                                    <div className={'order_group__item'}>
                                        <div className={classNameDIv} key={item.id} title={item.name} onClick={() => {
                                            router.redirect(url.to('orderGroup.manager') + '?id=' + item.id)
                                            this.props.onFetchDetailOrderGroup(item);
                                        }}
                                             style={{
                                                 margin: '4px 0',
                                                 alignItems: 'center',
                                                 display: 'flex',
                                                 flexDirection: 'row'
                                             }}
                                        >
                                            <ItemComponent {...item} />
                                            <p className={"a-order--group"}>
                                                <this.Description {...item}
                                                                  orderGroupSelectedId={lodash.get(dataDetail, 'id', null)}
                                                                  onFetchDetailOrderGroup={this.props.onFetchDetailOrderGroup}
                                                />
                                                <span className="list-code">{item.code}

                                        </span>
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ),
                )}
              </div>
            </Col>
        );
    }
}

ListOrderGroup.defaultProps = {
    data: []
};

ListOrderGroup.propTypes = {
    data: PropTypes.array
};

export default translate('translations')(ListOrderGroup);
