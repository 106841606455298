import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import MarkViolationOrder from './MarkViolationOrder';
import UnmarkViolationOrder from './UnmarkViolationOrder';
import ViolationReason from "./ViolationReason";
import lodash from "lodash";

function MarkingViolationOrder(props) {
    let {order} = props;
    let canMarkViolationStatuses = [
        'BOUGHT',
        'SELLER_DELIVERY',
        'RECEIVED'
    ];

    if (props.list) {
        return order.violation === 1 && <ViolationReason order={order} list/>
    } else {
        if (!lodash.includes(canMarkViolationStatuses, order.status)) {
            return null;
        }
        return (!order.orderDetail.violation || order.orderDetail.violation === 0)
            ? <MarkViolationOrder order={order}/>
            : <UnmarkViolationOrder order={order}/>;
    }

}

MarkingViolationOrder.defaultProps = {
    transaction: {},

};

MarkingViolationOrder.propTypes = {
    transaction: PropTypes.object,
};

export default translate()(MarkingViolationOrder);
