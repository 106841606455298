import {takeLatest, all, fork} from 'redux-saga/effects';
import * as constants from './constants';
import * as saga from './saga';
import managePurchaserWatcher from './ManagePurchasers/sagaWatcher';
import accessTokenWatcher from './AccessToken/sagaWatcher';

export default function* () {
    yield all([
        fork(managePurchaserWatcher),
        fork(accessTokenWatcher),
    ]);

    yield takeLatest(constants.GET_ME.REQUEST, saga.getMe);
    yield takeLatest(constants.GET_LIST_PURCHASER.REQUEST, saga.getPurchasers);
    yield takeLatest(constants.FETCH_ACCOUNT.REQUEST, saga.fetchAccount);
    yield takeLatest(constants.LOGOUT.REQUEST, saga.logout);
    yield takeLatest(constants.FETCH_USERS.REQUEST, saga.fetchUsers);
    yield takeLatest(constants.FETCH_USERS_COMPLAINT_SELLER.REQUEST, saga.fetchUsersComplaintSeller);
}
