export const FETCH_ORDER_GROUP = {
    REQUEST: 'FETCH_ORDER_GROUP_REQUEST',
    SUCCESS: 'FETCH_ORDER_GROUP_SUCCESS',
    FAILED: 'FETCH_ORDER_GROUP_FAILED',
};

export const CREATE_ORDER_GROUP = {
    REQUEST: 'CREATE_ORDER_GROUP_REQUEST',
    SUCCESS: 'CREATE_ORDER_GROUP_SUCCESS',
    FAILED: 'CREATE_ORDER_GROUP_FAILED',
};

export const FETCH_DETAIL_ORDER_GROUP = {
    REQUEST: 'FETCH_DETAIL_ORDER_GROUP_REQUEST',
    SUCCESS: 'FETCH_DETAIL_ORDER_GROUP_SUCCESS',
    FAILED: 'FETCH_DETAIL_ORDER_GROUP_FAILED',
};

export const UPDATE_ORDER_GROUP = {
    REQUEST: 'UPDATE_ORDER_GROUP_REQUEST',
    SUCCESS: 'UPDATE_ORDER_GROUP_SUCCESS',
    FAILED: 'UPDATE_ORDER_GROUP_FAILED',
};


export const CANCEL_FORM_ORDER_GROUP = {
    REQUEST: 'CANCEL_FORM_ORDER_GROUP_REQUEST',
    SUCCESS: 'CANCEL_FORM_ORDER_GROUP_SUCCESS',
    FAILED: 'CANCEL_FORM_ORDER_GROUP_FAILED',
};

export const UPDATE_STATUS_ORDER_GROUP = {
    REQUEST: 'UPDATE_STATUS_ORDER_GROUP_REQUEST',
    SUCCESS: 'UPDATE_STATUS_ORDER_GROUP_SUCCESS',
    FAILED: 'UPDATE_STATUS_ORDER_GROUP_FAILED',
};


export const RESET_ERROR_INPUT_ORDER_GROUP = {
    REQUEST: 'RESET_ERROR_INPUT_ORDER_GROUP_REQUEST',
    SUCCESS: 'RESET_ERROR_INPUT_ORDER_GROUP_SUCCESS',
    FAILED: 'RESET_ERROR_INPUT_ORDER_GROUP_FAILED',
};
