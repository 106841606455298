import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash, {isEmpty} from "lodash";
import GroupItem from "./GroupItem";
import Button from "@atlaskit/button";
import {Switch, Modal, Icon, Col, Row, Input} from "antd";
import {
    CONFIRM_CODES,
    CONFIRM_MESSAGES,
    TRANSACTION_TYPE_FINALI_ORDER,
    COMPLAINT_SELLER_CREATE_AUTO_BY_AGENCY,
    SYSTEM_SELLER_SOURCE, SYSTEM_CAN_EXPORTED_TOKEN
} from "../constants";
import FinalyOrder from "./FinalyOrder";
import ComplaintSellerByAgency from "./ComplaintSellerByAgency";
import Masonry from 'react-masonry-css';
import SellerSourceByPartner from "./SellerSourceByPartner";
import AccessTokenCanExportedByPartner from "./AccessTokenCanExportedByPartner";

const { confirm } = Modal;

class Setting extends React.Component {

    constructor(props) {
        super(props);

        let configSetting = lodash.omit(this.props.configSetting, 'TRANSACTION_TYPES');

        this.state = {
            params: [], // lưu các config thay đổi
            configSetting: configSetting,
            error: '',
            loading: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.loading !== prevProps.loading) {
            this.setState({loading: this.props.loading})
        }
    }

    /**
     * Thay đổi cấu hình có cấu hình cần xác nhận hoặc không
     */
    onChange(input) {
        if (CONFIRM_CODES.includes(input.code)) {
            confirm({
                title: 'Bạn muốn thay đổi cấu hình này ?',
                icon: <Icon type="warning"/>,
                content: CONFIRM_MESSAGES[input.code],
                onOk: () => {
                    this.onUpdateParams(input);
                    this.onUpdateConfigs(input);
                },
                onCancel: () => {}
            });
        } else {
            this.onUpdateParams(input);
            this.onUpdateConfigs(input);
        }
    }

    setError(code) {
        this.setState({
            error: code
        })
    }

    /**
     * Cập nhật params state theo dõi các cấu hình đã được chỉnh sửa
     * Nếu input truyền vào đã có trong state params thì cập nhật giá trị mới
     * Nếu không có thì thêm mới
     */
    onUpdateParams(input) {
        let {params} = this.state;
        let index = params.findIndex((param => param.code === input.code));

        if (index > -1)
            params[index] = input;
        else
            params.push(input);

        this.setState({params});
    }

    /**
     * Cập nhật configSetting state để hiển thị các giá trị đã thay đổi
     */
    onUpdateConfigs(input) {
        let {configSetting} = this.state;
        let updatedConfigSetting = {};

        Object.keys(configSetting).forEach((keyConfig) => {
            let group = configSetting[keyConfig];
            let groupItem = group.map((groupItem) => {
                return (groupItem.code === input.code) ? {...groupItem, ...input} : groupItem;
            });
            updatedConfigSetting = {...updatedConfigSetting, [keyConfig]: groupItem}
        });

        this.setState({configSetting: updatedConfigSetting})
    }

    /**
     * Call api update config
     * @param event
     */
    onSubmit(event) {
        event.preventDefault();
        let {params, error} = this.state;
        this.setState({loading: true});
        if (error !== '' || !isEmpty(params)) {
            this.props.updateConfigSystem(params);
        }
    }

    renderGroup = (groupKey, index) => {
        if (groupKey === "INVOICE_CODE") return null;

        let {t, permissionGroup} = this.props;
        let {configSetting} = this.state;
        let group = lodash.get(configSetting, groupKey, []);
        

        let groupUsing = lodash.head(group.filter(gr => gr.code === `${groupKey}:USING`));
        let permissionGroups = lodash.get(configSetting, permissionGroup, []);

        // groupUsing = undefined khi 1 nhóm quyền không có quyền {groupKey}:USING
        let isUsing = (groupUsing === undefined) ? true : !!parseInt(groupUsing.value, 10);

        // Lấy ra cấu hình có code là TRANSACTION:TYPE_FINALI_ORDER để hiển thị giao diện khác
        let finalyOrder = lodash.head(group.filter(item => item.code === TRANSACTION_TYPE_FINALI_ORDER));

        // Lấy ra cấu hình có code là TRANSACTION:TYPE_FINALI_ORDER để hiển thị giao diện khác
        let complaintSellerCreate = lodash.head(group.filter(item => item.code === COMPLAINT_SELLER_CREATE_AUTO_BY_AGENCY));

        // Lấy ra cấu hình có code là SYSTEM:SELLER_SOURCE để hiển thị giao diện khác
        let systemSellerSources = lodash.head(group.filter(item => item.code === SYSTEM_SELLER_SOURCE));

        // Lấy ra cấu hình có code là SYSTEM:CAN_EXPORTED_TOKEN để hiển thị giao diện khác
        let systemCanExportedToken = lodash.head(group.filter(item => item.code === SYSTEM_CAN_EXPORTED_TOKEN));
        if (!group.some(groupItem => permissionGroups.includes(groupItem.code)))
        {
            return null;
        }
        return (
            <>
                <Col span={12}>
                    <div className="a-content--box-shadow p-0 mb-5">
                        <div className="a-setting__title p-3">
                            <p>{t('setting:title.' + groupKey)}</p>
                        </div>

                        <div className="a-setting__body p-3">
                            {
                                group.map((groupItem, index) => {
                                    if (groupItem.code === TRANSACTION_TYPE_FINALI_ORDER) return null;
                                    if (groupItem.code === COMPLAINT_SELLER_CREATE_AUTO_BY_AGENCY) return null;
                                    if (groupItem.code === SYSTEM_SELLER_SOURCE) return null;
                                    if (groupItem.code === SYSTEM_CAN_EXPORTED_TOKEN) return null;

                                    return permissionGroups.includes(groupItem.code) ?
                                        <GroupItem
                                            t={t}
                                            key={index}
                                            groupItem={groupItem}
                                            onChange={this.onChange.bind(this)}
                                            setError={this.setError.bind(this)}
                                            isUsing={isUsing}
                                            inputVersion={index}
                                            statusUsing={groupItem.code === `${groupKey}:USING`}
                                            configSetting={configSetting}
                                        /> : null
                                })
                            }

                        </div>
                    </div>
                </Col>
                {
                    // Tự động tạo KNNB hiển thị bật tắt theo agency thì hiển thị giao diện khác
                    complaintSellerCreate && groupKey === "COMPLAINT_SELLER" ?
                        <ComplaintSellerByAgency
                            complaintSellerCreate={complaintSellerCreate}
                            configSetting={configSetting}
                            onChange={this.onChange.bind(this)}
                            isUsing={isUsing}
                        /> : null
                }
                {
                    // Bật tắt các loại giao dịch thì hiển thị giao diện khác
                    finalyOrder && permissionGroups.includes(finalyOrder.code) &&
                        <FinalyOrder
                            finalyOrder={finalyOrder}
                            configSetting={configSetting}
                            onChange={this.onChange.bind(this)}
                        />
                }
                {
                    // Chon nguon hang cho tung partner
                    systemSellerSources && groupKey === "SYSTEM" ?
                        <SellerSourceByPartner
                            configSetting={configSetting}
                            onChange={this.onChange.bind(this)}
                            systemSellerSources={systemSellerSources}
                        /> : null
                }
                {
                    // Chon nguon hang cho tung partner
                    systemCanExportedToken && groupKey === "SYSTEM" ?
                        <AccessTokenCanExportedByPartner
                            configSetting={configSetting}
                            onChange={this.onChange.bind(this)}
                            systemCanExportedToken={systemCanExportedToken}
                        /> : null
                }
            </>
        )
    };

    render() {
        let {t, permissionGroup} = this.props;
        let {configSetting, error, loading} = this.state;
        let permissionGroups = lodash.get(configSetting, permissionGroup, []);
        let settingsGroups = permissionGroups.map(setting => setting.substring(0,setting.indexOf(":")));

        return (
            <React.Fragment>
                <Row gutter={20}>
                {/*<Masonry*/}
                {/*    breakpointCols={2}*/}
                {/*    className="my-masonry-grid"*/}
                {/*    columnClassName="my-masonry-grid_column"*/}
                {/*>*/}
                    {
                        //[...new Set(settingsGroups)] trả về 1 mảng mới chứa các phần tử không trùng nhau
                        configSetting ?
                            [...new Set(settingsGroups)].map(this.renderGroup) : null
                    }
                {/*</Masonry>*/}


                    <Col span={24}>
                        <div className="setting__action d-flex">
                            <Button
                                className={`a-btn--setting_save mr-5 ${loading || error !== '' ? "disable" : ""}`}
                                appearance="primary"
                                isLoading={loading}
                                isDisabled={loading || error !== ''}
                                onClick={this.onSubmit.bind(this)}
                            >{t('setting:save')}</Button>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

Setting.defaultProps = {
    updateConfigSystem: (params) => {},
    configSetting: {}
};

Setting.propTypes = {
    configSetting: PropTypes.object
};


export default translate('translations')(Setting);
