import React from 'react';
import {Badge, Icon, Input, Tooltip} from 'antd';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import {trans} from "../../../../systems/i18n";

class InputAddInvoiceCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiceCode: "",
            idTransaction: 0,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (prevState.idTransaction !== nextProps.idTransaction) {
            nextState.idTransaction = nextProps.idTransaction;
            nextState.invoiceCode = "";
        }

        return nextState;
    }

    onChange(event) {
        this.setState({
            invoiceCode: event.target.value
        });

        if (this.props.callbackOnChange) {
            this.props.callbackOnChange(event.target.value);
        }
    }

    onEnter(event) {
        this.props.addInvoiceCode2Transaction({
            id_transaction: this.props.idTransaction,
            invoice_code: this.state.invoiceCode
        });
        this.setState({
            invoiceCode: ""
        });
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    render() {
        let {t, loading, invoiceCodeChanged} = this.props;
        return (
            <div className="d-flex justify-content-between">
                <Input
                    value={this.state.invoiceCode}
                    placeholder={t("transaction:placeholder.add_invoice_code")}
                    disabled={loading}
                    onChange={this.onChange.bind(this)}
                    onPressEnter={this.onEnter.bind(this)}
                />
                {
                    invoiceCodeChanged ?
                        <Badge
                            count={<Icon
                                type="check-circle"
                                theme="outlined"
                                style={{ color: '#52c41a', fontSize: '0.6em' }}
                                title={trans('transaction:label.invoice_code_changed')}
                            />}
                        > </Badge>
                        : null
                }
            </div>

        );
    }
}

InputAddInvoiceCode.defaultProps = {
    loading: false,
    idTransaction: 0,
};

InputAddInvoiceCode.propTypes = {
    idTransaction: PropTypes.number,
    addInvoiceCode2Transaction: PropTypes.func,
    clearState: PropTypes.func,
    callbackOnChange: PropTypes.func,
    loading: PropTypes.bool,
};

export default translate()(InputAddInvoiceCode);
