import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from './../UploadFileCSV/actions';
import UploadFileCSVResult from './components';
import lodash from 'lodash';

const mapStateToProps = (state, props) => {
    return {
        loading: props.loading  === true || lodash.get(state, 'Transaction.UploadTransactionFile.loading', false),
        list: lodash.get(state, 'Transaction.UploadTransactionFileResult.list', []),
    }
};

const mapDispatchToProps = (dispatch) => ({
    clearState: () => dispatch(actions.clearState()),
});

class UploadFileCSVWapper extends React.PureComponent {
    componentDidMount() {

    }

    render() {
        return <UploadFileCSVResult {...this.props}/>
    }
}

let UploadFileCSVContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadFileCSVWapper);

UploadFileCSVContainer.defaultProps = {
    value: null,
    autoSelect: false,
    onChange: value => {
    },
};

UploadFileCSVContainer.propTypes = {
    autoSelect: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default UploadFileCSVContainer;
