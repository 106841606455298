import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from "react-i18next";
import lodash from "lodash";
import {Empty, Table} from "antd";
import EditAccountPurchaserToken from "../../../modules/AccountPurchaser/EditAccountPurchaserToken";

const COLUMN = {
    ACCOUNT: 'account',
    SITE_NAME: 'site_name',
    TOKEN: 'token',
};

class ListToken extends Component {
    constructor(props) {
        super(props);
    }

    getDataSource() {
        let {t, tokens} = this.props;
        return tokens.map(request => {
            return {
                key: request.id,
                [COLUMN.ACCOUNT]: request.account,
                [COLUMN.SITE_NAME]: request.site_name && request.site_name.length > 0 && request.site_name.map(site => {
                    return <p className={"d-flex mb-1"}>
                                    <span className="a-form__group__value">
                                        <span className={"img-logo-partner _" + site.toLowerCase()}>
                                        </span>
                                    </span>{site}</p>
                }),
                [COLUMN.TOKEN]: <EditAccountPurchaserToken account={request} token={lodash.get(request, 'account_purchase_token', {})}/>
            }
        })
    }

    getColumns() {
        let {t} = this.props;

        let columns = [
            {
                key: COLUMN.ACCOUNT,
                selected: true,
                width: 500,
            },
            {
                key: COLUMN.SITE_NAME,
                selected: true,
                width: 300,
            },
            {
                key: COLUMN.TOKEN,
                selected: true,
                className: 'a-text--break-all'
            }
        ];
        return columns.map(column => ({
            dataIndex: column.key,
            title: t('purchaser:column.' + column.key),
            ...column,
        }));
    }

    render() {
        let {tokens, t} = this.props;
        return (
            <div className="a-list mt-5">
                <div>
                    <Table
                        className={"a-table--antd a-table--description"}
                        columns={this.getColumns()}
                        dataSource={this.getDataSource()}
                        pagination={false}
                        scroll={{x: 'fit-content'}}
                        loading={this.props.loading}
                        locale={{
                            emptyText: (
                                <Empty
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                    description={<span>{t("transaction:label.no_data")}</span>}
                                >
                                </Empty>)
                        }}
                    />
                </div>
            </div>
        );
    }
}

ListToken.propTypes = {};

export default translate()(ListToken);
