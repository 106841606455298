import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';

class InvoiceCodes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    expandToggle() {
        let {isOpen} = this.state;
        this.setState({isOpen: !isOpen});
    }

    render() {
        let {invoiceCodes} = this.props;
        let {isOpen} = this.state;
        let invoiceCodeFirst = lodash.head(invoiceCodes);
        invoiceCodes = lodash.slice(invoiceCodes, 1, invoiceCodes.length);
        return (
            <React.Fragment>
                <React.Fragment>
                    <p className={"a-text--bold a-text--green"}>
                        {lodash.get(invoiceCodeFirst, 'invoice_code', '---')}
                    </p>
                    {invoiceCodes.length ?
                        <span className={"more__codebill "+(isOpen? "hidden":"" )}
                              onClick={this.expandToggle.bind(this)}>
                            {invoiceCodes.length <= 9 ? invoiceCodes.length + '+' : "9+" }
                        </span>
                        : null
                    }
                </React.Fragment>
                {invoiceCodes.length ? (
                    <React.Fragment>
                        {invoiceCodes.map(invoiceCodeItem => (
                                <p key={invoiceCodeItem.invoice_code}
                                   className={"a-text--bold a-text--green "+(isOpen? "":"hidden" )}>
                                    {invoiceCodeItem.invoice_code}
                                </p>
                            )
                        )}
                    </React.Fragment>
                ) : null}
            </React.Fragment>
        );
    }
}

InvoiceCodes.defaultProps = {
    dataOrder: {}
};

InvoiceCodes.propTypes = {
    dataOrder: PropTypes.object
};

export default translate('translations')(InvoiceCodes);

