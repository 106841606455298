import React from 'react'
import { Table, Spin, Affix, Row, Col } from 'antd'
import lodash from 'lodash'
import Pagination from '../../../common/Pagination'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import ExportCSV from '../../ExportCSV/ExportCSV'
import authService from '../../../Auth/authService'
import * as PERMISSION from '../../../Permission/permissions'
import { t } from '../../../../systems/i18n'
import MarkViolationOrder from '../../../Order/MarkingViolationOrder'
import { url } from '../../../../systems/routing'
import Link from '../../../../systems/routing/Link'
import OrderProblems from '../../OrderProblems'
import CreateComplaintSeller from '../../../Order/ListOrder/components/CreateComplaintSeller'

function CustomExpandIcon(props) {
    let text = ''
    return (
        <a
            className="expand-row-icon"
            onClick={e => props.onExpand(props.record, e)}
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ color: 'blue', cursor: 'pointer' }}
        />
    )
}

class ListTransactionOrder extends React.Component {
    onChangePage(page, pageSize) {
        this.setState({
            visible: false,
        })
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            })
        }
    }

    getColumn() {
        let { t } = this.props
        return [
            {
                title: t('transaction:label.order_code'),
                dataIndex: 'code',
                key: 'code',
                className: '_transaction-column-order-code',
                render: (text, record) => {
                    return (
                        <>
                            <a href={record.partner_url_order_detail} target="_blank" className="a-text--bold">
                                {text}
                            </a>
                            <br />
                            {authService.can(PERMISSION.ORDER_FINANCIAL_VIEW) && (
                                <Link href={url.to('purchase.problem', { id: record.id })} className="a-text--nowrap a-text--fz-11 a-text--blue">
                                    Chi tiết đơn
                                </Link>
                            )}
                        </>
                    )
                },
            },
            {
                title: t('transaction:label.invoice_codes'),
                dataIndex: 'invoiceCodes',
                key: 'invoiceCodes',
                className: '_transaction-column-invoice-codes',
                render: text =>
                    !lodash.isEmpty(text)
                        ? text.map((item, index) => (
                              <p key={index}>
                                  <a href={item.link} target="_blank">
                                      {item.invoice_code}
                                  </a>
                              </p>
                          ))
                        : '---',
            },
            {
                title: t('transaction:label.grand_total_bought_display'),
                dataIndex: 'grand_total_bought_display',
                key: 'grand_total_bought_display',
                className: 'a-text--right _transaction-column-grand-total-bought-display',
                render: text => <span className="a-text--nowrap">{text}</span>,
            },
            {
                title: t('transaction:label.total_shipping_fee_inland_display'),
                dataIndex: 'total_shipping_fee_inland_display',
                key: 'total_shipping_fee_inland_display',
                className: 'a-text--right _transaction-column-total-shipping-fee-inland-display',
                render: text => <span className="a-text--nowrap ">{text}</span>,
            },
            {
                title: t('transaction:label.collect_amount'),
                dataIndex: 'collect_amount',
                key: 'collect_amount',
                className: 'a-text--right _transaction-column-collect-amount',
                render: text => <span className="a-text--nowrap">{text}</span>,
            },
            {
                title: t('transaction:label.spend_amount'),
                dataIndex: 'spend_amount',
                key: 'spend_amount',
                className: 'a-text--right _transaction-column-spend-amount',
                render: text => <span className="a-text--nowrap">{text}</span>,
            },
            {
                title: t('transaction:label.profit_display'),
                dataIndex: 'profit_display',
                key: 'profit_display',
                className: 'a-text--right _transaction-column-profit-display',
                render: (text, record) => (
                    <span className={'a-text--nowrap ' + (record.problem === 'NO_PROBLEM' ? '' : ' a-text--red ')}>
                        {record.problem === 'NO_PROBLEM' ? text : <b>{text}</b>} ({record.profit_percent_display})
                    </span>
                ),
            },
            // {
            //     title: t("transaction:label.profit_percent_display") + " %",
            //     dataIndex: 'profit_percent_display',
            //     key: 'profit_percent_display',
            //     className: 'a-text--right',
            //     render: text => <span className="a-text--nowrap">{text}</span>,
            // },
            {
                title: t('transaction:label.time_mark'),
                dataIndex: 'bought_at',
                key: 'bought_at',
                className: 'a-text--right _transaction-column-time-mark',
                render: (text, record) => {
                    return (
                        <div className="a-text--fz-12 a-text--color-black-65">
                            <p className="a-text--nowrap">Đặt cọc: {record.deposited_at}</p>
                            <p className="a-text--nowrap">Mua: {text}</p>
                            <p className="a-text--nowrap">Hủy: {record.cancelled_at}</p>
                        </div>
                    )
                },
            },
            // {
            //     title: t("transaction:label.deposited_at"),
            //     dataIndex: 'deposited_at',
            //     key: 'deposited_at',
            //     className: 'a-text--right',
            //     render: text => <span className="a-text--nowrap">{text}</span>,
            // },
            {
                title: 'NV mua',
                dataIndex: 'teller',
                key: 'teller',
                className: 'a-text--right _transaction-column-teller',
                render: (text, record) => {
                    let teller_name = lodash.get(record, 'teller.name', '---')
                    return <span className="a-text--nowrap">{teller_name}</span>
                },
            },
            {
                title: 'Agency',
                dataIndex: 'agency',
                key: 'agency',
                className: 'a-text--right _transaction-column-agency',
                render: (text, record) => {
                    return <span className="a-text--nowrap">{lodash.get(record, 'agency.name', '---')}</span>
                },
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                className: 'a-text--right _transaction-column-action',
                render: (text, record) => {
                    return (
                        <div style={{ fontSize: '0.8em' }} className="d-flex justify-content-end">
                            <CreateComplaintSeller order={record} complaintSellerReasons={this.props.complaintSellerReasons} />
                        </div>
                    )
                },
            },
        ]
    }

    render() {
        let { pagination, filter, orders, loading, t } = this.props
        let rows = this.props.orders.map(order => order.key)
        return (
            <div className="a-list mt-5">
                <Affix>
                    <div className="a-list__top a-flex">
                        <h2 className="a-list--title a-text--uppercase">
                            {t('transaction:label.list_transaction_order')}({pagination.total})
                        </h2>
                        <div className="ml-auto">
                            <ExportCSV
                                filter={this.props.filter}
                                csvFilter={this.props.csvFilter}
                                target={'transaction_order'}
                                t={this.props.t}
                                loading={this.props.loading}
                                resetError={this.props.resetError}
                            />
                        </div>
                    </div>
                </Affix>

                <div className={lodash.toInteger(pagination.page_total) > 1 ? 'a-content--is-pagination-fixed' : ''}>
                    <div className="a-list__body upload-csv ">
                        <Spin spinning={loading}>
                            <div className="a-table--responsive">
                                <Table
                                    columns={this.getColumn()}
                                    dataSource={orders}
                                    className={'a-table--antd a-table--description'}
                                    rowClassName="row-show-item-hover"
                                    expandedRowRender={record => {
                                        return (
                                            <div className="a-font--12">
                                                <OrderProblems order={record} />
                                                <MarkViolationOrder order={record} list />
                                            </div>
                                        )
                                    }}
                                    expandedRowKeys={rows}
                                    expandIcon={CustomExpandIcon}
                                    expandIconAsCell={false}
                                    pagination={false}
                                />
                            </div>
                            {lodash.toInteger(pagination.page_total) > 1 && (
                                <Pagination
                                    page={lodash.toInteger(filter.page) || 1}
                                    pageSize={lodash.toInteger(filter.per_page)}
                                    total={pagination.total}
                                    loading={this.props.loading}
                                    onChange={this.onChangePage.bind(this)}
                                />
                            )}
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}

ListTransactionOrder.defaultProps = {
    filter: { page: 1, per_page: 20 },
    loading: false,
    pagination: {
        total: 0,
    },
    orders: [],
}

ListTransactionOrder.propTypes = {
    filter: PropTypes.object,
    loading: PropTypes.bool,
    pagination: PropTypes.object,
    onSearch: PropTypes.func,
    orders: PropTypes.array,
}

export default translate()(ListTransactionOrder)
