import React, {PureComponent} from 'react';
import lodash from 'lodash';
import {Icon, Input} from "antd";
import apiService from "../apiService";
import {processResponse} from "../../../systems/api";
import notification from "../../../systems/notification"
import {translate} from "react-i18next";


class EditAmountPaymentRequest extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            amount: lodash.ceil(lodash.get(this.props.request, 'amount'), 2)
        };
        this.inputRefs = React.createRef();
    }


    handleOnClickEditPriceOrder = () => {
        this.setState({
            edit: true
        }, () => {
            this.inputRefs.current.focus();
        })
    };

    onBlur = () => {
        this.setState({
            edit: false
        })
    };
    onChange = (e) => {
        const {value} = e.target;
        const regex = /^[]?\d*\.?\d{0,5}$/;
        if (!(regex.test(value) || value === "")) return;
        this.setState({
            amount: e.target.value
        })
    };

    onPressEnter = () => {
        let {request: {id}, t} = this.props;
        let res = apiService.editAmountPaymentRequest(id, {amount: this.state.amount});
        let process = processResponse(res);
        process.then(res => {
            const amount = lodash.ceil(lodash.get(res, 'data.amount'), 2);
            this.setState({
                edit: false,
                amount
            });
            notification.success(t("message.update_amount_success"));
        });
        process.catch(error => {
            notification.error(t('message.cant_update_data'));
        })
    };

    render() {
        const {request: {canUpdateAmount}, currencySymbol} = this.props;
        const {edit, amount} = this.state;
        return (
            edit ?
                <div style={{minWidth: 100}}>
                    <Input
                        ref={this.inputRefs}
                        value={amount}
                        onPressEnter={this.onPressEnter}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                    />
                </div> :
                <div style={{minWidth: 100}}>
                    <span>{currencySymbol}{amount}</span>
                    {
                        canUpdateAmount && <a onClick={this.handleOnClickEditPriceOrder} className="ml-1"><Icon type="edit"/></a>
                    }
                </div>

        );
    }
}

export default translate()(EditAmountPaymentRequest);
