import React from 'react';
import LogMessage from "../../../common/Log/components/LogMessage";
import lodash from "lodash";
import {trans} from "../../../../systems/i18n";
import {Spin} from 'antd';
import {dateFormatter} from "./../../../../modules/common/services/format";

class LogTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scroll: false,
            transactionId: '',
        };
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scroll = window.pageYOffset > 74;

        if (this.state.scroll !== scroll) {
            this.setState({scroll});
        }
    };
    render() {
        let {logs, loading} = this.props;
        return (
            <div className={`a-log--transaction ${this.state.scroll ? 'scroll' :''}`}>
                <div className="a-log--transaction__title">
                    <span className="title">{trans('transaction:label.log')}</span>
                </div>
                {
                    loading ?
                        <Spin className="clear-both pt-5" size="large"/> :
                        <ul className="a-log--transaction__body">

                            {logs.map(log => (
                                <React.Fragment>
                                    <li className="a-log--transaction__item a-text--fz-14 mb-4 px-2" key={log.id}>
                                        <div className="d-flex justify-content-between mb-1">
                                            <span className="creator-log">
                                                <b>
                                                    {lodash.get(log, 'creator.name')}
                                                </b>
                                            </span>
                                            <span className="time-log">
                                            {
                                                dateFormatter.dateTime(lodash.get(log, 'time') * 1000)
                                            }
                                            </span>
                                        </div>
                                        {/*Component hiển thị log hành động đơn lẻ, truyền vào props log*/}
                                        <LogMessage log={log}/>
                                    </li>
                                </React.Fragment>
                            ))}
                        </ul>
                }

            </div>
        )
    }
}

export default LogTransaction;
