import {createSelector} from 'reselect';
import lodash from 'lodash';

const orderGroupLists = state => lodash.get(state, 'orderGroup.orderGroups.list', []);
const detaiOrderGroup = state => lodash.get(state, 'orderGroup.orderGroups.dataDetail', {});

export const orderGroups = createSelector(
    orderGroupLists,
    items => items
);

export const dataOrderGroup = createSelector(
    detaiOrderGroup,
    items => items
);
