import React from 'react';
import {translate} from 'react-i18next';
import CheckboxComponent from '../../common/components/Checkbox';
import IconTime from '@atlaskit/icon/glyph/emoji/frequent';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import lodash from "lodash";

class ListAccountPurchaser extends React.Component {
    /**
     * handle click status button
     * @param accountPurchaser
     * @private
     */
    _handleClickChangeStatus(accountPurchaser) {
        if (this.props.permission) {
            this.props.openModalChangeStatus(accountPurchaser);
        }
    }

    /**
     * handle click edit button
     * @param accountPurchaser
     * @private
     */
    _handleClickEdit(accountPurchaser, event) {
        event.preventDefault();
        this.props.openModalEdit(accountPurchaser);
    }

    render() {
        let {t, list, permission} = this.props;
        return (
            <table className="a-table a-table-responsive">
                <thead>
                <tr>
                    {/*<th><CheckboxComponent/></th>*/}
                    <th>{t("account_purchaser.account")}</th>
                    <th>{t("account_purchaser.email_or_phone")}</th>
                    <th>{t("account_purchaser.account_number")}</th>
                    <th>{t("account_purchaser.last_number_on_invoice_code")}</th>
                    <th>{t("account_purchaser.site_name")}</th>
                    <th>{t("account_purchaser.time")}</th>
                    <th>{t("account_purchaser.status")}</th>
                </tr>
                </thead>
                <tbody>
                {list.map(accountPurchaser =>
                    <tr key={accountPurchaser.id}>
                        {/*<td><CheckboxComponent/></td>*/}
                        <td data-title={t("account_purchaser.account")} className={'max-width-300'}>{accountPurchaser.account}</td>
                        <td data-title={t("account_purchaser.email_or_phone")} className={'max-width-300'}>{accountPurchaser.email_or_phone}</td>
                        <td data-title={t("account_purchaser.account_number")} className={'max-width-300'}>{accountPurchaser.account_number || ""}</td>
                        <td data-title={t("account_purchaser.last_number_on_invoice_code")}>{accountPurchaser.last_number_on_invoice_code || ""}</td>
                        <td data-title={t("account_purchaser.site_name")}>
                            {(accountPurchaser.site_name && accountPurchaser.site_name.length > 0 && accountPurchaser.site_name.map(site => {
                                return <p className={"d-flex mb-1"}>
                                    <span className="a-form__group__value">
                                        <span className={"img-logo-partner _" + site.toLowerCase()}>
                                        </span>
                                    </span>{site}</p>
                            }))}
                        </td>
                        <td data-title={t("account_purchaser.time")}>
                            <p className="a-text--row-center a-text--font-b a-text--font-12">
                                <IconTime className="icon"/>
                                <span className="ml-2">
                                    {t("account_purchaser.created_at")} <Moment
                                    format="DD/MM HH:mm">{accountPurchaser.created_at}</Moment>
                                </span>
                            </p>
                        </td>

                        <td data-title={t("account_purchaser.status")}  className={"text-right"}>
                            <span
                                className={"mb-2 a--cursor-poiter " + (accountPurchaser.status.toLocaleLowerCase() === 'active' ? "a-label--success" : "a-label--danger")}
                                onClick={this._handleClickChangeStatus.bind(this, accountPurchaser)}
                            >
                                {t("account_purchaser.status_" + accountPurchaser.status.toLocaleLowerCase())}
                            </span>
                            {permission &&
                            <p><a href="about:blank" onClick={this._handleClickEdit.bind(this, accountPurchaser)}>
                                {t("account_purchaser.btn_edit")}
                            </a></p>}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        );
    }
}

ListAccountPurchaser.defaultProps = {
    list: []
};

ListAccountPurchaser.propTypes = {
    list: PropTypes.array,
    openModalChangeStatus: PropTypes.func,
    openModalEdit: PropTypes.func
};

export default translate('translations')(ListAccountPurchaser);
