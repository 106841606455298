import React from 'react';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {Container, Row, Col} from 'react-grid-system';
import PropTypes from 'prop-types';
import FormStats from "./FormStats";
import ItemPurchase from './ItemPurchase';
import FormSearchHeader from './FormSearchHeader';
import Pagination from './../../../common/components/Pagination';
import {REAL_AMOUNT_OPERATION} from "../../constants";
import PusherComponent from "../../../common/components/Pusher/index";
import queryString from 'query-string';
import * as GlobalConstant from './../../../../systems/globalContant';
import FormPerformAction from "./FormPerformAction";
import Link from "../../../../systems/routing/Link";
import {Icon} from "antd";
import SwitchScreen from "../../../SwitchScreen";

const stateDefault = {
    code: '',
    invoice_code: '',
    seller_source: [],
    complaint_seller: '',
    total_item_link: '',
    real_amount_compare: '',
    grand_total_to: '',
    grand_total_from: '',
    log_status_to: '',
    log_status_from: '',
    wrong_process: '',
    sort: 'asc',
    id_purchase_address: []
};

const siteItems = [
    {label: 'taobao', value: 'taobao'},
    {label: 'tmall', value: 'tmall'},
    {label: '1688', value: '1688'},
];

class ListPurchases extends PusherComponent {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            code: '',
            formData: {item_checked: []}
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.props.setPageTitle(this.props.t('layout.title.list_purchase'));
        this.props.setPageDescription(this.props.t('layout.description.list_purchase'));
        this.props.setMenuActive("ListPurchase");
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.list_purchase',
                'link': '/list_purchase/'
            },
        ]);
        if (Array.isArray(this.props.purchaseAddresses) && this.props.purchaseAddresses.length === 0) {
            this.props.getPurchaseAddressList({pagination: 0, status: 1});
        }

        if (Array.isArray(this.props.warehouses) && this.props.warehouses.length === 0) {
            this.props.getListWarehouse({pagination: 0, status: 1});
        }

        if (Array.isArray(this.props.agencies) && this.props.agencies.length === 0) {
            this.props.getListAgency({});
        }

        let {user} = this.props;
        let address_default = lodash.get(user, 'user.purchaseAddress', []);
        address_default = address_default.map((address_default_item) => {
            return address_default_item.id
        });
        // Nếu không sử dụng cấu hình kho phân phối nhận đơn
        if (!this.props.isDistributiveWarehouseSetting && !this.props.isUsingOrderDistributorSetting) {
            let {formData} = this.state;
            let item_checked = lodash.concat(formData.item_checked, address_default);
            item_checked = lodash.uniq(item_checked);
            this.updateFormData({item_checked: item_checked});
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (Array.isArray(nextProps.warehouses) && nextProps.warehouses.length === 1) {
            nextState.formData = {item_checked: [nextProps.warehouses[0].value]};
        }
        return nextState;
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        super.componentDidUpdate(prevProps, prevState, prevContext);
    }

    /**
     * handle hành động nhận message
     * @param text
     * @param id
     * @param channel
     * @private
     */
    _pusherOnMessage(text, id, channel) {
        let dataObject = queryString.parse(text, {arrayFormat: 'bracket'});
        if (channel.startsWith(GlobalConstant.CHANNEL_ASSIGN_TELLER)) {
            this.props.pusherAssignOrder(dataObject);
        }

        if (channel.startsWith(GlobalConstant.CHANNEL_UNASSIGN_TELLER)) {
            this.props.pusherUnassignOrder(dataObject);
        }

        if (channel.startsWith(GlobalConstant.CHANNELS.ORDER_READ_COMMENT)) {
            this.props.pusherReadComemnt(dataObject);
        }
    }

    /**
     * handle action change filter
     * @param filter
     */
    onSearch(filter) {
        filter = this.customFilter(filter);
        this.props.onSearch({...filter, page: 1});
    }

    customFilter(filter) {
        if (filter.grand_total_to) {
            filter.grand_total_to = filter.grand_total_to * 1000;
        }

        if (filter.grand_total_from) {
            filter.grand_total_from = filter.grand_total_from * 1000;
        }

        if (filter.operation === REAL_AMOUNT_OPERATION.GREAT) {
            filter.total_item_link_from = filter.total_item_link;
        }

        if (filter.operation === REAL_AMOUNT_OPERATION.LESS) {
            filter.total_item_link_to = filter.total_item_link;
        }

        filter = lodash.omit(filter, ['operation, total_item_link']);
        return filter;
    }

    onAdvanceFilter() {
        this.setState({isOpenFilter: !this.state.isOpenFilter})
    }

    /**
     * handle change page of list
     * @param page
     */
    onChangePage(page) {
        let {filter} = this.props;
        window.scrollTo(0, 0);
        this.setState({page: page});
        this.props.onSearch({...filter, page: page});
    }

    /**
     * Xu ly khi GDV bam nut nhan don
     *
     * @param formData
     */
    onRequestDistributor(data) {
        let {user} = this.props;
        let params = {...data, ...{id_user: user.user.id}};
        this.props.requestOrdersForBuying(params);
    }

    componentWillUnmount() {
        this.props.clearAllCountStatusChanged();
    }

    onChangeInput(name, event) {
        this.setState({[name]: event.target.value});
    }

    onKeyPressInput(input, event) {
        if (event.key === 'Enter') {
            let {filter} = this.props;
            filter = {...filter, ...input};
            this.onSearch(filter);
        }
    }

    onChangeFormInput(param, value) {
        let {filter} = this.props;
        filter = {...filter, ...{[param]: value}};
        this.setState({[param]: value});
        this.onSearch(filter);
    }


    /**
     * Hàm thực hiện đánh dấu chọn hoặc bỏ chọn tích địa chỉ
     *
     * @param event
     */
    onCheckItem(event) {
        let target = event.target;
        let {warehouses} = this.props;
        let value = lodash.toInteger(target.value);
        let checked = target.checked;
        let check_all = false;
        let item_checked = lodash.get(this.state, 'formData.item_checked', []);
        // Nếu bỏ tích chọn
        if (!checked) {
            item_checked = lodash.difference(item_checked, [value]);
        }
        // Nếu checkbox được tích chọn
        if (checked) {
            item_checked = lodash.concat(item_checked, [value]);
            item_checked = lodash.uniq(item_checked);
        }
        if (item_checked.length === warehouses.length) {
            check_all = true;
        }
        this.updateFormData({item_checked: item_checked, check_all: check_all});
    }

    /**
     * Hàm thực hiện đánh dấu chọn hoặc bỏ chọn tất cả
     * NOTE: Hiện tại không dùng hàm này
     * @param event
     */
    onCheckAll(event) {
        let {purchaseAddresses} = this.props;
        let OrderIds = [];
        let checked = false;
        let item_checked = [];
        let check_all = false;
        // Nếu checkbox được tích chọn
        if (checked) {
            OrderIds = purchaseAddresses.map(purchaseAddressItem => {
                return purchaseAddressItem.value;
            });
            OrderIds = lodash.compact(OrderIds);
            check_all = true;
            item_checked = lodash.concat(item_checked, OrderIds);
        }

        this.updateFormData({item_checked: item_checked, check_all: check_all});
    }

    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        let formData = this.state.formData;
        formData = {...formData, ...input};
        this.setState({formData});
    }

    onChangeFormZero(event) {
        event.preventDefault();
        this.onSearch(stateDefault);
    }

    render() {
        let {
            t, filter, orders, stats, pagination, partner, agencies,
            countStatusChanged, purchaseAddresses, endDistributeOrder, user
        } = this.props;
        let formStatsParams = ['status'];
        let {formData} = this.state;
        let formSearchParams = ["code", "seller_source",
            "id_purchase_address", "sort", "invoice_code", "complaint_seller", "wrong_process", "id_agency"];
        return (
            <React.Fragment>
                {this.props.loading ? (
                    <div className='a-loading__page'>
                        <div className="a-loading__page__icon"></div>
                    </div>
                ) : null}

                <Container fluid>
                    <p className="mb-2 a-text--right">
                        <SwitchScreen cookieName="purchase_screen_is_new"/>
                    </p>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className="a-content--center"
                             key={typeof filter._i === "undefined" ? 0 : parseInt(filter._i, 10)}>
                            <div className={"a-content--box-shadow"}>
                                <FormPerformAction
                                    filter={filter}
                                    item_checked={formData.item_checked}
                                    onCheckItem={this.onCheckItem.bind(this)}
                                    onCheckAll={this.onCheckAll.bind(this)}
                                    isDistributiveWarehouseSetting={this.props.isDistributiveWarehouseSetting}
                                    isUsingOrderDistributorSetting={this.props.isUsingOrderDistributorSetting}
                                    warehouses={this.props.warehouses}
                                    stateDefault={stateDefault}
                                    onSubmit={(input) => this.onSearch({...filter, ...input})}
                                    input={lodash.pick(filter, formSearchParams)}
                                    onClear={(input) => this.onSearch({...input, ...{_i: filter._i}})}
                                    orders={orders}
                                    user={user}
                                    endDistributeOrder={endDistributeOrder}
                                    formSearchParams={formSearchParams}
                                    onRequestDistributor={this.onRequestDistributor.bind(this)}
                                    clearDistributeOrder={this.props.clearDistributeOrder}
                                    beginDistributeOrder={this.props.beginDistributeOrder}
                                    inputVersion={typeof filter._i === "undefined" ? 0 : parseInt(filter._i, 10)}
                                />
                                <FormStats
                                    onSubmit={(input) => {
                                        this.onSearch({...filter, ...input});
                                        this.props.clearCountStatusChanged(input)
                                    }}
                                    status={lodash.pick(filter, formStatsParams)}
                                    statsOrder={stats}
                                    countStatusChanged={countStatusChanged}
                                />
                                <div className="mt-4">
                                    <span className="a-font--12 mr-1">{t('order:list.total_order_result')}:</span>
                                    <b>{lodash.isNumber(pagination.total) ? pagination.total : '--'}</b>
                                </div>
                            </div>
                            <div className="a-sort purchase mt-5">
                                <FormSearchHeader
                                    onSubmit={(input) => this.onSearch({...filter, ...input})}
                                    purchaseAddresses={purchaseAddresses}
                                    siteItems={siteItems}
                                    agencies={agencies}
                                    input={lodash.pick(filter, formSearchParams)}
                                    inputVersion={typeof filter._i === "undefined" ? 0 : parseInt(filter._i, 10)}
                                />
                            </div>
                            <div className="a-content--box-shadow mt-5 pt-1">
                                <div className="a-group--item mt-5">
                                    {orders.map(order => (
                                        <ItemPurchase
                                            order={order}
                                            key={order.id}
                                            partner={partner}
                                            onConfirmCustomerOrderBuying={this.props.onConfirmCustomerOrderBuying}
                                        />
                                    ))}
                                    {orders.length === 0 ? <p>{t("order:list.no_record")}</p> : null}
                                </div>
                                <div className="a-group--item a_navigation mt-5 d-flex ">
                                    <Pagination
                                        value={parseInt(pagination.current_page, 10)}
                                        total={pagination.page_total}
                                        onChange={page => this.onChangePage(page)}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </React.Fragment>
        );
    }
}

ListPurchases.defaultProps = {
    channels: [],
    input: {},
    partner: {id: 0, code: "", domain: ""},
    inputVersion: 0,
    filter: {},
    filterVersion: 0,
    orders: [],
    purchaseAddresses: [],
    warehouses: [],
    isDistributiveWarehouseSetting: false,
    isUsingOrderDistributorSetting: false,
    onSearch: filter => {
    },
};

ListPurchases.propTypes = {
    input: PropTypes.object,
    partner: PropTypes.object,
    onSubmit: PropTypes.func,
    pusherAssignOrder: PropTypes.func,
    pusherUnassignOrder: PropTypes.func,
    filter: PropTypes.object,
    filterVersion: PropTypes.number,
    onSearch: PropTypes.func,
    orders: PropTypes.array,
    channels: PropTypes.array,
    clearAllCountStatusChanged: PropTypes.func,
    isDistributiveWarehouseSetting: PropTypes.bool,
    isUsingOrderDistributorSetting: PropTypes.bool,
    purchaseAddresses: PropTypes.array,
    warehouses: PropTypes.array,
    clearCountStatusChanged: PropTypes.func
};


export default translate('translations')(ListPurchases);
