import {takeLatest} from 'redux-saga/effects';
import * as ACTION from './constants';
import * as saga from './saga';

export default function*() {
    yield takeLatest(ACTION.FETCH_ORDER_GROUP.REQUEST, saga.getOrderGroup);
    yield takeLatest(ACTION.CREATE_ORDER_GROUP.REQUEST, saga.createOrderGroup);
    yield takeLatest(ACTION.FETCH_DETAIL_ORDER_GROUP.REQUEST, saga.fetchDetailOrderGroup);
    yield takeLatest(ACTION.UPDATE_ORDER_GROUP.REQUEST, saga.updateOrderGroup);
    yield takeLatest(ACTION.UPDATE_STATUS_ORDER_GROUP.REQUEST, saga.updateStatusOrderGroup);
}
