import React from "react";
import {processResponse} from "../../../systems/api";
import apiService from "../apiService";
import {Layout, notification} from "antd";
import {dispatch, getState} from "../../../systems/store";
import {setBreadCrumb, setMenuActive, setPageDescription, setPageTitle} from "../../../modules/common/actions";
import {translate} from "react-i18next";
import lodash from "lodash";
import SearchableComponent from "../../../modules/common/SearchableComponent";
import FormSearch from "./FormSearch";
import List from "./List";
import {router} from "../../../systems/routing";

const Content = Layout.Content;

class ForeignOrderList extends SearchableComponent {

    constructor(props) {
        super(props);

        this.defaultFilter = {
            page_no: 1,
            page_size: 50
        };

        this.defaultState = {
            orders: [],
            pagination: {},
            loading: false,
        };

        this.state = {
            ...this.defaultState,
        };
    }

    componentDidMount() {
        let filter = this.getCurrentFilter();
        super.componentDidMount();
        this.setPageInfo();
        this.fetchOrders(filter);
    }

    setPageInfo() {
        let {t} = this.props;
        dispatch(setPageTitle(t("theme.menu.foreign-order")));
        dispatch(setPageDescription(t("theme.menu.foreign-order")));
        dispatch(setMenuActive('foreign-order'));
        dispatch(setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            }, {
                'label': 'breadcrumb.foreign-order',
                'link': '/foreign-order'
            },
        ]));
    }

    onChangeFilter(filter) {
        if (this.state.loading)
            return;

        this.fetchOrders(filter);
    }

    async fetchOrders(filter) {
        let {t} = this.props;
        this.setState({loading: true});
        await processResponse(apiService.getTaobaoPurchaseOrders({...filter})).then(res => {
            const page_no = lodash.get(res, "data.page_no", 1);
            const page_size = lodash.get(res, "data.page_size", 50);
            const results_total = lodash.get(res, "data.results_total", 0);
            this.setState({
                loading: false,
                orders: lodash.get(res, 'data.purchase_orders', []),
                pagination: {page_no, page_size, results_total}
            });
        }).catch(error => {
            let permission = lodash.get(error, "data.permission", undefined);
            if (permission) {
                notification.error({message: t('error_page.403_message')});
            } else {
                notification.error({message: t('message.cant_fetch_data')});
            }
            this.setState({loading: false});
        });
    }

    getCurrentFilter() {
        return {
            ...this.getFilterFromLocation(this.props.location),
        };
    }

    onSearch(filter) {
        if (this.state.loading)
            return;

        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    }

    render() {
        let {
            orders,
            loading,
            pagination
        } = this.state;
        let user = getState('User.me.user', {});
        let filter = this.getFilter();
        const canViewPurchaseOrderTaobao = lodash.get(user, "canViewPurchaseOrderTaobao", false);

        if (!canViewPurchaseOrderTaobao) {
            return router.redirect("/403");
        }
        return (
            <Layout>
                <Content className="a-content--ant-layout sale-report">
                    <FormSearch
                        input={filter}
                        inputVersion={filter.i || 0}
                        loading={this.state.loading}
                        onSearch={this.onSearch.bind(this)}
                    />

                    <List
                        orders={orders}
                        loading={loading}
                        pagination={pagination}
                        onSearch={this.onSearch.bind(this)}
                        filter={filter}
                    />
                </Content>
            </Layout>
        );
    }
}

export default translate()(ForeignOrderList);
