import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, Layout, notification } from 'antd'
import lodash from 'lodash'
import FormSearch from './components/FormSearch'
import ListOrders from './components/ListOrder'
import Stats from './components/Stats'
import { dispatch, getState } from '../../../systems/store'
import { setBreadCrumb, setMenuActive, setPageDescription, setPageTitle } from '../../../modules/common/actions'
import { translate } from 'react-i18next'
import apiService from '../../../modules/Order/apiService'
import apiUser from '../../../modules/User/apiService'
import { processResponse } from '../../../systems/api'
import SearchableComponent from '../../../modules/common/SearchableComponent'
import { ORDER_STATUS, ORDER_TIME_TYPE } from '../../../systems/globalContant/actionContant'
import apiAssign from '../../../modules/Order/DistributeOrder/apiService'
import { updateCollectionItem } from '../../../modules/common/services/helps'
import { events } from '../../../systems/events'
import ORDER_EVENT from '../../../modules/Order/events'
import * as Selector from '../../../modules/ComplaintSeller/selectors'
import { fetchComplaintSellerReasons } from '../../../modules/ComplaintSeller/actions'
import { connect } from 'react-redux'
import authService from '../../../modules/Auth/authService'
import moment from 'moment'

const Content = Layout.Content
const currentDay = moment('23:59:59', 'HH:mm:ss')
const threeMonthsAgo = moment('00:00:00', 'HH:mm:ss').subtract(3, 'M')
class ListOrder extends SearchableComponent {
    constructor(props) {
        super(props)
        this.state = {
            stats: [],
            statsStatus: [],
            tellers: [],
            seller_source: [],
            orders: [],
            pagination: {},
            loading: false,
            distributing: 0,
            checkedOrders: [],
            canChangeWaitingPay: '0',
            canShowOrderWaitingBuy: false,
            canViewContact: false,
            canSelfAssign: false,
            canUsingFintech: false
        }

        this.defaultFilter = {
            status: authService.can('ORDER:VIEW_ONLY_ACCEPTED') ? [ORDER_STATUS.WAITING_PAI] : [ORDER_STATUS.WAITING_BUY],
            time_type: ORDER_TIME_TYPE.DEPOSITED,
            total_item_link_operation: '>',
            overdue_purchase: false,
            log_status_from: threeMonthsAgo.format('YYYY-MM-DD 00:00:00'),
            log_status_to: currentDay.format('YYYY-MM-DD 23:59:59'),
            // code: '',
            // invoice_code: '',
            // freight_bill: '',
            // buyer_username: '',
            // id_purchaser: [],
            // id_teller: [],
            // seller_source: [],
            // total_item_link: '',
            // real_amount_compare: '',
            // grand_total_to: '',
            // grand_total_from: '',
            // grand_total_converted_from: '',
            // grand_total_converted_to: '',
            // log_status_to: '',
            // log_status_from: '',
            // wrong_process: '',
            // operation: '',
            // sort: 'asc',
            // id_purchase_address: [],
            // id_agency: [],
        }
    }

    componentDidMount() {
        super.componentDidMount()
        let { complaintSellerReasons } = this.props

        this.setPageInfo()
        this.fetchStats()
        this.fetchTellers()
        this.fetchSellerSources()

        if (complaintSellerReasons.length === 0) this.props.fetchComplaintSellerReasons()
    }

    fetchSellerSources = () => {
        let { t } = this.props
        processResponse(apiService.fetchSellerSource())
            .then(res => {
                this.setState({ seller_source: res.data })
            })
            .catch(() => {
                notification.error(t('message.cant_fetch_data'))
            })
    }

    fetchStats = () => {
        let { t } = this.props
        let process = processResponse(apiService.getOrderStats())
            .then(res => {
                this.setState({
                    stats: lodash.get(res, 'data.stats', []),
                    canChangeWaitingPay: lodash.get(res, 'data.canChangeWaitingPay', '0'),
                })
            })
            .catch(() => {
                notification.error(t('message.cant_fetch_data'))
            })
    }

    fetchTellers = () => {
        let { t } = this.props
        processResponse(apiUser.getListTellers())
            .then(res => {
                this.setState({ tellers: res.data })
            })
            .catch(() => {
                notification.error(t('message.cant_fetch_data'))
            })
    }

    onAssignPurchaser = (orderId, purchaserId) => {
        let { t } = this.props
        this.setState({ distributing: orderId })
        processResponse(apiAssign.assignPurchaser(orderId, purchaserId))
            .then(res => {
                let order = lodash.get(res, 'data.order', {})
                let teller = lodash.get(res, 'data.teller', {})
                this.setState(
                    prevState => {
                        return {
                            orders: updateCollectionItem(prevState.orders, order.id, {
                                ...order,
                                teller: teller,
                                time_teller: order.time_teller,
                                teller_name: teller.name,
                                teller_username: teller.username,
                            }),
                            distributing: 0,
                        }
                    },
                    () => {
                        notification.success({ message: t('order:distribute_order.assign_purchaser_success') })
                    }
                )
            })
            .catch(() => {
                notification.error({ message: t('order:distribute_order.assign_purchaser_failed') })
                this.setState({ distributing: 0 })
            })
    }

    onAssignPurchaserOrders = (orderIds, purchaserId) => {
        let { t } = this.props
        processResponse(apiAssign.assignPurchaserOrders(orderIds, purchaserId))
            .then(res => {
                let orders = lodash.get(res, 'data.orders', {})
                let teller = lodash.get(res, 'data.teller', {})

                this.setState(
                    prevState => {
                        let new_orders = prevState.orders
                        orders.forEach(order => {
                            new_orders = updateCollectionItem(new_orders, order.order_id, {
                                ...order.order,
                                teller: teller,
                                time_teller: order.order.time_teller,
                                teller_name: teller.name,
                                teller_username: teller.username,
                            })
                        })
                        return { orders: new_orders }
                    },
                    () => {
                        notification.success({ message: t('order:distribute_order.assign_purchaser_success') })
                    }
                )
            })
            .catch(() => {
                notification.error({ message: t('order:distribute_order.assign_purchaser_failed') })
            })
    }

    updateOrders = orders => {
        this.setState(
            prevState => {
                let new_orders = prevState.orders
                const teller = getState('User.me.user', {});
                orders.forEach(order => {
                    new_orders = updateCollectionItem(new_orders, order.order_id, {
                        ...order.order,
                        teller: teller,
                        time_teller: order.order.time_teller,
                        teller_name: teller.name,
                        teller_username: teller.username,
                    })
                })
                return { orders: new_orders }
            }
        )
    }

    onUnassignPurchaser = orderId => {
        let { t } = this.props
        this.setState({ distributing: orderId })
        processResponse(apiAssign.unassignPurchaser(orderId))
            .then(res => {
                let order = lodash.get(res, 'data.order', {})

                this.setState(
                    prevState => {
                        return {
                            orders: updateCollectionItem(prevState.orders, order.id, {
                                ...order,
                                teller: null,
                                time_teller: null,
                                teller_name: null,
                                teller_username: null,
                            }),
                            distributing: 0,
                        }
                    },
                    () => {
                        notification.success({ message: t('order:distribute_order.unassign_purchaser_success') })
                    }
                )
            })
            .catch(err => {
                if (lodash.get(err, 'data.payment_request_processing', false)) {
                    notification.error({ message: t('order:distribute_order.payment_request_processing') })
                } else {
                    notification.error({ message: t('order:distribute_order.unassign_purchaser_failed') })
                    this.setState({ distributing: 0 })
                }
            })
    }

    onCheckAllOrders = (unchecked = false) => {
        if (unchecked) this.clearCheckAll()
        else
            this.setState(prevState => {
                let { orders, checkedOrders } = prevState
                orders.forEach(order => {
                    if (!lodash.includes(checkedOrders, order.id)) checkedOrders.push(order.id)
                })
                return { checkedOrders }
            })
    }

    onCheckOrder = (orderId, unchecked = false) => {
        if (unchecked)
            this.setState(prevState => {
                let { checkedOrders } = prevState
                checkedOrders.push(orderId)
                return { checkedOrders }
            })
        else
            this.setState(prevState => {
                let { checkedOrders } = prevState
                return { checkedOrders: lodash.without(checkedOrders, orderId) }
            })
    }

    clearCheckAll = () => {
        this.setState({ checkedOrders: [] })
    }

    fetchOrders = filter => {
        this.setState({ loading: true })
        let { t } = this.props

        let res = apiService.getListOrder(filter)
        let process = processResponse(res)

        process.then(res => {
            this.setState({
                orders: lodash.get(res, 'data.orders', []),
                pagination: lodash.get(res, 'data.pagination', {}),
                loading: false,
                filterLongTime: lodash.get(res, 'data.filterLongTime') || [],
                canShowOrderWaitingBuy: lodash.get(res, 'data.canShowOrderWaitingBuy', false),
                canViewContact: lodash.get(res, 'data.canViewContact', false),
                canSelfAssign: lodash.get(res, 'data.canSelfAssign', false),
                canUsingFintech: lodash.get(res, 'data.canUsingFintech', '0'),
            })
        })

        process.catch(() => {
            notification.error({ message: t('message.cant_fetch_data') })
            this.setState({ loading: false })
        })
    }

    fetchCountOrders = filter => {
        let { t } = this.props

        processResponse(apiService.countOrders(filter))
            .then(res => {
                this.setState({
                    statsStatus: lodash.get(res, 'data.stats', []),
                })
            })
            .catch(() => {
                notification.error({ message: t('message.cant_fetch_data') })
            })
    }

    setPageInfo() {
        let { t } = this.props
        dispatch(setPageTitle(t('layout.title.list_order')))
        dispatch(setPageDescription(t('layout.description.list_order')))
        dispatch(setMenuActive('ListOrder'))
        dispatch(
            setBreadCrumb([
                {
                    label: 'breadcrumb.home',
                    link: '/',
                },
                {
                    label: 'breadcrumb.list_order',
                    link: '/ordering/',
                },
            ])
        )
    }

    onSearch = filter => {
        let defaultFilter = this.getFilter()

        if (filter.reset) {
            let resetFilter = {
                ...this.defaultFilter,
                _i: parseInt(defaultFilter._i || 0, 0) + 1,
            }
            this.pushFilter({
                ...this.defaultFilter,
                _i: parseInt(defaultFilter._i || 0, 0) + 1,
            })
            events.dispatch(ORDER_EVENT.RESET_FILTER, resetFilter)
        } else if (lodash.isEmpty(filter.code) && lodash.isEmpty(filter.invoice_code) && lodash.isEmpty(filter.freight_bill)) {
            // const log_status_from = moment(lodash.get(filter, 'log_status_from', threeMonthsAgo), "YYYY-MM-DD")
            // const log_status_to = moment(lodash.get(filter, 'log_status_to', currentDay), "YYYY-MM-DD")
            // const diff = log_status_to.diff(log_status_from, 'months', true);
            // if (diff <= 3) {
            this.pushFilter({
                ...defaultFilter,
                ...filter,
                _i: parseInt(defaultFilter._i || 0, 0) + 1,
            })
            // } else {
            //     notification.error({ message: 'Thời gian tìm kiếm không được vượt quá 3 tháng' })
            // }
        } else {
            if (!lodash.isEmpty(filter.code)) this.pushFilter({ code: filter.code, status: 'ALL' })
            else if (!lodash.isEmpty(filter.invoice_code)) this.pushFilter({ invoice_code: filter.invoice_code, status: 'ALL' })
            else this.pushFilter({ freight_bill: filter.freight_bill, status: 'ALL' })
        }
    }

    onChangeFilter(filter) {
        let editedFilter = { ...filter }
        // const log_status_from = moment(lodash.get(filter, 'log_status_from', threeMonthsAgo), "YYYY-MM-DD")
        // const log_status_to = moment(lodash.get(filter, 'log_status_to', currentDay), "YYYY-MM-DD")
        // const diff = log_status_to.diff(log_status_from, 'months', true);

        if (filter.grand_total_converted_from) editedFilter.grand_total_converted_from = filter.grand_total_converted_from * 1000

        if (filter.grand_total_converted_to) editedFilter.grand_total_converted_to = filter.grand_total_converted_to * 1000

        if (!lodash.isEmpty(filter.code) || !lodash.isEmpty(filter.invoice_code) || !lodash.isEmpty(filter.freight_bill)) {
            editedFilter = {
                ...editedFilter,
                log_status_from: undefined,
                log_status_to: undefined,
            }
        }
        // if (lodash.isEmpty(filter.code) && lodash.isEmpty(filter.invoice_code) && lodash.isEmpty(filter.freight_bill) && diff > 3) {
        //
        //         notification.error({ message: 'Thời gian tìm kiếm không được vượt quá 3 tháng' })
        // } else {

        this.clearCheckAll()
        this.fetchCountOrders(editedFilter)
        this.fetchOrders(editedFilter)
        // }
    }

    render() {
        let filter = { ...this.getFilter() }

        // Báo cho biết người dùng click lại vào menu quản lý hay ko
        let searchStr = lodash.get(this.props, 'location.search', '')
        return (
            <Layout>
                <Content className="a-content--ant-layout new-list-orders">
                    <Stats
                        stats={this.state.stats}
                        canChangeWaitingPay={this.state.canChangeWaitingPay}
                        canShowOrderWaitingBuy={this.state.canShowOrderWaitingBuy}
                    />
                    <FormSearch
                        filter={filter}
                        onSearch={this.onSearch}
                        filterLongTime={this.state.filterLongTime}
                        resetFilter={searchStr === '' ? true : false}
                        defaultFilter={this.defaultFilter}
                        sellerSource={this.state.seller_source}
                        canUsingFintech={this.state.canUsingFintech}
                    />
                    <ListOrders
                        filter={filter}
                        checkedOrders={this.state.checkedOrders}
                        statsStatus={this.state.statsStatus}
                        tellers={this.state.tellers}
                        orders={this.state.orders}
                        distributing={this.state.distributing}
                        pagination={this.state.pagination}
                        loading={this.state.loading}
                        onSearch={this.onSearch}
                        onCheckOrder={this.onCheckOrder}
                        onCheckAllOrders={this.onCheckAllOrders}
                        onAssignPurchaser={this.onAssignPurchaser}
                        onUnassignPurchaser={this.onUnassignPurchaser}
                        onAssignPurchaserOrders={this.onAssignPurchaserOrders}
                        complaintSellerReasons={this.props.complaintSellerReasons}
                        canChangeWaitingPay={this.state.canChangeWaitingPay}
                        canShowOrderWaitingBuy={this.state.canShowOrderWaitingBuy}
                        canViewContact={this.state.canViewContact}
                        canSelfAssign={this.state.canSelfAssign}
                        updateOrders={this.updateOrders}
                    />
                </Content>
            </Layout>
        )
    }
}

ListOrder.propTypes = {}

const mapStateToProps = state => {
    return {
        complaintSellerReasons: Selector.getComplaintSellerReason(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchComplaintSellerReasons: () => dispatch(fetchComplaintSellerReasons({ status: 1, scope: 'basic' })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ListOrder))
