import React from 'react'
import ReduxToastr from 'react-redux-toastr';

export const ToastrRoot = () => {
    return (
        <ReduxToastr
            className={'toastr-custom'}
            timeOut={5000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
        />
    )
};