import React from 'react';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {Container, Row, Col} from 'react-grid-system';
import {Icon} from "antd";
import Item from './Item';
import FormSearchHeader from "./FormSearchHeader";
import FormSearchHeaderMobile from "./FormSearchHeaderMobile";
import PropTypes from 'prop-types';
import FormStats from "./FormStats";
import FormPerformAction from "./FormPerformAction";
import Pagination from './../../../common/components/Pagination';
import {REAL_AMOUNT_OPERATION} from "../../constants";
import {ORDER_STATUS, ORDER_TIME_TYPE} from "../../../../systems/globalContant/actionContant";
import withSizes from 'react-sizes';
import {trans} from './../../../../systems/i18n';
import OrderStats from './OrderStats';
import SwitchScreen from "../../../SwitchScreen";

let timeOptions = lodash.values(ORDER_TIME_TYPE).map(Item => {
    return {label: trans("order:type_type." + Item), value: Item}
});

timeOptions = lodash.compact(timeOptions);

const mapSizesToProps = ({width}) => ({
    isMobile: width < 992,
});

const stateDefault = {
    code: '',
    buyer_username: '',
    id_purchaser: [],
    seller_source: '',
    total_item_link: '',
    real_amount_compare: '',
    grand_total_to: '',
    grand_total_from: '',
    log_status_to: '',
    log_status_from: '',
    wrong_process: '',
    operation: '',
    sort: 'asc',
    id_purchase_address: [],
    time_type:ORDER_TIME_TYPE.DEPOSITED,
    status: ORDER_STATUS.WAITING_BUY
}

class ListOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            formData: {
                check_all: false,
                order_item_checked: [],
                code: null
            }
        }
    }

    componentDidMount() {
        this.props.setPageTitle(this.props.t('layout.title.list_order'));
        this.props.setPageDescription(this.props.t('layout.description.list_order'));
        this.props.setMenuActive("ListOrder");
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.list_order',
                'link': '/ordering/'
            },
        ]);
        this.props.getPurcharsers({pagination: 0});
        this.props.getPurchaseAddressList({pagination: 0, status: 1});
        this.props.getListAgency({});
        this.props.clearCreateNewOrder();
    }

    /**
     * handle action change filter
     * @param filter
     */
    onSearch(filter) {
        filter = this.customFilter(filter);
        if (filter.status === "WAITING_BUY") {
            this.props.clearCreateNewOrder();
        }
        this.props.onSearch({...filter, page: 1});
    }

    customFilter(filter) {
        if (filter.operation === REAL_AMOUNT_OPERATION.GREAT) {
            filter.total_item_link_from = filter.total_item_link;
        }

        if (filter.operation === REAL_AMOUNT_OPERATION.LESS) {
            filter.total_item_link_to = filter.total_item_link;
        }

        filter = lodash.omit(filter, ['operation, total_item_link']);
        return filter;
    }

    onAdvanceFilter() {
        this.setState({isOpenFilter: !this.state.isOpenFilter})
    }

    clearFilter(id) {
        let tradesman = lodash.get(this.state, 'input.tradesman', []);
        lodash.remove(tradesman, function (trade) {
            return trade.id === id;
        });

    }

    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        let formData = this.state.formData;
        formData = {...formData, ...input};
        this.setState({formData});
    }

    /**
     * handle change page of list
     * @param page
     */
    onChangePage(page) {
        let {filter} = this.props;
        window.scrollTo(0, 0);
        this.setState({page: page});
        this.props.onSearch({...filter, page: page});
        this.onClearCheckAll();
    }

    /**
     * Hàm thực hiện assign đơn cho nhân viên
     *
     * @param id_teller
     */

    onAssignPurchasers(id_teller) {
        let order_item_checked = lodash.get(this.state, 'formData.order_item_checked', []);
        let orders = this.getOrderIdsRefuses(order_item_checked);
        let orderIds = orders.map(order => {
            return order.id;
        });
        order_item_checked = lodash.difference(order_item_checked, orderIds);
        order_item_checked = lodash.compact(order_item_checked);
        this.props.onAssignPurchaserOrders(order_item_checked, id_teller);
    }

    /**
     * Hàm thực hiện đánh dấu chọn hoặc bỏ chọn tích đơn
     *
     * @param event
     */
    onCheckItem(event) {
        let target = event.target;
        let {orders} = this.props;
        let value = lodash.toInteger(target.value);
        let checked = target.checked;
        let check_all = false;
        let order_item_checked = lodash.get(this.state, 'formData.order_item_checked', []);
        // Nếu bỏ tích chọn
        if (!checked) {
            order_item_checked = lodash.difference(order_item_checked, [value]);
        }
        // Nếu checkbox được tích chọn
        if (checked) {
            order_item_checked = lodash.concat(order_item_checked, [value]);
            order_item_checked = lodash.uniq(order_item_checked);
        }
        if (order_item_checked.length === (orders.length)) {
            check_all = true;
        }

        this.updateFormData({order_item_checked: order_item_checked, check_all: check_all});
    }

    /**
     * Hàm thực hiện đánh dấu chọn hoặc bỏ chọn tất cả
     *
     * @param event
     */
    onCheckAll(event) {
        let {orders} = this.props;
        let OrderIds = [];
        let target = event.target;
        let checked = target.checked;
        let order_item_checked = [];
        let check_all = false;
        // Nếu checkbox được tích chọn
        if (checked) {
            OrderIds = orders.map(order => {
                return order.id;
            });
            OrderIds = lodash.compact(OrderIds);
            check_all = true;
            order_item_checked = lodash.concat(order_item_checked, OrderIds);
        }

        this.updateFormData({order_item_checked: order_item_checked, check_all: check_all});
    }


    onClearCheckAll() {
        this.updateFormData({order_item_checked: [], check_all: false});
    }

    getOrderIdsRefuses(orderIds) {
        let orders = lodash.get(this.props, 'orders', []);
        return lodash.filter(orders, function (order) {
            return (
                lodash.includes(orderIds, order.id) &&
                order.id_teller &&
                !order.distribute_status
            );
        });
    }

    render() {
        let {
            t, filter, orders, stats, purchasers, distributeOrderStatus, countWaitingBuyOrder, agencies,
            accountPurchase, pagination, partner, distributeOrders, purchaserOption, purchaseAddresses, isMobile,
        } = this.props;

        let formStatsParams = ['status'];
        let formSearchParams = [
            'complaint_seller', "id_purchaser", "id_teller", "grand_total_from", "grand_total_to", "total_item_link_operation", "total_item_link",
            "id_buyer", "code", "seller_source", "id_purchase_address", "sort", "wrong_process", "log_status_from", "log_status_to", "buyer_username", "time_type",
            "id_agency"

        ];
        let {formData} = this.state;
        return (
            <React.Fragment>
                {this.props.loading ? (
                    <div className='a-loading__page'>
                        <div className="a-loading__page__icon"></div>
                    </div>
                ) : null}

                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className="a-content--center ">
                            <p className="mb-2 a-text--right">
                                <SwitchScreen cookieName="order_screen_is_new"/>
                            </p>
                            <OrderStats stats={stats}/>
                            <div className="a-content--box-shadow">
                                <div className="a-perform-action mt-3">
                                    <FormPerformAction
                                        filter={filter}
                                        distributeOrderStatus={distributeOrderStatus}
                                        distributing={distributeOrders}
                                        onAssignPurchasers={this.onAssignPurchasers.bind(this)}
                                        onCloseFormAssignPurchaser={this.props.onCloseFormAssignPurchaser}
                                        purchasers={purchasers}
                                        purchaserOption={purchaserOption}
                                        orderIds={formData.order_item_checked}
                                        orderIdsRefuses={
                                            this.getOrderIdsRefuses(formData.order_item_checked)
                                        }
                                        stateDefault={stateDefault}
                                        onSubmit={(input) => this.onSearch({...filter, ...input})}
                                        input={lodash.pick(filter, formSearchParams)}
                                        onClear={(input) =>
                                            this.onSearch({...input, ...{_i: filter._i}})
                                        } orders={orders}
                                        inputVersion={typeof filter._i === "undefined" ? 0 : parseInt(filter._i, 10)}
                                        onAssignPurchaserClear={this.props.onOpenFormAssignPurchaser}
                                        onClearCheckAll={this.onClearCheckAll.bind(this)}
                                    />
                                </div>
                                <FormStats
                                    countStatusChanged={{[ORDER_STATUS.WAITING_BUY]: countWaitingBuyOrder}}
                                    onSubmit={(input) => this.onSearch({...filter, ...input})}
                                    status={lodash.pick(filter, formStatsParams)}
                                    statsOrder={stats}
                                    orderCount={this.props.orderCount}
                                    onClearCheckAll={this.onClearCheckAll.bind(this)}
                                />
                                <div className="mt-4">
                                    <span className="a-font--12 mr-1">{t('order:list.total_order_result')}:</span>
                                    <b>{lodash.isNumber(pagination.total) ? pagination.total : '--'}</b>
                                </div>
                            </div>
                            <div className="a-sort">
                                {isMobile ? <FormSearchHeaderMobile
                                    accountPurchase={accountPurchase}
                                    timeOptions={timeOptions}
                                    purchaseAddresses={purchaseAddresses}
                                    purchasers={purchasers.map(purchaser => ({
                                        label: purchaser.username,
                                        value: purchaser.id
                                    }))} onSubmit={(input) => this.onSearch({...filter, ...input})}
                                    input={lodash.pick(filter, formSearchParams)}
                                    onCheckAll={this.onCheckAll.bind(this)}
                                    check_all={formData.check_all}
                                    agencies={agencies}
                                    inputVersion={typeof filter._i === "undefined" ? 0 : parseInt(filter._i, 10)}
                                /> : <FormSearchHeader
                                    accountPurchase={accountPurchase}
                                    timeOptions={timeOptions}
                                    purchaseAddresses={purchaseAddresses}
                                    purchasers={purchasers.map(purchaser => ({
                                        label: purchaser.username,
                                        value: purchaser.id
                                    }))}
                                    onSubmit={(input) => this.onSearch({...filter, ...input})}
                                    input={lodash.pick(filter, formSearchParams)}
                                    onCheckAll={this.onCheckAll.bind(this)}
                                    check_all={formData.check_all}
                                    inputVersion={typeof filter._i === "undefined" ? 0 : parseInt(filter._i, 10)}
                                    agencies={agencies}
                                />}
                            </div>
                            <div className="a-group--item mt-5">
                                {orders.map(order => (
                                    <Item order={order}
                                          key={order.id}
                                          partner={partner}
                                          onConfirmCustomerOrderBuying={this.props.onConfirmCustomerOrderBuying}
                                          onCheckItem={this.onCheckItem.bind(this)}
                                          defaultChecked={formData.order_item_checked.includes(order.id)}
                                          purchasers={this.props.purchasers}
                                          onAssignPurchaser={(purchaserId) => this.props.onAssignPurchaser(order.id, purchaserId)}
                                          onUnassignPurchaser={() => this.props.onUnassignPurchaser(order.id)}
                                    />
                                ))}
                                {orders.length === 0 ? <p>{t("order:list.no_record")}</p> : null}
                            </div>
                            <div className="a-group--item a_navigation mt-5 d-flex ">
                                <Pagination
                                    value={parseInt(pagination.current_page, 10)}
                                    total={pagination.page_total}
                                    onChange={page => this.onChangePage(page)}
                                />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </React.Fragment>
        );
    }
}

ListOrder.defaultProps = {
    input: {},
    users: [],
    distributingOrders: {},
    partner: {id: 0, code: "", domain: ""},
    inputVersion: 0,
    filter: {},
    filterVersion: 0,
    orders: [],
    onSearch: filter => {
    },
};

ListOrder.propTypes = {
    input: PropTypes.object,
    users: PropTypes.array,
    distributingOrders: PropTypes.object,
    partner: PropTypes.object,
    onSubmit: PropTypes.func,
    filter: PropTypes.object,
    filterVersion: PropTypes.number,
    onSearch: PropTypes.func,
    orders: PropTypes.array,
};


export default translate('translations')(withSizes(mapSizesToProps)(ListOrder));
