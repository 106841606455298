import React from 'react';
import {translate} from 'react-i18next';
import Checkbox from '../../../common/components/Checkbox/index';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    DEFAULT_EMPTY_DATA,
    DEFAULT_EMPTY_NUMBER_DATA,
    ORDER_STATUS_WITH_CLASS
} from './../../constants';
import Moment from 'react-moment';
import lodash from "lodash";
import {currentFormatter} from '../../../common/services/format';
import Link from "../../../../systems/routing/Link";
import OrderPurchaser from './OrderPurchaser';
import NoticeConfirm from './NoticeConfirm';
import {DISTRIBUTE_STATUS} from '../../DistributeOrder/constants';
import Timer from "../../../common/components/Timer";
import bootbox from './../../../common/components/Bootbox/bootbox';
import {Button, Icon} from "antd";
import CreateComplaintSeller from "./CreateComplaintSeller";

class Item extends React.Component {

    /**
     * generate class for status block
     */
    _generateStatusClass(orderStatus) {
        return "a-status a-status--" +
            ((orderStatus && ORDER_STATUS_WITH_CLASS[orderStatus]) ?
                ORDER_STATUS_WITH_CLASS[orderStatus].className : "wating-buy");
    }

    render() {
        /* eslint-disable */
        let {t, order} = this.props;
        let currencySymbol = lodash.get(order, "currencySymbol",  '¥');
        let logTimeStatusOk = lodash.get(order, "log_time_status_" + order.status, "");
        let grandTotal = lodash.get(order, "grand_total", DEFAULT_EMPTY_NUMBER_DATA);
        let grandTotalVnd = lodash.get(order, "grand_total_converted", DEFAULT_EMPTY_NUMBER_DATA);
        grandTotal = grandTotal ? parseFloat(grandTotal) : DEFAULT_EMPTY_NUMBER_DATA;
        grandTotalVnd = grandTotalVnd ? parseFloat(grandTotalVnd) : DEFAULT_EMPTY_NUMBER_DATA;
        grandTotal = currentFormatter.toLocaleStringCurrency(grandTotal, 5) + " " + currencySymbol;
        grandTotalVnd = currentFormatter.toLocaleStringCurrencyVND(grandTotalVnd);
        grandTotalVnd += t("order:vnd");

        let updateQuantity = order.note_buyer_confirm_message();

        return (
            <React.Fragment>
                <div className="a-item">
                    <div className="a-item__top">
                        <ul>
                            <li className={'order--2'}>
                                {<Checkbox
                                    key={order.id + this.props.defaultChecked}
                                    defaultChecked={this.props.defaultChecked}
                                    value={order.id}
                                    onChange={this.props.onCheckItem}
                                    name="check_item"
                                />}
                            </li>
                            <li className="a-item__account w_100-30__lg order--1">
                                <Link isNewTab={true} to="purchase.buying.detail" params={{id: order.id}}>
                                    <img src={order.order_item_image || require('../resource/default-thumbnail.jpg')}
                                         onError={(e) => {
                                             e.target.onerror = null;
                                             e.target.src = require('../resource/default-thumbnail.jpg')
                                         }}
                                         alt="Avatar"/>
                                </Link>
                                <div className="a-item__account__infor">
                                    <div className="a-item__account__name">
                                        <span
                                            style={{ background: lodash.get(order, "agency.color", "") }}
                                            className={"ml-4 header_partner header_partner--" + order.agency_code}
                                        >     {order.agency_alias}
                                        </span>
                                        <div className="flex-start">
                                            <Link isNewTab={true}  to="purchase.buying.detail" params={{id: order.id}}>
                                                {order.code}
                                            </Link>
                                            <CopyToClipboard text={order.code} onCopy={() => {
                                                bootbox.success(t("message.copied") + ": " + order.code)
                                            }}>
                                                <i className="far fa-copy copy"/>
                                            </CopyToClipboard>
                                        </div>
                                    </div>

                                    <span
                                        className="a-item__account__username">@{order.buyer_username || DEFAULT_EMPTY_DATA}</span>
                                    <p className="a-item__account__time-ordering">
                                        <CalendarIcon size={"small"}/>
                                        <span className="a-item__account__time-detail">
                                        {t('order:list.deposit')} :
                                            {!order.order_partner_time_deposit_format ?
                                                DEFAULT_EMPTY_DATA :
                                                order.order_partner_time_deposit_format}
                                    </span>
                                    </p>
                                </div>
                            </li>
                            <li className="a-item__block w_50__lg order--3">
                                <div className="a-item__block__shop">
                                    <span>
                                        {
                                            order.wrong_process
                                                ? t('order:label.wrong_process')
                                                : <img style={{"height": "15px", "width": "15px"}}
                                                       src={"http://nhaphang.com/vp-assets/images/favicons_" + order.seller_source + ".png"}
                                                       onError={(e) => {
                                                           e.target.onerror = null;
                                                           e.target.src = require('../resource/u32.png')
                                                       }}
                                                       alt="avatar_seller"/>
                                        }

                                </span>
                                </div>

                            </li>
                            <li className="a-item__block w_50__lg order--4">
                                <p className="a-item__block__total">{t('order:list.total')}:
                                    <span>{order.total_item_qty_bought || DEFAULT_EMPTY_NUMBER_DATA}</span>{t('order:list.product')}/
                                    <span>{order.total_item_link || DEFAULT_EMPTY_NUMBER_DATA}</span>{t('order:list.link')}
                                </p>
                                <p className="mt-2 a-item__block__total--money">{grandTotalVnd}&nbsp;
                                    - <span
                                        className="a-text--red">{grandTotal}</span>
                                </p>
                                {order.complaintSellerTotal !== 0 ?
                                    <div className="a-item__block__is_complaintseller mt-3 mb-1">
                                        <div className="a-item__block__complaintseller mr-5"
                                             onClick={()=> window.open(order.urlComplaintByOrder, "_blank")}>
                                            <Link>{t('order:list.KNNB')}</Link>
                                            <span>{order.complaintSellerTotalFormat}</span>
                                        </div>
                                    </div> : null}
                            </li>
                            <li className="a-item__block w_50__lg flex-end-md order--5">
                                <div className="float--left mr-2">
                                    <span className={this._generateStatusClass(order.status)}>
                                        {t("order:status." + order.status)}
                                    </span>
                                    <br/>
                                    <span className="a-text--font-b a-item__block__time__waiting hidden-md">
                                        {logTimeStatusOk === "" ? DEFAULT_EMPTY_DATA :
                                            <Moment format="DD/MM HH:mm">{logTimeStatusOk}</Moment>}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="a-item__bottom">
                        {order.buying_policy_time &&
                        <div className={"a-item__bottom__left " + order.buying_policy_priority}>
                            <Timer
                                date={order.buying_policy_time_remaining || ''}
                                textBefore={t('order:detail.remaining')}
                                textAfter={t('order:detail.to_buying')}
                                overTimeText={t('order:status.OVERDUE_PURCHASE')}
                                timeBold
                                time_now={order.time_now}
                                order={order}
                            />
                        </div>}
                        <ul>
                            <li className="d-flex align-items-center">
                                <CreateComplaintSeller order={order}/>
                            </li>
                            <li className="d-flex align-items-center a-item__bottom__block_purchase">
                                <b className="mr-3">{t("order:list.tradesman")}</b>
                                <OrderPurchaser
                                    order={order}
                                    purchasers={this.props.purchasers}
                                    distributing={order.distribute_status === DISTRIBUTE_STATUS.LOADING}
                                    onAssignPurchaser={this.props.onAssignPurchaser}
                                    onUnassignPurchaser={this.props.onUnassignPurchaser}
                                />
                            </li>
                            <li className="d-flex align-items-center">
                                <p>{t("order:list.payer")}<span>{order.paid_staff_name || DEFAULT_EMPTY_DATA}
                                    / @{order.paid_staff_username || DEFAULT_EMPTY_DATA} {order.time_paid_staff_format}</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                {order.buyer_confirm && updateQuantity.length ? (
                    <NoticeConfirm
                        key={order.id}
                        order={order}
                        updateQuantity={order.note_buyer_confirm_message()}
                        onConfirmCustomerOrderBuying={this.props.onConfirmCustomerOrderBuying}
                    />
                ) : null}
            </React.Fragment>
        );
    }
}

Item.defaultProps = {
    order: {},
    partner: {id: 0, code: "", domain: ""},
    onConfirmCustomerOrderBuying: () => {
    }

};

Item.propTypes = {
    order: PropTypes.object,
    partner: PropTypes.object,
    onConfirmCustomerOrderBuying: PropTypes.func,
};


export default translate('translations')(Item);
