import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import lodash, { isEmpty, isNull } from 'lodash'
import { trans } from '../../../../../systems/i18n'
import { Link, url } from '../../../../../systems/routing'
import { currentFormatter } from './../../../services/format'
import { getVar } from '../../../../../systems/support/helpers'

const ORDER_ACTION = {
    UPDATE_QUANTITY_ITEM: 'ORDER.UPDATE_QUANTITY_ITEM',
    UPDATE_REAL_PAYMENT_AMOUNT: 'ORDER.UPDATE_REAL_PAYMENT_AMOUNT',
    UPDATE_REAL_REFUND_AMOUNT: 'ORDER.UPDATE_REAL_REFUND_AMOUNT',
    CREATE: 'ORDER.CREATE',
    ASSIGN: 'ORDER.ASSIGN',
    UNASSIGN: 'ORDER.UNASSIGN',
    CHANGE_STATE: 'ORDER.CHANGE_STATE',
    CHANGE_PAY: 'ORDER.CHANGE_PAY',
    ADD_INVOICE_CODE: 'ORDER.ADD_INVOICE_CODE',
    DELETE_INVOICE_CODE: 'ORDER.DELETE_INVOICE_CODE',
    CHANGE_ORDER_GROUP: 'ORDER.CHANGE_ORDER_GROUP',
    CHANGE_ACCOUNT_PURCHASER: 'ORDER.CHANGE_ACCOUNT_PURCHASER',
    CHANGE_FREE_SHIP: 'ORDER.CHANGE_FREE_SHIP',
    CHANGE_SHIPPING_FEE: 'ORDER.CHANGE_SHIPPING_FEE',
    PAID: 'ORDER.PAID',
    CHANGE_SERVICES: 'ORDER.CHANGE_SERVICES',
    CONFIRM_SERVICE: 'ORDER.CONFIRM_SERVICES',
    UPDATE_PRICE_ITEM: 'ORDER.UPDATE_PRICE_ITEM',
    ADD_NOTE_ITEM: 'ORDER.ADD_NOTE_ITEM',
    CONFIRM_QUANTITY: 'ORDER.CONFIRM_QUANTITY',
    CHANGE_REASONS: 'ORDER.CHANGE_REASONS',
    PAI_ORDER_ITEM: 'ORDER.PAI_ORDER_ITEM',
    ADD_FREIGHT_BILL: 'ORDER.ADD_FREIGHTBILL',
    DELETE_FREIGHT_BILL: 'ORDER.DELETE_FREIGHTBILL',
    CRAWL_FREIGHT_BILL: 'ORDER.CRAWL_FREIGHTBILL',
    CRAWL: 'ORDER.CRAWL',
    ORDER_CHANGE_RATE_DEPOSIT: 'ORDER.CHANGE_RATE_DEPOSIT',
    ORDER_UPDATE_INVOICE_CODE: 'ORDER.UPDATE_INVOICE_CODE',
    RECEIVE_ITEM: 'ORDER.RECEIVE_ITEM',
    CREATE_COMPLAINT_SELLER: 'ORDER.CREATE_COMPLAINT_SELLER',
    UPDATE_ORDER: 'ORDER.UPDATE',
    CHANGE_WRONG_PROCESS: 'ORDER.CHANGE_WRONG_PROCESS',
    CHANGE_PURCHASE_ADDRESS: 'ORDER.CHANGE_PURCHASE_ADDRESS',
    RECEIVED_ORDER: 'ORDER.RECEIVED_ORDER',
    MARK_VIOLATION: 'ORDER.MARK_VIOLATION',
    ORDER_CONFIRM_BALANCE: 'ORDER.CONFIRM_BALANCE',
    ORDER_FRAGILE: 'ORDER.FRAGILE',
    ORDER_ECOMMERCE_SHIPPING: 'ORDER.UPDATE_ECOMMERCE_SHIPPING',
    ORDER_CUSTOMS_INFO: 'ORDER.CUSTOMS_INFO',
    ORDER_HSCODE: 'ORDER.HS_CODE',
    CHANGE_FEATURE: 'ORDER.CHANGE_PROPERTY',
    UPDATE_INTERNAL_CODE: 'ORDER.UPDATE_INTERNAL_CODE',
    UPDATE_DISCOUNT_AMOUNT: 'ORDER.UPDATE_DISCOUNT_AMOUNT',
    ORDER_CHANGE_ITEM_PROPERTIES: 'ORDER.CHANGE_ITEM_PROPERTIES',
    ORDER_ADD_ITEM: 'ORDER.ADD_ITEM',
    ORDER_CANCEL_TAOBAO_PURCHASE: 'ORDER.CANCEL_TAOBAO_PURCHASE',
    ORDER_CANCEL_PURCHASE_ORDER_1688: 'ORDER.CANCEL_PURCHASE_ORDER_1688',
    CHANGE_BATCH_ORDER: 'ORDER.CHANGE_BATCH_ORDER',
    UPDATE_BATCH_ORDER_INFO: 'ORDER.UPDATE_BATCH_ORDER_INFO',
}

class OrderLogMessage extends React.Component {
    render() {
        let { log } = this.props
        let { payload } = log
        let attributes = lodash.get(payload, 'attributes', [])
        let services = lodash.get(payload, 'attributes.services', {})
        let is_admin = lodash.get(payload, 'creator.is_admin', null)
        let byPaymentRequest = lodash.get(payload, 'byPaymentRequest', null)
        let invoiceCodes = lodash.get(payload, 'orderInvoiceCodes', [])
        const property = lodash.get(payload, 'attributes.property', {})

        switch (log.action) {
            case ORDER_ACTION.CREATE:
                return trans('order:log.CREATE', {
                    order: this.linkToOrder(lodash.get(payload, 'order')),
                })
            case ORDER_ACTION.UPDATE_QUANTITY_ITEM:
                return this.renderMessageQuantityUpdate(lodash.get(payload, 'order_item'))

            case ORDER_ACTION.ORDER_UPDATE_INVOICE_CODE:
                return this.renderMessageUpdateInvoiceCode(payload)
            case ORDER_ACTION.ORDER_CONFIRM_BALANCE:
                return trans('order:log.ORDER_CONFIRM_BALANCE')
            case ORDER_ACTION.ORDER_FRAGILE:
                let fragile = lodash.get(log, 'payload.attributes.fragile')
                return trans(`order:log.ORDER_FRAGILE_${fragile}`)
            case ORDER_ACTION.ASSIGN:
                return trans('order:log.ASSIGN', {
                    order: this.linkToOrder(lodash.get(payload, 'order')),
                    teller: lodash.get(payload, 'teller.username'),
                })

            case ORDER_ACTION.UNASSIGN:
                return trans('order:log.UNASSIGN', {
                    order: this.linkToOrder(lodash.get(payload, 'order')),
                    teller: lodash.get(payload, 'teller.username'),
                })

            case ORDER_ACTION.RECEIVED_ORDER:
                return trans('order:log.RECEIVED_ORDER', {
                    order: this.linkToOrder(lodash.get(payload, 'order')),
                })
            case ORDER_ACTION.PAID:
                return trans('order:log.PAID', {
                    order: this.linkToOrder(lodash.get(payload, 'order')),
                })

            case ORDER_ACTION.ADD_NOTE_ITEM:
                return trans('order:log.ADD_NOTE_ITEM', {
                    order: this.linkToOrder(lodash.get(payload, 'order')),
                    item: (
                        <span className={'text-primary font-weight-bold'}>
                            <a href={'#' + lodash.get(payload, 'order_item.id')}>{'@' + lodash.get(payload, 'order_item.id')}</a>
                        </span>
                    ),
                    note: lodash.get(payload, 'order_item.attributes.note'),
                })

            case ORDER_ACTION.ADD_INVOICE_CODE:
                return this._renderMessageAddInvoiceCode(payload)
            case ORDER_ACTION.DELETE_INVOICE_CODE:
                return this._renderMessageDeleteInvoiceCode(payload)
            case ORDER_ACTION.CHANGE_ORDER_GROUP:
                return this._renderMessageChangeOrderGroup(payload)
            case ORDER_ACTION.CHANGE_ACCOUNT_PURCHASER:
                return this._renderMessageChangeAccountPurchaser(payload)
            case ORDER_ACTION.CHANGE_FREE_SHIP:
                return this._renderMessageChangeFreeShip(payload)
            case ORDER_ACTION.CHANGE_SHIPPING_FEE:
                return this._renderMessageChangeFee(payload)
            case ORDER_ACTION.CHANGE_STATE:
                return this._renderMessageChangeStatus(payload)
            case ORDER_ACTION.UPDATE_REAL_PAYMENT_AMOUNT:
                return this._renderMessageUpdateRealPaymentAmount(payload)
            case ORDER_ACTION.UPDATE_REAL_REFUND_AMOUNT:
                return this._renderMessageUpdateRealRefundAmount(payload)
            case ORDER_ACTION.CHANGE_WRONG_PROCESS:
                return this._renderMessageChangeWrongProcess(payload)
            case ORDER_ACTION.CHANGE_BATCH_ORDER:
                return this._renderMessageChangeBatchOrder(payload)
            case ORDER_ACTION.UPDATE_BATCH_ORDER_INFO:
                return this._renderMessageChangeBatchOrderInfo(payload)
            case ORDER_ACTION.CHANGE_PAY:
                let invoiceCodeText = lodash.join(invoiceCodes, ', ')

                // byPaymentRequest === true
                let paymentRequestChangePay =
                    byPaymentRequest === true
                        ? trans('order:log.PAYMENT_REQUEST_CHANGE_PAY', {
                              invoices: <span className="a-text--bold">{invoiceCodeText}</span>,
                          })
                        : ''
                return trans('order:log.CHANGE_PAY', {
                    order: this.linkToOrder(lodash.get(payload, 'order')),
                    paymentRequestChangePay: paymentRequestChangePay,
                })
            case ORDER_ACTION.UPDATE_PRICE_ITEM:
                return this._renderMessageChangePrice(payload)
            case ORDER_ACTION.ORDER_CHANGE_RATE_DEPOSIT:
                return this._renderMessageChangeReteDeposit(payload)
            case ORDER_ACTION.CHANGE_SERVICES:
                let action = lodash.get(services, 'action')
                let service = lodash.get(services, 'service_name', null)
                if (action === 'add') {
                    return trans('order:log.ADD_SERVICES', {
                        service: service,
                    })
                } else {
                    return trans('order:log.DELETE_SERVICES', {
                        service: service,
                    })
                }
            case ORDER_ACTION.CONFIRM_SERVICE:
                return trans('order:log.CONFIRM_SERVICES', {
                    service: services.service_name,
                    action: services.confirm === 1 ? trans('order_detail.APPROVAL') : trans('order_detail.UNAPPROVED'),
                })

            case ORDER_ACTION.CHANGE_REASONS:
                return this._renderMessageChangeReason(payload)

            case ORDER_ACTION.CONFIRM_QUANTITY:
                is_admin = lodash.get(payload, 'creator.is_admin', null)
                return is_admin ? trans('order:log.ADMIN_CONFIRM_QUANTITY') : trans('order:log.CONFIRM_QUANTITY')
            case ORDER_ACTION.PAI_ORDER_ITEM:
                let isPai = lodash.get(payload, 'order_item.attributes.is_pai')
                return trans('order:log.PAI_ORDER_ITEM', {
                    item_id: <span className={'text-primary font-weight-bold'}>{'¥' + lodash.get(payload, 'order_item.id', 0)}</span>,
                    action: isPai === 1 ? trans('order_detail.PAID') : trans('order_detail.UN_PAI'),
                })
            case ORDER_ACTION.ADD_FREIGHT_BILL:
                return trans('order:log.ADD_FREIGHT_BILL', {
                    code: lodash.get(payload, 'attributes.freight_bill', ''),
                })
            case ORDER_ACTION.DELETE_FREIGHT_BILL:
                return trans('order:log.DELETE_FREIGHT_BILL', {
                    code: lodash.get(payload, 'attributes.freight_bill', ''),
                })
            case ORDER_ACTION.CRAWL_FREIGHT_BILL:
                return trans('order:log.CRAWL_FREIGHT_BILL', {
                    codes: lodash.join(lodash.get(payload, 'attributes.freight_bills')),
                })
            case ORDER_ACTION.CRAWL:
                return trans('order:log.CRAWL')
            case ORDER_ACTION.MARK_VIOLATION:
                return trans('order:log.MARK_VIOLATION', {
                    note: <span className={'text-primary font-weight-bold'}>{lodash.get(payload, 'attributes.note')}</span>,
                })
            case ORDER_ACTION.RECEIVE_ITEM:
                return this.renderMessageReceiveItem(payload)
            case ORDER_ACTION.CHANGE_PURCHASE_ADDRESS:
                const changeBy = lodash.get(payload, 'attributes.logData.changeBy', '')
                if (lodash.get(payload, 'attributes.purchase_address.old', '') === '') {
                    return trans('newLocals:log.CHANGE_PURCHASE_ADDRESS_ADD', {
                        new: lodash.get(payload, 'attributes.purchase_address.new', ''),
                        change_by: changeBy ? trans('newLocals:log.changeBy.' + changeBy) : '',
                    })
                } else {
                    return trans('newLocals:log.CHANGE_PURCHASE_ADDRESS', {
                        old: lodash.get(payload, 'attributes.purchase_address.old', ''),
                        new: lodash.get(payload, 'attributes.purchase_address.new', ''),
                        change_by: changeBy ? trans('newLocals:log.changeBy.' + changeBy) : '',
                    })
                }

            case ORDER_ACTION.CREATE_COMPLAINT_SELLER:
                return trans('order:log.CREATE_COMPLAINT_SELLER', {
                    link: (
                        <a
                            href={url.to('complaint-seller.detail', { id: lodash.get(payload, 'complaint.id', 0) })}
                            target="_blank">
                            #{lodash.get(payload, 'complaint.id', 0)}
                        </a>
                    ),
                })
            case ORDER_ACTION.UPDATE_ORDER:
                if (attributes) {
                    var orderUpdateLog = ''
                    var showLog = false
                    var t = this
                    lodash.forEach(attributes, function (value, key) {
                        if (
                            lodash.includes(
                                ['rate_deposit', 'rate_exchange', 'shipping_bill_code', 'destination_warehouse', 'user_address', 'seller_aliwang'],
                                key
                            )
                        ) {
                            showLog = true
                            let oldData = value.old
                            let newData = value.new
                            if (key === 'user_address') {
                                oldData = t._renderUserAddress(oldData)
                                newData = t._renderUserAddress(newData)
                            }
                            if (oldData === '' || newData === '') {
                                if (oldData === '') {
                                    orderUpdateLog += trans('order:log.ORDER_CREATE_DATA', {
                                        key: trans('order_detail.' + key),
                                        newData: newData,
                                    })
                                }
                                if (newData === '') {
                                    orderUpdateLog += trans('order:log.ORDER_DELETE_DATA', {
                                        key: trans('order_detail.' + key),
                                        newData: newData,
                                    })
                                }
                            } else {
                                orderUpdateLog +=
                                    trans('order:log.ORDER_UPDATE') +
                                    trans('order:log.ORDER_UPDATE_PARAMS', {
                                        key: trans('order_detail.' + key),
                                        oldData: oldData,
                                        newData: newData,
                                    })
                            }
                        }
                    })
                    return showLog ? orderUpdateLog : ''
                }
            case ORDER_ACTION.ORDER_ECOMMERCE_SHIPPING:
                let ecommerce_shipping = lodash.get(log, 'payload.attributes.ecommerce_shipping')
                return trans(`order:log.ORDER_ECOMMERCE_SHIPPING_${ecommerce_shipping}`)
            case ORDER_ACTION.ORDER_CUSTOMS_INFO:
                let showCustomsLog = false
                let arrayOrigin = []
                let arrayTranslated = []
                let logOrigin = ''
                let logTranslated = ''
                let current_item = lodash.get(payload, 'item', {})
                if (attributes) {
                    const customs_info_name_manual = lodash.get(attributes, 'customs_info_name_manual', true)
                    const unit_manual = lodash.get(attributes, 'unit_manual', true)
                    lodash.forEach(attributes, function (value, key) {
                        if (value['old'] !== value['new']) {
                            if (lodash.includes(['unit', 'customs_info_name', 'brand', 'material'], key)) {
                                showCustomsLog = true
                                arrayOrigin.push(
                                    trans(`order:detail.customs_info.tq.${key}`, {
                                        old_value: !lodash.isEmpty(value['old']) ? trans(`order:detail.customs_info.from`, { value: value['old'] }) : '',
                                        new_value: trans(`order:detail.customs_info.to`, { value: value['new'] }),
                                    })
                                )
                            }

                            if (lodash.includes(['unit_vn', 'customs_info_name_vn', 'translated_brand', 'translated_material', 'translated_properties'], key)) {
                                showCustomsLog = true
                                if (key !== 'translated_properties') {
                                    arrayTranslated.push(
                                        trans(`order:detail.customs_info.vn.${key}`, {
                                            old_value: !lodash.isEmpty(value['old']) ? trans(`order:detail.customs_info.from`, { value: value['old'] }) : '',
                                            new_value: trans(`order:detail.customs_info.to`, { value: value['new'] }),
                                            customs_info_name_manual: customs_info_name_manual
                                                ? trans('order:detail.customs_info.manual')
                                                : trans('order:detail.customs_info.suggest'),
                                            unit_manual: unit_manual ? trans('order:detail.customs_info.manual') : trans('order:detail.customs_info.suggest'),
                                        })
                                    )
                                } else {
                                    let name_properties = []
                                    let value_properties = []
                                    value['new'].map((item, index) => {
                                        let old_name_item = ''
                                        let old_value_item = ''
                                        if (!lodash.isEmpty(value['old'][index])) {
                                            const old_item = value['old'][index]
                                            old_name_item = old_item.name
                                            old_value_item = old_item.value
                                        }
                                        if (item.name !== old_name_item) {
                                            if (old_name_item) {
                                                name_properties.push(
                                                    trans(`order:detail.customs_info.from`, { value: old_name_item }) +
                                                        ' ' +
                                                        trans(`order:detail.customs_info.to`, { value: item.name })
                                                )
                                            } else {
                                                name_properties.push(trans(`order:detail.customs_info.to`, { value: item.name }))
                                            }
                                        }
                                        if (item.value !== old_value_item) {
                                            if (old_name_item) {
                                                value_properties.push(
                                                    trans(`order:detail.customs_info.from`, { value: old_value_item }) +
                                                        ' ' +
                                                        trans(`order:detail.customs_info.to`, { value: item.value })
                                                )
                                            } else {
                                                value_properties.push(trans(`order:detail.customs_info.to`, { value: item.value }))
                                            }
                                        }
                                    })
                                    if (name_properties.length > 0) {
                                        arrayTranslated.push(
                                            trans(`order:detail.customs_info.vn.translated_properties_name`, { value: name_properties.join(', ') })
                                        )
                                    }
                                    if (value_properties.length > 0) {
                                        arrayTranslated.push(
                                            trans(`order:detail.customs_info.vn.translated_properties_value`, { value: value_properties.join(', ') })
                                        )
                                    }
                                }
                            }
                        }
                    })

                    if (arrayOrigin.length > 0) logOrigin = arrayOrigin.join('. ')

                    if (arrayTranslated.length > 0) logTranslated = arrayTranslated.join('. ')

                    let from = lodash.get(payload, 'from', '')
                    return trans(`order:log.ORDER_UPDATE_CUSTOMS`, {
                        customs: trans(`order:log.ORDER_UPDATE_CUSTOMS_ALL`, {
                            customs_vn: logTranslated ? logTranslated + (logOrigin ? '.' : '') : '',
                            customs_tq: logOrigin ? logOrigin : '',
                        }),
                        item: <span className={'text-primary font-weight-bold'}>#{current_item.partner_order_item_id}</span>,
                        tool: from ? trans(`order:detail.customs_info.from_tool`) : '',
                    })
                }
            case ORDER_ACTION.ORDER_HSCODE:
                if (attributes) {
                    const objectLog = lodash.get(attributes, 'object', '')
                    if (objectLog === 'order-items') {
                        const items = lodash.get(attributes, 'items', [])
                        let messageLog = []
                        if (items.length > 0) {
                            items.forEach(item => {
                                const oldData = lodash.get(item, 'from', '')
                                const newData = lodash.get(item, 'to', '')
                                const code = lodash.get(item, 'item', '')
                                if (lodash.isEmpty(newData)) {
                                    messageLog.push(
                                        trans('orderDetail:hscode.log.delete', {
                                            oldData: oldData,
                                            object: trans('orderDetail:hscode.object.' + objectLog),
                                            code: code,
                                        })
                                    )
                                } else if (lodash.isEmpty(oldData)) {
                                    messageLog.push(
                                        trans('orderDetail:hscode.log.create', {
                                            newData: newData,
                                            object: trans('orderDetail:hscode.object.' + objectLog),
                                            code: code,
                                        })
                                    )
                                } else {
                                    messageLog.push(
                                        trans('orderDetail:hscode.log.update', {
                                            oldData: oldData,
                                            newData: newData,
                                            object: trans('orderDetail:hscode.object.' + objectLog),
                                            code: code,
                                        })
                                    )
                                }
                            })
                        }
                        return messageLog.join('. ')
                    } else {
                        const oldData = lodash.get(attributes, 'from', '')
                        const newData = lodash.get(attributes, 'to', '')
                        const code = lodash.get(payload, 'order.code', '')
                        if (lodash.isEmpty(newData)) {
                            return trans('orderDetail:hscode.log.delete', {
                                oldData: oldData,
                                object: trans('orderDetail:hscode.object.' + objectLog),
                                code: code,
                            })
                        }
                        if (lodash.isEmpty(oldData)) {
                            return trans('orderDetail:hscode.log.create', {
                                newData: newData,
                                object: trans('orderDetail:hscode.object.' + objectLog),
                                code: code,
                            })
                        }
                        return trans('orderDetail:hscode.log.update', {
                            oldData: oldData,
                            newData: newData,
                            object: trans('orderDetail:hscode.object.' + objectLog),
                            code: code,
                        })
                    }
                }
                return ''
            case ORDER_ACTION.CHANGE_FEATURE:
                const action_change_feature = lodash.get(property, 'action')
                let property_name = lodash.get(property, 'name', null)
                if (action_change_feature === 'add') {
                    return trans('order:log.ADD_FEATURE', {
                        property_name,
                    })
                } else {
                    return trans('order:log.DELETE_FEATURE', {
                        property_name,
                    })
                }
            case ORDER_ACTION.UPDATE_INTERNAL_CODE:
                const old_value = lodash.get(attributes, 'from', null)
                const new_value = lodash.get(attributes, 'to', null)
                const partner_order_item_id = lodash.get(payload, 'item.partner_order_item_id', '')
                if (old_value) {
                    return trans('order:log.UPDATE_INTERNAL_CODE', {
                        old_value: <span className="font-weight-bold">{old_value}</span>,
                        new_value: <span className="font-weight-bold">{new_value}</span>,
                        item: <span className={'text-primary font-weight-bold'}>#{partner_order_item_id}</span>,
                    })
                } else {
                    return trans('order:log.NEW_INTERNAL_CODE', {
                        new_value: <span className="font-weight-bold">{new_value}</span>,
                        item: <span className={'text-primary font-weight-bold'}>#{partner_order_item_id}</span>,
                    })
                }
            case ORDER_ACTION.UPDATE_DISCOUNT_AMOUNT:
                const oldDiscountAmount = lodash.get(attributes, 'discount_amount.old', 0)
                const newDiscountAmount = lodash.get(attributes, 'discount_amount.new', 0)
                return trans('order:log.UPDATE_DISCOUNT_AMOUNT', {
                    old_value: <span className="font-weight-bold">¥{currentFormatter.toLocaleStringCurrency(oldDiscountAmount)}</span>,
                    new_value: <span className="font-weight-bold">¥{currentFormatter.toLocaleStringCurrency(newDiscountAmount)}</span>,
                })

            case ORDER_ACTION.ORDER_CHANGE_ITEM_PROPERTIES:
                const oldProperties = lodash.map(lodash.get(attributes, 'from.translated_properties', []), 'originalValue')
                const newProperties = lodash.map(lodash.get(attributes, 'to.translated_properties', []), 'originalValue')
                let productItem = lodash.get(attributes, 'item.partner_order_item_id')
                if (lodash.isEmpty(oldProperties)) {
                    return trans('order:log.ADD_ORDER_ITEM_PROPERTIES', {
                        value: <span className="font-weight-bold">{newProperties.join('_')}</span>,
                        product_id: <span className="font-weight-bold">{productItem}</span>,
                    })
                } else {
                    return trans('order:log.ORDER_CHANGE_ITEM_PROPERTIES', {
                        old_value: <span className="font-weight-bold">{oldProperties.join('-')}</span>,
                        new_value: <span className="font-weight-bold">{newProperties.join('_')}</span>,
                        product_id: <span className="font-weight-bold">{productItem}</span>,
                    })
                }

            case ORDER_ACTION.ORDER_ADD_ITEM:
                const productCode = lodash.get(attributes, 'item.partner_order_item_id')
                return trans('order:log.ORDER_ADD_ITEM', {
                    product_id: <span className="font-weight-bold">{productCode}</span>,
                })
            case ORDER_ACTION.ORDER_CANCEL_TAOBAO_PURCHASE:
                const username = lodash.get(payload, 'creator.username')
                const purchaseId = lodash.get(payload, 'attributes.purchase_id')
                return trans('order:log.ORDER_CANCEL_TAOBAO_PURCHASE', {
                    username: <span className="font-weight-bold">{username}</span>,
                    purchaseId: <span className="font-weight-bold text-primary">{purchaseId}</span>,
                })
            case ORDER_ACTION.ORDER_CANCEL_PURCHASE_ORDER_1688:
                const purchase_ids = lodash.get(attributes, 'purchase_ids', [])
                return trans('order:log.ORDER_CANCEL_PURCHASE_ORDER_1688', {
                    code: purchase_ids.map((item, index) => {
                        return (
                            <span className="font-weight-bold">
                                {index !== 0 ? ', ' : ''}#{item}
                            </span>
                        )
                    }),
                })
            default:
                return log.action
        }
    }

    /**
     * Địa chỉ nhận hàng
     * @param user_address
     * @returns {string}
     * @private
     */
    _renderUserAddress(user_address) {
        let user_address_text = ''
        if (user_address) {
            if (user_address.reciver_name) {
                user_address_text += user_address.reciver_name + ' / ' + user_address.detail + ',' + user_address.district + ',' + user_address.province
                if (user_address.reciver_phone) {
                    user_address_text += ' / ' + user_address.reciver_phone
                }
                if (user_address.note) {
                    user_address_text += ' / ' + user_address.note
                }
            } else {
                user_address_text = user_address.detail
            }
        }
        return user_address_text
    }

    linkToOrder({ id, code } = {}) {
        return (
            <Link
                to="purchase.buying.detail"
                params={{ id }}>
                {code}
            </Link>
        )
    }

    /**
     *
     * @param param
     * @returns {XML}
     * @private
     */
    _renderMessageChangePrice(param = {}) {
        let attributes = lodash.get(param, 'order_item.attributes', {})
        let id = lodash.get(param, 'order_item.partner_order_item_id', null)
        let old_value = 0
        let new_value = 0
        let reason = ''
        if (!lodash.isEmpty(attributes)) {
            old_value = lodash.get(attributes, 'unit_price.old', 0)
            new_value = lodash.get(attributes, 'unit_price.new', 0)
            reason = lodash.get(attributes, 'reason', '')
            old_value = currentFormatter.toLocaleStringCurrency(parseFloat(old_value), 5)
            new_value = currentFormatter.toLocaleStringCurrency(parseFloat(new_value), 5)
        }
        return (
            <span>
                {trans('order:log.UPDATE_UNIT_PRICE', {
                    order: <span className={'text-primary font-weight-bold'}>{'#' + id}</span>,
                    old_value: '¥' + old_value,
                    new_value: '¥' + new_value,
                    reason: reason ? trans('order:log_params.reason', { reason }) : null,
                })}
            </span>
        )
    }

    /**
     *
     * @param param
     * @returns {XML}
     * @private
     */
    _renderMessageChangeWrongProcess(param = {}) {
        let attributes = lodash.get(param, 'attributes', {})
        let new_value = false
        if (!lodash.isEmpty(attributes)) {
            new_value = lodash.get(attributes, 'wrong_process.new', false)
        }
        return new_value === true ? <span>{trans('order:log.UPDATE_WRONG_PROCESS', {})}</span> : <span>{trans('order:log.UPDATE_NOT_WRONG_PROCESS', {})}</span>
    }

    _renderMessageChangeBatchOrder(param = {}) {
        let attributes = lodash.get(param, 'attributes', {})
        let new_value = false
        if (!lodash.isEmpty(attributes)) {
            new_value = lodash.get(attributes, 'is_batch_order.new', false)
        }
        return new_value === true ? <span>{trans('order:log.SET_BATCH_ORDER', {})}</span> : <span>{trans('order:log.SET_NOT_BATCH_ORDER', {})}</span>
    }

    _renderMessageChangeBatchOrderInfo(param = {}) {
        let data = getVar(param, 'attributes.data', {})
        const estimate_freight_bill_quantity = getVar(data, 'estimate_freight_bill_quantity')
        const estimate_weight_order = getVar(data, 'estimate_weight_order')
        const estimate_volume = getVar(data, 'estimate_volume')
        const estimate_packages = getVar(data, 'estimate_packages', '[]')
        const hasInfo =
            !isNull(estimate_freight_bill_quantity) || !isNull(estimate_weight_order) || !isNull(estimate_volume) || !isEmpty(JSON.parse(estimate_packages))
        return hasInfo ? (
            <span>
                {trans('order:log.UPDATE_BATCH_ORDER_INFO')} <br />
                {estimate_freight_bill_quantity && (
                    <>
                        {trans('order:log.estimate_freight_bill_quantity', { quantity: <b>{estimate_freight_bill_quantity}</b> })}
                        <br />
                    </>
                )}
                {estimate_weight_order && (
                    <>
                        {trans('order:log.estimate_weight_order', { weight: <b>{estimate_freight_bill_quantity}</b> })}
                        <br />
                    </>
                )}
                {estimate_volume && (
                    <>
                        {trans('order:log.estimate_volume', { volume: <b>{estimate_volume}</b> })}
                        <br />
                    </>
                )}
                {estimate_packages &&
                    JSON.parse(estimate_packages).map(item => {
                        if (item.quantity) {
                            return (
                                <>
                                    {trans('order:log.estimate_package', {
                                        quantity: lodash.get(item, 'quantity', ''),
                                        dimension: `${lodash.get(item, 'length', '')}x${lodash.get(item, 'width', '')}x${lodash.get(item, 'height', '')}`,
                                    })}
                                </>
                            )
                        }
                    })}
            </span>
        ) : (
            trans('order:log.UPDATE_BATCH_ORDER_INFO_WITH_NODATA')
        )
    }

    /**
     *
     * @param param
     * @returns {XML}
     * @private
     */
    _renderMessageChangeReteDeposit(param = {}) {
        let attributes = lodash.get(param, 'attributes.rate_deposit', {})
        let old_value = 0
        let new_value = 0
        if (!lodash.isEmpty(attributes)) {
            old_value = lodash.get(attributes, 'old', 0)
            new_value = lodash.get(attributes, 'new', 0)
            old_value = currentFormatter.toLocaleStringCurrency(parseFloat(old_value))
            new_value = currentFormatter.toLocaleStringCurrency(parseFloat(new_value))
        }
        return (
            <span>
                {trans('order:log.ORDER_CHANGE_RATE_DEPOSIT', {
                    old_value: old_value + '%',
                    new_value: new_value + '%',
                })}
            </span>
        )
    }

    /**
     *
     * @param param
     * @returns {XML}
     * @private
     */
    _renderMessageUpdateRealPaymentAmount(param = {}) {
        let attributes = lodash.get(param, 'attributes.real_payment_amount', {})
        let old_value = 0
        let new_value = 0
        if (!lodash.isEmpty(attributes)) {
            old_value = lodash.get(attributes, 'old', 0)
            new_value = lodash.get(attributes, 'new', 0)
            old_value = currentFormatter.toLocaleStringCurrency(lodash.toNumber(old_value), 5)
            new_value = currentFormatter.toLocaleStringCurrency(lodash.toNumber(new_value), 5)
        }
        return (
            <span>
                {trans('order:log.UPDATE_REAL_PAYMENT_AMOUNT', {
                    old_value: '¥' + old_value,
                    new_value: '¥' + new_value,
                })}
            </span>
        )
    }

    /**
     *
     * @param param
     * @returns {XML}
     * @private
     */
    _renderMessageUpdateRealRefundAmount(param = {}) {
        let attributes = lodash.get(param, 'attributes.real_refund_amount', {})
        let old_value = 0
        let new_value = 0
        if (!lodash.isEmpty(attributes)) {
            old_value = lodash.get(attributes, 'old', 0)
            new_value = lodash.get(attributes, 'new', 0)
            old_value = currentFormatter.toLocaleStringCurrency(lodash.toNumber(old_value), 5)
            new_value = currentFormatter.toLocaleStringCurrency(lodash.toNumber(new_value), 5)
        }
        return (
            <span>
                {trans('order:log.UPDATE_REAL_REFUND_AMOUNT', {
                    old_value: '¥' + old_value,
                    new_value: '¥' + new_value,
                })}
            </span>
        )
    }

    /**
     * Hiển thị message log update quantity
     */
    renderMessageQuantityUpdate(param = {}) {
        let attributes = lodash.get(param, 'attributes', {})
        let id = lodash.get(param, 'partner_order_item_id', null)
        let old_value = 0
        let new_value = 0
        if (!lodash.isEmpty(attributes)) {
            old_value = attributes.qty_bought.old
            new_value = attributes.qty_bought.new
        }
        return (
            <span>
                {trans('order:log.UPDATE_QUANTITY_ITEM', {
                    order: <span className={'text-primary font-weight-bold'}>{'#' + id}</span>,
                    old_value: old_value,
                    new_value: new_value,
                })}
            </span>
        )
    }

    /**
     * Hiển thị message log update quantity
     */
    renderMessageReceiveItem(param = {}) {
        let logistic_package_barcode = lodash.get(param, 'logistic_package_barcode', '')
        let order_item = lodash.get(param, 'order_item', {})
        let attributes = lodash.get(order_item, 'attributes', {})
        let id = lodash.get(order_item, 'partner_order_item_id', null)

        let old_value = 0
        let new_value = 0
        if (!lodash.isEmpty(attributes)) {
            old_value = attributes.qty_received.old
            new_value = attributes.qty_received.new
        }
        return (
            <span>
                {trans('order:log.RECEIVED_ITEM', {
                    order: <span className={'text-primary font-weight-bold'}>{'#' + id}</span>,
                    logistic_package_barcode: <span className={'text-primary font-weight-bold'}>{logistic_package_barcode}</span>,
                    old_value: old_value,
                    new_value: new_value,
                })}
            </span>
        )
    }

    /**
     * Hiển thị message log update invoice code
     */
    renderMessageUpdateInvoiceCode(param = {}) {
        let attributes = lodash.get(param, 'attributes', {})
        let old_value = 0
        let new_value = 0
        if (!lodash.isEmpty(attributes)) {
            old_value = attributes.invoice_code_old
            new_value = attributes.invoice_code_new
        }
        return (
            <span>
                {trans('order:log.ORDER_UPDATE_INVOICE_CODE', {
                    old: <span className={'text-primary font-weight-bold'}>{old_value}</span>,
                    new: <span className={'text-primary font-weight-bold'}>{new_value}</span>,
                })}
            </span>
        )
    }

    _renderMessageChangeStatus(payload) {
        const oldStatusKey = lodash.get(payload, 'attributes.status.old', '')
        const newStatusKey = lodash.get(payload, 'attributes.status.new', '')
        const oldStatus = trans('order:status.' + oldStatusKey)
        const newStatus = trans('order:status.' + newStatusKey)
        let reasonChangeStatus = ''
        if (oldStatusKey === 'WAITING_PAY' && newStatusKey === 'PAID') {
            reasonChangeStatus = trans('order:log_params.reason', { reason: trans('order:reason.change_status') })
        }
        const log = trans('order:log.CHANGE_STATUS', {
            old: <span className={'text-primary font-weight-bold'}>{oldStatus}</span>,
            new: <span className={'text-primary font-weight-bold'}>{newStatus}</span>,
        })
        return (
            <span>
                {log}
                <br />
                {reasonChangeStatus}
            </span>
        )
    }

    /**
     * render message before change shipping fee
     * @param payload
     * @private
     */
    _renderMessageChangeFee(payload) {
        let newAttr = lodash.get(payload, 'attributes.total_shipping_fee_inland.new', 0)
        let oldAttr = lodash.get(payload, 'attributes.total_shipping_fee_inland.old', 0)
        newAttr = parseFloat(newAttr)
        oldAttr = parseFloat(oldAttr)
        newAttr = currentFormatter.toLocaleStringCurrency(newAttr)
        oldAttr = currentFormatter.toLocaleStringCurrency(oldAttr)
        newAttr = '¥' + newAttr
        oldAttr = '¥' + oldAttr

        return trans('order:log.CHANGE_SHIPPING_FEE', {
            new: <span className={'text-primary font-weight-bold'}>{newAttr}</span>,
            old: <span className={'text-primary font-weight-bold'}>{oldAttr}</span>,
        })
    }

    /**
     * render message before selecte/remove  free ship option
     * @param payload
     * @private
     */
    _renderMessageChangeFreeShip(payload) {
        let newAttr = lodash.get(payload, 'attributes.free_ship.new', '')
        let oldAttr = lodash.get(payload, 'attributes.free_ship.old', '')
        let keyMessage = newAttr ? 'order:log.CHANGE_FREE_SHIP' : 'order:log.REMOVE_FREE_SHIP'
        return trans(keyMessage, {
            new: <span className={'text-primary font-weight-bold'}>{newAttr}</span>,
            old: <span className={'text-primary font-weight-bold'}>{oldAttr}</span>,
        })
    }

    _renderMessageChangeReason(payload) {
        let attributes = lodash.get(payload, 'reasons.attributes', {})
        if (!attributes.old) {
            return trans('violate:log.CHOSE_REASONS', {
                reason: <span className={'text-primary'}>{attributes.new}</span>,
                name: <span className={'text-primary font-weight-bold'}>{payload.order_policy_name}</span>,
            })
        }

        return trans('violate:log.CHANGE_REASONS', {
            new: <span className={'text-primary '}>{attributes.new}</span>,
            old: <span className={'text-primary '}>{attributes.old}</span>,
            name: <span className={'text-primary font-weight-bold'}>{payload.order_policy_name}</span>,
        })
    }

    /**
     * render message before change account purchaser
     * @param payload
     * @private
     */
    _renderMessageChangeAccountPurchaser(payload) {
        let newAttr = lodash.get(payload, 'attributes.account_purchaser.new', '')
        let oldAttr = lodash.get(payload, 'attributes.account_purchaser.old', '')
        let keyMessage = 'order:log.CHANGE_ACCOUNT_PURCHASER'
        if (newAttr === '') {
            keyMessage = 'order:log.REMOVE_ACCOUNT_PURCHASER'
        }

        if (oldAttr === '') {
            keyMessage = 'order:log.FIRST_CHANGE_ACCOUNT_PURCHASER'
        }

        return trans(keyMessage, {
            new: <span className={'text-primary font-weight-bold'}>{newAttr}</span>,
            old: <span className={'text-primary font-weight-bold'}>{oldAttr}</span>,
        })
    }

    /**
     * render message before change order group
     * @param payload
     * @private
     */
    _renderMessageChangeOrderGroup(payload) {
        let newAttr = lodash.get(payload, 'attributes.order_group.new', '')
        let oldAttr = lodash.get(payload, 'attributes.order_group.old', '')
        let keyMessage = 'order:log.CHANGE_ORDER_GROUP'
        if (newAttr === '') {
            keyMessage = 'order:log.REMOVE_ORDER_GROUP'
        }

        if (oldAttr === '') {
            keyMessage = 'order:log.FIRST_CHANGE_ORDER_GROUP'
        }

        return trans(keyMessage, {
            new: <span className={'text-primary font-weight-bold'}>{newAttr}</span>,
            old: <span className={'text-primary font-weight-bold'}>{oldAttr}</span>,
        })
    }

    /**
     * render message before add new invoice code
     * @param payload
     * @private
     */
    _renderMessageAddInvoiceCode(payload) {
        let invoiceCode = lodash.get(payload, 'attributes.invoice_code', '')
        return trans('order:log.ADD_INVOICE_CODE', {
            invoice_code: <span className={'text-primary font-weight-bold'}>{invoiceCode}</span>,
        })
    }

    /**
     * render message before delete new invoice code
     * @param payload
     * @private
     */
    _renderMessageDeleteInvoiceCode(payload) {
        let invoiceCode = lodash.get(payload, 'attributes.invoice_code', '')
        return trans('order:log.DELETE_INVOICE_CODE', {
            invoice_code: <span className={'text-primary font-weight-bold'}>{invoiceCode}</span>,
        })
    }
}

OrderLogMessage.defaultProps = {
    log: {},
}

OrderLogMessage.propTypes = {
    log: PropTypes.object,
}

export default translate()(OrderLogMessage)
