import {createSelector} from 'reselect';
import lodash from 'lodash';
import * as GlobalConstant from './../../../systems/globalContant'
import {dateFormatter, currentFormatter} from './../../common/services/format';
import {getUserSelect} from "../../User/selectors";
import cookies from 'react-cookies';
import {url} from "../../../systems/routing";

const orderDetail = state => lodash.get(state, "PurchaseBuying.orderDetail.order", {});

export const getChannelOrderUpdate = createSelector(
    orderDetail,
    order => [getOrderLogChannel(order), getOrderItemLogChannel(order)]
);

const getOrderLogChannel = (order) => {
    return GlobalConstant.CHANNELS.ORDER_LOG + "_" + order.id;
};

const getOrderItemLogChannel = (order) => {
    return GlobalConstant.CHANNELS.ORDER_NOTE_ITEM + "_" + order.id;
};

export const purchasingOrderDetail = createSelector(
    [orderDetail, getUserSelect],
    (items, user) => {
        let defaultAccountPurchaserId = items.id_purchaser || items.default_account_purchaser_id;
        let settings = lodash.get(user, "settings", "[]");
        try {
            let arrSetting = JSON.parse(settings);
            arrSetting.map(setting => {
                if (setting.code === GlobalConstant.SAVE_ORDER_DISTRIBUTOR && setting.value !== "0") {
                    let savedAccountPurchaserId = cookies.load(GlobalConstant.KEY_ORDER_DISTRIBUTOR);
                    if (savedAccountPurchaserId[items.seller_source]) {
                        defaultAccountPurchaserId = savedAccountPurchaserId[items.seller_source];
                    }
                }
                return setting;
            });

        } catch (exception) {
        }
        let currencySymbol = lodash.get(items, "currencySymbol",  '¥');
        let grand_total_bought = items.grand_total_bought ? parseFloat(items.grand_total_bought) : 0;
        let grand_total = items.grand_total ? parseFloat(items.grand_total) : 0;
        let total_shipping_fee_inland = items.total_shipping_fee_inland ?
            parseFloat(items.total_shipping_fee_inland) : 0;
        let discount_amount = items.discount_amount ?
            parseFloat(items.discount_amount) : 0;
        let site_name = lodash.get(items, 'purchaser.site_name', null);

        if (lodash.isArray(site_name)) {
            site_name = ' - ' + lodash.join(site_name, ',');
        } else {
            if (site_name !== null) {
                site_name = "" + site_name;
                site_name = site_name.toLowerCase();
            } else {
                site_name = "-";
            }
        }
        let agencyCode = lodash.get(items, 'agency.name', '');
        let shortCode = lodash.get(items, 'agency.alias', '').toUpperCase();
        return {
            ...items,
            ...{
                default_account_purchaser_id: defaultAccountPurchaserId,
                agency: {
                    ...items.agency,
                    short_code: shortCode
                },
                rate_deposit_text: lodash.round(items.rate_deposit * 100, 2) || 0,
                buyer_phone_mark: items.buyer_phone || '---',
                rate_exchange_text: items.rate_exchange ? currentFormatter.toLocaleStringCurrency(items.rate_exchange) : '---',
                title: items.title || '---',
                buyer_username: items.buyer_username || '---',
                seller_name: items.seller_name || '---',
                seller_info: items.seller_info || '---',
                purchase_address_info: items.purchase_address_info || '',
                buyer_name: items.buyer_name || '---',
                destination_warehouse: items.destination_warehouse || '---',
                code: items.code || '---',
                orderGroupSelected: items.order_group_id ? {
                    label: items.order_group_info,
                    value: items.order_group_id
                } : {
                    label: '',
                    value: ''
                },
                purcharseSelected: items.id_purchaser ? {
                    label: items.purchaser.account,
                    value: items.id_purchaser
                } : {
                    label: '',
                    value: ''
                },
                shippingFeeHistoryOfSeller: items.shippingFeeHistoryOfSeller ? items.shippingFeeHistoryOfSeller.map((shippingFee) => {
                    return {
                        ...shippingFee,
                        total_shipping_fee_inland_format: shippingFee.total_shipping_fee_inland ? currencySymbol + currentFormatter.toLocaleStringCurrency(parseFloat(shippingFee.total_shipping_fee_inland)) : '',
                    }
                }) : null,
                complaintSellerTotalFormat: items.complaintSellerTotal > 9 ? "9+" : items.complaintSellerTotal,
                urlComplaintByOrder: url.to('complaint-seller') + '?_i=1&code=' + items.code,
                shipping_bill_code: items.shipping_bill_code || '---',
                total_shipping_fee_inland_text: total_shipping_fee_inland ? currencySymbol + currentFormatter.toLocaleStringCurrency(total_shipping_fee_inland) : currencySymbol + '0',
                discount_amount_text: discount_amount ? currencySymbol + currentFormatter.toLocaleStringCurrency(discount_amount) : currencySymbol + '0',
                order_group_info: items.order_group_info || '---',
                grand_total_bought_text: grand_total_bought ? currencySymbol + currentFormatter.toLocaleStringCurrency(grand_total_bought, 5) : currencySymbol + '0',
                grand_total__text: grand_total ? currencySymbol + currentFormatter.toLocaleStringCurrency(grand_total, 5) : currencySymbol + '0',
                seller_aliwang: items.seller_aliwang || '---',
                total_customer_comment: function () {
                    let total = 0;
                    lodash.get(items, 'items', []).map(item => {
                        let isHasCustomerNote = false;
                        if (item.note !== null && Array.isArray(item.note)) {
                            isHasCustomerNote = true;
                        }
                        if (isHasCustomerNote) {
                            total++;
                        }

                        return item;
                    });
                    return total;
                },
                total_link: lodash.get(items, 'items', []).length,
                total_qty: function () {
                    let total = 0;
                    lodash.get(items, 'items', []).map(item => {
                        total += item.qty || 0;
                        return item;
                    });
                    return total;
                },
                total_qty_bought: function () {
                    let total = 0;
                    lodash.get(items, 'items', []).map(item => {
                        total += item.qty_bought || 0;
                        return item;
                    });
                    return total;
                },
                total_qty_received: function () {
                    let total = 0;
                    lodash.get(items, 'items', []).map(item => {
                        total += item.qty_received || 0;
                        return item;
                    });
                    return total;
                },
                total_item_pai_success: function () {
                    let total = 0;
                    lodash.get(items, 'items', []).map(item => {
                        if (item.is_pai === 1) {
                            total++;
                        }
                        return item;
                    });
                    return total;
                },
                total_item_pai_fail: function () {
                    let total = 0;
                    lodash.get(items, 'items', []).map(item => {
                        if (item.is_pai !== 1) {
                            total++;
                        }
                        return item;
                    });
                    return total;
                },
                note_buyer_confirm_message: function () {
                    return lodash.chain(items.note_buyer_confirm).groupBy("username").map(function (offers, username) {
                        let messages = lodash.map(offers, function (it) {
                            return lodash.omit(it, "username");
                        });
                        messages = messages.map(
                            message => {
                                let oldValue = 0;
                                let newValue = 0;
                                if (message.type === 'qty_bought') {
                                    oldValue = message.old;
                                    newValue = message.new;
                                    oldValue = lodash.toInteger(oldValue);
                                    newValue = lodash.toInteger(newValue);
                                    oldValue = currentFormatter.toLocaleStringCurrency(oldValue);
                                    newValue = currentFormatter.toLocaleStringCurrency(newValue);
                                    return {...message, ...{old: oldValue, new: newValue}}
                                }
                                if (message.type === 'unit_price') {
                                    oldValue = message.old;
                                    newValue = message.new;
                                    oldValue = parseFloat(oldValue);
                                    newValue = parseFloat(newValue);
                                    oldValue = currentFormatter.toLocaleStringCurrency(oldValue, 5);
                                    newValue = currentFormatter.toLocaleStringCurrency(newValue, 5);
                                    return {...message, ...{old: currencySymbol + oldValue, new: currencySymbol + newValue}}
                                }
                                return message;
                            });
                        return {
                            username: username,
                            messages: messages,
                            timeAgo: lodash.last(messages).create_time
                        };
                    }).value();
                },
                partner: {
                    ...items.partner,
                    ...{
                        time_deposit_full: lodash.get(items, 'partner.time_deposit', null) ?
                            dateFormatter.dateTime(lodash.get(items, 'partner.time_deposit', null)) : "---"
                    }
                },
                invoiceCodes: lodash.filter(lodash.get(items, 'invoiceCodes', []), {
                    'is_deleted': 0,
                    'id_partner': items.id_partner
                }),
                items: lodash.get(items, 'items', []).map(item => {
                    let unit_price = parseFloat(item.unit_price || 0);
                    let qty_bought = parseFloat(item.qty_bought || 0);
                    return {
                        ...item, ...{
                            image: item.image || '../../resource/default-thumbnail.jpg',
                            location_sale: item.location_sale || '---',
                            option: item.option? item.option.replace(";", " - ") : '---',
                            qty_bought: qty_bought,
                            total: currentFormatter.toLocaleStringCurrency(unit_price * qty_bought, 5),
                            qty: item.qty || 0,
                            unit_price: item.unit_price,
                            unit_price_text: currencySymbol + currentFormatter.toLocaleStringCurrency(unit_price, 5),
                            unit_price_copy: lodash.replace(
                                currentFormatter.toLocaleStringCurrency(unit_price, 5),
                                new RegExp(/,/g), ""
                            ),
                            sum_qty_unit_price_text: currencySymbol + currentFormatter.toLocaleStringCurrency(unit_price * qty_bought, 5),
                            sum_qty_unit_price_copy: lodash.replace(currentFormatter.toLocaleStringCurrency(unit_price * qty_bought, 5), new RegExp(/,/g), ""),
                        }
                    }
                })
            }
        };
    }
);

const orderGroupLists = state => lodash.get(state, 'orderGroup.orderGroups.list', []);


export const getOrderGroupLists = createSelector(
    orderGroupLists,
    items => items.map((item) => buildOrderGroup(item))
);

function buildOrderGroup(orderGroup) {
    return {label: orderGroup.name, value: orderGroup.id}
}
