import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import {Col} from "react-grid-system";
import lodash from "lodash";
import GroupItem from "./GroupItem";
import Button from '@atlaskit/button';

class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: [],
            listConfig: {}
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if ((prevState.listConfig !== nextProps.listConfig) && nextProps.isEndProcess === true) {
            nextState.listConfig = nextProps.listConfig;
            nextState.params = [];
        }

        return nextState;
    }

    /**
     * handle action change value
     * @param input {code:xxxx , value: yyyy}
     */
    onChange(input) {
        let {listConfig, params} = this.state;
        for (let key in listConfig) {
            listConfig[key].map((config) => {
                if (input.code === config.code) {
                    config.value = input.value;
                    let isExist = false;
                    params.map(param => {
                        if (param.code === input.code) {
                            isExist = true;
                        }
                        return param;
                    });

                    if (!isExist) {
                        params.push(input);
                    }
                }
                return config;
            });
        }

        this.setState({
            listConfig: listConfig,
            params: params
        });
    }

    /**
     * Call api update config
     * @param event
     */
    onSubmit(event) {
        event.preventDefault();
        let {params} = this.state;
        this.props.updatePrivateSetting({params: [...params]});
    }

    render() {
        let {t, isLoading} = this.props;
        let {listConfig} = this.state;
        return (
            <React.Fragment>
                <div className="a-setting__block a-content--box-shadow ">
                    {Object.keys(listConfig).map((groupKey, index) => {
                        let group = lodash.get(listConfig, groupKey, []);
                        return (
                            <React.Fragment key={index}>
                                <div className="a-setting__title">
                                    <p>{t('setting:title.' + groupKey)}</p>
                                </div>
                                {
                                    group.map((groupItem, index) => {
                                        return (
                                            <GroupItem
                                                t={t}
                                                key={index}
                                                groupItem={groupItem}
                                                isUsing={true}
                                                onChange={this.onChange.bind(this)}
                                                inputVersion={index}
                                            />
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    })}

                    <Col xs={12} sm={12} md={8} lg={8} xl={8} offset={{md: 4}}>
                        <div className="setting__action d-flex">
                            <Button
                                isLoading={isLoading}
                                appearance="primary" type="submit" className="a-btn--submit"
                                onClick={this.onSubmit.bind(this)}>{t('setting:save')}</Button>
                        </div>
                    </Col>
                </div>
            </React.Fragment>
        );
    }
}

Setting.defaultProps = {
    listConfig: {},
    isEndProcess: true,
};

Setting.propTypes = {
    listConfig: PropTypes.object,
    isEndProcess: PropTypes.bool,
    updatePrivateSetting: PropTypes.func
};

export default translate('translations')(Setting);