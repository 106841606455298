import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import Link from "../../../systems/routing/Link";
import Select from '@atlaskit/select';
import lodash from "lodash";

class PerformAction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: ''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        if (JSON.stringify(nextProps.productReturn.status) !== JSON.stringify(prevState.status)) {
            state.status = nextProps.productReturn.status;
        }

        return state;
    }

    /**
     * xử lý hành động thay đổi status
     * @param data
     */
    onChangeSelect(data) {
        let {productReturn} = this.props;
        if (data && data.value) {
            this.props.onUpdateStatus({status: data.value, id: productReturn.id});
        }
    }

    render() {
        let {t, productReturn} = this.props;
        return (
            <div className="flex--column--reverse-md">
                <div className="a_complaint_solution__top">
                    <div className="a_complaint_solution__title">
                        <span
                            className="a-text--uppercase a-font--18">{t('productReturn:detail.product_return_title')},</span>
                        <h2 className="a_complaint_solution__title__complaint_id a-font--18">
                            <Link isNewTab={true} to={"complaint-seller.detail"}
                                  params={{id: productReturn.id_complaint_seller}}
                            >
                                {t('productReturn:detail.product_return_of_order') + ( productReturn.order ? productReturn.order.code : '---')}
                            </Link>
                        </h2>
                        <span
                            className={"a-status a-status--" + productReturn.status + "  a_complaint_solution__title__status"}>
                  {t('productReturn:status.' + (productReturn.status || "queued").toLowerCase())}
              </span>
                    </div>
                    {productReturn.listChangedStatus.length === 0 ? null :
                        <div className="a_complaint_solution__action">
                            <Select
                                placeholder={t('productReturn:detail.change_status')}
                                className="single-react"
                                classNamePrefix="react-select--filter_item_multi"
                                onChange={data => this.onChangeSelect(data)}
                                options={productReturn.listChangedStatus}
                                value={''}
                                isLoading={lodash.get(this.props, 'isLoadingData', false)}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

PerformAction.defaultProps = {
    productReturn: {},
    isLoadingData: false
};

PerformAction.propTypes = {
    productReturn: PropTypes.object,
    onUpdateStatus: PropTypes.func,
    isLoadingData: PropTypes.bool
};

export default translate()(PerformAction);
