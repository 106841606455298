import {createSelector} from 'reselect';
import lodash from 'lodash';
import {dateFormatter, currentFormatter} from '../../common/services/format';
import {trans} from './../../../systems/i18n';

const transaction = state => lodash.get(state, "Transaction.DetailTransaction.detail", {});
const orders = state => lodash.get(state, "Transaction.DetailTransaction.detail.transaction.orders", {});
const loadingDetailTransaction = state => lodash.get(state, "Transaction.DetailTransaction.loading", false);

export const getDetailTransaction = createSelector(
    transaction,
    loadingDetailTransaction,
    (transaction, loading) => {
        let transactionFile = lodash.get(transaction, 'transactionFile', {});
        let transactionFileId = lodash.get(transaction, 'transactionRaw.id_transaction_file', 0);
        transaction = lodash.get(transaction, 'transaction', {});
        transaction.created_at_format = (transaction.created_at === '0000-00-00 00:00:00' || transaction.created_at === '-0001-11-30 00:00:00') ? '--' : dateFormatter.dateTime(transaction.created_at);
        transaction.transaction_time_format = (transaction.transaction_time === '0000-00-00 00:00:00' || transaction.transaction_time === '-0001-11-30 00:00:00') ? '--' : dateFormatter.dateTime(transaction.transaction_time);
        transaction.transaction_time_create_format = (transaction.transaction_time_create === '0000-00-00 00:00:00' || transaction.transaction_time_create === '-0001-11-30 00:00:00') ? '--' : dateFormatter.dateTime(transaction.transaction_time_create);
        transaction.transaction_time_begin_format = (transaction.transaction_time_begin === '0000-00-00 00:00:00' || transaction.transaction_time_begin === '-0001-11-30 00:00:00') ? '--' : dateFormatter.dateTime(transaction.transaction_time_begin);
        transaction.resolved_time_format = (transaction.resolved_time === '0000-00-00 00:00:00' || transaction.resolved_time === '-0001-11-30 00:00:00') ? '--' : dateFormatter.dateTime(transaction.resolved_time);
        transaction.type_format = transaction.type ? trans('transaction:type.' + transaction.type) : '';
        transaction.amount_format = '¥' + currentFormatter.toLocaleStringCurrency(parseFloat(transaction.amount), 5);
        transaction.amount_refund_format = '¥' + currentFormatter.toLocaleStringCurrency(parseFloat(transaction.amount_refund), 5);
        transaction.service_fee_format = '¥' + currentFormatter.toLocaleStringCurrency(parseFloat(transaction.service_fee), 5);
        transaction.resolved_creator = lodash.get(transaction, 'resolved_creator', '');
        transaction.system = lodash.get(transaction, 'system', '');
        transaction.note_transaction = lodash.get(transaction, 'note_transaction', '--');
        transaction.note = lodash.get(transaction, 'note', '--');
        switch (transaction.cash_flow) {
            case '已收入':
                transaction.cash_flow_format = 'Thu';
                break;
            case '已支出':
                transaction.cash_flow_format = 'Chi';
                break;
            case '资金转移':
                transaction.cash_flow_format = 'Chuyển tiền';
                break;
            default:
                transaction.cash_flow_format = '--';
        }
        switch (transaction.collect_spend) {
            case 'COLLECT':
                transaction.amountColor =  ' a-text--bold a-text--color-primary';
                break;
            case 'SPEND':
                transaction.amountColor =  ' a-text--bold a-text--red';
                break;
            case 'UNKNOWN':
                break;
            default:
                transaction.amountColor =  '';
        }
        transaction.fileId = transactionFileId;
        transaction.filename = lodash.get(transactionFile, 'filename', '--');
        transaction.file_creator = lodash.get(transactionFile, 'creatorInfo.username', '');
        let orders, seller_source = [];
        if (transaction.transaction_invoice_code) {
            orders = lodash.map(transaction.transaction_invoice_code, 'order');
            orders && orders.map(order => {
                order !== null && (
                    !lodash.includes(seller_source, order.seller_source) && seller_source.push(order.seller_source)
                );
            });

        }
        transaction.seller_source = seller_source;
        return transaction;
    }
);
export const getOrders = createSelector(
    orders,
    loadingDetailTransaction,
    (orders, loading) => {
        orders = lodash.values(orders);
        orders = orders.map(order => {
            let grand_total_bought = parseFloat(lodash.get(order, 'grand_total_bought', 0));
            let grand_total = parseFloat(lodash.get(order, 'grand_total'));
            let total_shipping_fee_inland = parseFloat(lodash.get(order, 'total_shipping_fee_inland'));
            let profit = parseFloat(lodash.get(order, 'orderDetail.profit'));
            let profit_percent = parseFloat(lodash.get(order, 'orderDetail.profit_percent'));
            let total_transaction = parseFloat(lodash.get(order, 'orderDetail.total_transaction'));
            if (order) {
                order.grand_total_bought_text = grand_total_bought ? currentFormatter.toLocaleStringCurrency(grand_total_bought, 5) + '¥' : '0¥';
                order.grand_total_text =  grand_total ? currentFormatter.toLocaleStringCurrency(grand_total, 5) + '¥': '0¥';
                order.total_shipping_fee_inland_text =  total_shipping_fee_inland ? currentFormatter.toLocaleStringCurrency(total_shipping_fee_inland, 5) + '¥' : '0¥';
                order.profit_text =  profit ?  currentFormatter.toLocaleStringCurrency(profit, 5) + '¥' : '0¥';
                order.total_transaction_text =  total_transaction ? currentFormatter.toLocaleStringCurrency(total_transaction, 5) + '¥' : '0¥';
                order.profit_percent_text =  (profit_percent && profit_percent !== 0) ? profit_percent + "%" : '0';
            }
            return order;
        });
        return orders;
    }
);

