import React from "react";
import {url} from "../../../../systems/routing";
import {setActiveSettingItem} from "../../../../modules/Settings/SettingMenu/actions";
import lodash from "lodash";
import {Row, Col, Button, notification} from "antd";
import {translate} from "react-i18next";
import NumericInput from "../../../../modules/common/components/Input/NumericInput";
import FormProcessing from "../../../../modules/common/components/FormProcessing";
import {processResponse} from "../../../../systems/api";
import apiService from "../../../../modules/Settings/UserSetting/apiService";

class SettingPrivate extends FormProcessing {

    constructor(props) {
        super(props);

        this.state = {
            input: {
                ipphone: ""
            },
            loading: false,
            inputVersion: 1,
            changedParams: [],
        };
    }

    onSubmit = () => {
        let {input} = this.state;
        let {t, updateIpphone} = this.props;

        this.setState({loading: true});

        let res = processResponse(apiService.updateIpphone({...input}));
        res.then(res => {
            let ipphone = lodash.get(res, "data.ipphone", "");
            this.setState({loading: false});
            updateIpphone({caresoft_ipphone: ipphone});
            notification.success({message: t('setting:update_ipphone_success')});
        });

        res.catch(error => {
            this.setState({loading: false});
            notification.error({message: t('setting:update_ipphone_fail')});
        });
    }

    componentDidMount() {
        let {t, user} = this.props;
        this.props.setPageTitle(t('setting:private_title'));
        this.props.setPageDescription(t('setting:private_description'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.setting-private',
                'link': url.to('setting-private')
            },
        ]);
        this.props.setMenuActive("setting-private");
        setActiveSettingItem('setting-private');
        this.setState({input: {ipphone: user.caresoft_ipphone}});
    }

    render() {
        let {t, user} = this.props;
        let {input, loading} = this.state;

        return (
            <div className="a-content__right new-setting-private">
                <div className="a-setting__block a-content--box-shadow ">
                    <Row className="d-flex" gutter={20}>
                        <Col span={4} className="align-item-center flex-end">
                            Số IP Phone
                        </Col>
                        <Col span={6}>
                            <NumericInput
                                value={input.ipphone}
                                onChange={number => this.changeInput({ipphone:number})}
                                maxLength={4}
                            />
                        </Col>
                        <Col span={14} className="align-item-center">
                            <p className="color-gray font-italic a-text--fz-12">(Xác định IP Phone của Giao dich viên trên Caresoft)</p>
                        </Col>
                    </Row>
                    <Row className="d-flex mt-5" gutter={20}>
                        <Col span={6} offset={4}>
                            <Button
                                className={`mr-5 a-btn--setting_save ${user.caresoft_ipphone === input.ipphone ? 'disable' : ''}`}
                                type="primary"
                                onClick={this.onSubmit}
                                disabled={user.caresoft_ipphone === input.ipphone}
                                loading={loading}
                            >{t('setting:save')}</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default translate()(SettingPrivate);