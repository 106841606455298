import {takeLatest} from 'redux-saga/effects';
import * as constants from './constants';
import * as saga from './saga';

export default function*() {
    yield takeLatest(constants.PURCHASE_ADDRESS.REQUEST, saga.getPurchaseAddressList);
    yield takeLatest(constants.PURCHASE_ADDRESS_CREATE.REQUEST, saga.createPurchaseAddress);
    yield takeLatest(constants.PURCHASE_ADDRESS_UPDATE.REQUEST, saga.updatePurchaseAddress);
    yield takeLatest(constants.PURCHASE_ADDRESS_DETAIL.REQUEST, saga.getPurchaseAddressDetail);
    yield takeLatest(constants.UPDATE_STATUS_ADDRESS_PURCHASE.REQUEST, saga.updateStatusPurchaseAddressDetail);
}
