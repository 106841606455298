import React from "react";
import {Button, Icon, notification} from "antd";
import lodash from "lodash";
import CrawlModel from "./CrawlModel";
import { withEvent } from "../../../../../systems/events";
import * as SYNC_EVENTS from "./constants";
import {translate} from "react-i18next";

const DEFAULT_INDEX = -1;

class SynchronizingButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentIndex: DEFAULT_INDEX
        }
        this.crawl = new CrawlModel();
    }

    componentDidMount() {
        this.props.on([
            SYNC_EVENTS.CRAWL_SUCCESS,
            SYNC_EVENTS.CRAWL_FAIL,
            SYNC_EVENTS.DONT_LOGIN_SITE
        ], this.handleEventListener.bind(this));
    }

    componentDidUpdate(prevProps, prevState) {
        let {
            isCrawling,
            complaints,
            pagination
        } = this.props;
        let old_pagination = lodash.get(prevProps, "pagination", {});
        let {currentIndex} = this.state;

        if (isCrawling && pagination.current_page !== old_pagination.current_page && complaints && currentIndex === DEFAULT_INDEX) {
            this.startCrawling();
        }
    }

    componentWillUnmount() {
        this.crawl.terminate();
    }

    handleEventListener(event, payload) {
        let {
            updateComplaints,
            pushFailedComplaintIds,
            pushSuccessedComplaintIds
        } = this.props;

        switch (event) {
            case SYNC_EVENTS.CRAWL_SUCCESS: {
                let complaint = lodash.get(payload, "data.complaintSeller", {});
                pushSuccessedComplaintIds(complaint.id);
                updateComplaints(complaint, this.next.bind(this));
                return;
            }
            case SYNC_EVENTS.DONT_LOGIN_SITE: {
                notification.error({message: "Vui lòng đăng nhập site gốc để đồng bộ thông tin KNNB"});
                return;
            }
            case SYNC_EVENTS.CLOSE_TAB: {
                this.next();
                return;
            }
            case SYNC_EVENTS.CRAWL_FAIL: {
                let complaint = lodash.get(payload, "complaint", {});
                pushFailedComplaintIds(complaint.complaint_id)
                this.next();
                return;
            }
        }
    }

    onClick() {
        this.startCrawling();
    }

    startCrawling() {
        let { isCrawling, setIsCrawling } = this.props;

        if (!isCrawling)
            setIsCrawling(true, this.next.bind(this));
        else
            this.next();
    }

    next() {
        let { currentIndex } = this.state;
        let {
            complaints,
            setCrawlingComplaintId,
            isCrawling
        } = this.props;
        let nextIndex = currentIndex+1;

        if (!isCrawling)
            return;

        if (nextIndex < complaints.length) {
            /*
            * Tăng index cho KNNB lần tới
            * Thiết lập component cha để active icon loading ngoài màn hình
            * Bắt đầu crawl KNNB hiện tại
            */
            let complaint = complaints[nextIndex];
            this.setState({currentIndex: nextIndex}, () => {
                setCrawlingComplaintId(complaint.id);
                this.crawl.boot(complaint);
            });
        } else {
            this.nextPage();
        }
    }

    nextPage() {
        let { pagination, onNextPage, isCrawling } = this.props;

        if (pagination.current_page >= pagination.page_total) {
            this.reset();
        } else {
            this.reset(true);
            onNextPage(pagination.current_page+1);
        }
    }

    reset(crawling = false) {
        let { setCrawlingComplaintId, setIsCrawling } = this.props;

        this.setState({currentIndex: DEFAULT_INDEX});
        if (!crawling)
            setIsCrawling(false);

        setCrawlingComplaintId(undefined);
    }

    onCancel() {
        this.crawl.cancel();
        this.reset();
    }

    render() {
        let {isCrawling, t} = this.props;
        return (
            <>
                <Button
                    className="a-font--12 aphz-24 a-btn--primary-ant mr-4"
                    size={"small"}
                    icon="sync"
                    onClick={this.onClick.bind(this)}
                    disabled={isCrawling}
                    loading={isCrawling}
                    style={{lineHeight: '32px', height: '32px'}}
                >{t('complaintSeller:sync.sync_button_title')}</Button>
                <Button
                    className=" a-font--12 a-btn--primary-ant a-btn--link a-text--red"
                    size={"small"}
                    onClick={this.onCancel.bind(this)}
                    disabled={!isCrawling}
                    style={{lineHeight: '32px', height: '32px'}}
                >{t('btn.cancel')}</Button>
            </>
        );
    }

}

export default translate()(withEvent(SynchronizingButton));
