import {call, put} from 'redux-saga/effects';
import lodash from 'lodash';
import bootbox from "../../../modules/common/components/Bootbox/bootbox";
import * as Constants from './constants';
import apiService from "./apiService";
import * as GlobalConstants from "../../../systems/globalContant";
import {t} from './../../../systems/i18n/index.js';

export function* fetchConfigSystem(action) {
    try {
        let response = yield call(apiService.fetchConfigSystem, action.payload);
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.FETCH_CONFIG_SYSTEM.SUCCESS, payload: response.data});
        } else {

            yield put({type: Constants.FETCH_CONFIG_SYSTEM.FAILED, payload: response.data});
            bootbox.error(t('message.system_errors'));
        }
    } catch (err) {
        bootbox.error(t("message.system_errors") + " : " + err.message);
        yield put({type: Constants.FETCH_CONFIG_SYSTEM.FAILED, payload: {}});
    }
}

export function* updateConfigSystem(action) {
    try {
        let response = yield call(apiService.updateConfigSystem, action.payload);
        let orderDistributor_maxItem = lodash.get(response, 'data.data.ORDER_DISTRIBUTOR:MAX_ITEM', []);

        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.UPDATE_CONFIG_SYSTEM.SUCCESS, payload: response.data});
            bootbox.success(t('setting:update_success'));
        } else if (!lodash.isEmpty(orderDistributor_maxItem)) {
            yield put({type: Constants.UPDATE_CONFIG_SYSTEM.FAILED, payload: response.data});
            bootbox.error(t('setting:input_required_receive_order'));
        } else if (lodash.get(response, 'data.data.params', {}).hasOwnProperty('required') ) {
            yield put({type: Constants.UPDATE_CONFIG_SYSTEM.FAILED, payload: response.data});
            bootbox.error(t('setting:params_required'));
        }  else if (lodash.get(response, 'data.data', {}).hasOwnProperty('unauthorized') ) {
            yield put({type: Constants.UPDATE_CONFIG_SYSTEM.FAILED, payload: response.data});
            bootbox.error(t('error_page.permission_not_allow'));
        } else {
            yield put({type: Constants.UPDATE_CONFIG_SYSTEM.FAILED, payload: response.data});
            bootbox.error(t('message.system_errors'));
        }
    } catch (err) {
        bootbox.error(t("message.system_errors") + " : " + err.message);
        yield put({type: Constants.UPDATE_CONFIG_SYSTEM.FAILED, payload: {}});
    }
}

