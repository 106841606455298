import React from 'react';
import {createSelector} from 'reselect';
import lodash from "lodash";
import {currentFormatter} from "./../../common/services/format";
import moment from "moment";

const ordersSelector = state => lodash.get(state, 'Transaction.ListTransactionOrder.list', []);
export const pagination = state => lodash.get(state, "Transaction.ListTransactionOrder.pagination", []);
export const listStatus = state => lodash.get(state, 'Transaction.ListTransactionOrder.listStatus', []);
export const filters = state => lodash.get(state, 'Transaction.ListTransactionOrder.filters', {});

export const getState = (state, param = null, defaultValue = null) => lodash.get(state, 'Transaction.ListTransactionOrder' + (param ? '.' + param : ''), defaultValue);

/**
 * Get list order
 */
export const getOrders = createSelector(
    ordersSelector,
    orders => orders.map(order => {
            let currencySymbol = '¥';//lodash.get(order, "currencySymbol",  '¥');
        order.key = order.code;
        order.grand_total_bought_display = currencySymbol + currentFormatter.toLocaleStringCurrency(parseFloat(order.grand_total_bought));
        order.collect_amount = currencySymbol + currentFormatter.toLocaleStringCurrency(parseFloat(order.collect_amount));
        order.spend_amount = currencySymbol + currentFormatter.toLocaleStringCurrency(parseFloat(order.spend_amount));
        order.total_shipping_fee_inland_display = currencySymbol + currentFormatter.toLocaleStringCurrency(parseFloat(order.total_shipping_fee_inland));
        order.profit_display = currencySymbol + currentFormatter.toLocaleStringCurrency(parseFloat(order.profit));
        order.total_transaction_display = currencySymbol + currentFormatter.toLocaleStringCurrency(parseFloat(order.total_transaction));
        order.profit_percent_display = order.profit_percent + "%";
        order.bought_at = order.bought_at ? moment(order.bought_at).format('DD/MM/YYYY H:mm') : "---";
        order.deposited_at = order.deposited_at ? moment(order.deposited_at).format('DD/MM/YYYY H:mm') : "---";
        order.cancelled_at = order.cancelled_at ? moment(order.cancelled_at).format('DD/MM/YYYY H:mm') : "---";
        order.out_of_stock_at = order.out_of_stock_at ? moment(order.out_of_stock_at).format('DD/MM/YYYY H:mm') : "---";
        return order;
    })
);
export const getProblemTypes = types => {
        return lodash.keys(types);
};

