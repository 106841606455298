import React from 'react';
import {translate} from "react-i18next";
import {Button, Icon, Select, Row, Col, Dropdown, Menu} from 'antd';
import lodash from "lodash";

const ButtonGroup = Button.Group;
const {Option} = Select;
const STATSES = ["ALL", "WAITING_BUY", "WAITING_PAI", "PAID", "WAITING_PAY", "BOUGHT", "SELLER_DELIVERY", "RECEIVED", "OUT_OF_STOCK", "CANCELLED"];
const ORDER_STATUS_ADD_NEW = ["TRANSPORTING", "WAITING_DELIVERY", "DELIVERING", "DELIVERED"];

class Stats extends React.Component {

    handleClick = status => {
        let orderStatus = lodash.get(this.props.filter, 'status', []);
        orderStatus = orderStatus.includes(status) ? orderStatus.filter(item => item !== status) : [...orderStatus, status];

        this.props.onSearch({status: orderStatus, page: 1});
    };

    onChange = value => {
        let sort_arr = value.split('|');
        if (sort_arr.length === 1)
            this.props.onSearch({orderBy: "", sort: sort_arr[0]});
        else
            this.props.onSearch({orderBy: sort_arr[0], sort: sort_arr[1]});
    }

    render() {
        let {stats, t, filter, canChangeWaitingPay, canShowOrderWaitingBuy} = this.props;
        let orderStatus = lodash.get(filter, 'status', []);

        let sortValue = "";
        if (filter.orderBy && filter.sort)
            sortValue = filter.orderBy + "|" + filter.sort;
        else
            sortValue = filter.sort || "asc";
        let ORDER_STATSES = STATSES.slice();
        if (!canShowOrderWaitingBuy) {
            lodash.remove(ORDER_STATSES, function(status) {
                return status === 'WAITING_BUY';
            });
        }

        if (canChangeWaitingPay !== "1") {
            ORDER_STATSES = STATSES.filter(item => item !== "WAITING_PAY");
        }
        let order_status_add_new_active = ORDER_STATUS_ADD_NEW.filter(element => orderStatus.includes(element));
        const menu = (
            <Menu
                multiple
                selectedKeys={order_status_add_new_active}
            >
                {
                    ORDER_STATUS_ADD_NEW.map((item) => {
                        return (
                            <Menu.Item key={item} onClick={() => this.handleClick(item)} className={`${orderStatus.includes(item) ? "a-status--active-status-order" : ""}`}>
                                {t(`order:status.${item}`)} {stats[item] !== undefined ? `(${stats[item]})` : ""}
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        );
        return (
            <Row gutter={10} className="list-order-stats">
                <Col xs={24} lg={18}>
                    <ul className="d-flex justify-content-start list-order-stats-nav a-text--light-red a-text--fz-13">
                        {ORDER_STATSES.map((item) => {
                                return <li
                                    key={item}
                                    className={`px-4 py-3 a--cursor-poiter${orderStatus.includes(item) ? " active" : ""}${item != "OVERDUE_PURCHASE" ? " a-text--color-black-45" : " a-text--light-red"}`}
                                    onClick={() => this.handleClick(item)}
                                >
                                <span>
                                    {t(`order:status.${item}`)} {stats[item] !== undefined ? `(${stats[item]})` : ""}
                                </span>
                                </li>
                            }
                        )
                        }

                        {
                            <li className={`px-4 py-3 a--cursor-poiter${order_status_add_new_active.length > 0 ? " active" : ""}`}>
                                <Dropdown overlay={menu}>
                                    <Button style={{
                                        border: "none",
                                        height: "unset",
                                        color: `${order_status_add_new_active.length > 0 ? " white" : "black"}`,
                                        background: "unset"
                                    }}>...</Button>
                                </Dropdown>
                            </li>
                        }
                    </ul>
                </Col>
                <Col xs={24} lg={6}>
                    <div className="list-order-orderby">
                        <div>
                            Sắp xếp:
                            <Select
                                style={{width: "180px"}}
                                className="ml-2"
                                onChange={this.onChange}
                                value={sortValue}
                                size="small"
                            >
                                <Option value="asc">Cũ trước</Option>
                                <Option value="desc">Mới trước</Option>
                                <Option value="grand_total|desc">Tiền hàng cao trước</Option>
                                <Option value="grand_total|asc">Tiền hàng thấp trước</Option>
                            </Select>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default translate()(Stats);
