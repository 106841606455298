import React from 'react';
import {translate} from 'react-i18next';
import Setting from "../components/Setting";
import {url} from "../../../../systems/routing";
import {setActiveSettingItem} from '../../SettingMenu/actions';

class TransactionSettingComponent extends React.Component {

    componentDidMount() {
        let {t} = this.props;
        this.props.setPageTitle(t('breadcrumb.setting-transaction'));
        this.props.setPageDescription(t('setting-transaction.detail'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.setting-transaction',
                'link': url.to('setting-transaction')
            },
        ]);
        this.props.setMenuActive("setting-transaction");
        setActiveSettingItem('setting-transaction');
        this.props.fetchConfigSystem();
    }

    render() {
        let {
            configSetting,
            updateConfigSystem,
            loading,
            t
        } = this.props;

        // permissionGroup: là nhóm quyền endpoint trả về để client nhận biết các cấu hình được chỉnh sửa ở mỗi page
        return (
            <div className="a-content__right">
                {
                    configSetting ?
                        <Setting
                            configSetting={configSetting}
                            loading={loading}
                            updateConfigSystem={updateConfigSystem}
                            permissionGroup="transactionsSettings"
                        /> :
                        <div className="a-setting__block a-content--box-shadow">
                            <p className={"d-flex justify-content-center"}>{t("setting:no_record_global")}</p>
                        </div>
                }
            </div>
        );
    }
}

TransactionSettingComponent.defaultProps = {};
TransactionSettingComponent.propTypes = {};

export default translate('translations')(TransactionSettingComponent);
