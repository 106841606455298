import React from 'react';
import {translate} from 'react-i18next';
import * as PropTypes from "prop-types";
import Item from "./Item";

class ListRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {t, saleFigures} = this.props;

        return (
            <ul className="a_salefigures__history_list">
                {saleFigures.length ?
                    (<React.Fragment>
                        {saleFigures.map((saleFigureItem, index)=> (
                            <Item key={index} data={saleFigureItem}/>
                        ))}
                    </React.Fragment>)
                    :
                    (<React.Fragment>
                        <li>
                            <p>{t('sale:list.not_found')}</p>
                        </li>
                    </React.Fragment>)
                }
            </ul>
        );
    }
}

ListRequest.defaultProps = {
    filter: {},
    saleFigures: [],
};

ListRequest.propTypes = {
    filter: PropTypes.object,
    saleFigures: PropTypes.array,
};

export default translate('translations')(ListRequest);
