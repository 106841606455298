import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import Select from "@atlaskit/select/dist/esm/Select";
import InlineEdit from "../../../common/components/InlineEdit";
import {Row, Col} from "antd";

class ReceiveComplaint extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            selected: null
        }
    }

    componentWillUnmount() {
        this.props.onReset();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        if (JSON.stringify(nextProps.selected) !== JSON.stringify(prevState.selected)) {
            if (!lodash.isEmpty(nextProps.selected)) {
                state.selected = nextProps.selected;
            }
        }
        return state;
    }

    onAssign(data) {
        this.setState({selected: data});
        if (!this.props.loading) {
            this.props.onAssign(data.value);
        }
    }

    render() {
        let {t, canAssign, purchasers} = this.props;
        let {selected} = this.state;

        return (
            <Row>
                <Col span={8} className="cs-order-row">
                    <label className="a-text--font-b white-space-nowrap">
                        {t('complaintSeller:detail.processor')}
                    </label>
                </Col>
                <Col span={16} className="cs-order-row">
                    <div className="a__complaint__edit_user_ex">
                        {
                            canAssign ?
                            <InlineEdit
                                text={lodash.get(selected,'label', '---')}
                                isEdit={false}
                                disableAction
                                integer
                                isPencilLeft
                                editDisabled={false}
                            >
                                <Select
                                    className="single-react"
                                    classNamePrefix="react-select--purchasebuying"
                                    options={purchasers}
                                    value={selected}
                                    placeholder={t('complaintSeller:detail.choose_complaint_seller')}
                                    autoFocus={true}
                                    onChange={data => this.onAssign(data)}
                                    menuIsOpen={true}
                                    shouldFitContainer
                                />
                            </InlineEdit> :
                            lodash.get(selected,'label', '---')
                        }

                    </div>
                </Col>
            </Row>
        );
    }
}

ReceiveComplaint.defaultProps = {
    canReceive: false,
    loading: false,
    purchasers: [],
    onAssign: () => {
    },
    onReset: () => {
    },
};

ReceiveComplaint.propTypes = {
    canReceive: PropTypes.bool,
    loading: PropTypes.bool,
    purchasers: PropTypes.array,
    onAssign: PropTypes.func,
    onReset: PropTypes.func,
};

export default translate()(ReceiveComplaint);
