import {translate} from "react-i18next";
import React, {useState} from 'react';
import {Button, notification} from "antd";
import lodash from 'lodash';
import apiService from "../../screens/ForeignOrder/apiService";
import {processResponse} from "../../systems/api";

function DeleteTaobaoPurchaseOrder(props) {
    const {orderId, onSubmit} = props;
    const [loading, setLoading] = useState(false);

    function handleSubmit() {
        setLoading(true);
        processResponse(apiService.deleteSTOrder(orderId, {cancel_reason: "Change purchase address"})).then(() => {
            onSubmit();
            notification.success({message: "Huỷ đơn TBG thành công!"})
        }).catch((err) => {
            let message = "Huỷ đơn TBG thất bại! ";
            const cancel_error = lodash.get(err, "data.cancel_error", "");
            if (cancel_error) {
                message += cancel_error;
            }
            notification.error({message})
        }).finally(() => setLoading(false))
    }

    return <Button type="primary" loading={loading} onClick={handleSubmit}>Huỷ đơn TBG</Button>
}

export default translate()(DeleteTaobaoPurchaseOrder)