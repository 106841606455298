import {createSelector} from 'reselect';
import lodash from 'lodash';
import {PAYMENT_ORDER_ERROR} from './constants';

export const getPaymentOrder = state => lodash.get(state, 'PurchaseBuying.paymentOrder', {});

export const showFormSelectViolateReason = createSelector(
    getPaymentOrder,
    state => lodash.get(state, 'error.code') === PAYMENT_ORDER_ERROR.ORDER_POLICIES
);

export const showFormConfirmNotEnoughMoney = createSelector(
    getPaymentOrder,
    state => lodash.toString(lodash.get(state, 'error.code', '')).toUpperCase() === PAYMENT_ORDER_ERROR.ORDER_CONFIRM_MONEY
);

export const isPayingOrder = createSelector(
    getPaymentOrder,
    state => lodash.get(state, 'loading')
);

export const getViolatedPolicies = createSelector(
    getPaymentOrder,
    state => lodash.get(state, 'error.data', [])
);
export const getMessageShowFormConfirmNotEnoughMoney = createSelector(
    getPaymentOrder,
    state => lodash.get(state, 'error.data.error.message', false)
);

