import {createAction} from 'redux-actions';
import {
    WINDOW_PAGE,
    NOTIFICATION,
    PRINT_STAMP_PACKAGE,
    PUSHER_CREATE_NEW_ORDER_EVENT,
    CLEAR_COUNT_CREATE_NEW_ORDER_EVENT,
    FIRE_MENU_STATUS,
} from '../../systems/globalContant/actionContant';

export const setPageTitle = createAction(WINDOW_PAGE.UPDATE_TITLE, (value) => ({title: value}));
export const setPageDescription = createAction(WINDOW_PAGE.UPDATE_PAGE_DESCIPTION, (value) => ({subDesc: value}));
export const setBreadCrumb = createAction(WINDOW_PAGE.UPDATE_BREADCRUMB, (value) => ({data: value}));
export const setMenuActive = createAction(WINDOW_PAGE.UPDATE_MENU_ACTIVE, (value) => ({menu_active: value}));
export const hideNotification = createAction(NOTIFICATION.SET_EMPTY_MESSAGE, () => ({}));
export const printStamp = createAction(PRINT_STAMP_PACKAGE.PRINT, (data) => (data));
export const pusherCreateNewOrder = createAction(PUSHER_CREATE_NEW_ORDER_EVENT, (order) => (order));
export const clearCreateNewOrder = createAction(CLEAR_COUNT_CREATE_NEW_ORDER_EVENT, () => ({}));
export const fireMenuStatus = createAction(FIRE_MENU_STATUS, (isOpen) => (isOpen));