import React from 'react'
import {translate} from 'react-i18next';
import {Checkbox} from "antd";
import PropTypes from 'prop-types';
import Input from '../../../../common/components/Input/Super';
import lodash from 'lodash'

class ShippingFee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            isFree: false,
            isChecked: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const order = lodash.get(nextProps, 'order', null);
        let nextState = {...prevState};

        if (order) {
            if (order.canChangeShippingFee) {
                nextState.isChecked = order.free_ship;
                nextState.disabled = !!order.free_ship;

            } else {
                nextState.disabled = true;
            }
        }
        return nextState;
    }

    onChangeCheckbox(event) {
        const {onUpdateShippingFee, order} = this.props;
        let checked = event.target.checked;
        if (checked) {
            if (order && order.id) {
                onUpdateShippingFee({
                    free_ship: true,
                    id: order.id,
                    "status": order.status
                })
            }
            this.setState({
                disabled: true,
                isChecked: true
            });
        } else {
            onUpdateShippingFee({
                free_ship: false,
                id: order.id,
                "status": order.status
            });
            this.setState({
                disabled: false,
                isChecked: false
            });
        }
    }

    onUpdateShippingFee(event) {
        const {order, onUpdateShippingFee} = this.props;
        let value = event.target.value;

        if (event.key === 'Enter') {
            let length = value.length;
            if (value[length - 1] === '.') {
                value = value.substr(0, length - 1);
            }
            event.target.blur();
            if (value === order.total_shipping_fee_inland) {
                return false;
            }
            onUpdateShippingFee({
                id: order.id,
                total_shipping_fee_inland: value,
                "status": order.status
            })
        }
    }

    render() {
        const {t, order} = this.props;
        const {disabled, isChecked} = this.state;

        return (
            <React.Fragment>
                <div className="a-purchasod__group__shipping_fee__block">
                    <label className="d-flex align-item-center">
                        <p>{t("order_detail.free")}</p>
                        <Checkbox
                            checked={isChecked}
                            disabled={!order.canChangeShippingFee}
                            onChange={event => this.onChangeCheckbox(event)}
                            name="checkbox-basic"
                            className="ml-1 mr-3"
                        />
                    </label>
                </div>
                <div className="a-purchasod__group__shipping_fee__block">
                    <p>{t("order_detail.or")}</p>
                    <Input
                    value={order.free_ship ? '' : order.total_shipping_fee_inland}
                    onKeyPress={event => this.onUpdateShippingFee(event)}
                    placeholder={"NDT"}
                    disabled={disabled}
                    className={'input__shipping_fee'}
                    />
                    </div>
            </React.Fragment>
        )
    }
}

ShippingFee.propTypes = {
    onUpdateShippingFee: PropTypes.func,
    order: PropTypes.object
};

export default translate('translations')(ShippingFee)

