import {call, put} from 'redux-saga/effects'
import purchaseApi from './apiService'
import * as purchaseConstants from './constants'
import {router, url} from '../../systems/routing';
import * as GlobalConstants from './../../systems/globalContant'
import bootbox from "../common/components/Bootbox/bootbox";
import {t} from "../../systems/i18n";
import lodash from "lodash";

/**
 * Get purchase address list saga
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getPurchaseAddressList(action) {
    try {
        let response = yield call(purchaseApi.getPurchaseAddressList, action.payload);
        yield put({type: purchaseConstants.PURCHASE_ADDRESS.SUCCESS, response})

    } catch (err) {
        yield put({type: purchaseConstants.PURCHASE_ADDRESS.FAILED, err})
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* createPurchaseAddress(action) {
    try {
        let response = yield call(purchaseApi.createPurchaseAddress, action.payload.params);

        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            bootbox.success(t('purchase_address.created_success'));
            yield put({type: purchaseConstants.PURCHASE_ADDRESS_CREATE.SUCCESS, payload: response.data});
            let id = lodash.get(response, 'data.data.purchaseAddress.id', null);
            router.redirect(url.to('purchase-address') + "/" + id);
        } else {
            yield put({type: purchaseConstants.PURCHASE_ADDRESS_CREATE.FAILED, payload: response.data});
        }
    } catch (err) {
        bootbox.success(t('purchase_address.created_fail'));
        yield put({type: purchaseConstants.PURCHASE_ADDRESS_CREATE.FAILED, err})
    }
}

/**
 * Update purchase address
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* updatePurchaseAddress(action) {
    try {
        let response = yield call(purchaseApi.updatePurchaseAddress, action.payload.id, action.payload.params);
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            bootbox.success(t('purchase_address.updated_success'));
            yield put({type: purchaseConstants.PURCHASE_ADDRESS_UPDATE.SUCCESS, payload: response.data});
        } else {
            yield put({type: purchaseConstants.PURCHASE_ADDRESS_UPDATE.FAILED, payload: response.data});
        }
    } catch (err) {
        bootbox.error(t('purchase_address.updated_fail'));
        yield put({type: purchaseConstants.PURCHASE_ADDRESS_UPDATE.FAILED, payload: {}})
    }
}

/**
 * Update purchase address
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getPurchaseAddressDetail(action) {
    try {
        let response = yield call(purchaseApi.getPurchaseAddress, action.payload.id, action.payload.params);
        yield put({type: purchaseConstants.PURCHASE_ADDRESS_DETAIL.SUCCESS, payload: response.data});
    } catch (err) {
        yield put({type: purchaseConstants.PURCHASE_ADDRESS_DETAIL.FAILED, err})
    }
}


export function* updateStatusPurchaseAddressDetail(action) {

    try {
        let response = yield call(purchaseApi.updatePurchaseAddress, action.payload.id, action.payload.params);
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            bootbox.success(t('purchase_address.updated_success'));
            yield put({type: purchaseConstants.UPDATE_STATUS_ADDRESS_PURCHASE.SUCCESS, payload: response.data});
        } else {
            yield put({type: purchaseConstants.UPDATE_STATUS_ADDRESS_PURCHASE.FAILED, payload: response.data});
            router.redirect(url.to('purchase-address'));
        }

    } catch (err) {
        bootbox.error(t(err.message) || t('system.error'));
        yield put({type: purchaseConstants.UPDATE_STATUS_ADDRESS_PURCHASE.FAILED, payload: action.payload.params});
    }
}
