import {translate} from "react-i18next";
import Select from "@atlaskit/select/dist/esm/Select";
import lodash from "lodash";
import moment from 'moment';
import Button from '@atlaskit/button';
import * as React from "react";
import * as PropTypes from "prop-types";

class CreateRequestForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            purchaser_id: [],
            month: lodash.toInteger(moment().format('M')),
            year: lodash.toInteger(moment().format('YYYY')),
        }
    }

    /**
     * list purchaser default
     */
    makePurchaserDefault() {
        let {purchasers, t} = this.props;
        let purchasersClone = [...purchasers];
        lodash.sortBy(purchasersClone, ['username']);
        if (purchasersClone.length > 0) {
            purchasersClone.unshift({id: 'all', username: t("sale:form.all_purchaser"), name: ''});
        }
        purchasersClone = purchasersClone.map(purchaser => ({
            value: purchaser.id,
            label: `${purchaser.username} ${purchaser.name ? ' - ' + purchaser.name : ''}`,
        }));
        return purchasersClone;
    }

    /**
     * handle action change select
     * @param option
     */
    onChangeSelect(option, event) {
        this.setState({[option]: event ? event.value : null});
        this.props.clearErrorWhileCreate([option]);
    }

    onChangeMultiSelect(option, select) {
        let value = select.map((selectItem) => {
            return selectItem.value;
        });
        this.setState({[option]: value});
        this.props.clearErrorWhileCreate([option]);
    }

    makeValueMultiSelect(select) {
        let optionUsers = this.makePurchaserDefault();
        optionUsers = optionUsers.map(optionUsersItem => {
            let label = optionUsersItem.label;
            label = label.trim();
            label = label.length > 20 ? label.substr(0, 20) + '...' : label;
            return {...optionUsersItem, ...{label: label}};
        });
        return optionUsers.filter((optionUsersItem) => {
            return select.includes(optionUsersItem.value);
        });
    }

    /**
     * Enter to submit
     * @param event
     */
    onKeyPress(event) {
        if (event.key === 'Enter' && !this.props.isLoading) {
            let data = this.state;
            let all_purchaser = false;
            let month = event.target.value;
            month = lodash.toInteger(month);
            if( !data.month || data.month !== month && month){
                data = {...data,...{month: month}}
                this.setState({month: month});
            }
            let {purchasers} = this.props;
            let {purchaser_id} = data;
            purchasers = purchasers.map(purchaserItem=>{
                if( lodash.includes(purchaser_id, purchaserItem.id) ){
                    return purchaserItem;
                }
                return null;
            });
            purchasers = lodash.compact(purchasers);
            if( lodash.includes( purchaser_id, 'all' ) ){
                all_purchaser = true;
            }
            data = {...data,...{purchasers:purchasers, all_purchaser:all_purchaser}};
            this.props.createReports(data);
        }
    }

    /**
     * handle action submit form
     */
    onSubmit() {
        if (!this.props.isLoading) {
            let data = this.state;
            let {purchasers} = this.props;
            let {purchaser_id} = data;
            let all_purchaser = false;
            purchasers = purchasers.map(purchaserItem=>{
                if( lodash.includes(purchaser_id, purchaserItem.id) ){
                       return purchaserItem;
                }
                return null;
            });
            purchasers = lodash.compact(purchasers);
            if( lodash.includes( purchaser_id, 'all' ) ){
                all_purchaser = true;
            }
            data = {...data,...{purchasers:purchasers, all_purchaser:all_purchaser}};
            this.props.createReports(data);
        }
    }

    /**
     * Thực hiện render lỗi thông báo ra bên ngoài
     * @param attrName
     * @returns {null}
     */
    renderError(attrName) {
        let {error, t} = this.props;
        let errorItem = lodash.get(error, attrName, {});

        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('sale:error.' + Object.keys(errorItem)[0], {name: t('sale:create.' + attrName)});
                default:
                    return null;
            }
        }
    }

    onChangeFormInput(param, value) {
        this.setState({[param]: value});
    }

    render() {
        let {t, months, isLoading, year_previous, year_current} = this.props;
        let optionUsers = this.makePurchaserDefault();
        let {purchaser_id, month, year} = this.state;

        let date_to = year+'-'+month+'-15';
        let date_from =  moment(date_to).add(-1, 'months');
        date_from = date_from.format('MM-YYYY');
        date_to = moment(date_to).format('MM-YYYY');
        date_from = '16-'+date_from+' 00:00:00';
        date_to = '15-'+date_to+' 23:59:59';

        return (
            <div>
                <div className={'w-100 a_salefigures__title '}>
                    <span>{t("sale:form.title")}</span>
                </div>
                <div className="a_salefigures__action">
                    <div className="d-flex flex-wrap">
                        <div className="mr-4 a_salefigures__action__select_user">
                            <label className="w-100 a_salefigures__label mb-2">
                                {t("sale:form.choose_purchaser_label")}
                                <span className="ml-1 a-text--red">*</span>
                            </label>
                            <Select
                                noOptionsMessage={() => t("system.no_option")}
                                placeholder={t("sale:form.choose_purchaser_placeholder")}
                                value={this.makeValueMultiSelect(purchaser_id)}
                                options={optionUsers}
                                className="single-react select__sale_figures mt-2"
                                classNamePrefix="react-select--sale_figures"
                                onChange={this.onChangeMultiSelect.bind(this, 'purchaser_id')}
                                isMulti
                            />
                            <div className={"validatorMessage a--error "
                            + ( this.renderError("purchaser_id") ? 'active-error' : 'inactive-error' )}>
                                {this.renderError("purchaser_id")}
                            </div>
                        </div>
                        <div className="ml-4 mr-4 flex-wrap a_salefigures__action__height_sp a_salefigures__action__select_month ">
                            <label className="w-100 a_salefigures__label mb-2">{t("sale:form.choose_month_label")}<span className="ml-1 a-text--red">*</span></label>
                            <Select
                                noOptionsMessage={() => t("system.no_option")}
                                placeholder={t("sale:form.choose_month_placeholder")}
                                value={lodash.find(months, {value: month}) || null}
                                options={months}
                                className="select__month mt-2"
                                classNamePrefix="react-select--sale_figures"
                                onChange={this.onChangeSelect.bind(this,'month')}
                                onKeyDown={event => this.onKeyPress(event)}
                            />
                            <div className={"validatorMessage a--error "
                            + ( this.renderError("month") ? 'active-error' : 'inactive-error' )} style={{width:'100%'}}>
                                {this.renderError("month")}
                            </div>

                        </div>
                        <div className="ml-4 flex-wrap a_salefigures__action__height_sp a_salefigures__action__select_year">
                            <label className="w-100 a_salefigures__label mb-2">
                                {t("sale:create.year")}<span className="ml-1 a-text--red">*</span>
                            </label>
                            <div className="seach-pre-next">
                                <div className={'a-btn--pre_next_sale'}>
                                    <button className={year === year_previous ? "active" : ''}
                                            onClick={e => this.onChangeFormInput('year', year_previous)}>
                                        {year_previous}
                                    </button>
                                    <button className={year === year_current ? "active" : ''}
                                            onClick={e => this.onChangeFormInput('year', year_current)}>
                                        {year_current}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Button
                            className={"a-btn--export"}
                            appearance="primary"
                            isLoading={isLoading}
                            onClick={this.onSubmit.bind(this)}
                        >{t("sale:form.create_request_btn")}
                        </Button>
                        <div className="w-100 mt-2 a-text--red">
                            <span className="a-font--10">
                                {t('sale:create.data_export_from_to', {date_from: date_from, date_to: date_to})}
                            </span>
                        </div>
                    </div>

                </div>

            </div>

        );
    }
}

CreateRequestForm.defaultProps = {
    isLoading: false,
    purchasers: [],
    months: [],
    createReports: ()=> {
    }
};

CreateRequestForm.propTypes = {
    isLoading: PropTypes.bool,
    purchasers: PropTypes.array,
    months: PropTypes.array,
    createReports: PropTypes.func
};

export default translate('translations')(CreateRequestForm);
