import React from "react";
import {translate} from "react-i18next";
import {Spin} from "antd";
import PromotionItem from "./PromotionItem";
import Pagination from "../../../modules/common/components/Pagination";

class ListPromotions extends React.Component {

    constructor(props) {
        super(props);
    }

    onChangePage(page, pageSize) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            });
        }
    }

    render() {
        let {
            loading,
            orderItems,
            pagination
        } = this.props;

        return (
            <div className="a-content--is-pagination-fixed" style={{padding: "16px 24px 0 24px"}}>
                {
                    loading
                        ? <div className="text-center pt-3 pb-3"><Spin /></div>
                        : <>
                            <div className="list-promotion-items">
                                { orderItems.map(item => <PromotionItem orderItem={item} />) }
                            </div>
                            <div className="d-flex justify-content-end pb-4">
                                <Pagination
                                    value={parseInt(pagination.current_page, 10)}
                                    total={pagination.page_total}
                                    onChange={page => this.onChangePage(page)}
                                />
                            </div>
                        </>
                }
            </div>
        )
    }

}

export default translate()(ListPromotions);
