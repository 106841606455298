import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ChoseReason from "./ChoseReason";
import lodash from 'lodash';
import Link from "../../../../systems/routing/Link";
import {hasPermission, permissions} from "../../../Permission";
import bootbox from './../../../common/components/Bootbox/bootbox';

class ItemOrder extends React.Component {

    render() {
        let {order, t, violateReasons, updateReason} = this.props;
        return (
            <React.Fragment>
                <tr className={'a-table--violate--row-info'}>
                    <td>
                        <div>
                            <Link to="purchase.buying.detail" params={{id: order.id}}>{order.code || "---"}</Link>
                            <CopyToClipboard text={order.code} onCopy={() => {
                                            bootbox.success(t("message.copied") + ": " + order.code)
                                        }}>
                                <i className="far fa-copy copy"/>
                            </CopyToClipboard>
                        </div>
                        <br/>
                        <span>{order.id||"---"}</span>
                    </td>
                    <td>
                        <div className="a-table--violate-gr">
                            <span>{t("violate:list.teller")}</span>
                            <p>{order.teller_username || "---"}</p>
                        </div>
                        <div className="a-table--violate-gr">
                            <span>{t("violate:list.paid_staff")}</span>
                            <p>{order.paid_staff_username || "---"}</p>
                        </div>
                    </td>
                    <td>
                        <p>{order.time_deposit_time_format || "---"}</p>
                        <p>{order.time_deposit_date_format || "---"}</p>
                    </td>
                    <td>
                        <p>{order.time_teller_time_format || "---"}</p>
                        <p>{order.time_teller_date_format || "---"}</p>
                    </td>
                    <td>
                        <p>{order.time_paid_staff_time_format || "---"}</p>
                        <p>{order.time_paid_staff_date_format || "---"}</p>
                    </td>
                    <td>
                        <span
                            className={order.order_policy_status_class}>{t("violate:list." + order.order_policy_status) || "---"}</span>
                    </td>
                </tr>
                <tr className={`a-table--violate--row-reason ${order.type_reason_class}`}>
                    <td colSpan="6">
                        <ChoseReason
                            violateReasons={violateReasons}
                            canSelect={hasPermission(permissions.VIOLATED_ORDER_UPDATE_REASON) && order.can_change_reasons}
                            idOrderPolicy={order.id_order_policy}
                            reasonId={parseInt(lodash.get(order, 'violate_reason_order_id'),10)}
                            onUpdate={updateReason}
                        />
                    </td>
                </tr>
            </React.Fragment>
        )
    }
}

ItemOrder.defaultProps = {
    order: [],

};

ItemOrder.propTypes = {
    order: PropTypes.object,
};


export default translate('translations')(ItemOrder);
