import React, { useEffect, useState } from 'react'
import { t } from '../../../../../systems/i18n'
import Input from '../../../../common/components/Input/Super'
import lodash from 'lodash'
import authService from '../../../../Auth/authService'
import { SELF_NEGOTIATE_UPDATE_PRODUCT } from '../../../../Permission/permissions'

function TotalDiscount(props) {
    const { order, updateTotalDiscount } = props
    const orderDetail = lodash.get(order, "orderDetail", {})
    const canUpdate = lodash.get(order, 'canUpdateDiscountAmount', false)
    const orderId = lodash.get(order, 'id')
    const grandTotal = lodash.get(order, 'grand_total', 0)
    const self_negotiate = lodash.get(orderDetail, "self_negotiate", false)

    const [amount, setAmount] = useState(0)
    useEffect(() => {
        const amount = lodash.get(order, 'discount_amount', 0)
        setAmount(amount ? amount : 0)
    }, [order])

    const handleOnEnter = event => {
        if (event.key === 'Enter') {
            let { value } = event.target
            const amount = value ? value : 0
            setAmount(amount)
            updateTotalDiscount({ orderId, amount })
        }
    }

    return (
        <div className="a-purchasod__total mt-2">
            <div className="d-flex a-purchasod__total__detail justify-content-between" style={{backgroundColor: '#f1fbfb', borderColor: '#d6f4f2'}}>
                <div>
                    <strong>{t('orderDetail:label.seller_total_discount')}</strong>
                </div>
                <div>
                    <Input
                        value={amount}
                        placeholder={'NDT'}
                        disabled={!canUpdate || !((!!self_negotiate && authService.can(SELF_NEGOTIATE_UPDATE_PRODUCT)) || !self_negotiate) }
                        onKeyPress={handleOnEnter}
                        className="input__total_discount"
                        allowEmpty={false}
                    />
                </div>
            </div>
        </div>
    )
}
export default TotalDiscount
