import React from "react";
import PropTypes from 'prop-types';
import {Icon, Tooltip, notification} from "antd";
import {M5_KOMODO_ACTION, ALIPAY_ACTION, CRAWL_EVENTS} from "./constans";
import {processResponse} from "../../../../../systems/api";
import lodash from "lodash";
import {events} from "../../../../../systems/events";
import TRANSACTION_EVENT from "../../../events";
import apiService from "../../apiService";
import ListMessageType from "../ListMessageType";
import {withEvent} from "../../../../../systems/events";

class RelatedTransactionScanningTool extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            successData: {},
            displayMessage: "none"
        };
        this.handleMessage = this.handleMessage.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
    }

    hideMessage = () => {
        this.setState({
            successData: {},
            displayMessage: "none"
        });
    }

    componentDidMount() {
        window.addEventListener("message", this.handleMessage);

        // Nếu nhận được sự kiện đồng bộ hàng loạt thì bắt đầu quét từ GD đc bắn lên
        this.props.on({
            [CRAWL_EVENTS.DISPATCH_TRANSACTION]: (event, payload) => {
                let {transaction} = this.props;
                let currentTransaction = lodash.get(payload, "transaction", {});
                if (transaction.id === currentTransaction.id)
                    this.handleClick();
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleMessage);
    }

    handleMessage = message => {
        let {transaction} = this.props;
        let payload = message.data;

        if (payload.type != undefined && payload.type === ALIPAY_ACTION.SEND.IS_LOGIN_PAGE && payload.transaction.id === transaction.id) {
            notification.error({
                message: "Tài khoản Alipay chưa đăng nhập hoặc cần xác thực đăng nhập lại",
                duration: 5
            });
        }

        if (payload.type != undefined && payload.type === ALIPAY_ACTION.SEND.TRANSACTION_CODE_IS_NOT_EXISTED && payload.transaction.id === transaction.id) {
            notification.error({
                message: <span class="a-text--break-all">Mã giao dịch <strong>{payload.transaction.code}</strong> không tồn tại trên hệ thống của Alipay</span>,
                duration: 5
            });
            this.setState({loading: false});
            events.dispatch(CRAWL_EVENTS.GO_TO_NEXT_ONE, {});
        }

        if (payload.type != undefined && payload.type === ALIPAY_ACTION.CLOSE.LOGIN_PAGE && payload.transaction.id === transaction.id) {
            this.setState({loading: false});
            events.dispatch(CRAWL_EVENTS.STOP_SYNCHRONIZING, {});
        }

        if (payload.type != undefined && payload.type === M5_KOMODO_ACTION.SEND.RELATED_TRANSACTION_CODE && payload.transaction.id === transaction.id) {
            let res = apiService.addRelatedTransaction(payload.transaction.id, {codes: payload.relatedTransaction, fromTool: 1});
            let process = processResponse(res);
            process.then(res => {
                this.setState({
                    successData: lodash.get(res, "data.transactionRelated", {}),
                    loading: false,
                    displayMessage: "block"
                }, () => {
                    events.dispatch(TRANSACTION_EVENT.ADD_RELATED_TRANSACTION, {
                        transaction: {...transaction, ...(lodash.get(res, "data.transaction", {}))}
                    });
                });
            });

            process.catch((res) => {
                this.setState({
                    successData: lodash.get(res, "data.codes_invalid", {}),
                    loading: false,
                    displayMessage: "block"
                });
            });
            events.dispatch(CRAWL_EVENTS.GO_TO_NEXT_ONE, {});
        }
    }

    handleClick = e => {
        if (e) e.preventDefault();
        let {transaction} = this.props;
        this.setState({loading: true});
        window.postMessage({
            type: M5_KOMODO_ACTION.SEND.TRANSACTION_CODE,
            transaction: {id: transaction.id, code: transaction.code}
        });
    }

    render() {
        let {transaction} = this.props;
        const {successData} = this.state;
        return <>
                { this.state.loading ?
                    <Icon type="sync" spin /> :
                    <Tooltip placement="right" title="Quét tự động GD liên quan">
                        <a href={transaction.code} className="related-transaction-tool" onClick={this.handleClick}><Icon type="scan" /></a>
                    </Tooltip>
                }

                { successData &&
                    <MessageWrapper hiddenMessage={this.hideMessage} display={this.state.displayMessage}>
                        <ListMessageType data={lodash.get(successData, "valid", [])} type="valid" />
                        <ListMessageType data={lodash.get(successData, "invalid", [])} type="invalid" />
                        <ListMessageType data={lodash.get(successData, "not_exist", [])} type="not_exist" />
                        <ListMessageType data={lodash.get(successData, "already_exist", [])} type="already_exist" />
                    </MessageWrapper>
                }
            </>;
    }
}

RelatedTransactionScanningTool.defaultProps = {
    transaction: {}
}

RelatedTransactionScanningTool.propTypes = {
    transaction: PropTypes.object.isRequired
}

class MessageWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = e => {
        e.preventDefault();
        this.props.hiddenMessage();
    }

    render() {
        return <div style={{display: this.props.display}}>
            {this.props.children}
            <a href="#" className="pt-1 a-text--red a-font--12" onClick={this.handleClick}><Icon type="delete" /> Xóa thông báo</a>
        </div>
    }
}

export default withEvent(RelatedTransactionScanningTool);
