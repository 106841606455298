import {apiWithToken} from '../../systems/api';
import {API_URL} from './../../systems/globalContant';

export default {
    getTaobaoPurchaseOrders: (params) => apiWithToken.get(API_URL.DEFAULT + `/taobao-purchaser-orders`, {
        params,
        singleRequest: true,
    }),
    payTaobaoPurchaseOrders: (data) => apiWithToken.post(API_URL.DEFAULT + `/taobao-purchaser-orders/pay`, data),
    createTaobaoPurchaseOrder: (orderId) => apiWithToken.post(API_URL.DEFAULT + `/taobao-purchaser-orders/${orderId}`),
    updateItemsQuantity: (orderId, data) => apiWithToken.put(API_URL.DEFAULT + `/orders/${orderId}/update-items-quantity`, data),
    deleteSTOrder: (orderId, data) => apiWithToken.put(API_URL.DEFAULT + `/taobao-purchaser-orders/${orderId}/cancel`, data),
    createOrder1688: (orderId) => apiWithToken.post(API_URL.DEFAULT + `/orders-1688/${orderId}`, {})
};