import React, {useState, useEffect} from 'react';
import lodash from 'lodash';
import {notification, Select, Spin, Modal, Icon, Form, Button, Tooltip} from 'antd';
import apiService from "../../apiService";
import {t} from "../../../../../systems/i18n";
import {processResponse} from "../../../../../systems/api";

const {Option} = Select;
export default function UpdateHSCode(props) {
    const {defaultValue, type, orderId, items, code, updateHSCodeOrderDetail, hsCodes} = props;
    const [fetching, setFetching] = useState(false);
    const [value, setValue] = useState(defaultValue ? defaultValue : undefined);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newValue, setNewValue] = useState(defaultValue ? defaultValue : undefined);
    const hsCodeInfo = lodash.find(hsCodes, ["code_8_digit", newValue]);
    const [data, setData] = useState(hsCodeInfo ? [hsCodeInfo] : []);


    useEffect(() => {
        setValue(defaultValue ? defaultValue : undefined);
        setNewValue(defaultValue ? defaultValue : undefined);
    }, [defaultValue]);

    function isNumber(n) {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    }

    const fetchHSCode = lodash.debounce((value) => {
        setData([]);
        if (value) {
            const isSearch = isNumber(value) ? value.length >= 2 : value.length >= 4;
            if (isSearch) {
                setFetching(true);
                processResponse(apiService.searchHSCode({code: value}))
                    .then(res => {
                        setData(lodash.get(res, "data", []));
                    }).catch(err => {
                    notification.error({message: t("orderDetail:hscode.get_fail")});
                }).finally(() => {
                    setFetching(false);
                })
            }
        }
    }, 500);

    function updateOrder() {
        return processResponse(apiService.updateHSCodeOrder(orderId, {code: value}))
    };

    function updateOrderItem() {
        return processResponse(apiService.updateHSCodeOrderItem(orderId, {code: value, item_ids: items}))
    };

    function handleChange(value) {
        setFetching(false);
        setValue(value);
    };

    function handleUpdateHSCode() {
        setVisible(true);
    }

    function handleOk() {
        setLoading(true);
        const result = type === "order" ? updateOrder() : updateOrderItem();
        result.then(res => {
            updateHSCodeOrderDetail(res);
            setNewValue(value);
            setVisible(false);
            notification.success({
                message: t("orderDetail:hscode.update_success", {
                    type: t("orderDetail:hscode." + type),
                    code: code
                })
            })
        }).catch(err => {
            notification.error({
                message: t("orderDetail:hscode.update_fail", {
                    type: t("orderDetail:hscode." + type),
                    code: code
                })
            })
        }).finally(() => setLoading(false))

    }

    function handleCancel() {
        setVisible(false);
        setFetching(false);
        setValue(newValue);
        setLoading(false);
    }

    return (
        <>
            <span className="a-text--font-primary a-text--break-all ml-3"> HSCode: {newValue ? newValue : "---"}
                {
                    hsCodeInfo && (" (NKTT: " + hsCodeInfo.ordinary_tax + "%, NKƯĐ: " + hsCodeInfo.preferential_tax + "%, VAT: " + hsCodeInfo.vat + "%, ACFTA: " + hsCodeInfo.acfta + ") ")
                }
                <Icon className="ml-2 icon-edit-cost a-text--fz-12 a-text--font-blue"
                      type="edit"
                      onClick={() => handleUpdateHSCode()}
                      title={t('orderDetail:hscode.click_here_update_hscode')}
                />
            </span>
            {
                visible &&
                <Modal
                    title={t("orderDetail:hscode.title_update_hscode", {
                        type: t("orderDetail:hscode." + type),
                        code: code
                    })}
                    onCancel={handleCancel}
                    visible={visible}
                    width={"40%"}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            {t("btn.cancel")}
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={handleOk}
                        >
                            {t("btn.ok")}
                        </Button>
                    ]}
                >
                    <Form.Item
                        label="HSCode"
                        labelCol={{span: 4}}
                        wrapperCol={{span: 18}}
                    >
                        <Select
                            allowClear={true}
                            showSearch
                            value={value}
                            placeholder={t("orderDetail:hscode.placeholder_search")}
                            notFoundContent={fetching ? <Spin size="small"/> : null}
                            filterOption={false}
                            onSearch={(value) => fetchHSCode(value)}
                            onChange={(value) => handleChange(value)}
                            style={{width: '100%'}}
                        >
                            {
                                data.map(hscode => (
                                    <Option key={hscode.code_8_digit}>
                                        <Tooltip title={hscode.code_8_digit + " - " + hscode.name_8_digit}>
                                            {hscode.code_8_digit + " - " + hscode.name_8_digit}
                                        </Tooltip>
                                    </Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Modal>
            }
        </>
    )
};

