import {connect} from 'react-redux';
import {
    setBreadCrumb, setMenuActive,
    setPageDescription,
    setPageTitle
} from "../../common/actions";
import {syncOrderBillNo} from "../../PurchaseBuying/OrderDetail/actions";
import * as Actions from '../actions'
import ListOrderBillNo from './ListOrderBillNo'
import * as Selector from "../selectors";
import * as OrderSelector from "../../Order/selectors";
import {getListAccountPurchaserAction} from "../../AccountPurchaser/actions";
import lodash from "lodash";
import * as SelectorCustomer from "../../User/selectors";

const mapStateToProps = (state) => {
    return {
        channels: SelectorCustomer.getChannelOrder(state),
        orders: Selector.getOrders(state),
        purchasers: OrderSelector.getAccountPurchaseSort(state),
        pagination: lodash.get(state, "OrderBillNo.pagination", {
            current_page: 1,
            page_total: 1,
            per_page: 20,
            total: 0
        }),
        permissions: lodash.get(state, 'User.me.user.permissions', [])
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        /**
         * Order bill no syncs
         * @param data
         */
        orderBillNoSyncs: (data) => {
            dispatch(Actions.orderBillNoSyncs(data))
        },
        /**
         * Fetch orders
         * @param filter
         */
        fetchOrders: (filter = {}) => {
            filter.seller_source = 1688;
            filter.limit = 40;
            dispatch(Actions.fetchOrdersBillNo(filter));
        },
        /**
         *
         * @param filter
         */
        fetchAccountPurchase: (filter = {}) => {
            filter.scope = 'basic';
            dispatch(getListAccountPurchaserAction({...filter, status: 'ACTIVE'}));
        },
        /**
         * Sync order bill no
         * @param params
         */
        syncOrderBillNo: (params) => {
            dispatch(syncOrderBillNo(params))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListOrderBillNo)
