
export const CONFIRM = 'COMMON.MODAL.CONFIRM';
export const ACCEPT = 'COMMON.MODAL.ACCEPT';
export const CANCEL = 'COMMON.MODAL.CANCEL';
export const ALERT = 'COMMON.MODAL.ALERT';
export const CLOSE = 'COMMON.MODAL.CLOSE';

export const MODAL_TYPE = {
    CONFIRM: 'CONFIRM',
    ALERT: 'ALERT',
};
