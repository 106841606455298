import lodash from 'lodash';
import {getState, dispatch} from '../store';
import {auth} from '../api';
import {AUTH} from '../globalContant';
import {GET_ME} from '../../modules/User/constants';
import apiService from '../../modules/User/apiService';
import cookies from 'react-cookies';
import {SAVE_ORDER_DISTRIBUTOR, KEY_ORDER_DISTRIBUTOR} from './../globalContant';
import {t} from '../../systems/i18n';
import bootbox from '../../modules/common/components/Bootbox/bootbox';

function handleSetting(response) {
    let settings = lodash.get(response.data, "data.user.settings", "[]");
    try {
        let isNeedRemoveSetting =  true;
        let arrSetting = JSON.parse(settings);
        arrSetting.map(setting => {
            if (setting.code === SAVE_ORDER_DISTRIBUTOR && setting.value !== "0") {
                cookies.save(KEY_ORDER_DISTRIBUTOR,
                    lodash.get(response.data, "data.user.last_account_purchaser_id", "0"),
                    {path: '/'}
                );
                isNeedRemoveSetting = false;
            }
            return setting;
        });

        if (isNeedRemoveSetting) {
            cookies.remove(SAVE_ORDER_DISTRIBUTOR, {path: '/'});
        }

    } catch (exception) {
    }
}

/**
 * Middleware load thông tin user đang đăng nhập
 */
export default (payload, next) => {
    let user = lodash.get(getState(), 'User.me');
    if (!lodash.isEmpty(user)) {
        return next(payload);
    }
    return apiService.getMe()
        .then(response => {
            switch (response.data.code) {
                case AUTH.UNAUTHENTICATED: {
                    auth.removeToken();
                    window.location.reload();
                    return;
                }
                case 200: {
                    dispatch({type: GET_ME.SUCCESS, payload: response.data});
                    handleSetting(response);
                    next(payload);
                    return;
                }
                default: {
                    bootbox.error(t('message.auth_cant_load_user'));
                    next(payload);
                    return;
                }
            }
        })
        .catch(() => next(payload));
};