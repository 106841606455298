export const GET_PARTNER_CONFIG = {
    REQUEST: 'GET_PARTNER_CONFIG_REQUEST',
    SUCCESS: 'GET_PARTNER_CONFIG_SUCCESS',
    FAILED: 'GET_PARTNER_CONFIG_FAILED',
};

//Khai báo cho lỗi 404
export const GET_ERROR_404 = {
    REQUEST: 'GET_ERROR_404_REQUEST',
    SUCCESS: 'GET_ERROR_404_SUCCESS',
    FAILED: 'GET_ERROR_404_FAILED',
    CLEAR: 'GET_ERROR_404_CLEAR',
};

export const FIRE_MENU_STATUS = 'FIRE_MENU_STATUS';

export const OPTION_INPUT_API = {
    ON: 1,
    OFF: 0,
};

export const WINDOW_PAGE = {
    UPDATE_TITLE: 'UPDATE_WINDOW_TITLE',
    UPDATE_PAGE_DESCIPTION: 'UPDATE_PAGE_DESCIPTION',
    UPDATE_BREADCRUMB: 'UPDATE_BREADCRUMB',
    UPDATE_LAYOUT: 'UPDATE_LAYOUT',
    UPDATE_THEME: 'UPDATE_THEME',
    UPDATE_MENU_ACTIVE: 'UPDATE_MENU_ACTIVE'
}

export const NOTIFICATION = {
    SET_MESSAGE: "NOTIFICATION_SET_MESSAGE",
    SET_EMPTY_MESSAGE: "NOTIFICATION_SET_EMPTY_MESSAGE"
};

export const TYPE_PRINT = {
    PACKAGE: "TYPE_PRINT_PACKAGE"
};

export const PRINT_STAMP_PACKAGE = {
    PRINT: 'PRINT_STAMP_PACKAGE_REQUEST',
};

export const PUSH_NOTIFICATION_MESSAGE = 'PUSH_NOTIFICATION_MESSAGE';

export const ORDER_STATUS = {
    ALL: 'ALL',
    DEPOSITED: 'DEPOSITED',
    WAITING_BUY: 'WAITING_BUY',
    WAITING_PAI: 'WAITING_PAI',
    // PAI: 'PAI',
    PAID: 'PAID',
    WAITING_PAY: 'WAITING_PAY',
    BOUGHT: 'BOUGHT',
    OVERDUE_PAI: 'OVERDUE_PAI',
    OVERDUE_PAY: 'OVERDUE_PAY',
    CANCELLED: 'CANCELLED',
    OUT_OF_STOCK: 'OUT_OF_STOCK',
    // SELLER_DELIVERY: 'SELLER_DELIVERY',
    // RECEIVED_FROM_SELLER: 'RECEIVED_FROM_SELLER',
    // CROSS_CHECKING: 'CROSS_CHECKING',
    // RECEIVED: 'RECEIVED',
};

export const ORDER_TIME_TYPE = {
    DEPOSITED: 'DEPOSITED',
    PAID: 'PAID',
    BOUGHT: 'BOUGHT',
    SELLER_DELIVERY: 'SELLER_DELIVERY',
    CANCELLED: 'CANCELLED',
};

export const COMPLAINT_SELLER_STATUS = {
    WAITING: 'WAITING',
    PROCESSING: 'PROCESSING',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
};

export const PRODUCT_RETURN_STATUS = {
    ALL: 'ALL',
    QUEUED: 'QUEUED',
    PROCESSING: 'PROCESSING',
    WAITING_RETURN: 'WAITING_RETURN',
    RETURNED: 'RETURNED',
    CANCELLED: 'CANCELLED',
};

export const ORDER_OVERDUE_PURCHASE = "OVERDUE_PURCHASE";
export const AFTER_BOUGHT = "AFTER_BOUGHT";
export const PUSHER_CREATE_NEW_ORDER_EVENT = "PUSHER_CREATE_NEW_ORDER_EVENT";
export const CLEAR_COUNT_CREATE_NEW_ORDER_EVENT = "CLEAR_COUNT_CREATE_NEW_ORDER_EVENT";
/**
 * All of status on order
 */
export const ORDER_STATUS_REAL = {
    WAITING_BUY: 'WAITING_BUY',
    WAITING_PAI: 'WAITING_PAI',
    // PAI: 'PAI',
    PAID: 'PAID',
    WAITING_PAY: 'WAITING_PAY',
    BOUGHT: 'BOUGHT',
    OVERDUE_PAI: 'OVERDUE_PAI',
    OVERDUE_PAY: 'OVERDUE_PAY',
};

export const VIOLATE_ORDER_ACTION_STATUS = {
    WAITING_PROCESS: 'WAITING_PROCESS',
    CONFIRMED: 'CONFIRMED',
};

export const VIOLATE_REASON_TYPE = {
    SUBJECTIVITY: 'SUBJECTIVITY',
    OBJECTIVITY: 'OBJECTIVITY',
};

export const TIME_OUT_LOADING_REQUEST_ORDER = (1000 * 60 * 10);

