import React from 'react'
import lodash from 'lodash';
import {translate} from 'react-i18next';
import {ORDER_STATUS_WITH_CLASS} from "../../Order/constants";
import Spinner from "@atlaskit/spinner";
import bootbox from "../../common/components/Bootbox/bootbox";
import {CopyToClipboard} from "react-copy-to-clipboard";

class Item extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            order: {}
        }
    }

    static  getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        const {order} = nextProps;

        if (JSON.stringify(order) !== JSON.stringify(prevState.order)) {
            state.isLoading = false;
            state.order = order;
        }
        return state;
    }

    /**
     *
     * @param orderStatus
     * @returns {string}
     * @private
     */
    _generateStatusClass(orderStatus) {
        return "a-status a-status--" +
            ((orderStatus && ORDER_STATUS_WITH_CLASS[orderStatus]) ?
                ORDER_STATUS_WITH_CLASS[orderStatus].className : "wating-buy");
    }

    /**
     *
     * @param event
     */
    syncOrderBillNo(event) {
        const {order, syncOrderBillNo} = this.props;
        this.setState({isLoading: true});

        syncOrderBillNo({
            id: order.id
        })
    }

    generateErrors(errors) {
        let msg;
        let {t} = this.props;
        let unauthorized = 'NeedUserAuthenticated';

        if (typeof errors === 'string') {
            if (errors === unauthorized) {
                msg = t('orderDetail:order_detail.sync_order_bill_no.errors.' + unauthorized);
            }
        } else {
            Object.keys(errors).map(idx => {
                if (idx === unauthorized) {
                    msg = idx;
                }
                if (idx === 'errorMessage') {
                    msg = lodash.get(errors,'errorMessage','');
                }

                return idx;
            });
        }
        return msg;
    }

    render() {
        let {order, t, isChecked, syncing} = this.props;

        return (
            <React.Fragment key={order.id+'_'+order.code}>
            <tr>
                <td>
                    <input type="checkbox" name="check_item" value={order.id}
                           checked={isChecked}
                           onChange={this.props.onCheckItem}
                    />
                </td>
                <td>
                    <a href={`/purchase-buying/${order.id}`} target="_blank" className="code__order">{order.code}</a>
                </td>
                <td>
                    <span className={`a-text--normal ${this._generateStatusClass(order.status)}`}>{t('order:status.' + order.status)}</span>
                </td>
                <td>
                    {lodash.get(order, 'freight_bill', []).map( (freightBill, index) =>
                        <div key={freightBill+'_'+index}>
                            <span className="a-text--copy copy-hover">
                                {freightBill.freight_bill}
                                <CopyToClipboard text={freightBill.freight_bill} onCopy={() => {
                                    bootbox.success(t("message.copied") + ": " + freightBill.freight_bill)
                                }}>
                                    <i className="far fa-copy copy"/>
                                </CopyToClipboard>
                            </span>
                        </div>
                    )}
                </td>
                <td>
                    {(syncing || this.state.isLoading ) ?
                        <Spinner size="small"/>
                        :
                        <div>
                            <a onClick={event => this.syncOrderBillNo(event)}>{t('order:list.bill_no.sync')}</a>
                        </div>
                    }
                </td>

            </tr>
            {(order.errors && Object.keys(order.errors).length > 0) &&
            <tr className="not-border">
                <td colSpan={5} className="pt-0 pb-2">
                    {this.generateErrors(order.errors) &&
                    <p className="a-font--11 a-text--red a-text--bold">
                        <i className="fas fa-exclamation-triangle mr-1"></i>
                        {this.generateErrors(order.errors)}
                    </p>
                    }
                </td>
            </tr>
            }
            </React.Fragment>
        )
    }
}

export default translate('translations')(Item);
