export const ATTACH_PURCHASER = {
    REQUEST: 'USER.ATTACH_PURCHASER.REQUEST',
    SUCCESS: 'USER.ATTACH_PURCHASER.SUCCESS',
    FAILED: 'USER.ATTACH_PURCHASER.FAILED',
};

export const DETACH_PURCHASER = {
    REQUEST: 'USER.DETACH_PURCHASER.REQUEST',
    SUCCESS: 'USER.DETACH_PURCHASER.SUCCESS',
    FAILED: 'USER.DETACH_PURCHASER.FAILED',
};

