import {apiWithToken} from '../../systems/api';
import {API_URL} from '../../systems/globalContant';

export default {
    fetchProductReturn: (filter) => apiWithToken.get(API_URL.DEFAULT + '/product-returns/' + filter.id, {params: filter}, {loading: false}),
    fetchListProductReturns: (filters) => apiWithToken.get(API_URL.GET_LIST_COMPLAINT_SELLER + filters.id + '/product-returns', {params: filters}, {loading: false}),
    changeStatusProductReturn: (datas) => apiWithToken.put(API_URL.PRODUCT_RETURN + "/" + datas.id + '/change-state', {next_state: datas.status}, {loading: false}),
    updateInfoProductReturn: (params) => apiWithToken.put(API_URL.DEFAULT + '/product-returns/' + params.id, {...params}, {loading: false}),
    updateShippingBill: (id, codes) => apiWithToken.put(
        API_URL.DEFAULT + `/product-returns/${id}/shipping-bill-codes`,
        {shipping_bill_codes: codes.join()},
        {loading: false}
    ),
};