import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import moment from 'moment';
import {DatePicker} from 'antd';

class DateRange extends React.PureComponent {
    onChange(date) {
        this.props.onChange(this.formatValue(date[0]), this.formatValue(date[1]))
    }
    
    formatValue(date) {
        return date ? date.format(this.props.valueFormat) : null;
    }
    
    render() {
        let {t, from, to, format, valueFormat} = this.props;

        return (
            <DatePicker.RangePicker
                style={{width: "100%"}}
                placeholder={this.props.placeholder.length || [t('label.date_from'), t('label.date_to')]}
                format={format}
                showTime={this.props.showTime}
                value={[
                    from ? moment(from, valueFormat) : null,
                    to ? moment(to, valueFormat) : null,
                ]}
                onChange={this.onChange.bind(this)}
            />
        );
    }
}

DateRange.defaultProps = {
    from: null,
    to: null,
    format: 'DD/MM/YYYY',
    valueFormat: 'YYYY-MM-DD',
    placeholder: [],
    onChange: (from, to) => {},
};

DateRange.propTypes = {
    from: PropTypes.any,
    to: PropTypes.any,
    format: PropTypes.string,
    valueFormat: PropTypes.string,
    placeholder: PropTypes.array,
    onChange: PropTypes.func,
};

export default translate()(DateRange);
