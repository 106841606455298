import React from "react";
import {translate} from "react-i18next";
import {Row, Col, Button, Icon} from "antd";
import notification from "../../../../systems/notification";
import ReasonForm from "./ReasonForm";
import {ReactSortable} from "react-sortablejs";
import apiService from "../../../../modules/ViolateReason/apiService";
import {processResponse} from "../../../../systems/api";
import lodash from "lodash";
import ReasonItem from "./ReasonItem";

class ReasonBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: []
        }
    }

    /**
     * Sử dụng Sortablejs nên có một số vấn đề phát sinh
     * Lần đầu load component chuyển dữ liệu Reasons từ props sang list state
     * format of list state {id: reason.id, title: reason.title}
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        let {reasons} = nextProps;
        let {list} = prevState;
        if (list.length === 0) {
            let tmpList = reasons.map(reason => {
                return {id: reason.id, title: reason.title, priority: reason.priority, auto_approval: reason.auto_approval};
            });
            return {...prevState, list: tmpList};
        } else
            return {...prevState};

        // if (reasons.length > 0) {
        //     let tmpList = reasons.map(reason => {
        //         return {id: reason.id, title: reason.title, priority: reason.priority};
        //     });
        //     return {...prevState, list: tmpList};
        // } else {
        //     return {...prevState};
        // }
    }

    /**
     * Hàm truyền cho thuộc tính setList của ReactSortable để chạy khi kết thúc kéo thả
     * Cập nhật các thứ tự mới cho list state
     */
    setNewState = newState => {
        this.setState({ list: [...newState] });
    }

    onEnd = (evt) => {
        let {t} = this.props;
        let priorityArr = this.state.list.map((item, index) => {
            return {id: item.id, priority: index};
        });
        let res = apiService.updateViolateReasonPriority(priorityArr);
        let process = processResponse(res);
        process.catch(err => {
            notification.error(t('message.cant_fetch_data'));
        });
    }

    /**
     * Cập nhật reason mới cho list state của component này và data state của component cha
     */
    addReason = reason => {
        this.props.addReason(reason);
        this.setState(state => {
            let {list} = state;
            list.push({id: reason.id, title: reason.title, auto_approval: reason.auto_approval});
            return {...state, list: list};
        });
    }

    render() {
        let {list} = this.state;
        let {type, updateReasons} = this.props;

        return <div className="box-square">
            <div className="box-square--title">
                <div className="a-text--bold a-text--color-black a-flex align-item-center">{type === "OBJECTIVITY" ? "Lý do khách quan" : "Lý do chủ quan "}</div>
                <div>
                    <ReasonForm type={this.props.type} addReason={this.addReason} />
                </div>
            </div>
            <div className="box-square--body">
                <Row className="reason-line pt-2 pb-2" style={{borderTop: 0}}>
                    <Col span={3} offset={1}><strong>Mã lý do</strong></Col>
                    <Col span={16}><strong>Tên lý do</strong></Col>
                    <Col span={4}><strong>Tự động duyệt</strong></Col>
                </Row>
                <ReactSortable
                    list={list}
                    setList={this.setNewState}
                    onEnd={this.onEnd}
                >
                    {
                        list && list.map((reason, index) => {
                            return <ReasonItem
                                reason={reason}
                                updateReasons={updateReasons}
                            />
                        })
                    }
                </ReactSortable>
            </div>
        </div>;
    }
}

export default translate()(ReasonBox);