import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {trans} from '../../../../../systems/i18n';
import {url} from '../../../../../systems/routing';
import {dateFormatter,currentFormatter} from "./../../../services/format";

const ACTION = {
    CREATE: 'PRODUCT_RETURN.CREATE',
    CHANGE_STATE: 'PRODUCT_RETURN.CHANGE_STATE',
    ADD_PACKAGE: 'PRODUCT_RETURN.ADD_PACKAGE',
    DELETE_PACKAGE: 'PRODUCT_RETURN.DELETE_PACKAGE',
    UPDATE_TRACKING: 'PRODUCT_RETURN.UPDATE_TRACKING',
    UPDATE_RETURN: 'PRODUCT_RETURN.UPDATE_RETURN',
};

class ProductReturnLogMessage extends React.Component {
    render() {
        let {log} = this.props;
        let {payload} = log;

        switch (log.action) {
            case ACTION.CREATE: {
                let productReturn = lodash.get(payload, 'product_return', {attributes: {}}) || {};

                return trans('complaintSeller:product_return_log.CREATE', {
                    product_return: <a href={url.to('product.return.detail', {id: productReturn.id})}
                                       target="_blank">#{productReturn.id}</a>,
                });
            }

            case ACTION.CHANGE_STATE: {
                return trans('productReturn:log.CHANGE_STATE', {
                    from:
                        <b>{trans('productReturn:status.' + lodash.toLower(lodash.get(payload, 'attributes.old', '')))}</b>,
                    to:
                        <b>{trans('productReturn:status.' + lodash.toLower(lodash.get(payload, 'attributes.new', '')))}</b>,
                });
            }

            case ACTION.ADD_PACKAGE: {
                return trans('complaintSeller:product_return_log.ADD_PACKAGE', {
                    'package': <b>{lodash.get(payload, 'attributes.package_code')}</b>,
                });
            }

            case ACTION.DELETE_PACKAGE: {
                return trans('complaintSeller:product_return_log.DELETE_PACKAGE', {
                    'package': <b>{lodash.get(payload, 'attributes.package_code')}</b>,
                });
            }

            case ACTION.UPDATE_TRACKING: {
                return trans('complaintSeller:product_return_log.UPDATE_TRACKING', {
                    shipping_bill_codes: <b>{lodash.get(payload, 'product_return.shipping_bill_codes')}</b>,
                });
            }

            case ACTION.UPDATE_RETURN: {
                let attributes = lodash.get(payload, 'attributes') || {};
                let attributesCustom = Object.keys(attributes).map(function (key) {
                let valueFieldOld = attributes[key].old !== null ? (attributes[key].old).toString().trim() : null;
                let valueFieldNew = attributes[key].new !== null ? (attributes[key].new).toString().trim() : null;

                    if (key === 'return_date') {
                        valueFieldOld = attributes[key].old !== '' ? attributes[key].old : '';
                        valueFieldNew = attributes[key].new !== '' ? attributes[key].new : '';
                        valueFieldOld = dateFormatter.date(valueFieldOld);
                        valueFieldNew = dateFormatter.date(valueFieldNew);
                        valueFieldOld = valueFieldOld ? valueFieldOld:"";
                        valueFieldNew = valueFieldNew ? valueFieldNew:"";
                    }

                    if( valueFieldOld === '' && valueFieldNew ){
                        return [trans('complaintSeller:product_return_log.UPDATE_RETURN_CUSTOM_ADD', {
                            nameField: trans('complaintSeller:product_return.' + key),
                            valueFieldNew: valueFieldNew
                        })];
                    }

                    if( valueFieldOld && valueFieldNew ){
                        return [trans('complaintSeller:product_return_log.UPDATE_RETURN_CUSTOM', {
                            nameField: trans('complaintSeller:product_return.' + key),
                            valueFieldOld: valueFieldOld,
                            valueFieldNew: valueFieldNew
                        })];
                    }

                    return [trans('complaintSeller:product_return_log.UPDATE_RETURN_CUSTOM_DELETE', {
                        nameField: trans('complaintSeller:product_return.' + key)
                    })];

                });

                attributesCustom = lodash.compact(attributesCustom);
                return trans('complaintSeller:product_return_log.UPDATE_RETURN') + lodash.join(attributesCustom,' , ');
            }

            default:
                return log.action;
        }
    }
}

ProductReturnLogMessage.defaultProps = {
    log: {},
};

ProductReturnLogMessage.propTypes = {
    log: PropTypes.object,
};

export default translate()(ProductReturnLogMessage);
