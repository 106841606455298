import React from 'react'
import { translate } from 'react-i18next'
import queryString from 'query-string'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import { Container } from 'react-grid-system'
import { Icon, Row, Col, Tooltip, Button, Modal, Tag, Dropdown, Menu } from 'antd'
import BillInfo from './billinfo'
import Service from './Service/service'
import OrderList from './OrderList'
import NoticeConfirm from './NoticeConfirm'
import TotalItem from './total'
import ChatLog from './../../../common/Log/LogContainer'
import { url } from '../../../../systems/routing/index'
import FormConfirmNotEnoughMoney from './OtherInfo/FormConfirmNotEnoughMoney'
import { trans } from '../../../../systems/i18n'
import { currentFormatter } from '../../../common/services/format'
import FormSelectViolateReason from './OtherInfo/FormSelectViolateReason'
import Waypoint from 'react-waypoint'
import ScrollArea from 'react-scrollbar'
import Copy from '../../../common/components/Copy'
import ActionBottom from './ActionBottom/ActionBottom'
import withSizes from 'react-sizes'
import { hasSetting, settings } from '../../../Settings/UserSetting/ulti'
import UpdateWrongProcessContainer from './../../UpdateWrongProcess/UpdateWrongProcessContainer'
import UpdateFinanliOrderContainer from '../../UpdateFinanliOrder/UpdateFinanliOrderContainer'
import ComplaintSellerByOrder from './ComplaintSellerByOrder'
import CreateComplaintSeller from '../../../Order/ListOrder/components/CreateComplaintSeller'
import CreateExternalPaymentRequest from '../../../PaymentRequest/CreateExternalPaymentRequestButton'
import authService from '../../../Auth/authService'
import ListTransaction from '../components/ListTransaction'
import InvoiceCode from './MoreInfoComponents/InvoiceCode'
import FreightBill from './MoreInfoComponents/FreightBill'
import OrderGroup from './OtherInfo/OrderGroup'
import Timeline from './Timeline'
import ModalUpdateCustoms from './MoreInfoComponents/ModalUpdateCustoms'
import CheckExport from './CanExport/CheckExport'
import UpdateHSCode from './HSCode/UpdateHSCode'
import UpdateHSCodeMultipleItems from './HSCode/UpdateHSCodeMultipleItems'
import ToolAlert from '../../../../screens/Order/ScanOrders/ToolAlert'
import Features from './Features'
import WarningChangePurchaseAddress from './OtherInfo/WarningChangePurchaseAddress'
import AlertSpecialFeatures from './Features/AlertSpecialFeatures'
import WarningServices from './Service/warningServices'
import CreateTaobaoPurchaseOrder from '../../../../screens/ForeignOrder/Create/CreateTaobaoPurchaseOrder'
import CreatePaymentRequestBySBOrder from '../../../PaymentRequest/CreatePaymentRequestBySBOrder'
import TotalDiscount from './TotalDiscount'
import { hasPermission, permissions } from '../../../Permission'
import apiService from '../../../Order/apiService'
import notification from '../../../../systems/notification'
import { processResponse } from '../../../../systems/api'
import CancelTaobaoPurchaseOrder from './CancelTaobaoPurchaseOrder'
import CreateOrder1688 from '../../../../screens/ForeignOrder/Create/CreateOrder1688'
import Cancel1688Order from './Cancel1688Order'
import { SELF_NEGOTIATE_UPDATE_PRODUCT } from '../../../Permission/permissions'
import ChangeBatchOrder from './ChangeBatchOrder'
import EstimatedBatchOrderInfo from './EstimatedBatchOrderInfo'

const CN_IMAGE_LINK = require('./../../../../resources/img/flags-icons/cn.png')

const mapSizesToProps = ({ width }) => ({
    isDesktopSmall: width < 1400,
    screenWidth: width,
})

class PurchasingOrderDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                check_all: false,
                order_item_checked: [],
            },
            isActive: null,
            isActiveNote: null,
            isFilterCustomerNote: false,
            isFilterByPai: '',
            items: [],
            isOpenBillInfo: false,
            editHSCode: false,
            loadingSyncWebhook: false,
            loadingChangeReceive: false,
        }
    }

    componentDidMount() {
        const { t, dataOrder, complaintSellerReasons, location, match } = this.props

        let filters = { ...queryString.parse(location.search), ...match.params }
        this.props.getPurchasingOrderDetailAction(filters)
        this.props.setPageTitle(t('breadcrumb.order_detail'))
        this.props.setPageDescription(t('order_detail.manager'))
        this.props.setBreadCrumb([
            {
                label: 'breadcrumb.home',
                link: '/',
            },
            {
                label: 'breadcrumb.order_detail',
                link: url.to('purchase.buying.detail', { id: filters.id }),
            },
        ])
        this.props.setMenuActive('purchase-address-detail')
        this.props.getListAccountPurchaserAction({})
        this.props.fetchViolateReasons()
        authService.authForTool()

        if (complaintSellerReasons.length === 0) this.props.fetchComplaintSellerReasons()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { dataOrder } = this.props
        if (dataOrder && dataOrder.warningKeywords) {
            window.postMessage({
                type: 'RECEIVE_WARNING_KEYWORDS',
                warningKeywords: dataOrder.warningKeywords,
            })
        }
    }

    onToggleBillInfo = e => {
        this.setState(preState => {
            return { ...preState, isOpenBillInfo: !preState.isOpenBillInfo }
        })
    }

    handleShow() {
        this.setState({
            isActive: true,
        })
    }

    handleHidden() {
        this.setState({
            isActive: false,
        })
    }

    handleShowNote() {
        this.setState({
            isActiveNote: true,
        })
    }

    handleHiddenNote() {
        this.setState({
            isActiveNote: false,
        })
    }

    /**
     * Hàm thực hiện đánh dấu chọn hoặc bỏ chọn tích đơn
     *
     * @param event
     */
    onCheckItem(event) {
        this.props.beginClearSuccessUpdatePriceItems()
        let target = event.target
        let { items } = this.state
        let value = lodash.toInteger(target.value)
        let checked = target.checked
        let check_all = false
        let order_item_checked = lodash.get(this.state, 'formData.order_item_checked', [])
        // Nếu bỏ tích chọn
        if (!checked) {
            order_item_checked = lodash.difference(order_item_checked, [value])
        }
        // Nếu checkbox được tích chọn
        if (checked) {
            order_item_checked = lodash.concat(order_item_checked, [value])
            order_item_checked = lodash.uniq(order_item_checked)
        }
        if (order_item_checked.length === items.length) {
            check_all = true
        }

        this.updateFormData({ order_item_checked: order_item_checked, check_all: check_all })
    }

    /**
     * Hàm thực hiện đánh dấu chọn hoặc bỏ chọn tất cả
     *
     * @param event
     */
    onCheckAll(event) {
        this.props.beginClearSuccessUpdatePriceItems()
        let { items } = this.state
        let OrderIds = []
        let target = event.target
        let checked = target.checked
        let order_item_checked = []
        let check_all = false
        // Nếu checkbox được tích chọn
        if (checked) {
            OrderIds = items.map(orderItem => {
                return orderItem.id
            })
            check_all = true
            order_item_checked = OrderIds
        }

        this.updateFormData({ order_item_checked: order_item_checked, check_all: check_all })
    }

    /**
     * Xóa tích check all và các item
     */
    onClearCheckAll() {
        this.updateFormData({ order_item_checked: [], check_all: false })
    }

    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        let formData = this.state.formData
        formData = { ...formData, ...input }
        this.setState({ formData })
    }

    static filterOrderItems(propsObjects) {
        let { items, isFilterCustomerNote, isFilterByPai } = propsObjects
        return items.filter(
            item =>
                ((isFilterByPai === 'pai_fail' && item.is_pai !== 1) || (isFilterByPai === 'pai_success' && item.is_pai === 1) || isFilterByPai === '') &&
                (!isFilterCustomerNote || (isFilterCustomerNote && item.note !== null && Array.isArray(item.note) && item.note.length > 0))
        )
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = { ...prevState }
        if (lodash.get(nextProps, 'successUpdateItems', false) === true) {
            nextState.formData = {
                check_all: false,
                order_item_checked: [],
            }
        }

        if (lodash.get(prevState, 'isFilterCustomerNote', false) || lodash.get(prevState, 'isFilterByPai', false)) {
            nextState.items = PurchasingOrderDetail.filterOrderItems({
                items: lodash.get(nextProps, 'dataOrder.items', []),
                isFilterCustomerNote: prevState.isFilterCustomerNote,
                isFilterByPai: prevState.isFilterByPai,
            })
        } else {
            nextState.items = lodash.get(nextProps, 'dataOrder.items', [])
        }

        return nextState
    }

    onSubmitViolateReason(data) {
        let orderId = this.props.dataOrder.id
        let {
            dataOrder: { canChangePay, canChangeWaitingPay },
        } = this.props

        if (canChangeWaitingPay) {
            this.props.onConfirmWaitingPayment(orderId, data)
        } else {
            if (canChangePay) {
                this.props.onConfirmPayment(orderId, data)
            }
        }
    }

    onSubmitConfirmNotEngoughMoney() {
        let orderId = this.props.dataOrder.id
        let {
            dataOrder: { canChangePay, canChangeWaitingPay },
        } = this.props
        if (canChangeWaitingPay) {
            this.props.onWaitingPayment({ id: orderId, confirm_not_enough_money: true })
        } else {
            if (canChangePay) {
                this.props.onPayment({ id: orderId, confirm_not_enough_money: true })
            }
        }
    }

    onCancelPayment = () => {
        let {
            dataOrder: { canChangePay, canChangeWaitingPay },
        } = this.props
        if (canChangeWaitingPay) {
            this.props.onCancelWaitingPayment()
        } else {
            if (canChangePay) {
                this.props.onCancelPayment()
            }
        }
    }

    onOpenNewTab(url, event) {
        event.preventDefault()
        window.open(url, '_blank')
    }

    /**
     * handle action change field filter
     * @param newFilterByPai
     */
    changeFilterBy(newFilterByPai) {
        this.setState({ isFilterByPai: newFilterByPai })
    }

    /**
     * handle action active/unactive filter by customer's note
     */
    changeFilterCustomerNote() {
        this.setState(preState => {
            return { isFilterCustomerNote: !preState.isFilterCustomerNote }
        })
    }

    componentWillUnmount() {
        this.props.clearOrderDetailState()
    }

    handleSyncWebhook(orderId) {
        const { getPurchasingOrderDetailAction } = this.props
        this.setState({
            loadingSyncWebhook: true,
        })
        processResponse(apiService.syncWebhook(orderId))
            .then(() => {
                notification.success('Cập nhật dữ liệu đơn sanng admin thành công!')
                getPurchasingOrderDetailAction({ id: orderId })
            })
            .catch(err => {
                notification.error('Cập nhật dữ liệu đơn sanng admin thất bại!')
            })
            .finally(() => {
                this.setState({
                    loadingSyncWebhook: false,
                })
            })
    }

    handleChangeReceive(orderId) {
        const { getPurchasingOrderDetailAction } = this.props
        this.setState({
            loadingSyncWebhook: true,
        })
        processResponse(apiService.changeReceive(orderId))
            .then(() => {
                notification.success('Chuyển đơn về TQ nhận thành công!')
                getPurchasingOrderDetailAction({ id: orderId })
            })
            .catch(err => {
                notification.error('Chuyển đơn về TQ nhận thất bại!')
            })
            .finally(() => {
                this.setState({
                    loadingSyncWebhook: false,
                })
            })
    }
    render() {
        let {
            dataOrder,
            t,
            services,
            isLoadingUpdateData,
            clearPurchaserBuyingChangeDataState,
            postItemLog,
            onPayment,
            onConfirm,
            error,
            isFetchNewService,
            onPaid,
            updateOrderService,
            approvalOrderService,
            onCancel,
            onUpdateOrderGroupOrder,
            orderGroup,
            onPostInvoiceCode,
            isLoading,
            updateOrder,
            accountPurchasersOptions,
            isLoadingConfirmCustomer,
            freightBillDuplicateInOrders,
            me,
            isLoadingData,
            isOpenMenuBar,
            isLoadingCancelOrder,
            beginProcessOutOfStock,
            beginProcessCancelOrder,
            isEndProcess,
            isLoadingOutOfStockOrder,
            beginProcessUpdateOutStock,
            beginProcessUpdatePriceItems,
            beginProcessUpdateQuantityItems,
            successUpdateItems,
            beginClearErrorUpdatePriceItems,
            errorUnitPriceItems,
            updateOutOfStockItems,
            onOutOfStock,
            updateQuantityItems,
            clearErrorWhileUpdatePrice,
            errorUnitPrice,
            updatePriceItems,
            beginProcessChangePrice,
            isDesktopSmall,
            complaintSellerReasons,
            onWaitingPayment,
        } = this.props
        let { formData, isActive, isActiveNote, items } = this.state
        let updateQuantity = dataOrder.note_buyer_confirm_message()
        let agencyCode = lodash.get(dataOrder, 'agency.code', '')
        let agencyColor = lodash.get(dataOrder, 'agency.color', '')
        let agencyShortCode = lodash.get(dataOrder, 'agency.short_code', '')
        let order_group_using = hasSetting(settings.ORDER_GROUP_USING)
        let agencyNote = lodash.get(dataOrder, 'agency.note', '')

        let order_item_checked_items = items.map(Item => {
            return lodash.includes(formData.order_item_checked, Item.id) ? Item.id : null
        })
        order_item_checked_items = lodash.compact(order_item_checked_items)
        let defaultChecked = order_item_checked_items.length === items.length && items.length && order_item_checked_items.length
        defaultChecked = defaultChecked ? true : false
        let order_item_checked_current = formData.order_item_checked.map(itemId => {
            return lodash.includes(order_item_checked_items, itemId) ? itemId : null
        })
        order_item_checked_current = lodash.compact(order_item_checked_current)
        let agencyStyle = {
            backgroundColor: agencyColor,
        }
        let user_address = lodash.get(dataOrder, 'user_address', [])
        const orderDetail = lodash.get(dataOrder, 'orderDetail', {})
        const orderId = lodash.get(orderDetail, 'id')
        const hsCodes = lodash.get(dataOrder, 'hsCodes', [])
        const changePurchaseAddress = lodash.get(dataOrder, 'changePurchaseAddress', false)
        const properties = lodash.get(dataOrder, 'properties', [])
        const properties_warning = lodash.map(lodash.filter(properties, ['property.show_warning', 1]), 'property.name')
        const servicesWarning = lodash.get(dataOrder, 'servicesWarning', {})
        const warningHasServices = lodash.get(servicesWarning, 'warningHasServices', [])
        const warningNoServices = lodash.get(servicesWarning, 'warningNoServices', [])
        const packageNoteUrl = lodash.get(dataOrder, 'packageNoteUrl', '')
        const propertiesWarning = lodash.get(dataOrder, 'propertiesWarning', {})
        const warningHasFeatures = lodash.get(propertiesWarning, 'warningHasProperties', [])
        const warningNoFeatures = lodash.get(propertiesWarning, 'warningNoProperties', [])
        const sellerNote = lodash.get(dataOrder, 'seller.sellerNote.note', '')
        const canCreatePurchaseOrderTaobao = lodash.get(dataOrder, 'canCreatePurchaseOrderTaobao', false)
        const canCancelTaobaoPurchaseOrder = lodash.get(dataOrder, 'canCancelTaobaoPurchaseOrder', false)
        const canCreatePaymentRequestSB = lodash.get(dataOrder, 'canCreatePaymentRequestSB', false)
        const taobaoPurchaseOrder = lodash.get(dataOrder, 'taobaoPurchaseOrder', {})
        const canViewContact = lodash.get(dataOrder, 'canViewContact', false)
        const canShowDiscountAmount = lodash.get(dataOrder, 'canShowDiscountAmount', false)
        const canSyncWebhook = lodash.get(dataOrder, 'canSyncWebhook', false)
        const canChangeReceive = lodash.get(dataOrder, 'canChangeReceive', false)
        const canViewOrderPreview = lodash.get(dataOrder, 'canViewOrderPreview', false)
        const canCreateOrder1688 = lodash.get(dataOrder, 'canCreateOrder1688', false)
        const canCancelOrder1688 = lodash.get(dataOrder, 'canCancelOrder1688', false)
        const orderPreviewResuslt = lodash.get(dataOrder, 'orderPreview.response.orderPreviewResuslt', [])
        const orderPreview = lodash.get(dataOrder, 'orderPreview.response', [])
        const canViewOrder1688 = lodash.get(dataOrder, 'canViewOrder1688', false)
        const isCNAddress = lodash.get(dataOrder, 'isCnAddress', false)
        const order1688 = lodash.join(lodash.map(lodash.get(dataOrder, 'order1688s', []), 'origin_order_id'), ',')
        const self_negotiate = lodash.get(orderDetail, "self_negotiate", false)
        let detailReceiverInfo = user_address ? (
            <span>
                {user_address.reciver_name ? (
                    <span>
                        <strong>{user_address.reciver_name}</strong> / {user_address.detail}, {user_address.district}, {user_address.province}
                        {user_address.reciver_phone ? ' / ' + user_address.reciver_phone : ''}
                        {user_address.note ? ' / ' + user_address.note : ''}
                    </span>
                ) : (
                    <strong>{user_address.detail}</strong>
                )}
            </span>
        ) : (
            '---'
        )
        if (isCNAddress && user_address) {
            detailReceiverInfo = (
                <span>
                    {user_address.reciver_name ? (
                        <span>
                            <strong>{user_address.reciver_name}</strong> / {user_address.province}, {user_address.city}, {user_address.district},{' '}
                            {user_address.ward}, {user_address.detail}
                            {user_address.reciver_phone ? ' / ' + user_address.reciver_phone : ''}
                            {user_address.note ? ' / ' + user_address.note : ''}
                        </span>
                    ) : (
                        <strong>{user_address.detail}</strong>
                    )}
                </span>
            )
        }

        const menu = (
            <Menu>
                <Menu.Item>
                    {((!!self_negotiate && authService.can(SELF_NEGOTIATE_UPDATE_PRODUCT)) || !self_negotiate) && (
                        <UpdateWrongProcessContainer
                            dataOrder={dataOrder}
                            loading={isLoading}
                            canChangeQuantity={dataOrder.canChangeQuantity}
                        />
                    )}
                </Menu.Item>
                <Menu.Item>
                    {((!!self_negotiate && authService.can(SELF_NEGOTIATE_UPDATE_PRODUCT)) || !self_negotiate) && (
                        <ChangeBatchOrder dataOrder={dataOrder} />
                    )}
                </Menu.Item>
            </Menu>
        )
        const wrongProcess = dataOrder.wrong_process
        const isOpenUpdateBatchOrder = lodash.get(dataOrder, 'isOpenUpdateBatchOrder', false)
        const canUpdateBatchOrder = lodash.get(dataOrder, 'canUpdateBatchOrder', false)
        const isBatchOrder = orderDetail.is_batch_order
        const titleDropdown = !!wrongProcess && !!isBatchOrder ? 'Đơn ngoài, lô' : !!wrongProcess ? 'Đơn ngoài' : !!isBatchOrder ? 'Đơn lô' : 'Đánh dấu đơn'

        return typeof dataOrder.id !== 'undefined' ? (
            <React.Fragment>
                {changePurchaseAddress && (
                    <WarningChangePurchaseAddress
                        canCancelTaobaoPurchaseOrder={lodash.get(dataOrder, 'canCancelTaobaoPurchaseOrder', false)}
                        dataOrder={dataOrder}
                        visible={changePurchaseAddress}
                        getPurchasingOrderDetailAction={this.props.getPurchasingOrderDetailAction}
                    />
                )}
                {!!isBatchOrder && isOpenUpdateBatchOrder && !!canUpdateBatchOrder && (
                    <EstimatedBatchOrderInfo
                        dataOrder={dataOrder}
                        isOpened={true}
                    />
                )}
                {properties_warning.length > 0 && <AlertSpecialFeatures properties_warning={properties_warning} />}
                {(warningHasServices.length > 0 || warningNoServices.length > 0 || warningHasFeatures.length > 0 || warningNoFeatures.length > 0) && (
                    <WarningServices
                        warningHasServices={warningHasServices}
                        warningNoServices={warningNoServices}
                        warningHasFeatures={warningHasFeatures}
                        warningNoFeatures={warningNoFeatures}
                    />
                )}
                <div className="pb-2">
                    <ToolAlert message={'Công cụ mua hàng chưa được cài đặt'} />
                </div>
                {this.props.showFormSelectViolateReason ? (
                    <FormSelectViolateReason
                        order={dataOrder}
                        violatedPolicies={this.props.violatedPolicies}
                        reasons={this.props.violateReasons}
                        loading={this.props.isPayingOrder}
                        onSubmit={this.onSubmitViolateReason.bind(this)}
                        onCancel={this.onCancelPayment.bind(this)}
                    />
                ) : null}
                {this.props.showFormConfirmNotEnoughMoney ? (
                    <>
                        {/*{hasPermission(permissions.ORDER_CONFIRM_NEGATIVE_BALANCE_CHANGES) ? (*/}
                        <FormConfirmNotEnoughMoney
                            title={t('order_detail.confirm_not_enough_money_title')}
                            message={
                                this.props.messageShowFormConfirmNotEnoughMoney ||
                                trans('order_detail.confirm_not_enough_money_message', {
                                    finance: <b>{currentFormatter.formatMoney(lodash.get(this.props.getPaymentOrder, 'error.data.error.finance', 0))}</b>,
                                })
                            }
                            loading={this.props.isPayingOrder}
                            onConfirm={this.onSubmitConfirmNotEngoughMoney.bind(this)}
                            onClose={this.onCancelPayment.bind(this)}
                        />
                        {/*) : (*/}
                        {/*    <Modal*/}
                        {/*        title={t('order_detail.confirm_not_enough_money_title')}*/}
                        {/*        visible={true}*/}
                        {/*        footer={[<Button onClick={this.onCancelPayment.bind(this)}>{trans('order_detail.btn_action_cancel')}</Button>]}>*/}
                        {/*        <div>*/}
                        {/*            {this.props.messageShowFormConfirmNotEnoughMoney ||*/}
                        {/*                trans('order_detail.confirm_not_enough_money_message', {*/}
                        {/*                    finance: (*/}
                        {/*                        <b>{currentFormatter.formatMoney(lodash.get(this.props.getPaymentOrder, 'error.data.error.finance', 0))}</b>*/}
                        {/*                    ),*/}
                        {/*                })}*/}
                        {/*            <p style={{ color: 'red' }}>{t('orderDetail:order_detail.need_manage_confirm')}</p>*/}
                        {/*        </div>*/}
                        {/*    </Modal>*/}
                        {/*)}*/}
                    </>
                ) : null}
                <Container fluid>
                    <Row>
                        <Col
                            span={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            className="a-content--center">
                            <div className="a-purchasod">
                                <div className="a-purchasod__body">
                                    <div className="a-purchasod__body__left">
                                        {dataOrder &&
                                        dataOrder.buyer_confirm &&
                                        updateQuantity.length &&
                                        ((!!self_negotiate && authService.can(SELF_NEGOTIATE_UPDATE_PRODUCT)) || !self_negotiate) ? (
                                            <>
                                                <NoticeConfirm
                                                    key={dataOrder.id + updateQuantity.length}
                                                    isLoadingConfirmCustomer={isLoadingConfirmCustomer}
                                                    dataOrder={dataOrder}
                                                    updateQuantity={updateQuantity}
                                                    onConfirmCustomerOrderBuying={this.props.onConfirmCustomerOrderBuying}
                                                />
                                                <br />
                                            </>
                                        ) : null}

                                        <div className={`a-purchasod__first a-content--box-shadow p-0 a-status-border--${dataOrder.status}`}>
                                            <div className="d-flex justify-content-between row-first">
                                                <div className="a-purchasod__title align-item-center">
                                                    <a
                                                        style={agencyStyle}
                                                        href=""
                                                        onClick={event => event.preventDefault()}
                                                        className={`header_partner header_partner--${agencyCode}`}>
                                                        {agencyShortCode}
                                                    </a>
                                                    {orderDetail && !!orderDetail.is_full_customs_info && (
                                                        <Tooltip title={t('orderDetail:tooltip.full_customs_info')}>
                                                            <span className="pl-2">
                                                                <Icon
                                                                    type="check-circle"
                                                                    theme="twoTone"
                                                                    twoToneColor="#52c41a"
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                    <h2 className="a-purchasod__orderCode ml-2 mr-2">
                                                        <a
                                                            href={dataOrder.partner_url_order_detail}
                                                            onClick={this.onOpenNewTab.bind(this, dataOrder.partner_url_order_detail)}>
                                                            {dataOrder.code}
                                                        </a>
                                                    </h2>

                                                    <span className={`a-status a-status--is-new a-status--${dataOrder.status}`}>
                                                        {t('order_detail.' + dataOrder.status)}
                                                    </span>
                                                    {!!self_negotiate && (
                                                        <Tooltip title={t('orderDetail:tooltip.self_negotiate')}>
                                                            <Tag
                                                                color="#82e910"
                                                                className="ml-2 mr-2">
                                                                Deal
                                                            </Tag>
                                                        </Tooltip>
                                                    )}
                                                    {!!orderDetail.fintech && (
                                                        <Tooltip title={t('orderDetail:tooltip.fin_tech')}>
                                                            <Tag color="#108ee9">Fin</Tag>
                                                        </Tooltip>
                                                    )}
                                                    <CheckExport
                                                        dataOrder={dataOrder}
                                                        updateItemsOrderDetail={this.props.updateItemsOrderDetail}
                                                    />
                                                    <UpdateHSCode
                                                        defaultValue={orderDetail.hs_code}
                                                        type="order"
                                                        orderId={dataOrder.id}
                                                        code={dataOrder.code}
                                                        updateHSCodeOrderDetail={this.props.updateHSCodeOrderDetail}
                                                        hsCodes={hsCodes}
                                                    />
                                                </div>

                                                <div className="d-flex align-items-center">
                                                    {canSyncWebhook && (
                                                        <Button
                                                            size="small"
                                                            className="mr-2"
                                                            onClick={() => this.handleSyncWebhook(dataOrder.id)}>
                                                            Cập nhật dữ liệu đơn
                                                        </Button>
                                                    )}
                                                    {canChangeReceive && (
                                                        <Button
                                                            size="small"
                                                            className="mr-2"
                                                            onClick={() => this.handleChangeReceive(dataOrder.id)}>
                                                            Chuyển đơn về TQ nhận
                                                        </Button>
                                                    )}
                                                    {canCancelTaobaoPurchaseOrder && (
                                                        <CancelTaobaoPurchaseOrder
                                                            getPurchasingOrderDetailAction={this.props.getPurchasingOrderDetailAction}
                                                            orderId={dataOrder.id}
                                                            purchaseId={lodash.get(taobaoPurchaseOrder, 'purchase_id')}
                                                        />
                                                    )}

                                                    {canCreatePurchaseOrderTaobao && (
                                                        <CreateTaobaoPurchaseOrder
                                                            getPurchasingOrderDetailAction={this.props.getPurchasingOrderDetailAction}
                                                            className="mr-2"
                                                            orderId={dataOrder.id}
                                                        />
                                                    )}
                                                    {authService.can(permissions.CREATE_PURCHASE_ORDER_1688) && canCreateOrder1688 && (
                                                        <CreateOrder1688
                                                            getPurchasingOrderDetailAction={this.props.getPurchasingOrderDetailAction}
                                                            orderId={dataOrder.id}
                                                            orderItems={dataOrder.items}
                                                            canViewOrderPreview={canViewOrderPreview}
                                                            orderPreview={orderPreview}
                                                            orderPreviewResuslt={orderPreviewResuslt}
                                                        />
                                                    )}

                                                    {canCancelOrder1688 && (
                                                        <Cancel1688Order
                                                            getPurchasingOrderDetailAction={this.props.getPurchasingOrderDetailAction}
                                                            orderId={dataOrder.id}
                                                        />
                                                    )}

                                                    {!lodash.isEmpty(order1688) && canViewOrder1688 && (
                                                        <div style={{ color: '#ff6a08', fontWeight: 500 }}>
                                                            <p className="mr-2">Đã tạo đơn 1688: {order1688}</p>
                                                        </div>
                                                    )}

                                                    {!lodash.isEmpty(taobaoPurchaseOrder) && (
                                                        <div style={{ color: '#ff6a08', fontWeight: 500 }}>
                                                            <p className="mr-2">Đã tạo đơn TBG #{taobaoPurchaseOrder.purchase_id}</p>
                                                            <p>{`(${t(`order:ORDER_TAOBAO_STATUS.${taobaoPurchaseOrder.purchase_order_status}`)})`}</p>
                                                        </div>
                                                    )}

                                                    {canCreatePaymentRequestSB && (
                                                        <CreatePaymentRequestBySBOrder
                                                            className="mr-2"
                                                            orderIds={[dataOrder.id]}
                                                            violateReasons={this.props.violateReasons}
                                                        />
                                                    )}

                                                    {!lodash.isEmpty(packageNoteUrl) && (
                                                        <Button
                                                            type="primary"
                                                            icon="plus-square"
                                                            size="small"
                                                            className="mr-2">
                                                            <a
                                                                target={'_blank'}
                                                                href={packageNoteUrl}>
                                                                <span className="font-weight-bold ml-2 a-text--white">Tạo lưu ý hàng hoá</span>
                                                            </a>
                                                        </Button>
                                                    )}

                                                    <Dropdown overlay={menu}>
                                                        <Button className="ant-btn-sm">
                                                            {titleDropdown} <Icon type="down" />
                                                        </Button>
                                                    </Dropdown>

                                                    {dataOrder.complaintSellerTotal !== 0 ? (
                                                        <div className="a-item__block__is_complaintseller ml-4">
                                                            <div className="a-item__block__complaintseller">
                                                                <ComplaintSellerByOrder dataOrder={dataOrder} />
                                                            </div>
                                                        </div>
                                                    ) : null}

                                                    <CreateComplaintSeller
                                                        order={dataOrder}
                                                        complaintSellerReasons={complaintSellerReasons}
                                                    />
                                                    <CreateExternalPaymentRequest order={dataOrder} />
                                                </div>
                                            </div>

                                            <hr className="m-0" />

                                            <div className="row-second">
                                                {dataOrder.id && (
                                                    <BillInfo
                                                        subtitle={t('order_detail.information_bill')}
                                                        order={dataOrder}
                                                        updateOrder={updateOrder}
                                                        accountPurchasersOptions={accountPurchasersOptions}
                                                        me={me}
                                                        isDesktopSmall={isDesktopSmall}
                                                        handleHidden={this.handleHidden.bind(this)}
                                                        handleShow={this.handleShow.bind(this)}
                                                        changeRateDeposit={this.props.changeRateDeposit}
                                                        isOpenBillInfo={this.state.isOpenBillInfo}
                                                        updateSellerNote={this.props.updateSellerNote}
                                                        getPurchasingOrderDetailAction={this.props.getPurchasingOrderDetailAction}
                                                    />
                                                )}
                                                <Waypoint
                                                    onEnter={this.handleHiddenNote.bind(this)}
                                                    onLeave={this.handleShowNote.bind(this)}
                                                />

                                                <hr className="m-0" />
                                                {sellerNote ? (
                                                    <>
                                                        <hr className="m-0" />
                                                        <div className={`a-purchasod__note_customer main mt-2`}>
                                                            <i className="fas fa-sticky-note"></i>
                                                            <span>
                                                                {t('orderDetail:label.seller_note')}: {sellerNote}
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : null}
                                                <hr className="m-0" />
                                                {(isCNAddress || canViewContact) && (
                                                    <div className="pt-2 pb-2">
                                                        <span className="a-purchasod__group--label pb-1">
                                                            {t('order_detail.user_address')}:{' '}
                                                            {isCNAddress && (
                                                                <img
                                                                    src={CN_IMAGE_LINK}
                                                                    alt="language"
                                                                    style={{
                                                                        width: '25px',
                                                                        marginRight: '10px',
                                                                    }}
                                                                />
                                                            )}
                                                        </span>
                                                        <div className="header__value">{detailReceiverInfo}</div>
                                                    </div>
                                                )}

                                                <Timeline order={dataOrder} />

                                                {dataOrder.buyer_note ? (
                                                    <>
                                                        <hr className="m-0" />
                                                        <div className={`a-purchasod__note_customer main mt-2`}>
                                                            <i className="fas fa-sticky-note"></i>
                                                            <span>{dataOrder.buyer_note}</span>
                                                        </div>
                                                    </>
                                                ) : null}

                                                {agencyNote ? (
                                                    <>
                                                        <hr className="m-0" />
                                                        <div className={`a-purchasod__note_customer main mt-2`}>
                                                            <i className="fas fa-comment"></i>
                                                            <span>{agencyNote}</span>
                                                        </div>
                                                    </>
                                                ) : null}

                                                <ScrollArea
                                                    speed={0.8}
                                                    horizontal={true}
                                                    swapWheelAxes={true}>
                                                    {() => (
                                                        <div
                                                            className={`a-purchasod__header ${isActive ? 'active' : ''} ${
                                                                isOpenMenuBar ? 'sidebar-open' : ''
                                                            }`}>
                                                            <div className="a-purchasod__header__top">
                                                                <ul>
                                                                    <li>
                                                                        <a
                                                                            href=""
                                                                            onClick={event => event.preventDefault()}
                                                                            className={`header_partner header_partner--${agencyCode}`}>
                                                                            {agencyShortCode}
                                                                        </a>
                                                                        {orderDetail && !!orderDetail.is_full_customs_info && (
                                                                            <Tooltip
                                                                                title={t('orderDetail:tooltip.full_customs_info')}
                                                                                placement="bottom">
                                                                                <span className="p-2">
                                                                                    <Icon
                                                                                        type="check-circle"
                                                                                        theme="twoTone"
                                                                                        twoToneColor="#52c41a"
                                                                                    />
                                                                                </span>
                                                                            </Tooltip>
                                                                        )}
                                                                        <Copy contentNote={lodash.get(dataOrder, 'code', '')} />
                                                                        <span className={'a-status a-status--' + dataOrder.status}>
                                                                            {t('order_detail.' + dataOrder.status)}
                                                                        </span>
                                                                    </li>
                                                                    {canViewContact && (
                                                                        <>
                                                                            <li>
                                                                                <span className="header__label"> {t('order_detail.info_customer')}</span>
                                                                                <span className="header__value a-text--font-blue ">
                                                                                    {dataOrder.buyer_name} /
                                                                                    <a
                                                                                        href={dataOrder.partner_url_user_detail}
                                                                                        style={{ textDecoration: 'none' }}
                                                                                        onClick={this.onOpenNewTab.bind(
                                                                                            this,
                                                                                            dataOrder.partner_url_user_detail
                                                                                        )}>
                                                                                        @{dataOrder.buyer_username}
                                                                                    </a>
                                                                                </span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="header__label">{t('order_detail.phone')} </span>
                                                                                <span className="header__value">{dataOrder.buyer_phone_mark}</span>
                                                                            </li>
                                                                        </>
                                                                    )}

                                                                    <li>
                                                                        <span className="header__label">{t('order_detail.purchase_warehouse')}: </span>
                                                                        <span className="ml-1 a-text--bold ">
                                                                            {lodash.get(dataOrder, 'purchase_address.alias', '---')}
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <ModalUpdateCustoms
                                                                            order={dataOrder}
                                                                            order_item_checked={formData.order_item_checked}
                                                                            items={items}
                                                                            updateItemsOrderDetail={this.props.updateItemsOrderDetail}
                                                                            onClearCheckAll={this.onClearCheckAll.bind(this)}
                                                                            seperate={false}
                                                                        />
                                                                    </li>
                                                                    <li>
                                                                        <UpdateHSCodeMultipleItems
                                                                            orderId={dataOrder.id}
                                                                            items={formData.order_item_checked}
                                                                            updateItemsOrderDetail={this.props.updateItemsOrderDetail}
                                                                            onClearCheckAll={this.onClearCheckAll.bind(this)}
                                                                            seperate={false}
                                                                            updateHSCodeOrderDetail={this.props.updateHSCodeOrderDetail}
                                                                        />
                                                                    </li>
                                                                    {!lodash.isEmpty(packageNoteUrl) && (
                                                                        <li>
                                                                            <Button
                                                                                type="primary"
                                                                                icon="plus-square"
                                                                                size="small"
                                                                                className="mr-2">
                                                                                <a
                                                                                    target={'_blank'}
                                                                                    href={packageNoteUrl}>
                                                                                    <span className="font-weight-bold ml-2 a-text--white">
                                                                                        Tạo lưu ý hàng hoá
                                                                                    </span>
                                                                                </a>
                                                                            </Button>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>

                                                            {dataOrder.buyer_note ? (
                                                                <div className={`a-purchasod__header__bottom ${isActiveNote ? '' : 'hidden'}`}>
                                                                    <div className="a-purchasod__note_customer">
                                                                        <i className="fas fa-sticky-note"></i>
                                                                        <span>{dataOrder.buyer_note}</span>
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                </ScrollArea>
                                            </div>

                                            <div className="a-purchasod__table__order__show_hide a--cursor-poiter">
                                                <hr className="m-0" />
                                                <a onClick={this.onToggleBillInfo}>
                                                    {this.state.isOpenBillInfo ? (
                                                        <>
                                                            <Icon type="shrink" /> {t('orderDetail:order_detail.collapse')}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Icon type="arrows-alt" /> {t('orderDetail:order_detail.explain')}
                                                        </>
                                                    )}
                                                </a>
                                            </div>
                                        </div>

                                        <div className="a-purchasod__second a-content--box-shadow p-0 mt-3">
                                            <Features
                                                subtitle={t('order_detail.service')}
                                                features={this.props.features}
                                                order={dataOrder}
                                                updateOrderFeature={this.props.updateOrderFeature}
                                                isFetchNewFeature={this.props.isFetchNewFeature}
                                            />
                                            <OrderList
                                                changeFilterCustomerNote={this.changeFilterCustomerNote.bind(this)}
                                                isFilterCustomerNote={this.state.isFilterCustomerNote}
                                                isFilterByPai={this.state.isFilterByPai}
                                                items={items}
                                                defaultChecked={defaultChecked}
                                                channelSendMessage={`START_AUTO_PAI_` + window.KID}
                                                channels={this.props.channels}
                                                subtitle={t('order_detail.Products')}
                                                onCheckAll={this.onCheckAll.bind(this)}
                                                onCheckItem={this.onCheckItem.bind(this)}
                                                check_all={formData.check_all}
                                                order_item_checked={formData.order_item_checked}
                                                order={dataOrder}
                                                clearPurchaserBuyingChangeDataState={clearPurchaserBuyingChangeDataState}
                                                isLoadingUpdateData={isLoadingUpdateData}
                                                postItemLog={postItemLog}
                                                onUpdatePaidOrderDetail={this.props.onUpdatePaidOrderDetail}
                                                changeMutipleQuantity={this.props.changeMutipleQuantity}
                                                updateOrderItemPrice={this.props.updateOrderItemPrice}
                                                isEndProcess={this.props.isEndProcess}
                                                isLoading={isLoading}
                                                isLoadingData={isLoadingData}
                                                clearErrorWhileUpdatePrice={this.props.clearErrorWhileUpdatePrice}
                                                errorUnitPrice={this.props.errorUnitPrice}
                                                beginProcessChangePrice={this.props.beginProcessChangePrice}
                                                errorCommentItem={this.props.errorCommentItem}
                                                clearErrorWhilePostCommentItem={this.props.clearErrorWhilePostCommentItem}
                                                isLoadingWhilePostItemComment={this.props.isLoadingWhilePostItemComment}
                                                getPusherCommentItem={this.props.getPusherCommentItem}
                                                changeOrderItemQuantity={this.props.changeOrderItemQuantity}
                                                onSortOrderItems={this.props.onSortOrderItems}
                                                getPusherComment={this.props.getPusherComment}
                                                beginProcessUpdateOutStock={this.props.beginProcessUpdateOutStock}
                                                beginProcessUpdatePriceItems={this.props.beginProcessUpdatePriceItems}
                                                beginProcessUpdateQuantityItems={this.props.beginProcessUpdateQuantityItems}
                                                successUpdateItems={this.props.successUpdateItems}
                                                beginClearErrorUpdatePriceItems={this.props.beginClearErrorUpdatePriceItems}
                                                onClearCheckAll={this.onClearCheckAll.bind(this)}
                                                errorUnitPriceItems={this.props.errorUnitPriceItems}
                                                updateOutOfStockItems={this.props.updateOutOfStockItems}
                                                updatePriceItems={this.props.updatePriceItems}
                                                updateQuantityItems={this.props.updateQuantityItems}
                                                updateFragileOrderDetail={this.props.updateFragileOrderDetail}
                                                updateAffiliateLink={this.props.updateAffiliateLink}
                                                updateItemsOrderDetail={this.props.updateItemsOrderDetail}
                                                updateEcommerceShippingOrderDetail={this.props.updateEcommerceShippingOrderDetail}
                                                getPurchasingOrderDetailAction={this.props.getPurchasingOrderDetailAction}
                                                updateHSCodeOrderDetail={this.props.updateHSCodeOrderDetail}
                                                match={this.props.match}
                                            />
                                        </div>

                                        <div className="a-purchasod__third a-content--box-shadow p-0 mt-3">
                                            <div className="a-purchasod__third__title a-font--18 d-flex">
                                                {dataOrder.shipping_bill_code === '---' ? (
                                                    ' ---'
                                                ) : (
                                                    <Copy
                                                        contentNote={dataOrder.shipping_bill_code}
                                                        classText="a-text--bold a-text--font-blue"
                                                    />
                                                )}
                                            </div>
                                            <hr className="m-0" />
                                            <Row
                                                gutter={20}
                                                style={{ padding: '1rem' }}>
                                                <Col span={18}>
                                                    <TotalItem
                                                        order={dataOrder}
                                                        onUpdateShippingFee={this.props.onUpdateShippingFee}
                                                        shippingFeeHistory={this.props.shippingFeeHistory}
                                                        getShippingFeeHistory={this.props.getShippingFeeHistory}
                                                    />
                                                    {!!canShowDiscountAmount && (
                                                        <TotalDiscount
                                                            order={dataOrder}
                                                            updateTotalDiscount={this.props.onUpdateTotalDiscount}
                                                        />
                                                    )}

                                                    <InvoiceCode
                                                        order={dataOrder}
                                                        onUpdateShippingFee={this.props.onUpdateShippingFee}
                                                        beginProcessPostInvoiceCode={this.props.beginProcessPostInvoiceCode}
                                                        beginProcessDeleteInvoiceCode={this.props.beginProcessDeleteInvoiceCode}
                                                        onPostInvoiceCode={this.props.onPostInvoiceCode}
                                                        isLoading={lodash.get(isLoadingData, 'postInvoiceCode', false)}
                                                        isEndProcess={this.props.isEndProcess}
                                                        errorInvoiceCode={this.props.errorInvoiceCode}
                                                        deleteInvoiceCode={this.props.deleteInvoiceCode}
                                                        clearErrorWhilePostInvoiceCode={this.props.clearErrorWhilePostInvoiceCode}
                                                        updateInvoiceCode={this.props.updateInvoiceCode}
                                                    />

                                                    <FreightBill
                                                        order={dataOrder}
                                                        onUpdateShippingFee={this.props.onUpdateShippingFee}
                                                        beginProcessPostInvoiceCode={this.props.beginProcessPostInvoiceCode}
                                                        beginProcessDeleteInvoiceCode={this.props.beginProcessDeleteInvoiceCode}
                                                        addFreightBill={this.props.addFreightBill}
                                                        deleteFreightBill={this.props.deleteFreightBill}
                                                        isLoading={isLoading}
                                                        freightBillDuplicateInOrders={freightBillDuplicateInOrders}
                                                        isEndProcess={this.props.isEndProcess}
                                                        errorInvoiceCode={this.props.errorInvoiceCode}
                                                        clearErrorWhilePostInvoiceCode={this.props.clearErrorWhilePostInvoiceCode}
                                                        freightBillErrors={this.props.freightBillErrors}
                                                        clearFreightBillErrors={this.props.clearFreightBillErrors}
                                                        clearFreightBillDuplicate={this.props.clearFreightBillDuplicate}
                                                        syncOrderBillNo={this.props.syncOrderBillNo}
                                                    />

                                                    <Row className="mt-4">
                                                        <Col span={12}>
                                                            <UpdateFinanliOrderContainer
                                                                dataOrder={dataOrder}
                                                                loading={isLoading}
                                                                getPurchasingOrderDetailAction={this.props.getPurchasingOrderDetailAction}
                                                            />
                                                        </Col>
                                                        <Col
                                                            span={12}
                                                            style={{ paddingLeft: '15px' }}>
                                                            <OrderGroup
                                                                onUpdateOrderGroupOrder={onUpdateOrderGroupOrder}
                                                                options={orderGroup}
                                                                orderId={dataOrder.id}
                                                                selected={dataOrder.orderGroupSelected}
                                                                canChangeOrderGroup={dataOrder.canChangeOrderGroup}
                                                                orderGroup={dataOrder.orderGroup}
                                                                order_group_using={order_group_using}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <ListTransaction
                                                        dataOrder={dataOrder}
                                                        loading={isLoading}
                                                    />
                                                </Col>
                                                <Col span={6}>
                                                    <Service
                                                        subtitle={t('order_detail.service')}
                                                        services={services}
                                                        order={dataOrder}
                                                        approvalOrderService={approvalOrderService}
                                                        updateOrderService={updateOrderService}
                                                        isFetchNewService={isFetchNewService}
                                                    />
                                                </Col>
                                            </Row>
                                            <div className={'a-purchasod__block mb-1'}>
                                                <React.Fragment>
                                                    {dataOrder.warning !== '' && (
                                                        <div
                                                            className="p-2 pl-2 pr-0 d-flex align-items-center mt-2 ml-0"
                                                            style={{
                                                                backgroundColor: 'rgba(255, 165, 0, 0.1)',
                                                                borderRadius: ' 4px',
                                                            }}>
                                                            <i className="fas fa-exclamation-circle a-text--danger"></i>
                                                            <p className="ml-2 a-text--bold">{dataOrder.warning}</p>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            </div>
                                        </div>

                                        <ActionBottom
                                            changeFilterCustomerNote={this.changeFilterCustomerNote.bind(this)}
                                            changeFilterBy={this.changeFilterBy.bind(this)}
                                            isFilterCustomerNote={this.state.isFilterCustomerNote}
                                            isFilterByPai={this.state.isFilterByPai}
                                            isOpenMenuBar={isOpenMenuBar}
                                            order={dataOrder}
                                            items={items}
                                            defaultChecked={defaultChecked}
                                            error={error}
                                            isLoadingCancelOrder={isLoadingCancelOrder}
                                            isEndProcess={isEndProcess}
                                            isLoadingOutOfStockOrder={isLoadingOutOfStockOrder}
                                            beginProcessOutOfStock={beginProcessOutOfStock}
                                            beginProcessCancelOrder={beginProcessCancelOrder}
                                            onPayment={onPayment}
                                            onPaid={onPaid}
                                            onCancel={onCancel}
                                            onConfirm={onConfirm}
                                            onOutOfStock={onOutOfStock}
                                            changeMutipleQuantity={this.props.changeMutipleQuantity}
                                            updateOrderItemPrice={this.props.updateOrderItemPrice}
                                            isLoading={isLoading}
                                            check_all={formData.check_all}
                                            onCheckAll={this.onCheckAll.bind(this)}
                                            order_item_checked={order_item_checked_current}
                                            isLoadingData={isLoadingData}
                                            clearErrorWhileUpdatePrice={clearErrorWhileUpdatePrice}
                                            errorUnitPrice={errorUnitPrice}
                                            beginProcessChangePrice={beginProcessChangePrice}
                                            beginProcessUpdateOutStock={beginProcessUpdateOutStock}
                                            beginProcessUpdatePriceItems={beginProcessUpdatePriceItems}
                                            beginProcessUpdateQuantityItems={beginProcessUpdateQuantityItems}
                                            successUpdateItems={successUpdateItems}
                                            beginClearErrorUpdatePriceItems={beginClearErrorUpdatePriceItems}
                                            errorUnitPriceItems={errorUnitPriceItems}
                                            updateOutOfStockItems={updateOutOfStockItems}
                                            updatePriceItems={updatePriceItems}
                                            updateQuantityItems={updateQuantityItems}
                                            onWaitingPayment={onWaitingPayment}
                                            getPurchasingOrderDetailAction={this.props.getPurchasingOrderDetailAction}
                                        />
                                    </div>

                                    <div className={'a-purchasod__body__right'}>
                                        <div className={'a-chat__purchasod'}>
                                            {dataOrder.id ? (
                                                <ChatLog
                                                    object="order"
                                                    objectId={dataOrder.id}
                                                    canComment={dataOrder.canComment}
                                                    dataOrder={dataOrder}
                                                    size="large"
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="a-loading__page">
                    <div className="a-loading__page__icon"></div>
                </div>
            </React.Fragment>
        )
    }
}

PurchasingOrderDetail.defaultProps = {
    dataOrder: {},
    isLoadingData: {},
    freightBillDuplicateInOrders: {},
    dataError: {},
    isLoadingUpdateData: {},
    services: [],
    complaintSellerReasons: [],
    getPurchasingOrderDetailAction: () => {},
}

PurchasingOrderDetail.propTypes = {
    dataOrder: PropTypes.object,
    isLoadingData: PropTypes.object,
    freightBillDuplicateInOrders: PropTypes.object,
    dataError: PropTypes.object,
    isLoadingUpdateData: PropTypes.object,
    services: PropTypes.array,
    getPurchasingOrderDetailAction: PropTypes.func,
    complaintSellerReasons: PropTypes.array,
}

export default translate('translations')(withSizes(mapSizesToProps)(PurchasingOrderDetail))
