import React from 'react';
import Select from '@atlaskit/select';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import InlineEdit from '../../../common/components/InlineEdit'

class SelectPurchaser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            isEdit: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        if (JSON.stringify(nextProps.selected) !== JSON.stringify(prevState.selected)) {
            if (nextProps.selected.value) {
                state.selected = nextProps.selected;
            }
        }

        return state;
    }

    onChangeSelect(data) {
        if (data && data.value) {
            this.props.onUpdate(data.value);
        }
    }

    handleClickOutside = evt => {
        this.cancelForm();
    };

    getOrderAccountPurchaser() {
        let {order = {}, options} = this.props;
        let orderAccountPurchaserId = order.id_purchaser || order.default_account_purchaser_id;
        let orderPurchaseSelected = lodash.get(order, 'purchaser', null);
        return lodash.find(options, {value: orderAccountPurchaserId})
            ||  (orderPurchaseSelected ? {value: orderPurchaseSelected.id, label: orderPurchaseSelected.account} : null);
    }

    canChangeAccountPurchaser() {
        let {order} = this.props;
        return order.canChangeAccountPurchaser;
    }

    render() {
        let {t, options, wrapperClass} = this.props;
        let {selected, isEdit} = this.state;
        selected = selected ? selected : this.getOrderAccountPurchaser();
        return (
            <div>
                {this.canChangeAccountPurchaser() ?
                    <InlineEdit
                        wrapperClass={wrapperClass}
                        text={(selected && selected.label) ? selected.label : '---'}
                        options={options}
                        isEdit={isEdit}
                        disableAction
                        isPencilLeft
                        editDisabled={!this.canChangeAccountPurchaser()}
                    >
                        <Select
                            menuIsOpen={true}
                            noOptionsMessage={() => t("system.no_option")}
                            className="single-react"
                            classNamePrefix={`react-select--purchasebuying`}
                            options={options}
                            value={selected}
                            placeholder={t('order_detail.select_account_buyer')}
                            onChange={data => this.onChangeSelect(data)}
                            isDefaultOpen
                            autoFocus={true}
                            isDisabled={!this.canChangeAccountPurchaser()}

                        />
                    </InlineEdit>
                    :
                    (selected && selected.label) ? selected.label : '---'
                }
            </div>
        )
    }
}

SelectPurchaser.defaultProps = {
    options: [],
    order: {},
    onUpdate: (accountPurchaserId) => {},
};


SelectPurchaser.propTypes = {
    options: PropTypes.array,
    order: PropTypes.object,
    onUpdate: PropTypes.func,
};

export default translate('translations')(SelectPurchaser);
