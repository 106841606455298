import React from "react";
import {Icon} from "antd";

class CollapsedItem extends React.Component{

    handleToggle = e => {
        let {itemId, changeCurrentId, currentId} = this.props;
        itemId === currentId ? changeCurrentId(0) : changeCurrentId(itemId);
    }

    render() {
        let {currentId, className, title, itemId} = this.props;
        return <>
            <div className={`a-filter--group-collapsed ${currentId === itemId ? "active " : ""}${className ? className : ""}`}>
                <p className="a--cursor-poiter text-center p-2" onClick={this.handleToggle} style={{position: "relative", zIndex: 2}}>
                    <span className="a-text--color-primary title-search-col">{title}</span>
                </p>
                <div className="body-search-col" style={{display: currentId === itemId ? "block" : "none"}}>
                    <div className="form-search-col">
                        {this.props.children}
                    </div>
                </div>
            </div>
        </>;
    }
}

export default CollapsedItem;