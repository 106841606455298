export const FETCH_VIOLATE_POLICY_ORDERS = {
    REQUEST: 'FETCH_VIOLATE_POLICY_ORDERS_REQUEST',
    SUCCESS: 'FETCH_VIOLATE_POLICY_ORDERS_SUCCESS',
    FAILED: 'FETCH_VIOLATE_POLICY_ORDERS_FAILED'
};

export const UPDATE_REASON = {
    REQUEST: 'UPDATE_REASON_REQUEST',
    SUCCESS: 'UPDATE_REASON_SUCCESS',
    FAILED: 'UPDATE_REASON_FAILED'
};