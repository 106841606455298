import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Stats from "./Stats";
import PerformAction from "./PerformAction";
import ListOrder from "./ListOrder";

class OrderList extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className="a-list mt-5">
            <Stats
                stats={this.props.statsStatus}
                filter={this.props.filter}
                onSearch={this.props.onSearch}
                canChangeWaitingPay={this.props.canChangeWaitingPay}
                canShowOrderWaitingBuy={this.props.canShowOrderWaitingBuy}
            />
            <PerformAction
                onSearch={this.props.onSearch}
                onCheckAllOrders={this.props.onCheckAllOrders}
                onAssignPurchaserOrders={this.props.onAssignPurchaserOrders}
                updateOrders={this.props.updateOrders}
                checkedOrders={this.props.checkedOrders}
                orders={this.props.orders}
                filter={this.props.filter}
                tellers={this.props.tellers}
                pagination={this.props.pagination}
                canSelfAssign={this.props.canSelfAssign}
            />
            <ListOrder
                orders={this.props.orders}
                tellers={this.props.tellers}
                pagination={this.props.pagination}
                checkedOrders={this.props.checkedOrders}
                onCheckOrder={this.props.onCheckOrder}
                onSearch={this.props.onSearch}
                onAssignPurchaser={this.props.onAssignPurchaser}
                onUnassignPurchaser={this.props.onUnassignPurchaser}
                distributing={this.props.distributing}
                loading={this.props.loading}
                complaintSellerReasons={this.props.complaintSellerReasons}
                canViewContact={this.props.canViewContact}
            />
        </div>;
    }
}

OrderList.propTypes = {};

export default OrderList;
