import React from 'react';
import {translate} from 'react-i18next';
import {Link} from '../../../../systems/routing';
import {dispatch} from '../../../../systems/store';
import {setPageTitle} from './../../actions';

class Page403 extends React.Component {
    componentDidMount() {
        dispatch(setPageTitle(this.props.t('layout.title.403')));
    }

    render() {
        let {t} = this.props;

        return (
            <div className="error">
                <div className="container-floud">
                    <div className="col-xs-12 ground-color text-center">
                        <div className="container-error-404">
                            <div className="clip">
                                <div className="shadow"><span className="digit thirdDigit">4</span></div>
                            </div>
                            <div className="clip">
                                <div className="shadow"><span className="digit secondDigit">0</span></div>
                            </div>
                            <div className="clip">
                                <div className="shadow"><span className="digit firstDigit">3</span></div>
                            </div>
                        </div>
                        <h2 className="h1">{t('error_page.403_message')}</h2>
                        <Link href="/" className={'a-link'}>
                            <i className="fas fa-angle-double-left pr-1" />{t('error_page.return_home')}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate()(Page403);
