import {call, put} from 'redux-saga/effects'
import * as Constants from './constants';
import service from './apiService';
import bootbox from "../../modules/common/components/Bootbox/bootbox";
import {t} from './../../systems/i18n/index.js';
import * as GlobalConstants from "../../systems/globalContant";
import lodash from 'lodash';
export function* fetchViolatePolicyOrders(action) {
    try {
        let response = yield call(service.fetchViolatePolicyOrders, action.payload);
        if (response) {
            if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({type: Constants.FETCH_VIOLATE_POLICY_ORDERS.SUCCESS, payload: response.data});
            } else {
                yield put({type: Constants.FETCH_VIOLATE_POLICY_ORDERS.FAILED, payload: response.data});
            }
        }
    } catch (err) {
        bootbox.error(t('global.error'));
    }
}

export function* updateReason(action) {
    try {
        let id = lodash.get(action, 'payload.id');
        if(id){
            let response = yield call(service.updateReason, action.payload);
            if (response) {
                if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                    yield put({type: Constants.UPDATE_REASON.SUCCESS, payload: response.data});
                    bootbox.success(t('violate:list.update_reason_success'));
                } else {
                    yield put({type: Constants.UPDATE_REASON.FAILED, payload: response.data});
                }
            }
        }

    } catch (err) {
        bootbox.error(t('global.error'));
    }
}

/* eslint-disable */
export function* resultUpdate(action) {
    let data = lodash.get(action.payload, 'data', false);

    Object.keys(data).map(key => {
        bootbox.error(t("violate:list.errors." + key));
        return null;
    });
}
/* eslint-enable */