import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import ComplaintAssign from './components/ComplaintAssign';
import * as actions from './actions';

const mapStateToProps = (state, props) => {
    let {complaint, permissions} = props;
    let receptionComplaints = lodash.get(state, 'ComplaintSeller.complaintReception.complaints', []);
    let complaintReception = lodash.find(receptionComplaints, {id: complaint.id}) || {};
    return {
        ...complaintReception,
        canAssign: lodash.get(permissions, 'can_update_assign', false),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    let {complaint} = props;

    return {
        onAssign: (id_assign) => {
            dispatch(actions.assignComplaint({id: complaint.id, id_assign: id_assign}));
        },
        onReset: () => {
            dispatch(actions.clearState(complaint.id));
        }
    };
};

let ComplaintSellerAssignContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ComplaintAssign);

ComplaintSellerAssignContainer.defaultProps = {
    complaint: {},
};

ComplaintSellerAssignContainer.propTypes = {
    complaint: PropTypes.object.isRequired,
};

export default ComplaintSellerAssignContainer;
