import {combineReducers} from 'redux';
import ListTransactionFile from './ListTransactionFile/reducer';
import ListTransactionOrder from './ListTransactionOrder/reducer';
import UploadTransactionFile from './UploadFileCSV/reducer';
import UploadTransactionFileGlobal from './UploadFileCSVGlobal/reducer';
import UploadTransactionFileResult from './UploadFileCSVResult/reducer';
import ListTransaction from './ListTransaction/reducer';
import InputAddInvoiceCode from './InputAddInvoiceCode/reducer';
import OrderCode from './OrderCode/reducer';
import ModalManageInvoiceCode from './ModalManageInvoiceCode/reducer';
import DetailTransaction from './DetailTransaction/reducer';
import UploadInvoiceCode from './UploadInvoiceCodeCSV/reducer';
import MultiResolveTransaction from "./MultiResolveTransaction/reducer";

export default combineReducers({
    UploadTransactionFileResult,
    ListTransactionFile,
    UploadTransactionFile,
    UploadTransactionFileGlobal,
    ListTransactionOrder,
    ListTransaction,
    InputAddInvoiceCode,
    OrderCode,
    ModalManageInvoiceCode,
    DetailTransaction,
    UploadInvoiceCode,
    MultiResolveTransaction
});
