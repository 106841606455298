import React from 'react';
import FormSearch from './FormSearch';
import ListFileUpload from './ListFileUpload';
import {Layout} from 'antd';
import {url} from "../../../../systems/routing/index";
import {translate} from 'react-i18next';
import lodash from 'lodash';
import PropTypes from 'prop-types';

const {Content} = Layout;

class ListTransactionFile extends React.Component {

    componentDidMount() {
        let {t} = this.props;
        this.props.setPageTitle(t('transaction:label.list_file'));
        this.props.setPageDescription(t('transaction:label.list_file_desc'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.transaction-file',
                'link': url.to('transaction.files.list')
            },
        ]);
        this.props.setMenuActive("transaction.files.list");
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    render() {
        return (
            <Layout>
                <Content className="a-content--ant-layout ">
                    <FormSearch
                        input={this.props.filter}
                        inputVersion={lodash.toInteger(this.props.filter._i)}
                        loading={this.props.loading}
                        onSearch={this.props.onSearch}
                    />
                    <ListFileUpload
                        filter={this.props.filter}
                        transactionFiles={this.props.transactionFiles}
                        loading={this.props.loading}
                        pagination={this.props.pagination}
                        onSearch={this.props.onSearch}
                        downloadFile={this.props.downloadFile}
                        permission={this.props.permission}
                    />
                </Content>
            </Layout>
        )
    }
}

ListTransactionFile.defaultProps = {
    transactionFiles: [],
    pagination: {},
    filter: {},
    loading: false,
    onSearch: (filter) => {},
};

ListTransactionFile.propTypes = {
    transactionFiles: PropTypes.array,
    pagination: PropTypes.object,
    filter: PropTypes.object,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
    clearState: PropTypes.func,
    downloadFile: PropTypes.func,
};

export default translate('translations')(ListTransactionFile);
