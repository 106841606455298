import {call, put} from 'redux-saga/effects'
import lodash from 'lodash'
import {GET_PARTNER_CONFIG,GET_ERROR_404} from '../../systems/globalContant/actionContant'
import {GLOBAL_CONTANT} from '../../systems/globalContant/index'
import service from './apiServies'
import bootbox from './components/Bootbox/bootbox';

/**
 * Hệ thống sẽ kiểm tra xem có config tương ứng với domain đang sử dụng có lưu trong local stograte hay chưa
 * 1. Nếu chưa có hoặc không đúng với thông tin của url hiện tại thì:
 * 1.1 Call API lấy lại thông tin này từ server
 * 1.2 Dispact action để cập nhật lại state store( mục đích là load lại trang logo tại trang login/ thông báo k có thông tin config )
 * 2. Nếu có rồi thì sẽ không làm gì cả.
 * @param action
 */
export function* getPartnerConfig(action) {
    try {
        let domainStore = localStorage.getItem('domainCode');
        let fullUrl = window.location.href;
        let arrUrl = fullUrl.split("/");
        if (typeof domainStore === 'undefined' || domainStore === '' || !arrUrl[2].includes(domainStore)) {
            let response = yield call(service.getPartnerConfig, action.payload.datas);
            if (typeof response.data !== 'undefined') {
                if (response.data.code === GLOBAL_CONTANT.SUCCESS_CODE) {
                    localStorage.setItem('domainCode', response.data.data.code);
                    localStorage.setItem('domainLogo', response.data.data.logo);
                    yield put({type: GET_PARTNER_CONFIG.SUCCESS, payload: response.data});
                } else {
                    // TODO: viết code bắt trường hợp partner chưa được đăng ký
                    yield put({type: GET_PARTNER_CONFIG.FAILED, payload: response});
                }
            } else {
                yield put({type: GET_PARTNER_CONFIG.FAILED, payload: {}});
            }
        }
    } catch (err) {
        // TODO: viết code bắt trường hợp exception
        // yield put({type: GLOBAL_CONTANT.FAILED, err});
    }
}

/**
 * Event này dùng để thay đổi lại title của page khi có module nào call đến
 * @param {*} action
 */
export function* setPageTitle(action) {
    let title = yield typeof action.payload.title !== 'undefined' && action.payload.title ? action.payload.title : 'Home';
    try {
        document.title = title;
    } catch (err) {
        document.title = 'Home';
    }
}

/**
 * Xử lý api request
 */
export function* processApiRequest(actionType, handler, request = {}) {
    try {
        let response = yield call(handler);
        if(!response) {
            return;
        }
        return lodash.get(response, 'data.code') === GLOBAL_CONTANT.SUCCESS_CODE
            ? yield put({type: actionType.SUCCESS, payload: response.data, request})
            : yield put({type: actionType.FAILED, payload: response.data, request});
    } catch (error) {
        let message = lodash.get(error, 'message');

        if (message) {
            bootbox.error(message);
        }

        return yield put({
            type: actionType.FAILED,
            payload: {code: 500, message, request},
        });
    }
}


/**
 * Event này dùng để thay đổi error 404 của page khi có module nào call đến
 * @param {*} action
 */
export function* setError404(action) {
    yield put({type: GET_ERROR_404.SUCCESS, payload: {}});
}
