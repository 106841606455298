import {put} from 'redux-saga/effects';
import {processApiRequest} from '../../common/saga';
import * as ACTION from './constants';
import apiService from '../apiService';
import {t} from '../../../systems/i18n';
import bootbox from './../../common/components/Bootbox/bootbox';
import {dispatch} from './../../../systems/store';
import {fetchComplaintDetail, fetchComplaintLogs} from '../actions';

export function* receiveComplaint(action) {
    yield processApiRequest(
        ACTION.RECEIVE_COMPLAINT,
        () => apiService.receiveComplaint(action.payload.id),
        action.payload
    );
}

export function* onReceiveComplaintSuccess(action) {
    let {id} = action.request;
    yield put(fetchComplaintDetail({id}));
    setTimeout(() => dispatch(fetchComplaintLogs(id)), 2000);
    bootbox.success(t('complaintSeller:reception.message_receive_success'));
}

export function* onReceiveComplaintFailed() {
    yield bootbox.error(t('complaintSeller:reception.message_receive_failed'));
}
