import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import {Icon, Input, Modal, Button} from "antd";
import apiService from "../../apiService";
import {processResponse} from "../../../../systems/api";
import lodash from "lodash";
import bootbox from "../../../common/components/Bootbox/bootbox";
import {events} from "../../../../systems/events";
import {COMPLAINT_SELLER_REJECT} from "../../events";

class RejectComplaint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            openModal: false,
            note_failure: '',
        };
    }
    openModal() {
        this.setState({openModal: true});
    }
    onCancel() {
        if (!this.state.loading) {
            this.setState({openModal: false});
        }
    }
    rejectComplaintSeller() {
        if (this.state.loading) {
            return;
        }

        let {t, complaint} = this.props;
        this.setState({loading: true});
        events.dispatch(COMPLAINT_SELLER_REJECT.REQUEST, {
            complaint: {...complaint}
        });
        let res = apiService.rejectComplaintSeller({id: complaint.id, note_failure: this.state.note_failure})
            .finally(() => this.setState({loading: false}));

        let process = processResponse(res);

        process.then(res => {
            let complaint = lodash.get(res, "data.complaintSeller", {});

            events.dispatch(COMPLAINT_SELLER_REJECT.SUCCESS, {
                complaint: {...complaint}
            });
            bootbox.success(t('complaintSeller:rejection.message_reject_success'));
        });
        process.catch(() => {
            bootbox.error(t('complaintSeller:rejection.message_reject_failed'));
        });
    }
    render() {
        let {t, onList} = this.props;
        let {loading} = this.state;
        return (
            <React.Fragment>
                {onList ?
                    <span
                        className="a-text--red a--cursor-poiter ml-2"
                        role="button"
                        onClick={(event) => this.openModal()}
                    >{t("complaintSeller:list.refuse_complaint_seller")}</span> :
                    <Button
                        type="danger"
                        className="action-btn-cs reject-btn-cs"
                        onClick={(event) => this.openModal()}
                        loading={loading}
                        ghost
                    >
                        <Icon type="stop" />
                        {t('complaintSeller:rejection.btn_reject')}
                    </Button>
                }
                <Modal
                    width={360}
                    title={t('complaintSeller:list.refuse_complaint_seller')}
                    visible={this.state.openModal}
                    onOk={this.rejectComplaintSeller.bind(this)}
                    onCancel={this.onCancel.bind(this)}
                    footer={false}
                    centered={true}
                    maskClosable={false}
                    className={"a-modal--list-trade"}
                >
                    <span>{t('transaction:label.resolve_reason')} <span className="a-text--red">*</span></span>
                    <Input.TextArea
                        value={this.state.note_failure}
                        onChange={event => this.setState({note_failure: event.target.value})}
                        placeholder={t('transaction:placeholder.enter_reason')}
                        rows={"3"}
                        className="mt-1"
                        autosize={{minRows: 3, maxRows: 3}}
                        disabled={this.state.loading}
                    />
                    <div className={"amt-24 d-flex justify-content-end"}>
                        <Button
                            disabled={this.state.loading}
                            className=" a-font--12 a-btn--primary-ant ghost"
                            onClick={this.onCancel.bind(this)}
                        >{t('btn.cancel')}</Button>
                        <Button
                            loading={this.state.loading}
                            className=" a-font--12 a-btn--primary-ant aphz-24 ml-3 a-text--white"
                            disabled={!this.state.note_failure}
                            onClick={this.rejectComplaintSeller.bind(this)}
                        >{t('btn.submit')}</Button>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

RejectComplaint.defaultProps = {
    loading: false,
    complaint: {},
    onList: false
};

RejectComplaint.propTypes = {
    loading: PropTypes.bool,
    complaint: PropTypes.object,
    onList: PropTypes.bool,
};

export default translate()(RejectComplaint);
