import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import Setting from "../../components/Setting";
import {url} from "../../../../systems/routing/index";
import lodash from "lodash";
import {setActiveSettingItem} from '../../../Settings/SettingMenu/actions';

class UserSettingComponent extends React.Component {

    componentDidMount() {
        let {t} = this.props;
        this.props.setPageTitle(t('setting:private_title'));
        this.props.setPageDescription(t('setting:private_description'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.setting-private',
                'link': url.to('setting-private')
            },
        ]);
        this.props.setMenuActive("setting-private");
        setActiveSettingItem('setting-private');
        let listConfig = lodash.get(this.props, "listConfig", {});
        if (Object.keys(listConfig).length === 0 && listConfig.constructor === Object) {
            this.props.fetchAllConfigUser();
        }
    }

    render() {
        let {t} = this.props;
        return (
            <div className="a-content__right">
                {((Object.keys(this.props.listConfig).length === 0 && this.props.listConfig.constructor === Object) || this.props.listConfig.constructor === Array)
                    ?
                    <div
                        className="a-setting__block a-content--box-shadow "><p className={"d-flex justify-content-center"}>{this.props.isLoading ? t("setting:loading") : t("setting:no_record_user")}</p></div>
                    :
                    <Setting
                        isEndProcess={this.props.isEndProcess}
                        isLoading={this.props.isLoading}
                        listConfig={this.props.listConfig}
                        updatePrivateSetting={this.props.updatePrivateSetting}
                    />
                }

            </div>
        );
    }
}

UserSettingComponent.defaultProps = {
    listConfig: {},
    isEndProcess: true,
    isLoading: false,
};

UserSettingComponent.propTypes = {
    listConfig: PropTypes.object,
    isLoading: PropTypes.bool,
    isEndProcess: PropTypes.bool,
    updatePrivateSetting: PropTypes.func
};


export default translate()(UserSettingComponent);
