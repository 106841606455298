import React, {useState} from "react";
import {Icon, Modal, notification, Select} from "antd";
import {translate} from "react-i18next";
import apiService from "../../apiService";
import {processResponse} from "../../../../systems/api";
import {get, values, isEmpty, map} from "lodash";
import {t} from "../../../../systems/i18n";

const {Option} = Select;

function ChoosePackage(props) {
    let {complaint, getPackages} = props;
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [packageCodes, setPackageCodes] = useState([]);
    const [errors, setErrors] = useState({});
    const [packages, setPackages] = useState([]);

    function fetchPackages() {
        setLoading(true);
        let res = apiService.getPackages(complaint.id, {filterByOrder: 1});
        processResponse(res)
            .then(res => {
                setPackages(values(get(res, "data", {})));
            })
            .catch(() => {
            }).finally(() => setLoading(false));
    }

    function onClick(e) {
        e.preventDefault();
        fetchPackages();
        setVisible(true);
        return false;
    }

    function onOK() {
        setLoading(true);
        let process = processResponse(apiService.choosePackage(complaint.id, {packages: packageCodes}));
        process.then(() => {
            getPackages();
            onCancel();
            notification.success({message: t("complaintSeller:message.choose_package_success")});
        });
        process.catch(error => {
            const code = get(error, "code", undefined);
            if (code === "INPUT_INVALID") {
                setErrors(get(error, "data.packages", {}));
            } else {
                notification.error({message: t("complaintSeller:message.choose_package_fail")});
            }
        }).finally(() => setLoading(false));
    }

    function renderErrors() {
        if (isEmpty(errors))
            return "";
        if (errors.hasOwnProperty("ALREADY_EXIST")) {
            const packagesInfo = get(errors, "ALREADY_EXIST", []);
            return <p className="a--error a-text--fz-12 mt-1">
                {
                    t("complaintSeller:error.error.ALREADY_EXIST", {name: t("complaintSeller:label.package_code") + " " + packagesInfo.join(", ")})
                }
            </p>;
        }
        if (errors.hasOwnProperty("NOT_EXIST")) {
            const packagesInfo = get(errors, "NOT_EXIST", []);
            return <p className="a--error a-text--fz-12 mt-1">
                {
                    t("complaintSeller:error.error.NOT_EXIST", {name: t("complaintSeller:label.package_code") + " " + packagesInfo.join(", ")})
                }
            </p>;
        }
        return "";
    }

    function onCancel() {
        setPackageCodes([]);
        setErrors({});
        setVisible(false);
    }

    function onChange(value) {
        if(value.includes('all')){
            const idsPackage = map(packages, 'code')
            setPackageCodes(packages.length !== packageCodes.length? idsPackage: [] );
        }else{
            setPackageCodes(value);
        }

        setErrors({});
    }


    return (
        <>
            <a
                onClick={onClick}
                className="a-text--fz-14 a-text--uppercase _complaint-seller-detail-button-choose-package"
            >
                {t("complaintSeller:label.choose_package")}
            </a>
            {
                visible &&
                <Modal
                    title={t("complaintSeller:label.choose_package")}
                    visible={visible}
                    onOk={onOK}
                    onCancel={onCancel}
                    cancelText={t("btn.cancel")}
                    okText={t("btn.ok")}
                    width={520}
                    bodyStyle={{height: "120px"}}
                    okButtonProps={{
                        loading: loading,
                        disabled: packageCodes.length === 0,
                        className: "_complaint-seller-detail-choose-package-button-ok"
                    }}
                    cancelButtonProps={{className: "_complaint-seller-detail-choose-package-button-cancel"}}
                >
                    <Select
                        mode="multiple"
                        className={"_complaint-seller-detail-choose-package-package-code"}
                        style={{width: '100%'}}
                        placeholder={t("complaintSeller:placeholder.choose_package")}
                        value={packageCodes}
                        onChange={onChange}
                        allowClear={true}
                        showSearch={true}
                        optionFilterProp="children"
                    >
                        {
                            !isEmpty(packages) &&  <Option key={'all'} value="all" className={
                                packages.length === packageCodes.length? "ant-select-dropdown-menu-item ant-select-dropdown-menu-item-selected" : ""
                            }>
                              {t('complaintSeller:label.select_all')}
                              {/* {packages.length === packageCodes.length && <Icon type="check" /> } */}
                            </Option>
                        }
                        {
                            packages.map(packageInfo => {
                                return <Option key={packageInfo.code}>{packageInfo.code}</Option>
                            })
                        }
                        
                    </Select>
                    {
                        renderErrors()
                    }
                </Modal>
            }
        </>
    );
}

export default translate()(ChoosePackage);