import React from 'react';
import {translate} from 'react-i18next';
import {Alert, Button} from 'antd';
import {TOOL_CRAWL_INVOICE_EVENT} from '../../../modules/SourceInvoice/constans';

class ToolAlert extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            installed: true,
        };

        this.setTimeout = null;

        this.eventListener = this.eventListener.bind(this);
    }

    componentDidMount() {
        window.addEventListener('message', this.eventListener, false);

        window.postMessage({
            type: TOOL_CRAWL_INVOICE_EVENT.PING,
            payload: {},
        });

        this.setTimeout = setTimeout(() => this.setState({installed: false}), 5000);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.eventListener);
    }

    eventListener(message) {
        // We only accept messages from ourselves
        if (message.source != window) {
            return;
        }

        let event = message.data;

        if (event.type == TOOL_CRAWL_INVOICE_EVENT.PONG) {
            clearTimeout(this.setTimeout);
        }
    }

    render() {
        return !this.state.installed
            ? <Alert
                message={
                    <div className="d-flex">
                        {this.props.message ? this.props.message : this.props.t('message.tool_not_install')}
                        <a
                            className="pl-4"
                            target="_blank"
                            href="https://chrome.google.com/webstore/detail/c%C3%B4ng-c%E1%BB%A5-mua-h%C3%A0ng-gobiz/fmknmegefdocamdggpdlcnippgjfelmn">
                            Tải công cụ tại đây
                        </a>
                    </div>
                }
                type="error"
                banner
            />
            : null;
    }
}

export default translate()(ToolAlert);

