import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@atlaskit/button';
import ModalGeneral from './../../../common/components/ModalGeneral';
import FormProcessing from '../../../common/components/FormProcessing';
import FormError from '../../../common/components/FormError';

class FailedComplaintForm extends FormProcessing {
    constructor(props) {
        super(props);

        this.state = {
            input: {
                note_failure: '',
            },
            inputVersion: 0,
            changedParams: [],
        };
    }

    changeInput(input) {
        let result = super.changeInput(input);
        this.props.onChange(input);

        return result;
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.props.onSubmit(this.state.input);
        }
    }

    onCancel() {
        if (!this.props.loading) {
            this.props.onCancel();
        }
    }

    render() {
        let {t, error, loading} = this.props;
        let {input} = this.state;

        return (
            <ModalGeneral>
                <div className={"a-modal__title"}>
                    <p>{t('complaintSeller:failed.form_title')}</p>
                </div>
                <form onSubmit={this.onSubmit.bind(this)}>
                    <div className='a-group'>
                        <label className="label label--required a-label">
                            {t('complaintSeller:failed.reason')}:
                        </label>
                        <textarea
                            rows="4"
                            className="input input--fullwidth mt-2 a-textarea"
                            autoComplete="off"
                            value={input.note_failure || ''}
                            onChange={this.onChangeInput.bind(this, 'note_failure')}
                        />
                        <FormError
                            attribute={t('complaintSeller:failed.reason')}
                            errors={error.note_failure}
                        />
                    </div>
                    <div className="a--group--inline-end mb-3 a--user-group-btn">
                        <Button
                            appearance="primary"
                            type="submit"
                            className="a-btn--submit"
                            isLoading={loading}
                        >{t('btn.submit')}</Button>
                        <Button
                            appearance="subtle"
                            className="a-btn a-btn--none a-btn-link ml-3"
                            isDisabled={loading}
                            onClick={this.onCancel.bind(this)}
                        >{t('btn.cancel')}</Button>
                    </div>
                </form>
            </ModalGeneral>
        );
    }
}

FailedComplaintForm.defaultProps = {
    input: {},
    inputVersion: 0,
    error: {},
    loading: false,
    onChange: input => {},
    onSubmit: input => {},
    onCancel: input => {},
};

FailedComplaintForm.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.any,
    error: PropTypes.object,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
};

export default translate()(FailedComplaintForm);
