export const AUTO_PAYMENT_EVENT = {
    START: 'AUTO_PAYMENT.GET_REQUEST_PAYMENT_FROM_KO',
    DONT_LOGIN_SITE: 'AUTO_PAYMENT.DONT_LOGIN_SITE',
    NO_PIN_CODE: 'AUTO_PAYMENT.NO_PIN_CODE',
    SET_PIN: 'AUTO_PAYMENT.SET_PIN',
    SET_PIN_SUCCESS: 'AUTO_PAYMENT.SET_PIN_SUCCESS',
    CANCEL: 'AUTO_PAYMENT.CANCEL_AUTO_PAYMENT',
    SUCCESS: 'AUTO_PAYMENT.SUCCESS',
    FAILED: 'AUTO_PAYMENT.FAILED',
    CLOSE_PROCESSING_TAB: 'AUTO_PAYMENT.CLOSE_PROCESSING_TAB',
}

export const AUTO_PAYMENT_SYNC_EVENT = {
    DONT_LOGIN_SITE: 'PROCESSED_PAYMENT.DONT_LOGIN_SITE',
    NO_PIN_CODE: 'PROCESSED_PAYMENT.NO_PIN_CODE',
    SET_PIN_SUCCESS: 'PROCESSED_PAYMENT.SET_PIN_SUCCESS',
    FAILED: 'PROCESSED_PAYMENT.FAILED',
    SUCCESS: 'PROCESSED_PAYMENT.SUCCESS',
    CLOSE_PROCESSING_TAB: 'PROCESSED_PAYMENT.CLOSE_PROCESSING_TAB',
}

export const SUCCESS_FROM = ['manual', 'tool']
