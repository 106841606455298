import React, {useState} from 'react';
import {translate} from "react-i18next";
import {Button, Icon, Modal} from "antd";
import DeleteTaobaoPurchaseOrder from "./DeleteTaobaoPurchaseOrder";
import lodash from "lodash";

function ConfirmDeleteSTOrder(props) {
    const {visible, dataOrder, onSubmit, t, setVisible} = props;
    const {id: orderId} = dataOrder;
    const taobaoPurchaseOrder = lodash.get(dataOrder, "taobaoPurchaseOrder", {});

    function handleOk() {
        onSubmit({id: orderId});
        setVisible(false);
    }

    function closeModal() {
        setVisible(false);
    }

    return <Modal
        visible={visible}
        title={<><Icon type="warning" style={{
            fontSize: "18px",
            color: "red"
        }}/> {t("orderDetail:title.change_purchase_address")}</>}
        onCancel={closeModal}
        footer={[
            <Button key="cancel" onClick={closeModal}>{t("btn.cancel")}</Button>,
            <DeleteTaobaoPurchaseOrder orderId={orderId} onSubmit={handleOk}/>
        ]}
        bodyStyle={{border: "unset"}}
    >
        {
            !lodash.isEmpty(taobaoPurchaseOrder) &&
            <div>
                <p
                    style={{color: "#ff6a08", fontWeight: 500}}>Đã tạo đơn
                    ST <b>#{taobaoPurchaseOrder.purchase_id}{` (${t(`order:ORDER_TAOBAO_STATUS.${taobaoPurchaseOrder.purchase_order_status}`)})`}</b>
                </p>
                <p>Bạn có muốn hủy đơn TBG không?</p>
            </div>
        }
    </Modal>
}

export default translate('translations')(ConfirmDeleteSTOrder)