import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {Table, Affix} from 'antd';
import {dateFormatter} from '../../../modules/common/services/format';
import PaginationFull from '../../../modules/common/components/PaginationFull';
import PusherComponent from "../../../modules/common/components/Pusher";
import SettingColumns from '../../../modules/Settings/SettingColumns';
import OrderStatus from '../../../modules/Order/components/OrderStatus';
import ScanningStatus from '../../../modules/Order/components/ScanningStatus';
import {Link} from '../../../systems/routing';
import SyncOrderBill from '../../../modules/OrderBillNo/component/SyncOrderBill/SyncOrderBill';
import SyncOrderBills from "../../../modules/OrderBillNo/component/SyncOrderBillContainer";
import * as GlobalConstant from "../../../systems/globalContant";
import qs from "qs";
import SyncError from "./SyncError";

const COLUMN = {
    ORDER: 'order',
    STATUS: 'status',
    PURCHASER_ACCOUNT: 'purchaser_account',
    DEPOSIT_TIME: 'deposit_time',
    AGENCY: 'agency',
    FREIGHT_BILL: 'freight_bill',
    ITEMS: 'items',
    SHIPPING_ADDRESS: 'shipping_address',
    QUANTITY: 'quantity',
    ACTION: 'action',
    ERROR: 'error',
    PRICE: 'price',
    OPTION: 'option',
    SHIPPING_FEE: 'shipping_fee',
    TELLER: 'teller',
    COMPLAINT_SELLER_ACTIVE: 'complaint_seller_active'
};

class ListOrder extends PusherComponent {
    constructor(props) {
        super(props);
        this.defaultState = {
            selectedColumns: [],
            selectedOrderIds: [],
            syncing: false,
            completeSyncs: []
        };
        this.state = {...this.defaultState};

        this.pageTotalReduced = false;
    }

    getColumns() {
        let {t} = this.props;

        let columns = [
            {
                key: COLUMN.ORDER,
            },
            {
                key: COLUMN.STATUS,
                className: 'a-text--nowrap',
            },
            {
                key: COLUMN.PURCHASER_ACCOUNT,
            },
            {
                key: COLUMN.COMPLAINT_SELLER_ACTIVE,
                className: 'text-center',
                width: 40,
            },
            {
                key: COLUMN.DEPOSIT_TIME,
            },
            {
                key: COLUMN.AGENCY,
                className: 'a-text--nowrap',
            },
            {
                key: COLUMN.FREIGHT_BILL,
                className: 'text-center',
                width: 40,
            },
            {
                key: COLUMN.PRICE,
                className: 'text-center',
                width: 40,
            },
            {
                key: COLUMN.OPTION,
                className: 'text-center',
                width: 40,
            },
            {
                key: COLUMN.QUANTITY,
                className: 'text-center',
                width: 40,
            },
            {
                key: COLUMN.SHIPPING_ADDRESS,
                className: 'text-center',
                width: 40,
            },
            {
                key: COLUMN.SHIPPING_FEE,
                className: 'text-center',
                width: 40,
            },
            {
                key: COLUMN.TELLER,
                className: 'text-center',
                width: 40,
            },
            {
                key: COLUMN.ACTION,
                title: '',
            },
        ];

        return columns.map(column => ({
            dataIndex: column.key,
            title: t('order:scan.column.' + column.key),
            ...column,
        }));
    }
    /**
     * handle hành động nhận message
     * @param text
     * @param id
     * @param channel
     * @private
     */
    _pusherOnMessage(text, id, channel) {
        console.log(channel);
        if (channel.startsWith(GlobalConstant.CHANNELS.ORDER_SYNC_BILL_NO) && this.state.syncing) {
            let response = qs.parse(text, {plaintObject: true});
            let id_order = parseFloat(response.id_order);
            this.props.updateOrders({...response});
            let completedSyncs = [...this.state.completeSyncs];
            if (!lodash.includes(completedSyncs, id_order)) {
                completedSyncs = lodash.concat(this.state.completeSyncs, id_order)
            }
            this.setState({
                ...this.state,
                completeSyncs: completedSyncs
            })
        }
    }
    getSettingColumns() {
        return this.getColumns()
            .filter(column => column.key !== COLUMN.ACTION)
            .map(column => ({
                key: column.key,
                title: column.title,
                selected: true,
            }));
    }

    getTableColumns() {
        return this.getColumns().filter(column => {
            return column.key === COLUMN.ACTION || this.state.selectedColumns.includes(column.key);
        });
    }

    onSync(payload) {
        this.setState({
            ...payload
        })
    }
    getDataSource() {
        let {t, orders} = this.props;
        let {completeSyncs} = this.state;
        return orders.map(order => {
            return {
                key: order.id,
                [COLUMN.ORDER]: <Link href={'/purchase-buying/' + order.id}
                                      className="a-text--nowrap">{order.code}</Link>,
                [COLUMN.STATUS]: <OrderStatus status={order.status}/>,
                [COLUMN.PURCHASER_ACCOUNT]: lodash.get(order, 'account_purchaser.account') || '--',
                [COLUMN.COMPLAINT_SELLER_ACTIVE]: lodash.get(order, 'order_detail.complaint_seller_active', 0),
                [COLUMN.DEPOSIT_TIME]: dateFormatter.dateTime(order.deposited_at),
                [COLUMN.AGENCY]: lodash.get(order, 'agency.name') || '--',
                [COLUMN.FREIGHT_BILL]: <ScanningStatus
                    status={lodash.get(order, 'order_detail.freight_bill_scanning_status')}
                    scanningMessage={t('order:message.freight_bill_not_enough')}
                />,
                [COLUMN.PRICE]: <ScanningStatus
                    status={lodash.get(order, 'order_detail.price_scanning_status')}
                    scanningMessage={t('order:message.price_not_enough')}
                />,
                [COLUMN.OPTION]: <ScanningStatus
                    status={lodash.get(order, 'order_detail.option_scanning_status')}
                    scanningMessage={t('order:message.option_not_enough')}
                />,
                [COLUMN.QUANTITY]: <ScanningStatus
                    status={lodash.get(order, 'order_detail.qty_scanning_status')}
                    scanningMessage={t('order:message.qty_not_enough')}
                />,
                [COLUMN.SHIPPING_ADDRESS]: <ScanningStatus
                    status={lodash.get(order, 'order_detail.address_scanning_status')}
                    scanningMessage={t('order:message.address_not_enough')}
                />,
                [COLUMN.SHIPPING_FEE]: <ScanningStatus
                    status={lodash.get(order, 'order_detail.shipping_fee_scanning_status')}
                    scanningMessage={t('order:message.shipping_fee_not_enough')}
                />,
                [COLUMN.TELLER]: lodash.get(order, 'teller.name') || '--',
                [COLUMN.ACTION]: <SyncOrderBill order={order}
                                                selectedOrder={this.state.selectedOrderIds}
                                                onSync={this.onSync.bind(this)}
                                                syncing={this.state.syncing && lodash.includes(lodash.map(this.getSelectedOrders(), 'id'), order.id) && !lodash.includes(completeSyncs, order.id)}
                />,
                [COLUMN.ERROR]: order.errors && <SyncError errors={order.errors}/>,
            };
        });
    }

    onSelect(selectedOrderIds) {
        this.setState({selectedOrderIds});
    }

    onChangePage(page, pageSize) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            });
        }
    }

    getSelectedOrders() {
        let {selectedOrderIds} = this.state;
        return this.props.orders.filter(order => selectedOrderIds.length ? selectedOrderIds.includes(order.id) : true);
    }

    getNextOrders() {
        if (this.state.selectedOrderIds.length) {
            return null;
        }

        let {page_total, current_page} = this.props.pagination;
        let nextPage = current_page + 1;

        if (current_page >= page_total) {
            if (!this.pageTotalReduced) {
                return;
            }

            nextPage = 1;
            this.pageTotalReduced = false;
        }
    }
    cancel() {
        this.setState({
            syncing: false
        })
    }
    componentDidUpdate() {
        if (this.state.completeSyncs.length >= this.getSelectedOrders().length) {
            this.setState({
                ...this.state,
                syncing: false
            })
        }
    }
    componentWillUnmount() {
        this.setState({...this.defaultState});
    }

    render() {
        let {t, pagination, loading} = this.props;
        let rows = this.getDataSource();
        return (
            <div className="a-list mt-5">
                <Affix>
                    <div className="a-list__top a-flex">
                        <h2 className="a-list--title a-text--uppercase">{t('order:scan.label.list_order')} ({pagination ? pagination.total : 0})</h2>
                        <div className="ml-auto">
                            <SettingColumns
                                storageKey="orders.scan.columns"
                                columns={this.getSettingColumns()}
                                onChange={selectedColumns => this.setState({selectedColumns})}
                            />
                            <SyncOrderBills
                                orders={this.getSelectedOrders()}
                                syncing={this.state.syncing}
                                cancel={this.cancel.bind(this)}
                                onSync={this.onSync.bind(this)}
                                disabled={loading || this.getSelectedOrders().length === 0}
                            />
                        </div>
                    </div>
                </Affix>
                <div className="a-content--is-pagination-fixed">
                    <Table
                        className={"a-table--antd a-table--description"}
                        columns={this.getTableColumns()}
                        dataSource={rows}
                        rowSelection={{
                            selectedRowKeys: this.state.selectedOrderIds,
                            onChange: this.onSelect.bind(this),
                            columnWidth: '20px',
                        }}
                        loading={loading}
                        scroll={{x: 'fit-content'}}
                        pagination={false}
                        expandedRowKeys={rows.filter(row => row[COLUMN.ERROR]).map(row => row.key)}
                        expandedRowRender={row => row[COLUMN.ERROR] &&
                            <div className="a-text--red pb-2">{row[COLUMN.ERROR]}</div>}
                        expandIcon={() => null}
                        expandIconAsCell={false}
                    />
                    <PaginationFull
                        page={lodash.toInteger(pagination.current_page) || 1}
                        pageSize={lodash.toInteger(pagination.per_page)}
                        total={pagination.total}
                        disabled={!this.props.canSearch}
                        pageSizeOptions={[30, 50, 80, 100]}
                        onChange={this.onChangePage.bind(this)}
                        className="a-pagination a-pagination--fixed"
                    />
                </div>
            </div>
        )
    }
}

ListOrder.defaultProps = {
    filter: {},
    loading: false,
    canSearch: true,
    orders: [],
    pagination: {},
    onSearch: (filter) => {
    },
    fetchOrders: (filter) => {
    },
};

ListOrder.propTypes = {
    filter: PropTypes.object,
    loading: PropTypes.bool,
    canSearch: PropTypes.bool,
    orders: PropTypes.array,
    pagination: PropTypes.object,
    onSearch: PropTypes.func,
    fetchOrders: PropTypes.func,
};

export default translate()(ListOrder);
