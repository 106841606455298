import React from 'react';
import {Icon, Row, Col, Input, Modal, Button} from "antd";
import InlineEditInput from './InlineEditInput';
import lodash from 'lodash';
import ResolvingTransaction from '../../ResolvingTransaction';
import {permissions} from '../../../Permission';
import authService from '../../../../modules/Auth/authService';
import {trans} from "../../../../systems/i18n";
import MarkTransaction from "../../MarkTransaction";
import RelatedTransaction from "../../RelatedTransaction";


const TextArea = Input.TextArea;

function confirm() {
    Modal.confirm({
        className: "a-modal--confirm-trade",
        title: 'UNRESOLVE',
        content: 'Bạn có chắc chắn muốn Unresolve đơn này ?',
        cancelText: 'Hủy',
        okText: 'Unresolve',
        zIndex: 1050,
        icon: <Icon type="question-circle" theme="filled"/>,
    });
}


class InfoDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    handleDownload(id, filename) {
        this.props.downloadFile(id, filename);
    }

    render() {
        /* eslint-disable */
        let {transaction} = this.props;
        let amount_class;
        switch (transaction.collect_spend) {
            case 'COLLECT':
                amount_class = 'a-text--success';
                break;
            case 'SPEND':
                amount_class = 'a-text--red';
                break;
            default:
                amount_class = '';
        }
        let collapse = this.props.collapse;
        let downloadPermission = authService.can(permissions.TRANSACTION_DOWNLOAD_FILE);
        return (
            <React.Fragment>

                <div className="a-block a-block--notify-heading a-block--notify-success a-block--info-transaction ">
                    <div className="a-block__top a-block__top--info-transaction d-flex justify-content-between">
                        <div>
                            <h3 className="a-block__top__title">{!transaction.hasOwnProperty('code') ? '--' : transaction.code !== '' ? transaction.code : '--'}</h3>
                            <ResolvingTransaction transaction={transaction}/>
                            <MarkTransaction transaction={transaction}/>
                        </div>
                        {transaction.status && <span className="a-alert a-alert__dotted a-alert__dotted--warning">{transaction.status}</span> }
                    </div>
                    <div className="a-block__body">
                        <Row gutter={{lg: 24, xl: 32}}>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.amount_format')} </label>
                                <span className={"a-form__group__value" + (transaction.amountColor)}>{transaction.amount_format ? transaction.amount_format : '--'}</span>
                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.type')} </label>
                                <span className="a-form__group__value">{transaction.type_format ? transaction.type_format : '--'}</span>
                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.service_fee')} </label>
                                <span className="a-form__group__value">{transaction.service_fee_format ? transaction.service_fee_format : '--'}</span>
                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.amount_refund')} </label>
                                <span className="a-form__group__value">{transaction.amount_refund_format ? transaction.amount_refund_format : '--'} </span>
                            </Col>
                        </Row>
                        <span className={`a-block__body__collapse line-wrap ${collapse ? 'hidden' : ''}`}></span>
                        <Row gutter={{lg: 24, xl: 32}} className={`a-block__body__collapse ${collapse ? 'hidden' :''}` }>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.account')} </label>
                                <span className="a-form__group__value">{transaction.hasOwnProperty('account') ? transaction.account : '--'}</span>
                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.system')} </label>
                                {transaction.system !== ''
                                    ? <span className="a-form__group__value"><span className={"img-logo-partner _" + transaction.system}></span>{lodash.upperFirst(transaction.system)}</span>
                                    : <span className="a-form__group__value">--</span>}
                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.seller_source')} </label>
                                {(transaction.hasOwnProperty('seller_source') && transaction.seller_source.length > 0)
                                    ? transaction.seller_source.map(seller_source => <span className="a-form__group__value"><span className={"img-logo-partner _" + seller_source}></span>{lodash.upperFirst(seller_source)}</span>) : <span className="a-form__group__value">--</span>
                                }

                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.shop')} </label>
                                <span className="a-form__group__value">{!transaction.hasOwnProperty('shop') ? '--' : transaction.shop !== '' ? transaction.shop : '--'}  </span>
                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.code_provider')} </label>
                                <span className="a-form__group__value a-text--break-all">{!transaction.hasOwnProperty('code_provider') ? '--' : transaction.code_provider !== '' ? transaction.code_provider : '--'} </span>
                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.production')} </label>
                                <span className="a-form__group__value">{!transaction.hasOwnProperty('production') ? '--' : (transaction.production !== '' && transaction.production !== null) ? transaction.production : '--'} </span>
                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.cash_flow')} </label>
                                <span className="a-form__group__value">{!transaction.hasOwnProperty('cash_flow_format') ? '--' : transaction.cash_flow_format !== '' ? transaction.cash_flow_format : '--'} </span>
                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.file_creator')} </label>
                                <span className="a-form__group__value">{!transaction.hasOwnProperty('file_creator') ? '--' : transaction.file_creator !== '' ? transaction.file_creator : '--'} </span>
                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.transaction_time')} </label>
                                <span className="a-form__group__value">{transaction.transaction_time_format ? transaction.transaction_time_format : '--'} </span>
                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.transaction_time_create')} </label>
                                <span className="a-form__group__value">{transaction.transaction_time_create_format ? transaction.transaction_time_create_format : '--'} </span>
                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group">
                                <label className="a-form__group__label">{trans('transaction:label.created_at_format')} </label>
                                <span className="a-form__group__value">{transaction.created_at_format ? transaction.created_at_format : '--'} </span>
                            </Col>
                            <Col md={12} lg={6} xl={6} className="a-form__group clear-both">
                                <label className="a-form__group__label">{trans('transaction:label.filename_detail')} </label>
                                <span className="a-form__group__value">{downloadPermission ? <a onClick={this.handleDownload.bind(this, lodash.get(transaction, 'fileId', 0), lodash.get(transaction, 'filename', ''))} className="a-text--color-primary a-text--underline">{transaction.hasOwnProperty('filename') ? transaction.filename : '--'}</a> : (transaction.hasOwnProperty('filename') ? transaction.filename : '--')}</span>
                            </Col>
                            <Col md={18} lg={18} xl={18} className="a-form__group">
                                <InlineEditInput
                                    {...this.props}
                                    inputType='textarea' label="Ghi chú "
                                    value={transaction.note}/>
                            </Col>
                            <Col md={24} lg={24} xl={24} className="a-form__group">
                                <label className="a-form__group__label">Mã hóa đơn gốc</label>
                                <span className="a-form__group__value">
                                    {
                                        transaction.transaction_invoice_code &&
                                        transaction.transaction_invoice_code.map((invoice, index) => (
                                            invoice.order ?
                                                <React.Fragment>
                                                    <a
                                                        href={lodash.get(invoice, 'link_invoice_code', '')}
                                                        target={"_blank"}
                                                    >
                                                        {lodash.get(invoice, 'invoice_code', '')}
                                                    </a>
                                                    {
                                                        transaction.transaction_invoice_code.length > 1 &&
                                                        (index !== transaction.transaction_invoice_code.length - 1) &&
                                                        <span className="mr-1">,</span>
                                                    }
                                                </React.Fragment>
                                                 :
                                                <span>
                                                    {lodash.get(invoice, 'invoice_code', '')}
                                                    {
                                                        transaction.transaction_invoice_code.length > 1 &&
                                                    (index !== transaction.transaction_invoice_code.length - 1) &&
                                                        <span className="mr-1">,</span>}
                                                </span>
                                        ))
                                    }
                                </span>


                            </Col>
                            <Col md={24} lg={24} xl={24} className="a-form__group">
                                <label className="a-form__group__label">Ghi chú GD lúc tạo</label>
                                <span className="a-form__group__value">{transaction.note_transaction} </span>
                            </Col>
                            <Col md={24} lg={24} xl={24} className="a-form__group">
                                <label className="a-form__group__label">Giao dịch liên quan</label>
                                <RelatedTransaction transactionId={transaction.id} transaction={transaction} />
                            </Col>
                        </Row>
                    </div>
                </div>

                <Modal
                    width={360}
                    title="RESOLVE"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={false}
                    centered={true}
                    className={"a-modal--list-trade"}
                >
                    <span>Lý do</span>
                    <TextArea placeholder="Nhập lý do" rows={"3"} className="mt-1" autosize={{minRows: 3, maxRows: 3}}/>
                    <div className={"amt-24 a-text--right"}>
                        <Button className=" a-font--12 a-btn--default-ant  ">Huỷ</Button>
                        <Button className=" a-font--12 a-btn--primary-ant aphz-24 ml-3">Resolve</Button>
                    </div>
                </Modal>

            </React.Fragment>
        );
    }
}

export default InfoDetail;
