import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from './actions';
import OrderCode from './components';

const mapStateToProps = (state, props) => {
    return {
        canUpdateInvoiceCode: props.canUpdateInvoiceCode,
        canDeleteInvoiceCode: props.canDeleteInvoiceCode,
        invoiceCodes: props.invoiceCodes,
        orders: props.orders,
        id: props.id,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setCurrentTransaction: (datas) => dispatch(actions.setCurrentTransaction(datas)),
    clearState: () => dispatch(actions.clearState()),
});

class OrderCodeWapper extends React.PureComponent {
    render() {
        return <OrderCode {...this.props}/>
    }
}

let OrderCodeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderCodeWapper);

OrderCodeContainer.defaultProps = {
    invoiceCodes: [],
    orders: [],
    id: 0,
    canUpdateInvoiceCode: false,
    canDeleteInvoiceCode: false
};

OrderCodeContainer.propTypes = {
    canUpdateInvoiceCode: PropTypes.bool,
    canDeleteInvoiceCode: PropTypes.bool,
    invoiceCodes: PropTypes.array,
    orders: PropTypes.array,
    id: PropTypes.number,
};

export default OrderCodeContainer;
