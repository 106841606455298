import React from 'react';
import { translate } from 'react-i18next';
import { Layout, notification } from 'antd';
import config from '../../config/app';
import apiService from './apiService';
import { processResponse } from '../../systems/api';
import authService from '../../modules/Auth/authService';
import * as PERMISSION from '../../modules/Permission/permissions';

const { Content } = Layout;

class PerformanceReport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			srcBI: '',
		};
		this.getAuthToken = this.getAuthToken.bind(this);
	}

	componentWillMount() {
		const { t, setPageTitle, setBreadCrumb } = this.props;
		setPageTitle(t('layout.title.performance_report'))
		setBreadCrumb([
			{
				label: 'breadcrumb.home',
				link: '/',
			},
			{
				label: 'breadcrumb.performance_report',
				link: '/performance-report/',
			},
		]);
	}

	componentDidMount() {
		this.getAuthToken();
	}

	getAuthToken() {
		if (!authService.can(PERMISSION.SYSTEM_ADMIN)) {
			return;
		}
		const { t } = this.props;
		this.setState({ loading: true });

		processResponse(apiService.getAuthToken())
			.then((res) => {
				const { token } = res.data;
				const srcBI = `${config.biUrl}?params={"1stPartner":"${token}","2ndPartner":"${token}"}`;
				this.setState({ srcBI });
			})
			.catch(() => notification.error({ message: t('message.cant_fetch_data') }))
			.finally(() => this.setState({ loading: false }));
	}

	render() {
		const { loading, srcBI } = this.state;
		return (
			<Layout>
				{loading && !srcBI ? (
					<div className='a-loading__page'>
						<div className='a-loading__page__icon'></div>
					</div>
				) : (
					<Content className='a-content--ant-layout new-list-orders'>
						<iframe style={{ width: '100%', minHeight: '100vh' }} src={srcBI} />
					</Content>
				)}
			</Layout>
		);
	}
}

export default translate('translations')(PerformanceReport);
