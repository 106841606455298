import React, {useState} from "react";
import {Button, notification, Icon} from "antd";
import {t} from "../../../../../systems/i18n";
import apiService from "../../apiService";
import lodash from "lodash";
import CanExport from "./CanExport";

export default function CheckExport({dataOrder, updateItemsOrderDetail}) {
    const [loading, setLoading] = useState(false);
    const {orderDetail} = dataOrder;
    const [can_exported, setCanExported] = useState(lodash.get(orderDetail, "can_exported", null));
    function onClick() {
        setLoading(true);
        apiService.canExported(dataOrder.id)
            .then(res => {
                let data = lodash.get(res, "data", {});
                let orderDetail = lodash.get(data, "data.orderDetail", {});
                updateItemsOrderDetail(data);
                let can_exported = lodash.get(orderDetail, "can_exported", null);
                setCanExported(can_exported);
                if (can_exported === 1)
                    notification.success({message: t("orderDetail:can_exported.suitable", {attribute: t("orderDetail:can_exported.order")})});
                else
                    notification.success({message: t("orderDetail:can_exported.not_suitable", {attribute: t("orderDetail:can_exported.order")})});
            })
            .catch(err => {
                notification.success({message: t("orderDetail:can_exported.fail")});
            })
            .finally(() => setLoading(false));
    }

    // if (!dataOrder || !dataOrder.canCheckExported)
    //     return null;

    return (
        <>
            {
                dataOrder.canCheckExported &&
                <Button
                    size="small"
                    className="a-radius-50 pl-3 ml-2 d-flex align-item-center"
                    style={{paddingTop: "2px", paddingRight: "0.75rem"}}
                    onClick={onClick}
                    >
                    {loading && <Icon type="loading"/>}
                    {t("orderDetail:order_detail.btn_check_export")}
                </Button>
            }
            <CanExport
                can={can_exported}
                showText={true}
                type="order"
            />
        </>
    );
}
