import {call, put} from "@redux-saga/core/effects";
import service from "./apiService";
import {processApiRequest} from '../../common/saga'
import * as Constants from "./constants";
import bootbox from "../../common/components/Bootbox/bootbox";
import {t} from "../../../systems/i18n";



export function* getTransactionDetail(action) {
    yield processApiRequest(
        Constants.GET_TRANSACTION_DETAIL,
        () => service.getTransactionDetail(action.payload.id),
        action.payload
    );
}
export function* getTransactionDetailSuccess(action) {
    // console.log('success');
}
export function* getTransactionDetailFailed(action) {
    bootbox.error(t('message.system_errors'));
}
