import React from 'react';
import {connect} from 'react-redux';
import ListOrder from './components';
import * as Selector from './../selectors';
import * as UserSelector from './../../User/selectors';
import * as PurchaseAddressSelector from './../../PurchaseAddress/selectors';
import {fetchOrders} from "../actions";
import {
    initAssignPurchaserOrders,
    finishAssignPurchaserOrders,
    assignPurchaserOrders,
    assignPurchaser,
    unassignPurchaser
} from '../DistributeOrder/actions';
import SearchableComponent from "../../common/SearchableComponent";
import lodash from "lodash";
import {getListAccountPurchaserAction} from "../../AccountPurchaser/actions";
import {getPurchaseAddressList} from "../../PurchaseAddress/actions";
import {getPurcharsers} from "../../User/actions";
import {getListAgencyAction} from "./../../Agency/actions";
import * as AgencySelector from "./../../Agency/selectors";

import {
    setPageTitle,
    setBreadCrumb,
    setPageDescription,
    setMenuActive,
    pusherCreateNewOrder,
    clearCreateNewOrder
} from './../../common/actions';
import {ORDER_STATUS, ORDER_TIME_TYPE} from "../../../systems/globalContant/actionContant";


import {
    confirmCustomerOrderBuying
} from './../../PurchaseBuying/OrderDetail/actions';

const mapStateToProps = (state) => {
    return {
        orders: Selector.getOrders(state),
        orderCount: Selector.getState(state, 'count'),
        purchasers: UserSelector.purchasersSelector(state),
        purchaserOption: UserSelector.purchaserOption(state),
        stats: Selector.getStats(state),
        accountPurchase: Selector.getAccountPurchase(state),
        loading: lodash.get(state, "Order.ListOrder.loading", false),
        partner: lodash.get(state, "Order.ListOrder.partner", {id: 0, code: "", domain: ""}),
        pagination: lodash.get(state, "Order.ListOrder.pagination", {
            current_page: 1,
            page_total: 1,
            per_page: 20,
            total: 0
        }),
        distributeOrderStatus: state.Order.DistributeOrder.status,
        purchaseAddresses: PurchaseAddressSelector.getListPurchaserAddressOptions(state),
        countWaitingBuyOrder: lodash.get(state, "Common.countWaitingBuyOrder", 0),
        agencies: AgencySelector.getListAgency(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearCreateNewOrder: () => {
            dispatch(clearCreateNewOrder());
        },
        pusherCreateNewOrder: (orderData) => {
            dispatch(pusherCreateNewOrder(orderData));
        },
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        getPurcharsers: (filters) => {
            filters.scope = 'basic';
            dispatch(getPurcharsers(filters))
        },
        fetchOrders: (filter) => {
            if( lodash.isEmpty( filter )){
                filter = {...filter,...{status:ORDER_STATUS.WAITING_BUY, time_type:ORDER_TIME_TYPE.DEPOSITED}
            }}
            dispatch(fetchOrders({...filter, fetch_stats: true}));
        },
        onAssignPurchaser: (orderId, purchaserId) => {
            dispatch(assignPurchaser(orderId, purchaserId));
        },
        onUnassignPurchaser: (orderId) => {
            dispatch(unassignPurchaser(orderId));
        },
        onOpenFormAssignPurchaser: () => {
            dispatch(initAssignPurchaserOrders());
        },
        onCloseFormAssignPurchaser: () => {
            dispatch(finishAssignPurchaserOrders());
        },
        onAssignPurchaserOrders: (orderIds, purchaserId) => {
            dispatch(assignPurchaserOrders(orderIds, purchaserId));
        },
        fetchAccountPurchase: (filter = {}) => {
            filter.scope = 'basic';
            dispatch(getListAccountPurchaserAction({...filter, status: 'ACTIVE'}));
        },
        onConfirmCustomerOrderBuying: (params) => {
            dispatch(confirmCustomerOrderBuying(params));
        },
        getPurchaseAddressList: (filter) => {
            filter.scope = 'basic';
            dispatch(getPurchaseAddressList(filter));
        },
        getListAgency: (filter) => {
            dispatch(getListAgencyAction(filter));
        },
    };
};

class ListOrderContainer extends SearchableComponent {
    componentDidMount() {
        super.componentDidMount();
        this.props.fetchAccountPurchase();
    }

    onChangeFilter(filter) {
        this.props.fetchOrders(filter);
    }

    onSearch(filter) {
        // Nếu search theo code thì reset filter status
        filter = lodash.mapValues(filter, (value, param) => {
            return (param === 'status' && filter.code) ? ORDER_STATUS.ALL : value;
        });

        this.pushFilter({
            ...filter,
            _i: parseInt(filter._i || 0, 0) + 1,
        });
    }

    getCurrentFilter() {
        return {
            status: ORDER_STATUS.WAITING_BUY,
            time_type:ORDER_TIME_TYPE.DEPOSITED,
            code: '',
            buyer_username: '',
            id_purchaser: [],
            id_teller: [],
            seller_source: [],
            total_item_link: '',
            real_amount_compare: '',
            grand_total_to: '',
            grand_total_from: '',
            log_status_to: '',
            log_status_from: '',
            wrong_process: '',
            operation: '',
            sort: 'asc',
            id_purchase_address: [],
            id_agency: [],
            ...this.getFilterFromLocation(this.props.location),
        };
    }

    render() {
        let filter = this.getCurrentFilter();

        return <ListOrder {...{
            ...this.props,
            filter: filter,
            filterVersion: parseInt(filter._i, 0),
            onSearch: this.onSearch.bind(this),
        }}/>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListOrderContainer)
