import React from 'react'
import { connect } from 'react-redux'
import ListTransaction from './components'
import * as Selector from './selectors'
import { clearState, getListTransaction, resetError, takeErrors, updateTransaction } from './actions'
import SearchableComponent from '../../common/SearchableComponent'
import lodash from 'lodash'
import { setBreadCrumb, setMenuActive, setPageDescription, setPageTitle } from './../../common/actions'
import { withEvent } from '../../../systems/events'
import TRANSACTION_EVENT from '../events'
import { downloadFile } from '../ListTransactionFile/actions'
import moment from 'moment'

const mapStateToProps = (state) => {
    return {
        dateRange: lodash.get(state, 'Transaction.ListTransaction.dateRange', {}),
        errors: Selector.getErrors(lodash.get(state, "Transaction.ListTransaction.errors", {})),
        transactions: Selector.getListTransactions(state),
        unusualTypes: Selector.getUnusualTypes(lodash.get(state, "Transaction.ListTransaction.unusualTypes", {})),
        loading: lodash.get(state, 'User.loading.detail', false) || lodash.get(state, 'Transaction.ListTransaction.loading', false),
        pagination: Selector.pagination(state),
        currentTransaction: lodash.get(state, 'Transaction.OrderCode.currentTransaction', {
            idTransaction: 0,
            showModal: false,
            invoiceCodes: []
        }),
        permission: Selector.permission(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        fetchTransaction: (filter) => {
            dispatch(getListTransaction(filter));
        },
        updateTransaction: (id, data) => {
            dispatch(updateTransaction(id, data));
        },
        takeErrors: (data) => {
            dispatch(takeErrors(data));
        },
        downloadFile: (id, filename) => {
            dispatch(downloadFile({id: id, filename: filename}));
        },
        clearState: () => dispatch(clearState()),
        resetError: (params) => dispatch(resetError(params)),
    };
};
const currentDay = moment().format('YYYY-MM-DD')
const threeMonthsAgo = moment().subtract(3, 'M').format('YYYY-MM-DD')
class ListTransactionContainer extends SearchableComponent {
    constructor(props) {
        super(props);
        this.defaultFilter = {
            time_from: threeMonthsAgo,
            time_to: currentDay,
        };

        this.state = {
            input: {...this.defaultFilter},
        };
    }
    componentDidMount() {
        super.componentDidMount();
        let updateTransactionFc = (event, payload) => this.props.updateTransaction(payload.transaction.id, payload.transaction)
        this.props.on({
            [TRANSACTION_EVENT.RESOLVED]: updateTransactionFc,
            [TRANSACTION_EVENT.UNRESOLVED]: updateTransactionFc,
            [TRANSACTION_EVENT.MARKED]: updateTransactionFc,
            [TRANSACTION_EVENT.UNMARKED]: updateTransactionFc,
            [TRANSACTION_EVENT.ADD_RELATED_TRANSACTION]: updateTransactionFc,
            [TRANSACTION_EVENT.DELETE_RELATED_TRANSACTION]: updateTransactionFc,
            [TRANSACTION_EVENT.EXPORT_FAILED]: (event, payload) => this.props.takeErrors(payload),
        });
    }

    onChangeFilter(filter) {
        let editedFilter = { ...filter }
        if (!lodash.isEmpty(filter.code)) {
            editedFilter = {
                ...editedFilter,
                time_from: undefined,
                time_to: undefined,
            }
        }
        this.props.fetchTransaction(editedFilter);
    }

    onSearch(filter) {
        this.pushFilter({
            ...this.defaultFilter,
            ...filter,
            _i: parseInt(filter._i || 0, 0) + 1,
            id_transaction_file: filter.id_transaction_file ? parseInt(filter.id_transaction_file) : ''
        });
    }

    getCurrentFilter() {
        return {
            ...this.defaultFilter,
            ...this.getFilterFromLocation(this.props.location),
        };
    }
    setFilterCSV(filter) {
        this.setState({
            ...this.state,
            input : {
                ...this.state.input,
                ...filter
            }
        })
    }
    render() {
        let filter = this.getCurrentFilter();
        return <ListTransaction {...{
            ...this.props,
            filter: filter,
            csvFilter: {...this.state.input},
            downloadFile: this.props.downloadFile.bind(this),
            setFilterCSV: this.setFilterCSV.bind(this),
            filterVersion: parseInt(filter._i, 0),
            onSearch: this.onSearch.bind(this),
        }}/>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withEvent(ListTransactionContainer))
