import {combineReducers} from 'redux';
import * as Constant from './../../Order/constants'
import lodash from 'lodash';
import {updateCollectionItem} from "../../common/helps";

const list = (state = [], action) => {
    switch (action.type) {
        case Constant.FETCH_TRANSACTION_ORDERS.SUCCESS:
            return [...lodash.get(action, "payload.data.orders", [])];
        case Constant.UPDATE_TRANSACTION_ORDERS:
            return updateCollectionItem(state, action.payload.id, action.payload.data, 'id');
        case Constant.FETCH_TRANSACTION_ORDERS.REQUEST:
        case Constant.FETCH_TRANSACTION_ORDERS_CLEAR_STATE:
            return [];
        default:
            return state;
    }
};
const problemTypes = (state = {}, action) => {
    switch (action.type) {
        case Constant.FETCH_TRANSACTION_ORDERS.SUCCESS:
            return {...lodash.get(action, "payload.data.problemTypes", {})};
        case Constant.FETCH_TRANSACTION_ORDERS.REQUEST:
        case Constant.FETCH_TRANSACTION_ORDERS_CLEAR_STATE:
            return {};
        default:
            return state;
    }
};
const listScanningStatus = (state = [], action) => {
    switch (action.type) {
        case Constant.FETCH_TRANSACTION_ORDERS.SUCCESS:
            return [...lodash.get(action, "payload.data.listScanningStatus", [])];
        case Constant.FETCH_TRANSACTION_ORDERS.REQUEST:
        case Constant.FETCH_TRANSACTION_ORDERS_CLEAR_STATE:
            return [];
        default:
            return state;
    }
};
const listStatus = (state = [], action) => {
    switch (action.type) {
        case Constant.FETCH_TRANSACTION_ORDERS.SUCCESS:
            return [...lodash.get(action, "payload.data.listStatus", [])];
        case Constant.FETCH_TRANSACTION_ORDERS.REQUEST:
        case Constant.FETCH_TRANSACTION_ORDERS_CLEAR_STATE:
            return [];
        default:
            return state;
    }
};
const loading = (state = false, action) => {
    switch (action.type) {
        case Constant.FETCH_TRANSACTION_ORDERS.SUCCESS:
        case Constant.FETCH_TRANSACTION_ORDERS.FAILED:
        case Constant.FETCH_TRANSACTION_ORDERS_CLEAR_STATE:
            return false;
        case Constant.FETCH_TRANSACTION_ORDERS.REQUEST:
            return true;
        default:
            return state;
    }
};

const pagination = (state = {}, action) => {
    switch (action.type) {
        case Constant.FETCH_TRANSACTION_ORDERS.SUCCESS:
            return {...lodash.get(action, "payload.data.pagination", {})};
        case Constant.FETCH_TRANSACTION_ORDERS.REQUEST:
        case Constant.FETCH_TRANSACTION_ORDERS_CLEAR_STATE:
            return {};
        default:
            return state;
    }
};
const filters = (state = {}, action) => {
    switch (action.type) {
        case Constant.FETCH_TRANSACTION_ORDERS.SUCCESS:
            return {...lodash.get(action, "payload.data.filters", {})};
        case Constant.FETCH_TRANSACTION_ORDERS.REQUEST:
        case Constant.FETCH_TRANSACTION_ORDERS_CLEAR_STATE:
            return {};
        default:
            return state;
    }
};

export default combineReducers({
    list,
    loading,
    pagination,
    listStatus,
    filters,
    problemTypes,
    listScanningStatus,
});
