import React from "react";
import PropTypes from "prop-types";
import {Button, notification, Icon} from "antd";

const CARESOFT_STATUS = {
    GOBIZ_CALLING: {
        status: "CALLING",
        message: "Đang gọi..."
    },
    GOBIZ_CUSTOMER_ACCEPT: {
        status: "CUSTOMER_ACCEPT",
        message: "Đang trả lời..."
    },
    GOBIZ_END_CALL: {
        status: "END_CALL",
        message: "Cuộc gọi kết thúc..."
    },
    GOBIZ_IPCC_NOT_CONNECT_CUSTOMER: {
        status: "IPCC_NOT_CONNECT_CUSTOMER",
        message: "Không kết nối được đến số thuê bao"
    },
    GOBIZ_486: {
        status: "486",
        message: "Khách hàng đang bận"
    },
    GOBIZ_408: {
        status: "408",
        message: "Khách hàng không nghe máy hoặc có lỗi xảy ra"
    },
    GOBIZ_403: {
        status: "403",
        message: "Đầu số này chưa được thanh toán cước"
    },
    GOBIZ_487: {
        status: "403",
        message: "Khách hàng không nghe máy"
    },
    GOBIZ_480: {
        status: "480",
        message: "Thuê bao khách hàng tạm thời không liên lạc được"
    },
    GOBIZ_IPCC_NOT_CONNECT_AGENT_ID: {
        status: "IPCC_NOT_CONNECT_AGENT_ID",
        message: "Không thể kết nối được đến thiết bị"
    },
    GOBIZ_CALLOUT_AGENT_BUSY: {
        status: "CALLOUT_AGENT_BUSY",
        message: "Mã trả về 786 - Busy"
    },
    GOBIZ_CALLOUT_PERMISSION_DENY: {
        status: "CALLOUT_AGENT_BUSY",
        message: "Không có quyền gọi ra. Liên hệ Admin để cấu hình"
    },
    GOBIZ_ERROR_CALLOUT_CONNECT: {
        status: "ERROR_CALLOUT_CONNECT",
        message: "Có lỗi xảy ra"
    },
    GOBIZ_ORTHER_ERROR: {
        status: "ORTHER_ERROR",
        message: "Có lỗi xảy ra"
    }
};

class CallByCaresoft extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            status: ""
        }
    }

    onClick = (e) => {
        let {phone} = this.props;

        window.csCallout(phone);
        this.setState({
            isShow: true,
            status: CARESOFT_STATUS.GOBIZ_CALLING
        });

        return false;
    }

    componentDidMount() {
        window.addEventListener("message", this.handleMessage);
    }

    handleMessage = message => {
        let payload = message.data;

        switch (payload.type) {
            case window.CARESOFT_EVENTS.CARESOFT_ERROR:
                this.setState({
                    status: CARESOFT_STATUS["GOBIZ_"+payload.error_code] || CARESOFT_STATUS.GOBIZ_ORTHER_ERROR
                });
                break;
            case window.CARESOFT_EVENTS.CARESOFT_CUSTOMER_ACCEPT:
                this.setState({
                    status: CARESOFT_STATUS.GOBIZ_CUSTOMER_ACCEPT
                });
                break;
            case window.CARESOFT_EVENTS.CARESOFT_END_CALL:
                this.setState({
                    status: CARESOFT_STATUS.GOBIZ_END_CALL
                });
                break;
            default:
                break;
        }
    }

    endCall = () => {
        window.endCall();
        this.setState({
            isShow: false,
            status: ""
        });
    }

    render() {
        let {phone} = this.props;
        let {isShow, status} = this.state;

        return (
            <div>
                <a onClick={this.onClick}>{phone}</a>
                <div className="caresoft_box" style={{display: isShow ? "block" : "none"}}>
                    <div className="d-flex align-item-center">
                        <Button
                            type="danger"
                            shape="round"
                            icon="close"
                            onClick={this.endCall}
                        />
                        <p className="ml-2 a-text--fz-16">
                            { status.message }
                            { status.status === CARESOFT_STATUS.GOBIZ_CALLING.status && <strong>{phone}</strong> }
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

CallByCaresoft.defaultProps = {
    phone: "",
    careSoftToken: "",
    caresoftDomain: ""
}

CallByCaresoft.propTypes = {
    phone: PropTypes.string,
    careSoftToken: PropTypes.string,
    caresoftDomain: ""
}

export default CallByCaresoft;