import React from "react";
import {Empty, Table} from "antd";
import {translate} from "react-i18next";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as Selector from "../../../UpdateFinanliOrder/selectors";

class ListTransaction extends React.Component{

    getColumn() {
        let {t} = this.props;
        return [
            {
                title: t("transaction:column.transaction_time_format"),
                dataIndex: 'transaction_time_format',
                key: 'transaction_time_format',
                className: "_transaction-time"
            },
            {
                title: t("transaction:column.code"),
                dataIndex: 'code_format',
                key: 'code_format',
                className: "_transaction-code"
            },
            {
                title: t("transaction:column.type_format"),
                dataIndex: 'type_format',
                key: 'type_format',
                className: "_transaction-type"
            },
            {
                title: t("transaction:column.amount_format"),
                dataIndex: 'amount_format',
                key: 'amount_format',
                className: 'text-right _transaction-amount',
                render: (text, record) => <span className={record.collect_spend === 'COLLECT' ? 'a-text--color-primary' : record.collect_spend === 'SPEND' ? 'a-text--color-warning' : ''}>{text}</span>
            }
        ]
    }


    render() {
        let {t, transactions} = this.props;
        return <>
                <p className="mb-2 mt-4 a-text--w-medium a-text--fz-14 a-text--color-black-85">{t("transaction:label.list_transaction")}</p>
                <Table
                    className={"a-table--antd a-table--small-row"} columns={this.getColumn()} dataSource={transactions}
                    scroll={{x: 'fit-content'}}
                    pagination={false}
                    locale={{
                    emptyText: (
                       <Empty
                           image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                           description={<span>{t("transaction:label.no_data")}</span>}
                       >
                       </Empty>)
                }}/>
            </>;
    }
}

ListTransaction.defaultProps = {
    dataOrder: {}
};

ListTransaction.protoType = {
    dataOrder: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        transactions: Selector.getListTransactions(state, props)
    }
}

export default connect(mapStateToProps, null)(translate()(ListTransaction));