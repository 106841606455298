import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { translate } from 'react-i18next';
import { Modal, Spin, Empty, notification } from 'antd';
import { dateFormatter } from '../../../modules/common/services/format';
import apiService from '../apiService';
import { trans } from '../../../systems/i18n';
import { processResponse } from '../../../systems/api';
const INVOICE_APPROVAL_ACTION = {
	CREATE: 'ORDER_SOURCE_INVOICE_APPROVAL.CREATE',
	CHANGE_STATE: 'ORDER_SOURCE_INVOICE_APPROVAL.CHANGE_STATE',
};
const InvoiceCodeChangeMessage = (props) => {
	const { t, id, show, handleShowLog } = props;
	const [loading, setLoading] = useState(false);
	const [logs, setLogs] = useState([]);
	const thisRef = useRef(null);
	useEffect(() => {
		thisRef.current = true;
		return () => (thisRef.current = false);
	}, []);
	useEffect(() => {
		if (!id) return;
		setLoading(true);
		const res = apiService.getLogsInvoiceCodeChange(id).finally(() => {
			if (thisRef.current) {
				setLoading(false);
			}
		});
		processResponse(res)
			.then((res) => {
				if (thisRef.current) {
					setLogs(lodash.get(res, 'data', []));
					setLoading(false);
				}
			})
			.catch(() => {
				notification.error(t('message.cant_fetch_data'));
			});
	}, [id]);
	function logItem(log) {
		const payload = lodash.get(log, 'payload', {});
		const attributes = lodash.get(payload, 'attributes', {});
		const order_source_invoice_approval = lodash.get(payload, 'order_source_invoice_approval', {});
		let logAction = log.action;
		switch (log.action) {
			case INVOICE_APPROVAL_ACTION.CREATE:
				const transKey = order_source_invoice_approval.reason
					? 'changeInvoiceRequest:log.CREATE'
					: 'changeInvoiceRequest:log.CREATE_NO_REASON';
				logAction = trans(transKey, {
					reason: <b>{order_source_invoice_approval.reason}</b>,
				});
				break;
			case INVOICE_APPROVAL_ACTION.CHANGE_STATE: {
				const { statusNew } = attributes;
				if (statusNew === 'SUCCESS') {
					logAction = trans('changeInvoiceRequest:log.CHANGE_STATE_SUCCESS');
				}
				if (statusNew === 'CANCELLED') {
					const transKey = order_source_invoice_approval.note
						? 'changeInvoiceRequest:log.CHANGE_STATE_CANCELLED'
						: 'changeInvoiceRequest:log.CHANGE_STATE_CANCELLED_NO_NOTE';
					logAction = trans(transKey, {
						note: <b>{order_source_invoice_approval.note}</b>,
					});
				}
				break;
			}
			default:
				break;
		}
		return <p key={log.id}>{logAction}</p>;
	}
	function renderLogs() {
		if (loading)
			return (
				<div style={{ textAlign: 'center' }}>
					<Spin />
				</div>
			);
		return (
			<div>
				{logs.map((log) => {
					return (
						<React.Fragment>
							<li
								className='a-log--transaction__item a-text--fz-14 pt-2 pb-4 border-bottom'
								key={log.id}
							>
								<div className='d-flex justify-content-between mb-2'>
									<span className='creator-log'>
										<b>{lodash.get(log, 'creator.name')}</b>
									</span>
									<span className='time-log'>
										{dateFormatter.dateTime(lodash.get(log, 'time') * 1000)}
									</span>
								</div>
								{logItem(log)}
							</li>
						</React.Fragment>
					);
				})}
			</div>
		);
	}
	return (
		<Modal
			title={t('changeInvoiceRequest:log.title')}
			visible={show}
			onOk={handleShowLog}
			onCancel={handleShowLog}
			bodyStyle={{ maxHeight: '70vmin', overflowY: 'scroll' }}
			footer={false}
		>
			{renderLogs()}
		</Modal>
	);
};
InvoiceCodeChangeMessage.defaultProps = {
	id: 0,
	show: false,
	handleShowLog: () => {},
};
InvoiceCodeChangeMessage.propTypes = {
	id: PropTypes.number.isRequired,
	show: PropTypes.bool.isRequired,
	handleShowLog: PropTypes.func.isRequired,
};
export default translate()(InvoiceCodeChangeMessage);