import {takeLatest} from 'redux-saga/effects';
import * as constants from './constants';
import * as saga from './saga';
import * as sagaOrder from './../OrderDetail/saga';

export default function*() {
    yield takeLatest(constants.UPDATE_REAL_PAYMENT_AMOUNT_ORDER.REQUEST, saga.updateRealPaymentAmount);
    yield takeLatest(constants.UPDATE_REAL_REFUND_AMOUNT_ORDER.REQUEST, saga.updateRealRefundAmount);
    yield takeLatest(constants.UPDATE_REAL_PAYMENT_AMOUNT_ORDER.SUCCESS, sagaOrder.fetchLog);
    yield takeLatest(constants.UPDATE_REAL_REFUND_AMOUNT_ORDER.SUCCESS, sagaOrder.fetchLog);
}