import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.btnAccept = null;
    }

    componentDidUpdate() {
        if (this.props.isOpen && this.btnAccept) {
            this.btnAccept.focus();
        }
    }

    onCancel() {
        if (!this.props.isLoading) {
            this.props.onCancel();
        }
    }

    onAccept() {
        if (!this.props.isLoading) {
            this.props.onAccept();
        }
    }

    render() {
        const {t, title, message, isLoading} = this.props;

        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={() => this.props.onCancel()}
            >
                <ModalHeader>{title ? title : t('modal.confirm.title')}</ModalHeader>
                <ModalBody>{message ? message : t('modal.confirm.message')}</ModalBody>
                <ModalFooter>
                    {isLoading && 'Loading...'}
                    <button
                        className="btn btn-secondary"
                        onClick={this.onCancel.bind(this)}
                        disabled={isLoading}
                    >{t('modal.cancel')}</button>
                    <button
                        className="btn btn-primary"
                        onClick={this.onAccept.bind(this)}
                        disabled={isLoading}
                        ref={el => this.btnAccept = el}
                    >{t('modal.accept')}</button>
                </ModalFooter>
            </Modal>
        )
    }
}

Confirm.defaultProps = {
    isOpen: false,
    isLoading: false,
    onAccept: () => {},
    onCancel: () => {},
};

Confirm.propTypes = {
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    onAccept: PropTypes.func,
    onCancel: PropTypes.func,
};

export default translate()(Confirm);