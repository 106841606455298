import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next'
import Spinner from '@atlaskit/spinner';

class WaitingPayment extends React.Component {
    onClickWaitingPayment(event) {
        let {id, isLoading} = this.props;
        if (!isLoading) {
            this.props.onWaitingPayment({id});
        }
    }

    render() {

        let {t, canChangeWaitingPay, isLoading} = this.props;
        return (
            <React.Fragment>
                <button
                    className={`ml-2 a-btn a-btn--payment
                    ${canChangeWaitingPay ? '' : 'a-btn--disable-action'}
                    ${isLoading ? 'a-btn--disable-action':''}`}
                    onClick={this.onClickWaitingPayment.bind(this)}
                >
                    <span className={isLoading ? "pt-1" : "hidden"}>
                        <Spinner invertColor  size="small" isCompleting={false}/>&nbsp;&nbsp;
                    </span>
                    {t('order:detail.waiting_payment')}
                </button>
            </React.Fragment>
        );
    }
}

WaitingPayment.defaultProps = {
    canChangeWaitingPay: false,
    isLoading: false,
    onWaitingPayment: () => {
    },
    id: 0,
};

WaitingPayment.propTypes = {
    canChangeWaitingPay: PropTypes.bool,
    isLoading: PropTypes.bool,
    onWaitingPayment: PropTypes.func,
    id: PropTypes.number,
};

export default translate('translations')(WaitingPayment);
