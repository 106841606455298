import React, {useState} from 'react';
import {Icon, Modal, Tooltip, Input, notification} from "antd";

import {t} from "../../../../systems/i18n";
import {processResponse} from "../../../../systems/api";
import apiService from "../apiService";
import {get} from "lodash";

function SellerNote({sellerInfo, updateSellerNote}) {
    const sellerId = get(sellerInfo, "id", undefined);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(get(sellerInfo, "sellerNote.note", null));

    const handleOk = () => {
        setLoading(true);
        const response = apiService.updateSellerNote(sellerId, {note});
        const process = processResponse(response);
        process.then((res) => {
            updateSellerNote(res);
            setVisible(false);
            notification.success({message: t("orderDetail:message.update_seller_note_success")})
        }).catch(() => {
            notification.error({message: t("message.cant_update_data")})
        }).finally(() => setLoading(false))
    };

    const handleCancel = () => {
        setVisible(false);
        setNote(get(sellerInfo, "sellerNote.note", null));
    };
    return (
        <div>
            <Tooltip title={t("orderDetail:tooltip.update_note_seller")}>
                <Icon type="edit" onClick={() => setVisible(true)}/>
            </Tooltip>

            <Modal
                title={t("orderDetail:tooltip.update_note_seller")}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                cancelText={t("btn.cancel")}
                okText={t("btn.ok")}
                cancelButtonProps={{
                    className: "_order-detail-handle-seller-note-btn-cancel"
                }}
                okButtonProps={{
                    loading: loading,
                    className: "_order-detail-handle-seller-note-btn-ok"
                }}
            >
                <div className='a-group'>
                    <label className="label">{t("orderDetail:label.seller_note")}</label>
                    <Input.TextArea
                        name="note"
                        autosize={{minRows: 2, maxRows: 6}}
                        value={note}
                        placeholder={t("orderDetail:placeholder.seller_note")}
                        onChange={(e) => setNote(e.target.value)}
                        className={"_order-detail-handle-seller-note"}
                    />
                </div>
            </Modal>
        </div>

    );
}


export default SellerNote;