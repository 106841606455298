import React from "react";
import {connect} from "react-redux";
import BankAccount from "./Components";
import {setBreadCrumb, setMenuActive, setPageDescription, setPageTitle} from "../../modules/common/actions";

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        }
    };
};

export default connect(null, mapDispatchToProps)(BankAccount);