import {apiWithToken} from "../../systems/api";
import {API_URL} from "../../systems/globalContant";

export default {
    getPaymentRequests: (params) => apiWithToken.get(API_URL.DEFAULT + '/payment-requests', {
        params,
        singleRequest: true,
        loading: false
    }),
    getPaymentRequestCount: (params) => apiWithToken.get(API_URL.DEFAULT + '/payment-requests/count', {
        params,
        singleRequest: true,
        loading: false
    }),
    changeStatePaymentRequest: (requestId, data = {}) => apiWithToken.put(API_URL.DEFAULT + `/payment-requests/${requestId}/change-state`, {...data}, {loading: false}),
    getLogs: (requestId) => apiWithToken.get(API_URL.DEFAULT + `/logs/payment_request/${requestId}`, {
        singleRequest: true,
        loading: false
    }),

    getPaymentRequestDetail: (params) => apiWithToken.get(API_URL.DEFAULT + `/payment-requests/${params.id}/confirm`, {
        singleRequest: true,
        loading: false
    }),
    updateProblem: (requestId, orderId, data = {}) => apiWithToken.put(API_URL.DEFAULT + `/payment-requests/${requestId}/confirm/${orderId}`, {...data}, {loading: false}),
    createExternalPaymentRequest: (orderId, data) => apiWithToken.post(API_URL.DEFAULT + `/payment-requests/${orderId}/external-orders`, {...data}, {loading: false}),
    createExternalPaymentRequestMany: (data) => apiWithToken.post(API_URL.DEFAULT + `/payment-requests/external-orders-many`, {...data}, {loading: false}),
    createNotePaymentRequest: (id, data) => apiWithToken.put(API_URL.DEFAULT + `/payment-requests/${id}/note`, data),
    exportCSV: (data) => apiWithToken.post(API_URL.DEFAULT + `/payment-requests/export`, data),
    editAmountPaymentRequest: (requestId, data = {}) => apiWithToken.put(API_URL.DEFAULT + `/payment-requests/${requestId}/amount`, {...data}, {loading: false}),
    updateBankPaymentRequest: (requestId, data = {}) => apiWithToken.put(API_URL.DEFAULT + `/payment-requests/${requestId}/bank-payment`, {...data}, {loading: false}),
    createNoteOfCreatorPaymentRequest: (requestId, data) => apiWithToken.put(API_URL.DEFAULT + `/payment-requests/${requestId}/note-teller`, {...data}, {loading: false}),
    getPaymentRequestGroups: (params) => apiWithToken.get(API_URL.DEFAULT + `/payment-request-groups`, {
        params,
        singleRequest: true,
        loading: false
    }),
    getDetailPaymentRequestGroup: (groupId) => apiWithToken.get(API_URL.DEFAULT + `/payment-request-groups/${groupId}`, {
        singleRequest: true,
        loading: false
    }),
    addPaymentRequestGroup: (data = {}) => apiWithToken.post(API_URL.DEFAULT + `/payment-request-groups`, data),
    editPaymentRequestGroup: (groupId, data = {}) => apiWithToken.put(API_URL.DEFAULT + `/payment-request-groups/${groupId}`, data),
    changeStatusPaymentRequestGroup: (groupId, data) => apiWithToken.put(API_URL.DEFAULT + `/payment-request-groups/${groupId}/status`, {...data}, {
        singleRequest: true,
        loading: false
    }),
    addMemberPaymentRequestGroup: (groupId, data) => apiWithToken.post(API_URL.DEFAULT + `/payment-request-groups/${groupId}/member`, {...data}, {
        singleRequest: true,
        loading: false
    }),
    deleteMemberPaymentRequestGroup: (groupId, memberId) => apiWithToken.delete(API_URL.DEFAULT + `/payment-request-groups/${groupId}/member/${memberId}`, {
        singleRequest: true,
        loading: false
    }),
    forwardPaymentRequestToGroup: (requestId, groupId) => apiWithToken.put(API_URL.DEFAULT + `/payment-requests/${requestId}/change-group/${groupId}`),
    createPaymentRequestBySBOrder: (data) => apiWithToken.post(API_URL.DEFAULT+`/payment-requests/order-sb`, data),
    receiveManyRequest: (data) => apiWithToken.post(API_URL.DEFAULT + `/payment-requests/receive-many-requests`, data),
    deleteQrcodePaymentRequest: (requestId) => apiWithToken.delete(API_URL.DEFAULT + `/payment-requests/${requestId}/delete-qr-code`),
    updateQrCodePaymentRequest: (requestId, data) => apiWithToken.put(API_URL.DEFAULT + `/payment-requests/${requestId}/update-qr-code`, data)
}
