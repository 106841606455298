import React, {Component} from 'react';
import {translate} from "react-i18next";
import apiService from '../../../modules/PaymentRequest/apiService';
import {processResponse} from "../../../systems/api";
import {Modal, Select, notification} from "antd";
import lodash from "lodash"
import {events} from "../../../systems/events";
import PAYMENT_REQUEST_EVENT from "../../../modules/PaymentRequest/events";

const Option = Select.Option;
class ForwardPaymentRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            groupId: undefined,
            loading: false
        };
    }
    componentDidMount() {

    }
    openModal = () => {
        this.setState({visible: true});
    };

    handleCancel = () => {
        this.setState({visible: false});
    };
    handleOk = () => {
        this.setState({
            loading: true
        })
        const {t, request, oldStatus, status} = this.props;
        const {groupId} = this.state;
        if (groupId) {
            processResponse(apiService.forwardPaymentRequestToGroup(request.id, groupId))
                .then(res => {
                    events.dispatch(lodash.get(PAYMENT_REQUEST_EVENT, `${status}`, ''), {
                        request: {...res.data},
                        oldStatus
                    });
                    this.handleCancel();
                    notification.success({message: t("paymentRequest:message.forward_group_success")})
                }).catch(err => {
                    notification.error({message: t("paymentRequest:message.forward_group_fail")})
            }).finally(() => {
                this.setState({loading: false})
            })
        }
    };

    onChangeSelectGroup = (groupId) => {
        this.setState({
            groupId
        })
    };


    render() {
        const {t, groups} = this.props;
        const {visible, groupId, loading} = this.state;
        return (
            <>
                <a onClick={this.openModal}>{t("paymentRequest:btn.forward_payment_request")}</a>
                {
                    visible &&
                    <Modal
                        title={t("paymentRequest:label.forward_payment_request")}
                        visible={visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        okButtonProps={{className: "_payment-request-processing-action-forward-button-ok", loading, type: "primary"}}
                        cancelButtonProps={{className: `_payment-request-processing-action-forward-button-cancel`}}
                    >
                        <Select
                            style={{width: "100%"}}
                            placeholder={t("paymentRequest:placeholder.group_payment_request")}
                            value={groupId}
                            onChange={(value) => this.onChangeSelectGroup(value)}
                            showSearch
                            optionFilterProp="children"
                            className="_payment-request-processing-action-forward"
                        >
                            {
                                groups.map((item, index) => {
                                    return <Option key={index} value={item.id}>{item.code} - {item.name}</Option>
                                })
                            }
                        </Select>
                    </Modal>
                }
            </>
        );
    }

}

export default translate()(ForwardPaymentRequest)
