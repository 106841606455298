import {getConfig, getEnv} from '../systems/support/helpers';

export default {
    /**
     * The base api url
     */
    apiUrl: getConfig('API_URL', 'http://localhost:28081'),

    /**
     * The api version
     */
    apiVersion: getConfig('API_VERSION', 1),
    publicUrl: getEnv("PUBLIC_URL", ''),

    /**
     * The base api url
     */
    biUrl: getConfig('BI_URL', 'https://datastudio.google.com/embed/u/0/reporting/7432cf44-afb9-43c0-8a4e-224b78fddd43/page/rciqB'),
};