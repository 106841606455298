import React from 'react'
import {translate} from 'react-i18next';
import InlineEdit from "../../../../common/components/InlineEdit";
import Input from "../../../../common/components/Input/Super";
import PropTypes from 'prop-types';

class RateDeposit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            origin_rate_deposit: '',
            rate_deposit: ''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        if (nextProps.rate_deposit !== prevState.origin_rate_deposit) {
            state.rate_deposit = nextProps.rate_deposit;
        }

        return state;
    }

    /**
     * onChange input
     * @param event
     */
    onChangeInput(event) {
        const {orderId} = this.props;
        let value = event.target.value;
        if (event.key === 'Enter' && value) {
            this.props.onChange({
                id: orderId,
                rate_deposit: value,
            })
        }
    }

    render() {
        const {isEdit, rate_deposit} = this.state;
        const {canChangeRateDeposit} = this.props;

        return (
            <div>
                {canChangeRateDeposit ?
                    <InlineEdit
                        text={rate_deposit + '%'}
                        isEdit={isEdit}
                        disableAction
                        integer
                        isPencilLeft
                        editDisabled={false}
                        classTitle={'a-text--bold '}
                    >
                        <Input
                            className={'input__code_bill '}
                            onKeyPress={event => this.onChangeInput(event)}
                            value={rate_deposit || ''}
                            autoFocus={true}
                        />
                    </InlineEdit>
                    : rate_deposit + '%'
                }
            </div>

        );
    }
}

RateDeposit.propTypes = {
    rate_deposit: PropTypes.number,
    onChange: PropTypes.func,
    orderId: PropTypes.number
};

export default translate('translations')(RateDeposit);
