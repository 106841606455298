import React, {Component} from "react";
import {Input, Modal} from "antd";
import {translate} from "react-i18next";
import apiService from "./apiService";
import {processResponse} from "../../../systems/api";
import {events} from "../../../systems/events";
import notification from "../../../systems/notification";
import ORDER_EVENT from "../../Order/events";
import lodash from "lodash";
import authService from "../../Auth/authService";
import * as PERMISSION from "../../Permission/permissions";

class ResolveOrder extends Component {

    constructor({props}) {
        super(props);
        this.state = {
            visible: false,
            loading: false,
            reason: ""
        };
    }

    showModal = (e) => {
        e.preventDefault();
        this.setState({visible: true});
    }

    handleOk = e => {
        e.preventDefault();
        this.setState({loading: true});
        let { order, t } = this.props;

        let res = apiService.resolve(order.id, {note: this.state.reason});
        res.finally(() => {
            this.setState({visible: false, loading: false});
        });

        let process = processResponse(res);

        process.then(res => {
            notification.success('Đánh dấu đơn có vấn đề đã được xử lý thành công');
            events.dispatch(ORDER_EVENT.RESOLVED_ORDER, {
                id: order.id,
                data: {
                    is_resolved: lodash.get(res, "data.orderDetail.is_resolved"),
                    ...lodash.pick(res.data.orderDetail, ['problemResolveCreator', 'problem_resolve_creator_id', 'problem_resolve_note', 'problem_resolve_time'])
                }
            });
        });

        process.catch(() => {
            notification.error('Đánh dấu đơn có vấn đề đã được xử lý không thành công');
        });
    }

    handleCancel = e => {
        this.setState({visible: false});
    }

    render() {
        let {order, t} = this.props;
        return <div className="text-black-50 border-resolve-order">
            <a href="#" className="ml-2" onClick={this.showModal}>Resolve</a>
            <Modal
                centered={true}
                title="Đánh dấu đơn có vấn đề đã được xử lý"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                cancelText="Hủy"
                okText="Resolve"
                okButtonProps={{loading: this.state.loading}}
            >
                <span className="pb-1" style={{display: "block"}}>Lý do đánh dấu</span>
                <Input.TextArea
                    rows={5}
                    value={this.state.reason}
                    onChange={e => { this.setState({...this.state, reason: e.target.value})}}
                    placeholder="Nhập lý do đánh dấu..." />
            </Modal>
        </div>;
    }
}

export default translate()(ResolveOrder);