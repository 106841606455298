import React, { useState } from 'react'
import { Avatar, Button, Form, Input, Modal, notification, Radio, Divider } from 'antd'
import { t } from '../../../../../systems/i18n'
import { filter, find, flatten, get, groupBy, isEmpty, isEqual, map, omit, uniqWith } from 'lodash'
import apiService from '../../apiService'
import { Link } from '../../../../../systems/routing'
import { processResponse } from '../../../../../systems/api'
import { currentFormatter } from '../../../../common/services/format'

export default function AddLinkProduct({ type = 'primary', orderInfo, getPurchasingOrderDetailAction }) {
    const [visible, setVisible] = useState(false)
    const [productLink, setProductLink] = useState('')
    const [isFetching, setIsFetching] = useState(false)
    const [loading, setLoading] = useState(false)
    const [input, setInput] = useState({})
    const [productInfo, setProductInfo] = useState({})
    const [skuList, setSkuList] = useState({})
    const [properties, setProperties] = useState({})
    const [propertiesAllow, setPropertiesAllow] = useState([])
    const [skuInfo, setSkuInfo] = useState({})

    function handleModal(value) {
        setVisible(value)
        if (!value) {
            setProductLink('')
            setProductInfo({})
            setSkuList({})
            setProperties({})
            setPropertiesAllow([])
            setInput({})
            setIsFetching(false)
        }
    }

    function handleGetProductInfo() {
        const orderId = get(orderInfo, 'id', undefined)
        if (orderId) {
            try {
                setProductInfo(false)
                new URL(productLink)
                setIsFetching(true)
                processResponse(apiService.getOrderProductInfoByLink(orderId, { link: productLink }))
                    .then(res => {
                        const productInfo = get(res, 'data.itemData', {})
                        refactorData(productInfo)
                        setProductInfo(productInfo)
                        setProductLink('')
                    })
                    .catch(err => {
                        if (err.code === 'INPUT_INVALID') {
                            notification.error({ message: 'Link sản phẩm phải cùng người bán!' })
                        } else {
                            notification.error({ message: 'Có lỗi xảy ra khi lấy thông tin sản phẩm!' })
                        }
                    })
                    .finally(() => {
                        setIsFetching(false)
                    })
            } catch (e) {
                notification.error({ message: 'Link sản phẩm không hợp lệ!' })
            }
        }
    }

    function refactorData(productInfo) {
        const skuList = filter(get(productInfo, 'sku_list', []), item => !!item.quantity && item.status === 'active')
        const data = map(skuList, 'properties')
        const properties = uniqWith(flatten(data), isEqual)
        setProperties(groupBy(properties, item => item.prop_id)) //data properties
        setSkuList(skuList)
        const propertiesAllow = []
        skuList.map(item => {
            const skuProperties = get(item, 'properties', [])
            const skuPropertiesKey = skuProperties.map(item => [item.prop_id, item.value_id].join('_'))
            const skuId = get(item, 'sku_id')
            propertiesAllow.push([skuId, ...skuPropertiesKey].join(':'))
        })
        setPropertiesAllow(propertiesAllow)
    }

    function handleAddProduct() {
        const orderId = get(orderInfo, 'id', undefined)
        const quantity = get(input, 'quantity', 0)
        if (quantity) {
            setLoading(true)
            const data = omit(productInfo, ['sku_list'])
            processResponse(apiService.addProductItem(orderId, { ...data, qty: quantity, sku: skuInfo }))
                .then(() => {
                    handleModal(false)
                    getPurchasingOrderDetailAction({ id: orderId })
                    notification.success({ message: 'Thêm sản phẩm cho đơn thành công!' })
                })
                .catch(err => {
                    console.log('err', err)
                    if (err.code === 'INPUT_INVALID') {
                        const data = get(err, 'data', {})
                        const messages = []
                        Object.keys(data).map(item => {
                            const keys = Object.keys(data[item])
                            return keys.map(key => {
                                return messages.push(t(`orderDetail:message.${key}`, { attribute: t(`orderDetail:label.${item}`) }))
                            })
                        })

                        notification.error({ message: messages.join(', ') })
                    } else {
                        notification.error({ message: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu!' })
                    }
                })
                .finally(() => setLoading(false))
        } else {
            notification.error({ message: 'Vui lòng nhập vào số lượng' })
        }
    }

    function handleChooseProperty(prop_id, value) {
        const inputProperties = get(input, 'properties', {})
        const updatedProperties = {
            ...inputProperties,
            [prop_id]: value,
        }
        if (Object.keys(updatedProperties).length === Object.keys(properties).length) {
            const valueOfProperties = Object.values(updatedProperties)
            const key = find(propertiesAllow, property => {
                return valueOfProperties.every(item => property.includes(item))
            })
            const skuId = get(key.split(':'), '0')
            setSkuInfo(find(skuList, item => item.sku_id == skuId))
        }
        handleChangeInput('properties', updatedProperties)
    }

    function handleChangeInput(name, value) {
        if (name === 'quantity') {
            const regex = /^\+?([1-9]\d*)$/
            if (value === '' || regex.test(value)) {
                setInput(prevState => {
                    return {
                        ...prevState,
                        [name]: value,
                    }
                })
            }
        } else {
            setInput(prevState => {
                return {
                    ...prevState,
                    [name]: value,
                }
            })
        }
    }

    const inputProperties = get(input, 'properties')
    const quantity = get(input, 'quantity', 0)

    return (
        <>
            <Button
                size={'small'}
                type={type}
                onClick={() => handleModal(true)}>
                Thêm sản phẩm
            </Button>
            {visible && (
                <Modal
                    width={800}
                    title={`Thêm sản phẩm cho đơn`}
                    maskClosable={false}
                    visible={visible}
                    onCancel={() => handleModal(false)}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => handleModal(false)}>
                            {t('btn.cancel')}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            loading={loading}
                            onClick={handleAddProduct}
                            disabled={isEmpty(input) || Object.keys(inputProperties).length < Object.keys(properties).length || !quantity}>
                            {t('orderDetail:btn.add_product')}
                        </Button>,
                    ]}>
                    <div className="d-flex">
                        <Input
                            value={productLink}
                            onChange={e => setProductLink(e.target.value)}
                            placeholder={t('orderDetail:placeholder.add_link')}
                        />
                        <Button
                            className="ml-2"
                            disabled={!productLink}
                            loading={isFetching}
                            onClick={handleGetProductInfo}>
                            Lấy thông tin SP
                        </Button>
                    </div>
                    {!isEmpty(productInfo) && (
                        <div className="mt-4 add-product-info">
                            <div className="d-flex">
                                <Avatar
                                    shape="square"
                                    size={120}
                                    src={get(skuInfo, 'pic_url', get(productInfo, 'image', ''))}
                                />
                                <div className="ml-4">
                                    <Form.Item
                                        className="d-flex mb-0"
                                        label={<b>Tên sản phẩm</b>}>
                                        <Link
                                            isNewTab={true}
                                            style={{ textDecorationLine: 'none' }}
                                            href={get(productInfo, 'link', '#')}>
                                            {get(productInfo, 'title', '')}
                                        </Link>
                                    </Form.Item>

                                    <Form.Item
                                        className="d-flex mb-0"
                                        label={<b>Đơn giá gốc</b>}>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                                            {'¥' + currentFormatter.toLocaleStringCurrency(Number(get(skuInfo, 'price', get(productInfo, 'price', 0)), 2))}
                                        </span>
                                    </Form.Item>

                                    <Form.Item
                                        className="d-flex mb-1"
                                        label={<b>Đơn giá sale</b>}>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                                            {'¥' +
                                                currentFormatter.toLocaleStringCurrency(
                                                    Number(get(skuInfo, 'promotion_price', get(productInfo, 'promotion_price', 0)), 2)
                                                )}
                                        </span>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="mt-2">
                                {!isEmpty(properties) &&
                                    propertiesAllow.length > 0 &&
                                    Object.keys(properties).map(key => {
                                        const listValue = get(properties, key, [])
                                        return (
                                            <Form.Item
                                                key={key}
                                                className="mb-1"
                                                label={<b>{get(listValue, '0.prop_name', '')}</b>}>
                                                <Radio.Group
                                                    buttonStyle="solid"
                                                    onChange={e => handleChooseProperty(key, e.target.value)}
                                                    value={get(input, `properties.${key}`)}>
                                                    {listValue.map(item => {
                                                        let valueOfProperties = []
                                                        let disabled = false
                                                        if (Object.keys(properties).length > 1) {
                                                            if (!isEmpty(inputProperties)) {
                                                                valueOfProperties = Object.values(inputProperties)
                                                                disabled = isEmpty(
                                                                    find(
                                                                        propertiesAllow,
                                                                        property =>
                                                                            property.includes(item.prop_id + '_' + item.value_id) &&
                                                                            valueOfProperties.some(value => property.includes(value))
                                                                    )
                                                                )
                                                                if (valueOfProperties.length === 1 && !!get(inputProperties, key)) {
                                                                    disabled = false
                                                                }
                                                            }
                                                        } else {
                                                            disabled = isEmpty(
                                                                find(propertiesAllow, property => property.includes(item.prop_id + '_' + item.value_id))
                                                            )
                                                        }
                                                        return (
                                                            <Radio.Button
                                                                key={item.value_id}
                                                                value={item.prop_id + '_' + item.value_id}
                                                                disabled={disabled}>
                                                                {item.value_name}
                                                            </Radio.Button>
                                                        )
                                                    })}
                                                </Radio.Group>
                                            </Form.Item>
                                        )
                                    })}

                                <Form.Item
                                    label={<b>Số lượng</b>}
                                    className="d-flex mb-0">
                                    <Input
                                        value={get(input, 'quantity')}
                                        placeholder="Nhập vào số lượng"
                                        onChange={e => handleChangeInput('quantity', e.target.value)}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    )}
                </Modal>
            )}
        </>
    )
}
