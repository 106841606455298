import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import Pusher from "../../../../systems/ulti/Pusher";
import pusherConfig from '../../../../config/pusher';

const STATUS_PUSHER = {
    CLOSED: 0,
    CONNECTING: 1,
    OPEN: 2
};

export default class PusherComponent extends React.PureComponent {

    constructor(props) {
        super(props);
        this.pusher = {};

    }

    /**
     * khởi tạo object pusher
     * @private
     */
    _initPusher() {
        let {channelSendMessage} = this.props;
        this.pusher = new Pusher({
            host: pusherConfig.host,
            hostSendMessage: (pusherConfig.useSSL ? 'https://' : 'http://') + pusherConfig.host + '/pub',
            uri: {
                app_key: pusherConfig.subAppKey,
                secret: pusherConfig.subSecret
            },
            uriSend: {
                id: channelSendMessage || "test",
                app_key: pusherConfig.pubAppKey,
                secret: pusherConfig.pubSecret
            },
            useSSL: pusherConfig.useSSL,
            debug: false,
            _context: this
        });
    }


    /**
     * Kiểm tra xem render lại có thay đổi channel k thì add lại
     * @param channels
     * @private
     */
    _addChannels(channels) {
        let isNewChannel = false;
        if (!lodash.isEmpty(this.pusher, true) && channels.length > 0) {
            let objCurrentChannel = this.pusher.getChannel();
            channels.map((channel) => {
                if (!objCurrentChannel[channel] && typeof channel !== "undefined" && channel !== null) {
                    isNewChannel = true;
                    this.pusher.addChannel(channel);
                }
                return channel;
            });
            if (isNewChannel) {
                this.pusher.start();
            }
        }
    }

    /**
     * handle hành động nhận message <-- need overwise in child obj
     * @param text
     * @param id
     * @param channel
     * @private
     */
    _pusherOnMessage(text, id, channel) {
        console.info('_pusherOnMessage', text, id, channel);
    }

    _pusherOnSendMessage(message) {
        this.pusher.sendMessage(this.pusher.hostSendMessage + "?" + this.pusher.uriSend, message, this._successCallback, this._errorCallback);
    }

    _successCallback() {
        console.info("_successCallback");
    }

    _errorCallback() {
        console.info("_errorCallback");
    }

    /**
     * handle các state của pusher
     * @param state
     * @private
     */
    _pusherOnStatusChange(state) {
        if (state === STATUS_PUSHER.CLOSED) {
            console.info('Pusher closed');
        }

        if (state === STATUS_PUSHER.CONNECTING) {
        }

        if (state === STATUS_PUSHER.OPEN) {
            console.info('Pusher open with chancel: ', this.pusher.getChannel());
        }
    }

    componentDidMount() {
        this._initPusher();

        let {channels} = this.props;
        if (typeof channels !== "undefined") {
            channels.map((channel) => {
                if (channel !== null) {
                    this.pusher.addChannel(channel);
                }
                return channel;
            });
        }

        if (!lodash.isEmpty(this.pusher) && Array.isArray(channels) && channels.length > 0) {
            this.pusher.start();
        }
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (Array.isArray(this.props.channels) && this.props.channels.length > 0) {
            this._addChannels(this.props.channels);
            if (!lodash.isEmpty(this.pusher) && this.pusher.state !== 2) {
                this.pusher.start();
            }
        }
    }

    componentWillUnmount() {
        let {channels} = this.props;
        if (typeof channels !== "undefined") {
            channels.map((channel) => {
                if (channel !== null) {
                    this.pusher.removeChannel(channel);
                }
                return channel;
            });
        }

        if (this.pusher.hasOwnProperty('stop')) {
            this.pusher.stop(true);
        }
    }
}

PusherComponent.defaultProps = {
    channels: [],
    channelSendMessage: "",
};

PusherComponent.propTypes = {
    channels: PropTypes.array,
    channelSendMessage: PropTypes.string,
};
