import React from "react";
import {connect} from "react-redux";
import SettingPrivate from "./Components";
import lodash from "lodash";
import {setBreadCrumb, setMenuActive, setPageDescription, setPageTitle} from "../../../modules/common/actions";
import {updateIpPhone} from "../../../modules/User/actions";

const mapStateToProps = (state) => {
    return {
        user: lodash.get(state, 'User.me.user', [])
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        updateIpphone: (params) => {
            dispatch(updateIpPhone(params))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingPrivate);