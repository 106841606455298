import React from 'react';
import {router} from '../systems/routing'
import {translate} from 'react-i18next';
class Error extends React.Component {

    backHome(){
        router.redirect("/");
    }

    render() {
        const {t} = this.props;
        return (
            <div className="content-i">
                <div className="content-box">
                    <div className="big-error-w">
                        <h5>
                            {this.props.error.code}
                        </h5>
                        <h4>
                            {this.props.error.message}
                        </h4>
                        <hr/>
                        <a href="" onClick={this.backHome}>{t('message.go_home')}</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(Error);