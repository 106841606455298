import React from "react";
import {Tooltip} from "antd";

export default function InlineEdit2({text, children, isEdit, can, setIsEdit, className}) {
    return (
        isEdit && can !== false ? children :
            <span className={`a--cursor-poiter a--hover-edit pl-1 px-1 ${className ? className : ""}`} onClick={() => setIsEdit(true)}>
                { can ? <Tooltip title={"Click để chỉnh sửa"}>{text}</Tooltip> : text}
            </span>
    );
}
