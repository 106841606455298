import {combineReducers} from 'redux';
import lodash from "lodash";
import * as Constants from "./constants";

const list = (state = {}, action) => {
    switch (action.type) {
        case Constants.FETCH_ALL_CONFIG_USER.SUCCESS:
            return lodash.get(action, 'payload.data', []);
        case Constants.FETCH_ALL_CONFIG_USER.FAILED:
            return state;
        default:
            return {...state};
    }
};

/**
 * State Sử dụng để xem việc xử lý các action của form xong hay chưa
 * @param state
 * @param action
 * @returns {boolean}
 */
const isEndProcess = (preState = true, action) => {
    switch (action.type) {
        case Constants.FETCH_ALL_CONFIG_USER.REQUEST:
            return false;
        case Constants.FETCH_ALL_CONFIG_USER.SUCCESS:
        case Constants.FETCH_ALL_CONFIG_USER.FAILED:
        case Constants.UPDATE_USER_CONFIG.FAILED:
            return true;
        default:
            return preState;
    }
};

/**
 * State để quản lý xem có form có cần disable để xử lý hành động nào k
 * @param preState
 * @param action
 * @returns {boolean}
 */
const isLoading = (preState = false, action) => {
    switch (action.type) {
        case Constants.FETCH_ALL_CONFIG_USER.REQUEST:
        case Constants.UPDATE_USER_CONFIG.REQUEST:
            return true;
        case Constants.FETCH_ALL_CONFIG_USER.SUCCESS:
        case Constants.UPDATE_USER_CONFIG.SUCCESS:
        case Constants.FETCH_ALL_CONFIG_USER.FAILED:
        case Constants.UPDATE_USER_CONFIG.FAILED:
            return false;
        default:
            return preState;
    }
};

export default combineReducers({
    isLoading,
    isEndProcess,
    list
});