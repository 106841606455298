import * as permissions from './permissions'
import authService from '../Auth/authService'

/**
 * Allowed array or string with permission params
 *
 * @param permission
 * @returns {boolean}
 */
const hasPermission = (permission) => {
    return authService.can(permission);
};

/**
 *
 * @param permission
 * @param permissions
 * @returns {*}
 */
const hasPermissionOrder = (permission, permissions) => {
    if (Array.isArray(permission)) {
        return permission.some(function (code) {
            return permissions.indexOf(code) !== -1;
        });
    } else {
        return permissions.indexOf(permission) !== -1;
    }
};

export  {
    hasPermission,
    hasPermissionOrder,
    permissions
}