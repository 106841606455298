import React, {Component} from 'react';
import {Icon, Select} from "antd";
import {tellersService} from '../services';

const Option = Select.Option;

class SelectSearchTellers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggest_tellers: [],
            selected_tellers: [],
            tellers: []
        };
    }

    static getDerivedStateFromProps(props, state) {
        let nextState = {...state};
        if(!props.visible){
            nextState.suggest_tellers = [];
            nextState.selected_tellers = [];
        }
        return nextState;
    }

    handleSearch(value){
        if(value.length >= 3) {
            let {tellers} = this.state;
            let suggestTellers = tellers.filter(item => item.username.includes(value))
            this.setState({
                suggest_tellers: suggestTellers
            })
        } else {
            this.setState({
                suggest_tellers: []
            })
        }
    }

    handleChange(id){
        let selectedTellers = this.state.selected_tellers;
        let suggestTellers = this.state.suggest_tellers;
        let tellers = suggestTellers.filter(item => item.id === id)[0];
        
        if(selectedTellers.length === 0 || (selectedTellers.length > 0 && selectedTellers.every(item => item.id !== tellers.id))) {
            selectedTellers.push(tellers);
        }
        this.setState({
            selected_tellers: selectedTellers
        })

        this.props.setSelectedTellers(selectedTellers);
        this.props.clearTellersError();
    }

    componentDidMount(){
        this.fetchTellers()
    }

    clearTellers(){
        this.setState({
            suggest_tellers: [],
            selected_tellers: [],
            tellers: [],
        })
    }

    fetchTellers() {
        this.setState({loading: true});
        tellersService.list()
            .then(tellers => this.setState({tellers}))
            .finally(() => this.setState({loading: false}));
    }

    removeSelectedTellers(currentItem, event){
        event.preventDefault();
        let selectedTellers = this.state.selected_tellers;
        selectedTellers = selectedTellers.filter(item => item.id !== currentItem.id);
        this.setState({
            selected_tellers: selectedTellers
        })
        this.props.setSelectedTellers(selectedTellers);
    }

    render() {
        /* eslint-disable */
        let suggestTellers = this.state.suggest_tellers;
        let selectedTellers = this.state.selected_tellers;
        const suggestTellersOption = suggestTellers.length > 0 ? suggestTellers.map(item => <Option key={item.id} value={item.id}>{item.username}</Option>) : null;
        return (
            <React.Fragment>
                <Select
                    value={undefined}
                    showSearch
                    placeholder={this.props.placeholder}
                    style={this.props.style}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={this.handleSearch.bind(this)}
                    onChange={this.handleChange.bind(this)}
                    notFoundContent={null}
                    className="w-100"
                >
                    {suggestTellersOption}
                </Select>
                {selectedTellers.length > 0 ? 
                    <div className={"a-list--user mt-3"}>
                        {selectedTellers.map(item => (
                            <div className={"a-user"} key={item.id}>
                                <span className="a-user--name">{item.username}</span>
                                <a href="" className="a-user--icon-remove" onClick={this.removeSelectedTellers.bind(this, item)}><Icon type="close"/></a>
                            </div>
                        ))}
                    </div> : null}
            </React.Fragment>
        )
    }
}
export default SelectSearchTellers;
