import React from 'react';
import {Layout} from "antd";

import  FormSearch from './components/FormSearch';
import ListEmployee from './components/ListEmployee';
const Content = Layout.Content;

class EmployeePurchaseContainer extends React.PureComponent {
    render() {
        return (
            <Layout>
                <Content className="a-content--ant-layout">
                    <FormSearch/>
                    <ListEmployee/>
                </Content>
            </Layout>
        )
    }
}
export default EmployeePurchaseContainer;