import React from 'react';
import ReactDOM from 'react-dom';
import StoreProvider from './systems/store/StoreProvider';
import RoutingProvider from './systems/routing/RoutingProvider';
import {I18nextProvider} from 'react-i18next';
import i18n from './systems/i18n';
import {ToastrRoot} from './../src/modules/common/components/Bootbox/index';
import Modal from './modules/common/Modal/ModalContainer';

import './resources/scss/main.scss';

ReactDOM.render(
    <StoreProvider>
        <I18nextProvider i18n={i18n}>
            <div>
                <ToastrRoot/>
                <RoutingProvider/>
                <div id="no-print-notification">
                    <Modal/>
                </div>
            </div>
        </I18nextProvider>
    </StoreProvider>,
    document.getElementById('root')
);