import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import Select from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import {orderService} from '../../services';
import {hasPermission, permissions} from "../../../Permission";
import lodash from 'lodash'
class OrderPurchaser extends React.Component {
    onUnassignPurchaser(event) {
        event.preventDefault();

        if (!this.props.distributing) {
            this.props.onUnassignPurchaser();
        }
    }

    onAssignPurchaser({value}) {
        if (!this.props.distributing) {
            this.props.onAssignPurchaser(value);
        }
    }

    render() {
        let {t, purchasers, order, distributing} = this.props;
        const isSelfNegotiateOrder = lodash.get(order, "order_detail.self_negotiate", false)
        if (hasPermission(permissions.ORDER_DISTRIBUTE) && (orderService.canAssign(order) || (isSelfNegotiateOrder && hasPermission(permissions.SELF_NEGOTIATE_ASSIGN_ORDER) && orderService.canAssignForce(order))) && order.teller === null) {
            return (
                <div style={{minWidth: 200}}>
                    <Select
                        noOptionsMessage={() => t("system.no_option")}
                        className="single-react"
                        classNamePrefix="react-select--teller"
                        isLoading={distributing}
                        isDisabled={distributing}
                        placeholder={t('order:distribute_order.select_purchaser')}
                        options={purchasers.map(purchaser => ({
                            label: `${purchaser.username} - ${purchaser.name}`,
                            value: purchaser.id,
                        }))}
                        onChange={this.onAssignPurchaser.bind(this)}
                    />
                </div>
            );
        }

        return (
            <div className="text-muted d-flex align-items-center a-item__bottom__select_purchase">
                {distributing ? <div className="ml-2"><Spinner size="small"/></div> : null}
                <span>
                    {order.teller_name || '---'} / @{order.teller_username || '---'} {order.time_teller_format}
                </span>

                { hasPermission(permissions.ORDER_DISTRIBUTE) && (orderService.canUnassign(order)  || (isSelfNegotiateOrder && hasPermission(permissions.SELF_NEGOTIATE_ASSIGN_ORDER) && orderService.canUnassignForce(order))) ? (
                    <div className={'a-item__bottom__cancel_purchase'}>
                        <i className="ml-1 mr-1 hidden-md">-</i>
                        <a className={"a-text--danger"} href="" onClick={this.onUnassignPurchaser.bind(this)}>{t('order:distribute_order.btn_unassign')}</a>
                    </div>
                ) : null}
            </div>
        );
    }
}

OrderPurchaser.defaultProps = {
    purchasers: [],
    order: {},
    distributing: false,
    onAssignPurchaser: (purchaserId) => {},
    onUnassignPurchaser: () => {},
};

OrderPurchaser.propTypes = {
    order: PropTypes.object.isRequired,
    distributing: PropTypes.bool,
    onAssign: PropTypes.func,
    onUnassignPurchaser: PropTypes.func,
};

export default translate()(OrderPurchaser);
