import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import Select from '@atlaskit/select';
import lodash from "lodash";
import {VIOLATE_REASON_TYPE} from '../../../../systems/globalContant/actionContant';

class ChoseReason extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSelecting: false,
            isUpdate: false,
            reason: null,
        }
    }

    onHandleClick() {
        if (this.props.canSelect) {
            this.openForm();
        }
    }

    onBlurSelect() {
        this.cancelForm();
    }

    onChangeSelect(idOrderPolicy, reason) {
        this.setState({reason: reason});
        if (reason) {
            this.props.onUpdate({reasons_id: reason.value, id: idOrderPolicy});
        }

        this.setState({
            isSelecting: false,
            isUpdate: true,
        });
    }

    getOrderReason() {
        let {reasonId, violateReasons} = this.props;
        let reason = lodash.find(violateReasons, {id: reasonId});
        return reason ? {value: reason.id, label: reason.title} : null;
    }

    getCurrentIdReason() {
        let {isUpdate, reason} = this.state;

        return isUpdate ? reason : this.getOrderReason();
    }

    openForm() {
        this.setState({
            isSelecting: true,
            isUpdate: false,
            reason: this.getOrderReason(),
        });
    }


    cancelForm() {
        this.setState({
            isSelecting: false,
            isUpdate: false,
        });
    }

    makeReasonOptions() {
        return lodash.keys(VIOLATE_REASON_TYPE).map(type => ({
            label: this.props.t('violate:list.' + type),
            options: this.props.violateReasons
                .filter(reason => reason.type === type)
                .map(reason => ({value: reason.id, label: reason.title})),
        }));
    }

    renderFormContent() {
        let {t, canSelect} = this.props;
        return (
            <React.Fragment>
                {
                    canSelect ? <p onClick={this.onHandleClick.bind(this)}>
                        <span
                            className="can-select">{t('violate:list.reason')}: {lodash.get(this.getCurrentIdReason(), 'label') || "---"}</span>
                    </p> : <p>{t('violate:list.reason')}: {lodash.get(this.getCurrentIdReason(), 'label') || "---"}</p>
                }
            </React.Fragment>
        );
    }

    renderFormEdit() {
        let {t, idOrderPolicy = 1} = this.props;
        let {reason} = this.state;
        return (
            <React.Fragment>
                <Select
                    noOptionsMessage={() => t("system.no_option")}
                    formatGroupLabel={(group)=> {
                        return <span className={"react-select--violate--cus_gr"}>{group.label}</span>
                    }}
                    formatOptionLabel={(menu)=> {
                        return <span className={'react-select--violate--cus_op'}>{menu.label}</span>
                    }}
                    className={"single-react--violate"}
                    classNamePrefix="react-select--violate"
                    placeholder={t('violate:list.select_reason')}
                    value={reason}
                    autoFocus={true}
                    options={this.makeReasonOptions()}
                    onBlur={this.onBlurSelect.bind(this)}
                    onChange={this.onChangeSelect.bind(this, idOrderPolicy)}
                />
            </React.Fragment>
        );
    }

    render() {

        return (
            <React.Fragment>
                {this.state.isSelecting ? this.renderFormEdit() : this.renderFormContent()}
            </React.Fragment>
        );
    }
}

ChoseReason.defaultProps = {
    violateReasons: [],
    canSelect: true,
    onUpdate: (idReason) => {
    },
};

ChoseReason.propTypes = {
    violateReasons: PropTypes.array,
    canChange: PropTypes.bool,
    onUpdate: PropTypes.func,
};


export default translate('translations')(ChoseReason);