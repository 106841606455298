import React from "react";
import {Divider, Modal} from "antd";
import {translate} from "react-i18next";
import PropTypes from "prop-types";
import {Input, Icon, Row, Col, Popconfirm} from "antd";
import apiService from "../../apiService";
import {processResponse} from "../../../../../systems/api";
import lodash from "lodash";
import {events} from "../../../../../systems/events";
import TRANSACTION_EVENT from "../../../events";
import notification from "../../../../../systems/notification";
import {url} from "../../../../../systems/routing";
import Link from "../../../../../systems/routing/Link";
import ListMessageType from "../ListMessageType";
import RelatedTransactionCodeItem from "../RelatedTransactionCodeItem";


class RelatedTransactionModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            loading: false,
            errorMessage: "",
            successData: {}
        };
    }

    handleChange = e => this.setState({value: e.target.value, errorMessage: ""})

    handleOk = () => {
        this.setState({loading: true});
        const {transaction} = this.props;
        const codes = this.state.value.split('\n');
        let res = apiService.addRelatedTransaction(transaction.id, {
            codes: codes.map(code => code.trim())
        });
        let process = processResponse(res);
        process.then(res => {
            this.setState({
                errorMessage: false,
                successData: lodash.get(res, "data.transactionRelated", {}),
                loading: false,
                value: ""
            }, () => {
                events.dispatch(TRANSACTION_EVENT.ADD_RELATED_TRANSACTION, {
                    transaction: {...transaction, ...(lodash.get(res, "data.transaction", {}))}
                });
            });
        });

        process.catch((res) => {
            this.setState({
                errorMessage: "Danh sách mã giao dịch liên quan không hợp lệ",
                loading: false
            });
        });
    };

    handleCancel = () => {
        this.props.hideModal();
        this.setState({
            value: "",
            errorMessage: "",
            successData: {}
        });
    };

    render() {
        const {t, transaction} = this.props;
        const {successData, errorMessage} = this.state;
        const transactionRelated = lodash.get(transaction, "transactionRelated", []);
        const transactionRelatedTo = lodash.get(transaction, "transactionRelatedTo", {});

        return <>
            <Modal
                width={460}
                title="Giao dịch liên quan"
                visible={this.props.isShown}
                centered={true}
                className={"a-modal--related-transaction"}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                cancelText="Hủy"
                okText="Gửi"
                okButtonProps={{disabled: (this.state.value.trim() === "") ? true : false, loading: this.state.loading}}
            >
                { (!lodash.isEmpty(transactionRelated) || !lodash.isEmpty(transactionRelatedTo)) &&
                    <>
                        <p className="a-text--bold pb-2">Danh sách giao dịch liên quan</p>
                        <div>
                            { !lodash.isEmpty(transactionRelated) && transactionRelated.map((item, index) => {
                                return transaction.canDeleteRelatedTransaction ?
                                    <RelatedTransactionCodeItem transaction={transaction} related_transaction={item} key={index} /> :
                                    <Row className="pb-1" key={index}><Col span={24}>{item.code}</Col></Row>;
                            })}

                            { !lodash.isEmpty(transactionRelatedTo) &&
                                <Row>
                                    <Col span={24}>
                                        <Link
                                            isNewTab={true}
                                            href={url.to('transaction.detail', {id: transactionRelatedTo.id})}
                                            className="link_item">
                                            {transactionRelatedTo.code}
                                        </Link>
                                    </Col>
                                </Row>
                            }
                        </div>
                        <Divider className="mt-4 mb-5" />
                    </>
                }

                { transaction.canUpdateRelatedTransaction && <>
                        <Input.TextArea
                            onChange={this.handleChange}
                            rows={4}
                            placeholder="Nhập giao dịch liên quan"
                            value={this.state.value}
                        ></Input.TextArea>
                    </>
                }

                { successData && <>
                        <ListMessageType data={lodash.get(successData, "valid", [])} type="valid" />
                        <ListMessageType data={lodash.get(successData, "invalid", [])} type="invalid" />
                        <ListMessageType data={lodash.get(successData, "not_exist", [])} type="not_exist" />
                        <ListMessageType data={lodash.get(successData, "already_exist", [])} type="already_exist" />
                    </>
                }

                { errorMessage &&
                    <p className="pt-1 a-text--red a-font--12">
                        <Icon type="warning" theme="filled" /> {errorMessage}
                    </p>
                }
            </Modal>
        </>;
    }
}

export default translate()(RelatedTransactionModal);