import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';

function CrawlError({errors}) {
    return errors.map((error, index) => <div key={index}>{error.message}</div>);
}

CrawlError.defaultProps = {
    errors: [],
};

CrawlError.propTypes = {
    errors: PropTypes.array,
};

export default translate()(CrawlError);

