import {all, fork} from 'redux-saga/effects';
import OrderDetailWatcher from './OrderDetail/sagaWatcher';
import UpdateFinanliOrder from './UpdateFinanliOrder/sagaWatcher';
import UpdateWrongProcess from './UpdateWrongProcess/sagaWatcher';

export default function* () {
    yield all([
        fork(OrderDetailWatcher),
        fork(UpdateFinanliOrder),
        fork(UpdateWrongProcess),
    ]);
}
