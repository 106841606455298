import * as Constants from './constants';
import service from './apiService';
import {processApiRequest} from '../common/saga';
import lodash from 'lodash';
import authService from '../Auth/authService';

/**
 * get detail customer current logging
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getMe() {
    yield processApiRequest(Constants.GET_ME, () => service.getMe());
}

/**
 * Xoá toàn bộ dữ liệu lưu trong local storage và token lưu trong cookie, sau đó redirect qua trang home
 * TODO xem xét việc có call API để xoá token trên server hay không hiện tại là không, hiện tại đang để là không xoá
 */
export function logout() {
    localStorage.removeItem('token_customer');
    localStorage.removeItem('user');
    setTimeout(() => {
        authService.logout();
    }, 100);
}


/**
 * get detail customer current logging
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getPurchasers(action = {}) {
    let filters = lodash.get(action, 'payload.filters', {});
    yield processApiRequest(Constants.GET_LIST_PURCHASER, () => service.getPurchasers(filters));
}

export function* fetchUsers(action) {
    yield processApiRequest(Constants.FETCH_USERS, () => service.list(action.payload.filter));
}

/**
 * get detail customer current logging
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchAccount(action = {}) {
    yield processApiRequest(Constants.FETCH_ACCOUNT, () => service.fetchAccount(action.payload));
}

/**
 * Lấy danh sách giao dịch viên khiếu nại
 *
 * @param action
 */
export function* fetchUsersComplaintSeller(action) {
    yield processApiRequest(Constants.FETCH_USERS_COMPLAINT_SELLER, () => service.listUsersComplaintSeller(action.payload.filter));
}
