import React from 'react'
import {translate} from 'react-i18next';
import Spinner from "@atlaskit/spinner/dist/esm/Spinner/index";
import Input from "../../../../common/components/Input/Super";
import {CopyToClipboard} from "react-copy-to-clipboard";
import bootbox from "../../../../common/components/Bootbox/bootbox";
import {ModalTransition} from "@atlaskit/modal-dialog";
import ModalConfirmDelete from "./ModalConfirmDelete";
import lodash from "lodash";
import {trans} from './../../../../../systems/i18n';
import ModalFreightDuplicate from './ModalFreightDuplicate';

class FreightBill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModelConfirmShow: false,
            isLoading: false,
            freight_bill_delete: null,
            freight_bill: '',
            freight_bill_id: '',
            freightBills: null,
            freightBillErrors: null,
            isSyncBillNo: false,
            order: {}
        };

        this.isFocus = false;
        this.inputFocus = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = {...prevState};
        if (JSON.stringify(nextProps.order.freightBills) !== JSON.stringify(prevState.freightBills)) {
            newState.freightBills = nextProps.order.freightBills;
            newState.isLoading = false;
            newState.freight_bill = '';
            newState.isModelConfirmShow = false;
            newState.freight_bill_delete = null;
        }

        if (JSON.stringify(nextProps.order) !== JSON.stringify(prevState.order)) {
            newState.order = nextProps.order;
            newState.isSyncBillNo = false;
        }

        if (JSON.stringify(nextProps.freightBillErrors) !== JSON.stringify(prevState.freightBillErrors)) {
            newState.freightBillErrors = nextProps.freightBillErrors;
            newState.isLoading = false;
        }

        return newState;
    }

    componentDidUpdate() {
        if (this.isFocus) {
            this.isFocus = false;
            this.inputFocus.focus();
        }
    }

    /**
     * handle action close confirm before delete modal
     */
    closeModal() {
        this.setState({
            isModelConfirmShow: false,
        });
    }

    /**
     * handle open popup confirm
     * @param freightBill
     */
    openModalConfirmDelete(freightBill) {
        this.setState({
            isModelConfirmShow: true,
            freight_bill_delete: freightBill.freight_bill,
            freight_bill_id: freightBill.id
        });
        this.props.beginProcessDeleteInvoiceCode();
    }


    /**
     * handle action put request delete invoice_code
     */
    onDeleteFreightBill() {
        let {order, freight_bill_delete} = this.state;
        this.props.deleteFreightBill({
            id: order.id,
            freight_bill: freight_bill_delete,
        });
    }

    generateInvoiceCodeError() {
        let {t, freightBillErrors} = this.props;
        let errors = lodash.get(freightBillErrors, 'freight_bill', {});

        let message = '';
        Object.keys(errors).map(key => {
            if (Object.keys(errors[key]).length > 0) {
                Object.keys(errors[key]).map(error => {
                    message = t(`orderDetail:order_detail.freight_bill_errors.${key}.${error}`, {
                        value: errors[key][error]
                    });
                    return error;
                })
            } else {
                message = t(`orderDetail:order_detail.freight_bill_errors.${key}`);
            }

            return key;
        });

        return message;
    }

    onChangeInput = event => {
        if (typeof  event !== "object") {
            this.setState({freight_bill: event});
            this.props.clearFreightBillErrors();
        }
    };

    /**
     * Close input
     * @param event
     */
    onCloseEdit(event) {
        const {order} = this.props;
        let {freight_bill} = this.state;
        let value = freight_bill.trim();
        value = value.replace(/\s/g, "");
        value = value.replace(/[^\w\s-_//]/gi, '');
        if (value.length > 0) {
            this.setState({isLoading: true});
            this.props.addFreightBill({
                freight_bill: value,
                id: order.id
            });
        }
    }

    /**
     * Freight bill input on keypress
     * @param event
     */
    addFreightBill = event => {
        if (event.key === 'Enter') {
            const {order} = this.props;
            this.props.clearErrorWhilePostInvoiceCode();
            let value = event.target.value;
            this.setState({freight_bill: value});
            if (value) {
                this.setState({isLoading: true});
                this.props.addFreightBill({
                    freight_bill: value,
                    id: order.id
                });
            }
        }
    };

    /**
     * Sync order bill no
     * @param event
     */
    onHandleSyncOrderBillNo(event) {
        const {order, syncOrderBillNo} = this.props;
        this.setState({isSyncBillNo: true});
        syncOrderBillNo({id: order.id});
    }

    render() {
        const {t, order, freightBillDuplicateInOrders} = this.props;
        const {isModelConfirmShow, isLoading, freight_bill, freight_bill_delete} = this.state;
        let freightBills = lodash.get(order, 'freightBills', []);

        let freight_bill_in_others = lodash.get(freightBillDuplicateInOrders, 'freight_bill_in_others', {});
        let freight_bill_duplicate = lodash.get(freightBillDuplicateInOrders, 'freight_bill', null);
        let freight_bill_alphabet = lodash.get(order.agency, "freight_bill_alphabet", 0);
        return (
            <div className="a-purchasod__group__invoice_code mt-4">
                <span className="a-purchasod__group--label a-purchasod__group--label--invoice">
                    {t("orderDetail:order_detail.freight_bill")}
                    <span className={this.state.isSyncBillNo ? "mr-1 mt-1 ml-2" : "hidden"}>
                        <Spinner size="small" isCompleting={false}/>
                    </span>
                    {
                        (order.canAddFreightBill && !this.state.isSyncBillNo && order.seller_source === '1688') &&
                        <i className="fa fa-download a--cursor-poiter ml-2" onClick={event => this.onHandleSyncOrderBillNo(event)}/>
                    }
                </span>
                <div className="a-purchasod__group--special d-flex" style={{flexFlow: "wrap"}}>
                    {
                        order.canAddFreightBill &&
                        <div className="a-purchasod__group--code--input">
                            <div className="a-purchasod__group--bill-code d-flex">
                                <div className={'d-inline-flex a-purchasod__group--bill-code__input'}>
                                <span className={isLoading ? "mr-1 mt-1" : "hidden"}>
                                    <Spinner size="small" isCompleting={!isLoading}/>
                                </span>
                                <Input
                                    integerHasBlank={!freight_bill_alphabet}
                                    regexInput={freight_bill_alphabet ? /^\S*$/ : false}
                                    focus
                                    notReplaceZeroFirst
                                    className={this.generateInvoiceCodeError() === "" ? "" : "input--error"}
                                    placeholder={t("orderDetail:order_detail.add_freight_bill")}
                                    onKeyPress={this.addFreightBill}
                                    onChange={this.onChangeInput}
                                    name="freight_bull"
                                    value={freight_bill}
                                    disabled={isLoading}
                                    onRef={input => {
                                        this.inputFocus = input
                                    }}
                                />
                            </div>
                            <p className={this.generateInvoiceCodeError() === "" ? "hidden" : "a-font--11 a-text--left a-text--red mt-2 mr-2"}
                               dangerouslySetInnerHTML={{__html: this.generateInvoiceCodeError()}}/>
                            {
                                order.isExitedInOtherParner &&
                                <p className="a-font--11 text-warning mt-2 mr-2">{t("order_detail.exist_other_partner")}</p>
                            }
                        </div>
                        </div>
                    }

                    <a onClick={event => this.onCloseEdit(event)}
                       className={'bill-code--cancel '+( freight_bill ===""? "hidden":"" )}>
                        {t('order:detail.invoice_save')}
                    </a>

                    {
                        lodash.orderBy(freightBills, ['id'], 'desc').map((freightBill, index) => (
                            <div key={index} className="a-purchasod__group--code">
                                <CopyToClipboard text={freightBill.freight_bill} onCopy={() => {
                                    bootbox.success(t("message.copied") + ": " + freightBill.freight_bill)
                                }}>
                                    <i className="far fa-copy copy"/>
                                </CopyToClipboard>
                                <span className={'nowrap mr-2'} title={freightBill.freight_bill}>
                                    {(freightBill.freight_bill.length > 30 ? freightBill.freight_bill.substr(0, 30) + '...' : freightBill.freight_bill)}
                                </span>
                                {
                                    order.canRemoveFreightBill &&
                                    <i className="ion-android-close remove" onClick={event => this.openModalConfirmDelete(freightBill)}/>
                                }
                            </div>
                        ))
                    }
                </div>
                <ModalTransition>
                    {isModelConfirmShow && (
                        <ModalConfirmDelete
                            isLoading={isLoading}
                            title={t('account_purchaser.header_modal_change_status')}
                            message={trans('orderDetail:order_detail.freight_bill_remove_message', {
                                freight_bill: <b>{freight_bill_delete}</b>
                            })}
                            onClose={this.closeModal.bind(this)}
                            onConfirm={this.onDeleteFreightBill.bind(this)}
                        />
                    )}

                </ModalTransition>
                {
                    lodash.isEmpty(freight_bill_in_others) ?
                        null :
                        (<ModalFreightDuplicate key={order.id}
                                                title={t('modal.title_model_alert')}
                                                addFreightBill={this.props.addFreightBill}
                                                onClose={() => this.props.clearFreightBillDuplicate(order.id)}
                                                freight_bill_in_others={freight_bill_in_others}
                                                freight_bill={freight_bill_duplicate}
                                                id_order={order.id}
                                                isLoading={isLoading}
                                                onCloseEdit={event => this.onCloseEdit(event)}
                            />
                        )
                }
            </div>
        )
    }
}

export default translate('translations')(FreightBill);
