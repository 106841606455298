import React from 'react';
import {translate} from 'react-i18next';
import Checkbox from '../../../common/components/Checkbox/index';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import ServicesItem from './ServicesItem';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active';
import {
    DEFAULT_EMPTY_DATA,
    DEFAULT_EMPTY_NUMBER_DATA,
    ORDER_STATUS_WITH_CLASS
} from './../../constants';
import lodash from "lodash";
import {currentFormatter} from '../../../common/services/format';
import NoticeConfirm from './NoticeConfirm';
import Link from "../../../../systems/routing/Link";
import Timer from "../../../common/components/Timer";
import InvoiceCodes from './InvoiceCodes';
import bootbox from './../../../common/components/Bootbox/bootbox';

class ItemPurchase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invoice_show: false
        }
    }

    /**
     * Show more invoice source
     */
    onClickInvoiceShow() {
        this.setState({invoice_show: true});
    }

    /**
     * generate class for status block
     */
    _generateStatusClass(orderStatus) {
        return "a-status a-status--" +
            ((orderStatus && ORDER_STATUS_WITH_CLASS[orderStatus]) ?
                ORDER_STATUS_WITH_CLASS[orderStatus].className : "wating-buy");
    }

    render() {
        let {t, order} = this.props;
        let currencySymbol = lodash.get(order, "currencySymbol",  '¥');
        let grandTotal = lodash.get(order, "grand_total", DEFAULT_EMPTY_NUMBER_DATA);
        let grandTotalVnd = lodash.get(order, "grand_total_converted", DEFAULT_EMPTY_NUMBER_DATA);
        grandTotalVnd = grandTotalVnd ? parseFloat(grandTotalVnd) : DEFAULT_EMPTY_NUMBER_DATA;
        grandTotal = grandTotal ? parseFloat(grandTotal) : DEFAULT_EMPTY_NUMBER_DATA;

        grandTotal = currentFormatter.toLocaleStringCurrency(grandTotal, 5) + " " + currencySymbol;

        grandTotalVnd = currentFormatter.toLocaleStringCurrencyVND(grandTotalVnd);

        grandTotalVnd += ' đ';
        let updateQuantity = order.note_buyer_confirm_message();
        let account_purchaser = lodash.get(order, "account_purchaser", null);

        return (
            <React.Fragment>
                <div className={"a-item purchase " + (order.read_comment ? "icon-has-read-comment" : "")}>
                    <div className="a-item__top">
                        <ul>
                            <li className={'order--2'}>
                                {<Checkbox
                                    onChange={this.onChange}
                                    name="checkbox-basic"
                                />}

                                {
                                    order.read_comment ?
                                        null
                                        :
                                        (<span className="icon-wait-read-comment"><PresenceActiveIcon/></span>)
                                }
                            </li>
                            <li className="a-item__account block-paing w_100-30__lg order--1">
                                <Link to="purchase.buying.detail" params={{id: order.id}} isNewTab={true}>
                                    <img src={order.order_item_image || require('../resource/default-thumbnail.jpg')}
                                         onError={(e) => {
                                             e.target.onerror = null;
                                             e.target.src = require('../resource/default-thumbnail.jpg')
                                         }}
                                         alt="Avatar"/>
                                </Link>
                                <div className="a-item__account__infor">
                                    <div className="a-item__account__name">
                                        <span
                                           style={{ background: lodash.get(order, "agency.color", "") }}
                                           className={"ml-4 header_partner header_partner--" + order.agency_code}
                                        >
                                            {order.agency_alias}
                                        </span>

                                        <div className="flex-start">
                                            <Link to="purchase.buying.detail" params={{id: order.id}} isNewTab={true}>
                                                {order.code}
                                            </Link>
                                            <CopyToClipboard text={order.code} onCopy={() => {
                                                bootbox.success(t("message.copied") + ": " + order.code)
                                            }}>
                                                <i className="far fa-copy copy"/>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                    <span
                                        className="a-item__account__username">@{order.buyer_username || DEFAULT_EMPTY_DATA}</span>
                                    <p className="a-item__account__time-ordering"><CalendarIcon size={"small"}/> <span
                                        className="a-item__account__time-detail">{t('order:list.deposit')}: {!order.order_partner_time_deposit_format ? DEFAULT_EMPTY_DATA :
                                        order.order_partner_time_deposit_format}</span>
                                    </p>
                                </div>
                            </li>
                            <li className="a-item__block block-paing w_50__lg order--6 ml-1">
                                <div className="a-item__block__shop">
                                    <span>{
                                        order.wrong_process
                                            ? t('order:label.wrong_process')
                                            : <img style={{"height": "15px", "width": "15px"}}
                                                   src={"http://nhaphang.com/vp-assets/images/favicons_" + order.seller_source + ".png"}
                                                   onError={(e) => {
                                                       e.target.onerror = null;
                                                       e.target.src = require('../resource/u32.png')
                                                   }}
                                                   alt="avatar_seller"/>
                                    }
                                </span>
                                </div>
                                <div className={'a-item__block__purchase-2 flex-end-md'}>
                                    <InvoiceCodes
                                        countInvoiceCodeDisplay={order.countInvoiceCodeDisplay}
                                        invoiceCodes={order.source_invoice}
                                    />
                                </div>
                            </li>
                            <li className="a-item__block block-paing w_50__lg order--5 ">
                                <div className={'a-item--group'}>
                                    <p title={t('order:list.warehouse_code')} style={{color: '#333333'}}>
                                        {order.purchase_address_alias ? order.purchase_address_alias : '---'}
                                    </p>
                                </div>
                                <div className={'a-item--group'}>
                                    <p title={t('orderDetail:order_detail.account_buyer')}>
                                        {order.account_purchaser_account ? '@' + order.account_purchaser_account : '---'}
                                    </p>
                                </div>

                            </li>
                            <li className="a-item__block w_50__lg order--3 ">
                                <p className="a-item__block__total">{t('order:list.total')}:
                                    <span>{order.total_item_qty_bought || DEFAULT_EMPTY_NUMBER_DATA}</span>{t('order:list.product')}/
                                    <span>{order.total_item_link || DEFAULT_EMPTY_NUMBER_DATA}</span>{t('order:list.link')}
                                </p>
                                <p className="a-item__block__total--money">{grandTotalVnd}&nbsp;
                                    - <span
                                        className="a-text--red">{grandTotal}</span>
                                    {order.free_ship || order.total_shipping_fee_inland ?
                                        (<span className="a-text--blue a-font--11"
                                               title={t('order_detail.total_shipping_fee_inland')}
                                               style={{color: '#0052CC', marginLeft: '0px'}}>
                                        ({order.free_ship ?
                                            t('orderDetail:order_detail.free') :
                                            order.total_shipping_fee_inland_text
                                        })
                                    </span>) :
                                        null
                                    }
                                </p>
                                {order.complaintSellerTotal !== 0 ?
                                    <div className="a-item__block__is_complaintseller mt-3 mb-1">
                                        <div className="a-item__block__complaintseller mr-5"
                                             onClick={() => window.open(order.urlComplaintByOrder, "_blank")}
                                        >
                                            <Link>{t('order:list.KNNB')}</Link>
                                            <span>{order.complaintSellerTotalFormat}</span>
                                        </div>
                                    </div> : null}
                            </li>

                            <li className="a-item__block w_50__lg order--4 flex-end-md">
                                {order.previous_status &&
                                <div className="float--left mr-2">
                                    <span className={this._generateStatusClass(order.status)}>
                                        {t("order:status." + order.status)}
                                    </span>
                                    <br/>
                                    <span className="a-text--font-b a-item__block__time__waiting hidden-md">
                                        {order.logTimeStatusCurrent ? order.logTimeStatusCurrent : DEFAULT_EMPTY_DATA}
                                    </span>
                                </div>}
                            </li>
                        </ul>
                    </div>
                    <div className="a-item__bottom purchase">
                        <div className={"ml-2"}>
                            {order.buying_policy_time &&
                            <span className={"a-item__bottom__timing " + order.buying_policy_priority}>
                            <Timer
                                date={order.buying_policy_time_remaining || ""}
                                textBefore={t('order:detail.remaining')}
                                textAfter={t('order:detail.to_buying')}
                                overTimeText={t('order:status.OVERDUE_PURCHASE')}
                                timeBold
                                time_now={order.time_now}
                            />
                        </span>}
                        </div>
                        <ServicesItem services={order.services}
                                      account_purchaser={account_purchaser}
                        />
                    </div>
                </div>
                {order.buyer_confirm && updateQuantity.length ? (
                    <NoticeConfirm
                        order={order}
                        updateQuantity={order.note_buyer_confirm_message()}
                        onConfirmCustomerOrderBuying={this.props.onConfirmCustomerOrderBuying}
                    />
                ) : null}
            </React.Fragment>
        )
            ;
    }
}

ItemPurchase.defaultProps = {
    order: {},
    partner: {id: 0, code: "", domain: ""},

};

ItemPurchase.propTypes = {
    order: PropTypes.object,
    partner: PropTypes.object,
};


export default translate('translations')(ItemPurchase);
