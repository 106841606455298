import React, { useState } from 'react';
import moment from 'moment';
import { Button, notification } from 'antd';
import {trans, t} from "../../../../systems/i18n";
import apiService from "../../apiService";
import {processResponse} from "../../../../systems/api";
import lodash from "lodash";

const ExportCSV = (props) => {
	const { filter } = props;
	const [loading, setLoading] = useState(false);

	function exportExcel() {
        setLoading(true);
		processResponse(apiService.exportComplaintSeller(filter))
			.then(res => {
                notification.success({ message: t('complaintSeller:message.export_excel_success') });
			})
			.catch(err => {
				let response = lodash.get(err, "data", {});
				let {log_status_to, log_status_from} = filter;

				if (!log_status_to && !log_status_from) {
					notification.error({ message: t('complaintSeller:message.time_is_required') });
					return;
				}

				if (log_status_from) {
					const timeFrom = moment(log_status_from);
					const timeTo = moment(log_status_to);
					if (timeTo.diff(timeFrom, 'days') >= 31) {
						notification.error({ message: t('complaintSeller:message.not_over_one_month') });
						return;
					}
				}

				if (!lodash.isEmpty(response)) {
					Object.keys(response).forEach(error => {
						Object.keys(response[error]).forEach(errorKey => {
							notification.error({ message: trans(`complaintSeller:message.${errorKey.toLowerCase()}`, { attribute: t(`complaintSeller:time_type.${error.toLowerCase()}`) }) });
						})
					});
					return;
				}

                notification.error({ message: t('complaintSeller:message.export_excel_failed') });
			})
            .finally(() => setLoading(false));
	}

	return (
		<Button
			className='a-btn--primary-ant a-text--fz-14 ghost ml-3 _complain-seller-export-csv'
			onClick={exportExcel}
			loading={loading}
		>
			{t('paymentRequest:btn.export')}
		</Button>
	);
};

export default ExportCSV;
