import React from 'react';
import {translate} from 'react-i18next';
import {Link} from '../../../systems/routing';
import {withEvent} from '../../../systems/events';
import * as PERMISSION from '../../Permission/permissions';
import authService from '../../Auth/authService';
import SETTING_EVENT from '../events';
import {permissions} from "../../Permission";
import {TAOBAO_GET_PURCHASE_ORDER} from "../../Permission/permissions";

class SettingMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            activeItem: null,
        };

        this.menu = [
            {
                key: 'utilities',
                items: [
                    {
                        key: 'account_purchaser',
                        href: '/account-purchaser',
                        permission: [PERMISSION.ACCOUNT_PURCHASER_VIEW],
                    },
                    {
                        key: 'purchase-address',
                        href: '/purchase-address',
                        permission: [PERMISSION.PURCHASE_ADDRESS_VIEW],
                    },
                    {
                        key: 'slow-purchasing-reason',
                        href: '/slow-purchasing-reason',
                        permission: [PERMISSION.SETTING_VIOLATION_CAUSE],
                    },
                    {
                        key: 'order-group',
                        href: '/order-group',
                        permission: [PERMISSION.ORDER_GROUP_VIEW],
                    },
                    {
                        key: 'purchasers',
                        href: '/purchasers',
                        permission: [PERMISSION.PURCHASER_MANAGE],
                    },
                    {
                        key: 'user_access_token',
                        href: '/user/access-token',
                    },
                    {
                        key: 'account_purchaser_token',
                        href: '/account-purchaser-token',
                        permission: [permissions.SETTING_ACCESS_TOKEN_ALIBABA],
                    },
                    {
                        key: 'agency',
                        href: '/agency',
                        permission: [permissions.SETTING_AGENCY],
                    },
                    {
                        key: 'bank_account',
                        href: '/bank-account',
                        permission: [permissions.SETTING_BANK_ACCOUNTS]
                    },
                    {
                        key: 'group_manage',
                        href: '/group-manage',
                        permission: [permissions.PAYMENT_REQUEST_GROUP_MANAGE],
                    },
                ],
            },
            {
                key: 'configuration',
                items: [
                    {
                        key: 'setting-partner',
                        href: '/setting-partner',
                        permission: [PERMISSION.SETTING_GENERAL],
                    },
                    {
                        key: 'setting-buying',
                        href: '/setting-buying',
                        permission: [PERMISSION.SETTING_BUYING],
                    },
                    {
                        key: 'setting-transaction',
                        href: '/setting-transaction',
                        permission: [PERMISSION.SETTING_TRANSACTIONS],
                    },
                    {
                        key: 'setting-private',
                        href: '/setting-private',
                    },
                    {
                        key: 'connect-foreign-market',
                        href: '/connect-foreign',
                        permission: [PERMISSION.TAOBAO_GET_PURCHASE_ORDER]
                    },
                ],
            },
        ];
    }

    componentDidMount() {
        this.props.on({
            [SETTING_EVENT.ACTIVE_MENU_ITEM]: (event, payload) => this.setState({activeItem: payload.item}),
        });
    }

    getActiveMenu() {
        return this.menu.map(group => ({
            ...group,
            items: group.items.filter(item => !(item.permission && !authService.canAny(item.permission))),
        }))
            .filter(group => !!group.items.length);
    }
    toggleMenu() {
        this.setState({isOpen: !this.state.isOpen});
    }

    render() {
        let {isOpen, activeItem} = this.state;
        let {t} = this.props;

        return (
            <React.Fragment>
                <div className={'open_c_sidebar ' + (!isOpen ? 'hidden' : '')}/>
                <div className={'a-content__sidebar a-content--box-shadow  ' + (isOpen ? 'c_sidebar__collapse' : '')}>
                    <a className="button_toggle" onClick={this.toggleMenu.bind(this)}
                    ><i className="fas fa-bars"/></a>

                    {this.getActiveMenu().map(group => {
                        return (
                            <React.Fragment key={group.key}>
                                <p className="c_sidebar__title">
                                    {t('theme.menu.group_' + group.key)}
                                </p>
                                <ul>
                                    {group.items.map((item, index) => (
                                        <li
                                            key={index}
                                            className={activeItem === item.key ? "active" : ""}
                                        >
                                            <Link href={item.href}>{t('theme.menu.' + item.key)}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </React.Fragment>
                        )
                    })}
                </div>
            </React.Fragment>
        );
    }
}

export default translate()(withEvent(SettingMenu));
