import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from './actions';
import UploadCsv from './components';
import lodash from 'lodash';

const mapStateToProps = (state, props) => {
    return {
        loading: props.loading  === true || lodash.get(state, 'Transaction.UploadTransactionFile.loading', false)
    }
};

const mapDispatchToProps = (dispatch) => ({
    uploadFileCSVSuccess: (info) => dispatch(actions.uploadFileCSVSuccess(info)),
    uploadFileCSVFailed: (info) => dispatch(actions.uploadFileCSVFailed(info)),
    uploadFileCSVRequest: (info) => dispatch(actions.uploadFileCSVRequest(info)),
    clearState: () => dispatch(actions.clearState()),
});

class SelectUserContainer extends React.PureComponent {
    render() {
        return <UploadCsv {...this.props}/>
    }
}

let UploadFileCSVGlobalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectUserContainer);

UploadFileCSVGlobalContainer.defaultProps = {
    value: null,
    autoSelect: false,
    onChange: value => {
    },
};

UploadFileCSVGlobalContainer.propTypes = {
    autoSelect: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default UploadFileCSVGlobalContainer;
