import {createSelector} from 'reselect';
import lodash from "lodash";
import {dateFormatter} from "../common/services/format";

const ordersSelector = state => lodash.get(state, 'OrderBillNo.list', []);

/**
 * Get list order
 */
export const getOrders = createSelector(
    ordersSelector,
    orders => orders.map(order => orderDetail(order))
);

function orderDetail({...order}) {
    order.time_teller_format = dateFormatter.full(order.time_teller);
    order.time_paid_staff_format = dateFormatter.full(order.time_paid_staff);
    order.order_partner_time_deposit_format = dateFormatter.full(order.order_partner_time_deposit);

    return order;
}