import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import FormProcessing from '../../../common/components/FormProcessing';
import DropdownButton from '../../../common/components/DropdownButton';
import Form from '@atlaskit/form';
import lodash from 'lodash';
import {PLACEHOLDER} from "../../constants";
import FormSearchOrderItem from './FormSearchOrderItem';
import FormSearchAccountItem from "./FormSearchAccountItem";
import Checkbox from '../../../common/components/Checkbox/index';
import Select from "@atlaskit/select/dist/esm/Select";
import DatetimePicker from "../../../common/components/DatetimePicker";

const siteItems = [
    {label: 'taobao', value: 'taobao'},
    {label: 'tmall', value: 'tmall'},
    {label: '1688', value: '1688'},
];

const stateDefault = {
    code: '',
    buyer_username: '',
    id_purchaser: [],
    seller_source: [],
    id_teller: [],
    total_item_link: '',
    real_amount_compare: '',
    grand_total_to: '',
    grand_total_from: '',
    log_status_to: '',
    log_status_from: '',
    wrong_process: '',
    operation: '',
    sort: 'asc',
    id_purchase_address: []
};

class FormSearchHeaderMobile extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            input: stateDefault,
            inputVersion: 0,
            changedParams: [],
            showAdvance: false
        }
    }

    onChangeFormInput(param, value) {
        let input = this.changeInput({[param]: value});
        this.props.onSubmit(input);
    }

    onKeyPressInput(input, hiddenDropdown, openDropdown, event) {
        if (event.key === 'Enter') {
            if (hiddenDropdown) {
                hiddenDropdown();
            }
            this.props.onSubmit(input);
        } else {
            if (openDropdown) {
                openDropdown();
            }
        }
    }

    onKeyDown(input, hiddenDropdown, openDropdown, event) {
        if (event.keyCode === 8) {
            openDropdown();
        }
    }

    onChangeDateRange(event, time) {
        let input = this.changeInput({[event]: time});
        this.props.onSubmit(input);
    }

    onBlurInput() {
        this.props.onSubmit(this.state.input);
    }

    makeInputAccountItem(input) {
        let {accountPurchase = [], purchasers} = this.props;

        let id_purchaser = [];
        let id_teller = [];
        let purchaserArray = [];
        if (input.id_purchaser) {

            id_purchaser = lodash.head(accountPurchase.filter((account) => {
                return input.id_purchaser.find((purchaser) => {
                    return lodash.toInteger(purchaser) === lodash.toInteger(account.value);
                });
            }));

        }

        if (input.seller_source) {
            lodash.head(siteItems.filter((site) => {
                return input.seller_source.find((siteItem) => {
                    return siteItem.trim() === (site.value).trim();
                });
            }));
        }

        id_teller = input.id_teller;
        id_teller = id_teller ? id_teller.map(idTellerItem=> {
            return lodash.toInteger(idTellerItem)
        }) : '';
        if (id_teller) {
            purchaserArray = purchasers.map(purchaserItem=>{
                if (lodash.includes(id_teller, purchaserItem.value)){
                    return purchaserItem.label;
                } else{
                    return null;
                }
            });
            purchaserArray = lodash.compact(purchaserArray);
        }

        return {
            id_purchaser: lodash.get(id_purchaser, 'label', ''),
            seller_source: lodash.get(input, 'seller_source', []),
            id_teller: purchaserArray,
        };
    }

    makeInputPurchaserAddress(input) {
        let {purchaseAddresses = []} = this.props;

        let id_purchase_address = [];
        let buyer_username = '';
        if (input.id_purchase_address) {

            id_purchase_address = lodash.head(purchaseAddresses.filter((account) => {
                return input.id_purchase_address.find((purchaser) => {
                    return lodash.toInteger(purchaser) === lodash.toInteger(account.value);
                });
            }));

        }

        if (input.buyer_username) {
            buyer_username = input.buyer_username;
        }

        return {
            id_purchase_address: buyer_username.length ?
                ' - ' + lodash.get(id_purchase_address, 'label', '') : lodash.get(id_purchase_address, 'label', ''),
            buyer_username: buyer_username,
        };
    }

    //click quick search
    onClickQuickTime(quickTime) {
        let input = this.changeInput(quickTime);
        this.props.onSubmit(input);
    }

    /**
     * Xử lý submit ở các subform
     *
     * @param {Object} data
     */
    onSubmitSubForm(data) {
        let input = this.changeInput(data);
        this.props.onSubmit(input);
    }

    onChangeMultiSelect(param, select) {
        let value = select.map((selectItem) => {
            return selectItem.value;
        });
        let input = this.changeInput({[param]: value});
        this.props.onSubmit(input);
    }

    makeValueMultiSelect(select) {
        let {purchaseAddresses} = this.props;
        purchaseAddresses = purchaseAddresses.map(purchaseAddressesItem => {
            let label = purchaseAddressesItem.label;
            label = label.trim();
            label = label.length > 20 ? label.substr(0, 20) + '...' : label;
            return {...purchaseAddressesItem, ...{label: label}};
        });
        select = select.map((value) => lodash.parseInt(value));
        return purchaseAddresses.filter((purchaseAddressesItem) => {
            return select.includes(purchaseAddressesItem.value);
        });
    }

    onShowAdvance() {
        this.setState({showAdvance: !this.state.showAdvance});
    }

    render() {
        let {t, onSubmit, accountPurchase, purchaseAddresses, check_all, purchasers} = this.props;
        let {input, showAdvance} = this.state;

        return (
            <React.Fragment>
                <a className={'a-link mb-1 a-text--uppercase a-font--10'}
                        onClick={this.onShowAdvance.bind(this)}>
                    {t('order:list.advance_filter')}<i className={` ml-2 fas ${showAdvance ? "fa-angle-double-up": "fa-angle-double-down"}`}></i>
                </a>
                <ul className={'mt-2'}>
                    <li>
                        <div style={{width: '16px', marginLeft: '5px', marginRight: '25px'}}>
                            <Checkbox onChange={this.props.onCheckAll}
                                      key={"chk_all" + check_all}
                                      defaultChecked={check_all}/>
                        </div>
                    </li>
                    <li className={`flex-start mb-2 ${showAdvance? "": "hidden"}`}>
                        <div className="a-btn--filter-order flex-start filter-order--over  ">
                            <DropdownButton
                                type={PLACEHOLDER.FILTER}
                                input={this.makeInputPurchaserAddress(lodash.pick(input, ['buyer_username', 'id_purchase_address']))}
                                isMenuFixed={true}
                                content={(hiddenDropdown, openDropdown) => (
                                    <div className="a-form--dropdown--order">
                                        <Form name="order-code">
                                            <div className="a-form--input--order mt-3">
                                                <i className="ion-ios-search-strong"/>
                                                <label htmlFor=""
                                                       className="a-text--bold ">{t('order:list.buyer')}</label>
                                                <input type="text"
                                                       value={input.buyer_username || ''}
                                                       placeholder={t('order:list.buyer_placeholder')}
                                                       className="mt-2"
                                                       onBlur={this.onBlurInput.bind(this)}
                                                       onChange={this.onChangeInput.bind(this, 'buyer_username')}
                                                       onKeyPress={this.onKeyPressInput.bind(this, input, hiddenDropdown, openDropdown)}
                                                       onKeyDown={this.onKeyDown.bind(this, input, hiddenDropdown, openDropdown)}
                                                />
                                            </div>
                                            <div className={'a-form--dropdown--atlaskit__block pt-3'}>
                                                <div className={'a-form--dropdown--atlaskit__block__title'}>
                                                    <p className="a-text--font-primary">
                                                        {t('order:list.address_receive_china')}
                                                    </p>
                                                </div>
                                                <Select
                                                    style={{overflow: 'hidden !important'}}
                                                    noOptionsMessage={({inputValue: string}) => t('message.no_record')}
                                                    value={this.makeValueMultiSelect(input.id_purchase_address)}
                                                    onChange={this.onChangeMultiSelect.bind(this, 'id_purchase_address')}
                                                    placeholder={t('order:list.enter_address_receive_china')}
                                                    options={purchaseAddresses}
                                                    className="single-react"
                                                    classNamePrefix="react-select--filter_item_multi"
                                                    isMulti
                                                />
                                            </div>
                                        </Form>
                                    </div>
                                )}
                            />
                        </div>
                    </li>
                    <li className={`a-btn--filter-order filter-order--over mb-2 ${showAdvance? "": "hidden"}`}>
                        <DropdownButton
                            type={PLACEHOLDER.ACCOUNT}
                            isMenuFixed={true}
                            output={this.makeInputAccountItem(lodash.pick(input, ['id_purchaser', 'seller_source']))}
                            onSubmit={onSubmit}
                            content={(hiddenDropdown, openDropdown) => (
                                <Form>
                                    <FormSearchAccountItem
                                        input={lodash.pick(input, ["id_purchaser", "seller_source"])}
                                        accountPurchase={accountPurchase}
                                        purchasers={purchasers}
                                        siteItems={siteItems}
                                        onSubmit={this.onSubmitSubForm.bind(this)}
                                        hiddenDropdown={hiddenDropdown}
                                        openDropdown={openDropdown}
                                    />
                                </Form>
                            )}
                        />
                    </li>
                    <li className={`a-btn--filter-order mb-2 ${showAdvance? "": "hidden"}`}>
                        <DropdownButton
                            type={PLACEHOLDER.VALUE}
                            isMenuFixed={true}
                            input={lodash.pick(input, [
                                'total_item_link_operation',
                                'total_item_link',
                                'grand_total_from',
                                'grand_total_to',
                                'complaint_seller',
                                'wrong_process'
                            ])}
                            content={(hiddenDropdown, openDropdown) => (
                                <FormSearchOrderItem
                                    input={lodash.pick(input, [
                                        'total_item_link_operation',
                                        'total_item_link',
                                        'grand_total_from',
                                        'grand_total_to',
                                        'complaint_seller',
                                        'wrong_process'
                                    ])}
                                    inputVersion={this.props.inputVersion}
                                    onSubmit={this.onSubmitSubForm.bind(this)}
                                    hiddenDropdown={hiddenDropdown}
                                    openDropdown={openDropdown}
                                />
                            )}
                        />
                    </li>
                    <li className={`a-btn--filter-order mb-2 ${showAdvance? "": "hidden"}`}>
                        <DropdownButton
                            type={PLACEHOLDER.TIME}
                            input={lodash.pick(input, ["log_status_from", "log_status_to"])}
                            position="bottom right"
                            content={() => (
                                <div className={'a-form--dropdown--atlaskit'}>
                                    <div
                                        className={'a-form--dropdown--atlaskit__block select_time a-form--input--border-b mt-3 pb-4'}>
                                        <div className={'a-form--dropdown--atlaskit__block__title'}>
                                            <p className={'a-text--font-primary'}>{t('order:list.from')}</p>
                                        </div>
                                        <DatetimePicker
                                            name={'log_status_from'}
                                            date={lodash.get(input, "log_status_from")}

                                            placeholderText={t("order:list.log_status_placeholder")}
                                            onSubmit={this.onSubmitSubForm.bind(this)}
                                        />
                                    </div>
                                    <div className={'a-form--dropdown--atlaskit__block select_time pt-3'}>
                                        <div className={'a-form--dropdown--atlaskit__block__title'}>
                                            <p className={'a-text--font-primary'}>{t('order:list.to')}</p>
                                        </div>
                                        <DatetimePicker
                                            name={'log_status_to'}
                                            date={lodash.get(input, "log_status_to")}

                                            placeholderText={t("order:list.log_status_placeholder")}
                                            onSubmit={this.onSubmitSubForm.bind(this)}
                                        />
                                    </div>
                                </div>

                            )}
                        />

                    </li>
                    <li className={'seach-pre-next'}>
                        <div className={'a-btn--pre_next'}>
                            <button className={input.sort === 'asc' ? "active" : ''}
                                    onClick={e => this.onChangeFormInput('sort', 'asc')}>{t('order:list.filter_asc')}</button>
                            <button className={input.sort === 'desc' ? "active" : ''}
                                    onClick={e => this.onChangeFormInput('sort', 'desc')}>{t('order:list.filter_desc')}</button>
                        </div>
                    </li>
                </ul>
            </React.Fragment>
        );
    }
}

FormSearchHeaderMobile.defaultProps = {
    input: {},
    inputVersion: 0,
    onSubmit: input => {
    },
};

FormSearchHeaderMobile.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    onSubmit: PropTypes.func,
};

export default translate()(FormSearchHeaderMobile);
