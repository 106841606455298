import React, {Component} from "react";
import PropTypes from 'prop-types';
import lodash from "lodash";
import {Icon, Modal} from "antd";
import apiService from "../apiService";
import {processResponse} from "../../../systems/api";
import {events} from "../../../systems/events";
import ACCOUNT_PURCHASER from "../events";
import bootbox from "../../common/components/Bootbox/bootbox";

class UnassignAccountPurchaser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }
    confirm() {
        Modal.confirm({
            className: "a-modal--confirm-trade",
            title: 'Bỏ gán tài khoản mua hàng',
            content: 'Bạn có muốn bỏ gán tài khoản mua hàng khỏi GDV không?',
            cancelText: 'Hủy',
            okText: 'Đồng ý',
            onOk: this.detachPurchaserFromTeller,
            zIndex: 1050,
            icon: <Icon type="question-circle" theme="filled"/>,
        });
    }
    detachPurchaserFromTeller = () => {
        if (this.state.loading) {
            return;
        }

        let {t, account, username} = this.props;

        this.setState({loading: true});

        let res = apiService.unassignAccountPurchaser(account.id)
            .finally(() => this.setState({loading: false}));

        let process = processResponse(res);

        process.then(res => {
            let {tellers} = this.props;
            tellers = tellers.map(teller =>
                teller.id === res.data.user.id ?
                    {
                        ...teller,
                        user_account_purchasers: lodash.remove(teller.user_account_purchasers, function(account) {
                            return account.id !== res.data.userAccountPurchaser.id;
                        })
                    } :
                    teller
            );
            bootbox.success(t('purchaser:message.unassign_account_purchaser_success', {
                site: lodash.get(account, 'site'),
                user: username,
                account: lodash.get(account, 'account_purchaser.account'),
            }));
            events.dispatch(ACCOUNT_PURCHASER.UPDATED, {
                tellers: [...tellers]
            });
        });

        process.catch(() => {
            bootbox.success(t('purchaser:message.unassign_account_purchaser_failed', {
                site: lodash.get(account, 'site'),
                user: username,
                account: lodash.get(account, 'account_purchaser.account'),
            }));
        });
    };
    render() {
        let {loading} = this.state;
        return (
            <a className="a-account__employee__close"
               onClick={!loading && this.confirm.bind(this)}
            >
                {loading ? <Icon type="loading" style={{ fontSize: 24 }} spin /> : <Icon type="close" />}
            </a>
        )
    }
}

UnassignAccountPurchaser.defaultProps = {
    tellers: [],
    account: {},
    username: '',
    isMultiple: false
};

UnassignAccountPurchaser.propTypes = {
    tellers: PropTypes.array,
    account: PropTypes.object,
    username: PropTypes.string,
    isMultiple: PropTypes.bool
};

export default UnassignAccountPurchaser;
