import React from 'react'
import lodash from 'lodash'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down'
import { Col, Row } from 'react-grid-system'
import ServiceItem from './serviceItem'
import { Collapse } from 'react-collapse'

class Service extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: true,
            services: [],
            order_id: 0,
            isFetchNewService: false,
            serviceGroups: {},
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = { ...prevState }
        const { services, order } = nextProps
        if (prevState.order_id !== order.id || nextProps.isFetchNewService === true || JSON.stringify(services) !== JSON.stringify(prevState.serviceGroups)) {
            let orderServices = lodash.get(order, 'services', [])
            let newServices = lodash.merge([], services)
            newServices.map(service => {
                let serviceItems = lodash.get(service, 'services', [])
                serviceItems.map(serviceItem => {
                    // serviceItem.isChecked = 0;
                    orderServices.map(orderService => {
                        if (orderService.service_id === serviceItem.id && serviceItem.show === 1) {
                            serviceItem.loading = 0
                            serviceItem.isChecked = 1
                            serviceItem.confirmAction = orderService.confirm
                        }

                        return orderService
                    })
                    if (!order.canChangeService) {
                        serviceItem.isDisabled = true
                    }
                    return serviceItem
                })

                return service
            })
            state.services = newServices
            state.order_id = order.id

            state.serviceGroups = services
        } else {
            return null
        }

        return state
    }

    /**
     * Function toggle block
     */
    onHandleClick() {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    /**
     * Function when click checkbox
     */
    onChangeCheckbox(event, serviceId) {
        let { services } = this.state
        const { order, updateOrderService } = this.props

        let newServices = lodash.merge([], services)
        newServices.map(serviceGroup => {
            let servicesInGroup = lodash.get(serviceGroup, 'services', [])
            servicesInGroup.map(service => {
                if (service.id === serviceId) {
                    service.loading = true
                }
                return service
            })
            return serviceGroup
        })
        this.setState({ services: newServices })
        updateOrderService({
            id: order.id,
            service_id: serviceId,
            status: event.target.checked ? 1 : 0,
        })
    }

    render() {
        const { services } = this.state
        const { order, approvalOrderService, isFetchNewService } = this.props
        const isCNAddress = lodash.get(order, 'isCnAddress', false)
        return (
            <div className={'a-purchasod--service'}>
                <p className="a-text--bold a-text--color-black a-font--14">Lựa Chọn Dịch Vụ</p>
                {services.map(serviceGroup => (
                    <div
                        key={serviceGroup.id}
                        className="a-purchasod--service__block">
                        <div className="a-purchasod--service__title a-text--bold a-text--color-black-85 mb-2">
                            <span>{serviceGroup.name}</span>
                        </div>
                        <div className="a-purchasod--service__body">
                            {serviceGroup.services.map(serviceItem => {
                                if (isCNAddress) {
                                    serviceItem = { ...serviceItem, isDisabled: true }
                                }
                                return (
                                    <ServiceItem
                                        key={serviceItem.id}
                                        order={order}
                                        approvalOrderService={approvalOrderService}
                                        serviceItem={serviceItem}
                                        onChangeCheckbox={this.onChangeCheckbox.bind(this)}
                                        isFetchNewService={isFetchNewService}
                                    />
                                )
                            })}
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

Service.defaultProps = {
    order: {},
    service: [],
}

Service.propTypes = {
    order: PropTypes.object,
    service: PropTypes.array,
}

export default translate('translations')(Service)
