import {auth} from '../../systems/api';
import {url} from '../../systems/routing';
import bootbox from './../../modules/common/components/Bootbox/bootbox';
import {t} from './../i18n/index.js';
import {router} from './../routing';
import {put} from 'redux-saga/effects';
import * as UserConstants from "./../../modules/User/constants";
import authService from "../../modules/Auth/authService";

/**
 *
 * Middleware dùng để set token vào cookie rồi redirect về next_page
 *
 * @param payload
 * @param next
 * @returns {*}
 */
let getToken = (payload, next) => {
    let token = url.getQuery('token');

    if (token) {
        let urlReturn = sessionStorage.getItem('auth.url_return');

        auth.setToken(token);
        authService.authForTool();
        localStorage.setItem('token_customer', token);
        sessionStorage.removeItem('auth.url_return');
        bootbox.success(t('message.login_success'));
        put({type: UserConstants.GET_ME.REQUEST, payload: {}});
        router.redirect(urlReturn);
    } else {
        bootbox.error(t('message.cant_get_token'));
    }

    return (next(payload));
};


export default getToken;