import React, {useState, useRef, useLayoutEffect, useEffect} from 'react';
import styled from 'styled-components';
import {Icon, notification, Tooltip} from 'antd';
import apiService from '../../../modules/PaymentRequest/apiService';

const Wrapper = styled.div`
	margin-top: 3px;
`;

const Editor = styled.div`
	textarea {
		resize: none;
		width: 100%;
		font-size: 12px;
		padding: 0 3px;

		&:placeholder {
			color: gray;
		}
	}

	.input-action {
		text-align: right;
		margin-bottom: 1px;
		color: #008dff;
		font-weight: bold;

		i {
			margin-left: 4px;
		}
	}
`;

const AddNoteButton = styled.div`
	.btn-edit {
		cursor: pointer;
		color: #008dff;
	}

	.note-value {
		font-size: 12px;
		white-space: pre-wrap;
	}
`;

const NotePaymentRequest = ({
                                id,
                                note,
                                t,
                                idPaymentNoting,
                                updateIdPaymentNoting,
                                canUpdateNote,
                            }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [transformNote, setTransformNote] = useState(note);
    const [value, setValue] = useState(note);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const textAreaRef = useRef(null);
    const _isMounted = useRef(true);

    useEffect(() => {
        if (idPaymentNoting !== id && isEditing) {
            handleClickCancelEdit();
        }
    }, [idPaymentNoting]);

    useLayoutEffect(() => {
        if (isEditing) {
            textAreaRef.current.focus();
            updateIdPaymentNoting(id);
        }
    }, [isEditing]);

    function handleClickCancelEdit() {
        setIsEditing(false);
        setValue(transformNote);
    }

    function handleSaveNote() {
        setIsSubmitting(true);
        apiService
            .createNotePaymentRequest(id, {note: value})
            .then((response) => {
                const {code} = response.data;
                if (code !== 200) throw Error(code);
                notification.success({message: t('paymentRequest:message.create_note_success')});
                if (_isMounted) {
                    setIsEditing(false);
                    setTransformNote(value)
                }
            })
            .catch((err) => {
                notification.error({message: t('system.error')});
                if (_isMounted) textAreaRef.current.focus();
            })
            .finally(() => {
                if (_isMounted) setIsSubmitting(false);
            });
    }

    return (
        <Wrapper>
            {isEditing ? (
                <Editor>
                    <div className='input-action'>
                        {isSubmitting ? (
                            <Icon type='loading-3-quarters' spin/>
                        ) : (
                            <Icon type="check" onClick={handleSaveNote}/>
                        )}
                        <Icon type='close' onClick={handleClickCancelEdit}/>
                    </div>
                    <div>
						<textarea
                            ref={textAreaRef}
                            onChange={(e) => setValue(e.target.value)}
                            maxLength={250}
                            value={value}
                            onFocus={(e) => {
                                const val = e.target.value;
                                e.target.value = '';
                                e.target.value = val;
                            }}
                            rows={3}
                            placeholder='Nhập ghi chú'
                        />
                    </div>
                </Editor>
            ) : (
                <AddNoteButton onClick={() => setIsEditing(canUpdateNote)}>
                    <div className='btn-edit'>
                        {
                            canUpdateNote &&
                            <Tooltip title={t("paymentRequest:label.create_note")}><Icon type='edit'/></Tooltip>
                        }
                    </div>
                    <div className='note-value'>{value}</div>
                </AddNoteButton>
            )}
        </Wrapper>
    );
};

export default NotePaymentRequest;
