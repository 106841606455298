import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import ComplaintSellerAssign from './../../ComplaintAssign/ComplaintSellerAssignContainer';
import {Icon, Row, Col} from "antd";
import ListCommentsAboutShop from "./ListCommentsAboutShop";

class AccountPurcharse extends React.Component {

    render() {
        let {order, complaint, purchasers, permissions, t} = this.props;
        let selected = complaint.selected_receiver;

        let shop_label = lodash.get(order, 'purcharseSelected.label', '---');
        return (
            <div className="complaint-seller-box a-content--box-shadow p-0 h-100">
                <div className="cs-box-title d-flex align-item-center p-3 pt-2 pb-2 a-text--bold">
                    <p className="a-text--fz-14">THÔNG TIN SHOP</p>
                    <a href={complaint.urlShop}
                       target="_blank"
                       className="a-text--bold a-text--green a-font--14 a-link a-text--uppercase"
                    >
                        <Icon type="file-text" className="mr-1" />
                        {t('complaintSeller:detail.complaint_same_shop')}
                    </a>
                </div>
                <div className="border-top a__complaint__order p-3">
                    <Row>
                        <Col span={8} className="cs-order-row">
                            <label className="a-text--font-b">{t('complaintSeller:detail.account_buyer')}:</label>
                        </Col>
                        <Col span={16} className="cs-order-row">
                            <p className="a-text--bold">
                                { shop_label || "---" }
                            </p>
                        </Col>

                        <Col span={8} className="cs-order-row">
                            <label className="a-text--font-b">{t('complaintSeller:detail.shop')}:</label>
                        </Col>
                        <Col span={16}>
                            <p className="mb-2">
                                <img
                                    className="mr-2 img-shop"
                                    src={"http://nhaphang.com/vp-assets/images/favicons_" + order.seller_source + ".png"}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = require('./../../resource/u32.png')
                                    }}
                                    alt="Avatar"/>
                                {order.seller_name}
                            </p>
                            <a target="_blank"
                               href={'http://www.taobao.com/webww/ww.php?ver=3&touid=' +
                               (order.seller_aliwang) + '&siteid=cntaobao&status=1&charset=utf-8'}
                            >
                                <img border="0"
                                     src={"http://amos.alicdn.com/realonline.aw?v=2&uid=" + order.seller_aliwang + "&site=cntaobao&s=1&charset=utf-8"}
                                     title={t('order_detail.chat_with_sell')}
                                     alt={"avatar"}
                                />
                            </a>
                        </Col>
                        <ComplaintSellerAssign
                            complaint={complaint}
                            purchasers={purchasers}
                            selected={selected}
                            permissions={permissions}
                        />
                    </Row>
                    <ListCommentsAboutShop
                        order={order}
                    />
                </div>

            </div>
        );
    }
}

AccountPurcharse.defaultProps = {
    order: {},
    complaintSellerReasons: [],
    complaint: {},
    purchasers: [],
};

AccountPurcharse.propTypes = {
    order: PropTypes.object,
    complaintSellerReasons: PropTypes.array,
    complaint: PropTypes.object,
    purchasers: PropTypes.array,
};

export default translate()(AccountPurcharse);
