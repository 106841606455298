import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Button, Menu, Dropdown, Checkbox, Icon} from 'antd';
import authService from '../../Auth/authService';

class SettingColumns extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            selectedColumns: [],
        };
    }

    componentDidMount() {
        let selectedColumns = this.fetchSelectedColumns() || this.getDefaultSelectedColumns();
        this.setState({selectedColumns});
        this.props.onChange(selectedColumns);
    }

    fetchSelectedColumns() {
        let {columns} = this.props;
        let fixed_columns = [];
        columns.forEach(item => item.disabled ? fixed_columns.push(item.key) : null);
        let selectedColumns = localStorage.getItem(this.getStorageKey());

        return selectedColumns ? [...fixed_columns, ...JSON.parse(selectedColumns)] : null;
    }

    saveSelectedColumns(selectedColumns) {
        localStorage.setItem(this.getStorageKey(), JSON.stringify(selectedColumns));
    }

    getStorageKey() {
        return this.props.storageKey + '.' + authService.user('id');
    }

    getDefaultSelectedColumns() {
        return this.props.columns
            .filter(column => column.selected)
            .map(column => column.key);
    }

    onChange(optionKey, event) {
        let selectedColumns = event.target.checked
            ? [...this.state.selectedColumns, optionKey]
            : this.state.selectedColumns.filter(key => key !== optionKey);

        this.saveSelectedColumns(selectedColumns);
        this.setState({selectedColumns});
        this.props.onChange(selectedColumns);
    }

    render() {
        return (
            <Dropdown
                visible={this.state.isOpen}
                overlay={this.renderMenu()}
                trigger={['click']}
                overlayClassName="a-dropdown--set-field"
            >
                <Button
                    size="small"
                    className="a-font--12 aphz-24 a-btn--primary-ant ghost mr-4 _setting-column-button"
                    {...this.props.buttonProps}
                    onClick={() => this.setState({isOpen: !this.state.isOpen})}
                >{this.props.buttonText || this.props.t('btn.setting_columns')}</Button>
            </Dropdown>
        );
    }

    renderMenu() {
        return (
            <Menu className="a-dropdown--set-field__menu">
                <div className="close-field-menu">
                    <Icon onClick={() => this.setState({isOpen: false})} type="close"/>
                </div>
                {this.props.columns.map(column => (
                    <Menu.Item
                        key={column.key}
                        className="a-dropdown--set-field__item"
                    >
                        <Checkbox
                            className={`a-checkbox--set-field _setting-column-${column.key}`}
                            checked={this.state.selectedColumns.includes(column.key)}
                            disabled={column.disabled}
                            onChange={this.onChange.bind(this, column.key)}
                        >{column.title}</Checkbox>
                    </Menu.Item>
                ))}
            </Menu>
        );
    }
}

SettingColumns.defaultProps = {
    storageKey: null,
    columns: [],
    buttonProps: {},
    buttonText: null,
    onChange: selectedColumns => {},
};

SettingColumns.propTypes = {
    storageKey: PropTypes.string,
    columns: PropTypes.array,
    buttonProps: PropTypes.object,
    buttonText: PropTypes.string,
    onChange: PropTypes.func,
};

export default translate()(SettingColumns);