import React, {useState} from 'react';
import {translate} from "react-i18next";
import {t} from "../../../../systems/i18n";
import {Button, Modal, Popover, Input, notification} from "antd";
import {processResponse} from "../../../../systems/api";
import apiService from "../../apiService";
import {get, isEmpty} from 'lodash';

function ChangePackageStatus(props) {
    const {complaintId, packageCode, updateListPackages, status_damaged_code, can_update_return} = props;
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [errorStatus, setErrorStatus] = useState(undefined);
    const [data, setData] = useState({});

    function updatePackageStatus() {
        if (errorStatus === "return" && isEmpty(get(data, "return_address", undefined))) {
            notification.error({message: t("complaintSeller:error.error.required", {name: t("complaintSeller:label.return_address")})});
            return false;
        }

        setLoading(true);
        const response = apiService.changePackageStatus(complaintId, {
            ...data,
            error_status: errorStatus,
            packages: [packageCode]
        });
        const process = processResponse(response);
        process.then(() => {
            updateListPackages();
            handleCancel();
        }).catch(() => {
            notification.error({message: t("message.cant_update_data")})
        }).finally(() => setLoading(false))
    }

    function onClickSolution(key) {
        setErrorStatus(key);
        setVisible(true);
    }

    function handleCancel() {
        setData({});
        setVisible(false);
    }

    const renderContentSolution = (
        <div className="d-flex justify-content-center">
            {
                status_damaged_code !== "return" &&
                <Button type="primary" onClick={() => onClickSolution("return")}>Trả hàng</Button>
            }
            {
                status_damaged_code !== "liquidate" &&
                <Button type="primary" onClick={() => onClickSolution("liquidate")} className="ml-2">Thanh lý</Button>
            }
        </div>
    );

    const onChangeInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({...data, [name]: value});
    };

    const onChangeFee = (e) => {
        const {value, name} = e.target;
        const regex = /^[]?\d*\.?\d{0,5}$/;
        if (!(regex.test(value) || value === "")) return;
        setData({...data, [name]: value});
    };

    return (
        <>
            <Popover
                placement="top"
                title={t("complaintSeller:label.choose_solution")}
                content={renderContentSolution}
            >
                <span className="a--cursor-poiter a-text--bold a-text--font-blue _complaint-seller-detail-button-handle">
                    {t("complaintSeller:label.handle")}
                </span>
            </Popover>

            {
                (visible && errorStatus) &&
                <Modal
                    title={t("complaintSeller:label.solution_title", {
                        action: t("complaintSeller:label." + errorStatus),
                        packageCode
                    })}
                    visible={visible}
                    onOk={updatePackageStatus}
                    onCancel={handleCancel}
                    cancelText={t("btn.cancel")}
                    okText={t("btn.ok")}
                    okButtonProps={{
                        loading: loading,
                        disabled: (errorStatus === "return" && isEmpty(get(data, "return_address", undefined))),
                        className: "_complaint-seller-detail-button-handle-ok"
                    }}
                    cancelButtonProps={{className: "_complaint-seller-detail-button-handle-cancel"}}
                    width={520}
                >
                    {
                        errorStatus === "return" &&
                        <div className='a-group'>
                            <label
                                className="label label--required">{t("complaintSeller:label.return_address")}</label>
                            <Input
                                name="return_address"
                                value={get(data, "return_address", undefined)}
                                placeholder={t("complaintSeller:placeholder.return_address")}
                                onChange={onChangeInput}
                                className="_complaint-seller-detail-handle-return-address"
                            />
                        </div>
                    }
                    {
                        (!!can_update_return && errorStatus === "return") &&
                        <>
                            <div className='a-group'>
                                <label
                                    className="label">{t("complaintSeller:label.return_tracking_bill")}</label>
                                <Input
                                    name="return_tracking_bill"
                                    value={get(data, "return_tracking_bill", undefined)}
                                    placeholder={t("complaintSeller:placeholder.return_tracking_bill")}
                                    onChange={onChangeInput}
                                    className="_complaint-seller-detail-handle-return-tracking-bill"
                                />
                            </div>
                            <div className='a-group'>
                                <label
                                    className="label">{t("complaintSeller:label.return_fee")}</label>
                                <Input
                                    name="return_fee"
                                    value={get(data, "return_fee", undefined)}
                                    placeholder={t("complaintSeller:placeholder.return_fee")}
                                    onChange={onChangeFee}
                                    className="_complaint-seller-detail-handle-return-fee"
                                />
                            </div>
                        </>
                    }

                    <div className='a-group'>
                        <label className="label">{t("complaintSeller:label.note")}</label>
                        <Input.TextArea
                            name="note"
                            autoSize={{minRows: 2, maxRows: 6}}
                            value={get(data, "note", undefined)}
                            placeholder="Nhập vào ghi chú"
                            onChange={onChangeInput}
                            className={"_complaint-seller-detail-handle-note-status"}
                        />
                    </div>
                </Modal>
            }
        </>

    )
}

export default translate()(ChangePackageStatus);