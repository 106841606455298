import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import {t, trans} from '../../../systems/i18n';
import lodash from 'lodash';
import YuanInput from './../../common/components/Input/YuanInput';
import FormProcessing from './../../common/components/FormProcessing';
import {Modal, Select, DatePicker, Input} from "antd";
import {connect} from "react-redux";
import { pushComplaintSellerToList } from "../actions";
import moment from "moment";
import apiService from "../apiService";
import {processResponse} from "../../../systems/api";
import bootbox from "../../common/components/Bootbox/bootbox";
import {url} from "../../../systems/routing";


const { Option } = Select;
const { TextArea } = Input;
const defaultInput = {
    id_order: '',
    complaint_seller_reason_id: undefined,
    expire_time: moment().add(1, 'days').format('YYYY-MM-DD'),
    amount: '',
    origin_code: "",
    note: '',
};

class CreateComplaint extends FormProcessing {

    constructor(props) {
        super(props);
        this.state = {
            input: {},
            inputVersion: 0,
            changedParams: [],
            visible: false,
            errors: {},
            processing: false
        }
    }

    onChangeSelect = (param, value) => {
        this.changeInput({[param]: value});
        this.removeError(param);
    }

    renderError(attrName) {
        let {t} = this.props;
        let {errors} = this.state;
        let errorItem = lodash.get(errors, attrName, {});
        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('complaintSeller:error.error.' + Object.keys(errorItem)[0], {name: t('complaintSeller:list.' + attrName)});
                case 'NOT_EXIST':
                    return t('complaintSeller:error.error.' + Object.keys(errorItem)[0], {name: t('complaintSeller:list.' + attrName)});
                case 'ALREADY_EXIST':
                    return t('complaintSeller:error.error.' + Object.keys(errorItem)[0], {name: t('complaintSeller:list.' + attrName)});
                case 'INVALID':
                    return t('complaintSeller:error.error.' + Object.keys(errorItem)[0], {name: t('complaintSeller:list.' + attrName)});
                case 'unique':
                    return t('complaintSeller:error.error.' + Object.keys(errorItem)[0], {name: t('complaintSeller:list.' + attrName)});
                case 'max.string':
                    let objectmax = lodash.get(errorItem, 'max.string', null);
                    let overLength = lodash.get(objectmax, 'max', null);
                    return t('complaintSeller:error.error.max_string', {
                        name: t('complaintSeller:list.' + attrName),
                        value: overLength
                    });
                default:
                    return t('complaintSeller:error.error.system_errors');
            }
        }
        return null;
    }

    //Remove message error
    removeError(param) {
        this.setState(preState => {
            let {errors} = preState;
            delete errors[param];
            return {...preState, errors}
        });
    }

    onKeyPress(param) {
        this.removeError(param);
    }

    onChangeDatePicker = (dateMomment, dateString) => {
        this.changeInput({expire_time: dateString});
        this.removeError('expire_time');
    }

    onChangeAmount = amount => {
        this.changeInput({amount: amount});
    }

    componentDidMount() {
        let {
            id_order,
            orderCode
        } = this.props;

        // Nếu có orderCode được gửi theo url thì gán orderCode cho id_order
        if (orderCode)
            this.setState({input: {...defaultInput, id_order: orderCode}, visible: true});
        else
            this.setState({input: {...defaultInput, id_order: id_order || ''}});
    }

    showModal = () => {
        this.setState({visible: true});
    };

    handleOk = e => {
        let {input} = this.state;
        this.createComplaintSeller(input);
        this.setState({processing: true});
    };

    handleCancel = e => {
        let {id_order, orderCode} = this.props;
        this.setState({
            visible: false,
            errors: {},
            input: {...defaultInput, id_order: id_order || ''}
        });
    };

    createComplaintSeller(data) {
        let {
            page,
            openNewTab,
            onOrderIsJustAdded,
            id_order
        } = this.props;
        let res = apiService.createComplaintSeller(data);
        let process = processResponse(res);

        process.then(res => {
            if (page === "complaint-seller")
                this.props.pushComplaintSellerToList(res);

            let complaint = lodash.get(res, "data.complaintSeller", {});

            if (typeof onOrderIsJustAdded === "function") {
                let id_order = lodash.get(res, "data.complaintSeller.id_order", 0);
                onOrderIsJustAdded(id_order);
            }

            if (!lodash.isEmpty(complaint) && openNewTab)
                window.open(url.to("complaint-seller.detail", {id: complaint.id}), "_blank");

            bootbox.success(t('complaintSeller:list.create_success'));
            this.setState({
                visible: false,
                processing: false,
                errors: {},
                input: {...defaultInput, id_order: id_order || ''}
            });
        });

        process.catch(res => {
            if (res.code === "INPUT_INVALID")
                this.setState({errors: res.data, processing: false});
        });
    }

    render() {
        let { t, complaintSellerReasons, className, currencySymbol} = this.props;
        let { input, visible, processing } = this.state;

        return <>
            <span
                onClick={this.showModal}
                className={className} >{this.props.children}</span>
            <Modal
                title={t('complaintSeller:list.created')}
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okText={trans('account_purchaser.btn_action_confirm')}
                cancelText={trans('account_purchaser.btn_action_cancel')}
                okButtonProps={{loading: processing}}
                centered
            >
                <div className='a-group'>
                    <label className="label label--required a-label ">
                        {t('complaintSeller:list.id_order')}
                    </label>
                    <Input
                        type="text"
                        autoComplete="off"
                        name={"id_order"}
                        placeholder={t('complaintSeller:list.order_code_placeholder')}
                        onKeyPress={this.onKeyPress.bind(this, 'id_order')}
                        onChange={this.onChangeInput.bind(this, 'id_order')}
                        value={input.id_order || ""}
                        className={"_create-complaint-seller-id-order"}
                    />
                    <div className={"a--error"}>
                        {this.renderError("id_order")}
                        {this.renderError("status")}
                    </div>
                </div>
                <div className='a-group'>

                    <label className="label label--required a-label mb-2">
                        {t('complaintSeller:list.complaint_seller_reason_id')}
                    </label>
                    <Select
                        onChange={value => this.onChangeSelect('complaint_seller_reason_id', value)}
                        placeholder={t('complaintSeller:list.choose_reason_for_refund_money')}
                        value={input.complaint_seller_reason_id}
                        showSearch={true}
                        optionFilterProp="children"
                        style={{width: "100%"}}
                        className={"_create-complaint-seller-select-reason-id"}
                    >
                        {
                            complaintSellerReasons && complaintSellerReasons.map(reason => {
                                return <Option key={reason.value} value={reason.value}>{reason.label}</Option>
                            })
                        }
                    </Select>
                    <div className={"a--error"}>
                        {this.renderError("complaint_seller_reason_id")}
                    </div>
                </div>
                <div className='a-group'>
                    <label className="label label--required a-label">
                        {t('complaintSeller:list.expire_time')}
                    </label>
                    <DatePicker
                        onChange={this.onChangeDatePicker}
                        value={moment(input.expire_time)}
                        placeholder={t("complaintSeller:detail.renew_placeholder")}
                        style={{width: "100%"}}
                        className={"_create-complaint-seller-expire-time"}
                    />
                    <div className={"a--error"}>{this.renderError("expire_time")}</div>
                </div>
                <div className='a-group'>
                    <label className="label a-label ">
                        {t('complaintSeller:list.origin_code')}
                    </label>
                    <Input
                        type="text"
                        autoComplete="off"
                        name={"origin_code"}
                        placeholder={t('complaintSeller:list.origin_code_placeholder')}
                        onKeyPress={this.onKeyPress.bind(this, 'origin_code')}
                        onChange={this.onChangeInput.bind(this, 'origin_code')}
                        value={input.origin_code || ""}
                        className={"_create-complaint-seller-origin-code"}
                    />
                    <div className={"a--error"}>
                        {this.renderError("origin_code")}
                    </div>
                </div>
                <div className='a-group'>
                    <label className="label a-label ">
                        {t('complaintSeller:list.request_a_seller', {currencySymbol})}:
                    </label>

                    <YuanInput
                        negative={false}
                        name={"amount"}
                        unit_price={true}
                        onChange={this.onChangeAmount}
                        placeholder={t('complaintSeller:list.request_a_seller', {currencySymbol})}
                        className={"_create-complaint-seller-request-a-seller"}
                    />
                    <br/>
                    <div className={"a--error"}>{this.renderError("amount")}</div>
                </div>
                <div className='a-group' style={{marginBottom: 0}}>
                    <label className="label a-label ">
                        {t('complaintSeller:list.note')}:
                    </label>
                    <TextArea
                        rows="4"
                        name={"note"}
                        autoComplete="off"
                        placeholder={t('complaintSeller:list.note')}
                        onChange={this.onChangeInput.bind(this,'note')}
                        value={input.note || ""}
                        className={"_create-complaint-seller-note"}
                    />
                    <div className={"a--error"}>{this.renderError("account")}</div>
                </div>
            </Modal>
        </>;
    }
}

CreateComplaint.defaultProps = {
    page: 'complaint-seller', // complaint-seller, ordering, purchase-buying để bắn sự kiện tương ứng với mỗi loại màn hình
    complaintSellerReasons: [],
    openNewTab: false
}

CreateComplaint.propTypes = {
    page: PropTypes.string,
    complaintSellerReasons: PropTypes.array,
    openNewTab: PropTypes.bool
}

const mapDispatchToProps = dispatch => {
    return {
        pushComplaintSellerToList: data => dispatch(pushComplaintSellerToList(data))
    };
}

export default connect(null, mapDispatchToProps)(translate('translations')(CreateComplaint));
