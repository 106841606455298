import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import FormCreate from './FormCreate';
import ListPackages from './ListPackages';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {t, productReturn, onCreatePackage, onRemovePackage, canUpdateIntoStatus} = this.props;
        
        return (
            <React.Fragment>
                <div className="a_complaint_solution__body__block block__table">
                    <p>{t('complaintSeller:product_return.package')}</p>
                    <FormCreate
                        canUpdateIntoStatus={canUpdateIntoStatus}
                        loading={this.props.addingPackage}
                        inputVersion={this.props.countAddedPackage}
                        onCreatePackage={onCreatePackage}
                    />
                </div>
                <div className="table-responsive">
                    <ListPackages
                        isModalProcessing={this.props.isModalProcessing}
                        productReturn={productReturn}
                        canUpdateIntoStatus={canUpdateIntoStatus}
                        onRemovePackage={onRemovePackage}
                        deletingPackage={this.props.deletingPackage}
                        onBeginRemovePackage={this.props.onBeginRemovePackage}
                    />
                </div>
            </React.Fragment>
        );
    }
}

Index.defaultProps = {
    packages: [],
    canUpdateIntoStatus: false,
    onCreatePackage: ()=> {
    },
    onRemovePackage: ()=> {
    },
    onBeginRemovePackage: ()=> {
    },
    isModalProcessing: false,
};

Index.propTypes = {
    packages: PropTypes.array,
    canUpdateIntoStatus: PropTypes.bool,
    onCreatePackage: PropTypes.func,
    onRemovePackage: PropTypes.func,
    onBeginRemovePackage: PropTypes.func
};

export default translate('translations')(Index);
