import {combineReducers} from 'redux';
import lodash from "lodash";
import * as Constants from "./constants";

const list = (state = [], action) => {
    switch (action.type) {
        case Constants.FETCH_VIOLATE_POLICY_ORDERS.SUCCESS:
            return [...lodash.get(action, 'payload.data.orders', [])];
        case Constants.UPDATE_REASON.SUCCESS:
            let orderPolicy = lodash.get(action, 'payload.data.orderPolicy');
            return state.map(order => {
                if (order.id === orderPolicy.id_order) {
                    order.policies[orderPolicy.policy_type] = {...order.policies[orderPolicy.policy_type], ...orderPolicy};
                }
                return order;
            });
        default:
            return state;
    }
};

const pagination = (state = {}, action) => {
    let {payload} = action;

    switch (action.type) {
        case Constants.FETCH_VIOLATE_POLICY_ORDERS.SUCCESS:
            return lodash.get(payload, 'data.pagination', {from: 0, per_page: 20, total: 0});
        default:
            return state;
    }
};

const partner = (state = {}, action) => {
    switch (action.type) {
        case Constants.FETCH_VIOLATE_POLICY_ORDERS.SUCCESS:
            return lodash.get(action.payload, 'data.partner', {});
        case Constants.FETCH_VIOLATE_POLICY_ORDERS.FAILED:
            return {};
        default:
            return state;
    }
};

export default combineReducers({
    list,
    pagination,
    partner
});