import React from 'react';
import {translate} from 'react-i18next';
import {Col, Form, Layout, Row} from "antd";
import {withEvent} from '../../../systems/events';
import {dispatch} from "../../../systems/store";
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from '../../../modules/common/actions';
import {url} from "../../../systems/routing";
import SelectAgencies from '../../../modules/Agency/SelectAgencies';
import {setActiveSettingItem} from '../../../modules/Settings/SettingMenu/actions';
import DateRange from '../../../modules/common/components/DateTime/DateRange';
import ExportOrdersData from '../../../modules/Order/ExportData';
import FormProcessing from '../../../modules/common/components/FormProcessing';
import ORDER from '../../../modules/Order/events';
import authService from "../../../modules/Auth/authService";
import * as PERMISSION from "../../../modules/Permission/permissions";
import DateRangeCustomize from "../../../modules/common/components/DateTime/DateRangeCustomize";

const Content = Layout.Content;

class ExportOrder extends FormProcessing {

    constructor(props) {
        super(props);

        this.defaultInput = {
            log_status_from: '',
            log_status_to: '',
            id_agency: '',
        };

        this.state = {
            input: {...this.defaultInput},
            errors: {},
            changedParams: []
        };
    }
    changeDateRange(from, to) {
        let {errors} = this.state;
        errors.time && this.setState({
            ...this.state,
            errors: {
                ...this.state.errors,
                time: undefined
            }
        });
        this.changeInput({
            log_status_from: from,
            log_status_to: to,
        });
    }
    changeSelect(id_agency) {
        let {errors} = this.state;
        errors.id_agency && this.setState({
            ...this.state,
            errors: {
                ...this.state.errors,
                id_agency: undefined
            }
        });
        this.changeInput({
            id_agency,
        })
    }
    componentDidMount() {
        let {t} = this.props;
        dispatch(setPageTitle(t('layout.title.orders_export')));
        dispatch(setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.orders_export',
                'link': url.to('orders.export')
            },
        ]));
        dispatch(setPageDescription(t('layout.description.orders_export')));
        dispatch(setMenuActive('orders_export'));
        setActiveSettingItem('orders_export');
        this.props.on({
            [ORDER.EXPORT_DATA_SUCCESS]: () => {
                this.setState({
                    ...this.state,
                    errors: {}
                })
            },
            [ORDER.EXPORT_DATA_FAILED]: (_, payload) => {
                this.setState({
                    ...this.state,
                    errors: payload
                })
            },
            [ORDER.EXPORT_DATA_REQUEST]: (_, payload) => {

            }
        });
    }

    render() {
        let {t} = this.props;
        let {input, errors} = this.state;
        let exportActualAmountPermission = authService.can(PERMISSION.TOOLS_EXPORT_ACTUAL_AMOUNT);
        let exportOrderTransaction = authService.can(PERMISSION.TOOLS_EXPORT_ORDER_TRANSACTION);
        return (
            <Layout>
                <Content className="a-content--ant-layout">
                    <Row className="bg-white a-flex align-items-center pt-5 pb-5">
                        <Col lg={12} xl={12} xxl={12} className="a-flex align-items-center justify-content-end">
                            <img src={require('../../../resources/img/export.png')} alt=""/>
                        </Col>

                        <Form labelcol={{span: 12}}>
                            <Col lg={12} xl={12} xxl={12} className="a-block--export-data">
                                <h1 className={"title"}>Xuất dữ liệu đơn hàng</h1>
                                <Form.Item required={true} help={errors.time} validateStatus={errors.time ? 'error' : null} label={t("order:label.time")} className="a-form--ant-item  mb-2">
                                    <DateRangeCustomize
                                        from={input.log_status_from}
                                        to={input.log_status_to}
                                        onChange={(from, to) => this.changeDateRange(from, to)}
                                    />
                                </Form.Item>
                                <Form.Item required={true} help={errors.id_agency} validateStatus={errors.id_agency ? 'error' : null} label={t("order:label.agencies")} className="a-form--ant-item ">
                                    <SelectAgencies
                                        value={input.id_agency || ''}
                                        onChange={id_agency => this.changeSelect(id_agency)}
                                        placeholder={t('order:placeholder.agencies')}

                                    />
                                </Form.Item>
                                <div>
                                    {exportOrderTransaction && <ExportOrdersData input={input}
                                                                                 label={t('order:btn.orders_export')}
                                                                                 exportActualAmount={false}/>}
                                    {exportActualAmountPermission && <ExportOrdersData input={input}
                                                                                label={t('order:btn.export_actual_amount')}
                                                                                exportActualAmount={true}/>}
                                </div>
                            </Col>
                        </Form>
                    </Row>
                </Content>
            </Layout>
        )
    }
}

export default translate()(withEvent(ExportOrder))
