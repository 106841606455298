import {connect} from 'react-redux';
import * as Actions from '../actions'
import SyncOrderBills from "./SyncOrderBill/SyncOrderBills";
import * as SelectorCustomer from "../../User/selectors";



const mapDispatchToProps = (dispatch) => {
    return {
        orderBillNoSyncs: (data) => {
            dispatch(Actions.orderBillNoSyncs(data))
        },
    };
};

export default connect(
    null,
    mapDispatchToProps
)(SyncOrderBills)
