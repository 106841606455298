import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import Spinner from '@atlaskit/spinner';
import {Checkbox} from '@atlaskit/checkbox';

class Alipay extends React.PureComponent {
    onToggleAlipay(event) {
        let alipayId = this.props.alipay.id;
        event.target.checked ? this.props.onAttach(alipayId) : this.props.onDetach(alipayId);
    }

    render() {
        let {t, alipay} = this.props;
        
        return (
            <tr>
                {this.props.canMapping ? <td className="check__complaint">{this.renderCheckboxToogle()}</td> : null}
                <td>{alipay.transaction_time_format}</td>
                <td>{alipay.ali_code}</td>
                <td className="a-text--bold a-text--uppercase">
                    {t('complaintSeller:transaction_type.'+lodash.toLower(alipay.transaction_type))}
                </td>
                <td>{alipay.balance_format} / {alipay.balance_vnd_format}</td>
            </tr>
        );
    }

    renderCheckboxToogle() {
        let {t, alipay} = this.props;

        if (alipay.transaction_type !== 'REFUND') {
            return;
        }

        if (!alipay.can_mapping) {
            return <Checkbox
                isDisabled={true}
                isChecked={true}
                title={t('complaintSeller:alipay.alipay_mapped')}
            />;
        }

        if (alipay.mapping) {
            return <Spinner size="small"/>;
        }

        return <Checkbox
            defaultChecked={alipay.in_complaint}
            onChange={this.onToggleAlipay.bind(this)}
        />;
    }
}

Alipay.defaultProps = {
    alipay: {},
    canMapping: false,
    onAttach: (alipayId) => {},
    onDetach: (alipayId) => {},
};

Alipay.propTypes = {
    alipay: PropTypes.object,
    canMapping: PropTypes.bool,
    onAttach: PropTypes.func,
    onDetach: PropTypes.func,
};

export default translate()(Alipay);
