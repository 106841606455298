import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {dateFormatter, currentFormatter} from "./../../../services/format";
import {trans} from '../../../../../systems/i18n';
import complaintService from '../../../../ComplaintSeller/complaintService';
import {url} from '../../../../../systems/routing';

const COMPLAINT_ACTION = {
    CREATE: 'COMPLAINT.CREATE',
    CHANGE_STATE: 'COMPLAINT.CHANGE_STATE',
    COMPLAINT_RENEW: 'COMPLAINT.RENEW',
    RECEIVE: 'COMPLAINT.RECEIVE',
    ASSIGN: 'COMPLAINT.ASSIGN',
    FAILED: 'COMPLAINT.FAILED',
    PRODUCT_RETURN_CREATE: 'COMPLAINT.PRODUCT_RETURN.CREATE',
    COMPLAINT_PRODUCT_RETURN_CHANGE_STATE: 'COMPLAINT.PRODUCT_RETURN.CHANGE_STATE',
    COMPLAINT_UPDATE_NOTE: 'COMPLAINT.UPDATE_NOTE',
    COMPLAINT_MARK: 'COMPLAINT.MARK',
    COMPLAINT_UPDATE_REASON: 'COMPLAINT.UPDATE_REASON',
    COMPLAINT_ADD_TRANSACTION: 'COMPLAINT.ADD_TRANSACTION',
    COMPLAINT_DELETE_TRANSACTION: 'COMPLAINT.DELETE_TRANSACTION',
    COMPLAINT_ORIGIN_CODE: 'COMPLAINT.ORIGIN_CODE',
    COMPLAINT_ORIGIN_INFO: 'COMPLAINT.ORIGIN_INFO',
    COMPLAINT_CREATE_PACKAGE: 'COMPLAINT.CREATE_PACKAGE'
};

class ComplaintLogMessage extends React.Component {
    render() {
        let {log} = this.props;
        let {payload} = log;
        let productReturn = {};
        switch (log.action) {
            case COMPLAINT_ACTION.COMPLAINT_PRODUCT_RETURN_CHANGE_STATE:
                productReturn = lodash.get(payload, 'complaint_seller.product_return') || {};
                return trans('complaintSeller:product_return_log.COMPLAINT_PRODUCT_RETURN_CHANGE_STATE', {
                    product_return: <a href={url.to('product.return.detail', {id: productReturn.id})}
                                       target="_blank">#{productReturn.id}</a>,
                    from:
                        <b>{trans('productReturn:status.' + (lodash.get(payload, 'attributes.old')).toLowerCase())}</b>,
                    to: <b>{trans('productReturn:status.' + (lodash.get(payload, 'attributes.new')).toLowerCase())}</b>,
                });


            case COMPLAINT_ACTION.CREATE:
                return trans('complaintSeller:log.CREATE');
            case COMPLAINT_ACTION.COMPLAINT_ORIGIN_CODE:
                return trans('complaintSeller:log.COMPLAINT_ORIGIN_CODE');

            case COMPLAINT_ACTION.COMPLAINT_ADD_TRANSACTION:
                let transactionCodeAdd = lodash.get(payload, 'attributes.transaction_code', '')
                return trans('complaintSeller:log.COMPLAINT_ADD_TRANSACTION', {transaction_code: <b>{transactionCodeAdd}</b>});

            case COMPLAINT_ACTION.COMPLAINT_DELETE_TRANSACTION:
                let transactionCodeDelete = lodash.get(payload, 'attributes.transaction_code', '')
                return trans('complaintSeller:log.COMPLAINT_DELETE_TRANSACTION', {transaction_code: <b>{transactionCodeDelete}</b>});

            case COMPLAINT_ACTION.CHANGE_STATE:
                if (lodash.get(payload, 'attributes.new', '') === 'SUCCESS') {
                    return trans('complaintSeller:log.CHANGE_STATE_SUCCESS', {
                        from: <b>{complaintService.statusLabel(lodash.get(payload, 'attributes.old'))}</b>,
                        to: <b>{complaintService.statusLabel(lodash.get(payload, 'attributes.new'))}</b>,
                        amount: currentFormatter.toLocaleStringCurrency(parseFloat(lodash.get(payload, 'complaint_seller.amount_earned', 0), 10), 5),
                    });
                }
                if (lodash.get(payload, 'attributes.note', '') !== '') {
                    return trans('complaintSeller:log.CHANGE_STATE_WITH_NOTE', {
                        from: <b>{complaintService.statusLabel(lodash.get(payload, 'attributes.old'))}</b>,
                        to: <b>{complaintService.statusLabel(lodash.get(payload, 'attributes.new'))}</b>,
                        noted: <b>{(lodash.get(payload, 'attributes.note'))}</b>,
                    });
                } else {
                    return trans('complaintSeller:log.CHANGE_STATE', {
                        from: <b>{complaintService.statusLabel(lodash.get(payload, 'attributes.old'))}</b>,
                        to: <b>{complaintService.statusLabel(lodash.get(payload, 'attributes.new'))}</b>,
                    });
                }

            case COMPLAINT_ACTION.ASSIGN:
                return trans('complaintSeller:log.CHANGE_ASSIGN', {
                    to: <b>{lodash.get(payload, 'attributes.receiver_new.username')}</b>,
                });

            case COMPLAINT_ACTION.COMPLAINT_RENEW:
                let renew_message = lodash.get(log, "payload.attributes.message", "");
                return <>
                    {
                        trans('complaintSeller:log.COMPLAINT_RENEW', {
                            from: <b>{dateFormatter.date(lodash.get(payload, 'attributes.old'))}</b>,
                            to: <b>{dateFormatter.date(lodash.get(payload, 'attributes.new'))}</b>,
                        })
                    }
                    {(renew_message ? ` - ${renew_message}` : '')}
                </>;

            case COMPLAINT_ACTION.RECEIVE:
                return trans('complaintSeller:log.RECEIVE');

            case COMPLAINT_ACTION.FAILED:
                return trans('complaintSeller:log.FAILED');

            case COMPLAINT_ACTION.PRODUCT_RETURN_CREATE: {
                productReturn = lodash.get(payload, 'complaint_seller.product_return') || {};

                return trans('complaintSeller:product_return_log.CREATE', {
                    product_return: <a href={url.to('product.return.detail', {id: productReturn.id})}
                                       target="_blank">#{productReturn.id}</a>,
                });
            }

            case COMPLAINT_ACTION.COMPLAINT_MARK: {
                let attributes = lodash.get(payload, 'attributes') || {};
                let is_important_new = lodash.get(payload, 'attributes.is_important_new', 0);

                return is_important_new ? trans('complaintSeller:log.COMPLAINT_MARK', {}) : trans('complaintSeller:log.COMPLAINT_UNMARK', {});
            }

            case COMPLAINT_ACTION.COMPLAINT_UPDATE_NOTE: {
                let attributes = lodash.get(payload, 'attributes') || {};
                let note_old = lodash.get(payload, 'attributes.note_old', '');
                let note_new = lodash.get(payload, 'attributes.note_new', '');
                if(note_old === null) {
                    note_old = '';
                }
                if(note_new === null) {
                    note_new = '';
                }

                if(note_old !== '' && note_new !== '') {
                    return trans('complaintSeller:log.COMPLAINT_UPDATE_NOTE', {from:  <b>{note_old}</b>, to: <b>{note_new}</b>});
                }

                if(note_old === '' && note_new !== '') {
                    return trans('complaintSeller:log.COMPLAINT_ADD_NOTE', {note: <b>{note_new}</b>});
                }

                if(note_old !== '' && note_new === '') {
                    return trans('complaintSeller:log.COMPLAINT_DELETE_NOTE', {note: <b>{note_old}</b>});
                }
            }
            case COMPLAINT_ACTION.COMPLAINT_UPDATE_REASON: {
                let note_old = <span className="a-text--bold">{lodash.get(payload, 'attributes.note_old', '')}</span>;
                let note_new = <span className="a-text--bold">{lodash.get(payload, 'attributes.note_new', '')}</span>;
                return trans('complaintSeller:log.COMPLAINT_UPDATE_REASON', {
                    note_new: note_new,
                    note_old: note_old
                })
            }
            case COMPLAINT_ACTION.COMPLAINT_ORIGIN_INFO:
                return trans('complaintSeller:log.COMPLAINT_ORIGIN_INFO');
            case COMPLAINT_ACTION.COMPLAINT_CREATE_PACKAGE:
                let attributes = lodash.get(payload, 'attributes') || {};
                return trans('complaintSeller:log.COMPLAINT_CREATE_PACKAGE', {package: <b>{attributes.package}</b>});

            default:
                return log.action;
        }
    }
}

ComplaintLogMessage.defaultProps = {
    log: {},
};

ComplaintLogMessage.propTypes = {
    log: PropTypes.object,
};

export default translate()(ComplaintLogMessage);
