import React from 'react';
import {translate} from 'react-i18next';
import Button from '@atlaskit/button';
import PropTypes from 'prop-types';
import {trans} from '../../../systems/i18n';
import * as Constant from './../constants';
import lodash from 'lodash';
import Select from '@atlaskit/select';
import ModalGeneral from './../../common/components/ModalGeneral';

class ModalForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            account: "",
            email_or_phone: '',
            site_name: [],
            status: Constant.STATUS_ACCOUNT_PURCHASER[0].value,
            isLoading: false,
            account_number: "",
            last_number_on_invoice_code: ""
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        state.isLoading = nextProps.isLoading;

        if (lodash.get(nextProps, "accountPurchaser.id", 0) !== prevState.id) {
            state.id = lodash.get(nextProps, "accountPurchaser.id", 0);
            state.account = lodash.get(nextProps, "accountPurchaser.account", "");
            state.email_or_phone = lodash.get(nextProps, "accountPurchaser.email_or_phone", "");
            state.account_number = lodash.get(nextProps, "accountPurchaser.account_number", "");
            state.last_number_on_invoice_code = lodash.get(nextProps, "accountPurchaser.last_number_on_invoice_code", "");
            let siteName = lodash.get(nextProps, "accountPurchaser.site_name", null);
            state.site_name = [];
            if (siteName) {
                state.site_name = Constant.SOURCE_ACCOUNT_PURCHASER.filter(source => {
                    if (siteName.includes(source.value)) {
                        return source;
                    }

                    return null;
                });
            }

            state.status = lodash.get(nextProps, "accountPurchaser.status", Constant.STATUS_ACCOUNT_PURCHASER[0].value);
        }

        return state;
    }

    /**
     * handle action thay đổi value của các input trong form
     * @param event
     */
    onChangeInput(event) {
        let {error} = this.props;
        !lodash.isEmpty(error) && this.props.clearError();
        let {target} = event;
        this.setState({
            [target.name]: target.value.trim()
        });
    }

    onSelectMulti(param, values) {
        this.setState({[param] : values});
    }

    /**
     * xử lý hành động submit form
     */
    onSubmitForm() {
        let formData = lodash.merge({}, this.state);
        this.setState({isLoading: true});
        let siteNameSeleted = [];

        if (formData.site_name && formData.site_name.length) {
            formData.site_name.map(site => siteNameSeleted.push(site.value))
        }
        formData.site_name = siteNameSeleted;
        this.props.onSubmitForm(formData);
    }

    /**
     * Submit form when enter input
     * @param event
     */
    onKeyPress(event) {
        if (event.key === 'Enter') {
            this.onSubmitForm();
        }
    }

    /**
     * Thực hiện render lỗi thông báo ra bên ngoài
     * @param attrName
     * @returns {null}
     */
    renderError(attrName) {
        let {error, t} = this.props;
        let errorItem = lodash.get(error, attrName, {});
        attrName = attrName === "account" ? "account_label" : attrName;
        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('account_purchaser.error.' + Object.keys(errorItem)[0], {name: t('account_purchaser.' + attrName)});
                case 'unique':
                    return t('account_purchaser.error.' + Object.keys(errorItem)[0], {name: t('account_purchaser.' + attrName)});
                case 'max.string':
                    let objectmax = lodash.get(errorItem, 'max.string', null);
                    let overLength = lodash.get(objectmax, 'max', null);
                    return t('account_purchaser.error.max_string', {
                        name:   t('account_purchaser.' + attrName),
                        value: overLength
                    });
                case 'min.string':
                    let objectmin = lodash.get(errorItem, 'min.string', null);
                    let minLength = lodash.get(objectmin, 'min', null);
                    return t('account_purchaser.error.min_string', {
                        name:   t('account_purchaser.' + attrName),
                        value: minLength
                    });
                default:
                    return null;
            }
        }
    }

    render() {
        let {
            onClose,
            t,
            accountPurchaser,
            isLoading
        } = this.props;
        let {
            account,
            site_name,
            status,
            email_or_phone,
            account_number,
            last_number_on_invoice_code
        } = this.state;

        return (
                <ModalGeneral>
                    <div className={"a-modal__title"}>
                        <p >
                            {lodash.get(accountPurchaser, "id", 0) === 0 ? t("account_purchaser.modal_form_create_title") : t("account_purchaser.modal_form_update_title")}
                        </p>
                    </div>
                    <div className='a-group'>
                        <label htmlFor="name"
                               className="label label--required a-label ">{t("account_purchaser.account_label")}</label>
                        <input
                            spellCheck="false"
                            className="input input--fullwidth input--insert_acount mt-2"
                            type="text" name={"account"}
                            placeholder={t("account_purchaser.account_placeholder")}
                            onChange={this.onChangeInput.bind(this)}
                            onKeyPress={event => this.onKeyPress(event)}
                            value={account}
                        />
                        <div className={"a--error"}>{this.renderError("account")}</div>
                    </div>
                    <div className='a-group'>
                        <label htmlFor="name"
                               className="label label--required a-label ">{t("account_purchaser.email_or_phone")}</label>
                        <input
                            spellCheck="false"
                            className="input input--fullwidth input--insert_acount mt-2"
                            type="text" name={"email_or_phone"}
                            placeholder={t("account_purchaser.email_or_phone_placeholder")}
                            onChange={this.onChangeInput.bind(this)}
                            onKeyPress={event => this.onKeyPress(event)}
                            value={email_or_phone}
                        />
                        <div className={"a--error"}>{this.renderError("email_or_phone")}</div>
                    </div>
                    <div className='a-group'>
                        <label htmlFor="name"
                               className="label a-label label--required ">{t("account_purchaser.account_number")}</label>
                        <input
                            spellCheck="false"
                            className="input input--fullwidth input--insert_acount mt-2"
                            type="text" name={"account_number"}
                            placeholder={t("account_purchaser.account_number_placeholder")}
                            onChange={this.onChangeInput.bind(this)}
                            onKeyPress={event => this.onKeyPress(event)}
                            value={account_number}
                        />
                        <div className={"a--error"}>{this.renderError("account_number")}</div>
                    </div>
                    <div className='a-group'>
                        <label htmlFor="name"
                               className="label a-label">{t("account_purchaser.last_number_on_invoice_code")}</label>
                        <input
                            spellCheck="false"
                            className="input input--fullwidth input--insert_acount mt-2"
                            type="text" name={"last_number_on_invoice_code"}
                            placeholder={t("account_purchaser.last_number_on_invoice_code_placeholder")}
                            onChange={this.onChangeInput.bind(this)}
                            onKeyPress={event => this.onKeyPress(event)}
                            value={last_number_on_invoice_code}
                        />
                        <div className={"a--error"}>{this.renderError("last_number_on_invoice_code")}</div>
                    </div>
                    <div className='a-group'>
                        <label htmlFor="site_name"
                               className="label label--required a-label">{t("account_purchaser.site_name")}</label>

                        <Select
                            onChange={values => this.onSelectMulti("site_name", values)}
                            onKeyDown={event => this.onKeyPress(event)}
                            options={Constant.SOURCE_ACCOUNT_PURCHASER}
                            value={site_name}
                            name="site_name"
                            isMulti
                            placeholder={t("account_purchaser.site_name")}
                            className="single-react mt-2"
                            classNamePrefix="react-select--multi"
                        />
                        <div className={"a--error"}>{this.renderError("site_name")}</div>
                    </div>
                    <div className='a-group'>
                        <label htmlFor="status"
                               className="label label--required a-label">{t("account_purchaser.status")}</label>
                        <select
                            className="select select--fullwidth mt-2"
                            name={"status"}
                            onChange={this.onChangeInput.bind(this)}
                            onKeyPress={event => this.onKeyPress(event)}
                            value={status}
                        >
                            {Constant.STATUS_ACCOUNT_PURCHASER.map(
                                status => (<option key={status.value}
                                                   value={status.value}>{t("account_purchaser.status_" + status.label.toLocaleLowerCase())}</option>))}
                        </select>
                        <div className={"a--error"}>{this.renderError("status")}</div>
                    </div>
                    <div className="a--group--inline-end p-2 a--user-group-btn">
                        <Button appearance="primary" type="submit" className=" mr-3 a-btn--submit"
                                onClick={this.onSubmitForm.bind(this)}
                                isLoading={isLoading}
                        >
                            {trans('account_purchaser.btn_action_confirm')}
                        </Button>
                        <a appearance="subtle" onClick={onClose}
                           className={(isLoading ? "hidden " : "") + "a-btn a-btn--none a-btn-link "}>
                            {trans('account_purchaser.btn_action_cancel')}
                        </a>
                    </div>
                </ModalGeneral>
        );
    }
}

ModalForm.defaultProps = {
    accountPurchaser: {
        id: 0,
        account: "",
        site: "Taobao",
        status: 'ACTIVE'
    },
    isLoading: false,
    error: {}
}

ModalForm.propTypes = {
    accountPurchaser: PropTypes.object,
    onSubmitForm: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.object
}

export default translate('translations')(ModalForm);
