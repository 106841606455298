import React, {Component} from 'react';
import {Spin, Select} from "antd";
import apiService from "../apiService";
import {translate} from "react-i18next";
import {processResponse} from "../../../systems/api";
import PropTypes from "prop-types";
import notification from "../../../systems/notification";

const Option = Select.Option;

class SelectTellersV2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            currentValue: "",
        };
    }

    fetchTellers() {
        let {t} = this.props;
        this.setState({loading: true});
        processResponse(apiService.getListTellers())
            .then(res => this.setState({data: res.data, loading: false}))
            .catch(() => {
                notification.error(t('message.cant_fetch_data'));
                this.setState({loading: false});
            });
    }

    componentDidMount() {
        this.fetchTellers();
    }

    getValue() {
        let {value, multiple} = this.props;
        if (!value)
            return multiple ? undefined : "";

        if (multiple) {
            return Array.isArray(value) ? value.map(item => parseInt(item)) : [parseInt(value)];
        } else
            return parseInt(value);
    }

    render() {
        let {t, showName, multiple} = this.props;
        let {data} = this.state;
        return (<React.Fragment>
                <Select
                    placeholder={this.props.placeholder}
                    mode={multiple ? 'multiple' : 'default'}
                    value={this.getValue()}
                    style={this.props.style}
                    loading={this.state.loading}
                    showArrow={this.props.showArrow}
                    onChange={this.props.onChange}
                    showSearch={this.props.showSearch}
                    optionFilterProp={this.props.optionFilterProp}
                    allowClear={this.props.allowClear}
                    className={this.props.className}
                    >
                        {this.props.allOption && !multiple && <Option value="">{t('label.all')}</Option>}
                        {data.map(teller => (
                            <Option
                                key={teller.id}
                                value={teller.id}>
                                {(showName) ? teller.username + '-' + teller.name : teller.username}
                            </Option>
                        ))}
                </Select>
            </React.Fragment>);
    }
}

SelectTellersV2.defaultProps = {
    value: undefined,
    allOption: false,
    multiple: false,
    onChange: value => {},
    className: '',
    placeholder: '',
};

SelectTellersV2.propTypes = {
    value: PropTypes.any,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholer: PropTypes.string
};

export default translate()(SelectTellersV2);
