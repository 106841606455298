import React from 'react';
import {
    Button,
    Table,
    Pagination,
    Select,
    Affix,
    Icon,
    Modal,
    Input,
    Popconfirm, Form,
} from "antd";
import lodash from 'lodash';

const Option = Select.Option;
const TextArea = Input.TextArea;
const {Search} = Input;

function confirm() {
    Modal.confirm({
        className: "a-modal--confirm-trade",
        title: 'UNRESOLVE',
        content: 'Bạn có chắc chắn muốn Unresolve đơn này ?',
        cancelText: 'Hủy',
        okText: 'Unresolve',
        zIndex: 1050,
        icon: <Icon type="question-circle" theme="filled"/>,
    });
}

class ListEmployee extends React.Component {
    state = {
        visible: false,
        visible2: false,
    };

    SepecfyOne = () => {
        this.setState({
            visible: true,
        });
    };

    SepecfyAll = () => {
        this.setState({
            visible2: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
            visible2: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            visible2: false,
        });
    };

    render() {

        const columns = [
            {
                title: 'Nhân viên',
                dataIndex: 'username',
                key: 'username',
                width: 250
            },

            {
                title: 'Tài khoản mua hàng',
                dataIndex: 'codetrade',
                key: 'codetrade',
            }];

        let accounts =
            <div>
                {lodash.range(1, 10).map(id => (
                    <div className={"a-account__employee"} key={id}>
                        <div className="a-account__employee__logo-partner">
                            {
                                id <= 4 ? <
                                        img src={require("../../../resources/img/logo-partner/favicons_taobao.png")}
                                            className="img-partner" alt=""/>
                                    :
                                    <img src={require("../../../resources/img/logo-partner/favicons_1688.png")}
                                         className="img-partner" alt=""/>
                            }

                        </div>
                        <div className="a-account__employee__body">
                            <p className="name-account">{id <= 4 ? '华强数码宝贝店' : 'Le Hoang Thuy Trang'}</p>
                            <span className="date-create-account">Ngày cấp: 25/09/2019</span>
                        </div>
                        <a className="a-account__employee__close">
                            <Icon type="close"/>
                        </a>
                    </div>
                ))}
                <Button className=" a-font--12 aphz-50 a-btn--primary-ant ghost mr-4" onClick={this.SepecfyOne}>Chỉ định
                    tài
                    khoản</Button>
            </div>

        const data = [];

        for (let i = 1; i < 10; i++) {
            data.push({
                username: `Định Nguyễn`,
                codetrade: accounts
            });
        }
        return (
            <div className="a-list mt-5">
                <Affix>
                    <div className="a-list__top a-flex">
                        <h2 className="a-list--title a-text--uppercase">Danh sách nhân viên (200)</h2>
                        <div className="ml-auto">
                            <Button className=" a-font--12 aphz-50 a-btn--primary-ant ghost mr-4"
                                    onClick={this.SepecfyAll}>Chỉ định tài
                                khoản</Button>
                        </div>
                    </div>
                </Affix>
                <div className="a-content--is-pagination-fixed">
                    <Table className={"a-table--antd a-table--account-employee"} columns={columns} dataSource={data}
                           pagination={false}/>

                    <div className="a-pagination a-pagination--fixed ">
                        <Pagination defaultCurrent={5} total={200} className="a-pagination--detail"/>
                        <div className="a-pagination--select-number-item">
                            <span className="text-show mr-2">Hiển thị</span>
                            <Select defaultValue="10" className="a-select--select-number-show"
                                    getPopupContainer={trigger => trigger.parentNode}
                            >
                                <Option value="10">10</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                            </Select>
                        </div>
                    </div>
                </div>

                <Modal
                    width={400}
                    title="CHỈ ĐỊNH TÀI KHOẢN"
                    visible={this.state.visible2}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={false}
                    centered={true}
                    className={"a-modal--list-trade"}
                >
                    <Search
                        placeholder="Chọn tên nhân viên"
                    />
                    <div className={"a-list--user mt-3"}>
                        <div className={"a-user"}>
                            <span className="a-user--name">Trần Huyền Trang</span>
                            <a href="#" class="a-user--icon-remove"><Icon type="close"/></a>
                        </div>
                        <div className={"a-user"}>
                            <span className="a-user--name">Lê Danh Phương</span>
                            <a href="#" className="a-user--icon-remove"><Icon type="close"/></a>
                        </div>
                        <div className={"a-user"}>
                            <span className="a-user--name">Lê Hoàng Thuỳ Trang</span>
                            <a href="#" className="a-user--icon-remove"><Icon type="close"/></a>
                        </div>
                        <div className={"a-user"}>
                            <span className="a-user--name">Lê Trọng</span>
                            <a href="#" className="a-user--icon-remove"><Icon type="close"/></a>
                        </div>
                    </div>
                    <div className="mt-3">
                        <span className="a-text--fz-12 a-text--black-65">Nhà cung cấp</span>
                        <Select placeholder="Chọn nhà cung cấp" className="w-100 mt-02">
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                    </div>
                    <div className="mt-3">
                        <span className="a-text--fz-12 a-text--black-65">Tài khoản mua hàng</span>
                        <Select placeholder="Chọn tài khoản" className="w-100 mt-02">
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                    </div>
                    <div className={"amt-24 d-flex justify-content-end"}>
                        <Button className=" a-font--12 a-btn--default-ant ghost ">Huỷ</Button>
                        <Button className=" a-font--12 a-btn--primary-ant aphz-24 ml-3">Tôi đồng ý</Button>
                    </div>
                </Modal>
                <Modal
                    width={400}
                    title="CHỈ ĐỊNH TÀI KHOẢN"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={false}
                    centered={true}
                    className={"a-modal--list-trade"}
                >
                    <p><span className={"a-text--black-65 a-text--fz-12"}>Nhân viên: </span> <span
                        className={"a-text--bold a-text--black-85"}>Định Nguyễn</span></p>
                    <div className="mt-3">
                        <span className="a-text--fz-12 a-text--black-65 ">Nhà cung cấp</span>
                        <Select placeholder="Chọn nhà cung cấp" className="w-100 mt-02">
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                    </div>
                    <div className="mt-3">
                        <span className="a-text--fz-12 a-text--black-65">Tài khoản mua hàng</span>
                        <Select placeholder="Chọn tài khoản" className="w-100 mt-02">
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                    </div>
                    <div className={"amt-24 d-flex justify-content-end"}>
                        <Button className=" a-font--12 a-btn--default-ant ghost ">Huỷ</Button>
                        <Button className=" a-font--12 a-btn--primary-ant aphz-24 ml-3">Tôi đồng ý</Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default ListEmployee;