import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import Button from '@atlaskit/button';
import FailedComplaintForm from './FailedComplaintForm';

class FailedComplaint extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isOpenForm: false,
        };
    }

    componentWillUnmount() {
        this.props.onReset();
    }

    openForm() {
        this.setState({isOpenForm: true});
    }

    closeForm() {
        this.setState({isOpenForm: false});
    }

    onChangeFormInput(input) {
        let params = lodash.keys(input).filter(param => !!this.props.error[param]);

        if (params.length) {
            this.props.resetParamError(params);
        }
    }

    render() {
        return <div>
            <Button
                className="a-btn a-btn--gray"
                onClick={() => this.openForm()}
            >
                <i className="fas fa-exclamation-circle a-text--red mr-2"/>
                {this.props.t('complaintSeller:failed.btn_failed')}
            </Button>

            {this.state.isOpenForm ? <FailedComplaintForm
                error={this.props.error || {}}
                loading={this.props.loading}
                onChange={this.onChangeFormInput.bind(this)}
                onSubmit={this.props.onSubmit}
                onCancel={() => this.closeForm()}
            /> : null}
        </div>;
    }
}

FailedComplaint.defaultProps = {
    error: {},
    loading: false,
    onChange: (input) => {},
    onSubmit: (input) => {},
    onReset: () => {},
    resetParamError: (params) => {},
};

FailedComplaint.propTypes = {
    error: PropTypes.object,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onReset: PropTypes.func,
    resetParamError: PropTypes.func,
};

export default translate()(FailedComplaint);
