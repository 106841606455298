import React from "react";
import apiService from "../../apiService";
import {processResponse} from "../../../../../systems/api";
import {events} from "../../../../../systems/events";
import TRANSACTION_EVENT from "../../../events";
import {Col, Icon, Popconfirm, Row} from "antd";
import Link from "../../../../../systems/routing/Link";
import {url} from "../../../../../systems/routing";
import notification from "../../../../../systems/notification";

class RelatedTransactionCodeItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: false};
    }

    handleConfirm = () => {
        this.setState({loading: true});
        const {transaction, related_transaction} = this.props;
        let res = apiService.deleteRelatedTransaction(transaction.id, related_transaction.id);
        let process = processResponse(res);

        process.then(res => {
            events.dispatch(TRANSACTION_EVENT.DELETE_RELATED_TRANSACTION, {
                transaction: {...transaction, ...(res.data.transaction || {})},
            });
            this.setState({loading: false});
        });

        process.catch(() => {
            notification.error("Xóa giao dịch liên quan không thành công!");
        });
    }

    render() {
        const {related_transaction} = this.props;
        return <Row className="pb-1">
            <Col span={21}>
                <Link
                    isNewTab={true}
                    href={url.to('transaction.detail', {id: related_transaction.id})}
                    className="link_item">
                    {related_transaction.code}
                </Link>
            </Col>
            <Col span={3} className="a-text--right">
                {
                    this.state.loading ? <Icon type="loading" /> :
                        <Popconfirm
                            title="Bạn chắc chắn muốn xóa giao dịch liên quan?"
                            onConfirm={this.handleConfirm}
                            okText="Đồng ý"
                            cancelText="Hủy"
                        >
                            <a href="#">Xóa</a>
                        </Popconfirm>
                }
            </Col>
        </Row>;
    }
}

export default RelatedTransactionCodeItem;