import {createSelector} from 'reselect';
import lodash from "lodash";
import {VIOLATE_ORDER_ACTION_STATUS, VIOLATE_REASON_TYPE} from "../../systems/globalContant/actionContant";
import {t} from './../../systems/i18n/index.js';
import {dateFormatter} from "../common/services/format";

const ordersSelector = state => lodash.get(state, 'ViolatePolicyOrder.list', []);

export const getOrders = createSelector(
    ordersSelector,
    orders => orders.map(order => orderDetail(order))
);

function orderDetail({...order}) {
    if (order.order_partner_time_deposit) {
        order.time_deposit_time_format = dateFormatter.onlyTime(order.order_partner_time_deposit);
        order.time_deposit_date_format = dateFormatter.date(order.order_partner_time_deposit);
    }

    if (order.time_teller) {
        order.time_teller_time_format = dateFormatter.onlyTime(order.time_teller);
        order.time_teller_date_format = dateFormatter.date(order.time_teller);
    }

    if (order.time_paid_staff) {
        order.time_paid_staff_time_format = dateFormatter.onlyTime(order.time_paid_staff);
        order.time_paid_staff_date_format = dateFormatter.date(order.time_paid_staff);
    }

    if (lodash.has(order, 'policies') ) {
        let policiesBuying = lodash.get(order, "policies.BUYING", {});
        if (!lodash.isEmpty(policiesBuying)) {
            order.can_change_reasons = policiesBuying.policy_can_change_reasons;
            if (policiesBuying.policy_violate_reasons_type === VIOLATE_REASON_TYPE.SUBJECTIVITY) {
                order.type_reason_class = 'danger';
            }
            if (policiesBuying.policy_violate_reasons_type === VIOLATE_REASON_TYPE.OBJECTIVITY) {
                order.type_reason_class = 'success';
            }

            order.id_order_policy = policiesBuying.id_order_policy;
            order.order_policy_status = policiesBuying.policy_status;
            order.violate_reason_order = policiesBuying.policy_violate_reasons_info;
            order.violate_reason_order_id = policiesBuying.policy_violate_reasons_id;
        }

        if (order.order_policy_status === VIOLATE_ORDER_ACTION_STATUS.WAITING_PROCESS) {
            order.order_policy_status_class = 'a-label--warning';
            order.can_change_reasons = false;
        }

        if (order.order_policy_status === VIOLATE_ORDER_ACTION_STATUS.CONFIRMED) {
            order.order_policy_status_class = 'a-label--success';
        }

    }

    // if (lodash.has(order, 'policies') ) {
    //     let policiesInternal = lodash.get(order, "policies.INTERNAL_BUYING", {});
    //     if (!lodash.isEmpty(policiesInternal)) {
    //         order.can_change_reasons = policiesInternal.policy_can_change_reasons;
    //         if (policiesInternal.policy_violate_reasons_type === VIOLATE_REASON_TYPE.SUBJECTIVITY) {
    //             order.type_reason_class = 'danger';
    //         }
    //         if (policiesInternal.policy_violate_reasons_type === VIOLATE_REASON_TYPE.OBJECTIVITY) {
    //             order.type_reason_class = 'success';
    //         }

    //         order.id_order_policy = policiesInternal.id_order_policy;
    //         order.order_policy_status = policiesInternal.policy_status;
    //         order.violate_reason_order = policiesInternal.policy_violate_reasons_info;
    //         order.violate_reason_order_id = policiesInternal.policy_violate_reasons_id;
    //     }

    //     if (order.order_policy_status === VIOLATE_ORDER_ACTION_STATUS.WAITING_PROCESS) {
    //         order.order_policy_status_class = 'a-label--warning';
    //         order.can_change_reasons = false;
    //     }

    //     if (order.order_policy_status === VIOLATE_ORDER_ACTION_STATUS.CONFIRMED) {
    //         order.order_policy_status_class = 'a-label--success';
    //     }

    // }

    return order;
}

export const getViolateReasons = state => lodash.get(state.ViolateReasons, 'list', []);


const accountsSelector = state => lodash.get(state, "User.purchasers", []);

export const getAccountsSelector = createSelector(
    accountsSelector,
    accounts => buildAccount(accounts)
);

function buildAccount(accounts) {

    return accounts.map((account) => {
        return {
            label: account.username,
            value: account.id
        };
    });
}

export const VIOLATE_ORDER_STATUS = [
    {
        label: t("violate:list." + VIOLATE_ORDER_ACTION_STATUS.WAITING_PROCESS),
        value: VIOLATE_ORDER_ACTION_STATUS.WAITING_PROCESS
    },
    {
        label: t("violate:list." + VIOLATE_ORDER_ACTION_STATUS.CONFIRMED),
        value: VIOLATE_ORDER_ACTION_STATUS.CONFIRMED
    }
];