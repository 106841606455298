import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {dateFormatter, currentFormatter} from './../../../common/services/format';


class Alipays extends React.Component {
    render() {
        let {order, t} = this.props;
        let alipayTransactions = lodash.get(order, 'alipayTransactions', []);
        let rate = lodash.get(order,'rate_exchange', 0);
        rate = parseFloat(rate);
        return (
            <div className="a-content--box-shadow  a__complaint__detail__block mt-3">
                <a href="javascript:;" className="a-link--uppercase">
                    {t('complaintSeller:detail.alipay_transaction')} <i className="fas fa-plus"/>
                </a>
                <div className="table-responsive">
                    <table className="table a-table--deal_alipay">
                        <thead>
                        <tr>
                            <th>
                                {t('complaintSeller:detail.time')}
                            </th>
                            <th>
                                {t('complaintSeller:detail.alipay_code')}
                            </th>
                            <th>
                                {t('complaintSeller:detail.type_transaction')}
                            </th>
                            <th>
                                {t('complaintSeller:detail.value')}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {alipayTransactions.map(alipayTransactionItem => (
                            <tr key={alipayTransactionItem.id}>
                                <td>
                                    {alipayTransactionItem.created_at_text}
                                </td>
                                <td>
                                    <span>
                                        {alipayTransactionItem.ali_code}
                                    </span>
                                </td>
                                <td className="a-text--bold a-text--uppercase">
                                    {
                                        t('complaintSeller:transaction_type.'+
                                            lodash.get(alipayTransactionItem, 'transaction_type', '').toLowerCase())
                                    }
                                </td>
                                <td>
                                    <p>
                                        <span>{lodash.get(alipayTransactionItem, 'balance_text', '')} ¥ </span>
                                        /
                                        <span>
                                        {lodash.get(alipayTransactionItem, 'balance', 0) * rate ? currentFormatter.toLocaleStringCurrency(lodash.get(alipayTransactionItem, 'balance', 0) * rate) : '---'}đ
                                    </span>
                                    </p>
                                </td>
                            </tr>
                        ))}
                        {!alipayTransactions.length ? (
                            <tr>
                                <td colSpan={4} className="text-center">
                                    {t('complaintSeller:detail.not_found_data_alipay')}
                                </td>
                            </tr>
                        ) : null}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

Alipays.defaultProps = {
    order: {},
};

Alipays.propTypes = {
    order: PropTypes.object,
};

export default translate()(Alipays);
