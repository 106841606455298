import {apiWithToken} from '../../systems/api';
import {API_URL} from './../../systems/globalContant';

export default {
    /**
     * Gọi api tạo mới yêu cầu xuất doanh số
     * @param params
     */
    createReports: (params) => {
        if (params.purchaser_id.includes('all')) {
            params = {...params, purchaser_id: 'all'}
        }
        return apiWithToken.post(API_URL.DEFAULT + '/purchase-revenue-reports', {...params})
    },

    /**
     * Gọi api thêm mới yêu cầu xuất doanh số
     * @param datas
     */
    newRequestReport: (params={}) => apiWithToken.put(API_URL.DEFAULT + '/purchase-revenue-reports/reset', params),

    /**
     * Gọi api thực hiện hủy yêu cầu xuất doanh số
     * @param filters
     */
    createCancelReports: (filters) => apiWithToken.delete(API_URL.DEFAULT + '/purchase-revenue-reports/'+filters.id, {...filters}),

    /**
     * Gọi api lấy report đang thực hiện
     * @param params
     */
    getCurrentReports: (params) => apiWithToken.get(API_URL.DEFAULT + '/purchase-revenue-reports/current', {
        params: {},
        singleRequest: true,
        loading: false
    }),

    /**
     * Gọi api danh sách các report
     * @param params
     */
    getListReports: (params) => apiWithToken.get(API_URL.DEFAULT + '/purchase-revenue-reports', {
        params: params,
        singleRequest: true,
        loading: false
    }),
};
