import {put, call} from 'redux-saga/effects';
import lodash from 'lodash';
import apiService from '../apiService';
import {t} from '../../../systems/i18n';
import bootbox from './../../common/components/Bootbox/bootbox';
import * as Constants from './constants';
import * as GlobalConstants from './../../../systems/globalContant';
import {router, url} from './../../../systems/routing/index';

/**
 * Hàm lấy xử lý tạo yêu cầu trả hàng
 * @param action
 */
export function* createProductReturn(action) {
    try {
        let response = yield call(apiService.createProductReturn, action.payload.params);
        if(!response) {
            return;
        }
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.CREATE_PRODUCT_RETURN.SUCCESS, payload: response.data});
            bootbox.success(t('complaintSeller:product_return.message_product_return_success'));
            if(lodash.get(response, 'data.data.product_return.id', null)){
                router.redirect(url.to('product.return.detail', {id: lodash.get(response, 'data.data.product_return.id', null)}));
            }
        } else {
            bootbox.error(t('complaintSeller:product_return.message_product_return_failed'));
            yield put({
                type: Constants.CREATE_PRODUCT_RETURN.FAILED, payload: {...response.data.data, ...action.payload.params}
            });
        }
    } catch (err) {
        yield put({type: Constants.CREATE_PRODUCT_RETURN.FAILED, payload: {}});
        bootbox.error(t(err.message) || t('system.error'));
    }
}
