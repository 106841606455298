import React from "react";
import {translate} from "react-i18next";

class SortByColumn extends React.Component {

    constructor(props) {
        super(props);
    }

    onOrderBy = () => {
        let {filter, column} = this.props;

        this.props.onSearch({
            ...filter,
            orderBy: column,
            sort: filter.sort ? filter.sort : 'asc'
        });
    }

    onSort = () => {
        let {filter} = this.props;

        this.props.onSearch({
            ...filter,
            sort: filter.sort === 'asc' ? 'desc' : 'asc'
        });
    }

    render() {
        let {t, column, filter, onSearch} = this.props;
        let {orderBy, sort} = filter;
        return (
            <>
                {t('order:sale_report.column.' + column)}
                {
                    orderBy !== column ?
                        <i onClick={this.onOrderBy} className="fas fa-sort ml-2 a--cursor-poiter" /> :
                        <i onClick={this.onSort} className={sort === 'asc' ? "fas fa-sort-amount-up ml-2 a--cursor-poiter" : "fas fa-sort-amount-down ml-2 a--cursor-poiter"} />
                }
            </>
        );
    }
}

export default translate()(SortByColumn);