import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Affix, Layout, Table, Empty} from "antd";
import {translate} from "react-i18next";
import notification from "../../../systems/notification";
import apiService from "../../../modules/PaymentRequest/apiService";
import {processResponse} from "../../../systems/api";
import lodash from "lodash";
import queryString from "query-string";
import ProblemConfirmation from "../../../modules/PaymentRequest/ProblemConfirmation";
import Link from "../../../systems/routing/Link";
import {dispatch} from "../../../systems/store";
import {setBreadCrumb, setMenuActive, setPageDescription, setPageTitle} from "../../../modules/common/actions";

const Content = Layout.Content;

const COLUMN = {
    ORDER: 'order',
    PROBLEM: 'problem',
    ACTION: 'action',
};

class PaymentRequestConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirm: {},
            violateReasons: [],
            loading: false
        }
    }

    componentDidMount() {
        this.setPageInfo();
        this.getPaymentRequestDetail();
    }
    setPageInfo() {
        let {t} = this.props;

        dispatch(setPageTitle(t('paymentRequest:page.confirm_title')));
        dispatch(setPageDescription(t('paymentRequest:page.confirm_description')));
        dispatch(setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'paymentRequest:page.confirm_title',
                'link': '/payment-requests'
            },
        ]));
    }
    getPaymentRequestDetail() {
        let {location} = this.props;
        let filter = this.getFilter(location);
        if (this.state.loading) {
            return;
        }

        let {t} = this.props;

        this.setState({loading: true});

        let res = apiService.getPaymentRequestDetail({id: filter.id})
            .finally(() => this.setState({loading: false}));

        let process = processResponse(res);

        process.then(res => {
            let status = lodash.get(res, 'data.status', {});
            let code = lodash.get(res, 'code', {});
            this.setState({
                requestId: lodash.get(res, 'data.id', ''),
                confirm: lodash.get(res, 'data.confirm', {}),
                violateReasons: lodash.get(res, 'data.violateReasons', [])
            });
        });

        process.catch((err) => {
            let error = lodash.get(err, 'data', {});
            error.hasOwnProperty('status') && notification.error(t('paymentRequest:message.no_problem'));
            let invoice_codes = lodash.get(err, 'data.errors.invoice_codes', []);
            if (invoice_codes.length !== 0) {
                let invoice_codes_text = lodash.join(invoice_codes, ', ');
                notification.error(t('paymentRequest:message.invoice_code_invalid', {invoice_codes: invoice_codes_text}))
            }
        });
    }

    getFilter(location) {
        return queryString.parse(location.search, {arrayFormat: 'bracket'});
    }

    getDataSource() {
        let {t} = this.props;
        let {confirm} = this.state;
        let dataSource = [];
        let policyProblem = confirm.hasOwnProperty('policy') && confirm.policy.length !== 0 ? confirm.policy.map(order => {
            return {
                key: order.id,
                [COLUMN.ORDER]:
                    <React.Fragment>
                        <div className="d-flex">
                            <div
                                style={{width: '75px', height: '75px', backgroundImage: `url(${order.item.image})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                                className="mr-2"
                            >
                            </div>
                            <div className="d-flex flex-column">
                                <Link className="a-text--color-primary a-text--bold mb-1" href={`/purchase-buying/${order.id}`} isNewTab={true}>
                                    #{order.code}
                                </Link>
                                <span className="a-form__group__value">
                                    <span className={"img-logo-partner _" + order.seller_source}>
                                    </span>{lodash.upperFirst(order.seller_source)}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>,
                [COLUMN.PROBLEM]: <span>{t('paymentRequest:confirm_problem.policy')}</span>,
                [COLUMN.ACTION]: <ProblemConfirmation type={'policy'} requestId={this.state.requestId} order={order} violateReasons={this.state.violateReasons}/>
            }
        }) : [];
        let balanceProblem = confirm.hasOwnProperty('balance') && confirm.balance.length !== 0 ? confirm.balance.map(order => {
            return {
                key: order.id,
                [COLUMN.ORDER]:
                    <React.Fragment>
                        <div className="d-flex">
                            <div
                                style={{width: '75px', height: '75px', backgroundImage: `url(${order.item.image})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                                className="mr-2"
                            >
                            </div>
                            <div className="d-flex flex-column">
                                <Link className="a-text--color-primary a-text--bold mb-1" href={`/purchase-buying/${order.id}`} isNewTab={true}>
                                    #{order.code}
                                </Link>
                                <span className="a-form__group__value">
                                    <span className={"img-logo-partner _" + order.seller_source}>
                                    </span>{lodash.upperFirst(order.seller_source)}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>,
                [COLUMN.PROBLEM]: <span className="a-text--color-warning">{t('paymentRequest:confirm_action.balance')}</span>,
                [COLUMN.ACTION]:
                    <React.Fragment>
                        <ProblemConfirmation type={'balance'} requestId={this.state.requestId} order={order} violateReasons={this.state.violateReasons}/>
                    </React.Fragment>
            }
        }) : [];
        dataSource = lodash.concat(dataSource, policyProblem, balanceProblem);
        return dataSource;
    }

    getColumns() {
        let {t} = this.props;

        let columns = [
            {
                key: COLUMN.ORDER,
                selected: true,
            },
            {
                key: COLUMN.PROBLEM,
                selected: true,
            },
            {
                key: COLUMN.ACTION,
                selected: true,
                className: 'a-text--break-all'
            }
        ];
        return columns.map(column => ({
            dataIndex: column.key,
            title: t('paymentRequest:column.' + column.key),
            ...column,
        }));
    }

    render() {
        let {t} = this.props;
        return (
            <Layout>
                <Content className="a-content--ant-layout ">
                    <div className="a-list mt-5 pb-2">
                        <Affix>
                            <div className="a-list__top a-flex">
                                <h2 className="a-list--title a-text--uppercase">Yêu cầu xác nhận</h2>
                                <div className="ml-auto">
                                </div>
                            </div>
                        </Affix>
                        <Table
                            className={"a-table--antd a-table--description"}
                            columns={this.getColumns()}
                            dataSource={this.getDataSource()}
                            pagination={false}
                            scroll={{x: 'fit-content'}}
                            loading={this.props.loading}
                            locale={{
                                emptyText: (
                                    <Empty
                                        image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                        description={<span>{t("transaction:label.no_data")}</span>}
                                    >
                                    </Empty>)
                            }}
                        />
                    </div>
                </Content>
            </Layout>
        );
    }
}

PaymentRequestConfirmation.propTypes = {};

export default translate()(PaymentRequestConfirmation);
