import {combineReducers} from 'redux';
import * as Constant from './constants'
import lodash from 'lodash';

const list = (state = [], action) => {
    switch (action.type) {
        case Constant.GET_LIST_AGENCY.SUCCESS:
            return [...lodash.get(action, "payload.data", [])];
        case Constant.GET_LIST_AGENCY.REQUEST:
            return [];
        default:
            return state;
    }
};

export default combineReducers({
    list,
});