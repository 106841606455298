
export const FETCH_CONFIG_SYSTEM = {
    REQUEST: 'FETCH_CONFIG_SYSTEM_REQUEST',
    SUCCESS: 'FETCH_CONFIG_SYSTEM_SUCCESS',
    FAILED: 'FETCH_CONFIG_SYSTEM_FAILED',
};

export const UPDATE_CONFIG_SYSTEM = {
    REQUEST: 'UPDATE_CONFIG_SYSTEM_REQUEST',
    SUCCESS: 'UPDATE_CONFIG_SYSTEM_SUCCESS',
    FAILED: 'UPDATE_CONFIG_SYSTEM_FAILED',
};

// Các cấu hình cần được xác nhận trước khi thay đổi do ảnh hưởng tới các hoạt động khi chạy
export const CONFIRM_CODES = ["ORDER_GROUP:USING", "ORDER_GROUP:REQUIRE_WHEN_PAY", "DISTRIBUTIVE_WAREHOUSE:USING", "DISTRIBUTIVE_WAREHOUSE:REQUIRED_ORDER"];
export const CONFIRM_MESSAGES = {
    "ORDER_GROUP:USING": "Cấu hình này sẽ ảnh hưởng khi thanh toán đơn",
    "ORDER_GROUP:REQUIRE_WHEN_PAY": "Cấu hình này sẽ ảnh hưởng khi thanh toán đơn",
    "DISTRIBUTIVE_WAREHOUSE:USING": "Cấu hình này sẽ ảnh hưởng khi nhận đơn",
    "DISTRIBUTIVE_WAREHOUSE:REQUIRED_ORDER": "Cấu hình này sẽ ảnh hưởng khi nhận đơn",
    "ORDER_DISTRIBUTOR:MAX_WAITING_ITEM_STATUS": "Cấu hình này sẽ ảnh hưởng khi nhận đơn"
};

export const UPLOAD_LOGO = 'SYSTEM:UPDATE_LOGO';
export const TRANSACTION_TYPE_FINALI_ORDER = "TRANSACTION:TYPE_FINALI_ORDER";
export const COMPLAINT_SELLER_CREATE_AUTO_BY_AGENCY = "COMPLAINT_SELLER:CREATE_AUTO_BY_AGENCY";
export const SYSTEM_SELLER_SOURCE = "SYSTEM:SELLER_SOURCE";
export const ORDER_DISTRIBUTOR_MAX_WAITING_ITEM_STATUS = "ORDER_DISTRIBUTOR:MAX_WAITING_ITEM_STATUS";
export const SYSTEM_CAN_EXPORTED_TOKEN = "SYSTEM:CAN_EXPORTED_TOKEN";
export const ORDER_DISTRIBUTOR_TYPE = "ORDER_DISTRIBUTOR:TYPE";
