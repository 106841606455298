import React from 'react';
import {Form, Row, Col, Input, DatePicker, Button, Icon} from 'antd';
import FormProcessing from '../../../modules/common/components/FormProcessing';
import {setActiveSettingItem} from '../../../modules/Settings/SettingMenu/actions';
import moment from "moment";
import SelectAccountPurchaser from "../../../modules/AccountPurchaser/SelectAccountPurchasersByAccountName"
import {translate} from "react-i18next";
import PropTypes from "prop-types";
const Search = Input.Search;
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY/MM/DD';


class FormSearch extends FormProcessing  {
    constructor(props) {
        super(props);

        this.defaultInput = {
            username: '',
            site: '',
            account: '',
            time_from: '',
            time_to: ''
        };

        this.state = {
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
        };
    }
    componentDidMount() {
        setActiveSettingItem('specify_purchaser');
    }
    onChangeText(param, event) {
        this.onChangeInput(param, event);
    }
    onChangeSpecifyTime(date) {
        this.changeInput({
            'time_from': date[0] ? date[0].format('YYYY-MM-DD') : '',
            'time_to': date[1] ? date[1].format('YYYY-MM-DD') : '',
        });
    }
    onSubmit(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.props.onSearch({...this.state.input});
        }
    }
    onReset(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.changeInput(this.defaultInput);
            this.props.onSearch(this.defaultInput);
        }
    }

    render() {
        let {t} = this.props;
        let {input} = this.state;
        return (
            <div className="a-list--form-search mr-0 ml-0">
                <Row gutter={32}>
                    <Form labelCol={{span: 12}} onSubmit={this.onSubmit.bind(this)} >
                        <Col lg={6} xl={5} xxl={4}>
                            <Form.Item label={t("purchaser:label.tellers")} className="a-form--ant-item  mb-0">
                                <Search
                                    value={input.username}
                                    placeholder={t("purchaser:placeholder.tellers")}
                                    onChange={this.onChangeText.bind(this, 'username')}/>
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={4} xxl={4}>
                            <Form.Item label={t("purchaser:label.account")} className="a-form--ant-item  mb-0">
                                <SelectAccountPurchaser
                                    value={input.account || ''}
                                    allOption={true}
                                    onChange={account => this.changeInput({account})}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={7} xl={6} xxl={5}>
                            <Form.Item label={t("purchaser:label.created_time")} className="a-form--ant-item  mb-0">
                                <RangePicker
                                    placeholder={[t("purchaser:placeholder.from"), t("purchaser:placeholder.to")]}
                                    format='DD/MM/YYYY'
                                    value={[
                                        input['time_from'] ? moment(input['time_from'], dateFormat) : null,
                                        input['time_to'] ? moment(input['time_to'], dateFormat) : null,
                                    ]}
                                    onChange={this.onChangeSpecifyTime.bind(this)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{span: 24}} lg={{span: 24}} xl={{span: 24}} className="mt-4 pt-5 border-top-dashed">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="a-list--action-search flex-row ml-auto">
                                    <a
                                        href="#"
                                        className="link-reset mr-5"
                                        onClick={this.onReset.bind(this)}
                                    ><Icon type="sync"/>{t("btn.reset_search")}</a>
                                    <Button
                                        loading={this.props.loading}
                                        className="a-text--uppercase a-btn--padding-large a-btn--primary-ant"
                                        type="primary"
                                        htmlType="submit"
                                    >{t('btn.search')}</Button>
                                </div>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </div>

        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: null,
    loading: false,
    onSearch: (input) => {},
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.any,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

export default translate()(FormSearch);
