import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import ComplaintMarkImportant from './components/index';
import {updateMarkImportant, clearMarkImportant} from './actions';

const mapStateToProps = (state, props) => {
    let {permissions} = props;
    return {
        ...props,
        canMark: lodash.get(permissions, 'can_mark', false),
        isLoading: lodash.get(state, 'ComplaintSeller.complaintMarkImportant.isLoading', false),
        isEndProcess: lodash.get(state, 'ComplaintSeller.complaintMarkImportant.isEndProcess', false),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    let {complaint} = props;

    return {
        onUpdateMarkImportant: (params) => {
            dispatch(updateMarkImportant({id: complaint.id, params: params}));
        },
        clearMarkImportant: () => {
            dispatch(clearMarkImportant({}));
        },
    };
};

let ComplaintSellerMarkImportantContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ComplaintMarkImportant);

ComplaintSellerMarkImportantContainer.defaultProps = {
    complaint: {},
    view: false,
};

ComplaintSellerMarkImportantContainer.propTypes = {
    complaint: PropTypes.object.isRequired,
    view: PropTypes.bool,
};

export default ComplaintSellerMarkImportantContainer;
