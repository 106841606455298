import React from 'react';

class WithEventFactory {
    /**
     * @param {Dispatcher} dispatcher
     */
    constructor(dispatcher) {
        this.dispatcher = dispatcher;
    }

    make(Component) {
        let dispatcher = this.dispatcher;

        class ComponentWithEvent extends React.Component {
            constructor(props) {
                super(props);
                this.cancelEvent = null;
            }

            componentWillUnmount() {
                this.removeEvent();
            }

            listenEvent(events, listener = null) {
                this.removeEvent();
                this.cancelEvent = dispatcher.listen(events, listener);
            }

            removeEvent() {
                if (this.cancelEvent) {
                    this.cancelEvent();
                    this.cancelEvent = null;
                }
            }

            render() {
                return <Component
                    {...this.props}
                    events={dispatcher}
                    on={this.listenEvent.bind(this)}
                    off={this.removeEvent.bind(this)}
                />
            }
        }

        return ComponentWithEvent;
    }
}

export default WithEventFactory;
