import React, {useEffect} from "react";
import {connect} from "react-redux";
import {fetchFeatures} from "../actions";
import lodash from "lodash";
import {Select} from "antd";
import * as Selector from "../selector";

const {Option} = Select;

function SelectFeatures({features, fetchFeatures, isLoading, allowClear, multiple, onChange, className, placeholder}) {

    useEffect(() => {
        if (lodash.isEmpty(features))
            fetchFeatures();
    }, []);

    return (
        <Select
            showSearch={true}
            optionFilterProp={"children"}
            mode={multiple ? 'multiple' : 'default'}
            allowClear={allowClear}
            loading={isLoading}
            onChange={onChange}
            className={className}
            placeholder={placeholder}
        >
            {features.map(feature => <Option key={feature.id} value={feature.id}>{feature.name}</Option>)}
        </Select>
    );
}

const mapStateToProps = states => {
    let features = [];
    lodash.get(states, "Features.list", []).forEach(feature => {
        if (lodash.isArray(feature.properties))
            features = [...features, ...feature.properties];
    });
    return {
        features,
        isLoading: lodash.get(states, "Features.isLoading", false)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchFeatures: (params = {}) => dispatch(fetchFeatures(params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectFeatures);
