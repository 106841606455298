export const CREATE_EXPORT_REPORT = {
    REQUEST: 'CREATE_EXPORT_REPORT_REQUEST',
    SUCCESS: 'CREATE_EXPORT_REPORT_SUCCESS',
    FAILED: 'CREATE_EXPORT_REPORT_FAILED',
    CANCEL: 'CREATE_EXPORT_REPORT_CANCEL'
};

export const NEW_REQUEST_REPORT = {
    REQUEST: 'NEW_REQUEST_REPORT_REQUEST',
    SUCCESS: 'NEW_REQUEST_REPORT_SUCCESS',
    FAILED: 'NEW_REQUEST_REPORT_FAILED',
};

export const CANCEL_EXPORT_REPORT = {
    REQUEST: 'CANCEL_EXPORT_REPORT_REQUEST',
    SUCCESS: 'CANCEL_EXPORT_REPORT_SUCCESS',
    FAILED: 'CANCEL_EXPORT_REPORT_FAILED'
};

export const GET_LIST_REPORTS = {
    REQUEST: 'GET_LIST_REPORTS_REQUEST',
    SUCCESS: 'GET_LIST_REPORTS_SUCCESS',
    FAILED: 'GET_LIST_REPORTS_FAILED'
};

export const GET_CURRENT_REPORT = {
    REQUEST: 'GET_CURRENT_REPORT_REQUEST',
    SUCCESS: 'GET_CURRENT_REPORT_SUCCESS',
    FAILED: 'GET_CURRENT_REPORT_FAILED'
};

export const GET_PUSHER_REPORT = "PURCHASE_REPORT_PROGRESS";
export const CLEAR_ERROR_REPORT = "CLEAR_ERROR_REPORT";
