import {call, put} from 'redux-saga/effects';
import lodash from 'lodash';
import * as Constants from './constants';
import apiService from '../apiService';
import {t} from '../../../systems/i18n';
import * as GlobalConstants from './../../../systems/globalContant';
import bootbox from './../../common/components/Bootbox/bootbox';

/**
 * Hàm cập nhật ghi chú
 * @param action
 */
export function* updateMarkImportant(action) {
    try {
        let response = yield call(apiService.markImportUpdateComplaintDetail, action.payload.params);
        if(!response) {
            return;
        }
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({
                type: Constants.COMPLAINT_MARK_IMPORTANT.SUCCESS,
                payload: {...response.data, ...{'params': {...action.payload.params.params, ...{id: action.payload.params.id}}}}
            });
            bootbox.success(t('complaintSeller:detail.seller_note_success'));
        } else {
            bootbox.error(t('complaintSeller:detail.seller_note_failed'));
            yield put({
                type: Constants.COMPLAINT_MARK_IMPORTANT.FAILED,
                payload: {...response.data, ...{'params': {...action.payload.params.params, ...{id: action.payload.params.id}}}}
            });
        }
    } catch (err) {
        yield put({type: Constants.COMPLAINT_MARK_IMPORTANT.ERROR, payload: {'params': {...action.payload.params.params, ...{id: action.payload.params.id}}}});
        bootbox.error(t(err.message) || t('system.error'));
    }
}