import {call, put} from 'redux-saga/effects'
import * as Constants from './constants';
import service from './apiService';
import bootbox from "../../modules/common/components/Bootbox/bootbox";
import {t} from './../../systems/i18n/index.js';
import * as GlobalConstants from "../../systems/globalContant";

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* orderBillNoSyncs(action) {
    try {
        let response = yield call(service.orderBillNoSyncs, action.payload);
        if (response) {
            if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({type: Constants.ORDER_SYNC_BILL_NO.SUCCESS, payload: response.data, request: action.payload});
                bootbox.success(t("orderDetail:order_detail.sync_order_bill_no.success"));
            } else {
                yield put({type: Constants.ORDER_SYNC_BILL_NO.FAILED, payload: response.data});
            }
        }
    } catch (err) {
        bootbox.error(t('global.error'));
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getOrdersBillNo(action) {
    try {
        let response = yield call(service.getOrdersBillNo, action.payload);
        if (response) {
            if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({type: Constants.ORDER_LIST_BILL_NO.SUCCESS, payload: response.data});
            } else {
                yield put({type: Constants.ORDER_LIST_BILL_NO.FAILED, payload: response.data});
            }
        }
    } catch (err) {
        bootbox.error(t('global.error'));
    }
}
