import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {ToggleStateless} from '@atlaskit/toggle';
import FormProcessing from './../../../../common/components/FormProcessing';
import { Switch } from 'antd';

class OrderItemStatus extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                id: '',
                is_pai: 0
            },
            inputVersion: 0,
            changedParams: [],
        };
    }

    /**
     * Cập nhật dữ liệu thay đổi của input
     * @param event
     */
    onHandleChange(event) {
        let input = this.state.input;
        let target = event.target;
        let value = target.value;
        let name = target.name;
        if (name === 'is_pai') {
            value = input.is_pai ? 0 : 1;
        }
        if (name === 'code') {
            value = value.trim();
            value = value.replace(/\s/g, '');
        }
        this.changeInput({[name]: value});
        this.updateFormData({[name]: value});
        let item = this.props.item;
        item = {...item, ...{is_pai: value}};
        this.props.onUpdatePaidOrderDetail(item);
    }

    /**
     * Cập nhật dữ liệu thay đổi của Switch
     * @param status
     * @param name
     */
    onHandleChangeSwitch(name, status) {
        let value = status ? 1 : 0;
        this.changeInput({[name]: value});
        this.updateFormData({[name]: value});
        let item = this.props.item;
        item = {...item, ...{is_pai: value}};
        this.props.onUpdatePaidOrderDetail(item);
    }

    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        this.setState(state => ({input: {...state.input, ...input}}));
    }

    render() {
        let {item} = this.props;
        let {input} = this.state;
        return (
            <div className="flex--column a-toggle__item ">
                <div className={`toggleState a-purchaser__paid_${item.id}`}>
                    <Switch
                        checked={!!input.is_pai}
                        size="small"
                        onChange={this.onHandleChangeSwitch.bind(this, 'is_pai')}
                        className="gtoogle"
                    />
                </div>
            </div>
        );
    }
}

OrderItemStatus.defaultProps = {
    order: {},
    item: {},
};

OrderItemStatus.propTypes = {
    order: PropTypes.object,
    item: PropTypes.object
};

export default translate('translations')(OrderItemStatus);
