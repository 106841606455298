import {combineReducers} from 'redux';
import * as Constant from './constants'
import * as UploadConstant from './../UploadFileCSV/constants';
import lodash from 'lodash';

const list = (state = [], action) => {
    switch (action.type) {
        case Constant.GET_LIST_TRANSACTION_FILE.SUCCESS:
            return [...lodash.get(action, "payload.data.transactionFiles.data", [])];
        case UploadConstant.TRANSACTION_UPLOAD_FILE_CSV.SUCCESS:
            let newFile = lodash.get(action, 'payload.file.response.data', {});
            if(lodash.isObject(newFile)){
                newFile.total_transaction = 0
            }
            return [newFile, ...state];
        case Constant.GET_LIST_TRANSACTION_FILE.REQUEST:
        case Constant.TRANSACTION_LIST_FILE_CLEAR_STATE:
            return [];
        default:
            return state;
    }
};

const loading = (state = false, action) => {
    switch (action.type) {
        case Constant.GET_LIST_TRANSACTION_FILE.SUCCESS:
        case Constant.GET_LIST_TRANSACTION_FILE.FAILED:
        case Constant.TRANSACTION_LIST_FILE_CLEAR_STATE:
            return false;
        case Constant.GET_LIST_TRANSACTION_FILE.REQUEST:
            return true;
        default:
            return state;
    }
};

const pagination = (state = {}, action) => {
    switch (action.type) {
        case Constant.GET_LIST_TRANSACTION_FILE.SUCCESS:
            return {...lodash.get(action, "payload.data.pagination", {})};
        case Constant.GET_LIST_TRANSACTION_FILE.REQUEST:
        case Constant.TRANSACTION_LIST_FILE_CLEAR_STATE:
            return {};
        default:
            return state;
    }
};

const permission = (state = {}, action) => {
    switch (action.type) {
        case Constant.GET_LIST_TRANSACTION_FILE.SUCCESS:
            const canUseGlobal =  lodash.get(action, "payload.data.canUseGlobal", false);
            const canUseMbill =  lodash.get(action, "payload.data.canUseMbill", false);
            const canUseWorldFirst =  lodash.get(action, "payload.data.canUseWorldFirst", false);
            return {canUseGlobal, canUseMbill, canUseWorldFirst}
        case Constant.GET_LIST_TRANSACTION_FILE.REQUEST:
        case Constant.TRANSACTION_LIST_FILE_CLEAR_STATE:
            return {canUseGlobal: false, canUseMbill: false, canUseWorldFirst};
        default:
            return state;
    }
};

export default combineReducers({
    list,
    loading,
    pagination,
    permission
});