import {combineReducers} from 'redux';
import * as Constants from "./constants";
import lodash from "lodash";
import * as Constant from "../PurchaseBuying/OrderDetail/constants";
import moment from "moment";

const list = (state = [], action) => {
    const payload = lodash.get(action, 'payload', []);
    const response = lodash.get(action, 'payload.data', []);
    const request = lodash.get(action, 'request', {});
    let newState = lodash.merge([], state);
    let errors = [];

    switch (action.type) {
        case Constants.ORDER_LIST_BILL_NO.SUCCESS:
            return [...lodash.get(action, 'payload.data.orders', [])];
        case Constants.ORDER_SYNC_BILL_NO.SUCCESS:

            let orderId = lodash.get(payload, 'id_order', null);
            let newOrder = lodash.get(payload, 'order', {});

            return newState.map(order => {
                if (order.id === parseInt(orderId, 10)) {
                    order.freight_bill = Array.isArray(order.freight_bill) ? order.freight_bill.concat(lodash.get(payload, 'orderFreightBill', [])) : lodash.get(payload, 'orderFreightBill', []);
                    order.sync_time = moment().format();
                    order.errors = lodash.get(payload, 'errors', []);
                    return {...order, ...newOrder}
                }
                return order;
            });

        case Constant.SYNC_ORDER_BILL_NO.SUCCESS:
            const requestOrderId = lodash.get(request, 'id', null);
            let newFreightBills = lodash.get(response, 'orderFreightBill', []);
            let order = lodash.get(response, 'order', {});
            errors = lodash.get(response, 'errors', []);

            return newState.map(orderItem => {
                let freightBill = lodash.find(newFreightBills, {id_order: orderItem.id});
                if (freightBill) {
                    orderItem.freight_bill.push(freightBill);
                }

                if (order.id === orderItem.id) {
                    orderItem.status = order.status;
                    if (errors) {
                        order.errors = errors;
                    }
                }

                if (orderItem.id === requestOrderId) {
                    orderItem.sync_time = orderItem.sync_time ? orderItem.sync_time + 1 : 1;
                }

                return orderItem;
            });
        default:
            return state;
    }
};

const pagination = (state = {}, action) => {
    let {payload} = action;

    switch (action.type) {
        case Constants.ORDER_LIST_BILL_NO.SUCCESS:
            return lodash.get(payload, 'data.pagination', {from: 0, per_page: 20, total: 0});
        default:
            return state;
    }
};

export default combineReducers({
    list, pagination
});
