export const FETCH_PURCHASING_ORDER_DETAIL = {
    REQUEST: 'FETCH_PURCHASING_ORDER_DETAIL_REQUEST',
    SUCCESS: 'FETCH_PURCHASING_ORDER_DETAIL_SUCCESS',
    FAILED: 'FETCH_PURCHASING_ORDER_DETAIL_FAILED',
}

export const UPDATE_FRAGILE_ORDER_DETAIL = 'UPDATE_FRAGILE_ORDER_DETAIL'
export const UPDATE_ITEMS_ORDER_DETAIL = 'UPDATE_ITEMS_ORDER_DETAIL'
export const UPDATE_AFFILIATE_LINK_ORDER_DETAIL = 'UPDATE_AFFILIATE_LINK_ORDER_DETAIL'
export const UPDATE_ECOMMERCE_SHIPPING_ORDER_DETAIL = 'UPDATE_ECOMMERCE_SHIPPING_ORDER_DETAIL'
export const UPDATE_HSCODE_ORDER_DETAIL = 'UPDATE_HSCODE_ORDER_DETAIL'

export const UPDATE_PAID_ORDER_DETAIL = {
    REQUEST: 'UPDATE_PAID_ORDER_DETAIL_REQUEST',
    SUCCESS: 'UPDATE_PAID_ORDER_DETAIL_SUCCESS',
    FAILED: 'UPDATE_PAID_ORDER_DETAIL_FAILED',
}

export const CHANGE_MUTIPLE_QUANTITY = {
    REQUEST: 'CHANGE_MUTIPLE_QUANTITY_REQUEST',
    SUCCESS: 'CHANGE_MUTIPLE_QUANTITY_SUCCESS',
    FAILED: 'CHANGE_MUTIPLE_QUANTITY_FAILED',
}

export const CONFIRM_CUSTOMER_ORDER_BUYING = {
    REQUEST: 'CONFIRM_CUSTOMER_ORDER_BUYING_REQUEST',
    SUCCESS: 'CONFIRM_CUSTOMER_ORDER_BUYING_SUCCESS',
    WARNING: 'CONFIRM_CUSTOMER_ORDER_BUYING_WARING',
    FAILED: 'CONFIRM_CUSTOMER_ORDER_BUYING_FAILED',
}

export const UPDATE_ORDER_INVOICE_CODE = {
    REQUEST: 'UPDATE_ORDER_INVOICE_CODE_REQUEST',
    SUCCESS: 'UPDATE_ORDER_INVOICE_CODE_SUCCESS',
    FAILED: 'UPDATE_ORDER_INVOICE_CODE_FAILED',
}

export const UPDATE_SHIPPING_FEE = {
    REQUEST: 'UPDATE_SHIPPING_FEE_REQUEST',
    SUCCESS: 'UPDATE_SHIPPING_FEE_SUCCESS',
    FAILED: 'UPDATE_SHIPPING_FEE_FAILED',
}

export const POST_INVOICE_CODE = {
    REQUEST: 'POST_INVOICE_CODE_REQUEST',
    SUCCESS: 'POST_INVOICE_CODE_REQUEST_SUCCESS',
    FAILED: 'POST_INVOICE_CODE_REQUEST_FAILED',
    ERROR: 'POST_INVOICE_CODE_REQUEST_ERROR',
}

export const DELETE_INVOICE_CODE = {
    REQUEST: 'DELETE_INVOICE_CODE_REQUEST',
    SUCCESS: 'DELETE_INVOICE_CODE_REQUEST_SUCCESS',
    FAILED: 'DELETE_INVOICE_CODE_REQUEST_FAILED',
    ERROR: 'DELETE_INVOICE_CODE_REQUEST_ERROR',
}

export const ADD_FREIGHT_BILL = {
    REQUEST: 'ADD_FREIGHT_BILL_REQUEST',
    SUCCESS: 'ADD_FREIGHT_BILL_SUCCESS',
    FAILED: 'ADD_FREIGHT_BILL_FAILED',
    ERROR: 'ADD_FREIGHT_BILL_ERROR',
}

export const CLEAR_FREIGHT_BILL_DUPLICATE = 'CLEAR_FREIGHT_BILL_DUPLICATE'

export const DELETE_FREIGHT_BILL = {
    REQUEST: 'DELETE_FREIGHT_BILL_REQUEST',
    SUCCESS: 'DELETE_FREIGHT_BILL_SUCCESS',
    FAILED: 'DELETE_FREIGHT_BILL_FAILED',
    ERROR: 'DELETE_FREIGHT_BILL_ERROR',
}

export const CLEAR_FREIGHT_BILL_ERRORS = 'CLEAR_FREIGHT_BILL_ERRORS'

export const IS_BEGIN_PROCESS_POST_INVOICE_CODE = 'IS_BEGIN_PROCESS_POST_INVOICE_CODE'
export const IS_BEGIN_PROCESS_DELETE_INVOICE_CODE = 'IS_BEGIN_PROCESS_DELETE_INVOICE_CODE'
export const IS_BEGIN_PROCESS_CHANGE_PRICE = 'IS_BEGIN_PROCESS_CHANGE_PRICE'
export const IS_BEGIN_PROCESS_CANCEL_ORDER = 'IS_BEGIN_PROCESS_CANCEL_ORDER'
export const IS_BEGIN_PROCESS_OUT_OF_STOCK_ORDER = 'IS_BEGIN_PROCESS_OUT_OF_STOCK_ORDER'

export const PAYMENT_ORDER = {
    REQUEST: 'PAYMENT_ORDER_REQUEST',
    SUCCESS: 'PAYMENT_ORDER_SUCCESS',
    FAILED: 'PAYMENT_ORDER_FAILED',
    CANCEL: 'PAYMENT_ORDER_CANCEL',
}

export const CANCEL_ORDER = {
    REQUEST: 'CANCEL_ORDER_REQUEST',
    SUCCESS: 'CANCEL_ORDER_SUCCESS',
    FAILED: 'CANCEL_ORDER_FAILED',
    ERROR: 'CANCEL_ORDER_ERROR',
}

export const OUT_OF_STOCK_ORDER = {
    REQUEST: 'OUT_OF_STOCK_ORDER_REQUEST',
    SUCCESS: 'OUT_OF_STOCK_ORDER_SUCCESS',
    FAILED: 'OUT_OF_STOCK_ORDER_FAILED',
    ERROR: 'OUT_OF_STOCK_ORDER_ERROR',
}

export const UPDATE_ORDER_GROUP_ORDER = {
    REQUEST: 'UPDATE_ORDER_GROUP_ORDER_REQUEST',
    SUCCESS: 'UPDATE_ORDER_GROUP_ORDER_SUCCESS',
    FAILED: 'UPDATE_ORDER_GROUP_ORDER_FAILED',
}

export const UPDATE_PAID_ORDER_STATUS = {
    REQUEST: 'UPDATE_PAID_ORDER_STATUS_REQUEST',
    SUCCESS: 'UPDATE_PAID_ORDER_STATUS_SUCCESS',
    FAILED: 'UPDATE_PAID_ORDER_STATUS_FAILED',
}

export const UPDATE_ORDER_ACCOUNT_PURCHASER = {
    REQUEST: 'UPDATE_ORDER_ACCOUNT_PURCHASER_REQUEST',
    SUCCESS: 'UPDATE_ORDER_ACCOUNT_PURCHASER_SUCCESS',
    FAILED: 'UPDATE_ORDER_ACCOUNT_PURCHASER_FAILED',
}

export const UPDATE_OUT_STOCK_ITEMS = {
    REQUEST: 'UPDATE_OUT_STOCK_ITEMS_REQUEST',
    SUCCESS: 'UPDATE_OUT_STOCK_ITEMS_SUCCESS',
    FAILED: 'UPDATE_OUT_STOCK_ITEMS_FAILED',
}
export const IS_BEGIN_PROCESS_UPDATE_OUT_STOCK_ITEMS = 'IS_BEGIN_PROCESS_UPDATE_OUT_STOCK_ITEMS'
export const IS_CLEAR_ERROR_PROCESS_UPDATE_OUT_STOCK_ITEMS = 'IS_CLEAR_ERROR_PROCESS_UPDATE_OUT_STOCK_ITEMS'

export const UPDATE_PRICE_ITEMS = {
    REQUEST: 'UPDATE_PRICE_ITEMS_REQUEST',
    SUCCESS: 'UPDATE_PRICE_ITEMS_SUCCESS',
    FAILED: 'UPDATE_PRICE_ITEMS_FAILED',
}
export const IS_BEGIN_PROCESS_UPDATE_PRICE_ITEMS = 'IS_BEGIN_PROCESS_UPDATE_PRICE_ITEMS'
export const IS_AFTER_SUCCESS_UPDATE_PRICE_ITEMS = 'IS_AFTER_SUCCESS_UPDATE_PRICE_ITEMS'
export const CLEAR_IS_AFTER_SUCCESS_UPDATE_PRICE_ITEMS = 'CLEAR_IS_AFTER_SUCCESS_UPDATE_PRICE_ITEMS'
export const IS_CLEAR_ERROR_PROCESS_UPDATE_PRICE_ITEMS = 'IS_CLEAR_ERROR_PROCESS_UPDATE_PRICE_ITEMS'
export const KEYUP_CLEAR_ERROR_PROCESS_UPDATE_PRICE_ITEMS = 'KEYUP_CLEAR_ERROR_PROCESS_UPDATE_PRICE_ITEMS'

export const UPDATE_QUANTITY_ITEMS = {
    REQUEST: 'UPDATE_QUANTITY_ITEMS_REQUEST',
    SUCCESS: 'UPDATE_QUANTITY_ITEMS_SUCCESS',
    FAILED: 'UPDATE_QUANTITY_ITEMS_FAILED',
}
export const IS_BEGIN_PROCESS_UPDATE_QUANTITY_ITEMS = 'IS_BEGIN_PROCESS_UPDATE_QUANTITY_ITEMS'
export const IS_AFTER_SUCCESS_UPDATE_QUANTITY_ITEMS = 'IS_AFTER_SUCCESS_UPDATE_QUANTITY_ITEMS'
export const CLEAR_IS_AFTER_SUCCESS_UPDATE_QUANTITY_ITEMS = 'CLEAR_IS_AFTER_SUCCESS_UPDATE_QUANTITY_ITEMS'
export const IS_CLEAR_ERROR_PROCESS_UPDATE_QUANTITY_ITEMS = 'IS_CLEAR_ERROR_PROCESS_UPDATE_QUANTITY_ITEMS'

/**
 * Order service
 */
export const UPDATE_ORDER_SERVICE = {
    REQUEST: 'UPDATE_ORDER_SERVICE_REQUEST',
    SUCCESS: 'UPDATE_ORDER_SERVICE_SUCCESS',
    FAILED: 'UPDATE_ORDER_SERVICE_FAILED',
    UNCHECK: 'UPDATE_ORDER_SERVICE_UNCHECK',
}

/**
 * Approval order service
 */
export const APPROVAL_ORDER_SERVICE = {
    REQUEST: 'APPROVAL_ORDER_SERVICE_REQUEST',
    SUCCESS: 'APPROVAL_ORDER_SERVICE_SUCCESS',
    FAILED: 'APPROVAL_ORDER_SERVICE_FAILED',
    ERROR: 'APPROVAL_ORDER_SERVICE_ERROR',
}

export const UPDATE_ORDER_ITEM_PRICE = {
    REQUEST: 'UPDATE_ORDER_ITEM_PRICE_REQUEST',
    SUCCESS: 'UPDATE_ORDER_ITEM_PRICE_SUCCESS',
    FAILED: 'UPDATE_ORDER_ITEM_PRICE_FAILED',
    ERROR: 'UPDATE_ORDER_ITEM_PRICE_ERROR',
}

export const SYNC_ORDER_BILL_NO = {
    REQUEST: 'SYNC_ORDER_BILL_NO_REQUEST',
    SUCCESS: 'SYNC_ORDER_BILL_NO_SUCCESS',
    FAILED: 'SYNC_ORDER_BILL_NO_FAILED',
    ERROR: 'SYNC_ORDER_BILL_NO_ERROR',
}

export const CLEAR_ERROR_WHILE_UPDATE_PRICE = 'CLEAR_ERROR_WHILE_UPDATE_PRICE'

export const DEFAULT_NUMBER_COMMENT = 3

export const DEFAULT_NUMBER_NOTICE = 5

export const PURCHASER_UPDATE_CHANGE_CLEAR = 'PURCHASER_UPDATE_CHANGE_CLEAR'

export const CLEAR_ORDER_DETAIL_STATE = 'CLEAR_ORDER_DETAIL_STATE'

export const CLEAR_ERROR_WHILE_POST_INVOICE_CODE = 'CLEAR_ERROR_WHILE_POST_INVOICE_CODE'
export const CLEAR_ERROR_WHILE_POST_COMMENT_ITEM = 'CLEAR_ERROR_WHILE_POST_COMMENT_ITEM'

export const SORT_ORDER_ITEMS = 'PURCHASE_BUYING.ORDER_DETAIL.SORT_ORDER_ITEMS'

export const CHANGE_SORT_BY_ITEM = {
    PAI_TIME_UP: 'CHANGE_SORT_BY_ITEM_PAI_TIME_UP',
    PAI_TIME_DOWN: 'CHANGE_SORT_BY_ITEM_PAI_TIME_DOWN',
}

export const CHANGE_RATE_DEPOSIT = {
    REQUEST: 'CHANGE_RATE_DEPOSIT_REQUEST',
    SUCCESS: 'CHANGE_RATE_DEPOSIT_SUCCESS',
    FAILED: 'CHANGE_RATE_DEPOSIT_FAILED',
    ERROR: 'CHANGE_RATE_DEPOSIT_ERROR',
}

export const GET_SHIPPING_FEE_HISTORY = {
    REQUEST: 'GET_SHIPPING_FEE_HISTORY_REQUEST',
    SUCCESS: 'GET_SHIPPING_FEE_HISTORY_SUCCESS',
    FAILED: 'GET_SHIPPING_FEE_HISTORY_FAILED',
    ERROR: 'GET_SHIPPING_FEE_HISTORY_ERROR',
}

export const UPDATE_AFTER_VOTING_SHOP = 'UPDATE_AFTER_VOTING_SHOP'
export const UPDATE_PURCHASE_ADDRESS = 'UPDATE_PURCHASE_ADDRESS'

export const WAITING_PAYMENT_ORDER = {
    REQUEST: 'WAITING_PAYMENT_ORDER_REQUEST',
    SUCCESS: 'WAITING_PAYMENT_ORDER_SUCCESS',
    FAILED: 'WAITING_PAYMENT_ORDER_FAILED',
    CANCEL: 'WAITING_PAYMENT_ORDER_CANCEL',
}

export const DATA_SOURCE_DEFAULT_UNIT_VN = ['Cái', 'Đôi', 'Bộ']

export const UPDATE_ORDER_FEATURE = {
    REQUEST: 'UPDATE_ORDER_FEATURE_REQUEST',
    SUCCESS: 'UPDATE_ORDER_FEATURE_SUCCESS',
    FAILED: 'UPDATE_ORDER_FEATURE_FAILED',
    UNCHECK: 'UPDATE_ORDER_FEATURE_UNCHECK',
}

export const UPDATE_AFTER_UDPATE_SHOP_NOTE = 'UPDATE_AFTER_UDPATE_SHOP_NOTE'

export const UPDATE_TOTAL_DISCOUNT = {
    REQUEST: 'UPDATE_TOTAL_DISCOUNT_REQUEST',
    SUCCESS: 'UPDATE_TOTAL_DISCOUNT_SUCCESS',
    FAILED: 'UPDATE_TOTAL_DISCOUNT_FAILED',
}

export const SET_BATCH_ORDER = {
    REQUEST: 'SET_BATCH_ORDER_REQUEST',
    SUCCESS: 'SET_BATCH_ORDER_SUCCESS',
    FAILED: 'SET_BATCH_ORDER_FAILED',
}
