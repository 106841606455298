import React, {useState} from 'react';
import {Modal, Button, Icon} from "antd";
import {translate} from "react-i18next";
import lodash from "lodash";
import DeleteTaobaoPurchaseOrder from "../../../../ForeignOrder/DeleteTaobaoPurchaseOrder";

function WarningChangePurchaseAddress({visible, getPurchasingOrderDetailAction, dataOrder, t, canCancelTaobaoPurchaseOrder = false}) {
    const {id: orderId} = dataOrder;
    const taobaoPurchaseOrder = lodash.get(dataOrder, "taobaoPurchaseOrder", {});
    const [visibleModal, setVisibleModal] = useState(!!visible);

    function onCancel() {
        setVisibleModal(false);
    }

    function handleOk() {
        onCancel();
        getPurchasingOrderDetailAction({id: orderId})
    }

    return (
        <Modal
            visible={visibleModal}
            title={<><Icon type="warning" style={{
                fontSize: "18px",
                color: "red"
            }}/> {t("orderDetail:title.change_purchase_address")}</>}
            onCancel={onCancel}
            closable={false}
            maskClosable={false}
            footer={[
                <Button onClick={handleOk} type="primary">{t("btn.ok")}</Button>
            ]}
            bodyStyle={{border: "unset"}}
        >
            {
                t("orderDetail:order_detail.content_change_purchase_address",
                    {property: t("orderDetail:label." + visible)})
            }
            {
                (visible === "feature" && canCancelTaobaoPurchaseOrder) &&
                <div className="mt-2">
                    <p
                        style={{color: "#ff6a08", fontWeight: 500}}>Đã tạo đơn
                        ST <b>#{taobaoPurchaseOrder.purchase_id}{` (${t(`order:ORDER_TAOBAO_STATUS.${taobaoPurchaseOrder.purchase_order_status}`)})`}</b>
                    </p>
                    <p>Bạn có muốn hủy đơn TBG không? <DeleteTaobaoPurchaseOrder orderId={orderId} onSubmit={handleOk}/></p>
                </div>
            }

        </Modal>
    );
}

export default translate('translations')(WarningChangePurchaseAddress)