import {combineReducers} from "redux";
import lodash from "lodash";
import * as Constants from './constants';
/**
 * State quản lý danh sách các report
 * @param state
 * @param action
 * @returns {*}
 */
const list = (state = [], action) => {
    let data = lodash.get(action, 'payload.data', []);
    let report = lodash.get(action, 'payload.data.report', {});
    let payload = lodash.get(action, 'payload', {});
    let newState = [];
    switch (action.type) {
        case Constants.CREATE_EXPORT_REPORT.SUCCESS:
            newState = state;
            let newStateClone = newState.filter(newStateItem => newStateItem.id === report.id);
            if (!newStateClone.length) {
                newState.unshift(report);
            }
            return newState;
        case Constants.CREATE_EXPORT_REPORT.FAILED:
            return state;
        case Constants.GET_LIST_REPORTS.SUCCESS:
            return data;
        case Constants.NEW_REQUEST_REPORT.REQUEST:
            newState = state;
            if (lodash.get(payload, 'status', null) === 'COMPLETED'){
                newState = newState.map(newStateItem=>{
                    if( newStateItem.id !== payload.id  ){
                        return newStateItem;
                    }
                    else{
                        return {
                            ...newStateItem, ...{
                                status: payload.status,
                                progress: payload.progress,
                                file: payload.file
                            }
                        };
                    }
                })
                let newStateClone = newState.filter(newStateItem =>newStateItem.id === payload.id);
                if( !newStateClone.length ){
                    newState.unshift(payload);
                }
            }
            return newState;
        case Constants.GET_LIST_REPORTS.FAILED:
            return state;
        default:
            return state;
    }
};

/**
 * Pagination list order reducer
 */
const pagination = (state = {}, action) => {
    let {payload} = action;

    switch (action.type) {
        case Constants.GET_LIST_REPORTS.SUCCESS:
            return lodash.get(payload, 'data.pagination', {from: 0, per_page: 20, total: 0});
        default:
            return state;
    }
};

/**
 * State quản lý report đang thực hiện
 * @param state
 * @param action
 * @returns {*}
 */
const reports = (state = {}, action) => {
    let payload = lodash.get(action, 'payload', {});
    let data = lodash.get(action, 'payload.data.report', {});
    switch (action.type) {
        case Constants.GET_PUSHER_REPORT:
            if( (lodash.get(payload,'status','' ) === 'PROCESSING' && lodash.isEmpty(state) ) ||
                (lodash.get(payload,'status','' ) === 'COMPLETED' && lodash.isEmpty(state) ) ||
                (lodash.isEmpty(payload) && lodash.isEmpty(state) )
            ){
                return {};
            }
            state = {
                ...state, ...payload
            };
            return state;

        case Constants.GET_CURRENT_REPORT.SUCCESS:
            return data;
        case Constants.CREATE_EXPORT_REPORT.SUCCESS:
            if( lodash.isEmpty(state) ){
                return data;
            }
            return state;
        case Constants.CREATE_EXPORT_REPORT.REQUEST:
            return {};
        case Constants.CANCEL_EXPORT_REPORT.SUCCESS:
        case Constants.NEW_REQUEST_REPORT.REQUEST:
        case Constants.NEW_REQUEST_REPORT.SUCCESS:
        case Constants.CREATE_EXPORT_REPORT.FAILED:
            return {};
        default:
            return state;
    }
};

const defaultIsLoading = {
    postCreateRequest: false,
    loadingListReport: false,
    loadingCurrentReport: false,
};

/**
 * State quản lý việc có hệ thống khi thực hiện các thao tác xử lý đang xử l
 * @param state
 * @param action
 */
const isLoadingData = (state = defaultIsLoading, action) => {
    switch (action.type) {
        case Constants.CREATE_EXPORT_REPORT.REQUEST:
            return {...state, ...{postCreateRequest: true}};
        case Constants.CREATE_EXPORT_REPORT.SUCCESS:
        case Constants.CREATE_EXPORT_REPORT.FAILED:
        case Constants.CREATE_EXPORT_REPORT.CANCEL:
            return {...state, ...{postCreateRequest: false}};

        case Constants.GET_LIST_REPORTS.REQUEST:
            return {...state, ...{loadingListReport: true}};

        case Constants.GET_LIST_REPORTS.SUCCESS:
        case Constants.GET_LIST_REPORTS.FAILED:
        case Constants.GET_LIST_REPORTS.CANCEL:
            return {...state, ...{loadingListReport: false}};

        case Constants.GET_CURRENT_REPORT.REQUEST:
            return {...state, ...{loadingCurrentReport: true}};

        case Constants.GET_CURRENT_REPORT.SUCCESS:
        case Constants.GET_CURRENT_REPORT.FAILED:
            return {...state, ...{loadingCurrentReport: false}};

        default:
            return state;
    }
};

const defaultIsEndProcess = {
    postCreateRequest: false,
};

/**
 * State quản lý việc có hệ thống đã kết thúc tiến trình tạo mới, hủy yêu cầu tạo mới
 * @param state
 * @param action
 */
const isEndProcess = (state = defaultIsEndProcess, action) => {
    switch (action.type) {
        case Constants.CREATE_EXPORT_REPORT.SUCCESS:
        case Constants.CREATE_EXPORT_REPORT.CANCEL:
            return {...state, postCreateRequest: true};

        case Constants.CREATE_EXPORT_REPORT.REQUEST:
        case Constants.CREATE_EXPORT_REPORT.FAILED:
            return {...state, postCreateRequest: true};
        default:
            return state;
    }
};
/**
 * State quản lý lỗi trên màn hình report
 * @param state
 * @param action
 * @returns {{}}
 */
const error = (state = {}, action) => {
    switch (action.type) {
        case Constants.CREATE_EXPORT_REPORT.FAILED:
            return {...action.payload.data};
        case Constants.CREATE_EXPORT_REPORT.REQUEST:
        case Constants.GET_CURRENT_REPORT.REQUEST:
            return {};
        case Constants.CLEAR_ERROR_REPORT:
            let params = lodash.get(action, "payload.param", []);
            let newErrorState = {...state};
            params.map(param => {
                if (typeof newErrorState[param] !== "undefined") {
                    delete newErrorState[param];
                }

                if (param === "unit_price" && typeof newErrorState["status"] !== "undefined") {
                    delete newErrorState["status"];
                }

                return param;
            });
            return {...newErrorState};
        default:
            return state;
    }
};

export default combineReducers({
    list,
    pagination,
    isLoadingData,
    isEndProcess,
    error,
    reports
});
