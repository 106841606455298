import React from "react";
import { CRAWL_EVENTS, ORIGIN_COMPLAINT_LINK} from "../../../../../modules/ComplaintSeller/constants";
import lodash from "lodash";
import { events } from "../../../../../systems/events";
import * as SYNC_EVENTS from "./constants";

class CrawlModel {

    constructor() {
        this.handMessage = this.handMessage.bind(this);
        this.init();
        this.complaint = {}
    }

    init() {
        window.addEventListener("message", this.handMessage, false);
    }

    terminate() {
        window.removeEventListener("message", this.handMessage, false);
    }

    boot(complaint) {
        let {order} = complaint;
        this.complaint = {...complaint};

        // Bắn message đồng bộ KNNB với site gốc
        window.postMessage({
            type: CRAWL_EVENTS.MESSAGE_CODE,
            payload: {
                complaint_id: complaint.id,
                origin_code: complaint.origin_code,
                link: ORIGIN_COMPLAINT_LINK[order.seller_source] + complaint.origin_code
            }
        });
    }

    cancel() {
        window.postMessage({type: CRAWL_EVENTS.CANCEL_CRAWL});
    }

    handMessage(event) {
        let {data} = event;
        switch (data.type) {
            case CRAWL_EVENTS.SYNCHRONIZE_RESPONSE: {
                let res = lodash.get(event, 'data.response', {});
                // console.log('res SYNCHRONIZE_RESPONSE', res);
                events.dispatch(SYNC_EVENTS.CRAWL_SUCCESS, res);
                return;
            }
            case CRAWL_EVENTS.CLOSE_CRAWLING_TAB: {
                let res = lodash.get(event, 'data', {});
                // console.log('res CLOSE_CRAWLING_TAB', res);
                events.dispatch(SYNC_EVENTS.CLOSE_TAB, res);
                return;
            }
            case CRAWL_EVENTS.CRAWL_COMPLAINT_FAIL: {
                let res = lodash.get(event, 'data', {});
                // console.log('res CRAWL_COMPLAINT_FAIL', res);
                events.dispatch(SYNC_EVENTS.CRAWL_FAIL, res);
                return;
            }
            case CRAWL_EVENTS.DONT_LOGIN_SITE: {
                let res = lodash.get(event, 'data', {});
                console.log('res DONT_LOGIN_SITE', res);
                events.dispatch(SYNC_EVENTS.DONT_LOGIN_SITE, res);
                return;
            }
        }
    }

}

export default CrawlModel;
