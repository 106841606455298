import React, { useState } from 'react'
import { t } from '../../../../systems/i18n'
import apiService from '../../apiService';
import { get, map, join, find } from "lodash";
import { processResponse } from '../../../../systems/api';
import { Input, Modal } from 'antd';
import notification from '../../../../systems/notification';


const EditPackage = ({ packagesEdit, complaintId, getPackages }) => {
    const initialValue = {
        return_tracking_bill: undefined,
        return_fee: 0,
        return_address: undefined,
    };
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(initialValue);

    function updatePackageInfo() {
            setLoading(true);
            const response = apiService.changePackageInfo(complaintId, {...data, packages: map(packagesEdit, 'code')});
            const process = processResponse(response);
            process.then(() => {
                notification.success(t("complaintSeller:message.update_package_info_success", {package_code:join(map(packagesEdit, 'code'), ', ')}))
                getPackages();
                handleCancel()
            }).catch(() => {
                notification.error( t("message.cant_update_data"))
            }).finally(() => setLoading(false))
    }

    function handleCancel() {
        setData(initialValue);
        setVisible(false);
    }

    const onChangeInput = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const onChangeFee = (e) => {
        const {value, name} = e.target;
        const regex = /^[]?\d*\.?\d{0,5}$/;
        if (!(regex.test(value) || value === "")) return;
        setData({...data, [name]: value});
    };
    
    const  onVisible= ()=>{
        setVisible(true)
    }

    return (
        <div>
            <a
                onClick={onVisible}
                className="a-text--fz-14 a-text--uppercase _complaint-seller-detail-button-choose-package"
            >
                {t('complaintSeller:label.edit_selected_package')}
            </a>

            {
                visible &&  <Modal
                title={t("complaintSeller:modal.update_package_info", {package_code: join(map(packagesEdit, 'code'), ', ')})}
                visible={visible}
                onOk={updatePackageInfo}
                onCancel={handleCancel}
                cancelText={t("btn.cancel")}
                okText={t("btn.ok")}
                okButtonProps={{
                    loading: loading,
                    className: "_edit-package-info-button-handle-ok"
                }}
                cancelButtonProps={{className: "_edit-package-info-button-handle-cancel"}}
                width={520}
            >

                <div className='a-group'>
                    <label
                        className="label">{t("complaintSeller:label.return_tracking_bill")}</label>
                    <Input
                        name="return_tracking_bill"
                        value={get(data, "return_tracking_bill", undefined)}
                        placeholder={t("complaintSeller:placeholder.return_tracking_bill")}
                        onChange={onChangeInput}
                        className="_edit-package-info-return-tracking-bill"
                    />
                </div>
                <div className='a-group'>
                    <label
                        className="label">{t("complaintSeller:label.return_fee")}</label>
                    <Input
                        name="return_fee"
                        value={get(data, "return_fee", undefined)}
                        placeholder={t("complaintSeller:placeholder.return_fee")}
                        onChange={onChangeFee}
                        className="_edit-package-info-return-fee"
                    />
                </div>
                <div className='a-group'>
                    <label
                        className="label">{t("complaintSeller:label.return_address")}</label>
                    <Input
                        name="return_address"
                        value={get(data, "return_address", undefined)}
                        placeholder={t("complaintSeller:placeholder.return_address")}
                        onChange={onChangeInput}
                        className="_edit-package-info-return-address"
                    />
                </div>
            </Modal>
            }
        </div>
    )
}

export default EditPackage
