import {apiWithToken} from './../../../systems/api';
import {API_URL} from './../../../systems/globalContant';

export default {
    fetchConfigSystem: (filters = {}) => apiWithToken.get(API_URL.SETTINGS_PARTNER,
        {params: filters}, {loading: false}
    ),
    updateConfigSystem: (params = {}) => apiWithToken.post(API_URL.SETTINGS_PARTNER,
        params, {loading: false, singleRequest: true}
    ),
    connectTaobao: (params) => apiWithToken.get(`${API_URL.DEFAULT}/taobao-auth`,{params}),
    getListAccountConnectedTaobao: () => apiWithToken.get(`${API_URL.DEFAULT}/taobao-purchaser-orders/account-connected`),
    setAccountTaobao: (data) => apiWithToken.put(`${API_URL.DEFAULT}/taobao-purchaser-orders/set-account`, data),
    removeAccount: (data) => apiWithToken.put(`${API_URL.DEFAULT}/taobao-purchaser-orders/remove-account`, data),
};
