import {createAction} from 'redux-actions';
import * as ACTION from './constants';

export const fetchLogs = createAction(ACTION.FETCH_LOGS.REQUEST, (object, object_id, filter = {}) => ({object, object_id, filter}));
export const fetchComments = createAction(ACTION.FETCH_COMMENTS.REQUEST, (object, object_id) => ({object, object_id}));
export const updateFilter = createAction(ACTION.UPDATE_FILTER, filter => ({filter}));
export const collapseAllLogs = createAction(ACTION.COLLAPSE_ALL_LOGS, collapse => ({collapse}));
export const collapseLog = createAction(ACTION.COLLAPSE_LOG, (logId, collapse) => ({logId, collapse}));
export const comment = createAction(ACTION.COMMENT.REQUEST, (object, object_id, comment) => ({object, object_id, comment}));
export const clearState = createAction(ACTION.CLEAR_STATE);
