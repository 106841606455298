import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import Link from "../../../../systems/routing/Link";
import CreateProductReturns from './../../CreateProductReturn/CreateProductReturnContainer';

class ComplaintSolution extends React.Component {

    render() {
        let {listProductReturns, order, t, complaint, permissions} = this.props;
        let can_view_product_return = lodash.get(permissions, 'can_view_product_return', false);
        let can_create_product_return = lodash.get(permissions, 'can_create_product_return', false);
        let packages = [];

        return (
            <React.Fragment>
                {!can_view_product_return && !can_create_product_return ? null:
                    <div className="complaint-seller-box a-content--box-shadow p-0 h-100">
                        {/*<div className="a__complaint__detail__solution">*/}
                            <CreateProductReturns order={order} complaint={complaint} permissions={permissions}/>
                            <div className="table-responsive border-top">
                                <table className="table a-table--list_complaint_solution">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t('complaintSeller:product_return.request')}</th>
                                            <th>{t('complaintSeller:product_return.status')}</th>
                                            <th>{t('complaintSeller:product_return.information')}</th>
                                            <th>{t('complaintSeller:product_return.package')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {can_view_product_return ?
                                        <React.Fragment>
                                            {listProductReturns.map((listProductReturnItem, index) => {
                                                packages =  lodash.get(listProductReturnItem, 'packages', []) || [];
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td className="text-uppercase">
                                                            <Link isNewTab={true}
                                                                  to={"product.return.detail"}
                                                                  params={{id: listProductReturnItem.id}}
                                                                  className="a_complaint_solution__link"
                                                            >
                                                                {'trả hàng'}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {t('productReturn:status.'+(listProductReturnItem.status || "queued").toLowerCase())}
                                                            </p>
                                                            <span>{listProductReturnItem.dateFormat}</span>
                                                        </td>
                                                        <td>
                                                            <div className="a-form__inline_table__complaint_solution">
                                                                <label>{t('complaintSeller:product_return.receiver')}:</label>
                                                                <p>{listProductReturnItem.receiver}</p>
                                                            </div>
                                                            <div className="a-form__inline_table__complaint_solution">
                                                                <label>{t('complaintSeller:product_return.phone')}:</label>
                                                                <p>{listProductReturnItem.phone}</p>
                                                            </div>
                                                            <div className="a-form__inline_table__complaint_solution">
                                                                <label>{t('complaintSeller:product_return.address')}:</label>
                                                                <p>{listProductReturnItem.address}</p>
                                                            </div>
                                                            <div className="a-form__inline_table__complaint_solution">
                                                                <label>{t('complaintSeller:product_return.return_date')}:</label>
                                                                <p>{listProductReturnItem.returnDateFormat}</p>
                                                            </div>
                                                            <div className="a-form__inline_table__complaint_solution">
                                                                <label>{t('complaintSeller:product_return.freight_bill_product_return')}: </label>
                                                                <p>
                                                                    {listProductReturnItem.shipping_bill_codes}
                                                                </p>
                                                            </div>
                                                            <div className="a-form__inline_table__complaint_solution">
                                                                <label>{t('complaintSeller:product_return.fee_product_return_none')}: </label>
                                                                <p>{listProductReturnItem.costFormat} <sup>¥</sup></p>
                                                            </div>
                                                            <div className="a-form__inline_table__complaint_solution">
                                                                <label>{t('complaintSeller:product_return.paid_cost_none')}:</label>
                                                                <p>{listProductReturnItem.paidCostFormat} <sup>¥</sup></p>
                                                            </div>
                                                            <div className="a-form__inline_table__complaint_solution">
                                                                <label>{t('complaintSeller:product_return.note')}:</label>
                                                                <p>{listProductReturnItem.note}</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {
                                                                packages.length ? (
                                                                    <React.Fragment>
                                                                        {packages.map((packageItem, index)=> {
                                                                            return <a href="#" className="a-link">
                                                                                {packageItem}{index + 1 < packages.length ? ',' : ''}
                                                                            </a>
                                                                        })}

                                                                    </React.Fragment>
                                                                )
                                                                :
                                                                '---'
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            {!listProductReturns.length ? <tr style={{borderBottom: '1px solid #f2f2f2' }}>
                                                <td className="text-center d-table-cell a-font--12 a-text--font-primary" colSpan={5}>{t('complaintSeller:product_return.not_found_data')}</td>
                                            </tr> : null}
                                        </React.Fragment>
                                        :
                                        <tr style={{borderBottom: '1px solid #f2f2f2' }}>
                                            <td className="text-center d-table-cell a-font--12 a-text--font-primary" colSpan={5}>{t('complaintSeller:product_return.not_have_permission_view')}</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        {/*</div>*/}
                    </div>
                }
            </React.Fragment>
            
        );
    }
}

ComplaintSolution.defaultProps = {
    order: {},
    listProductReturns: [],

};

ComplaintSolution.propTypes = {
    order: PropTypes.object,
    listProductReturns: PropTypes.array,
};

export default translate()(ComplaintSolution);
