import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';

function OrderStatus({t, status}) {
    return <span className={"a-status a-text--nowrap a-status--" + status}>{t('order:status.' + status)}</span>;
}

OrderStatus.defaultProps = {
    status: '',
};

OrderStatus.propTypes = {
    status: PropTypes.string,
};

export default translate()(OrderStatus);

