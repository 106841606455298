import {takeLatest} from 'redux-saga/effects';

import {
    FETCH_ALL_CONFIG_USER,
    UPDATE_USER_CONFIG
} from './constants';
import * as saga from './saga';

export default function* () {
    yield takeLatest(FETCH_ALL_CONFIG_USER.REQUEST, saga.fetchAllConfigUser);
    yield takeLatest(UPDATE_USER_CONFIG.REQUEST, saga.updateUserConfig);
}
