import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import {currentFormatter} from '../../../common/services/format';
import {ORDER_STATUS, ORDER_STATUS_REAL} from "../../../../systems/globalContant/actionContant";

class FormSearchHeader extends React.Component {
    onClick(status) {
        this.props.onClearCheckAll();
        this.props.onSubmit({status});
    }

    isShowingStatus(code) {
        switch (code) {
            case ORDER_STATUS.OUT_OF_STOCK:
            case ORDER_STATUS.CANCELLED:
            case ORDER_STATUS_REAL.OVERDUE_PAI:
            case ORDER_STATUS_REAL.OVERDUE_PAY:
                return false;
            default:
                return true;
        }
    }

    render() {
        let {status, t, countStatusChanged, statsOrder} = this.props;

        return (
            <div className="a-tab-order">
                <div className="a-tab-order__title">
                    <ul>
                        {statsOrder.map(orderStatus => {
                            if (!this.isShowingStatus(orderStatus.code)) {
                                return null;
                            }

                            let changedCount = countStatusChanged[orderStatus.code];

                            return (
                                <li key={orderStatus.code}
                                    className={`${orderStatus.className} ${(orderStatus.code === status.status ? 'active' : '')}`}
                                    onClick={this.onClick.bind(this, orderStatus.code)}
                                >
                                    {changedCount ?
                                        <span className={(changedCount > 9 ? "more " : "") + 'a-tab-order--number-noti'}>
                                            {changedCount > 9 ? "9+" : changedCount}
                                        </span>
                                    : null}
                                    <a href="" onClick={(event) => event.preventDefault()}>
                                        <span>{this.renderOrderCount(orderStatus.total)}</span>
                                        <span>{t('order:status.' + orderStatus.code)}</span>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }

    renderOrderCount(count) {
        if (count === undefined) {
            return '--';
        }

        return count > 10000 ? currentFormatter.prettyNumber(count, 1) : (count || 0)
    }
}

FormSearchHeader.defaultProps = {
    onClearCheckAll: () => {
    },
    countStatusChanged: {
        [ORDER_STATUS.ALL]: 0,
        [ORDER_STATUS.WAITING_BUY]: 0,
        [ORDER_STATUS.WAITING_PAI]: 0,
        [ORDER_STATUS.PAID]: 0,
        [ORDER_STATUS.WAITING_PAY]: 0,
        [ORDER_STATUS.BOUGHT]: 0,
        [ORDER_STATUS.OUT_OF_STOCK]: 0,
        [ORDER_STATUS.CANCELLED]: 0,
        OVERDUE_PURCHASE: 0
    }
};

FormSearchHeader.propTypes = {
    onClearCheckAll: PropTypes.func,
    countStatusChanged: PropTypes.object
};

export default translate()(FormSearchHeader);
