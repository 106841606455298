import {combineReducers} from 'redux';
import * as Constant from './constants'

const loading = (state = false, action) => {
    switch (action.type) {
        case Constant.ADD_INVOICE_CODE_2_TRANSACTION.SUCCESS:
        case Constant.ADD_INVOICE_CODE_2_TRANSACTION.FAILED:
        case Constant.CLEAR_ADD_INVOICE_CODE_2_TRANSACTION:
            return false;
        case Constant.ADD_INVOICE_CODE_2_TRANSACTION.REQUEST:
            return true;
        default:
            return state;
    }
};

export default combineReducers({
    loading,
});