import React, { useState } from 'react'
import { t } from '../../../../systems/i18n'
import { Button } from 'antd'
import ModalGeneral from '../../../common/components/ModalGeneral'
import apiService from '../apiService'
import notification from '../../../../systems/notification'
import { get } from 'lodash'

const Cancel1688Order = ({ orderId, getPurchasingOrderDetailAction, }) => {
    const [visible, setVisible] = useState(false)

    const [loading, setLoading] = useState(false)

    const handleVisibleConfirm = () => {
        setVisible(true)
    }
    const handleHideConfirm = () => {
        setVisible(false)
    }

    const handleCancel = () => {
        setLoading(true)
        apiService
            .cancelOrder1688(orderId)
            .then(res => {
                const code = get(res, 'data.code', false)
                if (code === 200) {
                    getPurchasingOrderDetailAction({ id: orderId })
                    handleHideConfirm()
                    notification.success(t('order:message.cancel_order_1688_success'))
                } else {
                    notification.error(t('order:message.cancel_order_1688_fail'))
                }
            })
            .catch(err => {
                notification.error(t('order:message.cancel_order_1688_fail'))
            })
            .finally(() => {
                setLoading(false)
            })
    }
           

    return (
        <>
            <Button size="small" className="mr-2 btn-cancel-order" onClick={handleVisibleConfirm}>
                {t('order:btn.cancel_order_1688')}
            </Button>

            {visible && (
                <ModalGeneral>
                    <div className={'a-modal__title'}>
                        <p>{t('orderDetail:order_detail.cancel_popup.title')} </p>
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>
                        {t('order:message.confirm_cancel_order_1688')}
                    </div>
                    <div className={'a--group--inline-end p-2 a--user-group-btn '}>
                        <Button className={' mr-3 a-btn--submit btn-submit'} onClick={handleCancel} loading={loading}>
                            {t('orderDetail:order_detail.cancel_popup.btn_submit')}
                        </Button>
                        <a appearance="subtle" onClick={handleHideConfirm} className={loading ? 'hidden' : 'a-btn a-btn--none'}>
                            {t('orderDetail:order_detail.cancel_popup.btn_cancel')}
                        </a>
                    </div>
                </ModalGeneral>
            )}
        </>
    )
}

export default Cancel1688Order
