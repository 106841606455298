import React from "react";
import {translate} from "react-i18next";

const ListStatus = function (props) {
    let {t, status, filter, onSearch} = props;

    function changeStatus(status) {
        onSearch({...filter, status});
    }

    return (
        <div className="a_complaint_status mt-4 mb-4">
            <ul>
                {
                    status && status.map((item, index) => {
                        return (
                            <li key={index}
                                onClick={() => changeStatus(item)}
                                className={filter.status === item ? "active" : ""}
                            >
                                <a href="" onClick={(event) => event.preventDefault()}>
                                    <span>{t(`changeInvoiceRequest:status.${item}`)}</span>
                                </a>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
}

export default translate()(ListStatus);
