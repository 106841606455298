import React from 'react';
import lodash from 'lodash';
import {translate} from 'react-i18next';
import * as PropTypes from "prop-types";
import moment from 'moment';
import CreateRequestForm from "./CreateRequestForm";
import ProgressBar from "./ProgressBar";
import ListRequest from "./ListRequest";
import FormSearchList from "./FormSearchList";
import * as Constants from './../constants';
import PusherComponent from "../../common/components/Pusher/index";
import {hasPermission, permissions} from "./../../Permission";
import {url} from './../../../systems/routing/index';
import Pagination from './../../common/components/Pagination';
import {Layout} from "antd";

const Content = Layout.Content;

let months = [
    {value: 1, label: 1},
    {value: 2, label: 2},
    {value: 3, label: 3},
    {value: 4, label: 4},
    {value: 5, label: 5},
    {value: 6, label: 6},
    {value: 7, label: 7},
    {value: 8, label: 8},
    {value: 9, label: 9},
    {value: 10, label: 10},
    {value: 11, label: 11},
    {value: 12, label: 12},
];

class SaleFigures extends PusherComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        super.componentDidMount();
        this.props.setPageTitle(this.props.t('layout.title.sale'));
        this.props.setPageDescription(this.props.t('layout.description.sale'));
        this.props.setMenuActive("sale_figures");
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.sale',
                'link': url.to('salefigures')
            },
        ]);
        if (Array.isArray(this.props.purchasers) && this.props.purchasers.length === 0) {
            this.props.getPurcharsers({pagination: 0});
        }

        if (this.props.channels) {
            this._addChannels(this.props.channels);
        }
    }

    componentDidUpdate() {
        if (this.props.channels) {
            this._addChannels(this.props.channels);
        }
    }

    /**
     * handle hành động nhận message
     * @param text
     * @param id
     * @param channel
     * @private
     */
    _pusherOnMessage(text, id, channel) {
        if (channel.startsWith(Constants.GET_PUSHER_REPORT)) {
            let messageObj = JSON.parse(text.replace("report=", ""));
            if (lodash.get(messageObj, "id", "") !== "") {
                this.props.getPusherReports(messageObj);
            }
        }
    }

    /**
     * Hàm thực hiện tìm kiếm dữ liệu
     * @param input
     */
    onSearch(input) {
        let {filter} = this.props;
        filter = {...filter, ...input}
        this.props.onSearch({...filter, page: 1});
    }


    /**
     * handle change page of list
     * @param page
     */
    onChangePage(page) {
        let {filter} = this.props;
        this.setState({page: page});
        this.props.onSearch({...filter, page: page});
    }

    /**
     * Search into username
     * @param user
     * @param username
     * @returns {boolean}
     */
    matchUserByUsername(user, username, item) {
        let {t} = this.props;
        username = username ? username.trim() : '';
        username = username ? username.toLowerCase() : '';
        return user.username.toLowerCase() === username ||
            (username.normalize()  === t('sale:form.all_purchaser').toLowerCase().normalize() &&
            lodash.get(item, 'all_purchaser', false)
            );
    }

    /**
     * Search into month
     * @param date
     * @param month
     * @returns {boolean}
     */
    matchUserByMonth(date, month) {
        return lodash.toInteger(date) === lodash.toInteger(month)
    }

    /**
     * Search into year
     * @param date
     * @param year
     * @returns {boolean}
     */
    matchUserByYear(date, year) {
        return lodash.toInteger(date) === lodash.toInteger(year)
    }

    /**
     * Prepare customize data before render to from
     * @returns {{list: (*|Array), pagination: {current_page: *, page_total: number}}}
     */
    renderData() {
        let {filter, saleFigures} = this.props;
        let saleFiguresCustom = saleFigures.filter(item =>
            (
                (!filter.username) ||
                (filter.username &&
                    lodash.get(item, 'purchasers', []).filter(
                        itemPurchaser => this.matchUserByUsername(itemPurchaser, filter.username, item)).length
                )
            )
            &&
            (
                (!filter.month && this.matchUserByMonth(item.month, moment().format('M'))) ||
                (filter.month && this.matchUserByMonth(item.month, filter.month) &&
                filter.year && this.matchUserByYear(item.year, filter.year))
            )
        );

        let per_page = 20;
        let current_page = lodash.get(filter, 'page', 1);
        current_page = lodash.toInteger(current_page);
        let from = (current_page - 1) * per_page;
        let to = current_page * per_page;
        let page_total = Math.ceil(saleFiguresCustom.length / per_page);
        return {
            list: lodash.slice(saleFiguresCustom, from, to),
            pagination: {
                current_page: current_page,
                page_total: page_total
            }
        }
    }

    render() {
        let {t, filter, isEndProcess, isLoadingData, error, reports} = this.props;
        let {list, pagination} = this.renderData();

        let year_current = lodash.toInteger(moment().format('YYYY'));
        let year_previous = lodash.toInteger(moment().add(-1, 'years').format('YYYY'));
        
        return (
            <Layout>
                <Content className="a-content--ant-layout">
                    <div className="a-content__right a-content--box-shadow">
                        <div className="a_salefigures">
                            {hasPermission(permissions.PURCHASE_REPORT_MANAGE) &&
                            <React.Fragment>
                                {(lodash.isEmpty(reports) && !lodash.get(isLoadingData, 'loadingCurrentReport', false) ) &&
                                <React.Fragment>
                                    <CreateRequestForm
                                        report={reports}
                                        purchasers={this.props.purchasers}
                                        createReports={this.props.createReports}
                                        months={months}
                                        year_previous={year_previous}
                                        year_current={year_current}
                                        error={error}
                                        clearErrorWhileCreate={this.props.clearErrorWhileCreate}
                                        isLoading={lodash.get(isLoadingData, 'postCreateRequest', false)}
                                        loadingListReport={lodash.get(isLoadingData, 'loadingListReport', false)}
                                    />
                                </React.Fragment>
                                }

                                {( !lodash.isEmpty(reports) ) &&
                                <React.Fragment>
                                    <ProgressBar
                                        report={reports}
                                        purchasers={this.props.purchasers}
                                        createCancelReports={this.props.createCancelReports}
                                        newRequestReport={this.props.newRequestReport}
                                        getPusherReports={this.props.getPusherReports}
                                        getCurrentReports={this.props.getCurrentReports}
                                        isLoading={lodash.get(isLoadingData, 'postCreateRequest', false)}
                                        isEndProcess={lodash.get(isEndProcess, 'postCreateRequest', false)}
                                    />
                                </React.Fragment>
                                }
                            </React.Fragment>
                            }
                            <div>
                                <div className="a_salefigures__content">
                                                    <span className="a_salefigures__history_text a-font--14 ">
                                                        <i className="fas fa-history mr-2"/>
                                                        {t('sale:list.request_recently')}
                                                    </span>
                                    <p className="a-font--10 a-text--red mt-2">
                                        {t('sale:list.history_current_mont')}
                                    </p>
                                </div>
                                <FormSearchList
                                    onSearch={this.onSearch.bind(this)}
                                    months={months}
                                    year_previous={year_previous}
                                    year_current={year_current}
                                    filter={filter}
                                    input={filter}
                                    onClear={(input) =>
                                        this.onSearch({...input, ...{_i: filter._i}})
                                    }
                                    inputVersion={typeof filter._i === "undefined" ? 0 : parseInt(filter._i, 0)}
                                />
                                <ListRequest
                                    filter={filter}
                                    saleFigures={list}
                                    months={months}
                                    inputVersion={typeof filter._i === "undefined" ? 0 : parseInt(filter._i, 0)}
                                />
                                <div className={"a-group--item a_navigation mt-5 d-flex justify-content-start " +
                                (pagination.page_total > 1 ? '' : 'hidden')}>
                                    <Pagination
                                        value={parseInt(pagination.current_page, 10)}
                                        total={pagination.page_total}
                                        onChange={page => this.onChangePage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </Layout>
        );
    }
}

SaleFigures.defaultProps = {
    filter: {},
    reports: {},
    saleFigures: [],
    createCancelReports: () => {
    },
};

SaleFigures.propTypes = {
    filter: PropTypes.object,
    reports: PropTypes.object,
    saleFigures: PropTypes.array,
    createCancelReports: PropTypes.func,
};

export default translate('translations')(SaleFigures);
