import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {url} from '../../../../../systems/routing';

class DistributeOrdersResult extends React.Component {
    render() {
        let {title, orders, color} = this.props;

        return (
            <div className="mt-3">
                <div className={`font-weight-bold mb-1`}>{title}</div>
                <div className={`a-box-quote a-box-quote--${color}`}>
                    {orders.map((order, index) => <span key={order.id}>
                        {index ? ', ' : null}
                        <a href={url.to('purchase.buying.detail', {id: order.id})} className="a-link" target="_blank">{order.code}</a>
                    </span>)}
                </div>
            </div>
        );
    }
}

DistributeOrdersResult.defaultProps = {
    title: null,
    orders: [],
    color: 'primary',
};

DistributeOrdersResult.propTypes = {
    title: PropTypes.any,
    orders: PropTypes.array,
    color: PropTypes.oneOf(['primary', 'success', 'danger', 'warning']),
};

export default translate()(DistributeOrdersResult);