import {combineReducers} from 'redux';
import * as ACTION from './constants';
import {setCollectionItem} from '../../common/services/helps';

let complaints = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.COMPLAINT_ASSIGN.REQUEST:
            return setCollectionItem(state, payload.id, {loading: true, error: null});

        case ACTION.COMPLAINT_ASSIGN.SUCCESS:
            return setCollectionItem(state, action.request.id, {loading: false});

        case ACTION.COMPLAINT_ASSIGN.FAILED:
            return setCollectionItem(state, action.request.id, {loading: false, error: payload});

        case ACTION.CLEAR_STATE:
            return state.filter(complaint => complaint.id !== payload.id);

        default:
            return state;
    }
};

let isLoading = (state = [], action) => {
    let {type} = action;

    switch (type) {
        case ACTION.COMPLAINT_ASSIGN.REQUEST:
            return true;

        case ACTION.COMPLAINT_ASSIGN.SUCCESS:
        case ACTION.COMPLAINT_ASSIGN.FAILED:
        case ACTION.CLEAR_STATE:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    complaints,
    isLoading,
});
