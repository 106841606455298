export const FETCH_ALL_CONFIG_USER = {
    REQUEST: 'FETCH_ALL_CONFIG_USER_REQUEST',
    SUCCESS: 'FETCH_ALL_CONFIG_USER_SUCCESS',
    FAILED: 'FETCH_ALL_CONFIG_USER_FAILED',
};

export const UPDATE_USER_CONFIG = {
    REQUEST: 'UPDATE_USER_CONFIG_REQUEST',
    SUCCESS: 'UPDATE_USER_CONFIG_SUCCESS',
    FAILED: 'UPDATE_USER_CONFIG_FAILED',
};