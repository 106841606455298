import {call, put} from 'redux-saga/effects';
import * as Constants from './constants';
import lodash from 'lodash';
import * as GlobalConstants from '../../systems/globalContant';
import service from './apiService';
import bootbox from './../common/components/Bootbox/bootbox';
import {t} from './../../systems/i18n/index.js';
import apiService from './apiService';

/**
 * Hàm lấy danh sách lý do
 * @param action
 */
export function* getComplaintSellerReasons(action) {

    try {
        let response = yield call(apiService.getListComplaintSellerReasons, action.payload.filter);
        if(!response) {
            return;
        }
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.FETCH_COMPLAINT_SELLER_REASONS.SUCCESS, payload: response.data});
        } else {
            bootbox.error(t('order_group.fetch_data_fail'));
            yield put({type: Constants.FETCH_COMPLAINT_SELLER_REASONS.FAILED, payload: response.data});
        }
    } catch (err) {
        yield put({type: Constants.FETCH_COMPLAINT_SELLER_REASONS.FAILED, payload: {}});
        bootbox.error(t(err.message) || t('system.error'));

    }
}

/**
 * Hàm lấy danh sách lý do gia hạn
 * @param action
 */
export function* fetchComplaintSellerRenewReasons(action) {
    try {
        let response = yield call(apiService.getComplaintSellerRenewReasons, action.payload);
        if(!response) return;

        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.FETCH_COMPLAINT_SELLER_RENEW_REASONS.SUCCESS, payload: response.data});
        } else
            bootbox.error(t('complaintSeller:renewReason.fetch_renew_reason_fail'));
    } catch (err) {
        bootbox.error(t(err.message) || t('system.error'));
    }
}

/**
 * Hàm lấy chi tiết khiếu nại người bán
 * @param action
 */
export function* fetchComplaintDetail(action) {

    try {
        let response = yield call(apiService.fetchComplaintDetail, action.payload);
        if(!response) {
            return;
        }
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.FETCH_COMPLAINT_DETAIL.SUCCESS, payload: response.data});
        } else {
            bootbox.error(t('order_group.fetch_data_fail'));
            yield put({type: Constants.FETCH_COMPLAINT_DETAIL.FAILED, payload: response.data});
        }
    } catch (err) {
        yield put({type: Constants.FETCH_COMPLAINT_DETAIL.FAILED, payload: {}});
        bootbox.error(t(err.message) || t('system.error'));

    }
}

/**
 * Hàm lấy danh sách khiếu nại người bán
 * @param action
 */
export function* getComplaintSeller(action) {

    try {
        let response = yield call(apiService.getListComplaintSeller, action.payload);
        if(!response) {
            return;
        }
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.FETCH_COMPLAINT_SELLER.SUCCESS, payload: response.data});
        } else {
            bootbox.error(t('order_group.fetch_data_fail'));
            yield put({type: Constants.FETCH_COMPLAINT_SELLER.FAILED, payload: response.data});
        }
    } catch (err) {
        yield put({type: Constants.FETCH_COMPLAINT_SELLER.FAILED, payload: {}});
        bootbox.error(t(err.message) || t('system.error'));

    }
}

/**
 * Hàm tạo mới khiếu nại người bán
 * @param action
 */
export function* createComplaintSellerSaga(action) {
    try {
        let response = yield call(service.createComplaintSeller, action.payload.datas);
        if(!response) {
            return;
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.CREATE_COMPLAINT_SELLER.SUCCESS, payload: response.data});
            bootbox.success(t('complaintSeller:list.create_success'));
        } else {
            yield put({type: Constants.CREATE_COMPLAINT_SELLER.FAILED, payload: response.data});
        }
    } catch (err) {
        bootbox.error(t("messages.system_errors") + " : " + err.message);
        yield put({type: Constants.CREATE_COMPLAINT_SELLER.ERROR, payload: err});
    }
}

/**
 * Hàm lấy xử lý cập nhận hạn xử lý
 * @param action
 */
export function* expireUpdateComplaintDetail(action) {

    try {
        let response = yield call(apiService.expireUpdateComplaintDetail, action.payload);
        if(!response) {
            return;
        }
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.EXPIRE_COMPLAINT_SELLER.SUCCESS, payload: response.data});
            bootbox.success(t('complaintSeller:detail.expire_processing_success'));
        } else {

            if (Object.keys(lodash.get(response, 'data.data.status', {})).length) {
                bootbox.error(t('complaintSeller:failed.message_status_invalid'));
            }

            yield put({
                type: Constants.EXPIRE_COMPLAINT_SELLER.FAILED, payload: {...response.data, ...action.payload}
            });
        }
    } catch (err) {
        yield put({type: Constants.EXPIRE_COMPLAINT_SELLER.FAILED, payload: {}});
        bootbox.error(t(err.message) || t('system.error'));

    }
}
