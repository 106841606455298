import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { Select, Button } from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import { dateFormatter } from '../../../../common/services/format'
import { VIOLATE_REASON_TYPE } from '../../../../../systems/globalContant/actionContant'
import { trans } from '../../../../../systems/i18n'
import ModalGeneral from './../../../../common/components/ModalGeneral'

class FormSelectViolateReason extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
        }
    }

    onChangeReason(type, reason) {
        const { data } = this.state
        this.setState({ data: { ...data, [type]: reason } })
    }

    onSubmit() {
        let { data } = this.state

        if (!this.props.loading && !lodash.isEmpty(data)) {
            this.props.onSubmit(data)
        }
    }

    /**
     * Enter submit
     * @param event
     */
    onKeyPress(event) {
        if (event.key === 'Enter') {
            this.onSubmit()
        }
    }

    onCancel() {
        if (!this.props.loading) {
            this.props.onCancel()
        }
    }

    render() {
        let { t, violatedPolicies, order, loading } = this.props
        let { data } = this.state
        return (
            <ModalGeneral scrollBehavior="outside">
                <div className="a-modal__title">{t('violate:select_reason.title')}</div>
                <div className={'a-modal--violate__body'}>
                    {violatedPolicies.map((violatedPolicy, index) => {
                        const reasonId = lodash.get(data, violatedPolicy.policy_type, undefined)
                        return (
                            <div
                                className="mb-4"
                                key={index}>
                                <div className="mb-1 a-text--fz-14 ">
                                    <b>{`${index + 1}: `}</b>
                                    {trans('violate:select_reason.violated_order', {
                                        policy: <b>{violatedPolicy.policy_name}</b>,
                                    })}
                                </div>
                                {/* <div className="ml-4"> */}
                                <div className="mb-1">
                                    {t('violate:select_reason.order_deposit_time')}: <b>{lodash.get(order, 'partner.time_deposit_full', '--')}</b>
                                </div>
                                <div className="mb-3">
                                    {t('violate:select_reason.now_time')}: <b>{dateFormatter.full(moment())}</b>
                                </div>
                                <div className="mb-2">
                                    <b>{t('violate:select_reason.select_reason')}</b>
                                </div>
                                <Select
                                    style={{ width: '100%' }}
                                    value={reasonId ? parseInt(reasonId) : undefined}
                                    noOptionsMessage={() => t('system.no_option')}
                                    className="single-react"
                                    classNamePrefix="react-select--violate_modal"
                                    placeholder=""
                                    allowClear={false}
                                    onChange={value => this.onChangeReason(violatedPolicy.policy_type, value)}
                                    onKeyDown={event => this.onKeyPress(event)}>
                                    {lodash.keys(VIOLATE_REASON_TYPE).map(type => (
                                        <Select.OptGroup label={this.props.t('violate:list.' + type)}>
                                            {this.props.reasons
                                                .filter(reason => reason.type === type)
                                                .map(reason => (
                                                    <Select.Option
                                                        value={reason.id}
                                                        key={reason.id}>
                                                        {reason.title}
                                                    </Select.Option>
                                                ))}
                                        </Select.OptGroup>
                                    ))}
                                </Select>
                                {/* </div> */}
                            </div>
                        )
                    })}
                </div>
                <div className="text-right mt-3 mb-3">
                    <Button
                        type="primary"
                        className="mr-2"
                        loading={loading}
                        disabled={lodash.isEmpty(data) || violatedPolicies.length !== Object.keys(data).length}
                        onClick={this.onSubmit.bind(this)}>
                        {t('btn.continue')}
                    </Button>
                    <Button
                        appearance="link"
                        disabled={loading}
                        onClick={this.onCancel.bind(this)}>
                        {t('btn.cancel')}
                    </Button>
                </div>
            </ModalGeneral>
        )
    }
}

FormSelectViolateReason.defaultProps = {
    order: {},
    violatedPolicies: [],
    reasons: [],
    loading: false,
    onSubmit: () => {},
    onCancel: () => {},
}

FormSelectViolateReason.propTypes = {
    order: PropTypes.object,
    violatedPolicies: PropTypes.array,
    reasons: PropTypes.array,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
}

export default translate()(FormSelectViolateReason)
