import React from 'react';
import {translate} from 'react-i18next';
import {Container, Row, Col} from 'react-grid-system';

class Help extends React.Component {
    componentDidMount() {
        this.props.setPageTitle(this.props.t('layout.title.help'));
        this.props.setPageDescription(this.props.t('layout.description.help'));
        this.props.setMenuActive("ListPurchase");
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.help',
                'link': '/help'
            },
        ]);
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} className="a-content--center">

                        <div className="a-help">

                            <div className="a-help--body">
                                <ul>
                                    <li>
                                        <a href="https://docs.google.com/document/d/1Q8GP1Hivq0_Ywnk33MxywVlsID37_NHhTXPl_1-33_E/edit">1: Hướng dẫn sử dụng</a>
                                    </li>
                                    <li>
                                        <a href="https://support.gobiz.vn/#/tickets/create?product=komodo&type=bug">2: Hướng dẫn báo lỗi</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default translate('translations')(Help);
