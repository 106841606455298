import React from 'react';
import {connect} from 'react-redux';
import ListTransactionFile from './components';
import * as Selector from './selectors';
import {getListTransactionFile, clearState, downloadFile} from "./actions";
import SearchableComponent from "../../common/SearchableComponent";
import lodash from "lodash";
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from './../../common/actions';
import { permission } from './selectors'

const mapStateToProps = (state) => {
    return {
        transactionFiles: Selector.getListTransactionFiles(state),
        loading: lodash.get(state, 'User.loading.detail', false) || lodash.get(state, 'Transaction.ListTransactionFile.loading', false),
        pagination: Selector.pagination(state),
        permission: Selector.permission(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        fetchTransactionFile: (filter) => {
            dispatch(getListTransactionFile(filter));
        },
        downloadFile: (id, filename) => {
            dispatch(downloadFile({id: id, filename: filename}));
        },
        clearState: () => dispatch(clearState()),
    };
};

class ListTransactionFileContainer extends SearchableComponent {
    componentDidMount() {
        super.componentDidMount();
    }

    onChangeFilter(filter) {
        filter.status = 'OVERDUE_PURCHASE';
        filter.time_type = 'DEPOSITED';
        filter.policy = 1;
        this.props.fetchTransactionFile(filter);
    }

    onSearch(filter) {
        this.pushFilter({
            ...filter,
            _i: parseInt(filter._i || 0, 0) + 1,
        });
    }

    getCurrentFilter() {
        let filter = {
            ...this.getFilterFromLocation(this.props.location),
        };

        return filter;
    }

    render() {
        let filter = this.getCurrentFilter();

        return <ListTransactionFile {...{
            ...this.props,
            filter: filter,
            filterVersion: parseInt(filter._i, 0),
            onSearch: this.onSearch.bind(this),
        }}/>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListTransactionFileContainer)