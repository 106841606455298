import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import {Col, Container, Row} from "react-grid-system";
import ChatLog from './../../common/Log/LogContainer';
import {url} from './../../../systems/routing/index';
import queryString from 'query-string';
import OrderInfo from './OrderInfo';
import PerformAction from './PerformAction';
import lodash from "lodash";
import ManagerPackagesContainer from './../ManagerPackages/ManagerPackagesContainer'
import UpdateShippingBill from '../UpdateShippingBill/UpdateShippingBillContainer';

class Index extends React.Component {

    componentDidMount() {
        let {t, location, match} = this.props;
        let filter = {...queryString.parse(location.search), ...match.params};
        this.props.setPageTitle(t('breadcrumb.complaint_inventory_solution'));
        this.props.setPageDescription(t('breadcrumb.complaint_inventory_solution'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.complaint_inventory_solution',
                'link': url.to('complaint-seller.detail', {id: filter.id})
            },
        ]);

        this.props.fetchProductReturn(filter);
        this.props.setMenuActive("ComplaintSeller");
    }

    componentWillUnmount() {
        this.props.clearProductReturn();
    }

    render() {
        let {productReturn, updateInfoProductReturn,
            resetErrorUpdateInfoProductReturn, createErrors,
            isLoadingData} = this.props;
        //nếu là trạng thái thành công hoặc hủy thì không cho sửa dữ liệu
        let canUpdateIntoStatus = !lodash.includes(["RETURNED","CANCELLED"],productReturn.status);
        return lodash.get(productReturn, 'id', 0) !== 0 ? (
                <React.Fragment>
                    <Container fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} className="a-content--center">
                                <div className="a_complaint_solution">
                                    <div className="a_complaint_solution__left">
                                        <PerformAction
                                            onUpdateStatus={this.props.onUpdateStatus}
                                            productReturn={productReturn}
                                            isLoadingData={this.props.isLoadingData.updateStatus}
                                        />
                                        <div className="a_complaint_solution__body">
                                            <Row>
                                                <OrderInfo productReturn={productReturn}
                                                           input={productReturn}
                                                           inputVersion={productReturn.id}
                                                           errors={createErrors}
                                                           canUpdateIntoStatus={canUpdateIntoStatus}
                                                           isLoadingData={isLoadingData}
                                                           resetError={resetErrorUpdateInfoProductReturn}
                                                           updateInfoProductReturn={updateInfoProductReturn}
                                                />
                                                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                    <div className="a-content--box-shadow">
                                                        {productReturn.id ? <UpdateShippingBill canUpdateIntoStatus={canUpdateIntoStatus}
                                                                                                productReturn={productReturn} /> : null
                                                        }
                                                        {productReturn.id ? <ManagerPackagesContainer canUpdateIntoStatus={canUpdateIntoStatus}
                                                                                                       productReturn={productReturn}/> : null
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="a_complaint_solution__right">
                                        {productReturn.id ?
                                            <ChatLog object="product_return" objectId={productReturn.id}
                                                     size="large"/> : null
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            ) :
            <React.Fragment>
                <div className='a-loading__page'>
                    <div className="a-loading__page__icon">{""}</div>
                </div>
            </React.Fragment>;
    }
}

Index.defaultProps = {
    productReturn: {},
    isLoadingData: {
        getProductReturn: false,
        updateStatus: false
    },
    createErrors:{},
    resetErrorUpdateInfoProductReturn:()=>{},
};

Index.propTypes = {
    productReturn: PropTypes.object,
    isLoadingData: PropTypes.object,
    onUpdateStatus: PropTypes.func,
    createErrors: PropTypes.object,
    resetErrorUpdateInfoProductReturn: PropTypes.func
};

export default translate()(Index);
