
export const ATTACH_ALIPAY = {
    REQUEST: 'COMPLAINT_SELLER.ALIPAY.ATTACH.REQUEST',
    SUCCESS: 'COMPLAINT_SELLER.ALIPAY.ATTACH.SUCCESS',
    FAILED: 'COMPLAINT_SELLER.ALIPAY.ATTACH.FAILED',
};

export const DETACH_ALIPAY = {
    REQUEST: 'COMPLAINT_SELLER.ALIPAY.DETACH.REQUEST',
    SUCCESS: 'COMPLAINT_SELLER.ALIPAY.DETACH.SUCCESS',
    FAILED: 'COMPLAINT_SELLER.ALIPAY.DETACH.FAILED',
};