import lodash from 'lodash';
import {events} from '../../../systems/events';
import authService from '../../Auth/authService';
import {TOOL_CRAWL_INVOICE_EVENT} from '../constans';
import INVOICE_EVENT from '../events';

let incrementId = 1;

export default class SourceInvoiceCrawler {
    constructor() {
        this.request = incrementId++;
        this.eventListener = this.eventListener.bind(this);
    }

    boot() {
        window.addEventListener('message', this.eventListener, false);
    }

    terminate() {
        window.removeEventListener('message', this.eventListener);
    }

    crawl(orders) {
        authService.authForTool();

        window.postMessage({
            type: TOOL_CRAWL_INVOICE_EVENT.START,
            payload: {
                request: this.request,
                invoices: this.getInvoices(orders),
            },
        });
    }

    cancel() {
        window.postMessage({
            type: TOOL_CRAWL_INVOICE_EVENT.CANCEL,
            payload: {
                request: this.request,
            },
        });
    }

    getInvoices(orders) {
        let invoices = orders.map(order => order.invoice_codes.map(invoice => ({
            code: invoice.invoice_code,
            source: order.seller_source,
            account: lodash.get(order.account_purchaser, "account", ""),
        }))).flat();

        return lodash.uniqBy(invoices, invoice => invoice.source + ':' + invoice.code);
    }

    eventListener(message) {
        // We only accept messages from ourselves
        if (message.source != window) {
            return;
        }

        let event = message.data;

        if (lodash.get(event, 'payload.request') !== this.request) {
            return;
        }

        switch (lodash.get(event, 'type')) {
            case TOOL_CRAWL_INVOICE_EVENT.START:
                return events.dispatch(INVOICE_EVENT.CRAWL_START, event.payload);

            case TOOL_CRAWL_INVOICE_EVENT.PROCESSING:
                return events.dispatch(INVOICE_EVENT.CRAWL_PROCESSING, event.payload);

            case TOOL_CRAWL_INVOICE_EVENT.SUCCESS:
                return events.dispatch(INVOICE_EVENT.CRAWL_SUCCESS, event.payload);

            case TOOL_CRAWL_INVOICE_EVENT.FAILED:
                return events.dispatch(INVOICE_EVENT.CRAWL_FAILED, event.payload);

            case TOOL_CRAWL_INVOICE_EVENT.FINISH:
                return events.dispatch(INVOICE_EVENT.CRAWL_FINISH, event.payload);

            case TOOL_CRAWL_INVOICE_EVENT.CANCEL:
                return events.dispatch(INVOICE_EVENT.CRAWL_CANCEL, event.payload);
        }
    }
}
