import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Button, Icon, Divider, Row, Col, notification } from 'antd'
import { translate } from 'react-i18next'
import { hasPermission, permissions } from '../../../../../modules/Permission'
import lodash from 'lodash'
import { ORDER_STATUS } from '../../../../../modules/Order/constants'
import ModalFormAssign from '../../../../../modules/Order/ListOrder/components/ModalForm/ModalFormAssign'
import AssignPurchaserModal from './AssignPurchaserModal'
import { events } from '../../../../../systems/events'
import ORDER_EVENT from '../../../../../modules/Order/events'
import { ORDER_PURCHASER_DISTRIBUTE } from '../../../../../modules/Permission/permissions'
import { processResponse } from '../../../../../systems/api'
import apiAssign from '../../../../../modules/Order/DistributeOrder/apiService'
import { updateCollectionItem } from '../../../../../modules/common/services/helps'
import { t } from '../../../../../systems/i18n'

class PerformAction extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isCheckedAll: false,
            loading: false,
        }
    }

    handleReset = e => {
        this.props.onSearch({ reset: true })
    }

    handleChange = e => {
        if (e.target.checked) this.props.onCheckAllOrders()
        else this.props.onCheckAllOrders(true)
    }

    onSelfAssign = () => {
        const { checkedOrders, updateOrders } = this.props
        console.log('checkedOrders', checkedOrders)
        this.setState({
            loading: true,
        })
        processResponse(apiAssign.selfAssignOrders(checkedOrders))
            .then(res => {
                let orders = lodash.get(res, 'data.orders', [])
                updateOrders(orders)
                this.props.onCheckAllOrders(true);
                notification.success({ message: t('order:distribute_order.self_assign.success') })
            })
            .catch(err => {
                const data = lodash.get(err, 'data', {})
                if (!lodash.isEmpty(data)) {
                    Object.keys(data).map(item => {
                        switch (item) {
                            case 'max_waiting_item':
                                return notification.error({ message: t('order:distribute_order.self_assign.error.max_waiting_item') })
                            case 'max_item':
                                return notification.error({ message: t('order:distribute_order.self_assign.error.max_item') })
                            default:
                                return notification.error({ message: t('order:distribute_order.self_assign.failed') })
                        }
                    })
                } else {
                    notification.error({ message: t('order:distribute_order.self_assign.failed') })
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    render() {
        let { checkedOrders, orders, filter, t, pagination } = this.props
        const { loading } = this.state
        return (
            <Row className="list-order-distributed">
                <Col
                    xs={24}
                    md={12}>
                    <div className="list-order-distributed-left">
                        <Checkbox
                            className="a-text--fz-12"
                            onChange={this.handleChange}
                            checked={orders.length && checkedOrders.length === orders.length ? true : false}>
                            Chọn tất cả đơn chưa phân
                        </Checkbox>
                        <Divider
                            type="vertical"
                            className="mt-1 mr-3"
                        />
                        <p className="a-font--12">
                            {t('order:list.total_order_result')}: <strong>{lodash.isNumber(pagination.total) ? pagination.total : '--'}</strong>
                        </p>
                        <Divider
                            type="vertical"
                            className="mt-1 mr-3"
                        />
                        <span
                            className="a--cursor-poiter"
                            onClick={this.handleReset}>
                            <Icon
                                type="sync"
                                className="mr-2"
                            />
                            <span className="a-text--fz-12">Làm mới bộ lọc</span>
                        </span>
                    </div>
                </Col>
                <Col
                    xs={24}
                    md={12}>
                    <div className="list-order-distributed-right">
                        {this.props.canSelfAssign && (
                            <Button
                                className="a-font--12 aphz-24 a-btn--primary-ant ml-3 btn-active"
                                loading={loading}
                                style={{ paddingTop: '1px' }}
                                onClick={this.onSelfAssign}
                                size="small"
                                type="submit">
                                <i
                                    className="fa fa-user-plus"
                                    aria-hidden="true"></i>
                                &nbsp;{t('order:distribute_order.self_assign.label')}
                            </Button>
                        )}

                        {hasPermission(permissions.ORDER_DISTRIBUTE) && (
                            <AssignPurchaserModal
                                orders={orders}
                                filter={filter}
                                tellers={this.props.tellers}
                                checkedOrders={checkedOrders}
                                onAssignPurchaserOrders={this.props.onAssignPurchaserOrders}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        )
    }
}

export default translate()(PerformAction)
