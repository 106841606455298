import {takeEvery} from 'redux-saga/effects';
import * as ACTION from './constants';
import * as saga from './saga';

export default function*() {
    yield takeEvery(ACTION.ATTACH_ALIPAY.REQUEST, saga.attachAlipay);
    yield takeEvery(ACTION.ATTACH_ALIPAY.SUCCESS, saga.onAttachAlipaySuccess);
    yield takeEvery(ACTION.ATTACH_ALIPAY.FAILED, saga.onAttachAlipayFailed);
    yield takeEvery(ACTION.DETACH_ALIPAY.REQUEST, saga.detachAlipay);
    yield takeEvery(ACTION.DETACH_ALIPAY.SUCCESS, saga.onDetachAlipaySuccess);
    yield takeEvery(ACTION.DETACH_ALIPAY.FAILED, saga.onDetachAlipayFailed);
}