import {connect} from 'react-redux';
import * as Selector from './selectors';
import UpdateFinanliOrder from './components';
import {
    clearErrorRealPayment,
    clearErrorRealRefund,
    updateRealPaymentAmount,
    updateRealRefundAmount,
} from './actions';

const mapStateToProps = (state, props) => {
    return {
        dataOrder: props.dataOrder,
        loading: props.loading,
        getPurchasingOrderDetailAction:props.getPurchasingOrderDetailAction,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateRealPaymentAmount: (datas) => dispatch(updateRealPaymentAmount(datas)),
        updateRealRefundAmount: (datas) => dispatch(updateRealRefundAmount(datas)),
        clearErrorRealPayment: () => {
            dispatch(clearErrorRealPayment());
        },
        clearErrorRealRefund: () => {
            dispatch(clearErrorRealRefund());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateFinanliOrder)