import React from "react";
import {t} from "../../../../systems/i18n";
import apiService from "../../../PurchaseBuying/OrderDetail/apiService";
import {processResponse} from "../../../../systems/api";
import * as GlobalConstants from "../../../../systems/globalContant";
import lodash from "lodash";
import bootbox from "../../../common/components/Bootbox/bootbox";
import {Icon} from "antd";
import {events} from "../../../../systems/events";
import ORDER_BILL_NO_EVENT from "../../events";
class SyncOrderBill extends React.Component {
    constructor(props) {
        super(props);

    }
    syncOrderBill(event) {
        const {order, selectedOrder} = this.props;

        this.props.onSync({
            selectedOrderIds: [order.id],
            completeSyncs: [],
            syncing: true
        });

        let res = apiService.syncOrderBillNo({id: order.id});

        let process = processResponse(res);

        process.then(res => {
            if (res) {
                if (res.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                    let errors = lodash.get(res, 'data.errors', []);
                    let order = lodash.get(res, 'data.order', {});
                    Object.keys(errors).map(key => {
                        Object.keys(errors[key]).map(error => {
                            let message = errors[key][error];
                            if (Object.keys(message).length > 0) {
                                this.props.onSync({
                                    syncing: false
                                });
                                Object.keys(message).map(msKey =>
                                    bootbox.error(t('orderDetail:order_detail.sync_order_bill_no.errors.' + msKey, {
                                        value: message[msKey]
                                    }))
                                )
                            } else {
                                this.props.onSync({
                                    syncing: false
                                });
                                bootbox.error(t('orderDetail:order_detail.sync_order_bill_no.errors.' + error))
                            }
                        });
                    });
                }
            }
        });

        process.catch((err) => {
            this.props.onSync({
                syncing: false
            });
            bootbox.error(t("message.system_errors") + " : " + err.message);
        });
    }
    render() {
        return (
            (this.props.syncing) ?
                <Icon type="sync" spin/>
                :
                <div>
                    <Icon type="sync" className="a-text--color-primary a-text--pointer" onClick={event => this.syncOrderBill(event)}/>
                </div>
        )
    }
}
export default SyncOrderBill;
