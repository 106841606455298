import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import ItemOrder from "./ItemOrder";

class List extends React.Component {


    render() {
        let {t, orders, violateReasons, updateReason} = this.props;

        return (
            <table className={'a-table--violate table-responsive'}>
                <thead>
                <tr>
                    <th>
                        {t("violate:list.order")}
                    </th>
                    <th>
                        {t("violate:list.processor")}
                    </th>
                    <th>
                        {t("violate:list.time_deposit")}
                    </th>

                    <th>
                        {t("violate:list.time_teller")}
                    </th>
                    <th>
                        {t("violate:list.time_paid")}
                    </th>
                    <th>
                        {t("violate:list.status")}
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    orders.map((order) => {
                        return (<ItemOrder
                            key={order.id}
                            order={order}
                            updateReason={updateReason}
                            violateReasons={violateReasons}
                        />);
                    })
                }
                </tbody>
            </table>
        )
            ;
    }
}

List.defaultProps = {
    orders: [],
    partner: {id: 0, code: "", domain: ""},

};

List.propTypes = {
    orders: PropTypes.array,
    partner: PropTypes.object,
};


export default translate('translations')(List);
