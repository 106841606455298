import {connect} from 'react-redux';
import FormError from './../../components/Error/index';
import {setBreadCrumb, setPageDescription, setPageTitle, setMenuActive} from './../../actions';

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormError)
