import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import Pagination from '@atlaskit/pagination';

class PaginationCustom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {onChange, value, max, total} = this.props;
        let pages = [];
        for (let i = 1; i <= total; i++) {
            pages[i] = i;
        }
        return (
            <React.Fragment>
                {pages.length > 2 ? <Pagination
                    className={this.props.className}
                    selectedIndex={value}
                    pages={pages}
                    max={max}
                    onChange={(event, newSelectedPage) => onChange(newSelectedPage)}
                /> : null}
            </React.Fragment>
        )
    }
}

PaginationCustom.defaultProps = {
    max: 10,
    value: 1,
    total: 1,
};

PaginationCustom.propTypes = {
    max: PropTypes.number,
    value: PropTypes.number,
    total: PropTypes.number,
};

export default translate('translations')(PaginationCustom);
