import React from 'react';
import {Form, Row, Col, Input, Button, Icon, DatePicker, Select} from 'antd';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import FormProcessing from "../../../common/components/FormProcessing";
import lodash from 'lodash';
import Super from '../../../common/components/Input/Super';
import moment from "moment";
import SelectAccountPurchaser from "../../../AccountPurchaser/SelectAccountPurchasers";
import TRANSACTION_EVENT from "../../events";
import {events} from "../../../../systems/events";
import SelectScanningStatus from "../../../Order/SelectScanningStatus";
import IncludedAllOptionSelect from "../../../common/components/IncludedAllOptionSelect"
import SelectTellersV2 from "../../../User/SelectTellersV2";
import SelectAgencies from "../../../Agency/SelectAgencies";
import { TRANSACTION_ORDER_STATUS, TRANSACTION_PROBLEM_TYPES, LIST_SCANNING_STATUS} from '../../../Order/constants'

const Option = Select.Option;
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const Search = Input.Search;
const InputGroup = Input.Group;
const checkError = (input, errors, t) => {
    let {profit_from, profit_to, profit_percent_from, profit_percent_to} = input;
    if (parseFloat(profit_from) > parseFloat(profit_to)) {
        errors = {...errors, profit: t("order:message.profit_error")};
    }

    if (parseFloat(profit_percent_from) > parseFloat(profit_percent_to)) {
        errors = {...errors, profit_percent: t("order:message.profit_percent_error")};
    }

    return errors;
};
const currentDay = moment().format("YYYY-MM-DD")
const monthAgo = moment().subtract(1, 'M').add(1, 'd').format('YYYY-MM-DD')
class FormSearch extends FormProcessing {
    constructor(props) {
        super(props);

        this.defaultInput = {
            is_problem: "",
            violation: "",
            code: "",
            id_purchaser: "",
            status: "",
            profit_from: "",
            profit_to: "",
            profit_percent_from: "",
            profit_percent_to: "",
            bought_at_from: '',
            bought_at_to: '',
            deposited_at_from: monthAgo,
            deposited_at_to: currentDay,
            scanning_status: '',
            problem_type: '',
            is_resolved: '',
            problem_resolve_time_from: "",
            problem_resolve_time_to: ""
        };

        this.state = {
            input: {...this.defaultInput},
            errors: {},
            inputVersion: 0,
            changedParams: [],
        };
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     let state = super.getDerivedStateFromProps(nextProps, prevState);
    //     state.errors = checkError({...prevState.input}, {}, nextProps.t);
    //     if (!lodash.isEmpty(nextProps.filters) && prevState.input.deposited_at_from === '' && prevState.input.deposited_at_to === '' && prevState.inputVersion === 0) {
    //         nextProps.onSearch({
    //             ...prevState.input,
    //             deposited_at_from: nextProps.filters.deposited_at_from,
    //             deposited_at_to: nextProps.filters.deposited_at_to
    //         });
    //         nextProps.setCSVFilter({
    //             deposited_at_from: nextProps.filters.deposited_at_from,
    //             deposited_at_to: nextProps.filters.deposited_at_to
    //         });
    //     }
    //     return state;
    // }

    componentDidMount() {
        this.props.setCSVFilter({
            ...this.state.input
        });
    }

    onSubmit(event) {
        let {input} = this.state;
        event.preventDefault();
        Object.keys(input).map(key => input[key] = typeof input[key] == 'string' ? input[key].trim() : input[key]);
        if (!this.props.loading) {
            events.dispatch(TRANSACTION_EVENT.RESET_ERROR);
            this.props.onSearch({
                ...lodash.omit(this.state.input, ['page', 'per_page']),
            });
        }
    }

    onChangeText(param, event) {
        this.onChangeInput(param, event);
        this.props.setCSVFilter({[param]: event.target.value})
    }

    onChangeNumber(value, param) {
        let {t} = this.props;
        if (param.includes("from") || param.includes("to")) {
            value = parseFloat(value);
        }
        let {input, errors} = this.state;
        input = {...input, [param]: value};
        errors = checkError(input, errors, t);
        this.setState({errors: {...errors}});
        this.changeInput({[param]: value});
        this.props.setCSVFilter({[param]: value})
    }

    onSearchSelect = (param, value) => {
        let {filter} = this.props;

        if ((value === "" || value === undefined) && (filter[param] === undefined || filter[param] === ""))
            return false;

        let dataFilter = this.changeInput({[param]: value, page: 1});
        this.props.onSearch(dataFilter);
    }

    onChangeSelect(value, param) {
        this.changeInput({[param]: value});
        this.props.setCSVFilter({[param]: value})
    }

    onSwitchStatus(param) {
        let variable = lodash.get(this.state.input, `${param}`, '');
        let changeValue = (!variable || variable === "") ? 1 : "";

        this.changeInput({[param]: changeValue});
        if (!this.props.loading) {
            this.props.onSearch({
                ...lodash.omit(this.state.input, ['page', 'per_page']),
                [param]: changeValue
            });
        }
        this.props.setCSVFilter({[param]: changeValue})
    }

    onSelectStatus(param, value) {
        let variable = lodash.get(this.state.input, `${param}`, '');
        let changeValue;
        switch (variable) {
            case '':
                changeValue = value;
                break;
            default:
                changeValue = (variable === value) ? '' : value;
                break;
        }
        this.changeInput({[param]: changeValue});
        if (!this.props.loading) {
            this.props.onSearch({
                ...lodash.omit(this.state.input, ['page', 'per_page']),
                [param]: changeValue
            });
        }
        this.props.setCSVFilter({[param]: changeValue})
    }

    onChangeTimeRange(param1, param2, date) {
        this.changeInput({
            [param1]: date[0] ? date[0].format('YYYY-MM-DD') : '',
            [param2]: date[1] ? date[1].format('YYYY-MM-DD') : ''
        });
        this.props.setCSVFilter({
            [param1]: date[0] ? date[0].format('YYYY-MM-DD') : '',
            [param2]: date[1] ? date[1].format('YYYY-MM-DD') : '',
        })
    }

    onReset(event) {
        event.preventDefault();
        if (!this.props.loading) {
            this.setState({
                ...this.state,
                errors: {}
            });
            events.dispatch(TRANSACTION_EVENT.RESET_ERROR);
            this.changeInput(this.defaultInput);
            this.props.onSearch({...this.defaultInput});
            this.props.setCSVFilter({...this.defaultInput})
        }
    }

    render() {

        let {t, external_errors} = this.props;
        let {input, errors} = this.state;
        // let is_problem = input.is_problem;
        let is_resolved = input.is_resolved;
        let violation = input.violation;
        return (
            <Form onSubmit={this.onSubmit.bind(this)}>
                <Row gutter={24} className="a-list--form-search mr-0 ml-0">
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item
                            label={t("order:label.code")}
                            className="a-form--ant-item mb-0"
                        >
                            <Search
                                value={input.code || ''}
                                onChange={this.onChangeText.bind(this, 'code')}
                                placeholder={t("order:placeholder.code")}
                                className="_transactions-search-code"
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item
                            label={t("order:label.invoice_code")}
                            className="a-form--ant-item  mb-0">
                            <Input
                                className="_transactions-search-invoice-code"
                                type="text"
                                allowClear
                                value={input.invoice_code || ''}
                                onChange={this.onChangeText.bind(this, 'invoice_code')}
                                placeholder={t("transaction:label.invoice_code")}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item
                            label={t("order:label.account")}
                            className="a-form--ant-item mb-0"
                        >
                            <SelectAccountPurchaser
                                className="_transactions-search-account"
                                value={input.id_purchaser || ''}
                                placeholder={t("order:placeholder.account")}
                                allAccount={true}
                                allOption={true}
                                onChange={id_purchaser => this.onChangeSelect(id_purchaser, 'id_purchaser')}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item label={t("transaction:label.status")} className="a-form--ant-item  mb-0">
                            <Select
                                showSearch={true}
                                value={this.props.loading ? undefined : input.status}
                                placeholder={t("transaction:placeholder.status")}
                                className="single-react _transactions-search-status"
                                loading={this.props.loading}
                                noOptionsMessage={({inputValue: string}) => t('message.no_record')}
                                onSelect={status => this.onChangeSelect(status, 'status')}
                                optionFilterProp="children"

                            >
                                <Option value="">{t('label.all')}</Option>
                                {TRANSACTION_ORDER_STATUS.map(status => (
                                    <Option
                                        key={status}
                                        value={status}
                                    >{t("order:status." + status)}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item
                            label={t("transaction:label.bought_at")}
                            className="a-form--ant-item  mb-0"
                            help={external_errors.bought_at}
                            validateStatus={external_errors.bought_at ? 'error' : null}>
                            <RangePicker
                                className="_transactions-search-bought-at"
                                placeholder={[t("transaction:placeholder.begin"), t("transaction:placeholder.end")]}
                                format='DD/MM/YYYY'
                                value={[
                                    input['bought_at_from'] ? moment(input['bought_at_from'], dateFormat) : null,
                                    input['bought_at_to'] ? moment(input['bought_at_to'], dateFormat) : null,
                                ]}
                                onChange={this.onChangeTimeRange.bind(this, 'bought_at_from', 'bought_at_to')}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item
                            label={t("transaction:label.deposited_at")}
                            className="a-form--ant-item  mb-0"
                            help={external_errors.deposited_at}
                            validateStatus={external_errors.deposited_at ? 'error' : null}>
                            <RangePicker
                                className="_transactions-search-deposited-at"
                                placeholder={[t("transaction:placeholder.begin"), t("transaction:placeholder.end")]}
                                format='DD/MM/YYYY'
                                value={[
                                    input['deposited_at_from'] ? moment(input['deposited_at_from'], dateFormat) : null,
                                    input['deposited_at_to'] ? moment(input['deposited_at_to'], dateFormat) : null,
                                ]}
                                onChange={this.onChangeTimeRange.bind(this, 'deposited_at_from', 'deposited_at_to')}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item
                            label={"TG hủy"}
                            className="a-form--ant-item  mb-0"
                            help={external_errors.cancelled_at}
                            validateStatus={external_errors.cancelled_at ? 'error' : null}>
                            <RangePicker
                                className="_transactions-search-cancelled-at"
                                placeholder={[t("transaction:placeholder.begin"), t("transaction:placeholder.end")]}
                                format='DD/MM/YYYY'
                                value={[
                                    input['cancelled_at_from'] ? moment(input['cancelled_at_from'], dateFormat) : null,
                                    input['cancelled_at_to'] ? moment(input['cancelled_at_to'], dateFormat) : null,
                                ]}
                                onChange={this.onChangeTimeRange.bind(this, 'cancelled_at_from', 'cancelled_at_to')}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item
                            label={"TG hết hàng"}
                            className="a-form--ant-item  mb-0"
                            help={external_errors.out_of_stock_at}
                            validateStatus={external_errors.out_of_stock_at ? 'error' : null}>
                            <RangePicker
                                className="_transactions-search-out-of-stock-at"
                                placeholder={[t("transaction:placeholder.begin"), t("transaction:placeholder.end")]}
                                format='DD/MM/YYYY'
                                value={[
                                    input['out_of_stock_at_from'] ? moment(input['out_of_stock_at_from'], dateFormat) : null,
                                    input['out_of_stock_at_to'] ? moment(input['out_of_stock_at_to'], dateFormat) : null,
                                ]}
                                onChange={this.onChangeTimeRange.bind(this, 'out_of_stock_at_from', 'out_of_stock_at_to')}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item label={t('order:label.scanning_status')} className="a-form--ant-item  mb-0">
                            <IncludedAllOptionSelect
                                className="_transactions-search-scanning-status"
                                loading={this.props.loading}
                                value={input.scanning_status || ''}
                                placeholder={t("order:placeholder.scanning_status")}
                                showSearch={true}
                                optionFilterProp={'children'}
                                list={LIST_SCANNING_STATUS}
                                translatePrefix={'order:scanning_status'}
                                allOption={true}
                                onChange={scanning_status => this.onChangeSelect(scanning_status, 'scanning_status')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item label={t('order:label.problem_type')} className="a-form--ant-item  mb-0">
                            <IncludedAllOptionSelect
                                className="_transactions-search-problem-type"
                                loading={this.props.loading}
                                value={input.problem_type || ''}
                                placeholder={t("order:placeholder.problem_type")}
                                showSearch={true}
                                optionFilterProp={'children'}
                                list={TRANSACTION_PROBLEM_TYPES}
                                translatePrefix={'order:problem_type'}
                                allOption={true}
                                onChange={problem_type => this.onChangeSelect(problem_type, 'problem_type')}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item label="Nhân viên mua hàng" className="a-form--ant-item  mb-0">
                            <SelectTellersV2
                                className="_transactions-search-id-teller"
                                value={input.id_teller}
                                multiple={true}
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="children"
                                placeholder="Chọn nhân viên mua hàng"
                                onChange={value => this.onChangeSelect(value, "id_teller")}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item label={t("order:label.agencies")} className="a-form--ant-item mb-0">
                            <SelectAgencies
                                className="_transactions-search-id-agency"
                                value={input.id_agency}
                                multiple={true}
                                allOption={false}
                                allowClear={true}
                                placeholder={t("order:placeholder.agencies")}
                                onChange={value => this.onSearchSelect("id_agency", value)}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item label={t("order:list.KNNB")} className="a-form--ant-item  mb-0">
                            <Select
                                className="_transactions-search-complaint_seller"
                                value={input.complaint_seller ? input.complaint_seller : ""}
                                allowClear={true}
                                placeholder={t("order:list.KNNB")}
                                onChange={value => this.onSearchSelect("complaint_seller", value)}
                            >
                                <Option value="">{t('label.all')}</Option>
                                <Option value={"ALL"}>{t("order:list.has_complaint_seller")}</Option>
                                <Option value={"ACTIVE"}>{t("order:list.has_complaint_seller_active")}</Option>
                                <Option value={"NONE"}>{t("order:list.has_not_complaint_seller")}</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item
                            label={t("order:label.profit")}
                            className="a-form--ant-item mb-0"
                            help={errors.profit}
                            validateStatus={errors.profit ? 'error' : null}
                        >
                            <InputGroup compact className="a-group--input">
                                <Super
                                    negative={true}
                                    value={input.profit_from || ''}
                                    placeholder={t("order:label.profit_from")}
                                    className={'a-input--number input-first _transactions-search-profit-from'}
                                    onChange={event => this.onChangeNumber(event, "profit_from")}
                                />
                                <Input
                                    placeholder="-"
                                    disabled
                                    className={"input-disable"}
                                />
                                <Super
                                    negative={true}
                                    value={input.profit_to || ''}
                                    placeholder={t("order:label.profit_to")}
                                    className={'a-input--number input-second _transactions-search-profit-to'}
                                    onChange={value => this.onChangeNumber(value, "profit_to")}
                                />
                            </InputGroup>
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item
                            label={t("order:label.profit_percent") + " %"} className="a-form--ant-item  mb-0"
                            help={errors.profit_percent}
                            validateStatus={errors.profit_percent ? 'error' : null}
                        >
                            <InputGroup compact className="a-group--input">
                                <Super
                                    negative={true}
                                    value={input.profit_percent_from || ''}
                                    placeholder={t("order:label.profit_percent_from")}
                                    className={'a-input--number input-first _transactions-search-profit-percent-from'}
                                    onChange={value => this.onChangeNumber(value, "profit_percent_from")}
                                />
                                <Input
                                    placeholder="-"
                                    disabled
                                    className={"input-disable"}
                                />
                                <Super
                                    negative={true}
                                    value={input.profit_percent_to || ''}
                                    placeholder={t("order:label.profit_percent_to")}
                                    className={'a-input--number input-second _transactions-search-profit-percent-to'}
                                    onChange={value => this.onChangeNumber(value, "profit_percent_to")}
                                />
                            </InputGroup>
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item
                            label={t("transaction:label.problem_resolve_creator")}
                            className="a-form--ant-item mb-0"
                        >
                            <SelectTellersV2
                                className="_transactions-search-problem-resolve-creator"
                                allOption
                                value={input.problem_resolve_creator_id}
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="children"
                                placeholder="Chọn giao dịch viên"
                                onChange={value => this.onChangeSelect(value, "problem_resolve_creator_id")}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item
                            label={t("transaction:label.problem_resolve_time")}
                            className="a-form--ant-item  mb-0"
                            help={external_errors.problem_resolve_time}
                            validateStatus={external_errors.problem_resolve_time ? 'error' : null}>
                            <RangePicker
                                className="_transactions-search-problem-resolve-time"
                                placeholder={[t("transaction:placeholder.begin"), t("transaction:placeholder.end")]}
                                format='DD/MM/YYYY'
                                value={[
                                    input['problem_resolve_time_from'] ? moment(input['problem_resolve_time_from'], dateFormat) : null,
                                    input['problem_resolve_time_to'] ? moment(input['problem_resolve_time_to'], dateFormat) : null,
                                ]}
                                onChange={this.onChangeTimeRange.bind(this, 'problem_resolve_time_from', 'problem_resolve_time_to')}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{span: 24}} lg={{span: 24}} xl={{span: 24}}
                         className="pr-0 pl-0 mt-4 pt-5 border-top-dashed">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="a-list--action-search flex-row">
                                <Button
                                    loading={this.props.loading}
                                    disabled={!lodash.isEmpty(errors)}
                                    onClick={this.onSelectStatus.bind(this, 'is_resolved', '0')}
                                    className={"a-text--uppercase a-btn--primary-ant mx-1 _transactions-search-button-unresolved-order " + ((!is_resolved || is_resolved === "" || is_resolved === '1') ? "ghost" : "")}>{t("order:btn.unresolved_order")}</Button>
                                <Button
                                    loading={this.props.loading}
                                    disabled={!lodash.isEmpty(errors)}
                                    onClick={this.onSelectStatus.bind(this, 'is_resolved', '1')}
                                    className={"a-text--uppercase a-btn--primary-ant mx-1 _transactions-search-button-resolved-order " + ((!is_resolved || is_resolved === "" || is_resolved === '0') ? "ghost" : "")}>{t("order:btn.resolved_order")}</Button>
                                <Button
                                    loading={this.props.loading}
                                    disabled={!lodash.isEmpty(errors)}
                                    onClick={this.onSwitchStatus.bind(this, 'violation')}
                                    className={"a-text--uppercase a-btn--primary-ant mx-1 _transactions-search-button-violation-order " + ((!violation || violation === "") ? "ghost" : "")}>{t("order:btn.search_violation_order")}</Button>
                            </div>
                            <div className="a-list--action-search flex-row">
                                <a href="/" onClick={this.onReset.bind(this)} className="link-reset"><Icon
                                    type="sync"/>{t("btn.reset_search")}
                                </a>
                                <Button
                                    loading={this.props.loading}
                                    disabled={!lodash.isEmpty(errors)}
                                    type="primary"
                                    htmlType="submit"
                                    className={"ant-btn a-text--uppercase a-btn--primary-ant ml-2 aphz-50 _transactions-search-button-submit-search " + (lodash.isEmpty(errors) ? "" : "disable")}
                                >{t('btn.search')}</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form>
        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    onSearch: (input) => {
    },
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

export default translate('translations')(FormSearch);
