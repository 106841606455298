import lodash from 'lodash';
import {apiWithToken} from '../../systems/api';
import {API_URL} from './../../systems/globalContant';
import {ORDER_STATUS} from '../../systems/globalContant/actionContant';

const normalizeQueryListOrder = params => {
    if (params.status === ORDER_STATUS.ALL) {
        params = lodash.omit(params, ['status']);
    }

    if (params.total_item_link_operation === '>') {
        params.total_item_link_from = params.total_item_link;
    }

    if (params.total_item_link_operation === '<') {
        params.total_item_link_to = params.total_item_link;
    }

    return lodash.omit(params, [
        'total_item_link_operation',
        'total_item_link',
    ]);
};

export default {
    getListOrder: (params) => apiWithToken.get(API_URL.GET_LIST_ORDER, {
        params: normalizeQueryListOrder(params),
        singleRequest: true,
        loading: false
    }),
    getListTransactionOrder: (params) => apiWithToken.get(API_URL.GET_LIST_TRANSACTION_ORDER, {
        params: params,
        singleRequest: true,
        loading: false
    }),
    countOrders: (params) => apiWithToken.get(API_URL.DEFAULT + '/orders/count', {
        params: normalizeQueryListOrder(params),
        singleRequest: true,
        loading: false
    }),
    getOrderStats: () => apiWithToken.get(API_URL.DEFAULT + '/orders/stats', {singleRequest: true, loading: false}),
    fetchServices: () => apiWithToken.get(API_URL.GET_LIST_SERVICE, {loading: false}),
    scanableOrders: (params) => apiWithToken.get(API_URL.DEFAULT + '/orders/scanning', {params, singleRequest: true, loading: false}),
    crawlableOrders: (params) => apiWithToken.get(API_URL.DEFAULT + '/orders/crawls', {params, singleRequest: true, loading: false}),
    exportActualAmount: (params) => apiWithToken.get(API_URL.DEFAULT + '/orders/export-actual-amounts', {params, singleRequest: true, loading: false}, {...params}),
    exportOrder: (params) => apiWithToken.get(API_URL.DEFAULT + '/orders/export-transactions', {params, singleRequest: true, loading: false}, {...params}),
    markViolationOrder: (orderId, data = {}) => apiWithToken.put(API_URL.DEFAULT + `/orders/${orderId}/mark-violation`, data, {singleRequest: true, loading: false}),
    unmarkViolationOrder: (orderId, data = {}) => apiWithToken.put(API_URL.DEFAULT + `/orders/${orderId}/unmark-violation`, data, {singleRequest: true, loading: false}),
    fetchSellerSource: () => apiWithToken.get(API_URL.LIST_SELLER_SOURCE, {loading: false}),
    addInvoiceCodeApprovals: (orderId, data = {}) => apiWithToken.post(API_URL.DEFAULT + `/invoice-code-approvals/${orderId}`, data, {singleRequest: true, loading: false}),
    fetchDistributiveWarehouse: () => apiWithToken.get(API_URL.DEFAULT + '/warehouse-distributions', {singleRequest: true, loading: false}),
    fetchPromotions: (params) => apiWithToken.get(API_URL.DEFAULT + "/order-item/affiliate", {singleRequest: true, loading: false, params}),
    syncWebhook: (orderId) => apiWithToken.post(API_URL.DEFAULT + `/orders/${orderId}/sync-webhook`),
    changeReceive: (orderId) => apiWithToken.put(API_URL.DEFAULT + `/orders/${orderId}/received`)
};
