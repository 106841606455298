import React from "react";
import { connect } from 'react-redux';
import { setPageTitle, setBreadCrumb, setPageDescription, setMenuActive } from '../../modules/common/actions';
import SearchableComponent from "../../modules/common/SearchableComponent";
import {translate} from "react-i18next";
import {Layout, notification} from "antd";
import {processResponse} from "../../systems/api";
import apiService from "./apiService";
import lodash from "lodash";
import ListApprovals from "./components/ListApprovals";
import ListStatus from "./components/ListStatus";
import FormSearch from "./components/FormSearch";
import {updateCollectionItem} from "../../modules/common/helps";

const Content = Layout.Content;

class InvoiceCodeApprovals extends SearchableComponent {

	constructor(props) {
		super(props);

		this.defaultInput = {
			// invoice_code: '',
			status: 'WAITING',
			// created_at_from: '',
			// created_at_to: '',
			// processing_time_from: '',
			// processing_time_to: '',
			// page: 1,
			// per_page: 50
		};

		this.defaultState = {
			approvals: [],
			pagination: {},
			loading: false,
			listStatus: []
		};

		this.state = {
			...this.defaultState,
			errors: {},
		};
	}

	componentDidMount() {
		super.componentDidMount();
		this.setPageInfo();
	}

	updateInvoiceCodeApprovals(approval) {
		this.setState(state => {
			let {approvals} = state;
			return {
				...state,
				approvals: updateCollectionItem(approvals, approval.id, {...approval})
			};
		});
	}

	setPageInfo() {
		let {t, setPageTitle, setPageDescription, setMenuActive, setBreadCrumb } = this.props;
		setPageTitle(t('layout.title.invoice_code_approvals'));
		setPageDescription(t('layout.description.invoice_code_approvals'));
		setMenuActive('invoice_code_approvals');
		setBreadCrumb([
			{
				label: 'breadcrumb.home',
				link: '/',
			},
			{
				label: 'breadcrumb.invoice_code_approvals',
				link: '/invoice-code-approvals/',
			},
		]);
	}

	onChangeFilter(filter) {
		if (this.state.loading)
			return;

		if (lodash.isEmpty(filter))
			filter = {...this.defaultInput};

		this.fetchInvoiceCodeApprovals(filter);
	}

	fetchInvoiceCodeApprovals(filter) {
		let {t} = this.props;

		this.setState({loading: true});
		processResponse(apiService.fetchInvoiceCodeChange({...filter}))
			.then(res => {
				this.setState({
					loading: false,
					status: lodash.get(res, 'data.listStatus', []),
					approvals: lodash.get(res, 'data.orderSourceInvoiceApprovals', []),
					pagination: lodash.get(res, 'data.pagination', 0)
				});
			})
			.catch(error => {
				notification.error({message: t('message.cant_fetch_data')});
			});
	}

	getCurrentFilter() {
		return {
			...this.defaultInput,
			...this.getFilterFromLocation(this.props.location)
		};
	}

	onSearch(filter) {
		if (this.state.loading)
			return;

		this.pushFilter({
			...filter,
			i: parseInt(filter.i || 0, 0) + 1,
		});
	}

	render() {
		let { pagination, approvals, status, loading } = this.state;
		let filter = this.getCurrentFilter();

		return (
			<Layout>
				<Content className="a-content--ant-layout sale-report">
					<FormSearch
						input={filter}
						inputVersion={filter.i || 0}
						loading={this.state.loading}
						listStatus={status}
						onSearch={this.onSearch.bind(this)}
						defaultInput={this.defaultInput}
					/>

					<ListStatus
						status={status}
						filter={filter}
						onSearch={this.onSearch.bind(this)}
					/>

					<ListApprovals
						approvals={approvals}
						pagination={pagination}
						loading={loading}
						filter={filter}
						onSearch={this.onSearch.bind(this)}
						updateInvoiceCodeApprovals={this.updateInvoiceCodeApprovals.bind(this)}
					/>
				</Content>
			</Layout>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setPageTitle: (value) => {
			dispatch(setPageTitle(value));
		},
		setBreadCrumb: (items) => {
			dispatch(setBreadCrumb(items));
		},
		setPageDescription: (value) => {
			dispatch(setPageDescription(value));
		},
		setMenuActive: (menu) => {
			dispatch(setMenuActive(menu));
		},
	};
};

export default connect(null, mapDispatchToProps)(translate()(InvoiceCodeApprovals));
