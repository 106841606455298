import {createSelector} from 'reselect';
import lodash from "lodash";
import moment from 'moment';
import {ORDER_STATUS, ORDER_STATUS_REAL, ORDER_OVERDUE_PURCHASE} from '../../systems/globalContant/actionContant';
import {dateFormatter, currentFormatter} from "../common/services/format";
import {STATE_FILTER_COMPLAINT_DEFAULT} from "./../ComplaintSeller/constants";
import {url} from '../../systems/routing/index';
import queryString from 'query-string';

const ordersSelector = state => lodash.get(state, 'Purchase.ListOrder.list', []);

/**
 * Get list order
 */
export const getOrders = createSelector(
    ordersSelector,
    orders => orders.map(order => orderDetail(order))
);

function orderDetail({...order}) {
    order.total_shipping_fee_inland_text = order.total_shipping_fee_inland ? currentFormatter.toLocaleStringCurrency(parseFloat(order.total_shipping_fee_inland), 10) + '¥' : '---';
    order.time_now = order.time_now ? order.time_now : moment().format('YYYY-MM-DD HH:mm:ss');
    order.time_teller_format = dateFormatter.full(order.time_teller);
    order.time_paid_staff_format = dateFormatter.full(order.time_paid_staff);
    order.order_partner_time_deposit_format = dateFormatter.shortTime(order.order_partner_time_deposit);

    if (order.status && lodash.get(order, "log_time_status_" + order.status)) {
        order.logTimeStatusCurrent = dateFormatter.shortTime(lodash.get(order, "log_time_status_" + order.status));
    }

    order.services = lodash.get(order, 'services', []).filter((service) => {
        return service.status !== 'INACTIVE';
    });

    // order previous status and time
    order.previous_status_time = '';
    order.previous_status = '';
    let orderStatusArr = lodash.values(ORDER_STATUS_REAL);
    let statusIndexOf = lodash.indexOf(orderStatusArr, order.status);

    if (orderStatusArr[statusIndexOf - 1]) {
        let previousStatus = orderStatusArr[statusIndexOf - 1];
        let logStatusTime = 'log_time_status_' + previousStatus;
        order.previous_status_time = dateFormatter.shortTime(lodash.get(order, logStatusTime));
        order.previous_status = previousStatus;
    }

    order.complaintSellerTotalFormat = order.complaintSellerTotal > 9 ? "9+" : order.complaintSellerTotal;
    let filterComplaint = {...STATE_FILTER_COMPLAINT_DEFAULT};
    filterComplaint = {...filterComplaint, ...{code: lodash.get(order, 'code', '')}};
    filterComplaint = lodash.omitBy(filterComplaint, value => value === '');
    let urlComplaintByOrder = url.to('complaint-seller') + '?_i=1&';
    order.urlComplaintByOrder = urlComplaintByOrder + queryString.stringify(filterComplaint, {arrayFormat: 'bracket'});
    let invoiceCodes = lodash.get(order, 'source_invoice', []);
    order.invoiceCodeDisplay = invoiceCodes.length > 0 ? invoiceCodes[0].invoice_code : "";
    order.countInvoiceCodeDisplay = invoiceCodes.length -1 > 9 ? "9+" : invoiceCodes.length -1;
    order.agency_alias = lodash.get(order, 'agency_alias', '').toUpperCase();
    order.note_buyer_confirm_message = function () {
        return lodash.chain(order.note_buyer_confirm).groupBy("username").map(function (offers, username) {
            let messages = lodash.map(offers, function (item) {
                if (item.type === 'qty_bought') {
                    item = {
                        ...item, ...{
                            new: currentFormatter.toLocaleStringCurrency(parseFloat(item.new)),
                            old: currentFormatter.toLocaleStringCurrency(parseFloat(item.old))
                        }
                    };
                }
                if (item.type === 'unit_price') {
                    item = {
                        ...item, ...{
                            new: '¥' + currentFormatter.toLocaleStringCurrency(parseFloat(item.new), 5),
                            old: '¥' + currentFormatter.toLocaleStringCurrency(parseFloat(item.old), 5)
                        }
                    };
                }
                return lodash.omit(item, "username");
            });

            return {
                username: username,
                messages: messages,
                timeAgo: lodash.last(messages).create_time
            };
        }).value();
    };

    let buyingPolicy = lodash.get(order, 'policies.BUYING', null);
    if (buyingPolicy) {
        let timeRemaining = lodash.get(buyingPolicy, 'time_remaining', null);
        if (timeRemaining && timeRemaining.time && buyingPolicy.is_show_policy) {
            order.buying_policy_priority = "time-ago--green";
            order.buying_policy_time = true;
            order.buying_policy_time_remaining = timeRemaining.time_end;
            if (timeRemaining.priority === 'high') {
                order.buying_policy_priority = 'time-ago--red';
            } else if (timeRemaining.priority === 'medium') {
                order.buying_policy_priority = 'time-ago--yellow';
            }

        } else if (timeRemaining && !timeRemaining.time && buyingPolicy.is_show_policy) {
            order.buying_policy_over_due = true;
            order.buying_policy_time = true;
        }
    }
    
    let internalPolicy = lodash.get(order, 'policies.INTERNAL_BUYING', null);
    if (internalPolicy) {
        const timeInternalRemaining = lodash.get(internalPolicy, 'time_remaining', null);
        if (timeInternalRemaining && timeInternalRemaining.time && internalPolicy.is_show_policy) {
            order.internal_policy_time = true;
            order.internal_policy_priority = "time-ago--orange";
            order.internal_policy_time_remaining = timeInternalRemaining.time_end;
        } else if (timeInternalRemaining && !timeInternalRemaining.time && internalPolicy.is_show_policy) {
            order.internal_policy_time = true;
            order.internal_policy_over_due = true;
        }
    }

    return order;
}

const statsSelector = state => lodash.get(state, 'Purchase.ListOrder.stats', {});

/**
 * Get stats status
 */
export const getStats = createSelector(
    statsSelector,
    items => buildStats(items)
);

let orderStatus = [
    {
        code: ORDER_STATUS.WAITING_PAI,
        total: 0,
        className: '',
        countable: true,
    }, {
        code: ORDER_STATUS.PAID,
        total: 0,
        className: '',
        countable: true,
    }, {
        code: ORDER_STATUS.BOUGHT,
        total: 0,
        className: '',
        countable: false,
    },
    {
        code: ORDER_OVERDUE_PURCHASE,
        total: 0,
        className: 'danger',
        countable: false,
    }
];

function buildStats(stats) {
    return orderStatus.map(orderStats => {
        if (orderStats.code === ORDER_STATUS.ALL) {
            let total = 0;
            orderStatus.map(object => {
                if (object.countable) {
                    total += stats[object.code] ? stats[object.code] : 0;
                }

                return null;
            });
            return {...orderStats, total: total}
        }
        return {...orderStats, total: stats[orderStats.code]}
    });
}

const accountPurchaseSelector = state => lodash.get(state.AccountPurchaser, 'list', []);

/**
 * Get list order
 */
export const getAccountPurchase = createSelector(
    accountPurchaseSelector,
    accountPurchases => buildAccountPurchase(accountPurchases)
);


function buildAccountPurchase(accountPurchases) {
    accountPurchases = lodash.filter(accountPurchases, function (accountPurchase) {
        return accountPurchase.status === "ACTIVE";
    });

    return accountPurchases.map((accountPurchase) => {
        return {
            label: accountPurchase.account,
            value: accountPurchase.id
        };
    });
}
