export const TOOL_CRAWL_INVOICE_EVENT = {
    START: 'GOBIZ_M5.CRAWL_INVOICE.START',
    FINISH: 'GOBIZ_M5.CRAWL_INVOICE.FINISH',
    CANCEL: 'GOBIZ_M5.CRAWL_INVOICE.CANCEL',
    PROCESSING: 'GOBIZ_M5.CRAWL_INVOICE.PROCESSING',
    SUCCESS: 'GOBIZ_M5.CRAWL_INVOICE.SUCCESS',
    FAILED: 'GOBIZ_M5.CRAWL_INVOICE.FAILED',
    PING: 'GOBIZ_M5.CRAWL_INVOICE.PING',
    PONG: 'GOBIZ_M5.CRAWL_INVOICE.PONG',
};

export const TOOL_CRAWL_INVOICE_ERROR = {
    INVOICE_SOURCE_NOT_SUPPORTED: 'INVOICE_SOURCE_NOT_SUPPORTED',
    UNAUTHORIZED: 'UNAUTHORIZED',
    INVOICE_NOT_FOUND: 'INVOICE_NOT_FOUND',
    CAPTCHA: 'CAPTCHA',
    UPDATE_FAILED: 'UPDATE_FAILED',
    UNKNOW: 'UNKNOW',
    ACCOUNT: 'ACCOUNT',
};

export const CRAWL_STATUS = {
    PENDING: 'PENDING',
    PROCESSING: 'PROCESSING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
};
