import React from "react";
import PropTypes from 'prop-types';
import {Switch} from "antd";
import cookies from "react-cookies";
import moment from "moment";
import lodash from "lodash";

class SwitchScreen extends React.Component {
    constructor(props) {
        super(props);
    }

    onChange = (checked, e) => {
        cookies.save(this.props.cookieName, checked, {
            path: '/',
            expires: moment().add(7, 'd').toDate()
        });
        setTimeout(() => window.location.reload(), 500);
    }

    render() {
        let {cookieName} = this.props;
        return <>
            <Switch
                size="small"
                defaultChecked={cookies.load(cookieName) === "true"}
                onChange={this.onChange}
                className="mr-1"
            /> Dùng giao diện mới
        </>;
    }
}

SwitchScreen.defaultProps = {
    cookieName: ''
}

SwitchScreen.propTypes = {
    cookieName: PropTypes.string.isRequired
}

export default SwitchScreen;