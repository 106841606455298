import {combineReducers} from 'redux';
import * as UploadConstant from './../UploadFileCSV/constants';

const list = (state = [], action) => {
    switch (action.type) {
        case UploadConstant.TRANSACTION_UPLOAD_FILE_CSV.SUCCESS:
        case UploadConstant.TRANSACTION_UPLOAD_FILE_CSV.FAILED:
        case UploadConstant.TRANSACTION_UPLOAD_FILE_CSV.REQUEST:
            return [...action.payload.fileList];
        case UploadConstant.TRANSACTION_UPLOAD_FILE_CLEAR_STATE:
            return [];
        default:
            return state;
    }
};

export default combineReducers({
    list,
});