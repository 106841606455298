import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import DropdownMenu, {DropdownItemGroup} from '@atlaskit/dropdown-menu';
import {dateFormatter} from '../../common/services/format';

class CustomPlaceHolder extends React.Component {
    hasProrperty(object, prop) {
        return object && object.hasOwnProperty(prop) && object[prop];
    }

    render() {
        let {t, input, output, type} = this.props;

        if (this.hasProrperty(input, 'code') || this.hasProrperty(input, 'buyer_username') || this.hasProrperty(input, 'id_purchase_address')
            || this.hasProrperty(input, 'id_agency')
        ) {
            let buyer_username = lodash.get(input, 'buyer_username');
            let id_purchase_address = lodash.get(input, 'id_purchase_address');
            let id_agency = lodash.get(input, 'id_agency');

            return <span>{buyer_username} {id_purchase_address}  {id_agency}</span>;
        }

        if (this.hasProrperty(input, 'complaint_seller_reason_id')
            || ( this.hasProrperty(input, 'product_return') && lodash.get(input, 'product_return', []).length)
            || ( this.hasProrperty(input, 'is_important') && lodash.get(input, 'is_important', []).length)
        ) {
            let complaint_seller_reason_id = lodash.get(input, 'complaint_seller_reason_id');
            let product_return = lodash.get(input, 'product_return');
            let is_important = lodash.get(input, 'is_important');
            return <span>{complaint_seller_reason_id} {product_return} {is_important}</span>;
        }

        if (( this.hasProrperty(output, 'seller_source') &&
                lodash.get(output, 'seller_source', []).length) ||
            ( this.hasProrperty(output, 'order_seller_source') &&
                lodash.get(output, 'order_seller_source', []).length
            ) ||
            this.hasProrperty(output, 'id_purchaser') ||
            this.hasProrperty(output, 'invoice_code') ||
            this.hasProrperty(output, 'refund_status') ||
            this.hasProrperty(output, 'order_seller_name') ||
            ( this.hasProrperty(output, 'id_teller') && lodash.get(output, 'id_teller', []).length)
        ) {
            let id_purchaser = lodash.get(output, 'id_purchaser', '');
            let refund_status = lodash.get(output, 'refund_status', '');
            let seller_source = lodash.get(output, 'seller_source', []);
            let invoice_code = lodash.get(output, 'invoice_code', '');
            let order_seller_name = lodash.get(output, 'order_seller_name', '');
            let order_seller_source = lodash.get(output, 'order_seller_source', '');

            return <span>{`${id_purchaser} ${invoice_code} ${id_purchaser && seller_source ? " - " : ""} ${seller_source} ${order_seller_source} ${order_seller_name} ${refund_status}`}</span>;
        }
        if (this.hasProrperty(input, 'grand_total_from') ||
            this.hasProrperty(input, 'grand_total_to') ||
            this.hasProrperty(input, 'total_item_link') ||
            this.hasProrperty(input, 'complaint_seller') ||
            this.hasProrperty(input, 'wrong_process')
        ) {
            let total_item_link_operation = lodash.get(input, 'total_item_link_operation', "");
            let wrong_process = lodash.get(input, 'wrong_process', "");
            wrong_process = ("" + wrong_process === "0") ? t("order:label.process_ok") :
                ("" + wrong_process === "1" ? t("order:label.process_wrong")  : "");
            let total_item_link = lodash.get(input, 'total_item_link', "");
            let grand_total_from = lodash.get(input, 'grand_total_from', "");
            let grand_total_to = lodash.get(input, 'grand_total_to', "");
            let complaint_seller = lodash.get(input, "complaint_seller", "");
            complaint_seller = complaint_seller === "ALL" ? t("order:list.has_complaint_seller") :
                (
                    complaint_seller === "NONE"
                        ? t("order:list.has_not_complaint_seller") :
                        (complaint_seller === "ACTIVE" ?
                            t("order:list.has_complaint_seller_active") : "")
                );
            return <span>{`${total_item_link && total_item_link_operation ? total_item_link_operation : ""} ${total_item_link_operation && total_item_link ? " " : ""} ${total_item_link} ${total_item_link && (grand_total_from || grand_total_to) ? " - gt: " : ""} ${grand_total_from} ${grand_total_from && grand_total_to ? " -> " : ""} ${grand_total_to} ${complaint_seller} ${wrong_process}`}</span>;
        }

        if (this.hasProrperty(input, 'log_status_from') ||
            this.hasProrperty(input, 'log_status_to') ||
            ( this.hasProrperty(input, 'time_type') && lodash.get(input, 'time_type', []).length)
        ) {
            let log_status_from = lodash.get(input, 'log_status_from') ? dateFormatter.date(lodash.get(input, 'log_status_from')) : '';
            let log_status_to = lodash.get(input, 'log_status_to') ? dateFormatter.date(lodash.get(input, 'log_status_to')) : '';
            let time_type = lodash.get(input, 'time_type', '');

            return <span>{` ${time_type} ${log_status_from} ${log_status_from && log_status_to ? " - " : ""} ${log_status_to}`}</span>;
        }

        if ((this.hasProrperty(input, 'id_processor') &&
                lodash.get(input, 'id_processor', []).length ) ||
            (this.hasProrperty(input, 'account_purchaser_id') &&
                lodash.get(input, 'account_purchaser_id', []).length )
        ) {
            let id_processor = lodash.get(input, 'id_processor', '');
            let account_purchaser_id = lodash.get(input, 'account_purchaser_id', '');

            return <span>{`${id_processor} ${account_purchaser_id}`}</span>;
        }

        return <span
            className="a-font--14">{t('order:list.filter' + (type ? "_" + type.toString().toLowerCase() : ""))}</span>;
    }
}

class DropdownButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    hiddenDropdown(i) {
        this.setState({isOpen: false});
    }

    openDropdown(i) {
        this.setState({isOpen: true});
    }

    componentDidUpdate(prevProp, prevState) {
        if (prevProp.isOpen !== this.props.isOpen) {
            this.setState({isOpen: true});
        }
    }

    render() {
        let {t, content, input, type, output, position} = this.props;

        return (
            <DropdownMenu
                position={position}
                trigger={
                    <CustomPlaceHolder t={t} input={input} output={output} type={type}/>
                }
                triggerButtonProps={{className: 'max-width-100 '}}
                triggerType="button"
                isOpen={this.state.isOpen}
            >
                <DropdownItemGroup>
                    {content(this.hiddenDropdown.bind(this), this.openDropdown.bind(this))}
                </DropdownItemGroup>
            </DropdownMenu>
        );
    }
}

DropdownButton.defaultProps = {
    label: null,
    content: () => {
    },
};

DropdownButton.propTypes = {
    label: PropTypes.any,
    content: PropTypes.func.isRequired,
};

export default translate()(DropdownButton);
