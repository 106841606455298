import {combineReducers} from 'redux';
import * as Constants from "./constants";

const global = (state = false, action) => {
    switch (action.type) {
        case Constants.FETCH_CONFIG_SYSTEM.SUCCESS:
            return action.payload.data;
        default:
            return state;
    }
};

const loading = (state= false, action) => {
    switch (action.type) {
        case Constants.UPDATE_CONFIG_SYSTEM.REQUEST:
            return true;
        case Constants.UPDATE_CONFIG_SYSTEM.SUCCESS:
        case Constants.UPDATE_CONFIG_SYSTEM.FAILED:
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    global,
    loading
});