import React from "react";
import SearchableComponent from "../../../modules/common/SearchableComponent";
import {dispatch} from "../../../systems/store";
import {setBreadCrumb, setMenuActive, setPageDescription, setPageTitle} from "../../../modules/common/actions";
import {Affix, Layout, notification} from "antd";
import {t} from "../../../systems/i18n";
import apiService from "../../../modules/Order/apiService";
import {processResponse} from "../../../systems/api";
import FormSearch from "./FormSearch";
import ListPromotions from "./ListPromotions";
import lodash from "lodash";
import "./style.scss";

const {Content} = Layout;

export default class PromotionList extends SearchableComponent {

    constructor(props) {
        super(props);

        this.defaultFilter = {
            page: 1,
            per_page: 50
        };

        this.state = {
            orderItems: [],
            pagination: {},
            loading: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.setPageInfo();
    }

    onChangeFilter(filter) {
        if (this.state.loading)
            return;

        this.fetchPromotions(filter);
    }

    fetchPromotions(filter) {

        this.setState({loading: true});
        let {t} = this.props;

        let res = apiService.fetchPromotions(filter);
        let process = processResponse(res);

        process.then(res => {
            this.setState({
                orderItems: lodash.get(res, "data.order_items", []),
                pagination: lodash.get(res, "data.pagination", {}),
                loading: false,
            });
        });

        process.catch(() => {
            notification.error({message: t('message.cant_fetch_data')});
            this.setState({loading: false});
        });
    }

    setPageInfo() {
        dispatch(setPageTitle(t('promotion:page.title')));
        dispatch(setPageDescription(t('promotion:page.description')));
        dispatch(setMenuActive('payment_requests'));
        dispatch(setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'promotion:breadcrumb.list',
                'link': '/promotions'
            },
        ]));
    }

    onSearch(filter) {
        if (this.state.loading)
            return;

        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    }

    render() {
        let {
            orderItems,
            pagination,
            loading
        } = this.state;

        let filter = this.getFilter();

        return (
            <Layout>
                <Content className="a-content--ant-layout new-list-orders">
                    <div className="a-list">
                        <Affix>
                            <div className="a-list__top a-flex justify-content-between align-item-center">
                                <h2 className="a-list--title a-text--uppercase">Danh sách sản phẩm khuyến mại ({!lodash.isEmpty(pagination) ? pagination.total : 0})</h2>
                                <FormSearch
                                    input={filter}
                                    inputVersion={filter.i || 0}
                                    loading={this.state.loading}
                                    onSearch={this.onSearch.bind(this)}
                                />
                            </div>
                        </Affix>

                        <ListPromotions
                            orderItems={orderItems}
                            pagination={pagination}
                            loading={loading}
                        />
                    </div>
                </Content>
            </Layout>
        );
    }

}
