import React, {Component} from 'react';
import {translate} from "react-i18next";
import apiService from "../apiService";
import {processResponse} from "../../../systems/api";
import {Button, Row, Col, Icon, Modal, Radio} from "antd";
import lodash from "lodash"
import notification from '../../../systems/notification'
import SelectAccountPurchasers from "../../AccountPurchaser/SelectAccountPurchasers";
import SelectAccountBanks from "../../BankAccount/SelectAccountBanks";
import "./style.scss";

class ChangeAccountBankPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            data: this.props.request,
            loading: false
        };
    }
    componentDidMount() {
        this.getBankPaymentState(false);
    }

    getBankPaymentState = (update = true) => {
        let {data, payment_type} = this.state;
        let id_bank_payment = undefined;
        let bank_payment_name = undefined;
        id_bank_payment = lodash.get(data, "id_bank_payment", undefined);
        bank_payment_name = lodash.get(data, "paymentAccountBank.account_name", undefined);
        let id_account_purchaser_payment = lodash.get(data, "id_account_purchaser_payment", undefined);
        let payment_type_of_required = lodash.get(data, "payment_type", undefined);
        if (id_bank_payment)
        {
            bank_payment_name = lodash.get(data, "paymentAccountBank.account_name", undefined);
            payment_type_of_required = "bank";
        }
        if (id_account_purchaser_payment)
        {
            id_bank_payment = id_account_purchaser_payment;
            bank_payment_name = lodash.get(data, "paymentAccountPurchaser.account", undefined);
            payment_type_of_required = "alipay";
        }
        if (payment_type_of_required === "" && !bank_payment_name) {
            bank_payment_name = lodash.get(data, "account_payment_tool", undefined);
        }

        if (update && payment_type !== payment_type_of_required){
            id_bank_payment = undefined;
            bank_payment_name = undefined;
        }else{
            payment_type = payment_type_of_required;
        }

        this.setState( (state) => ({
            ...state,
            payment_type,
            id_bank_payment,
            bank_payment_name,
            error_message: undefined
        }))
    };

    handleOnClickEditBankPayment = () => {
        this.setState({ edit: true })
    };

    onChange = (value) => {
        this.setState({
            error_message: undefined,
            id_bank_payment: value
        })
    };

    handleCancel = () => {
        this.setState({edit: false});
        this.getBankPaymentState(false);
    };
    handleOk = () => {
        this.setState({
            loading: true
        })
        let {t} = this.props;
        const {payment_type, id_bank_payment, data: {id}} = this.state;
        if (!id_bank_payment) {
            this.setState({error_message: t(`paymentRequest:error.id_bank_payment_required`)});
            return;
        }
        if (!payment_type) {
            this.setState({
                error_message: t(`paymentRequest:error.payment_type_required`)
            });
            return;
        }
        let res = apiService.updateBankPaymentRequest(id, {payment_type, id_bank_payment});
        let process = processResponse(res);
        process.then(res => {
            const data = lodash.get(res, 'data');
            this.setState({
                data
            }, () => {
                this.getBankPaymentState();
            });
            notification.success(t("message.update_account_bank_success"));
        }).catch(error => {
            this.getBankPaymentState(false);
            notification.error(t('message.cant_update_data'));
        }).finally(() => {
            this.setState({
                edit: false,
                loading: false
            })
        })
    };

    changePaymentType(event) {
        let payment_type = event.target.value;
        this.setState({
            payment_type,
            error_message: undefined
        }, () => {
            this.getBankPaymentState();
        });
    }

    render() {
        const {t} = this.props;
        const {
            edit,
            bank_payment_name,
            id_bank_payment,
            payment_type,
            data: {canUpdateBankPayment, account_payment_tool},
            error_message,
            loading
        } = this.state;
        let editInfo = "";
        if (canUpdateBankPayment){
            if (payment_type){
                editInfo = <a onClick={this.handleOnClickEditBankPayment} className="ml-1"><Icon type="edit"/></a>;
            }else{
                if (!account_payment_tool){
                    editInfo = <a onClick={this.handleOnClickEditBankPayment} className="ml-1"><Icon type="edit"/></a>;
                }
            }
        }
        return (
                <div>
                    <span>{bank_payment_name}</span>{editInfo}
                    <Modal
                        title={t(`paymentRequest:modal.title.update_bank_payment_${payment_type}`)}
                        visible={edit}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        okButtonProps={{loading}}
                    >
                        <Row className="mb-3" gutter={24}>
                            <Radio.Group value={payment_type} onChange={(e) => this.changePaymentType(e)}>
                                <Col span={12}>
                                    <Button className="btn-block">
                                        <Radio value={'bank'}>
                                            {t('paymentRequest:btn.via_bank')}
                                        </Radio>
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button className="btn-block">
                                        <Radio value={'alipay'}>
                                            {t('paymentRequest:btn.via_alipay')}
                                        </Radio>
                                    </Button>
                                </Col>
                            </Radio.Group>
                        </Row>

                        {
                            payment_type === 'bank' ?
                                <SelectAccountBanks
                                    value={id_bank_payment || undefined}
                                    placeholder={t('paymentRequest:placeholder.id_bank_payment_bank')}
                                    multiple={false}
                                    onChange={(value) => this.onChange(value)}
                                    filter={{scope: "basic"}}
                                    className="select-account-banks-payment"
                                /> :
                                <SelectAccountPurchasers
                                    value={id_bank_payment || undefined}
                                    placeholder={t('paymentRequest:placeholder.id_bank_payment_alipay')}
                                    multiple={false}
                                    onChange={(value) => this.onChange(value)}
                                    className="select-account-banks-payment"
                                />
                        }
                        {
                            error_message && <div className={"a--error"}><span>{error_message}</span></div>
                        }
                    </Modal>
                </div>
        );
    }

}

export default translate()(ChangeAccountBankPayment)
