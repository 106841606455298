import { call, put } from 'redux-saga/effects'
import lodash from 'lodash'
import * as Constants from './constants'
import * as ConstantsLog from './../../common/Log/constants'
import * as GlobalConstants from '../../../systems/globalContant'
import * as actionContant from '../../../systems/globalContant/actionContant'
import service from './apiService'
import bootbox from './../../common/components/Bootbox/bootbox'
import { t } from './../../../systems/i18n/index.js'
import { FETCH_LOGS } from '../../common/Log/constants'
import { fetchLogs } from '../../common/Log/actions'
import { PAYMENT_ORDER_ERROR } from '../PaymentOrder/constants'
import { currentFormatter } from './../../common/services/format'
import { fetchOrderGroup } from './../../OrderGroupManager/ListOrderGroup/actions'
import { fetchServices } from './../../Services/actions'
import { AUTH } from '../../../systems/globalContant'
import { fetchFeatures } from '../../Features/actions'
import { FETCH_PURCHASING_ORDER_DETAIL, UPDATE_PRICE_ITEMS } from './constants'

export function* getPurchaserOrderDetail(action) {
    try {
        let response = yield call(service.getPurchasingOrderDetailAction, action.payload)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            const servicesWarning = lodash.get(action, 'payload.data.order.servicesWarning', {})
            const propertiesWarning = lodash.get(action, 'payload.data.order.propertiesWarning', {})
            const isOpenUpdateBatchOrder = lodash.get(action, 'payload.isOpenUpdateBatchOrder', false)
            yield put({
                type: Constants.FETCH_PURCHASING_ORDER_DETAIL.SUCCESS,
                payload: { ...response.data, servicesWarning, propertiesWarning, isOpenUpdateBatchOrder},
            })
            yield put({ type: actionContant.GET_ERROR_404.CLEAR, payload: {} })
        } else {
            if (lodash.get(response, 'data.data.id', [])) {
                let keyError = Object.keys(lodash.get(response, 'data.data.id', []))
                if (keyError && keyError[0] === 'NOT_EXIST') {
                    yield put({ type: actionContant.GET_ERROR_404.REQUEST, payload: {} })
                }
            }
            yield put({ type: Constants.FETCH_PURCHASING_ORDER_DETAIL.FAILED, payload: response.data })
            bootbox.error(t('message.system_errors'))
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.FETCH_PURCHASING_ORDER_DETAIL.FAILED, payload: {} })
    }
}

export function* getPurchaserOrderDetailAfterUpdateItem(action) {
    if (lodash.get(action, 'payload.data.first_order_paid', false) === true) {
        let filters = { payload: { id: action.payload.data.order.id } }
        yield getPurchaserOrderDetail(filters)
    }
}

export function* updatePaidOrderDetail(action) {
    try {
        let response = yield call(service.updatePaidOrderDetail, action.payload.params)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({ type: Constants.UPDATE_PAID_ORDER_DETAIL.SUCCESS, payload: response.data })
        } else {
            yield put({ type: Constants.UPDATE_PAID_ORDER_DETAIL.FAILED, payload: response.data })
            bootbox.error(t('message.system_errors'))
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.UPDATE_PAID_ORDER_DETAIL.FAILED, payload: action.payload.params })
    }
}

export function* updateOrderInvoiceCode(action) {
    try {
        let response = yield call(service.updateOrderInfo, action.payload)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({ type: Constants.UPDATE_ORDER_INVOICE_CODE.SUCCESS, payload: response.data })
        } else {
            yield put({ type: Constants.UPDATE_ORDER_INVOICE_CODE.FAILED, payload: response.data })
            bootbox.error(t('message.system_errors'))
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.UPDATE_ORDER_INVOICE_CODE.FAILED, payload: action.payload.params })
    }
}

/**
 * Order update shipping fee
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* updateShippingFee(action) {
    try {
        const params = lodash.omit(action.payload.params, ['status'])
        let response = yield call(service.updateShippingFee, params)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({ type: Constants.UPDATE_SHIPPING_FEE.SUCCESS, payload: { ...response.data, isFetchLog: true } })
            bootbox.success(t('order_detail.shipping_fee.success'))
        } else {
            if (response.data.code !== GlobalConstants.GLOBAL_CONTANT.PERMISSION_DENIED) {
                yield put({ type: Constants.UPDATE_SHIPPING_FEE.FAILED, payload: response.data })
                let errors = response.data.data

                if (response.data.code === 'REDUCE_SHIPPING_FEE' && errors === 'Unauthorized') {
                    const status = lodash.get(action, 'payload.params.status', '')
                    bootbox.error(
                        t(`order:detail.shipping_fee.REDUCE_SHIPPING_FEE.authorization`, { order_status: status ? t('order:status.' + status) : 'hiện tại' })
                    )
                    return
                }
                Object.keys(errors).map(key => {
                    Object.keys(errors[key]).map(error => {
                        if (key === 'total_shipping_fee_inland') {
                            let numeric = lodash.get(errors[key], 'max.numeric', {})
                            numeric = lodash.get(numeric, 'max', 0)
                            numeric = lodash.toInteger(numeric)
                            bootbox.error(
                                t(`order:detail.shipping_fee.${key}.${error}`, {
                                    name: t('order:detail.total_shipping_fee_inland'),
                                    number: currentFormatter.toLocaleStringCurrency(numeric),
                                })
                            )
                        } else {
                            bootbox.error(t(`order:detail.shipping_fee.${key}.${error}`))
                        }
                        return error
                    })
                    return key
                })
            }
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.UPDATE_SHIPPING_FEE.FAILED, payload: action.payload.params })
    }
}

export function* postInvoiceCode(action) {
    try {
        let response = yield call(service.postInvoiceCode, action.payload)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({ type: Constants.POST_INVOICE_CODE.SUCCESS, payload: { ...response.data, isFetchLog: true } })
            bootbox.success(t('order_detail.message.post_invoice_code_success') + ' : ' + action.payload.invoice_code)
        } else {
            yield put({ type: Constants.POST_INVOICE_CODE.FAILED, payload: response.data })
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.POST_INVOICE_CODE.ERROR, payload: action.payload.params })
    }
}

export function* deleteInvoiceCode(action) {
    try {
        let response = yield call(service.deleteInvoiceCode, action.payload)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({ type: Constants.DELETE_INVOICE_CODE.SUCCESS, payload: { ...response.data, isFetchLog: true } })
            bootbox.success(t('order_detail.message.delete_invoice_code_success') + ' : ' + action.payload.invoice_code)
        } else {
            yield put({ type: Constants.DELETE_INVOICE_CODE.FAILED, payload: response.data })
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.DELETE_INVOICE_CODE.ERROR, payload: action.payload.params })
    }
}

export function* paymentOrder(action) {
    try {
        if (action.payload.id) {
            let response = yield call(service.paymentOrder, action.payload)
            if (!response) {
                return
            }
            if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({
                    type: Constants.PAYMENT_ORDER.SUCCESS,
                    payload: { id: action.payload.id, ...response.data, isFetchLog: true },
                })
                bootbox.success(t('order:detail.payment_order_success'))
            } else {
                yield put({ type: Constants.PAYMENT_ORDER.FAILED, payload: response.data })
            }
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.PAYMENT_ORDER.FAILED, payload: action.payload.params })
    }
}

export function* updateOrderGroupOrder(action) {
    try {
        if (action.payload.orderId) {
            let response = yield call(service.updateOrderGroupOrder, action.payload)
            if (!response) {
                return
            }
            if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({
                    type: Constants.UPDATE_ORDER_GROUP_ORDER.SUCCESS,
                    payload: { ...response.data, isFetchLog: true },
                })
                bootbox.success(t('order:detail.update_order_success', { key: t('order:detail.order_group') }))
            } else {
                yield put({ type: Constants.UPDATE_ORDER_GROUP_ORDER.FAILED, payload: response.data })
                let errors = lodash.get(response, 'data.data', {})
                Object.keys(errors).map(error => {
                    Object.keys(errors[error]).map(key => {
                        bootbox.error(t(`order:detail.order_group_message.${error}.${key}`))
                        return key
                    })

                    return error
                })
            }
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.UPDATE_ORDER_GROUP_ORDER.FAILED, payload: action.payload.params })
    }
}

export function* recallDetail(action) {
    if (action.payload.id) {
        yield put({ type: Constants.FETCH_PURCHASING_ORDER_DETAIL.REQUEST, payload: action.payload })
    }

    yield fetchLog(action)
}

export function* updatePaidOrderStatus(action) {
    try {
        if (action.payload.id) {
            let response = yield call(service.updatePaidOrderStatus, action.payload)
            if (!response) {
                return
            }
            if (
                typeof response !== 'undefined' &&
                (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE || response.data.code === 'servicesWarning')
            ) {
                yield put({
                    type: Constants.UPDATE_PAID_ORDER_STATUS.SUCCESS,
                    payload: { id: action.payload.id, isFetchLog: true, ...response.data },
                })
                bootbox.success(t('order:detail.paid_order_success'))
            } else {
                yield put({ type: Constants.UPDATE_PAID_ORDER_STATUS.FAILED, payload: response.data })
            }
        }
    } catch (err) {
        bootbox.error(t('messages.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.UPDATE_PAID_ORDER_STATUS.FAILED, payload: action.payload })
    }
}

export function* cancelOrder(action) {
    try {
        if (action.payload.id) {
            let response = yield call(service.cancelOrder, action.payload)
            if (!response) {
                return
            }
            if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({
                    type: Constants.CANCEL_ORDER.SUCCESS,
                    payload: { id: action.payload.id, isFetchLog: true, ...response.data },
                })
                bootbox.success(t('orderDetail:order_detail.cancel_order_success'))
            } else if (response.data.code === 'PAYMENT_REQUEST_EXIST') {
                yield put({ type: Constants.CANCEL_ORDER.FAILED, payload: response.data })
                bootbox.error(t('orderDetail:order_detail.cancel_order_payment_request_exist'))
            } else if (response.data.code !== 201) {
                yield put({ type: Constants.CANCEL_ORDER.FAILED, payload: response.data })

                if (response.data.code === 'INPUT_INVALID') {
                    bootbox.error(t(`orderDetail:message.payment_request_processing_cancel_order`))
                } else {
                    bootbox.error(t('orderDetail:order_detail.cancel_order_fail'))
                }
            } else {
                yield put({ type: Constants.CANCEL_ORDER.FAILED, payload: response.data })
            }
        }
    } catch (err) {
        bootbox.error(t('messages.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.CANCEL_ORDER.FAILED, payload: action.payload })
    }
}

export function* outOfStocklOrder(action) {
    try {
        if (action.payload.id) {
            let response = yield call(service.outOfStockOrder, action.payload)
            if (!response) {
                return
            }
            if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({
                    type: Constants.OUT_OF_STOCK_ORDER.SUCCESS,
                    payload: { id: action.payload.id, isFetchLog: true, ...response.data },
                })
                bootbox.success(t('orderDetail:order_detail.out_of_stock_order_success'))
            } else if (response.data.code !== 201) {
                yield put({ type: Constants.OUT_OF_STOCK_ORDER.FAILED, payload: response.data })
                if (response.data.code === 'INPUT_INVALID') {
                    bootbox.error(t(`orderDetail:message.payment_request_processing_out_of_stock`))
                } else {
                    bootbox.error(t('orderDetail:order_detail.out_of_stock_order_fail'))
                }
            } else {
                yield put({ type: Constants.OUT_OF_STOCK_ORDER.FAILED, payload: response.data })
                bootbox.error(t('orderDetail:order_detail.out_of_stock_order_fail'))
            }
        }
    } catch (err) {
        bootbox.error(t('messages.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.OUT_OF_STOCK_ORDER.FAILED, payload: action.payload })
    }
}

/* eslint-disable */
export function* recallOnConfirm(action) {
    let code = lodash.get(action.payload, 'code', '')
    let data = lodash.get(action.payload, 'data', false)
    let status = lodash.get(action.payload, 'data.order_status.INVALID', false)
    // Nếu là lỗi đơn vi phạm chính sách thì không hiển thị error message
    if (
        code === AUTH.UNAUTHORIZED ||
        code === PAYMENT_ORDER_ERROR.ORDER_POLICIES ||
        lodash.get(action.payload, 'data.error.code', '').toUpperCase() === PAYMENT_ORDER_ERROR.ORDER_CONFIRM_MONEY
    ) {
        return
    }

    if (code === PAYMENT_ORDER_ERROR.NOT_MATCH_STATUS) {
        bootbox.error(t('order:detail.not_match_status'))
        return
    }

    if (status) {
        let key = 'status'
        let message = t('order:detail.' + key)
        bootbox.error(message)
    } else {
        Object.keys(data).map(key => {
            switch (key) {
                case 'SERVICE_GROUP_FORCE':
                    bootbox.error(t('order:detail.service_group_force', { key: data[key] }))
                    break
                case 'SERVICE_NOT_CONFIRM':
                    Object.keys(data[key]).map(error => {
                        bootbox.error(t('order:detail.service_not_confirm', { key: error }))
                        return error
                    })
                    break
                case 'UNAUTHORIZED':
                    bootbox.error(t('error_page.permission_not_allow'))
                    break
                case 'PROPERTY_REQUIRED':
                case 'PROPERTY_GROUP_FORCE':
                    const list_key = lodash.keys(data[key]).join(', ')
                    bootbox.error(t('order_error.features.' + key, { key: list_key }))
                    break
                case 'PROPERTY_GROUP_GREATER':
                    bootbox.error(t('order:detail.property_group_greater'))
                    break
                case 'order':
                    bootbox.error(t('order:detail.order'))
                    break
                default:
                    bootbox.error(t('order:detail.input_invalid', { key: t('order:detail.' + key) }))
            }
            return key
        })
    }
}

export function* recallOnConfirmPaid(action) {
    let code = lodash.get(action, 'payload.code')
    let data = lodash.get(action, 'payload.data', false)
    if (code && code !== 201) {
        if (code === 'INPUT_INVALID') {
            if (data) {
                Object.keys(data).map(key => {
                    switch (key) {
                        case 'PROPERTY_REQUIRED':
                        case 'PROPERTY_GROUP_FORCE':
                            const list_key = lodash.keys(data[key]).join(', ')
                            bootbox.error(t('order_error.features.' + key, { key: list_key }))
                            break
                        default:
                            bootbox.error(t('order:detail.input_invalid', { key: t('order:detail.' + key) }))
                    }
                })
            } else {
                bootbox.error(t('order:detail.input_invalid'))
            }
        } else {
            bootbox.error(t('order:detail.paid_action.' + code))
        }
    }
}

/* eslint-enable */

/**
 * Update order
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* updateOrderAccountPurchaser(action) {
    try {
        let response = yield call(service.updateOrderAccountPurchaser, action.payload.params)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({
                type: Constants.UPDATE_ORDER_ACCOUNT_PURCHASER.SUCCESS,
                payload: { ...response.data, isFetchLog: true },
            })
            bootbox.success(t('order:detail.update_order_success', { key: t('order:detail.id_purchaser') }))
        } else {
            let errors = response.data.data
            Object.keys(errors).map(key => bootbox.error(t('order_error.account_purchaser.' + key)))
            yield put({ type: Constants.UPDATE_ORDER_ACCOUNT_PURCHASER.FAILED, payload: response.data })
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.UPDATE_ORDER_ACCOUNT_PURCHASER.FAILED, payload: {} })
    }
}

export function* changeMutipleQuantity(action) {
    try {
        let response = yield call(service.changeMutipleQuantity, action.payload.params)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({ type: Constants.CHANGE_MUTIPLE_QUANTITY.SUCCESS, payload: response.data })
            bootbox.success(t('orderDetail:order_detail.update_quantity_success'))
            yield put({ type: Constants.PURCHASER_UPDATE_CHANGE_CLEAR, payload: {} })
        } else {
            let errorItem = lodash.get(response, 'data.data', {})
            if (!lodash.isEmpty(errorItem)) {
                if (Object.keys(errorItem)[0] === 'qty_bought') {
                    let objectmaxNumberQuantity = lodash.get(errorItem, 'qty_bought', {})
                    objectmaxNumberQuantity = lodash.get(objectmaxNumberQuantity, 'max.numeric', 0)
                    let overLengthNumberQuantity = lodash.get(objectmaxNumberQuantity, 'max', 0)
                    overLengthNumberQuantity = lodash.toInteger(overLengthNumberQuantity)
                    overLengthNumberQuantity = currentFormatter.toLocaleStringCurrency(overLengthNumberQuantity)
                    bootbox.error(
                        t('orderDetail:order_detail.error.max_numeric', {
                            name: t('orderDetail:order_detail.qty_bought'),
                            value: overLengthNumberQuantity,
                        })
                    )
                }

                if (Object.keys(errorItem)[0] === 'payment_request_processing') {
                    bootbox.error(t(`orderDetail:message.payment_request_processing_out_of_stock`))
                }
            }
            yield put({ type: Constants.CHANGE_MUTIPLE_QUANTITY.FAILED, payload: response.data })
        }
    } catch (err) {
        bootbox.error(t('messages.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.CHANGE_MUTIPLE_QUANTITY.FAILED, payload: action.payload.params })
    }
}

export function* confirmCustomerOrderBuying(action) {
    try {
        let response = yield call(service.confirmCustomerOrderBuying, action.payload.params)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({ type: Constants.CONFIRM_CUSTOMER_ORDER_BUYING.SUCCESS, payload: response.data })
            bootbox.success(t('orderDetail:order_detail.confirm_success'))
        } else {
            if (response.data.code === 'WARNING') {
                yield put({ type: Constants.CONFIRM_CUSTOMER_ORDER_BUYING.WARNING, payload: action.payload.params })
                bootbox.warning(t('orderDetail:order_detail.confirm_duplicate'))
            } else if (response.data.code !== 201) {
                yield put({ type: Constants.CONFIRM_CUSTOMER_ORDER_BUYING.FAILED, payload: response.data })
                bootbox.error(t('orderDetail:order_detail.confirm_failed'))
            }
        }
    } catch (err) {
        bootbox.error(t('messages.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.CONFIRM_CUSTOMER_ORDER_BUYING.FAILED, payload: action.payload.params })
    }
}

export function* updateOrderItemPrice(action) {
    try {
        let response = yield call(service.updateOrderItemPrice, action.payload.params)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({ type: Constants.UPDATE_ORDER_ITEM_PRICE.SUCCESS, payload: { ...response.data, isFetchLog: true } })
            yield put({ type: Constants.FETCH_PURCHASING_ORDER_DETAIL.REQUEST, payload: { id: lodash.get(response, 'data.data.id_order') } })
            bootbox.success(t('orderDetail:order_detail.update_price_success'))
        } else {
            yield put({ type: Constants.UPDATE_ORDER_ITEM_PRICE.FAILED, payload: response.data })
        }
    } catch (err) {
        bootbox.error(t('messages.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.UPDATE_ORDER_ITEM_PRICE.FAILED, payload: {} })
    }
}

export function* handleReControlChangeQuantitySuccess(action) {
    yield put({
        type: ConstantsLog.FETCH_LOGS.REQUEST,
        payload: {
            object: 'order',
            object_id: lodash.get(action, 'payload.data.id_order', null),
            filter: {},
        },
    })
}

/**
 * Update order
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* updateOrderServiceSaga(action) {
    try {
        let params = action.payload.params
        let response = yield call(service.updateOrderService, params)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            let orderId = lodash.get(params, 'id', 0)
            yield put({ type: Constants.UPDATE_ORDER_SERVICE.SUCCESS, payload: response.data })
            if (orderId) {
                yield put({
                    type: FETCH_LOGS.REQUEST,
                    payload: {
                        object: 'orders',
                        object_id: orderId,
                    },
                })
            }
            bootbox.success(t('orderDetail:order_detail.update_service_success'))
        } else {
            let errors = response.data.data
            Object.keys(errors).map(key => {
                Object.keys(errors[key]).map(error => {
                    if (key === 'service_id' && error === 'excluded_services') {
                        let objectError = errors[key]
                        bootbox.error(
                            t('service:message.wrong_services', {
                                service: objectError[error][0],
                            })
                        )
                    } else {
                        bootbox.error(
                            t('order_error.services.' + key, {
                                group: error,
                            })
                        )
                    }

                    return error
                })

                return key
            })
            yield put({ type: Constants.UPDATE_ORDER_SERVICE.FAILED, payload: response.data })
        }
    } catch (err) {
        bootbox.error(t('messages.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.UPDATE_ORDER_SERVICE.FAILED, payload: {} })
    }
}

/**
 * Approval order service
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* approvalOrderServiceSaga(action) {
    try {
        let params = action.payload.params
        let response = yield call(service.approvalOrderService, params)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            let orderId = lodash.get(params, 'id', 0)
            yield put({ type: Constants.APPROVAL_ORDER_SERVICE.SUCCESS, payload: response.data })
            if (orderId) {
                yield put({
                    type: FETCH_LOGS.REQUEST,
                    payload: {
                        object: 'orders',
                        object_id: orderId,
                    },
                })
            }
            bootbox.success(t('orderDetail:order_detail.approval_service_success'))
        } else {
            let errors = response.data.data
            Object.keys(errors).map(key => bootbox.error(t('order_error.services.' + key)))
            yield put({ type: Constants.APPROVAL_ORDER_SERVICE.FAILED, payload: response.data })
        }
    } catch (err) {
        bootbox.error(t('messages.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.APPROVAL_ORDER_SERVICE.FAILED, payload: {} })
    }
}

export function* fetchLog(action) {
    let orderId = lodash.get(action, 'payload.data.order.id')
    orderId = orderId || lodash.get(action, 'payload.data.orderSourceInvoice.id_order')

    let shouldFetchLogs = lodash.get(action, 'payload.isFetchLog')

    if (orderId && shouldFetchLogs) {
        yield put(fetchLogs('order', orderId, {}))
    }
}

export function* changeOutOfStockItems(action) {
    try {
        let response = yield call(service.changeOutOfStockItems, action.payload.params)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({
                type: Constants.UPDATE_OUT_STOCK_ITEMS.SUCCESS,
                payload: {
                    ...action.payload.params,
                    order: response.data.data,
                    isFetchLog: true,
                },
            })
            bootbox.success(t('orderDetail:order_detail.out_of_stock_success'))
        } else {
            yield put({ type: Constants.UPDATE_OUT_STOCK_ITEMS.FAILED, payload: response.data })
            yield put({ type: Constants.IS_CLEAR_ERROR_PROCESS_UPDATE_OUT_STOCK_ITEMS, payload: {} })
        }
    } catch (err) {
        bootbox.error(t('messages.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.UPDATE_OUT_STOCK_ITEMS.FAILED, payload: { data: { error: err.message } } })
        yield put({ type: Constants.IS_CLEAR_ERROR_PROCESS_UPDATE_OUT_STOCK_ITEMS, payload: {} })
    }
}

export function* updatePriceItems(action) {
    try {
        let response = yield call(service.updatePriceItems, action.payload.params)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({
                type: Constants.UPDATE_PRICE_ITEMS.SUCCESS,
                payload: {
                    ...action.payload.params,
                    order: response.data.data,
                    isFetchLog: true,
                },
            })
            console.log('response', response)

            yield put({ type: Constants.FETCH_PURCHASING_ORDER_DETAIL.REQUEST, payload: { id: response.data.data.id } })

            bootbox.success(t('orderDetail:order_detail.update_price_success'))
            yield put({ type: Constants.IS_AFTER_SUCCESS_UPDATE_PRICE_ITEMS, payload: {} })
        } else {
            yield put({ type: Constants.UPDATE_PRICE_ITEMS.FAILED, payload: response.data })
        }
    } catch (err) {
        bootbox.error(t('messages.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.UPDATE_PRICE_ITEMS.FAILED, payload: { data: { error: err.message } } })
    }
}

export function* updateQuantityItems(action) {
    try {
        let response = yield call(service.updateQuantityItems, action.payload.params)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({
                type: Constants.UPDATE_QUANTITY_ITEMS.SUCCESS,
                payload: {
                    ...action.payload.params,
                    order: response.data.data,
                    isFetchLog: true,
                },
            })
            bootbox.success(t('orderDetail:order_detail.update_quantity_success'))
            yield put({ type: Constants.IS_AFTER_SUCCESS_UPDATE_QUANTITY_ITEMS, payload: {} })
        } else {
            yield put({ type: Constants.UPDATE_QUANTITY_ITEMS.FAILED, payload: response.data })
        }
    } catch (err) {
        bootbox.error(t('messages.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.UPDATE_QUANTITY_ITEMS.FAILED, payload: { data: { error: err.message } } })
    }
}

/**
 * Add freight bill
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* addFreightBill(action) {
    try {
        let response = yield call(service.addFreightBill, action.payload)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({ type: Constants.ADD_FREIGHT_BILL.SUCCESS, payload: { ...response.data, isFetchLog: true } })
            bootbox.success(t('orderDetail:order_detail.freight_bill_add_success') + ' : ' + action.payload.freight_bill)
        } else {
            yield put({ type: Constants.ADD_FREIGHT_BILL.FAILED, payload: response.data })
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.ADD_FREIGHT_BILL.ERROR, payload: action.payload.params })
    }
}

/**
 * Delete freight bill
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* deleteFreightBill(action) {
    try {
        let response = yield call(service.deleteFreightBill, action.payload)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({ type: Constants.DELETE_FREIGHT_BILL.SUCCESS, payload: { ...response.data, isFetchLog: true } })
            let freight_bill = lodash.get(response, 'data.data.orderFreightBill.freight_bill', null)
            bootbox.success(t('orderDetail:order_detail.freight_bill_remove_success') + ' : ' + freight_bill)
        } else {
            yield put({ type: Constants.DELETE_FREIGHT_BILL.FAILED, payload: response.data })
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.DELETE_FREIGHT_BILL.ERROR, payload: action.payload.params })
    }
}

/**
 * Lấy danh sách các phí vc nội địa của các đơn liên quan
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getShippingFeeHistory(action) {
    try {
        let response = yield call(service.getShippingFeeHistory, action.payload)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({
                type: Constants.GET_SHIPPING_FEE_HISTORY.SUCCESS,
                payload: lodash.get(response, 'data.data.shippingFeeHistoryOfSeller', []),
            })
        } else {
            bootbox.success(t('message.system_errors') + ' : ' + response.data)
            yield put({ type: Constants.GET_SHIPPING_FEE_HISTORY.FAILED, payload: { ...response.data } })
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.GET_SHIPPING_FEE_HISTORY.ERROR, payload: action.payload.params })
    }
}

/**
 * Delete freight bill
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* changeRateDeposit(action) {
    try {
        let response = yield call(service.changeRateDeposit, action.payload)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({ type: Constants.CHANGE_RATE_DEPOSIT.SUCCESS, payload: { ...response.data, isFetchLog: true } })
            bootbox.success(t('orderDetail:order_detail.rate_deposit_message.success'))
        } else {
            let errors = response.data.data
            Object.keys(errors).map(key => {
                Object.keys(errors[key]).map(error =>
                    bootbox.error(
                        t('orderDetail:order_detail.rate_deposit_message.errors.' + error, {
                            value: errors[key][error]['max'] || errors[key][error]['min'],
                        })
                    )
                )
                return key
            })
            yield put({ type: Constants.CHANGE_RATE_DEPOSIT.FAILED, payload: response.data })
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.CHANGE_RATE_DEPOSIT.ERROR, payload: action.payload.params })
    }
}

/**
 * Sync order bill no
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* syncOrderBillNo(action) {
    try {
        let response = yield call(service.syncOrderBillNo, action.payload)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({ type: Constants.SYNC_ORDER_BILL_NO.SUCCESS, payload: response.data, request: action.payload })
            let errors = lodash.get(response, 'data.data.errors', [])

            Object.keys(errors).map(key => {
                Object.keys(errors[key]).map(error => {
                    let message = errors[key][error]
                    if (Object.keys(message).length > 0) {
                        Object.keys(message).map(msKey =>
                            bootbox.error(
                                t('orderDetail:order_detail.sync_order_bill_no.errors.' + msKey, {
                                    value: message[msKey],
                                })
                            )
                        )
                    } else {
                        bootbox.error(t('orderDetail:order_detail.sync_order_bill_no.errors.' + error))
                    }
                })
            })
            // bootbox.success(t("orderDetail:order_detail.sync_order_bill_no.success"));
        } else {
            yield put({ type: Constants.SYNC_ORDER_BILL_NO.FAILED, payload: response.data })
            let errors = lodash.get(response, 'data.data.errors', [])

            Object.keys(errors).map(key => {
                Object.keys(errors[key]).map(error => {
                    let message = errors[key][error]
                    if (Object.keys(message).length > 0) {
                        Object.keys(message).map(msKey =>
                            bootbox.error(
                                t('orderDetail:order_detail.sync_order_bill_no.errors.' + msKey, {
                                    value: message[msKey],
                                })
                            )
                        )
                    } else {
                        bootbox.error(t('orderDetail:order_detail.sync_order_bill_no.errors.' + error))
                    }
                })
            })
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.SYNC_ORDER_BILL_NO.ERROR, payload: action.payload.params })
    }
}

export function* fetchOrderGroups(action) {
    let order = lodash.get(action, 'payload.data.orders')
    yield put(
        fetchOrderGroup({
            status: actionContant.OPTION_INPUT_API.ON,
            scope: 'basic',
            id_agency: lodash.get(order, 'id_agency', 0),
            getByAgencyOrPartner: true,
        })
    )
}

export function* fetchServiceGroups(action) {
    let order = lodash.get(action, 'payload.data.orders')
    yield put(
        fetchServices({
            status: actionContant.OPTION_INPUT_API.ON,
            scope: 'basic',
            id_agency: lodash.get(order, 'id_agency', 0),
            getByAgencyOrPartner: true,
        })
    )
}

export function* waitingPaymentOrder(action) {
    try {
        if (action.payload.id) {
            let response = yield call(service.waitingPaymentOrder, action.payload)
            if (!response) {
                return
            }
            if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({
                    type: Constants.WAITING_PAYMENT_ORDER.SUCCESS,
                    payload: { id: action.payload.id, ...response.data, isFetchLog: true },
                })
                bootbox.success(t('order:detail.waiting_payment_order_success'))
            } else {
                yield put({ type: Constants.WAITING_PAYMENT_ORDER.FAILED, payload: response.data })
            }
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.WAITING_PAYMENT_ORDER.FAILED, payload: action.payload.params })
    }
}

export function* fetchFeaturesGroups(action) {
    let order = lodash.get(action, 'payload.data.orders')
    yield put(
        fetchFeatures({
            status: actionContant.OPTION_INPUT_API.ON,
            scope: 'basic',
            id_agency: lodash.get(order, 'id_agency', 0),
            getByAgencyOrPartner: true,
        })
    )
}

export function* updateOrderFeatureSaga(action) {
    try {
        let params = action.payload.params
        let response = yield call(service.updateOrderFeature, params)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            let orderId = lodash.get(params, 'id', 0)
            yield put({ type: Constants.UPDATE_ORDER_FEATURE.SUCCESS, payload: response.data })
            if (orderId) {
                yield put({
                    type: FETCH_LOGS.REQUEST,
                    payload: {
                        object: 'orders',
                        object_id: orderId,
                    },
                })
            }
            bootbox.success(t('orderDetail:order_detail.update_feature_success'))
        } else {
            let errors = response.data.data
            Object.keys(errors).map(key => {
                Object.keys(errors[key]).map(error => {
                    bootbox.error(
                        t('order_error.features.' + key, {
                            group: error,
                        })
                    )
                    return error
                })
                return key
            })
            yield put({ type: Constants.UPDATE_ORDER_FEATURE.FAILED, payload: response.data })
        }
    } catch (err) {
        bootbox.error(t('messages.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.UPDATE_ORDER_FEATURE.FAILED, payload: {} })
    }
}

export function* updateTotalDiscountOrder(action) {
    try {
        const data = lodash.omit(action.payload.data, ['orderId'])
        const orderId = lodash.get(action, 'payload.data.orderId')

        let response = yield call(service.updateTotalDiscountOrder, orderId, data)
        if (!response) {
            return
        }
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({ type: Constants.UPDATE_TOTAL_DISCOUNT.SUCCESS, payload: { data: { order: { ...response.data.data } }, isFetchLog: true } })
            bootbox.success(t('orderDetail:total_discount.success'))
        } else {
            if (response.data.code !== GlobalConstants.GLOBAL_CONTANT.PERMISSION_DENIED) {
                let errors = response.data.data
                yield put({ type: Constants.UPDATE_TOTAL_DISCOUNT.FAILED, payload: response.data })
                Object.keys(errors).map(key => {
                    Object.keys(errors[key]).map(error => {
                        bootbox.error(t('orderDetail:total_discount.errors.' + error))
                    })
                })
            } else {
                bootbox.error(t('error_page.permission_not_allow'))
            }
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
        yield put({ type: Constants.UPDATE_TOTAL_DISCOUNT.FAILED, payload: action.payload.params })
    }
}

export function* setBatchOrder(action) {
    try {
        if (!lodash.isEmpty(action.payload)) {
            const data = lodash.get(action, 'payload.data')
            const orderId = lodash.get(action, 'payload.orderId')
            const status = lodash.get(data, 'status', 0)
            let response = yield call(service.setBatchOrder, orderId, data)
            if (!response) {
                return
            }
            if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({
                    type: Constants.SET_BATCH_ORDER.SUCCESS,
                    payload: { id: orderId, ...response.data, isFetchLog: true, isOpenUpdateBatchOrder: !!status },
                })
                bootbox.success(status ? t('order:message.add_batch_order_success') : t('order:message.remove_batch_order_success'))
            } else {
                yield put({
                    type: Constants.SET_BATCH_ORDER.FAILED,
                })
                bootbox.error(status ? t('order:message.add_batch_order_fail') : t('order:message.remove_batch_order_fail'))
            }
        }
    } catch (err) {
        bootbox.error(t('message.system_errors') + ' : ' + err.message)
    }
}
