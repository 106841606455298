import React, {useState} from 'react';
import {translate} from "react-i18next";
import {processResponse} from "../../../systems/api";
import apiService from "../apiService";
import lodash from 'lodash';
import {Button, notification, Icon, Modal, Table, Avatar} from "antd";
import {hasPermission, permissions} from "../../../modules/Permission";

function CreateTaobaoPurchaseOrder(props) {
    const {t, orderId, className, getPurchasingOrderDetailAction} = props;
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [visible, setVisible] = useState(false);
    const [itemErrors, setItemErrors] = useState({});

    const columns = [
        {
            title: 'Mã sản phẩm',
            dataIndex: 'partner_order_item_id',
            key: 'partner_order_item_id',
            render: (text, record) => {
                const {link, image, title, property, item_id} = record;
                return <div className="d-flex align-items-center">
                    <a href={link} target="_blank">
                        <Avatar
                            shape="square"
                            src={image ? image : ''}
                            className="mr-2"
                            size={36}
                            style={{flexShrink: 0}}
                        />
                    </a>
                    <div>
                        <span className="a--cursor-poiter a-text--bold a-text--font-blue">
                                    #{lodash.get(record, 'partner_order_item_id', null)}
                                </span>
                        <p>
                            <a
                                target="_blank"
                                href={link}
                                data-item-id={item_id}
                                className={`order-item-link order-item-link_` + item_id}>
                                {title}
                            </a>
                        </p>
                        <p>Mẫu: <span className="a-text--font-primary a-text--break-all">{property}</span></p>
                    </div>
                </div>
            }
        },
        {
            title: 'Số lượng',
            dataIndex: 'qty_bought',
            key: 'qty_bought',
        },
        {
            title: 'Số lượng site gốc',
            dataIndex: 'stock',
            key: 'stock',
        },
    ];

    function updateItemsQuantity(data) {
        const dataUpdate = [];
        data.map(item => dataUpdate.push({[`${item.id}`]: item.stock}));
        setUpdating(true);
        processResponse(apiService.updateItemsQuantity(orderId, {items: dataUpdate})).then(() => {
            notification.success({message: "Cập nhật sản phẩm site gốc thành công"});
            getPurchasingOrderDetailAction({id: orderId});
            setVisible(false);
        }).catch(() => {
            notification.error({message: "Cập nhật sản phẩm site gốc thất bại"});
        }).finally(() => setUpdating(false));
    }


    function handleCreatePurchaseOrder() {
        
        if (orderId) {
            setLoading(true);
            processResponse(apiService.createTaobaoPurchaseOrder(orderId)).then(res => {
                const success = lodash.get(res, "data.purchaseOrder.success", false);
                if (success) {
                    getPurchasingOrderDetailAction({id: orderId});
                    notification.success({message: "Tạo đơn hàng thành công!"});
                } else {
                    notification.error({message: "Tạo đơn hàng thất bại!"});
                }
            }).catch(err => {

                const itemErrors = lodash.get(err, "data.item_errors", {});
                if (!lodash.isEmpty(itemErrors)) {
                    setItemErrors(itemErrors);
                    setVisible(true);
                } else {
                    const data = lodash.get(err, 'data', {});
                    let messages = [];
                    if (!lodash.isEmpty(data)) {
                        Object.keys(data).map(key => {
                            switch (key) {
                                case "purchaser_address":
                                    messages.push("Đơn chưa có địa chỉ kho nhận");
                                    break;
                                case "SERVICE_GROUP_FORCE":
                                    messages.push(t("order:detail.service_group_force", {key: data[key]}));
                                    break;
                                case "SERVICE_NOT_CONFIRM":
                                    Object.keys(data[key]).map(error => {
                                            messages.push(t("order:detail.service_not_confirm", {key: error}));
                                            return error;
                                        }
                                    );
                                    break;
                                case "PROPERTY_REQUIRED":
                                case "PROPERTY_GROUP_FORCE":
                                    const list_key = lodash.keys(data[key]).join(", ");
                                    messages.push(t("order_error.features." + key, {key: list_key}));
                                    break;
                                case "PROPERTY_GROUP_GREATER":
                                    messages.push(t("order:detail.property_group_greater"));
                                    break;
                                case "user":
                                    if(lodash.get(data, `user`,'') === "not_connect"){
                                        messages.push(t("order:detail.user_not_connect"));
                                    }
                                    break;
                                default:
                                    messages.push("Có lỗi trong quá trình xử lý");
                            }
                            return key;
                        });
                        
                        if (messages.length > 0) {
                            setItemErrors(prevState => ({...prevState, messages}));
                            setVisible(true);
                        }
                    } else {
                        notification.error({message: "Có lỗi trong quá trình xử lý"});
                    }
                }

            }).finally(() => setLoading(false));
        } else {
            notification.error({message: "Mã đơn hàng hệ thống không hợp lệ"})
        }
    }

    const outOfStocks = lodash.get(itemErrors, "outOfStocks", []);
    const notMapSku = lodash.get(itemErrors, "notMapSku", []);
    const messages = lodash.get(itemErrors, "messages", []);

    return <>
        <Button
            size={"small"}
            className={className}
            type="primary"
            loading={loading}
            icon="plus"
            onClick={handleCreatePurchaseOrder}
        >
            Tạo đơn TBG
        </Button>
        {
            visible &&
            <Modal
                width={800}
                title="Tạo đơn TBG thất bại"
                footer={null}
                visible={visible}
                onCancel={() => setVisible(false)}
            >
                <div>
                    {
                        messages.length > 0 &&
                        messages.map((item, index) => <p key={index}>{item}</p>)
                    }
                    {
                        !lodash.isEmpty(outOfStocks) &&
                        <div>
                            <p> - Sản phẩm bị hết/thiếu hàng</p>
                            <Table
                                dataSource={outOfStocks} columns={columns}
                                pagination={false}
                                footer={() => hasPermission(permissions.ORDER_UPDATE_ITEM_QUANTITY) ? <Button
                                    className="text-right"
                                    type="primary"
                                    onClick={() => updateItemsQuantity(outOfStocks)}
                                    loading={updating}
                                >
                                    Cập nhật số lượng theo site gốc
                                </Button> : null}
                            />

                        </div>
                    }
                    {
                        !lodash.isEmpty(notMapSku) &&
                        <div className="mt-4">
                            <p> - Sản
                                phẩm <b>{lodash.map(notMapSku, "item_id").join(", ")}</b> có
                                SKU không map
                                được thuộc tính với site gốc</p>

                        </div>
                    }

                </div>
            </Modal>
        }
    </>

}

export default translate()(CreateTaobaoPurchaseOrder);