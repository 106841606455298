import React from 'react';
import {Row, Col, Icon } from "antd";
import {translate} from "react-i18next";
import lodash from "lodash";

const STATUSES = [
    {id: "WAITING_BUY", icon: 'shopping-cart'}, //chờ mua
    {id: "WAITING_PAI", icon: 'inbox'}, //Chờ pai
    {id: "PAID", icon: 'check'}, // Đã pai
    {id: "WAITING_PAY", icon: 'bulb'}, // Chờ thanh toán
    {id: "BOUGHT", icon: 'shopping'}, // đã mua
    {id: "OVERDUE_PURCHASE", icon: 'alert'} // quá hạn mua
];

let Stats = function(props) {
    let {stats, t, canChangeWaitingPay, canShowOrderWaitingBuy} = props;
    let ORDER_STATUS = STATUSES.slice();
    let statsBoxStyle = canChangeWaitingPay !== "1" ? "stats-box" : "stats-box-has-waiting-pay";
    if (!canShowOrderWaitingBuy) {
        lodash.remove(ORDER_STATUS, function(status) {
            return status.id === 'WAITING_BUY';
        });
    }
    if (canChangeWaitingPay !== "1") {
        ORDER_STATUS = STATUSES.filter(item => item.id !== "WAITING_PAY");
    }
    return <Row gutter={15} className={`mb-5 ${statsBoxStyle}`}>
        {
            ORDER_STATUS.map((status) => (
                <Col key={status.id} xs={12} xl={4}>
                    <div className="a-block--status" >
                        <div className={`p-1 circle-icon mr-3 a-status a-status--${status.id}`}>
                            <Icon type={status.icon} className="circle-icon" />
                        </div>

                        <div className="d-flex flex-column">
                            <span className="a-text--bold a-text--color-black a-text--fz-22">{stats[status.id] ? stats[status.id] : 0}</span>
                            <span className="text-secondary a-text--uppercase a-text--fz-11">
                                {t(`order:status.${status.id}`)}
                            </span>
                        </div>
                    </div>
                </Col>
            ))
        }
    </Row>;
}

export default translate()(Stats);