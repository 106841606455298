import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import {COMPLAINT_SELLER_STATUS} from "./../../../../systems/globalContant/actionContant";
import lodash from "lodash";

class FormSearchHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {statusList: []};
    }

    componentDidMount() {
        let {filter} = this.props;
        this.setState({statusList: [...filter.status]});
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let {filter} = nextProps;
        return {statusList: [...filter.status]};
    }

    /**
     * Hàm thay đổi các tab thực hiện tìm kiếm
     *
     * @param status
     */
    onClick(status) {
        let {statusList} = this.state;
        if (statusList.includes(status)) {
            let newStatusList = statusList.filter(item => item !== status);
            this.setState({statusList: [...newStatusList]});
            this.props.onSubmit({status: [...newStatusList]});
        } else {
            let newStatusList = [...statusList, status];
            this.setState({statusList: [...newStatusList]});
            this.props.onSubmit({status: [...newStatusList]});
        }
        //let {filter} = this.props;
        //this.props.onSubmit({status, sortBy: "log_time_status_" + status, sort: lodash.get(filter,"sort", "desc")});
    }

    /**
     * cho phép hiển thị kết quả tổng số theo từng trạng thái
     * @param code
     * @returns {boolean}
     */
    isShowingStatus(code) {
        switch (code) {
            case COMPLAINT_SELLER_STATUS.ALL:
                return false;
            default:
                return true;
        }
    }

    render() {
        let {status, statsOrder, t, countStatusChanged} = this.props;
        return (
            <div className="a_complaint_status mt-4 mb-4">
                <ul>
                    {
                        statsOrder.map((order, index) => {
                            let countStatus = countStatusChanged[order.code];
                            if (this.isShowingStatus(order.code)) {
                                return (
                                    <li key={index} onClick={this.onClick.bind(this, order.code)}
                                        className={`${status.status.includes(order.code) ? 'active' : order.className}`}>
                                        {
                                            countStatus !== 0 && typeof countStatus !== "undefined" && order.code !== COMPLAINT_SELLER_STATUS.ALL ?
                                            <span
                                                className={(countStatus > 9 ? "more " : "") + 'a-tab-order--number-noti'}>
                                                {countStatus > 9 ? "9+" : countStatus}
                                            </span> : null
                                        }
                                        <a
                                            href=""
                                            onClick={(event) => event.preventDefault()}
                                            className={"_complaint-seller-click-stats-status-" + order.code}
                                        >
                                            <span>
                                                {order.code === COMPLAINT_SELLER_STATUS.ALL ?
                                                    t('order:status.ACTIVITY') :
                                                    t('complaintSeller:status.' + order.code.toLowerCase())}
                                            </span>
                                            <span> {order.total !== undefined ? `(${order.total})` : ''}</span>
                                        </a>
                                    </li>
                                )
                            }
                            return null;
                        })
                    }
                </ul>
            </div>

        );
    }
}

FormSearchHeader.defaultProps = {
    countStatusChanged: {
        [COMPLAINT_SELLER_STATUS.ALL]: 0,
        [COMPLAINT_SELLER_STATUS.PENDING]: 0,
        [COMPLAINT_SELLER_STATUS.PROCESSING]: 0,
        [COMPLAINT_SELLER_STATUS.SUCCESS]: 0,
        [COMPLAINT_SELLER_STATUS.FAILURE]: 0
    }
};

FormSearchHeader.propTypes = {
    countStatusChanged: PropTypes.object,
    filter: PropTypes.object,
};

export default translate()(FormSearchHeader);
