import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Form, Row, Col, Input, Button, Icon, Select} from 'antd';
import FormProcessing from "../../../modules/common/components/FormProcessing";

class FormSearch extends FormProcessing {

    constructor(props) {
        super(props);

        this.defaultInput = {
            item_id: ""
        };

        this.state = {
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
        };
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.props.onSearch({...this.state.input, page: 1});
        }
    }

    onReset(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.changeInput(this.defaultInput);
            this.props.onSearch(this.defaultInput);
        }
    }

    onChangeText(param, event) {
        this.onChangeInput(param, event);
    }

    render() {
        let {t} = this.props;
        let {input} = this.state;

        return (
            <Form layout="inline" className="d-flex align-item-center">
                <Form.Item className="a-form--ant-item mb-0">
                    <Input
                        value={input.item_id || ''}
                        placeholder={t('promotion:placeholder.keyword')}
                        onChange={this.onChangeText.bind(this, 'item_id')}
                        onPressEnter={this.onSubmit.bind(this)}
                        style={{width: "280px"}}
                        allowClear={true}
                    />
                </Form.Item>
                <a href="#"
                   className="link-reset"
                   onClick={this.onReset.bind(this)}
                ><Icon type="sync"/></a>
            </Form>
        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: null,
    loading: false,
    onSearch: (input) => {},
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.any,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

export default translate()(FormSearch);
