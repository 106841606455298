import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import {currentFormatter} from '../../../common/services/format';

class OrderStats extends React.Component {
    render() {
        let {t, stats} = this.props;

        return (
            <div className="a-content--box-shadow mb-5">
                <div className="a-tab-order">
                    <div className="a-tab-order__title">
                        <ul>
                            {stats.map(item => {
                                return (
                                    <li key={item.code} className={'active-no-border ' + item.className}>
                                        <a href="" onClick={event => event.preventDefault()}>
                                            <span>{item.total > 10000 ? currentFormatter.prettyNumber(item.total, 1) : (item.total || 0)}</span>
                                            <span>{t('order:status.' + item.code)}</span>
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

OrderStats.defaultProps = {
    stats: {},
};

OrderStats.propTypes = {
    stats: PropTypes.object,
};

export default translate()(OrderStats);
