import {notification} from 'antd';
import {t} from '../../../systems/i18n';
import {processApiRequest} from '../../common/saga';
import * as ACTION from './constants';
import {LOG_TYPE} from './constants';
import lodash from 'lodash';
import apiService from './apiService';

export function* fetchLogs(action) {
    yield processApiRequest(ACTION.FETCH_LOGS, () => {
        let {object, object_id, filter} = action.payload;

        return apiService.fetchLogs(object, object_id, filter);
    }, action.payload);
}

export function* fetchComments(action) {
    let {object, object_id} = action.payload;

    switch (object) {
        case 'order':
            return yield processApiRequest(ACTION.FETCH_COMMENTS, () => apiService.fetchOrderComments(object_id), action.payload);
        default:
            return;
    }
}

export function* comment(action) {
    yield processApiRequest(ACTION.COMMENT, () => {
        let {object_id, object, comment} = action.payload;

        if (object === 'order')
            return apiService.commentOrder(object_id, comment);

        if (object === 'complaint_seller')
            return apiService.commentComplaint(object_id, comment);

        let action_comment = lodash.get(action, 'payload.comment.message', '');
        let base64Matcher = new RegExp("data:image\/([a-zA-Z]*);base64,([^\"]*)");

        if (base64Matcher.test(action_comment)) {
            action_comment = (object === 'order') ? LOG_TYPE.COMMENT_IMAGE : LOG_TYPE.COMPLAINT_COMMENT_IMAGE;
            if (object === 'product_return'){
                action_comment = LOG_TYPE.PRODUCT_RETURN_COMMENT_IMAGE;
            }
        } else {
            action_comment = (object === 'order') ? LOG_TYPE.COMMENT : LOG_TYPE.COMPLAINT_COMMENT_IMAGE;
            if (object === 'product_return'){
                action_comment = LOG_TYPE.PRODUCT_RETURN_COMMENT;
            }
        }

        switch (object) {
            case 'product_return':
                return apiService.commentProductReturn({
                    ...comment,
                    action: action_comment,
                    object_id,
                });
            default:
                return;
        }
    }, action.payload);
}

export function* onCommentFailed(action) {
    yield notification.error({message: t('log:error.post_comment_failed')});
}
