import React from 'react';
import {
    Button,
    Modal,
    Popconfirm,
    Icon
} from "antd";
import {translate} from 'react-i18next';
import {trans} from './../../../../systems/i18n';
import PropTypes from 'prop-types';
import InputAddInvoiceCodeContainer from "../../InputAddInvoiceCode/InputAddInvoiceCodeContainer";

class ModalManageInvoiceCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            invoiceCode: "",
            idTransaction: 0,
            canUpdateInvoiceCode: false,
            canDeleteInvoiceCode: false
        }
    }

    changeInvoiceCode(invoiceCode) {
        this.setState({invoiceCode: invoiceCode});
    }

    handleAddInvoiceCode() {
        this.props.addInvoiceCode2Transaction({
            id_transaction: this.props.idTransaction,
            invoice_code: this.state.invoiceCode
        });
    }

    clearState() {
        this.props.clearState();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (prevState.idTransaction !== nextProps.idTransaction && nextProps.idTransaction !== 0) {
            nextState.idTransaction = nextProps.idTransaction;
            nextState.invoiceCode = "";
        }

        return nextState;
    }

    handleDeleteInvoiceCode(invoiceCode) {
        this.props.deleteInvoiceCodeTransaction({
            id_transaction: this.props.idTransaction,
            id_transaction_invoice_code: invoiceCode.id
        });
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    render() {
        let {t, loading, invoiceCodes, idTransaction, showModal, canUpdateInvoiceCode, canDeleteInvoiceCode, maxInvoiceCode, transactionType} = this.props;
        let disableAddingInvoiceCode = (maxInvoiceCode && invoiceCodes.length >= maxInvoiceCode);
        return (
            <Modal
                width={360}
                title={t("transaction:label.invoice_code_upcase")}
                visible={showModal}
                onCancel={this.clearState.bind(this)}
                footer={false}
                centered={true}
                className={"a-modal--list-trade"}
            >
                <p>{t("transaction:label.invoice_code_lowcase")}</p>
                <ul className="mb-5 mt-3">
                    {invoiceCodes.map(invoiceCode => {
                        return <li className="d-flex justify-content-between mb-2">
                            <span className={"a-text--bold "}>{invoiceCode.invoice_code}</span>
                            {canDeleteInvoiceCode ? <Popconfirm
                                onConfirm={this.handleDeleteInvoiceCode.bind(this, invoiceCode)}
                                title={t("transaction:label.confirm_delete_invoice_code")}
                                okText={t("btn.ok")}
                                cancelText={t("btn.cancel")}
                            >
                                {loading ? <Icon type="loading"/> : <span
                                    className="a-text--green-new a-text--pointer">{t("transaction:label.delete_invoice_code")}</span>}
                            </Popconfirm> : null}

                        </li>
                    })}
                    {invoiceCodes.length === 0 && <span className={"a-text--bold "}>{t("transaction:label.no_invoice_code")}</span>}
                </ul>
                {canUpdateInvoiceCode ? disableAddingInvoiceCode ?
                    <span className="a-text--fz-12 a-text--danger">
                        {trans('transaction:warning.limit_invoice_code', {
                            type: trans(`transaction:type.${transactionType}`),
                            maxInvoiceCode: maxInvoiceCode
                        })}

                    </span> :
                    <div>
                        <span className="mt-3">{t("transaction:label.add_invoice_code")}</span>
                        <div className="d-flex t-group--input-inline">
                            <InputAddInvoiceCodeContainer loading={loading} idTransaction={idTransaction}
                                                          callbackOnChange={this.changeInvoiceCode.bind(this)}/>

                            <Button
                                className=" a-font--12 a-btn--primary-ant"
                                onClick={this.handleAddInvoiceCode.bind(this)}
                                loading={loading}
                                icon={"plus"}
                            >
                            </Button>
                        </div>
                    </div>
                    : null}
            </Modal>
        );
    }
}

ModalManageInvoiceCode.defaultProps = {
    loading: false,
    showModal: false,
    idTransaction: 0,
    invoiceCodes: [],
    canUpdateInvoiceCode: false,
    canDeleteInvoiceCode: false
};

ModalManageInvoiceCode.propTypes = {
    idTransaction: PropTypes.number,
    addInvoiceCode2Transaction: PropTypes.func,
    clearState: PropTypes.func,
    deleteInvoiceCodeTransaction: PropTypes.func,
    loading: PropTypes.bool,
    showModal: PropTypes.bool,
    invoiceCodes: PropTypes.array,
    canUpdateInvoiceCode: PropTypes.bool,
    canDeleteInvoiceCode: PropTypes.bool,
};

export default translate()(ModalManageInvoiceCode);
