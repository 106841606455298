import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import Spinner from '@atlaskit/spinner';
import FormProcessing from '../../../common/components/FormProcessing';

class FormCreate extends FormProcessing {
    constructor(props) {
        super(props);

        this.state = {
            input: {
                packageCode: '',
            },
            inputVersion: 0,
            changedParams: [],
        };
    }

    onCreatePackage(){
        let {onCreatePackage} = this.props;
        let {packageCode} = this.state.input;

        if (packageCode) {
            onCreatePackage(packageCode);
        }
    }

    onSubmit(event) {
        let {onCreatePackage} = this.props;
        let {packageCode} = this.state.input;

        if (event.key === 'Enter' && packageCode) {
            onCreatePackage(packageCode);
        }
    }

    render() {
        let {t, loading, canUpdateIntoStatus} = this.props;
        let {packageCode} = this.state.input;
        return (
            <React.Fragment>
                <div className="a-form__inline">
                    <input type="text" value={packageCode || ''}
                           onChange={this.onChangeInput.bind(this, 'packageCode')}
                           onKeyPress={this.onSubmit.bind(this)}
                           className={canUpdateIntoStatus? "":"disable"}
                    />
                    <a onClick={this.onCreatePackage.bind(this)} className={canUpdateIntoStatus? "":"disable"}>{loading ? <Spinner size="small"/> : '+'}</a>
                </div>
            </React.Fragment>
        );
    }
}

FormCreate.defaultProps = {
    input: {},
    loading: false,
    canUpdateIntoStatus: false,
    inputVersion: 0,
    onCreatePackage: packageCode => {},
};

FormCreate.propTypes = {
    input: PropTypes.object,
    loading: PropTypes.bool,
    canUpdateIntoStatus: PropTypes.bool,
    inputVersion: PropTypes.any,
    onCreatePackage: PropTypes.func,
};

export default translate()(FormCreate);
