import React from 'react'
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import {Item} from "@atlaskit/navigation-next";
import lodash from "lodash";
import {Col, Row} from "react-grid-system";
import {router, url} from '../../../systems/routing';

class PurchaseAddressList extends React.Component {

    /**
     * Cập nhật trạng thái
     * @param purchaseAddress
     * @constructor
     */
    UpdateStatus(input) {
        input = {...input, ...{status: input.status ? 0 : 1}};
        this.props.onUpdateStatusPurchaseAddress(input.id, input);
    }

    onClickSelected(id) {
        const {match} = this.props;
        let lastId = lodash.get(match, 'params.id', 0);

        if (parseFloat(id) !== parseFloat(lastId)) {
            this.props.clearPurchaseAddressDetail();
            router.redirect(url.to('purchase-address') + '/' + id);
            this.props.getPurchaseAddressDetail(id);
        }
    }

    render() {
        const {t, purchaseAddressList, detail = {}, permission} = this.props;
        return (
            <Col xs={12} sm={12} md={12} lg={8} xl={8} className="column-left purchase-address">
              <div className="a-content--box-shadow">
                {permission &&
                <a href={url.to('orderGroup.manager')}
                   title={t("order_group.create_order_group")}
                   onClick={this.props.onResetHandler}
                   className="nav-link nav-link-link order-group-link-create pl-0">
                    <EditorAddIcon/>
                    <span className="create_order_group">
                       {t("order_group.create_order_group")}
                    </span>
                </a>}
                {purchaseAddressList && purchaseAddressList.map(({title, items, itemComponent: ItemComponent = Item}) => (
                        <div className={'order_group--list'} key={title}>
                            {items.map((item) => {

                                let classNameDIv = item.status ? 'item-order-group active purchase-address-block' : 'item-order-group inactive purchase-address-block';
                                if (item.id === parseFloat(lodash.get(detail, 'id', null))) {
                                    classNameDIv += ' selected-item';
                                }
                                return (
                                    <div className={'order_group__item'}>
                                        {!!item.default && <span className={'item-default'}>{t('purchase_address.is_default')}</span>}
                                        <div className={classNameDIv} key={item.id} title={item.address}
                                             onClick={e => this.onClickSelected(item.id)}
                                        >
                                            <ItemComponent {...item} />
                                            <p className="description-order-group pr-10 description-address-purchase purchase-address-title">
                                                {item.address}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                            {!items.length ?
                                (<div>{t('purchase_address.not_found')}</div>) : null
                            }
                        </div>
                    ),
                )}
              </div>
            </Col>
        )
    }
}

export default PurchaseAddressList;
