import React from 'react';
import {translate} from 'react-i18next';
import ListAccountPurchaser from './ListAccountPurchaser';
import {ModalTransition} from '@atlaskit/modal-dialog';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import ModalConfirmChangeStatus from './ModalConfirmChangeStatus';
import ModalForm from './ModalForm';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {hasPermission, permissions} from "../../Permission";
import {setActiveSettingItem} from '../../Settings/SettingMenu/actions';

class AccountPurchaser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenConfirmStatus: false,
      isOpenForm: false,
      accountPurchaser: {}
    }
  }

  componentDidMount() {
    this.props.setPageTitle(this.props.t('layout.title.account_purchaser'));
    this.props.setPageDescription(this.props.t('layout.description.account_purchaser'));
    this.props.setMenuActive("AccountPurchaser");
    this.props.setBreadCrumb([
      {
        'label': 'breadcrumb.home',
        'link': '/'
      },
      {
        'label': 'breadcrumb.account_purchaser',
        'link': '/account-purchaser'
      },
    ]);
    setActiveSettingItem('account_purchaser');
    this.props.getList();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextState = {...prevState};
    if ((prevState.isOpenForm === true || prevState.isOpenConfirmStatus === true) && nextProps.isModalProcessing === false) {
      nextState.isOpenForm = false;
      nextState.isOpenConfirmStatus = false;
    }

    return nextState;
  }

  /**
   * handle việc mở popup change status
   * @param accountPurchaser
   */
  openModalChangeStatus(accountPurchaser) {
    this.setState({
      isOpenConfirmStatus: true,
      accountPurchaser: accountPurchaser
    });
    this.props.openModalForm();
    this.props.clearError();
  }

  /**
   * Handle action click button edit
   * @param accountPurchaser
   */
  openModalEdit(accountPurchaser) {
    this.setState({
      isOpenForm: true,
      accountPurchaser: accountPurchaser
    });
    this.props.openModalForm();
    this.props.clearError();
  }

  /**
   * Handle việc mở modal form
   */
  openModalForm = () => {
    this.setState({isOpenForm: true, accountPurchaser: {}});
    this.props.openModalForm();
    this.props.clearError();
  }

  /**
   * handle việc đóng popup change status lại
   */
  closeModalChangeStatus = () => {
    let {accountPurchaser} = this.state;
    this.props.changeStatusAccountPurchaser({
      id: accountPurchaser.id,
      status: accountPurchaser.status.toLocaleLowerCase() === 'active' ? 'INACTIVE' : 'ACTIVE'
    });
    this.setState({
      isOpenConfirmStatus: false,
      accountPurchaser: {}
    });
    this.props.closeModalForm();
  }

  /**
   * handle việc đóng popup form lại
   */
  closeModalForm = () => {
    this.props.closeModalForm();
    this.setState({isOpenForm: false});
  }

  /**
   * handle action close all modal, reset all state
   */
  closeModal() {
    this.props.closeModalForm();
    this.setState({
      isOpenConfirmStatus: false,
      isOpenForm: false,
      accountPurchaser: {}
    });
  }

  /**
   * handle action submit form
   * @param formData
   */
  onSubmitForm(formData) {
    if (lodash.get(formData, "id", 0) === 0) {
      this.props.createAccountPurchaser(formData);
    } else {
      this.props.updateAccountPurchaser(formData);
    }
  }

  render() {
    const {isOpenConfirmStatus, isOpenForm, accountPurchaser} = this.state;
    let {t, list, isLoading, error, clearError, permissionsList} = this.props;
    let permission = hasPermission(permissions.ACCOUNT_PURCHASER_MANAGE);

    return (
        <div className="a-content__right">
          <div className="a-content--box-shadow">
            {
              permission &&
              <a
                  className="a-btn--none mb-3 a-btn-link--uppercase d-inline-flex"
                  onClick={this.openModalForm.bind(this)}>
                <EditorAddIcon/>{t("account_purchaser.btn_add_new")}
              </a>
            }
            <ListAccountPurchaser
                list={list}
                openModalChangeStatus={this.openModalChangeStatus.bind(this)}
                openModalEdit={this.openModalEdit.bind(this)}
                permission={permission}
            />
          </div>

          <ModalTransition>
            {isOpenConfirmStatus && (
                <ModalConfirmChangeStatus
                    title={t('account_purchaser.header_modal_change_status')}
                    onClose={this.closeModal.bind(this)}
                    onConfirm={this.closeModalChangeStatus}
                    accountPurchaser={accountPurchaser}
                />
            )}
            {isOpenForm && (
                <ModalForm
                    title={t('account_purchaser.header_modal_form')}
                    onClose={this.closeModalForm}
                    accountPurchaser={accountPurchaser}
                    onSubmitForm={this.onSubmitForm.bind(this)}
                    isLoading={isLoading}
                    error={error}
                    clearError={clearError}
                />
            )}
          </ModalTransition>
        </div>
    );
  }
}

AccountPurchaser.defaultProps = {
  list: []
}

AccountPurchaser.protoType = {
  list: PropTypes.array,
};

export default translate('translations')(AccountPurchaser);
