import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip'

class CopyPure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            copied: false,
        }
    }

    componentWillMount() {
        this.interval = setInterval(() => this.clearStateCopy()
            , 3500);
    }

    clearStateCopy() {
        this.setState((state, props) => ({
            copied: false
        }));
    }

    render() {
        let {t, contentCopy, contentText, classText} = this.props;
        let {copied} = this.state;
        return (
            <React.Fragment key={contentCopy+'_'+copied}>
                <ReactTooltip place="top" type="dark" effect="float"/>
                <span data-tip={copied ? t("message.copied"):t('orderDetail:order_detail.click_here_copy_unit_price')}>
                    <span className={classText}>
                        <CopyToClipboard text={contentCopy} onCopy={() => {
                            this.setState({copied: true})
                        }}>
                            <span onChange={({target: {value}}) => this.setState({value, copied: false})}>
                                {contentText}
                            </span>
                        </CopyToClipboard>
                   </span>
                </span>
            </React.Fragment>
        )
    }
}


CopyPure.defaultProps = {
    contentCopy: null,
    contentText: null,
    isDefaultShow: false,
    classText: "a-text--red mr-3"
};

CopyPure.propTypes = {
    isDefaultShow: PropTypes.bool,
    classText: PropTypes.string,
    contentCopy: PropTypes.string,
    contentText: PropTypes.string,
};

export default translate('translations')(CopyPure);
