import {takeLatest, all, fork} from 'redux-saga/effects';
import {GET_PARTNER_CONFIG, WINDOW_PAGE, GET_ERROR_404} from '../../systems/globalContant/actionContant';
import * as saga from './saga';
import logWatcher from './Log/sagaWatcher';

export default function* () {
    yield all([fork(logWatcher)]);
    yield takeLatest(GET_PARTNER_CONFIG.REQUEST, saga.getPartnerConfig);
    yield takeLatest(WINDOW_PAGE.UPDATE_TITLE, saga.setPageTitle);
    yield takeLatest(GET_ERROR_404.REQUEST, saga.setError404);
}
