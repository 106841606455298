import React from 'react';
import {translate} from 'react-i18next';
import Button from '@atlaskit/button';
import PropTypes from 'prop-types';
import {trans} from './../../../../../systems/i18n';
import {url} from './../../../../../systems/routing/index';
import ModalGeneral from './../../../../common/components/ModalGeneral';

class ModalFreightDuplicate extends React.Component {
    addFreightBill(event) {
        let {freight_bill, id_order} = this.props;
        this.props.addFreightBill({
            freight_bill,
            id: id_order,
            force: 1
        });
    }

    onClose(){
        this.props.onClose();
    }

    render() {
        let {isLoading, freight_bill_in_others,freight_bill} = this.props;
        freight_bill_in_others = Object.keys(freight_bill_in_others);
        let sumfreightBillDuplicate = freight_bill_in_others.length;
        return (
            <ModalGeneral>
                <div className={"a-modal__title"}>
                    <p >{this.props.title}</p>
                </div>
                <div className="a-group" style={{wordBreak: "break-word"}}>
                    {
                        freight_bill_in_others.length ?
                            (
                                <div className="a-group--notice-order-duplicate">
                                    {trans('orderDetail:order_detail.freight_bill_duplicate',
                                        {
                                            freight_bill: <b>{freight_bill}</b>,
                                            orders: freight_bill_in_others.map((freightBillDuplicateItem, index)=>
                                                <React.Fragment key={index+'_'+freightBillDuplicateItem}>
                                                    <a  target="_blank" style={{textDecoration:'none'}}
                                                        href={url.to('purchase.buying.detail',{id:freightBillDuplicateItem})}
                                                        params={{id: freightBillDuplicateItem}}
                                                    >
                                                        {freightBillDuplicateItem}
                                                    </a>
                                                    {index < ( sumfreightBillDuplicate - 1 ) ? (" , ") : null}
                                                </React.Fragment>
                                            )
                                        }
                                    )}
                                </div>
                            )
                            :
                            null
                    }
                </div>
                <div className="a--group--inline-end p-2 a--user-group-btn ">
                    <Button appearance="primary" autoFocus="true"
                            type="submit" onClick={this.addFreightBill.bind(this)}
                            className=" mr-3 a-btn--submit"
                            isLoading={isLoading}
                    >
                        {trans('modal.accept')}
                    </Button>
                    <a
                        appearance="subtle"
                        onClick={this.onClose.bind(this)}
                        className={(isLoading ? "hidden " : "") + "a-btn a-btn--none a-btn-link "}
                    >
                        {trans('modal.cancel')}
                    </a>
                </div>
            </ModalGeneral>
        );
    }
}

ModalFreightDuplicate.defaultProps = {
    title: "",
    onClose: ()=> {}
};

ModalFreightDuplicate.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
};

export default translate('translations')(ModalFreightDuplicate);
