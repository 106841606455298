import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import ProductReturns from './components/index';
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from './../common/actions';
import * as Selector from './selectors';
import {
    onUpdateStatus,
    clearProductReturn,
    fetchProductReturn,
    updateInfoProductReturn,
    resetErrorUpdateInfoProductReturn,
} from './actions';

const mapStateToProps = (state, props) => {
    return {
        createErrors: lodash.get(state, "complainProductReturn.createErrors", {}),
        isLoadingData: lodash.get(state, "complainProductReturn.isLoadingData", {}),
        productReturn: Selector.getProductReturn(state),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onUpdateStatus: (status) => {
            dispatch(onUpdateStatus(status));
        },
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        fetchProductReturn: (params) => {
            dispatch(fetchProductReturn(params));
        },
        updateInfoProductReturn: (params) => {
            dispatch(updateInfoProductReturn(params));
        },
        resetErrorUpdateInfoProductReturn: (params) => {
            dispatch(resetErrorUpdateInfoProductReturn(params));
        },
        clearProductReturn: () => {
            dispatch(clearProductReturn());
        },
    }
};

let ComplaintProductReturnContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductReturns);

ComplaintProductReturnContainer.defaultProps = {
    complaint: {},
    alipays: [],
};

ComplaintProductReturnContainer.propTypes = {
    complaint: PropTypes.object.isRequired,
    alipays: PropTypes.array.isRequired,
};

export default ComplaintProductReturnContainer;
