import lodash from 'lodash';
import INVOICE_EVENT from '../../../modules/SourceInvoice/events';
import {CRAWL_STATUS} from '../../../modules/SourceInvoice/constans';
import {updateCollectionItem} from '../../../modules/common/services/helps';

const findOrderByInvoice = (orders, invoiceCode, source) => orders.find(order => {
    return order.seller_source === source && lodash.find(order.invoice_codes, {invoice_code: invoiceCode});
});

const orderContainsInvoices = (order, invoices) => lodash.find(order.invoice_codes, invoice => lodash.find(invoices, {
    code: invoice.invoice_code,
    source: order.seller_source,
}));

export const orders = (orders = [], event) => {
    let {type, payload} = event;
    
    switch (type) {
        case INVOICE_EVENT.CRAWL_START: {
            return orders.map(order => {
                if (
                    order.crawl_status === CRAWL_STATUS.PROCESSING
                    || !orderContainsInvoices(order, payload.invoices)
                ) {
                    return order;
                }

                return {
                    ...order,
                    crawl_status: CRAWL_STATUS.PENDING,
                    invoice_codes: order.invoice_codes.map(invoice => lodash.omit(invoice, ['crawl_error'])),
                };
            });
        }

        case INVOICE_EVENT.CRAWL_PROCESSING: {
            let {invoice} = payload;
            let order = findOrderByInvoice(orders, invoice.code, invoice.source);

            return order
                ? updateCollectionItem(orders, order.id, {crawl_status: CRAWL_STATUS.PROCESSING})
                : orders;
        }

        case INVOICE_EVENT.CRAWL_SUCCESS: {
            let {invoice} = payload;
            let order = findOrderByInvoice(orders, invoice.code, invoice.source);

            if (!order) {
                return orders;
            }

            return updateCollectionItem(orders, order.id, {
                ...lodash.pick(lodash.get(payload, 'data.order'), ['status', 'order_detail']),
                crawl_status: CRAWL_STATUS.SUCCESS,
            });
        }

        case INVOICE_EVENT.CRAWL_FAILED: {
            let {invoice, error} = payload;
            let order = findOrderByInvoice(orders, invoice.code, invoice.source);

            if (!order) {
                return orders;
            }

            return updateCollectionItem(orders, order.id, order => ({
                crawl_status: CRAWL_STATUS.FAILED,
                invoice_codes: updateCollectionItem(order.invoice_codes, invoice.code, {crawl_error: error}, 'invoice_code'),
            }));
        }

        case INVOICE_EVENT.CRAWL_FINISH:
        case INVOICE_EVENT.CRAWL_CANCEL: {
            return orders.map(order => [CRAWL_STATUS.PENDING, CRAWL_STATUS.PROCESSING].includes(order.crawl_status)
                ? lodash.omit(order, ['crawl_status'])
                : order
            );
        }

        default:
            return orders;
    }
};

export const crawling = (crawling = false, event) => {
    switch (event.type) {
        case INVOICE_EVENT.CRAWL_START:
            return true;

        case INVOICE_EVENT.CRAWL_FINISH:
        case INVOICE_EVENT.CRAWL_CANCEL:
            return false;

        default:
            return crawling;
    }
};