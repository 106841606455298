import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import FailedComplaint from './components/FailedComplaint';
import * as actions from './actions';

const mapStateToProps = (state, props) => {
    let {complaint} = props;
    let complaints = lodash.get(state, 'ComplaintSeller.failedComplaint.complaints', []);
    let data = lodash.find(complaints, {id: complaint.id}) || {};

    return {
        ...data,
        error: data.error || {},
    };
};

const mapDispatchToProps = (dispatch, props) => {
    let {complaint} = props;

    return {
        resetParamError: (param) => {
            dispatch(actions.resetParamError(complaint.id, param));
        },
        onSubmit: (input) => {
            dispatch(actions.failedComplaint(complaint.id, input));
        },
        onReset: () => {
            dispatch(actions.clearState(complaint.id));
        },
    };
};

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(FailedComplaint);

Container.defaultProps = {
    complaint: {},
};

Container.propTypes = {
    complaint: PropTypes.object.isRequired,
};

export default Container;
