import React from 'react';
import {Form, Row, Col, Input, Select, DatePicker, Button, Icon} from 'antd';

const Search = Input.Search;
const Option = Select.Option;
const {RangePicker} = DatePicker;


class FormSearchUploadCsvAlipay extends React.Component {
    render() {
        return (
            <Row gutter={32} className="a-list--form-search mr-0 ml-0">
                <Form labelCol={{span: 12}}>
                    <Col xs={6} sm={6} md={5} lg={5} xl={4} className="pl-0">
                        <Form.Item label="Mã giao dịch / Nhà cung cấp" className="a-form--ant-item  mb-0">
                            <Search/>
                        </Form.Item>
                    </Col>
                    <Col xs={6} sm={6} md={5} lg={5} xl={4}>
                        <Form.Item label="Người tạo" className="a-form--ant-item  mb-0">
                            <Select placeholder="Người tạo">
                                <Option value="jack">Jack</Option>
                                <Option value="lucy">Lucy</Option>
                                <Option value="Yiminghe">yiminghe</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={8} sm={8} md={6} lg={7} xl={6}>
                        <Form.Item label="Thời gian" className="a-form--ant-item  mb-0">
                            <RangePicker placeholder={['Từ ngày', 'Đến ngày']}/>
                        </Form.Item>
                    </Col>
                    <Col xs={{span: 5, offset: 1}} lg={{offset: 1, span: 6}} xl={{offset: 6, span: 4}} className="pr-0">
                        <div className="a-list--action-search">
                            <a href="#" className="link-reset"><Icon type="sync"/>Làm mới bộ lọc</a>
                            <Button className="a-text--uppercase a-btn--primary-ant">Tìm kiếm</Button>
                        </div>
                    </Col>
                </Form>
            </Row>
        )
    }
}

export default FormSearchUploadCsvAlipay;