import React, {PureComponent} from 'react';
import Spinner from '@atlaskit/spinner';

class AutoPaiButton extends PureComponent {

    render() {
        let {item_id, biz_item_id, seller_source, link, property_value, property, qty_bought, canChangePaid, sku} = this.props;
        return (
            <React.Fragment>
                {
                    canChangePaid && qty_bought ? <div
                        className={`a-purchasod__autopai a-purchasod__autopai_${item_id} a-purchasod__autopai_biz_${biz_item_id}`}
                        data-item-link={link}
                        data-supported={true}
                        data-props={property}
                        data-props-value={property_value}
                        data-amount={qty_bought}
                        data-sku={sku}
                        data-source={seller_source}
                        data-item-id={item_id}
                        data-biz-item-id={biz_item_id}
                    >
                        <span className={`a-purchasod__autopai__label`}>
                            <div className={`a-purchasod__autopai__label__spinner`}><Spinner size="xsmall"/></div>
                            <div className={`a-purchasod__autopai__label__done`}><i className="fas fa-check"/></div>
                                <span className="a-purchasod__autopai__label__text">
                                    AUTOPAI
                                </span>
                            </span>
                        <div className="a-purchasod__autopai__label__response"/>
                    </div> : null
                }
            </React.Fragment>
        );
    }
}

AutoPaiButton.defaultProps = {};

AutoPaiButton.propTypes = {};


export default AutoPaiButton;