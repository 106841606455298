import TrayIcon from '@atlaskit/icon/glyph/tray';
import FlagFilledIcon from '@atlaskit/icon/glyph/flag-filled';
import VidRaisedHandIcon from '@atlaskit/icon/glyph/vid-raised-hand';
import PersonCircleIcon from '@atlaskit/icon/glyph/person-circle';
import ExcelSpreadsheet24Icon from '@atlaskit/icon-file-type/glyph/excel-spreadsheet/24';
import BacklogIcon from '@atlaskit/icon/glyph/backlog';
import MediaServicesSpreadsheetIcon from '@atlaskit/icon/glyph/media-services/spreadsheet';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import {permissions} from "../../../../modules/Permission";

export const MENU_TYPE = {
    SINGLE: "single",
    MULTI: "multi",
    GROUP: "group"
}

/**
 * type của item có 3 dạng:
 * single: menu chỉ có 1 cấp,
 * multi: menu có cấp con, các menu con được khai báo trong thuộc tính sub_menu,
 * group: là title chứa các menu con các menu con cũng được khai báo trong thuộc tính sub_menu
 *
 * @type {[null,null,null]}
 */
export const Menus = [
    {
        is_open: true,
        key: "OrderGroup",
        type: MENU_TYPE.GROUP,
        text: 'theme.menu.group_order',
        sub_menu: [
            {
                is_open: true,
                key: "ListOrder",
                href: '/ordering',
                text: 'theme.menu.list-order',
                type: MENU_TYPE.SINGLE,
                icon: {
                    object: BacklogIcon,
                    size: "medium"
                },
                sub_menu: [],
                permission: [permissions.ORDER_VIEW]
            },{
                is_open: true,
                key: "ListPurchase",
                href: '/list_purchase',
                text: 'theme.menu.list-purchase',
                type: MENU_TYPE.SINGLE,
                icon: {
                    object: TrayIcon,
                    size: "medium"
                },
                sub_menu: [],
                permission: [permissions.ORDER_VIEW]
            },
            {
                is_open: true,
                key: "ViolatePolicy",
                href: '/violate-policy/list',
                text: 'theme.menu.violate-policy',
                type: MENU_TYPE.SINGLE,
                icon: {
                    object: FlagFilledIcon,
                    size: "medium"
                },
                sub_menu: [],
                permission: [permissions.VIOLATED_ORDER_VIEW]
            }
        ],
    },

    {
        is_open: true,
        key: "Configuration",
        type: MENU_TYPE.GROUP,
        text: 'theme.menu.group_configuration',
        sub_menu: [
            {
                is_open: true,
                key: "ComplaintSeller",
                href: '/complaint-seller',
                text: 'theme.menu.complaint-seller',
                type: MENU_TYPE.SINGLE,
                icon: {
                    object: MediaServicesSpreadsheetIcon,
                    size: "medium"
                },
                sub_menu: [],
                permission: [permissions.COMPLAINT_SELLER_VIEW]
            },
            {
                is_open: true,
                key: "AccountPurchaser",
                href: '/account-purchaser',
                text: 'theme.menu.account_purchaser',
                type: MENU_TYPE.SINGLE,
                icon: {
                    object: VidRaisedHandIcon,
                    size: "medium"
                },
                sub_menu: [

                ],
                permission: [permissions.ACCOUNT_PURCHASER_VIEW]
            },
            {
                is_open: true,
                key: "OrderGroup",
                href: '/order-group',
                text: 'theme.menu.order-group',
                type: MENU_TYPE.SINGLE,
                icon: {
                    object: BacklogIcon,
                    size: "medium"
                },
                sub_menu: [],
                permission: [permissions.ORDER_GROUP_VIEW]
            },
            {
                is_open: true,
                key: "PurchaseAddress",
                href: '/purchase-address',
                text: 'theme.menu.purchase-address',
                type: MENU_TYPE.SINGLE,
                icon: {
                    object: MediaServicesSpreadsheetIcon,
                    size: "medium"
                },
                sub_menu: [],
                permission: [permissions.PURCHASE_ADDRESS_VIEW]
            },
            {
                is_open: true,
                key: "Sale",
                href: '/sale-figures',
                text: 'theme.menu.sale_figures',
                type: MENU_TYPE.SINGLE,
                icon: {
                    object: ExcelSpreadsheet24Icon,
                    size: "medium"
                },
                permission: [permissions.PURCHASE_REPORT_MANAGE, permissions.PURCHASE_REPORT_VIEW]
            },
            {
                is_open: true,
                key: 'purchasers',
                href: '/purchasers',
                text: 'theme.menu.purchasers',
                type: MENU_TYPE.SINGLE,
                icon: {
                    object: PeopleGroupIcon,
                    size: "medium"
                },
                sub_menu: [],
                permission: [permissions.PURCHASER_MANAGE]
            },
            {
                is_open: true,
                key: 'OrderBillNo',
                href: '/order-bill-no-syncs',
                text: 'theme.menu.order_bill_no',
                type: MENU_TYPE.SINGLE,
                icon: {
                    object: BacklogIcon,
                    size: "medium"
                },
                sub_menu: [],
                permission: [permissions.ORDER_SYNC_BILL_NO_SYNC]
            },
            {
                is_open: true,
                key: "BankAccount",
                href: '/bank-account',
                text: 'theme.menu.bank_account',
                type: MENU_TYPE.SINGLE,
                icon: {
                    object: VidRaisedHandIcon,
                    size: "medium"
                },
                sub_menu: [

                ],
                permission: [permissions.SETTING_BANK_ACCOUNTS]
            },
        ],
    },
    {
        disabled: true,
        is_open: true,
        key: "Personal",
        type: MENU_TYPE.GROUP,
        text: 'theme.menu.group_personal',
        sub_menu: [
            {
                is_open: true,
                key: "Profile",
                href: ';',
                type: "single",
                text: 'theme.menu.profile',
                icon: {
                    object: PersonCircleIcon,
                    size: "medium"
                },
                sub_menu: []
            },
        ],
    }
];
