import React from 'react';
import {Affix, Row, Modal} from "antd";
import lodash from 'lodash';
import AssignAccountPurchaser from "../../../modules/AccountPurchaser/AssigningAccountPurchaser/AssignAccountPurchaser";
import {dateFormatter} from '../../../modules/common/services/format';
import PaginationFull from "../../../modules/common/components/PaginationFull"
import UnassignAccountPurchaser from "../../../modules/AccountPurchaser/AssigningAccountPurchaser/UnassignAccountPurchaser";
import {translate} from "react-i18next";
import PropTypes from "prop-types";
import AssignPurchaserLogs from "../../../modules/AccountPurchaser/AssignPurchaserLogs/AssignPurchaserLogs";
import authService from "../../../modules/Auth/authService";
import * as PERMISSION from "../../../modules/Permission/permissions";

class ListTellers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            loading: false,
            visible: false,
            teller_id: 0
        }
    }
    onChangePage(page, pageSize) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            });
        }
    }

    showLogs(teller) {
        this.setState({
            teller_id: teller.key
        });
        this.showModal();
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            teller_id: 0
        });
    };
    renderAccounts(teller, accounts) {
        let {t} = this.props;
        return (
            <div className="d-flex align-items-center py-1 flex-wrap">
                {accounts.map(account => (
                    <div className={"a-account__employee"} key={account.id}>
                        <div className="a-account__employee__body">
                            <p className="name-account">{lodash.get(account, 'account_purchaser.account', '--')}</p>
                            <span className="date-create-account">{t('purchaser:label.created_at')}: {dateFormatter.date(account.created_at)}</span>
                        </div>
                        <UnassignAccountPurchaser {...this.props} account={account} username={teller.username}/>
                    </div>
                ))}
                <AssignAccountPurchaser teller={teller} tellers={this.props.tellers} />
            </div>
        )
    }

    render() {
        let {filter, pagination, tellers, loading, t} = this.props;
        const data = [];
        tellers.map(teller => {
            data.push({
                key: teller.id,
                username: teller.username,
                codetrade: this.renderAccounts(teller, lodash.get(teller, 'user_account_purchasers', [])),
                accounts: lodash.get(teller, 'user_account_purchasers', [])
            });
        });
        return (
            <div className="a-list mt-5 pb-2">
                <Affix>
                    <div className="a-list__top a-flex">
                        <h2 className="a-list--title a-text--uppercase">{t('purchaser:label.tellers_list')} ({loading ? '' : tellers.length})</h2>
                        <div className="ml-auto">
                            <AssignAccountPurchaser isMultiple={true} />
                        </div>
                    </div>
                </Affix>
                <div className="a-content--is-pagination-fixed">
                    {
                        data.map(teller => {
                            return (
                                <div className="bg-light m-4 border">
                                    <Row className="py-3 px-3 m-0 border-bottom">
                                        <div className="d-flex justify-content-between">
                                            <span className="a-text--bold">{teller.username} ({teller.accounts.length})</span>
                                            {
                                                authService.can(PERMISSION.ACCOUNT_PURCHASER_VIEW)
                                                    ? <span className='a-text--pointer a-text--color-primary' onClick={this.showLogs.bind(this, teller)}>{t('account_purchaser.label.log_title')}</span>
                                                    : null
                                            }
                                        </div>
                                    </Row>
                                    <Row className="py-3 px-3">
                                        {teller.codetrade}
                                    </Row>
                                </div>
                            )
                        })
                    }
                    <Modal
                        title={t('account_purchaser.label.log_title')}
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        bodyStyle={{paddingTop: '0px', maxHeight: '70vmin', overflowY: 'scroll'}}
                        footer={false}
                    >
                        <AssignPurchaserLogs teller_id={this.state.teller_id} t={this.props.t}/>
                    </Modal>
                    <PaginationFull
                        page={lodash.toInteger(filter.page) || 1}
                        pageSize={lodash.toInteger(filter.per_page)}
                        total={pagination.total}
                        onChange={this.onChangePage.bind(this)}
                        className="a-pagination a-pagination--fixed a-pagination--fixed__is-sub-sidebar"
                    />
                </div>
            </div>
        )
    }
}

ListTellers.defaultProps = {
    filter: {},
    loading: false,
    orders: [],
    pagination: {},
    onSearch: (filter) => {
    },
};

ListTellers.propTypes = {
    filter: PropTypes.object,
    loading: PropTypes.bool,
    orders: PropTypes.array,
    pagination: PropTypes.object,
    onSearch: PropTypes.func,
};

export default translate()(ListTellers);
