import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {translate} from 'react-i18next';
import FormProcessing from '../../../common/components/FormProcessing';

class FormSearchOrderItem extends FormProcessing {
    constructor(props) {
        super(props);

        this.state = {
            input: {
                total_item_link_operation: '>',
                total_item_link: '',
                grand_total_from: '',
                grand_total_to: '',
                complaint_seller: "",
                wrong_process: ""
            },
            inputVersion: 0,
            changedParams: [],
        };
    }

    onBlurInput() {
        let {input} = this.state;
        let total_item_link_operation = lodash.get(input, 'total_item_link_operation', '>');
        input = {...input,...{total_item_link_operation:total_item_link_operation}};
        this.props.onSubmit(input);
    }

    onKeyPressInput(input, event) {
        if (event.key === 'Enter') {
            if( event.target.name === 'grand_total_to' || event.target.name === 'grand_total_from' ){
                let grand_total_from = lodash.get(input, 'grand_total_from', 0);
                let grand_total_to = lodash.get(input, 'grand_total_to', 0);
                grand_total_from = lodash.toInteger(grand_total_from, 0);
                grand_total_to = lodash.toInteger(grand_total_to, 0);
                if( lodash.get(input, 'grand_total_from', '') &&
                    lodash.get(input, 'grand_total_to', '') &&
                    grand_total_from < grand_total_to) {
                    this.props.hiddenDropdown();
                }
            }else{
                this.props.hiddenDropdown();
            }
            let total_item_link_operation = lodash.get(input, 'total_item_link_operation', '>');
            input = {...input,...{total_item_link_operation:total_item_link_operation}};
            this.props.onSubmit(input);
        } else {
            this.props.openDropdown();
        }
    }

    onKeyDown(input, event) {
        if (event.keyCode === 8) {
            this.props.openDropdown();
        }
    }

    onChangeSelect(param, event) {
        let target = event.target;
        let input = this.changeInput({[param]: target.value});
        this.props.onSubmit(input);
    }

    render() {
        let {t} = this.props;
        let {input} = this.state;
        let totalItemLinkOperations = ['>', '<'];
        let error_grand_total = '';
        let grand_total_from = lodash.get(input, 'grand_total_from', 0);
        let grand_total_to = lodash.get(input, 'grand_total_to', 0);
        grand_total_from = lodash.toInteger(grand_total_from, 0);
        grand_total_to = lodash.toInteger(grand_total_to, 0);
        if( lodash.get(input, 'grand_total_from', '') &&
            lodash.get(input, 'grand_total_to', '') &&
            grand_total_from > grand_total_to) {
            error_grand_total =  t('order_error.filter_errors.grand_total.500');
        }
        return (
            <div className="a-form--dropdown--order ">
                <div className="font-weight-bold mb-2 mt-3 a-text--font-primary">{t('order:list.total_item_link')}</div>
                <div className="row ml-0 mr-0">
                    <div className="col pl-0">
                        <select
                            className="select"
                            value={input.total_item_link_operation || totalItemLinkOperations[0]}
                            onChange={this.onChangeSelect.bind(this, 'total_item_link_operation')}
                        >{totalItemLinkOperations.map(operation => (
                            <option key={operation} value={operation}>{t('operation.' + operation)}</option>
                        ))}</select>
                    </div>
                    <div className="col pr-0">
                        <input
                            type="text"
                            className="input input--fullwidth input--search_item"
                            min={0}
                            value={input.total_item_link || ''}
                            onChange={this.onChangeInputNumber.bind(this, 'total_item_link')}
                            onBlur={this.onBlurInput.bind(this)}
                            onKeyPress={this.onKeyPressInput.bind(this, input)}
                            onKeyDown={this.onKeyDown.bind(this, input)}
                        />
                    </div>
                </div>
                <hr/>
                <div className="font-weight-bold a-text--font-primary mb-2">
                    {t('order:list.grand_total')}
                    <small className="ml-1">({t('label.default')} <span className="text-danger">K</span>)</small>
                </div>
                <div className="row ml-0 mr-0">
                    <div className="col pl-0">
                        <input
                            type="text"
                            name={'grand_total_from'}
                            className="input input--fullwidth input--search_item"
                            min={0}
                            placeholder={t('order:list.from')}
                            value={input.grand_total_from}
                            onChange={this.onChangeInputNumber.bind(this, 'grand_total_from')}
                            onBlur={this.onBlurInput.bind(this)}
                            onKeyPress={this.onKeyPressInput.bind(this, input)}
                            onKeyDown={this.onKeyDown.bind(this, input)}
                        />
                    </div>
                    <div className="col pr-0">
                        <input
                            type="text"
                            name={'grand_total_to'}
                            className="input input--fullwidth input--search_item"
                            min={0}
                            placeholder={t('order:list.to')}
                            value={input.grand_total_to}
                            onChange={this.onChangeInputNumber.bind(this, 'grand_total_to')}ó
                            onBlur={this.onBlurInput.bind(this)}
                            onKeyPress={this.onKeyPressInput.bind(this, input)}
                            onKeyDown={this.onKeyDown.bind(this, input)}
                        />
                    </div>
                </div>
              <hr/>
              <div className="font-weight-bold a-text--font-primary mb-2">
                  {t("order:list.KNNB")}
                <small className="ml-1">{t("order:list.KNNB_note")}</small>
              </div>
              <div className="search__complaintseller">
                  <select
                      className="select"
                      value={input.complaint_seller}
                      onChange={this.onChangeSelect.bind(this, 'complaint_seller')}
                  >
                      <option key={""} value={""}>{t("order:list.all_order")}</option>
                      <option key={t("order:list.has_complaint_seller_all")} value={"ALL"}>{t("order:list.has_complaint_seller")}</option>
                      <option key={t("order:list.has_complaint_seller_active")} value={"ACTIVE"}>{t("order:list.has_complaint_seller_active")}</option>
                      <option key={t("order:list.has_not_complaint_seller")} value={"NONE"}>{t("order:list.has_not_complaint_seller")}</option>
                  </select>
              </div>
                {error_grand_total ? (
                    <div className="row ml-0 mr-0">
                        <div className="col pl-0">
                            <p style={{color:'red',marginTop:'10px'}}>{error_grand_total}</p>
                        </div>
                    </div>
                ) : null}
                <hr/>
                <div className="font-weight-bold a-text--font-primary mb-2">
                    {t("order:label.wrong_process_search")}
                    <small className="ml-1">{t("order:label.wrong_process_search_detail")}</small>
                </div>
                <div className={'d-flex align-items-center'}>
                    <select
                        className="select"
                        value={input.wrong_process}
                        name={"wrong_process"}
                        onChange={this.onChangeSelect.bind(this, 'wrong_process')}
                    >
                        <option value="">{t("order:label.all")}</option>
                        <option key={false} value={0}>{t("order:label.process_ok")}</option>
                        <option value={1}>{t("order:label.process_wrong")}</option>
                    </select>
                </div>
            </div>

        );
    }
}

FormSearchOrderItem.defaultProps = {
    input: {},
    inputVersion: 0,
    onSubmit: input => {
    },
};

FormSearchOrderItem.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    onSubmit: PropTypes.func,
};

export default translate()(FormSearchOrderItem);
