import React from 'react';
import lodash from 'lodash';
import moment from 'moment';
import {connect} from 'react-redux';
import SaleFigures from './components/index';
import {setBreadCrumb, setPageDescription, setPageTitle, setMenuActive} from './../common/actions';
import * as UserSelector from './../User/selectors';
import {getPurcharsers} from "../User/actions";
import {
    createReports,
    newRequestReport,
    getListReports,
    getCurrentReports,
    getPusherReports,
    clearErrorWhileCreate,
    createCancelReports
} from "./actions";
import SearchableComponent from "./../common/SearchableComponent";
import * as Selector from './selectors';
import {setActiveSettingItem} from '../Settings/SettingMenu/actions';

const mapStateToProps = (state) => {
    return {
        purchasers: UserSelector.purchasersSelector(state),
        reports: lodash.get(state, 'SalesFigures.reports', {}),
        saleFigures: Selector.getSalesFigures(state),
        isLoadingData: lodash.get(state, 'SalesFigures.isLoadingData', {}),
        isEndProcess: lodash.get(state, 'SalesFigures.isEndProcess', {}),
        error: lodash.get(state, 'SalesFigures.error', {}),
        channels: Selector.getCreateSaleFiguresChannel(state),
        permissions: lodash.get(state, 'User.me.user.permissions', [])

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createReports: (params)=> {
            dispatch(createReports(params));
        },
        newRequestReport: (params)=> {
            dispatch(newRequestReport(params));
        },
        clearErrorWhileCreate: (params)=> {
            dispatch(clearErrorWhileCreate(params));
        },
        getPusherReports: (data)=> {
            dispatch(getPusherReports(data));
        },
        getListReports: (filters)=> {
            dispatch(getListReports(filters));
        },
        getCurrentReports: (filters)=> {
            dispatch(getCurrentReports(filters));
        },
        createCancelReports: (filters)=> {
            dispatch(createCancelReports(filters));
        },
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        getPurcharsers: (filters) => {
            dispatch(getPurcharsers({...filters, scope: 'basic'}))
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu));
        }
    };
};

class SaleFiguresContainer extends SearchableComponent {
    componentDidMount() {
        super.componentDidMount();
        this.props.getCurrentReports({});
        this.props.getListReports({});
        setActiveSettingItem('sale_figures');
    }

    onSearch(filter) {
        this.pushFilter({
            ...filter,
            _i: parseInt(filter._i || 0, 0) + 1,
        });
    }


    getCurrentFilter() {
        return {
            username: null,
            month: moment().format('M'),
            year: moment().format('YYYY'),
            ...this.getFilterFromLocation(this.props.location),
        };
    }

    render() {
        let filter = this.getCurrentFilter();
        return <SaleFigures {...{
            ...this.props,
            filter: filter,
            filterVersion: parseInt(filter._i, 0),
            onSearch: this.onSearch.bind(this),
        }}/>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaleFiguresContainer)
