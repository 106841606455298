import lodash from 'lodash';
import {apiWithToken} from '../../systems/api';
import {API_URL} from './../../systems/globalContant';

const normalizeQueryListOrder = params => {
    if (params.total_item_link_operation === '>') {
        params.total_item_link_from = params.total_item_link;
    }

    if (params.total_item_link_operation === '<') {
        params.total_item_link_to = params.total_item_link;
    }
    return lodash.omit(params, [
        'total_item_link_operation',
        'total_item_link',
    ]);
};

export default {
    orderBillNoSyncs: (data) => apiWithToken.post(API_URL.DEFAULT + `/orders/syncs-bill-no`, {loading: false}, {data}),

    getOrdersBillNo: (params) => apiWithToken.get(API_URL.DEFAULT + '/orders/order-bill-no', {
        params: normalizeQueryListOrder(params),
        singleRequest: true,
        loading: false
    }),
};
