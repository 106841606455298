import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import Button from '@atlaskit/button';
import ModalGeneral from './../../../../common/components/ModalGeneral';

class CancelFragment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};

        if (prevState.isOpenModal === true && nextProps.isModalProcessing === false) {
            nextState.isOpenModal = false;
        }
        return nextState;
    }

    /**
     * handle action call CANCEL api
     * @param event
     */
    onCancel(event) {
        let {id} = this.props;
        this.setState({isOpenModal: true});
        this.props.onCancel({id});
    }

    /**
     * handle action open popup
     * @param event
     */
    onOpenPopupConfirm(event) {
        this.setState({isOpenModal: true});
        this.props.beginProcessCancelOrder();
    }

    /**
     * handle action close popup
     * @param event
     */
    onClose(event) {
        this.setState({isOpenModal: false});
    }

    render() {
        let {t, canCancel, code} = this.props;
        let {isOpenModal} = this.state;
        return (
            <React.Fragment>
                {canCancel === true &&
                <a className={"a-text--red"}
                   onClick={this.onOpenPopupConfirm.bind(this)}>
                    {t('orderDetail:order_detail.cancel_order_label')}
                </a>}
                {isOpenModal ?
                    <ModalGeneral>
                    <div className={"a-modal__title"}>
                        <p>{t("orderDetail:order_detail.cancel_popup.title")} </p>
                    </div>
                    <div style={{wordBreak: "break-all"}}>
                        {t("orderDetail:order_detail.cancel_popup.message")} <b>#{code}</b>
                    </div>
                    <div className={"a--group--inline-end p-2 a--user-group-btn "}>
                        <Button
                            appearance="primary" type="submit" className={" mr-3 a-btn--submit"}
                            onClick={this.onCancel.bind(this)}
                            isLoading={this.props.isLoadingCancelOrder}
                        >
                            {t('orderDetail:order_detail.cancel_popup.btn_submit')}
                        </Button>
                        <a
                            appearance="subtle"
                            onClick={this.onClose.bind(this)}
                            className={this.props.isLoadingCancelOrder ? "hidden" : "a-btn a-btn--none"}
                        >
                            {t('orderDetail:order_detail.cancel_popup.btn_cancel')}
                        </a>
                    </div>
                </ModalGeneral>
                    : null
                }
            </React.Fragment>
        );
    }
}

CancelFragment.defaultProps = {
    canCancel: false,
    onCancel: () => {
    },
    id: 0,
    isModalProcessing: false,
    isLoadingCancelOrder: false,
};

CancelFragment.propTypes = {
    canCancel: PropTypes.bool,
    isModalProcessing: PropTypes.bool,
    onCancel: PropTypes.func,
    id: PropTypes.number,
    isLoadingCancelOrder: PropTypes.bool,
    beginProcessCancelOrder: PropTypes.func,
    code: PropTypes.string
};

export default translate('translations')(CancelFragment);
