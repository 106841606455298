import NotFound from './layouts/notFound';
import Error from './modules/error/ErrorContainer';
import AtlakitNavbar from './layouts/AtlakitNavbarContainer';
import SettingLayout from './layouts/Setting';
import Home from './modules/home/HomeContainer';
import AccountPurchaser from './modules/AccountPurchaser/AccountPurchaserContainer';
import OrderGroup from './modules/OrderGroupManager/ListOrderGroup/orderGroupContainer';
import {router} from './systems/routing';
import i18n from './systems/i18n';
import * as PERMISSION from './modules/Permission/permissions';

import switchDefaultLayoutMiddleware from './systems/middlewares/switchDefaultLayoutMiddleware';
import commonMiddleware from './systems/middlewares/commonMiddleware';
import {checkIsLogin} from './systems/middlewares/checkIsLoginMiddleware';
import getToken from './systems/middlewares/getToken';
import pubToken from './systems/middlewares/pubToken';
import loadAuthenticatedUser from './systems/middlewares/loadAuthenticatedUser';
import {can, canAny} from './modules/Auth/middleware/authorize';

import PurchasingOrderDetailContainer from './modules/PurchaseBuying/OrderDetail/OrderDetailContainer'
import PurchaseAddressContainer from "./screens/PurchaseAddress/PurchaseAddressContainer";
import ListOrderContainer from './modules/Order/ListOrder/ListOrderContainer';
import ListPurchaseContainer from "./modules/Purchase/ListOrder/ListPurchaseContainer";
import ManagePurchasers from "./modules/User/ManagePurchasers/ManagePurchasersContainer";
import ViolatePolicyOrderContainer from "./modules/ViolatePolicyOrder/List/ViolatePolicyOrderContainer";
import HelpContainer from "./modules/Help/HelpContainer";
import Page403 from './modules/common/components/Page403'
import ComplaintSellerContainer from "./modules/ComplaintSeller/ComplaintList/ComplaintSellerContainer";
import ComplaintSellerDetailContainer
    from "./modules/ComplaintSeller/ComplaintSellerDetail/ComplaintSellerDetailContainer";
import OrderBillNoContainer from './modules/OrderBillNo/component/OrderBillNoContainer'
import PartnerSetting from "./modules/Settings/ConfigSetting/PartnerSetting/PartnerSettingContainer";
import BuyingSetting from "./modules/Settings/ConfigSetting/BuyingSetting/BuyingSettingContainer";
import TransactionSetting from "./modules/Settings/ConfigSetting/TransactionSetting/TransactionSettingContainer";
import UserSettingContainer from "./modules/Settings/UserSetting/UserSettingContainer";
import SettingPrivateContainer from "./screens/Settings/SettingPrivate/SettingPrivateContainer";
import AccessToken from './modules/User/AccessToken/UserAccessTokenContainer';
import SaleFiguresContainer from "./modules/SalesFigures/SaleFiguresContainer";
import ComplaintProductReturnContainer from "./modules/ComplaintProductReturn/ComplaintProductReturnContainer";

import UploadCsvAlipay from './modules/UploadCsvAlipay/UploadCsvAlipayContainer';
import ListTradeContainer from "./modules/ListTrade/ListTradeContainer";
import ListTransactionFileContainer from "./modules/Transaction/ListTransactionFile/ListTransactionFileContainer";
import ListOrderProblemContainer from "./modules/ListOrderProblem/ListOrderProblemContainer";
import ListTransactionOrderContainer from "./modules/Transaction/ListTransactionOrder/ListTransactionOrderContainer";
import ListTransactionContainer from "./modules/Transaction/ListTransaction/ListTransactionContainer";
import DetailTransactionContainer from "./modules/Transaction/DetailTransaction/DetailTransactionContainer";
import ScanOrders from './screens/Order/ScanOrders';
import ExportOrder from './screens/Order/Export';
import EmployeePurchase from "./modules/EmployeePurchase/EmployeePurchaseContainer";
import ListAssignPurchaser from "./screens/Purchaser/ListAssignPurchaser";
import ProblemOrder from "./screens/Order/ProblemOrder";
import OrderBillNoSync from "./screens/Order/OrderBillNoSync";
import PaymentRequest from "./screens/PaymentRequest";
import ListAccountPurchaserToken from "./screens/Purchaser/ListAccountPurchaserToken";
import PaymentRequestConfirmation from "./screens/PaymentRequest/PaymentRequestConfimation";
import CreateExternalPaymentRequest from "./screens/PaymentRequest/CreateExternalPaymentRequest";
import NewListOrder from "./screens/Order/ListOrder";
import NewListPurchase from "./screens/Purchase/ListPurchase";
import SaleReport from "./screens/SaleReport";
import AgencyContainer from "./screens/Agency/AgencyContainer";
import SlowPurchasingReasonContainer from "./screens/SlowPurchasingReason/SlowPurchasingReasonContainer";
import BankAccountContainer from "./screens/BankAccount/BankAccountContainer";
import ScanComplaintSeller from "./screens/ComplaintSeller/ScanComplaintSeller";
import InvoiceCodeApprovals from "./screens/InvoiceCodeApprovals/InvoiceCodeApprovalsContainer";
import PerformanceReportContainer from "./screens/PerformanceReport/PerformanceReportContainer";
import GroupManage from "./screens/GroupManage";
import GroupManageDetail from "./screens/GroupManage/GroupManageDetail";
import PromotionList from "./screens/Order/PromotionList";
import ForeignOrderList from "./screens/ForeignOrder/List";
import ConnectForeign from "./modules/Settings/ConfigSetting/ConnectForeign";
import {TAOBAO_GET_PURCHASE_ORDER} from "./modules/Permission/permissions";

export default {
    routes: [
        {
            name: '_',
            path: '/',
            exact: true,
            component: Home,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser],
        },
        {
            name: '403',
            path: '/403',
            exact: true,
            component: Page403,
            // layout: AtlakitNavbar
        },
        {
            path: '/error',
            component: Error
        },
        {
            name: 'purchase.buying.detail',
            path: '/purchase-buying/:id',
            exact: true,
            component: PurchasingOrderDetailContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, pubToken, canAny([PERMISSION.ORDER_VIEW, PERMISSION.ORDER_VIEW_ASSIGNED, PERMISSION.ORDER_VIEW_ONLY_ACCEPTED])],
        },
        {
            name: 'purchase.buying.detail',
            path: '/performance-report',
            exact: true,
            component: PerformanceReportContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.SYSTEM_ADMIN)],
        },
        {
            name: 'purchase.problem',
            path: '/purchase-problem/:id',
            exact: true,
            component: ProblemOrder,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, pubToken, canAny([PERMISSION.ORDER_FINANCIAL_VIEW])],
        },
        {
            name: '',
            path: '/get_token/',
            exact: true,
            component: Home,
            layout: AtlakitNavbar,
            middleware: [getToken],
        },
        {
            name: 'complaint-seller',
            path: '/complaint-seller',
            component: ComplaintSellerContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.COMPLAINT_SELLER_VIEW)],
        },
        {
            name: 'scan_complaint_seller',
            path: '/scan-complaint-seller',
            component: ScanComplaintSeller,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.COMPLAINT_SELLER_VIEW)],
        },
        {
            name: 'complaint-seller.detail',
            path: '/complaint-seller-detail/:id',
            component: ComplaintSellerDetailContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.COMPLAINT_SELLER_VIEW)],
        },
        {
            name: 'ordering',
            path: '/ordering/',
            exact: true,
            component: NewListOrder, // ListOrderContainer old
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, canAny([PERMISSION.ORDER_VIEW, PERMISSION.ORDER_VIEW_ASSIGNED, PERMISSION.ORDER_VIEW_ONLY_ACCEPTED])],
        },
        {
            name: 'foreign-order.list',
            path: '/foreign-order',
            exact: true,
            component: ForeignOrderList,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.TAOBAO_GET_PURCHASE_ORDER)],
        },
        {
            name: 'transaction.files.list',
            path: '/transaction/files',
            exact: true,
            component: ListTransactionFileContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.TRANSACTION_MANAGE_FILE)],
        },
        {
            name: 'transaction.order.list',
            path: '/transaction/orders',
            exact: true,
            component: ListTransactionOrderContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.ORDER_TRANSACTION)],
        },
        {
            name: 'transaction.list',
            path: '/transactions',
            exact: true,
            component: ListTransactionContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, canAny([PERMISSION.SUPPLIER_TRANSACTION_LIST, PERMISSION.SUPPLIER_TRANSACTION_LIST_NORMAL, PERMISSION.SUPPLIER_TRANSACTION_LIST_ALL])],
        },
        {
            name: 'transaction.detail',
            path: '/transaction-detail/:id',
            exact: true,
            component: DetailTransactionContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.SUPPLIER_TRANSACTION_VIEW)],
        },
        {
            name: 'purchase.list',
            path: '/list_purchase/',
            exact: true,
            component: NewListPurchase, // ListPurchaseContainer old
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, canAny([PERMISSION.ORDER_VIEW, PERMISSION.ORDER_VIEW_ASSIGNED, PERMISSION.ORDER_VIEW_ONLY_ACCEPTED])],
        },
        {
            name: 'violate-policy.list',
            path: '/violate-policy/list',
            exact: true,
            component: ViolatePolicyOrderContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.VIOLATED_ORDER_VIEW)],
        },
        {
            name: 'user.access_token',
            path: '/user/access-token',
            exact: true,
            component: AccessToken,
            layout: SettingLayout,
            middleware: [checkIsLogin, loadAuthenticatedUser],
        },

        {
            name: 'help',
            path: '/help',
            exact: true,
            component: HelpContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin],
        },
        {
            name: 'product.return.detail',
            path: '/product_return_detail/:id',
            exact: true,
            component: ComplaintProductReturnContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, canAny([PERMISSION.PRODUCT_RETURN_VIEW, PERMISSION.PRODUCT_RETURN_VIEW_ME])],
        },
        {
            name: 'upload-csv-alipay',
            path: '/upload-csv-alipay',
            exact: true,
            component: UploadCsvAlipay,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser],
        },
        {
            name: 'list-trade',
            path: '/list-trade',
            exact: true,
            component: ListTradeContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser],
        },
        {
            name: 'list-order-problem',
            path: '/list-order-problem',
            exact: true,
            component: ListOrderProblemContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser],
        },
        {
            name: 'employee-purchase',
            path: '/employee-purchase',
            exact: true,
            component: EmployeePurchase,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser],
        },
        {
            name: 'payment-requests',
            path: '/payment-requests',
            exact: true,
            component: PaymentRequest,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, canAny([PERMISSION.PAYMENT_REQUEST_VIEW, PERMISSION.PAYMENT_REQUEST_VIEW_ALL, PERMISSION.PAYMENT_REQUEST_VIEW_GROUP, PERMISSION.PAYMENT_REQUEST_VIEW_GROUP_ALL])],
        },
        {
            name: 'payment-request.confirmation',
            path: '/payment-request-confirmation',
            exact: true,
            component: PaymentRequestConfirmation,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser],
        },
        {
            name: 'payment-request.create-external',
            path: '/create-external-payment-request/:idOrder',
            exact: true,
            component: CreateExternalPaymentRequest,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.ORDER_EXTERNAL_PAYMENT_REQUEST)],
        },
        {
            name: 'order.billno',
            path: '/order-bill-no-syncs',
            exact: true,
            component: OrderBillNoSync,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.ACCOUNT_PURCHASER_VIEW)]
        },
        {
            name: 'orders.scan',
            path: '/orders/scan',
            exact: true,
            component: ScanOrders,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.ORDER_SCAN)],
        },
        {
            name: 'purchasers.list',
            path: '/purchasers',
            exact: true,
            component: ManagePurchasers,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.PURCHASER_MANAGE)],
        },
        {
            name: 'salefigures',
            path: '/sales',
            exact: true,
            component: SaleFiguresContainer,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can([PERMISSION.PURCHASE_REPORT_MANAGE])],
        },
        {
            name: 'orders.export',
            path: '/orders/export',
            exact: true,
            component: ExportOrder,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, canAny([PERMISSION.TOOLS_EXPORT_ORDER_TRANSACTION, PERMISSION.TOOLS_EXPORT_ACTUAL_AMOUNT])],
        },
        {
            name: 'purchaser.assign_account_purchaser',
            path: '/purchasers/assign-account-purchaser',
            exact: true,
            component: ListAssignPurchaser,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, can(PERMISSION.PURCHASER_MANAGE)],
        },
        {
            name: 'salereport',
            path: '/sale-report',
            exact: true,
            component: SaleReport,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, canAny([PERMISSION.PURCHASE_REPORT_MANAGE, PERMISSION.PURCHASE_REPORT_VIEW])],
        },
        {
            name: 'order.invoice_code_change',
            path: '/invoice-code-approvals',
            exact: true,
            component: InvoiceCodeApprovals,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser, canAny([PERMISSION.ORDER_VIEW, PERMISSION.ORDER_VIEW_ASSIGNED, PERMISSION.ORDER_VIEW_ONLY_ACCEPTED])],
        },
        {
            name: 'promotions',
            path: '/promotions',
            exact: true,
            component: PromotionList,
            layout: AtlakitNavbar,
            middleware: [checkIsLogin, loadAuthenticatedUser],
        },
        {
            group: {
                layout: SettingLayout,
                middleware: [checkIsLogin, loadAuthenticatedUser],
                routes: [
                    {
                        name: 'AccountPurchaser',
                        path: '/account-purchaser/',
                        exact: true,
                        component: AccountPurchaser,
                        middleware: [can(PERMISSION.ACCOUNT_PURCHASER_VIEW)],
                    },
                    {
                        name: 'purchase-address',
                        path: '/purchase-address',
                        component: PurchaseAddressContainer,
                        middleware: [can(PERMISSION.PURCHASE_ADDRESS_VIEW)],
                    },
                    {
                        name: 'purchase-address-detail',
                        path: '/purchase-address/:id',
                        component: PurchaseAddressContainer,
                        middleware: [can(PERMISSION.PURCHASE_ADDRESS_VIEW)],
                    },
                    {
                        name: 'orderGroup.manager',
                        path: '/order-group',
                        exact: true,
                        component: OrderGroup,
                        layout: AtlakitNavbar,
                        middleware: [can(PERMISSION.ORDER_GROUP_VIEW)],
                    },
                    {
                        name: 'agency',
                        path: '/agency',
                        exact: true,
                        component: AgencyContainer,
                        layout: AtlakitNavbar,
                        middleware: [can(PERMISSION.SETTING_AGENCY)],
                    },
                    {
                        name: 'setting-partner',
                        path: '/setting-partner',
                        exact: true,
                        component: PartnerSetting,
                        middleware: [can(PERMISSION.SETTING_GENERAL)]
                    },
                    {
                        name: 'setting-buying',
                        path: '/setting-buying',
                        exact: true,
                        component: BuyingSetting,
                        middleware: [can(PERMISSION.SETTING_BUYING)]
                    },
                    {
                        name: 'setting-transaction',
                        path: '/setting-transaction',
                        exact: true,
                        component: TransactionSetting,
                        middleware: [can(PERMISSION.SETTING_TRANSACTIONS)]
                    },
                    {
                        name: 'setting-private',
                        path: '/setting-private',
                        exact: true,
                        component: SettingPrivateContainer, // old UserSettingContainer
                    },
                    {
                        name: 'account_purchaser.token',
                        path: '/account-purchaser-token',
                        exact: true,
                        component: ListAccountPurchaserToken,
                        middleware: [can(PERMISSION.SETTING_ACCESS_TOKEN_ALIBABA)],
                    },
                    {
                        name: 'slow_purchasing_reason',
                        path: '/slow-purchasing-reason',
                        exact: true,
                        component: SlowPurchasingReasonContainer,
                        middleware: [can(PERMISSION.SETTING_VIOLATION_CAUSE)],
                    },
                    {
                        name: 'bank_account',
                        path: '/bank-account',
                        exact: true,
                        component: BankAccountContainer,
                        middleware: [can(PERMISSION.SETTING_BANK_ACCOUNTS)],
                    },
                    {
                        name: 'group_manage',
                        path: '/group-manage',
                        exact: true,
                        component: GroupManage,
                        middleware: [can(PERMISSION.PAYMENT_REQUEST_GROUP_MANAGE)],
                    },
                    {
                        name: 'group_manage_detail',
                        path: '/group-manage/:groupId',
                        exact: true,
                        component: GroupManageDetail,
                        middleware: [can(PERMISSION.PAYMENT_REQUEST_GROUP_MANAGE)],
                    },
                    {
                        name: 'connect-foreign-market',
                        path: '/connect-foreign',
                        exact: true,
                        component: ConnectForeign,
                        middleware: [can(PERMISSION.TAOBAO_GET_PURCHASE_ORDER)],
                    },
                ],
            },
        },
    ],
    defaultRoute: {
        component: NotFound,
    },
    middleware: [
        (payload, next) => {
            // Cách lấy ra 1 URL trong project url.to('login',{id:1})
            return commonMiddleware(payload, next, router, i18n);
        },
        switchDefaultLayoutMiddleware
    ],
};
