import {processApiRequest} from '../../common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import {t} from '../../../systems/i18n';
import bootbox from './../../common/components/Bootbox/bootbox';

export function* generateToken(action) {
    yield processApiRequest(
        ACTION.GENERATE_TOKEN,
        () => apiService.generateToken(),
        action.payload
    );
}
/* eslint-disable */
export function* onGenerateTokenSuccess() {
    bootbox.success(t('user:access_token.message.generate_token_success'));
}

export function* onGenerateTokenFailed() {
    bootbox.error(t('user:access_token.message.generate_token_failed'));
}
