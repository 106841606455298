import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {notification} from "antd";
import Link from "../../../../systems/routing/Link";
import CreateProductReturns from './../../CreateProductReturn/CreateProductReturnContainer';
import {processResponse} from "../../../../systems/api";
import apiService from "../../apiService";
import moment from "moment";

const STATUS_CODE = {
    PROCESSING: 'Đang giải quyết',
    REFUND: 'Hoàn tiền',
    REJECT: 'Từ chối',
    NEW: 'Chờ tiếp nhận',
    ACCEPTED: 'Chấp nhận'
}

class ComplaintService extends React.Component {

    constructor(props) {
        super(props);
        this.state = { data: [] };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        let {order} = this.props;
        let process = processResponse(apiService.getComplaintServices(order.id));
        process.then(res => {
            this.setState({ data: res.data });
        });
        process.catch(err => {
            notification.error({message: "Tải dữ liệu KN dịch vụ không thành công!"});
        })
    }

    render() {
        let {t} = this.props;
        let {data} = this.state;

        return (
            <React.Fragment>
                <div className="complaint-seller-box a-content--box-shadow p-0 h-100">
                    {/*<div className="a__complaint__detail__solution">*/}
                        <div className="cs-box-title d-flex align-item-center p-3 pt-2 pb-2 a-text--bold">
                            <p className="a-text--fz-14 d-flex align-item-center a-text--uppercase">
                                {t('complaintSeller:complaint_service.complaint_service_title')}
                            </p>
                        </div>
                        <div className="table-responsive border-top">
                            <table className="table a-table--list_complaint_solution">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t('complaintSeller:complaint_service.name')}</th>
                                        <th>{t('complaintSeller:complaint_service.state')}</th>
                                        <th style={{paddingLeft: '.5rem'}}>{t('complaintSeller:complaint_service.createdAt')}</th>
                                        <th>{t('complaintSeller:complaint_service.description')}</th>
                                        <th>{t('complaintSeller:complaint_service.receivedStaff')}</th>
                                        <th>{t('complaintSeller:complaint_service.deadline')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.length > 0 ?
                                            data.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td><a href={item.url} target="_blank">{item.code}</a></td>
                                                            <td>{item.name}</td>
                                                            <td>{STATUS_CODE[item.state]}</td>
                                                            <td>{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                                                            <td>{lodash.get(item, 'description', '')}</td>
                                                            <td>{lodash.get(item, 'receivedStaff.username', '')}</td>
                                                            <td>{item.deadline ? moment(item.deadline).format('DD/MM/YYYY HH:mm') : ''}</td>
                                                        </tr>
                                                    </>
                                                );
                                            }) :
                                            <tr style={{borderBottom: '1px solid #f2f2f2' }}>
                                                <td className="text-center d-table-cell a-font--12 a-text--font-primary" colSpan={7}>{t('complaintSeller:product_return.not_found_data')}</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    {/*</div>*/}
                </div>
            </React.Fragment>

        );
    }
}

ComplaintService.defaultProps = {
    order: {},
    listProductReturns: [],

};

ComplaintService.propTypes = {
    order: PropTypes.object,
    listProductReturns: PropTypes.array,
};

export default translate()(ComplaintService);
