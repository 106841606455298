export const GET_LIST_ACCOUNT_PURCHASER = {
    REQUEST: 'GET_LIST_ACCOUNT_PURCHASER_REQUEST',
    SUCCESS: 'GET_LIST_ACCOUNT_PURCHASER_SUCCESS',
    FAILED: 'GET_LIST_ACCOUNT_PURCHASER_FAILED',
};

export const CREATE_ACCOUNT_PURCHASER = {
    REQUEST: 'CREATE_ACCOUNT_PURCHASER_REQUEST',
    SUCCESS: 'CREATE_ACCOUNT_PURCHASER_SUCCESS',
    FAILED: 'CREATE_ACCOUNT_PURCHASER_FAILED',
    ERROR: 'CREATE_ACCOUNT_PURCHASER_ERROR',
};

export const UPDATE_ACCOUNT_PURCHASER = {
    REQUEST: 'UPDATE_ACCOUNT_PURCHASER_REQUEST',
    SUCCESS: 'UPDATE_ACCOUNT_PURCHASER_SUCCESS',
    FAILED: 'UPDATE_ACCOUNT_PURCHASER_FAILED',
    ERROR: 'UPDATE_ACCOUNT_PURCHASER_ERROR',
};

export const CHANGE_STATUS_ACCOUNT_PURCHASER = {
    REQUEST: 'CHANGE_STATUS_ACCOUNT_PURCHASER_REQUEST',
    SUCCESS: 'CHANGE_STATUS_ACCOUNT_PURCHASER_SUCCESS',
    FAILED: 'CHANGE_STATUS_ACCOUNT_PURCHASER_FAILED',
};

export const STATUS_ACCOUNT_PURCHASER = [
    {label: 'ACTIVE', value: 'ACTIVE'},
    {label: 'INACTIVE', value: 'INACTIVE'}
];

export const SOURCE_ACCOUNT_PURCHASER = [
    {label: 'Taobao', value: 'Taobao'},
    {label: 'Tmall', value: 'Tmall'},
    {label: '1688', value: '1688'}
];

export const OPEN_MODAL_ACCOUNT_PURCHASER = "OPEN_MODAL_ACCOUNT_PURCHASER";
export const CLOSE_MODAL_ACCOUNT_PURCHASER = "CLOSE_MODAL_ACCOUNT_PURCHASER";
export const CLEAR_ERROR_ACCOUNT_PURCHASER = "CLEAR_ERROR_ACCOUNT_PURCHASER";