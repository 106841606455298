import React from "react";
import lodash from "lodash";

import handleProcessPayment from "./HandleProcessPayment";
import {Modal, Button, Input, notification} from "antd";
import {translate} from "react-i18next";
import {events, withEvent} from "../../../systems/events";
import {AUTO_PAYMENT_SYNC_EVENT} from "../constans";
import apiService from "../apiService";
import {processResponse} from "../../../systems/api";
import PAYMENT_REQUEST_EVENT from "../events";

const DEFAULT_INDEX = -1;

class ProcessPaymentsRequestAuto extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentIndex: DEFAULT_INDEX,
            visible: false,
            pin: undefined
        };
        this.process = new handleProcessPayment();
    }

    componentDidMount() {
        this.props.on([
            AUTO_PAYMENT_SYNC_EVENT.DONT_LOGIN_SITE,
            AUTO_PAYMENT_SYNC_EVENT.NO_PIN_CODE,
            AUTO_PAYMENT_SYNC_EVENT.FAILED,
            AUTO_PAYMENT_SYNC_EVENT.SUCCESS,
            AUTO_PAYMENT_SYNC_EVENT.SET_PIN_SUCCESS
        ], this.handleEventListener.bind(this));
    }

    componentDidUpdate(prevProps, prevState) {

        let {
            isProcessing,
            paymentRequests,
        } = this.props;
        let oldPaymentRequests = lodash.get(prevProps, "paymentRequests", []);
        let {currentIndex} = this.state;

        if (isProcessing && paymentRequests && JSON.stringify(oldPaymentRequests) !== JSON.stringify(paymentRequests) && currentIndex === DEFAULT_INDEX) {
            this.startProcessing();
        }
    }

    componentWillUnmount() {
        this.process.terminate();
    }

    handleEventListener(event, payload) {
        let {pushSuccessRequestIds, pushFailedRequestIds, t, setIsProcessing} = this.props;
        switch (event) {
            case AUTO_PAYMENT_SYNC_EVENT.DONT_LOGIN_SITE: {
                const sellerSource = lodash.get(payload, "request.sellerSource", "");
                setIsProcessing(true, this.next.bind(this));
                notification.error({message: `Vui lòng đăng nhập site gốc ${sellerSource} để tiến hành xử lý YCTT`});
                return;
            }
            case AUTO_PAYMENT_SYNC_EVENT.NO_PIN_CODE: {
                this.setState({visible: true});
                return;
            }
            case AUTO_PAYMENT_SYNC_EVENT.FAILED: {
                if (lodash.get(payload, "request.id")) {
                    pushFailedRequestIds(lodash.get(payload, "request.id"));
                }
                notification.error({message: lodash.get(payload, "message", "")});
                this.next();
                return;
            }
            case AUTO_PAYMENT_SYNC_EVENT.SUCCESS: {
                const request = lodash.get(payload, "request", {});
                const requestId = lodash.get(request, "id");
                console.log("payload", payload);
                if (requestId) {
                    let res = apiService.changeStatePaymentRequest(requestId, {status: "SUCCESS"})
                        .finally(() => this.setState({loading: false}));

                    let process = processResponse(res);

                    process.then(res => {
                        pushSuccessRequestIds(request.id);
                        notification.success({message: lodash.get(payload, "message", "")});
                        // events.dispatch(lodash.get(PAYMENT_REQUEST_EVENT, `SUCCESS`, ''), {
                        //     request: {...res.data},
                        //     oldStatus: 'PROCESSING'
                        // });
                    });

                    process.catch((res) => {
                        let {code, data} = res;
                        data.hasOwnProperty('UNAUTHORIZED') ?
                            notification.error({message: t('paymentRequest:error.unauthorized')}) :
                            (
                                (code === "INPUT_INVALID") ?
                                    notification.error({message: t('paymentRequest:confirm_payment.INVALID')})
                                    :
                                    notification.error({message: t('message.cant_update_data')})
                            )

                    });
                } else {
                    notification.error({message: "Có lỗi xảy ra trong quá trình thanh toán"})
                }
                this.next();
                return;
            }
            case AUTO_PAYMENT_SYNC_EVENT.SET_PIN_SUCCESS: {
                this.setState({visible: false});
                this.process.reboot();
                return;
            }
            case AUTO_PAYMENT_SYNC_EVENT.CLOSE_PROCESSING_TAB: {
                this.next();
                return;
            }
        }
    }

    onClick() {
        this.startProcessing();
    }

    startProcessing() {
        let {isProcessing, setIsProcessing} = this.props;

        if (!isProcessing)
            setIsProcessing(true, this.next.bind(this));
        else
            this.next();
    }

    next() {
        let {currentIndex} = this.state;
        let {
            paymentRequests,
            setProcessingRequestId,
            isProcessing,
            selectedRequestIds
        } = this.props;
        if (selectedRequestIds.length > 0) {
            paymentRequests = paymentRequests.filter(item => selectedRequestIds.includes(item.id));
        }
        let nextIndex = currentIndex + 1;

        if (!isProcessing)
            return;
        console.log("paymentRequests", paymentRequests);
        console.log("nextIndex", nextIndex);
        if (nextIndex < paymentRequests.length) {
            /*
            * Tăng index cho YCTT lần tới
            * Bắt đầu process YCTT hiện tại
            */
            let request = paymentRequests[nextIndex];
            this.setState({currentIndex: nextIndex}, () => {
                setProcessingRequestId(request.id);
                this.process.boot(request);
            });
        } else {
            this.nextPage();
        }
    }

    nextPage() {
        let {pagination, onNextPage, selectedRequestIds} = this.props;
        if (selectedRequestIds.length > 0) {
            this.reset();
        } else {
            if (pagination.current_page >= pagination.page_total) {
                this.reset();
            } else {
                this.reset(true);
                setTimeout(() => {
                    onNextPage();
                }, 3000)

            }
        }
    }

    reset(processing = false) {
        let {setProcessingRequestId, setIsProcessing} = this.props;

        this.setState({currentIndex: DEFAULT_INDEX});
        if (!processing)
            setIsProcessing(false);

        setProcessingRequestId(undefined);
    }

    onCancel() {
        this.process.cancel();
        this.reset();
    }

    handleSetPinBySource() {
        const {pin} = this.state;
        this.process.handleSetPin(pin);
    }

    handleCancelSetPinBySource() {
        this.setState({visible: false})
    }

    render() {
        const {t, isProcessing} = this.props;
        const {visible, pin} = this.state;
        return (
            <React.Fragment>
                <Button
                    className='a-font--12 aphz-24 a-btn--primary-ant ml-2 _payment-request-processing-auto'
                    type="primary"
                    style={{paddingTop: 1, marginRight: 3}}
                    disabled={isProcessing}
                    loading={isProcessing}
                    onClick={this.onClick.bind(this)}
                    size='small'
                >
                    {t('paymentRequest:btn.payment_auto')}
                </Button>
                <Button
                    disabled={!isProcessing}
                    className='a-font--12 a-btn--primary-ant a-btn--link a-text--red ml-2 _payment-request-processing-auto-cancel'
                    style={{paddingTop: 1, marginRight: 3}}
                    onClick={this.onCancel.bind(this)}
                    size='small'
                >
                    {t('btn.cancel')}
                </Button>
                {
                    visible &&
                    <Modal
                        title={"Vui lòng nhập mã pin cho tài khoản thanh toán"}
                        visible={visible}
                        okText="Đồng ý"
                        cancelText="Huỷ"
                        onOk={this.handleSetPinBySource.bind(this)}
                        onCancel={this.handleCancelSetPinBySource.bind(this)}
                    >
                        <Input.Password
                            value={pin}
                            onChange={(e) => this.setState({pin: e.target.value})}
                        />
                    </Modal>
                }
            </React.Fragment>
        );
    }


}

export default translate()(withEvent(ProcessPaymentsRequestAuto));
