import React from 'react';
import {Icon} from 'antd';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import * as UploadConstant from './../../UploadFileCSV/constants';


class UploadFileCSVResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            openboxupload: true,
            numFile: 0
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        let numFile = nextProps.list.length;
        if (prevState.openboxupload === true && prevState.numFile !== numFile && numFile !== 0) {
            nextState.openboxupload = false;
            nextState.isShow = true;
        }

        nextState.numFile = numFile;
        return nextState;
    }

    onClickFormListFileUpload = () => {
        this.setState({openboxupload: !this.state.openboxupload});
    };

    componentWillUnmount() {
        this.props.clearState();
    }

    clearState() {
        this.setState({openboxupload: true, isShow: false});
        this.props.clearState();
    }



    render() {
        let {t, list} = this.props;
        let uploadingNum = list.filter((file) => {
            return file.status === UploadConstant.STATUS_UPLOAD_UPLOADING;
        }).length;
        return (
            <div
                className={`list-block-file-upload ${this.state.openboxupload ? "collapsed" : ""} ${this.state.isShow ? "" : " hidden"}`}>
                <div className="title">
                    <span>{t("transaction:message.uploaded", {
                        num: list.filter((file) => {
                            return file.status === UploadConstant.STATUS_UPLOAD_DONE;
                        }).length
                    })}</span>
                    <div className="action">
                        <Icon type={`${this.state.openboxupload ? "up" : "down"}`}
                              onClick={this.onClickFormListFileUpload}/>
                        <Icon type="close" onClick={this.clearState.bind(this)}/>
                    </div>
                </div>
                <div className="body">
                    {uploadingNum !== 0 && <div className="body-top">
                        <span className="number-uploading">{t("transaction:message.uploading", {
                            num: uploadingNum
                        })}</span>
                    </div>}
                    <ul className="list-file-upload">
                        {list.map((file) => {
                            return (
                                <li>
                                    <span
                                        className={"file-name-upload " + (file.status === UploadConstant.STATUS_UPLOAD_ERROR ? "a-text--red" : "")}>{file.name}</span>
                                    {file.status === UploadConstant.STATUS_UPLOAD_UPLOADING &&
                                    <Icon type="loading" className="icon-upload"/>}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

        )
    }
}

UploadFileCSVResult.defaultProps = {
    loading: false,
    list: [],
};

UploadFileCSVResult.propTypes = {
    loading: PropTypes.bool,
    list: PropTypes.array,
    clearState: PropTypes.func
};

export default translate()(UploadFileCSVResult);