import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import bootbox from './Bootbox/bootbox';

class Copy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            copied: false,
        }
    }

    componentWillMount() {
        this.interval = setInterval(() => {
            this.setState({copied: false})
        }, 1500);
    }

    render() {
        let {t, contentNote, classText} = this.props;
        return (
            <div className="content-copy">
                <CopyToClipboard text={contentNote} onCopy={() => {
                    bootbox.success(t("message.copied") + ": " + contentNote)
                }}>
                    <p onChange={({target: {value}}) => this.setState({value, copied: false})}
                       readOnly={true}
                       className={classText}>
                        {contentNote}
                        <span
                            className={"ml-2 a-text--font-b content-btn-copy "
                            + ( (contentNote === '' || contentNote === '--') ? 'hidden' : '')}>
                        <i className="far fa-copy"/>
                    </span>
                    </p>
                </CopyToClipboard>
            </div>
        )
    }
}


Copy.defaultProps = {
    contentNote: null,
    isDefaultShow: false,
    classText: "a-text--red mr-3"
};

Copy.propTypes = {
    isDefaultShow: PropTypes.bool,
    classText: PropTypes.string,
    contentNote: PropTypes.string,
};

export default translate('translations')(Copy);
