import {dispatch} from '../store'
import {router} from './../routing';
import {eventUpdateLayout} from '../../modules/common/index'
import url from 'url';
let layouts = {
    default: {
        class_body: 'full-screen menu-position-side menu-side-left',
        class_wrapper: 'all-wrapper solid-bg-all',
        class_menu: 'menu-w selected-menu-color-light menu-has-selected-link color-scheme-light color-style-default sub-menu-color-light menu-position-side menu-side-left menu-layout-compact sub-menu-style-flyout menu-activated-on-hover'
    },
    dark: {
        class_body: 'full-screen with-content-panel menu-position-top',
        class_wrapper: 'all-wrapper with-side-panel solid-bg-all',
        class_menu: 'menu-w selected-menu-color-light menu-activated-on-hover menu-has-selected-link color-scheme-dark color-style-bright sub-menu-color-bright menu-position-top menu-layout-full sub-menu-style-over'
    }
};

/**
 *
 * Đoạn code dưới có nhiệm vụ là lấy động các layout tương ứng với domain đang sử dụng <br />
 *
 * @param payload
 * @param next
 * @returns {*}
 */
let switchDefaultLayoutMiddleware = (payload, next) => {
    let {request} = payload;
    let fullUrl = window.location.href;
    let infoDomain = url.parse(fullUrl);

    localStorage.setItem('domain', infoDomain.hostname);
    
    // Update lại layout của theme theo URL request
    let custom_layout = typeof request.route.custom_layout !== 'undefined' ? request.route.custom_layout : 'dark';
    dispatch(eventUpdateLayout(getCurrentLayout(custom_layout)));

    // Đổi lại body class theo URL
    switchBodyClass(custom_layout);

    return (next(payload));
}

let getCurrentLayout = (custom_layout) => {
    let current_layout = "";
    if (typeof layouts[custom_layout] === 'undefined') {
        current_layout = layouts['default'];
    } else {
        current_layout = layouts[custom_layout];
    }

    return current_layout;
}

let switchBodyClass = (custom_layout) => {
    if (router.history.location.pathname === "/" ) {
        document.body.classList.add('auth-wrapper');
    } else {
        document.body.className = getCurrentLayout(custom_layout).class_body;
        document.body.classList.remove('with-content-panel');
    }
}

export default switchDefaultLayoutMiddleware;