import {createSelector} from 'reselect';
import lodash from "lodash";
import {dateFormatter} from "../common/services/format";
import * as Constants from './constants';

const salesFiguresSelector = state => lodash.get(state, 'SalesFigures.list', []);
const customerState = state => lodash.get(state, "User.me", {});

/**
 * Get list order
 */
export const getSalesFigures = createSelector(
    salesFiguresSelector,
    items => lodash.compact(items.map(item => item.status === 'COMPLETED' ? salesFigureDetail(item) : null))
);

function salesFigureDetail({...item}) {
    item.month_text = lodash.toString(item.month).length === 1 ? '0' + item.month : item.month;
    item.year_text = lodash.get(item, 'year', '');
    item.year = lodash.toInteger(lodash.get(item, 'year', ''));
    item.created_at = dateFormatter.standa(item.created_at);
    return item;
}

export const getUserSelect = createSelector(
    customerState,
    user => user.user
);

export const getCreateSaleFiguresChannel = createSelector(
    getUserSelect,
    user => {
        return (user) ?
            [Constants.GET_PUSHER_REPORT + "_" + user.id_partner] : [];
    }
);
