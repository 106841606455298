import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import LogMessage from './LogMessage';
import lodash from 'lodash';
import {Tooltip} from "antd";

class Log extends React.Component {
    render() {
        let {log = {}} = this.props;
        let arrIncludeComment = ["COMMENT", "COMMENT.ADD_NOTE_ITEM"];
        return lodash.includes(arrIncludeComment, log.action) ? this.renderComment() : this.renderLog();
    }

    /**
     * Hiển thị comment
     */
    renderComment() {
        let {log} = this.props;
        let {creator = {}} = log;
        let is_admin = lodash.get(creator, 'is_admin', null);
        let avatar = lodash.get(creator, 'avatar', '');
        return (
            <div className="a-chat__block">
                {/*<div className={'a-chat__info--avatar'}>*/}
                {/*    {*/}
                {/*        (avatar) ? <img src={avatar}/> :*/}
                {/*            <i className="fa fa-comments" aria-hidden="true"></i>*/}
                {/*    }*/}
                {/*</div>*/}
                <div  className={'a-chat__info--content'}>
                    <div className={'a-chat__info'}>
                        <p className={'a-chat__info--author'+(is_admin?"":" customer")}>
                            {
                                !is_admin ?
                                    <>
                                        <img className={'a-chat__info--customer-icon'} src={require('../../../../resources/img/crown.png')} alt=""/>
                                        <strong className="a-text--red"><Tooltip title={creator.username}>KH. {creator.name}</Tooltip></strong><span>, {log.time_format}</span>
                                    </> :
                                    <>
                                        <strong className="a-text--color-black"><Tooltip title={creator.username}>{creator.name}</Tooltip></strong><span>, {log.time_format}</span>
                                    </>
                                }
                        </p>
                    </div>

                    <div className={"a-chat__comment"+(is_admin?"":" customer")}>
                        <p><LogMessage log={log}/></p>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Hiển thị log
     */
    renderLog() {
        let {log} = this.props;
        let creator = lodash.get(log, "creator", {});
        let is_admin = lodash.get(creator, 'is_admin', null);
        return (
            <div className={(creator.username === null || creator.username === "" ? "system" : "") + " a-chat__block"}>
                {/*<div  className={'a-chat__info--avatar'}>*/}
                {/*     <i className="fa fa-user-circle" aria-hidden="true"></i>*/}
                {/*</div>*/}

                <div  className={'a-chat__info--content'}>
                    <div className={'a-chat__info'}>
                        <p className={'a-chat__info--author '+(is_admin?"":"customer")}>
                            {creator.username === null || creator.username === "" ? "System" : creator.name},  <span>{log.time_format}</span>
                        </p>
                    </div>
                    <div className={"a-chat__comment"+(is_admin?"":" customer")}>
                        <p className={"system"}><LogMessage log={log}/></p>
                    </div>
                </div>
            </div>
        );
    }
}

Log.defaultProps = {
    log: {},
    onCollapse: () => {
    },
};

Log.propTypes = {
    log: PropTypes.object.isRequired,
    onCollapse: PropTypes.func,
};

export default translate()(Log);
