import lodash from 'lodash';
import apiService from '../apiService';

export default class AccountPurchaserService {
    constructor() {
        this.accounts = [];
        this.loaded = false;
    }

    list() {
        if (this.loaded) {
            return Promise.resolve(this.accounts);
        }

        return apiService.getListAccountPurchaser()
            .then(res => {
                this.loaded = true;
                this.accounts = lodash.get(res, 'data.data') || [];

                return this.accounts;
            });
    }

    listActive() {
        return this.list().then(accounts => accounts.filter(account => account.status === 'ACTIVE'));
    }
}
