import React from "react";
import DislikeButton from "./DislikeButton";
import LikeButton from "./LikeButton";
import lodash from "lodash";
import "./style.scss";
import moment from "moment";
import {UPDATE_AFTER_VOTING_SHOP} from "../../constants";
import {connect} from "react-redux";

class VoteShop extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {seller, me, updateOrderDetail} = this.props;
        let votes = lodash.sortBy(lodash.get(seller, "votes", []), ['created_at']).reverse();
        let id_creators = votes.map(vote => vote.id_creator);

        return (
            <div className={`vote-shop ${this.props.className}`}>
                {
                    lodash.includes(id_creators, me.id) ?
                        <LikeButton
                            seller={seller}
                            updateOrderDetail={updateOrderDetail}
                        /> :
                        <DislikeButton
                            seller={seller}
                            updateOrderDetail={updateOrderDetail}
                        />
                }
                {
                    votes.length > 0 &&
                    <div className="vote-list a-text--fz-12">
                        <ul className={`p-2 a-text--color-black-45 ${votes.length > 5 ? 'sroller' : ''}`}>
                            {
                                votes.map(item => {
                                    let fullname = lodash.get(item, "creator.name", "--");
                                    return <li>
                                        <p>
                                            <strong className="a-text--color-black-65">{fullname}</strong>, {moment(item.created_at).format("HH:mm DD/MM/YYYY")}
                                        </p>
                                        <div className="a-text--color-black-65">{item.note}</div>
                                    </li>;
                                })
                            }
                        </ul>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        me: lodash.get(state, "User.me.user", {})
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateOrderDetail: res => dispatch({
            type: UPDATE_AFTER_VOTING_SHOP,
            payload: res
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VoteShop);

