import {ORDER_STATUS} from '../constants';

export default class OrderService {
    constructor(orderStatus) {
        this.orderStatus = orderStatus;
    }

    canAssign(order) {
        return !order.id_teller && this.orderStatus.isBefore(order.status, ORDER_STATUS.BOUGHT);
    }

    canUnassign(order) {
        return order.id_teller && this.orderStatus.isBefore(order.status, ORDER_STATUS.BOUGHT);
    }

    canAssignForce(order) {
        return !order.id_teller && this.orderStatus.isBeforeOrEqual(order.status, ORDER_STATUS.DELIVERED);
    }

    canUnassignForce(order) {
        return order.id_teller && this.orderStatus.isBeforeOrEqual(order.status, ORDER_STATUS.DELIVERED);
    }
}