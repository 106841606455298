import React, {useEffect, useState} from "react";
import lodash from "lodash";
import {connect} from "react-redux";
import InlineEdit2 from "../../../common/components/InlineEdit2";
import {processResponse} from "../../../../systems/api";
import apiService from "../../../PurchaseAddress/apiService";
import apiServiceOrder from "../apiService";
import {notification, Select} from "antd";
import {t} from "../../../../systems/i18n";
import {updatePurchaseAddress} from "../actions";
import ConfirmDeleteSTOrder from "../../../ForeignOrder/ConfirmDeleteSTOrder";

const {Option} = Select;

function InlinePurchaseAddress({order, getPurchasingOrderDetailAction, updatePurchaseAddress}) {
    const [edit, setEdit] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [purchaseAddresses, setPurchaseAddresses] = useState([]);
    const purchase_address = lodash.get(order, "purchase_address", {});

    useEffect(() => {
        fetchPurchaseAddress();
    }, []);

    function fetchPurchaseAddress() {
        let {id_agency} = order;
        setLoading(true);
        processResponse(apiService.getPurchaseAddressList({agency_id: id_agency}))
            .then(res => setPurchaseAddresses(lodash.get(res, "data", [])))
            .catch(err => notification.error(t('message.cant_fetch_data')))
            .finally(() => {
                setLoading(false);
            });
    }

    function _onChange(purchase_address_id) {
        setLoading(true);
        processResponse(apiServiceOrder.updatePurchaseAddress(order.id, {purchase_address_id}))
            .then(res => {
                updatePurchaseAddress(lodash.get(res, "data", {}));
                const canCancelTaobaoPurchaseOrder = lodash.get(res, "data.canCancelTaobaoPurchaseOrder", false);
                if (canCancelTaobaoPurchaseOrder) {
                    setVisible(true);
                }
            })
            .catch(err => {
                const connect_error = lodash.get(err, "data.connect_error", "");
                if (!lodash.isEmpty(connect_error)) {
                    notification.error({message: t('orderDetail:errors.purchase_address.connect_error')})
                } else {
                    notification.error({message: t('message.cant_update_data')})
                }
            }).finally(() => {
            setEdit(false);
            setLoading(false);
        });
    }

    function _onBlur() {
        setEdit(false);
    }

    return (
        <>
            <InlineEdit2
                text={lodash.get(order, 'purchase_address.alias', '---')}
                isEdit={edit}
                setIsEdit={setEdit}
                can={order.id_agency && order.canChangePurchaseAddress}
                className="_purchase-buying-purchase-address-inline-edit"
            >
                <Select
                    value={purchase_address ? purchase_address.id : undefined}
                    showSearch={true}
                    optionFilterProp={"children"}
                    allowClear={true}
                    loading={loading}
                    onChange={_onChange}
                    placeholder="Chọn kho nhận"
                    size="small"
                    style={{width: "200px"}}
                    autoFocus={true}
                    onBlur={_onBlur}
                    className="_purchase-buying-purchase-address-select"
                >
                    {
                        purchaseAddresses.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)
                    }
                </Select>
            </InlineEdit2>
            {
                visible &&
                <ConfirmDeleteSTOrder visible={visible} dataOrder={order} setVisible={setVisible} onSubmit={getPurchasingOrderDetailAction}/>
            }
        </>
    );
}

const mapDispatchToProp = (dispatch) => {
    return {
        updatePurchaseAddress: data => dispatch(updatePurchaseAddress(data))
    };
}

export default connect(null, mapDispatchToProp)(InlinePurchaseAddress);
