import {call, put} from 'redux-saga/effects';
import * as Constants from './constants';
import * as GlobalConstants from '../../../systems/globalContant';
import bootbox from './../../common/components/Bootbox/bootbox';
import {t} from './../../../systems/i18n/index.js';
import apiService from './apiService';
import {dispatch} from './../../../systems/store';
import {fetchComplaintDetail, fetchComplaintLogs} from '../actions';

/**
 * Cập nhật khiếu nại người bán thành
 * @param action
 */
export function* updateComplaintSellerSuccess(action) {
    try {
        let response = yield call(apiService.updateComplaintSellerSuccess, action.payload);
        if(!response) {
            return;
        }
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            bootbox.success(t('complaintSeller:modal.update_ComplaintSeller_success_success'));
            yield put({type: Constants.UPDATE_COMPLAINT_SELLER_SUCCESS.SUCCESS, payload: response.data, request: action.payload});
        } else {
            yield put({type: Constants.UPDATE_COMPLAINT_SELLER_SUCCESS.FAILED, payload: response.data, request: action.payload});
        }
    } catch (err) {
        yield put({type: Constants.UPDATE_COMPLAINT_SELLER_SUCCESS.FAILED, payload: {}, request: action.payload});
        bootbox.error(t(err.message) || t('system.error'));

    }
}

export function* onUpdateComplaintSellerSuccess(action) {
    let {id} = action.request;
    yield put(fetchComplaintDetail({id}));
    setTimeout(() => dispatch(fetchComplaintLogs(id)), 2000);
}

