import React from 'react';
import {translate} from 'react-i18next';
import {Col, Row} from "antd";
import PropTypes from 'prop-types';
import ChangePaymentInput from "./ChangePaymentInput";
import lodash from "lodash"
class UpdateFinanliOrder extends React.Component {
    onSubmitPayment(datas) {
        // const orderId = lodash.get(this, 'props.dataOrder.id', '')
        // this.props.getPurchasingOrderDetailAction({id: orderId})
        return this.props.updateRealPaymentAmount(datas);
    }

    onSubmitRefund(datas) {
        // const orderId = lodash.get(this, 'props.dataOrder.id', '')
        // this.props.getPurchasingOrderDetailAction({id: orderId})
        return this.props.updateRealRefundAmount(datas);
    }

    render() {
        let {isLoading, dataOrder} = this.props;
        
        const canUpdateRealPaymentAmount = lodash.get(dataOrder, 'canUpdateRealPaymentAmount', true)

        return <Row>
            <Col span={12}>
                <ChangePaymentInput
                    label={"real_payment_amount"}
                    isLoading={isLoading}
                    dataOrder={dataOrder}
                    handleUpdate={this.onSubmitPayment.bind(this)}
                    disableUpdateRealPaymentAmount={!canUpdateRealPaymentAmount}
                />
            </Col>
            <Col span={12} style={{paddingLeft: "15px"}}>
                <ChangePaymentInput
                    label={"real_refund_amount"}
                    isLoading={isLoading}
                    dataOrder={dataOrder}
                    handleUpdate={this.onSubmitRefund.bind(this)}
                />
            </Col>
        </Row>;
    }
}

UpdateFinanliOrder.defaultProps = {
    dataOrder: {},
    isLoading: false,
};

UpdateFinanliOrder.protoType = {
    dataOrder: PropTypes.object,
    isLoading: PropTypes.bool,
    updateRealPaymentAmount: PropTypes.func,
    updateRealRefundAmount: PropTypes.func,

};

export default translate('translations')(UpdateFinanliOrder);
