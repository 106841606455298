import {takeLatest, all, fork} from 'redux-saga/effects';
import * as saga from './saga';
import * as ACTION from './constants';
import updateShippingBillWatcher from './UpdateShippingBill/sagaWatcher';
import managerPackageWatcher from './ManagerPackages/sagaWatcher';

export default function*() {
    yield all([
        fork(updateShippingBillWatcher),
        fork(managerPackageWatcher),
    ]);

    yield takeLatest(ACTION.FETCH_COMPLAINT_PRODUCT_RETURN.REQUEST, saga.fetchProductReturn);
    yield takeLatest(ACTION.FETCH_LIST_PRODUCT_RETURNS.REQUEST, saga.fetchListProductReturns);
    yield takeLatest(ACTION.UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.REQUEST, saga.updateStatusProductReturn);
    yield takeLatest(ACTION.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.REQUEST, saga.updateInfoProductReturn);
}
