import {apiWithToken} from '../../systems/api';
import {API_URL} from './../../systems/globalContant';

export default {
    getPurchaseReport: (params) => apiWithToken.get(API_URL.DEFAULT + `/orders/purchase-report`, {
        params,
        singleRequest: true,
        loading: false
    }),
    exportCSV: (params) => apiWithToken.post(API_URL.DEFAULT + `/orders/purchase-report`, {...params}),
    statsPurchaseReport: (params) => apiWithToken.get(API_URL.DEFAULT + `/orders/purchase-report-stats`, {params, singleRequest: true, loading: false})
};