import React, { useState, useEffect } from 'react'
import { Modal, Icon, Tooltip, Badge, Table, Divider } from 'antd'
import { translate } from 'react-i18next'
import { processResponse } from '../../../../systems/api'
import apiService from '../../apiService'
import { get, isEmpty, values, map } from 'lodash'
import { t } from '../../../../systems/i18n'
import ChoosePackage from './ChoosePackage'
import ChangePackageStatus from './ChangePackageStatus'
import PackageLog from './PackageLog/PackageLog'
import bootbox from '../../../common/components/Bootbox/bootbox'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import CreatePackage from './CreatePackage'
import UpdatePackageReturnInfo from './UpdatePackageReturnInfo'
import EditPackage from './EditPackage'
import { hasPermission, permissions } from '../../../Permission'

function ListPackages(props) {
    const { complaint, order } = props
    const canChangePackage = complaint.canChangePackage
    const [packages, setPackages] = useState([])
    const [loading, setLoading] = useState(false)
    const [visibleLog, setVisibleLog] = useState(false)
    const [packageCode, setPackageCode] = useState(undefined)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedPackages, setSelectedPackages] = useState([])
    const permissionCreatePackage = hasPermission(permissions.COMPLAINT_SELLER_CREATE_PACKAGE)

    useEffect(() => {
        getPackages()
    }, [])

    function getPackages() {
        setLoading(true)
        let res = apiService.getPackages(complaint.id)
        processResponse(res)
            .then(res => {
                setPackages(get(res, 'data', []))
            })
            .catch(() => {})
            .finally(() => setLoading(false))
    }

    function handleOpenLogPackage(packageCode) {
        setPackageCode(packageCode)
        setVisibleLog(true)
    }

    const columns = [
        {
            title: t('complaintSeller:label.package_code'),
            dataIndex: 'package_code',
            className: '_complaint-seller-detail-list-package-code',
            render: (text, record) => {
                const package_detail_url = get(record, 'package_detail_url', '')
                return (
                    <div className={'d-flex align-item-center'}>
                        {!isEmpty(package_detail_url) ? (
                            <a
                                target={'_blank'}
                                className="a-text--bold"
                                href={package_detail_url}>
                                {record.code}
                            </a>
                        ) : (
                            <p className="a--cursor-poiter a-text--bold a-text--font-blue">{record.code}</p>
                        )}
                        <CopyToClipboard
                            text={record.code}
                            onCopy={() => {
                                bootbox.success(t('message.copied') + ': ' + record.code)
                            }}>
                            <i className="far fa-copy copy a--cursor-poiter ml-1 _complaint-seller-detail-button-copy-package-code" />
                        </CopyToClipboard>
                    </div>
                )
            },
        },
        {
            title: t('complaintSeller:label.weight_net'),
            dataIndex: 'weight_net',
            className: '_complaint-seller-detail-list-package-weight-net',
            render: (text, record) => {
                const weight_net = get(record, 'weight_net', '')
                return <p>{weight_net ? weight_net + 'KG' : ''}</p>
            },
        },
        {
            title: t('complaintSeller:label.package_status'),
            dataIndex: 'status',
            className: '_complaint-seller-detail-list-package-status',
            render: (text, record) => {
                const return_goods_partner_code = get(record, 'return_goods_partner_code', '')
                const return_goods_partner_name = get(record, 'return_goods_partner_name', '')
                const status_transport = get(record, 'status_transport', '')
                const status_damaged = get(record, 'status_damaged', '')
                const return_address = get(record, 'return_address', '')
                const return_fee = get(record, 'return_fee', '')
                const return_tracking_bill = get(record, 'return_tracking_bill', '')
                const note = get(record, 'note', '')
                const isShowBagde = status_damaged !== 'liquidate' ? !!return_address || !!return_fee || !!return_tracking_bill || !!note : !!note
                const titleBagde = (
                    <Tooltip
                        title={
                            <>
                                {return_address && status_damaged !== 'liquidate' && (
                                    <p>{t('complaintSeller:label.return_address') + ': ' + return_address}</p>
                                )}
                                {!!return_fee && status_damaged !== 'liquidate' && <p>{t('complaintSeller:label.return_fee') + ': ' + return_fee}</p>}
                                {return_tracking_bill && status_damaged !== 'liquidate' && (
                                    <p>{t('complaintSeller:label.return_tracking_bill') + ': ' + return_tracking_bill}</p>
                                )}
                                {note && <p>{t('complaintSeller:label.note') + ': ' + note}</p>}
                                {(return_goods_partner_name || return_goods_partner_code) && (
                                    <p>
                                        {t('complaintSeller:label.return_goods_partner') + ': ' + `${return_goods_partner_name} (${return_goods_partner_code})`}
                                    </p>
                                )}
                            </>
                        }>
                        <Icon
                            type="info-circle"
                            className="pl-4"
                        />
                    </Tooltip>
                )
                return (
                    <p>
                        {isShowBagde ? (
                            <Badge
                                count={titleBagde}
                                className={'a-text--fz-12 _complaint-seller-detail-detail-info'}>
                                <span>{t(`complaintSeller:status_transport.${status_transport}`)}</span>
                            </Badge>
                        ) : (
                            <span>{t(`complaintSeller:status_transport.${status_transport}`)}</span>
                        )}
                    </p>
                )
            },
        },
        {
            title: t('complaintSeller:label.current_status'),
            dataIndex: 'current_warehouse',
            className: '_complaint-seller-detail-list-package-current-warehouse',
            render: (text, record) => {
                return <p>{get(record, 'current_warehouse', '')}</p>
            },
        },
        {
            title: t('complaintSeller:label.error_status'),
            dataIndex: 'error_status',
            className: '_complaint-seller-detail-list-package-error-status',
            render: (text, record) => {
                const status_damaged = get(record, 'status_damaged', '')
                return <p className="_complaint-seller-error-status-detail-info">{t(`complaintSeller:status_damaged.${status_damaged}`)}</p>
            },
        },
        {
            key: 'operation',
            fixed: 'right',
            width: 100,
            className: 'a-text--fz-12 _complaint-seller-detail-list-package-action',
            render: (text, record) => {
                const status_code = get(record, 'status_transport', '')
                const can_update_return = get(record, 'can_update_return', false)
                const canUpdatePackageStatus = !isEmpty(status_code) && status_code !== 'RETURNED' && status_code !== 'LIQUIDATED'
                const status_damaged_code = get(record, 'status_damaged', '')

                return (
                    <p className="d-flex">
                        {canUpdatePackageStatus && (
                            <ChangePackageStatus
                                complaintId={complaint.id}
                                packageCode={record.code}
                                updateListPackages={getPackages}
                                status_damaged_code={status_damaged_code}
                                can_update_return={can_update_return}
                            />
                        )}
                        {can_update_return  && (
                            <UpdatePackageReturnInfo
                                className="ml-2"
                                packageInfo={record}
                                complaintId={complaint.id}
                                updateListPackages={getPackages}
                            />
                        )}
                        <span className={'ml-2'}>
                            <Icon
                                className={'_complaint-seller-detail-button-open-logs'}
                                onClick={() => handleOpenLogPackage(record.code)}
                                type="message"
                            />
                        </span>
                    </p>
                )
            },
        },
    ]

    const onSelectChange = (selectedRowKeys, options) => {
        setSelectedPackages(options)
        setSelectedRowKeys(selectedRowKeys)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: record => ({
            disabled: !get(record, 'can_update_return', false), // Column configuration not to be checked
          }),
      };

    return (
        <div className="complaint-seller-box a-content--box-shadow p-0 h-100 mt-3">
            <div className="cs-box-title d-flex align-item-center p-3 pt-2 pb-2 a-text--bold">
                <p className="a-text--fz-14 a-text--uppercase">{t('complaintSeller:label.list_package')}</p>
                <div className='d-flex'> 
                    {
                        !isEmpty(selectedRowKeys) &&
                        <EditPackage packagesEdit={selectedPackages}  complaintId={complaint.id} getPackages={getPackages}/>
                    }

                    <Divider type="vertical" />
                {canChangePackage && (
                   <>
                   
                        <ChoosePackage
                            complaint={complaint}
                            order={order}
                            getPackages={getPackages}
                        />
                        <Divider type="vertical" />
                        {permissionCreatePackage && <CreatePackage
                            complaint={complaint}
                            fetchPackages={getPackages}
                        />}
                        
                    </>
                )} 
                
                </div>
            </div>

            <Table
                columns={columns}
                dataSource={packages}
                rowKey={record => record.code}
                scroll={{ x: '650px' }}
                pagination={false}
                loading={loading}
                rowSelection={rowSelection}
                locale={{ emptyText: t('complaintSeller:product_return.not_found_data') }}
                rowClassName="a-text--fz-12 _complaint-detail-row-list-package"
                className="complaint-detail-table-list-package"
            />
            {visibleLog && packageCode && (
                <Modal
                    title={t('complaintSeller:label.log_package')}
                    visible={visibleLog}
                    onCancel={() => setVisibleLog(false)}
                    footer={null}
                    okButtonProps={{ className: '_complaint-seller-detail-button-logs-ok' }}
                    cancelButtonProps={{ className: '_complaint-seller-detail-button-logs-cancel' }}>
                    <PackageLog
                        complaintId={complaint.id}
                        packageCode={packageCode}
                    />
                </Modal>
            )}
        </div>
    )
}
export default translate()(ListPackages)