import React from "react";
import {Icon} from "antd";
import notification from '../../../systems/notification';
import {url} from "../../../systems/routing";
import {translate} from "react-i18next";
import ProblemDetail from "./ProblemDetail";
import LogProblemOrder from "./LogProblemOrder";
import {processResponse} from "../../../systems/api";
import apiProblemOrder from "./apiProblemOrder";
import lodash from "lodash";
import {currentFormatter} from "../../../modules/common/services/format";
import {dispatch} from "../../../systems/store";
import {setBreadCrumb, setMenuActive, setPageDescription, setPageTitle} from "../../../modules/common/actions";
import ORDER_EVENT from '../../../modules/Order/events';
import {withEvent} from "../../../systems/events";
class ProblemOrder extends React.Component {

    constructor(props) {
        super(props);
        this.state = { data: undefined };
    }

    componentDidMount() {
        const { t, match } = this.props;

        let res = apiProblemOrder.getOrderById(match.params.id).finally();
        let process = processResponse(res);
        process.then(json => {
            this.setState({data: this.convertData(lodash.get(json, "data.orders"))});
        });

        process.catch(() => {
            notification.error(t('transaction:message.mark_transaction_failed'));
        });

        this.setPageTitle(t('breadcrumb.problem_order'));
        this.setPageDescription(t('problem_order.description'));
        this.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.problem_order',
                'link': url.to('purchase.problem', {id: lodash.get(match, "params.id")})
            },
        ]);
        this.setMenuActive("transaction.files.order");
        this.props.on({
            [ORDER_EVENT.MARKED_VIOLATION]: (event, payload) => {
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        orderDetail: {
                            ...this.state.data.orderDetail,
                            ...lodash.pick(payload.order.orderDetail, ['violation', 'violation_note', 'violationCreator', 'violation_time'])
                        }
                    }
                })
            },
            [ORDER_EVENT.RESOLVED_ORDER]: this.updateOrderDetail,
            [ORDER_EVENT.UNRESOLVED_ORDER]: this.updateOrderDetail
        });

    }
    /*
     * Cập nhật lại dữ liệu cho orderDetail sau khi resolve or unresolve
     */
    updateOrderDetail = (event, payload) => {
        let {data} = this.state;
        this.setState({
            data: {
                ...data,
                is_resolved: payload.data.is_resolved,
                orderDetail: {...data.orderDetail, ...payload.data}
            }
        });
    }

    setPageTitle = (value) => {
        dispatch(setPageTitle(value));
    }

    setBreadCrumb = (items) => {
        dispatch(setBreadCrumb(items));
    }

    setPageDescription = (value) => {
        dispatch(setPageDescription(value));
    }

    setMenuActive = (menu) => {
        dispatch(setMenuActive(menu));
    }

    convertData = (order) => {
        const {t} = this.props;
        const {orderDetail} = order;
        const order_params = {
            grand_total_bought_text: currentFormatter.toLocaleStringCurrency(parseFloat(order.grand_total_bought)) + "¥",
            grand_total__text: currentFormatter.toLocaleStringCurrency(parseFloat(order.grand_total)) + "¥",
            total_shipping_fee_inland_text: currentFormatter.toLocaleStringCurrency(parseFloat(order.total_shipping_fee_inland)) + "¥",
        };
        if (orderDetail)
            return {
                ...order,
                ...order_params,
                profit_text: currentFormatter.toLocaleStringCurrency(parseFloat(orderDetail.profit)) + "¥",
                total_transaction_text: currentFormatter.toLocaleStringCurrency(parseFloat(orderDetail.total_transaction)) + "¥",
                profit_percent_text: (orderDetail.profit_percent ? orderDetail.profit_percent : 0) + "%",
                description: (lodash.includes(["CANCELLED", "OUT_OF_STOCK"], order.status) && orderDetail.total_transaction !== 0) ?
                    <div className="pb-2 mt-1">
                        <Icon type="exclamation-circle" theme="filled" className="a-text--red  mr-1"/>
                        <span
                            className="a-text--red">{t("transaction:problem.MISMATCHED_TOTAL_CANCELLED")}</span>
                    </div> :
                    orderDetail.problem === "NO_PROBLEM" ? "" : <div className="pb-2 mt-1">
                        <Icon type="exclamation-circle" theme="filled" className="a-text--red  mr-1"/>
                        <span
                            className="a-text--red">{t("transaction:problem." + orderDetail.problem)}</span>
                    </div>
            };

        return {
            ...order,
            ...order_params,
            profit_text: "0¥",
            total_transaction_text: "0¥",
            profit_percent_text: "0%",
            description: "---"
        };
    }

    render() {
        const { t } = this.props;
        let dataOrder = this.state.data;

        return dataOrder !== undefined ? <>
            <div className="a-content--problem-detail">
                <div className="a-content--ant-layout">
                    <ProblemDetail dataOrder={dataOrder}/>
                </div>
            </div>
            <LogProblemOrder object="order" objectId={dataOrder.id} />
        </> :
            <div className='a-loading__page'>
                <div className="a-loading__page__icon"></div>
            </div>;
    }
}

export default translate()(withEvent(ProblemOrder));
