import React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Modal, Form, Input, Row, Col, Switch, Select, notification} from "antd";
import FormProcessing from "../../../modules/common/components/FormProcessing";
import {withEvent} from "../../../systems/events";
import {translate} from 'react-i18next';
import lodash from "lodash";
import PURCHASE_ADDRESS_EVENTS from "../../../modules/PurchaseAddress/events";
import apiService from "../../../modules/PurchaseAddress/apiService";
import {processResponse} from "../../../systems/api";
import {updateCollectionItem} from "../../../modules/common/helps";
const {Option} = Select;

const defaultInput = {
    id: '',
    code: "",
    name: "",
    address: "",
    alias: "",
    default: 0,
    id_partner: "",
    phone: "",
    receiver: "",
    status: 1,
    id_agency: 0,
    city: "",
    district: "",
    state: "",
    zip_code: "",

}

class FormAddress extends FormProcessing {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            inputVersion: 0,
            changedParams: [],
            input: {},
            dataError: {},
            loading: false
        }
    }

    onClick = e => {
        this.setState({visible: true});
    }

    handleOk = e => {
        let {input} = this.state;
        this.setState({loading: true});
        if (!lodash.isEmpty(input.id) || !input.id)
            this.createPurchaseAddress();
        else
            this.updatePurchaseAddress();
    }

    handleCancel = () => {
        this.onResetHandler();
    }

    onResetHandler = () => {
        this.setState({
            visible: false,
            loading: false,
            input: defaultInput,
            dataError: {}
        });
    }

    createPurchaseAddress = () => {
        let {input} = this.state;
        input = {...defaultInput, ...input};
        let {updateState, data, t} = this.props;
        let res = processResponse(apiService.createPurchaseAddress(input))
            .then(res => {
                data.unshift(lodash.get(res, "data.purchaseAddress"));
                updateState({data});
                this.onResetHandler();
                notification.success({message: t('purchase_address.created_success')});
            })
            .catch(error => {
                if (error.code === "INPUT_INVALID") {
                    this.setState({
                        loading: false,
                        dataError: error.data
                    });
                } else
                    notification.error({message: t('purchase_address.created_fail')});
            });
    }

    updatePurchaseAddress = () => {
        let {input} = this.state;
        let {updateState, data, t} = this.props;
        let res = processResponse(apiService.updatePurchaseAddress(input.id, input))
            .then(res => {
                let purchase_address = lodash.get(res, "data.purchaseAddress");
                data = updateCollectionItem(data, purchase_address.id, purchase_address);
                updateState({data});
                this.onResetHandler();
                notification.success({message: t('purchase_address.updated_success')});
            })
            .catch(error => {
                if (error.code === "INPUT_INVALID") {
                    this.setState({
                        loading: false,
                        dataError: error.data
                    });
                } else
                    notification.error({message: t('purchase_address.updated_fail')});
            });
    }

    onHandleUpdate = (event, address) => {
        this.setState({
            visible: true,
            input: {...address}
        });
    }

    componentDidMount() {
        this.props.on({
            [PURCHASE_ADDRESS_EVENTS.UPDATED]: this.onHandleUpdate
        });
    }

    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        this.setState(state => ({input: {...state.input, ...input}}));
    }

    /**
     * Cập nhật dữ liệu thay đổi của input
     * @param field
     * @param event
     */
    onHandleChange = (field, event) => {
        let value = event.target.value;

        if (field === 'code') {
            value = value.trim();
            value = value.replace(/\s/g, '');
        }

        this.changeInput({[field]: value});
        this.resetError(field);
    }

    /**
     * Cập nhật dữ liệu thay đổi của select
     * @param event
     */
    onHandleChangeSelect = (field, value) => {
        this.changeInput({[field]: value});
        this.resetError(field);
    }


    resetError = (field) => {
        this.setState(state => {
            let {dataError} = this.state;
            delete dataError[field];
            return {...state, dataError}
        });
    }

    /**
     * Cập nhật dữ liệu thay đổi của Switch
     * @param status
     * @param name
     */
    onHandleChangeSwitch(field, checked) {
        let value = checked ? 1 : 0;
        this.changeInput({[field]: value});
        this.resetError(field);
    }

    /**
     * Thực hiện render lỗi thông báo ra bên ngoài
     * @param attrName
     * @returns {null}
     */
    renderError = attrName => {
        let {t} = this.props;
        let {dataError} = this.state;
        let errorItem = lodash.get(dataError, attrName, {});

        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('purchase_address.error.' + Object.keys(errorItem)[0], {name: t('purchase_address.' + attrName)});
                case 'CODE_USED':
                    return t('purchase_address.error.' + Object.keys(errorItem)[0], {name: t('purchase_address.' + attrName)});
                case 'unique':
                    return t('purchase_address.error.' + Object.keys(errorItem)[0], {name: t('purchase_address.' + attrName)});
                case 'status_invalid':
                    return t('purchase_address.error.' + Object.keys(errorItem)[0], {name: t('purchase_address.' + attrName)});
                case 'max.string':
                    let objectmax = lodash.get(errorItem, 'max.string', null);
                    let overLength = lodash.get(objectmax, 'max', null);
                    return t('purchase_address.error.max_string', {
                        name: t('purchase_address.' + attrName),
                        value: overLength
                    });
                default:
                    return null;
            }
        }
    }

    render() {
        let {input} = this.state;
        let {permission, agencies} = this.props;

        if (!permission) return null;

        return <>
            <Button
                className="a-btn--primary-ant a-font--12 pl-4"
                style={{paddingTop: "1px"}}
                onClick={this.onClick} >
                <Icon type="plus-square" /> THÊM MỚI
            </Button>
            <Modal
                title={input.id ? "CẬP NHẬT KHO NHẬN" : "THÊM MỚI KHO NHẬN"}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                cancelText="Hủy"
                okText={input.id ? "Cập nhật" : "Thêm mới"}
                width={420}
                okButtonProps={{loading: this.state.loading}}
            >
                <Form className="form-order-group">
                    <Form.Item label="Agency" className="a-form--ant-item mb-0">
                        <Select
                            placeholder="Chọn agency"
                            value={input.id_agency || undefined}
                            onChange={value => this.onHandleChangeSelect("id_agency", value)}
                        >
                            {
                                agencies.length > 0 &&
                                agencies.map(item => {
                                    return <Option value={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("id_agency") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("id_agency")}
                        </div>
                    </Form.Item>
                    <Form.Item label="Tên kho" className="a-form--ant-item mb-0 required">
                        <Input
                            type="text"
                            placeholder="Tên kho"
                            allowClear
                            value={input.name || undefined}
                            onChange={e => this.onHandleChange("name", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("name") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("name")}
                        </div>
                    </Form.Item>
                    <Form.Item label="Mã kho" className="a-form--ant-item mb-0 required">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Mã kho"
                            value={input.alias || undefined}
                            onChange={e => this.onHandleChange("alias", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("alias") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("alias")}
                        </div>
                    </Form.Item>

                    <Form.Item label="Tỉnh" className="a-form--ant-item mb-0">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Tỉnh"
                            value={input.state || undefined}
                            onChange={e => this.onHandleChange("state", e)}
                        />
                        <div className={"validatorMessage a--error "
                            + ( this.renderError("state") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("state")}
                        </div>
                    </Form.Item>

                    <Form.Item label="Thành Phố" className="a-form--ant-item mb-0">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Thành Phố"
                            value={input.city || undefined}
                            onChange={e => this.onHandleChange("city", e)}
                        />
                        <div className={"validatorMessage a--error "
                            + ( this.renderError("city") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("city")}
                        </div>
                    </Form.Item>

                    <Form.Item label="Quận huyện" className="a-form--ant-item mb-0">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Quận huyện"
                            value={input.district || undefined}
                            onChange={e => this.onHandleChange("district", e)}
                        />
                        <div className={"validatorMessage a--error "
                            + ( this.renderError("district") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("district")}
                        </div>
                    </Form.Item>

                    <Form.Item label="Địa chỉ kho" className="a-form--ant-item mb-0">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Địa chỉ kho"
                            value={input.address || undefined}
                            onChange={e => this.onHandleChange("address", e)}
                        />
                        <div className={"validatorMessage a--error "
                            + ( this.renderError("address") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("address")}
                        </div>
                    </Form.Item>


                    <Form.Item label="Zip Code" className="a-form--ant-item mb-0">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Zip Code"
                            value={input.zip_code || undefined}
                            onChange={e => this.onHandleChange("zip_code", e)}
                        />
                        <div className={"validatorMessage a--error "
                            + ( this.renderError("zip_code") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("zip_code")}
                        </div>
                    </Form.Item>

                    <Form.Item label="Người nhận tại kho" className="a-form--ant-item mb-0">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Người nhận tại kho"
                            value={input.receiver || undefined}
                            onChange={e => this.onHandleChange("receiver", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("receiver") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("receiver")}
                        </div>
                    </Form.Item>
                    <Form.Item label="Điện thoại" className="a-form--ant-item mb-0">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Điện thoại"
                            value={input.phone || undefined}
                            onChange={e => this.onHandleChange("phone", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("phone") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("phone")}
                        </div>
                    </Form.Item>
                    <Form.Item className="m-0">
                        <Row gutter={0}>
                            <Col span={12} className="a-text--fz-12">Trạng thái</Col>
                            <Col span={12} className="a-text--right">
                                <Switch
                                    size="small"
                                    onChange={checked => this.onHandleChangeSwitch("status", checked)}
                                    checked={input.status === 0 ? false : true}
                                />
                            </Col>
                            <Col span={24}>
                                <div className={"validatorMessage a--error "
                                + ( this.renderError("status") ? 'active-error' : 'inactive-error' )}>
                                    {this.renderError("status")}
                                </div>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item className="m-0">
                        <Row gutter={0}>
                            <Col span={12} className="a-text--fz-12">Địa chỉ mặc định</Col>
                            <Col span={12} className="a-text--right">
                                <Switch
                                    size="small"
                                    onChange={checked => this.onHandleChangeSwitch("default", checked)}
                                    checked={input.default === 1 ? true : false}
                                />
                            </Col>
                            <Col span={24}>
                                <div className={"validatorMessage a--error "
                                + ( this.renderError("default") ? 'active-error' : 'inactive-error' )}>
                                    {this.renderError("default")}
                                </div>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }
}

FormAddress.defaultProps = {
    agencies: [],
    dataDetail: {}
}

FormAddress.propTypes = {
    agencies: PropTypes.array.isRequired,
    dataDetail: PropTypes.object
}

export default translate()(withEvent(FormAddress));
