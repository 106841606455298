import {createSelector} from 'reselect';
import lodash from 'lodash';
import {dateFormatter, currentFormatter} from "../common/services/format";
import {trans} from '../../systems/i18n';

const listProductReturnSelector = state => lodash.get(state, 'complainProductReturn.listProductReturns', []);
export const productReturn = (state) => lodash.get(state, 'complainProductReturn.productReturn', {});

export const getProductReturn = createSelector(
    productReturn,
    items => {
        let shipping_bill_codes = lodash.get(items, 'shipping_bill_codes', "");
        let shipping_bill_code_custom = shipping_bill_codes ? lodash.split(shipping_bill_codes, ',') : [];
        let listChangedStatus = [];
        lodash.get(items, 'enabledStatus', []).map((status) => {
            listChangedStatus.push({
                label: trans("productReturn:status." + status.toLowerCase()), value: status
            });
            return status;
        });
        return {
            ...items, ...{
                shipping_bill_code_custom: shipping_bill_code_custom,
                listChangedStatus: listChangedStatus
            }
        }
    }
);

export const getListProductReturn = createSelector(
    listProductReturnSelector,
    items => items.map((item) => {
        let dateFormat = dateFormatter.dateTime(item.created_at);
        let returnDateFormat = dateFormatter.date(item.return_date);
        return {
            ...item,
            costFormat: item.cost !== '' ? currentFormatter.toLocaleStringCurrency(parseFloat(item.cost, 5), 5) : '---',
            paidCostFormat: item.paid_cost !== '' ? currentFormatter.toLocaleStringCurrency(parseFloat(item.paid_cost, 10), 5) : '---',
            dateFormat: dateFormat,
            returnDateFormat: returnDateFormat
        }
    })
);
