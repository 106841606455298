import React from "react";
import { Input, Tooltip } from 'antd';
class NumericInput extends React.Component {

    onChange = e => {
        const { value } = e.target;
        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            this.props.onChange(value);
        }
    };


    render() {
        const { value } = this.props;
        return (

                <Input
                    {...this.props}
                    onChange={this.onChange}
                    placeholder={this.props.placeholder}
                    maxLength={25}
                />

        );
    }
}

export default NumericInput;
