import React from 'react'
import PropTypes from 'prop-types'

class Super extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: '',
        }
    }

    componentWillMount() {
        const { value } = this.props
        this.setState({ value: value })
    }

    onKeyDown(e) {
        const { onKeyDown } = this.props
        if (onKeyDown) {
            onKeyDown(e)
        }
    }

    onChange(e) {
        const { onChange } = this.props
        let value = this.onRegexValue(e)
        if (onChange) {
            onChange(value)
        }
    }

    onKeyPress(e) {
        const { onKeyPress, allowEmpty } = this.props
        let value = this.onRegexValue(e)
        if (onKeyPress) {
            onKeyPress(e, value)
            value = value ? value : allowEmpty ? '' : 0
            this.setState({ isFocus: false, value })
        }
    }

    onBlur(e) {
        const { onBlur } = this.props
        let value = this.onRegexValue(e)
        if (onBlur) {
            this.setState({ isFocus: false })
            onBlur(value)
        }
    }

    onRegexValue(e) {
        const { negative, integer, unit_price, notReplaceZeroFirst, numberNotZero, regexInput, integerHasBlank } = this.props

        let regex
        let value = e.target.value

        // regex pass from input
        if (regexInput) {
            if (value === '' || regexInput.test(value)) {
                this.setState({ value })
                return value
            }
        } else {
            if (negative) {
                regex = /^[-+]?\d*\.?\d{0,2}$/
            }
            /* eslint-disable */
            if (integer) {
                regex = /^[]?\d*$/
            } else if (integerHasBlank) {
                regex = /^[ ]*?\d*[ ]*$/
            } else {
                if (!negative) {
                    regex = /^[]?\d*\.?\d{0,2}$/
                }
            }

            if (numberNotZero) {
                regex = /^[1-9]\d*$/
            }

            /* eslint-enable */

            if (!notReplaceZeroFirst) {
                value = value.replace(/^0+$/, '0')
                if (value.length > 1 && /^0+\d*$/.test(value)) {
                    value = value.substr(1, value.length)
                }
            }
            /* eslint-disable */
            if (unit_price) {
                // language=JSRegexp
                regex = /^[]?\d*\.?\d{0,5}$/
            }
            /* eslint-enable */
            if (value === '' || regex.test(value)) {
                this.setState({ value: value })
                return value
            }
        }

        return ''
    }

    componentWillReceiveProps(nextProps) {
        const { value } = nextProps
        if (JSON.stringify(this.props.value) !== JSON.stringify(value)) {
            this.setState({ value: value })
        }
    }

    render() {
        const { value } = this.state
        const { className, placeholder, disabled, name, onRef, autoFocus } = this.props

        return (
            <input
                type="text"
                autoComplete="off"
                autoFocus={autoFocus}
                name={name}
                onKeyUp={this.props.onKeyUp}
                onChange={this.onChange.bind(this)}
                onBlur={this.onBlur.bind(this)}
                onKeyDown={this.onKeyDown.bind(this)}
                onKeyPress={this.onKeyPress.bind(this)}
                value={value || value === 0 ? value : ''}
                disabled={disabled || false}
                className={className}
                placeholder={placeholder}
                ref={input => {
                    if (onRef) {
                        onRef(input)
                    }
                }}
            />
        )
    }
}

Super.defaultProps = {
    quantity: false,
    autoFocus: false,
    unit_price: false,
    allowEmpty: true,
    onKeyUp: () => {},
}

Super.propTypes = {
    autoFocus: PropTypes.bool,
    unit_price: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    placeholder: PropTypes.string,
    negative: PropTypes.any,
    quantity: PropTypes.any,
    integer: PropTypes.any,
    integerHasBlank: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    notReplaceZeroFirst: PropTypes.bool, // Giữ không xóa số 0 khi nhập vào. 0001 -> 0001 || 0001 -> 1
    onRef: PropTypes.func,
    regexInput: PropTypes.any,
    allowEmpty: PropTypes.bool,
}

export default Super
