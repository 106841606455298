import {combineReducers} from "redux";
import * as constants from './constants';
import lodash from "lodash";
const detail = (state = {}, action) => {
    switch (action.type) {
        case constants.GET_TRANSACTION_DETAIL.SUCCESS:
            return {...lodash.get(action, "payload.data", {})};
        case constants.GET_TRANSACTION_DETAIL.FAILED:
        case constants.GET_TRANSACTION_DETAIL.REQUEST:
        case constants.UPDATE_TRANSACTION:
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    ...lodash.get(action, "payload.data", {})}
            };
        case constants.CLEAR_STATE:
            return {};
        default:
            return state;
    }
};
const loading = (state = false, action) => {
    switch (action.type) {
        case constants.GET_TRANSACTION_DETAIL.SUCCESS:
            return false;
        case constants.GET_TRANSACTION_DETAIL.FAILED:
        case constants.GET_TRANSACTION_DETAIL.REQUEST:
        default:
            return state;
    }
};
export default combineReducers({
    loading,
    detail,
});
