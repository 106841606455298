import {put, call} from 'redux-saga/effects';
import lodash from 'lodash';
import * as Constants from './constants';
import apiService from './apiService';
import {router, url} from './../../../systems/routing/index';
import * as GlobalConstants from './../../../systems/globalContant';
import bootbox from './../../common/components/Bootbox/bootbox';
import {t} from './../../../systems/i18n/index.js';

export function* getOrderGroup(action) {

    try {
        let response = yield call(apiService.getOrderGroup, action.payload.filter);

        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.FETCH_ORDER_GROUP.SUCCESS, payload: response.data});
        } else {
            bootbox.error(t('order_group.fetch_data_fail'));
            yield put({type: Constants.FETCH_ORDER_GROUP.FAILED, payload: response.data});
            router.redirect(url.to('orderGroup.manager'));
        }
    } catch (err) {
        yield put({type: Constants.FETCH_ORDER_GROUP.FAILED, payload: {}});
        bootbox.error(t(err.message) || t('system.error'));

    }
}


export function* createOrderGroup(action) {
    try {
        let response = yield call(apiService.createOrderGroup, action.payload.params);
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            bootbox.success(t('order_group.created_success'));
            yield put({type: Constants.CREATE_ORDER_GROUP.SUCCESS, payload: response.data});
            let id = lodash.get(response.payload, 'data.orderGroup.id', null);
            if (id) {
                router.redirect(url.to('orderGroup.manager') + "?id=" + id);
            }
        } else {
            yield put({type: Constants.CREATE_ORDER_GROUP.FAILED, payload: response.data});
        }
    } catch (err) {
        bootbox.error(t(err.message) || t('system.error'));
        yield put({type: Constants.CREATE_ORDER_GROUP.FAILED, payload: action.payload.params});
    }
}


export function* fetchDetailOrderGroup(action) {
    try {
        let response = yield call(apiService.fetchDetailOrderGroup, action.payload.filters);
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.FETCH_DETAIL_ORDER_GROUP.SUCCESS, payload: response.data});
        } else {
            bootbox.error(t('order_group.fetch_data_fail'));
            yield put({type: Constants.FETCH_DETAIL_ORDER_GROUP.FAILED, payload: response.data});
            router.redirect(url.to('orderGroup.manager'));
        }
    } catch (err) {
        bootbox.error(t(err.message) || t('system.error'));
        yield put({type: Constants.FETCH_DETAIL_ORDER_GROUP.FAILED, payload: {}});
    }
}

export function* updateOrderGroup(action) {

    try {
        let response = yield call(apiService.updateOrderGroup, action.payload.params);
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            bootbox.success(t('order_group.updated_success'));
            yield put({type: Constants.UPDATE_ORDER_GROUP.SUCCESS, payload: response.data});
        } else {
            yield put({type: Constants.UPDATE_ORDER_GROUP.FAILED, payload: response.data});
            router.redirect(url.to('orderGroup.manager'));
        }

    } catch (err) {
        bootbox.error(t(err.message) || t('system.error'));
        yield put({type: Constants.UPDATE_ORDER_GROUP.FAILED, payload: {}});
    }
}

export function* updateStatusOrderGroup(action) {
    try {
        let response = yield call(apiService.updateStatusOrderGroup, action.payload.params);
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            bootbox.success(t('order_group.updated_success'));
            yield put({type: Constants.UPDATE_STATUS_ORDER_GROUP.SUCCESS, payload: response.data});
        } else {
            yield put({type: Constants.UPDATE_STATUS_ORDER_GROUP.FAILED, payload: response.data});
            router.redirect(url.to('orderGroup.manager'));
        }

    } catch (err) {
        bootbox.error(t(err.message) || t('system.error'));
        yield put({type: Constants.UPDATE_STATUS_ORDER_GROUP.FAILED, payload: action.payload.params});
    }
}
