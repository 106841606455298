import React, {Component} from 'react';
import {Layout, Form, Input, Row, Col, Button, Radio, Divider, Icon, Badge, Tooltip} from 'antd';
import {translate} from "react-i18next";
import NumericInput from "../../../modules/common/components/Input/NumericInput";
import lodash from 'lodash';
import apiService from "../../../modules/PaymentRequest/apiService";
import {processResponse} from "../../../systems/api";
import notification from '../../../systems/notification';
import {dispatch} from "../../../systems/store";
import {setBreadCrumb, setMenuActive, setPageDescription, setPageTitle} from "../../../modules/common/actions";
import Link from '../../../systems/routing/Link';
import {router, url} from '../../../systems/routing';
import cookies from "react-cookies";
import moment from "moment";
import {trans} from '../../../systems/i18n';
import SelectAccountPurchasers from "../../../modules/AccountPurchaser/SelectAccountPurchasers";
import SelectAccountBanks from "../../../modules/BankAccount/SelectAccountBanks";
import SelectViolateReason from "../../../modules/ViolateReason/components/SelectViolateReason";
import ReactFileReader from "react-file-reader";
import ChangeQrcodeImage from "../../../modules/PaymentRequest/ChangeQrcodeImage";
import PhotoGallery from "../../../modules/common/components/PhotoGallery";
import get from "lodash/get";
import {connect} from "react-redux";
import { fetchViolateReasons } from '../../../modules/ViolateReason/actions';
import { PAYMENT_ORDER_ERROR } from '../../../modules/PurchaseBuying/PaymentOrder/constants';

const Content = Layout.Content;
const APLIPAY_COOKIE_NAME = "external-payment-request-alipay";

class CreateExternalPaymentRequest extends Component {
    constructor(props) {
        super(props);
        this.defaultInput = {
            amount: '',
            id_order: [],
            payment_type: 'alipay',
            bank_account: '',
            branch_name: '',
            receiver_name: '',
            payment_content: '',
            link: '',
            id_bank_payment: undefined
        };
        this.state = {
            input: this.defaultInput,
            errors: {},
            visiabledOpenModalViolate: false,
            dataViolatedPolicy: {},
            qrImage: undefined
        }
    }

    componentDidMount() {
        let {match} = this.props;
        let {input} = this.state;

        this.setPageInfo(match);
        this.props.fetchViolateReasons()
        if (match.params.idOrder) {
            this.setState({
                input: {
                    ...input,
                    id_order: match.params.idOrder.split(","),
                    bank_account: input.payment_type === 'alipay' ? this.loadAlipayCookie() : '' // Gợi ý tài khoản alipay đã lưu
                }
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {input} = this.state;
        let payment_type = lodash.get(this.state, "input.payment_type", "");
        let old_payment_type = lodash.get(prevState, "input.payment_type", "");

        // Kiểu thanh toán là alipay thì hiển thị tài khoản alipay đã lưu
        if (payment_type !== old_payment_type && input.payment_type === 'alipay') this.setState({
            input: {
                ...input,
                bank_account: this.loadAlipayCookie()
            }
        })
    }

    setPageInfo() {
        let {t, match} = this.props;

        dispatch(setPageTitle(t('paymentRequest:page.external_title')));
        dispatch(setPageDescription(t('paymentRequest:page.external_description')));
        dispatch(setMenuActive('payment_requests'));
        dispatch(setBreadCrumb([{
            'label': 'breadcrumb.home', 'link': '/'
        }, {
            'label': 'paymentRequest:page.external_title',
            'link': `/create-external-payment-request/${match.params.idOrder}`
        },]));
    }

    onChangeText(param, event) {
        this.setState({
            input: {
                ...this.state.input, [param]: event.target.value
            }, errors: lodash.omit(this.state.errors, [param])
        })
    }

    onChangeSelect = (param, value) => {
        this.setState({
            input: {
                ...this.state.input, [param]: value
            }, errors: lodash.omit(this.state.errors, [param])
        })
    };

    onChangeNumber(param, value) {
        this.setState({
            input: {
                ...this.state.input, [param]: value
            }, errors: lodash.omit(this.state.errors, [param])
        })
    }

    changeRadio(param, event) {
        this.setState({
            input: {
                ...this.state.input,
                [param]: event.target.value,
                bank_account: '',
                branch_name: '',
                receiver_name: '',
                id_bank_payment: undefined
            }, errors: lodash.omit(this.state.errors, ['bank_account', 'branch_name', 'receiver_name'])
        })
    }


    onSubmit() {
        let {input, policy, qrImage} = this.state;
        let {t} = this.props;

        this.resetError();

        // Trim input
        input = lodash.each(input, function (value, key) {
            if (key !== "id_order") input[key] = lodash.trim(value);
        });

        // Loại bỏ value rỗng
        input = lodash.omitBy(input, lodash.isEmpty);

        if (this.state.loading) return;

        this.setState({loading: true});

        // if (policy) {
        //     input['policy'] = policy;
        // }

        let process = processResponse(apiService.createExternalPaymentRequestMany({...input, ...policy, qr_code: qrImage ? qrImage: undefined})); // old logic = apiService.createExternalPaymentRequest(input.id_order, input)
        process.then(res => {
            notification.success(t('paymentRequest:message.create_payment_request_success'));

            // Nếu kiểu thanh toán là alipay thì lưu lại tài khoản alipay và gợi ý cho lần sau
            if (input.payment_type === 'alipay') {
                let oldAlipay = this.loadAlipayCookie();
                if (oldAlipay === '' || oldAlipay !== input.bank_account) this.saveAlipayCookie(input.bank_account);
            }

            this.resetInput();

            let external_order = lodash.get(res, 'data.external_order', true);
            let url_params = (external_order) ? '?external_order=1&status=PENDING' : '?external_order=0&status=WAITING';
            router.redirect(url.to('payment-requests') + url_params);
            // router.redirect(url.to('purchase.buying.detail', {id: input.id_order}));
        });
        process.catch((err) => {
            let errors = err.data;
            let {visiabledOpenModalViolate, dataViolatedPolicy} = this.state;
            const errorCode = lodash.get(err, "code");
            if (errorCode === PAYMENT_ORDER_ERROR.ORDER_POLICIES) {
                visiabledOpenModalViolate = true;
                dataViolatedPolicy = lodash.get(err, 'data', {})
            } else {
                errors = lodash.forEach(errors, function (errValue, errKey) {
                    if (errKey === "policy") {
                        visiabledOpenModalViolate = true;
                        dataViolatedPolicy = errValue
                    } else {
                        let messagePrefix = t(`paymentRequest:form_label.${errKey}`);
                        if (input.payment_type === 'alipay' && errKey === 'bank_account') {
                            messagePrefix = t(`paymentRequest:form_label.alipay_account`);
                        }
                        switch (errKey) {
                            case 'external_order':
                                notification.error(t('paymentRequest:unique_error.external_order'));
                                break;
                            case 'amount':
                                errValue = t('paymentRequest:unique_error.amount');
                                break;
                            case 'amount_greater':
                                notification.error(t('paymentRequest:unique_error.amount_greater'));
                                break;
                            case 'status':
                                notification.error(t('paymentRequest:unique_error.status'));
                                break;
                            case 'id_order':
                                lodash.mapKeys(errValue, function (value, key) {
                                    errValue = t(`paymentRequest:unique_error.id_order_${key}`);
                                });
                                break;
                            case 'wrong_process':
                                errValue = t(`paymentRequest:unique_error.wrong_process`);
                                break;
                            case 'order':
                                errValue = trans(`paymentRequest:unique_error.${errValue.error}`, {
                                    orderId: <strong>{errValue.order}</strong>
                                });
                                break;
                            case 'validate_change_pay_error':
                                errValue = trans(`paymentRequest:unique_error.validate_change_pay_error`, {
                                    orderId: <strong>{errValue.order}</strong>
                                });
                                break;
                            case 'receiver_name_or_link':
                                errValue = trans(`paymentRequest:unique_error.beneficiary_name_or_link`);
                                break;
                            case 'order_st':
                                errValue = trans(`paymentRequest:error.order_st`, {attribute: <strong>{errValue.order}</strong>});
                                break;
                            case "real_payment_amount":
                                notification.error(t('paymentRequest:unique_error.real_payment_amount'));
                                break;
                            default:
                                lodash.forEach(errValue, function (value, key) {
                                    let messageSuffix = t(`paymentRequest:error.${key}`);
    
                                    errValue = `${messagePrefix} ${messageSuffix}`;
                                });
    
                        }
                        errors[errKey] = errValue;
                    }
                });
            }
            
            
            this.setState({
                errors: errors, visiabledOpenModalViolate, dataViolatedPolicy
            })
            // }
        });

        process.finally(() => this.setState({loading: false}))
    }

    saveAlipayCookie = (alipay) => {
        cookies.save(APLIPAY_COOKIE_NAME, alipay, {
            path: '/', expires: moment().add(7, 'd').toDate()
        });
    };

    loadAlipayCookie = () => {
        return cookies.load(APLIPAY_COOKIE_NAME) || '';
    };

    resetInput = () => {
        let {match} = this.props;

        if (match.params.idOrder.length > 0) {
            this.setState({
                input: {
                    ...this.defaultInput, id_order: match.params.idOrder.split(',')
                }, visiabledOpenModalViolate: false
            })
        }
    };

    onCancel() {
        this.resetError();
        this.resetInput();
    }

    resetError() {

        this.setState({
            errors: {}
        })
    }

    componentWillUnmount() {
        this.setState({
            input: this.defaultInput, errors: {}
        });
    }

    onCancelModalViolate = () => {
        this.setState({
            visiabledOpenModalViolate: false
        })
    };
    onSubmitModalViolate = (policy) => {
        this.setState(state => ({
            ...state, policy
        }), () => {
            this.onSubmit();
        });

    };

    handleFile = file => {
        this.setState({qrImage: lodash.get(file, 'base64', undefined)});
    }

    removeImage = () => {
        this.setState({qrImage: undefined})
    }

    render() {
        let {t} = this.props;
        console.log("this.props", this.props)
        let {input, errors, visiabledOpenModalViolate, dataViolatedPolicy, qrImage, loading} = this.state;
        let disable = false;
        let requiredFields = ['amount', 'id_order', 'bank_account', input.payment_type === 'bank' && 'branch_name', input.payment_type === 'bank' && 'receiver_name'];
        requiredFields.map(field => {
            if (input[field] === '') disable = true;
        });

        return (<Layout>
                <Content className="a-content--ant-layout">
                    <div className="a-list__top a-flex">
                        <h2 className="a-list--title a-text--uppercase">
                            {t("paymentRequest:label.create_external_payment_request")}
                        </h2>
                    </div>
                    <div className="a-block__body--create-payment-request py-5">
                        <Form className="container">
                            <Row gutter={24}>
                                <Col span={24} className="mb-3">
                                    <span
                                        className="a-text--bold">{t('paymentRequest:label.creator_information')}</span>
                                </Col>
                                <Divider className="p-0"/>
                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                    <Form.Item
                                        label={t('paymentRequest:label.amount')}
                                        required={true}
                                        help={errors.amount}
                                        validateStatus={errors.amount ? 'error' : null}
                                        className="a-form--ant-item  mb-2"
                                    >
                                        <NumericInput
                                            className={"_create-payment-request-amount"}
                                            value={this.state.input.amount}
                                            placeholder={t('paymentRequest:placeholder.amount')}
                                            onChange={this.onChangeNumber.bind(this, 'amount')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                    <Form.Item
                                        label={t('paymentRequest:label.code')}
                                        help={errors.id_order}
                                        validateStatus={(errors.id_order) ? 'error' : null}
                                        className="a-form--ant-item mb-2"
                                    >
                                        {input.id_order && input.id_order.reverse().map((code, index) => {
                                            return (<div key={index}>
                                                {index > 0 ? ", " : ""}
                                                <Link isNewTab={true}
                                                      key={index}
                                                      href={url.to('purchase.buying.detail', {id: code})}
                                                      className="a-text--bold _create-payment-request-code">{code}</Link>
                                            </div>);
                                        })}
                                    </Form.Item>
                                    <Form.Item
                                        label={''}
                                        help={errors.wrong_process}
                                        validateStatus={errors.wrong_process ? 'error' : null}
                                        className="a-form--ant-item mb-0"
                                    >
                                    </Form.Item>
                                    <Form.Item
                                        label={''}
                                        help={errors.validate_change_pay_error}
                                        validateStatus={errors.validate_change_pay_error ? 'error' : null}
                                        className="a-form--ant-item mb-0"
                                    >
                                    </Form.Item>
                                    <Form.Item
                                        label={''}
                                        help={errors.order || errors.order_st}
                                        validateStatus={(errors.order || errors.order_st) ? 'error' : null}
                                        className="a-form--ant-item mb-0"
                                    >
                                    </Form.Item>
                                </Col>
                                <Col span={24} className="mb-3 mt-4">
                                    <span
                                        className="a-text--bold">{t('paymentRequest:label.payment_information')}</span>
                                </Col>
                                <Divider className="p-0"/>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="mb-3 d-flex">
                                    <Radio.Group
                                        value={this.state.input.payment_type}
                                        onChange={this.changeRadio.bind(this, 'payment_type')}
                                        className="_create-payment-request-payment-type"
                                    >
                                        <Col span={12}>
                                            <Button className="btn-block _create-payment-request-button-bank">
                                                <Radio value={'bank'}>
                                                    {t('paymentRequest:btn.via_bank')}
                                                </Radio>
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button className="btn-block  _create-payment-request-button-alipay">
                                                <Radio value={'alipay'}>
                                                    {t('paymentRequest:btn.via_alipay')}
                                                </Radio>
                                            </Button>
                                        </Col>
                                    </Radio.Group>
                                    <div className="ml-2">
                                        <ReactFileReader
                                            handleFiles={this.handleFile}
                                            fileTypes={["image/*"]}
                                            multipleFiles={false}
                                            base64={true}
                                        >
                                            <Button>
                                                <Icon type={'upload'}/> Tải mã QR thanh toán
                                            </Button>
                                        </ReactFileReader>
                                        {
                                            qrImage &&
                                            <div
                                                className="ant-upload ant-upload-select ant-upload-select-picture-card mt2">
                                                <span tabIndex="0" className="ant-upload" role="button">
                                                    <Tooltip title="Xoá mã QR thanh toán">
                                                        <Badge count={<Icon style={{color: "red"}} type="delete" theme="filled" onClick={this.removeImage}/>}>
                                                            <PhotoGallery images={qrImage ? [qrImage] : []} width={100} height={100}/>
                                                        </Badge>
                                                    </Tooltip>
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </Col>


                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                    <Form.Item
                                        label={t('paymentRequest:label.id_bank_payment')}
                                        help={errors.id_bank_payment}
                                        validateStatus={errors.id_bank_payment ? 'error' : null}
                                        className="a-form--ant-item  mb-2"
                                    >
                                        {input.payment_type === 'bank' ? <SelectAccountBanks
                                            value={input.id_bank_payment || undefined}
                                            placeholder={t('paymentRequest:placeholder.id_bank_payment_bank')}
                                            multiple={false}
                                            onChange={this.onChangeSelect.bind(this, 'id_bank_payment')}
                                            filter={{scope: "basic"}}
                                            className="_create-payment-request-select-id-bank-payment"
                                        /> : <SelectAccountPurchasers
                                            value={input.id_bank_payment || undefined}
                                            placeholder={t('paymentRequest:placeholder.id_bank_payment_alipay')}
                                            multiple={false}
                                            onChange={this.onChangeSelect.bind(this, 'id_bank_payment')}
                                            className="_create-payment-request-select-id-bank-payment"
                                        />}
                                    </Form.Item>
                                </Col>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    {input.payment_type === 'bank' ? <Form.Item
                                        label={t('paymentRequest:label.provider_bank_account')}
                                        required={true}
                                        help={errors.bank_account}
                                        validateStatus={errors.bank_account ? 'error' : null}
                                        className="a-form--ant-item  mb-2"
                                    >
                                        <Input
                                            value={this.state.input.bank_account}
                                            placeholder={t('paymentRequest:placeholder.provider_bank_account')}
                                            onChange={this.onChangeText.bind(this, 'bank_account')}
                                            className="_create-payment-request-bank-account"
                                        />
                                    </Form.Item> : <Form.Item
                                        label={t('paymentRequest:label.alipay_account')}
                                        required={true}
                                        help={errors.bank_account}
                                        validateStatus={errors.bank_account ? 'error' : null}
                                        className="a-form--ant-item  mb-2"
                                    >
                                        <Input
                                            value={this.state.input.bank_account}
                                            placeholder={t('paymentRequest:placeholder.alipay_account')}
                                            onChange={this.onChangeText.bind(this, 'bank_account')}
                                            className="_create-payment-request-bank-account"
                                        />
                                    </Form.Item>}
                                </Col>
                                {input.payment_type === 'bank' ? <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                    <Form.Item
                                        label={t('paymentRequest:label.bank_branch_name')}
                                        required={true}
                                        help={errors.branch_name}
                                        validateStatus={errors.branch_name ? 'error' : null}
                                        className="a-form--ant-item  mb-2"
                                    >
                                        <Input
                                            value={this.state.input.branch_name}
                                            placeholder={t('paymentRequest:placeholder.bank_branch_name')}
                                            onChange={this.onChangeText.bind(this, 'branch_name')}
                                            className="_create-payment-request-bank-name"
                                        />
                                    </Form.Item>
                                </Col> : null}

                                <Col xl={input.payment_type === 'bank' ? 12 : 24}
                                     lg={input.payment_type === 'bank' ? 12 : 24} md={24} sm={24} xs={24}>
                                    <Form.Item
                                        label={t('paymentRequest:label.beneficiary_name')}
                                        required={true}
                                        help={errors.receiver_name || errors.receiver_name_or_link}
                                        validateStatus={(errors.receiver_name || errors.receiver_name_or_link) ? 'error' : null}
                                        className="a-form--ant-item mb-2"
                                    >
                                        <Input
                                            value={this.state.input.receiver_name}
                                            placeholder={t('paymentRequest:placeholder.beneficiary_name')}
                                            onChange={this.onChangeText.bind(this, 'receiver_name')}
                                            className={"_create-payment-request-receiver-name"}
                                        />
                                    </Form.Item>
                                </Col>

                                {input.payment_type === 'alipay' ? <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Form.Item
                                        label={t('paymentRequest:label.link')}
                                        help={errors.link}
                                        validateStatus={errors.link ? 'error' : null}
                                        className="a-form--ant-item mb-2"
                                    >
                                        <Input
                                            value={this.state.input.link}
                                            placeholder={t('paymentRequest:placeholder.link')}
                                            onChange={this.onChangeText.bind(this, 'link')}
                                            className="_create-payment-request-link"
                                        />
                                    </Form.Item>
                                </Col> : null}

                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Form.Item
                                        label={t('paymentRequest:label.payment_content')}
                                        help={errors.payment_content}
                                        validateStatus={errors.payment_content ? 'error' : null}
                                        className="a-form--ant-item  mb-2"
                                    >
                                        <Input
                                            value={this.state.input.payment_content}
                                            placeholder={t('paymentRequest:placeholder.payment_content')}
                                            onChange={this.onChangeText.bind(this, 'payment_content')}
                                            className={"_create-payment-request-payment-content"}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24} className="d-flex flex-end">
                                    <Button
                                        className="a-text--uppercase a-btn--primary-ant aphz-24 mr-3 ghost _create-payment-request-button-cancel"
                                        onClick={this.onCancel.bind(this)}
                                    >
                                        {t('paymentRequest:btn.cancel')}
                                    </Button>
                                    <Button
                                        htmlType="submit"
                                        disabled={disable}
                                        loading={loading}
                                        className="a-text--uppercase a-btn--primary-ant aphz-24 mr-3 _create-payment-request-button-submit"
                                        onClick={this.onSubmit.bind(this)}
                                    >
                                        {t('paymentRequest:btn.submit')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <SelectViolateReason
                            visibled={visiabledOpenModalViolate}
                            violateReasons={this.props.violateReasons}
                            violated={dataViolatedPolicy}
                            onCancel={this.onCancelModalViolate}
                            onSubmit={this.onSubmitModalViolate}
                        />
                    </div>
                </Content>

            </Layout>
        );
    }
}

CreateExternalPaymentRequest.propTypes =
    {}
;

const mapStateToProps = (state) => {
    return {
     violateReasons: lodash.get(state, "ViolateReasons.list", []),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchViolateReasons: (filter = {}) => {
            filter.scope = 'basic';
            dispatch(fetchViolateReasons({...filter, status: 'ACTIVE'}));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(translate()(CreateExternalPaymentRequest));
