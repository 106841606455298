import {call, put} from 'redux-saga/effects';
import * as Constants from './constants';
import * as GlobalConstants from '../../systems/globalContant';
import service from './apiService';
import bootbox from './../common/components/Bootbox/bootbox';
import {t} from './../../systems/i18n/index.js';

export function* getListAccountPurchaserSaga(action) {
    try {
        let response = yield call(service.getListAccountPurchaser, action.payload);
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.GET_LIST_ACCOUNT_PURCHASER.SUCCESS, payload: response.data});
        } else {
            yield put({type: Constants.GET_LIST_ACCOUNT_PURCHASER.FAILED, payload: response.data});
            bootbox.error(t('messages.system_errors'));
        }
    } catch (err) {
        bootbox.error(t("messages.system_errors") + " : " + err.message);
    }
}

export function* changeStatusAccountPurchaserSaga(action) {
    try {

        let response = yield call(service.updateAccountPurchaser, action.payload.datas);
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.CHANGE_STATUS_ACCOUNT_PURCHASER.SUCCESS, payload: response.data});
            bootbox.success(t('account_purchaser.change_status_success'));
        } else {
            bootbox.error(t('account_purchaser.change_status_fails'));
        }
    } catch (err) {
        bootbox.error(t("messages.system_errors") + " : " + err.message);
    }
}

export function* updateAccountPurchaserSaga(action) {
    try {
        let response = yield call(service.updateAccountPurchaser, action.payload.datas);
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.UPDATE_ACCOUNT_PURCHASER.SUCCESS, payload: response.data});
            bootbox.success(t('account_purchaser.update_success'));
        } else {
            yield put({type: Constants.UPDATE_ACCOUNT_PURCHASER.FAILED, payload: response.data});
        }
    } catch (err) {
        yield put({type: Constants.UPDATE_ACCOUNT_PURCHASER.ERROR, payload: err});
        bootbox.error(t("messages.system_errors") + " : " + err.message);
    }
}

export function* createAccountPurchaserSaga(action) {
    try {
        let response = yield call(service.createAccountPurchaser, action.payload.datas);
        if (typeof response !== 'undefined' && response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.CREATE_ACCOUNT_PURCHASER.SUCCESS, payload: response.data});
            bootbox.success(t('account_purchaser.create_success'));
        } else {
            yield put({type: Constants.CREATE_ACCOUNT_PURCHASER.FAILED, payload: response.data});
        }
    } catch (err) {
        bootbox.error(t("messages.system_errors") + " : " + err.message);
        yield put({type: Constants.CREATE_ACCOUNT_PURCHASER.ERROR, payload: err});
    }
}

