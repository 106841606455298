import React from "react";
import PropTypes from "prop-types";
import notification, {Button, Icon, Modal, Form, Input, Row, Col, Switch, Select} from "antd";
import FormProcessing from "../../../common/components/FormProcessing";
import {withEvent} from "../../../../systems/events";
import {translate} from 'react-i18next';
import lodash from "lodash";
import {router, url} from './../../../../systems/routing/index';
import ORDER_GROUP_EVENTS from "../events";

const {Option} = Select;
const {TextArea} = Input
const defaultInput = {
    id: '',
    name: '',
    code: '',
    description: '',
    priority: 0,
    status: 1,
    id_agency: 0
}

class FormGroup extends FormProcessing {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            inputVersion: 0,
            changedParams: [],
            input: {},
            dataError: {},
            loading: false
        }
    }

    onClick = e => {
        this.setState({visible: true});
    }

    handleOk = e => {
        let {input} = this.state;
        this.setState({loading: true});

        if (lodash.get(input, 'id', null))
            this.props.onUpdateOrderGroup(input);
        else
            this.props.createOrderGroup(input);

        setTimeout(() => {
            if (lodash.isEmpty(this.props.dataError))
                this.handleCancel();

            this.setState({loading: false});
        }, 600);
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            input: defaultInput
        });
        this.onResetHandler();
    }

    onHandleUpdate = (event, group) => {
        this.setState({
            visible: true,
            input: {...group}
        });
    }

    componentDidMount() {
        this.props.on({
            [ORDER_GROUP_EVENTS.UPDATED]: this.onHandleUpdate
        });
        this.setState({input: defaultInput});
    }

    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        this.setState(state => ({input: {...state.input, ...input}}));
    }

    /**
     * Cập nhật dữ liệu thay đổi của input
     * @param field
     * @param event
     */
    onHandleChange = (field, event) => {
        let value = event.target.value;

        if (field === 'code') {
            value = value.trim();
            value = value.replace(/\s/g, '');
        }

        this.changeInput({[field]: value});
        this.props.resetErrorFormOrderGroup({[field]: value});
    }

    /**
     * Cập nhật dữ liệu thay đổi của select
     * @param event
     */
    onHandleChangeSelect = (field, value) => {
        this.changeInput({[field]: value});
        this.props.resetErrorFormOrderGroup({[field]: value});
    }

    /**
     * Thực hiện reset form
     * @param event
     */
    onResetHandler = () => {
        this.updateFormData(defaultInput);
        this.props.onCancelFormOrderGroup({});
        router.redirect(url.to('orderGroup.manager'));
    }

    /**
     * Cập nhật dữ liệu thay đổi của Switch
     * @param status
     * @param name
     */
    onHandleChangeSwitch(field, checked) {
        let value = checked ? 1 : 0;
        this.changeInput({[field]: value});
        this.props.resetErrorFormOrderGroup({[field]: value});
    }

    /**
     * Thực hiện render lỗi thông báo ra bên ngoài
     * @param attrName
     * @returns {null}
     */
    renderError = attrName => {
        let {dataError, t} = this.props;
        let errorItem = lodash.get(dataError, attrName, {});

        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('order_group.error.' + Object.keys(errorItem)[0], {name: t('order_group.' + attrName)});
                case 'CODE_USED':
                    return t('order_group.error.' + Object.keys(errorItem)[0], {name: t('order_group.' + attrName)});
                case 'max.string':
                    let objectmax = lodash.get(errorItem, 'max.string', null);
                    let overLength = lodash.get(objectmax, 'max', null);
                    return t('order_group.error.max_string', {
                        name: t('order_group.' + attrName),
                        value: overLength
                    });
                case 'integer':
                    return t('order_group.error.' + Object.keys(errorItem)[0], {name: t('order_group.' + attrName)});
                default:
                    return null;
            }
        }
    }

    render() {
        let {input} = this.state;
        let {agencies, permission} = this.props;

        if (!permission) return null;

        return <>
            <Button
                className="a-btn--primary-ant a-font--12 pl-4"
                style={{paddingTop: "1px"}}
                onClick={this.onClick} >
                <Icon type="plus-square" /> THÊM MỚI
            </Button>
            <Modal
                title={input.id ? "CẬP NHẬT NHÓM NGÀNH HÀNG" : "THÊM MỚI NHÓM NGÀNH HÀNG"}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                cancelText="Hủy"
                okText={input.id ? "Cập nhật" : "Thêm mới"}
                width={420}
                okButtonProps={{loading: this.state.loading}}
            >
                <Form className="form-order-group">
                    <Form.Item label="Agency" className="a-form--ant-item mb-0">
                        <Select
                            placeholder="Chọn agency"
                            value={input.id_agency || undefined}
                            onChange={value => this.onHandleChangeSelect("id_agency", value)}
                        >
                            {
                                agencies.length > 0 &&
                                agencies.map(item => {
                                    return <Option value={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("id_agency") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("id_agency")}
                        </div>
                    </Form.Item>
                    <Form.Item label="Tên ngành" className="a-form--ant-item mb-0 required">
                        <Input
                            type="text"
                            placeholder="Tên của ngành hàng"
                            allowClear
                            value={input.name || undefined}
                            onChange={e => this.onHandleChange("name", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("name") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("name")}
                        </div>
                    </Form.Item>
                    <Form.Item label="Mã ngành" className="a-form--ant-item mb-0 required">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Ví dụ: MOBILE"
                            value={input.code || undefined}
                            onChange={e => this.onHandleChange("code", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("code") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("code")}
                        </div>
                    </Form.Item>
                    <Form.Item label="Độ ưu tiên" className="a-form--ant-item mb-0">
                        <Input
                            type="text"
                            allowClear
                            placeholder="Ví dụ: 1, 2, 3, 4, 5"
                            value={input.priority || undefined}
                            onChange={e => this.onHandleChange("priority", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("priority") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("priority")}
                        </div>
                    </Form.Item>
                    <Form.Item label="Mô tả" className="a-form--ant-item mb-0">
                        <TextArea
                            style={{height: "80px"}}
                            placeholder="Mô tả về ngành hàng"
                            value={input.description || undefined}
                            onChange={e => this.onHandleChange("description", e)}
                        />
                        <div className={"validatorMessage a--error "
                        + ( this.renderError("description") ? 'active-error' : 'inactive-error' )}>
                            {this.renderError("description")}
                        </div>
                    </Form.Item>
                    <Form.Item className="m-0">
                        <Row gutter={0}>
                            <Col span={12}>Trạng thái</Col>
                            <Col span={12} className="a-text--right">
                                <Switch
                                    size="small"
                                    onChange={checked => this.onHandleChangeSwitch("status", checked)}
                                    checked={input.status === 1 ? true : false}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }
}

FormGroup.defaultProps = {
    agencies: [],
    dataDetail: {}
}

FormGroup.propTypes = {
    agencies: PropTypes.array.isRequired,
    dataDetail: PropTypes.object
}

export default translate()(withEvent(FormGroup));
