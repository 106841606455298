import React, {useState} from 'react';
import {Icon, Modal} from "antd";
import {trans} from "../../../../../systems/i18n";
import lodash from 'lodash';

export default function WarningServices({warningHasServices, warningNoServices, warningNoFeatures, warningHasFeatures}) {
    const [visible, setVisible] = useState(true);
    return (
        <Modal
            visible={visible}
            title={
                <>
                    <Icon type="warning"
                          style={{fontSize: "18px", color: "red"}}/> {trans("orderDetail:services_warning.title")}
                </>
            }
            onCancel={() => setVisible(false)}
            footer={null}
            bodyStyle={{border: "unset"}}
            width={650}
        >
            {
                warningHasServices.length > 0 && warningHasServices.map(service => {
                    const warning_message = lodash.get(service, "warning_message", false);
                    return <>
                        {
                            trans("orderDetail:services_warning.content_has_service",
                                {
                                    has: <b>{trans("orderDetail:services_warning.has")}</b>,
                                    service: <b>{lodash.get(service, "name", "")}</b>,
                                    message: warning_message ? warning_message : trans("orderDetail:services_warning.message_has_service")
                                })
                        }
                        <br/>
                    </>
                })
            }
            {
                warningNoServices.length > 0 && warningNoServices.map(service => {
                    const warning_message = lodash.get(service, "warning_message", false);
                    return <>
                        {
                            trans("orderDetail:services_warning.content_no_service", {
                                not_has: <b>{trans("orderDetail:services_warning.not_has")}</b>,
                                service: <b>{lodash.get(service, "name", "")}</b>,
                                message: warning_message ? warning_message : trans("orderDetail:services_warning.message_no_service")
                            })
                        }
                        <br/>
                    </>
                })
            }
            {
                warningHasFeatures.length > 0 && warningHasFeatures.map(feature => {
                    const warning_message = lodash.get(feature, "warning_message", false);
                    return <>
                        {
                            trans("orderDetail:services_warning.content_has_feature",
                                {
                                    has: <b>{trans("orderDetail:services_warning.has")}</b>,
                                    feature: <b>{lodash.get(feature, "name", "")}</b>,
                                    message: warning_message ? warning_message : trans("orderDetail:services_warning.message_has_service")
                                })
                        }
                        <br/>
                    </>
                })
            }
            {
                warningNoFeatures.length > 0 && warningNoFeatures.map(feature => {
                    const warning_message = lodash.get(feature, "warning_message", false);
                    return <>
                        {
                            trans("orderDetail:services_warning.content_no_feature", {
                                not_has: <b>{trans("orderDetail:services_warning.not_has")}</b>,
                                feature: <b>{lodash.get(feature, "name", "")}</b>,
                                message: warning_message ? warning_message : trans("orderDetail:services_warning.message_no_service")
                            })
                        }
                        <br/>
                    </>
                })
            }
        </Modal>
    )
}