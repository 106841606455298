import {apiWithToken} from '../../systems/api';
import {API_URL} from './../../systems/globalContant';

export default {
    getListAccountPurchaser: (filters = {}) => apiWithToken.get(API_URL.GET_LIST_ACCOUNT_PURCHASER, {params: filters}, {loading: false}),
    updateAccountPurchaser: (datas) => apiWithToken.put(API_URL.GET_LIST_ACCOUNT_PURCHASER + "/" + datas.id, {...datas}, {loading: false}),
    createAccountPurchaser: (datas) => apiWithToken.post(API_URL.GET_LIST_ACCOUNT_PURCHASER , {...datas}, {loading: false}),
    unassignAccountPurchaser: (id) => apiWithToken.delete(API_URL.DEFAULT + '/user-account-purchasers/' + id, {params: {}, loading: false}),
    assignAccountPurchaser: (datas) => apiWithToken.post(API_URL.CREATE_USER_ACCOUNT_PURCHASER + "/" + datas.id, {...datas}, {loading: false}),
    assignAccountPurchaserMulti: (datas) => apiWithToken.post(API_URL.CREATE_USER_ACCOUNT_PURCHASER_MULTI , {...datas}, {loading: false}),
    getAssignAccountLogs: (datas) => apiWithToken.get(API_URL.DEFAULT + `/logs/user/${datas.id}`, {id: datas.id}, {loading: false}),
    getAccountPurchaserToken: (filters) => apiWithToken.get(API_URL.DEFAULT + `/account_purchasers/tokens?scope=basic`, {params: filters}, {loading: false}),
    updateAccountPurchaserToken: (id, data) => apiWithToken.put(API_URL.DEFAULT + `/account-purchaser-tokens/${id}`, {...data}, {loading: false}),
};
