import React from 'react';
import PropTypes from 'prop-types';
import lodash from "lodash";

import {Layout} from "antd";

import {withEvent} from "../../../systems/events";
import apiService from "../../../modules/AccountPurchaser/apiService";
import {translate} from "react-i18next";
import {processResponse} from "../../../systems/api";
import notification from "../../../systems/notification";

import SearchableComponent from "../../../modules/common/SearchableComponent";
import FormSearch from "./FormSearch";
import ListToken from "./ListToken";
import {setActiveSettingItem} from "../../../modules/Settings/SettingMenu/actions";
import {dispatch} from "../../../systems/store";
import {setBreadCrumb, setMenuActive, setPageDescription, setPageTitle} from "../../../modules/common/actions";
import ACCOUNT_PURCHASER_EVENT from "../../../modules/AccountPurchaser/events";

const Content = Layout.Content;

class ListAccountPurchaserToken extends SearchableComponent {
    constructor(props) {
        super(props);

        this.state = {
            tokens: [],
            loading: false,
        };
    }
    componentDidMount() {
        super.componentDidMount();
        this.getPageInfo();
        this.props.on({
            [ACCOUNT_PURCHASER_EVENT.TOKEN_UPDATE.SUCCESS]: (event, payload) => {
                this.setState({
                    ...this.state,
                    tokens: this.state.tokens.map(token => {
                        if (token.id === payload.id) {
                            token = {...payload}
                        }
                        return token;
                    })
                })
            },
        })
    }

    getPageInfo() {
        let {t} = this.props;

        dispatch(setPageTitle(t('purchaser:page.token_title')));
        dispatch(setPageDescription(t('purchaser:page.token_description')));
        dispatch(setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'purchaser:page.token_title',
                'link': '/account-purchaser-token'
            },
        ]));
        dispatch(setMenuActive('account_purchaser_token'));
        setActiveSettingItem('account_purchaser_token');
    }

    onSearch(filter) {
        if (this.state.loading) {
            return;
        }

        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    }

    onChangeFilter(filter) {
        if (this.state.loading) {
            return;
        }

        let {t} = this.props;

        this.setState({loading: true});

        let res = apiService.getAccountPurchaserToken(filter)
            .finally(() => this.setState({loading: false}));

        let process = processResponse(res);

        process.then(res => {
            let tokens = lodash.get(res, 'data') || [];
            tokens = lodash.orderBy(tokens, ['id'], ['desc']);
            this.setState({
                tokens: tokens,
            });
        });

        process.catch(() => {
            notification.error(t('message.cant_fetch_data'));
        });
    }

    getFilter() {
        return {
            ...this.getFilterFromLocation(this.props.location),
        };
    }

    render() {
        let filter = this.getFilter();
        return (
            <Layout>
                <Content>
                    <FormSearch
                        loading={this.state.loading}
                        input={filter}
                        inputVersion={filter.i || 0}
                        onSearch={this.onSearch.bind(this)}
                    />
                    <ListToken
                        loading={this.state.loading}
                        tokens={this.state.tokens}
                    />
                </Content>
            </Layout>
        );
    }
}

ListAccountPurchaserToken.defaultProps = {

};

ListAccountPurchaserToken.propTypes = {

};

export default translate()(withEvent(ListAccountPurchaserToken));
