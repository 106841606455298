import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Select, Form, Input, DatePicker, Button} from 'antd';
import CollapsedItem from "./CollapsedItem";
import SelectPurchaseAddress from "../../../../../../modules/PurchaseAddress/component/SelectPurchaseAddress";
import SelectAgencies from "../../../../../../modules/Agency/SelectAgencies";
import {translate} from "react-i18next";
import SelectAccountPurchasers from "../../../../../../modules/AccountPurchaser/SelectAccountPurchasers";
import SelectTeller from "../../../../../../modules/User/SelectTellers";
import lodash from "lodash";
import {ORDER_TIME_TYPE, PRODUCT_RETURN_STATUS} from "../../../../../../systems/globalContant/actionContant";
import {trans} from "../../../../../../systems/i18n";
import moment from "moment";
import {dateFormatter} from "../../../../../../modules/common/services/format";
import ORDER_EVENTS from "../../../../../../modules/Order/events";
import SelectTellersV2 from "../../../../../../modules/User/SelectTellersV2";
import {withEvent} from "../../../../../../systems/events";
import FormProcessing from "../../../../../../modules/common/components/FormProcessing";
import DateRange from "../../../../../../modules/common/components/DateTime/DateRange";
import DateRangeCustomize from "../../../../../common/components/DateTime/DateRangeCustomize";

const {Option} = Select;
const InputGroup = Input.Group;


let timeOptions = lodash.values(ORDER_TIME_TYPE).map(Item => {
    return {label: trans("order:type_type." + Item), value: Item}
});

timeOptions = lodash.compact(timeOptions);

let productReturnStatus = Object.keys(PRODUCT_RETURN_STATUS).map((key, index) => {
    return {label: trans("productReturn:status." + PRODUCT_RETURN_STATUS[key].toLowerCase()), value: key}
});

let productReturnIsImportant = [
    {label: trans('complaintSeller:status.all'), value: 'ALL'},
    {label: trans('complaintSeller:list.product_return_important'), value: 'TRUE'},
    {label: trans('complaintSeller:list.product_return_not_important'), value: 'FALSE'}
];

class CollapsedFormSearch extends FormProcessing {

    constructor(props) {
        super(props);
        this.state = {
            currentId: 0,
            input: {},
            inputVersion: 1,
            changedParams: []
        }
    }

    changeCurrentId = id => {
        this.setState({currentId: id});
    };

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     const { filter }  = this.props;
    //     const { filter: preFilter } = prevProps;
    //     if (JSON.stringify(preFilter) !== JSON.stringify(filter)) {
    //         this.setState({
    //             input: { ...filter },
    //         });
    //     }
    // }

    componentDidMount() {
        this.setState({input: {...this.props.filter}});
        let resetFc = (event, payload) => this.setState({input: {...payload}});
        this.props.on({
            [ORDER_EVENTS.RESET_FILTER]: resetFc
        });

        document.querySelector("body").addEventListener("click", e => {
            let {target} = e,
                checkedTemp = false,
                checkedClassName = [
                    "form-search-col",
                    "ant-select-dropdown-menu",
                    "ant-calendar",
                    "a-filter--group-collapsed"
                ];

            const positionFc = item => target.parentNode.className.indexOf(item) > -1;

            while (target.parentNode && !checkedTemp) {
                if (target.parentNode.nodeName === "BODY" || (!lodash.isEmpty(target.parentNode.className) && checkedClassName.some(positionFc)))
                    checkedTemp = true;
                target = target.parentNode;
            }

            if (target.nodeName === "BODY" || target.nodeName === "#document")
                this.setState({currentId: 0});
        });
    }

    onChangeRangePicker = (dates) => {
        if (!lodash.isEmpty(dates))
            this.props.onSearch(this.changeInput({
                log_status_from: dates.log_status_from,
                log_status_to: dates.log_status_to,
                page: 1
            }));
        else
            this.props.onSearch(this.changeInput({
                log_status_from: "",
                log_status_to: "",
                page: 1
            }));
    }


    onSearchBlur = (param, e) => {
        let {input} = this.state;
        let {filter} = this.props;

        if ((input[param] === undefined || input[param] === "") && e.target.value === "" && (filter[param] === undefined || filter[param] === ""))
            return false;

        if (filter[param] === e.target.value)
            return false;

        this.props.onSearch({...input, page: 1});
    }

    onSearchSelect = (param, value) => {
        let filter = this.changeInput({[param]: value, page: 1});
        this.props.onSearch(filter);
    }

    onSearchInput = (param, e) => {
        this.onChangeInput(param, e);
        if (e.target.value === "")
            e.target.focus();
    }

    getSelectValue(value) {
        if (!value)
            return undefined;

        return Array.isArray(value) ? value.map(item => parseInt(item)) : parseInt(value);
    }

    render() {
        let {
            t,
            complaintSellerReasons,
            siteItems,
            timeOptions,
            purchasers,
            accountPurchasersOptions
        } = this.props;
        let {input} = this.state;
        let renewalCountOperations = ['>', '<'];

        return <Form>
            <Row className="a-list--form-search m-0 p-0 border-bottom">
                <Col span={6}>
                    <CollapsedItem
                        itemId={1}
                        currentId={this.state.currentId}
                        changeCurrentId={this.changeCurrentId}
                        active={true}
                        title="Bộ lọc khách hàng">
                        <Form.Item label="Mã đơn hàng" className="a-form--ant-item mb-0">
                            <Input
                                type="text"
                                allowClear
                                value={input.code || undefined}
                                placeholder={t('order:list.order_code_placeholder')}
                                onChange={e => this.onSearchInput("code", e)}
                                onBlur={e => this.onSearchBlur("code", e)}
                                onPressEnter={e => this.onSearchBlur("code", e)}
                                className={"_complaint-seller-search-code"}
                            />
                        </Form.Item>

                        <Form.Item label="TK khách hàng" className="a-form--ant-item mb-0">
                            <Input type="text"
                                   allowClear
                                   value={input.buyer_username || ''}
                                   placeholder={t('order:list.buyer_placeholder')}
                                   onChange={e => this.onSearchInput("buyer_username", e)}
                                   onBlur={e => this.onSearchBlur("buyer_username", e)}
                                   onPressEnter={e => this.onSearchBlur("buyer_username", e)}
                                   className={"_complaint-seller-search-buyer-username"}
                            />
                        </Form.Item>

                        <Form.Item label={t('complaintSeller:list.choose_reason_for_refund_money')} className="a-form--ant-item  mb-0">
                            <Select
                                value={input.complaint_seller_reason_id}
                                allowClear={true}
                                placeholder={t('complaintSeller:list.choose_complaint_seller_important')}
                                showSearch={true}
                                optionFilterProp="children"
                                onChange={value => this.onSearchSelect("complaint_seller_reason_id", value)}
                                className={"_complaint-seller-search-complaint-seller-reason-id"}
                            >
                                {complaintSellerReasons.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item label={t('complaintSeller:list.homeland')} className="a-form--ant-item  mb-0">
                            <SelectAgencies
                                value={input.id_agency}
                                multiple={true}
                                allOption={false}
                                allowClear={true}
                                placeholder={t('complaintSeller:list.homeland_placeholder')}
                                onChange={value => this.onSearchSelect("id_agency", value)}
                                className={"_complaint-seller-search-id-agency"}
                            />
                        </Form.Item>

                        <Form.Item label={t('order:label.invoice_code')} className="a-form--ant-item mb-0">
                            <Input
                                type="text"
                                allowClear
                                value={input.invoice_code || undefined}
                                placeholder={t('order:placeholder.invoice_code')}
                                onChange={e => this.onSearchInput("invoice_code", e)}
                                onBlur={e => this.onSearchBlur("invoice_code", e)}
                                onPressEnter={e => this.onSearchBlur("invoice_code", e)}
                                className={"_complaint-seller-search-invoice-code"}
                            />
                        </Form.Item>
                    </CollapsedItem>
                </Col>
                <Col span={6}>
                    <CollapsedItem
                        itemId={2}
                        currentId={this.state.currentId}
                        changeCurrentId={this.changeCurrentId}
                        className="border-left"
                        title="Bộ lọc tài khoản">
                        <Form.Item label="Yêu cầu trả hàng" className="a-form--ant-item mb-0">
                            <Select
                                value={input.product_return || undefined}
                                allowClear={true}
                                placeholder={t('complaintSeller:list.choose_product_return')}
                                showSearch={true}
                                optionFilterProp="children"
                                onChange={value => this.onSearchSelect("product_return", value)}
                                className={"_complaint-seller-search-product-return"}
                            >
                                {productReturnStatus.map(status => <Option key={status.value} value={status.value}>{status.label}</Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item label={t('complaintSeller:list.mark_search')} className="a-form--ant-item  mb-0">
                            <Select
                                value={input.is_important || undefined}
                                allowClear={true}
                                placeholder={t('complaintSeller:list.choose_complaint_seller_important')}
                                showSearch={true}
                                optionFilterProp="children"
                                onChange={value => this.onSearchSelect("is_important", value)}
                                className={"_complaint-seller-search-is-important"}
                            >
                                {productReturnIsImportant.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Số lần gia hạn" className="a-form--ant-item  mb-0">
                            <InputGroup compact>
                                <Select
                                    value={input.renewal_count_operation || renewalCountOperations[0]}
                                    allowClear={true}
                                    optionFilterProp="children"
                                    onChange={value => this.onSearchSelect("renewal_count_operation", value)}
                                    style={{ width: '65%'}}
                                    className={"_complaint-seller-search-renewal-count-operation"}
                                >
                                    {renewalCountOperations.map(operation => (
                                        <Option key={operation} value={operation}>{t('operation.' + operation)}</Option>
                                    ))}
                                </Select>
                                <Input
                                    type="number"
                                    min={0}
                                    max={10}
                                    style={{ width: '35%', paddingRight: "5px"}}
                                    value={input.renewal_count}
                                    onChange={e => this.onChangeInput("renewal_count", e)}
                                    onBlur={e => this.onSearchBlur("renewal_count", e)}
                                    onPressEnter={e => this.onSearchBlur("renewal_count", e)}
                                    className={"_complaint-seller-search-renewal-count"}
                                />
                            </InputGroup>
                        </Form.Item>

                        <Form.Item label={<span>Số tiền KN cần đòi</span>} className="a-form--ant-item mb-0">
                            <InputGroup compact>
                                <Input
                                    type="number"
                                    min={0}
                                    style={{ width: '50%', paddingRight: "5px"}}
                                    value={input.complaint_cost_from}
                                    placeholder="Từ"
                                    onChange={e => this.onChangeInput("complaint_cost_from", e)}
                                    onBlur={e => this.onSearchBlur("complaint_cost_from", e)}
                                    onPressEnter={e => this.onSearchBlur("complaint_cost_from", e)}
                                    className={"_complaint-seller-search-complaint-cost-from"}
                                />
                                <Input
                                    type="number"
                                    min={0}
                                    style={{ width: '50%', paddingRight: "5px"}}
                                    value={input.complaint_cost_to}
                                    placeholder="Đến"
                                    onChange={e => this.onChangeInput("complaint_cost_to", e)}
                                    onBlur={e => this.onSearchBlur("complaint_cost_to", e)}
                                    onPressEnter={e => this.onSearchBlur("complaint_cost_to", e)}
                                    className={"_complaint-seller-search-complaint-cost-to"}
                                />
                            </InputGroup>
                        </Form.Item>
                        <Form.Item label={<span>Số tiền KN đòi được</span>} className="a-form--ant-item mb-0">
                            <InputGroup compact>
                                <Input
                                    type="number"
                                    min={0}
                                    style={{ width: '50%', paddingRight: "5px"}}
                                    value={input.complaint_earned_from}
                                    placeholder="Từ"
                                    onChange={e => this.onChangeInput("complaint_earned_from", e)}
                                    onBlur={e => this.onSearchBlur("complaint_earned_from", e)}
                                    onPressEnter={e => this.onSearchBlur("complaint_earned_from", e)}
                                    className={"_complaint-seller-search-complaint-earned-from"}
                                />
                                <Input
                                    type="number"
                                    min={0}
                                    style={{ width: '50%', paddingRight: "5px"}}
                                    value={input.complaint_earned_to}
                                    placeholder="Đến"
                                    onChange={e => this.onChangeInput("complaint_earned_to", e)}
                                    onBlur={e => this.onSearchBlur("complaint_earned_to", e)}
                                    onPressEnter={e => this.onSearchBlur("complaint_earned_to", e)}
                                    className={"_complaint-seller-search-complaint-earned-to"}
                                />
                            </InputGroup>
                        </Form.Item>
                    </CollapsedItem>
                </Col>

                <Col span={6}>
                    <CollapsedItem
                        itemId={3}
                        currentId={this.state.currentId}
                        changeCurrentId={this.changeCurrentId}
                        className="border-left"
                        title="Bộ lọc sản phẩm">
                        <Form.Item label={'Tài khoản người bán'} className="a-form--ant-item  mb-0">
                            <Input type="text"
                                   allowClear
                                   value={input.order_seller_name || ''}
                                   placeholder={t('complaintSeller:list.seller_name_placeholder')}
                                   onChange={e => this.onSearchInput("order_seller_name", e)}
                                   onBlur={e => this.onSearchBlur("order_seller_name", e)}
                                   onPressEnter={e => this.onSearchBlur("order_seller_name", e)}
                                   className={"_complaint-seller-search-order-seller-name"}
                            />
                        </Form.Item>

                        <Form.Item label={t('complaintSeller:list.staff_name')} className="a-form--ant-item  mb-0">
                            <Select
                                value={input.id_processor}
                                mode="multiple"
                                allowClear={true}
                                placeholder={t('complaintSeller:list.staff_name')}
                                showSearch={true}
                                optionFilterProp="children"
                                onChange={value => this.onSearchSelect("id_processor", value)}
                                className={"_complaint-seller-search-staff-name"}
                            >
                                {purchasers.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="GDV xử lý đơn" className="a-form--ant-item  mb-0">
                            <Select
                                value={input.order_teller}
                                mode="multiple"
                                allowClear={true}
                                placeholder="Chọn GDV xử lý đơn"
                                showSearch={true}
                                optionFilterProp="children"
                                onChange={value => this.onSearchSelect("order_teller", value)}
                                className={"_complaint-seller-search-order-teller"}
                            >
                                {purchasers.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item label={t('complaintSeller:list.account_purchaser')} className="a-form--ant-item  mb-0">
                            <Select
                                value={input.account_purchaser_id}
                                mode="multiple"
                                allowClear={true}
                                placeholder={t('complaintSeller:list.account_purchaser_placeholder')}
                                showSearch={true}
                                optionFilterProp="children"
                                onChange={value => this.onSearchSelect("account_purchaser_id", value)}
                                className={"_complaint-seller-search-account-purchaser-id"}
                            >
                                {accountPurchasersOptions.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={t("complaintSeller:list.package_status_transport")}
                            className="a-form--ant-item  mb-0"
                        >
                            <Select
                                value={input.package_status_transport || ""}
                                allowClear={true}
                                onChange={value => this.onSearchSelect("package_status_transport", value)}
                                className={"_complaint-seller-search-have-package-returned"}
                            >
                                <Option value="">{t('label.all')}</Option>
                                <Option value={"RETURNED"}>{t("complaintSeller:list.package_retured")}</Option>
                            </Select>
                        </Form.Item>
                    </CollapsedItem>
                </Col>

                <Col span={6}>
                    <CollapsedItem
                        itemId={4}
                        currentId={this.state.currentId}
                        changeCurrentId={this.changeCurrentId}
                        className="border-left"
                        title="Bộ lọc thời gian">
                        <Form.Item label={"Nguồn hàng"} className="a-form--ant-item  mb-0">
                            <Select
                                value={input.order_seller_source}
                                mode="multiple"
                                allowClear={true}
                                placeholder={"Chọn nguồn hàng"}
                                showSearch={true}
                                optionFilterProp="children"
                                onChange={value => this.onSearchSelect("order_seller_source", value)}
                                className={"_complaint-seller-search-order-seller-source"}
                            >
                                {siteItems.map(item => <Option key={item} value={item}>{item}</Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item label={t('complaintSeller:list.choose_time')} className="a-form--ant-item  mb-0">
                            <Select
                                value={input.time_type}
                                allowClear={true}
                                placeholder={t('complaintSeller:list.choose_time')}
                                showSearch={true}
                                optionFilterProp="children"
                                onChange={value => this.onSearchSelect("time_type", value)}
                                className={"_complaint-seller-search-time-type"}
                            >
                                {timeOptions.map(item => <Option key={item.value} value={item.value}>{t('complaintSeller:status.' + ((item.label === 'waiting') ? 'created_at' : item.label))}</Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Thời gian" className="a-form--ant-item  mb-0">
                            <DateRangeCustomize
                                from={input['log_status_from']}
                                to={input['log_status_to']}
                                format = 'DD/MM/YYYY'
                                valueFormat = 'YYYY-MM-DD'
                                onChange={(from, to) => this.onChangeRangePicker({
                                    'log_status_from': from,
                                    'log_status_to': to,
                                })}
                                className={"_complaint-seller-search-time-log-status"}
                            />
                        </Form.Item>

                        <Form.Item label={t('complaintSeller:list.has_transaction')} className="a-form--ant-item  mb-0">
                            <Select
                                value={(input.has_transaction || input.has_transaction === 0) ? parseInt(input.has_transaction) : ""}
                                allowClear={true}
                                placeholder={t("complaintSeller:list.has_transaction")}
                                onChange={value => this.onSearchSelect("has_transaction", value)}
                                className={"_complaint-seller-search-has-transaction"}
                            >
                                <Option value="">{t('label.all')}</Option>
                                <Option value={0}>{t("complaintSeller:list.miss_transaction")}</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label={t('complaintSeller:list.financial_valid_label')} className="a-form--ant-item mb-0">
                            <Select
                                value={(input.financial_valid || input.financial_valid === 0) ? parseInt(input.financial_valid) : ""}
                                allowClear={true}
                                placeholder={t("complaintSeller:list.financial_valid_label")}
                                onChange={value => this.onSearchSelect("financial_valid", value)}
                                className={"_complaint-seller-search-financial-valid"}
                            >
                                <Option value="">{t('label.all')}</Option>
                                <Option value={0}>{t("complaintSeller:list.financial_invalid")}</Option>
                            </Select>
                        </Form.Item>
                    </CollapsedItem>
                </Col>
            </Row>
        </Form>;
    }

}

export default translate()(withEvent(CollapsedFormSearch));