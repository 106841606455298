import React from "react";
import {t} from "../../../../systems/i18n";
import lodash from "lodash";
import {Button, Icon} from "antd";


class SyncOrderBills extends React.Component {
    constructor(props) {
        super(props);
    }

    syncOrderBills(event) {
        const {orders, orderBillNoSyncs} = this.props;
        let orderIds = lodash.map(orders, 'id');

        this.props.onSync({
            selectedOrderIds: [...orderIds],
            completeSyncs: [],
            syncing: true
        });

        orderBillNoSyncs({
            id_orders: orderIds
        })
    }

    render() {
        let {syncing} = this.props;
        return (
            <React.Fragment>
                <Button
                    className="a-font--12 aphz-24 a-btn--primary-ant mr-4"
                    size={"small"}
                    icon="sync"
                    onClick={this.syncOrderBills.bind(this)}
                    loading={syncing}
                    disabled={this.props.disabled}
                >{t('order:scan.btn.sync')}</Button>
                <Button
                    className=" a-font--12 a-btn--primary-ant a-btn--link a-text--red"
                    size={"small"}
                    onClick={this.props.cancel.bind(this)}
                    disabled={this.props.disabled}
                >{t('btn.cancel')}</Button>
            </React.Fragment>


        )
    }
}
export default SyncOrderBills;
