import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {Checkbox} from '@atlaskit/checkbox';
import {translate} from 'react-i18next';

class FormFilter extends React.Component {
    /**
     * Xử lý khi thay đổi giá trị của type
     */
    onChangeType(type) {
        this.props.onChange({type: (type === 'ALL') ? null : type});
    }

    render() {
        let {t, filter} = this.props;
        return (
            <div className="flex--center a-chat__filter">
                {['ALL', 'COMMENT', 'LOG'].map(type => (
                    <div key={type+(lodash.get(filter,'type',null) || "ALL")}
                         className={'a-chat__group flex--center '
                         +(type === 'ALL' || type=== "COMMENT" ? "mr-2":"")
                         +(type === 'ALL' ? " a-chat__group-ml--5":"")
                         }
                         onClick={this.onChangeType.bind(this, type)}
                    >
                        <Checkbox style={{cursor:'pointer'}}
                                  value="Checked by default"
                                  name="checkbox-checked"
                                  defaultChecked={ (lodash.get(filter,'type',null) || "ALL") === type }
                        />
                        <label style={{cursor:'pointer'}}
                               className={'a-text--uppercase label-checkbox'}>
                            {t('log:type.' + type)}
                        </label>
                    </div>
                ))}
            </div>
        );
    }
}

FormFilter.defaultProps = {
    input: {},
    loading: false,
    onChange: input => {},
    onToogleCollapsedPublic: input => {},
};

FormFilter.propTypes = {
    input: PropTypes.object,
    loading: PropTypes.object,
    onChange: PropTypes.func,
    onToogleCollapsedPublic: PropTypes.func,
};

export default translate()(FormFilter);
