import {connect} from 'react-redux';
import Error from '../../layouts/errors';
import * as Selector from './selectors';

const mapStateToProps = (state) => {
    return {
        error: Selector.getError(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Error)