import {connect} from 'react-redux';
import AtlakitNavbar from './AtlakitNavbar';
import lodash from 'lodash';

const mapStateToProps = (state) => {
    return {
        error404: lodash.get(state, "Common.error404", false)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AtlakitNavbar)
