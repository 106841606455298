import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as Constants from './../constants';
import {ORDER_STATUS} from "../../../systems/globalContant/actionContant";
import {CONFIRM_CUSTOMER_ORDER_BUYING} from './../../PurchaseBuying/OrderDetail/constants';

const list = (state = [], action) => {
    let data = lodash.get(action, 'payload.data', {});
    let order;
    switch (action.type) {
        case Constants.FETCH_PURCHASES.SUCCESS:
            order = lodash.get(action, 'payload.data.orders', []);
            order = order.map(orderItem=> {
                return {
                    ...orderItem,
                    ...{link_favicons: 'https://www.google.com/s2/favicons?domain=' + lodash.get(orderItem, 'agency_domain', orderItem.id)}
                }
            });
            return [...order];
        case CONFIRM_CUSTOMER_ORDER_BUYING.SUCCESS: {
            order = action.payload.data;

            return [...state.map(orderItem => {
                if (order.id === orderItem.id) {
                    return {
                        ...orderItem,
                        ...{note_buyer_confirm: order.note_buyer_confirm ? order.note_buyer_confirm : []}
                    }
                }
                return orderItem;
            })];
        }
        case Constants.TELLER_READ_COMMENT:
            let newListOrder = [...state];
            let orderReadComment = action.payload;
            return newListOrder.map(orderItem => {
                if ("" + orderReadComment.id === "" + orderItem.id) {
                    return {
                        ...orderItem,
                        read_comment: parseInt(orderReadComment.read_comment, 10)
                    }
                }

                return orderItem;
            });
        case CONFIRM_CUSTOMER_ORDER_BUYING.WARNING:
            data = lodash.get(action, 'payload', {});
            return [...state.map(orderItem => {
                if (orderItem.id === data.id) {
                    return {
                        ...orderItem,
                        ...{note_buyer_confirm: []}
                    }
                }
                return orderItem;
            })];
        default:
            return state;
    }
};

const stats = (state = {}, action) => {
    switch (action.type) {
        case Constants.FETCH_PURCHASER_ORDER_STATS.REQUEST:
            return {};

        case Constants.FETCH_PURCHASER_ORDER_STATS.SUCCESS:
            return lodash.get(action.payload, 'data.stats', {});

        default:
            return state;
    }
};

/**
 * Pagination list order reducer
 */
const pagination = (state = {}, action) => {
    let {payload} = action;
    switch (action.type) {
        case Constants.FETCH_PURCHASES.SUCCESS:
            return lodash.get(payload, 'data.pagination', {from: 0, per_page: 20, total: 0});
        default:
            return state;
    }
};

/**
 * loading order reducer
 */
const loading = (state = false, action) => {
    switch (action.type) {
        case Constants.FETCH_PURCHASES.REQUEST:
            return true;
        case Constants.FETCH_PURCHASES.SUCCESS:
        case Constants.FETCH_PURCHASES.FAILED:
            return false;
        default:
            return state;
    }
};

const partner = (state = {}, action) => {
    switch (action.type) {
        case Constants.FETCH_PURCHASES.SUCCESS:
            return lodash.get(action.payload, 'data.partner', {});
        case Constants.FETCH_PURCHASES.FAILED:
            return {};
        default:
            return state;
    }
};

/**
 * End is process finished istribute Order
 * @param state
 * @param action
 * @returns {*}
 */
const endDistributeOrder = (state = false, action) => {
    switch (action.type) {
        case Constants.CREATE_DISTRIBUTOR_OWNER.SUCCESS:
        case Constants.CREATE_DISTRIBUTOR_OWNER.FAILED:
        case Constants.CREATE_DISTRIBUTOR_OWNER.BEGIN:
            return true;
        case Constants.CREATE_DISTRIBUTOR_OWNER.REQUEST:
        case Constants.CREATE_DISTRIBUTOR_OWNER.CLEAR:
            return false;
        default:
            return state;
    }
};

let defaultCountStatusChanged = {
    [ORDER_STATUS.ALL]: 0,
    [ORDER_STATUS.WAITING_PAI]: 0,
    [ORDER_STATUS.PAID]: 0,
    [ORDER_STATUS.WAITING_PAY]: 0,
    [ORDER_STATUS.BOUGHT]: 0,
    [ORDER_STATUS.OUT_OF_STOCK]: 0,
    [ORDER_STATUS.CANCELLED]: 0
};

const countStatusChanged = (state = defaultCountStatusChanged, action) => {
        switch (action.type) {
            case Constants.PUSHER_ASSIGN_ORDER:
                return {
                    ...state,
                    [action.payload.status]: lodash.get(state, action.payload.status, 0) + 1,
                    [ORDER_STATUS.ALL]: lodash.get(state, ORDER_STATUS.ALL, 0) + 1
                };
            case Constants.PUSHER_UN_ASSIGN_ORDER:
                let countStatus = lodash.get(state, action.payload.status, 0);
                let countAllStatus = lodash.get(state, ORDER_STATUS.ALL, 0);
                let newState = (countStatus === 0) ? {...state} : {...state, [action.payload.status]: countStatus - 1};
                newState = (countAllStatus === 0) ? {...newState} : {...newState, [ORDER_STATUS.ALL]: countAllStatus - 1};
                return newState;
            case Constants.CLEAR_COUNT_STATUS_CHANGED:
                return {...state, [lodash.get(action, "payload.status", ORDER_STATUS.ALL)]: 0};
            case Constants.CLEAR_ALL_COUNT_STATUS_CHANGED:
                return {...defaultCountStatusChanged};
            default:
                return state;
        }
    }
;

const getLoadingRequestOrder = (state = false, action) => {

    switch (action.type) {
        case Constants.CREATE_DISTRIBUTOR_OWNER.SUCCESS:
            let orders = lodash.get(action, 'payload.data.orderDistributor', []);
            if (orders.length > 0) {
                return true;
            }
            return false;
        case Constants.CREATE_DISTRIBUTOR_OWNER.FAILED:
        case Constants.CREATE_DISTRIBUTOR_OWNER.BEGIN:
        case Constants.CREATE_DISTRIBUTOR_OWNER.REQUEST:
        case Constants.CREATE_DISTRIBUTOR_OWNER.CLEAR:
            return false;
        default:
            return state;
    }
}

export default combineReducers({
    list,
    stats,
    loading,
    endDistributeOrder,
    pagination,
    countStatusChanged,
    partner,
    getLoadingRequestOrder
});
