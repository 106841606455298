import React from 'react'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { DatePicker, Modal, Input, Form, Col, Row, Icon } from 'antd'
import Tooltip from '@atlaskit/tooltip'
import moment from 'moment'
import { dateFormatter } from './../../../common/services/format'
import ComplaintUpdateNote from './../../ComplaintNote/ComplaintSellerNoteContainer'
import { COMPLAINT_STATUS } from '../../constants'
import SelectRenewReason from '../components/SelectRenewReason'
import Link from '../../../../systems/routing/Link'
import MarkImportantComplaint from '../../ComplaintMarkImportant/ComplaintSellerMarkImportantContainer'
import OriginCode from './OriginCode'
import bootbox from '../../../common/components/Bootbox/bootbox'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const TextArea = Input.TextArea
const defaultPayload = {
    expire_time: '',
    complaint_seller_renew_reason_id: '',
    note: '',
}

let disabledDate = current => {
    // Can not select days before today and today
    return current && current < moment().endOf('day')
}

class OrderInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: { ...defaultPayload },
            visible: false,
            showNote: false,
            loading: false,
        }
    }

    onChangeRenew = (date, dateString) => {
        if (lodash.isEmpty(date)) return null

        let value = date.format('YYYY-MM-DD')
        let { complaint } = this.props
        let input = { ...defaultPayload, expire_time: value, id: complaint.id }

        if (complaint.id_receiver === 0) {
            // KNNB chưa tiếp nhận thì hiển thị modal
            this.setState(preState => {
                return {
                    ...preState,
                    visible: true,
                    formData: { ...input },
                }
            })
        } else {
            // KNNB đã tiếp nhận thì gia hạn bình thường
            this.submitRenew(input)
        }
    }

    submitRenew = input => {
        this.props.expireClearErrorComplaintDetail({})
        this.props.expireUpdateComplaintDetail(input)
    }

    handleOk = () => {
        this.setState({
            loading: true,
        })
        let { formData } = this.state
        this.submitRenew(formData)
        setTimeout(() => {
            this.setState({
                visible: false,
                formData: { ...defaultPayload },
                showNote: false,
                loading: false,
            })
        }, 1000)
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            formData: { ...defaultPayload },
            showNote: false,
        })
    }

    isSpace = str => {
        while (str.indexOf(' ') >= 0) str = str.replace(' ', '')

        return str ? false : true
    }

    onChangeTextArea = e => {
        let value = e.target.value
        this.setState(preState => {
            return { formData: { ...preState.formData, note: value } }
        })
    }

    onChangeSelect = value => {
        let { renewReasons } = this.props
        // Lấy ra lý do có mã là OTHER (Lý do khác)
        let otherReason = renewReasons.filter(reason => reason.code === 'OTHER')[0]

        this.setState(preState => {
            if (otherReason && value === otherReason.id)
                // Nếu lý do là khác thì hiển thị textarea note để nhập ghi chú
                return {
                    ...preState,
                    formData: {
                        ...preState.formData,
                        complaint_seller_renew_reason_id: value,
                    },
                    showNote: true,
                }
            else
                return {
                    ...preState,
                    formData: {
                        ...preState.formData,
                        complaint_seller_renew_reason_id: value,
                        note: '',
                    },
                    showNote: false,
                }
        })
    }

    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        let formData = this.state.formData
        formData = { ...formData, ...input }
        this.setState({ formData })
    }

    checkSubmit = () => {
        let { formData } = this.state
        let { renewReasons } = this.props

        // Lấy ra lý do có mã là OTHER (Lý do khác)
        let otherReason = renewReasons.filter(reason => reason.code === 'OTHER')[0]

        if (otherReason && formData.complaint_seller_renew_reason_id === otherReason.id && (lodash.isEmpty(formData.note) || this.isSpace(formData.note)))
            return true

        return formData.complaint_seller_renew_reason_id ? false : true
    }

    getValueDatePicker = () => {
        let { formData } = this.state
        let { complaint } = this.props
        let expire_time = lodash.get(formData, 'expire_time', '')

        if (moment(expire_time).isValid()) return moment(expire_time)

        return moment(complaint.expire_time).isValid() ? moment(complaint.expire_time) : ''
    }

    render() {
        let { order, complaint, permissions, t } = this.props
        let { showNote, visible, loading } = this.state
        let freightBills = lodash.get(order, 'freightBills', [])
        let invoiceCodes = lodash.get(order, 'invoiceCodes', [])
        let { formData } = this.state
        let priority = lodash.get(complaint, 'time_remaining.priority', '')
        let time = lodash.get(complaint, 'time_remaining.time', '')
        let status = lodash.get(complaint, 'status', '')
        let expirationColor
        let currencySymbol = lodash.get(order, 'currencySymbol', '¥')
        if (status !== 'WAITING' && status !== 'PROCESSING') {
            expirationColor = ''
        } else {
            if (time === 0) {
                expirationColor = 'a-text--red'
            } else {
                switch (priority) {
                    case 'high':
                        expirationColor = 'a-text--danger'
                        break
                    case 'medium':
                        expirationColor = 'a-text--yellow'
                        break
                    case 'low':
                        expirationColor = 'a-text--success'
                        break
                    default:
                        expirationColor = ''
                }
            }
        }
        let { canRenew } = complaint

        return (
            <div className="complaint-seller-box a-content--box-shadow p-0 h-100">
                <div className="cs-box-title d-flex align-item-center p-3 pt-2 pb-2 a-text--bold">
                    <div className="a-text--fz-14 d-flex align-item-center">
                        <span>THÔNG TIN ĐƠN</span>
                        <span className="m-2 mt-0 mb-0">-</span>
                        <a
                            href={order.partner_url_order_detail}
                            target="_blank">
                            {order.code}
                        </a>
                        <CopyToClipboard
                            text={order.code}
                            onCopy={() => {
                                bootbox.success(t('message.copied') + ': ' + order.code)
                            }}>
                            <span className="ml-2 a-text--font-b content-btn-copy a-text--pointer">
                                <i className="far fa-copy copy" />
                            </span>
                        </CopyToClipboard>
                        <MarkImportantComplaint
                            complaint={complaint}
                            input={lodash.pick(complaint, ['id', 'is_important'])}
                            permissions={permissions}
                            inputVersion={complaint.id + complaint.is_important}
                        />
                    </div>
                    <a
                        href={complaint.urlOrder}
                        target="_blank"
                        className="a-text--bold a-text--green a-font--14 a-link a-text--uppercase">
                        <Icon
                            type="file-text"
                            className="mr-1"
                        />
                        {t('complaintSeller:detail.complaint_same_order')}
                    </a>
                </div>
                <div className="a__complaint__order p-3 border-top">
                    <Row gutter={15}>
                        <Col span={12}>
                            <Row>
                                <Col
                                    span={8}
                                    className="cs-order-row">
                                    <label className="a-text--font-b">{t('complaintSeller:detail.rate')}:</label>
                                </Col>
                                <Col
                                    span={16}
                                    className="cs-order-row">
                                    <p className="a-text--bold a-text--red">{order.rate_exchange_text} VNĐ</p>
                                </Col>

                                <Col
                                    span={8}
                                    className="cs-order-row">
                                    <label className="a-text--font-b">{t('complaintSeller:detail.status_order')}:</label>
                                </Col>
                                <Col
                                    span={16}
                                    className="cs-order-row">
                                    <p className="a-text--bold">{order.status ? t('order_detail.' + order.status) : '---'}</p>
                                </Col>

                                <Row>
                                    <Col
                                        span={8}
                                        className="cs-order-row">
                                        <label className="a-text--font-b">{t('complaintSeller:detail.invoice_codes')}:</label>
                                    </Col>
                                    <Col
                                        span={16}
                                        className="cs-order-row">
                                        <div>
                                            {invoiceCodes.map((invoiceCodeItem, index) => (
                                                <p key={index}>
                                                    <a
                                                        href={lodash.get(invoiceCodeItem, 'link', '')}
                                                        target={'_blank'}
                                                        className="a-text--green a-text--bold mb-1"
                                                        key={invoiceCodeItem.id}>
                                                        {invoiceCodeItem.invoice_code}
                                                    </a>
                                                </p>
                                            ))}
                                            {invoiceCodes.length ? '' : '---'}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col
                                        span={8}
                                        className="cs-order-row">
                                        <label className="a-text--font-b">{t('complaintSeller:detail.bill_no')}:</label>
                                    </Col>
                                    <Col
                                        span={16}
                                        className="cs-order-row">
                                        <div>
                                            {freightBills.map(freightBillItem => (
                                                <p
                                                    className="a-text--green a-text--bold mb-1"
                                                    key={freightBillItem.id}>
                                                    {freightBillItem.freight_bill}
                                                </p>
                                            ))}
                                            {freightBills.length ? '' : '---'}
                                        </div>
                                    </Col>
                                </Row>

                                <ComplaintUpdateNote
                                    complaint={complaint}
                                    input={lodash.pick(complaint, ['note'])}
                                    inputVersion={complaint.id}
                                    permissions={permissions}
                                />

                                {complaint.status === COMPLAINT_STATUS.FAILURE ? (
                                    <Row>
                                        <Col
                                            span={8}
                                            className="cs-order-row">
                                            <label className="a-text--font-b">{t('complaintSeller:failed.reason')}:</label>
                                        </Col>
                                        <Col
                                            span={16}
                                            className="cs-order-row">
                                            <p className="a-text--red">{complaint.note_failure || '--'}</p>
                                        </Col>
                                    </Row>
                                ) : null}
                            </Row>
                        </Col>

                        <Col span={12}>
                            <Row>
                                <Col
                                    span={8}
                                    className="cs-order-row">
                                    <label className="a-text--font-b">{t('complaintSeller:detail.money_label')}:</label>
                                </Col>
                                <Col
                                    span={16}
                                    className="cs-order-row">
                                    <p className="a-text--red a-text--bold amount-required">
                                        {complaint.amountFormat}
                                        {currencySymbol}
                                    </p>
                                </Col>

                                {complaint.status === 'SUCCESS' ? (
                                    <>
                                        <Col
                                            span={8}
                                            className="cs-order-row">
                                            <label className="a-text--font-b">{t('complaintSeller:detail.money_refund_label')}:</label>
                                        </Col>
                                        <Col
                                            span={16}
                                            className="cs-order-row">
                                            <p className="a-text--red a-text--bold amount-required">
                                                {complaint.amountEarned}
                                                {currencySymbol}
                                            </p>
                                        </Col>
                                    </>
                                ) : null}

                                <Col
                                    span={8}
                                    className="cs-order-row">
                                    <label className="a-text--font-b">{t('complaintSeller:detail.products')}:</label>
                                </Col>
                                <Col
                                    span={16}
                                    className="cs-order-row">
                                    <span className="a-text--bold d-flex">
                                        <Tooltip content={t('complaintSeller:detail.total_item_qty')}>
                                            <span>{order.total_item_qty}</span>
                                        </Tooltip>{' '}
                                        /
                                        <Tooltip content={t('complaintSeller:detail.total_item_qty_bought')}>
                                            <span>{order.total_item_qty_bought}</span>
                                        </Tooltip>{' '}
                                        /
                                        <Tooltip content={t('complaintSeller:detail.total_item_qty_received')}>
                                            <span>{order.total_item_qty_received}</span>
                                        </Tooltip>
                                    </span>
                                </Col>

                                {complaint.status === 'PROCESSING' || complaint.status === 'WAITING' ? (
                                    <>
                                        <Col
                                            span={8}
                                            className="cs-order-row">
                                            <label className="a-text--font-b">{t('complaintSeller:detail.number_extension')}:</label>
                                        </Col>
                                        <Col
                                            span={16}
                                            className="cs-order-row">
                                            <p className="a-text--bold">{complaint.renewal_count}</p>
                                        </Col>
                                    </>
                                ) : (
                                    ''
                                )}

                                <Col
                                    span={8}
                                    className="cs-order-row">
                                    <label className={'a-text--font-b'}>{t('complaintSeller:detail.expire_processing')}:</label>
                                </Col>
                                <Col
                                    span={16}
                                    className="cs-order-row">
                                    <div className={'a-datetime--complantseller'}>
                                        {canRenew ? (
                                            <DatePicker
                                                value={this.getValueDatePicker()}
                                                format={'DD-MM-YYYY'}
                                                placeholderText={t('complaintSeller:detail.renew_placeholder')}
                                                onChange={this.onChangeRenew}
                                                disabledDate={disabledDate}
                                                size="small"
                                            />
                                        ) : (
                                            <div className={`${expirationColor}`}>
                                                {moment(complaint.expire_time).isValid() ? dateFormatter.date(complaint.expire_time) : '---'}
                                            </div>
                                        )}
                                        <Modal
                                            title="Lý do gia hạn KNNB"
                                            visible={visible}
                                            onOk={this.handleOk}
                                            onCancel={this.handleCancel}
                                            okText="Thực hiện"
                                            cancelText="Hủy"
                                            okButtonProps={{ disabled: this.checkSubmit(), loading }}>
                                            <Form.Item
                                                label="Lý do gia hạn"
                                                className="a-form--ant-item mb-0">
                                                <SelectRenewReason
                                                    placeholder="Chọn lý do gia hạn"
                                                    value={formData.complaint_seller_renew_reason_id || undefined}
                                                    onChange={this.onChangeSelect}
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                            {showNote && (
                                                <Form.Item
                                                    label="Ghi chú lý do"
                                                    className="a-form--ant-item mb-0 required">
                                                    <TextArea
                                                        value={formData.note}
                                                        style={{ height: '100px' }}
                                                        onChange={this.onChangeTextArea}
                                                        placeholder="Ghi chú lý do gia hạn"
                                                    />
                                                </Form.Item>
                                            )}
                                        </Modal>
                                    </div>
                                </Col>

                                <OriginCode
                                    complaint={complaint}
                                    order={order}
                                    updateOriginCodeComplaint={this.props.updateOriginCodeComplaint}
                                    updateComplaintDetailAgain={this.props.updateComplaintDetailAgain}
                                />
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

OrderInfo.defaultProps = {
    order: {},
    permissions: {},
    canUpdateRenew: false,
    filterDefault: {},
}

OrderInfo.propTypes = {
    filterDefault: PropTypes.object,
    permissions: PropTypes.object,
    canUpdateRenew: PropTypes.bool,
    order: PropTypes.object,
}

export default translate()(OrderInfo)
