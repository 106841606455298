import {call, put} from 'redux-saga/effects'
import * as Constants from './constants';
import service from './apiService';
import bootbox from "../../common/components/Bootbox/bootbox";
import {t} from '../../../systems/i18n/index.js';
import * as GlobalConstants from "../../../systems/globalContant/index";
import lodash from 'lodash';
import {updateTransaction} from "../ListTransaction/actions";

export function* deleteInvoiceCodeTransaction(action) {
    try {
        let response = yield call(service.deleteInvoiceCodeFromTransaction, action.payload);
        if (response) {
            if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                bootbox.success(t('transaction:message.delete_invoice_code_success'));
                yield put({type: Constants.DELETE_INVOICE_CODE_FROM_TRANSACTION.SUCCESS, payload: response.data});

                let transaction = lodash.get(response.data.data, 'transaction', null);
                if(transaction) {
                    yield put(updateTransaction(transaction.id, transaction));
                }

            } else {
                yield put({type: Constants.DELETE_INVOICE_CODE_FROM_TRANSACTION.FAILED, payload: response.data});
            }
        }
    } catch (err) {
        bootbox.error(t('message.system_errors'));
    }
}

export function* deleteInvoiceCodeTransactionFail(action) {
    if (Array.isArray(lodash.get(action, 'payload.data.invoice_code.ALREADY_EXIST', null))) {
        return yield  bootbox.error(t('transaction:message.delete_invoice_code_not_exits'));
    } else {
        return yield  bootbox.error(t('transaction:message.delete_invoice_code_fail'));
    }
}