import React from "react";
import PropTypes from "prop-types";
import {Col, Row, Divider, Switch, Icon, Button, notification} from "antd";
import {events} from "../../../systems/events";
import PURCHASE_ADDRESS_EVENTS from "../../../modules/PurchaseAddress/events";
import {processResponse} from "../../../systems/api";
import apiService from "../../../modules/PurchaseAddress/apiService";
import lodash from "lodash";
import {updateCollectionItem} from "../../../modules/common/helps";
import {translate} from 'react-i18next';

class AddressItem extends React.Component {

    handleClick = e => {
        let {address} = this.props;
        events.dispatch(PURCHASE_ADDRESS_EVENTS.UPDATED, address);
    }

    handleChange = checked => {
        let {updateState, t, address, data} = this.props;
        let res = processResponse(apiService.updatePurchaseAddress(address.id, {...address, status: checked === true ? 1 : 0}))
            .then(res => {
                let purchase_address = lodash.get(res, "data.purchaseAddress");
                data = updateCollectionItem(data, purchase_address.id, purchase_address);
                updateState({data});
                notification.success({message: t('purchase_address.updated_status_success')});
            })
            .catch(error => {
                notification.error({message: t('purchase_address.updated_status_fail')});
            });
    };

    render() {
        let {address, permission} = this.props;
        return <Row className="group-line pt-3 pb-3">
            <Col span={3}>
                <div>
                    <span className={`${address.status ? "active" : ""} blue-cycle`}>{address.alias}</span>
                    {address.default === 1 && <span className="a-text--color-primary"> (Mặc định)</span>}
                    <Divider type="vertical"/>
                    <span className="a--cursor-poiter" onClick={this.handleClick}><Icon type="edit" /></span>
                </div>
            </Col>
            <Col span={3}>
                <div>
                    <span className="a-text--color-black">{address.name}</span>
                </div>
            </Col>
            <Col span={8}>
                <div>
                    <span className="a-text--color-black">Address: {address.address}</span>
                    <span className="a-text--color-black">{address.district ? " - " + address.district : ''}</span>
                    <span className="a-text--color-black">{address.city ? " - " + address.city : ''}</span>
                    <span className="a-text--color-black">{address.state ? " - " + address.state : ''}</span>
                    <span className="a-text--color-black">{address.zip_code ? " - ZipCode: " + address.zip_code : ''}</span>
                </div>
            </Col>
            <Col span={3}>
                <div>
                    <span className="a-text--color-black">{address.receiver}</span>
                </div>
            </Col>
            <Col span={3}>
                <div>
                    <span className="a-text--color-black">{address.phone}</span>
                </div>
            </Col>
            <Col span={3}>
                <div className="a-text--right">
                    <span className="mr-2">Trạng thái</span>
                    {
                        permission ?
                            <Switch size="small" checked={address.status ? true : false} onChange={this.handleChange} /> :
                            <Switch size="small" checked={address.status} />
                    }
                </div>
            </Col>
        </Row>;
    }
}

AddressItem.defaultProps = {
    group: {}
}

AddressItem.propTypes = {
    group: PropTypes.object.isRequired
}

export default translate()(AddressItem);
