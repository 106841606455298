import {createAction} from 'redux-actions';
import * as ACTION from './constants';

export const fetchOrderGroup = createAction(ACTION.FETCH_ORDER_GROUP.REQUEST, (filter = {}) => ({filter}));
export const fetchDetailOrderGroup = createAction(
    ACTION.FETCH_DETAIL_ORDER_GROUP.REQUEST, (filters = {}) => ({filters})
);
export const createOrderGroup = createAction(ACTION.CREATE_ORDER_GROUP.REQUEST, (params = {}) => ({params}));
export const updateOrderGroup = createAction(ACTION.UPDATE_ORDER_GROUP.REQUEST, (params = {}) => ({params}));
export const updateStatusOrderGroup = createAction(
    ACTION.UPDATE_STATUS_ORDER_GROUP.REQUEST, (params = {}) => ({params}));
export const cancelFormOrderGroup = createAction(ACTION.CANCEL_FORM_ORDER_GROUP.REQUEST, (params = {}) => ({params}));
export const resetErrorFormOrderGroup = createAction(
    ACTION.RESET_ERROR_INPUT_ORDER_GROUP.REQUEST, (params = {}) => ({params})
);

