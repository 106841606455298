import {takeLatest, all, fork} from 'redux-saga/effects';
import * as constants from './constants';
import * as saga from './saga';
import DistributeOrderWatcher from './DistributeOrder/sagaWatcher';

export default function*() {
    yield all([fork(DistributeOrderWatcher)]);
    yield takeLatest(constants.FETCH_ORDERS.REQUEST, saga.getListOrder);
    yield takeLatest(constants.FETCH_TRANSACTION_ORDERS.REQUEST, saga.getListTransactionOrder);
    yield takeLatest(constants.FETCH_ORDERS.SUCCESS, saga.onFetchOrdersSuccess);
    yield takeLatest(constants.FETCH_ORDER_COUNT.REQUEST, saga.fetchOrderCount);
    yield takeLatest(constants.FETCH_ORDER_STATS.REQUEST, saga.fetchOrderStats);
}