import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import apiService from "../apiService";
import {Select} from 'antd';
import notification from "../../../systems/notification";
import {processResponse} from "../../../systems/api";
import ServicePurchaseAddress from "../ServicePurchaseAddress";

const {Option} = Select;

class SelectPurchaseAddress extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            purchaseAddress: [],
            loading: false
        };
    }

    componentDidMount() {
        this.fetchPurchaseAddress();
    }

    fetchPurchaseAddress() {
        let {t} = this.props;
        this.setState({loading: true});
        ServicePurchaseAddress.list()
            .then(res => this.setState({purchaseAddress: res}))
            .finally(() => this.setState({loading: false}));
    }

    getValue() {
        let {value, multiple} = this.props;
        if (!value)
            return undefined;

        if (multiple) {
            return Array.isArray(value) ? value.map(item => parseInt(item)) : [parseInt(value)];
        } else
            return parseInt(value);
    }

    render() {
        let {t} = this.props;
        return (
            <Select
                value={this.state.loading ? undefined : this.getValue()}
                showSearch={true}
                optionFilterProp={"children"}
                mode={this.props.multiple ? 'multiple' : 'default'}
                allowClear={this.props.allowClear}
                loading={this.state.loading}
                onChange={this.props.onChange}
                className={this.props.className}
                placeholder={this.props.placeholder}
                size={this.props.size ? this.props.size : "default"}
                style={this.props.style ? this.props.style : {}}
                autoFocus={this.props.autoFocus}
                onBlur={this.props.onBlur}
            >
                {this.props.allOption && <Option value="">{t('label.all')}</Option>}
                {
                    this.state.purchaseAddress.map(item => {
                        if (this.props.onlyActive && !item.status)
                            return null;

                        return <Option
                            key={item.id}
                            value={item.id}
                            className={item.status ? '' : 'a-text--color-black-30'}
                        >{item.name}</Option>;
                    })
                }
            </Select>
        );
    }
}

SelectPurchaseAddress.defaultProps = {
    value: undefined,
    allOption: false,
    multiple: false,
    onChange: value => {},
    className: '',
    placeholder: ''
};

SelectPurchaseAddress.propTypes = {
    value: PropTypes.any,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholer: PropTypes.string
};

export default translate()(SelectPurchaseAddress);
