import {combineReducers} from 'redux';
import {PAYMENT_ORDER, WAITING_PAYMENT_ORDER} from '../OrderDetail/constants';

/**
 * State lưu trạng thái loading
 */
const loading = (state = false, action) => {
    switch (action.type) {
        case PAYMENT_ORDER.REQUEST:
        case WAITING_PAYMENT_ORDER.REQUEST:
            return true;

        case PAYMENT_ORDER.SUCCESS:
        case PAYMENT_ORDER.FAILED:
        case PAYMENT_ORDER.CANCEL:
        case WAITING_PAYMENT_ORDER.SUCCESS:
        case WAITING_PAYMENT_ORDER.FAILED:
        case WAITING_PAYMENT_ORDER.CANCEL:
            return false;

        default:
            return state;
    }
};

/**
 * State lưu trạng thái loading
 */
const error = (state = null, action) => {
    switch (action.type) {
        case PAYMENT_ORDER.FAILED:
        case WAITING_PAYMENT_ORDER.FAILED:
            return action.payload;

        case PAYMENT_ORDER.SUCCESS:
        case PAYMENT_ORDER.CANCEL:
        case WAITING_PAYMENT_ORDER.SUCCESS:
        case WAITING_PAYMENT_ORDER.CANCEL:
            return null;

        default:
            return state;
    }
};

export default combineReducers({
    loading,
    error,
})
