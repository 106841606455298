import lodash from 'lodash'
import React from 'react'

/**
 * Get the value of env param
 *
 * @param param
 * @param defaultValue
 * @return mixed
 */
export const getEnv = (param, defaultValue = null) => lodash.get(process.env, param, defaultValue)

/**
 * Get global config
 *
 * @param param
 * @param defaultValue
 * @return mixed
 */
export const getConfig = (param, defaultValue = null) => lodash.get(window.APP_CONFIG, param, defaultValue)

export const getVar = (object, param, defaultValue = null) => {
    let value = lodash.get(object, param)

    return value === undefined || value === null || value === '' ? defaultValue : value
}
