import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {Select} from 'antd';
import {accountPurchaserService} from '../services';
import {allAccountPurchaserService} from '../services';

const {Option} = Select;

class SelectAccountPurchasers extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            accounts: [],
            loading: false,
        };
    }

    componentDidMount = () => {
        this.fetchAccountPurchasers();
    };

    fetchAccountPurchasers() {
        let {allAccount} = this.props;
        this.setState({loading: true});
        if(allAccount) {
            allAccountPurchaserService.listActive()
                .then(accounts => this.setState({accounts}))
                .finally(() => this.setState({loading: false}));
        } else {
            accountPurchaserService.listActive()
                .then(accounts => this.setState({accounts}))
                .finally(() => this.setState({loading: false}));
        }

    }

    getValue() {
        let {value, multiple, searchByAccount} = this.props;
        if (!value)
            return undefined;

        if (!searchByAccount && multiple)
            return Array.isArray(value) ? value.map(item => parseInt(item)) : [parseInt(value)];
        else if (!searchByAccount && !multiple)
            return parseInt(value);
        else
            return value;
    }

    render() {
        let {t, searchByAccount} = this.props;

        return (
            <Select
                showSearch={true}
                optionFilterProp={"children"}
                mode={this.props.multiple ? 'multiple' : 'default'}
                value={this.state.loading ? undefined : this.getValue()}
                allowClear={this.props.allowClear}
                loading={this.state.loading}
                onChange={this.props.onChange}
                className={this.props.className}
                placeholder={this.props.placeholder}
            >
                {this.props.allOption && <Option value="">{t('label.all')}</Option>}
                {this.state.accounts.map(account => <Option key={account.id} value={ (searchByAccount) ? account.account : account.id }>{account.account}</Option>)}
            </Select>
        );
    }
}

SelectAccountPurchasers.defaultProps = {
    value: undefined,
    allOption: false,
    multiple: false,
    onChange: value => {},
    onBlur: value => {},
    className: '',
    placeholder: '',
    searchByAccount: false
};

SelectAccountPurchasers.propTypes = {
    value: PropTypes.any,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    placeholer: PropTypes.string,
    searchByAccount: PropTypes.bool
};

export default translate()(SelectAccountPurchasers);
