import {combineReducers} from 'redux';
import * as ACTION from './constants';

let token = (state = null, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.GENERATE_TOKEN.SUCCESS:
            return payload.data.token;

        case ACTION.GENERATE_TOKEN.REQUEST:
        case ACTION.CLEAR_STATE:
            return null;

        default:
            return state;
    }
};

let error = (state = null, action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.GENERATE_TOKEN.FAILED:
            return payload;

        case ACTION.GENERATE_TOKEN.SUCCESS:
        case ACTION.CLEAR_STATE:
            return null;

        default:
            return state;
    }
};

let loading = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.GENERATE_TOKEN.REQUEST:
            return true;

        case ACTION.GENERATE_TOKEN.SUCCESS:
        case ACTION.GENERATE_TOKEN.FAILED:
        case ACTION.CLEAR_STATE:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    token,
    error,
    loading,
});
