import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Divider, Icon} from 'antd';
import lodash from "lodash";
import {dateFormatter} from "../../common/services/format";

class ViolationReason extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            reason: '',
        };
    }


    render() {
        let {t, order} = this.props;
        return (
            <div className={"description-trade text-justify"}>
                    <span className="a-font--12 a-text--gray-new a-text--bold mr-2 ">
                        <Icon type="file-text" className="mr-2 "/>
                        {t('order:label.violation')}
                    </span>
                <span className="a-font--12 a-text--gray-new">
                            {t('transaction:label.resolve_reason')}: {order.violation_note} <Divider type="vertical"/> {t('transaction:label.resolver')}: {lodash.get(order, 'violationCreator.name')} <Divider type="vertical"/> {dateFormatter.dateTime(order.violation_time)}
                    </span>

            </div>
        );
    }
}

ViolationReason.defaultProps = {
    order: {},
};

ViolationReason.propTypes = {
    order: PropTypes.object,
};

export default translate()(ViolationReason);
