import React from "react";
import {translate} from "react-i18next";
import {Empty, Table} from "antd";
import {dateFormatter, currentFormatter} from "../../../modules/common/services/format";
import {url} from "../../../systems/routing";
import Link from "../../../systems/routing/Link";
import lodash from "lodash";
import {trans} from "../../../systems/i18n";
import {transition} from "@atlaskit/toggle/dist/cjs/styled/constants";
const { Column } = Table;

class ListSpendTransactions extends React.Component {

    formatTransaction(transaction) {
        return {
            ...transaction,
            amount_format: ((transaction.collect_spend === "SPEND" || transaction.type === "SPEND_ASK_HELP") ? '-' : '') + currentFormatter.toLocaleStringCurrency(parseFloat(transaction.amount), 5) + '¥',
            amount_class: transaction.collect_spend === 'COLLECT' ? 'a-text--color-primary' : (transaction.collect_spend === 'SPEND' ? 'a-text--red' : ''),
            type_format: trans('transaction:type.' + transaction.type)
        };
    }


    render() {
        let {typeOpacity, t} = this.props;
        let spendTransactions = this.props.spendTransactions.map(transition => this.formatTransaction(transition));
        return <>
            <h3 className="a-font--14 mt-3 mb-5 a-text--color-black-85">{t("problem_order.list_spend_transaction")}</h3>
            <Table
                dataSource={spendTransactions}
                rowKey="id"
                pagination={false}
                locale={{
                    emptyText: (
                        <Empty
                            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                            description={<span>{t("transaction:label.no_data")}</span>}
                        >
                        </Empty>)
                }}
                rowClassName={(record) => {
                    return lodash.includes(typeOpacity, record.type) ? "opacity_6" : "";
                }}>
                <Column
                    key="code"
                    dataIndex="code"
                    title={t("problem_order.transaction_no")}
                    width="33%"
                    render={(text, record) => {
                        return <Link isNewTab={true} href={url.to('transaction.detail', {id: record.id})}>{text}</Link>
                    }} />
                <Column
                    key="created_at"
                    dataIndex="created_at"
                    title={t("account_purchaser.time")}
                    width="25%"
                    render={(text) => dateFormatter.dateTime(text)} />
                <Column
                    key="type_format"
                    dataIndex="type_format"
                    title={t("transaction:label.type")}
                    width="30%"/>
                <Column
                    key="amount_format"
                    dataIndex="amount_format"
                    title={t("problem_order.amount")}
                    align="right"
                    render={(text, record) => {
                        return <strong className={record.amount_class}>{text}</strong>
                    }}/>
            </Table>
        </>;
    }
}

export default translate()(ListSpendTransactions);