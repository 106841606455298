import React from "react";
import {translate} from "react-i18next";
import ChangeStatus from "./ChangeStatus";

const ApprovalStatus = function (props) {
    let { approval, t } = props;

    if (approval.status === "CANCELLED" || approval.status === "SUCCESS" || !approval.canChangeState)
        return <span>{t("changeInvoiceRequest:status."+approval.status)}</span>

    return (
        <>
            <ChangeStatus
                approval={approval}
                status="SUCCESS"
                className="a-text--color-green"
                updateInvoiceCodeApprovals={props.updateInvoiceCodeApprovals}
            />
            <span className="p-2 text-black-50">|</span>
            <ChangeStatus
                approval={approval}
                status="CANCELLED"
                className="a-text--red"
                updateInvoiceCodeApprovals={props.updateInvoiceCodeApprovals}
            />
        </>
    );
}

export default translate()(ApprovalStatus);
