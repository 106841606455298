import React from "react";
import Link from "../../../systems/routing/Link";
import lodash from "lodash";
import * as GlobalConstant from "../../../systems/globalContant";
import {translate} from "react-i18next";
import {Tooltip} from "antd";

let menuSettingActive = [
    "AccountPurchaser",
    "PurchaseAddress",
    "OrderGroup",
    "OrderBillNo",
    "purchasers",
];

class MenuGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = { expand: false }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.isOpen && prevState.expand) this.setState({expand: false});
    }

    toggleSubMenu = (e) => {
        e.preventDefault();
        if (!this.state.expand) {
            this.props.openMenu();
            this.setState({expand: true});
        } else {
            this.setState({expand: false});
        }
    }

    closeMenu = () => {
        this.props.closeMenu();
        this.setState({expand: false});
    }

    handleActive = () => {
        let {menu_active, menu_group} = this.props;
        return menu_group.sub_menu.some(sub => menu_active === sub.key || (sub.key === 'settings' && lodash.includes(menuSettingActive, menu_active))) ? 'active' : '';
    }

    render() {
        let {menu_group, menu_active, t, showNumber, isOpen} = this.props;
        let { expand } = this.state;

        return menu_group.sub_menu.length ?
            <div className={`a_sidebar__body__group ${(expand && isOpen) ? "expand" : ""} ${this.handleActive()}`}>
                <Tooltip placement="right" title={t(`${menu_group.text}`)}>
                    <p className="a_sidebar__item">
                        <a className="a_sidebar__link" onClick={this.toggleSubMenu.bind(this)}>
                            <i className={lodash.get(menu_group, 'icon.class_name')}/>
                            <span>{t(menu_group.text)}</span>
                        </a>
                    </p>
                </Tooltip>
                <ul>
                    { menu_group.sub_menu.map((sub) => {
                        return (<li
                            key={sub.key}
                            onClick={this.closeMenu.bind(this)}
                            className={'a_sidebar__item a_sidebar__sub ' + (menu_active === sub.key || (sub.key === 'settings' && lodash.includes(menuSettingActive, menu_active) ) ? 'active' : '' )}>
                            <Link href={sub.href} className={"a_sidebar__link"}>
                                {t(sub.text)}
                            </Link>
                            { showNumber && menu_active !== sub.key && sub.key === GlobalConstant.LIST_ORDER_MENU ?
                                <div className="notify__number">
                                    <span>{showNumber}</span>
                                </div>
                                : null
                            }
                        </li>)
                    })}
                </ul>
            </div> :
            null;
    }
}

export default translate()(MenuGroup);
