import {connect} from 'react-redux';
import Logout from './components';
import {logout} from './../../modules/User/actions'
const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleLogout() {
            dispatch(logout());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Logout)