import React, {useState} from 'react';
import apiService from "../../apiService";
import lodash from 'lodash';
import {notification, Input, Tooltip, Icon} from "antd";
import {processResponse} from "../../../../../systems/api";
import {t} from "../../../../../systems/i18n";

export default function InternalCode(props) {
    const {order, item} = props;
    const [isEdit, setIsEdit] = useState(false);
    const [code, setCode] = useState(lodash.get(item, "internal_code", null));

    function onPressEnter() {
        if (code.trim().length === 0) {
            setIsEdit(false);
            setCode(lodash.get(item, "internal_code", null));
            return;
        }

        let response = processResponse(apiService.updateItemInternalCode(item.id, {code}));
        response.then((res) => {
            setIsEdit(false);
            notification.success({message: `Cập nhật thông tin mã sản phẩm nội bộ thành công`});
        });
        response.catch(err => {
            setCode(lodash.get(item, "internal_code", null));
            setIsEdit(false);
            notification.error({message: `Cập nhật thông tin mã nội bộ sản phẩm không thành công`});
        });
    }

    return (
        <div>
            {
                (isEdit && order.canUpdateInternalCode) ?
                    <div className="d-flex align-items-center a-text--font-primary a-text--break-all ml-3">
                        Mã sản phẩm nội bộ:
                        <div style={{width: "auto"}}>
                            <Input
                                value={code}
                                onPressEnter={onPressEnter}
                                onBlur={() => setIsEdit(false)}
                                onChange={(e) => setCode(e.target.value)}
                                placeholder={"Nhập vào mã sản phẩm nội bộ cần cập nhật"}
                            />
                        </div>
                    </div> :
                    <span
                        className="a-text--font-primary a-text--break-all ml-3"> Mã sản phẩm nội bộ: {code ? code : "---"}
                        {
                            order.canUpdateInternalCode &&
                            <Icon className="ml-2 icon-edit-cost a-text--fz-12 a-text--font-blue"
                                  type="edit"
                                  onClick={() => setIsEdit(true)}
                                  title={t('orderDetail:hscode.click_here_update_hscode')}
                            />
                        }
            </span>
            }
        </div>
    );

}