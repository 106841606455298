/* eslint-disable */
import React, {Component} from 'react';
import { Icon, Input} from "antd";
import notification from '../../../../systems/notification';
import apiService from "../../apiService";
import lodash from 'lodash';
import {processResponse} from "../../../../systems/api";
import {events} from "../../../../systems/events";
import TRANSACTION_EVENT from "../../events";

const {TextArea} = Input;

class InlineEditInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            loading: false,
            iconedit: true,
            iconremove: false,
            iconsave: false,
            labelvalue: true,
            input: false,
            note: '',
            isSubmit: false
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let { transaction } = nextProps;
        let nextState = {...prevState};
        if (lodash.get(transaction, 'id') && lodash.get(transaction, 'id') !== prevState.id) {
            nextState.id = transaction.id;
            nextState.note = transaction.note;
        }
        return nextState;
    }
    componentDidMount() {
        this.setState({
            note: this.props.value
        })
    }

    onClickEditInline = () => {
        this.setState({
            iconedit: !this.state.iconedit,
            iconremove: !this.state.iconremove,
            iconsave: !this.state.iconsave,
            labelvalue: !this.state.labelvalue,
            input: !this.state.input,
        })
    };
    onCancel = () => {
        this.setState({
            note: this.props.value
        });
        this.onClickEditInline();
    };
    onSubmit = () => {
        if (this.state.loading) {
            return;
        }

        let {transaction, t} = this.props;
        if (this.state.note !== transaction.note) {
            this.setState({loading: true});

            let res = apiService.updateNote(transaction.id, {note: this.state.note})
                .finally(() => this.setState({loading: false}));

            let process = processResponse(res);

            process.then(res => {

                this.onClickEditInline();
                notification.success(t('transaction:message.update_note_transaction_success'));
                events.dispatch(TRANSACTION_EVENT.NOTE_UPDATED, {
                    transaction: {...transaction, ...(res.data.transaction || {})},
                });

            });
            process.catch(() => {
                this.onClickEditInline();
                notification.error(t('transaction:message.update_note_transaction_failed'));
            });
        }

        else {
            this.onClickEditInline();
        }


    };
    changeValue = (event) => {
        this.setState({
            note: event.target.value
        });
    };

    render() {
        const {iconedit, iconremove, iconsave, labelvalue, input, note, loading} = this.state;
        const {label,value,inputType, t, placeholder} = this.props;
        return (
            <div className="a-inline-edit">
                <label className="a-inline-edit--label">{label}
                    <Icon type="edit" onClick={this.onClickEditInline}
                          className={`icon-inline ${iconedit ? '' : 'hidden'} `}/>
                    <Icon type="close" onClick={this.onCancel}
                          className={`icon-inline ${iconremove ? '' : 'hidden'} `}/>
                    <Icon type="check" onClick={this.onSubmit}
                          className={`icon-inline ml-0 ${iconsave ? '' : 'hidden'} `}/>
                </label>
                <div className={`a-inline-edit--value ${labelvalue ? '' : 'hidden'}`}>{value}</div>
                <div className={`a-inline-edit--input ${input ? '' : 'hidden'}`}>
                    {inputType === 'textarea' ?
                        <TextArea
                            onChange={this.changeValue.bind(this)}
                            placeholder={placeholder}
                            value={note}
                            autosize={{minRows: 1, maxRows: 3}}/>
                        :
                        <Input
                            onChange={this.changeValue.bind(this)}
                            value={note}
                            placeholder={placeholder}
                        />
                    }
                    {/*<p className="a-inline-edit--error-text ">Đây chính xác là lỗi :(</p>*/}
                    {(loading === true) && <Icon type="loading-3-quarters"
                                                 className="icon-loading"/>}
                </div>
            </div>
        )
    }
}

export default InlineEditInput;
