export const M5_KOMODO_ACTION = {
    SEND: {
        TRANSACTION_CODE: "TRANSACTION_CODE_SPENT_FROM_M5",
        RELATED_TRANSACTION_CODE: "SEND_RELATED_TRANSACTION_CODE_TO_CONTENT"
    }
};

export const CRAWL_EVENTS = {
    DISPATCH_TRANSACTION: "SYNCHRONIZE_TRANSACTION.DISPATCH_TRANSACTION",
    STOP_SYNCHRONIZING: "SYNCHRONIZE_TRANSACTION.STOP_SYNCHRONIZING",
    GO_TO_NEXT_ONE: "SYNCHRONIZE_TRANSACTION.GO_TO_NEXT_ONE",
}

export const ALIPAY_ACTION = {
    SEND: {
        RELATED_TRANSACTION_CODE: "SEND_RELATED_TRANSACTION_CODE_TO_BACKGROUND",
        IS_LOGIN_PAGE: "IS_LOGIN_PAGE",
        TRANSACTION_CODE_IS_NOT_EXISTED: "TRANSACTION_CODE_IS_NOT_EXISTED"
    },
    CLOSE: {
        LOGIN_PAGE: "CLOSE_LOGIN_PAGE"
    },
    CHECK: {
        TRANSACTION_CODE: "CHECK_TRANSACTION_CODE"
    }
};

