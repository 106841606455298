import React from 'react';
import { isEqual } from 'lodash';
import { Col, Icon, Row, Input, Switch} from 'antd';
import notification from '../../../../systems/notification';
import apiService from '../../../../modules/ViolateReason/apiService';
import { processResponse } from '../../../../systems/api';
import { translate } from 'react-i18next';
import lodash from 'lodash';

class ReasonItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			edit: false,
			title: props.reason.title,
			auto_approval: false,
		};
		this.inputRefs = React.createRef();
	}

	componentDidMount() {
		this.setState({
			title: this.props.reason.title,
			auto_approval: this.props.reason.auto_approval,
		});
	}

	componentDidUpdate(preProps) {
		const { reason: preReason } = preProps;
		const { reason } = this.props;

		if (!isEqual(preReason, reason)) {
			this.setState({
				title: reason.title,
				auto_approval: reason.auto_approval,
			});
		}
	}

	onClick = (e) => {
		e.preventDefault();
		this.setState({ edit: true }, () => {
			this.inputRefs.current.focus();
		});
    };
    
    handleChangeAutoApproval = checked => {
        this.setState({ auto_approval: checked });
        console.log(checked)
        this.onSubmit({ auto_approval: checked });
    }

	onPressEnter = (e) => {
		this.onSubmit({ title: e.target.value });
	};

	onBlur = (e) => {
		this.setState({ edit: false });
	};

	onChange = (e) => {
		this.setState({ title: e.target.value });
    };
    
    onSubmit = dataSubmit => {
		let { reason, t } = this.props;
		let res = apiService.updateViolateReasonTitle({ id: reason.id, ...dataSubmit });
		let process = processResponse(res);
		process.then((res) => {
			let data = lodash.get(res, 'data.violateReasons', {});
			this.setState({ edit: false, title: data.title, auto_approval: data.auto_approval });
			notification.success('Cập nhật lý do mua hàng chậm thành công');
		});
		process.catch((err) => {
			notification.error(t('message.cant_update_data'));
		});
    }

	render() {
		let { reason } = this.props;

		return (
			<Row className='reason-line reason-line-move d-flex align-item-center'>
				<Col span={2} className='text-center'>
					<span className='draggable'>::</span>
				</Col>
				<Col span={3}>#{reason.id}</Col>
				<Col span={16}>
					{this.state.edit ? (
						<Input
							ref={this.inputRefs}
							value={this.state.title}
							onPressEnter={this.onPressEnter}
							onBlur={this.onBlur}
							onChange={this.onChange}
						/>
					) : (
						<a href='' onClick={this.onClick}>
							{this.state.title} <Icon type='edit' />
						</a>
					)}
				</Col>
				<Col span={4} style={{ textAlign: 'center' }}>
					<Switch size="small" checked={this.state.auto_approval}  onChange={this.handleChangeAutoApproval} />
				</Col>
			</Row>
		);
	}
}

export default translate()(ReasonItem);
