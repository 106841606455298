import {combineReducers} from 'redux';
import * as Constant from './constants';
import * as AddInvoiceCode from './../InputAddInvoiceCode/constants';
import * as DeleteInvoiceCode from './../ModalManageInvoiceCode/constants';
import * as ModalManageInvoiceCodeContant from './../ModalManageInvoiceCode/constants';
import lodash from 'lodash';

const currentTransaction = (state = {
    idTransaction: 0,
    showModal: false,
    invoiceCodes: [],
    canUpdateInvoiceCode: false,
    canDeleteInvoiceCode: false,
}, action) => {
    switch (action.type) {
        case Constant.SET_CURRENT_INVOICE_CODE_FOR_TRANSACTION:
            return {...action.payload};
        case DeleteInvoiceCode.DELETE_INVOICE_CODE_FROM_TRANSACTION.SUCCESS:
            let {invoiceCodes} =  state;
            state.invoiceCodes = invoiceCodes.filter(invoiceCode => {
                return invoiceCode.id !== lodash.get(action, 'payload.data.id', 0);
            });
            return {...state};
        case Constant.CLEAR_CURRENT_INVOICE_CODE_FOR_TRANSACTION:
        case AddInvoiceCode.CLEAR_ADD_INVOICE_CODE_2_TRANSACTION:
        case AddInvoiceCode.ADD_INVOICE_CODE_2_TRANSACTION.SUCCESS:
        case ModalManageInvoiceCodeContant.CLEAR_MODAL_INVOICE_CODE_2_TRANSACTION:
            return {
                idTransaction: 0,
                showModal: false,
                invoiceCodes: [],
                canUpdateInvoiceCode: false,
                canDeleteInvoiceCode: false,
            };
        default:
            return state;
    }
};

export default combineReducers({
    currentTransaction,
});