import {toastr} from 'react-redux-toastr';

class Bootbox {

    static warning = (message, options) => {
        toastr.warning('', message, options)
    };

    static success = (message, options) => {
        toastr.success('', message, options);
    };

    static error = (message, options) => {
        toastr.error('', message, options)
    }
}

export default Bootbox;