import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import lodash from 'lodash';
import {url} from '../../../../systems/routing/index';
// import {Col, Container, Row} from "react-grid-system";
import {Row, Col} from "antd";
import * as GlobalConstants from './../../../../systems/globalContant/actionContant';
import Alipays from './Alipays';
import AccountPurcharse from './AccountPurcharse';
import OrderInfo from './OrderInfo';
import PerformAction from './PerformAction';
import UpdateComplaint from './UpdateComplaint';
import {COMPLAINT_SELLER_STATUS} from "./../../../../systems/globalContant/actionContant";
import ChatLog from './../../../common/Log/LogContainer';
import ComplaintAlipays from '../../ComplaintAlipays/ComplaintAlipaysContainer';
import ComplaintProductReturn from './ComplaintProductReturn';
import {STATE_FILTER_COMPLAINT_DEFAULT} from './../../constants';
import {COMPLAINT_SELLER_REJECT, COMPLAINT_SELLER_REASON_UPDATE} from "../../events";
import {withEvent} from "../../../../systems/events";
import {updateComplaintSellerReason} from "../../actions";
import ListTransactions from "./ListTransactions";
import ListMissedProducts from "./ListMissedProducts";
import ComplaintService from "./ComplaintService";
import ListPackages from "./ListPackages";
import AlertSpecialFeatures from "../../../PurchaseBuying/OrderDetail/components/Features/AlertSpecialFeatures";

const stateDefault = {
    code: '',
    order_seller_name: '',
    seller_source: [],
    id_processor: [],
    account_purchaser_id: [],
    order_seller_source: [],
    refund_status: '',
    time_type: COMPLAINT_SELLER_STATUS.WAITING,
    log_status_to: '',
    log_status_from: '',
    orderBy: 'created_at',
    sort: 'desc',
};

class DetailComplaintSeller extends React.Component {

    componentDidMount() {
        let {t, location, match, purchasers} = this.props;
        let filter = {...queryString.parse(location.search), ...match.params};
        this.props.fetchComplaintDetail(filter);
        this.props.fetchProductReturns(filter);
        this.props.fetchComplaintSellerReasons({status: GlobalConstants.OPTION_INPUT_API.ON});
        this.props.setPageTitle(t('breadcrumb.complaint_seller_detail'));
        this.props.setPageDescription(t('complaint_seller.detail'));
        if (purchasers.length === 0) {
            this.props.fetchUsersComplaintSeller();
        }
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.complaint_seller_detail',
                'link': url.to('complaint-seller.detail', {id: filter.id})
            },
        ]);
        this.props.setMenuActive("ComplaintSeller");
        this.props.on({
            [COMPLAINT_SELLER_REJECT.SUCCESS]: (event, payload) => this.props.rejectedComplaintSellers(payload),
            [COMPLAINT_SELLER_REASON_UPDATE.SUCCESS]: (event, payload) => this.props.updateComplaintSellerReason(payload),
        });
    }

    componentWillUnmount() {
        this.props.clearComplaintDetail();
    }

    render() {
        return (
            <React.Fragment>
                {this.props.loading ? (
                    <div className='a-loading__page'>
                        <div className="a-loading__page__icon"></div>
                    </div>
                ) : null}

                {this.renderComplaint()}
            </React.Fragment>
        );
    }

    renderComplaint() {
        let {
            order, complaint, permissions, purchasers,
            error, canUpdateRenew, openModalChangeSuccessForm,
            isModalChangeSuccessProcessing, clearErrorComplaintSellerSuccess,
            complaintSellerReasons, alipays, listProductReturns,
            updateOriginCodeComplaint,
            renewReasons,
            addCommentProduct,
            addTransactionCode,
            updateComplaintDetailAgain,
            canChangePackage
        } = this.props;

        if (lodash.isEmpty(complaint)) {
            return null;
        }

        const properties = lodash.get(order, "properties", []);
        const properties_warning = lodash.map(lodash.filter(properties, ['property.show_warning', 1]), "property.name");
        return (
            <div className={"a-content__complaint-seller"}>
                <React.Fragment>
                    {
                        (properties_warning.length  > 0) &&
                        <AlertSpecialFeatures properties_warning={properties_warning}/>
                    }
                    <div className="a-purchasod over-flow--inherit">
                        <PerformAction
                            order={order}
                            complaint={complaint}
                            openModalChangeSuccessForm={openModalChangeSuccessForm}
                            isModalChangeSuccessProcessing={isModalChangeSuccessProcessing}
                            clearErrorComplaintSellerSuccess={clearErrorComplaintSellerSuccess}
                            permissions={permissions}
                            complaintSellerReasons={complaintSellerReasons}
                        />

                        <div className="a-purchasod__body">
                            <div className="a-purchasod__body__left w-100 pb-3">
                                <Row gutter={15} className="d-flex">
                                    <Col xs={16}>
                                        <OrderInfo
                                            order={order}
                                            filterDefault={{...STATE_FILTER_COMPLAINT_DEFAULT}}
                                            expireUpdateComplaintDetail={this.props.expireUpdateComplaintDetail}
                                            expireClearErrorComplaintDetail={this.props.expireClearErrorComplaintDetail}
                                            complaint={complaint}
                                            permissions={permissions}
                                            renewReasons={renewReasons}
                                            error={error}
                                            canUpdateRenew={canUpdateRenew}
                                            complaintSellerReasons={complaintSellerReasons}
                                            updateOriginCodeComplaint={updateOriginCodeComplaint}
                                            updateComplaintDetailAgain={updateComplaintDetailAgain}
                                        />
                                    </Col>
                                    <Col xs={8}>
                                        <AccountPurcharse
                                            order={order}
                                            complaint={complaint}
                                            purchasers={purchasers}
                                            permissions={permissions}
                                            complaintSellerReasons={complaintSellerReasons}/>
                                    </Col>
                                </Row>
                                <Row gutter={15} className="d-flex pt-3">
                                    <Col xs={16}>
                                        <ComplaintService order={order}/>
                                    </Col>
                                    <Col xs={8}>
                                        <ListTransactions
                                            complaint={complaint}
                                            permissions={permissions}
                                            order={order}
                                            addTransactionCode={addTransactionCode}
                                        />
                                    </Col>
                                </Row>

                                {/*<ComplaintProductReturn*/}
                                {/*    listProductReturns={listProductReturns}*/}
                                {/*    order={order}*/}
                                {/*    complaint={complaint}*/}
                                {/*    permissions={permissions}*/}
                                {/*/>*/}
                                <Row gutter={15} className="d-flex">
                                    <Col xs={12}>
                                        <ListMissedProducts
                                            order={order}
                                            addCommentProduct={addCommentProduct}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <ListPackages
                                            complaint={complaint}
                                            order={order}
                                        />
                                    </Col>
                                </Row>


                                {
                                    true === 0 ? <UpdateComplaint/> : null
                                }
                            </div>
                            <div className={'a-purchasod__body__right'}>
                                {
                                    complaint.id ?
                                        <ChatLog
                                            object="complaint_seller"
                                            objectId={complaint.id}
                                            size="large"
                                        /> : null
                                }
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </div>
        );
    }
}

DetailComplaintSeller.defaultProps = {
    loading: false,
    canUpdateRenew: false,
    complaint: {},
    permissions: {},
    order: {},
    complaintSellerReasons: [],
    clearComplaintDetail: () => {
    },
};

DetailComplaintSeller.protoType = {
    loading: PropTypes.bool,
    canUpdateRenew: PropTypes.bool,
    complaint: PropTypes.object,
    permissions: PropTypes.object,
    order: PropTypes.object,
    complaintSellerReasons: PropTypes.array,
    clearComplaintDetail: PropTypes.func,
};

export default translate('translations')(withEvent(DetailComplaintSeller));
