import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {trans} from '../../../systems/i18n';

const USER_ACTION = {
    ADD_ACCOUNT_PURCHASER: 'USER.ADD_ACCOUNT_PURCHASER',
    REMOVE_ACCOUNT_PURCHASER: 'USER.REMOVE_ACCOUNT_PURCHASER',
};

class UserLogMessage extends React.Component {
    render() {
        let {log} = this.props;
        let {payload} = log;

        switch (log.action) {
            case USER_ACTION.ADD_ACCOUNT_PURCHASER:
                return trans('user:log.ADD_ACCOUNT_PURCHASER', {
                    user: <b>{lodash.get(payload, 'attributes.username')}</b>,
                    account: <b>{lodash.get(payload, 'attributes.account')}</b>,
                });
            // user: Giao dịch viên
            // account: Tài khoản mua hàng
            case USER_ACTION.REMOVE_ACCOUNT_PURCHASER:
                return trans('user:log.REMOVE_ACCOUNT_PURCHASER', {
                    user: <b>{lodash.get(payload, 'attributes.username')}</b>,
                    account: <b>{lodash.get(payload, 'attributes.account')}</b>,
                });
            // user: Giao dịch viên
            // account: Tài khoản mua hàng

            default:
                return log.action;
        }
    }
}

UserLogMessage.defaultProps = {
    log: {},
};

UserLogMessage.propTypes = {
    log: PropTypes.object,
};

export default translate()(UserLogMessage);
