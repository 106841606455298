import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import FormProcessing from '../../../common/components/FormProcessing';
import DropdownButton from '../../../common/components/DropdownButton';
import Form from '@atlaskit/form';
import lodash from 'lodash';
import {PLACEHOLDER} from "../../constants";
import Select from "@atlaskit/select/dist/esm/Select";

const siteItems = [
    {label: 'taobao', value: 'taobao'},
    {label: 'tmall', value: 'tmall'},
    {label: '1688', value: '1688'},
    {label: 'Đơn ngoài', value: 'external'},
];

const stateDefault = {
    code: '',
    invoice_code: '',
    seller_source: [],
    total_item_link: '',
    complaint_seller: '',
    real_amount_compare: '',
    grand_total_to: '',
    grand_total_from: '',
    log_status_to: '',
    log_status_from: '',
    sort: 'asc',
    wrong_process: '',
    id_purchase_address: [],
    id_agency: [],
};

class FormSearchHeader extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            input: stateDefault,
            inputVersion: 0,
            changedParams: [],
        }
    }

    onChangeFormInput(param, value) {
        let input = this.changeInput({[param]: value});
        this.props.onSubmit(input);
    }

    onKeyPressInput(input, hiddenDropdown, openDropdown, event) {
        if (event.key === 'Enter') {
            if (hiddenDropdown) {
                hiddenDropdown();
            }
            this.props.onSubmit(input);
        } else {
            if (openDropdown) {
                openDropdown();
            }
        }
    }

    onKeyDown(input, hiddenDropdown, openDropdown, event) {
        if (event.keyCode === 8) {
            openDropdown();
        }
    }

    onBlurInput() {
        this.props.onSubmit(this.state.input);
    }

    makeInputInvoiceCode(input) {
        let invoice_code = '';
        if (input.invoice_code) {
            invoice_code = input.invoice_code;
        }
        return {
            invoice_code: invoice_code,
            seller_source: lodash.get(input, 'seller_source', [])
        };
    }

    makeInputPurchaserAddress(input) {
        let {purchaseAddresses = [], agencies = []} = this.props;

        let id_purchase_address = [];
        let buyer_username = '';
        if (input.id_purchase_address) {

            id_purchase_address = lodash.head(purchaseAddresses.filter((account) => {
                return input.id_purchase_address.find((purchaser) => {
                    return lodash.toInteger(purchaser) === lodash.toInteger(account.value);
                });
            }));

        }

        let id_agency = [];
        if (input.id_agency) {
            id_agency = lodash.head(agencies.filter((agency) => {
                return input.id_agency.find((id_agency) => {
                    return lodash.toInteger(id_agency) === lodash.toInteger(agency.value);
                });
            }));
        }

        if (input.buyer_username) {
            buyer_username = input.buyer_username;
        }

        return {
            id_purchase_address: buyer_username.length ?
                ' - ' + lodash.get(id_purchase_address, 'label', '') : lodash.get(id_purchase_address, 'label', ''),
            buyer_username: buyer_username,
            id_agency: lodash.get(id_agency, 'label', ''),
        };
    }

    //click quick search
    onClickQuickTime(quickTime) {
        let input = this.changeInput(quickTime);
        this.props.onSubmit(input);
    }

    /**
     * Xử lý submit ở các subform
     *
     * @param {Object} data
     */
    onSubmitSubForm(data) {
        let input = this.changeInput(data);
        this.props.onSubmit(input);
    }

    onChangeMultiSelect(param, select) {
        let value = select.map((selectItem) => {
            return selectItem.value;
        });
        let input = this.changeInput({[param]: value});
        this.props.onSubmit(input);
    }

    onChangeSelect(param, event) {
        let target = event.target;
        let input = this.changeInput({[param]: target.value});
        this.props.onSubmit(input);
    }

    makeValueMultiSelect(select) {
        let {purchaseAddresses} = this.props;
        purchaseAddresses = purchaseAddresses.map(purchaseAddressesItem => {
            let label = purchaseAddressesItem.label;
            label = label.trim();
            label = label.length > 20 ? label.substr(0, 20) + '...' : label;
            return {...purchaseAddressesItem, ...{label: label}};
        });
        select = select.map((value) => lodash.parseInt(value));
        return purchaseAddresses.filter((purchaseAddressesItem) => {
            return select.includes(purchaseAddressesItem.value);
        });
    }

    agencyMakeValueMultiSelect(select) {
        let {agencies} = this.props;
        agencies = agencies.map(agencyItem => {
            let label = agencyItem.label;
            label = label.trim();
            label = label.length > 20 ? label.substr(0, 20) + '...' : label;
            return {...agencyItem, ...{label: label}};
        });
        select = (select || []).map((value) => lodash.parseInt(value));
        return agencies.filter((agencyItem) => {
            return select.includes(agencyItem.value);
        });
    }

    makeValueSelect(select) {
        let {siteItems} = this.props;
        select = select.map((value) => value);
        return siteItems.filter((siteItem) => {
            return select.includes(siteItem.value);
        });
    }

    render() {
        let {t, onSubmit, purchaseAddresses, agencies} = this.props;
        let {input} = this.state;
        return (
            <ul>
                <li style={{width: '0px'}}/>
                <li className="flex-start " style={{paddingLeft: '0px'}}>
                    <div className="a-btn--filter-order flex-start filter-order--over  ">
                        <DropdownButton
                            type={PLACEHOLDER.FILTER}
                            input={this.makeInputPurchaserAddress(lodash.pick(input, ['buyer_username', 'id_purchase_address', 'id_agency']))}
                            isMenuFixed={true}
                            content={(hiddenDropdown, openDropdown) => (
                                <div className="a-form--dropdown--order" style={{width: '286px'}}>
                                    <Form name="order-code">

                                        <div className={'a-form--dropdown--atlaskit__block pt-3'}>
                                            <div className={'a-form--dropdown--atlaskit__block__title'}>
                                                <p className="a-text--font-primary">
                                                    {t('order:list.address_receive_china')}
                                                </p>
                                            </div>
                                            <Select
                                                style={{overflow: 'hidden !important'}}
                                                noOptionsMessage={({inputValue: string}) => t('message.no_record')}
                                                value={this.makeValueMultiSelect(input.id_purchase_address)}
                                                onChange={this.onChangeMultiSelect.bind(this, 'id_purchase_address')}
                                                onBlur={this.onBlurInput.bind(this)}
                                                placeholder={t('order:list.enter_address_receive_china')}
                                                options={purchaseAddresses}
                                                className="single-react"
                                                classNamePrefix="react-select--filter_item_multi"
                                                isMulti
                                            />
                                        </div>

                                        <div className={'a-form--dropdown--atlaskit__block pt-3'}>
                                            <div className={'a-form--dropdown--atlaskit__block__title'}>
                                                <p className="a-text--font-primary">
                                                    {t('order:list.agency')}
                                                </p>
                                            </div>
                                            <Select
                                                style={{overflow: 'hidden !important'}}
                                                noOptionsMessage={({inputValue: string}) => t('message.no_record')}
                                                value={this.agencyMakeValueMultiSelect(input.id_agency)}
                                                onChange={this.onChangeMultiSelect.bind(this, 'id_agency')}
                                                onBlur={this.onBlurInput.bind(this)}
                                                placeholder={t('order:list.enter_agency')}
                                                options={agencies}
                                                className="single-react"
                                                classNamePrefix="react-select--filter_item_multi"
                                                isMulti
                                            />
                                        </div>
                                    </Form>
                                </div>
                            )}
                        />
                    </div>
                </li>

                <li className="a-btn--filter-order filter-order--over  ">
                    <DropdownButton
                        type={PLACEHOLDER.ACCOUNT}
                        isMenuFixed={true}
                        output={this.makeInputInvoiceCode(lodash.pick(input, ['invoice_code', 'seller_source']))}
                        onSubmit={onSubmit}
                        content={(hiddenDropdown, openDropdown) => (
                            <div className={'a-form--dropdown--atlaskit'}>
                                <div className={'a-form--dropdown--atlaskit__block a-form--input--border-b mt-3 pb-4'}>
                                    <div className="a-form--input--order mt-3">
                                        <i className="ion-ios-search-strong"/>
                                        <label htmlFor="" className="a-text--bold ">
                                            {t('orderDetail:order_detail.original_invoice')}
                                        </label>
                                        <input type="text"
                                               value={input.invoice_code || ''}
                                               placeholder={t('orderDetail:order_detail.original_invoice_placeholder')}
                                               className="mt-2"
                                               onBlur={this.onBlurInput.bind(this)}
                                               onChange={this.onChangeInput.bind(this, 'invoice_code')}
                                               onKeyPress={this.onKeyPressInput.bind(this, input, hiddenDropdown, openDropdown)}
                                               onKeyDown={this.onKeyDown.bind(this, input, hiddenDropdown, openDropdown)}
                                        />
                                    </div>
                                </div>
                                <div className={'a-form--dropdown--atlaskit__block pt-3'}>
                                    <div className={'a-form--dropdown--atlaskit__block__title'}>
                                        <p className="a-text--font-primary">{t('order:list.site')}</p>
                                    </div>
                                    <Select
                                        noOptionsMessage={() => t("system.no_option")}
                                        isClearable={true}
                                        value={this.makeValueSelect(input.seller_source)}
                                        placeholder={t('order:list.site')}
                                        options={siteItems}
                                        onBlur={this.onBlurInput.bind(this)}
                                        onChange={this.onChangeMultiSelect.bind(this, 'seller_source')}
                                        className="single-react"
                                        classNamePrefix="react-select--filter_item_multi"
                                        isMulti
                                    />
                                </div>
                            </div>
                        )}
                    />
                </li>
                <li>{""}</li>
                <li className="a-btn--filter-order filter-order--over  ">
                    <DropdownButton
                        input={{complaint_seller: input.complaint_seller, wrong_process: input.wrong_process}}
                        type={PLACEHOLDER.ACCOUNT}
                        isMenuFixed={true}
                        output={{complaint_seller: input.complaint_seller}}
                        onSubmit={onSubmit}
                        content={(hiddenDropdown, openDropdown) => (
                            <div className={'a-form--dropdown--atlaskit'}>
                                <div className="font-weight-bold a-text--font-primary mb-2">
                                    {t("order:list.KNNB")}
                                    <small className="ml-1">{t("order:list.KNNB_note")}</small>
                                </div>
                                <div className="search__complaintseller">
                                    <select
                                        className="select"
                                        value={input.complaint_seller}
                                        onChange={this.onChangeSelect.bind(this, 'complaint_seller')}
                                    >
                                        <option key={""} value={""}>{t("order:list.all_order")}</option>
                                        <option key={t("order:list.has_complaint_seller_all")} value={"ALL"}>{t("order:list.has_complaint_seller")}</option>
                                        <option key={t("order:list.has_complaint_seller_active")} value={"ACTIVE"}>{t("order:list.has_complaint_seller_active")}</option>
                                        <option key={t("order:list.has_not_complaint_seller")} value={"NONE"}>{t("order:list.has_not_complaint_seller")}</option>
                                    </select>
                                </div>
                                <hr/>
                                <div className="font-weight-bold a-text--font-primary mb-2">
                                    {t("order:label.wrong_process_search")}
                                    <small className="ml-1">{t("order:label.wrong_process_search_detail")}</small>
                                </div>
                                <div className={'d-flex align-items-center'} >
                                    <select
                                        className="select"
                                        value={input.wrong_process}
                                        name={"wrong_process"}
                                        onChange={this.onChangeSelect.bind(this, 'wrong_process')}
                                    >
                                        <option value="">{t("order:label.all")}</option>
                                        <option key={false} value={0}>{t("order:label.process_ok")}</option>
                                        <option value={1}>{t("order:label.process_wrong")}</option>
                                    </select>
                                </div>
                            </div>
                        )}
                    />
                </li>
            </ul>
        );
    }
}

FormSearchHeader.defaultProps = {
    input: {},
    inputVersion: 0,
    onSubmit: input => {
    },
};

FormSearchHeader.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    onSubmit: PropTypes.func,
};

export default translate()(FormSearchHeader);
