import React from 'react'
import moment from 'moment';
import lodash from 'lodash';
import PropTypes from 'prop-types'
import Countdown from 'react-countdown-now'

const language = {
    vi: {
        day: "ngày",
        hour: "giờ",
        minute: "phút"
    },
    en: {
        day: "days",
        hour: "hours",
        minute: "minutes"
    }
};

class Timer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            time_now: '',
            creaseSecond: 0
        }
    }

    componentDidMount() {
        // update every second
        this.interval = setInterval(() => {
            let {creaseSecond} = this.state;
            creaseSecond = creaseSecond+1;
            let time_now = this.calculateCountdown();
            time_now ? this.setState({time_now: time_now, creaseSecond: creaseSecond}) : this.stop();
        }, 1000);
    }

    componentWillUnmount() {
        this.stop();
    }

    stop() {
        clearInterval(this.interval);
    }

    calculateCountdown() {
        let {time_now} = this.props;
        let {creaseSecond} = this.state;
        time_now = moment(time_now).add(creaseSecond, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        return time_now;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (lodash.get(nextProps, "time_now", '') !== lodash.get(prevState, "time_now", '') ) {
            nextState.time_now = lodash.get(prevState, "time_now", '');
        }
        return nextState;
    }


    renderer(props) {
        let {lang, textAfter, textBefore, overTimeText, timeBold, overtimeClass} = this.props;
        let {days, hours, minutes, completed} = props;
        if (completed || ( lodash.toInteger(minutes) < 1 &&
            lodash.toInteger(days) <= 0 &&
            lodash.toInteger(hours) <= 0)) {
            return `<span class='a-status ${overtimeClass ? overtimeClass : 'a-status--cancelled'}'>${overTimeText}</span>`
        } else {
            let timeLeft = '';
            if (!lang) lang = 'vi';
            if (days > 0) {
                timeLeft += days + " " + language[lang]['day'] + " ";
            }
            if (hours > 0) {
                timeLeft += hours + " " + language[lang]['hour'] + " ";
            }
            if (minutes > 0) {
                timeLeft += minutes + " " + language[lang]['minute'] + " ";
            }
            if (timeBold) {
                return textBefore + " <b>" + timeLeft + "</b> " + textAfter;
            } else {
                return textBefore + ' ' + timeLeft + ' ' + textAfter;
            }

        }
    }

    render() {
        let {date} = this.props;
        let {time_now} = this.state;
        time_now = time_now ? time_now: this.props.time_now;
        return (
            <Countdown
                date={date}
                zeroPadTime={0}
                intervalDelay={0}
                now={() =>{return new Date(time_now)}}
                renderer={props => <span className="lost__deadline" dangerouslySetInnerHTML={{__html: this.renderer(props)}}></span>}
            />
        )
    }
}

Timer.propTypes = {
    date: PropTypes.string.isRequired,
    timeBold: PropTypes.bool,
    textBefore: PropTypes.string,
    textAfter: PropTypes.string,
    overTimeText: PropTypes.string,
    time_now: PropTypes.string
};

export default Timer;
