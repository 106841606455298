import React from "react";
import SettingMenu from "../../../modules/Settings/SettingMenu";
import {Divider, Icon, notification, Row, Col, Tooltip} from "antd";
import {url} from "../../../systems/routing";
import {setActiveSettingItem} from "../../../modules/Settings/SettingMenu/actions";
import {translate} from "react-i18next";
import apiService from "../../../modules/Agency/apiService";
import {processResponse} from "../../../systems/api";
import lodash from "lodash";
import AgencyItem from "../Components/AgencyItem";
import FormAgency from "./FormAgency";
import {updateCollectionItem} from "../../../modules/common/helps";

class ListAgencies extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            updatedAgency: {}
        };
    }

    onSetUpdatedAgency = val => {
        this.setState({updatedAgency: val});
    }

    onUpdateData = agency => {
        let {data} = this.state;
        this.setState({data: updateCollectionItem(data, agency.id, agency)});
    }

    componentDidMount() {
        let {t} = this.props;

        this.props.setPageTitle(t('agency.title'));
        this.props.setPageDescription(t('agency.description'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.agency',
                'link': url.to('agency')
            },
        ]);
        this.props.setMenuActive("agency");
        setActiveSettingItem('agency');

        this.fetchAgency();
    }

    fetchAgency() {
        let proccess = processResponse(apiService.getListAgency());

        proccess.then(res => {
            this.setState({
                data: lodash.get(res, "data", [])
            });
        });

        proccess.catch(error => {
            notification.error({message: "Tải dữ liệu agency không thành công"});
        });
    }

    render() {
        let {
            data,
            updatedAgency
        } = this.state;
        return (
            <div className="order-group">
                <div className="a-content__is_sidebar justify-content-start">
                    <SettingMenu/>
                    <div className="a-content__right__center width-fixed settings__body">
                        <div className="a-content__right w-100">
                            <div className="a-content--box-shadow">
                                <Row>
                                    <Col span={12} className="d-flex align-item-center">
                                        <p className="a-text--bold a-text--color-black">DANH SÁCH AGENCY ({data.length})</p>
                                    </Col>
                                </Row>
                                <Divider className="mt-3 mb-0" />
                                <div className="agency-box mt-3">
                                    <div className="agency-box-body pl-4" style={{paddingRight: "1rem"}}>
                                        <Row className="group-line pt-3 pb-3">
                                            <Col span={5} className="a-text--bold">Tên agency</Col>
                                            <Col span={3} className="a-text--bold">Màu sắc</Col>
                                            <Col span={2} className="a-text--bold">Mã agency</Col>
                                            <Col span={2} className="a-text--bold">Ký hiệu</Col>
                                            <Col span={3} className="a-text--bold">Caresoft Domain</Col>
                                            <Col span={4} className="a-text--bold">Caresoft Token</Col>
                                            <Col span={2} className="a-text--bold">
                                                MHĐG
                                                <Tooltip title="Cho phép nhập MHĐG có chứa ký tự Alphabet">
                                                    <Icon type="question-circle-o" className="ml-1" />
                                                </Tooltip>
                                            </Col>
                                            <Col span={2} className="a-text--bold">
                                                Mã vận đơn
                                                <Tooltip title="Cho phép nhập mã vận đơn có chứa ký tự Alphabet">
                                                    <Icon type="question-circle-o" className="ml-1" />
                                                </Tooltip>
                                            </Col>
                                            <Col span={1} className="a-text--bold a-text--right">Sửa</Col>
                                        </Row>
                                        {
                                            data.length > 0 && data.map((agency, index) => {
                                                return <AgencyItem
                                                    key={index}
                                                    agency={agency}
                                                    onSetUpdatedAgency={this.onSetUpdatedAgency}
                                                />;
                                            })
                                        }
                                    </div>
                                </div>
                                <FormAgency
                                    updatedAgency={updatedAgency}
                                    onUpdateData={this.onUpdateData}
                                    onSetUpdatedAgency={this.onSetUpdatedAgency}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate()(ListAgencies);