import React from "react";
import {Col, Row} from "antd";
import lodash from "lodash";
import {translate} from "react-i18next";
import OrderProblems from "../../../modules/Transaction/OrderProblems";

class ProblemBrief extends React.Component {

    getCodeByField = (items, field, link = false) => {
        if (link)
            return items.map((item, index) => <><a href={item.link} target="_blank">{item[field]}</a>{index < items.length-1 && " | "}</>);

        return items.map(item => item[field]).join(" | ");
    }

    render() {
        let {t, dataOrder} = this.props;
        let user_address = lodash.get(dataOrder, 'user_address', null);
        return (
            <div className="pt-4 pb-4 pd-detail a-text--color-black-85">
                <Row gutter={20}>
                    <Col span={2} className="gutter-row pd-thumb">
                        <div className="gutter-box">
                            {dataOrder.items &&
                                <a href={lodash.get(dataOrder.items[0], "link")} target="_blank">
                                    <img src={lodash.get(dataOrder.items[0], "image")} alt={lodash.get(dataOrder.items[0], "title")} />
                                </a>
                            }
                        </div>
                    </Col>

                    <Col span={22} className="gutter-row">

                        <Row gutter={20} className="pb-3">
                            <Col span={9}>
                                <span className="a-font--12 a-text--color-black-45">{t("problem_order.order_bill_no")}</span>
                                <p>{dataOrder.invoiceCodes.length > 0 && this.getCodeByField(dataOrder.invoiceCodes, "invoice_code", true) || '---'}</p>
                            </Col>
                            <Col span={9}>
                                <span className="a-font--12 a-text--color-black-45">{t("problem_order.partner")}</span>
                                <p>{lodash.get(dataOrder,"agency.name", "---")}</p>
                            </Col>
                            <Col span={6}>
                                <span className="a-font--12 a-text--color-black-45">{t("account_purchaser.site_name")}</span>
                                <p><span className={"img-logo-partner _" + dataOrder.seller_source}>{dataOrder.seller_source}</span></p>
                            </Col>
                        </Row>

                        <Row gutter={20} className="pb-3">
                            <Col span={9}>
                                <span className="a-font--12 a-text--color-black-45">Link/{t("order_detail.Products")}</span>
                                <p>{dataOrder.total_item_link}/{dataOrder.total_item_qty}</p>
                            </Col>
                            <Col span={9}>
                                <span className="a-font--12 a-text--color-black-45">{t("problem_order.received_address")}</span>
                                <p>
                                    {user_address ? (user_address.reciver_name ? (
                                        <span>
                                        <strong>{user_address.reciver_name}</strong>
                                        / {user_address.detail}, {user_address.district}, {user_address.province}
                                            {user_address.reciver_phone ? ' / ' + user_address.reciver_phone : ''}
                                            {user_address.note ? ' / ' + user_address.note : ''}
                                        </span>
                                        ) : (
                                        <strong>{user_address.detail}</strong>
                                    )) : "---"}
                                </p>
                            </Col>
                        </Row>

                        <Row gutter={20} className="pb-3">
                            <Col span={24}>
                                <span className="a-font--12 a-text--color-black-45">{t("problem_order.code_no")}</span>
                                <p>{dataOrder.freightBills.length > 0 && this.getCodeByField(dataOrder.freightBills, "freight_bill") || '---'}</p>
                            </Col>
                        </Row>

                        <Row gutter={20} className="pb-3">
                            <Col span={9}>
                                <span className="a-font--12 a-text--color-black-45">{t("problem_order.grand_total_bought")}</span>
                                <p><strong>{dataOrder.grand_total__text}</strong></p>
                            </Col>
                            <Col span={9}>
                                <span className="a-font--12 a-text--color-black-45">{t("problem_order.profit")}</span>
                                <p><strong className="a-text--light-red">{dataOrder.profit_text}</strong></p>
                            </Col>
                            <Col span={6}>
                                <span className="a-font--12 a-text--color-black-45">{t("problem_order.profit_percent")} (%)</span>
                                <p><strong>{dataOrder.profit_percent_text}</strong></p>
                            </Col>
                        </Row>

                        <Row gutter={20} className="pb-3">
                            <Col span={9}>
                                <span className="a-font--12 a-text--color-black-45">{t("problem_order.grand_total")}</span>
                                <p><strong>{dataOrder.grand_total_bought_text}</strong></p>
                            </Col>
                            <Col span={9}>
                                <span className="a-font--12 a-text--color-black-45">{t("problem_order.total_transaction")}</span>
                                <p><strong>{dataOrder.total_transaction_text}</strong></p>
                            </Col>
                            <Col span={6}>
                                <span className="a-font--12 a-text--color-black-45">{t("problem_order.total_shipping_fee_inland")}</span>
                                <p><strong>{dataOrder.total_shipping_fee_inland_text}</strong></p>
                            </Col>
                        </Row>

                        <Row gutter={20}>
                            <Col span={24}>
                                <span className="a-font--12 a-text--color-black-45">{t("problem_order.description_problem")}</span>
                                <p><OrderProblems order={dataOrder} /></p>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </div>
        );
    }
}

export default translate()(ProblemBrief);