import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Table } from "antd";
import { t } from "../../../../systems/i18n";
import { get, isNil } from "lodash";
import { currentFormatter } from "../../../common/services/format";
import apiService from "../apiService";
import notification from "../../../../systems/notification";
import { connect } from "react-redux";

const mapDispatchToProps = () => {
	return {};
};

const ModalOrderList = (props) => {
	const { handleHideModal, orderPayments, transactionId, renderNotice, visible } = props;
	const [orderPaymentId, setOrderPaymentId] = useState();
	const [loading, setLoading] = useState(false);

	const columns = [
		{
			title: t("transaction:column.order_code"),
			dataIndex: "code",
		},
		{
			title: t("order:list.buyer"),
			dataIndex: "customer_username",
		},
		{
			title: t("paymentRequest:label.amount"),
			dataIndex: "amount",
			render: (amount, record) => {
				return <p>{currentFormatter.toLocaleStringCurrency(parseFloat(amount), 5) + "¥"}</p>;
			},
		},
	];

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setOrderPaymentId(get(selectedRowKeys, "0"));
		},
	};

	const handleRefundAmount = async () => {
		setLoading(true);
		try {
			const res = await apiService.refundAmount(transactionId, { order_payment_id: orderPaymentId });
			const { data } = res;
			handleHideModal();
			renderNotice(data);
		} catch (error) {
			notification.error(t("transaction:message.create_transaction_completed_failed"));
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal
			title="Danh sách đơn YCTT"
			visible={visible}
			onCancel={handleHideModal}
			width={800}
			okText={t("btn.ok")}
			cancelText={t("btn.cancel")}
			okButtonProps={{ loading, disabled: isNil(orderPaymentId) }}
			onOk={handleRefundAmount}
		>
			<Table
				rowSelection={{
					type: "radio",
					...rowSelection,
				}}
				columns={columns}
				dataSource={orderPayments}
				rowKey={(record) => get(record, "id")}
			/>
		</Modal>
	);
};

ModalOrderList.propTypes = {
	transactionId: PropTypes.number,
	handleHideModal: PropTypes.func,
	orderPayments: PropTypes.array,
	renderNotice: PropTypes.func,
	visible: PropTypes.bool,
};

export default connect(mapDispatchToProps)(ModalOrderList);
