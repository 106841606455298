export const TRANSACTION_UPLOAD_FILE_CSV = {
    REQUEST: 'TRANSACTION_UPLOAD_FILE_CSV_REQUEST',
    SUCCESS: 'TRANSACTION_UPLOAD_FILE_CSV_SUCCESS',
    FAILED: 'TRANSACTION_UPLOAD_FILE_CSV_FAILED',
};

export const TRANSACTION_UPLOAD_FILE_CLEAR_STATE = 'TRANSACTION_UPLOAD_FILE_CLEAR_STATE';
export const STATUS_UPLOAD_UPLOADING = 'uploading';
export const STATUS_UPLOAD_DONE = 'done';
export const STATUS_UPLOAD_ERROR = 'error';
