import React from "react";
import lodash from "lodash";
import PropTypes from "prop-types";
import {Icon} from "antd";
import {translate} from "react-i18next";

class ListProblems extends React.Component {

    render() {
        let {t, order} = this.props;

        if (lodash.includes(["CANCELLED", "OUT_OF_STOCK"], order.status) && order.total_transaction !== 0)
            return <div className="mr-2">
                <Icon type="exclamation-circle" theme="filled" className="a-text--red mr-1"/>
                <span className="a-text--red">{t("transaction:problem.MISMATCHED_TOTAL_CANCELLED")}</span>
            </div>;

        return <div className="mr-2 list-problems">
            { order.problems.map((item, index) => {
                return <p key={index}>
                        <Icon type="exclamation-circle" theme="filled" className="a-text--red mr-1"/>
                        <span className="a-text--red">{t("transaction:problem." + item.problem)}</span>
                    </p>;
                })
            }
        </div>;
    }
}

ListProblems.defaultProps = {
    order: {}
};

ListProblems.propType = {
    order: PropTypes.object
};

export default translate()(ListProblems);