import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Select} from 'antd';

const {Option} = Select;

class SelectUnusualType extends React.PureComponent {
    render() {
        let {t, unusualTypes, loading} = this.props;
        return (
            <Select
                value={!loading ? this.props.value : undefined}
                mode={this.props.multiple ? 'multiple' : 'default'}
                showSearch={this.props.showSearch}
                optionFilterProp={this.props.optionFilterProp || 'children'}
                placeholder={this.props.placeholder}
                isLoading={loading}
                onChange={this.props.onChange}
            >
                {this.props.allOption && <Option value="">{t('label.all')}</Option>}
                {unusualTypes.map(status => <Option key={status} title={t('transaction:reasons.' + status)} value={status}>{t('transaction:reasons.' + status)}</Option>)}
            </Select>
        );
    }
}

SelectUnusualType.defaultProps = {
    value: null,
    allOption: false,
    multiple: false,
    filter: value => true,
    onChange: value => {},
    listScanningStatus: [],
};

SelectUnusualType.propTypes = {
    value: PropTypes.any,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    filter: PropTypes.func,
    onChange: PropTypes.func,
    listScanningStatus: PropTypes.array,
};

export default translate()(SelectUnusualType);
