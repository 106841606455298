import React from 'react';
import {translate} from 'react-i18next';
class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleLogout = this.handleLogout.bind(this);
    }

    componentWillMount() {
        this.props.setPageTitle('Dashboard');
        this.props.setBreadCrumb([
            {
                'label' : 'Home',
                'link'  : '/'
            },
            {
                'label' : 'Dashboard',
                'link'  : '/'
            }
        ]);
    }

    handleLogout(e) {
        e.preventDefault();
        this.props.onLogout();
    }

    render() {
        return (
            <div className="row" >

            </div>
        );
    }

}

export default translate('translations')(Home);