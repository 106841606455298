import React, {useState} from "react";
import {translate} from "react-i18next";
import {Modal, Input, notification, Radio} from "antd";
import apiService from "../apiService";
import lodash from "lodash";

const {TextArea} = Input;

const MESSAGE_ERROR = {
    CANCELLED: "Từ chối",
    SUCCESS: "Duyệt"
};

const ChangeStatus = function (props) {
    let {approval, status, t} = props;
    let [visible, setVisible] = useState(false);
    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState([]);
    let [note, setNote] = useState("");
    const [confirmInvoiceDelete, setConfirmInvoiceDelete] = useState(undefined);
    const {add_errors, errorsPaymentRequestExit, errorsPaymentRequestSuccess, confirmInvoice} = renderInvoiceCode(errors);

    function renderInvoiceCode(errors) {
        let add_errors = errors.filter(error => error.action === "add");
        let delete_errors = errors.filter(error => error.action === "delete");
        let errorsPaymentRequestExit = [];
        let errorsPaymentRequestSuccess = [];
        if (delete_errors.length > 0) {
            delete_errors.map(error => {
                if (lodash.has(lodash.get(error, "errors", {}), 'invoice_code.PAYMENT_REQUEST_EXIST')) {
                    const invoice_code = lodash.get(error, "invoice_code", undefined);
                    if (invoice_code) {
                        errorsPaymentRequestExit.push(invoice_code)
                    }
                }
                if (lodash.has(lodash.get(error, "errors", {}), 'invoice_code.PAYMENT_REQUEST_SUCCESS')) {
                    const invoice_code = lodash.get(error, "invoice_code", undefined);
                    if (invoice_code) {
                        errorsPaymentRequestSuccess.push(invoice_code)
                    }
                }
            });
        }
        const confirmInvoice = (errorsPaymentRequestExit.length === 0 && errorsPaymentRequestSuccess.length > 0);
        return {add_errors, errorsPaymentRequestSuccess, errorsPaymentRequestExit, confirmInvoice}
    }

    async function changeStatus() {
        let data = {status, note};
        if (confirmInvoice) {
            data["confirm"] = confirmInvoiceDelete;
        }
        return await apiService.changeApprovalRequestStatus(approval.id, {...data});
    }

    function handleClick(e) {
        e.preventDefault();
        setVisible(true);
    }

    function handleOk() {
        if (confirmInvoice && confirmInvoiceDelete === undefined){
            notification.error({message: "Bạn chưa xác nhận xóa MHĐG đã có YCTT thành công!"});
            return;
        }
        changeStatus()
            .then(res => {
                let data = lodash.get(res, "data", {});
                if (data.code === "INPUT_INVALID") {
                    const validate_error = lodash.get(data, "data.invoice_code.validate_error", []);
                    const {confirmInvoice} = renderInvoiceCode(validate_error);
                    if (!confirmInvoice) {
                        notification.error({message: MESSAGE_ERROR[status] + " yêu cầu duyệt thay đổi MHĐG không thành công vì MHĐG đang có YCTT."});
                    }
                    setErrors(validate_error);
                } else {
                    notification.success({message: MESSAGE_ERROR[status] + " đề nghị thay đổi MHĐG thành công!"});
                    props.updateInvoiceCodeApprovals(data.data);
                    setVisible(false);
                }
            })
            .catch(err => {
                notification.error({message: MESSAGE_ERROR[status] + " đề nghị thay đổi MHĐG không thành công!"});
                setVisible(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleCancel() {
        setVisible(false);
        setErrors([]);
    }

    function changeNote(e) {
        setNote(e.target.value);
    }

    function onChangeConfirmInvoiceDelete(e) {
        const value = e.target.value;
        if (parseInt(value)) {
            setConfirmInvoiceDelete(value);
        } else {
            handleCancel();
        }
    }

    return <>
        <a href="#"
           onClick={handleClick}
           className={props.className}
        >
            {t("changeInvoiceRequest:button." + status)}
        </a>
        <Modal
            title={t("changeInvoiceRequest:modal." + status)}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={t("changeInvoiceRequest:button." + status)}
            cancelText={t("changeInvoiceRequest:button.CANCEL")}
            okButtonProps={{loading: loading, disabled: loading}}
        >
            <label className="d-block pb-1">{t("changeInvoiceRequest:note." + status)}</label>
            <TextArea onChange={changeNote} style={{height: "100px"}}/>
            {
                add_errors.length > 0 &&
                <div className="mt-2">
                    <div className="a-text--color-primary"><strong>Các MHĐG đã tồn tại trên hệ thống:</strong></div>
                    {
                        add_errors.map(error => {
                            return <p className="ml-2">{error.invoice_code}</p>;
                        })
                    }
                </div>
            }
            {
                errorsPaymentRequestExit.length > 0 &&
                <div className="mt-2">
                    <div className="a-text--color-primary"><strong>Các MHĐG đang có YCTT:</strong></div>
                    {
                        errorsPaymentRequestExit.map(invoice_code => {
                            return <p className="ml-2">{invoice_code}</p>;
                        })
                    }
                </div>
            }
            {
                errorsPaymentRequestSuccess.length > 0 &&
                <div className="mt-2">
                    <div className="a-text--color-primary"><strong>Các MHĐG có YCTT thành công:</strong></div>

                    {
                        errorsPaymentRequestSuccess.map(invoice_code => {
                            return <p className="ml-2">{invoice_code}</p>;
                        })
                    }
                    {
                        confirmInvoice &&
                        <>
                            <label className="d-block pt-2 pb-1">
                                <strong>Bạn có chắc chắn muốn xóa MHĐG có đã có YCTT thành công?</strong>
                            </label>
                            <Radio.Group
                                value={confirmInvoiceDelete} name="confirm"
                                onChange={onChangeConfirmInvoiceDelete}
                                buttonStyle="solid"
                            >
                                <Radio.Button value="1">Xác nhận</Radio.Button>
                                <Radio.Button value="0">Hủy</Radio.Button>
                            </Radio.Group>
                        </>
                    }
                </div>
            }
        </Modal>
    </>;
}

export default translate()(ChangeStatus);
