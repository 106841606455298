import React from 'react';
import lodash from 'lodash';
import FormProcessing from '../../../common/components/FormProcessing';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import Button from "@atlaskit/button";
import DropdownSelectCheckbox from '../../../common/components/DropdownSelectCheckbox/index';
import authService from "../../../Auth/authService";
import * as PERMISSION from "../../../Permission/permissions";

class FormPerformAction extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            isOpenForm: false,
            input: {
                code: ''
            },
            inputVersion: 0,
            changedParams: []
        }
    }

    /**
     * handle việc đóng popup form lại
     */
    closeModalForm = () => {
        this.setState({isOpenForm: false});
        this.props.onCloseFormAssignPurchaser();
    };

    /**
     * handle việc đóng popup form lại
     */
    closeModalFormAfterSuccess = () => {
        this.setState({isOpenForm: false});
        this.props.onClearCheckAll();
        this.props.onCloseFormAssignPurchaser()
    };

    onBlurInput() {
        this.props.onSubmit(this.state.input);
    }

    onChangeFormInput(param, value) {
        if (value === '') {
            let input = this.changeInput({[param]: value});
            this.props.onSubmit(input);
        }
    }

    onChangeInputCustomize(param, event) {
        let value = event.target.value;
        value = value.trim();
        if (value === '') {
            let input = this.changeInput({[param]: value});
            this.props.onSubmit(input);
        }
    }

    onChangeInputBlur(param, event) {
        let value = event.target.value;
        value = value.trim();
        let input = this.changeInput({[param]: value});
        this.props.onSubmit(input);
    }

    onChangeFormZero(event) {
        event.preventDefault();
        this.props.onClear(this.props.stateDefault);
    }

    onKeyPressInput(input, event) {
        if (event.key === 'Enter') {
            this.props.onSubmit(input);
        }
    }

    onRequestDistributor() {
        let {item_checked} = this.props;
        item_checked = item_checked.map(function (value) {
            return lodash.parseInt(value);
        });
        this.props.onRequestDistributor({id_warehouse: item_checked});
    }


    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        let formData = this.state.formData;
        formData = {...formData, ...input};
        this.setState({formData});
    }

    /**
     * Làm đẹp Box select kho, trường hợp có 1 thì Text không cần show đủ
     * Trường hợp danh sách địa chỉ kho chỉ có 1 thì cần gì show mã + label.
     */
    prettyWarehouseChoice(warehouses) {
        let arr = [...warehouses];
        let { t } = this.props;
        if (warehouses.length === 1) {
            arr = warehouses.map(function (warehouse) {
                warehouse.label = t('order:list.default_warehouse');

                return warehouse;
            });
        }

        return arr;
    }

    render() {
        let {
            t, warehouses, isDistributiveWarehouseSetting,
            clearDistributeOrder, requiredChooseAddress, isUsingOrderDistributorSetting, item_checked
        } = this.props;
        warehouses = this.prettyWarehouseChoice(warehouses);
        let {input} = this.state;
        return (
            <div className='a-filter a-filter__purchase mb-5' key={this.props.inputVersion}>
                <div className={"flex-start"}>
                    <div className={'a-btn--filter__group_purchase'}>
                        <i className="ion-ios-search-strong icon-search"/>
                        <input
                            type="text"
                            className={"input input--sort_purchase "}
                            placeholder={t('order:list.order_code_placeholder')}
                            value={input.code}
                            onChange={this.onChangeInput.bind(this, 'code')}
                            onBlur={this.onChangeInputBlur.bind(this, 'code')}
                            onKeyUp={this.onChangeInputCustomize.bind(this, 'code')}
                            onKeyPress={event => this.onKeyPressInput(input, event)}
                        />
                        {(input && input.code) &&
                        <i className="fas fa-times-circle icon_remove"
                           onClick={event => this.onChangeFormInput('code', '')}
                        />
                        }
                    </div>
                    &nbsp;&nbsp;
                    <a className="btn-reset hidden" onClick={event => this.onChangeFormZero(event)}>
                        <i className="fas fa-sync icon-reset"/>&nbsp;{t("btn.reset")}
                    </a>
                </div>
                <div className={isUsingOrderDistributorSetting ? "flex-end" : "hidden"}>
                    <div className={'group_btn_purchase'}>
                        {
                            authService.can(PERMISSION.ORDER_SELF_CLAIM) &&
                            <div className={"d-flex"}>
                                <div className="mr-4">
                                    <DropdownSelectCheckbox
                                        t={t}
                                        item_checked={item_checked}
                                        options={warehouses}
                                        clearDistributeOrder={clearDistributeOrder}
                                        title_button={isDistributiveWarehouseSetting ? t('order:list.choose_warehouse') : t('order:list.choose_address_receive_china')}
                                        placeholder_search={t('order:list.search_address_receive_china')}
                                        onCheckItem={this.props.onCheckItem}
                                        onCheckAll={this.props.onCheckAll}
                                    />
                                </div>

                                <Button
                                    appearance="primary"
                                    isDisabled={!(item_checked.length && requiredChooseAddress)}
                                    onClick={this.onRequestDistributor.bind(this)}
                                    className={"btn-active " + (item_checked.length && requiredChooseAddress ? '' : 'a-disable')}>
                                    <i className={"fab fa-mixcloud mr-1"}/>
                                    {t("order:list.request_order")}
                                </Button>

                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    }
}

FormPerformAction.defaultProps = {
    purchasers: [],
    warehouses: [],
    item_checked: [],
    orderIds: [],
    isLoading: false,
    onTellerAssign: () => {
    },
    clearDistributeOrder: () => {
    },
    error: {},
    user: {},
    isDistributiveWarehouseSetting: false,
    isUsingOrderDistributorSetting: false,
    requiredChooseAddress: true,
};

FormPerformAction.propTypes = {
    purchasers: PropTypes.array,
    item_checked: PropTypes.array,
    orderIds: PropTypes.array,
    isLoading: PropTypes.bool,
    onTellerAssign: PropTypes.func,
    clearDistributeOrder: PropTypes.func,
    error: PropTypes.object,
    user: PropTypes.object,
    warehouses: PropTypes.array,
    isUsingOrderDistributorSetting: PropTypes.bool,
    isDistributiveWarehouseSetting: PropTypes.bool,
    requiredChooseAddress: PropTypes.bool
};

export default translate('translations')(FormPerformAction);
