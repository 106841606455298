import React from 'react';
import {Row, Col, Icon} from 'antd';
import {translate} from "react-i18next";

const STATUSES = [
    {id: "WAITING_PAI", icon: 'inbox'},
    {id: "PAID", icon: 'check'},
    {id: "WAITING_PAY", icon: 'bulb'},
    {id: "BOUGHT", icon: 'shopping'},
    {id: "OVERDUE_PURCHASE", icon: 'alert'}
];

class Stats extends React.Component {

    handleClick = status => {
        this.props.onSearch({status: status, page: 1});
    }

    render() {
        let {stats, t, filter, canChangeWaitingPay} = this.props;
        let ORDER_STATUS = STATUSES;
    
        if (canChangeWaitingPay !== "1") {
            ORDER_STATUS = STATUSES.filter(item => item.id !== "WAITING_PAY");
        }

        return <Row className="d-flex justify-content-start list-order-stats-nav a-text--light-red" style={{ flexWrap: 'wrap' }}>
            {
                ORDER_STATUS.map((status, index) => (
                    <Col key={index} style={{ width: `${100/ORDER_STATUS.length}%` }}>
                        <div
                            onClick={e => this.handleClick(status.id)}
                            className={`a-block--status a-block--status-purchases a-text--pointer ${status.id === filter.status ? 'effect-line':''}`}
                        >
                            <div className={`p-1 circle-icon mr-3 a-status a-status--${status.id}`}>
                                <Icon type={status.icon} className="circle-icon" />
                            </div>

                            <div className="d-flex flex-column">
                                <span className="a-text--bold a-text--color-black a-text--fz-22">{stats[status.id] ? stats[status.id] : 0}</span>
                                <span className="text-secondary a-text--uppercase a-text--fz-11">
                                {t(`order:status.${status.id}`)}
                            </span>
                            </div>
                        </div>
                    </Col>
                ))
            }
        </Row>;
    }
}

export default translate()(Stats);
