import {createSelector} from 'reselect';
import lodash from 'lodash';

const servicesLists = state => lodash.get(state, 'Services.list', []);

export const services = createSelector(
    servicesLists,
    items => items.map(service => serviceDetail(service))
);

const serviceDetail = (service) => {
    return lodash.assign({}, service);
};