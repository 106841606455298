import React from 'react';
import {translate} from 'react-i18next';
import {Tooltip} from "antd";

class Logout extends React.Component {

    handleLogout(e) {
        e.preventDefault();
        this.props.handleLogout();
    }

    render() {
        let {t} = this.props;
        return (
            <Tooltip placement="right" title={t('theme.menu.logout')}>
                <p className="a_sidebar__item not-icon-hover" onClick={this.handleLogout.bind(this)}>
                    <a href="" className="a_sidebar__link">
                        <i className="fas fa-sign-out-alt a-text--right rotate--180 icon__logout" /> <span>{t("layout.title.logout")}</span>
                    </a>
                </p>
            </Tooltip>
        );
    }

}

export default translate('translations')(Logout);
