import React from 'react';
import lodash from 'lodash';
import FormProcessing from './../../../common/components/FormProcessing';
import PropTypes from 'prop-types';
import moment from 'moment';
import {translate} from 'react-i18next';
import Button from "@atlaskit/button/dist/esm/components/Button";
import {ModalTransition} from '@atlaskit/modal-dialog';
import FormCreate from './FormCreate';
import {dateFormatter} from "./../../../common/services/format";
import ModalUpdateForm from './ModalUpdateForm';
import {hasPermission, permissions} from "../../../Permission";
import {Icon} from "antd";
import CreateComplaint from "../../CreateComplaint";
import {events} from "../../../../systems/events";
import {COMPLAINT_SELLER} from "../../events";
import ExportCSV from "./ExportCSV";

class FormPerformAction extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            isOpenForm: false,
            isOpenUpdateForm: false,
            input: {
                code: '',
            },
            inputVersion: 0,
            changedParams: []
        }
    }

    /**
     * handle việc đóng popup form lại
     */
    closeModalForm = () => {
        this.setState({isOpenForm: false});
    };

    /**
     * handle việc đóng bỏ chuột ra ngoài thực hiện search dữ liệu
     */
    onBlurInput() {
        this.props.onSubmit(this.state.input);
    }
    /**
     * handle việc thay đổi giá trị input
     */
    onChangeFormInput(param, value) {
        if (value === '') {
            let input = this.changeInput({[param]: value});
            this.props.onSubmit(input);
        }
    }
    /**
     * handle việc reset form tìm kiếm
     */
    onChangeFormZero(event){
        event.preventDefault();
        let {defaultState} = this.props;
        events.dispatch(COMPLAINT_SELLER.RESET_FILTER, defaultState);
        this.props.onClear(defaultState);
    }

    /**
     * Handle việc mở modal form
     */
    openModalForm(){
        this.setState({isOpenForm: true, accountPurchaser: {}});
        this.props.beginProcesscreateComplaintSeller({});
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState =  FormProcessing.getDerivedStateFromProps(nextProps, prevState);
        if (lodash.get(nextProps, "isEndProcess.postComplaintSellerCreate", false) === true) {
            nextState.isOpenForm = false;
        }

        return nextState;
    }

    componentDidMount() {
        let {filter} = this.props;
        if(lodash.get(filter, 'showCreateForm', 0) === "1") {
            this.openModalForm();
        }
    }

    render() {
        let {t,
            complaintSellerReasons,
            inputVersion,
            filter}= this.props;
        let {isOpenUpdateForm} = this.state;
        return (
            <div className='a-filter a-filter__purchase' key={inputVersion}>
                <h3 className="a-text--fz-16">Danh sách khiếu nại người bán</h3>
                <div className="group_btn_purchase">
                    <a
                        className="btn-reset _complaint-seller-click-button-reset"
                        onClick={event => this.onChangeFormZero(event)}
                    >
                        <Icon type="sync" className="a-text--fz-12" /> <span>{t("btn.reset")}</span>
                    </a>
                    <ExportCSV filter={filter} />
                    {
                        hasPermission(permissions.COMPLAINT_SELLER_CREATE) &&
                        <>
                            <CreateComplaint
                                orderCode={filter.orderCode}
                                complaintSellerReasons={complaintSellerReasons}
                            >
                                <Button
                                    className="btn-active ml-3 _complaint-seller-button-create-complaint"
                                    appearance="primary"
                                    type="submit"
                                >
                                    <i className="fas fa-plus mr-1"></i> {t('complaintSeller:list.created')}
                                </Button>
                            </CreateComplaint>
                        </>
                    }
                </div>
                <ModalTransition>
                    {isOpenUpdateForm && (
                        <ModalUpdateForm
                            title={t('account_purchaser.header_modal_form')}
                            complaintSellerReasons={complaintSellerReasons}
                            onSubmitForm={this.props.onSubmitForm}
                            onClose={this.closeModalForm}
                        />
                    )}
                </ModalTransition>
            </div>
        );
    }
}

FormPerformAction.defaultProps = {
    purchasers: [],
    orderIds: [],
    isLoading: false,
    onTellerAssign: () => {
    },
    clearErrorCreateComplaintSeller: () => {
    },
    error: {}
};

FormPerformAction.propTypes = {
    purchasers: PropTypes.array,
    orderIds: PropTypes.array,
    isLoading: PropTypes.bool,
    onTellerAssign: PropTypes.func,
    clearErrorCreateComplaintSeller: PropTypes.func,
    error: PropTypes.object
};

export default translate('translations')(FormPerformAction);
