import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import Alipay from './Alipay';

class ComplaintAlipays extends React.Component {
    render() {
        let {t} = this.props;
        
        return (
            <div className="a-content--box-shadow  a__complaint__detail__block mt-3">
                <span className="a-link--uppercase a-text--font-primary">
                    {t('complaintSeller:detail.alipay_transaction')}
                </span>
                <div className="table-responsive">
                    <table className="table a-table--deal_alipay">
                        <thead>
                            <tr>
                                {this.props.canMapping ? <th style={{width: '1rem'}}/> : null}
                                <th className="check__complaint">
                                    {t('complaintSeller:detail.time')}
                                </th>
                                <th>
                                    {t('complaintSeller:detail.alipay_code')}
                                </th>
                                <th>
                                    {t('complaintSeller:detail.type_transaction')}
                                </th>
                                <th>
                                    {t('complaintSeller:detail.value')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderAlipays()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
    
    renderAlipays() {
        let {t, alipays} = this.props;

        if (!alipays.length) {
            return (
                <tr>
                    <td colSpan={5} className="text-center">
                        {t('complaintSeller:detail.not_found_data_alipay')}
                    </td>
                </tr>
            );
        }
        
        return alipays.map(alipay => <Alipay
            key={alipay.id}
            alipay={alipay}
            canMapping={this.props.canMapping}
            onAttach={this.props.onAttach}
            onDetach={this.props.onDetach}
        />);
    }
}

ComplaintAlipays.defaultProps = {
    alipays: [],
    canMapping: false,
    onAttach: (alipayId) => {},
    onDetach: (alipayId) => {},
};

ComplaintAlipays.propTypes = {
    alipays: PropTypes.array,
    canMapping: PropTypes.bool,
    onAttach: PropTypes.func,
    onDetach: PropTypes.func,
};

export default translate()(ComplaintAlipays);
