import {all, fork} from 'redux-saga/effects';
import ListTransactionFileWatcher from './ListTransactionFile/sagaWatcher';
import UploadTransactionFileWatcher from './UploadFileCSV/sagaWatcher';
import ListTransactionWatcher from './ListTransaction/sagaWatcher';
import InputAddInvoiceCodeWatcher from './InputAddInvoiceCode/sagaWatcher';
import ModalManageInvoiceCodeWatcher from './ModalManageInvoiceCode/sagaWatcher';
import DetailTransactionWatcher from './DetailTransaction/sagaWatcher';
import UploadInvoiceCodeCSV from './UploadInvoiceCodeCSV/sagaWatcher';
import MultiResolveTransaction from "./MultiResolveTransaction/sagaWatcher";
import UploadAutoMGD from "./UploadAutoMGD/sagaWatcher";

export default function*() {
    yield all([fork(ListTransactionFileWatcher)]);
    yield all([fork(UploadTransactionFileWatcher)]);
    yield all([fork(ListTransactionWatcher)]);
    yield all([fork(InputAddInvoiceCodeWatcher)]);
    yield all([fork(ModalManageInvoiceCodeWatcher)]);
    yield all([fork(DetailTransactionWatcher)]);
    yield all([fork(UploadInvoiceCodeCSV)]);
    yield all([fork(MultiResolveTransaction)]);
    yield all([fork(UploadAutoMGD)]);
}
