import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {Select, Icon, Row, Col, Tooltip} from "antd";
import {translate} from 'react-i18next';
import _ from "lodash";

const {Option} = Select;

class OrderGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            isEdit: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        if (JSON.stringify(nextProps.selected) !== JSON.stringify(prevState.selected)) {
            if (nextProps.selected.value) {
                state.selected = nextProps.selected;
            }
        }

        return state;
    }

    onChangeSelect(value) {
        if (value) {
            //let order_group_id = lodash.get(data, 'value');
            let {orderId, options} = this.props;
            let data = lodash.find(options, {value: value});
            this.props.onUpdateOrderGroupOrder({order_group_id: value, orderId});
            this.setState({
                selected: data,
                isEdit: false
            });
        }
    }

    render() {
        let {t, options, canChangeOrderGroup, order_group_using, orderGroup} = this.props;
        const {selected, isEdit} = this.state;
        let description = _.get(orderGroup, "description", null);
        return (
                <div className="d-flex flex-column">
                    <span className="a-purchasod__group--label a-purchasod__group--label--invoice">{t("order:detail.order_group_select")}</span>
                    {
                        canChangeOrderGroup && order_group_using ?
                            <Row>
                                <Col span={description ? 22 : 24}>
                                    <Select
                                        value={(selected && selected.value) ? selected.value : undefined}
                                        placeholder={t('order:detail.order_group_select')}
                                        onChange={data => this.onChangeSelect(data)}
                                        disabled={!canChangeOrderGroup}
                                        optionFilterProp="children"
                                        filterOption={true}
                                        showSearch={true}
                                        style={{width: "100%"}}
                                    >
                                        {
                                            options.map((opt, index) => {
                                                return <Option key={index} value={parseInt(opt.value)}>{opt.label}</Option>;
                                            })
                                        }
                                    </Select>
                                </Col>
                                {
                                    description &&
                                    <Col span={2} className="mt-2">
                                        <Tooltip title={description}>
                                            <Icon type="info-circle" className="a-text--fz-16 a-text--blue ml-2 a-text--green"/>
                                        </Tooltip>
                                    </Col>
                                }
                            </Row>
                        :
                        (selected && selected.label) ? selected.label : '---'
                    }
                </div>
        );
    }
}

OrderGroup.defaultProps = {
    canChangeOrderGroup: false,
    onPayment: () => {
    },
    options: [],
};

OrderGroup.propTypes = {
    canChangeOrderGroup: PropTypes.bool,
    onPayment: PropTypes.func,
    options: PropTypes.array,
    orderGroupId: PropTypes.number
};

export default translate('translations')(OrderGroup);
