export const GET_LIST_TRANSACTION_FILE = {
    REQUEST: 'GET_LIST_TRANSACTION_FILE_REQUEST',
    SUCCESS: 'GET_LIST_TRANSACTION_FILE_SUCCESS',
    FAILED: 'GET_LIST_TRANSACTION_FILE_FAILED',
};
export const DOWNLOAD_FILE_TRANSACTION = {
    REQUEST: 'DOWNLOAD_FILE_TRANSACTION_REQUEST',
    SUCCESS: 'DOWNLOAD_FILE_TRANSACTION_SUCCESS',
    FAILED: 'DOWNLOAD_FILE_TRANSACTION_FAILED',
};

export const TRANSACTION_LIST_FILE_CLEAR_STATE = 'TRANSACTION_LIST_FILE_CLEAR_STATE';
