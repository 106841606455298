import React from 'react'
import {translate} from 'react-i18next'
import FormProcessing from "../../common/components/FormProcessing";
import Select from "@atlaskit/select/dist/esm/Select";
import {ORDER_STATUS} from "../../Order/constants";
import lodash from 'lodash'

class Filter extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                code: '',
                status: '',
                id_purchaser: []
            },
            inputVersion: 0,
            changedParams: [],
        }
    }

    /**
     *
     * @param event
     */
    onClearFilter(event) {
        this.setState({
            input: {
                code: '',
                status: '',
                id_purchaser: []
            }
        });
        this.props.onClearFilter({});
    }

    /**
     *
     * @param param
     * @param event
     */
    onChangeSelect(param, event) {
        let value;
        if (lodash.isArray(event)) {
            value = lodash.map(event, 'value')
        } else {
            value = lodash.get(event, 'value', null);
        }
        this.changeInput({[param]: value});
    }

    /**
     * Submit form
     * @param event
     */
    onFilters(event) {
        let {input} = this.state;
        this.props.onSearch(input);
    }

    makeValueMultiSelect() {
        let {purchaserOptions} = this.props;
        let {input} = this.state;
        if (input.id_purchaser) {
            return purchaserOptions.filter(purchaserOption => {
                return (input.id_purchaser.includes(purchaserOption.value))
            });
        }
        return [];
    }


    render() {
        const {t, purchaserOptions} = this.props;
        const {input} = this.state;

        let statusOptions = [];
        let statusSelected = [];
        Object.keys(ORDER_STATUS).map(idx => {
            statusOptions.push({value: ORDER_STATUS[idx], label: t('order:status.' + ORDER_STATUS[idx])});

            if (input.status) {
                if (input.status.length > 0 && input.status.includes(idx)) {
                    statusSelected.push({value: ORDER_STATUS[idx], label: t('order:status.' + ORDER_STATUS[idx])});
                }
            } else {
                statusSelected = [];
            }

            return idx;
        });

        if(!statusSelected.length) {
            statusSelected.push({value: 'BOUGHT', label: t('order:status.BOUGHT')});
        }

        return (
            <div className="a-tab-order">
                <div className="a-tab-order__title a-filter--violate d-flex justify-content-start mb-4">
                    <div className="a-filter--violate--form flex--column  ">
                        <label className="a-text">{t('order:list.bill_no.filter_order_code')}</label>
                        <input
                            className={'input input--filter-no-bill'}
                            placeholder={t('order:list.order_code_placeholder')}
                            type="text"
                            value={input.code || ''}
                            onChange={this.onChangeInput.bind(this, 'code')}
                        />
                    </div>

                    <div className="a-filter--violate--form  ">
                        <label htmlFor=""
                               className="a-text a-label--order-billno">{t('order:list.bill_no.order_status')}</label>
                        <Select
                            classNamePrefix="react-select--violate-policy react-select--multi"
                            placeholder={""}
                            isClearable={event => this.onChangeSelect('status', event)}
                            isSearchable={true}
                            isMulti
                            value={statusSelected}
                            onChange={event => this.onChangeSelect('status', event)}
                            options={statusOptions}
                        />
                    </div>
                    <div className="a-filter--violate--form  ">
                        <label htmlFor=""
                               className="a-text a-label--order-billno">{t('order:list.bill_no.account_purchase')} </label>
                        <Select
                            classNamePrefix="react-select--violate-policy react-select--multi"
                            placeholder={""}
                            isClearable={event => this.onChangeSelect('id_purchaser', event)}
                            isSearchable={true}
                            isMulti
                            value={this.makeValueMultiSelect()}
                            options={purchaserOptions}
                            onChange={event => this.onChangeSelect('id_purchaser', event)}
                        />
                    </div>

                    <div className="a-filter--violate--action order-billno ">
                        <button className={'sort '} onClick={event => this.onFilters(event)}>
                            {t('order:list.bill_no.search')}
                        </button>
                        <a className='reset' onClick={event => this.onClearFilter(event)}>
                            {t('order:list.bill_no.refresh')}
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate('translations')(Filter);