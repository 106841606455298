import {COMPLAINT_SELLER_STATUS} from "./../../systems/globalContant/actionContant";

export const FETCH_COMPLAINT_SELLER_REASONS = {
    REQUEST: 'FETCH_COMPLAINT_SELLER_REASONS_REQUEST',
    SUCCESS: 'FETCH_COMPLAINT_SELLER_REASONS_SUCCESS',
    FAILED: 'FETCH_COMPLAINT_SELLER_REASONS_FAILED',
    ERROR: 'FETCH_COMPLAINT_SELLER_REASONS_ERROR',
};

export const FETCH_COMPLAINT_SELLER_RENEW_REASONS = {
    REQUEST: 'FETCH_COMPLAINT_SELLER_RENEW_REASONS_REQUEST',
    SUCCESS: 'FETCH_COMPLAINT_SELLER_RENEW_REASONS_SUCCESS'
};

export const FETCH_COMPLAINT_DETAIL = {
    REQUEST: 'FETCH_COMPLAINT_DETAIL_REQUEST',
    SUCCESS: 'FETCH_COMPLAINT_DETAIL_SUCCESS',
    FAILED: 'FETCH_COMPLAINT_DETAIL_FAILED',
    ERROR: 'FETCH_COMPLAINT_DETAIL_ERROR',
};

export const ADD_COMMENT_PRODUCT_SUCCESS = "ADD_COMMENT_PRODUCT_SUCCESS";
export const ADD_TRANSACTION_CODE_CUCCESS = "ADD_TRANSACTION_CODE_CUCCESS";

export const CLEAR_COMPLAINT_DETAIL = 'CLEAR_COMPLAINT_DETAIL';
export const UPDATE_ORIGIN_CODE_COMPLAINT_DETAIL = 'UPDATE_ORIGIN_CODE_COMPLAINT_DETAIL';
export const UPDATE_COMPLAINT_DETAIL_AGAIN = 'UPDATE_COMPLAINT_DETAIL_AGAIN';

export const FETCH_COMPLAINT_SELLER = {
    REQUEST: 'FETCH_COMPLAINT_SELLER_REQUEST',
    SUCCESS: 'FETCH_COMPLAINT_SELLER_SUCCESS',
    FAILED: 'FETCH_COMPLAINT_SELLER_FAILED',
    ERROR: 'FETCH_COMPLAINT_SELLER_ERROR',
};

export const CREATE_COMPLAINT_SELLER = {
    REQUEST: 'CREATE_COMPLAINT_SELLER_REQUEST',
    SUCCESS: 'CREATE_COMPLAINT_SELLER_SUCCESS',
    FAILED: 'CREATE_COMPLAINT_SELLER_FAILED',
    ERROR: 'CREATE_COMPLAINT_SELLER_ERROR',
};
export const COMPLAINT_SELLERS_REJECTED = 'COMPLAINT_SELLERS_REJECTED';
export const COMPLAINT_SELLER_REJECTED = 'COMPLAINT_SELLER_REJECTED';
export const COMPLAINT_SELLER_REASON_UPDATE = {
    REQUEST: 'COMPLAINT_SELLER_REASON_REQUEST',
    SUCCESS: 'COMPLAINT_SELLER_REASON_SUCCESS',
    FAILED: 'COMPLAINT_SELLER_REASON_FAILED',
    ERROR: 'COMPLAINT_SELLER_REASON_ERROR',
};

export const UPDATE_COMPLAINT_SELLER = {
    REQUEST: 'UPDATE_COMPLAINT_SELLER_REQUEST',
    SUCCESS: 'UPDATE_COMPLAINT_SELLER_SUCCESS',
    FAILED: 'UPDATE_COMPLAINT_SELLER_FAILED',
    ERROR: 'UPDATE_COMPLAINT_SELLER_ERROR',
};

export const EXPIRE_COMPLAINT_SELLER = {
    REQUEST: 'EXPIRE_COMPLAINT_SELLER_REQUEST',
    SUCCESS: 'EXPIRE_COMPLAINT_SELLER_SUCCESS',
    FAILED: 'EXPIRE_COMPLAINT_SELLER_FAILED',
    ERROR: 'EXPIRE_COMPLAINT_SELLER_ERROR',
};

export const CLEAR_ERROR_EXPIRE_COMPLAINT_SELLER = "CLEAR_ERROR_EXPIRE_COMPLAINT_SELLER";

export const CLEAR_ERROR_COMPLAINT_SELLER = "CLEAR_ERROR_COMPLAINT_SELLER";
export const IS_BEGIN_PROCESS_CREATE_COMPLAINT_SELLER = "IS_BEGIN_PROCESS_CREATE_COMPLAINT_SELLER";

export const OPEN_MODAL_COMPLAINT_SELLER = "OPEN_MODAL_COMPLAINT_SELLER";
export const OPEN_MODAL_CHANGE_COMPLAINT_SUCCESS = "OPEN_MODAL_CHANGE_COMPLAINT_SUCCESS";
export const CLOSE_MODAL_CHANGE_COMPLAINT_SUCCESS = "CLOSE_MODAL_CHANGE_COMPLAINT_SUCCESS";
export const CLOSE_MODAL_COMPLAINT_SELLER = "CLOSE_MODAL_COMPLAINT_SELLER";

export const STATUS_ACCOUNT_PURCHASER = [
    {label: 'ACTIVE', value: 'ACTIVE'},
    {label: 'INACTIVE', value: 'INACTIVE'}
];

export const SOURCE_ACCOUNT_PURCHASER = [
    {label: 'Taobao', value: 'Taobao'},
    {label: 'Tmall', value: 'Tmall'},
    {label: '1688', value: '1688'}
];

export const PLACEHOLDER = {
    ORDER: 'ORDER',
    ACCOUNT: 'ACCOUNT',
    VALUE: 'VALUE',
    TIME: 'TIME',
    FILTER: 'FILTER',
};

export const COMPLAINT_STATUS = {
    WAITING: 'WAITING',
    PROCESSING: 'PROCESSING',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
    RETURNEE: 'RETURNEE',
};


export const STATE_FILTER_COMPLAINT_DEFAULT = {
    code: '',
    order_seller_name: '',
    seller_source: [],
    id_processor: [],
    account_purchaser_id: [],
    order_seller_source: [],
    refund_status: '',
    time_type: COMPLAINT_SELLER_STATUS.WAITING,
    log_status_to: '',
    log_status_from: '',
    orderBy: 'created_at',
    sort: 'desc',
};

export const CRAWL_EVENTS = {
    MESSAGE_CODE: "SYNCHRONIZE_COMPLAINT.SEND_COMPLAINT_CODE_FROM_KO",
    SYNCHRONIZE_RESPONSE: "SYNCHRONIZE_COMPLAINT.SEND_COMPLAINT_RESPONSE",
    CRAWL_COMPLAINT_FAIL: "SYNCHRONIZE_COMPLAINT.SEND_COMPLAINT_FAIL",
    DONT_LOGIN_SITE: "SYNCHRONIZE_COMPLAINT.DONT_LOGIN_SITE",
    CANCEL_CRAWL: "SYNCHRONIZE_COMPLAINT.CANCEL_CRAWL",
    CLOSE_CRAWLING_TAB: "SYNCHRONIZE_COMPLAINT.CLOSE_CRAWLING_TAB"
}

export const ORIGIN_COMPLAINT_LINK = {
    '1688': "https://dispute.1688.com/refund/assureRefundDetail.htm?refundId=",
    taobao: "https://refund2.taobao.com/dispute/detail.htm?type=1&disputeId=",
    tmall: "https://refund2.tmall.com/dispute/detail.htm?type=1&disputeId="
};
