import React, {Component} from 'react';
import PropTypes from 'prop-types';
import lodash from "lodash";
import {Select, Icon} from "antd";
import apiService from "./../../apiService";
import {processResponse} from "../../../../systems/api";
import bootbox from "../../../common/components/Bootbox/bootbox";
import {events} from "../../../../systems/events";
import {COMPLAINT_SELLER_REASON_UPDATE} from "../../events";
import {translate} from "react-i18next";

const {Option} = Select;
class UpdateComplaintSellerReason extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isEdit: false
        }
    }

    onClick = e => {
        this.setState({isEdit: true});
    }

    onBlur = e => {
        this.setState({isEdit: false});
    }

    onChangeReason(value) {
        let {complaint, t} = this.props;

        if (this.state.loading) {
            return;
        }

        this.setState({loading: true});

        let res = apiService.updateComplaintSellerReason({complaint_id: complaint.id, reason_id: value})
            .finally(() => this.setState({loading: false, isEdit: false}));

        let process = processResponse(res);

        process.then(res => {
            bootbox.success(t('complaintSeller:update_reason.messsage_update_reason_success'));
            events.dispatch(COMPLAINT_SELLER_REASON_UPDATE.SUCCESS, {
                ...res.data.complaintSeller.reason
            });
        });

        process.catch((err) => {
            let errKey = lodash.keys(err.data)[0];
            let errValue = lodash.keys(lodash.get(err, `data.${errKey}`, {}))[0];
            let errName = t(`complaintSeller:error.${errKey}`);
            bootbox.error(t(`complaintSeller:error.error.${errValue}`, {name : errName}));
        });
    }

    render() {
        let {complaint, complaintSellerReasons} = this.props;
        let {loading, isEdit} = this.state;

        return (
            <div className="d-flex align-item-center cs-reason">
                <span className="mr-2">Lý do:</span>
                {
                    (!lodash.includes(['SUCCESS', 'FAILURE'], complaint.status)) ?
                        <>
                            {
                                isEdit ?
                                    <Select
                                        showSearch={true}
                                        style={{minWidth: '200px'}}
                                        value={parseInt(lodash.get(complaint, 'reason.id', 0))}
                                        optionFilterProp={'children'}
                                        showArrow={true}
                                        disabled={loading}
                                        loading={loading}
                                        onChange={this.onChangeReason.bind(this)}
                                        onBlur={this.onBlur}
                                    >
                                        {
                                            complaintSellerReasons.map(reason => {
                                                return <Option value={reason.value}>
                                                    {reason.label}
                                                </Option>
                                            })
                                        }
                                    </Select> :
                                    <span
                                        style={{height: "32px", lineHeight: "32px"}}
                                        onClick={this.onClick}
                                        className="a-text--color-primary"
                                    >{lodash.get(complaint, 'reason.title', null)} <Icon type="edit" /></span>
                            }
                        </>:
                        <span
                            style={{height: "32px", lineHeight: "32px"}}
                            className="a-text--color-primary"
                        >{lodash.get(complaint, 'reason.title', null)}</span>
                }
            </div>
        );
    }
}

UpdateComplaintSellerReason.propTypes = {};

export default translate()(UpdateComplaintSellerReason);
