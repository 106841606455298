import React from 'react'
import { Input, Modal, Icon, Col, Row, Table } from 'antd'
import lodash from 'lodash'
import ReactFileReader from 'react-file-reader'
import { t, trans } from '../../../systems/i18n'
import { Form } from 'antd'
import SelectAccountBanks from '../../BankAccount/SelectAccountBanks'
import SelectAccountPurchasers from '../../AccountPurchaser/SelectAccountPurchasers'
import apiService from '../apiService'
import { processResponse } from '../../../systems/api'
import { events } from '../../../systems/events'
import PAYMENT_REQUEST_EVENT from '../events'
import notification from '../../../systems/notification'

class SuccessPaymentConfirmation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            disabled: false,
            loading: false,
            visible: false,
            inputAttachFile: [],
            transactionCodes: undefined,
            transactionCodesError: '',
        }
    }

    processPaymentRequestHasFile = (oldStatus, data) => {
        let { request, form } = this.props
        let { status } = data

        let res = apiService.changeStatePaymentRequest(request.id, data)
        processResponse(res)
            .then(res => {
                notification.success(t(`paymentRequest:message.CHANGED_STATE_${status}`))
                events.dispatch(lodash.get(PAYMENT_REQUEST_EVENT, `${status}`, ''), {
                    request: { ...res.data },
                    oldStatus,
                })
            })
            .catch(err => {
                let { code, data } = err
                let transactionCodesError = ''
                let messages = {}
                if (code === 'INPUT_INVALID') {
                    Object.keys(data).forEach(key => {
                        let errors = Object.keys(data[key])
                        if (key === 'status') {
                            notification.error(t('paymentRequest:confirm_payment.INVALID'))
                        } else {
                            messages[key] = {
                                errors: errors.map(error => {
                                    let transaction_code = lodash.get(data, `${key}.${error}`, '')
                                    console.log("transaction_code", transaction_code);
                                    if (['ALREADY_EXIST', 'DUPLICATE', 'GREATER', 'REQUIRED'].includes(error) && transaction_code) {
                                        transactionCodesError = trans(`paymentRequest:confirm_payment.${error}`, {
                                            attribute: t(`paymentRequest:label.${key}`),
                                            code: transaction_code,
                                        })
                                    } else {
                                        return new Error(trans(`paymentRequest:confirm_payment.${error}`, { attribute: t(`paymentRequest:label.${key}`) }))
                                    }
                                }),
                            }
                        }
                    })
                    form.setFields(messages)
                } else {
                    notification.error(t('message.cant_update_data'))
                }
                this.setState({
                    disabled: false,
                    loading: false,
                    transactionCodesError
                })
            })
            .finally(() =>
                this.setState({
                    disabled: false,
                    loading: false,
                })
            )
    }

    handleOk = e => {
        const { form } = this.props
        const { transactionCodes } = this.state
        this.setState({
            disabled: true,
            loading: true,
        })
        form.validateFields((err, values) => {
            if (!err) {
                let transactionCodesFormatted = {}
                if (!lodash.isEmpty(transactionCodes)) {
                    console.log("transactionCodes", transactionCodes);
                    Object.keys(transactionCodes).map(item => {
                        const transactionCode = lodash.get(transactionCodes, item)
                        transactionCodesFormatted[item] = transactionCode ? transactionCode.split('\n').map(item => item.trim()) : []
                    })
                }

                this.processPaymentRequestHasFile('PROCESSING', {
                    status: 'SUCCESS',
                    images: this.state.inputAttachFile,
                    id_bank_payment: form.getFieldValue('id_bank_payment'),
                    message: form.getFieldValue('message'),
                    transaction_codes: transactionCodesFormatted,
                    confirm: true,
                })
            } else {
                this.setState({
                    disabled: false,
                    loading: false,
                })
            }
        })
    }

    handleCancel = e => {
        let { form } = this.props
        this.setState({
            visible: false,
            disabled: false,
            loading: false,
            inputAttachFile: [],
            transactionCodes: undefined,
            transactionCodesError: '',
        })
        form.resetFields()
    }

    handleModal = e => {
        this.setState({ visible: true })
    }

    handleFiles = files => {
        const { inputAttachFile } = this.state
        files.base64.map(item => inputAttachFile.push(item))
        this.setState({ inputAttachFile })
    }

    removeImage = id => {
        const { inputAttachFile } = this.state
        this.setState({ inputAttachFile: inputAttachFile.filter((item, index) => index != id) })
    }

    handlePaste = event => {
        const clipboardData = event.clipboardData
        const allowedAttachFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif']
        const allowedAttachFileSize = 5120000 // bytes => 5MB

        if (clipboardData.files.length > 0) {
            lodash.forEach(clipboardData.items, item => {
                if (lodash.includes(allowedAttachFileTypes, item.type)) {
                    let attachFile = item.getAsFile()
                    let reader = new FileReader()

                    // Bắt đầu đọc file
                    reader.readAsDataURL(attachFile)

                    // Chờ file load xong tạm trong DOM mới lấy đươc base64 data
                    reader.onload = ev => {
                        let { inputAttachFile } = this.state
                        inputAttachFile.push(ev.target.result)
                        this.setState({ inputAttachFile })
                    }
                }
            })
        }

        return false
    }

    handleOnchangeValue(key, value) {
        const { transactionCodes } = this.state
        this.setState({
            transactionCodesError: '',
        })
        let data = {
            ...transactionCodes,
        }
        if (value) {
            data = {
                ...data,
                [key]: value,
            }
        } else {
            delete data[key]
        }
        this.setState({
            transactionCodes: data,
        })
    }

    render() {
        const { form, request } = this.props

        const { getFieldDecorator } = form
        const { payment_type, paymentAccountBank, paymentAccountPurchaser } = request
        const invoiceCodes = lodash.get(request, 'invoiceCodes', [])
        const { inputAttachFile, transactionCodes, transactionCodesError } = this.state

        return (
            <>
                <a className="a-text--color-primary a--cursor-poiter _payment-request-processing-action-success" onClick={this.handleModal}>
                    {this.props.children}
                </a>
                <Modal
                    centered={true}
                    className="payment-request-modal"
                    title="Xác nhận thanh toán thành công"
                    width={650}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    cancelText={t('btn.cancel')}
                    okText={t('btn.save')}
                    cancelButtonProps={{ className: '_payment-request-processing-cancel-confirm-button' }}
                    okButtonProps={{
                        className: '_payment-request-processing-ok-confirm-button',
                        disabled: this.state.disabled,
                        loading: this.state.loading,
                    }}>
                    <Form>
                        <Form.Item label="Chọn tài khoản thanh toán" className="mb-0">
                            {getFieldDecorator('id_bank_payment', {
                                initialValue:
                                    payment_type === 'bank'
                                        ? lodash.get(paymentAccountBank, 'id', undefined)
                                        : lodash.get(paymentAccountPurchaser, 'id', undefined),
                            })(
                                payment_type === 'bank' ? (
                                    <SelectAccountBanks
                                        placeholder={t('paymentRequest:placeholder.id_bank_payment_bank')}
                                        multiple={false}
                                        filter={{ scope: 'basic' }}
                                        className="select-account-banks-payment _payment-confirm-success-bank-payment"
                                    />
                                ) : (
                                    <SelectAccountPurchasers
                                        placeholder={t('paymentRequest:placeholder.id_bank_payment_alipay')}
                                        multiple={false}
                                        className="select-account-banks-payment _payment-confirm-success-bank-payment"
                                    />
                                )
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Mã giao dịch Alipay/Ngân hàng"
                            className="mb-0"
                            validateStatus={transactionCodesError ? 'error' : ''}
                            help={transactionCodesError ? transactionCodesError : ''}>
                            <Table
                                className="_payment-confirm-success-transaction-codes"
                                dataSource={invoiceCodes}
                                rowKey={record => record.id}
                                columns={[
                                    {
                                        title: 'Mã hoá đơn gốc',
                                        dataIndex: 'invoice_code',
                                        key: 'invoice_code',
                                        className: '_invoice-code',
                                    },
                                    {
                                        title: 'Mã giao dịch tương ứng',
                                        dataIndex: 'transaction_code',
                                        key: 'transaction_code',
                                        className: '_transaction-code',
                                        render: (text, record) => {
                                            const invoiceCode = lodash.get(record, 'invoice_code', '')
                                            const value = lodash.get(transactionCodes, invoiceCode)
                                            return (
                                                <Input.TextArea
                                                    key={invoiceCode}
                                                    value={value}
                                                    onChange={e => this.handleOnchangeValue(invoiceCode, e.target.value)}
                                                    rows={2}
                                                    placeholder={'Nhập mã giao dịch'}
                                                    className="_payment-confirm-success-transaction-codes"
                                                />
                                            )
                                        },
                                    },
                                ]}
                                pagination={false}
                            />
                        </Form.Item>
                        <div style={{ position: 'relative' }}>
                            <Form.Item label="Ghi chú kết quả" className="mb-0">
                                {getFieldDecorator('message', {
                                    rules: [{ required: true, message: 'Ghi chú kết quả là bắt buộc.' }],
                                })(
                                    <Input.TextArea
                                        rows={3}
                                        onPaste={this.handlePaste}
                                        placeholder="Nhập ghi chú kết quả"
                                        className={'_payment-confirm-success-message'}
                                    />
                                )}
                            </Form.Item>
                            <div style={{ position: 'absolute', top: '45px', right: '10px' }}>
                                <ReactFileReader
                                    handleFiles={this.handleFiles}
                                    fileTypes={['image/*']}
                                    multipleFiles={true}
                                    base64={true}
                                    className={'_payment-confirm-success-image'}>
                                    <i className="fas fa-camera" style={{ cursor: 'pointer' }} title="Upload" />
                                </ReactFileReader>
                            </div>
                            {inputAttachFile.length > 0 && (
                                <ul className="img-item">
                                    {inputAttachFile.map((item, index) => {
                                        return (
                                            <li key={index} className={index !== 0 ? 'ml-1' : ''}>
                                                <img height={50} src={item} />
                                                <span className="a--cursor-poiter a-text--red" onClick={() => this.removeImage(index)}>
                                                    ✕
                                                </span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            )}
                        </div>
                    </Form>
                </Modal>
            </>
        )
    }
}

export default Form.create()(SuccessPaymentConfirmation)
