import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import ManagerPackages from './components/index';
import {createPackage, removePackage, beginRemovePackage} from './actions';

const mapStateToProps = (state) => {
    return lodash.get(state, 'complainProductReturn.managePackages') || {};
};

const mapDispatchToProps = (dispatch, props) => {
    let {productReturn} = props;

    return {
        onCreatePackage: (package_code) => {
            dispatch(createPackage(productReturn.id, package_code));
        },
        onRemovePackage: (package_code) => {
            dispatch(removePackage(productReturn.id, package_code));
        },
        onBeginRemovePackage: () => {
            dispatch(beginRemovePackage(productReturn.id));
        },
    };
};

let ManagerPackagesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManagerPackages);

ManagerPackagesContainer.defaultProps = {
    productReturn: {},
};

ManagerPackagesContainer.propTypes = {
    productReturn: PropTypes.object.isRequired,
};

export default ManagerPackagesContainer;
