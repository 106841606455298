import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {router, url} from '../../../systems/routing';
import PurchaseAddressList from './PurchaseAddressList'
import PurchaseAddressForm from './PurchaseAddressForm'
import {hasPermission, permissions} from "../../../modules/Permission";
import {setActiveSettingItem} from '../../../modules/Settings/SettingMenu/actions';
import {Divider, Icon, Switch, Col, Row, notification} from "antd";
import apiService from "../../../modules/PurchaseAddress/apiService";
import {processResponse} from "../../../systems/api";
import AddressItem from "./AddressItem";
import FormAddress from "./FormAddress";
import {updateCollectionItem} from "../../../modules/common/helps";
import AgencyBox from "./AgencyBox/";

class PurchaseAddress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false
        };
    }

    componentDidMount() {
        const {t} = this.props;
        this.props.setPageTitle(t('breadcrumb.purchase_address'));
        this.props.setPageDescription(t('purchase_address.manager'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.purchase_address',
                'link': url.to('purchase-address')
            },
        ]);
        this.props.setMenuActive("PurchaseAddress");
        setActiveSettingItem('purchase-address');
        this.fetchPurchaseAddress();
    }

    updateState = (input) => {
        this.setState(state => ({...state, ...input}));
    }

    fetchPurchaseAddress() {
        this.setState({loading: true});
        let res = apiService.getPurchaseAddressList();
        let process = processResponse(res)
            .then(res => {
                this.setState({
                    data: lodash.get(res, "data"),
                    loading: false
                });
            });
    }

    render() {
        let permission = hasPermission(permissions.PURCHASE_ADDRESS_MANAGE);
        let {
            data,
            loading
        } = this.state;
        let {agencies}= this.props;

        return (
            <div className="purchase-address w-100">
                <div className="a-content--box-shadow">
                    <Row>
                        <Col span={12} className="d-flex align-item-center">
                            <p className="a-text--bold a-text--color-black">DANH SÁCH KHO NHẬN ({data.length})</p>
                        </Col>
                        <Col span={12}>
                            <p className="a-text--right">
                                <FormAddress
                                    {...this.props}
                                    permission={permission}
                                    updateState={this.updateState}
                                    data={data}
                                />
                            </p>
                        </Col>
                    </Row>

                    {
                        agencies.length > 0 && data.length > 0 &&
                        <AgencyBox
                            agency={{name: "Chung", code: "chung", alias: "CH", color: "#ccc"}}
                            onUpdateStatusPurchaseAddress={this.props.onUpdateStatusPurchaseAddress}
                            updateState={this.updateState}
                            data={data}
                            purchaseAddress={data.filter(item => item.id_agency === 0)}
                            permission={permission} />
                    }

                    {
                        agencies.length > 0 && data.length > 0 &&
                        agencies.map((agency, index) => {
                            let purchaseAddress = data.filter(item => agency.id === item.id_agency);
                            return (purchaseAddress.length === 0)
                                ? null
                                : <AgencyBox
                                    key={index}
                                    data={data}
                                    agency={agency}
                                    onUpdateStatusPurchaseAddress={this.props.onUpdateStatusPurchaseAddress}
                                    updateState={this.updateState}
                                    purchaseAddress={purchaseAddress}
                                    permission={permission} />;
                        })
                    }
                </div>
            </div>
        );
    }
}

PurchaseAddress.defaultProps = {
    data: [],
    detail: {},
    dataError: {},
};

PurchaseAddress.propTypes = {
    data: PropTypes.array,
    detail: PropTypes.object,
    dataError: PropTypes.object
};

export default translate()(PurchaseAddress);
