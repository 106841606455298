import React from "react";
import PropTypes from "prop-types";
import SearchableComponent from "../../modules/common/SearchableComponent";
import { processResponse } from "../../systems/api";
import apiService from "./apiService";
import { Layout, notification, Table } from "antd";
import { dispatch } from "../../systems/store";
import { setBreadCrumb, setMenuActive, setPageDescription, setPageTitle } from "../../modules/common/actions";
import { translate } from "react-i18next";
import lodash from "lodash";
import ListSale from "./components/ListSale";
import * as Selector from "./selectors";
import FormSearch from "../SaleReport/components/FormSearch";
import FormStats from "./components/FormStats";

const Content = Layout.Content;

class SaleReport extends SearchableComponent {
	constructor(props) {
		super(props);

		this.defaultFilter = {
			code: "",
			status: "",
			id_agency: "",
			id_teller: "",
			seller_source: "",
			order_profit_from: "",
			order_profit_to: "",
			bought_at_from: "",
			bought_at_to: "",
			page: 1,
			per_page: 50,
		};

		this.defaultState = {
			orders: [],
			pagination: {},
			loading: false,
			loadingStats: false,
			listStatus: [],
			stats: {},
		};

		this.state = {
			...this.defaultState,
			csvFilter: { ...this.defaultFilter },
			errors: {},
		};
	}

	componentDidMount() {
		let filter = this.getCurrentFilter();
		super.componentDidMount();
		this.setPageInfo();
		this.fetchSaleReport(filter);
		this.setCSVFilter(filter);
	}

	setPageInfo() {
		let { t } = this.props;
		dispatch(setPageTitle(t("layout.title.sale_report")));
		dispatch(setPageDescription(t("layout.description.sale_report")));
		dispatch(setMenuActive("sale_report"));
		dispatch(
			setBreadCrumb([
				{
					label: "breadcrumb.home",
					link: "/",
				},
				{
					label: "breadcrumb.sale_report",
					link: "/sale-report/",
				},
			])
		);
	}

	onChangeFilter(filter) {
		if (this.state.loading) return;

		this.fetchSaleReport(filter);
		this.fetchStats(filter);
	}

	fetchSaleReport(filter) {
		let { t } = this.props;
		this.setState({ loading: true });
		let process = processResponse(apiService.getPurchaseReport({ ...filter }))
			.then((res) => {
				this.setState({
					loading: false,
					orders: Selector.getOrders(res),
					listStatus: lodash.get(res, "data.listStatus", []),
					pagination: lodash.get(res, "data.pagination", 0),
				});
			})
			.catch((error) => {
				notification.error({ message: t("message.cant_fetch_data") });
			});
	}

	fetchStats = (filter) => {
		let { t } = this.props;
		this.setState({ loadingStats: true });
		const response = processResponse(apiService.statsPurchaseReport(filter));
		response
			.then((res) => {
				this.setState({
					stats: lodash.get(res, "data", {}),
				});
			})
			.catch((err) => {
				notification.error(t("message.cant_fetch_data"));
			})
			.finally(() =>
				this.setState({
					loadingStats: false,
				})
			);
	};

	getCurrentFilter() {
		return {
			...this.getFilterFromLocation(this.props.location),
		};
	}

	setCSVFilter(filter) {
		this.setState((state) => {
			return {
				...state,
				csvFilter: {
					...state.csvFilter,
					...filter,
				},
			};
		});
	}

	onSearch(filter) {
		if (this.state.loading) return;

		this.pushFilter({
			...filter,
			i: parseInt(filter.i || 0, 0) + 1,
		});
	}

	render() {
		let { orders, loading, pagination, stats } = this.state;

		let filter = this.getFilter();

		return (
			<Layout>
				<Content className="a-content--ant-layout sale-report">
					<FormSearch
						input={filter}
						inputVersion={filter.i || 0}
						loading={this.state.loading}
						listStatus={this.state.listStatus}
						onSearch={this.onSearch.bind(this)}
						setCSVFilter={this.setCSVFilter.bind(this)}
					/>

					<FormStats filter={filter} stats={stats} loading={this.state.loadingStats} />

					<ListSale
						orders={orders}
						loading={loading}
						pagination={pagination}
						onSearch={this.onSearch.bind(this)}
						filter={filter}
						csvFilter={this.state.csvFilter}
					/>
				</Content>
			</Layout>
		);
	}
}

export default translate()(SaleReport);
