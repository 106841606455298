import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import lodash from 'lodash'
import { trans } from '../../../systems/i18n'
import PhotoGallery from '../../common/components/PhotoGallery'

const PAYMENT_REQUEST = {
    CREATE: 'PAYMENT_REQUEST.CREATE',
    CHANGE_STATE: 'PAYMENT_REQUEST.CHANGE_STATE',
    CHANGE_NOTE: 'PAYMENT_REQUEST.UPDATE_NOTE',
    UPDATE_AMOUNT: 'PAYMENT_REQUEST.UPDATE_AMOUNT',
    UPDATE_ID_BANK_PAYMENT: 'PAYMENT_REQUEST.UPDATE_ID_BANK_PAYMENT',
    UPDATE_NOTE_TELLER: 'PAYMENT_REQUEST.UPDATE_NOTE_TELLER',
    CHANGE_GROUP: 'PAYMENT_REQUEST.CHANGE_GROUP',
    UPDATE_QR_CODE: 'PAYMENT_REQUEST.UPDATE_QR_CODE',
    DELETE_QR_CODE: 'PAYMENT_REQUEST.DELETE_QR_CODE',
}

class PaymentRequestLogMessage extends React.Component {
    render() {
        let { log } = this.props
        let { payload } = log

        switch (log.action) {
            case PAYMENT_REQUEST.CREATE:
                return trans('paymentRequest:log.CREATE')
            // user: Giao dịch viên
            // account: Tài khoản mua hàng
            case PAYMENT_REQUEST.CHANGE_STATE:
                let message = lodash.get(payload, 'attributes.message')
                if (message === 'payment_failed') {
                    message = `(${trans('paymentRequest:log_reason.payment_failed')})`
                }
                if (message === 'payment_success') {
                    message = `(${trans('paymentRequest:log_reason.payment_success')})`
                }
                let statusOld = lodash.get(payload, 'attributes.statusOld')
                let statusNew = lodash.get(payload, 'attributes.statusNew')
                return trans('paymentRequest:log.CHANGE_STATE', {
                    statusOld: <b>{trans(`paymentRequest:status.${statusOld}`)}</b>,
                    statusNew: <b>{trans(`paymentRequest:status.${statusNew}`)}</b>,
                    message: message,
                })
            // user: Giao dịch viên
            // account: Tài khoản mua hàng
            case PAYMENT_REQUEST.CHANGE_NOTE:
                const noteOld = lodash.get(payload, 'attributes.noteOld')
                const noteNew = lodash.get(payload, 'attributes.noteNew')
                if (!noteNew) return trans('paymentRequest:log.DELETE_NOTE', { noteOld: <b>{noteOld}</b> })
                const logContent = !!noteOld
                    ? trans('paymentRequest:log.UPDATE_NOTE', {
                          noteOld: <b>{noteOld}</b>,
                          noteNew: <b>{noteNew}</b>,
                      })
                    : trans('paymentRequest:log.CREATE_NOTE', {
                          noteNew: <b>{noteNew}</b>,
                      })
                return logContent
            //update amount
            case PAYMENT_REQUEST.UPDATE_AMOUNT:
                let amountOld = lodash.get(payload, 'attributes.amountOld')
                let amountNew = lodash.get(payload, 'attributes.amountNew')
                return trans('paymentRequest:log.UPDATE_AMOUNT', {
                    amountOld: <b>{lodash.ceil(amountOld, 2)}</b>,
                    amountNew: <b>{lodash.ceil(amountNew, 2)}</b>,
                })
            case PAYMENT_REQUEST.UPDATE_ID_BANK_PAYMENT:
                let bankPaymentOld = lodash.get(payload, 'attributes.bankPaymentOld')
                let bankPaymentNew = lodash.get(payload, 'attributes.bankPaymentNew')
                if (bankPaymentOld) {
                    return trans('paymentRequest:log.UPDATE_ID_BANK_PAYMENT', {
                        bankPaymentOld: <b>{bankPaymentOld}</b>,
                        bankPaymentNew: <b>{bankPaymentNew}</b>,
                    })
                } else {
                    return trans('paymentRequest:log.UPDATE_ID_BANK_PAYMENT_NEW', {
                        bankPaymentNew: <b>{bankPaymentNew}</b>,
                    })
                }
            case PAYMENT_REQUEST.UPDATE_NOTE_TELLER:
                const noteTellerOld = lodash.get(payload, 'attributes.noteOld')
                const noteTellerNew = lodash.get(payload, 'attributes.noteNew')
                if (!noteTellerNew) return trans('paymentRequest:log.DELETE_NOTE', { noteOld: <b>{noteTellerOld}</b> })
                return !!noteTellerOld
                    ? trans('paymentRequest:log.UPDATE_NOTE_TELLER', {
                          noteOld: <b>{noteTellerOld}</b>,
                          noteNew: <b>{noteTellerNew}</b>,
                      })
                    : trans('paymentRequest:log.CREATE_NOTE_TELLER', {
                          noteNew: <b>{noteTellerNew}</b>,
                      })
            case PAYMENT_REQUEST.CHANGE_GROUP:
                const groupOld = lodash.get(payload, 'attributes.groupOld')
                const groupNew = lodash.get(payload, 'attributes.groupNew')
                return groupOld
                    ? trans('paymentRequest:log.CHANGE_GROUP', {
                          groupOld: <b>{groupOld}</b>,
                          groupNew: <b>{groupNew}</b>,
                      })
                    : trans('paymentRequest:log.ADD_GROUP', {
                          groupNew: <b>{groupNew}</b>,
                      })
            case PAYMENT_REQUEST.UPDATE_QR_CODE:
                const qrCodeNew = lodash.get(payload, 'attributes.qr_code_url_new', '')
                const qrCodeOld = lodash.get(payload, 'attributes.qr_code_url_old', '')
                if (qrCodeOld) {
                    return trans('paymentRequest:log.UPDATE_QR_CODE', {
                        qrCodeOld: <PhotoGallery images={[qrCodeOld]} />,
                        qrCodeNew: <PhotoGallery images={[qrCodeNew]} />,
                    })
                } else {
                    return trans('paymentRequest:log.ADD_QR_CODE', {
                        qrCodeNew: <PhotoGallery images={[qrCodeNew]} />,
                    })
                }
            case PAYMENT_REQUEST.DELETE_QR_CODE:
                return trans('paymentRequest:log.DELETE_QR_CODE')
            default:
                return log.action
        }
    }
}

PaymentRequestLogMessage.defaultProps = {
    log: {},
}

PaymentRequestLogMessage.propTypes = {
    log: PropTypes.object,
}

export default translate()(PaymentRequestLogMessage)
