import {createSelector} from 'reselect'
import lodash from 'lodash';
import * as GlobalConstant from './../../systems/globalContant'
import {getState} from '../../systems/store';

const sortUsers = users => {
    return Array.isArray(users.items) ? lodash.sortBy(users.items, user => user.username.toLowerCase()) :
        lodash.sortBy(users, user => user.username.toLowerCase());
};

const customerState = state => lodash.get(state, "User.me", {});

export const getUsers = state => sortUsers(state.User.users);
export const getAllUser = state => lodash.sortBy(lodash.get(state, 'User.users', []), user => user.username.toLowerCase());

// get me
export const getMeSelector = createSelector(
    customerState,
    me => me
);

export const getUserSelect = createSelector(
    customerState,
    user => user.user
);

// get list users
export const purchasersSelector = state => sortUsers(lodash.get(state, "User.purchasers", []).filter(user => user.is_teller === true));

/*
 * Lấy danh sách GDV
 */
export const getPurchasers = createSelector(
    getUsers,
    users => users.filter(user => user.is_teller)
);

export const findUser = (id) => lodash.find(getUsers(getState()), {id});

export const purchaserOption = createSelector(
    purchasersSelector,
    purchasers => purchasers.map(purchaser => {
        let label = purchaser.username + (purchaser.name ? ' - ' + purchaser.name : '');
        return {value: purchaser.id, label: label};
    })
);

export const getChannelOrder = createSelector(
    getUserSelect,
    user => [getAssignOrderChannelByUser(user), getUnassignOrderChannelByUser(user), GlobalConstant.CHANNELS.ORDER_READ_COMMENT, GlobalConstant.CHANNELS.ORDER_SYNC_BILL_NO]
);

const getAssignOrderChannelByUser = (user) => {
    return (user) ?
        GlobalConstant.CHANNEL_ASSIGN_TELLER + user.id : null;
};

const getUnassignOrderChannelByUser = (user) => {
    return (user) ?
        GlobalConstant.CHANNEL_UNASSIGN_TELLER + user.id : null;
};

export const getCreateOrderChannel = createSelector(
    getUserSelect,
    user => {
        return (user) ?
            [GlobalConstant.CHANNELS.ORDER_CREATE_NEW + "_" + user.id_partner] : [];
    }
);

/**
 * function check xem việc user đg đăng nhập có setting sử dụng việc nhân đơn theo kho pp hay không,
 * mặc định là không sử dụng
 */
export const getDistributiveWarehouseSetting = createSelector(
    getUserSelect,
    user => {
        let isUsing = false;
        if (user) {
            let settings = lodash.get(user, "settings", []);
            isUsing = true;
            settings.map(setting => {
                if (isUsing && setting.code === GlobalConstant.SETTING_CODE.DISTRIBUTIVE_WAREHOUSE_USING && parseInt(setting.value, 10) === 0) {
                    isUsing = false;
                }

                if (isUsing && setting.code === GlobalConstant.SETTING_CODE.DISTRIBUTIVE_WAREHOUSE_REQUIRED_ORDER && parseInt(setting.value, 10) === 0) {
                    isUsing = false;
                }

                return setting;
            })
        }

        return isUsing;
    }
);

/**
 * function check xem việc user đg đăng nhập có setting được tự nhận đơn hay không,
 * mặc định là không sử dụng
 */
export const getOrderDistributorUsingSetting = createSelector(
    getUserSelect,
    user => {
        let isUsing = false;
        if (user) {
            let settings = lodash.get(user, "settings", []);
            isUsing = true;
            settings.map(setting => {
                if (isUsing && setting.code === GlobalConstant.SETTING_CODE.ORDER_DISTRIBUTOR_USING && parseInt(setting.value, 10) === 0) {
                    isUsing = false;
                }

                return setting;
            })
        }

        return isUsing;
    }
);
