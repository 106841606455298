import React from 'react';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {withEvent} from '../../../systems/events';
import {processResponse} from '../../../systems/api';
import notification from '../../../systems/notification';
import apiService from '../../../modules/User/apiService';
import SearchableComponent from '../../../modules/common/SearchableComponent';
import FormSearch from './FormSearch';
import ListTellers from './ListTellers';
import {dispatch} from "../../../systems/store";
import ACCOUNT_PURCHASER from "../../../modules/AccountPurchaser/events";
import {Layout} from "antd";
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from '../../../modules/common/actions';
import {url} from "../../../systems/routing";
import {setActiveSettingItem} from '../../../modules/Settings/SettingMenu/actions';

const Content = Layout.Content;

class AssigningAccountPurchaser extends SearchableComponent {
    constructor(props) {
        super(props);

        this.state = {
            tellers: [],
            pagination: {},
            loading: false,
        };
    }

    onSearch(filter) {
        if (this.state.loading) {
            return;
        }

        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        });
    }

    onChangeFilter(filter) {
        if (this.state.loading) {
            return;
        }

        let {t} = this.props;

        this.setState({loading: true});

        let res = apiService.getTellersList(filter)
            .finally(() => this.setState({loading: false}));

        let process = processResponse(res);

        process.then(res => {
            this.setState({
                tellers: lodash.get(res, 'data') || [],
                pagination: lodash.get(res, 'data.pagination') || {},
            });
        });

        process.catch(() => {
            notification.error(t('message.cant_fetch_data'));
        });
    }

    getFilter() {
        return {
            ...this.getFilterFromLocation(this.props.location),
        };
    }
    componentDidMount() {
        let {t} = this.props;
        super.componentDidMount();
        this.props.on({
            [ACCOUNT_PURCHASER.UPDATED]: (event, payload) =>
                this.setState({
                    ...this.state,
                    tellers: payload.tellers
                }),
            [ACCOUNT_PURCHASER.CREATED]: () => {
                this.onChangeFilter(this.getFilter())
            }
        });
        dispatch(setPageTitle(t('breadcrumb.purchaser')));
        dispatch(setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.purchaser',
                'link': url.to('purchaser.assign_account_purchaser')
            },
        ]));
        dispatch(setPageDescription(t('purchaser:label.list_purchaser')));
        dispatch(setMenuActive('purchaser_account_purchaser'));
        setActiveSettingItem('purchaser_account_purchaser');
    }
    render() {
        let filter = this.getFilter();

        return (
            <Layout>
                <Content className="a-content--ant-layout">
                    <FormSearch
                        input={filter}
                        inputVersion={filter.i || 0}
                        loading={this.state.loading}
                        onSearch={this.onSearch.bind(this)}
                    />
                    <ListTellers
                        tellers={this.state.tellers}
                        t={this.props.t}
                        pagination={this.state.pagination}
                        filter={filter}
                        loading={this.state.loading}
                        onSearch={this.onSearch.bind(this)}
                    />
                </Content>
            </Layout>
        );
    }
}

export default translate()(withEvent(AssigningAccountPurchaser));


