import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import Button from '@atlaskit/button';
import ModalGeneral from './../../../../common/components/ModalGeneral';

class OutOfStock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};

        if (prevState.isOpenModal === true && nextProps.isModalProcessing === false) {
            nextState.isOpenModal = false;
        }
        return nextState;
    }

    /**
     * handle action call CANCEL api
     * @param event
     */
    onOutOfStock(event) {
        let {id} = this.props;
        this.setState({isOpenModal: false});
        this.props.onOutOfStock({id});
    }

    /**
     * handle action open popup
     * @param event
     */
    onOpenPopupConfirm(canUpdateOutOfStock, event) {
        if (canUpdateOutOfStock) {
            this.setState({isOpenModal: true});
            this.props.beginProcessOutOfStock();
        }
    }

    /**
     * handle action close popup
     * @param event
     */
    onClose(event) {
        this.setState({isOpenModal: false});
    }

    render() {
        let {t, canUpdateOutOfStock, code} = this.props;
        let {isOpenModal} = this.state;
        return (
            <React.Fragment>
                {
                <a className={`a-text--font-primary ${canUpdateOutOfStock? "":'disable'}`}
                   onClick={this.onOpenPopupConfirm.bind(this, canUpdateOutOfStock)}>
                    {t('orderDetail:order_detail.out_of_stock_order_label')}
                </a>}
                {isOpenModal ?
                    <ModalGeneral>
                        <div className={"a-modal__title"}>
                            <p>{t("orderDetail:order_detail.out_of_stock_popup.title")} </p>
                        </div>
                        <div style={{wordBreak: "break-all"}}>
                            {t("orderDetail:order_detail.out_of_stock_popup.message")} <b>#{code}</b>
                        </div>
                        <div className={"a--group--inline-end p-2 a--user-group-btn "}>
                            <Button
                                appearance="primary" type="submit" className={" mr-3 a-btn--submit"}
                                onClick={this.onOutOfStock.bind(this)}
                                isLoading={this.props.isLoadingOutOfStockOrder}
                            >
                                {t('orderDetail:order_detail.out_of_stock_popup.btn_submit')}
                            </Button>
                            <a
                                appearance="subtle"
                                onClick={this.onClose.bind(this)}
                                className={this.props.isLoadingOutOfStockOrder ? "hidden" : "a-btn a-btn--none"}
                            >
                                {t('orderDetail:order_detail.out_of_stock_popup.btn_cancel')}
                            </a>
                        </div>
                    </ModalGeneral>
                    : null
                }
            </React.Fragment>
        );
    }
}

OutOfStock.defaultProps = {
    canUpdateOutOfStock: false,
    onOutOfStock: () => {
    },
    id: 0,
    isModalProcessing: false,
    isLoadingOutOfStockOrder: false,
};

OutOfStock.propTypes = {
    canUpdateOutOfStock: PropTypes.bool,
    isModalProcessing: PropTypes.bool,
    onOutOfStock: PropTypes.func,
    id: PropTypes.number,
    isLoadingOutOfStockOrder: PropTypes.bool,
    beginProcessOutOfStock: PropTypes.func,
    code: PropTypes.string
};

export default translate('translations')(OutOfStock);
