import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import OrderItemComment from "../../../PurchaseBuying/OrderDetail/components/OrderItem/OrderItemComment";
import Spinner from '@atlaskit/spinner';
import {Row, Col, Input, notification} from "antd";
import {currentFormatter} from "../../../common/services/format";
import apiService from "../../../LogItem/apiService";
import {processResponse} from "../../../../systems/api";

class OrderItemProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            loading: false
        }
    }

    onChange = e => {
        this.setState({
            comment: e.target.value
        });
    }

    addComment = (e) => {
        let {item, addCommentProduct} = this.props;
        let {comment} = this.state;
        if (e.target.value.trim() !== "") {
            this.setState({loading: true});
            let process = processResponse(apiService.postItemLog({id: item.id, message: comment}));

            process.then(res => {
                addCommentProduct(res);
                this.setState({
                    comment: "",
                    loading: false
                });
            });

            process.catch(error => {
                notification.error({message: "Thêm ghi chú cho sản phẩm không thành công"})
            });
        }
    }

    /**
     * generate the errors while post comment
     * @returns {*}
     */
    generateError() {
        // let {errorCommentItem, item, t} = this.props;
        // if (typeof errorCommentItem[item.id] !== "undefined") {
        //     let errorItem = lodash.get(errorCommentItem[item.id], "message", {});
        //     if (!lodash.isEmpty(errorItem)) {
        //         switch (Object.keys(errorItem)[0]) {
        //             case 'required':
        //                 return t('orderDetail:order_detail.error.' + Object.keys(errorItem)[0], {name: t('orderDetail:order_detail.comment')});
        //             case 'unique':
        //                 return t('orderDetail:order_detail.error.' + Object.keys(errorItem)[0], {name: t('orderDetail:order_detail.comment')});
        //             case 'max.string':
        //                 let objectmax = lodash.get(errorItem, 'max.string', null);
        //                 let overLength = lodash.get(objectmax, 'max', null);
        //                 return t('orderDetail:order_detail.error.max_string', {
        //                     name: t('orderDetail:order_detail.comment'),
        //                     value: overLength
        //                 });
        //             case 'min.string':
        //                 let objectmin = lodash.get(errorItem, 'min.string', null);
        //                 let length = lodash.get(objectmin, 'min', null);
        //                 return t('orderDetail:order_detail.error.min_string', {
        //                     name: t('orderDetail:order_detail.comment'),
        //                     value: length
        //                 });
        //             case "status_invalid":
        //                 return t('orderDetail:order_detail.error.status_invalid');
        //             default:
        //                 return null;
        //         }
        //     }
        // }

        return null;
    }

    render() {
        let {t, item, order} = this.props;
        const items = lodash.get(order, "items", []);
        let {comment, loading} = this.state;
        let error = this.generateError(),
            link_text = (item.affiliate_link) ? item.affiliate_link : item.link;
        let currencySymbol = lodash.get(order, "currencySymbol", '¥');
        const partner_order_item_id = lodash.get(item, 'partner_order_item_id', null);
        const listBy = lodash.get(item, "list_by", undefined);
        let highlight = false;
        if (listBy && partner_order_item_id && lodash.find(items, {'partner_order_item_id': partner_order_item_id})) {
            highlight = true;
        }
        return (
            <div style={{background: `${highlight ? "#ff45002e" : ""}`}}>
                <Row id={lodash.get(item, 'partner_order_item_id', null)} className="mb-3 mt-3">
                    <Col span={2} className="a-purchasod__table__order__infos__left">
                        <a href={link_text} target="_blank"><img src={item.image} alt="Avatar"/></a>
                    </Col>
                    <Col span={22}>
                        <Row>
                            <Col span={12}>
                        <span className="a--cursor-poiter ml-3 a-text--bold a-text--font-blue">
                                    #{partner_order_item_id}
                                </span>
                            </Col>
                            <Col span={6}>
                                {item.qty} <span>/</span> {item.qty_bought} <span>/</span> {item.qty_received}
                            </Col>
                            <Col span={6}>
                                <div className="d-flex mb-2 align-item-center">
                                    <span>{currencySymbol}{currentFormatter.toLocaleStringCurrency(parseFloat(item.unit_price), 2)}</span>
                                    <span className="ml-1 mr-1"> / </span>
                                    <span>{currencySymbol}{currentFormatter.toLocaleStringCurrency(parseFloat(item.total), 2)}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className="d-flex justify-content-between">
                                <div>

                                    {
                                        item.voucher_link ?
                                            <div className="w-100 d-flex justify-content-end">
                                                <a target="_blank" href={item.voucher_link}
                                                   className='order-item-link a-text--uppercase'
                                                   style={{textDecoration: 'none'}}>
                                                    <i className="fas fa-gift mr-1"></i>
                                                    {t('orderDetail:order_detail.get_voucher_link')}
                                                </a>
                                            </div> : ''
                                    }
                                </div>
                            </div>
                            <div className="mt-1">
                                <p className="ml-3">
                                    <a
                                        target="_blank"
                                        href={link_text}
                                        data-source={order.seller_source}
                                        data-item-id={item.item_id}
                                        className={`order-item-link order-item-link_` + item.item_id}>
                                        {item.title}
                                    </a>
                                </p>
                                {
                                    item.category_name ?
                                        <p className="ml-3">{t('order_detail.category_of_goods')}: <span>{item.category_name}</span>
                                        </p> :
                                        null
                                }
                                <p className="ml-3">
                                    Mẫu: <span className="a-text--font-primary a-text--break-all">{item.option}</span>
                                </p>
                            </div>
                        </Row>
                    </Col>
                </Row>
                {
                    !listBy &&
                    <Row>
                        <div className="">
                            {
                                order.canOrderItemComment === true &&
                                <Input
                                    type="text"
                                    className="input__chat_product"
                                    onPressEnter={this.addComment}
                                    onChange={this.onChange}
                                    placeholder={t("order_detail.internal_chat_about_the_product")}
                                    value={comment}
                                />
                            }
                            <span className={`ml-2 pt-1 ${loading ? '' : 'hidden'}`}><Spinner size="small"
                                                                                              isCompleting={false}/></span>
                            <span className={error === null ? "hidden" : "a-text--red a-font--10 mt-1"}>{error}</span>
                            <OrderItemComment item={item}/>
                        </div>
                    </Row>
                }
            </div>
        );
    }
}

OrderItemProduct.defaultProps = {
    order: {},
    items: {}
};

OrderItemProduct.propTypes = {
    order: PropTypes.object,
    item: PropTypes.object
};

export default translate('translations')(OrderItemProduct);
