export const COMPLAINT_SELLER_REJECT = {
    REQUEST: 'COMPLAINT_SELLER_REJECT_REQUEST',
    SUCCESS: 'COMPLAINT_SELLER_REJECT_SUCCESS',
    FAILED: 'COMPLAINT_SELLER_REJECT_FAILED',
    ERROR: 'COMPLAINT_SELLER_REJECT_ERROR',
};
export const COMPLAINT_SELLER_REASON_UPDATE = {
    SUCCESS: 'COMPLAINT_SELLER_REASON_UPDATE_SUCCESS',
    FAILED: 'COMPLAINT_SELLER_REASON_UPDATE_FAILED',
};

export const COMPLAINT_SELLER = {
    RESET_FILTER: 'COMPLAINT_SELLER.RESET_FILTER'
};
