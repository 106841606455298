import React from "react";
import "./style.scss";
import {connect} from "react-redux";
import {setBreadCrumb, setMenuActive, setPageDescription, setPageTitle} from "../../modules/common/actions";
import SlowPurchasingReason from "./components";

class SlowPurchasingReasonContainer extends React.Component {

    render() {
        return <SlowPurchasingReason {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SlowPurchasingReasonContainer);