import React from "react";
import PropTypes from "prop-types";
import {translate} from "react-i18next";
import {Affix, Button, Divider, Empty, Modal, Table, Icon, Tag, Tooltip} from "antd";
import PaginationFull from "../../modules/common/components/PaginationFull";
import lodash from "lodash";
import {url} from '../../systems/routing';
import {dateFormatter} from "../../modules/common/services/format";
import SettingColumns from "../../modules/Settings/SettingColumns";
import ProcessPaymentRequest from "../../modules/PaymentRequest/ProcessingPaymentRequest/ProcessPaymentRequest";
import PaymentRequestLogs from "../../modules/PaymentRequest/PaymentRequestLogs/PaymentRequestLogs";
import ExportCSV from './ExportCSV';
import NotePaymentRequest from "./components/NotePaymentRequest";
import EditAmountPaymentRequest from "../../modules/PaymentRequest/EditAmountPaymentRequest";
import ChangeAccountBankPayment from "../../modules/PaymentRequest/ChangeAccountBankPayment";
import NoteOfCreatorPaymentRequest from "./components/NoteOfCreatorPaymentRequest";
import CanExport from "../../modules/PurchaseBuying/OrderDetail/components/CanExport/CanExport";
import {processResponse} from "../../systems/api";
import apiService from "../../modules/PaymentRequest/apiService";
import notification from "../../systems/notification";
import {events, withEvent} from "../../systems/events";
import PhotoGallery from "../../modules/common/components/PhotoGallery";
import ProcessPaymentsRequestAuto from "../../modules/PaymentRequest/ProcessPaymentsRequestAuto";
import PAYMENT_REQUEST_EVENT from "../../modules/PaymentRequest/events";
import ReactFileReader from "react-file-reader";
import ChangeQrcodeImage from "../../modules/PaymentRequest/ChangeQrcodeImage";

const COLUMN = {
    REQUEST_TIME: 'request_time',
    ID: 'id',
    // SELLER_SOURCE: 'seller_source',
    INVOICE_CODE: 'invoice_code',
    INVOICE_CODE_AMOUNT: 'invoice_code_amount',
    CUSTOMER: 'customer',
    ORDER_CODE: 'order_code',
    ACCOUNT_PAYMENT: 'account_payment',
    ACCOUNT_BENEFICIARY: 'account_beneficiary',
    ACCOUNT_PURCHASER_TOOL: 'accountPurchaser',
    AMOUNT: 'amount',
    CREATOR: 'creator',
    APPROVED_BY: 'approved_by',
    RECEIVER: 'receiver',
    LAST_RECEIVER: 'last_receiver',
    CONFIRM_IMAGES: 'confirm_images',
    CONFIRM_NOTE: 'confirm_note',
    TRANSACTION_CODES: 'transactionCodes',
    QRCODE: 'qrcode',
    ACTION: 'action',
    LOG: 'log'
};

class PaymentRequestList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            request_id: 0,
            selectedColumns: [],
            isProcessing: false,
            selectedRowKeys: [],
            idPaymentNoting: null,
            processingRequestId: undefined, // YCTT đang được xử lý
            failedRequestIds: [], // YCTT xử lý lỗi
            successRequestIds: [],// YCTT xử lý  thành công
            isReceiving: false
        };
        this.pageTotalReduced = false;
        this.handleUpdateIdPaymentNoting = this.handleUpdateIdPaymentNoting.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {paymentRequests} = this.props;
        const {paymentRequests: oldPaymentRequests} = prevProps;
        if (JSON.stringify(oldPaymentRequests) !== JSON.stringify(paymentRequests)) {
            this.setState({
                failedRequestIds: lodash.get(this.listPaymentRequestListAllowAutoProcess(), "failedIds", []),
            });
        }
    }

    // Gán YCTT đang được process
    setProcessingRequestId(request_id) {
        this.setState({processingRequestId: request_id});
    }

    // Thêm YCTT process lỗi
    pushFailedRequestIds(request_id) {
        let {failedRequestIds} = this.state;
        failedRequestIds.push(request_id);
        this.setState({failedRequestIds});
    }

    // Thêm YCTT thành công
    pushSuccessRequestIds(request_id) {
        let {successRequestIds} = this.state;
        successRequestIds.push(request_id);
        this.setState({successRequestIds});
    }

    // Thiết lập trạng thái đang xử lý tự động YCTT
    setIsProcessing(status, callback = () => {
    }) {
        this.setState({isProcessing: status}, callback);
    }

    handleUpdateIdPaymentNoting(id) {
        this.setState({idPaymentNoting: id});
    }

    onChangePage(page, pageSize) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            });
        }
    }

    getSettingColumns() {
        return this.getColumns()
            .filter(column => !lodash.includes([COLUMN.ACTION, COLUMN.LOG, COLUMN.INVOICE_CODE_AMOUNT], column.key))
            .map(column => ({
                key: column.key,
                title: column.title,
                selected: column.selected,
                disabled: column.disabled,
            }));
    }

    rowSelection = {
        onChange: (selectedRowKeys) => {
            this.setState({selectedRowKeys})
        },
    };

    getColumns() {
        let {t} = this.props;

        let columns = [
            {
                key: COLUMN.ID,
                selected: false,
                disabled: false,
                className: "_payment-request-column-id"
            },
            {
                key: COLUMN.REQUEST_TIME,
                selected: true,
                className: "_payment-request-column-request-time"
            },
            {
                key: COLUMN.INVOICE_CODE,
                selected: true,
                disabled: true,
                className: "_payment-request-column-invoice-code"
            },
            {
                key: COLUMN.INVOICE_CODE_AMOUNT,
                selected: true,
                className: 'a-text--right a-text--nowrap _payment-request-column-invoice-code-amount'
            },
            {
                key: COLUMN.CUSTOMER,
                selected: true,
                className: "_payment-request-column-customer"
            },
            {
                key: COLUMN.ORDER_CODE,
                selected: true,
                className: "_payment-request-column-order-code"
            },
            {
                key: COLUMN.ACCOUNT_PAYMENT,
                selected: true,
                disabled: true,
                className: "_payment-request-column-account-payment"
            },
            {
                key: COLUMN.ACCOUNT_PURCHASER_TOOL,
                selected: true,
                disabled: true,
                className: "_payment-request-column-purchase-tool"
            },
            {
                key: COLUMN.ACCOUNT_BENEFICIARY,
                selected: false,
                disabled: false,
                className: "_payment-request-column-account-beneficiary"
            },
            {
                key: COLUMN.QRCODE,
                selected: false,
                disabled: false,
                className: "_payment-request-column-qrcode"
            },
            {
                key: COLUMN.AMOUNT,
                selected: true,
                className: 'a-text--right _payment-request-column-amount',
            },
            {
                key: COLUMN.CREATOR,
                selected: true,
                className: "_payment-request-column-creator"
            },
            {
                key: COLUMN.APPROVED_BY,
                selected: true,
                className: "_payment-request-column-approved-by"
            },
            {
                key: COLUMN.RECEIVER,
                selected: true,
                className: "_payment-request-column-receiver"
            },
            {
                key: COLUMN.LAST_RECEIVER,
                selected: true,
                className: "_payment-request-column-last-receiver"
            },
            {
                key: COLUMN.CONFIRM_NOTE,
                selected: true,
                className: "_payment-request-column-confirm-note"
            },
            {
                key: COLUMN.CONFIRM_IMAGES,
                selected: true,
                className: "_payment-request-column-confirm-images"
            },
            {
                key: COLUMN.TRANSACTION_CODES,
                selected: true,
                className: "_payment-request-column-transaction-codes"
            },
            {
                key: COLUMN.ACTION,
                selected: true,
                title: '',
                className: "_payment-request-column-action"
            },
            {
                key: COLUMN.LOG,
                selected: true,
                title: '',
                className: "_payment-request-column-log"
            },
        ];
        return columns.map(column => ({
            dataIndex: column.key,
            title: t('paymentRequest:column.' + column.key),
            ...column,
        }));
    }

    getTableColumns() {
        return this.getColumns().filter(column => {
            return lodash.includes([COLUMN.ACTION, COLUMN.LOG, COLUMN.INVOICE_CODE_AMOUNT], column.key) || this.state.selectedColumns.includes(column.key);
        });
    }

    onClickShowLog(requestId) {
        this.setState({
            visible: true,
            request_id: requestId
        });
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            request_id: 0
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
            request_id: 0
        });
    };

    getDataSource() {
        let {t, paymentRequests, user, groups, filter, loading} = this.props;
        const currentStatusTab = lodash.pick(filter, ['status']);
        const {idPaymentNoting, successRequestIds, processingRequestId, failedRequestIds} = this.state;
        let _t = this;

        return paymentRequests.map(request => {
            let orderCode = lodash.get(request, 'order.code', '');
            let orderDetailInfo = lodash.get(request, "order.orderDetail", {})
            const self_negotiate = lodash.get(orderDetailInfo, "self_negotiate", false)
            let orders = lodash.get(request, 'orders', []);
            const is_order_sb = lodash.get(request, "is_order_sb", false);
            let invoiceCodes = lodash.get(request, 'invoiceCodes', []);
            let currencySymbol = lodash.get(request.order, "currencySymbol",
                (lodash.get(lodash.head(orders), "currencySymbol",
                    lodash.get(lodash.head(invoiceCodes), "order.currencySymbol", "¥")
                ))
            );
            const sellerSource = lodash.get(request, 'order.seller_source', lodash.get(request, 'invoiceCodes.[0].order.seller_source', ''));
            return {
                key: request.id,
                warning_other_account: request.warning_other_account,
                in_payment_request_success: invoiceCodes.filter(record => !!record.in_payment_request_success),
                seller_source: sellerSource,
                [COLUMN.ID]: <div className="d-flex">
                    <span>{"#" + request.id}</span>
                    {
                        currentStatusTab.status === "PROCESSING" && !loading && !!filter.external_order && sellerSource === '1688' &&
                        <span className="ml-2">
                            {
                                (processingRequestId !== request.id && failedRequestIds.includes(request.id)) ?
                                    <Icon type="warning" className="a-text--red"/> :
                                    (processingRequestId !== request.id && successRequestIds.includes(request.id)) ?
                                        <Icon type="check" className="a-text--color-green"/> :
                                        <Icon type="sync"
                                              spin={processingRequestId === request.id}
                                              className={processingRequestId !== request.id ? "a-text--color-black-30" : ""}
                                        />
                            }
                        </span>
                    }
                </div>,
                [COLUMN.REQUEST_TIME]: <div className="text-nowrap">{dateFormatter.dateTime(request.created_at)}</div>,
                [COLUMN.INVOICE_CODE]:
                    request.external_order === 1 ?
                        <>
                            {/*{t('paymentRequest:label.external_order')}*/}
                            {
                                request.invoiceCodes.map((invoice, index) => (
                                    <span key={request.id + index} className="a-form__group__value mb-1">
                                        <a href={lodash.get(invoice, 'link_invoice_code')} target={"_blank"}
                                           className="a-text">{lodash.get(invoice, 'invoice_code', '')}</a>
                                    </span>
                                ))
                            }
                        </> :
                        request.invoiceCodes.map((invoice, index) => (
                            <span key={request.id + index} className="a-form__group__value mb-1">
                                <a href={lodash.get(invoice, 'link_invoice_code')} target={"_blank"}
                                   className="a-text">{lodash.get(invoice, 'invoice_code', '')}</a>
                            </span>
                        )),
                [COLUMN.INVOICE_CODE_AMOUNT]:
                    request.external_order === 1 ? "" : request.invoiceCodes.map((invoice, index) => {
                        return <span key={request.id + index} className="a-form__group__value mb-1">
                            {(invoice.amount && lodash.ceil(lodash.get(invoice, 'amount'), 2) > 0) ? `${currencySymbol + lodash.ceil(lodash.get(invoice, 'amount'), 2)}` : "--"}
                        </span>

                    }),
                [COLUMN.CUSTOMER]:
                    request.external_order === 1 && request.order ?
                        <a href={lodash.get(request, 'order.partner_url_user_detail', '#')}
                           target={request.order.partner_url_user_detail && '_blank'}>
                            {lodash.get(request, 'order.buyer_name', '--')}({lodash.get(request, 'order.buyer_username', '--')})
                        </a> : '--',
                [COLUMN.ORDER_CODE]: <div className="d-flex align-items-center">
                    <div>
                        {(orderCode || orders.length > 0) ?
                            <>
                                {
                                    orders.length > 0 ?
                                        <>
                                            {
                                                orders.map((item, index) => {
                                                    const orderDetail = lodash.get(item, "orderDetail", {})
                                                    const self_negotiate = lodash.get(orderDetail, "self_negotiate", false)
                                                    return (
                                                        <p key={index}>
                                                            <a
                                                                href={url.to('purchase.buying.detail', {id: item.code})}
                                                                target={"_blank"} className="a-text mr-2">{item.code}</a>
                                                            {
                                                                !!self_negotiate && 
                                                                <Tooltip title={t("orderDetail:tooltip.self_negotiate")}>
                                                                    <Tag color="#82e910">Deal</Tag>
                                                                </Tooltip>
                                                            }
                                                        </p>
                                                    );
                                                })
                                            }
                                        </> :
                                        <>
                                            <a href={url.to('purchase.buying.detail', {id: orderCode})} target={"_blank"}
                                            className="a-text">
                                                {orderCode}
                                            </a>
                                            {
                                                !!self_negotiate && 
                                                <Tooltip title={t("orderDetail:tooltip.self_negotiate")}>
                                                    <Tag color="#82e910">Deal</Tag>
                                                </Tooltip>
                                            }
                                        </>
                                }
                            </> :
                            invoiceCodes.map((invoice, index) => {
                                let orderDetail = lodash.get(invoice, "order.orderDetail", null);
                                let can_exported = lodash.get(orderDetail, "can_exported", null);
                                const self_negotiate = lodash.get(orderDetail, "self_negotiate", false)
                                return (
                                    <span key={index} className="a-form__group__value mb-1">
                                    <a href={url.to('purchase.buying.detail', {id: lodash.get(invoice, 'order.code', '')})}
                                       target={"_blank"}
                                       className="a-text">{lodash.get(invoice, 'order.code', '')}</a>
                                    <CanExport
                                        can={can_exported}
                                        type="order"
                                        className="mr-2"
                                    />
                                    {
                                        !!self_negotiate && 
                                        <Tooltip title={t("orderDetail:tooltip.self_negotiate")}>
                                            <Tag color="#82e910">Deal</Tag>
                                        </Tooltip>
                                    }
                                </span>
                                );
                            })
                        }
                    </div>
                    <div>
                        {
                            is_order_sb && <Tag color="#f50" className="ml-1">TBG</Tag>
                        }
                    </div>
                </div>,
                [COLUMN.ACCOUNT_PAYMENT]: <ChangeAccountBankPayment request={request} user={user}/>,
                [COLUMN.ACCOUNT_PURCHASER_TOOL]: <span>{lodash.get(request, 'accountPurchaser.account', '')}</span>,
                [COLUMN.ACCOUNT_BENEFICIARY]: <div>
                    <span className="a-form__group__value">{request.bank_account}</span>
                    <span className="a-form__group__value">{request.branch_name}</span>
                    <span className="a-form__group__value">{request.receiver_name}</span>
                </div>, // old logic = lodash.get(request, 'accountPurchaser.account', '')
                [COLUMN.QRCODE]: <ChangeQrcodeImage request={request}/>,
                [COLUMN.AMOUNT]: <EditAmountPaymentRequest request={request} user={user}
                                                           currencySymbol={currencySymbol}/>,
                [COLUMN.CREATOR]: (
                    <div>
                        <span>{lodash.get(request, 'senderRequest.name', '')}({lodash.get(request, 'senderRequest.username', '')})</span>
                        <NoteOfCreatorPaymentRequest
                            t={t}
                            request={request}
                        />
                    </div>
                ),
                [COLUMN.APPROVED_BY]: request.confirmer ?
                    <span>{lodash.get(request, 'confirmer.name', '')}({lodash.get(request, 'confirmer.username', '')})</span> : null,
                [COLUMN.RECEIVER]: (
                    <div className="text-nowrap">
                        {request.receiver ? (
                            <span>
								{lodash.get(request, 'receiver.name', '')}(
                                {lodash.get(request, 'receiver.username', '')})
							</span>
                        ) : null}
                        <NotePaymentRequest
                            t={t}
                            id={request.id}
                            note={request.note}
                            updateIdPaymentNoting={this.handleUpdateIdPaymentNoting}
                            idPaymentNoting={idPaymentNoting}
                            canUpdateNote={request.canUpdateNote}
                        />
                    </div>
                ),
                [COLUMN.LAST_RECEIVER]: request.lastReceiver ?
                    <span>
                        {lodash.get(request, 'lastReceiver.name', '')}({lodash.get(request, 'lastReceiver.username', '')})
                        {
                            request.status === "SUCCESS" && request.success_from === "tool" && <Icon type="tool" />
                        }
                    </span> : null,
                [COLUMN.CONFIRM_NOTE]: request.confirm_note,
                [COLUMN.CONFIRM_IMAGES]: <PhotoGallery images={lodash.get(request, "confirm_images", [])}/>,
                [COLUMN.TRANSACTION_CODES]: request.transactionCodes ? lodash.get(request, "transactionCodes", []).map((transaction, index) =>
                    <p key={index}>{transaction.transaction_code}</p>) : null,
                [COLUMN.ACTION]: <ProcessPaymentRequest request={request} user={user} groups={groups}/>,
                [COLUMN.LOG]:
                    <React.Fragment>
                        <span className="a--cursor-poiter d-flex flex-row justify-content-center">
                            {
                                // request.status === 'PROCESSING' && request.external_order === 0
                                ['PROCESSING', 'SUCCESS'].includes(request.status) ?
                                    <a href={request.link} target="_blank"
                                       onClick={() => this.onClickPaymentLink(request)} className="ml-2"><Icon
                                        style={{fontSize: '18px'}} type="link" title={"Link thanh toán"}/></a> : null
                            }
                            {
                                <a onClick={() => {
                                    _t.onClickShowLog(request.id)
                                }} className="a-text--pointer ml-2"><Icon type="message"/></a>
                            }


                        </span>
                    </React.Fragment>
            };
        });
    }

    /**
     * Bắn sự kiện tới Content của công cụ khi quỹ click vào link thanh toán hộ
     * Bỏ phần này sẽ không bắn sự kiện xuống cho công cụ được
     */
    onClickPaymentLink(request) {
        let {link, status, invoiceCodes} = request;
        if (status === "PROCESSING" && link.indexOf("shenghuo.alipay.com") > -1 && invoiceCodes.length > 0) {
            window.postMessage({
                type: "ALERT_MONEY_IS_WRONG.INVOICE_CODES",
                invoice_codes: invoiceCodes.map(invoice => {
                    return {invoice_code: invoice.invoice_code, amount: invoice.amount || 0};
                })
            });
        }
    }

    onNextPage() {
        const {failedRequestIds} = this.state;
        const {pagination: {current_page, per_page}} = this.props;
        if (!this.props.loading) {
            let page = 1;
            if (failedRequestIds.length >= per_page) {
                page = current_page + 1;
            }
            this.props.onSearch({
                ...this.props.filter,
                page
            })
        }
    }

    listPaymentRequestListAllowAutoProcess() {
        const requestsAllow = [];
        const failedIds = [];
        this.props.paymentRequests.map(item => {
            const sellerSource = lodash.get(item, 'order.seller_source', lodash.get(item, 'invoiceCodes.[0].order.seller_source', ''));
            if (sellerSource === "1688") {
                requestsAllow.push(item);
            } else {
                failedIds.push(item.id);
            }
        });
        return {requestsAllow, failedIds};
    }

    handleReceivedMultiPaymentRequest() {
        const {selectedRowKeys} = this.state;
        const {t, filter} = this.props;
        this.setState({
            isReceiving: true
        })
        const process = processResponse(apiService.receiveManyRequest({ids: selectedRowKeys}));
        process.then((res) => {
            this.props.onSearch({
                ...this.props.filter,
                i: parseInt(filter.i || 0, 0) + 1,
            });
            notification.success(t(`paymentRequest:message.CHANGED_STATE_PROCESSING`));

        }).catch(err => {
            console.log("err", err);
            notification.error("Có lỗi xảy ra trong quá trình tiếp nhận")
        }).finally(() => {
            this.setState({
                isReceiving: false
            })
        })
    }

    render() {
        let {t, pagination, loading, csvFilter, canExport, filter,} = this.props;
        let rows = this.getDataSource();
        const currentStatusTab = lodash.pick(filter, ['status']);
        return (
            <div className="a-list mt-5">
                <Affix>
                    <div className="a-list__top a-flex">
                        <h2 className="a-list--title a-text--uppercase">
                            {t("paymentRequest:label.list_payment_request")}
                            ({pagination.total})
                        </h2>
                        <div className="ml-auto">
                            {
                                currentStatusTab.status === "WAITING" && this.state.selectedRowKeys.length > 0 &&
                                <Button
                                    loading={this.state.isReceiving}
                                    className="a-font--12 aphz-24 a-btn--primary-ant mr-4 ant-btn-sm" type="primary"
                                    onClick={() => this.handleReceivedMultiPaymentRequest()}>
                                    Tiếp nhận YCTT được chọn
                                </Button>
                            }
                            {canExport && (
                                <ExportCSV
                                    t={t}
                                    csvFilter={csvFilter}
                                />
                            )}
                            <SettingColumns
                                storageKey="paymentRequest.columns"
                                columns={this.getSettingColumns()}
                                onChange={selectedColumns => this.setState({selectedColumns})}
                            />

                            {/*{*/}
                            {/*    this.props.paymentRequests.length > 0 && currentStatusTab.status === "PROCESSING" && !loading && !!filter.external_order &&*/}
                            {/*    <ProcessPaymentsRequestAuto*/}
                            {/*        selectedRequestIds={this.state.selectedRowKeys}*/}
                            {/*        paymentRequests={lodash.get(this.listPaymentRequestListAllowAutoProcess(), "requestsAllow", [])}*/}
                            {/*        disabled={loading}*/}
                            {/*        filter={filter}*/}
                            {/*        pagination={pagination}*/}
                            {/*        setProcessingRequestId={this.setProcessingRequestId.bind(this)}*/}
                            {/*        pushFailedRequestIds={this.pushFailedRequestIds.bind(this)}*/}
                            {/*        pushSuccessRequestIds={this.pushSuccessRequestIds.bind(this)}*/}
                            {/*        onNextPage={this.onNextPage.bind(this)}*/}
                            {/*        isProcessing={this.state.isProcessing}*/}
                            {/*        setIsProcessing={this.setIsProcessing.bind(this)}*/}
                            {/*    />*/}
                            {/*}*/}
                        </div>
                    </div>
                </Affix>
                <div className={lodash.toInteger(pagination.page_total) > 1 ? "a-content--is-pagination-fixed" : ""}>
                    <Table
                        className={"a-table--antd a-table--description"}
                        rowKey={(record => record.key)}
                        columns={this.getTableColumns()}
                        dataSource={rows}
                        pagination={false}
                        scroll={{x: 'fit-content'}}
                        loading={loading}
                        locale={{
                            emptyText: (
                                <Empty
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                    description={<span>{t("transaction:label.no_data")}</span>}
                                >
                                </Empty>)
                        }}
                        rowSelection={(currentStatusTab.status === "WAITING" ? this.rowSelection : false)}
                        expandedRowKeys={lodash.map(rows, 'id')}
                        expandIcon={() => null}
                        expandIconAsCell={false}
                        expandedRowRender={record => {
                            return (
                                <>
                                    {
                                        record.warning_other_account === 1 ?
                                            <p className="a-text--red a-font--12 pb-2 warning_other_account">
                                                <Icon type="warning" theme="filled" className="a-text--red mr-1"/>
                                                <span>Tài khoản mua hàng của đơn không khớp với tài khoản mua hàng đã gửi YCTT</span>
                                            </p> : ""
                                    }
                                    {
                                        record.in_payment_request_success.length > 0 &&
                                        <p className="a-text--red a-font--12 pb-2 in_payment_request_success">
                                            <Icon type="warning" theme="filled" className="a-text--red mr-1"/>
                                            <span>
                                                {
                                                    t("paymentRequest:message.in_payment_request_success",
                                                        {
                                                            count: record.in_payment_request_success.length > 1 ? t("paymentRequest:message.multi") : ""
                                                        })
                                                }
                                            </span>
                                        </p>
                                    }
                                </>
                            );
                        }}
                    />
                    <Modal
                        title={t('account_purchaser.label.log_title')}
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        bodyStyle={{paddingTop: '0px', maxHeight: '70vmin', overflowY: 'scroll'}}
                        footer={false}
                    >
                        <PaymentRequestLogs request_id={this.state.request_id} t={this.props.t}/>
                    </Modal>
                    <PaginationFull
                        page={lodash.toInteger(pagination.current_page) || 1}
                        pageSize={lodash.toInteger(pagination.per_page)}
                        total={pagination.total}
                        loading={this.props.loading}
                        onChange={this.onChangePage.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

PaymentRequestList.defaultProps = {
    filter: {},
    loading: false,
    canSearch: true,
    paymentRequests: [],
    pagination: {},
    onSearch: (filter) => {
    },
    fetchPaymentRequest: (filter) => {

    }
};

PaymentRequestList.propTypes = {
    filter: PropTypes.object,
    loading: PropTypes.bool,
    canSearch: PropTypes.bool,
    paymentRequests: PropTypes.array,
    pagination: PropTypes.object,
    onSearch: PropTypes.func,
    fetchPaymentRequest: PropTypes.func
};
export default translate()(withEvent(PaymentRequestList));
