import {combineReducers} from 'redux';
import * as ACTION from './constants';

let loading = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.UPDATE_SHIPPING_BILL.REQUEST:
            return true;

        case ACTION.UPDATE_SHIPPING_BILL.SUCCESS:
        case ACTION.UPDATE_SHIPPING_BILL.FAILED:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    loading,
});
