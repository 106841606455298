import React from "react";
import ListProblems from "./ListProblems";
import PropTypes from "prop-types";
import ResolveOrder from "./ResolveOrder";
import "./style.scss";
import UnresolveOrder from "./UnresolveOrder";
import lodash from "lodash";
import authService from "../../Auth/authService";
import * as PERMISSION from "../../Permission/permissions";
import {Divider} from "antd";

class OrderProblems extends React.Component {

    render() {
        let {order, hideResolve} = this.props;
        let is_resolved = lodash.get(order, "orderDetail.is_resolved", order.is_resolved);
        let problem_resolve_creator_id = lodash.get(order, "orderDetail.problem_resolve_creator_id", order.problem_resolve_creator_id);

        if (order.problems.length === 0 && order.is_resolved)
            return "";

        return <>
                {
                    is_resolved === 0 && order.problems.length > 0 &&
                    <div className="order-problems mb-2 mt-1">
                        <ListProblems order={order} />
                        {
                            authService.can(PERMISSION.ORDER_FINANCIAL_RESOLVE) && !hideResolve &&
                            <ResolveOrder order={order} />
                        }
                    </div>
                }
                {
                    is_resolved === 1 && problem_resolve_creator_id > 0 &&
                    <div className="order-problems mb-2 mt-1">
                        <UnresolveOrder order={order} hideResolve={hideResolve}/>
                    </div>
                }
        </>;
    }
}

OrderProblems.defaultProps = {
    order: {}
};

OrderProblems.propType = {
    order: PropTypes.object
};

export default OrderProblems;
