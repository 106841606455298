import React from 'react';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {withEvent} from '../../../systems/events';
import {processResponse} from '../../../systems/api';
import {dispatch} from '../../../systems/store';
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from '../../../modules/common/actions';
import notification from '../../../systems/notification';
import apiService from '../../../modules/OrderBillNo/apiService';
import SearchableComponent from '../../../modules/common/SearchableComponent';
import {setActiveSettingItem} from '../../../modules/Settings/SettingMenu/actions';
import FormSearch from './FormSearch';
import ListOrder from './ListOrder';
import {Layout} from 'antd';
import * as GlobalConstant from "../../../systems/globalContant";
import moment from "moment";

const Content = Layout.Content;

class OrderBillNoSync extends SearchableComponent {
    constructor(props) {
        super(props);
        this.defaultState = {
            orders: [],
            pagination: {},
            listScanningStatus: [],
            loading: false,
        };
        this.state = {...this.defaultState};

        this.defaultFilter = {
            page: 1,
            per_page: 50,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.setPageInfo();
    }

    setPageInfo() {
        let {t} = this.props;

        dispatch(setPageTitle(t('layout.title.order_bill_no')));
        dispatch(setPageDescription(t('layout.description.order_bill_no')));
        dispatch(setMenuActive('order_bill_no'));
        setActiveSettingItem('order_bill_no');
        dispatch(setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.order_bill_no',
                'link': '/order-bill-no-syncs'
            },
        ]));
    }

    onSearch(filter) {
        if (this.state.loading || this.state.crawling) {
            return;
        }

        filter = filter.code ? lodash.pick(filter, ['i', 'code']) : filter;

        this.pushFilter({
            ...filter,
            seller_source: '1688',
            i: parseInt(filter.i || 0, 0) + 1,
        });
    }

    onChangeFilter(filter) {
        if (this.state.loading || this.state.crawling) {
            return;
        }

        this.fetchOrders(filter);
    }

    fetchOrders(filter) {
        let {t} = this.props;

        this.setState({loading: true});

        let res = apiService.getOrdersBillNo({...filter, seller_source: '1688'})
            .finally(() => this.setState({loading: false}));

        let process = processResponse(res);

        process.then(res => {
            this.setState({
                orders: lodash.get(res, 'data.orders') || [],
                pagination: lodash.get(res, 'data.pagination') || {},
                listScanningStatus: lodash.get(res, 'data.listScanningStatus') || [],
                filterLongTime : lodash.get(res, 'data.filterLongTime') || []
            });
        });

        process.catch(() => {
            notification.error(t('message.cant_fetch_data'));
        });

        return process;
    }

    updateOrders(payload) {
        let {orders} = this.state;
        let orderId = lodash.get(payload, 'id_order', null);
        let errors = lodash.get(payload, 'errors', {});
        let newOrders = orders.map(order => {
            if (order.id === parseInt(orderId, 10)) {
                order.freight_bill = Array.isArray(order.freight_bill) ? order.freight_bill.concat(lodash.get(payload, 'orderFreightBill', [])) : lodash.get(payload, 'orderFreightBill', []);
                order.sync_time = moment().format();
                order.errors = errors;
            }
            return order;
        });
        this.setState({
            ...this.state,
            orders: [...newOrders]
        })
    }
    componentWillUnmount() {
        this.setState({...this.defaultState});
    }

    render() {
        let channels = [GlobalConstant.CHANNELS.ORDER_SYNC_BILL_NO];
        let filter = this.getFilter();

        return (
            <Layout>
                <Content className="a-content--ant-layout">
                    <FormSearch
                        input={filter}
                        inputVersion={filter.i || 0}
                        loading={this.state.loading}
                        listScanningStatus={this.state.listScanningStatus}
                        filterLongTime={this.state.filterLongTime}
                        canSearch={!this.state.crawling}
                        onSearch={this.onSearch.bind(this)}
                    />

                    <ListOrder
                        updateOrders={this.updateOrders.bind(this)}
                        channels={channels}
                        orders={this.state.orders}
                        pagination={this.state.pagination}
                        filter={filter}
                        loading={this.state.loading}
                        onSearch={this.onSearch.bind(this)}
                        fetchOrders={this.fetchOrders.bind(this)}
                    />
                </Content>
            </Layout>
        );
    }
}

export default translate()(OrderBillNoSync);
