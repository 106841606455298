import React from 'react';
import Checkbox from './../Checkbox/index';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import onClickOutside from "react-onclickoutside";

class DropdownSelectCheckbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            input: [],
            open: false,
            code_search:'',
        }
    }

    openSearch() {
        let {open} = this.state;
        this.setState({open: !open});
    }

    onChangeInput(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    makeValueMultiSelect(select) {
        let {options} = this.props;
        select = select.map((value) => lodash.parseInt(value));
        options = options.filter((optionItem) => {
            return select.includes(optionItem.value);
        });

        options = options.map(optionItem => {
            return optionItem.label;
        });
        return options;
    }

    handleClickOutside = evt => {
        let {open} = this.state;
        if( open ){
            this.setState({open: !open});
        }
    };


    render() {
        let {options, placeholder_search, t, title_button,item_checked} = this.props;
        let {open, code_search} = this.state;
        if( code_search !== '' ){
            let code_search_item = code_search.trim();
            options = options.filter( (optionItem) =>{
                return optionItem
                     ? optionItem.label.toLowerCase().indexOf(code_search_item.toLowerCase()) !== -1
                     : true;
            });
        }
        let item_checked_options = this.makeValueMultiSelect(item_checked);
        return (
            <div className="a__select" key={item_checked.length}>
                <button className="button_select_china" onClick={this.openSearch.bind(this)}>
                    {item_checked.length ? (
                    lodash.join(item_checked_options, ',')
                ) : title_button}
                </button>
                <div className={"a__select__option "+(open? "":"hidden")}>
                    <div className="input-search">
                        <input type="text" name="code_search"
                               value={code_search}
                               onChange={this.onChangeInput.bind(this)}
                               placeholder={placeholder_search}/>
                    </div>
                    <ul className="a__select__value">
                        {options.length ? (
                            options.map(optionItem => (
                                <li key={optionItem.value}>
                                    <Checkbox name="item" value={optionItem.value}
                                              defaultChecked={item_checked.includes(optionItem.value)}
                                              label={optionItem.label}
                                              onChange={this.props.onCheckItem}
                                    />
                                </li>
                            ))
                            ):(
                            <li>{t('message.no_record')}</li>
                            )}
                    </ul>
                </div>
            </div>
        );
    }
}

DropdownSelectCheckbox.defaultProps = {
    options: [],
    item_checked: [],
    defaultChecked: false,
    onChange: ()=> {
    },
    label: '',
};

DropdownSelectCheckbox.propTypes = {
    options: PropTypes.array,
    item_checked: PropTypes.array,
    defaultChecked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
};
export default onClickOutside(DropdownSelectCheckbox);
