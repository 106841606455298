import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as ACTION from './constants';

let isLoading = (state = [], action) => {
    let {type} = action;
    let id = null;
    switch (type) {
        case ACTION.COMPLAINT_MARK_IMPORTANT.REQUEST:
            id = lodash.get(action, 'payload.params.id', null);
            id = lodash.concat(state, [id]);
            id = lodash.uniq(id);
            id = lodash.compact(id);
            return id;
        case ACTION.COMPLAINT_MARK_IMPORTANT.SUCCESS:
        case ACTION.COMPLAINT_MARK_IMPORTANT.FAILED:
        case ACTION.COMPLAINT_MARK_IMPORTANT.ERROR:
            id = lodash.get(action, 'payload.params.id', null);
            id = lodash.difference(state, [id]);
            return id;
        default:
            return state;
    }
};

let isEndProcess = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.COMPLAINT_MARK_IMPORTANT.REQUEST:
        case ACTION.COMPLAINT_MARK_IMPORTANT.FAILED:
        case ACTION.COMPLAINT_MARK_IMPORTANT.ERROR:
        case ACTION.COMPLAINT_MARK_IMPORTANT_CLEAR:
            return false;
        case ACTION.COMPLAINT_MARK_IMPORTANT.SUCCESS:
            return true;
        default:
            return state;
    }
};

export default combineReducers({
    isLoading,
    isEndProcess,
});
