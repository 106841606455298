import React from 'react';
import {Upload, Button, Icon} from 'antd';
import {API_URL} from '../../../../systems/globalContant/index';
import ApiAuth from './../../../../systems/api/ApiAuth';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import * as constants from './../constants';
import config from '../../../../config/app';

class UploadCsv extends React.Component {
    onChange(info) {
        let status = lodash.get(info, "file.status", "");
        let responseCode = lodash.get(info, "file.response.code", 0);
        if (status === constants.STATUS_UPLOAD_UPLOADING && responseCode === 200) {
            this.props.uploadFileCSVRequest(info);
        }
        if (status === constants.STATUS_UPLOAD_DONE && responseCode === 200) {
            this.props.uploadFileCSVSuccess(info);
        } else if (status === constants.STATUS_UPLOAD_ERROR || responseCode === 403) {
            this.props.uploadFileCSVFailed(info);
        }
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    render() {
        let {t, loading} = this.props;
        return (
            <Upload
                accept={'.csv,.zip'}
                name={'file'}
                multiple={true}
                showUploadList={false}
                headers={{
                    'Access-Control-Allow-Headers': 'Authorization',
                    'Authorization': 'Bearer ' + new ApiAuth('token_customer').getToken(),
                }}
                action={config.apiUrl + API_URL.UPLOAD_TRANSACTION_FILE_GLOBAL}
                onChange={this.onChange.bind(this)}
            >
                <Button loading={loading} className=" a-font--12 aphz-24 a-btn--primary-ant a-btn--upload-file ml-2"
                        size={"small"}><Icon type="cloud-upload" className="a-font--18"/>{t("transaction:btn.upload_global")}
                </Button>
            </Upload>

        )
    }
}

UploadCsv.defaultProps = {
    loading: false,
};

UploadCsv.propTypes = {
    uploadFileCSVSuccess: PropTypes.func,
    uploadFileCSVFailed: PropTypes.func,
    uploadFileCSVRequest: PropTypes.func,
    loading: PropTypes.bool,
};

export default translate()(UploadCsv);
