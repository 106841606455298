import {apiWithToken} from '../../../systems/api';
import {API_URL} from './../../../systems/globalContant';

export default {
    assignPurchaserOrders: (orderIds, tellerId) => apiWithToken.post(
        API_URL.DEFAULT + '/order-distributors/assign-multi',
        {order_ids: orderIds, teller_id: tellerId},
        {loading: false}
    ),
    selfAssignOrders: (orderIds) => apiWithToken.post(
        API_URL.DEFAULT + '/order-distributors/self-assign',
        {order_ids: orderIds},
        {loading: false}
    ),
    assignPurchaser: (orderId, tellerId) => apiWithToken.post(
        API_URL.DEFAULT + '/order-distributors/assign',
        {order_id: orderId, teller_id: tellerId},
        {loading: false}
    ),
    unassignPurchaser: (orderId) => apiWithToken.post(
        API_URL.DEFAULT + '/order-distributors/unassign',
        {order_id: orderId},
        {loading: false}
    ),
};
