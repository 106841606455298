import React from 'react';
import authService from '../authService';
import Page403 from '../../common/components/Page403';

const authorize = (payload, next, permissions, canAny = false) => {
    let allowed = canAny ? authService.canAny(permissions) : authService.can(permissions);

    if (!allowed) {
        payload.response.render(<Page403/>);
        return;
    }

    next(payload);
};

export const can = (permissions) => (payload, next) => authorize(payload, next, permissions);

export const canAny = (permissions) => (payload, next) => authorize(payload, next, permissions, true);

export default authorize;