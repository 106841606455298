import React from 'react';
import {Form, Row, Col, Input, DatePicker, Button, Icon} from 'antd';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import FormProcessing from "../../../common/components/FormProcessing";
import SelectUserContainer from "../../../User/SelectUser/SelectUserContainer";
import moment from 'moment';
import lodash from "lodash";
import SelectAccountPurchasers from "../../../AccountPurchaser/SelectAccountPurchasers";

const Search = Input.Search;
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY/MM/DD';

class FormSearch extends FormProcessing {
    constructor(props) {
        super(props);

        this.defaultInput = {
            filename: '',
            id_creator: '',
            created_at_from: '',
            created_at_to: '',
            errors: '',
            pending: ''
        };

        this.state = {
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
        };
    }

    onSubmit(event) {
        event.preventDefault();
        if (!this.props.loading) {
            this.props.onSearch(
                lodash.omit(this.state.input, ['page', 'per_page'])
            );
        }
    }

    onChangeSelect(val, param) {
        this.changeInput({[param]: val});
    }

    onChangeCreatedTime(date) {
        this.changeInput({
            'created_at_from': date[0] ? date[0].format('YYYY-MM-DD') : '',
            'created_at_to': date[1] ? date[1].format('YYYY-MM-DD') : '',
        });
    }

    onChangeTracsactionTime(date) {
        this.changeInput({
            'transaction_time_start': date[0] ? date[0].format('YYYY-MM-DD') : '',
            'transaction_time_end': date[1] ? date[1].format('YYYY-MM-DD') : '',
        });
    }

    onSearchErrorFile() {
        let {errors} = this.state.input;
        this.changeInput({...this.state.input, pending: "", errors: (errors === "" || !errors) ? 1 : "", page: 1});
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.state.input,
                pending: "",
                errors: (errors === "" || !errors) ? 1 : "",
                page: 1
            });
        }
    }

    onSearchPendingFile() {
        let {pending} = this.state.input;
        this.changeInput({...this.state.input, errors: "", pending: (pending === "" || !pending) ? 1 : "", page: 1});
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.state.input,
                errors: "",
                pending: (pending === "" || !pending) ? 1 : "",
                page: 1
            });
        }
    }

    onReset(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.changeInput(this.defaultInput);
            this.props.onSearch(this.defaultInput);
        }
    }

    render() {
        let {t} = this.props;
        let {input, errors, pending} = this.state;

        return (
            <Row gutter={24} className="a-list--form-search mr-0 ml-0">
                <Form labelcol={{span: 12}} onSubmit={this.onSubmit.bind(this)}>
                    <Col xs={6} sm={6} md={5} lg={5} xl={4} className="pl-0">
                        <Form.Item
                            label={t("transaction:label.filename")}
                            className="a-form--ant-item  mb-0"
                        >
                            <Search
                                value={input.filename || ''}
                                onChange={this.onChangeInput.bind(this, 'filename')}
                                placeholder={t("transaction:placeholder.filename")}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={6} sm={6} md={5} lg={5} xl={4} className="mb-2">
                        <Form.Item label={t("transaction:label.account")} className="a-form--ant-item  mb-0">
                            <SelectAccountPurchasers
                                showName
                                allOption
                                allAccount={true}
                                searchByAccount={true}
                                placeholder={t("transaction:placeholder.account")}
                                loading={this.props.loading}
                                value={input.account || ""}
                                onChange={val => this.onChangeSelect(val, 'account')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={6} sm={6} md={5} lg={5} xl={4}>
                        <Form.Item label={t("transaction:label.id_creator")} className="a-form--ant-item  mb-0">
                            <SelectUserContainer
                                placeholder={""}
                                emptyOption={true}
                                showName={true}
                                autoSelect={false}
                                className="a-select a-select--search-content _id_creator"
                                value={input.id_creator || ""}
                                onChange={val => this.onChangeSelect(val, 'id_creator')}
                            >
                            </SelectUserContainer>
                        </Form.Item>
                    </Col>
                    <Col xs={8} sm={8} md={6} lg={7} xl={6}>
                        <Form.Item label={t("transaction:label.created_at")} className="a-form--ant-item  mb-0">
                            <RangePicker
                                placeholder={[t("transaction:placeholder.begin"), t("transaction:placeholder.end")]}
                                format='DD/MM/YYYY'
                                value={[
                                    input['created_at_from'] ? moment(input['created_at_from'], dateFormat) : null,
                                    input['created_at_to'] ? moment(input['created_at_to'], dateFormat) : null,
                                ]}
                                onChange={this.onChangeCreatedTime.bind(this)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={8} sm={8} md={6} lg={7} xl={6}>
                        <Form.Item label={t("transaction:label.transaction_time")} className="a-form--ant-item  mb-0">
                            <RangePicker
                                placeholder={[t("transaction:label.transaction_time_start"), t("transaction:label.transaction_time_end")]}
                                format='DD/MM/YYYY'
                                value={[
                                    input['transaction_time_start'] ? moment(input['transaction_time_start'], dateFormat) : null,
                                    input['transaction_time_end'] ? moment(input['transaction_time_end'], dateFormat) : null,
                                ]}
                                onChange={this.onChangeTracsactionTime.bind(this)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{span: 24}} lg={{span: 24}} xl={{span: 24}}
                         className="pr-0 pl-0 mt-4 pt-5 border-top-dashed">
                        <div className="d-flex">
                            <div className="a-list--action-search">
                                <Button
                                    loading={this.props.loading}
                                    disabled={!lodash.isEmpty(errors)}
                                    onClick={this.onSearchErrorFile.bind(this)}
                                    className={"a-text--uppercase a-btn--primary-ant " + (input.errors === "" || !input.errors ? "ghost" : "")}>{t("transaction:btn.error_filter")}</Button>
                            </div>
                            <div className="pl-2">
                                <Button
                                    loading={this.props.loading}
                                    disabled={!lodash.isEmpty(pending)}
                                    onClick={this.onSearchPendingFile.bind(this)}
                                    className={"a-text--uppercase a-btn--primary-ant " + (input.pending === "" || !input.pending ? "ghost" : "")}>{t("transaction:btn.file_pending")}</Button>
                            </div>
                            <div className="a-list--action-search flex-row ml-auto">
                                <a href="/" onClick={this.onReset.bind(this)} className="link-reset"><Icon
                                    type="sync"/>{t("btn.reset_search")}
                                </a>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="ant-btn a-text--uppercase a-btn--primary-ant ml-2 aphz-50"
                                    loading={this.props.loading}
                                >{t('btn.search')}</Button>
                            </div>
                        </div>
                    </Col>
                </Form>
            </Row>
        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    onSearch: (input) => {
    },
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

export default translate('translations')(FormSearch);
