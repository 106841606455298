import {combineReducers} from 'redux';
import * as ACTION from './constants';
import * as ACTION_COMPLAINT from './../constants';

let isLoading = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.COMPLAINT_UPDATE_NOTE.REQUEST:
            return true;
        case ACTION.COMPLAINT_UPDATE_NOTE.SUCCESS:
        case ACTION.COMPLAINT_UPDATE_NOTE.FAILED:
            return false;
        default:
            return state;
    }
};

let isEndProcess = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION_COMPLAINT.FETCH_COMPLAINT_DETAIL.REQUEST:
        case ACTION.COMPLAINT_UPDATE_NOTE.REQUEST:
        case ACTION.COMPLAINT_UPDATE_NOTE.FAILED:
        case ACTION.COMPLAINT_UPDATE_NOTE_CLEAR:
            return false;
        case ACTION.COMPLAINT_UPDATE_NOTE.SUCCESS:
        case ACTION_COMPLAINT.FETCH_COMPLAINT_DETAIL.SUCCESS:
            return true;
        default:
            return state;
    }
};

export default combineReducers({
    isLoading,
    isEndProcess,
});
