import React, {useState} from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default function PhotoGallery({images, className= "",  width= 35, height = 35}) {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    function _onClick(index) {
        setIsOpen(true);
        setPhotoIndex(index);
    }

    return (
        <div className={className}>
            {
                images && images.map((image, index) => {
                    return <span key={index} className={`a-text--pointer`} onClick={() => _onClick(index)}><img src={image} width={width} height={height} /></span>;
                })
            }
            {
                isOpen &&
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
            }
        </div>
    );
}
