import {connect} from 'react-redux';
import AccountPurchaser from './components';
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from './../common/actions';
import {
    getListAccountPurchaserAction, changeStatusAccountPurchaserAction,
    updateAccountPurchaserAction, createAccountPurchaserAction,
    closeModalForm, openModalForm, clearError
} from './actions';
import {getListAccountPurchaser} from './selectors';
import lodash from 'lodash';

const mapStateToProps = (state) => {
    return {
        list: getListAccountPurchaser(state),
        isModalProcessing: lodash.get(state, "AccountPurchaser.isModalProcessing", false),
        error: lodash.get(state, "AccountPurchaser.error", {}),
        isLoading: lodash.get(state, "AccountPurchaser.isLoading", false),
        permissionsList: lodash.get(state, 'User.me.user.permissions', [])
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        getList: () => {
            let filter = {scope: 'basic'};
            dispatch(getListAccountPurchaserAction(filter));
        },
        changeStatusAccountPurchaser: (accountPurchaserDatas) => {
            dispatch(changeStatusAccountPurchaserAction(accountPurchaserDatas));
        },
        updateAccountPurchaser: (accountPurchaserDatas) => {
            dispatch(updateAccountPurchaserAction(accountPurchaserDatas));
        },
        createAccountPurchaser: (accountPurchaserDatas) => {
            dispatch(createAccountPurchaserAction(accountPurchaserDatas));
        },
        openModalForm: () => {
            dispatch(openModalForm());
        },
        closeModalForm: () => {
            dispatch(closeModalForm());
        },
        clearError: () => {
            dispatch(clearError());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPurchaser)