import React, {Component} from 'react';
import {withEvent} from "../../../systems/events";
import {translate} from "react-i18next";
import {Button, Row, Col, Input, notification} from "antd";
import {processResponse} from "../../../systems/api";
import apiService from "../../../modules/PaymentRequest/apiService";
import lodash from 'lodash';

class AddMemberGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            username: undefined
        }
    }

    addMember = () => {
        const {username} = this.state;
        const {groupId, getGroupInfo, t} = this.props;
        if (username){
            this.setState({loading: true});
            processResponse(apiService.addMemberPaymentRequestGroup(groupId, {username}))
                .then(res => {
                    this.setState({username: undefined});
                    getGroupInfo();
                    notification.success({message: t("group:messages.add_member_success")})
                }).catch(err => {
                const errorCode = lodash.get(err, "code", "");
                let message = "";
                if (errorCode === "INPUT_INVALID") {

                    const data = lodash.get(err, "data", {});
                    for (let field in data) {
                        for (let errorCode in data[field]) {
                            message = field + "_" + errorCode;
                        }
                    }
                }
                if (message) {
                    notification.error({message: t("group:messages.INPUT_INVALID." + message)})
                }else {
                    notification.error({message: t("group:messages.add_member_fail")})
                }
            }).finally(() => {this.setState({loading: false})})
        }
    };
    onChangeMember = (e) => {
        this.setState({
            username: e.target.value
        })
    };
    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.addMember();
        }
    };

    render() {
        const {t} = this.props;
        const {username, loading} = this.state;
        return (
            <Row className="p-3 d-flex" gutter={20}>
                <Col span={10} className="a-text--right">
                    <Input
                        value={username}
                        placeholder={t("group:placeholder.add_member")}
                        onChange={e => this.onChangeMember(e)}
                        onKeyPress={e => this.onKeyPress(e)}
                    />
                </Col>
                <Col span={2}>
                    <p className="a-text--right">
                        <Button loading={loading} type="primary" icon="plus-square" onClick={this.addMember}>{t("btn.add")}</Button>
                    </p>
                </Col>
            </Row>
        );
    }
}

export default translate()(withEvent(AddMemberGroup))