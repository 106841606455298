import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as AddActions from './../InputAddInvoiceCode/actions';
import * as actions from './actions';
import ModalManageInvoiceCode from './components';
import lodash from 'lodash';

const mapStateToProps = (state, props) => {
    return {
        invoiceCodes: props.invoiceCodes,
        idTransaction: props.idTransaction,
        showModal: props.showModal,
        loading: props.loading === true || lodash.get(state, 'Transaction.ModalManageInvoiceCode.loading', false) ||
        lodash.get(state, 'Transaction.InputAddInvoiceCode.loading', false),
        canUpdateInvoiceCode: props.canUpdateInvoiceCode,
        canDeleteInvoiceCode: props.canDeleteInvoiceCode,
        maxInvoiceCode: props.maxInvoiceCode,
    }
};

const mapDispatchToProps = (dispatch) => ({
    addInvoiceCode2Transaction: (datas) => dispatch(AddActions.addInvoiceCode2Transaction(datas)),
    deleteInvoiceCodeTransaction: (datas) => dispatch(actions.deleteInvoiceCodeTransaction(datas)),
    clearState: () => dispatch(actions.clearState()),
});

class ModalManageInvoiceCodeWapper extends React.PureComponent {
    render() {
        return <ModalManageInvoiceCode {...this.props}/>
    }
}

let ModalManageInvoiceCodeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalManageInvoiceCodeWapper);

ModalManageInvoiceCodeContainer.defaultProps = {
    loading: false,
    idTransaction: 0,
    showModal: false,
    invoiceCodes: [],
    canUpdateInvoiceCode: false,
    canDeleteInvoiceCode: false,
    maxInvoiceCode: ''
};

ModalManageInvoiceCodeContainer.propTypes = {
    loading: PropTypes.bool,
    showModal: PropTypes.bool,
    idTransaction: PropTypes.number,
    invoiceCodes: PropTypes.array,
    addInvoiceCode2Transaction: PropTypes.func,
    canUpdateInvoiceCode: PropTypes.bool,
    canDeleteInvoiceCode: PropTypes.bool,
    maxInvoiceCode: PropTypes.string
};

export default ModalManageInvoiceCodeContainer;
