export const GET_LIST_POST_DEMO = {
    REQUEST: 'GET_LIST_POST_DEMO_REQUEST',
    SUCCESS: 'GET_LIST_POST_DEMO_SUCCESS',
    FAILED: 'GET_LIST_POST_DEMO_FAILED',
};

export const LOGOUT = {
    REQUEST: 'LOGOUT_REQUEST',
    SUCCESS: 'LOGOUT_SUCCESS',
    FAILED: 'LOGOUT_FAILED',
};