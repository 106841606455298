import React, {useState} from 'react';
import {Icon, Input, Tooltip} from "antd";
import apiService from '../../../modules/PaymentRequest/apiService';
import authService from "../../../modules/Auth/authService";

const Textarea = Input.TextArea;

function NoteOfCreatorPaymentRequest(props) {
    const {t, request} = props;
    const requestId = props.request.id;
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [note, setNote] = useState(request.note_teller);
    const canUpdateNote = request.senderRequest ? authService.user().id === request.senderRequest.id : false;

    function handleSaveNote() {
        setIsLoading(true);
        apiService.createNoteOfCreatorPaymentRequest(requestId, {note}).then((res) => {

        }).catch(error => {

        }).finally(() => {
            setIsLoading(false);
            setIsEdit(false);
        })
    }

    function handleClickCancelEdit() {
        setIsEdit(false);
    }

    return (
        <div>
            {isEdit ?

                (
                    <div>
                        <div className='input-action'>
                            {isLoading ? (
                                <Icon type='loading-3-quarters' spin/>
                            ) : (
                                <Icon type="check" onClick={handleSaveNote}/>
                            )}
                            <Icon type='close' onClick={handleClickCancelEdit}/>
                        </div>
                        <div>
						<Textarea
                            onChange={(e) => setNote(e.target.value)}
                            maxLength={250}
                            value={note}
                            onFocus={(e) => {
                                const val = e.target.value;
                                e.target.value = '';
                                e.target.value = val;
                            }}
                            rows={3}
                            placeholder='Nhập ghi chú'
                        />
                        </div>
                    </div>
                ) : (
                    <>
                        {
                            canUpdateNote &&
                            <Tooltip title={t("paymentRequest:label.create_note_teller")}>
                                <Icon type="edit" style={{color: "#008dff"}} onClick={() => setIsEdit(true)}/>
                            </Tooltip>
                        }
                        <div style={{fontSize: "12px", whiteSpace: "pre-wrap"}}>{note}</div>
                    </>
                )
            }
        </div>
    );
}

export default NoteOfCreatorPaymentRequest