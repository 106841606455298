import { createSelector } from 'reselect'
import lodash from 'lodash';

const selectGetFeatures =  state => lodash.get(state, 'Features.list', []);

export const features = createSelector(
    selectGetFeatures,
    items => items.map(item => featureDetail(item))
);

const featureDetail = (feature) => {
    return lodash.assign({}, feature);
};