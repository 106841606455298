export const PURCHASE_ADDRESS = {
    REQUEST: 'PURCHASE_ADDRESS_REQUEST',
    SUCCESS: 'PURCHASE_ADDRESS_SUCCESS',
    FAILED: 'PURCHASE_ADDRESS_FAILED',
};

export const PURCHASE_ADDRESS_CREATE = {
    REQUEST: 'PURCHASE_ADDRESS_CREATE_REQUEST',
    SUCCESS: 'PURCHASE_ADDRESS_CREATE_SUCCESS',
    FAILED: 'PURCHASE_ADDRESS_CREATE_FAILED',
};

export const PURCHASE_ADDRESS_UPDATE = {
    REQUEST: 'PURCHASE_ADDRESS_UPDATE_REQUEST',
    SUCCESS: 'PURCHASE_ADDRESS_UPDATE_SUCCESS',
    FAILED: 'PURCHASE_ADDRESS_UPDATE_FAILED',
};

export const PURCHASE_ADDRESS_DETAIL = {
    REQUEST: 'PURCHASE_ADDRESS_DETAIL_REQUEST',
    SUCCESS: 'PURCHASE_ADDRESS_DETAIL_SUCCESS',
    FAILED: 'PURCHASE_ADDRESS_DETAIL_FAILED',
    CLEAR: 'PURCHASE_ADDRESS_DETAIL_CLEAR'
};

export const RESET_ERROR_INPUT_PURCHASE_ADDRESS = {
    REQUEST: 'RESET_ERROR_INPUT_PURCHASE_ADDRESS_REQUEST',
    SUCCESS: 'RESET_ERROR_INPUT_PURCHASE_ADDRESS_SUCCESS',
    FAILED: 'RESET_ERROR_INPUT_PURCHASE_ADDRESS_FAILED',
};


export const UPDATE_STATUS_ADDRESS_PURCHASE = {
    REQUEST: 'UPDATE_STATUS_ADDRESS_PURCHASE_REQUEST',
    SUCCESS: 'UPDATE_STATUS_ADDRESS_PURCHASE_SUCCESS',
    FAILED: 'UPDATE_STATUS_ADDRESS_PURCHASE_FAILED',
};
