import React from 'react';
import FormSearch from './FormSearch';
import ListData   from './ListData';
import {Layout} from 'antd';
import {url} from "../../../../systems/routing/index";
import {translate} from 'react-i18next';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {TYPES} from "../constants";
import PusherComponent from "../../../common/components/Pusher";
import * as GlobalConstant from "../../../../systems/globalContant";

import {getState} from "../../../../systems/store";

const {Content} = Layout;

class ListTransaction extends PusherComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpenListSetting: false,
            isCurrentPage: TYPES.SINGLE
        }
    }

    componentDidMount() {
        let {t} = this.props;
        this.props.setPageTitle(t('breadcrumb.transaction'));
        this.props.setPageDescription(t('transaction:label.list_transaction_desc'));
        this.props.setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            },
            {
                'label': 'breadcrumb.transaction',
                'link': url.to('transaction.list')
            },
        ]);
        this.props.setMenuActive("transaction.list");
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    onOpenListSetting(isOpenListSetting, isCurrentPage) {
        this.setState({isOpenListSetting, isCurrentPage})
    }

    render() {
        let user = getState('User.me.user', {});
        let channels = [
            GlobalConstant.CHANNELS.IMPORT_TRANSACTION_INVOICE_CODE + "_" + user.id,
            GlobalConstant.CHANNELS.IMPORT_TRANSACTION_RESOLVE + "_" + user.id,
            GlobalConstant.CHANNELS.TRANSACTION_IMPORT_MANUAL + "_" + user.id
        ];

        let {filter} = this.props;
        return (
            <Layout>
                <Content className="a-content--ant-layout ">
                    {
                        !filter.hasOwnProperty('id_transaction_file') ?
                            <FormSearch
                                errors={this.props.errors}
                                getDateRange={this.props.getDateRange}
                                csvFilter={this.props.csvFilter}
                                setFilterCSV={this.props.setFilterCSV}
                                input={this.props.filter}
                                resetError={this.props.resetError}
                                inputVersion={lodash.toInteger(this.props.filter.i)}
                                loading={this.props.loading}
                                onSearch={this.props.onSearch}
                                unusualTypes={this.props.unusualTypes}
                                permission={this.props.permission}
                            /> : null
                    }
                    <ListData
                        channels={channels}
                        filter={this.props.filter}
                        dateRange={this.props.dateRange}
                        csvFilter={this.props.csvFilter}
                        setFilterCSV={this.props.setFilterCSV}
                        downloadFile={this.props.downloadFile}
                        transactions={this.props.transactions}
                        loading={this.props.loading}
                        pagination={this.props.pagination}
                        resetError={this.props.resetError}
                        onSearch={this.props.onSearch}
                        isOpenListSetting={this.state.isOpenListSetting}
                        onOpenListSetting={this.onOpenListSetting.bind(this)}
                        isCurrentPage={this.state.isCurrentPage}
                        object={"transaction"}
                        currentTransaction={this.props.currentTransaction}
                        permission={this.props.permission}
                    />
                </Content>
            </Layout>
        )
    }
}

ListTransaction.defaultProps = {
    transactions: [],
    pagination: {},
    filter: {},
    loading: false,
    onSearch: (filter) => {},
    currentTransaction: {
        idTransaction: 0,
        showModal: false,
        invoiceCodes: []
    },
};

ListTransaction.propTypes = {
    transactions: PropTypes.array,
    pagination: PropTypes.object,
    filter: PropTypes.object,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
    clearState: PropTypes.func,
    currentTransaction: PropTypes.object,

};

export default translate('translations')(ListTransaction);
