// @flow
/* eslint-disable react/no-multi-comp */
import React, {Component} from 'react';
import Page from '@atlaskit/page';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import HeaderContainer from "./AtlakitComponents/Header/HeaderContainer";
import MenuBarContainer from "./HomeMadeComponents/MenuBarContainer";
import ErrorContainer from "./../modules/common/components/Error/ErrorContainer";

class AtlakitNavbar extends Component {
    render() {
        let {children,error404} = this.props;
        return (
            <Page
                navigation={<MenuBarContainer/>}
            >
                <HeaderContainer/>
                <div className={"a-content"}>
                    {error404 && error404 !==undefined? (<ErrorContainer/>):(children)}
                </div>
            </Page>
        );
    }
}
AtlakitNavbar.defaultProps = {
    error404: false
};

AtlakitNavbar.propTypes = {
    error404: PropTypes.bool,
};


export default translate('translations')(AtlakitNavbar);
