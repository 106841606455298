import React from 'react'
import lodash from 'lodash'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import bootbox from './../../../../common/components/Bootbox/bootbox'
import { ModalTransition } from '@atlaskit/modal-dialog'
import ModalConfirmDelete from './ModalConfirmDelete'
import Spinner from '@atlaskit/spinner'
import Input from '../../../../common/components/Input/Super'
import notification from '../../../../../systems/notification'
import { Button, Modal } from 'antd'
import apiService from '../../apiService'
import { processResponse } from '../../../../../systems/api'
import ModalProposedChangesInvoiceCode from './ModalProposedChangesInvoiceCode'
import ModalConfirmPaidInvoiceCode from './ModalConfirmPaidInvoiceCode'
import styled from 'styled-components'
import { trans } from '../../../../../systems/i18n'
import { EVENTS, STATUS_LIST, URL_DISTRIBUTOR } from '../../../../../screens/ForeignOrder/constants'
import { CRAWL_EVENTS, ORIGIN_COMPLAINT_LINK } from '../../../../ComplaintSeller/constants'
const ProposedChangeOriginalInvoice = styled.span`
    .ant-btn-primary:hover,
    .ant-btn-primary:focus,
    .ant-btn:hover,
    .ant-btn:focus {
        background-color: #0050b3 !important;
        border-color: #0050b3 !important;
    }
`

class InvoiceCode extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpenModalConfirmDelete: false,
            modalConfirmPaidInvoiceCode: false, // Xác nhận lần 2 xóa MHĐG đã được thanh toán thành công
            invoice_code: '',
            invoice_code_delete: '',
            creating_invoice_code: false,
            isOpenModalProposeChange: false,
        }

        this.inputFocus = React.createRef()
        this.isFocus = false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = { ...prevState }
        if (lodash.get(nextProps, 'isEndProcess.postInvoiceCode', false) === true) {
            state.invoice_code = ''
        }

        // Nếu xóa MHĐG đã thanh toán thành công cần confirm lại
        if (Object.keys(lodash.get(nextProps, 'errorInvoiceCode.invoice_code', {})).includes('PAYMENT_REQUEST_SUCCESS')) {
            state.modalConfirmPaidInvoiceCode = true
        } else {
            state.modalConfirmPaidInvoiceCode = false
        }

        if (lodash.get(nextProps, 'isEndProcess.deleteInvoiceCode', false) === true) {
            state.isOpenModalConfirmDelete = false

            // Nếu là xóa MHĐG đã thanh toán thành công thì lưu lại MHĐG bị xóa để confirm
            if (!state.modalConfirmPaidInvoiceCode) state.invoice_code_delete = ''
        }

        return state
    }

    /**
     * handle action post new invoice code
     * @param event
     */
    onPostInvoiceCode(event) {
        const { order } = this.props
        let { target } = event
        this.setState({
            invoice_code: target.value,
        })

        if (event.key === 'Enter') {
            let value = target.value.trim()
            value = value.replace(/\s/g, '')
            value = value.replace(/[^\w\s-_//]/gi, '')
            if (value.length > 0) {
                this.props.onPostInvoiceCode({
                    invoice_code: value,
                    id: order.id,
                })
            }
        }
    }

    /**
     * handle while paste value
     * @param event
     */
    onChangeInput(event) {
        if (typeof event !== 'object') {
            this.props.beginProcessPostInvoiceCode()
            this.setState({
                invoice_code: event,
            })
        }
    }

    /**
     * handle action keydown to clear error if it is existed
     * @param e
     */
    handleKeyDown(e) {
        if (this.generateInvoiceCodeError() !== '') {
            this.props.clearErrorWhilePostInvoiceCode()
            this.props.beginProcessPostInvoiceCode()
        }
    }

    /**
     * Hiển thị danh sách lỗi của invoice_code
     */
    generateInvoiceCodeError() {
        let { t, errorInvoiceCode } = this.props
        let status = lodash.get(errorInvoiceCode, 'status.INVALID', false)
        let errorMessage = ''
        if (!lodash.isEmpty(errorInvoiceCode)) {
            for (let field in errorInvoiceCode) {
                for (let errorCode in errorInvoiceCode[field]) {
                    if ((status || errorCode === 'INVALID') && field === 'order_status') {
                        errorMessage = t('order_detail.validate_message.invaild_status')
                    } else if (errorCode === 'PAYMENT_REQUEST_EXIST') {
                        errorMessage = 'PAYMENT_REQUEST_EXIST'
                    } else if (errorCode === 'PAYMENT_REQUEST_SUCCESS') {
                        errorMessage = 'PAYMENT_REQUEST_SUCCESS'
                    } else {
                        errorMessage += t('order_detail.validate_message.' + errorCode.replace('.', ''), { field: t('order_detail.field.' + field) }) + '<br />'
                    }
                }
            }
        }

        return errorMessage
    }

    /**
     * handle action close confirm before delete modal
     */
    closeModal() {
        this.setState({
            isOpenModalConfirmDelete: false,
        })
    }

    closeModalConfirmPaidInvoiceCode() {
        this.props.clearErrorWhilePostInvoiceCode()
        this.setState({
            modalConfirmPaidInvoiceCode: false,
            invoice_code_delete: '',
        })
    }

    /**
     * handle open popup confirm
     * @param invoiceCode
     */
    openModalConfirmDelete(invoiceCode) {
        this.setState({
            isOpenModalConfirmDelete: true,
            invoice_code_delete: invoiceCode,
        })
        this.props.beginProcessDeleteInvoiceCode()
    }

    /**
     * handle action put request delete invoice_code
     */
    onDeleteInvoiceCode() {
        let { order } = this.props
        let { invoice_code_delete } = this.state
        this.props.deleteInvoiceCode({
            id: order.id,
            invoice_code: invoice_code_delete,
            is_deleted: 1,
        })
    }

    onConfirmDeletingPaidInvoiceCode() {
        let { order } = this.props
        let { invoice_code_delete } = this.state
        this.props.deleteInvoiceCode({
            id: order.id,
            invoice_code: invoice_code_delete,
            is_deleted: 1,
            confirm: 1,
        })
    }

    componentDidUpdate() {
        if (this.isFocus) {
            this.isFocus = false
            this.inputFocus.focus()
        }
    }

    /**
     * Close edit
     */
    onCloseEdit(event) {
        const { order } = this.props
        let { invoice_code } = this.state
        let value = invoice_code.trim()
        value = value.replace(/\s/g, '')
        value = value.replace(/[^\w\s-_//]/gi, '')
        if (value.length > 0) {
            this.props.onPostInvoiceCode({
                invoice_code: value,
                id: order.id,
            })
        }
        this.props.beginProcessPostInvoiceCode()
    }

    onCreateInvoiceCodeAuto = e => {
        let { order, t } = this.props
        this.setState({ creating_invoice_code: true })
        let res = apiService.createInvoiceCodeAuto(order.id)

        let process = processResponse(res)
        process.then(res => {
            notification.success(t('orderDetail:order_detail.create_invoice_code_automatically.success'))
            this.setState({ creating_invoice_code: false })
            this.props.updateInvoiceCode(res)
        })
        process.catch(error => {
            notification.error(t('orderDetail:order_detail.create_invoice_code_automatically.fail'))
            this.setState({ creating_invoice_code: false })
        })
    }

    openModalProposedChangeOriginalInvoice = () => {
        this.setState({
            isOpenModalProposeChange: true,
        })
    }
    closeModalProposedChangeOriginalInvoice = () => {
        this.setState({
            isOpenModalProposeChange: false,
        })
    }

    handleOpenLinkTaobaoPurchaseOrder(event, invoice_code) {
        event.preventDefault()
        window.postMessage({
            type: EVENTS.OPEN_LINK_DISTRIBUTOR,
            payload: {
                invoice_code
            }
        });

    }

    render() {
        let { t, order, isLoading } = this.props
        const taobaoPurchaseOrder = lodash.get(order, 'taobaoPurchaseOrder', {})
        let showLinkNormal = true
        if (!lodash.isEmpty(taobaoPurchaseOrder)) {
            const status = lodash.get(taobaoPurchaseOrder, 'purchase_order_status', 'TRADE_CLOSED')
            if (status !== 'TRADE_CLOSED') {
                showLinkNormal = false
            }
        }

        let { invoice_code, isOpenModalConfirmDelete, invoice_code_delete, isOpenModalProposeChange, modalConfirmPaidInvoiceCode } = this.state
        let invoiceSources = lodash.get(order, 'invoiceCodes', [])
        let invoice_status = {
            PENDING: 'Đang chờ duyệt',
            WAITING: 'Chưa thanh toán',
            SUCCESS: 'Đã thanh toán',
            PROCESSING: 'Đang thanh toán',
            CANCELLED: 'Huỷ',
            DEPOSITED: 'Đã đặt cọc',
        }
        let invoice_code_alphabet = lodash.get(order.agency, 'invoice_code_alphabet', 0)

        return (
            <div className="a-purchasod__group__invoice_code mt-4">
                <span className="a-purchasod__group--label a-purchasod__group--label--invoice">
                    {t('order_detail.original_invoice')} <span style={{ color: '#ff0000' }}>*</span>
                </span>

                <div className="a-purchasod__group--special d-flex" style={{ flexFlow: 'wrap' }}>
                    {order.canAddInvoiceCodes ? (
                        <div className={'a-purchasod__group--code--input'}>
                            <div className="a-purchasod__group--bill-code d-flex">
                                <div className={'d-inline-flex a-purchasod__group--bill-code__input'}>
                                    <span className={isLoading ? 'mr-1 mt-1' : 'hidden'}>
                                        <Spinner size="small" isCompleting={!isLoading} />
                                    </span>
                                    <Input
                                        integerHasBlank={!invoice_code_alphabet}
                                        regexInput={invoice_code_alphabet ? /^\S*$/ : false}
                                        focus
                                        notReplaceZeroFirst
                                        className={
                                            lodash.includes(['', 'PAYMENT_REQUEST_EXIST', 'PAYMENT_REQUEST_SUCCESS'], this.generateInvoiceCodeError())
                                                ? ''
                                                : 'input--error'
                                        }
                                        placeholder={t('order_detail.invoice_code_placeholder')}
                                        onChange={event => this.onChangeInput(event)}
                                        onKeyPress={event => this.onPostInvoiceCode(event)}
                                        onKeyDown={event => this.handleKeyDown(event)}
                                        name={'invoice_code'}
                                        value={invoice_code}
                                        disabled={isLoading}
                                        onRef={input => {
                                            this.inputFocus = input
                                        }}
                                    />
                                    <a onClick={event => this.onCloseEdit(event)} className={isLoading || invoice_code === '' ? 'hidden' : 'bill-code--cancel'}>
                                        {t('order:detail.invoice_save')}
                                    </a>
                                    {order.wrong_process && (
                                        <Button
                                            size="small"
                                            className="a-font--12 a-btn--primary-ant btn-active"
                                            onClick={this.onCreateInvoiceCodeAuto}
                                            loading={this.state.creating_invoice_code}>
                                            Thêm tự động
                                        </Button>
                                    )}
                                </div>
                                <p
                                    className={
                                        !lodash.includes(['', 'PAYMENT_REQUEST_EXIST', 'PAYMENT_REQUEST_SUCCESS'], this.generateInvoiceCodeError())
                                            ? 'a-font--11 a-text--left a-text--red mt-2 mr-2'
                                            : 'hidden'
                                    }
                                    dangerouslySetInnerHTML={{ __html: this.generateInvoiceCodeError() }}
                                />
                                {order.isExitedInOtherParner && <p className="a-font--11 text-warning mt-2 mr-2">{t('order_detail.exist_other_partner')}</p>}
                            </div>
                        </div>
                    ) : null}

                    {invoiceSources.map((invoiceCode, index) => {
                        let invoiceCode_status = lodash.get(invoiceCode, 'status', '')
                        return (
                            <React.Fragment key={index}>
                                <div key={index} className="a-purchasod__group--code">
                                    <CopyToClipboard
                                        text={invoiceCode.invoice_code}
                                        onCopy={() => {
                                            bootbox.success(t('message.copied') + ': ' + invoiceCode.invoice_code)
                                        }}>
                                        <i className="far fa-copy copy" />
                                    </CopyToClipboard>
                                    {showLinkNormal ? (
                                        <a href={invoiceCode.link} target="_blank" rel="noopener noreferrer" title={invoiceCode.invoice_code}>
                                            {' '}
                                            {invoiceCode.invoice_code}
                                        </a>
                                    ) : (
                                        <a
                                            onClick={e => this.handleOpenLinkTaobaoPurchaseOrder(e, invoiceCode.invoice_code)}
                                            rel="noopener noreferrer"
                                            title={invoiceCode.invoice_code}>
                                            {' '}
                                            {invoiceCode.invoice_code}
                                        </a>
                                    )}

                                    {invoiceCode_status ? (
                                        <span className="mr-2 font-weight-normal a-text--color-black-45">[{invoice_status[invoiceCode_status]}]</span>
                                    ) : (
                                        ''
                                    )}
                                    {order.canRemoveInvoiceCodes ? (
                                        <i className="ion-android-close remove" onClick={this.openModalConfirmDelete.bind(this, invoiceCode.invoice_code)} />
                                    ) : null}
                                </div>
                                <p
                                    className={
                                        this.generateInvoiceCodeError() !== 'PAYMENT_REQUEST_EXIST' ? 'hidden' : 'a-font--11 a-text--left a-text--red mt-2 mr-2'
                                    }
                                    dangerouslySetInnerHTML={{ __html: t(`order_detail.validate_message.${this.generateInvoiceCodeError()}`) }}
                                />
                            </React.Fragment>
                        )
                    })}
                    {(order.canApprovalAddInvoiceCode || order.canApprovalDeleteInvoiceCode) && (
                        <ProposedChangeOriginalInvoice className="a-purchasod__group--label a-purchasod__group--label--invoice">
                            <Button type="primary" onClick={this.openModalProposedChangeOriginalInvoice}>
                                {t('orderDetail:label.proposed_change_original_invoice')}
                            </Button>
                        </ProposedChangeOriginalInvoice>
                    )}
                </div>
                <ModalTransition>
                    {modalConfirmPaidInvoiceCode && (
                        <ModalConfirmPaidInvoiceCode
                            isLoading={isLoading}
                            title={t('account_purchaser.header_modal_change_status')}
                            message={trans('order_detail.confirm_deleting_paid_invoice_code', { invoice_code: <strong>{invoice_code_delete}</strong> })}
                            invoice_code={invoice_code_delete}
                            onClose={this.closeModalConfirmPaidInvoiceCode.bind(this)}
                            onConfirm={this.onConfirmDeletingPaidInvoiceCode.bind(this)}
                        />
                    )}
                    {isOpenModalConfirmDelete && (
                        <ModalConfirmDelete
                            isLoading={isLoading}
                            title={t('account_purchaser.header_modal_change_status')}
                            message={t('order_detail.modal_confirm_delete', {
                                invoice_code: invoice_code_delete,
                            })}
                            onClose={this.closeModal.bind(this)}
                            onConfirm={this.onDeleteInvoiceCode.bind(this)}
                        />
                    )}
                    {(order.canApprovalAddInvoiceCode || order.canApprovalDeleteInvoiceCode) && (
                        <ModalProposedChangesInvoiceCode
                            visible={isOpenModalProposeChange}
                            onCloseModal={this.closeModalProposedChangeOriginalInvoice}
                            order={order}
                            invoice_status={invoice_status}
                        />
                    )}
                </ModalTransition>
            </div>
        )
    }
}

InvoiceCode.defaultProps = {
    order: {},
    errorInvoiceCode: {},
    isLoading: false,
    isEndProcess: {
        postInvoiceCode: false,
        deleteInvoiceCode: false,
    },
}

InvoiceCode.propTypes = {
    order: PropTypes.object,
    onUpdateShippingFee: PropTypes.func,
    beginProcessPostInvoiceCode: PropTypes.func,
    beginProcessDeleteInvoiceCode: PropTypes.func,
    onPostInvoiceCode: PropTypes.func,
    isLoading: PropTypes.bool,
    isEndProcess: PropTypes.object,
    errorInvoiceCode: PropTypes.object,
    deleteInvoiceCode: PropTypes.func,
    updateInvoiceCode: PropTypes.func,
}

export default translate('translations')(InvoiceCode)
