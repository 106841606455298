import React from "react";
import PropTypes from "prop-types";
import {Col, Row, Icon, Switch} from "antd";
import {Form} from "antd/lib/form";

class AgencyItem extends React.Component {

    handleClick = e => {
        let { onSetUpdatedAgency, agency } = this.props;
        onSetUpdatedAgency(agency);
    }

    render() {
        let {agency} = this.props;
        return <Row className="group-line pt-3 pb-3">
            <Col span={5}>
                <span className="a-text--color-black a-text--bold">{agency.name}</span>
            </Col>
            <Col span={3}>
                {
                    agency.color &&
                    <span
                        className="a-text--center a-text--fz-12 p-1"
                        style={{background: agency.color, color: "#fff"}}
                    >{agency.color}</span>
                }
            </Col>
            <Col span={2}>{agency.code}</Col>
            <Col span={2}>{agency.alias}</Col>
            <Col span={3}>{agency.caresoft_domain}</Col>
            <Col span={4}>{agency.caresoft_access_token}</Col>
            <Col span={2}>
                {
                    agency.invoice_code_alphabet ? <Icon type="check" style={{color: "green"}}/> : <Icon type="close" style={{color: "red"}}/>
                }
            </Col>
            <Col span={2}>
                {
                    agency.freight_bill_alphabet ? <Icon type="check" style={{color: "green"}}/> : <Icon type="close" style={{color: "red"}}/>
                }
            </Col>
            <Col span={1} className="a-text--right">
                <span className="a--cursor-poiter" onClick={this.handleClick}><Icon type="edit" /></span>
            </Col>
        </Row>;
    }
}

AgencyItem.defaultProps = {
    agency: {},
    onSetUpdatedAgency: () => {}
}

AgencyItem.propTypes = {
    agency: PropTypes.object.isRequired,
    onSetUpdatedAgency: PropTypes.func.isRequired
}

export default AgencyItem;
