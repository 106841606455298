import lodash from 'lodash';
import {processApiRequest} from '../../common/saga';
import * as ACTION from './constants';
import apiService from '../apiService';
import {t} from '../../../systems/i18n';
import bootbox from './../../common/components/Bootbox/bootbox';

export function* attachAlipay(action) {
    yield processApiRequest(
        ACTION.ATTACH_ALIPAY,
        () => apiService.attachAlipay(action.payload.complaintId, action.payload.alipayId),
        action.payload
    );
}
/* eslint-disable */
export function* onAttachAlipaySuccess() {
    bootbox.success(t('complaintSeller:alipay.message_attach_success'));
}

export function* onDetachAlipaySuccess() {
    bootbox.success(t('complaintSeller:alipay.message_detach_success'));
}

export function* onAttachAlipayFailed(action) {
    let errors = action.payload.data || {};

    if (lodash.get(errors, 'id_alipay.exists_complaint')) {
        return yield bootbox.error(t('complaintSeller:alipay.message_already_attached'));
    }

    yield bootbox.error(t('complaintSeller:alipay.message_attach_failed'));
}

export function* detachAlipay(action) {
    yield processApiRequest(
        ACTION.DETACH_ALIPAY,
        () => apiService.detachAlipay(action.payload.complaintId, action.payload.alipayId),
        action.payload
    );
}

export function* onDetachAlipayFailed(action) {
    let errors = action.payload.data || {};
    if (lodash.isEmpty(errors)) {
        return;
    }

    if (lodash.get(errors, 'id_alipay.empty_complaint')) {
        return yield bootbox.error(t('complaintSeller:alipay.message_already_detached'));
    }

    yield bootbox.error(t('complaintSeller:alipay.message_detach_failed'));
}
