import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'antd';
import {SCANNING_STATUS} from '../constants';

function ScanningStatus(props) {
    switch (props.status) {
        case SCANNING_STATUS.SCANNING:
            return <Icon type="info-circle" theme="filled" className="a-text--warning" title={props.scanningMessage}/>;
        case SCANNING_STATUS.SUCCESS:
            return <Icon type="check-circle" theme="filled" className="a-text--success"/>;
        case SCANNING_STATUS.ERROR:
            return <Icon type="exclamation-circle" theme="filled" className="a-text--error"/>;
        default:
            return '--';
    }
}

ScanningStatus.defaultProps = {
    status: '',
    scanningMessage: null,
};

ScanningStatus.propTypes = {
    status: PropTypes.string,
    scanningMessage: PropTypes.string,
};

export default ScanningStatus;

