import React from 'react'
import lodash from 'lodash'
import { translate } from 'react-i18next'
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down'
import PropTypes from 'prop-types'
import Copy from './../../../common/components/Copy'
import SelectPurchaser from './SelectPurchaser'
import { Collapse } from 'react-collapse'
import CustomerInfo from './customerinfo'
import Waypoint from 'react-waypoint'
import RateDeposit from './MoreInfoComponents/ChangeRateDeposit'
import VoteShop from './VoteShop'
// import Tooltip from '@atlaskit/tooltip';
import { Col, Row, Icon, Tooltip, notification, Select } from 'antd'
import CallByCaresoft from './CallByCaresoft'
import axios from 'axios'
import cookies from 'react-cookies'
import moment from 'moment'
import queryString from 'query-string'
import { url } from '../../../../systems/routing'
import authService from '../../../Auth/authService'
import SiteIcon from '../../../Image/SiteIcon'
import SelectPurchaseAddress from '../../../PurchaseAddress/component/SelectPurchaseAddress'
import InlinePurchaseAddress from './InlinePurchaseAddress'
import { can } from '../../../Auth/middleware/authorize'
import * as PERMISSION from '../../../Permission/permissions'
import SellerNote from './SellerNote'
import apiService from '../apiService'
import { processResponse } from '../../../../systems/api'
import { hasPermission, permissions } from '../../../Permission'

class billInfo extends React.Component {
    constructor(props) {
        super(props)

        // Dùng call lên caresoft không được bỏ
        this.careSoftToken = cookies.load('caresoft_token') || ''
        this.state = {
            staffs: [],
        }
    }

    componentDidMount() {
        // Kết nối caresoft không được xóa
        let { order } = this.props
        if (order.careSoftToken && order.caresoftDomain) this.initCaresoft()

        window.addEventListener('message', this.handleMessage)
        if (hasPermission(permissions.ORDER_VIEW_SALE_CONTACT)) {
            this.getStaffs()
        }
    }

    getStaffs() {
        const { order } = this.props
        const orderId = lodash.get(order, 'id', 0)
        if (orderId) {
            processResponse(apiService.getBuyerStaffs(orderId))
                .then(res => {
                    const data = lodash.get(res, 'data.staffs', [])
                    this.setState({
                        staffs: data,
                    })
                })
                .catch(err => {
                    notification.error({ message: 'Lấy thông tin nhân viên CSKH không thành công!' })
                })
        }
    }

    /*-------------------Begin caresoft-------------------*/
    initCaresoft = () => {
        let { order } = this.props
        let { caresoftDomain } = order

        if (this.careSoftToken) {
            if (!window.isCaresoftInit) window.csInit(this.careSoftToken, caresoftDomain)
        } else {
            this.loginCaresoft()
        }
    }

    loginCaresoft = () => {
        let { order } = this.props
        let { careSoftToken, caresoftDomain } = order

        axios
            .post('https://capi.caresoft.vn/ali/thirdParty/login', { token: careSoftToken })
            .then(res => {
                let status = lodash.get(res, 'data.code', '')
                if (status === 'ok') {
                    this.careSoftToken = careSoftToken
                    cookies.save('caresoft_token', careSoftToken, {
                        path: '/',
                        expires: moment().add(7, 'd').toDate(),
                    })
                    window.csInit(this.careSoftToken, caresoftDomain)
                }
            })
            .catch(error => {
                notification.error({ message: 'Đăng nhập caresoft không thành công' })
            })
    }

    handleMessage = message => {
        let payload = message.data

        switch (payload.type) {
            case window.CARESOFT_EVENTS.CARESOFT_ERROR_INIT:
                this.loginCaresoft()
                break
            default:
                break
        }
    }

    /*-------------------End caresoft-------------------*/

    render() {
        let { t, order, updateOrder, isDesktopSmall, isOpenBillInfo } = this.props
        let policies = lodash.get(order, 'policies', [])
        const sellerInfo = lodash.get(order, 'seller', {})
        const canViewContact = lodash.get(order, 'canViewContact', false)
        const isCNAddress = lodash.get(order, 'isCnAddress', false)
        const { staffs } = this.state
        const orderDetail = lodash.get(order, "orderDetail", {})
        const self_negotiate = lodash.get(orderDetail, "self_negotiate", false)
        const canChangeRateDeposit = order.canChangeRateDeposit && ((!!self_negotiate && authService.can(PERMISSION.SELF_NEGOTIATE_UPDATE_PRODUCT)) || !self_negotiate);

        return (
            <div>
                <div className="a-purchasod__block--bill__header">
                    <div className="a-purchasod__block--bill__header_info w-100 mb-2">
                        {canViewContact && (
                            <>
                                <span className="a-text--blue">
                                    <label className="bill__header__label">KH: </label>
                                    <a
                                        href={order.partner_url_user_detail}
                                        style={{ textDecoration: 'none' }}
                                        target={'_blank'}>
                                        {order.buyer_name}(@{order.buyer_username})
                                    </a>
                                </span>
                                <span className="seperate-v">
                                    {order.careSoftToken && order.caresoftDomain ? (
                                        <CallByCaresoft
                                            phone={order.buyer_phone_mark}
                                            careSoftToken={order.careSoftToken}
                                            caresoftDomain={order.caresoftDomain}
                                        />
                                    ) : (
                                        order.buyer_phone_mark
                                    )}
                                </span>
                            </>
                        )}

                        <span className={`a-text--bold ${canViewContact ? 'seperate-v' : ''}`}>{order.destination_warehouse}</span>

                        <span className="ml-4 d-flex">
                            <label className="bill__header__label">
                                {isDesktopSmall ? (
                                    <Tooltip title={t('order_detail.shop')}>
                                        <i className="fas fa-shopping-bag"></i>
                                    </Tooltip>
                                ) : (
                                    t('order_detail.shop') + ':'
                                )}
                            </label>
                            <SiteIcon
                                className="mr-2 ml-2 img-shop"
                                width="16px"
                                src={order.seller_source}
                                alt="Avatar"
                            />
                            <span>{order.seller_name}</span>
                            {authService.can(PERMISSION.SUPPLIER_WARNING) && !lodash.isEmpty(sellerInfo) && (
                                <div className="ml-2">
                                    <SellerNote
                                        sellerInfo={sellerInfo}
                                        updateSellerNote={this.props.updateSellerNote}
                                    />
                                </div>
                            )}
                        </span>
                        {/*https://amos.alicdn.com/getcid.aw?v=3&groupid=${order.order_group_id}&s=1&charset=utf-8&uid=${order.seller_aliwang}&site=cntaobao*/}
                        {!lodash.isEmpty(order.seller_aliwang) && (
                            <a
                                target="_blank"
                                href={`https://amos.alicdn.com/getcid.aw?v=3&s=1&charset=utf-8&uid=${order.seller_aliwang}&site=cntaobao`}
                                className="ww-inline ww-online ml-2"
                                style={{ marginTop: '-2px' }}>
                                <img
                                    border="0"
                                    className=""
                                    src={'http://amos.alicdn.com/realonline.aw?v=2&uid=' + order.seller_aliwang + '&site=cntaobao&s=1&charset=utf-8'}
                                    title={t('order_detail.chat_with_sell')}
                                    alt={'avatar'}
                                />
                            </a>
                        )}
                        {order.seller_name && order.seller && (
                            <VoteShop
                                seller={order.seller}
                                className="ml-2"
                            />
                        )}

                        <div className="seperate-v">
                            <label className="bill__header__label">
                                {isDesktopSmall ? (
                                    <Tooltip content={t('order_detail.account_buyer')}>
                                        <i className="fas fa-user-tag"></i>
                                    </Tooltip>
                                ) : (
                                    'TK mua: '
                                )}
                            </label>
                            <div style={{ display: 'inline-block' }}>
                                <SelectPurchaser
                                    order={order}
                                    options={this.props.accountPurchasersOptions}
                                    onUpdate={accountPurchaserId =>
                                        updateOrder({
                                            id: order.id,
                                            id_purchaser: accountPurchaserId,
                                        })
                                    }
                                    selected={order.purcharseSelected}
                                />
                            </div>
                            <Waypoint
                                onEnter={() => this.props.handleHidden()}
                                onLeave={() => this.props.handleShow()}
                            />
                        </div>

                        <div className="seperate-v">
                            <label className="bill__header__label mr-1">
                                {isDesktopSmall ? (
                                    <Tooltip title={t('order_detail.purchase_warehouse')}>
                                        <i className="fas fa-warehouse"></i>
                                    </Tooltip>
                                ) : (
                                    t('order_detail.purchase_warehouse') + ':'
                                )}
                            </label>
                            {isCNAddress ? (
                                '---'
                            ) : (
                                <InlinePurchaseAddress
                                    order={order}
                                    getPurchasingOrderDetailAction={this.props.getPurchasingOrderDetailAction}
                                />
                            )}
                        </div>
                        <div className="seperate-v">
                            <label className="bill__header__label mr-1">
                                {isDesktopSmall ? (
                                    <Tooltip title={t('order_detail.deposit_rate')}>
                                        <i className="fas fa-balance-scale"></i>
                                    </Tooltip>
                                ) : (
                                    t('order_detail.deposit_rate') + ': '
                                )}
                            </label>
                            <div style={{ display: 'inline-block' }}>
                                <RateDeposit
                                    rate_deposit={order.rate_deposit_text}
                                    onChange={this.props.changeRateDeposit}
                                    orderId={order.id}
                                    canChangeRateDeposit={canChangeRateDeposit}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Collapse isOpened={isOpenBillInfo}>
                    <Row>
                        <Col span={8}>
                            <div className="a-purchasod__group">
                                <span className="a-purchasod__group--label mr-1">{t('order_detail.purchaser')}:</span>
                                <span className="a-purchasod__group--value">{order.teller ? `${order.teller.name} (@${order.teller.username})` : '---'}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="a-purchasod__group">
                                <span className="a-purchasod__group--label mr-1">{t('order_detail.deposit')}:</span>
                                <span className="a-purchasod__group--value">{lodash.get(order, 'partner.time_deposit_full', '--')}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="a-purchasod__group">
                                <span className="a-purchasod__group--label mr-1">{t('order_detail.exchange_rate')}:</span>
                                <span className="a-purchasod__group--value a-text--bold a-text--red">
                                    {order.rate_exchange_text} {t('order:vnd')}
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <div className="a-purchasod__group">
                                <span className="a-purchasod__group--label mr-1">{t('order_detail.paid_staff')}:</span>
                                <span className="a-purchasod__group--value">
                                    {order.paidStaff ? `${order.paidStaff.name} (@${order.paidStaff.username})` : '---'}
                                </span>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className="a-purchasod__group">
                                <span className="a-purchasod__group--label mr-1">{t('order_detail.policy')}:</span>
                                <span className="a-purchasod__group--shop">
                                    {policies.length > 0
                                        ? policies.map(policy => (
                                              <>
                                                  {/*<div className={'a-group--inline'}>*/}
                                                  {/*    <p>{policy.policy_name}</p>*/}
                                                  {/*</div>*/}
                                                  {policy.policy_name}
                                              </>
                                          ))
                                        : '---'}
                                </span>
                            </div>
                        </Col>
                        {hasPermission(PERMISSION.ORDER_VIEW_SALE_CONTACT) && (
                            <Col span={8}>
                                <div className="a-purchasod__group">
                                    <span className="a-purchasod__group--label mr-1">{t('orderDetail:label.customer_service')}:</span>
                                    <span className="a-purchasod__group--value">{staffs.length ? lodash.map(staffs, 'staff').join(', ') : '---'}</span>
                                </div>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="a-purchasod__group">
                                <span className="a-purchasod__group--label mr-1">{t('order_detail.chinese_purchasing_address')}: </span>
                                <span
                                    className={
                                        (lodash.get(order, 'purchase_address.address', '') === '' ? 'a-text--bold' : 'a-text--red') +
                                        ' a-purchasod__group--value a-text--font-primary'
                                    }>
                                    {lodash.get(order, 'purchase_address.address', '') === '' ? (
                                        '---'
                                    ) : (
                                        <Copy contentNote={lodash.get(order, 'purchase_address.address', '')} />
                                    )}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Collapse>
            </div>
        )
    }
}

billInfo.defaultProps = {
    dataOrder: {},
}

billInfo.propTypes = {
    dataOrder: PropTypes.object,
}

export default translate('translations')(billInfo)
