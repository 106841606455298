import React from "react";
import {Input, Modal, Divider, Icon} from "antd";
import notification from '../../../systems/notification';
import {translate} from 'react-i18next';
import {dateFormatter} from "../../common/services/format";
import apiService from "../apiService";
import {processResponse} from "../../../systems/api";
import {events} from "../../../systems/events";
import TRANSACTION_EVENT from "../events";
import lodash from "lodash";
import authService from "../../Auth/authService";
import * as PERMISSION from "../../Permission/permissions";

class MarkTransaction extends React.Component {

    constructor({props}) {
        super(props);
        this.state = {
            visible: false,
            loading: false,
            reason: ""
        };
    }

    showModal = (e) => {
        e.preventDefault();
        this.setState({visible: true});
    }

    handleOk = e => {
        e.preventDefault();
        this.setState({loading: true});

        let { transaction, t } = this.props;

        let res = apiService.mark(transaction.id, {note: this.state.reason});
        res.finally(() => {
            this.setState({visible: false, loading: false});
        });

        let process = processResponse(res);

        process.then(res => {
            notification.success(t('transaction:message.mark_transaction_success'));
            events.dispatch(TRANSACTION_EVENT.MARKED, {
                transaction: {...transaction, ...(res.data || {})},
            });
        });

        process.catch(() => {
            notification.error(t('transaction:message.mark_transaction_failed'));
        });
    }

    handleCancel = e => {
        this.setState({visible: false});
    }

    render() {
        let {transaction, t} = this.props;
        let is_ok_note = transaction.unusual_type !== "" ? t(`transaction:reasons.${transaction.unusual_type}`) : transaction.is_ok_note;
        let is_ok_creator = transaction.unusual_type !== "" ? t('transaction:creator.system') : lodash.get(transaction, 'is_ok_creator.name');
        if (authService.can(PERMISSION.SUPPLIER_TRANSACTION_MARK_UNUSUAL) && transaction.is_ok) {
            return <div className="description-trade a-font--12 a-text--gray-new">
                <p><a href="#" className="a-text--gray-new a-text--hover-underline" onClick={this.showModal}>Đánh dấu giao dịch bất thường</a></p>
                <Modal
                    centered={true}
                    title={t('transaction:label.mark_title')}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    cancelText="Hủy"
                    okText="Mark"
                    okButtonProps={{disabled: (this.state.reason.trim() === "") ? true : false, loading: this.state.loading}}
                >
                    <span className="pb-1" style={{display: "block"}}>{t('transaction:label.mark_reason')} <span className="a-text--red">*</span></span>
                    <Input.TextArea
                        rows={5}
                        value={this.state.reason}
                        onChange={e => { this.setState({...this.state, reason: e.target.value})}}
                        placeholder={t('transaction:placeholder.enter_mark')} />
                </Modal>
            </div>;
        }

        if (transaction.is_ok_creator_id > 0) {
            return <div className="description-trade a-font--12 a-text--gray-new">
                <p className="a-text--gray-new">
                    <span>{t('transaction:label.resolve_reason')}: {transaction.is_ok_note}</span>
                    <Divider type="vertical"/>
                    <span>{t('transaction:label.resolver')}: {lodash.get(transaction, 'is_ok_creator.name')}</span>
                    <Divider type="vertical"/>
                    <span>{dateFormatter.dateTime(transaction.is_ok_time)}</span>
                </p>
            </div>;
        }

        return "";

    }
}

export default translate()(MarkTransaction);
