import lodash from 'lodash';
import {apiWithToken} from '../../systems/api';
import {API_URL} from './../../systems/globalContant';
import {ORDER_STATUS} from '../../systems/globalContant/actionContant';

const normalizeQueryListOrder = params => {
    if (params.status === ORDER_STATUS.ALL) {
        params = lodash.omit(params, ['status']);
    }

    if (params.total_item_link_operation === '>') {
        params.total_item_link_from = params.total_item_link;
    }
    if (params.total_item_link_operation === '<') {
        params.total_item_link_to = params.total_item_link;
    }

    return lodash.omit(params, [
        'total_item_link_operation',
        'total_item_link',
    ]);
};

export default {
    fetchPurchases: (params) => apiWithToken.get(API_URL.FETCH_PURCHASES, {
        params: normalizeQueryListOrder(normalizeQueryListOrder(params)),
        singleRequest: true,
        loading: false
    }),
    getPurchaserOrderStats: (params) => apiWithToken.get(API_URL.DEFAULT + '/orders/teller-stats', {
        params: normalizeQueryListOrder(normalizeQueryListOrder(params)),
        singleRequest: true,
        loading: false
    }),
    createDistributorOrder: (datas) => apiWithToken.post(API_URL.DISTRIBUTOR_ORDER, {...datas}, {loading: false}),
    fetchSellerSource: () => apiWithToken.get(API_URL.LIST_SELLER_SOURCE, {loading: false}),
};