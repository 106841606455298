export const GET_ME = {
    REQUEST: 'GET_ME_REQUEST',
    SUCCESS: 'GET_ME_SUCCESS',
    FAILED: 'GET_ME_FAILED',
    ERROR: 'GET_ME_ERROR',
};

export const IPPHONE = {
    UPDATE: "IPPHONE_UPDATE"
}

export const LOGOUT = {
    REQUEST: 'LOGOUT_REQUEST',
    SUCCESS: 'LOGOUT_SUCCESS',
    FAILED: 'LOGOUT_FAILED'
};

export const GET_LIST_PURCHASER = {
    REQUEST: 'GET_LIST_PURCHASER_REQUEST',
    SUCCESS: 'GET_LIST_PURCHASER_SUCCESS',
    FAILED: 'GET_LIST_PURCHASER_FAILED',
    ERROR: 'GET_LIST_PURCHASER_ERROR',
};

export const FETCH_USERS = {
    REQUEST: 'USER.FETCH_USERS.REQUEST',
    SUCCESS: 'USER.FETCH_USERS.SUCCESS',
    FAILED: 'USER.FETCH_USERS.FAILED',
};

export const FETCH_ACCOUNT = {
    REQUEST: 'FETCH_ACCOUNT_REQUEST',
    SUCCESS: 'FETCH_ACCOUNT_SUCCESS',
    FAILED: 'FETCH_ACCOUNT_FAILED',
    ERROR: 'FETCH_ACCOUNT_ERROR',
};

export const FETCH_USERS_COMPLAINT_SELLER = {
    REQUEST: 'USER.FETCH_USERS_COMPLAINT_SELLER.REQUEST',
    SUCCESS: 'USER.FETCH_USERS_COMPLAINT_SELLER.SUCCESS',
    FAILED: 'USER.FETCH_USERS_COMPLAINT_SELLER.FAILED',
};

export const USER_SOURCE = {
    taobao: 'taobao',
    tmall: 'tmall',
    '1688': '1688',
};
