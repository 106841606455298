import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import lodash from 'lodash';
import reducers from '../../reducers';
import sagas from '../../sagas';
import {createBrowserHistory}  from 'history';

let composeEnhancers = process.env.NODE_ENV === 'production' ? null : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
composeEnhancers = composeEnhancers || compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(
    sagaMiddleware
)));

sagaMiddleware.run(sagas);

const dispatch = store.dispatch;

const history = createBrowserHistory ();

const getState = (key = null, defaults = null) => {
    return key ? lodash.get(store.getState(), key, defaults) : store.getState();
};

export {
    store,
    dispatch,
    getState,
    history,
};