import React from 'react';
import {BreadcrumbsStateless, BreadcrumbsItem} from '@atlaskit/breadcrumbs';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';

class Breadcrumb extends React.PureComponent {
    render() {
        let {breadcrumb, t} = this.props;
        return (
            <div className="pageHeader-breadcrumbs">
                <BreadcrumbsStateless>
                    {breadcrumb.map(breadcrumbDetail => (
                        <BreadcrumbsItem
                            key={breadcrumbDetail.label}
                            href={breadcrumbDetail.link}
                            text={t(breadcrumbDetail.label)}
                        />
                    ))}
                </BreadcrumbsStateless>
            </div>
        );
    }
}

Breadcrumb.defaultProps = {
    breadcrumb: []
};

Breadcrumb.propTypes = {
    breadcrumb: PropTypes.array.isRequired,
};

export default translate('translations')(Breadcrumb);