import React from 'react';
import {translate} from 'react-i18next';
import Button from '@atlaskit/button';
import PropTypes from 'prop-types';
import {trans} from '../../../../../systems/i18n';
import lodash from 'lodash';
import Input from '../../../../common/components/Input/Super';
import {currentFormatter} from './../../../../common/services/format';
import ModalGeneral from './../../../../common/components/ModalGeneral';

class ModalConfirmChangePrice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            unit_price: null,
            reason: "",
            isLoading: false,
            errorDuplicate: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        state.isLoading = nextProps.isLoading;

        if (lodash.get(nextProps, "item.id", 0) !== prevState.id) {
            state.id = lodash.get(nextProps, "item.id", 0);
            state.unit_price = null;
            state.reason = "";
            state.isLoading = false;
        }

        return state;
    }

    /**
     * handle action thay đổi value của các input trong form
     * @param event
     */
    onChangeInput(event) {
        let {target} = event;
        if (this.renderError(target.name) !== null) {
            this.props.clearError([target.name]);
        }
        this.setState({
            [target.name]: target.value
        });
    }

    onKeyUpPrice(event) {
        let unit_price = lodash.get(this.props, 'item.unit_price', 0);
        unit_price = parseFloat(unit_price);
        let unit_price_key = parseFloat(event.target.value);
        this.setState({
            unit_price: unit_price_key,
            errorDuplicate: unit_price === unit_price_key
        });
    }

    /**
     * handle action thay đổi value của các unit price
     * @param value
     */
    onChangePrice(value) {
        this.props.clearError(["unit_price"]);
        this.setState({
            "unit_price": value
        });
    }

    /**
     * clear error unit price
     */
    onClearPriceError() {
        this.props.clearError(["unit_price"]);
    }

    /**
     * xử lý hành động submit form
     */
    onSubmitForm() {
        let formData = Object.assign(this.state, {});
        this.setState({isLoading: true});
        this.props.onSubmitForm({...formData, reason: formData.reason.trim()});
    }

    /**
     * Enter submit
     * @param event
     */
    onKeyPress(event) {
        if (event.key === 'Enter') {
            this.onSubmitForm();
        }
    }

    /**
     * Thực hiện render lỗi thông báo ra bên ngoài
     * @param attrName
     * @returns {null}
     */
    renderError(attrName) {
        let {error, t} = this.props;
        let errorItem = lodash.get(error, attrName, {});
        attrName = attrName === "unit_price" ? "unit_price_label" : attrName;
        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('orderDetail:order_detail.error.' + Object.keys(errorItem)[0], {name: t('orderDetail:order_detail.' + attrName)});
                case 'unique':
                    return t('orderDetail:order_detail.error.' + Object.keys(errorItem)[0], {name: t('orderDetail:order_detail.' + attrName)});
                case 'max.string':
                    let objectmax = lodash.get(errorItem, 'max.string', null);
                    let overLength = lodash.get(objectmax, 'max', null);
                    return t('orderDetail:order_detail.error.max_string', {
                        name: t('orderDetail:order_detail.' + attrName),
                        value: overLength
                    });
                case 'min.string':
                    let objectmin = lodash.get(errorItem, 'min.string', null);
                    let length = lodash.get(objectmin, 'min', null);
                    return t('orderDetail:order_detail.error.min_string', {
                        name: t('orderDetail:order_detail.' + attrName),
                        value: length
                    });
                case "status_invalid":
                    return t('orderDetail:order_detail.error.status_invalid');
                case 'max.numeric':
                    let objectmaxNumber = lodash.get(errorItem, 'max.numeric', null);
                    let overLengthNumber = lodash.get(objectmaxNumber, 'max', null);
                    overLengthNumber = lodash.toInteger(overLengthNumber);
                    overLengthNumber = currentFormatter.toLocaleStringCurrency(overLengthNumber);
                    return t('orderDetail:order_detail.error.max_numeric', {
                        name: t('orderDetail:order_detail.' + attrName),
                        value: overLengthNumber+'¥'
                    });
                default:
                    return null;
            }
        }
        return null;
    }

    render() {
        let {onClose, t, item, isLoading} = this.props;
        let {reason, errorDuplicate} = this.state;
        return (
            <ModalGeneral>
                <div className={"a-modal__title"}>
                    <p>
                        {trans("orderDetail:order_detail.edit_unit_price_header", {
                            item: <span
                                className={"a-text--font-blue a-text--bold"}>#{item.partner_order_item_id}</span>
                        })}
                    </p>
                </div>
                <div className={"row"}>
                    <div className={"col col-sm-12 col-md-6 col-lg-6 p-0"}>
                        <div className={'a-modal--cfprice--left'}>
                            <label htmlFor="name"
                                   className="a-label a-text--font-primary a-text--bold">{t("orderDetail:order_detail.current_unit_price_label")}</label>
                            <p>{item.unit_price_text}</p>
                        </div>
                    </div>
                    <div className={"col col-sm-12 col-md-6 col-lg-6 text-right p-0"}>
                        <div className={'a-modal--cfprice--right'}>
                            <label htmlFor="name"
                                   className="a-label a-text--font-primary a-text--bold">{t("orderDetail:order_detail.new_unit_price_label")}
                                <span className="a-text--red">*</span></label>
                            <br/>
                            <Input
                                negative={false}
                                onKeyUp={this.onKeyUpPrice.bind(this)}
                                className={(this.renderError("unit_price") === null || errorDuplicate ? "" : "input--error ") + "input text-right input--price"}
                                name={"unit_price"}
                                unit_price={true}
                                onChange={this.onClearPriceError.bind(this)}
                                onBlur={this.onChangePrice.bind(this)}
                                onKeyPress={event => this.onKeyPress(event)}
                            />
                            <br/>
                            {errorDuplicate ? (
                                <span
                                    className={"a-text--red a-font--10"}>
                                    {t('orderDetail:order_detail.error.price_duplicate')}
                                </span>
                            ) : null}

                            <span
                                className={this.renderError("unit_price") === null ? "hidden" : "a-text--red a-font--10"}>{this.renderError("unit_price")}</span>
                            <span
                                className={this.renderError("status") === null ? "hidden" : "a-text--red a-font--10"}><br/>{this.renderError("status")}</span>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12 p-0"}>
                        <div className="a-modal--cfprice--body">
                            <label htmlFor="name"
                                   className="a-label a-text--font-primary a-text--bold pr-2">{t("orderDetail:order_detail.reason_unit_price")}</label>
                            <textarea
                                name={"reason"}
                                onChange={this.onChangeInput.bind(this)}
                                className={(this.renderError("reason") === null ? "" : "input--error " ) + 'a-textarea--cfprice'}
                                rows={'4'}
                                value={reason}
                            />
                            <span className="a-text--red a-font--10">{this.renderError("reason")}</span>
                        </div>
                    </div>
                </div>
                <div className="a--group--inline-end mt-2 mb-3 a--user-group-btn">

                    <Button appearance="primary" type="submit"
                            className={"mr-3 a-btn--submit " + (errorDuplicate ? "a-btn--disable" : "")}
                            onClick={this.onSubmitForm.bind(this)}
                            isLoading={isLoading}
                            isDisabled={errorDuplicate}
                    >
                        {trans('account_purchaser.btn_action_confirm')}
                    </Button>
                    <a appearance="subtle" onClick={onClose}
                       className={(isLoading ? "hidden " : "") + "a-btn a-btn--none a-btn-link "}>
                        {trans('account_purchaser.btn_action_cancel')}
                    </a>
                </div>
            </ModalGeneral>
        );
    }
}

ModalConfirmChangePrice.defaultProps = {
    item: {
        item_id: 0,
        unit_price_text: '¥ 0',
        unit_price: 0,
        partner_order_item_id: 0
    },
    isLoading: false,
    error: {}
};

ModalConfirmChangePrice.propTypes = {
    item: PropTypes.object,
    onSubmitForm: PropTypes.func,
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.object
};

export default translate('translations')(ModalConfirmChangePrice);
