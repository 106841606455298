import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {url} from './../../../../systems/routing/index';
import FormProcessing from './../../../common/components/FormProcessing';
import onClickOutside from "react-onclickoutside";
import Link from "../../../../systems/routing/Link";
import {Col} from "antd";

class ListComplaintSeller extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            isOpenForm: false,
        };
    }

    /**
     * handle việc đóng popup form lại
     */
    openModalForm = () => {
        let {isOpenForm} = this.state;
        this.setState({isOpenForm: !isOpenForm});
    }

    handleClickOutside = evt => {
        this.setState({
            isOpenForm: false,
        });
    };

    render() {
        let {t, complaintSeller} = this.props;
        let {isOpenForm} = this.state;
        return (
            <Col span={24} className="item_option hidden" onClick={this.openModalForm.bind(this)}>
                <span></span>
                {isOpenForm ?
                    <ul className="item_option__detail">
                        <li>
                            <Link isNewTab={true}
                                  href={url.to('complaint-seller.detail', {id: complaintSeller.id})}
                                  className="link_item">
                                {t('complaintSeller:list.detail')}
                            </Link>
                        </li>
                    </ul>
                    :
                    ""
                }
            </Col>
        );
    }
}

ListComplaintSeller.propTypes = {
    onRef: PropTypes.func,
    onUpdate: PropTypes.func
};

export default translate('translations')(onClickOutside(ListComplaintSeller));
