import {combineReducers} from 'redux';
import * as ACTION from './constants';

let addingPackage = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.CREATE_PACKAGE.REQUEST:
            return true;

        case ACTION.CREATE_PACKAGE.SUCCESS:
        case ACTION.CREATE_PACKAGE.FAILED:
            return false;

        default:
            return state;
    }
};

let countAddedPackage = (state = 0, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.CREATE_PACKAGE.SUCCESS:
            return state + 1;

        default:
            return state;
    }
};

let deletingPackage = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.DELETE_PACKAGE.REQUEST:
            return true;
        case ACTION.DELETE_PACKAGE.SUCCESS:
        case ACTION.DELETE_PACKAGE.FAILED:
            return false;

        default:
            return state;
    }
};

let isModalProcessing = (state = false, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.BEGIN_DELETE_PACKAGE:
        case ACTION.DELETE_PACKAGE.REQUEST:
        case ACTION.DELETE_PACKAGE.FAILED:
            return true;
        case ACTION.DELETE_PACKAGE.SUCCESS:
            return false;
        default:
            return state;
    }
};


export default combineReducers({
    addingPackage,
    countAddedPackage,
    deletingPackage,
    isModalProcessing,
});
