import React from 'react';
import {translate} from 'react-i18next';
import Modal from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import Select from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import PropTypes from 'prop-types';
import {trans} from '../../../../../systems/i18n';
import lodash from 'lodash';
import {DISTRIBUTE_STATUS} from './../../../DistributeOrder/constants';
import FormProcessing from '../../../../common/components/FormProcessing';
import DistributeOrdersResult from "./DistributeOrdersResult";

class ModalFormAssign extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                id_teller: 0,
                purchaserId: 0,
            },
            inputVersion: 0,
            changedParams: [],
        }
    }

    renderError(attrName) {
        let {error, t} = this.props;
        let errorItem = lodash.get(error, attrName, {});
        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('order:list.error.' + Object.keys(errorItem)[0], {name: t('order:list.' + attrName)});
                default:
                    return null;
            }
        }
    }

    /**
     * Entern submit
     * @param event
     */
    onKeyPress(event) {
        if (event.key === 'Enter') {
            this.onSubmitForm();
        }
    }

    onSubmitForm() {
        let {input} = this.state;
        let {distributeOrderStatus} = this.props;

        if (input.id_teller && distributeOrderStatus !== DISTRIBUTE_STATUS.LOADING) {
            this.props.onSubmitFormAssign(input.id_teller);
        }
    }

    filterOrders(distribute_status) {
        return lodash.filter(this.props.orders, {distribute_status});
    }

    makePurchaserOptions() {
        return this.props.purchasers.map(purchaser => ({
            value: purchaser.id,
            label: `${purchaser.username} ${purchaser.name ? ' - ' + purchaser.name : ''}`,
        }));
    }

    onChangePurchaser(option) {
        this.changeInput({id_teller: option ? option.value : null});
    }

    render() {
        let {
            onClose, t, onCloseAfterSuccess,
            orderIds, orderIdsRefuses, distributeOrderStatus, isLoading
        } = this.props;
        let {input} = this.state;
        let id_teller = lodash.get(input, 'id_teller', '');
        id_teller = lodash.toInteger(id_teller);
        let successOrders = this.filterOrders(DISTRIBUTE_STATUS.SUCCESS);
        let failedOrders = this.filterOrders(DISTRIBUTE_STATUS.FAILED);
        let purchaserOptions = this.makePurchaserOptions();

        return (
            <Modal scrollBehavior="outside"  >
                <div className={"a-modal__title"}>
                    <p className="order-assign">
                        {t("order:distribute_order.tellers_assign")}
                    </p>
                </div>
                {orderIds.length === orderIdsRefuses.length ? (
                    <div className='a-group'>
                        <label style={{fontWeight: '400'}} className={"label a-label a-text--red"}>
                            {orderIds.length ?
                                t('order:distribute_order.unassign_purchaser_has_assign') :
                                t('order:distribute_order.must_choose_order')}
                        </label>

                        <div className="a--group--inline-end p-2 a--user-group-btn">
                            <a appearance="subtle" onClick={onCloseAfterSuccess}
                               className={"a-btn a-btn--none a-btn-link "}>
                                {trans('order:distribute_order.close')}
                            </a>
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        <div className='a-group'>
                            <label style={{fontWeight: '400'}}
                                   className={"label a-label  a-text--font-primary " + (orderIds.length ? '' : 'a-label-error')}>
                                {orderIds.length ?
                                    trans("order:distribute_order.tellers_assign_notice", {
                                        quantity: <b>{orderIds.length}</b>,
                                        order: <b>{t('order:distribute_order.order_title')}</b>
                                    })
                                    :
                                    t('order:distribute_order.must_choose_order')
                                }
                            </label>
                            {orderIds.length ? (
                                <Select
                                    noOptionsMessage={() => t("system.no_option")}
                                    value={lodash.find(purchaserOptions, {value: input.id_teller}) || null}
                                    options={purchaserOptions}
                                    isDisabled={[DISTRIBUTE_STATUS.LOADING, DISTRIBUTE_STATUS.FINISH].includes(distributeOrderStatus)}
                                    onChange={this.onChangePurchaser.bind(this)}
                                    onKeyDown={event => this.onKeyPress(event)}
                                    placeholder=""
                                    className="single-react mt-2"
                                    classNamePrefix="react-select--normal"
                                />
                            ) : null}


                            <div className='a-group mt-2'>
                                {successOrders.length ? (
                                    <DistributeOrdersResult
                                        orders={successOrders}
                                        title={t("order:distribute_order.orders_assign_success", {total: successOrders.length})}
                                        color='success'/>
                                ) : null}

                                {failedOrders.length ? (
                                    <DistributeOrdersResult
                                        orders={failedOrders}
                                        title={t("order:distribute_order.orders_assign_failed", {total: failedOrders.length})}
                                        color='danger'
                                    />
                                ) : null}

                                {orderIdsRefuses.length ? (
                                    <DistributeOrdersResult
                                        orders={orderIdsRefuses}
                                        title={t("order:distribute_order.orders_has_assign", {
                                            total_selected: orderIds.length,
                                            total: orderIdsRefuses.length,
                                        })}
                                        color='warning'
                                    />
                                ) : null}
                            </div>
                        </div>
                        <div className="a--group--inline-end p-2 a--user-group-btn">
                            {distributeOrderStatus === DISTRIBUTE_STATUS.LOADING ? (
                                <span>
                            <Spinner/>&nbsp;&nbsp;&nbsp;
                        </span>
                            ) : null}

                            {distributeOrderStatus !== DISTRIBUTE_STATUS.FINISH && orderIds.length ? (
                                <Button
                                    appearance="primary"
                                    type="submit"
                                    className={"mr-3 a-btn--submit " + ( distributeOrderStatus === DISTRIBUTE_STATUS.LOADING || !id_teller ? "a-btn--disable" : "")}
                                    onClick={this.onSubmitForm.bind(this)}
                                    loading={isLoading || false}
                                >{t('account_purchaser.btn_action_confirm')}</Button>
                            ) : null}

                            {distributeOrderStatus === DISTRIBUTE_STATUS.FINISH ?
                                <React.Fragment>
                                    <a appearance="subtle" onClick={onCloseAfterSuccess}
                                       className={"a-btn a-btn--none a-btn-link "}>
                                        {trans('order:distribute_order.close')}
                                    </a>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <a appearance="subtle"
                                       onClick={onClose}
                                       className={"a-btn a-btn--none a-btn-link "
                                       + (distributeOrderStatus === DISTRIBUTE_STATUS.LOADING ? "isDisableda-btn-link" : "")}>
                                        {orderIds.length === 0 ?
                                            trans('order:distribute_order.close') : trans('account_purchaser.btn_action_cancel')
                                        }
                                    </a>
                                </React.Fragment>
                            }
                        </div>
                    </React.Fragment>
                )}
            </Modal>
        );
    }
}

ModalFormAssign.defaultProps = {
    purchasers: [],
    orderIdsRefuses: [],
    orderIds: [],
    isLoading: false,
    error: {}
}

ModalFormAssign.propTypes = {
    purchasers: PropTypes.array,
    orderIdsRefuses: PropTypes.array,
    orderIds: PropTypes.array,
    onSubmitForm: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.object
}

export default translate('translations')(ModalFormAssign);
