import {combineReducers} from 'redux';
import lodash from 'lodash';
import * as ACTION from './constants';
import {setCollectionItem} from '../../common/services/helps';

let complaints = (state = [], action) => {
    let {type, payload} = action;

    switch (type) {
        case ACTION.FAILED_COMPLAINT.REQUEST:
            return setCollectionItem(state, payload.id, {loading: true, error: null});

        case ACTION.FAILED_COMPLAINT.SUCCESS:
            return setCollectionItem(state, action.request.id, {loading: false});

        case ACTION.FAILED_COMPLAINT.FAILED:
            return setCollectionItem(state, action.request.id, {loading: false, error: payload.data});

        case ACTION.RESET_PARAM_ERROR:
            return state.map(complaint => {
                if (complaint.id !== payload.id) {
                    return complaint;
                }

                return {
                    ...complaint,
                    error: lodash.omit(complaint.error, payload.param),
                };
            });

        case ACTION.CLEAR_STATE:
            return state.filter(complaint => complaint.id !== payload.id);

        default:
            return state;
    }
};

export default combineReducers({
    complaints,
});
