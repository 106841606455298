import React from "react";
import notification from '../../../systems/notification';
import {t} from '../../../systems/i18n/index.js';
import lodash from 'lodash';
import {trans} from "../../../systems/i18n/index";

export function* uploadFileSuccess(action) {
    let filename = <span className="a-text--break-all">{lodash.get(action, 'payload.file.name', '')}</span>;
    yield notification.success(<span>{trans('transaction:message.upload_success', {filename: filename})}</span>);
}

export function* uploadFileFailed(action) {
    let responseFile = lodash.get(action, "payload.file.response.data.file", "");
    if (responseFile === "file is edited"){
        return yield  notification.error(t('transaction:message.upload_failed_edited', {filename: lodash.get(action, 'payload.file.name', '')}));
    }

    if (lodash.get(action, "payload.file.response.data.filename", "") === "INVALID"){
        return yield  notification.error(t('transaction:message.upload_failed_filename', {filename: lodash.get(action, 'payload.file.name', '')}));
    }

    let account_invalid = lodash.get(action, "payload.file.response.data.account_invalid", "");
    if (account_invalid){
        return yield  notification.error(t('transaction:message.upload_failed_account', {account: account_invalid}));
    }

    return yield  notification.error(t('error_page.permission_not_allow', {filename: lodash.get(action, 'payload.file.name', '')}));
}
