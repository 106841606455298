import React from 'react';
import {translate} from 'react-i18next';
import moment from 'moment';
import * as PropTypes from "prop-types";
import lodash from 'lodash';
import vi from 'javascript-time-ago/locale/vi';
import en from 'javascript-time-ago/locale/en';
import ReactTimeAgo from 'react-time-ago/no-tooltip';
import JavascriptTimeAgo from 'javascript-time-ago';
import onClickOutside from "react-onclickoutside";
import FormProcessing from './../../common/components/FormProcessing';

JavascriptTimeAgo.locale(vi);
JavascriptTimeAgo.locale(en);

class Item extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            input: {},
            inputVersion: 0,
            changedParams: [],
            linkDownloadCsv: null,
            isOpen: false
        }
    }

    /**
     * thay dổi format để không bị lôi trên trình duyệt safari
     * @param input
     * @returns {Date}
     * @private
     */
    _parseDate(input) {
        if (typeof input === 'string') {
            let format = "yyyy-mm-dd hh:MM:ss";
            let parts = input.match(/(\d+)/g),
                i = 0, fmt = {};
            format.replace(/(yyyy|dd|mm|hh|MM|ss)/g, function (part) {
                fmt[part] = i++;
            });
            return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']], parts[fmt['hh']], parts[fmt['MM']], parts[fmt['ss']]);
        } else {
            return input;
        }
    }

    /**
     * Open khi có nhiều user
     * @param event
     */
    handleOpen(event) {
        event.preventDefault();
        this.setState({isOpen: !this.state.isOpen})
    }

    /**
     * Ẩn danh sách user liên quan
     * @param evt
     */
    handleClickOutside = evt => {
        this.setState({
            isOpen: false
        })
    };

    render() {
        let {t, data} = this.props;
        let {isOpen} = this.state;
        data.created_at = this._parseDate(data.created_at);
        let purchasers = lodash.get(data, 'purchasers', []);
        let purchaserFirst = lodash.head(purchasers);
        let purchaserses = lodash.slice(purchasers, 1, purchasers.length);
        return (
            <React.Fragment>
                <li>
                    <div className="history__left">
                        <div className="history__left__top ">
                          <div className="history_is_dropdown">
                              <a href="javascript:;">
                                  {data.all_purchaser ?
                                      (<React.Fragment>
                                          {t('sale:form.all_purchaser')}
                                          {purchasers.length > 0 ?
                                              (
                                                  <React.Fragment>
                                                    <span onClick={this.handleOpen.bind(this)}>
                                                        &nbsp;(+{purchasers.length})
                                                    </span>
                                                  </React.Fragment>
                                              ) : null}
                                      </React.Fragment>)
                                      :
                                      (<React.Fragment>
                                          {lodash.get(purchaserFirst, 'username', '---')}
                                          {purchaserses.length > 0 ?
                                              (
                                                  <React.Fragment>
                                                    <span onClick={this.handleOpen.bind(this)}>
                                                        &nbsp;(+{purchaserses.length})
                                                    </span>
                                                  </React.Fragment>
                                              ) : null}
                                      </React.Fragment>
                                      )}
                              </a>
                              <ul className={"history__left__top__detail " + (isOpen ? "" : "hidden")}>
                                  {data.all_purchaser ?
                                      (
                                      <React.Fragment>
                                          {purchasers.map(purchaserItem => (
                                              <li key={data.id+'_'+purchaserItem.id}>
                                                  <span>{purchaserItem.username}</span>
                                              </li>)
                                          )
                                          }
                                      </React.Fragment>
                                      )
                                      :
                                      (<React.Fragment>
                                      {purchaserses.map(purchaserItem => (
                                          <li key={data.id+'_'+purchaserItem.id}>
                                              <span>{purchaserItem.username}</span>
                                          </li>)
                                      )}
                                  </React.Fragment>)
                                  }
                            </ul>
                            <span>{data.month_text+'-'+data.year_text || null}</span>
                          </div>
                        </div>
                        <div className="history__left__bottom ">
                            <i className="far fa-clock"></i>
                            <span>
                                <ReactTimeAgo
                                    tooltipClassName=""
                                    tooltip={false}
                                    locale={localStorage.getItem('i18nextLng') || 'en'}>
                                    {new Date(this._parseDate(data.created_at) || moment.now())}
                                </ReactTimeAgo>
                            </span>
                        </div>
                    </div>
                    <div className="history__right">
                        <i className="fas fa-cloud-download-alt"
                           onClick={()=> window.open(data.file, "_blank")}/>
                    </div>
                </li>

            </React.Fragment>
        );
    }
}


Item.defaultProps = {
    data: {},
};

Item.propTypes = {
    data: PropTypes.object,
};

export default translate('translations')(onClickOutside(Item));
