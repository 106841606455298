import {createSelector} from 'reselect';
import lodash from 'lodash';
import {
    COMPLAINT_SELLER_STATUS
} from './../../systems/globalContant/actionContant';
import {dateFormatter, currentFormatter} from "../common/services/format";
import complaintService from './complaintService';
import {ORDER_STATUS} from './../Order/constants';

const complaintSellerReaonsLists = state => lodash.get(state, 'ComplaintSeller.listComplaintSellerReason', []);
const complaintSellerLists = state => lodash.get(state, 'ComplaintSeller.listComplaintSeller.complaintSellers', []);
const statsSelector = state => lodash.get(state, 'ComplaintSeller.listComplaintSeller.stats', {});
const complaintDetailSelector = state => lodash.get(state, 'ComplaintSeller.complaintDetail.complaint', {});
const orderSelector = state => lodash.get(state, 'ComplaintSeller.complaintDetail.order', {});

const makeUrlRelatedComplaints = (complaint, order) => {
    let query = {
        time_type: COMPLAINT_SELLER_STATUS.WAITING,
        orderBy: 'created_at',
        sort: 'desc',
    };

    return {
        urlShop: complaintService.urlList({
            ...query,
            order_seller_name: order.seller_name,
            order_seller_source: [order.seller_source],
        }),
        urlOrder: complaintService.urlList({
            ...query,
            code: order.code,
        }),
    };
};

export const getState = (state, param, defaultValue) => lodash.get(state, 'ComplaintSeller.complaintDetail.' + param, defaultValue);
export const getOrderAlipays = state => getState(state, 'orderAlipays', []);

export const getComplaintSellerReason = createSelector(
    complaintSellerReaonsLists,
    items => items.map((item) => buildComplaintSellerReason(item))
);

export const getAlipays = createSelector(
    complaintDetailSelector,
    getOrderAlipays,
    orderSelector,
    (complaint, orderAlipays, order) => orderAlipays.map(alipay => {
        let inComplaint = alipay.id_complaint_seller === complaint.id;
        let balanceVnd = parseFloat(alipay.balance) * parseFloat(order.rate_exchange);

        return {
            ...alipay,
            in_complaint: inComplaint,
            can_mapping: (alipay.transaction_type === 'REFUND' && (!alipay.id_complaint_seller || inComplaint)),
            transaction_time_format: dateFormatter.dateTime(alipay.transaction_time),
            balance_format: currentFormatter.toLocaleStringCurrency(alipay.balance, 2) + '¥',
            balance_vnd: balanceVnd,
            balance_vnd_format: currentFormatter.toLocaleStringCurrency(balanceVnd, 2) + 'đ',
        };
    })
);

export const getComplaintSellerInfo = createSelector(
    complaintDetailSelector,
    orderSelector,
    (items, order) => {
        if (lodash.isEmpty(items)) {
            return items;
        }

        let historyTime = lodash.get(items, 'historyTime', []);
        let statusArr = Object.values(COMPLAINT_SELLER_STATUS);
        let icon = false;
        let dateFormat = '';
        let historyTimeSelected = historyTime.filter(historyTimeItem => historyTimeItem.status === items.status);
        if (historyTimeSelected.length) {
            historyTimeSelected = lodash.head(historyTimeSelected);
        } else {
            historyTimeSelected = {};
        }

        let historyTimeText = statusArr.map(item => {
            historyTimeSelected = historyTime.filter(historyTimeItem => historyTimeItem.status === item);
            icon = false;
            if (item === COMPLAINT_SELLER_STATUS.WAITING) {
                icon = true;
            }
            if (historyTimeSelected.length) {
                historyTimeSelected = lodash.head(historyTimeSelected);
                dateFormat = dateFormatter.dateTime(historyTimeSelected.created_at);
            } else {
                dateFormat = "---";
            }

            return {status: item, icon: icon, dateFormat: dateFormat}
        });

        let receiver = lodash.get(items, 'receiver', {});
        if (!lodash.isEmpty(receiver)) {
            receiver = {label: receiver.username, value: receiver.id};
        }

        return {
            ...items,
            ...makeUrlRelatedComplaints(items, order),
            ...{
                historyTimeSelected: historyTimeSelected,
                historyTimeText: historyTimeText,
                amountFormat: currentFormatter.toLocaleStringCurrency(parseFloat(items.amount, 10), 5),
                amountEarned: currentFormatter.toLocaleStringCurrency(parseFloat(items.amount_earned, 10), 5),
                canChangeSuccess: true,
                canMappingAlipay: items.status !== COMPLAINT_SELLER_STATUS.WAITING,
                selected_receiver: receiver,
                renewal_count: items.renewal_count ? items.renewal_count : '---',
            }
        }
    }
);

export const getOrderInfo = createSelector(
    orderSelector,
    items => {
        let site_name = lodash.get(items, 'purchaser.site_name', null);

        if (lodash.isArray(site_name)) {
            site_name = ' - ' + lodash.join(site_name, ',');
        } else {
            if (site_name !== null) {
                site_name = "" + site_name;
                site_name = site_name.toLowerCase();
            } else {
                site_name = "-";
            }
        }

        let alipayTransactions = lodash.get(items, 'alipayTransactions', []);
        alipayTransactions = alipayTransactions.map(item => {
            let dateFormat = dateFormatter.dateTime(item.created_at);
            return {
                ...item,
                ...{
                    created_at_text: dateFormat,
                    balance_text: item.balance ? currentFormatter.toLocaleStringCurrency(item.balance, 5) : '---',
                    balance: item.balance ? parseFloat(item.balance) : 0,
                }
            }
        });
        if (items.status === ORDER_STATUS.BOUGHT || items.status === ORDER_STATUS.OUT_OF_STOCK) {
            items.total_item_qty_received = "---";
        }
        return {
            ...items,
            ...{
                alipayTransactions: alipayTransactions,
                rate_deposit_text: Math.round(items.rate_deposit * 100) || 0,
                rate_exchange_text: items.rate_exchange ? currentFormatter.toLocaleStringCurrency(items.rate_exchange) : '---',
                code: items.code || '---',
                purcharseSelected: items.id_purchaser ? {
                    label: items.purchaser.account + (lodash.get(items, 'purchaser.site_name', null) ? site_name : ''),
                    value: items.id_purchaser
                } : {
                    label: '',
                    value: ''
                },

                total_link: lodash.get(items, 'items', []).length,
                total_qty: function () {
                    let total = 0;
                    lodash.get(items, 'items', []).map(item => {
                        total += item.qty || 0;
                        return item;
                    });
                    return total;
                },
                total_qty_bought: function () {
                    let total = 0;
                    lodash.get(items, 'items', []).map(item => {
                        total += item.qty_bought || 0;
                        return item;
                    });
                    return total;
                },
                invoiceCodes: lodash.filter(lodash.get(items, 'invoiceCodes', []), {
                    'is_deleted': 0,
                    'id_partner': items.id_partner
                }),
            }
        };
    }
);

export const getComplaintSeller = createSelector(
    complaintSellerLists,
    items => items.map((item) => buildComplaintSeller(item))
);

function buildComplaintSellerReason(complaintSellerReason) {
    return {label: complaintSellerReason.title, value: complaintSellerReason.id}
}

function buildComplaintSeller(complaintSeller) {
    let historyTimes = lodash.get(complaintSeller, "complaint_seller_history_time", []);
    historyTimes.map(history => {
        complaintSeller['log_time_status_' + history.status] = dateFormatter.dateTime(history.created_at);
        return history;
    });
    return {
        ...complaintSeller,
        receiver_username: lodash.get(complaintSeller, 'receiver.username', '--'),
        account_purchaser_code: lodash.get(complaintSeller, 'purchaser.account', '--'),
        reason_title: lodash.get(complaintSeller, 'reason.title', '--'),
        order_code: lodash.get(complaintSeller, 'order.code', ''),
        order_seller_source: lodash.get(complaintSeller, 'order.seller_source', ''),
        order_seller_name: lodash.get(complaintSeller, 'order.seller_name', '--'),

        amountFormat: complaintSeller.amount !== '' ? currentFormatter.toLocaleStringCurrency(parseFloat(complaintSeller.amount, 5), 5) : '---',
        amountEarned: complaintSeller.amount_earned !== '' ? currentFormatter.toLocaleStringCurrency(parseFloat(complaintSeller.amount_earned, 10), 5) : '---',
        renewal_count: complaintSeller.renewal_count ? complaintSeller.renewal_count : '---',
    };
}

/**
 * Get stats status
 */
export const getStats = createSelector(
    statsSelector,
    items => buildStats(items)
);

let complaintStatus = [
    {
        code: COMPLAINT_SELLER_STATUS.WAITING,
        total: 0,
        className: '',
        countable: true,
    },
    {
        code: COMPLAINT_SELLER_STATUS.PROCESSING,
        total: 0,
        className: '',
        countable: true,
    },
    {
        code: COMPLAINT_SELLER_STATUS.SUCCESS,
        total: 0,
        className: 'bought',
        countable: false,
    },
    {
        code: COMPLAINT_SELLER_STATUS.FAILURE,
        total: 0,
        className: 'danger',
        countable: false,
    }
];

function buildStats(stats) {
    return complaintStatus.map(orderStats => {
        if (orderStats.code === COMPLAINT_SELLER_STATUS.ALL) {
            let total = 0;
            complaintStatus.map(object => {
                if (object.countable) {
                    total += stats[object.code] ? stats[object.code] : 0;
                }

                return null;
            });
            return {...orderStats, total: total}
        }
        return {...orderStats, total: stats[orderStats.code]}
    });
}

const sortUsers = users => lodash.sortBy(users, user => lodash.toLower(user.username));

export const getUsers = state => sortUsers(state.User.users);

export const getPurchasers = createSelector(
    getUsers,
    users => users.map(user => {
        if (user.is_teller) {
            return buildPurchasers(user);
        }

        return false;
    }).filter(item => item)
);

function buildPurchasers(purchaser) {
    return {label: purchaser.username, value: purchaser.id}
}

export const getUsersComplaintSeller = state => sortUsers(state.User.users_complaint_seller);
export const getUsersComplaintSellerReceive = createSelector(
    getUsersComplaintSeller,
    users => users.map(user => {
        return buildPurchasers(user);
    }).filter(item => item)
);
