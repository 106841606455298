import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from "lodash";

class SyncError extends React.Component{
    generateErrors(errors) {
        let msg;
        let {t} = this.props;
        let unauthorized = 'NeedUserAuthenticated';

        if (typeof errors === 'string') {
            if (errors === unauthorized) {
                msg = t('orderDetail:order_detail.sync_order_bill_no.errors.' + unauthorized);
            } else {
                msg = errors
            }
        } else {
            Object.keys(errors).map(idx => {
                if (idx === unauthorized) {
                    msg = idx;
                }
                if (idx === 'errorMessage') {
                    msg = lodash.get(errors,'errorMessage','');
                }

                return idx;
            });
        }
        return msg;
    }
    render() {
        return <div>{this.generateErrors(this.props.errors)}</div>;
    }
}

SyncError.defaultProps = {
    errors: [],
};

SyncError.propTypes = {
    errors: PropTypes.array,
};

export default translate()(SyncError);

