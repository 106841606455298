import React from 'react';
import {translate} from 'react-i18next';
import FormFilter from './FormFilter';
import UserItem from './UserItem';
import {Layout} from "antd";

const Content = Layout.Content;

class ListPurchaser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        username: '',
        group: 'purchaser',
      }
    };
  }

  onChangeFilter(filter) {
    this.setState({filter: {...this.state.filter, ...filter}});
  }

  getActiveUsers() {
    let {filter} = this.state;

    return this.props.users.filter(user => {
      return (
        this.matchUserByUsername(user, filter.username)
        && this.matchUserByGroup(user, filter.group)
      );
    });
  }

  matchUserByUsername(user, username) {
    username = username ? username.trim() : '';

    return username
      ? user.username.toLowerCase().indexOf(username.toLowerCase()) !== -1
      : true;
  }

  matchUserByGroup(user, group) {
    switch (group) {
      case 'purchaser':
        return !!user.is_teller;
      case 'user':
        return !user.is_teller;
      default:
        return true;
    }
  }

  render() {
    let {t} = this.props;
    let activeUsers = this.getActiveUsers();

    return (
        <Layout>
          <Content className="a-content--ant-layout">
            <div className="a-content__right">
              <div className="a-content--box-shadow">
                <FormFilter
                    input={this.state.filter}
                    onChange={this.onChangeFilter.bind(this)}
                />
              </div>
              <div className="a-content--box-shadow mt-3">
                <table className="table t-table--user">
                  <thead>
                  <tr>
                    <td className="text-uppercase font-weight-bold">
                      {t('purchaser:label.account')}
                    </td>
                    <td className="text-uppercase font-weight-bold" style={{width: 200}}>
                      {t('purchaser:label.group')}
                    </td>
                    <td className="text-center font-weight-bold" style={{width: 100}}>#</td>
                  </tr>
                  </thead>
                  <tbody>
                  {activeUsers.map(user => (
                      <UserItem
                          key={user.id}
                          user={user}
                          onAttachPurchaser={() => this.props.onAttachPurchaser(user.id)}
                          onDetachPurchaser={() => this.props.onDetachPurchaser(user.id)}
                      />
                  ))}
                  </tbody>
                </table>

                {!activeUsers.length ? <div>{t('message.no_record')}</div> : null}
              </div>
            </div>
          </Content>
        </Layout>
    );
  }
}

export default translate()(ListPurchaser);
