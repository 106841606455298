import {createSelector} from 'reselect'
import MediaServicesRectangleIcon from "@atlaskit/icon/glyph/media-services/rectangle";
import lodash from 'lodash';

const purchaseAddressSelector = state => state.PurchaseAddress || [];
const purchaseAddressesSelectors = state => state.PurchaseAddress.list || [];

/**
 * Get purchase address list
 */
export const getPurchaseAddressListSelector = createSelector(
    purchaseAddressSelector,
    purchaseAddress => {
        let list = purchaseAddress.list.map(dataItem=> {
            return {
                ...dataItem, ...{
                    before: MediaServicesRectangleIcon
                }
            };
        });

        return [
            {
                title: 'purchase-address',
                items: list,
            }
        ];
    }
);

/**
 * Get purchase address create errors
 */
export const getCreateErorrsSelector = createSelector(
    purchaseAddressSelector,
    purchaseAddress => purchaseAddress.createErrors
);

/**
 * Get purchase address detail
 */
export const getPurchaseAddressDetailSelector = createSelector(
    purchaseAddressSelector,
    purchaseAddress => purchaseAddress.detail
);


/**
 * List account purchasers options
 */
export const getListPurchaserAddressOptions = createSelector(
    purchaseAddressesSelectors,
    purchaseAddresses => lodash.orderBy(purchaseAddresses, ['id'],['desc']).map(purchaseAddress => {
        return {
            value: purchaseAddress.id,
            label: purchaseAddress.alias + '-' + purchaseAddress.address,
            className:'react-select--purchaseaddress__option'
        }
    })
);
