import {createSelector} from 'reselect';
import lodash from 'lodash';
import moment from 'moment';
import {dateFormatter} from '../../common/services/format';
import {LOG_TYPE} from './constants';

const logsSelector = state => state.CommonLog.logs || [];
const commentsSelector = state => state.CommonLog.comments || [];
const filterSelector = state => state.CommonLog.filter || {};

const getLogType = (log) => {
    if (log.action === LOG_TYPE.COMMENT || log.action === LOG_TYPE.COMPLAINT_COMMENT || log.action === LOG_TYPE.PRODUCT_RETURN_COMMENT) {
        return LOG_TYPE.COMMENT;
    }
    if (log.action === 'COMMENT.ADD_NOTE_ITEM') {
        return LOG_TYPE.COMMENT;
    }
    return lodash.get(log, 'creator.type') === 'SYSTEM' ? LOG_TYPE.LOG : LOG_TYPE.ACTION;
};

const activeLogsSelector = createSelector(
    logsSelector,
    commentsSelector,
    filterSelector,
    (logs, comments, filter) => [...logs, ...comments].filter(log => {
        let {type} = filter;
        let logType = getLogType(log);

        if (!type) {
            return true;
        }

        return (type === LOG_TYPE.COMMENT)
            ? logType === LOG_TYPE.COMMENT
            : [LOG_TYPE.LOG, LOG_TYPE.ACTION].includes(logType);
    })
);

export const getLogs = createSelector(
    activeLogsSelector,
    (logs) => {
        return lodash.sortBy(logs, log => lodash.parseInt(log.time) * -1)
            .map(log => ({
                ...log,
                time_format: dateFormatter.dateTime(moment.unix(log.time)),
            }));
    }
);
