import React, { useEffect, useState } from 'react'
import { Button, Icon, Modal, notification, Row, Table } from 'antd'
import { translate } from 'react-i18next'
import { dispatch } from '../../../../systems/store'
import { setActiveSettingItem } from '../../SettingMenu/actions'
import { setBreadCrumb, setMenuActive, setPageDescription, setPageTitle } from '../../../common/actions'
import { processResponse } from '../../../../systems/api'
import apiService from '../apiService'
import lodash from 'lodash'
import ModalGeneral from '../../../common/components/ModalGeneral'
import { hasPermission, permissions } from '../../../Permission'
import { trans } from '../../../../systems/i18n'

function ConnectForeign(props) {
    const { t } = props
    const [loading, setLoading] = useState(false)
    const [connecting, setConnecting] = useState(false)
    const [data, setData] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [visible, setVisible] = useState(false)
    const [accountRemove, setAccountRemove] = useState('')
    const [loadingRemove, setLoadingRemove] = useState(false)
    const permission = hasPermission(permissions.TAOBAO_ACCOUNT_REMOVE)
    const [loadingNewApp, setLoadingNewApp] = useState(false)
    const [useNewApp, setUseNewApp] = useState(false)
    const [connectApp, setConnectApp] = useState(false);

    useEffect(() => {
        dispatch(setPageTitle(t('layout.title.connect_foreign')))
        dispatch(setPageDescription(t('layout.description.connect_foreign')))
        dispatch(setMenuActive('connect_foreign'))
        dispatch(
            setBreadCrumb([
                {
                    label: 'breadcrumb.home',
                    link: '/',
                },
                {
                    label: 'breadcrumb.connect_foreign',
                    link: '/connect-foreign/',
                },
            ])
        )
        setActiveSettingItem('connect-foreign-market')
        getListAccount()
    }, [])

    function handleOnClick(params = {}) {
        if (useNewApp === "1") {
            Modal.confirm({
                title: 'Xác nhận',
                content: `Bạn chắc chắn muốn kết nối tài khoản trên app ${lodash.get(params, 'app') === 'new' ? 'mới' : 'cũ'}`,
                cancelText: 'Hủy',
                okText: 'Đồng ý',
                onOk: () => {
                    if (lodash.get(params, 'app') === 'new') {
                        setLoadingNewApp(true)
                    } else {
                        setConnecting(true)
                    }

                    return processResponse(apiService.connectTaobao(params))
                        .then(res => {
                            window.location = res.data.url
                        })
                        .catch(error => {
                            const errorCode = lodash.get(error, 'code', '')
                            if (errorCode === 'INPUT_INVALID' && lodash.get(params, 'app') === 'new') {
                                notification.error({ message: 'Kết nối thất bại kiểm tra lại cấu hình ứng dụng!' })
                            } else {
                                notification.error({ message: 'Kết nối không thành công!' })
                            }
                        })
                        .finally(() => {
                            setConnecting(false)
                            setLoadingNewApp(false)
                        })
                },
                icon: <Icon type="question-circle" theme="filled" />,
            })
        } else {
            setConnecting(true)

            return processResponse(apiService.connectTaobao(params))
                .then(res => {
                    window.location = res.data.url
                })
                .catch(error => {
                    const errorCode = lodash.get(error, 'code', '')
                    if (errorCode === 'INPUT_INVALID' && lodash.get(params, 'app') === 'new') {
                        notification.error({ message: 'Kết nối thất bại kiểm tra lại cấu hình ứng dụng!' })
                    } else {
                        notification.error({ message: 'Kết nối không thành công!' })
                    }
                })
                .finally(() => {
                    setConnecting(false)
                })
        }
    }

    function getListAccount() {
        setLoading(true)
        processResponse(apiService.getListAccountConnectedTaobao())
            .then(res => {
                setData(lodash.get(res, 'data.accounts', []))
                const userAccount = lodash.get(res, 'data.user_account', '')
                setUseNewApp(lodash.get(res, 'data.use_new_app', ''))
                setConnectApp(lodash.get(res, 'data.connect_app', ''));

                if (userAccount) {
                    setSelectedRowKeys([userAccount])
                }
            })
            .catch(() => {
                notification.error({ message: t('message.cant_fetch_data') })
            })
            .finally(() => setLoading(false))
    }

    function setAccount(account) {
        if (account) {
            processResponse(apiService.setAccountTaobao({ account }))
                .then(() => {
                    setSelectedRowKeys([account])
                    getListAccount()
                    notification.success({ message: t('message.update_data_success', { attribute: 'Chọn tài khoản kết nối' }) })
                })
                .catch(() => {
                    notification.error({ message: t('message.cant_update_data', { attribute: 'Chọn tài khoản kết nối' }) })
                })
        }
    }

    const rowSelection = {
        type: 'radio',
        selectedRowKeys,
        onChange: selectedRowKeys => {
            setAccount(selectedRowKeys[0])
        },
    }

    const columns = [
        {
            className: 'text-center',
            title: 'Tài khoản',
            render: (text, record) => <p>{record}</p>,
        },
        {
            className: 'text-center',
            title: 'Chức năng',
            render: (text, record) => {
                return (
                    permission && (
                        <span onClick={() => handleShowConfirm(record)} className="p-3  a-text--pointer text-delete">
                            <Icon type="delete" />
                        </span>
                    )
                )
            },
        },
    ]

    const handleShowConfirm = account => {
        setAccountRemove(account)
        setVisible(true)
    }

    const handleHideConfirm = () => {
        setAccountRemove('')
        setVisible(false)
    }

    const handleRemoveAccount = () => {
        setLoadingRemove(true)

        apiService
            .removeAccount({ account: accountRemove })
            .then(res => {
                const account_error = lodash.get(res, 'data.data.account')

                if (account_error === 'connect_another_account') {
                    notification.error({ message: 'Bạn cần phải kết nối với tài khoản khác trước' })
                } else {
                    getListAccount()
                    setVisible(false)
                    notification.success({ message: 'Xóa tài khoản thành công' })
                }
            })
            .catch(error => {
                const account_error = lodash.get(error, 'response.data.data.account')

                if (account_error === 'connect_another_account') {
                    notification.error({ message: 'Bạn cần phải kết nối với tài khoản khác trước' })
                } else {
                    notification.error({ message: 'Xóa tài khoản thất bại' })
                }
            })
            .finally(() => setLoadingRemove(false))
    }

    return (
        <div className="a-content__right new-setting-private">
            <div className="a-setting__block a-content--box-shadow ">
                {connectApp === "1" ? ( <Row className="d-flex  justify-content-end" gutter={20}>
                        {useNewApp === "1" ? (
                            <>
                                <Button  onClick={handleOnClick} loading={connecting} className="mr-4" type="danger">
                                    Kết nối theo app cũ
                                </Button>
                                <Button type="primary" onClick={() => handleOnClick({ app: 'new' })} loading={loadingNewApp}>
                                    Kết nối theo app mới
                                </Button>
                            </>
                        ) : (
                            <Button type="primary" onClick={handleOnClick} loading={connecting} className="mr-2">
                                Kết nối TaoBao Global
                            </Button>
                        )}
                    </Row> ) : ""}
            </div>

            <div className="a-content--box-shadow">
                <Table
                    loading={loading}
                    rowKey={record => record}
                    title={() => 'Danh sách tài khoản đã kết nối TaoBao Global'}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    locale={{ emptyText: t('message.no_data') }}
                />
            </div>

            {visible && (
                <ModalGeneral>
                    <div className={'a-modal__title'}>
                        <p>{t('orderDetail:order_detail.cancel_popup.title')} </p>
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>{trans('setting:message.confirm_remove_account_st', { username: <b>{accountRemove}</b> })}</div>
                    <div className={'a--group--inline-end p-2 a--user-group-btn '}>
                        <Button className={' mr-3 a-btn--submit btn-submit'} onClick={handleRemoveAccount} loading={loadingRemove}>
                            {t('orderDetail:order_detail.cancel_popup.btn_submit')}
                        </Button>
                        <a appearance="subtle" onClick={handleHideConfirm} className={loadingRemove ? 'hidden' : 'a-btn a-btn--none'}>
                            {t('orderDetail:order_detail.cancel_popup.btn_cancel')}
                        </a>
                    </div>
                </ModalGeneral>
            )}
        </div>
    )
}

export default translate()(ConnectForeign)
