import React from "react";
import lodash from "lodash";
import {Empty, Table} from "antd";
import {translate} from "react-i18next";
import apiService from "../../../modules/PurchaseBuying/OrderDetail/apiService";
import {processResponse} from "../../../systems/api";
import {url} from "../../../systems/routing";
import Link from "../../../systems/routing/Link";
import {currentFormatter, dateFormatter} from "../../../modules/common/services/format";
import {Tooltip} from "antd";
const { Column } = Table;

class ListComplaintSeller extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount() {
        let {dataOrder, t} = this.props;
        let res = apiService.getComplaintSellerByOrder(dataOrder.id);
        let process = processResponse(res);
        process.then(res => {
            this.setState({
                data: res.data.complaintSellers.map(complaint => {
                    let newComplaint = {
                        ...complaint,
                        complaintAmount: {
                            amount: complaint.amount,
                            amount_earned: complaint.amount_earned
                        }
                    };
                    return newComplaint;
                })

            });
        });
    }

    render() {
        const {t} = this.props;
        return <>
            <h3 className="a-font--14 mt-3 mb-5 a-text--color-black-85">{t("problem_order.list_complaint_seller")}</h3>
            <Table
                dataSource={this.state.data}
                rowKey="id" pagination={false}
                locale={{
                    emptyText: (
                        <Empty
                            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                            description={<span>{t("transaction:label.no_data")}</span>}
                        >
                        </Empty>)
                }}>
                <Column
                    key="id"
                    dataIndex="id"
                    title={t("problem_order.complaint_no")}
                    width="15%"
                    render={(text, record) => {
                        return <Link isNewTab={true} href={url.to('complaint-seller.detail', {id: record.id})}>{text}</Link>
                    }} />
                <Column
                    key="reason"
                    dataIndex="reason"
                    title={t("problem_order.reason")}
                    width="30%"
                    render={(text, record) => lodash.get(record, "reason.title")} />
                <Column
                    key="amount"
                    dataIndex="complaintAmount"
                    title={t("complaintSeller:list.complaint_amount")}
                    width="20%"
                    align="right"
                    render={(text) => {
                        return (
                            <React.Fragment>
                                <Tooltip
                                    placement="left"
                                    title={t('complaintSeller:list.amount_earned_success')}
                                    arrowPointAtCenter={true}
                                >
                                    <strong className="a-text--red">{currentFormatter.toLocaleStringCurrency(parseFloat(text.amount_earned, 10), 5)+'¥'}</strong>
                                </Tooltip>/
                                <Tooltip
                                    placement="right"
                                    title={t('complaintSeller:list.amount_want_to_earn')}
                                    arrowPointAtCenter={true}>
                                <strong className="a-text--green-dark">{currentFormatter.toLocaleStringCurrency(parseFloat(text.amount, 10), 5)+'¥'}</strong>
                                </Tooltip>
                            </React.Fragment>
                        )
                    }} />
                <Column
                    key="expire_time"
                    dataIndex="expire_time"
                    title={t("complaintSeller:list.expire_time")}
                    width="20%"
                    render={(text) => dateFormatter.date(text)} />
                <Column
                    key="status"
                    dataIndex="status"
                    title={t("order_group.status")}
                    align="right"
                    render={(text) => t(`complaintSeller:status.${text.toLowerCase()}`)}/>
            </Table>
        </>;
    }
}

export default translate()(ListComplaintSeller);
