import React from 'react';
import UploadCSV from './Upload';
import {Button, Table, Pagination, Select, Icon, Upload, Modal} from "antd";
import {ReactComponent as IconDownload} from '../../../resources/img/ic_file_download_48px.svg';

const Option = Select.Option;

const data = [];
for (let i = 0; i < 20; i++) {
    data.push({
        file: `FileAlipay`,
        person: `Lê Hoàng Thuỳ Trang`,
        time: `19:00:20 - 22/05/2019`,
        deal: `34`,
        dealsuccess:'34',
        dealfail:'--',
        error: (i >=10 ? 0 : 1)
    });
}

class ListFileUpload extends React.Component {

    state = { visible: false ,openboxupload:false};
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };
    onClickFormListFileUpload = () =>{
        this.setState({openboxupload: !this.state.openboxupload});
        console.log(this.state.openboxupload);
    }
    render() {
        const columns = [
            {
                title: 'Mã têp',
                dataIndex: 'file',
                key: 'file',
                render: text => <span className="a-text--nowrap name-file " onClick={this.showModal} ><Icon type="loading" className="a-text--font-b" /> <Icon type="check-circle" className="a-text--success"/> <Icon type="warning" className={"a-text--red"} />     <Icon type="sync" spin className="a-text--font-b" />
                    {text}</span>,
            },
            {
                title: 'Người tạo / ID',
                dataIndex: 'person',
                key: 'person',
                className: "a-text--nowrap",
            }, {
                title: 'Thời gian',
                dataIndex: 'time',
                key: 'time',
                render: text => <span className="a-text--nowrap">{text}</span>,
            }, {
                title: 'Tổng giao dịch',
                dataIndex: 'deal',
                key: 'deal',
                render: text => <span className="a-text--nowrap">{text}</span>,

            },
            {
                title: 'Số giao dịch đúng',
                dataIndex: 'dealsuccess',
                key: 'dealsuccess',
                render: text => <span className="a-text--nowrap">{text}</span>,

            },
            {
                title: 'Số giao dịch sai',
                dataIndex: 'dealfail',
                key: 'dealfail',
                render: text => <span className="a-text--nowrap">{text}</span>,

            },{
                title: '',
                dataIndex: 'deal',
                key: 'deal',
                render: text => <a href="" className="action-row-show-hover"><IconDownload/></a>,
            }
        ];

        return (
            <div className="a-list mt-5">
                <div className="a-list__top a-flex">
                    <h2 className="a-list--title a-text--uppercase">Danh sách upload tệp (200)</h2>
                    <div className="ml-auto">
                        <Upload>
                            <Button className=" a-font--12 aphz-24 a-btn--primary-ant a-btn--upload-file"
                                    size={"small"}><Icon type="cloud-upload" className="a-font--18"/>Tải tệp
                                lên</Button>
                        </Upload>

                    </div>
                </div>
                <div className="a-content--upload-csv a-content--is-pagination-fixed">
                    <div className="a-list__body upload-csv pt-0">
                        <Table className={"a-table--antd"} columns={columns} dataSource={data}
                               rowClassName= {(record) => `row-show-item-hover ${record.error ? "error-deal" : ''}`}
                               pagination={false}
                        />
                        <div className="a-pagination a-pagination--fixed ">
                            <Pagination defaultCurrent={5} total={200} className="a-pagination--detail"/>
                            <div className="a-pagination--select-number-item">
                                <span className="text-show mr-2">Hiển thị</span>
                                <Select defaultValue="10" className="a-select--select-number-show"
                                        getPopupContainer={trigger => trigger.parentNode}
                                >
                                    <Option value="10">10</Option>
                                    <Option value="50">50</Option>
                                    <Option value="100">100</Option>
                                </Select>
                            </div>
                        </div>
                        <div className={`list-block-file-upload ${this.state.openboxupload ? "collapsed" :""}`}>
                            <div className="title">
                                <span>Đã tải lên xong 1 mục </span>
                                <div className="action">
                                    <Icon type={`${this.state.openboxupload ? "up" :"down"}`} onClick={this.onClickFormListFileUpload}/>
                                    <Icon type="close" />
                                </div>
                            </div>
                            <div className="body">
                                <div className="body-top">
                                    <span className="number-uploading">Đang tải lên 2 tệp </span>
                                    <a href="#" className="cancel-upload">Huỷ</a>
                                </div>
                                <ul className="list-file-upload">
                                    <li>
                                        <span className="file-name-upload">FileABCD</span>
                                        <Icon type="loading"  className="icon-upload"/>
                                    </li>
                                    <li>
                                        <span className="file-name-upload">FileABCD</span>
                                        <Icon type="loading"  className="icon-upload"/>
                                    </li>
                                    <li>
                                        <span className="file-name-upload">FileABCD</span>
                                        <Icon type="loading"  className="icon-upload"/>
                                    </li>
                                    <li>
                                        <span className="file-name-upload">FileABCD</span>
                                        <Icon type="check-circle" className="icon-upload a-text--green-new" theme={"filled"}/>
                                    </li>
                                    <li>
                                        <span className="file-name-upload">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                        <Icon type="warning"  className="icon-upload a-text--red" theme={"filled"}/>
                                    </li>
                                </ul>
                            </div>
                         </div>
                    </div>
                </div>

                <Modal
                    title="Danh sách dòng bị lỗi"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={false}
                >
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                </Modal>

            </div>
        )
    }
}

export default ListFileUpload;