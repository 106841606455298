import React from 'react';
import {translate} from 'react-i18next';
import {Tooltip} from 'antd';

class ServicesItem extends React.Component {

    render() {
        let {t, services} = this.props;
        return (
            <div className="d-flex a-font--12">
                <p className="a-text--color-black-45">{t("order:list.service")}:</p>
                {services.map((service) => {
                    return <Tooltip key={service.id} placement="topLeft" title={service.name} arrowPointAtCenter overlayClassName="a-font--12">
                        <i className={`${service.icon ? service.icon : "ion-android-globe"} ml-2 a-font--14`} />
                    </Tooltip>;
                })}

            </div>
        )
            ;
    }
}

ServicesItem.defaultProps = {
    services: [],
}
;

export default translate('translations')(ServicesItem);