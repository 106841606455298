import {getState} from "../../../systems/store";
import lodash from 'lodash';
import * as settings from './settings'

/**
 *
 * @param setting
 * @returns {boolean}
 */
const hasSetting = (setting) => {
    let settings = lodash.get(getState(), 'User.me.user.settings', []);
    let user_setting = false;
    settings.map(param => {
        if (param.code === setting && parseInt(param.value, 10) !== 0) {
            user_setting = true;
        }

        return param;
    });
    return user_setting;

};
/**
 * @param setting
 * @param settings
 */
const getValue = (setting, settings = []) => {
    let settingValue = '';
    settings.map(param => {
        if (param.code === setting && parseInt(param.value, 10) !== 0) {
            settingValue = param.value;
        }

        return param;
    });
    return settingValue;

};


export {
    hasSetting,
    getValue,
    settings
}