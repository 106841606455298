import {takeLatest, all, fork} from 'redux-saga/effects';
import * as saga from './saga';
import * as ACTION from './constants';
import complaintAssignWatcher from './ComplaintAssign/sagaWatcher';
import completeComplaintWatcher from './CompleteComplaint/sagaWatcher';
import complaintReceptionWatcher from './ComplaintReception/sagaWatcher';
import failedComplaintWatcher from './FailedComplaint/sagaWatcher';
import complaintAlipaysWatcher from './ComplaintAlipays/sagaWatcher';
import createProductReturnWatcher from './CreateProductReturn/sagaWatcher';
import complaintNoteWatcher from './ComplaintNote/sagaWatcher';
import complaintMarkImportantWatcher from './ComplaintMarkImportant/sagaWatcher';

export default function*() {
    yield all([
        fork(complaintAssignWatcher), 
        fork(completeComplaintWatcher),
        fork(complaintReceptionWatcher),
        fork(failedComplaintWatcher),
        fork(complaintAlipaysWatcher),
        fork(createProductReturnWatcher),
        fork(complaintNoteWatcher),
        fork(complaintMarkImportantWatcher),
    ]);
    
    yield takeLatest(ACTION.FETCH_COMPLAINT_SELLER_REASONS.REQUEST, saga.getComplaintSellerReasons);
    yield takeLatest(ACTION.FETCH_COMPLAINT_SELLER.REQUEST, saga.getComplaintSeller);
    yield takeLatest(ACTION.CREATE_COMPLAINT_SELLER.REQUEST, saga.createComplaintSellerSaga);
    yield takeLatest(ACTION.FETCH_COMPLAINT_DETAIL.REQUEST, saga.fetchComplaintDetail);
    yield takeLatest(ACTION.EXPIRE_COMPLAINT_SELLER.REQUEST, saga.expireUpdateComplaintDetail);
    yield takeLatest(ACTION.FETCH_COMPLAINT_SELLER_RENEW_REASONS.REQUEST, saga.fetchComplaintSellerRenewReasons);
}
