import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from "lodash";
import {notification, Select} from 'antd';
import {processResponse} from "../../../systems/api";
import apiService from "../apiService";
import {t} from "../../../systems/i18n";

const {Option} = Select;

function SelectDistributionWarehouse(props) {
    const {value} = props;
    const [loading, setLoading] = useState(false);
    const [warehouses, setWarehouses] = useState([]);

    useEffect(() => {
        fetchDistributiveWarehouse();
    }, []);

    function fetchDistributiveWarehouse() {
        setLoading(true);
        processResponse(apiService.fetchDistributiveWarehouse())
            .then(res => {
                setWarehouses(lodash.get(res, "data", []));
            })
            .catch(() => {
                notification.error(t('message.cant_fetch_data'));
            }).finally(() => setLoading(false));
    };

    return (
        <Select
            value={value}
            showSearch={true}
            mode={props.multiple ? 'multiple' : 'default'}
            onChange={props.onChange}
            className={props.className}
            placeholder={props.placeholder}
            loading={loading}
        >
            {props.allOption && <Option value="">{t('label.all')}</Option>}
            {
                warehouses.map(warehouse => {
                    const name = lodash.get(warehouse, "name", "");
                    const code = lodash.get(warehouse, "code", "");
                    return <Option key={warehouse.id} value={code}>{name}</Option>
                })
            }
        </Select>
    );
}

SelectDistributionWarehouse.defaultProps = {
    value: undefined,
    allOption: false,
    multiple: false,
    onChange: value => {
    },
    className: '',
    placeholder: ''
};

SelectDistributionWarehouse.propTypes = {
    value: PropTypes.any,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string
};

export default translate()(SelectDistributionWarehouse);
