export const ORDER_SYNC_BILL_NO = {
    REQUEST: 'ORDER_SYNC_BILL_NO_REQUEST',
    SUCCESS: 'ORDER_SYNC_BILL_NO_SUCCESS',
    FAILED: 'ORDER_SYNC_BILL_NO_FAILED'
};


export const ORDER_LIST_BILL_NO = {
    REQUEST: 'ORDER_LIST_BILL_NO_REQUEST',
    SUCCESS: 'ORDER_LIST_BILL_NO_SUCCESS',
    FAILED: 'ORDER_LIST_BILL_NO_FAILED'
};
