import {createSelector} from 'reselect';
import lodash from 'lodash';
import {dateFormatter, currentFormatter} from '../../common/services/format';
import {trans} from './../../../systems/i18n';
import React from "react";
import InputAddInvoiceCodeContainer from "../InputAddInvoiceCode/InputAddInvoiceCodeContainer";
import OrderCodeContainer from "../OrderCode/OrderCodeContainer";
import {translateValidationErrors} from '../../common/helps';

const listTransactions = state => lodash.get(state, "Transaction.ListTransaction.list", []);
const loadingListTransactions = state => lodash.get(state, "Transaction.ListTransaction.loading", false);
export const pagination = state => lodash.get(state, "Transaction.ListTransaction.pagination", []);
export const invoiceCodeMax = state => lodash.get(state, "Transaction.ListTransaction.invoiceCodeMax", {});
export const unusualTypes = state => lodash.get(state, "Transaction.ListTransaction.unusualTypes", {});
export const permission = state => lodash.get(state, "Transaction.ListTransaction.permission", {});

export const getListTransactions = createSelector(
    listTransactions,
    loadingListTransactions,
    invoiceCodeMax,
    (transactions, loading, invoiceCodeMax) => transactions.map(transaction => {
        transaction = {...transaction};
        transaction.created_at_format = dateFormatter.dateTime(transaction.created_at);
        transaction.transaction_time_format = (transaction.transaction_time === '0000-00-00 00:00:00' || transaction.transaction_time === '-0001-11-30 00:00:00') ? '---' : dateFormatter.dateTime(transaction.transaction_time);
        transaction.transaction_time_create_format = dateFormatter.dateTime(transaction.transaction_time_create);
        transaction.transaction_time_begin_format = dateFormatter.dateTime(transaction.transaction_time_begin);
        transaction.resolved_time_format = (transaction.resolved_time === '0000-00-00 00:00:00' || transaction.resolved_time === '-0001-11-30 00:00:00' || !transaction.resolved_time) ? '---' : dateFormatter.dateTime(transaction.resolved_time);
        transaction.type_format = trans('transaction:type.' + transaction.type);
        switch (transaction.collect_spend) {
            case 'COLLECT':
                transaction.amount_class = 'a-text--color-primary';
                break;
            case 'SPEND':
                transaction.amount_class = 'a-text--red';
                break;
            case 'UNKNOWN':
            default:
                transaction.amount_class = '';
        }

        transaction.amount_format = ((transaction.collect_spend === "SPEND" || transaction.type === "SPEND_ASK_HELP") ? '-' : '') + currentFormatter.toLocaleStringCurrency(parseFloat(transaction.amount), 5) + '¥';
        transaction.amount_refund_format = currentFormatter.toLocaleStringCurrency(parseFloat(transaction.amount_refund), 5) + '¥' ;
        transaction.service_fee_format = currentFormatter.toLocaleStringCurrency(parseFloat(transaction.service_fee), 5) + '¥' ;
        transaction.csv_file_name = lodash.get(transaction, "transactionFile.filename", '--');
        transaction.invoiceCodeForm = <p>{''}</p>;
        let orders, invoiceCodes = [];
        let keys = lodash.keys(invoiceCodeMax);
        let transactionType = lodash.get(transaction, 'type', '');
        let maxInvoiceCode = (lodash.includes(keys, transactionType)) ? parseFloat(lodash.get(invoiceCodeMax, transactionType)) : '';
        transaction.maxInvoiceCode = maxInvoiceCode;
        if (transaction.transaction_invoice_code) {
            orders = lodash.map(transaction.transaction_invoice_code, 'order');
            invoiceCodes = transaction.transaction_invoice_code;
        }
        let invoiceCodeLength = lodash.get(transaction, "transaction_invoice_code.length", 0);
        if (invoiceCodeLength === 0 && transaction.canUpdateInvoiceCode === true) {
            transaction.invoiceCodeForm =
                <InputAddInvoiceCodeContainer invoiceCodeChanged={transaction.invoice_code_changed} loading={loading} idTransaction={transaction.id}/>;
        } else if (invoiceCodeLength > 0) {
            transaction.invoiceCodeForm = <OrderCodeContainer
                canUpdateInvoiceCode={transaction.canUpdateInvoiceCode}
                canDeleteInvoiceCode={transaction.canDeleteInvoiceCode}
                invoiceCodeChanged={transaction.invoice_code_changed}
                id={transaction.id}
                orders={orders}
                invoiceCodes={invoiceCodes}
                maxInvoiceCode={maxInvoiceCode}
                transactionType={transactionType}
            />
        }
        return transaction;
    })
);
export const getErrors = error => {
    return lodash.mapValues(error.data, (rules, param) => {
        rules = lodash.mapKeys(rules, function (value, key) {
            return lodash.toLower(key);
        });
        let attribute = trans('transaction:label.' + param);
        let messages = lodash.mapValues(rules, (data, rule) => {
            attribute = rule === 'greater' ? trans('transaction:label.time_mark') : attribute;
            return trans('transaction:errors.' + rule, {attribute: attribute})
        });
        return lodash.first(lodash.values(messages));
    });
};
export const getUnusualTypes = types => {
    return lodash.keys(types);
};
