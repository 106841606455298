import axios from 'axios';
import loading from './loading';
import CancelToken from './CancelToken';
import {dispatch} from "../store";
import lodash from 'lodash';
import {PERMISSION_NOT_ALLOW} from '../../modules/Permission/constants';


class ApiFactory {
    constructor(baseUrl, auth = null) {
        this.baseUrl = baseUrl;
        this.auth = auth;
    }

    make() {
        let auth = this.auth;
        let cancelToken = new CancelToken();

        var api = axios.create({
            baseURL: this.baseUrl,
        });

        api.interceptors.request.use(config => {
            config.url = lodash.trimEnd(config.url, '/');
            config.params = config.params || {};
            config.params.lang = localStorage.getItem('i18nextLng');

            if (auth) {
                config.headers = {
                    ...config.headers,
                    'Access-Control-Allow-Headers': 'Authorization',
                    'Authorization': 'Bearer ' + auth.getToken(),
                };
            }

            // Handle single request
            if (config.singleRequest) {
                config.cancelToken = cancelToken.create(config.method + '::' + config.url);
            }

            if (config.loading !== false) {
                loading.start();
            }

            return config;
        }, error => {
            return Promise.reject(error);
        });

        api.interceptors.response.use(response => {
            let {config} = response;
            if (config.loading !== false) {
                loading.end();
            }
            let data = lodash.get(response, 'data', {});

            if (data.code === 403) {
                dispatch({type: PERMISSION_NOT_ALLOW});
            }

            return response;
        }, error => {
            loading.end();

            return Promise.reject(error);
        });

        return api;
    }

}

export default ApiFactory;
