import React from "react";
import PropTypes from "prop-types";
import GroupItem from "./GroupItem";
import lodash from "lodash";

class AgencyBox extends React.Component {
    render() {
        let {agency, groups} = this.props;

        /**
         * Sắp xếp lại thứ tự theo độ ưu tiên
         */
        groups = lodash.sortBy(groups, ["priority"]);

        return <div className="agency-box mt-3">
            <div className="agency-box-title d-flex p-3 pb-3 pl-3 pl-3">
                <p style={{ background: agency.color }}
                   className={"a-font--12 header_partner mr-1 header_partner--" + agency.code}>
                    {agency.alias}
                </p>
                <span className="a-text--color-black a-text--bold">{agency.name}</span>
            </div>
            <div className="agency-box-body pl-4" style={{paddingRight: "1rem"}}>
                {
                    groups.map((group, index) => <GroupItem
                        key={index}
                        group={group}
                        permission={this.props.permission}
                        onUpdateStatusOrderGroup={this.props.onUpdateStatusOrderGroup}/>)
                }
            </div>
        </div>;
    }
}

AgencyBox.defaultProps = {
    groups: [],
    agency: {}
}

AgencyBox.propTypes = {
    groups: PropTypes.array.isRequired,
    agency: PropTypes.object.isRequired
}

export default AgencyBox;
