import ApiAuth from './ApiAuth';
import ApiUrl from './ApiUrl';
import ApiFactory from './ApiFactory';
import ResponseProcessor from './ResponseProcessor';
import config from '../../config/app';

let auth = new ApiAuth('token_customer');
let url = new ApiUrl(config.apiUrl, auth);
let apiTest = (new ApiFactory(config.apiUrl, auth)).make();
let apiWithToken = (new ApiFactory(config.apiUrl, auth)).make();
let apiWithoutToken = (new ApiFactory(config.apiUrl)).make();
let responseProcessor = new ResponseProcessor();

const processResponse = responseProcessor.process.bind(responseProcessor);

export {
    auth,
    apiTest,
    url,
    apiWithToken,
    apiWithoutToken,
    processResponse,
};