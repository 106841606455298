import {createAction} from 'redux-actions';
import * as Constants from './constants';

export const getListTransaction = createAction(Constants.GET_LIST_TRANSACTION.REQUEST, (filters) => (filters));
export const updateTransaction = createAction(Constants.UPDATE_TRANSACTION, (id, data) => ({id, data}));
export const takeErrors = createAction(Constants.TAKE_ERRORS, (data) => ({data}));

export const resetError = createAction(Constants.RESET_ERROR, (params) => ({params}));
export const clearState = createAction(Constants.TRANSACTION_LIST_FILE_CLEAR_STATE,  () => ({}));


