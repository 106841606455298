import React from 'react';
import moment from 'moment';
import lodash from 'lodash';
import {translate} from 'react-i18next';
import * as PropTypes from "prop-types";
import Select from '@atlaskit/select';
import FormProcessing from './../../common/components/FormProcessing';

class FormSearchList extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                username: null,
                month: null,
                year: moment().format('YYYY'),
            },
            inputVersion: 0,
            changedParams: [],
        }
    }

    /**
     * Hàm xác nhận thay đổi tháng và thực hiện tìm kiếm
     * @param option
     */
    onChangeMonth(option) {
        this.changeInput({month: option ? option.value : null});
        let {input} = this.state;
        input = {...input, ...{month: option ? option.value : null}}
        this.props.onSearch(input);
    }

    /**
     * Hàm thay đổi chọn năm tìm kiếm
     * @param param
     * @param value
     */
    onChangeSearchYear(param, value) {
        this.changeInput({[param]: value});
        let {input} = this.state;
        input = {...input, ...{[param]: value}}
        this.props.onSearch(input);
    }

    /**
     * Entern submit
     * @param event
     */
    onKeyPressInput(input, event) {
        if (event.key === 'Enter') {
            this.props.onSearch(input);
        }
    }

    /**
     * Hàm blur để tìm kiếm khi nhập tài khoản giao dịch viên
     */
    onBlurInput(event) {
        let {input} = this.state;
        if ( input.username && input.username !== event.target.value) {
            this.props.onSearch(input);
        }
    }

    /**
     * Hàm thực hiện xóa dữ liệu tìm kiếm
     */
    onClearFormSearch() {
        this.props.onClear({username: '', month: moment().format('M'), year: moment().format('YYYY')});
    }

    render() {
        let {t, months, inputVersion, year_previous, year_current} = this.props;
        let {input} = this.state;

        return (
            <div className={"mt-3 a_salefigures__form_search"} key={inputVersion}>
                <div className="mr-4 a_salefigures__action__select_user">
                    <label className="w-100 a_salefigures__label mb-2">
                        {t('sale:form.label_filter_username')}
                    </label>
                    <input type="text" className="input input--salefigures w-100 mt-2"
                           placeholder={t('sale:form.enter_username_placeholder')}
                           onChange={this.onChangeInput.bind(this, 'username')}
                           onBlur={this.onBlurInput.bind(this)}
                           value={input.username || ''}
                           onKeyPress={this.onKeyPressInput.bind(this, input)}
                    />
                </div>
                <div className="ml-4 mr-4 a_salefigures__action__select_month">
                    <label className="w-100 a_salefigures__label mb-2">
                        {t('sale:form.label_filter_month')}
                    </label>
                    <Select
                        noOptionsMessage={() => t("system.no_option")}
                        placeholder={t("sale:form.choose_month_placeholder")}
                        value={lodash.find(months, {value: lodash.toInteger(input.month)}) || null}
                        options={months}
                        className="select__month mt-2"
                        classNamePrefix="react-select--sale_figures"
                        onChange={this.onChangeMonth.bind(this)}
                    />

                </div>
                <div className="ml-4 flex-wrap a_salefigures__action__height_sp a_salefigures__action__select_year ">
                    <label className="w-100 a_salefigures__label mb-2">
                        {t("sale:form.label_filter_year")}
                    </label>
                    <div className="seach-pre-next">
                        <div className={'a-btn--pre_next_sale'}>
                            <button className={lodash.toInteger(input.year) === year_previous ? "active" : ''}
                                    onClick={e => this.onChangeSearchYear('year', year_previous)}>
                                {year_previous}
                            </button>
                            <button className={lodash.toInteger(input.year) === year_current ? "active" : ''}
                                    onClick={e => this.onChangeSearchYear('year', year_current)}>
                                {year_current}
                            </button>
                        </div>
                    </div>
                </div>
              <span
                style={{cursor:'pointer'}}
                className="d-flex a-link mb-2 sale_figures_remove_sort"
                onClick={this.onClearFormSearch.bind(this)}>
                        {t('sale:form.label_filter_clear')}
                    </span>
            </div>
        );
    }
}


FormSearchList.defaultProps = {
    months: [],
};

FormSearchList.propTypes = {
    months: PropTypes.array,
};

export default translate('translations')(FormSearchList);
