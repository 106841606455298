import React from "react";
import {Col, Input} from "antd";
import lodash from "lodash";
import {translate} from "react-i18next";

class AccessTokenCanExportedByPartner extends React.Component {

    constructor(props) {
        super(props);
        let {systemCanExportedToken} = this.props;
        this.state = {
            value: lodash.get(systemCanExportedToken, 'value', "")
        }
    }
    onChangeInput(e) {
        const {systemCanExportedToken} = this.props;
        const value = e.target.value;
        this.setState({value});
        this.props.onChange({code: systemCanExportedToken.code, value: value});
    }


    render() {
        let {systemCanExportedToken, t} = this.props;
        let {value} = this.state;
        return (
            <Col xs={{span: 12}} md={{span: 12}}>
                <div className="a-content--box-shadow p-0 mb-5">
                    <div className="a-setting__title p-3">
                        <p>{t('setting:description.' + systemCanExportedToken.code)}</p>
                    </div>

                    <div className="a-setting__body p-3">
                        <Input
                            name={'settings'}
                            value={value}
                            style={{width: "100%"}}
                            placeholder={t('setting:placeholder.' + systemCanExportedToken.code)}
                            onChange={(e) => this.onChangeInput(e)}
                        />
                    </div>
                </div>
            </Col>
        );
    }
}


export default translate()(AccessTokenCanExportedByPartner);