import React from "react";
import {Col, Row, Icon, Divider} from "antd";
import ListSpendTransactions from "./ListSpendTransactions";
import ListComplaintSeller from "./ListComplaintSeller";
import ProblemBrief from "./ProblemBrief";
import {url} from "../../../systems/routing";
import Link from "../../../systems/routing/Link";
import {translate} from "react-i18next";
import lodash from 'lodash';
import MarkViolationOrder from "../../../modules/Order/MarkingViolationOrder";



class ProblemDetail extends React.Component {

    render() {
        const { t, dataOrder } = this.props;
        return <>
            <p className="a-font--14 a-text--uppercase pb-3">
                <Link
                    href={url.to('purchase.buying.detail', {id: dataOrder.id})}
                    className="a-text--color-black-85">
                    <Icon type="arrow-left" /> {t("breadcrumb.order_detail")} - <strong className="a-text--green">{dataOrder.code}</strong>
                </Link>
            </p>

            <div className="a-block a-block--problem-detail">

                {/*------------Begin Title----------*/}
                <div className="a-block__top a-block__top--problem-detail">
                    <Row>
                        <Col span={12}>
                            <strong className="a-text--color-primary a-font--18">{dataOrder.code}</strong>
                        </Col>
                        <Col span={12} className="a-text--right">
                            {
                                !dataOrder.orderDetail.violation &&
                                <MarkViolationOrder order={dataOrder}/>
                            }
                        </Col>
                    </Row>
                    {
                        (dataOrder.orderDetail.violation) ?
                        <Row>
                            <Col span={24} className={"text-justify"}>
                                <MarkViolationOrder order={dataOrder}/>
                            </Col>
                        </Row> : null
                    }

                </div>


                {/*------------Begin Body----------*/}
                <div className="a-block__body">
                    <div className="a-block__body--problem-detail pb-5 mb-5">

                        <ProblemBrief dataOrder={dataOrder} />
                        <ListSpendTransactions spendTransactions={dataOrder.transactions} typeOpacity={dataOrder.transactionNotFinali} />
                        <br/>
                        { dataOrder.id !== undefined &&
                        <div>
                            <ListComplaintSeller dataOrder={dataOrder} />
                            <br/>
                        </div>
                        }

                    </div>
                </div>
            </div>
        </>;
    }
}

export default translate()(ProblemDetail);
