import React from 'react';
import {Button, Icon} from "antd";
import Link from "../../../../systems/routing/Link";
import {translate} from "react-i18next";
import {trans} from "../../../../systems/i18n";
import lodash from 'lodash';
import authService from "../../../Permission/authService";
import {permissions} from './../../../Permission';
import CreateComplaint from "../../../ComplaintSeller/CreateComplaint";

const allowedStatuses = [
    'SELLER_DELIVERY',
    'BOUGHT',
    'OUT_OF_STOCK',
    'RECEIVED',
    'CANCELLED',
    'TRANSPORTING',
    'WAITING_DELIVERY',
    'DELIVERING',
    'DELIVERED'

];
class CreateComplaintSeller extends React.Component {
    render() {
        let {
            order,
            newScreen,
            onOrderIsJustAdded,
            ordersIsJustAddedComplaint,
            complaintSellerReasons
        } = this.props;
        let currencySymbol = lodash.get(order, "currencySymbol",  '¥');
        return (
            lodash.includes(allowedStatuses, order.status) && authService.can(permissions.COMPLAINT_SELLER_CREATE) && order.canCreateComplaintSeller ?
                <CreateComplaint
                    openNewTab={true}
                    id_order={order.code}
                    className="a--cursor-poiter a-text--color-primary ml-4"
                    onOrderIsJustAdded={onOrderIsJustAdded}
                    complaintSellerReasons={complaintSellerReasons}
                    currencySymbol={currencySymbol}
                >
                    {
                        newScreen ?
                        <>
                            {
                                Array.isArray(ordersIsJustAddedComplaint) && ordersIsJustAddedComplaint.includes(order.id) &&
                                <Icon type="check" className="a-text--fz-14 a-text--color-green mr-2" />
                            }
                            <Icon type="file-done" className="a-font--12"/>
                            <span className="ml-1 a-font--12">Tạo KNNB</span>
                        </> :
                        <Button className="px-2 d-flex align-items-center" style={{backgroundColor: '#5cb85c', fontSize: '1em', color: '#fff'}} size={"small"}>
                            <Icon type="plus-square" theme={"filled"} style={{fontSize: '1em'}}/>
                            <span className="font-weight-bold ml-2">Tạo KNNB</span>
                        </Button>
                    }
                </CreateComplaint> : null
        );
    }
}

export default translate()(CreateComplaintSeller);
