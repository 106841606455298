import {createAction} from 'redux-actions';
import * as Constants from './constants';

export const getListAccountPurchaserAction = createAction(Constants.GET_LIST_ACCOUNT_PURCHASER.REQUEST, (filters) => (filters));
export const updateAccountPurchaserAction = createAction(Constants.UPDATE_ACCOUNT_PURCHASER.REQUEST, (datas) => ({datas}));
export const createAccountPurchaserAction = createAction(Constants.CREATE_ACCOUNT_PURCHASER.REQUEST, (datas) => ({datas}));
export const changeStatusAccountPurchaserAction = createAction(Constants.CHANGE_STATUS_ACCOUNT_PURCHASER.REQUEST, (datas) => ({datas}));
export const openModalForm = createAction(Constants.OPEN_MODAL_ACCOUNT_PURCHASER, () => ({}));
export const closeModalForm = createAction(Constants.CLOSE_MODAL_ACCOUNT_PURCHASER, () => ({}));
export const clearError = createAction(Constants.CLEAR_ERROR_ACCOUNT_PURCHASER, () => ({}));
