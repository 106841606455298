import { Select } from 'antd'
import React from 'react'
import { t } from '../../../../../systems/i18n'

const { Option } = Select

const SelectFin = props => {
    const { value, onChange } = props

    return (
        <Select 
            placeholder={t('order:placeholder.loan_bifiin')} 
            allowClear 
            value={value ? value : undefined} 
            onChange={onChange}
        >
            <Option value="yes">{t('order:label.loan_fin')}</Option>
            <Option value="no">{t('order:label.regular_deposit')}</Option>
        </Select>
    )
}

export default SelectFin
