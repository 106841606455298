import React, {useState, useEffect} from "react";
import {Icon, notification, Tooltip, Button} from "antd";
import {processResponse} from "../../../../../systems/api";
import apiService from "../../apiService";
import lodash from "lodash";

function UpdateAffiliateLink(props) {
    let {
        item,
        order,
        updateAffiliateLink
    } = props;
    let [isLoading, setLoading] = useState(false);

    function handleClick() {
        setLoading(true);

        let process = processResponse(apiService.updateAffiliateLink(item.id));
        process.then(res => {
            if (res.data !== null)
                updateAffiliateLink(res);

            notification.success({message: "Cập nhật link affiliate thành công"});
            setLoading(false);
        });
        process.catch(error => {
            notification.error({message: "Cập nhật link affiliate không thành công"});
            setLoading(false);
        });
    }

    return (
        (item.affiliate_link !== "") ?
        <Tooltip placement="top" title="Click để cập nhật lại link sản phẩm nếu vào link sản phẩm bị lỗi">
            <Button
                size="small"
                onClick={handleClick}
                loading={isLoading}
                className="ml-2"
            >Cập nhật link</Button>
        </Tooltip> : null
    );
}

export default UpdateAffiliateLink;
