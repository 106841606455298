import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {trans} from '../../../../../systems/i18n';

const TRANSACTION_ACTION = {
    CREATE: 'TRANSACTION.CREATE',
    IS_NOT_OK: 'TRANSACTION.IS_NOT_OK',
    RESOLVE: 'TRANSACTION.RESOLVE',
    UNRESOLVE: 'TRANSACTION.UNRESOLVE',
    UPDATE_NOTE: 'TRANSACTION.UPDATE_NOTE',
    ADD_INVOICE_CODE: 'TRANSACTION.ADD_INVOICE_CODE',
    REMOVE_INVOICE_CODE: 'TRANSACTION.DELETE_INVOICE_CODE',
    ADD_ORDER_CODE: 'TRANSACTION.ORDER_CODE.ADD',
    UPDATE: 'TRANSACTION.UPDATE',
    ADD_TRANSACTION_RELATED: 'TRANSACTION.ADD_TRANSACTION_RELATED', //Log hành động khi thêm các GD liên quan
    DELETE_TRANSACTION_RELATED: 'TRANSACTION.DELETE_TRANSACTION_RELATED', //Log hành động khi xóa GD liên quan
    ADD_TRANSACTION_RELATED_TO: 'TRANSACTION.ADD_TRANSACTION_RELATED_TO', // Khi 1 GD được thêm vào 1 GD nào đó
    DELETE_TRANSACTION_RELATED_TO: 'TRANSACTION.DELETE_TRANSACTION_RELATED_TO', //Khi 1 GD bị xóa khỏi 1 GD nào đó
};

class TransactionLogMessage extends React.Component {
    render() {
        let {log} = this.props;
        let {payload} = log;
        let fromTool = lodash.get(payload, 'attributes.fromTool', 0);

        switch (log.action) {
            case TRANSACTION_ACTION.CREATE:
                return trans('transaction:log.CREATE');
            case TRANSACTION_ACTION.UPDATE_NOTE:
                return trans('transaction:log.UPDATE_NOTE');
            // transaction: Mã giao dịch
            // creator: Người resolve
            // reason: Lý do resolve
            case TRANSACTION_ACTION.RESOLVE:
                return trans('transaction:log.RESOLVE', {
                    transaction: <b>{lodash.get(payload, 'transaction.code')}</b>,
                    creator: <b>{lodash.get(payload, 'creator.name')}</b>,
                    reason: <b>{lodash.get(payload, 'attributes.note_resolved.new')}</b>,
                });
            // transaction: Mã giao dịch
            // creator: Người unresolve
            case TRANSACTION_ACTION.UNRESOLVE:
                return trans('transaction:log.UNRESOLVE', {
                    transaction: <b>{lodash.get(payload, 'transaction.code')}</b>,
                    creator: <b>{lodash.get(payload, 'creator.name')}</b>,
                });
            // transaction: Mã giao dịch
            // creator: Người đánh dấu bất thường
            // reason: Lý do đánh dấu bất thường
            case TRANSACTION_ACTION.IS_NOT_OK:
                return trans('transaction:log.IS_NOT_OK', {
                    transaction: <b>{lodash.get(payload, 'transaction.code')}</b>,
                    creator: <b>{lodash.get(payload, 'creator.name')}</b>,
                    reason: <b>{lodash.get(payload, 'attributes.note.new')}</b>,
                });
            case TRANSACTION_ACTION.UPDATE:
                return trans('transaction:log.UPDATE', {
                    transactionFileName: <b>{lodash.get(payload, 'attributes.transactionFileName')}</b>,
                });
            case TRANSACTION_ACTION.ADD_INVOICE_CODE:
                return this._renderMessageAddInvoiceCode(payload);
            case TRANSACTION_ACTION.REMOVE_INVOICE_CODE:
                return this._renderMessageDeleteInvoiceCode(payload);
            case TRANSACTION_ACTION.ADD_TRANSACTION_RELATED:
                // Mã giao dịch trả về là mảng các object có dạng codes: [{id: id_giao_dich_1, code: code_giao_dich_1}, {id: id_giao_dich_2, code: code_giao_dich_2}]
                let transactions = lodash.get(payload, 'attributes.codes', []);
                let transactions_format = transactions.map((transaction, index) => (
                    <React.Fragment>
                        <b>
                            <a
                                href={lodash.get(transaction, 'id', '')}
                                target={"_blank"}
                                className="a-text--break-all"
                            >
                                {lodash.get(transaction, 'code', '')}
                            </a>
                        </b>
                        {
                            transactions.length > 1 &&
                            (index !== transactions.length - 1) &&
                            <span className="mr-1">,</span>
                        }
                    </React.Fragment>
                ));
                let messageKeyRelated = (fromTool) ? 'transaction:log.ADD_TRANSACTION_RELATED_FROM_TOOL' : 'transaction:log.ADD_TRANSACTION_RELATED';
                return trans(messageKeyRelated, {
                    creator: lodash.get(payload, 'creator.name'),
                    transactionRelated: transactions_format,
                });
            case TRANSACTION_ACTION.DELETE_TRANSACTION_RELATED:
                return trans('transaction:log.DELETE_TRANSACTION_RELATED', {
                    creator: lodash.get(payload, 'creator.name'),
                    transactionRelated : <b className="a-text--break-all"><a href={lodash.get(payload, 'attributes.id','')} target={"_blank"}>{lodash.get(payload, 'attributes.code','')}</a></b>,
                });
            case TRANSACTION_ACTION.ADD_TRANSACTION_RELATED_TO:
                let messageKeyRelatedTo = (fromTool) ? 'transaction:log.ADD_TRANSACTION_RELATED_TO_FROM_TOOL' : 'transaction:log.ADD_TRANSACTION_RELATED_TO';
                return trans(messageKeyRelatedTo, {
                    creator: lodash.get(payload, 'creator.name'),
                    transactionRelatedTo : <b className="a-text--break-all"><a href={lodash.get(payload, 'attributes.id','')} target={"_blank"}>{lodash.get(payload, 'attributes.code','')}</a></b>,
                });
            case TRANSACTION_ACTION.DELETE_TRANSACTION_RELATED_TO:
                return trans('transaction:log.DELETE_TRANSACTION_RELATED_TO', {
                    creator: lodash.get(payload, 'creator.name'),
                    transactionRelatedTo : <b className="a-text--break-all"><a href={lodash.get(payload, 'attributes.id','')} target={"_blank"}>{lodash.get(payload, 'attributes.code','')}</a></b>,
                });
            default:
                return log.action;
        }
    }


    /**
     * render message before add new invoice code
     * @param payload
     * @private
     */
    _renderMessageAddInvoiceCode(payload) {
        let invoiceCode = lodash.get(payload, 'attributes.invoice_code', "");
        let message = lodash.get(payload, 'attributes.message', "");
        return trans('transaction:log.ADD_INVOICE_CODE', {
            invoice_code: <span className={"text-primary font-weight-bold"}>{invoiceCode}</span>,
            message: message ? ` (${message})` : ""
        });
    }

    /**
     * render message before delete new invoice code
     * @param payload
     * @private
     */
    _renderMessageDeleteInvoiceCode(payload) {
        let invoiceCode = lodash.get(payload, 'attributes.invoice_code', "");
        let message = lodash.get(payload, 'attributes.message', "");
        return trans('transaction:log.DELETE_INVOICE_CODE', {
            invoice_code: <span className={"text-primary font-weight-bold"}>{invoiceCode}</span>,
            message: message ? ` (${message})` : ""
        });
    }
}

TransactionLogMessage.defaultProps = {
    log: {},
};

TransactionLogMessage.propTypes = {
    log: PropTypes.object,
};

export default translate()(TransactionLogMessage);
