import {takeLatest} from 'redux-saga/effects';

import {
    FETCH_CONFIG_SYSTEM,
    UPDATE_CONFIG_SYSTEM,
} from './constants';
import * as saga from './saga';

export default function* () {
    yield takeLatest(FETCH_CONFIG_SYSTEM.REQUEST, saga.fetchConfigSystem);
    yield takeLatest(UPDATE_CONFIG_SYSTEM.REQUEST, saga.updateConfigSystem);
}
