import React from 'react'
import Spinner from '@atlaskit/spinner';
import Popover from 'react-popover'
import lodash from "lodash";
import {translate} from 'react-i18next';
import {Checkbox} from "antd";
import Tooltip from '@atlaskit/tooltip';

class ServiceItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            onChecked: false,
            isActionLoading: false,
            idServiceItem: 0
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {...prevState};
        const {serviceItem} = nextProps;

        if (JSON.stringify(serviceItem) !== JSON.stringify(prevState.service)) {
            if (serviceItem.confirm && serviceItem.isChecked && prevState.onChecked) {
                state.isOpen = true;
                state.onChecked = false;
            } else {
                state.isOpen = false;
            }
            state.isActionLoading = false;
            state.service = serviceItem;
        }
        return state;
    }

    onApproval(serviceId, stats) {
        const {approvalOrderService, order} = this.props;
        approvalOrderService({
            service_id: serviceId,
            status: stats,
            id: order.id
        });
        this.setState({isActionLoading: true});
    }

    /**
     * Hover popover
     * @param toState
     * @param isConfirm
     * @param checked
     * @param event
     */
    toggle(toState = null, isConfirm, checked, event) {
        let className = event.target.className;
        if (isConfirm && checked) {
            if (!toState || className !== 'a-popover') {
                this.setState({isOpen: toState});
            }
        }
    }

    onHandleChecked(checked) {
        this.setState({onChecked: checked})
    }

    render() {
        const {serviceItem, onChangeCheckbox, t} = this.props;
        let classColor = '';
        if (serviceItem.isChecked) {
            if (serviceItem.confirmAction === 1) {
                classColor = 'a-text--green';
            } else if (serviceItem.confirmAction === 2) {
                classColor = 'a-text--red';
            }
        }

        let showWarning = serviceItem.isChecked && serviceItem.confirm && serviceItem.confirmAction === 0;

        return (
            <div className={serviceItem.show ? "a-purchasod--service__body__group " : "hidden"}>
                <div
                    onMouseEnter={event => this.toggle(true, serviceItem.confirm, serviceItem.isChecked, event)}
                    onMouseLeave={event => this.toggle(false, true, true, event)}
                    className={`a-purchasod--service__body__group__detail ${showWarning ? 'warning' : ''}`}>
                    {!serviceItem.loading ?
                        <Popover
                            isOpen={serviceItem.isDisabled ? false : this.state.isOpen}
                            place="above"
                            body={
                                <div className={'a-popover'} key={serviceItem.id}>
                                    <div className="a-popover--title">
                                        <p><span>{t("order_detail.confirm_approve_service")}</span>{serviceItem.name}</p>
                                    </div>
                                    <div className="a-popover--body">
                                        <div className={'a-loading--service mr-1'}>
                                            {this.state.isActionLoading &&
                                            <Spinner size="small"/>}
                                        </div>
                                        {serviceItem.confirmAction === 1 ?
                                            <div style={{textTransform: "uppercase"}}
                                                 className="a-btn--pop-submit disable">{t('order_detail.APPROVAL')} </div>
                                            :
                                            <button className={'a-btn--pop-submit'}
                                                    style={{textTransform: "uppercase"}}
                                                    onClick={event => this.onApproval(serviceItem.id, 1)}>
                                                {t('order_detail.APPROVAL')}
                                            </button>
                                        }
                                        {serviceItem.confirmAction === 2 ?
                                            <div
                                                className="a-btn--pop-cancel ml-2 disable">{t('order_detail.UNAPPROVED')} </div>
                                            :
                                            <button className={'a-btn--pop-cancel ml-2 '}
                                                    onClick={event => this.onApproval(serviceItem.id, 0)}>
                                                {t('order_detail.UNAPPROVED')}
                                            </button>
                                        }

                                    </div>
                                </div>
                            }
                            children={
                                <div className={'checkbox--group'} key={serviceItem.id}>
                                    <Checkbox
                                           className={'checkbox--service'}
                                           key={serviceItem.id}
                                           checked={!!serviceItem.isChecked}
                                           disabled={serviceItem.isDisabled}
                                           onChange={event => {
                                               let checked = event.target.checked;
                                               if (checked) {
                                                   this.onHandleChecked(checked);
                                               }
                                               onChangeCheckbox(event, serviceItem.id)
                                           }}
                                    />
                                </div>
                            }
                        />
                        :
                        <div className={'a-loading--service'}><Spinner size="small"/></div>
                    }

                    <p className="a-purchasod--service__body__group__info">
                        &nbsp;
                        <i className={
                            lodash.get(serviceItem, 'icon', null) ?
                                lodash.get(serviceItem, 'icon', 'ion-android-globe') : 'ion-android-globe'}
                        />
                        <span>{serviceItem.name}</span>
                    </p>
                    {!!showWarning &&
                    <i className={`fas fa-exclamation-triangle icon-warning-service`}/>
                    }
                </div>
            </div>
        )
    }
}

export default translate('translations')(ServiceItem);
