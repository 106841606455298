import {connect} from 'react-redux';
import Breadcrumbs from './components';
import lodash from 'lodash';

const mapStateToProps = (state) => {
    return {
        breadcrumb: lodash.get(state, "Common.breadcrumb", []),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Breadcrumbs)