import React from "react";
import PropTypes from "prop-types";
import {Col, Row, Divider, Switch, Icon, Button} from "antd";
import {events} from "../../../../../systems/events";
import ORDER_GROUP_EVENTS from "../../events";

class GroupItem extends React.Component {

    handleClick = e => {
        let {group} = this.props;
        events.dispatch(ORDER_GROUP_EVENTS.UPDATED, group);
    }

    handleChange = checked => {
        let {group} = this.props;
        this.props.onUpdateStatusOrderGroup({...group, status: checked === true ? 1 : 0});
    }

    render() {
        let {group, permission} = this.props;
        return <Row className="group-line pt-3 pb-3">
            <Col span={12}>
                <div>
                    <span className={`a-text--color-black ${group.status ? "active" : ""} blue-cycle`}>{group.name}</span>
                    <Divider type="vertical"/>
                    <span className="a-text--color-black-45 mr-2">{group.code}</span>
                    {
                        permission && <span className="a--cursor-poiter" onClick={this.handleClick}><Icon type="edit" /></span>
                    }
                </div>
            </Col>
            <Col span={6}>
                <div className="a-text--center">{group.priority}</div>
            </Col>
            <Col span={6}>
                <div className="a-text--right">
                    <span className="mr-2">Trạng thái</span>
                    {
                        permission ?
                            <Switch size="small" checked={group.status ? true : false} onChange={this.handleChange} /> :
                            <Switch size="small" checked={group.status} />
                    }
                </div>
            </Col>
        </Row>;
    }
}

GroupItem.defaultProps = {
    group: {}
}

GroupItem.propTypes = {
    group: PropTypes.object.isRequired
}

export default GroupItem;
