import apiService from "../apiService";
import lodash from 'lodash';

export default class AccountBankService {
    constructor() {
        this.listBanks = [];
        this.loaded = false;
    }

    getListAccountBank = (filter) => {
        if (this.loaded) {
            return Promise.resolve(this.listBanks)
        }

        return apiService.getListBankAccount(filter).then(res => {
            this.loaded = true;
            this.listBanks = lodash.get(res, 'data.data');
            return this.listBanks;
        })
    };

    list = (filter = {}) => {
        return this.getListAccountBank(filter).then(banks => banks);
    }

}