import React from "react";
import {Affix, Table, Icon, Button, Tooltip, Select} from "antd";
import lodash from "lodash";
import {Link, url} from "../../../../systems/routing";
import {CopyToClipboard} from "react-copy-to-clipboard";
import bootbox from "../../../../modules/common/components/Bootbox/bootbox";
import {translate} from "react-i18next";
import PaginationFull from "../../../../modules/common/components/PaginationFull";
import SynchronizingButton from "./SynchronizingButton";
import moment from "moment";
import SiteIcon from "../../../../modules/Image/SiteIcon";

const Option = Select.Option;
const DEFAULT_INIT = {
    crawlingComplaintId: undefined, // KNNB đang crawl
    failedComplaintIds: [], // crawl lỗi
    successedComplaintIds: [] // crawl thành công
}

class ListComplaintSeller extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...DEFAULT_INIT};
    }

    // Gán KNNB đang được crawl
    setCrawlingComplaintId(complaint_id) {
        this.setState({crawlingComplaintId: complaint_id});
    }

    // Thêm KNNB crawl lỗi
    pushFailedComplaintIds(complaint_id) {
        let {failedComplaintIds} = this.state;
        failedComplaintIds.push(complaint_id);
        this.setState({failedComplaintIds});
    }

    // Thêm KNNB thành công
    pushSuccessedComplaintIds(complaint_id) {
        let {successedComplaintIds} = this.state;
        successedComplaintIds.push(complaint_id);
        this.setState({successedComplaintIds});
    }

    getColumns() {
        let {t} = this.props;
        let columns = [
            {
                key: 'id_order',
                className: "a-text--fz-13",
                render: (text, complaint) => {
                    let order = lodash.get(complaint, "order", {});
                    return (!lodash.isEmpty(order) ?
                            <div className="d-flex">
                                <div>
                                    <div className="d-flex align-item-center">
                                        <Link
                                            isNewTab={true}
                                            href={url.to('purchase.buying.detail', {id: order.id})}
                                        >
                                            <strong>{order.code}</strong>
                                        </Link>
                                        <CopyToClipboard
                                            text={order.code}
                                            onCopy={() => bootbox.success(t("message.copied") + ": " + order.code)}
                                        >
                                            <i className="far fa-copy copy ml-2"/>
                                        </CopyToClipboard>
                                        <p className="ml-2 more_info__action a-text--fz-12">
                                            <Link isNewTab={true}
                                                  href={url.to('complaint-seller.detail', {id: complaint.id})}
                                                  className="link_item">
                                                {t('complaintSeller:list.detail')}
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div> : ""
                    );
                }
            },
            {
                key: 'reason',
                className: 'a-text--fz-13',
                render: (text, complaint) => {
                    return (
                        <div>
                            <div className="group__inline">
                                {
                                    complaint.reason_title &&
                                    <span className="reason__text">{complaint.reason_title}</span>
                                }
                            </div>
                            <div className="group__inline">
                                {
                                    complaint.financial_valid ?
                                        <span className="a-text--color-green a-text--fz-12">Tài chính hợp lệ</span> :
                                        <span className="a-text--light-red a-text--fz-12">Tài chính không hợp lệ</span>
                                }
                            </div>
                        </div>
                    );
                }
            },
            {
                key: 'seller_source',
                className: "a-text--nowrap a-text--fz-13",
                render: (text, complaint) => {
                    let order = lodash.get(complaint, "order", {});
                    return (!lodash.isEmpty(order) ?
                            <div className="group__inline">
                                {order.seller_source ? (
                                    <span className="a-font--12">
                                     <SiteIcon
                                         className="mr-2 img-shop"
                                         src={order.seller_source}
                                         alt={order.seller_source}
                                     />
                                </span>
                                ) : null}
                                <span className="a-font--12">{order.seller_name}</span>
                            </div> : ""
                    );
                }
            },
            {
                key: 'amount',
                className: "a-text--right a-text--nowrap a-text--fz-13",
                render: (text, complaint) => {
                    return (<>
                            <Tooltip
                                content={complaint.status === "SUCCESS" ?
                                    t('complaintSeller:list.amount_earned_success') : t('complaintSeller:list.amount_earned_alipay')}>
                                <span className="a-text--red a--cursor-poiter a-text--bold">{complaint.amount_earned}{complaint.currencySymbol}</span>
                            </Tooltip>
                            &nbsp;/&nbsp;
                            <Tooltip content={t('complaintSeller:list.amount_want_to_earn')}>
                                <span className="a-text--green a--cursor-poiter a-text--bold">{complaint.amount}{complaint.currencySymbol}</span>
                            </Tooltip>
                        </>
                    )
                }
            },
            {
                key: 'receiver_name',
                className: 'a-text--fz-13',
                render: (text, complaint) => {
                    return <span className="a-text--font-blue">{complaint.receiver_name}</span>
                }
            },
            {
                key: 'origin_code',
                className: "a-text--nowrap a-text--fz-13",
                render: (text, complaint) => {
                    let titleComplaint = (<div style={{fontSize: 13}}>
                        <div style={{whiteSpace: "nowrap"}}>Thời gian gia hạn
                            KN: {complaint.origin_expired_at && moment(complaint.origin_expired_at).format("HH:mm DD/MM/YYYY")}</div>
                        <div>Mã KN site gốc: {complaint.origin_code}</div>
                        <div>Số tiền KN: {complaint.origin_amount && `¥${complaint.origin_amount}`}</div>
                        <div>Trạng thái KN: {complaint.origin_status}</div>
                        <div>Mã vận đơn trả hàng: {complaint.origin_delivery_code}</div>
                        <div>Mã HĐG: {complaint.origin_invoice_code}</div>
                    </div>);

                    return (
                        <Tooltip placement="top" title={titleComplaint}>
                            <Link isNewTab={true} href={complaint.origin_link}>
                                <span>{complaint.origin_code}</span>
                            </Link>
                        </Tooltip>
                    )
                }
            },
            {
                key: 'sync',
                className: "a-text--right a-text--nowrap a-text--fz-13",
                render: (text, complaint) => {
                    let {
                        crawlingComplaintId,
                        failedComplaintIds,
                        successedComplaintIds
                    } = this.state;

                    if (crawlingComplaintId !== complaint.id && failedComplaintIds.includes(complaint.id))
                        return (<Icon type="warning" className="a-text--red" />);

                    if (crawlingComplaintId !== complaint.id && successedComplaintIds.includes(complaint.id))
                        return (<Icon type="check" className="a-text--color-green" />);

                    return (
                        <Icon
                            type="sync"
                            spin={crawlingComplaintId !== complaint.id ? false : true}
                            className={crawlingComplaintId !== complaint.id ? "a-text--color-black-30" : ""}
                        />
                    );
                }
            },
        ];

        return columns.map(column => ({
            dataIndex: column.key,
            title: t('complaintSeller:list.' + column.key),
            ...column
        }));
    }

    onChangePage(page, pageSize) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            });
        }
    }

    onNextPage(page) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page
            });
        }
    }

    onChangeSelect(value, param) {
        let filter = this.props.filter;
        filter[param] = value;
        this.props.onSearch({
            ...filter
        });
    };

    render() {
        let {
            loading,
            complaints,
            pagination,
            t,
            filter
        } = this.props;

        return (
            <div className="a-list mt-5">
                <Affix>
                    <div className="a-list__top a-flex">
                        <h2 className="a-list--title a-text--uppercase" style={{lineHeight: "32px"}}>Danh sách KNNB
                            ({!lodash.isEmpty(pagination) ? pagination.total : 0})</h2>
                        <div className="ml-auto mr-2">
                            {t('order:label.sort')}:
                            <Select
                                className="ml-1"
                                value={filter.sort}
                                onChange={value => this.onChangeSelect(value, "sort")}
                            >
                                <Option value="asc">{t('order:sort.asc')}</Option>
                                <Option value="desc">{t('order:sort.desc')}</Option>
                            </Select>
                        </div>
                        <div className="ml-2">
                            {
                                !lodash.isEmpty(complaints) && !lodash.isEmpty(pagination) &&
                                <SynchronizingButton
                                    complaints={complaints}
                                    pagination={pagination}
                                    setIsCrawling={this.props.setIsCrawling}
                                    setCrawlingComplaintId={this.setCrawlingComplaintId.bind(this)}
                                    isCrawling={this.props.isCrawling}
                                    updateComplaints={this.props.updateComplaints}
                                    pushFailedComplaintIds={this.pushFailedComplaintIds.bind(this)}
                                    pushSuccessedComplaintIds={this.pushSuccessedComplaintIds.bind(this)}
                                    onNextPage={this.onNextPage.bind(this)}
                                />
                            }
                        </div>
                    </div>
                </Affix>
                <div className="a-content--is-pagination-fixed" style={{padding: "16px 24px 0 24px"}}>
                    <Table
                        className="a-table--antd a-table--description a-text--color-black-65"
                        columns={this.getColumns()}
                        dataSource={complaints}
                        loading={loading}
                        scroll={{x: 'fit-content'}}
                        pagination={false}
                        rowKey="id"
                    />
                    <PaginationFull
                        page={lodash.toInteger(pagination.current_page) || 1}
                        pageSize={lodash.toInteger(pagination.per_page)}
                        total={pagination.total}
                        pageSizeOptions={[20, 30, 50, 80, 100]}
                        onChange={this.onChangePage.bind(this)}
                        className="a-pagination a-pagination--fixed"
                    />
                </div>
            </div>
        );
    }
}

export default translate()(ListComplaintSeller);
