import { apiWithToken } from './../../../systems/api'
import { API_URL } from './../../../systems/globalContant'

export default {
    getPurchasingOrderDetailAction: (filters = {}) => apiWithToken.get(API_URL.DEFAULT + '/orders/' + filters.id, { params: filters }, { loading: false }),
    updatePaidOrderDetail: (params = {}) => apiWithToken.put(API_URL.DEFAULT + '/order-item/' + params.id, params),

    updateShippingFee: (params = {}) => apiWithToken.put(API_URL.ORDER + '/' + params.id + '/' + API_URL.ORDER_SHIPPING_FREE, { ...params }),
    postInvoiceCode: datas => apiWithToken.post(API_URL.POST_INVOICE_CODE.replace('{id}', datas.id), datas, { loading: false }),
    deleteInvoiceCode: datas => apiWithToken.put(API_URL.POST_INVOICE_CODE.replace('{id}', datas.id), datas, { loading: false }),
    updatePaidOrderStatus: (params = {}) => apiWithToken.put(API_URL.UPDATE_ORDER_INFO + params.id + '/paid', params),
    getShippingFeeHistory: params => apiWithToken.get(API_URL.UPDATE_ORDER_INFO + params.id + '/shipping-fee-sellers', params),

    cancelOrder: (params = {}) => apiWithToken.put(API_URL.CANCEL_ORDER + params.id + '/cancel', params),

    outOfStockOrder: (params = {}) => apiWithToken.put(API_URL.OUT_OF_STOCK_ORDER + params.id + '/out-of-stock', params),

    paymentOrder: (params = {}) => apiWithToken.put(API_URL.UPDATE_ORDER_INFO + params.id + '/pay', params),

    updateOrderGroupOrder: (params = {}) => apiWithToken.put(API_URL.UPDATE_ORDER_GROUP_ORDER + params.orderId + '/order-group', params),

    updateOrderAccountPurchaser: (params = {}) => apiWithToken.put(API_URL.DEFAULT + '/orders/' + params.id + '/account-purchaser', params),
    changeMutipleQuantity: (params = {}) => apiWithToken.put(API_URL.UPDATE_QUANTITY + params.id + '/update-quantity', params),
    confirmCustomerOrderBuying: (params = {}) => apiWithToken.put(API_URL.GET_LIST_ORDER + params.id + '/confirm-quantity', params),
    updateOrderService: (params = {}) => apiWithToken.put(API_URL.DEFAULT + '/orders/' + params.id + '/service', { ...params }),
    waitingPaymentOrder: (params = {}) => apiWithToken.put(API_URL.UPDATE_ORDER_INFO + params.id + '/waiting-pay', params),
    /**
     * Approval order service
     */
    approvalOrderService: (params = {}) => apiWithToken.put(API_URL.DEFAULT + '/orders/' + params.id + '/confirm-service', { ...params }),
    updateOrderItemPrice: (params = {}) => apiWithToken.put(API_URL.UPDATE_ORDER_PRICE.replace('{id}', params.id), { ...params }, { loading: false }),

    changeOutOfStockItems: (params = {}) => apiWithToken.put(API_URL.DEFAULT + '/orders/' + params.id + '/out-stock', { ...params }),

    updatePriceItems: (params = {}) => apiWithToken.put(API_URL.DEFAULT + '/orders/' + params.id + '/update-price', { ...params }),

    updateQuantityItems: (params = {}) => apiWithToken.put(API_URL.DEFAULT + '/orders/' + params.id + '/update-quantity', { ...params }),

    /**
     * Handle freight bill
     * @param data
     * @returns {*|AxiosPromise<any>|void}
     */
    addFreightBill: data => apiWithToken.post(API_URL.ORDER_FREIGHT_BILL.replace('{id}', data.id), data, { loading: false }),
    /**
     * Handle freight bill
     * @param data
     * @returns {*|AxiosPromise<any>|void}
     */
    deleteFreightBill: data =>
        apiWithToken.delete(API_URL.ORDER_FREIGHT_BILL.replace('{id}', data.id), { params: { freight_bill: data.freight_bill } }, { loading: false }),

    /**
     * Change Order rate deposit
     * @param params
     * @returns {AxiosPromise<any> | void | PutEffectDescriptor<any> | ChannelPutEffectDescriptor<any> | IDBRequest<IDBValidKey> | Promise<void>}
     */
    changeRateDeposit: params => apiWithToken.put(API_URL.ORDER + '/' + params.id + '/rate-deposit', { ...params }),

    /**
     *
     * @param params
     * @returns {AxiosPromise<any> | * | void}
     */
    syncOrderBillNo: params => apiWithToken.post(API_URL.DEFAULT + '/orders/' + params.id + '/sync-bill-no'),
    getComplaintSellerByOrder: id_order => apiWithToken.get(API_URL.DEFAULT + `/complaint-sellers/${id_order}/order`),

    /**
     * Create Invoice Code automatically
     * @param order_id
     * @returns {AxiosPromise<any> | void | PutEffectDescriptor<any> | ChannelPutEffectDescriptor<any> | IDBRequest<IDBValidKey> | Promise<void>}
     */
    createInvoiceCodeAuto: order_id => apiWithToken.post(API_URL.ORDER + `/${order_id}/invoice-code-auto`),
    dislikeSeller: (seller_id, params) => apiWithToken.post(API_URL.SELLER + `/${seller_id}/vote`, { ...params }),
    likeSeller: seller_id => apiWithToken.delete(API_URL.SELLER + `/${seller_id}/vote`),
    toggleFragile: (order_id, params) => apiWithToken.put(API_URL.ORDER + `/${order_id}/fragile`, params),
    updateAffiliateLink: order_item_id => apiWithToken.put(API_URL.DEFAULT + `/order-item/${order_item_id}/affiliate-link`),
    fetchTimeline: order_id => apiWithToken.get(API_URL.DEFAULT + `/orders/${order_id}/milestones`),
    updateCustomsInfo: (product_id, params) => apiWithToken.put(API_URL.DEFAULT + `/order-item/${product_id}/customs-info`, params),
    updateCustomsInfoMulti: (order_id, params) => apiWithToken.put(API_URL.DEFAULT + `/orders/${order_id}/customs-info`, params),
    onToggleEcommerceShipping: (order_id, params) => apiWithToken.put(API_URL.ORDER + `/${order_id}/ecommerce-shipping`, params),
    searchInfoProduct: params => apiWithToken.get(API_URL.DEFAULT + `/categories`, { params }),
    canExported: order_id => apiWithToken.post(API_URL.ORDER + `/${order_id}/can-exported`),
    searchHSCode: params =>
        apiWithToken.get(API_URL.HSCODE + `/suggest`, {
            params,
            singleRequest: true,
            loading: false,
        }),
    updateHSCodeOrder: (orderId, data) => apiWithToken.put(API_URL.ORDER + `/${orderId}/hs-code`, data),
    updateHSCodeOrderItem: (orderId, data) => apiWithToken.put(API_URL.ORDER + `/${orderId}/hs-code-items`, data),
    updateOrderFeature: (params = {}) => apiWithToken.put(API_URL.DEFAULT + '/orders/' + params.id + '/property', { ...params }),
    updateItemInternalCode: (itemId, data) => apiWithToken.put(API_URL.ORDER_ITEM + `/${itemId}/internal-code`, data),
    getBargainHistory: (orderId, params) =>
        apiWithToken.get(API_URL.ORDER + `/${orderId}/negotiation-history`, {
            params,
            singleRequest: true,
            loading: false,
        }),
    updatePurchaseAddress: (orderId, data) => apiWithToken.put(API_URL.ORDER + `/${orderId}/purchase-address`, data),
    updateSellerNote: (sellerId, data) => apiWithToken.put(API_URL.SELLER + `/${sellerId}/note`, data),
    updateTotalDiscountOrder: (orderId, data) => apiWithToken.put(API_URL.ORDER + `/${orderId}/discount-amount`, data),
    getBuyerStaffs: orderId => apiWithToken.get(API_URL.DEFAULT + `/buyers/${orderId}/staffs`),
    getOrderProductInfoByLink: (orderId, params) =>
        apiWithToken.get(API_URL.DEFAULT + `/orders/${orderId}/get-item-info`, { params, singleRequest: true, loading: false }),
    addProductItem: (orderId, data) => apiWithToken.post(API_URL.DEFAULT + `/orders/${orderId}/add-item`, data, { singleRequest: true, loading: false }),
    getOrderItemProperties: orderItemId => apiWithToken.get(API_URL.DEFAULT + `/order-item/${orderItemId}/properties`, { singleRequest: true, loading: false }),
    updateOrderItemProperties: (orderItemId, data) =>
        apiWithToken.put(API_URL.DEFAULT + `/order-item/${orderItemId}/properties`, data, { singleRequest: true, loading: false }),
    cancelTaoBaoOrder: orderId => apiWithToken.put(API_URL.DEFAULT + `/taobao-purchaser-orders/${orderId}/cancel`, {}, { singleRequest: true, loading: false }),
    searchClassifyProduct: data => apiWithToken.post(API_URL.DEFAULT + `/classify-products`, data, { singleRequest: true, loading: false }),
    cancelOrder1688: orderId => apiWithToken.put(API_URL.DEFAULT + `/orders-1688/${orderId}/cancel`, {}),
    setBatchOrder: (orderId, data) => apiWithToken.put(API_URL.DEFAULT + `/orders/${orderId}/set-batch-order`, data, { singleRequest: true, loading: false }),
    updateBatchOrder: (orderId, data) =>
        apiWithToken.put(API_URL.DEFAULT + `/orders/${orderId}/update-batch-order`, data, { singleRequest: true, loading: false }),
}
