import React from 'react';
import PropTypes from 'prop-types';
import authService from "../../Auth/authService";
import {Button, Popover} from "antd";
import app from "../../../config/app"

function UploadSampleCSV(props) {
    let content =
        <div className="d-flex flex-column">
            <Button
                href={`${app.publicUrl}/${props.object}-sample.${props.affix || "xls"}`}
                loading={props.loading}
                className=" a-font--12 aphz-24 a-btn--primary-ant a-btn--upload-file ghost d-flex justify-content-center align-items-center mb-3"
                size={"small"}>
                Tải tệp mẫu
            </Button>
            {props.uploadComponent}
        </div>;
    let permission = props.permission ? authService.can(props.permission) : true;
    return (
        permission &&
        <Popover
            content={content}
            placement={"bottom"}
        >
            <Button loading={props.loading} className=" a-font--12 aphz-24 a-btn--primary-ant a-btn--upload-file ghost mr-4"
                    size={"small"}>
                {props.title}
            </Button>
        </Popover>
    );
}

export default UploadSampleCSV;
