import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import ReceiveComplaint from './components/ReceiveComplaint';
import * as actions from './actions';

const mapStateToProps = (state, props) => {
    let {complaint} = props;
    let complaints = lodash.get(state, 'ComplaintSeller.complaintReception.complaints', []);

    return lodash.find(complaints, {id: complaint.id}) || {};
};

const mapDispatchToProps = (dispatch, props) => {
    let {complaint} = props;

    return {
        onReceive: () => {
            dispatch(actions.receiveComplaint(complaint.id));
        },
        onReset: () => {
            dispatch(actions.clearState(complaint.id));
        },
    };
};

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReceiveComplaint);

Container.defaultProps = {
    complaint: {},
};

Container.propTypes = {
    complaint: PropTypes.object.isRequired,
};

export default Container;
