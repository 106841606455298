import apiService from "./apiService";
import {notification} from "antd";
import _ from "lodash";
import {t} from "../../systems/i18n";
import BaseServiceData from "../common/services/BaseServiceData";
import {processResponse} from "../../systems/api";

class ServicePurchaseAddress extends BaseServiceData {

    runApi() {
        let res = apiService.getPurchaseAddressList();
        return processResponse(res)
            .then(res => _.reverse(_.sortBy(_.get(res, "data", []), ['status']))) // Sắp xếp kho nhận theo "status" kho nào bị disable thì cho xuống dưới
            .catch(err => {
                notification.error(t('message.cant_fetch_data'));
                return Promise.reject(err);
            });
    }
}

export default new ServicePurchaseAddress();
