import {combineReducers} from 'redux';
import * as Constant from './constants'

const loading = (state = false, action) => {
    switch (action.type) {
        case Constant.RESOLVE_UPLOAD_FILE.SUCCESS:
        case Constant.RESOLVE_UPLOAD_FILE.FAILED:
        case Constant.TRANSACTION_UPLOAD_FILE_CLEAR_STATE:
            return false;
        case Constant.RESOLVE_UPLOAD_FILE.REQUEST:
            return true;
        default:
            return state;
    }
};

export default combineReducers({
    loading,
});
