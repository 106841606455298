import {call, put} from 'redux-saga/effects'
import * as Constants from './constants';
import service from './apiService';
import bootbox from "../../modules/common/components/Bootbox/bootbox";
import {processApiRequest} from '../common/saga';
import {t} from './../../systems/i18n/index.js';


import * as GlobalConstants from "../../systems/globalContant";

export function* getListOrder(action) {
    try {
        let messageValidates = yield Constants.validate(action.payload);
        if (messageValidates.length > 0) {
            messageValidates.map(messages => {
                Object.keys(messages).map(message =>
                    bootbox.error(t(`order_error.filter_errors.${message}.${messages[message]}`))
                );
                return messages;
            });
            yield put({type: Constants.FETCH_ORDERS.SUCCESS, payload: []});
        } else {
            let response = yield call(service.getListOrder, orderFilter(action.payload));
            if (response) {
                if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                    yield put({type: Constants.FETCH_ORDERS.SUCCESS, payload: response.data, request: action.payload});
                } else {
                    yield put({type: Constants.FETCH_ORDERS.FAILED, payload: response.data, request: action.payload});
                }
            }
        }
    } catch (err) {
        if (err.message !== undefined)
            bootbox.error(t('global.error'));
    }
}

export function* getListTransactionOrder(action) {
    try {
        let response = yield call(service.getListTransactionOrder, action.payload);
        if (response) {
            if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({type: Constants.FETCH_TRANSACTION_ORDERS.SUCCESS, payload: response.data, request: action.payload});
            } else {
                yield put({type: Constants.FETCH_TRANSACTION_ORDERS.FAILED, payload: response.data, request: action.payload});
            }
        }
    } catch (err) {
        bootbox.error(t('global.error'));
    }
}


export function* onFetchOrdersSuccess(action) {
    if (action.request.fetch_stats) {
        yield put({type: Constants.FETCH_ORDER_COUNT.REQUEST, payload: action.request});
        yield put({type: Constants.FETCH_ORDER_STATS.REQUEST});
    }
}

/**
 * Convert filter
 *
 * @param filter
 * @returns {*}
 */
function orderFilter(filter) {
    if (filter.grand_total_to) {
        filter = {...filter, grand_total_to: filter.grand_total_to * 1000};
    }
    if (filter.grand_total_from) {
        filter = {...filter, grand_total_from: filter.grand_total_from * 1000};
    }

    return filter;
}

export function* fetchServices(action) {
    try {
        let response = yield call(service.fetchServices, action.payload);
        if (response) {
            if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({type: Constants.FETCH_SERVICES.SUCCESS, payload: response.data});
            } else {
                yield put({type: Constants.FETCH_SERVICES.FAILED, payload: response.data});
            }
        }
    } catch (err) {
        bootbox.error(t('global.error'));
    }
}

export function* fetchOrderCount(action) {
    yield processApiRequest(Constants.FETCH_ORDER_COUNT, () => service.countOrders(orderFilter(action.payload)), action.payload);
}

export function* fetchOrderStats(action) {
    yield processApiRequest(Constants.FETCH_ORDER_STATS, () => service.getOrderStats(), action.payload);
}
