import * as Constants from './constants';
import lodash from 'lodash';
import {combineReducers} from 'redux';

const list = (state = [], action) => {
    let {payload} = action || {};
    switch (action.type) {
        case Constants.FETCH_SERVICES.SUCCESS:
            return lodash.get(payload, 'data', []);
        default:
            return state;
    }
};

export default combineReducers({
    list
});
