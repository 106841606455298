import * as Constants from './constants';
import lodash from 'lodash';
import {combineReducers} from 'redux';

const list = (state = [], action) => {
    let {payload} = action || {};
    let params = lodash.get(payload, 'params', {})
    switch (action.type) {
        case Constants.FETCH_ORDER_GROUP.SUCCESS:
            return lodash.get(payload, 'data', []);
        case Constants.UPDATE_ORDER_GROUP.SUCCESS:
            let datadetail = lodash.get(payload, 'data.orderGroup', {});
            state = state.map(stateItem=> {
                if (stateItem.id === datadetail.id) {
                    return datadetail;
                }
                return stateItem
            });
            return state;
        case Constants.CREATE_ORDER_GROUP.SUCCESS:
            let stateClone = state;
            let dataCreated = lodash.get(payload, 'data.orderGroup', {});
            if (!lodash.isEmpty(dataCreated)) {
                let found = stateClone.find(function (element) {
                    return element.id === dataCreated.id;
                });

                if (lodash.isEmpty(found)) {
                    stateClone.unshift(dataCreated);
                }
            }
            return stateClone;

        case Constants.UPDATE_STATUS_ORDER_GROUP.REQUEST:
            state = state.map(stateItem=> {
                if (stateItem.id === params.id) {
                    return params;
                }
                return stateItem
            });
            return state;
        case Constants.UPDATE_STATUS_ORDER_GROUP.FAILED:
            state = state.map(stateItem=> {
                if (stateItem.id === payload.id) {
                    return {...payload, ...{status: payload.status ? 0 : 1}};
                }
                return stateItem
            });
            return state;
        default:
            return state;
    }
};

const dataDetail = (state = {}, action) => {
    let {payload} = action || {};
    let params = lodash.get(payload, 'params', {})
    switch (action.type) {
        case Constants.FETCH_DETAIL_ORDER_GROUP.SUCCESS:
        case Constants.UPDATE_ORDER_GROUP.SUCCESS:
        case Constants.CREATE_ORDER_GROUP.SUCCESS:
            return lodash.get(payload, 'data.orderGroup', {});
        case Constants.FETCH_ORDER_GROUP.REQUEST:
        case Constants.CANCEL_FORM_ORDER_GROUP.REQUEST:
            return {status: 1};
        case Constants.UPDATE_STATUS_ORDER_GROUP.REQUEST:
            if (!lodash.isEmpty(state) && params.id === state.id) {
                return params;
            }
            return {status: 1};
        case Constants.UPDATE_STATUS_ORDER_GROUP.FAILED:
            if (!lodash.isEmpty(state)) {
                return {...payload, ...{status: payload.status ? 0 : 1}}
            }
            return state ;
        default:
            return state;
    }
};

const dataError = (state = {}, action) => {
    let {payload} = action || {};
    switch (action.type) {
        case Constants.CREATE_ORDER_GROUP.FAILED:
        case Constants.UPDATE_ORDER_GROUP.FAILED:
            return lodash.get(payload, 'data', {});
        case Constants.CREATE_ORDER_GROUP.REQUEST:
        case Constants.UPDATE_ORDER_GROUP.REQUEST:
        case Constants.CANCEL_FORM_ORDER_GROUP.REQUEST:
        case Constants.FETCH_DETAIL_ORDER_GROUP.REQUEST:
            return {};
        case Constants.RESET_ERROR_INPUT_ORDER_GROUP.REQUEST:
            let params = lodash.get(payload, 'params', {});
            let orderDataError = state;
            if ( lodash.has(orderDataError, 'name')
                 && lodash.get(params, 'name', '').length > 0)
            {
                orderDataError = lodash.omit(orderDataError, 'name');
            }

            if ( lodash.has(orderDataError, 'code')
                && lodash.get(params, 'code', '').length > 0)
            {
                orderDataError = lodash.omit(orderDataError, 'code');
            }
            return orderDataError;
        default:
            return state;
    }
};

export default combineReducers({
    list,
    dataDetail,
    dataError
});
