import React, {useEffect, useState} from 'react';
import {translate} from "react-i18next";
import {t} from "../../../../../systems/i18n";
import {Empty, notification, Spin} from "antd";
import {get, includes} from "lodash";
import {dateFormatter} from "../../../../common/services/format";
import apiService from "../../../apiService";
import {processResponse} from "../../../../../systems/api";
import PackageLogMessage from "./PackageLogMessage";

function PackageLog(props) {
    const {complaintId, packageCode} = props;
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getLogs();
    }, []);

    function getLogs() {
        setLoading(true);
        const process = processResponse(apiService.getPackageLog(complaintId, {packages: [packageCode]}));
        process.then(res => {
            const logsData = get(res, "data.logs." + packageCode, []);
            setLogs(logsData);
        }).catch(() => {
            notification.error({message: t("message.cant_fetch_data")})
        }).finally(() => setLoading(false))
    }

    return (

        <div>
            {
                loading ? <div className="text-center"><Spin size="large"/></div> :
                    logs.length === 0 ?
                        <Empty
                            className="pt-2"
                            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                            description={<span>{t("transaction:label.no_data")}</span>}
                        >
                        </Empty> :
                        <ul className="a-log--transaction__body p-0">
                            {logs.map(log => {
                                return <>
                                    <li className="a-log--transaction__item a-text--fz-14 pb-4 border-bottom"
                                        key={log.id}>
                                        <div className="d-flex justify-content-between mb-2">
                                            <span className="creator-log">
                                                <b>
                                                    {get(log, 'creator.name')}
                                                </b>
                                            </span>
                                            <span className="time-log">
                                                {
                                                    dateFormatter.dateTime(get(log, 'time'))
                                                }
                                            </span>
                                        </div>
                                        <PackageLogMessage log={log}/>
                                    </li>
                                </>
                            })}
                        </ul>
            }
        </div>
    )
}

export default translate()(PackageLog)
