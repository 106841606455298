import {put, call} from 'redux-saga/effects';
import lodash from 'lodash';
import {processApiRequest} from '../../common/saga';
import * as actions from './actions';
import * as ACTION from './constants';
import {DISTRIBUTE_STATUS} from './constants';
import apiService from './apiService';
import bootbox from '../../common/components/Bootbox/bootbox';
import {t} from '../../../systems/i18n';
import {getState} from '../../../systems/store';

export function* assignPurchaserNextOrder() {
    let status = getState('Order.DistributeOrder.status');

    if (status !== DISTRIBUTE_STATUS.LOADING) {
        return;
    }

    let orders = getState('Order.ListOrder.list', []);
    let purchaserId = getState('Order.DistributeOrder.input.purchaserId');
    let order = lodash.find(orders, {distribute_status: DISTRIBUTE_STATUS.PENDING});

    return order
        ? yield put(actions.assignPurchaser(order.id, purchaserId, false))
        : yield put(actions.finishAssignPurchaserOrders());
}

export function* assignPurchaserOrders(action) {
    yield processApiRequest(
        ACTION.ASSIGN_PURCHASER_ORDERS,
        () => apiService.assignPurchaserOrders(action.payload.orderIds, action.payload.purchaserId),
        action.payload
    );
}

export function* onAssignPurchaserOrdersFailed() {
    yield bootbox.error(t('order:distribute_order.assign_purchaser_failed'))
}

export function* assignPurchaser(action) {
    yield processApiRequest(
        ACTION.ASSIGN_PURCHASER,
        () => apiService.assignPurchaser(action.payload.orderId, action.payload.purchaserId),
        action.payload
    );
}

export function* onAssignPurchaserSuccess(action) {
    return action.request.single
        ? yield bootbox.success(t('order:distribute_order.assign_purchaser_success'))
        : yield call(assignPurchaserNextOrder);
}

export function* onAssignPurchaserFailed(action) {
    return action.request.single
        ? yield bootbox.error(t('order:distribute_order.assign_purchaser_failed'))
        : yield call(assignPurchaserNextOrder);
}

export function* unassignPurchaser(action) {
    yield processApiRequest(
        ACTION.UNASSIGN_PURCHASER,
        () => apiService.unassignPurchaser(action.payload.orderId),
        action.payload
    );
}

export function* onUnassignPurchaserSuccess(action) {
    if (action.request.single) {
        yield bootbox.success(t('order:distribute_order.unassign_purchaser_success'));
    }
}

export function* onUnassignPurchaserFailed(action) {
    if (action.request.single) {
        yield bootbox.error(t('order:distribute_order.unassign_purchaser_failed'));
    }
}