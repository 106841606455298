import {processApiRequest} from '../../common/saga';
import lodash from 'lodash';
import * as ACTION from './constants';
import apiService from '../apiService';
import {t} from '../../../systems/i18n';
import bootbox from '../../common/components/Bootbox/bootbox';
import {dispatch} from './../../../systems/store';
import {fetchProductReturnLogs} from '../actions';
import * as GlobalConstants from './../../../systems/globalContant';

export function* updateShippingBill(action) {
    yield processApiRequest(
        ACTION.UPDATE_SHIPPING_BILL,
        () => apiService.updateShippingBill(action.payload.id, action.payload.codes),
        action.payload
    );
}

export function* onUpdateShippingBillSuccess(action) {
    let {id} = action.request;
    setTimeout(() => dispatch(fetchProductReturnLogs(id)), 2000);

    yield bootbox.success(t('complaintSeller:product_return.message_update_shipping_bill_success'));
}

export function* onUpdateShippingBillFailed(action) {
    let error = action.payload.data;
    if (action.payload.code !== GlobalConstants.GLOBAL_CONTANT.PERMISSION_DENIED) {
        if (lodash.get(error, 'shipping_bill_codes.required')) {
            yield bootbox.error(t('validation:required', {attribute: t('complaintSeller:detail.bill_no')}));
        } else if (lodash.get(error, 'status.status_invalid', false)) {
            yield bootbox.error(t('complaintSeller:product_return.message_status_change'));
        } else {
            yield bootbox.error(t('complaintSeller:product_return.message_update_shipping_bill_failed'));
        }
    }

}
