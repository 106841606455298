import React from 'react'
import lodash from 'lodash'
import { translate } from 'react-i18next'
import FeatureItem from './featureItem'

class Features extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: true,
            features: [],
            order_id: 0,
            isFetchNewFeature: false,
            featureGroups: {},
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let state = { ...prevState }
        const { features, order } = nextProps
        if (prevState.order_id !== order.id || nextProps.isFetchNewFeature === true || JSON.stringify(features) !== JSON.stringify(prevState.featureGroups)) {
            let orderFeatures = lodash.get(order, 'properties', [])
            let newFeatures = lodash.merge([], features)
            newFeatures.map(feature => {
                let featureItems = lodash.get(feature, 'properties', [])
                featureItems.map(featureItem => {
                    orderFeatures.map(orderFeature => {
                        if (orderFeature.property_id === featureItem.id && featureItem.show === 1) {
                            featureItem.loading = 0
                            featureItem.isChecked = 1
                            featureItem.confirmAction = orderFeature.confirm
                        }

                        return orderFeature
                    })
                    if (!order.canChangeProperty) {
                        featureItem.isDisabled = true
                    }
                    return featureItem
                })

                return feature
            })
            state.features = newFeatures
            state.order_id = order.id

            state.featureGroups = features
        } else {
            return null
        }

        return state
    }

    /**
     * Function when click checkbox
     */
    onChangeCheckbox(event, featureId) {
        let { features } = this.state
        const { order, updateOrderFeature } = this.props

        let newFeatures = lodash.merge([], features)
        newFeatures.map(featureGroup => {
            let featuresInGroup = lodash.get(featureGroup, 'properties', [])
            featuresInGroup.map(feature => {
                if (feature.id === featureId) {
                    feature.loading = true
                }
                return feature
            })
            return featureGroup
        })
        this.setState({ features: newFeatures })
        updateOrderFeature({
            id: order.id,
            property_id: featureId,
            status: event.target.checked ? 1 : 0,
        })
    }

    render() {
        const { features } = this.state
        const { order, isFetchNewFeature } = this.props
        const isCNAddress = lodash.get(order, 'isCnAddress', false)

        return (
            <div className="d-flex flex-wrap justify-content-end border-bottom">
                {features.map(featureGroup => (
                    <div
                        key={featureGroup.id}
                        className={'p-2 ' + (features.length > 1 ? 'border-right' : '')}>
                        <div className="d-flex">
                            {featureGroup.properties.map(featureItem => {
                                if (isCNAddress) {
                                    featureItem = {...featureItem, isDisabled: true}
                                }
                                return (
                                    <FeatureItem
                                        key={featureItem.id}
                                        order={order}
                                        featureItem={featureItem}
                                        onChangeCheckbox={this.onChangeCheckbox.bind(this)}
                                        isFetchNewFeature={isFetchNewFeature}
                                    />
                                )
                            })}
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

export default translate('translations')(Features)
