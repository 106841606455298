import {handleActions} from 'redux-actions';
import {
    GET_ERROR_404,
    GET_PARTNER_CONFIG,
    WINDOW_PAGE,
    NOTIFICATION,
    PRINT_STAMP_PACKAGE,
    TYPE_PRINT,
    PUSHER_CREATE_NEW_ORDER_EVENT,
    CLEAR_COUNT_CREATE_NEW_ORDER_EVENT, FIRE_MENU_STATUS
} from '../../systems/globalContant/actionContant';
import lodash from "lodash";

export default handleActions({
        [GET_PARTNER_CONFIG.SUCCESS]: (prevState, action) => {
            let {data} = action.payload;
            return {
                partner: {
                    name: data.domain,
                    logo: data.logo,
                    code: data.code
                },
            };
        },
        [PUSHER_CREATE_NEW_ORDER_EVENT]: (prevState, action) => {
            return {
                ...prevState,
                countWaitingBuyOrder: lodash.get(prevState, "countWaitingBuyOrder", 0) + 1
            };
        },
        [CLEAR_COUNT_CREATE_NEW_ORDER_EVENT]: (prevState, action) => {
            return {
                ...prevState,
                countWaitingBuyOrder: 0
            };
        },
        [FIRE_MENU_STATUS]: (prevState, action) => {
            return {
                ...prevState,
                isOpenMenuBar: action.payload.isOpen
            };
        },
        [GET_ERROR_404.SUCCESS]: (prevState, action) => {
            return {
                ...prevState,
                error404: true
            };
        },
        [GET_ERROR_404.CLEAR]: (prevState, action) => {
            return {
                ...prevState,
                error404: false
            };
        },
        [WINDOW_PAGE.UPDATE_BREADCRUMB]: (prevState, action) => {
            return {
                ...prevState,
                breadcrumb: action.payload.data
            };
        },
        [WINDOW_PAGE.UPDATE_PAGE_DESCIPTION]: (prevState, action) => {
            return {
                ...prevState,
                subDesc: action.payload.subDesc
            };
        },
        [WINDOW_PAGE.UPDATE_TITLE]: (prevState, action) => {
            return {
                ...prevState,
                title: action.payload.title
            };
        },
        [WINDOW_PAGE.UPDATE_MENU_ACTIVE]: (prevState, action) => {
            return {
                ...prevState,
                menu_active: action.payload.menu_active
            };
        },
        [WINDOW_PAGE.UPDATE_LAYOUT]: (prevState, action) => {
            return {
                ...prevState,
                current_layout: action.payload
            }
        },
        [NOTIFICATION.SET_EMPTY_MESSAGE]: (prevState, action) => {
            return {
                ...prevState,
                notification: {
                    message: "",
                    data: "",
                    type: ""
                }
            };
        },
        [NOTIFICATION.SET_MESSAGE]: (prevState, action) => {
            return {
                ...prevState,
                notification: {
                    message: action.payload.message,
                    type: action.payload.type,
                    data: action.payload.data,
                    module: action.payload.module || ""
                }
            };
        },
        [PRINT_STAMP_PACKAGE.PRINT]: (prevState, action) => {

            return {
                ...prevState,
                dataPrint: {
                    typePrint: TYPE_PRINT.PACKAGE,
                    packageData: action.payload.packageData,
                    warehouseCode: action.payload.warehouseCode,
                    numberStamp: action.payload.numberStamp || 1
                }
            };
        }
    },
    {
        data: [],
        breadcrumb: [
            {
                'label': 'Home',
                'link': '#'
            }
        ]
    });
