import React, { useEffect, useState } from 'react'
import { Avatar, Button, Form, Icon, Input, Modal, notification, Radio, Spin, Tooltip } from 'antd'
import { filter, find, flatten, get, groupBy, isEmpty, isEqual, map, reduce, uniqWith } from 'lodash'
import { processResponse } from '../../../../../systems/api'
import apiService from '../../apiService'
import { t } from '../../../../../systems/i18n'
import { Link } from '../../../../../systems/routing'
import { currentFormatter } from '../../../../common/services/format'

export default function UpdateProperties({ orderItem, updateItemsOrderDetail }) {
    const [visible, setVisible] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [loading, setLoading] = useState(false)
    const [input, setInput] = useState({})
    const [productInfo, setProductInfo] = useState({})
    const [skuList, setSkuList] = useState({})
    const [properties, setProperties] = useState({})
    const [propertiesAllow, setPropertiesAllow] = useState([])
    const [skuInfo, setSkuInfo] = useState({})

    useEffect(() => {
        if (visible) {
            getOrderItemProperties()
        }
    }, [visible, orderItem])

    function getOrderItemProperties() {
        const itemId = get(orderItem, 'id')
        setIsFetching(true)
        processResponse(apiService.getOrderItemProperties(itemId))
            .then(res => {
                const data = get(res, 'data', {})
                refactorData(data)
            })
            .catch(() => {
                notification.error({ message: 'Không lấy được thông tin thuộc tính sản phẩm' })
            })
            .finally(() => setIsFetching(false))
    }

    function handleModal(value) {
        setVisible(value)
        if (!value) {
            setProductInfo({})
            setSkuList({})
            setProperties({})
            setPropertiesAllow([])
            setInput({})
            setIsFetching(false)
        }
    }

    function refactorData(response) {
        const productInfo = get(response, 'itemData', {})
        const skuInfo = find(get(productInfo, 'sku_list', []), item => {
            const skuId = get(response, 'orderItem.sku')
            return item.sku_id == skuId
        })
        const skuList = filter(get(productInfo, 'sku_list', []), item => !!item.quantity && item.status === 'active')
        const data = map(skuList, 'properties')
        const properties = uniqWith(flatten(data), isEqual)
        const propertiesAllow = []
        skuList.map(item => {
            const skuProperties = get(item, 'properties', [])
            const skuPropertiesKey = skuProperties.map(item => [item.prop_id, item.value_id].join('_'))
            const skuId = get(item, 'sku_id')
            propertiesAllow.push([skuId, ...skuPropertiesKey].join(':'))
        })
        const inputProperties = get(skuInfo, 'properties', [])
        setInput({
            properties: reduce(
                inputProperties,
                (result, value) => {
                    return {
                        ...result,
                        [value.prop_id]: value.prop_id + '_' + value.value_id,
                    }
                },
                {}
            ),
        })
        setSkuInfo(skuInfo)
        setSkuList(skuList)
        setProductInfo(productInfo)
        setPropertiesAllow(propertiesAllow)
        setProperties(groupBy(properties, item => item.prop_id)) //data properties
    }

    function handleUpdateProduct() {
        const itemId = get(orderItem, 'id')
        setLoading(true)
        processResponse(apiService.updateOrderItemProperties(itemId, { sku: skuInfo }))
            .then(res => {
                handleModal(false)
                updateItemsOrderDetail(res)
                notification.success({ message: 'Cập nhật thuộc tính cho sản phẩm thành công!' })
            })
            .catch(() => {
                notification.error({ message: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu!' })
            })
            .finally(() => setLoading(false))
    }

    function handleChooseProperty(prop_id, value) {
        const inputProperties = get(input, 'properties', {})
        const updatedProperties = {
            ...inputProperties,
            [prop_id]: value,
        }
        if (Object.keys(updatedProperties).length === Object.keys(properties).length) {
            const valueOfProperties = Object.values(updatedProperties)
            const key = find(propertiesAllow, property => {
                return valueOfProperties.every(item => property.includes(item))
            })
            const skuId = get(key.split(':'), '0')
            setSkuInfo(find(skuList, item => item.sku_id == skuId))
        }
        handleChangeInput('properties', updatedProperties)
    }

    function handleChangeInput(name, value) {
        if (name === 'quantity') {
            const regex = /^\+?([1-9]\d*)$/
            if (value === '' || regex.test(value)) {
                setInput(prevState => {
                    return {
                        ...prevState,
                        [name]: value,
                    }
                })
            }
        } else {
            setInput(prevState => {
                return {
                    ...prevState,
                    [name]: value,
                }
            })
        }
    }

    const inputProperties = get(input, 'properties')
    return (
        <>
            <Tooltip title={'Cập nhật thuộc tính sản phẩm'}>
                <Icon type="edit" className="ml-1" onClick={() => handleModal(true)} />
            </Tooltip>

            {visible && (
                <Modal
                    width={800}
                    title={`Cập nhật thuộc tính cho sản phẩm`}
                    maskClosable={false}
                    visible={visible}
                    onCancel={() => handleModal(false)}
                    footer={[
                        <Button key="cancel" onClick={() => handleModal(false)}>
                            {t('btn.cancel')}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            loading={loading}
                            onClick={handleUpdateProduct}
                            disabled={isEmpty(input) || Object.keys(inputProperties).length < Object.keys(properties).length}>
                            {t('btn.update')}
                        </Button>,
                    ]}>
                    <Spin spinning={isFetching} className="text-center d-block">
                        {!isEmpty(productInfo) && (
                            <div className="mt-4 add-product-info">
                                <div className="d-flex">
                                    <Avatar className="mr-2" shape="square" size={120} src={get(skuInfo, 'pic_url', get(productInfo, 'image', ''))} />
                                    <div className="ml-4">
                                        <Form.Item className="d-flex mb-0" label={<b>Tên sản phẩm</b>}>
                                            <Link isNewTab={true} style={{ textDecorationLine: 'none' }} href={get(productInfo, 'link', '#')}>
                                                {get(productInfo, 'title', '')}
                                            </Link>
                                        </Form.Item>

                                        <Form.Item className="d-flex mb-0" label={<b>Đơn giá gốc</b>}>
                                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                                                {'¥' + currentFormatter.toLocaleStringCurrency(Number(get(skuInfo, 'price', get(productInfo, 'price', 0)), 2))}
                                            </span>
                                        </Form.Item>

                                        <Form.Item className="d-flex mb-1" label={<b>Đơn giá sale</b>}>
                                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                                                {'¥' +
                                                    currentFormatter.toLocaleStringCurrency(
                                                        Number(get(skuInfo, 'promotion_price', get(productInfo, 'promotion_price', 0)), 2)
                                                    )}
                                            </span>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    {!isEmpty(properties) &&
                                        propertiesAllow.length > 0 &&
                                        Object.keys(properties).map(key => {
                                            const listValue = get(properties, key, [])
                                            return (
                                                <Form.Item key={key} className="mb-1" label={<b>{get(listValue, '0.prop_name', '')}</b>}>
                                                    <Radio.Group
                                                        buttonStyle="solid"
                                                        onChange={e => handleChooseProperty(key, e.target.value)}
                                                        value={get(input, `properties.${key}`)}>
                                                        {listValue.map(item => {
                                                            let valueOfProperties = []
                                                            let disabled = false
                                                            if (Object.keys(properties).length > 1) {
                                                                if (!isEmpty(inputProperties)) {
                                                                    valueOfProperties = Object.values(inputProperties)
                                                                    disabled = isEmpty(
                                                                        find(
                                                                            propertiesAllow,
                                                                            property =>
                                                                                property.includes(item.prop_id + '_' + item.value_id) &&
                                                                                valueOfProperties.some(value => property.includes(value))
                                                                        )
                                                                    )
                                                                    if (valueOfProperties.length === 1 && !!get(inputProperties, key)) {
                                                                        disabled = false
                                                                    }
                                                                }
                                                            } else {
                                                                disabled = isEmpty(
                                                                    find(propertiesAllow, property => property.includes(item.prop_id + '_' + item.value_id))
                                                                )
                                                            }
                                                            return (
                                                                <Radio.Button
                                                                    key={item.value_id}
                                                                    value={item.prop_id + '_' + item.value_id}
                                                                    disabled={disabled}>
                                                                    {item.value_name}
                                                                </Radio.Button>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </Form.Item>
                                            )
                                        })}
                                </div>
                            </div>
                        )}
                    </Spin>
                </Modal>
            )}
        </>
    )
}
