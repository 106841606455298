import React from 'react';
import {getState} from "../../../systems/store";
import {Avatar, Menu, Dropdown} from 'antd';
import LogoutContainer from "../../logout/LogoutContainer";
import {getValue, settings} from "../../../modules/Settings/UserSetting/ulti";
import lodash from 'lodash';

class UserInfo extends React.PureComponent {
    /**
     * Kiểm tra xem render lại có thay đổi channel k thì add lại
     * @param channels
     * @private
     */
    _addChannels(channels) {
        if (!lodash.isEmpty(this.pusher, true) && channels.length > 0) {
            let objCurrentChannel = this.pusher.getChannel();
            channels.map((channel) => {
                if (!objCurrentChannel[channel]) {
                    this.pusher.addChannel(channel);
                    console.info('Pusher add chancel: ', channel);
                }

                return channel;
            });

        }
    }
    render() {
        let {globalSetting} = this.props;
        let logoSettings = getValue(settings.UPDATE_LOGO, globalSetting);
        this._addChannels(this.props.channels);
        let user = getState('User.me.user', {});
        const menu = (
            <Menu>
                <Menu.Item style={{textAlign: "center"}}>
                    <Avatar size="large" src={logoSettings || require('../../HomeMadeComponents/lALPAuoR5ZXpjaDNBcbNBgw_1548_1478.png')}/>
                    <p className="a-text--bold text-uppercase mt-2">{user.name}</p>
                    <p className="mt-2">{user.username}</p>
                </Menu.Item>
                <Menu.Item>
                    <LogoutContainer/>
                </Menu.Item>
            </Menu>
        );
        return (
            <Dropdown overlay={menu} placement="bottomRight">
                <div>
                    <span style={{fontSize: 14}}>{user.username || user.name} </span>
                    <Avatar
                        size="large"
                        src={logoSettings || require('../../HomeMadeComponents/lALPAuoR5ZXpjaDNBcbNBgw_1548_1478.png')}
                        alt={"avatar"}
                    />
                </div>
            </Dropdown>
        );
    }
}

export default UserInfo;
