import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Icon, Tooltip} from 'antd';
import {ReactComponent as NewTab} from "../../../../resources/img/newtab.svg";
import {url} from "../../../../systems/routing";
import Link from "../../../../systems/routing/Link";
import lodash from 'lodash';
import {trans} from "../../../../systems/i18n";

class OrderCode extends React.Component {

    showModalEidtCode() {
        this.props.setCurrentTransaction({
            idTransaction: this.props.id,
            showModal: true,
            invoiceCodes: this.props.invoiceCodes,
            canUpdateInvoiceCode: this.props.canUpdateInvoiceCode,
            canDeleteInvoiceCode: this.props.canDeleteInvoiceCode,
            maxInvoiceCode: this.props.maxInvoiceCode,
            transactionType: this.props.transactionType
        })
    };

    componentWillUnmount() {
        this.props.clearState();
    }

    render() {
        let {invoiceCodes, canUpdateInvoiceCode, canDeleteInvoiceCode, invoiceCodeChanged} = this.props;
        return (
            <div className="d-flex">
                <ul className="w-100">
                    {invoiceCodes.map(invoiceCode => {
                        const orderPayments = lodash.get(invoiceCode, "orderPayments", []); //don YCTT
                        const order = lodash.get(invoiceCode, "order", {}); //don mua ho

                        return (
                            <li className="d-flex w-100" key={invoiceCode.invoice_code}>
                                <div className="d-flex flex-wrap">
                                    {
                                        invoiceCode.order &&
                                        <div className="mr-4">
                                            <a href={invoiceCode.order.partner_url_order_detail} target={"_blank"}>
                                                {invoiceCode.order.code}
                                            </a>
                                        </div>
                                    }

                                    {
                                        orderPayments.map((item, key) => {
                                            return <div className="mr-4" key={key}>
                                                <a href={item.partner_url_order_detail} target={"_blank"}>
                                                    {item.code}
                                                </a>
                                            </div>
                                        })
                                    }
                                    {
                                        (orderPayments.length === 0 && lodash.isEmpty(order)) &&
                                        <div className="mr-4">--</div>
                                    }
                                </div>
                                <ul className="ml-4 d-flex w-100">
                                    <li className="d-flex w-100 justify-content-end">
                                        <div className="a-group--icon-edit mb-2">
                                            {
                                                invoiceCode.order !== null ?
                                                    <Link
                                                        href={invoiceCode.link_invoice_code}
                                                        className="link_item"
                                                        isNewTab={true}
                                                    >
                                                        {invoiceCode.invoice_code}
                                                    </Link> : <span className="info">{invoiceCode.invoice_code}</span>
                                            }

                                            {(canUpdateInvoiceCode || canDeleteInvoiceCode) ?
                                                <span className="icon-edit"
                                                      onClick={this.showModalEidtCode.bind(this)}
                                                >
                                        <Icon type="edit"/>
                                        </span> : null}

                                        </div>
                                    </li>
                                </ul>
                            </li>
                        );
                    })}
                </ul>
                {
                    invoiceCodeChanged ?
                        <Badge
                            count={<Icon
                                type="check-circle"
                                theme="outlined"
                                offset={[0, 100]}
                                style={{color: '#52c41a', fontSize: '0.6em'}}
                                title={trans('transaction:label.invoice_code_changed')}
                            />}
                            offset={[-18, 2]}
                        > </Badge>
                        : null
                }
            </div>);
    }
}

OrderCode.defaultProps = {
    invoiceCodes: [],
    orders: [],
    id: 0,
    canUpdateInvoiceCode: false,
    canDeleteInvoiceCode: false
};

OrderCode.propTypes = {
    canUpdateInvoiceCode: PropTypes.bool,
    canDeleteInvoiceCode: PropTypes.bool,
    invoiceCodes: PropTypes.array,
    orders: PropTypes.array,
    id: PropTypes.number,
    setCurrentTransaction: PropTypes.func,
    clearState: PropTypes.func,
};

export default OrderCode;
