import { translate } from 'react-i18next'
import { Button, Input, Modal, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { processResponse } from '../../../systems/api'
import lodash from 'lodash'
import apiService from '../apiService'
import { PAYMENT_ORDER_ERROR } from '../../PurchaseBuying/PaymentOrder/constants'
import { VIOLATE_REASON_TYPE } from '../../../systems/globalContant/actionContant'
import { trans } from '../../../systems/i18n'

function CreatePaymentRequestBySBOrder(props) {
    const { t, orderIds, className, violateReasons } = props
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [errors, setErrors] = useState([])
    const [note, setNote] = useState()
    const [violated, setViolated] = useState({})
    const [reasons, setReasons] = useState({})
    const [reasonsDataLength, setReasonsDataLength] = useState(0)
    const [violatedDataLength, setViolatedDataLength] = useState(0)

    useEffect(() => {
        let reasonsDataLength = 0
        Object.values(reasons).map(item => (reasonsDataLength += Object.values(item).length))
        setReasonsDataLength(reasonsDataLength)
    }, [reasons])

    useEffect(() => {
        let violatedDataLength = 0
        Object.values(violated).map(item => (violatedDataLength += Object.values(item).length))
        setViolatedDataLength(violatedDataLength)
    }, [violated])

    function createPaymentRequest() {
        if (orderIds.length > 0) {
            setLoading(true)
            let payload = { orderIds, note_teller: note }
            if (violatedDataLength > 0 && violatedDataLength === reasonsDataLength) {
                payload = { ...payload, confirm: 1, reasons }
            }
            processResponse(apiService.createPaymentRequestBySBOrder(payload))
                .then(res => {
                    console.log('res', res)
                    cancelModal()
                    notification.success({ message: 'Tạo YCTT thành công!' })
                })
                .catch(err => {
                    if (err.code === 'INPUT_INVALID') {
                        const order_invalid = lodash.get(err, 'data.order_invalid', [])
                        const orderNotPay = lodash.get(err, 'data.order_not_pay', [])
                        const orderNegative = lodash.get(err, 'data.order_negative', [])
                        const paymentRequestCreated = lodash.get(err, 'data.payment_request_created', [])
                        const invoiceCodeInvalid = lodash.get(err, 'data.order_invoice_code_invalid', {})
                        const dataError = lodash.get(err, 'data', {})
                        if (lodash.get(invoiceCodeInvalid, 'errors') === 'not_connect') {
                            return notification.error({ message: t('Không kết nối được tài khoản TBG vui lòng kết nối lại tài khoản ') })
                        }

                        if (dataError.hasOwnProperty('real_payment_amount_invalid')) {
                            return notification.error({ message: t('paymentRequest:unique_error.real_payment_amount') })
                        }

                        const errors = []
                        if (
                            order_invalid.length ||
                            orderNegative.length ||
                            paymentRequestCreated.length ||
                            orderNotPay.length ||
                            !lodash.isEmpty(invoiceCodeInvalid)
                        ) {
                            if (order_invalid.length) {
                                errors.push(
                                    <p>
                                        - Đơn <b>{lodash.map(order_invalid, 'code').join(', ')}</b> chưa đủ điều kiện tạo YCTT đơn TBG
                                    </p>
                                )
                            }
                            if (paymentRequestCreated.length) {
                                errors.push(
                                    <p>
                                        - Đơn <b>{lodash.map(paymentRequestCreated, 'code').join(', ')}</b> đã tạo YCTT đơn TBG
                                    </p>
                                )
                            }
                            if (orderNegative.length) {
                                errors.push(
                                    <p>
                                        - Đơn <b>{lodash.map(orderNegative, 'code').join(', ')}</b> là đơn hàng âm
                                    </p>
                                )
                            }
                            if (orderNotPay.length) {
                                errors.push(
                                    <p>
                                        - Đơn <b>{lodash.map(orderNotPay, 'code').join(', ')}</b> chưa đủ điều kiện tạo YCTT đơn TBG
                                    </p>
                                )
                            }
                            if (!lodash.isEmpty(invoiceCodeInvalid)) {
                                errors.push(
                                    <p>
                                        - MHĐG <b>{lodash.get(invoiceCodeInvalid, 'invoice_code')}</b> đang không có trên hệ thống site gốc
                                    </p>
                                )
                            }
                        } else {
                            notification.error({ message: t('Có lỗi xảy ra trong quá trình tạo YCTT') })
                        }
                        setErrors(errors)
                    } else if (err.code === PAYMENT_ORDER_ERROR.ORDER_POLICIES) {
                        setViolated(lodash.get(err, 'data', {}))
                    } else {
                        notification.error({ message: t('Có lỗi xảy ra trong quá trình tạo YCTT') })
                    }
                })
                .finally(() => setLoading(false))
        } else {
            notification.error({ message: 'Vui lòng chọn đơn cần tạo YCTT' })
        }
    }

    function cancelModal() {
        setVisible(false)
        setNote(null)
        setErrors([])
        setReasons({})
        setViolated({})
        setReasonsDataLength(0)
        setViolatedDataLength(0)
    }

    function onChangeReason(order, policyType, reasonId) {
        const reasonsByOrder = lodash.get(reasons, order, [])
        setReasons({ ...reasons, [order]: { ...reasonsByOrder, [policyType]: reasonId } })
    }

    return (
        <div>
            <Button
                className={`${className} a-font--12 aphz-24 ml-3 a-btn--primary-ant btn-active`}
                style={{ paddingTop: '1px' }}
                onClick={() => setVisible(true)}
                size="small"
                icon="plus-square"
                loading={loading}
                type="submit">
                <i className="fab fa-plus-circle" />
                &nbsp;{t('paymentRequest:btn.create_payment_request_by_sb_order')}
            </Button>
            {visible && (
                <Modal
                    width={700}
                    title="Nhập vào ghi chú YCTT đơn TBG"
                    onOk={createPaymentRequest}
                    okText={t('paymentRequest:btn.create_payment_request_by_sb_order')}
                    cancelText={t('btn.cancel')}
                    visible={visible}
                    onCancel={cancelModal}
                    okButtonProps={{
                        loading: loading,
                        disabled: (!lodash.isEmpty(violated) && lodash.isEmpty(reasons)) || (reasonsDataLength > 0 && reasonsDataLength !== violatedDataLength),
                    }}>
                    <Input.TextArea
                        value={note}
                        onChange={event => setNote(event.target.value)}
                        placeholder="Nhập vào nội dung ghi chú"
                        rows={'3'}
                        className="mt-1 mb-2"
                        autosize={{ minRows: 3, maxRows: 3 }}
                        disabled={loading}
                    />
                    {errors.length > 0 &&
                        errors.map((item, index) => {
                            return (
                                <p
                                    key={index}
                                    style={{ color: 'red' }}>
                                    {item}
                                </p>
                            )
                        })}

                    {!lodash.isEmpty(violated) &&
                        Object.keys(violated).map(order => {
                            const violatePoliciesOfOrder = Object.values(violated[order])
                            return violatePoliciesOfOrder.map((violatedPolicy, index) => {
                                const reasonId = lodash.get(reasons, [order, violatedPolicy.policy_type])
                                return (
                                    <div
                                        className="mt-4 mb-4"
                                        key={`${order}_${index}`}>
                                        <div className="mb-1 a-text--fz-14">
                                            {trans('violate:select_reason.violated_order', {
                                                policy: <b>{violatedPolicy.policy_name}</b>,
                                                order: <b>{order}</b>,
                                            })}
                                        </div>
                                        <Select
                                            style={{ width: '100%' }}
                                            value={reasonId ? parseInt(reasonId) : undefined}
                                            noOptionsMessage={() => t('system.no_option')}
                                            className="single-react"
                                            classNamePrefix="react-select--violate_modal"
                                            placeholder={t('violate:select_reason.select_reason')}
                                            allowClear={false}
                                            onChange={value => onChangeReason(order, violatedPolicy.policy_type, value)}>
                                            {lodash.keys(VIOLATE_REASON_TYPE).map(type => (
                                                <Select.OptGroup label={t('violate:list.' + type)}>
                                                    {violateReasons
                                                        .filter(reason => reason.type === type)
                                                        .map(reason => (
                                                            <Select.Option
                                                                value={reason.id}
                                                                key={reason.id}>
                                                                {reason.title}
                                                            </Select.Option>
                                                        ))}
                                                </Select.OptGroup>
                                            ))}
                                        </Select>
                                    </div>
                                )
                            })
                        })}
                </Modal>
            )}
        </div>
    )
}

export default translate()(CreatePaymentRequestBySBOrder)
