import {createAction} from 'redux-actions';
import * as Constants from './constants';
import {fetchLogs} from '../common/Log/actions';

export const fetchComplaintLogs = (id) => fetchLogs('complaint_seller', id);
export const fetchComplaintSellerReasons = createAction(Constants.FETCH_COMPLAINT_SELLER_REASONS.REQUEST, (filters) => (filters));
export const fetchComplaintSeller = createAction(Constants.FETCH_COMPLAINT_SELLER.REQUEST, (filters) => ({...filters}));
export const fetchComplaintDetail = createAction(Constants.FETCH_COMPLAINT_DETAIL.REQUEST, (param) => (param));
export const fetchComplaintSellerRenewReasons = createAction(Constants.FETCH_COMPLAINT_SELLER_RENEW_REASONS.REQUEST, filter => filter);
export const pushComplaintSellerToList = createAction(Constants.CREATE_COMPLAINT_SELLER.SUCCESS, (data) => ({...data}));
export const expireUpdateComplaintDetail = createAction(Constants.EXPIRE_COMPLAINT_SELLER.REQUEST, (param) => (param));
export const expireClearErrorComplaintDetail = createAction(Constants.CLEAR_ERROR_EXPIRE_COMPLAINT_SELLER, (param) => (param));
export const clearComplaintDetail = createAction(Constants.CLEAR_COMPLAINT_DETAIL);
export const openModalForm = createAction(Constants.OPEN_MODAL_COMPLAINT_SELLER, () => ({}));
export const closeModalForm = createAction(Constants.CLOSE_MODAL_COMPLAINT_SELLER, () => ({}));
export const clearError = createAction(Constants.CLEAR_ERROR_COMPLAINT_SELLER, () => ({}));
export const createComplaintSeller = createAction(Constants.CREATE_COMPLAINT_SELLER.REQUEST, (datas) => ({datas}));
export const beginProcesscreateComplaintSeller =
    createAction(Constants.IS_BEGIN_PROCESS_CREATE_COMPLAINT_SELLER, () => ({}));
export const clearErrorCreateComplaintSeller =
    createAction(Constants.CLEAR_ERROR_COMPLAINT_SELLER, (data) => (data));
export const openModalChangeSuccessForm = createAction(Constants.OPEN_MODAL_CHANGE_COMPLAINT_SUCCESS, () => ({}));
export const rejectedComplaintSellers = createAction(Constants.COMPLAINT_SELLERS_REJECTED, (data) => ({data}));
export const rejectedComplaintSeller = createAction(Constants.COMPLAINT_SELLER_REJECTED, (data) => ({data}));
export const updateComplaintSellerReason = createAction(Constants.COMPLAINT_SELLER_REASON_UPDATE.SUCCESS, (data) => ({data}));
export const updateOriginCodeComplaint = createAction(Constants.UPDATE_ORIGIN_CODE_COMPLAINT_DETAIL, (data) => ({...data}));
export const addCommentProduct = createAction(Constants.ADD_COMMENT_PRODUCT_SUCCESS, (data) => ({...data}));
export const addTransactionCode = createAction(Constants.ADD_TRANSACTION_CODE_CUCCESS, (data) => ({...data}));
export const updateComplaintDetailAgain = createAction(Constants.UPDATE_COMPLAINT_DETAIL_AGAIN, (data) => ({...data}));
