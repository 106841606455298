import appConfig from '../../config/app';

// cho khai báo cho các golobal contant như mã thành công/thất bại, các message ...
export const GLOBAL_CONTANT = {
    SUCCESS_CODE: 200,
    PERMISSION_DENIED: 403,
    ALERT_TYPE_SUCCESS: "alert-success",
    ALERT_TYPE_INFO: "alert-info",
    ALERT_TYPE_WARNING: "alert-warning",
    ALERT_TYPE_DANGER: "alert-danger",
    ERROR_CODE: {error201: "201", error205: "205", error212: "212", error215: "215", error216: "216"},

};

// khai báo các API URL cho toàn hệ thống
let apiVersion = appConfig.apiVersion;
let prefixAPI = "/admin/v" + apiVersion;

export const API_URL = {
    DEFAULT: prefixAPI,
    LOGIN: prefixAPI + "/login",
    LOGOUT: prefixAPI + "/logout",
    GET_USER: prefixAPI + "/auth/user",
    GET_LIST_ACCOUNT_PURCHASER: prefixAPI + "/account_purchasers",
    GET_LIST_TELLERS: prefixAPI + "/users/tellers",
    CREATE_USER_ACCOUNT_PURCHASER: prefixAPI + "/user-account-purchasers",
    CREATE_USER_ACCOUNT_PURCHASER_MULTI: prefixAPI + "/user-account-purchasers/multi",
    PURCHASE_ADDRESS_API: prefixAPI + '/purchase-address',
    GET_LIST_ORDER: prefixAPI + '/orders/',
    GET_LIST_TRANSACTION_ORDER: prefixAPI + '/orders/transaction',
    FETCH_PURCHASES: prefixAPI + '/orders/teller',
    GET_LIST_SERVICE: prefixAPI + '/services',
    GET_LIST_SERVICES_API: prefixAPI + '/service-groups',
    GET_LIST_COMPLAINT_SELLER_REASONS: prefixAPI + '/complaint-seller-reasons/',
    GET_LIST_COMPLAINT_SELLER: prefixAPI + '/complaint-sellers/',
    PRODUCT_RETURN_DETAIL_API: prefixAPI + '/complaint_product_return',
    FETCH_COMPLAINT_DETAIL: prefixAPI + '/complaint-sellers/',
    UPDATE_COMPLAINT_DETAIL_SUCCESS: prefixAPI + '/complaint-sellers/{id}/change-state',
    POST_ITEM_LOG_API: prefixAPI + '/logs',
    DISTRIBUTOR_ORDER: prefixAPI + '/order-distributors',
    UPDATE_QUANTITY: prefixAPI + '/order-item/',
    UPDATE_ORDER_INVOICE_CODE: prefixAPI + '/orders/',
    POST_INVOICE_CODE: prefixAPI + '/orders/{id}/invoice-code',
    DELETE_INVOICE_CODE: prefixAPI + '/orders/{id}/invoice-code',
    UPDATE_ORDER_INFO: prefixAPI + '/orders/',
    CANCEL_ORDER: prefixAPI + '/orders/',
    OUT_OF_STOCK_ORDER: prefixAPI + '/orders/',
    UPDATE_ORDER_GROUP_ORDER: prefixAPI + '/orders/',
    ORDER_SHIPPING_FREE: 'shipping-fee',
    UPDATE_ORDER_PRICE: prefixAPI + '/order-item/{id}/update-price',
    ORDER: prefixAPI + '/orders',
    ADD_NOTE_ITEM: prefixAPI + '/order-item/{id}/add-note',
    FETCH_VIOLATE_REASONS: prefixAPI + '/violate-reasons',
    FETCH_SLOW_PURCHASING_REASONS: prefixAPI + '/violate-reasons',
    FETCH_VIOLATE_POLICY_ORDERS: prefixAPI + '/orders/policies',
    CHANGE_REASONS: prefixAPI + '/order-policies/',
    ORDER_FREIGHT_BILL: prefixAPI + '/orders/{id}/freight-bill',
    SETTINGS_PARTNER: prefixAPI + '/settings/partner',
    SETTINGS_USER: prefixAPI + '/settings/user',
    LIST_WAREHOUSE: prefixAPI + '/order-distributors/warehouses',
    LIST_TRANSACTION_FILE: prefixAPI + '/transaction-files',
    UPLOAD_TRANSACTION_FILE: prefixAPI + '/transaction-files',
    UPLOAD_TRANSACTION_FILE_WP: prefixAPI + '/transaction-files/world-first',
    UPLOAD_TRANSACTION_FILE_GLOBAL: prefixAPI + '/transaction-files/global',
    DOWNLOAD_TRANSACTION_FILE: prefixAPI + '/transaction-files/',
    UPLOAD_INVOICE_CODE_FILE: prefixAPI + '/transaction-invoice-codes/import',
    PRODUCT_RETURN: prefixAPI + '/product-returns',
    REAL_PAYMENT_AMOUNT_ORDER: prefixAPI + '/orders/{id}/real-payment-amount',
    REAL_REFUND_AMOUNT_ORDER: prefixAPI + '/orders/{id}/real-refund-amount',
    WRONG_PROCESS_ORDER: prefixAPI + '/orders/{id}/wrong-process',
    LIST_AGENCY: prefixAPI + '/agencies',
    LIST_TRANSACTION: prefixAPI + '/transactions',
    ADD_INVOICE_CODE_2_TRANSACTION: prefixAPI + '/transaction-invoice-codes/{id_transaction}',
    DELETE_INVOICE_CODE_2_TRANSACTION: prefixAPI + '/transaction-invoice-codes/{id_transaction_invoice_code}',
    EXPORT_CSV: prefixAPI + '/transactions/export',
    SELLER: prefixAPI + '/sellers',
    BANK_ACCOUNT: prefixAPI + "/banks",
    UPLOAD_TRANSACTION_FILE_MBILL: prefixAPI + '/transaction-files/mbill',
    LIST_SELLER_SOURCE: prefixAPI  + "/seller-sources/partner",
    LIST_INVOICE_CODE_CHANGE: prefixAPI  + "/invoice-code-approvals",
    LOGS_INVOICE_CODE_CHANGE: prefixAPI  + "/logs/order_source_invoice_approval/",
    HSCODE: prefixAPI  + "/hs-codes",
    ORDER_ITEM: prefixAPI + "/order-item",
    GET_LIST_FEATURES: prefixAPI + "/property-groups",
    UPLOAD_GD_AUTO_CREATE_FILE: prefixAPI + '/transaction-files/manual',
};

/**
 * API URL cho customer
 * @type {number}
 */
let apiCustomerVersion = (typeof process.env.REACT_APP_API_CUSTOMER_VERSION === "undefined") ? 1 : parseInt(process.env.REACT_APP_API_CUSTOMER_VERSION, 10);
let prefixAPICustomer = `api/customer/v${apiCustomerVersion}`;


export const API_URL_CUSTOMER = {
    DEFAULT: prefixAPICustomer,
    FETCH_BAG_DETAIL: `${prefixAPICustomer}/bags`,
    GET_BAGS: `${prefixAPICustomer}/bags`,
    GET_ORDERS: `${prefixAPICustomer}/orders`,
};

export const IS_SYNC_SOLR_SUCCESS = "1";
export const CHANNEL_ASSIGN_TELLER = "ORDER_ASSIGN_TELLER_";
export const CHANNEL_UNASSIGN_TELLER = "ORDER_UN_ASSIGN_TELLER_";

export const CHANNELS = {
    ORDER_READ_COMMENT: "ORDER_READ_COMMENT",
    ORDER_CREATE_NEW: "ORDER_CREATE_NEW",
    PAYMENT_REQUEST_CREATE: "PAYMENT_REQUEST_CREATE",
    ORDER_PAY_ERROR_BY_PAYMENT_REQUEST: "ORDER_PAY_ERROR_BY_PAYMENT_REQUEST",
    ORDER_LOG: "ORDER_LOG",
    ORDER_NOTE_ITEM: "ORDER_NOTE_ITEM",
    ORDER_SYNC_BILL_NO: "ORDER_SYNC_BILL_NO",
    IMPORT_TRANSACTION_INVOICE_CODE: "IMPORT_TRANSACTION_INVOICE_CODE",
    IMPORT_TRANSACTION_RESOLVE: "IMPORT_TRANSACTION_RESOLVE",
    ORDER_CRAWL: "ORDER_CRAWL",
    TAOBAO_BATCH_PAY_PURCHASE_ORDER_ERROR: 'TAOBAO_BATCH_PAY_PURCHASE_ORDER_ERROR',
    TAOBAO_BATCH_PAY_PURCHASE_ORDER_SUCCESS: 'TAOBAO_BATCH_PAY_PURCHASE_ORDER_SUCCESS',
    TRANSACTION_IMPORT_MANUAL: "TRANSACTION_IMPORT_MANUAL"
};

export const LIST_ORDER_MENU = "ListOrder";
export const SAVE_ORDER_DISTRIBUTOR = "ORDER_DISTRIBUTOR:SAVE_BROWSER";
export const KEY_ORDER_DISTRIBUTOR = "setting_save_order_distributor";

export const AUTH = {
    UNAUTHENTICATED: 401,
    UNAUTHORIZED: 403,
};

export const SETTING_CODE = {
    SYSTEM_USING: "SYSTEM:USING",
    DISTRIBUTIVE_WAREHOUSE_USING: "DISTRIBUTIVE_WAREHOUSE:USING",
    DISTRIBUTIVE_WAREHOUSE_REQUIRED_ORDER: "DISTRIBUTIVE_WAREHOUSE:REQUIRED_ORDER",
    ORDER_DISTRIBUTOR_USING: "ORDER_DISTRIBUTOR:USING",
};
