import React from "react";
import PropTypes from "prop-types";
import {Col, Icon, Input, Row, Switch} from "antd";
import lodash from "lodash";
import {translate} from "react-i18next";
import InputNumber from '../../../common/components/Input/Super';

class FinalyOrder extends React.Component {

    constructor(props) {
        super(props);
        let {finalyOrder} = this.props;

        this.state = {
            finance: lodash.split(finalyOrder.value, ",")
        };
    }

    onChangeInput(name, value) {
        this.props.onChange({code: name, value: value});
    }

    onChangeSwitch(transactionCode, value) {
        let {finance} = this.state;
        let {finalyOrder} = this.props;

        if (value)
            finance.push(transactionCode);
        else {
            let index = finance.indexOf(transactionCode);
            finance.splice(index, 1);
        }

        this.setState({finance}, () => {
            let {finance} = this.state;
            this.props.onChange({code: finalyOrder.code, value: finance.join()});
        });
    }

    render() {
        let {t, configSetting} = this.props;
        let {finance} = this.state;
        let invoiceCode = lodash.get(configSetting, "INVOICE_CODE", []);
        let transactionTypes = lodash.get(configSetting, "TRANSACTION_TYPES", []);

        return (
            <Col xs={{span: 12}} md={{span: 12}}>
                <div className="a-content--box-shadow p-0 mb-5">
                    <div className="a-setting__title p-3">
                        <p>{t('setting:title.TRANSACTION_TYPE_FINALI_ORDER')}</p>
                    </div>

                    <div className="a-setting__body p-3">
                        <Row className="d-flex setting-line">
                            <Col span={11} className="d-flex align-item-center a-text--bold">Loại giao dịch</Col>
                            <Col span={6} className="a-text--bold">Số MHĐG tối đa cho GD</Col>
                            <Col span={6} offset={2} className="a-text--bold d-flex align-item-center flex-end">Có tính trong tài chính mua</Col>
                        </Row>
                        {
                            invoiceCode.map((item, index) => {
                                let transactionCode = item.code.replace("INVOICE_CODE:", "");
                                return (
                                    <Row className="d-flex setting-line" key={index}>
                                        <Col span={11} className="d-flex align-item-center">{t(`setting:description.${item.code}`)}</Col>
                                        <Col span={4}>
                                            <InputNumber
                                                integer
                                                name={'settings'}
                                                className="ant-input"
                                                value={item.value}
                                                style={{width: "100%"}}
                                                // placeholder={t('setting:placeholder.' + item.code)}
                                                onChange={this.onChangeInput.bind(this, item.code)}
                                            />
                                        </Col>
                                        <Col span={9} className="d-flex align-item-center flex-end">
                                            <Switch
                                                checkedChildren={<Icon type="check" />}
                                                unCheckedChildren={<Icon type="close" />}
                                                checked={finance.includes(transactionCode)}
                                                onChange={value => this.onChangeSwitch(transactionCode, value)}
                                                size="small"
                                            />
                                        </Col>
                                    </Row>
                                );
                            })
                        }
                    </div>
                </div>
            </Col>
        );
    }
}

FinalyOrder.defaultProps = {
    updateConfigSystem: (params) => {},
    configSetting: {}
};

FinalyOrder.propTypes = {
    updateConfigSystem: PropTypes.func,
    configSetting: PropTypes.object
};

export default translate()(FinalyOrder);