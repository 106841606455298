
export const FETCH_LOGS = {
    REQUEST: 'COMMON.LOG.FETCH_LOGS.REQUEST',
    SUCCESS: 'COMMON.LOG.FETCH_LOGS.SUCCESS',
    FAILED: 'COMMON.LOG.FETCH_LOGS.FAILED',
};

export const FETCH_COMMENTS = {
    REQUEST: 'COMMON.LOG.FETCH_COMMENTS.REQUEST',
    SUCCESS: 'COMMON.LOG.FETCH_COMMENTS.SUCCESS',
    FAILED: 'COMMON.LOG.FETCH_COMMENTS.FAILED',
};

export const COMMENT = {
    REQUEST: 'COMMON.LOG.COMMENT.REQUEST',
    SUCCESS: 'COMMON.LOG.COMMENT.SUCCESS',
    FAILED: 'COMMON.LOG.COMMENT.FAILED',
};

export const UPDATE_FILTER = 'COMMON.LOG.UPDATE_FILTER';

export const COLLAPSE_ALL_LOGS = 'COMMON.LOG.COLLAPSE_ALL_LOGS';

export const COLLAPSE_LOG = 'COMMON.LOG.COLLAPSE_LOG';

export const CLEAR_STATE = 'COMMON.LOG.CLEAR_STATE';

export const LOG_TYPE = {
    COMMENT: 'COMMENT',
    COMMENT_IMAGE: 'COMMENT_IMAGE',
    ACTION: 'ACTION',
    LOG: 'LOG',
    COMPLAINT_COMMENT: "COMPLAINT_COMMENT",
    PRODUCT_RETURN_COMMENT: "PRODUCT_RETURN_COMMENT",
    PRODUCT_RETURN_COMMENT_IMAGE: "PRODUCT_RETURN_COMMENT_IMAGE",
    COMPLAINT_COMMENT_IMAGE: "COMPLAINT_COMMENT_IMAGE",
};

export const LOG_STATUS = {
    PUBLIC: 'PUBLIC',
    PRIVATE: 'PRIVATE',
};

