import React from 'react'
import PropTypes from 'prop-types'
import onClickOutside from "react-onclickoutside";

class InlineEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false
        }
    }

    componentWillReceiveProps(nextProps) {
        const {isEdit} = nextProps;
        if (isEdit !== this.state.isEdit) {
            this.setState({isEdit})
        }
    }

    handleClickOutside = evt => {
        this.setState({
            isEdit: false,
        });
    };

    onCancel(event) {
        this.setState({isEdit: false})
    }

    onEdit(event) {
        this.setState({isEdit: true})
    }

    render() {
        const {
            text, children, disableAction, editDisabled, classTitle,
            wrapperClass
        } = this.props;
        const {isEdit} = this.state;

        return (
            <div className={`a-content-editable a-content-editable--account ${wrapperClass}`}
                 title={!isEdit ? "Click vào để sửa" : ""}>
                {!isEdit &&
                <label>
                    <span className={'a-content-editable--title ' + classTitle}
                          onClick={e => this.onEdit(e)}> {text || '---'} </span>
                    <span className={'a-content-editable--code'}></span>
                    {!editDisabled &&
                    <i className={'fas fa-pencil-alt'} onClick={e => this.onEdit(e)}></i>}
                </label>
                }

                {isEdit &&
                <div className={'a-content-editable--group '}>
                    {children}

                    {!disableAction &&
                    <div className={'a-content-editable--action'}>
                        <button onClick={e => this.onUpdate(e)}><i className={'ion-checkmark'}></i></button>
                        <button onClick={e => this.onCancel(e)}><i className={'fas fa-times'}></i></button>
                    </div>}
                </div>}
            </div>
        )
    }
}

InlineEdit.propTypes = {
    text: PropTypes.string,
    disableAction: PropTypes.bool,
    isPencilLeft: PropTypes.bool,
    isEdit: PropTypes.bool,
    editDisabled: PropTypes.bool,
    classTitle: PropTypes.string
};

export default onClickOutside(InlineEdit);
