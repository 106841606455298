import React from 'react';
import lodash from 'lodash';
import FormProcessing from '../../../common/components/FormProcessing';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import Button from "@atlaskit/button/dist/esm/components/Button";
import ModalFormAssign from "./ModalForm/ModalFormAssign";
import {ORDER_STATUS} from './../../constants';
import {hasPermission, permissions} from "../../../Permission";

class FormPerformAction extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            isOpenForm: false,
            input: {
                code: '',
            },
            inputVersion: 0,
            changedParams: [],
        }
    }

    /**
     * Handle việc mở modal form
     */
    openModalForm = () => {
        this.setState({isOpenForm: true});
        this.props.onAssignPurchaserClear();
    };

    /**
     * handle việc đóng popup form lại
     */
    closeModalForm = () => {
        this.setState({isOpenForm: false});
        this.props.onCloseFormAssignPurchaser();
    };

    /**
     * handle việc đóng popup form lại
     */
    closeModalFormAfterSuccess = () => {
        this.setState({isOpenForm: false});
        this.props.onClearCheckAll();
        this.props.onCloseFormAssignPurchaser()
    };

    onBlurInput() {
        this.props.onSubmit(this.state.input);
    }

    onChangeFormInput(param, value) {
        if( value === '' ){
            let input = this.changeInput({[param]: value});
            this.props.onSubmit(input);
        }
    }

    onChangeFormZero(event){
        event.preventDefault();
        this.props.onClear(this.props.stateDefault);
    }

    onKeyPressInput(input, event) {
        if (event.key === 'Enter') {
            this.props.onSubmit(input);
        }
    }

    render() {
        let {
            t, filter, isLoading, orderIdsRefuses, distributing, clearError = () => {
            }, purchasers, orderIds, distributeOrderStatus, orders, purchaserOption
        } = this.props;
        let {isOpenForm, input} = this.state;
        let statusCurrent = lodash.get(filter, 'status', null);
        return (
            <div className='a-filter a-filter__purchase' key={this.props.inputVersion}>
                {isOpenForm && (
                    <ModalFormAssign
                        title={t('order:distribute_order.tellers_assign')}
                        onClose={this.closeModalForm.bind(this)}
                        distributeOrderStatus={distributeOrderStatus}
                        onCloseAfterSuccess={this.closeModalFormAfterSuccess.bind(this)}
                        onSubmitFormAssign={this.props.onAssignPurchasers}
                        isLoading={isLoading}
                        error={{id_teller: {required: []}}}
                        purchasers={purchasers}
                        purchaserOption={purchaserOption}
                        orderIds={orderIds}
                        orders={orders}
                        orderIdsRefuses={orderIdsRefuses}
                        distributing={distributing}
                        clearError={clearError}
                    />
                )}
                <div className={'a-btn--filter__group_purchase d-flex align-items-center '}>
                    <i className="ion-ios-search-strong icon-search"/>
                    <input
                        type="text"
                        className={"input input--sort_purchase "}
                        placeholder={t('order:list.order_code_placeholder')}
                        value={input.code}
                        onChange={this.onChangeInput.bind(this, 'code')}
                        onKeyPress={event => this.onKeyPressInput(input, event)}
                    />
                    {(input && input.code) &&
                    <i className="fas fa-times-circle icon_remove"
                       onClick={event => this.onChangeFormInput('code', '')}
                    />
                    }
                </div>

                <div className={'group_btn_purchase'}>
                    <a className="btn-reset" onClick={event => this.onChangeFormZero(event)}>
                        <i className="fas fa-sync icon-reset"/>&nbsp;{t("btn.reset")}
                    </a>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    {hasPermission(permissions.ORDER_DISTRIBUTE) &&
                    <Button
                        className={
                            "btn-active " +
                            (lodash.includes([ORDER_STATUS.WAITING_BUY,
                                ORDER_STATUS.WAITING_PAI,
                                ORDER_STATUS.PAID,
                                ORDER_STATUS.WAITING_PAY], statusCurrent) ? '' : ' btn-disabled')
                        }
                        onClick={this.openModalForm.bind(this)}
                        appearance="primary"
                        type="submit">
                        <i className="fab fa-mixcloud" />&nbsp;{t("order:distribute_order.tellers_assign")}
                    </Button>}
                </div>
            </div>
        );
    }
}

FormPerformAction.defaultProps = {
    purchasers: [],
    orderIds: [],
    isLoading: false,
    onTellerAssign: () => {
    },
    error: {}
}

FormPerformAction.propTypes = {
    purchasers: PropTypes.array,
    orderIds: PropTypes.array,
    isLoading: PropTypes.bool,
    onTellerAssign: PropTypes.func,
    error: PropTypes.object
}


export default translate('translations')(FormPerformAction);
