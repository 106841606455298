import {takeLatest, put} from 'redux-saga/effects';
import {t} from "../../systems/i18n";
import bootbox from "../../modules/common/components/Bootbox/bootbox";
import * as GlobalConstants from './../../systems/globalContant'
import * as ActionConstants from '../../systems/globalContant/actionContant';

/**
 * Push notification message with bootbox
 *
 * @param action
 * @returns {IterableIterator<*|PutEffect<{type: *, response: *}>>}
 */
export function* message(action) {
    const {response, actionType, subject}  = action.payload;
    const {data} = response;
    if (data && data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
        yield put({type: actionType.SUCCESS, response});
        bootbox.success(t(subject + '.' + data.code, data.data));
    } else {
        yield put({type: actionType.FAILED, response});
        bootbox.error(t(subject + '.' + data.code, data.data));
    }
}

export default function*() {
    yield takeLatest(ActionConstants.PUSH_NOTIFICATION_MESSAGE, message)
}