import { trans } from '../../systems/i18n'

export const FETCH_ORDERS = {
    REQUEST: 'FETCH_ORDERS_REQUEST',
    SUCCESS: 'FETCH_ORDERS_SUCCESS',
    FAILED: 'FETCH_ORDERS_FAILED',
}

export const FETCH_TRANSACTION_ORDERS = {
    REQUEST: 'FETCH_TRANSACTION_ORDERS_REQUEST',
    SUCCESS: 'FETCH_TRANSACTION_ORDERS_SUCCESS',
    FAILED: 'FETCH_TRANSACTION_ORDERS_FAILED',
}

export const FETCH_TRANSACTION_ORDERS_CLEAR_STATE = 'FETCH_TRANSACTION_ORDERS_CLEAR_STATE'
export const UPDATE_TRANSACTION_ORDERS = 'UPDATE_TRANSACTION_ORDERS'

export const FETCH_ORDER_STATS = {
    REQUEST: 'FETCH_ORDER_STATS.REQUEST',
    SUCCESS: 'FETCH_ORDER_STATS.SUCCESS',
    FAILED: 'FETCH_ORDER_STATS.FAILED',
}

export const FETCH_ORDER_COUNT = {
    REQUEST: 'FETCH_ORDER_COUNT.REQUEST',
    SUCCESS: 'FETCH_ORDER_COUNT.SUCCESS',
    FAILED: 'FETCH_ORDER_COUNT.FAILED',
}

export const FETCH_SERVICES = {
    REQUEST: 'FETCH_SERVICES_REQUEST',
    SUCCESS: 'FETCH_SERVICES_SUCCESS',
    FAILED: 'FETCH_SERVICES_FAILED',
}

export const DEFAULT_EMPTY_DATA = '---'
export const DEFAULT_EMPTY_NUMBER_DATA = 0
export const DEFAULT_EMPTY_EXCHANGE = 0

export const ORDER_STATUS_WITH_CLASS = {
    WAITING_BUY: {
        value: 'WAITING_BUY',
        className: 'wating-buy',
    },
    WAITING_PAI: {
        value: 'WAITING_PAI',
        className: 'wating-pai',
    },
    PAI: {
        value: 'PAI',
        className: 'paied',
    },
    PAID: {
        value: 'PAID',
        className: 'wating-buy',
    },
    WAITING_PAY: {
        value: 'WAITING_PAY',
        className: 'wating-pay',
    },
    BOUGHT: {
        value: 'BOUGHT',
        className: 'bought',
    },
    CANCELLED: {
        value: 'CANCELLED',
        className: 'cancelled',
    },
    OUT_OF_STOCK: {
        value: 'OUT_OF_STOCK',
        className: 'out-of-stock',
    },
    SELLER_DELIVERY: {
        value: 'SELLER_DELIVERY',
        className: 'seller-delivery',
    },
    RECEIVED_FROM_SELLER: {
        value: 'RECEIVED_FROM_SELLER',
        className: 'recived-from-seller',
    },
    CROSS_CHECKING: {
        value: 'CROSS_CHECKING',
        className: 'cross-checking',
    },
    REVOKED: {
        value: 'REVOKED',
        className: 'revoked',
    },
}

export const REAL_AMOUNT_OPERATION = {
    EQUAL: 'EQUAL',
    LESS: 'LESS',
    GREAT: 'GREAT',
}

export const PLACEHOLDER = {
    ORDER: 'ORDER',
    ACCOUNT: 'ACCOUNT',
    VALUE: 'VALUE',
    TIME: 'TIME',
    FILTER: 'FILTER',
}

export const ORDER_STATUS = {
    WAITING_BUY: 'WAITING_BUY', // Chờ mua
    WAITING_PAI: 'WAITING_PAI', // Chờ PAI
    PAI: 'PAI', // Đang PAI
    PAID: 'PAID', // Đã PAI
    WAITING_PAY: 'WAITING_PAY', // Chờ thanh toán
    BOUGHT: 'BOUGHT', // Đã mua
    CANCELLED: 'CANCELLED', // Hủy bỏ
    OUT_OF_STOCK: 'OUT_OF_STOCK', // Hết hàng
    SELLER_DELIVERY: 'SELLER_DELIVERY', // Người bán giao
    RECEIVED_FROM_SELLER: 'RECEIVED_FROM_SELLER', // nhận
    CROSS_CHECKING: 'CROSS_CHECKING', // Đối soát
    RECEIVED: 'RECEIVED', // Đã nhận
    TRANSPORTING: "TRANSPORTING",
    WAITING_DELIVERY: "WAITING_DELIVERY",
    DELIVERING: "DELIVERING",
    DELIVERED: "DELIVERED"
}

export const SCAN_STATUSES = [
    { label: trans('order:scan_status.pending'), value: 'PENDING' }, //chưa có mã HDG nào được quét
    { label: trans('order:scan_status.scanning'), value: 'SCANNING' }, // Đã có mã HDG được quét
    { label: trans('order:scan_status.success'), value: 'SUCCESS' }, //Tất cả các mã HDG được quét thành công
]

export const ORDER_SOURCE = {
    taobao: 'taobao',
    tmall: 'tmall',
    1688: '1688',
}

export const SCANNING_STATUS = {
    PENDING: 'PENDING',
    SCANNING: 'SCANNING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
}

/**
 * Validate filter order
 *
 * @param data
 * @returns {Array}
 */
export const validate = data => {
    let messages = []
    if (data.log_status_from && data.log_status_to) {
        if (data.log_status_from > data.log_status_to) {
            messages.push({ time: 500 })
        }
    }

    if (data.grand_total_from && data.grand_total_to) {
        if (parseFloat(data.grand_total_from) > parseFloat(data.grand_total_to)) {
            messages.push({ grand_total: 500 })
        }
    }
    return messages
}

export const TRANSACTION_ORDER_STATUS = [
    'BOUGHT',
    'SELLER_DELIVERY',
    'RECEIVED',
    'TRANSPORTING',
    'WAITING_DELIVERY',
    'DELIVERING',
    'DELIVERED',
    'CROSS_CHECKING',
    'CANCELLED',
    'REVOKED',
    'OUT_OF_STOCK',
    'MIA',
]

export const TRANSACTION_PROBLEM_TYPES = [
    'NO_TRANSACTION',
    'TOO_MUCH',
    'NO_REFUND',
    'NEGATIVE_PROFIT',
    'PROBLEM_PRICE',
    'PROBLEM_OPTION',
    'PROBLEM_ADDRESS',
    'PROBLEM_QTY',
    'PROBLEM_SHIPPING_FEE',
    'PROBLEM_PRODUCT',
    'PROBLEM_CANCELLED',
]

export const LIST_SCANNING_STATUS = ['PENDING', 'SCANNING', 'SUCCESS']
