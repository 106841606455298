import React from "react";
import {Icon, Modal} from "antd";
import apiService from "../../apiService";
import {processResponse} from "../../../../../systems/api";
import notification from '../../../../../systems/notification';
import {translate} from "react-i18next";
import lodash from "lodash";

const { confirm } = Modal;

class LikeButton extends React.Component {

    onClick = e => {
        let {seller, t, updateOrderDetail} = this.props;

        confirm({
            title: 'Bạn chắc chắn muốn bỏ đánh dấu shop?',
            content: 'Click vào nút Xác nhận để bỏ đánh dấu shop',
            onOk: () => {
                let res = apiService.likeSeller(seller.id);
                processResponse(res)
                    .then(res => {
                        updateOrderDetail(res);
                        notification.success('Bỏ đánh dấu NCC xấu thành công');
                    })
                    .catch(err => {
                        notification.error("Bỏ đánh dấu NCC xấu không thành công");
                    });
            },
            onCancel: () => {},
            centered: true,
            okText: "Xác nhận",
            cancelText: "Hủy"
        });
    }

    render() {
        let {seller} = this.props;
        let votes = lodash.get(seller, 'votes', []);

        return (
            <div>
                <a
                    className="disliked"
                    onClick={this.onClick}
                >
                    <Icon type="dislike" className="a-text--fz-10" /> Bỏ đánh dấu ({votes.length})
                </a>
            </div>
        );
    }
}

export default translate()(LikeButton);