export const ASSIGN_PURCHASER_ORDERS = {
    INIT: 'ORDER.ASSIGN_PURCHASER_ORDERS.INIT',
    BEGIN: 'ORDER.ASSIGN_PURCHASER_ORDERS.BEGIN',
    FINISH: 'ORDER.ASSIGN_PURCHASER_ORDERS.FINISH',
    REQUEST: 'ORDER.ASSIGN_PURCHASER_ORDERS.REQUEST',
    SUCCESS: 'ORDER.ASSIGN_PURCHASER_ORDERS.SUCCESS',
    FAILED: 'ORDER.ASSIGN_PURCHASER_ORDERS.FAILED',
};

export const ASSIGN_PURCHASER = {
    REQUEST: 'ORDER.ASSIGN_PURCHASER.REQUEST',
    SUCCESS: 'ORDER.ASSIGN_PURCHASER.SUCCESS',
    FAILED: 'ORDER.ASSIGN_PURCHASER.FAILED',
};

export const UNASSIGN_PURCHASER = {
    REQUEST: 'ORDER.UNASSIGN_PURCHASER.REQUEST',
    SUCCESS: 'ORDER.UNASSIGN_PURCHASER.SUCCESS',
    FAILED: 'ORDER.UNASSIGN_PURCHASER.FAILED',
};

export const DISTRIBUTE_STATUS = {
    PENDING: 'PENDING',
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    FINISH: 'FINISH',
};

