export const UPDATE_REAL_PAYMENT_AMOUNT_ORDER = {
    REQUEST: 'UPDATE_REAL_PAYMENT_AMOUNT_ORDER_REQUEST',
    SUCCESS: 'UPDATE_REAL_PAYMENT_AMOUNT_ORDER_SUCCESS',
    FAILED: 'UPDATE_REAL_PAYMENT_AMOUNT_ORDER_FAILED',
};

export const UPDATE_REAL_REFUND_AMOUNT_ORDER = {
    REQUEST: 'UPDATE_REAL_REFUND_AMOUNT_ORDER_REQUEST',
    SUCCESS: 'UPDATE_REAL_REFUND_AMOUNT_ORDER_SUCCESS',
    FAILED: 'UPDATE_REAL_REFUND_AMOUNT_ORDER_FAILED',
    ERROR: 'UPDATE_REAL_REFUND_AMOUNT_ORDER_ERROR',
};

export const CLEAR_ERROR_UPDATE_REAL_REFUND_AMOUNT_ORDER = "CLEAR_ERROR_UPDATE_REAL_REFUND_AMOUNT_ORDER";
export const CLEAR_ERROR_UPDATE_REAL_PAYMENT_AMOUNT_ORDER = "CLEAR_ERROR_UPDATE_REAL_PAYMENT_AMOUNT_ORDER";