import React from 'react';
import PropTypes from 'prop-types';
import {Col, Form, Row, Input, Icon, Button} from "antd";
import FormProcessing from "../../../modules/common/components/FormProcessing";
import {translate} from "react-i18next";
import SelectAccountPurchasers from "../../../modules/AccountPurchaser/SelectAccountPurchasers";
import SelectUserSource from "../../../modules/User/SelectUserSource";

const Search = Input.Search;

class FormSearch extends FormProcessing {
    constructor(props) {
        super(props);

        this.defaultInput = {
            username: '',
            site_name: '',
            account: '',
            time_from: '',
            time_to: ''
        };

        this.state = {
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
        };
    }

    componentDidMount() {

    }
    onChangeSelect(name, value) {
        this.changeInput({[name]: value});
    }

    onChangeText(param, event) {
        this.onChangeInput(param, event);
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.props.onSearch({...this.state.input});
        }
    }

    onReset(event) {
        event.preventDefault();

        if (!this.props.loading) {
            this.changeInput(this.defaultInput);
            this.props.onSearch(this.defaultInput);
        }
    }

    render() {
        let {t} = this.props;
        let {input} = this.state;
        return (
            <div className="a-list--form-search mr-0 ml-0">
                <Row gutter={32}>
                    <Form labelCol={{span: 12}} onSubmit={this.onSubmit.bind(this)} >
                        <Col lg={6} xl={5} xxl={4}>
                            <Form.Item label={t("purchaser:label.account_purchaser")} className="a-form--ant-item  mb-0">
                                <SelectAccountPurchasers
                                    showName
                                    allOption
                                    allAccount={true}
                                    searchByAccount={true}
                                    placeholder={t("transaction:placeholder.account")}
                                    loading={this.props.loading}
                                    onChange={this.onChangeSelect.bind(this, 'account')}
                                    value={input.account}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={5} xxl={4}>
                            <Form.Item label={t("purchaser:label.site_name")} className="a-form--ant-item  mb-0">
                                <SelectUserSource
                                    showName
                                    allOption
                                    placeholder={t("transaction:placeholder.site")}
                                    loading={this.props.loading}
                                    onChange={this.onChangeSelect.bind(this, 'site_name')}
                                    value={input.site_name || ''}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{span: 24}} lg={{span: 24}} xl={{span: 24}} className="mt-2 pt-5 border-top-dashed">
                            <div className="d-flex justify-content-end align-items-center">
                                <div className="a-list--action-search flex-row">
                                    <a href="#" onClick={this.onReset.bind(this)}  className="link-reset mr-5"><Icon type="sync"/>{t("btn.reset_search")}</a>
                                    <Button
                                        loading={this.props.loading}
                                        // disabled={!lodash.isEmpty(init_errors)}
                                        className="a-text--uppercase a-btn--padding-large a-btn--primary-ant"
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {t('btn.search')}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </div>
        );
    }
}

FormSearch.propTypes = {};

export default translate()(FormSearch);
