import {trans} from './../../../systems/i18n';
export const GET_LIST_TRANSACTION = {
    REQUEST: 'GET_LIST_TRANSACTION_REQUEST',
    SUCCESS: 'GET_LIST_TRANSACTION_SUCCESS',
    FAILED: 'GET_LIST_TRANSACTION_FAILED',
};

export const TAKE_ERRORS = 'TRANSACTION.LIST_TRANSACTION.TAKE_ERRORS';
export const RESET_ERROR = 'TRANSACTION.LIST_TRANSACTION.RESET_ERROR';

export const UPDATE_TRANSACTION = 'TRANSACTION.LIST_TRANSACTION.UPDATE';
export const GET_DATE_RANGE = 'TRANSACTION.LIST_TRANSACTION.GET_DATE_RANGE';

export const TRANSACTION_LIST_FILE_CLEAR_STATE = 'TRANSACTION_LIST_FILE_CLEAR_STATE';

export const TRANSACTION_TYPES = [
    {label: trans('label.all'), value: ''},
    {label: trans('transaction:type.SPEND_NORMAL'), value: 'SPEND_NORMAL'},
    {label: trans('transaction:type.SPEND_ASK_HELP'), value: 'SPEND_ASK_HELP'},
    {label: trans('transaction:type.COLLECT_NOMAL'), value: 'COLLECT_NOMAL'},
    {label: trans('transaction:type.COLLECT_ASK_HELP'), value: 'COLLECT_ASK_HELP'},
    {label: trans('transaction:type.TRANSFER'), value: 'TRANSFER'},
    {label: trans('transaction:type.SPEND_HELP'), value: 'SPEND_HELP'},
    {label: trans('transaction:type.COMPENSATORY'), value: 'COMPENSATORY'},
    {label: trans('transaction:type.INTEREST'), value: 'INTEREST'},
    {label: trans('transaction:type.COLLECT_HELP'), value: 'COLLECT_HELP'},
    {label: trans('transaction:type.RECHARGE'), value: 'RECHARGE'},
    {label: trans('transaction:type.COLLECT_COMPLAINT'), value: 'COLLECT_COMPLAINT'},
    {label: trans('transaction:type.COLLECT_HONGBAO'), value: 'COLLECT_HONGBAO'},
    {label: trans('transaction:type.UNKNOWN'), value: 'UNKNOWN'},
    {label: trans('transaction:type.CANCELED'), value: 'CANCELED'},
    {label: trans('transaction:type.CONVERSION'), value: 'CONVERSION'},
    {label: trans('transaction:type.SHIPPING_INSURANCE'), value: 'SHIPPING_INSURANCE'},
    {label: trans('transaction:type.COLLECT_TC'), value: 'COLLECT_TC'},
    {label: trans('transaction:type.SPEND_TC'), value: 'SPEND_TC'}
];
export const TIME_TYPES = [
    {label: trans('transaction:time_type.transaction_time'), value: 'transaction_time'},
    {label: trans('transaction:time_type.created_at'), value: 'created_at'},
    {label: trans('transaction:time_type.resolved_time'), value: 'resolved_time'},
]
export const TRANSACTION_HAS_INVOICE_CODE = [
    {label: trans('transaction:has_invoice_code.all'), value: 'ALL'},
    {label: trans('transaction:has_invoice_code.yes'), value: 1},
    {label: trans('transaction:has_invoice_code.no'), value: 0},
]

export const TRANSACTION_COLLECT_SPEND = [
    {label: trans('transaction:collect_spend.all'), value: 'ALL'},
    {label: trans('transaction:collect_spend.collect'), value: 'COLLECT'},
    {label: trans('transaction:collect_spend.spend'), value: 'SPEND'},
]

export const TRANSACTION_COLUMN = {
    ACCOUNT: 'account',
    SERI_NUMBER: 'seri_number',
    CODE: 'code',
    AMOUNT: 'amount_format',
    SERVICE_FEE: 'service_fee',
    TRANSACTION_TIME: 'transaction_time',
    ORDER_CODE: 'order_code',
    INVOICE_CODE: 'invoice_code',
    TYPE: 'type',
    SHOP: 'shop',
    CODE_PROVIDER: 'code_provider',
    TRANSACTION_TIME_CREATE: 'transaction_time_create',
    TRANSACTION_TIME_BEGIN: 'transaction_time_begin',
    AMOUNT_REFUND: 'amount_refund',
    SYSTEM: 'system',
    SOURCE: 'source',
    NOTE_TRANSACTION: 'note_transaction',
    CREATED_AT: 'created_at',
    RESOLVED_TIME: 'resolved_time',
    CSV_FILE: 'csv_file_name',
    RELATED_TRANSACTION: 'related_transaction',
    RELATED_ACCOUNT: 'accountRelated',
};

export const TRANSACTION_COLUMN_DISPLAY = {
    ACCOUNT: 'account',
    SERI_NUMBER: 'seri_number',
    CODE: 'code',
    AMOUNT: 'amount_format',
    SERVICE_FEE: 'service_fee',
    TRANSACTION_TIME: 'transaction_time_format',
    ORDER_CODE: 'order_code',
    // INVOICE_CODE: 'invoice_code',
    TYPE: 'type_format',
    SHOP: 'shop',
    CODE_PROVIDER: 'code_provider',
    TRANSACTION_TIME_CREATE: 'transaction_time_create_format',
    TRANSACTION_TIME_BEGIN: 'transaction_time_begin_format',
    AMOUNT_REFUND: 'amount_refund_format',
    SYSTEM: 'system',
    SOURCE: 'source',
    NOTE_TRANSACTION: 'note_transaction',
    CREATED_AT: 'created_at_format',
    RESOLVED_TIME: 'resolved_time_format',
    CSV_FILE: 'csv_file_name',
    RELATED_TRANSACTION: 'related_transaction',
    RELATED_ACCOUNT: 'accountRelated'
};

export const TYPES = {
    SINGLE: 'SINGLE',
    MULTIPLE: 'MULTIPLE'
};
