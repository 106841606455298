export const CREATE_DISTRIBUTOR_OWNER = {
    REQUEST: 'CREATE_DISTRIBUTOR_OWNER_REQUEST',
    SUCCESS: 'CREATE_DISTRIBUTOR_OWNER_SUCCESS',
    FAILED: 'CREATE_DISTRIBUTOR_OWNER_FAILED',
    ERROR: 'CREATE_DISTRIBUTOR_OWNER_ERROR',
    CLEAR: 'CREATE_DISTRIBUTOR_OWNER_CLEAR',
    BEGIN: 'CREATE_DISTRIBUTOR_OWNER_BEGIN',
};

export const FETCH_PURCHASES = {
    REQUEST: 'FETCH_PURCHASES_REQUEST',
    SUCCESS: 'FETCH_PURCHASES_SUCCESS',
    FAILED: 'FETCH_PURCHASES_FAILED'
};

export const FETCH_PURCHASER_ORDER_STATS = {
    REQUEST: 'FETCH_PURCHASER_ORDER_STATS.REQUEST',
    SUCCESS: 'FETCH_PURCHASER_ORDER_STATS.SUCCESS',
    FAILED: 'FETCH_PURCHASER_ORDER_STATS.FAILED'
};

export const FETCH_SERVICES = {
    REQUEST: 'FETCH_SERVICES_REQUEST',
    SUCCESS: 'FETCH_SERVICES_SUCCESS',
    FAILED: 'FETCH_SERVICES_FAILED'
};

export const PUSHER_UN_ASSIGN_ORDER = "PUSHER_UN_ASSIGN_ORDER";
export const PUSHER_ASSIGN_ORDER = "PUSHER_ASSIGN_ORDER";
export const CLEAR_COUNT_STATUS_CHANGED = "CLEAR_COUNT_STATUS_CHANGED";
export const CLEAR_ALL_COUNT_STATUS_CHANGED = "CLEAR_ALL_COUNT_STATUS_CHANGED";
export const TELLER_READ_COMMENT = "TELLER_READ_COMMENT";

export const DEFAULT_EMPTY_DATA = "---";
export const DEFAULT_EMPTY_NUMBER_DATA = 0;
export const DEFAULT_EMPTY_EXCHANGE = 0;
export const ALREADY_READ_COMMENT = 1;

export const ORDER_STATUS_WITH_CLASS = {
    WAITING_BUY: {
        value: 'WAITING_BUY',
        className: 'wating-buy'
    },
    WAITING_PAI: {
        value: 'WAITING_PAI',
        className: 'wating-pai'
    },
    PAI: {
        value: 'PAI',
        className: 'paied'
    },
    PAID: {
        value: 'PAID',
        className: 'wating-buy'
    },
    WAITING_PAY: {
        value: 'WAITING_PAY',
        className: 'wating-pay'
    },
    BOUGHT: {
        value: 'BOUGHT',
        className: 'bought'
    },
    CANCELLED: {
        value: 'CANCELLED',
        className: 'cancelled'
    },
    OUT_OF_STOCK: {
        value: 'OUT_OF_STOCK',
        className: 'out-of-stock'
    },
    SELLER_DELIVERY: {
        value: 'SELLER_DELIVERY',
        className: 'seller-delivery'
    },
    RECEIVED_FROM_SELLER: {
        value: 'RECEIVED_FROM_SELLER',
        className: 'recived-from-seller'
    },
    CROSS_CHECKING: {
        value: 'CROSS_CHECKING',
        className: 'cross-checking'
    },
};

export const REAL_AMOUNT_OPERATION = {
    EQUAL: 'EQUAL',
    LESS: 'LESS',
    GREAT: 'GREAT',
};

export const PLACEHOLDER = {
    ORDER: 'ORDER',
    ACCOUNT: 'ACCOUNT',
    VALUE: 'VALUE',
    TIME: 'TIME',
};
