import React from 'react';
import {translate} from "react-i18next";
import {Icon} from "antd";
import lodash from 'lodash';

class FormStatuses extends React.Component {

    onClick(status) {
        this.props.onSearch(
            {
                ...this.props.input,
                page: 1,
                status
            }
        );
        lodash.includes(['WAITING', 'PENDING'], status) && this.props.checkNewPaymentRequest(status);
    }

    renderPaymentRequestCount(status, count) {
        if (!this.props.listStatus.includes(status)) {
            return;
        }

        return (count === undefined || this.props.loadingCount)
            ? ' --'
            : <React.Fragment> - <b>{count}</b></React.Fragment>
    }

    render() {
        let {listStatus, status, statsCount, t, loading, input} = this.props;
        // if (parseInt(input.external_order) !== 1)
        //     listStatus = listStatus.filter(item => item !== "PENDING");
        return (
            <div className="a-tab-order">
                <div className="a-tab-order__title">
                    <ul>
                        {
                            listStatus.map(requestStatus => {
                                return (
                                    <li key={requestStatus}
                                        className={requestStatus === status.status ? 'active' : ''}
                                        onClick={this.onClick.bind(this, requestStatus)}
                                    >
                                        <a href="" onClick={(event) => event.preventDefault()} className={`_payment-request-status-${requestStatus}`}>
                                            <span/>
                                            <span className="a-text--">
                                                    {t('paymentRequest:status.' + requestStatus)}{statsCount ? this.renderPaymentRequestCount(requestStatus, statsCount[requestStatus]) : null}
                                                {
                                                    (!loading && requestStatus === 'WAITING' && this.props.newWAITINGPaymentRequest) ?
                                                        <span><Icon className={"ml-2"} type="bell" theme="filled" style={{color: '#19d900'}} title={t('paymentRequest:label.is_new_payment_request')}/></span> : null
                                                }
                                                {
                                                    (!loading && requestStatus === 'PENDING' && this.props.newPENDINGPaymentRequest) ?
                                                        <span><Icon className={"ml-2"} type="bell" theme="filled" style={{color: '#19d900'}} title={t('paymentRequest:label.is_new_payment_request')}/></span> : null
                                                }
                                                </span>

                                        </a>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }
}
export default translate()(FormStatuses);
