import lodash from "lodash";
import {translate} from "react-i18next";
import PropTypes from 'prop-types';
import {Col, Row} from "react-grid-system";
import React from 'react';
import DatetimePicker from "../../common/components/DatetimePicker";

class GroupItem extends React.Component {
    constructor(props) {
        super(props);
        let {groupItem} = this.props;
        this.state = {
            checked: !!parseInt(groupItem.value, 10),
            value: groupItem.value,
            date: groupItem.value
        }
    }

    onClickCheckbox(name, event) {
        let checked = !this.state.checked;
        this.setState({
            checked: checked
        });
        this.props.onChange({code: name, value: checked ? 1 : 0});
    }

    onChangeInput(name, event) {
        let value = lodash.get(event, 'target.value');
        this.setState({value: value})
    }

    onChangeDate(date) {
        this.setState(date)
    }

    render() {
        let {groupItem, t, isUsing} = this.props;
        let dataType = lodash.get(groupItem, 'data_type');
        let {checked, value, date} = this.state;
        return (
            <React.Fragment>
                <div className="a-setting__body">
                    <Row>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                            <p className="label__title">{t('setting:label.' + groupItem.code)}</p>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8} className="d-flex ">
                            {
                                dataType === "bool" ?
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <input type="checkbox"
                                               className="tgl tgl-light"
                                               checked={checked}
                                        />
                                        <label htmlFor="cb2"
                                               className={`tgl-btn ${isUsing ? "" : "disable" }`}
                                               onClick={this.onClickCheckbox.bind(this, groupItem.is_user_setting === 1 ? groupItem.code : 'isUsing')}
                                        />
                                    </Col> : null
                            }
                            {
                                dataType === "int" ?
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <input type="text"
                                               className={`input input--settings ${isUsing ? "" : "disable" }`}
                                               name={'log_status_from'}
                                               value={value}
                                               placeholder={t('setting:placeholder.' + groupItem.code)}
                                               onChange={this.onChangeInput.bind(this, groupItem.code)}
                                        />
                                    </Col> : null
                            }
                            {
                                dataType === "datetime" ?
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8} className="disable">
                                        <DatetimePicker
                                            className={`${isUsing ? "" : "disable" }`}
                                            name={'date'}
                                            date={date}
                                            placeholderText={'Chọn thời gian bắt đầu áp dụng '}
                                            onSubmit={this.onChangeDate.bind(this)}
                                        />
                                    </Col> : null
                            }
                        </Col>
                    </Row>

                </div>
            </React.Fragment>
        );
    }
}

GroupItem.defaultProps = {
    onChange: () => {
    },
    input: {},
};

GroupItem.propTypes = {
    groupItem: PropTypes.object,
    isUsing: PropTypes.bool,
    inputVersion: PropTypes.number,
};


export default translate('translations')(GroupItem);