import {combineReducers} from 'redux';
import * as ACTION from './constants';
import {DISTRIBUTE_STATUS} from './constants';

/*
 * State lưu trạng thái khi phân nhiều đơn cho GDV
 */
let status = (state = DISTRIBUTE_STATUS.PENDING, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.ASSIGN_PURCHASER_ORDERS.INIT:
        case ACTION.ASSIGN_PURCHASER_ORDERS.FAILED:
            return DISTRIBUTE_STATUS.PENDING;

        case ACTION.ASSIGN_PURCHASER_ORDERS.BEGIN:
        case ACTION.ASSIGN_PURCHASER_ORDERS.REQUEST:
            return DISTRIBUTE_STATUS.LOADING;

        case ACTION.ASSIGN_PURCHASER_ORDERS.FINISH:
        case ACTION.ASSIGN_PURCHASER_ORDERS.SUCCESS:
            return DISTRIBUTE_STATUS.FINISH;

        default:
            return state;
    }
};

/*
 * State lưu thông tin khi phân nhiều đơn cho GDV
 */
let input = (state = {}, action) => {
    let {type} = action;

    switch (type) {
        case ACTION.ASSIGN_PURCHASER_ORDERS.BEGIN:
            return action.payload;

        case ACTION.ASSIGN_PURCHASER_ORDERS.FINISH:
            return {};

        default:
            return state;
    }
};

export default combineReducers({
    status,
    input,
});
