import React from 'react';
import lodash from 'lodash';
import Spinner from '@atlaskit/spinner';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Tooltip} from "antd";
import FormProcessing from './../../../../common/components/FormProcessing';
import Input from '../../../../common/components/Input/Super';
import authService from '../../../../Auth/authService';
import { SELF_NEGOTIATE_UPDATE_PRODUCT } from '../../../../Permission/permissions';

class OrderItemQuantity extends FormProcessing {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                id: null,
                qty_bought: 0,
                id_partner: null,
            },
            inputVersion: 0,
            changedParams: [],
            comment: "",
            isHidden: false
        };

        this.inputFocus = React.createRef();
        this.isFocus = false;
    }

    componentDidUpdate() {
        if (this.isFocus) {
            this.isFocus = false;
            this.inputFocus.focus();
        }
    }

    onClickSoldOut() {
       
        let {input} = this.state;
        input = {...input, ...{qty_bought: 0}};
        this.props.changeMutipleQuantity(input);
    }

    handEditItems(event) {
        this.props.clearPurchaserBuyingChangeDataState();
        this.setState({
            isHidden: true
        });
        this.isFocus = true;
    }

    handSubmitFormEdit(event) {
        let {target} = event;
        let {item} = this.props;
        let {input} = this.state;
        let value = lodash.toInteger(target.value.trim());
        if (event.key === 'Enter' && item.qty_bought !== value && value) {
            input = {...input, ...{qty_bought: lodash.toInteger(target.value.trim())}};
            this.props.changeMutipleQuantity(input);
        }
        if ((event.key === 'Enter' && item.qty_bought === value)) {
            this.setState({
                isHidden: false
            });
        }
    }

    onChangeQuantity(value) {
        value = lodash.toInteger(value);
        this.setState({qty_bought: value});
    }

    render() {
        let {t, item, isLoadingUpdateData, order} = this.props;
        let inputVersion = item.id + '_' + item.is_pai;
        let {isHidden} = this.state;
        if (isHidden && isLoadingUpdateData.showEdit === false) {
            isHidden = false;
        }
        let isLoading = isLoadingUpdateData.qty_bought;
        const orderDetail = lodash.get(order, 'orderDetail', {})
        const self_negotiate = lodash.get(orderDetail, "self_negotiate", false)

        return (
            <div key={inputVersion} className="a-quantity d-flex">

                <div className={"d-flex mr-2 "+(order.canChangeQuantity ? '' : 'a-not-active disabled')}>
                    <Tooltip title={t('orderDetail:tooltip.qty_product')}>
                        <span>{item.qty}</span>
                    </Tooltip>
                    <span>/</span>
                    {
                        ((!!self_negotiate && authService.can(SELF_NEGOTIATE_UPDATE_PRODUCT)) || !self_negotiate)  ? 
                        <Tooltip title={t('orderDetail:order_detail.click_here_update_quantity')}>
                            <span onClick={this.handEditItems.bind(this)}
                                hidden={isHidden} style={{"cursor": "pointer"}}
                                className={"a-text--font-blue a-text--bold "+
                                ( isLoading? ' a-not-active ':' ')}>
                                <span>{item.qty_bought}</span>
                            </span>
                        </Tooltip> : <span className={"a-text--font-blue a-text--bold "}>{item.qty_bought}</span>
                    }
                    
                    <span>/</span>
                    <Tooltip title={t('orderDetail:tooltip.qty_received_product')}>
                        <span>{order.canShowQuantityReceived ? item.qty_received : '---'}</span>
                    </Tooltip>
                    <span hidden={!isHidden} className="a-text">&nbsp;
                        <Input
                            value={item.qty_bought}
                            disabled={isLoading}
                            numberNotZero={true}
                            onKeyUp={this.onChangeQuantity.bind(this)}
                            className="input--edit_quantity ml-0"
                            name="qty_bought"
                            onKeyPress={this.handSubmitFormEdit.bind(this)}
                            onRef={input => {this.inputFocus = input}}
                        />
                    </span>
                    {isLoading && lodash.get(isLoadingUpdateData, 'id', null) === item.id ?
                        (<span className="spinner"><Spinner size={"small"}/></span>) : null
                    }
                </div>

                {
                    order.canUpdateOutOfStock &&
                    <Tooltip title="" key={inputVersion + '_' + item.id}>
                        <p className={order.canChangeQuantity ? '' : 'a-not-active a-disable disabled'}>
                            <a
                                onClick={this.onClickSoldOut.bind(this)}
                                className={"btn-link _click-item-out-of-stock a--cursor-poiter " + (isLoading ? 'a-not-active ' : 'a-active') + (item.qty_bought === 0 ? ' a-disable ' : ' ') + (order.canChangeQuantity ? '' : ' a-not-active a-disable disabled ')}>
                                {t('orderDetail:order_detail.out_of_stock')}
                            </a>
                        </p>
                    </Tooltip>
                }
            </div>
        );
    }
}

OrderItemQuantity.defaultProps = {
    order: {},
    items: {},
};

OrderItemQuantity.propTypes = {
    order: PropTypes.object,
    item: PropTypes.object
};

export default translate('translations')(OrderItemQuantity);
