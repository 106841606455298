import React, { useState } from 'react';
import moment from 'moment';
import { Button, notification } from 'antd';
import apiService from '../../modules/PaymentRequest/apiService';
import { trans } from '../../systems/i18n';

const ExportCSV = (props) => {
	const {
		t,
		csvFilter,
		csvFilter: { created_at_from, created_at_to, last_state_time_from, last_state_time_to },
	} = props;
	const [loading, setLoading] = useState(false);

	async function exportExcel() {
		try {
			setLoading(true);
			const { page, per_page, ...filter } = csvFilter;
			const response = await apiService.exportCSV(filter);
			const { code } = response.data;

			if (code !== 200) throw response.data;

			notification.success({ message: t('paymentRequest:message.export_excel_success') });
		} catch (err) {
			if (err.code === 403) return;
			if (!created_at_from && !last_state_time_from) {
				notification.error({ message: 'Thời gian yêu cầu không được để trống' });
				return;
			}
			if (created_at_from) {
				const timeFrom = moment(created_at_from);
				const timeTo = moment(created_at_to);
				if (timeTo.diff(timeFrom, 'days') >= 31) {
					notification.error({
						message: trans('paymentRequest:reason.require_export_excel', {
							filterName: t('paymentRequest:label.request_time'),
						}),
					});
					return;
				}
			}
			if (last_state_time_from) {
				const timeFrom = moment(last_state_time_from);
				const timeTo = moment(last_state_time_to);
				if (timeTo.diff(timeFrom, 'days') >= 31) {
					notification.error({
						message: trans('paymentRequest:reason.require_export_excel', {
							filterName: t('paymentRequest:label.last_time'),
						}),
					});
					return;
				}
			}
			notification.error({ message: t('paymentRequest:message.export_excel_failed') });
		} finally {
			setLoading(false);
		}
	}

	return (
		<Button
			className='a-font--12 aphz-24 a-btn--primary-ant ghost mr-4 _payment-request-export-csv'
			style={{ paddingTop: 1, marginRight: 3 }}
			onClick={exportExcel}
			loading={loading}
			size='small'
		>
			{t('paymentRequest:btn.export')}
		</Button>
	);
};

export default ExportCSV;
