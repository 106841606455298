import React from 'react'
import UpdateAffiliateLink from '../UpdateAffiliateLink'
import { translate } from 'react-i18next'
import lodash, { isEmpty } from 'lodash'
import { Icon, Tooltip, Modal, Row, Col, Input, notification, AutoComplete } from 'antd'
import AutoCompleteCategory from './AutoCompleteCategory'
import { processResponse } from '../../../../../../systems/api'
import apiService from '../../../apiService'
import { DATA_SOURCE_DEFAULT_UNIT_VN } from '../../../constants'
import UpdateProperties from '../../UpdateProduct/UpdateProperties'
import SuggestProductName from './SuggestProductName'
import { t } from '../../../../../../systems/i18n'

class ProductName extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isVisible: false,
            isLoading: false,
            data: {},
            errorMessage: null,
            customs_info_name_manual: true,
            unit_manual: true,
        }
    }

    componentDidMount() {
        let { translated_brand, translated_material, translated_properties, customs_info_name, unit, customs_info_name_vn, unit_vn } = this.props.item

        let new_state = {
            customs_info_name,
            unit,
            customs_info_name_vn,
            unit_vn,
            translated_brand,
            translated_material,
        }

        if (translated_properties && translated_properties.length > 0) this.setState({ data: { ...new_state, translated_properties } })
        else this.setState({ data: new_state })
    }

    static getDerivedStateFromProps(props, state) {
        let { data, isVisible } = state

        if (isVisible) return null

        let updatedData = {}
        let { item } = props
        ;['translated_brand', 'translated_material', 'customs_info_name', 'customs_info_name_vn', 'unit', 'unit_vn', 'translated_properties'].forEach(prop => {
            if (item[prop] !== data[prop]) updatedData[prop] = item[prop]
        })

        return lodash.isEmpty(updatedData) ? null : { ...state, data: { ...data, ...updatedData } }
    }

    showModal = e => {
        e.preventDefault()
        this.fetchOrderDetail().then(() => this.setState({ isVisible: true }))
        // this.setState({isVisible: true});
    }

    fetchOrderDetail = () => {
        let { order } = this.props
        let res = processResponse(apiService.getPurchasingOrderDetailAction({ id: order.id }))
        return res
            .then(res => {
                let items = lodash.get(res.data, 'orders', {})
                this.props.updateItemsOrderDetail({ data: items })
            })
            .catch(err => {})
    }

    handleOk = () => {
        let { item, t } = this.props
        let { data, customs_info_name_manual, unit_manual } = this.state
        this.setState({ isLoading: true })

        let res = processResponse(
            apiService.updateCustomsInfo(item.id, {
                name: 'data',
                value: Object.keys(data).map(key => {
                    return {
                        name: key,
                        value: data[key],
                    }
                }),
                customs_info_name_manual,
                unit_manual,
            })
        )
        res.then(res => {
            this.props.updateItemsOrderDetail(res)
            notification.success({ message: `Cập nhật thông tin hải quan sản phẩm thành công` })
            this.setState({ isVisible: false })
        })
        res.catch(err => {
            const data = lodash.get(err, 'data', {})
            if (data) {
                const connect_error = lodash.get(data, 'connect_error', '')
                if (!lodash.isEmpty(connect_error)) {
                    notification.error({ message: 'Cập nhật thông tin hải quan không thành công, vui lòng thử lại' })
                } else {
                    Object.keys(data).map(key => {
                        notification.error({ message: t(`validation:${Object.keys(data[key])[0].toLowerCase()}`, { attribute: t(`order:label.${key}`) }) })
                    })
                }
            } else {
                notification.error({ message: `Cập nhật thông tin hải quan sản phẩm không thành công` })
            }
        })
        res.finally(() => {
            this.setState({ isLoading: false })
        })
    }

    handleCancel = () => {
        this.setState({ isVisible: false })
    }

    onChangeInfo = (value, property, labelList = []) => {
        let { data } = this.state
        if (labelList.includes(value)) {
            if (property === 'customs_info_name_vn') {
                this.setState({
                    customs_info_name_manual: false,
                })
            }
            if (property === 'unit_vn') {
                this.setState({
                    unit_manual: false,
                })
            }
        }
        this.setState({
            data: { ...data, [property]: value },
            errorMessage: null,
        })
    }

    onChangeProperty = (e, id, attr) => {
        let { data } = this.state
        let translated_properties = lodash.get(this.state, 'data.translated_properties', [])
        translated_properties = translated_properties.map((prop, index) => {
            if (id === index || (id === prop.id && id !== 0)) return { ...prop, [attr]: e.target.value }
            else return prop
        })
        this.setState({
            data: { ...data, translated_properties },
            errorMessage: null,
        })
    }

    onRenderVN = key => {
        const { item, classifyProduct } = this.props

        const filterProducts = lodash.filter(classifyProduct, val => {
            return lodash.replace(val.title || '', /\s/g, '') === lodash.replace(lodash.get(item, 'title', '') || '', /\s/g, '')
        })

        switch (key) {
            case 'name':
                if (item.customs_info_name_vn) {
                    return item.customs_info_name_vn
                }

                const labels = lodash.get(filterProducts, '0.labels', [])
                if (!isEmpty(labels)) {
                    return (
                        <span className="text-warning">
                            {lodash.get(labels, '0')}
                            <Tooltip title={t('order:message.suggest_by_system')}>
                                <Icon
                                    type="info-circle"
                                    className="ml-1 a--cursor-poiter"
                                />
                            </Tooltip>
                        </span>
                    )
                }

                break
            case 'unit':
                if (item.unit_vn) {
                    return item.unit_vn
                }

                const unitList = lodash.get(filterProducts, '0.units', [])
                if (!isEmpty(unitList)) {
                    return (
                        <span className="text-warning">
                            {lodash.get(unitList, '0')}{' '}
                            <Tooltip title={t('order:message.suggest_by_system')}>
                                <Icon
                                    type="info-circle"
                                    className="ml-1 a--cursor-poiter"
                                />
                            </Tooltip>
                        </span>
                    )
                }

                break
            default:
                break
        }

        return '---'
    }

    onConfirmNameAndUnit = () => {
        const { item, classifyProduct } = this.props

        Modal.confirm({
            className: 'a-modal--confirm-trade',
            title: t('order:message.update_name_unit_vn'),
            content: '',
            cancelText: 'Hủy',
            okText: 'Đồng ý',
            zIndex: 1050,
            icon: (
                <Icon
                    type="question-circle"
                    theme="filled"
                />
            ),
            onOk: () => {
                const filterProducts = lodash.filter(classifyProduct, val => {
                    return lodash.replace(val.title || '', /\s/g, '') === lodash.replace(lodash.get(item, 'title', '') || '', /\s/g, '')
                })
                const labels = lodash.get(filterProducts, '0.labels', [])
                const unitList = lodash.get(filterProducts, '0.units', [])

                return processResponse(
                    apiService.updateCustomsInfo(item.id, {
                        name: 'data',
                        value: [
                            {
                                name: 'customs_info_name_vn',
                                value: lodash.get(labels, '0'),
                            },
                            {
                                name: 'unit_vn',
                                value: lodash.get(unitList, '0'),
                            },
                        ],
                    })
                )
                    .then(res => {
                        this.props.updateItemsOrderDetail(res)
                        notification.success({ message: `Cập nhật thông tin hải quan sản phẩm thành công` })
                        this.setState({ isVisible: false })
                    })
                    .catch(err => {
                        const data = lodash.get(err, 'data', {})
                        if (data) {
                            const connect_error = lodash.get(data, 'connect_error', '')
                            if (!lodash.isEmpty(connect_error)) {
                                notification.error({ message: 'Cập nhật thông tin hải quan không thành công, vui lòng thử lại' })
                            } else {
                                Object.keys(data).map(key => {
                                    notification.error({
                                        message: t(`validation:${Object.keys(data[key])[0].toLowerCase()}`, { attribute: t(`order:label.${key}`) }),
                                    })
                                })
                            }
                        } else {
                            notification.error({ message: `Cập nhật thông tin hải quan sản phẩm không thành công` })
                        }
                    })
                    .finally(() => {
                        this.setState({ isLoading: false })
                    })
            },
        })
    }

    render() {
        let { t, item, order, classifyProduct } = this.props
        let { isVisible, isLoading, data, errorMessage } = this.state
        let link_text = item.affiliate_link ? item.affiliate_link : item.link
        let { warningKeywords } = order
        let properties = []

        if (item.translated_brand) properties.push(item.translated_brand)
        if (item.translated_material) properties.push(item.translated_material)

        let translated_properties = lodash.get(item, 'translated_properties', [])
        if (translated_properties && translated_properties.length > 0) {
            translated_properties.forEach(prop => {
                if (prop.value) properties.push(prop.value)
            })
        }

        const filterProducts = lodash.filter(classifyProduct, val => {
            return lodash.replace(val.title || '', /\s/g, '') === lodash.replace(lodash.get(item, 'title', '') || '', /\s/g, '')
        })

        const unitList = lodash.get(filterProducts, '0.units', [])

        return (
            <div className="mt-1">
                {/* broken-easy-product- sử dụng cho autopai extension, cảnh báo sản phẩm dễ vỡ dont change it pls. */}
                <p
                    className="ml-3"
                    id={`broken-easy-product-${item.id}`}>
                    <a
                        target="_blank"
                        href={link_text}
                        data-source={order.seller_source}
                        data-item-id={item.item_id}
                        className={`order-item-link order-item-link_` + item.item_id}>
                        {item.title}
                    </a>
                    {properties.length > 0 || order.canShowCustomsInfo ? <span className="ml-1 mr-1">-</span> : ''}
                    {order.canShowCustomsInfo && order.canUpdateCustomsInfo ? (
                        <a onClick={this.showModal}>
                            <Tooltip title={'Cập nhật thông tin hải quan sản phẩm'}>
                                {properties.join(' / ')}
                                <Icon
                                    type="edit"
                                    className="ml-1"
                                />
                            </Tooltip>
                        </a>
                    ) : order.canShowCustomsInfo ? (
                        <span>{properties.join(' / ')}</span>
                    ) : (
                        ''
                    )}
                    {item.origin_name && warningKeywords.some(keyword => item.origin_name.indexOf(keyword) >= 0) && (
                        <span className="broken-ease ml-2">[HÀNG DỄ VỠ]</span>
                    )}
                    <UpdateAffiliateLink
                        item={item}
                        order={order}
                        updateAffiliateLink={this.props.updateAffiliateLink}
                    />
                </p>
                {item.category_name ? (
                    <p className="ml-3">
                        {t('order_detail.category_of_goods')}: <span>{item.category_name}</span>
                    </p>
                ) : null}
                <p className="ml-3">
                    Mẫu:{' '}
                    <span className="a-text--font-primary a-text--break-all">
                        {item.option}
                        {order.canChangePropertiesOrderItem && (
                            <UpdateProperties
                                orderItem={item}
                                updateItemsOrderDetail={this.props.updateItemsOrderDetail}
                            />
                        )}
                    </span>
                </p>
                {order.canShowCustomsInfo && (
                    <div className="ml-3">
                        <p>
                            <Tooltip title={'Tên sản phẩm khai báo hải quan tiếng Trung'}>
                                Tên TQ: {item.customs_info_name ? item.customs_info_name : '---'}
                            </Tooltip>
                            <span className="ml-2 mr-2">/</span>
                            <Tooltip title={'Đơn vị khai báo hải quan tiếng Trung'}>Đơn vị TQ: {item.unit ? item.unit : '---'}</Tooltip>
                        </p>
                        <p>
                            <Tooltip title={'Tên sản phẩm khai báo hải quan tiếng Việt'}>Tên VN:</Tooltip> {this.onRenderVN('name')}
                            <span className="ml-2 mr-2">/</span>
                            <Tooltip title={'Đơn vị khai báo hải quan tiếng Việt'}>Đơn vị VN:</Tooltip> {this.onRenderVN('unit')}
                            {(!item.customs_info_name_vn || !item.unit_vn) && (
                                <Tooltip title={'Cập nhật thông tin hải quan sản phẩm'}>
                                    {' '}
                                    <a onClick={this.onConfirmNameAndUnit}>
                                        <Icon
                                            type="check"
                                            className="ml-1"
                                        />
                                    </a>{' '}
                                </Tooltip>
                            )}
                        </p>
                    </div>
                )}
                {item.edited_customs_info === 1 && (
                    <p className="ml-3 a-text--color-black-45">
                        <Icon
                            type="check-circle"
                            className="a-text--color-green"
                        />{' '}
                        Đã cập nhật thông tin hải quan
                    </p>
                )}
                {isVisible && (
                    <Modal
                        title="Cập nhật thông tin hải quan sản phẩm"
                        visible={isVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        cancelText="Hủy"
                        okText="Cập nhật"
                        okButtonProps={{ loading: isLoading }}>
                        {errorMessage && <p className="a-text--red a-text--fz-12 mt-2 mb-2">{errorMessage}</p>}
                        <Row>
                            <Col span={6}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Tên tiếng Trung
                                </label>
                            </Col>
                            <Col span={18}>
                                <AutoCompleteCategory
                                    placeholder="Tên sản phẩm khai báo hải quan tiếng Trung"
                                    type="customs_info_name"
                                    onSelect={value => this.onChangeInfo(value, 'customs_info_name')}
                                    defaultValue={data.customs_info_name}
                                    width="100%"
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col span={6}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Đơn vị T.Trung
                                </label>
                            </Col>
                            <Col span={18}>
                                <AutoCompleteCategory
                                    placeholder="Đơn vị khai báo hải quan tiếng Trung"
                                    type="unit"
                                    onSelect={value => this.onChangeInfo(value, 'unit')}
                                    defaultValue={data.unit}
                                    width="100%"
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col span={6}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Tên tiếng Việt
                                </label>
                            </Col>
                            <Col span={18}>
                                <SuggestProductName
                                    placeholder="Tên sản phẩm khai báo hải quan tiếng Việt"
                                    type="customs_info_name_vn"
                                    onSelect={value => this.onChangeInfo(value, 'customs_info_name_vn', lodash.get(filterProducts, '0.labels', []))}
                                    defaultValue={data.customs_info_name_vn}
                                    width="100%"
                                    product={item}
                                    classifyProductOne={lodash.get(filterProducts, '0.labels', [])}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col span={6}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Đơn vị tiếng Việt
                                </label>
                            </Col>
                            <Col span={18}>
                                <AutoComplete
                                    dataSource={!lodash.isEmpty(unitList) ? unitList : DATA_SOURCE_DEFAULT_UNIT_VN}
                                    filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                    placeholder="Đơn vị khai báo hải quan tiếng Việt"
                                    onSelect={value => this.onChangeInfo(value, 'unit_vn', !lodash.isEmpty(unitList) ? unitList : DATA_SOURCE_DEFAULT_UNIT_VN)}
                                    onChange={value => this.onChangeInfo(value, 'unit_vn', !lodash.isEmpty(unitList) ? unitList : DATA_SOURCE_DEFAULT_UNIT_VN)}
                                    defaultValue={data.unit_vn}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col
                                span={6}
                                style={{ lineHeight: '32px' }}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Thương hiệu
                                </label>
                            </Col>
                            <Col span={18}>
                                {item.brand && (
                                    <Tooltip title="Tên thương hiệu tiếng Trung">
                                        <span>{item.brand}</span>
                                    </Tooltip>
                                )}
                                <AutoCompleteCategory
                                    placeholder="Tên thương hiệu"
                                    type="brand"
                                    onSelect={value => this.onChangeInfo(value, 'translated_brand')}
                                    defaultValue={data.translated_brand}
                                    width="100%"
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col span={6}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Chất liệu
                                </label>
                            </Col>
                            <Col span={18}>
                                {item.material && (
                                    <Tooltip title="Tên chất liệu tiếng Trung">
                                        <span>{item.material}</span>
                                    </Tooltip>
                                )}
                                <AutoCompleteCategory
                                    placeholder="Tên chất liệu"
                                    type="material"
                                    onSelect={value => this.onChangeInfo(value, 'translated_material')}
                                    defaultValue={data.translated_material}
                                    width="100%"
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col
                                span={6}
                                style={{ lineHeight: '32px' }}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Thuộc tính
                                </label>
                            </Col>
                            <Col span={18}>
                                {data.translated_properties ? (
                                    data.translated_properties.map((property, index) => {
                                        if (!property.name) {
                                            if (order.seller_source == 'taobao' || order.seller_source == 'tmall') {
                                                if (index === 0) property.name = 'Size'
                                                if (index === 1) property.name = 'Màu'
                                            } else {
                                                if (index === 0) property.name = 'Màu'
                                                if (index === 1) property.name = 'Size'
                                            }
                                        }
                                        return (
                                            <>
                                                <Row
                                                    gutter={10}
                                                    className={index > 0 ? 'mt-3' : ''}>
                                                    <Col span={12}>
                                                        {property.originalName && (
                                                            <Tooltip title="Tên thuộc tính tiếng Trung">
                                                                <span>{property.originalName}</span>
                                                            </Tooltip>
                                                        )}
                                                    </Col>
                                                    <Col span={12}>
                                                        {property.originalValue && (
                                                            <Tooltip title="Giá trị thuộc tính tiếng Trung">
                                                                <span>{property.originalValue}</span>
                                                            </Tooltip>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row gutter={10}>
                                                    <Col span={12}>
                                                        <Input
                                                            value={property.name}
                                                            placeholder={'Tên thuộc tính'}
                                                            onChange={e => this.onChangeProperty(e, property.id !== 0 ? property.id : index, 'name')}
                                                        />
                                                    </Col>
                                                    <Col span={12}>
                                                        <Input
                                                            value={property.value}
                                                            placeholder={'Giá trị thuộc tính'}
                                                            onChange={e => this.onChangeProperty(e, property.id !== 0 ? property.id : index, 'value')}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    })
                                ) : (
                                    <p
                                        style={{ lineHeight: '32px' }}
                                        className="a-text--red">
                                        Đơn dữ liệu cũ chưa hỗ trợ nhập thông tin thuộc tính.
                                    </p>
                                )}
                            </Col>
                        </Row>
                    </Modal>
                )}
            </div>
        )
    }
}

export default translate('translations')(ProductName)
