import { connect } from 'react-redux';
import PerformanceReport from './index';
import { setPageTitle, setBreadCrumb } from '../../modules/common/actions';

const mapDispatchToProps = (dispatch) => {
	return {
		setPageTitle: (value) => {
			dispatch(setPageTitle(value));
		},
		setBreadCrumb: (items) => {
			dispatch(setBreadCrumb(items));
		},
	};
};

export default connect(null, mapDispatchToProps)(PerformanceReport);
