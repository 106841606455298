import React from 'react'
import { Form, Row, Col, Input, DatePicker, Button, Icon, Select } from 'antd'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import FormProcessing from '../../../common/components/FormProcessing'
import SelectAccountPurchasers from '../../../AccountPurchaser/SelectAccountPurchasers'
import moment from 'moment'
import lodash from 'lodash'
import { TRANSACTION_TYPES, TRANSACTION_HAS_INVOICE_CODE, TRANSACTION_COLLECT_SPEND, TIME_TYPES } from '../constants'
import SelectTeller from '../../../User/SelectTellers'
import { permissions } from '../../../Permission'
import authService from '../../../../modules/Auth/authService'
import * as PERMISSION from '../../../Permission/permissions'
import Super from '../../../common/components/Input/Super'
import SelectUnusualType from '../../SelectUnusualType'

const Option = Select.Option
const InputGroup = Input.Group
const { RangePicker } = DatePicker
const dateFormat = 'YYYY/MM/DD'

const checkError = (input, errors, t) => {
    let { amount_from, amount_to } = input
    if (parseFloat(amount_from) > parseFloat(amount_to)) {
        errors = { ...errors, amount: t('transaction:message.amount_error') }
    } else {
        errors = lodash.omit(errors, 'amount')
    }
    return errors
}

const currentDay = moment().format('YYYY-MM-DD')
const threeMonthsAgo = moment().subtract(3, 'M').format('YYYY-MM-DD')
class FormSearch extends FormProcessing {
    constructor(props) {
        super(props)

        this.defaultInput = {
            code_or_code_provider: '',
            code: '',
            code_provider: '',
            shop: '',
            order_code: '',
            invoice_code: '',
            type: undefined,
            account: '',
            transaction_time_from: '',
            transaction_time_to: '',
            time_type: TIME_TYPES[0].value,
            time_from: threeMonthsAgo,
            time_to: currentDay,
            is_resolved: '',
            has_invoice_code: undefined,
            collect_spend: undefined,
            resolved_creator_id: undefined,
            amount_from: '',
            amount_to: '',
            unusual_type: '',
            is_duplicated_invoice_code: undefined,
            account_related: '',
            code_by_account: '',
        }

        this.state = {
            input: { ...this.defaultInput },
            init_errors: {},
            inputVersion: 0,
            changedParams: [],
        }
    }

    onSubmit(event) {
        let { input } = this.state
        event.preventDefault()
        let data = { ...this.state.input }
        data = lodash.omitBy(data, value => value === 'ALL')
        data = lodash.mapValues(data, value => (lodash.isString(value) ? value.trim() : value))
        if (input.resolved_creator_id) {
            this.changeInput({ ...input, is_resolved: '1' })
            data = {
                ...data,
                amount_from: data.amount_from ? parseFloat(data.amount_from) : '',
                amount_to: data.amount_from ? parseFloat(data.amount_to) : '',
                is_resolved: '1',
            }
        }
        if (!this.props.loading) {
            this.props.onSearch(data)
        }
        this.props.resetError('time')
    }

    componentDidMount() {
        let { input } = this.props
        this.props.setFilterCSV({
            ...input,
        })

        if (
            authService.canAny([permissions.SUPPLIER_TRANSACTION_LIST, permissions.SUPPLIER_TRANSACTION_LIST_ALL]) &&
            !authService.can(permissions.SUPPLIER_TRANSACTION_LIST_NORMAL) &&
            input.is_resolved !== '1'
        ) {
            this.props.onSearch({ ...input, is_resolved: '0' })
        }
    }

    onChangeSelect(name, value) {
        this.changeInput({ [name]: value })
        let resolve = {
            is_resolved: name === 'resolved_creator_id' && value !== '' ? 1 : '',
        }
        this.props.setFilterCSV({
            [name]: value,
            ...resolve,
        })
    }

    /**
     * Thay đổi lọc đã xử lý hoặc chưa xử lý GD bất thường
     * @param resolved
     */
    onChangeResolved(resolved) {
        let is_resolved_new = ''
        let { input } = this.state
        let { is_resolved } = input

        if (is_resolved !== '') {
            is_resolved = parseInt(is_resolved)
        }

        if (is_resolved === 1) {
            if (resolved === 'is_not_resolved') {
                is_resolved_new = '0'
            }
        } else if (is_resolved === 0) {
            if (resolved === 'is_resolved') {
                is_resolved_new = '1'
            }
        } else if (is_resolved === '') {
            if (resolved === 'is_not_resolved') {
                is_resolved_new = '0'
            } else if (resolved === 'is_resolved') {
                is_resolved_new = '1'
            }
        }

        this.changeInput({ is_resolved: is_resolved_new, is_duplicated_invoice_code: undefined })

        if (!this.props.loading) {
            this.props.onSearch({ ...input, is_resolved: is_resolved_new, is_duplicated_invoice_code: undefined })
            this.props.setFilterCSV({ is_resolved: is_resolved_new })
        }
    }

    onChangeDuplicateMHDG() {
        let { is_duplicated_invoice_code } = this.state.input
        let is_duplicated_invoice_code_new = is_duplicated_invoice_code
        if (!is_duplicated_invoice_code) {
            is_duplicated_invoice_code_new = 1
        } else {
            is_duplicated_invoice_code_new = undefined
        }
        this.changeInput({ is_duplicated_invoice_code: is_duplicated_invoice_code_new, is_resolved: '' })

        if (!this.props.loading) {
            this.props.onSearch({ ...this.state.input, is_duplicated_invoice_code: is_duplicated_invoice_code_new, is_resolved: '' })
            this.props.setFilterCSV({ is_duplicated_invoice_code: is_duplicated_invoice_code_new })
        }
    }

    onChangeText(param, event) {
        this.onChangeInput(param, event)
    }

    onChangeNumber(value, param) {
        let { t } = this.props
        if (param.includes('from') || param.includes('to')) {
            value = parseFloat(value)
        }
        let { input, init_errors } = this.state
        input = { ...input, [param]: value }
        init_errors = checkError(input, init_errors, t)
        this.setState({ init_errors: { ...init_errors } })
        this.changeInput({ [param]: value })
        this.props.setFilterCSV({ [param]: value })
    }
    setFilter(param, event) {
        this.props.setFilterCSV({ [param]: event.target.value })
    }
    onChangeTransactionTime(date) {
        this.changeInput({
            time_from: date[0] ? date[0].format('YYYY-MM-DD') : '',
            time_to: date[1] ? date[1].format('YYYY-MM-DD') : '',
        })
        this.props.setFilterCSV({
            time_from: date[0] ? date[0].format('YYYY-MM-DD') : '',
            time_to: date[1] ? date[1].format('YYYY-MM-DD') : '',
        })
    }

    onReset(event) {
        let { input } = this.state
        event.preventDefault()
        if (!this.props.loading) {
            this.changeInput(this.defaultInput)
            if (
                authService.canAny([permissions.SUPPLIER_TRANSACTION_LIST, permissions.SUPPLIER_TRANSACTION_LIST_ALL]) &&
                !authService.can(permissions.SUPPLIER_TRANSACTION_LIST_NORMAL)
            ) {
                this.changeInput({ ...this.defaultInput, is_resolved: '0' })
                this.props.onSearch({ ...this.defaultInput, is_resolved: '0' })
            } else {
                this.changeInput(this.defaultInput)
                this.props.onSearch(this.defaultInput)
            }

            this.props.setFilterCSV({
                ...this.defaultInput,
            })
        }
        this.props.resetError('time')
    }

    render() {
        let { t, errors } = this.props
        let { input, init_errors } = this.state
        let { is_resolved, is_duplicated_invoice_code } = input
        if (is_resolved !== '') {
            is_resolved = parseInt(is_resolved)
        }
        const {canUseGlobal, canUseMbill} = this.props.permission;
        return (
            <div className="a-list--form-search mr-0 ml-0">
                <Row gutter={24}>
                    <Form
                        labelcol={{ span: 12 }}
                        onSubmit={this.onSubmit.bind(this)}>
                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.code')}
                                className="a-form--ant-item mb-0">
                                <Input
                                    type={'text'}
                                    value={input.code || ''}
                                    placeholder={t('transaction:label.code')}
                                    onChange={this.onChangeText.bind(this, 'code')}
                                    onBlur={this.setFilter.bind(this, 'code')}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.shop')}
                                className="a-form--ant-item mb-0">
                                <Input
                                    type={'text'}
                                    value={input.shop || ''}
                                    placeholder={t('transaction:label.shop')}
                                    onChange={this.onChangeText.bind(this, 'shop')}
                                    onBlur={this.setFilter.bind(this, 'shop')}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.code_provider')}
                                className="a-form--ant-item mb-0">
                                <Input
                                    type={'text'}
                                    value={input.code_provider || ''}
                                    placeholder={t('transaction:label.code_provider')}
                                    onChange={this.onChangeText.bind(this, 'code_provider')}
                                    onBlur={this.setFilter.bind(this, 'code_provider')}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.order_code')}
                                className="a-form--ant-item  mb-0">
                                <Input
                                    type="text"
                                    value={input.order_code || ''}
                                    placeholder={t('transaction:label.order_code')}
                                    onChange={this.onChangeText.bind(this, 'order_code')}
                                    onBlur={this.setFilter.bind(this, 'order_code')}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.invoice_code')}
                                className="a-form--ant-item  mb-0">
                                <Input
                                    type="text"
                                    value={input.invoice_code || ''}
                                    placeholder={t('transaction:label.invoice_code')}
                                    onChange={this.onChangeText.bind(this, 'invoice_code')}
                                    onBlur={this.setFilter.bind(this, 'invoice_code')}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.has_invoice_code')}
                                className="a-form--ant-item  mb-0">
                                <Select
                                    showSearch={true}
                                    value={isNaN(parseInt(input.has_invoice_code, 10)) ? input.has_invoice_code : parseInt(input.has_invoice_code, 10)}
                                    placeholder={t('transaction:placeholder.has_invoice_code')}
                                    className="single-react"
                                    noOptionsMessage={({ inputValue: string }) => t('message.no_record')}
                                    onSelect={this.onChangeSelect.bind(this, 'has_invoice_code')}
                                    optionFilterProp="children">
                                    {TRANSACTION_HAS_INVOICE_CODE.map(type => (
                                        <Option
                                            key={type.value}
                                            value={type.value}>
                                            {type.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.account')}
                                className="a-form--ant-item  mb-0">
                                <SelectAccountPurchasers
                                    showName
                                    allOption
                                    allAccount={true}
                                    searchByAccount={true}
                                    placeholder={t('transaction:placeholder.account')}
                                    loading={this.props.loading}
                                    onChange={this.onChangeSelect.bind(this, 'account')}
                                    value={input.account}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.type')}
                                className="a-form--ant-item  mb-0">
                                <Select
                                    showSearch={true}
                                    value={input.type}
                                    placeholder={t('transaction:placeholder.type')}
                                    className="single-react"
                                    noOptionsMessage={({ inputValue: string }) => t('message.no_record')}
                                    onSelect={this.onChangeSelect.bind(this, 'type')}
                                    optionFilterProp="children">
                                    {TRANSACTION_TYPES.map(type => (
                                        <Option
                                            key={type.value}
                                            value={type.value}>
                                            {type.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.collect_spend')}
                                className="a-form--ant-item  mb-0">
                                <Select
                                    showSearch={true}
                                    value={input.collect_spend}
                                    placeholder={t('transaction:placeholder.collect_spend')}
                                    className="single-react"
                                    noOptionsMessage={({ inputValue: string }) => t('message.no_record')}
                                    onSelect={this.onChangeSelect.bind(this, 'collect_spend')}
                                    optionFilterProp="children">
                                    {TRANSACTION_COLLECT_SPEND.map(type => (
                                        <Option
                                            key={type.value}
                                            value={type.value}>
                                            {type.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.file_resolver')}
                                className="a-form--ant-item  mb-0">
                                <SelectTeller
                                    showName
                                    allOption
                                    value={input.resolved_creator_id}
                                    showArrow={false}
                                    placeholder={t('transaction:placeholder.resolver')}
                                    loading={this.props.loading}
                                    onChange={this.onChangeSelect.bind(this, 'resolved_creator_id')}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.amount')}
                                className="a-form--ant-item  mb-0"
                                help={init_errors.amount}
                                validateStatus={init_errors.amount ? 'error' : null}>
                                <InputGroup
                                    compact
                                    className="a-group--input">
                                    <Super
                                        negative={true}
                                        value={input.amount_from || ''}
                                        placeholder={t('transaction:label.amount_from')}
                                        className={'a-input--number input-first'}
                                        onChange={event => this.onChangeNumber(event, 'amount_from')}
                                    />
                                    <Input
                                        placeholder="-"
                                        disabled
                                        className={'input-disable'}
                                    />
                                    <Super
                                        negative={true}
                                        value={input.amount_to || ''}
                                        placeholder={t('transaction:label.amount_to')}
                                        className={'a-input--number input-second'}
                                        onChange={value => this.onChangeNumber(value, 'amount_to')}
                                    />
                                </InputGroup>
                            </Form.Item>
                        </Col>
                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.unusual_type')}
                                className="a-form--ant-item mb-0">
                                <SelectUnusualType
                                    loading={this.props.loading}
                                    value={input.unusual_type || ''}
                                    placeholder={t('transaction:placeholder.unusual_type')}
                                    showSearch={true}
                                    optionFilterProp={'children'}
                                    unusualTypes={this.props.unusualTypes}
                                    allOption={true}
                                    onChange={this.onChangeSelect.bind(this, 'unusual_type')}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.account_purchase')}
                                className="a-form--ant-item mb-0">
                                <SelectAccountPurchasers
                                    value={input.account_related}
                                    allOption={false}
                                    allowClear={true}
                                    searchByAccount={true}
                                    placeholder="Chọn tài khoản liên quan"
                                    onChange={this.onChangeSelect.bind(this, 'account_related')}
                                />
                            </Form.Item>
                        </Col>
                        {
                            (canUseGlobal || canUseMbill) &&
                            <Col
                                lg={6}
                                xl={4}
                                xxl={4}
                                className="mb-2">
                                <Form.Item
                                    label={t('transaction:label.seri_number')}
                                    className="a-form--ant-item mb-0">
                                    <Input
                                        type={'text'}
                                        value={input.code_by_account || ''}
                                        placeholder={t('transaction:label.seri_number')}
                                        onChange={this.onChangeText.bind(this, 'code_by_account')}
                                        onBlur={this.setFilter.bind(this, 'code_by_account')}
                                    />
                                </Form.Item>
                            </Col>
                        }
                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.time_type_label')}
                                className="a-form--ant-item  mb-0"
                                validateStatus={errors.time && lodash.startsWith(errors.time, t('transaction:label.time_type')) ? 'error' : null}>
                                <Select
                                    showSearch={true}
                                    value={input.time_type}
                                    placeholder={t('transaction:placeholder.time_type')}
                                    className="single-react"
                                    noOptionsMessage={({ inputValue: string }) => t('message.no_record')}
                                    onSelect={this.onChangeSelect.bind(this, 'time_type')}
                                    optionFilterProp="children">
                                    {TIME_TYPES.map(type => (
                                        <Option
                                            key={type.value}
                                            value={type.value}>
                                            {type.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            lg={6}
                            xl={4}
                            xxl={4}
                            className="mb-2">
                            <Form.Item
                                label={t('transaction:label.time_mark')}
                                className="a-form--ant-item  mb-0"
                                help={[errors.time]}
                                validateStatus={errors.time ? 'error' : null}>
                                <RangePicker
                                    placeholder={[t('transaction:placeholder.begin'), t('transaction:placeholder.end')]}
                                    format="DD/MM/YYYY"
                                    value={[
                                        input['time_from'] ? moment(input['time_from'], dateFormat) : null,
                                        input['time_to'] ? moment(input['time_to'], dateFormat) : null,
                                    ]}
                                    onChange={this.onChangeTransactionTime.bind(this)}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 24 }}
                            xl={{ span: 24 }}
                            className="mt-2 pt-5 border-top-dashed">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="a-list--action-search flex-row">
                                    <Button
                                        className={' a-font--12 a-btn--primary-ant mr-5 ' + (parseInt(is_resolved) !== 0 ? 'ghost' : '')}
                                        size={'small'}
                                        onClick={
                                            !authService.can(PERMISSION.SUPPLIER_TRANSACTION_LIST_NORMAL) && parseInt(is_resolved) === 0
                                                ? null
                                                : this.onChangeResolved.bind(this, 'is_not_resolved')
                                        }>
                                        {t('transaction:is_not_ok.not_resolved')}
                                    </Button>
                                    <Button
                                        className={'a-font--12 a-btn--primary-ant mr-5 ' + (parseInt(is_resolved) !== 1 ? 'ghost' : '')}
                                        size={'small'}
                                        onClick={
                                            !authService.can(PERMISSION.SUPPLIER_TRANSACTION_LIST_NORMAL) && parseInt(is_resolved) === 1
                                                ? null
                                                : this.onChangeResolved.bind(this, 'is_resolved')
                                        }>
                                        {t('transaction:is_not_ok.resolved')}
                                    </Button>

                                    <Button
                                        className={'a-font--12 a-btn--primary-ant ' + (is_duplicated_invoice_code ? '' : 'ghost')}
                                        size={'small'}
                                        onClick={
                                            !authService.can(PERMISSION.SUPPLIER_TRANSACTION_LIST_NORMAL) && is_duplicated_invoice_code
                                                ? null
                                                : this.onChangeDuplicateMHDG.bind(this)
                                        }>
                                        {t('transaction:btn.is_duplicated_invoice_code')}
                                    </Button>
                                </div>
                                <div className="a-list--action-search flex-row">
                                    <a
                                        href="#"
                                        onClick={this.onReset.bind(this)}
                                        className="link-reset mr-5">
                                        <Icon type="sync" />
                                        {t('btn.reset_search')}
                                    </a>
                                    <Button
                                        loading={this.props.loading}
                                        disabled={!lodash.isEmpty(init_errors)}
                                        className="a-text--uppercase a-btn--padding-large a-btn--primary-ant"
                                        type="primary"
                                        htmlType="submit">
                                        {t('btn.search')}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </div>
        )
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    onSearch: input => {},
}

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
}

export default translate('translations')(FormSearch)
