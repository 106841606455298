import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import ComplaintNote from './components/index';
import {updateNote, clearUpdateNote} from './actions';

const mapStateToProps = (state, props) => {
    let {complaint, permissions} = props;
    return {
        complaint: complaint,
        canUpdateNote: lodash.get(permissions, 'can_update_note', false),
        isLoading: lodash.get(state, 'ComplaintSeller.complaintNote.isLoading', false),
        isEndProcess: lodash.get(state, 'ComplaintSeller.complaintNote.isEndProcess', false),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    let {complaint} = props;

    return {
        onUpdateNote: (params) => {
            dispatch(updateNote({id: complaint.id, params: params}));
        },
        clearUpdateNote: () => {
            dispatch(clearUpdateNote({}));
        },
    };
};

let ComplaintSellerNoteContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ComplaintNote);

ComplaintSellerNoteContainer.defaultProps = {
    complaint: {},
};

ComplaintSellerNoteContainer.propTypes = {
    complaint: PropTypes.object.isRequired,
};

export default ComplaintSellerNoteContainer;
