import React from 'react'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { AutoComplete } from 'antd'
import { processResponse } from '../../../../../../systems/api'
import apiService from '../../../apiService'

class SuggestProductName extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inputValue: null,
            dataSource: [],
            loading: false,
        }

        this._isMounted = false
    }

    componentDidMount() {
        this._isMounted = true
        this.getProductsName()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getProductsName() {
        const { classifyProductOne } = this.props
        
        const labels = lodash.get(classifyProductOne, '0.labels', [])

        this.setState({
            dataSource: classifyProductOne,
        })

        // const { product } = this.props
        // this.setState({
        //     loading: true,
        // })
        // processResponse(
        //     apiService.searchClassifyProduct({
        //         titles: [lodash.get(product, 'title') || ''],
        //         limit: 10,
        //     })
        // )
        //     .then(response => {
        //         let result = lodash.get(response, 'data', [])
        //         const labels = lodash.get(result, 'title_labels.0.labels', [])
        //         this.setState({
        //             dataSource: labels,
        //         })
        //     })
        //     .finally(() => {
        //         this.setState({
        //             loading: false,
        //         })
        //     })
    }

    handleBlur = (value, option) => {
        this.handleSelect(value, option)
        this.props.onBlur(value, option)
    }

    handleSelect = (value, option) => {
        this.props.onSelect(value, option)
    }

    render() {
        const { placeholder, width, autoFocus, defaultValue , classifyProductOne} = this.props

        const { dataSource } = this.state
        return (
            <>
                <AutoComplete
                    placeholder={placeholder}
                    dataSource={dataSource}
                    onSelect={this.handleSelect}
                    defaultValue={defaultValue}
                    onBlur={this.handleBlur}
                    autoFocus={autoFocus}
                    style={{ width }}
                    disabled={this.props.disabled || false}
                    filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                    key={record => record}
                />
            </>
        )
    }
}
export default SuggestProductName

SuggestProductName.defaultProps = {
    placeholder: '',
    type: '',
    width: 'unset',
    onSelect: () => {},
    onBlur: () => {},
    autoFocus: false,
}

SuggestProductName.propTypes = {
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    autoFocus: PropTypes.bool,
    width: PropTypes.string,
}
