import React, {useState} from 'react';
import {Button, Icon, Modal} from "antd";
import {trans} from "../../../../../systems/i18n";

export default function AlertSpecialFeatures(props) {
    const [visible, setVisible] = useState(true);
    const {properties_warning} = props;
    return (
        <Modal
            visible={visible}
            title={
                <>
                    <Icon type="warning"
                          style={{fontSize: "18px", color: "red"}}/> {trans("orderDetail:features_warning.title")}
                </>
            }
            onCancel={() => setVisible(false)}
            footer={[
                <Button key={"submit"} onClick={() => setVisible(false)} type="primary">{trans("btn.ok")}</Button>
            ]}
            bodyStyle={{border: "unset"}}
        >
            {
                trans("orderDetail:features_warning.content", {features: <b>{properties_warning.join(", ")}</b>})
            }
        </Modal>
    )
}