import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Input, InputNumber, DatePicker, Form, Select, Divider, Icon, Button } from 'antd'
import SelectPurchaseAddress from '../../../../../../modules/PurchaseAddress/component/SelectPurchaseAddress'
import SelectAgencies from '../../../../../../modules/Agency/SelectAgencies'
import SelectAccountPurchasers from '../../../../../../modules/AccountPurchaser/SelectAccountPurchasers'
import FormProcessing from '../../../../../../modules/common/components/FormProcessing'
import { translate } from 'react-i18next'
import SelectTeller from '../../../../../../modules/User/SelectTellers'
import SelectTellersV2 from '../../../../../../modules/User/SelectTellersV2'
import lodash from 'lodash'
import { ORDER_TIME_TYPE } from '../../../../../../systems/globalContant/actionContant'
import { trans } from '../../../../../../systems/i18n'
import { dateFormatter } from '../../../../../../modules/common/services/format'
import moment from 'moment'
import { withEvent } from '../../../../../../systems/events'
import ORDER_EVENTS from '../../../../../../modules/Order/events'
import DateRange from '../../../../../../modules/common/components/DateTime/DateRange'
import SelectFeatures from '../../../../../../modules/Features/components/SelectFeatures'
import SelectDistributionWarehouse from '../../../../../../modules/Order/SelectDistributionWarehouse'
import { STATUS_LIST } from '../../../../../ForeignOrder/constants'
import DateRangeCustomize from '../../../../../../modules/common/components/DateTime/DateRangeCustomize'
import { hasPermission, permissions } from '../../../../../../modules/Permission'
import SelectOrderGroupService from '../../../../../../modules/OrderGroupManager/SelectOrderGroupService'
import SelectFin from '../SelectFin'

const { Option } = Select
const { RangePicker } = DatePicker
const InputGroup = Input.Group

let timeOptions = lodash.values(ORDER_TIME_TYPE).map(Item => {
    return { label: trans('order:type_type.' + Item), value: Item }
})

const currentDay = moment('23:59:59', 'HH:mm:ss')
const threeMonthsAgo = moment('00:00:00', 'HH:mm:ss').subtract(3, 'M')

timeOptions = lodash.compact(timeOptions)

class ExpandedFormSearch extends FormProcessing {
    constructor(props) {
        super(props)

        this.state = {
            input: {},
            inputVersion: 1,
            changedParams: [],
        }
    }

    componentDidMount() {
        let { filter } = this.props
        this.setState({ input: { ...filter } })
        let resetFc = (event, payload) => this.setState({ input: { ...payload } })
        this.props.on({
            [ORDER_EVENTS.RESET_FILTER]: resetFc,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        let { resetFilter, defaultFilter } = this.props

        if (prevProps.resetFilter !== resetFilter && resetFilter === true) this.setState({ input: { ...defaultFilter } })
    }

    onChangeRangePicker = dates => {
        if (!lodash.isEmpty(dates))
            this.props.onSearch(
                this.changeInput({
                    log_status_from: dates.log_status_from,
                    log_status_to: dates.log_status_to,
                    page: 1,
                })
            )
        else
            this.props.onSearch(
                this.changeInput({
                    log_status_from: '',
                    log_status_to: '',
                    page: 1,
                })
            )
    }

    onSearchBlur = (param, e) => {
        let { input } = this.state
        let { filter } = this.props

        if ((input[param] === undefined || input[param] === '') && e.target.value === '' && (filter[param] === undefined || filter[param] === '')) return false

        if (filter[param] === e.target.value) return false

        this.props.onSearch({ ...input, page: 1 })
    }

    onSearchSelect = (param, value) => {
        let { filter } = this.props

        if ((value === '' || value === undefined) && (filter[param] === undefined || filter[param] === '')) return false
        let data = { [param]: value, page: 1 }
        if (param === 'id_agency') {
            data = {
                ...data,
                service_id: undefined,
            }
        }
        let dataFilter = this.changeInput(data)
        this.props.onSearch(dataFilter)
    }

    onSearchInput = (param, e) => {
        this.onChangeInput(param, e)
        if (e.target.value === '') e.target.focus()
    }

    changeDeliveryLongTime = () => {
        let { filterLongTime } = this.props
        let deliveryLongTime = lodash.get(filterLongTime, 'deliveryLongTime.day') || 0
        let days = moment()
            .subtract(deliveryLongTime * 24, 'h')
            .format('YYYY-MM-DD HH:mm')

        let filter = this.changeInput({
            status: ['BOUGHT'],
            time_type: 'BOUGHT',
            log_status_from: moment(days).subtract(1, 'M').format('YYYY-MM-DD HH:mm'),
            log_status_to: days,
        })
        this.props.onSearch(filter)
    }

    changeReceivedLongTime = () => {
        let { filterLongTime } = this.props
        let receivedLongTime = lodash.get(filterLongTime, 'receivedLongTime.day') || 0
        let days = moment()
            .subtract(receivedLongTime * 24, 'h')
            .format('YYYY-MM-DD HH:mm')

        let filter = this.changeInput({
            status: ['SELLER_DELIVERY'],
            time_type: 'SELLER_DELIVERY',
            log_status_from: moment(days).subtract(1, 'M').format('YYYY-MM-DD HH:mm'),
            log_status_to: days,
        })
        this.props.onSearch(filter)
    }

    /**
     *
     * @returns {*}
     */
    getOverduePurchase = () => {
        let { filter } = this.props
        let overdue_purchase = lodash.get(filter, 'overdue_purchase', false)
        if (overdue_purchase == 'false') {
            overdue_purchase = false
        } else if (overdue_purchase == 'true') {
            overdue_purchase = true
        }

        return overdue_purchase
    }

    changeOverduePurchase = () => {
        let overdue_purchase = this.getOverduePurchase()
        let filterInput = this.changeInput({
            overdue_purchase: !overdue_purchase,
        })

        this.props.onSearch(filterInput)
    }

    render() {
        let { t, filterLongTime, sellerSource, canUsingFintech } = this.props
        let { input } = this.state
        let totalItemLinkOperations = ['>', '<']
        let deliveryLongTime = lodash.get(filterLongTime, 'deliveryLongTime.day') || 0
        let receivedLongTime = lodash.get(filterLongTime, 'receivedLongTime.day') || 0
        let overdue_purchase = this.getOverduePurchase()

        return (
            <Form className="a-list--form-search">
                <Row gutter={40}>
                    <Col xl={6} lg={6} md={12} sm={24} xs={24} className="a-filter--group-expanded">
                        <Form.Item label="TK khách hàng" className="a-form--ant-item mb-0">
                            <Input
                                type="text"
                                allowClear
                                value={input.buyer_username || ''}
                                placeholder={t('order:list.buyer_placeholder')}
                                onChange={e => this.onSearchInput('buyer_username', e)}
                                onBlur={e => this.onSearchBlur('buyer_username', e)}
                                onPressEnter={e => this.onSearchBlur('buyer_username', e)}
                                className="_ordering_form_search_buyer_username"
                            />
                        </Form.Item>
                        <Form.Item label="Mã đơn hàng" className="a-form--ant-item mb-0">
                            <Input
                                type="text"
                                allowClear
                                value={input.code || undefined}
                                placeholder={t('order:list.order_code_placeholder')}
                                onChange={e => this.onSearchInput('code', e)}
                                onBlur={e => this.onSearchBlur('code', e)}
                                onPressEnter={e => this.onSearchBlur('code', e)}
                                className="_ordering_form_search_code"
                            />
                        </Form.Item>
                        <Form.Item label="Mã hóa đơn gốc" className="a-form--ant-item mb-0">
                            <Input
                                type="text"
                                allowClear
                                value={input.invoice_code || undefined}
                                placeholder={'Nhập mã hóa đơn gốc'}
                                onChange={e => this.onSearchInput('invoice_code', e)}
                                onBlur={e => this.onSearchBlur('invoice_code', e)}
                                onPressEnter={e => this.onSearchBlur('invoice_code', e)}
                                className="_ordering_form_search_invoice_code"
                            />
                        </Form.Item>
                        <Form.Item label="Mã vận đơn" className="a-form--ant-item mb-0">
                            <Input
                                type="text"
                                allowClear
                                value={input.freight_bill || undefined}
                                placeholder={'Nhập mã vận đơn'}
                                onChange={e => this.onSearchInput('freight_bill', e)}
                                onBlur={e => this.onSearchBlur('freight_bill', e)}
                                onPressEnter={e => this.onSearchBlur('freight_bill', e)}
                                className="_ordering_form_search_freight_bill"
                            />
                        </Form.Item>
                        {hasPermission(permissions.TAOBAO_GET_PURCHASE_ORDER) && (
                            <Form.Item label={t('order:label.order_st_status')} className="a-form--ant-item mb-0">
                                <Select
                                    mode={'multiple'}
                                    value={input.order_st_status ? input.order_st_status : []}
                                    allowClear={true}
                                    placeholder={t('order:placeholder.order_st_status')}
                                    onChange={value => this.onSearchSelect('order_st_status', value)}>
                                    {STATUS_LIST.map(source => (
                                        <Option key={source} value={source}>
                                            {t(`order:ORDER_TAOBAO_STATUS.${source}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}

                        <Form.Item label={t('order:label.type_order')} className="a-form--ant-item mb-0">
                            <Select
                                value={input.self_negotiate ? input.self_negotiate : ''}
                                allowClear={true}
                                placeholder={t('order:placeholder.type_order')}
                                onChange={value => this.onSearchSelect('self_negotiate', value)}>
                                <Option key="" value="">Tất cả</Option>
                                <Option key="YES" value="YES">Đơn tự deal</Option>
                                <Option key="NO" value="NO">Đơn thường</Option>
                            </Select>
                        </Form.Item>

                       {canUsingFintech  && <Form.Item
                            label={t('order:label.loan_bifiin')}
                            className="a-form--ant-item mb-0">
                            <SelectFin  
                                onChange={value => this.onSearchSelect('fintech', value)} 
                                value={lodash.get(input, 'fintech')}
                            />
                        </Form.Item>}
                    </Col>

                    <Col xl={6} lg={6} md={12} sm={24} xs={24} className="a-filter--group-expanded">
                        <Form.Item label={t('order:list.account_purchase')} className="a-form--ant-item  mb-0">
                            <SelectAccountPurchasers
                                value={input.id_purchaser}
                                multiple={true}
                                allOption={false}
                                allowClear={true}
                                placeholder="Chọn tài khoản mua hàng"
                                onChange={value => this.onSearchSelect('id_purchaser', value)}
                                className="_ordering_form_search_id_purchaser"
                            />
                        </Form.Item>
                        <Form.Item label="Kho nhận" className="a-form--ant-item mb-0">
                            <SelectPurchaseAddress
                                value={input.id_purchase_address}
                                multiple={true}
                                allOption={false}
                                placeholder="Chọn kho nhận"
                                onChange={value => this.onSearchSelect('id_purchase_address', value)}
                                className="_ordering_form_search_id_purchase_address"
                            />
                        </Form.Item>
                        <Form.Item label={t('order:list.site')} className="a-form--ant-item  mb-0">
                            <Select
                                value={input.seller_source}
                                allowClear={true}
                                mode="multiple"
                                placeholder="Chọn nguồn"
                                showSearch={true}
                                optionFilterProp="children"
                                onChange={value => this.onSearchSelect('seller_source', value)}
                                className="_ordering_form_search_seller_source">
                                {sellerSource.map(source => (
                                    <Option key={source} value={source}>
                                        {t(`order:ORDER_SOURCE.${source}`)}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Giao dịch viên" className="a-form--ant-item  mb-0">
                            <SelectTellersV2
                                allOption
                                value={input.id_teller}
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="children"
                                placeholder="Chọn giao dịch viên"
                                onChange={value => this.onSearchSelect('id_teller', value)}
                                className="_ordering_form_search_id_teller"
                            />
                        </Form.Item>

                        <Form.Item label="Kho phân phối" className="a-form--ant-item mb-0">
                            <SelectDistributionWarehouse
                                value={input.destination_warehouse}
                                multiple={true}
                                allOption={false}
                                placeholder="Chọn kho phân phối"
                                onChange={value => this.onSearchSelect('destination_warehouse', value)}
                                className="_ordering_form_search_id_warehouse_distribution"
                            />
                        </Form.Item>

                        <Form.Item label={t('order:label.self_transport')} className="a-form--ant-item mb-0">
                            <Select
                                value={input.is_cn_address ? input.is_cn_address : ''}
                                allowClear={true}
                                placeholder={t('order:placeholder.self_transport')}
                                onChange={value => this.onSearchSelect('is_cn_address', value)}>
                                <Option key="" value="">Tất cả</Option>
                                <Option key="YES" value="YES">Đơn tự vận chuyển</Option>
                                <Option key="NO" value="NO">Đơn không tự vận chuyển</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={24} xs={24} className="a-filter--group-expanded">
                        <Form.Item label={t('order:label.agencies')} className="a-form--ant-item mb-0">
                            <SelectAgencies
                                value={input.id_agency}
                                multiple={true}
                                allOption={false}
                                allowClear={true}
                                placeholder={t('order:placeholder.agencies')}
                                onChange={value => this.onSearchSelect('id_agency', value)}
                                className="_ordering_form_search_id_agency"
                            />
                        </Form.Item>

                        <Row className="p-0 m-0">
                            <Col span={12}>
                                <Form.Item label="Số lượng link" className="a-form--ant-item  mb-0">
                                    <InputGroup compact>
                                        <Select
                                            value={input.total_item_link_operation || totalItemLinkOperations[0]}
                                            allowClear={true}
                                            optionFilterProp="children"
                                            onChange={value => this.onSearchSelect('total_item_link_operation', value)}
                                            style={{ width: '65%' }}>
                                            {totalItemLinkOperations.map(operation => (
                                                <Option key={operation} value={operation}>
                                                    {t('operation.' + operation)}
                                                </Option>
                                            ))}
                                        </Select>
                                        <Input
                                            type="number"
                                            min={0}
                                            max={10}
                                            style={{ width: '35%', paddingRight: '5px' }}
                                            value={input.total_item_link}
                                            onChange={e => this.onChangeInput('total_item_link', e)}
                                            onBlur={e => this.onSearchBlur('total_item_link', e)}
                                            onPressEnter={e => this.onSearchBlur('total_item_link', e)}
                                        />
                                    </InputGroup>
                                </Form.Item>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={11}>
                                <Form.Item
                                    label={
                                        <span>
                                            Giá trị <span style={{ color: '#ccc' }}>(Đơn vị K)</span>
                                        </span>
                                    }
                                    className="a-form--ant-item mb-0">
                                    <InputGroup compact>
                                        <Input
                                            type="number"
                                            min={0}
                                            style={{ width: '50%', paddingRight: '5px' }}
                                            value={input.grand_total_converted_from}
                                            placeholder="Từ"
                                            onChange={e => this.onChangeInput('grand_total_converted_from', e)}
                                            onBlur={e => this.onSearchBlur('grand_total_converted_from', e)}
                                            onPressEnter={e => this.onSearchBlur('grand_total_converted_from', e)}
                                            className="_ordering_form_search_grand_total_converted_from"
                                        />
                                        <Input
                                            type="number"
                                            min={0}
                                            style={{ width: '50%', paddingRight: '5px' }}
                                            value={input.grand_total_converted_to}
                                            placeholder="Đến"
                                            onChange={e => this.onChangeInput('grand_total_converted_to', e)}
                                            onBlur={e => this.onSearchBlur('grand_total_converted_to', e)}
                                            onPressEnter={e => this.onSearchBlur('grand_total_converted_to', e)}
                                            className="_ordering_form_search_grand_total_converted_to"
                                        />
                                    </InputGroup>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item label={<span>Giá trị Nhân dân tệ </span>} className="a-form--ant-item mb-0">
                            <InputGroup compact>
                                <Input
                                    type="number"
                                    min={0}
                                    style={{ width: '50%', paddingRight: '5px' }}
                                    value={input.grand_total_from}
                                    placeholder="Từ"
                                    onChange={e => this.onChangeInput('grand_total_from', e)}
                                    onBlur={e => this.onSearchBlur('grand_total_from', e)}
                                    onPressEnter={e => this.onSearchBlur('grand_total_from', e)}
                                    className="_ordering_form_search_grand_total_from"
                                />
                                <Input
                                    type="number"
                                    min={0}
                                    style={{ width: '50%', paddingRight: '5px' }}
                                    value={input.grand_total_to}
                                    placeholder="Đến"
                                    onChange={e => this.onChangeInput('grand_total_to', e)}
                                    onBlur={e => this.onSearchBlur('grand_total_to', e)}
                                    onPressEnter={e => this.onSearchBlur('grand_total_to', e)}
                                    className="_ordering_form_search_grand_total_to"
                                />
                            </InputGroup>
                        </Form.Item>

                        <Form.Item label={t('order:list.KNNB')} className="a-form--ant-item  mb-0">
                            <Select
                                value={input.complaint_seller ? input.complaint_seller : ''}
                                allowClear={true}
                                placeholder={t('order:list.KNNB')}
                                onChange={value => this.onSearchSelect('complaint_seller', value)}
                                className="_ordering_form_search_complaint_seller">
                                <Option value="">{t('label.all')}</Option>
                                <Option value={'ALL'}>{t('order:list.has_complaint_seller')}</Option>
                                <Option value={'ACTIVE'}>{t('order:list.has_complaint_seller_active')}</Option>
                                <Option value={'NONE'}>{t('order:list.has_not_complaint_seller')}</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={t('order:list.customs_info')} className="a-form--ant-item  mb-0">
                            <Select
                                value={input.is_full_customs_info ? input.is_full_customs_info : ''}
                                placeholder={t('order:list.customs_info')}
                                onChange={value => this.onSearchSelect('is_full_customs_info', value)}
                                className="_ordering_form_search_is_full_customs_info">
                                <Option value="">{t('label.all')}</Option>
                                <Option value="1">{t('order:label.order_full_customs_info')}</Option>
                                <Option value="0">{t('order:label.order_not_full_customs_info')}</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={t('order:list.can_exported')} className="a-form--ant-item  mb-0">
                            <Select
                                value={input.can_exported ? input.can_exported : ''}
                                placeholder={t('order:list.can_exported')}
                                onChange={value => this.onSearchSelect('can_exported', value)}
                                className="_ordering_form_search_can_exported">
                                <Option value="">{t('label.all')}</Option>
                                <Option value="NULL">{t('order:label.can_exported_not_scanned')}</Option>
                                <Option value="NO">{t('order:label.can_not_exported_scanned')}</Option>
                                <Option value="YES">{t('order:label.can_exported')}</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label={t('order:detail.order_group')} className="a-form--ant-item  mb-0">
                            <Select
                                value={input.has_order_group ? input.has_order_group : ''}
                                placeholder={t('order:detail.order_group')}
                                onChange={value => this.onSearchSelect('has_order_group', value)}>
                                <Option value="">{t('label.all')}</Option>
                                <Option value="NO">{t('order:label.not_has_order_group')}</Option>
                                <Option value="YES">{t('order:label.has_order_group')}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={24} xs={24} className="a-filter--group-expanded">
                        <Form.Item label={t('order:label.wrong_process_search')} className="a-form--ant-item  mb-0">
                            <Select
                                value={input.wrong_process !== '' && input.wrong_process != null ? parseInt(input.wrong_process) : ''}
                                allowClear={true}
                                placeholder={t('order:label.wrong_process_search')}
                                onChange={value => this.onSearchSelect('wrong_process', value)}
                                className="_ordering_form_search_wrong_process">
                                <Option value="">{t('label.all')}</Option>
                                <Option value={0}>{t('order:label.process_ok')}</Option>
                                <Option value={1}>{t('order:label.process_wrong')}</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label={t('order:label.batch_order')} className="a-form--ant-item  mb-0">
                            <Select
                                value={input.is_batch_order ? input.is_batch_order : ''}
                                allowClear={true}
                                placeholder={t('order:label.batch_order')}
                                onChange={value => this.onSearchSelect('is_batch_order', value)}>
                                <Option value="">{t('label.all')}</Option>
                                <Option value="NO">{t('order:label.is_not_batch_order')}</Option>
                                <Option value="YES">{t('order:label.is_batch_order')}</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label="Loại thời gian" className="a-form--ant-item  mb-0">
                            <Select
                                value={input.time_type ? input.time_type : ''}
                                placeholder={t('order:list.choose_time')}
                                onChange={value => this.onSearchSelect('time_type', value)}
                                className="_ordering_form_search_time_type">
                                {timeOptions.map(item => (
                                    <Option key={item.value} value={item.value}>
                                        {item.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Thời gian" className="a-form--ant-item mb-0">
                            <DateRangeCustomize
                                from={input['log_status_from']}
                                to={input['log_status_to']}
                                format="DD/MM/YYYY HH:mm:ss"
                                valueFormat="YYYY-MM-DD HH:mm:ss"
                                showTime={{ defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }}
                                onChange={(from, to) =>
                                    this.onChangeRangePicker({
                                        log_status_from: from,
                                        log_status_to: to,
                                    })
                                }
                                className="_ordering_form_search_log_status"
                            />
                        </Form.Item>
                        <Form.Item label="Tên shop" className="a-form--ant-item mb-0">
                            <Input
                                type="text"
                                allowClear
                                value={input.seller_name || undefined}
                                placeholder="Nhập tên shop"
                                onChange={e => this.onSearchInput('seller_name', e)}
                                onBlur={e => this.onSearchBlur('seller_name', e)}
                                onPressEnter={e => this.onSearchBlur('seller_name', e)}
                                className="_ordering_form_search_seller_name"
                            />
                        </Form.Item>
                        <Form.Item label={t('order:list.order_nature')} className="a-form--ant-item  mb-0">
                            <SelectFeatures
                                value={input.properties || undefined}
                                placeholder={t('order:list.choose_order_nature')}
                                allowClear={true}
                                multiple={true}
                                onChange={value => this.onSearchSelect('properties', value)}
                                className="_ordering_form_search_properties"
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('order_detail.service')}
                            validateStatus={!input.id_agency || (input.id_agency && input.id_agency.length === 1) ? undefined : 'warning'}
                            help={!input.id_agency || (input.id_agency && input.id_agency.length === 1) ? undefined : 'Chỉ chọn 1 đại lý để có thể tìm kiếm dịch vụ'}
                            className="a-form--ant-item mb-0">
                            <SelectOrderGroupService
                                id_agency={input.id_agency && input.id_agency.length === 1 ? input.id_agency[0] : undefined}
                                value={input.service_id ? input.service_id : undefined}
                                multiple={false}
                                allowClear={true}
                                placeholder={t('order:placeholder.service')}
                                onChange={value => this.onSearchSelect('service_id', value)}
                            />
                        </Form.Item>
                        
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} className="mt-4 pt-5 border-top-dashed ">
                        <Button onClick={this.changeDeliveryLongTime} className="a-text--uppercase a-btn--primary-ant mx-1 ghost">
                            {deliveryLongTime + ' ngày chưa phát hàng'}
                        </Button>
                        <Button onClick={this.changeReceivedLongTime} className="a-text--uppercase a-btn--primary-ant mx-1 ghost">
                            {receivedLongTime + ' ngày chưa nhận hàng'}
                        </Button>
                        <Button
                            onClick={this.changeOverduePurchase}
                            className={
                                'a-text--uppercase a-btn--primary-ant mx-1 ' + (overdue_purchase === false || overdue_purchase === 'false' ? 'ghost' : '')
                            }>
                            Quá hạn mua
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
    }
}

ExpandedFormSearch.defaultProps = {
    input: {},
    inputVersion: 0,
    onSubmit: input => {},
}

ExpandedFormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    onSubmit: PropTypes.func,
}

export default translate()(withEvent(ExpandedFormSearch))
