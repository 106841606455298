import React from "react";
import {Button, Col, Form, Icon, Input, Modal, Row, Select, Switch} from "antd";
import apiService from "../../../../modules/ViolateReason/apiService";
import {processResponse} from "../../../../systems/api";
import lodash from "lodash";
import notification from "../../../../systems/notification";
import {translate} from "react-i18next";
import ReasonBox from "./index";

const {Option} = Select;
const {TextArea} = Input;

class ReasonForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            title: "",
            loading: false,
            canAdd: false,
            auto_approval: true,
        };
    }

    handleClick = e => {
        this.setState({visible: true});
    }

    handleOk = e => {
        this.setState({loading: true});
        let {t} = this.props;
        let res = apiService.createViolateReasons({
            type: this.props.type,
            title: this.state.title,
            active: "ACTIVE",
            auto_approval: this.state.auto_approval
        });
        let process = processResponse(res);
        process.then(res => {
            notification.success('Thêm lý do mua hàng chậm thành công');
            this.props.addReason(lodash.get(res, "data.violateReasons"));
            this.setState({visible: false, title: "", loading: false, canAdd: false});
        });

        process.catch(err => {
            notification.error(t('message.cant_fetch_data'));
            this.setState({visible: false, title: "", loading: false, canAdd: false});
        });
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            title: "",
            canAdd: false
        });
    }

    onChange = e => {
        let reason = e.target.value;

        while (reason.indexOf(" ") >= 0)
            reason = reason.replace(" ", "");

        this.setState({
            title: e.target.value,
            canAdd: reason.length > 0 ? true : false
        });
    }

    handleChangeAutoApproval = checked => {
        this.setState({ auto_approval: checked });
    }

    render() {
        let {loading, canAdd, title} = this.state;
        let {type} = this.props;
        let titleForm = type === 'OBJECTIVITY' ? 'Thêm mới lý do khách quan' : 'Thêm mới lý do chủ quan';
        return <>
            <Button
                className="a-btn--primary-ant a-font--12"
                size="small"
                style={{padding: "2px 15px 0 15px"}}
                onClick={this.handleClick}
            >
                <Icon type="plus-square" /> THÊM MỚI
            </Button>
            <Modal
                title={titleForm}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                cancelText="Hủy"
                okText="Thêm mới"
                width={420}
                okButtonProps={{loading: loading, disabled: !canAdd}}
            >
                <Form className="form-order-group">
                    <Form.Item label="Lý do" className="a-form--ant-item mb-0">
                        <TextArea
                            style={{height: "100px"}}
                            value={title}
                            placeholder="Lý do mua hàng chậm"
                            onChange={this.onChange}
                        />
                    </Form.Item>
                    <Form.Item label="Tự động duyệt" className="a-form--ant-item mb-0">
                        <Switch size="small" checked={this.state.auto_approval}  onChange={this.handleChangeAutoApproval} />
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }
}

export default translate()(ReasonForm);