import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Icon, Modal, Input, Button} from 'antd';
import {processResponse} from '../../../systems/api';
import {events} from '../../../systems/events';
import notification from '../../../systems/notification';
import TRANSACTION_EVENT from '../events';
import apiService from '../apiService';
import authService from '../../Auth/authService';
import * as PERMISSION from '../../Permission/permissions';

class ResolveTransaction extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            openModal: false,
            reason: '',
        };
    }

    openModal() {
        this.setState({openModal: true});
    }

    onResolve() {
        if (this.state.loading) {
            return;
        }

        let {transaction, t} = this.props;

        this.setState({loading: true});

        let res = apiService.resolve(transaction.id, {note_resolved: this.state.reason})
            .finally(() => this.setState({loading: false, openModal: false}));
        let process = processResponse(res);

        process.then(res => {
            notification.success(t('transaction:message.resolve_transaction_success'));

            events.dispatch(TRANSACTION_EVENT.RESOLVED, {
                transaction: {...transaction, ...(res.data || {})},
            });
            
        });

        process.catch(() => {
            notification.error(t('transaction:message.resolve_transaction_failed'));
        });
    }

    onCancel() {
        if (!this.state.loading) {
            this.setState({openModal: false});
        }
    }

    render() {
        let {t, transaction} = this.props;

        return (
            <div className={"description-trade"}>
                <Icon type="warning" theme="filled" className="a-text--red  mr-1"/>
                <span className="a-text--red a-font--12">{transaction.unusual_type ? `${t(`transaction:reasons.${transaction.unusual_type}`, {transactionRelatedAmount: transaction.transactionRelatedAmount, amount: transaction.amount})} | NV: ${t('transaction:label.system')}` : t('transaction:label.transaction_not_ok')}</span>

                {authService.can(PERMISSION.SUPPLIER_TRANSACTION_RESOLVE) && (
                    <a href="#"
                       className="a-text--green-new ml-2 a-text--decoration-underline a-font--12"
                       onClick={(event) => {
                           event.preventDefault();
                           this.openModal();
                       }}
                    >{t('transaction:btn.resolve')}</a>
                )}

                <Modal
                    width={360}
                    title={t('transaction:btn.resolve')}
                    visible={this.state.openModal}
                    onOk={this.onResolve.bind(this)}
                    onCancel={this.onCancel.bind(this)}
                    footer={false}
                    centered={true}
                    maskClosable={false}
                    className={"a-modal--list-trade"}
                >
                    <span>{t('transaction:label.resolve_reason')} <span className="a-text--red">*</span></span>
                    <Input.TextArea
                        value={this.state.reason}
                        onChange={event => this.setState({reason: event.target.value})}
                        placeholder={t('transaction:placeholder.enter_reason')}
                        rows={"3"}
                        className="mt-1"
                        autosize={{minRows: 3, maxRows: 3}}
                        disabled={this.state.loading}
                    />
                    <div className={"amt-24 d-flex justify-content-end"}>

                        <Button
                            disabled={this.state.loading}
                            className=" a-font--12 a-btn--primary-ant ghost "
                            onClick={this.onCancel.bind(this)}
                        >{t('btn.cancel')}</Button>
                        <Button
                            loading={this.state.loading}
                            className=" a-font--12 a-btn--primary-ant aphz-24 ml-3"
                            disabled={!this.state.reason}
                            onClick={this.onResolve.bind(this)}
                        >{t('transaction:btn.resolve')}</Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

ResolveTransaction.defaultProps = {
    transaction: {},
};

ResolveTransaction.propTypes = {
    transaction: PropTypes.object,
};

export default translate()(ResolveTransaction);
