import React from 'react';
import {connect} from 'react-redux';
import ViolatePolicy from './components';
import * as Selector from './../selectors';
import {fetchViolatePolicyOrders, updateReason} from "../actions";
import SearchableComponent from "../../common/SearchableComponent";
import lodash from "lodash";
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from './../../common/actions';
import {fetchAccount} from "../../User/actions";
import {fetchViolateReasons} from "../../ViolateReason/actions";

const mapStateToProps = (state) => {
    return {
        orders: Selector.getOrders(state),
        accounts: Selector.getAccountsSelector(state),
        violateReasons: Selector.getViolateReasons(state),
        status: Selector.VIOLATE_ORDER_STATUS,
        partner: lodash.get(state, "ViolatePolicyOrder.partner", {id: 0, code: "", domain: ""}),
        pagination: lodash.get(state, "ViolatePolicyOrder.pagination", {
            current_page: 1,
            page_total: 1,
            per_page: 20,
            total: 0
        }),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        fetchViolatePolicyOrders: (filter) => {
            dispatch(fetchViolatePolicyOrders(filter));
        },

        fetchAccount: (filter = {}) => {
            filter.scope = 'basic';
            dispatch(fetchAccount({...filter, pagination: 0, status: 'ACTIVE'}));
        },

        fetchViolateReasons: (filter = {}) => {
            filter.scope = 'basic';
            dispatch(fetchViolateReasons({...filter, status: 'ACTIVE'}));
        },
        updateReason: (filter)=> {
            dispatch(updateReason(filter));
        }
    };
};

class ViolatePolicyOrderContainer extends SearchableComponent {
    componentDidMount() {
        super.componentDidMount();
    }

    onChangeFilter(filter) {
        filter.status = 'OVERDUE_PURCHASE';
        filter.time_type = 'DEPOSITED';
        filter.policy = 1;
        this.props.fetchViolatePolicyOrders(filter);
    }

    onSearch(filter) {
        this.pushFilter({
            ...filter,
            _i: parseInt(filter._i || 0, 0) + 1,
        });
    }

    getCurrentFilter() {
        let filter = {
            ...this.getFilterFromLocation(this.props.location),
        };

        return filter;
    }

    render() {
        let filter = this.getCurrentFilter();

        return <ViolatePolicy {...{
            ...this.props,
            filter: filter,
            filterVersion: parseInt(filter._i, 0),
            onSearch: this.onSearch.bind(this),
        }}/>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViolatePolicyOrderContainer)