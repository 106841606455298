import lodash from 'lodash';
import {processApiRequest} from '../../common/saga';
import * as ACTION from './constants';
import apiService from '../apiService';
import {t} from '../../../systems/i18n';
import bootbox from './../../common/components/Bootbox/bootbox';
import {dispatch} from './../../../systems/store';

export function* assignComplaint(action) {
    yield processApiRequest(
        ACTION.COMPLAINT_ASSIGN,
        () => apiService.assignComplaint(action.payload.params),
        action.payload
    );
}
/* eslint-disable */
export function* onAssignComplaintSuccess(action) {
    bootbox.success(t('complaintSeller:assign.message_receive_success'));
}

export function* onAssignComplaintFailed(action) {
    let {payload} = action;
    if(!payload) {
        return;
    }
    if (payload.code === 'INPUT_INVALID' && !lodash.isEmpty(payload.data)){
        if( lodash.get(payload,'data.id_assign','') !== '' ){
            bootbox.error(t('complaintSeller:assign.message_receive_permission'));
        }
        if( lodash.get(payload,'data.status','') !== '' ){
            bootbox.error(t('complaintSeller:failed.message_status_invalid'));
        }
    }
}
