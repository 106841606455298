import { Component } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import lodash from 'lodash'

class SearchableComponent extends Component {
    constructor(props) {
        super(props)
        this.defaultFilter = {}
    }

    componentDidMount() {
        this.prepareFilter(this.props, true)
    }

    componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(nextProps.location.search) ||
            JSON.stringify(this.props.match.params) !== JSON.stringify(nextProps.match.params)
        ) {
            this.prepareFilter(nextProps)
        }
    }

    prepareFilter(props, isDidAmount = false) {
        if (isDidAmount) {
            this.onChangeFilter({
                ...this.defaultFilter,
                ...this.getFilterFromLocation(props.location),
                ...props.match.params,
            })
        } else {
            this.onChangeFilter({
                ...this.getFilterFromLocation(props.location),
                ...props.match.params,
            })
        }
    }

    getFilterFromLocation(location) {
        return queryString.parse(location.search, { arrayFormat: 'bracket' })
    }

    onChangeFilter(filter) {}

    pushFilterToHistory(filter) {
        this.props.history.push(this.props.location.pathname + '?' + queryString.stringify(filter, { arrayFormat: 'bracket' }))
    }

    pushFilter(filter) {
        filter = lodash.omitBy(filter, value => value === '')
        this.pushFilterToHistory(filter)
    }

    getFilter() {
        return {
            ...this.defaultFilter,
            ...this.getFilterFromLocation(this.props.location),
        }
    }
}

SearchableComponent.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
}

export default SearchableComponent
