import {apiWithToken} from '../../systems/api';
import {API_URL} from './../../systems/globalContant';

export default {
    fetchViolatePolicyOrders: (params) => apiWithToken.get(API_URL.FETCH_VIOLATE_POLICY_ORDERS, {
        params: params,
        singleRequest: true,
        loading: false
    }),
    updateReason: (params) => apiWithToken.put(API_URL.CHANGE_REASONS + params.id + "/change-reasons", params),
};
