import 'regenerator-runtime/runtime';
import {all, fork} from 'redux-saga/effects';
import LoginWatcher from './modules/login/sagaWatcher';
import CommonWatcher from './modules/common/sagaWatcher';
import Common from './systems/common/commonSaga'
import CustomerWatcher from './modules/User/sagaWatcher';
import orderGroupWatcher from './modules/OrderGroupManager/sagaWatcher';
import AccountPurchaserWatcher from './modules/AccountPurchaser/sagaWatcher';
import PurchaseAddressWatcher from './modules/PurchaseAddress/sagaWatcher'
import PurchaseBuyingWatcher from './modules/PurchaseBuying/sagaWatcher';
import ServicesWatcher from './modules/Services/sagaWatcher';
import LogItemWatcher from './modules/LogItem/sagaWatcher';
import OrderWatcher from './modules/Order/sagaWatcher';
import PurchaseWatcher from './modules/Purchase/sagaWatcher';
import ViolatePolicyOrderWatcher from './modules/ViolatePolicyOrder/sagaWatcher';
import ViolateReasonWatcher from './modules/ViolateReason/sagaWatcher';
import PermissionWatcher from './modules/Permission/sagaWatcher';
import ComplaintSellerWatcher from './modules/ComplaintSeller/sagaWatcher';
import OrderBillNoWatcher from './modules/OrderBillNo/sagaWatcher'
import SettingsContainer from './modules/Settings/sagaWatcher'
import WarehouseWatcher from './modules/Warehouse/sagaWatcher'
import SalesFiguresWatcher from './modules/SalesFigures/sagaWatcher';
import ComplaintProductReturnWatcher from './modules/ComplaintProductReturn/sagaWatcher';
import Transaction from './modules/Transaction/sagaWatcher';
import AgencyWatcher from './modules/Agency/sagaWatcher';
import FeaturesWatcher from './modules/Features/sagaWatcher';

export default function* start() {
    yield all([
        fork(Transaction),
        fork(PurchaseAddressWatcher),
        fork(LoginWatcher),
        fork(CommonWatcher),
        fork(Common),
        fork(CustomerWatcher),
        fork(orderGroupWatcher),
        fork(OrderWatcher),
        fork(AccountPurchaserWatcher),
        fork(PurchaseBuyingWatcher),
        fork(ServicesWatcher),
        fork(PurchaseWatcher),
        fork(LogItemWatcher),
        fork(ViolatePolicyOrderWatcher),
        fork(SalesFiguresWatcher),
        fork(ViolateReasonWatcher),
        fork(PermissionWatcher),
        fork(ComplaintSellerWatcher),
        fork(OrderBillNoWatcher),
        fork(SettingsContainer),
        fork(WarehouseWatcher),
        fork(ComplaintProductReturnWatcher),
        fork(AgencyWatcher),
        fork(FeaturesWatcher)
    ]);
}
