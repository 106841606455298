import React from 'react';
import {translate} from 'react-i18next';
import Modal from '@atlaskit/modal-dialog';
import {trans} from './../../../systems/i18n';
import withSizes from 'react-sizes';
import PropTypes from 'prop-types';

let mapSizesToProps = ({width}) => ({
    isIpad: width < 768,
    isMobile: width < 480,
});

class ModalGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {children, isIpad} = this.props;
        return (
            <React.Fragment>
                {isIpad ?
                    (<Modal>{children}</Modal>) :
                    (<Modal scrollBehavior="outside">{children}</Modal>)
                }
            </React.Fragment>
        );
    }
}

ModalGeneral.defaultProps = {
    isIpad: false,
    isMobile: false
};

ModalGeneral.propTypes = {
    isIpad: PropTypes.bool,
    isMobile: PropTypes.bool,
};

export default translate('translations')(withSizes(mapSizesToProps)(ModalGeneral));
