import React from 'react'
import { connect } from 'react-redux'
import ListTransactionOrder from './components'
import * as Selector from './selectors'
import { getState } from './../../../systems/store'
import { fetchTransactionOrders, clearTransactionOrderState, updateTransactionOrders } from './../../Order/actions'
import SearchableComponent from '../../common/SearchableComponent'
import lodash from 'lodash'
import { setPageTitle, setBreadCrumb, setPageDescription, setMenuActive } from './../../common/actions'
import { withEvent } from '../../../systems/events'
import ORDER_EVENT from '../../Order/events'
import * as SelectComplaint from '../../ComplaintSeller/selectors'
import { fetchComplaintSellerReasons } from '../../ComplaintSeller/actions'
import moment from 'moment'
import authService from '../../Auth/authService'
import { ORDER_STATUS, ORDER_TIME_TYPE } from '../../../systems/globalContant/actionContant'

const currentDay = moment().format('YYYY-MM-DD')
const monthAgo = moment().subtract(1, 'M').add(1, 'd').format('YYYY-MM-DD')

const mapStateToProps = state => {
    return {
        orders: Selector.getOrders(state) || [],
        loading: lodash.get(state, 'User.loading.detail', false) || lodash.get(state, 'Transaction.ListTransactionOrder.loading', false),
        pagination: Selector.pagination(state),
        // listStatus: Selector.listStatus(state),
        // filters: Selector.filters(state),
        // problemTypes: Selector.getProblemTypes(lodash.get(state, 'Transaction.ListTransactionOrder.problemTypes')),
        // listScanningStatuses: getState('Transaction.ListTransactionOrder.listScanningStatus', []),
        complaintSellerReasons: SelectComplaint.getComplaintSellerReason(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPageTitle: value => {
            dispatch(setPageTitle(value))
        },
        setBreadCrumb: items => {
            dispatch(setBreadCrumb(items))
        },
        setPageDescription: value => {
            dispatch(setPageDescription(value))
        },
        setMenuActive: menu => {
            dispatch(setMenuActive(menu))
        },
        fetchTransactionOrders: filter => {
            dispatch(fetchTransactionOrders(filter))
        },
        clearState: () => {
            dispatch(clearTransactionOrderState())
        },
        updateTransactionOrders: payload => {
            dispatch(updateTransactionOrders(payload))
        },
        fetchComplaintSellerReasons: () => dispatch(fetchComplaintSellerReasons({ status: 1, scope: 'basic' })),
    }
}

class ListTransactionOrderContainer extends SearchableComponent {
    constructor(props) {
        super(props)
        this.defaultFilter = {
            deposited_at_from: monthAgo,
            deposited_at_to: currentDay,
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.props.on({
            [ORDER_EVENT.RESOLVED_ORDER]: (event, payload) => this.props.updateTransactionOrders(payload),
            [ORDER_EVENT.UNRESOLVED_ORDER]: (event, payload) => this.props.updateTransactionOrders(payload),
        })
    }

    onChangeFilter(filter) {
        let editedFilter = { ...filter }
        if (!lodash.isEmpty(filter.code) || !lodash.isEmpty(filter.invoice_code)) {
            editedFilter = {
                ...editedFilter,
                deposited_at_from: undefined,
                deposited_at_to: undefined,
            }
        }
        this.props.fetchTransactionOrders(editedFilter)
    }

    onSearch(filter) {
        const editedFilter = {
            ...this.defaultFilter,
            ...filter,
            _i: parseInt(filter._i || 0, 0) + 1,
        }
        this.pushFilter(editedFilter)
    }

    getCurrentFilter() {
        return {
            ...this.defaultFilter,
            ...this.getFilterFromLocation(this.props.location),
        }
    }

    render() {
        let filter = this.getCurrentFilter()
        return (
            <ListTransactionOrder
                {...{
                    ...this.props,
                    filter: filter,
                    filterVersion: parseInt(filter._i, 0),
                    onSearch: this.onSearch.bind(this),
                }}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withEvent(ListTransactionOrderContainer))
