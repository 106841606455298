export const FETCH_COMPLAINT_PRODUCT_RETURN = {
    REQUEST: 'FETCH_COMPLAINT_PRODUCT_RETURN.REQUEST',
    SUCCESS: 'FETCH_COMPLAINT_PRODUCT_RETURN.SUCCESS',
    FAILED: 'FETCH_COMPLAINT_PRODUCT_RETURN.FAILED'
};

export const CHANGE_PRODUCT_RETURN_STATUS = {
    REQUEST: 'CHANGE_PRODUCT_RETURN_STATUS.REQUEST',
    SUCCESS: 'CHANGE_PRODUCT_RETURN_STATUS.SUCCESS',
    FAILED: 'CHANGE_PRODUCT_RETURN_STATUS.FAILED'
};

export const UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN = {
    REQUEST: 'UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.REQUEST',
    SUCCESS: 'UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.SUCCESS',
    FAILED: 'UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.FAILED'
};

export const UPDATE_COMPLAINT_PRODUCT_RETURN = {
    REQUEST: 'UPDATE_COMPLAINT_PRODUCT_RETURN.REQUEST',
    SUCCESS: 'UPDATE_COMPLAINT_PRODUCT_RETURN.SUCCESS',
    FAILED: 'UPDATE_COMPLAINT_PRODUCT_RETURN.FAILED'
};

export const UPDATE_INFO_COMPLAINT_PRODUCT_RETURN = {
    REQUEST: 'UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.REQUEST',
    SUCCESS: 'UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.SUCCESS',
    FAILED: 'UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.FAILED'
};

export const CLEAR_INFO_COMPLAINT_PRODUCT_RETURN = "CLEAR_INFO_COMPLAINT_PRODUCT_RETURN";

export const FETCH_LIST_PRODUCT_RETURNS = {
    REQUEST: 'FETCH_LIST_PRODUCT_RETURNS_REQUEST',
    SUCCESS: 'FETCH_LIST_PRODUCT_RETURNS_SUCCESS',
    FAILED: 'FETCH_LIST_PRODUCT_RETURNS_FAILED',
    ERROR: 'FETCH_LIST_PRODUCT_RETURNS_ERROR',
};

export const CLEAR_PRODUCT_RETURN = 'COMPLAINT_PRODUCT_RETURN.CLEAR_PRODUCT_RETURN';

export const CLEAR_UPDATE_COMPLAINT_PRODUCT_RETURN = 'CLEAR_UPDATE_COMPLAINT_PRODUCT_RETURN';
export const IS_BEGIN_PROCESS_CREATE_COMPLAINT_SELLER = "IS_BEGIN_PROCESS_CREATE_COMPLAINT_SELLER";