import React from 'react'
import { AutoComplete, Col, Input, Modal, notification, Row, Switch } from 'antd'
import { translate } from 'react-i18next'
import AutoCompleteCategory from '../OrderItem/ProductName/AutoCompleteCategory'
import apiService from '../../apiService'
import PropTypes from 'prop-types'
import { DATA_SOURCE_DEFAULT_UNIT_VN } from '../../constants'
import lodash from 'lodash'
import { t } from '../../../../../systems/i18n'
import SuggestProductName from '../OrderItem/ProductName/SuggestProductName'
import queryString from 'query-string'
import { processResponse } from '../../../../../systems/api'

const defaultProperty = [
    {
        id: 0,
        name: '',
        value: '',
    },
    {
        id: 1,
        name: '',
        value: '',
    },
]
const INIT_DATA = {
    translated_brand: '',
    translated_material: '',
    translated_properties: defaultProperty,
    customs_info_name: '',
    unit: '',
    customs_info_name_vn: '',
    unit_vn: '',
}

class ModalUpdateCustoms extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isVisible: false,
            data: { ...INIT_DATA },
            itemsMulti: [],
            advancedEditing: false,
            customs_info_name_manual: true,
            unit_manual: true,
        }
    }

    showModal = e => {
        e.preventDefault()
        this.setState({
            isVisible: true,
        })
    }

    onChangeInfo = (value, property, labelList = []) => {
        let { data } = this.state
        if (labelList.includes(value)) {
            if (property === 'customs_info_name_vn') {
                this.setState({
                    customs_info_name_manual: false,
                })
            }
            if (property === 'unit_vn') {
                this.setState({
                    unit_manual: false,
                })
            }
        }
        this.setState({
            data: { ...data, [property]: value },
            errorMessage: null,
        })
    }

    onChangeInfoMultiItem = (value, key, title, labelList = []) => {
        const { itemsMulti } = this.state

        const index = lodash.findIndex(itemsMulti, item => item.title === title)
        if (labelList.includes(value)) {
            if (key === 'customs_info_name_vn') {
                this.setState({
                    customs_info_name_manual: false,
                })
            }
            if (key === 'unit_vn') {
                this.setState({
                    unit_manual: false,
                })
            }
        }
        if (index !== -1) {
            const tempData = [...itemsMulti]

            const newData = lodash.map(tempData, val => {
                if (val.title === title) {
                    val[key] = value
                }
                return val
            })

            this.setState({
                itemsMulti: newData,
            })
        } else {
            const newData = [...itemsMulti]
            newData.push({
                title,
                [key]: value,
            })

            this.setState({
                itemsMulti: newData,
            })
        }
    }

    onChangeProperty = (e, id, attr) => {
        let { data } = this.state
        let translated_properties = data.translated_properties.map((prop, index) => {
            if (id === index || (id === prop.id && id !== 0)) return { ...prop, [attr]: e.target.value }
            else return prop
        })
        this.setState({
            data: { ...data, translated_properties },
            errorMessage: null,
        })
    }

    handleOKMulti = () => {
        let { data, customs_info_name_manual, unit_manual } = this.state
        let { order, order_item_checked, t } = this.props
        console.log("customs_info_name_manual", customs_info_name_manual)

        let filter_data = Object.keys(data).filter(key => (Array.isArray(data[key]) ? data[key] : data[key].trim() !== ''))

        if (filter_data.length > 0) {
            const value = []
            filter_data.forEach(key => {
                if (key === 'translated_properties') {
                    if (!lodash.isEqual(defaultProperty, data[key])) {
                        value.push({
                            name: key,
                            value: data[key],
                        })
                    }
                } else {
                    value.push({
                        name: key,
                        value: data[key],
                    })
                }
            })
            if (value.length > 0) {
                this.setState({ isLoading: true })
                let res = processResponse(
                    apiService.updateCustomsInfoMulti(order.id, {
                        name: 'data',
                        value: value,
                        id_items: order_item_checked,
                        customs_info_name_manual,
                        unit_manual,
                    })
                )
                res.then(res => {
                    this.props.updateItemsOrderDetail(res)
                    this.props.onClearCheckAll()
                    notification.success({ message: `Cập nhật thông tin hải quan các sản phẩm thành công` })
                    this.setState({
                        isVisible: false,
                        isLoading: false,
                        data: { ...INIT_DATA },
                    })
                })
                res.catch(err => {
                    const data = lodash.get(err, 'data', {})
                    if (data) {
                        const connect_error = lodash.get(data, 'connect_error', '')
                        if (!lodash.isEmpty(connect_error)) {
                            notification.error({ message: 'Cập nhật thông tin hải quan không thành công, vui lòng thử lại' })
                        } else {
                            Object.keys(data).map(key => {
                                notification.error({
                                    message: t(`validation:${Object.keys(data[key])[0].toLowerCase()}`, { attribute: t(`order:label.${key}`) }),
                                })
                            })
                        }
                    } else {
                        notification.error({ message: `Cập nhật thông tin hải quan các sản phẩm không thành côn` })
                    }
                    this.setState({ isLoading: false })
                })
            } else {
                notification.error({ message: `Nhập vào thông tin hải quan muốn cập nhật` })
            }
        }
    }

    handleOK = () => {
        let { data, itemsMulti, customs_info_name_manual, unit_manual } = this.state
        const { customs_info_name_vn, unit_vn } = data
        let { order_item_checked, items, match } = this.props

        const itemChecked = lodash.map(
            lodash.filter(items, item => order_item_checked.includes(item.id)),
            val => ({ ...val, customs_info_name_vn: '', unit_vn: '' })
        )

        // const itemWithId = itemsMulti.map(item => {
        //     itemChecked.forEach(element => {
        //         if (lodash.replace(item.title || '', /\s/g, '') === lodash.replace(element.title || '', /\s/g, '')) {
        //             item.id = element.id
        //         }
        //     })
        //     return item
        // })

        const itemWithId = itemChecked.map(element => {
            itemsMulti.forEach(item => {
                if (lodash.replace(item.title || '', /\s/g, '') === lodash.replace(element.title || '', /\s/g, '')) {
                    console.log('asdas')
                    element.customs_info_name_vn = item.customs_info_name_vn
                    element.unit_vn = item.unit_vn
                }
            })
            return {
                customs_info_name_vn: element.customs_info_name_vn || customs_info_name_vn,
                unit_vn: element.unit_vn || unit_vn,
                id: element.id,
            }
        })

        const dataPayload = itemWithId.map(item => {
            const dataNew = []

            Object.entries({ ...data, ...item }).forEach(([key, value]) => {
                dataNew.push({
                    name: key,
                    value: value,
                })
            })

            return {
                name: 'data',
                id: item.id,
                value: dataNew,
            }
        })

        if (!lodash.isEmpty(dataPayload)) {
            this.setState({ isLoading: true })

            Promise.all(dataPayload.map(item => apiService.updateCustomsInfo(item.id, {...item, customs_info_name_manual, unit_manual})))
                .then(res => {
                    this.setState({ isVisible: false })
                    const firstError = lodash.get(res, '0.data.code', {})
                    if (firstError === 'INPUT_INVALID') {
                        res.forEach(val => {
                            if (firstError === 'INPUT_INVALID') {
                                const data = lodash.get(val, 'data.data', {})
                                if (data) {
                                    const connect_error = lodash.get(data, 'connect_error', '')
                                    if (!lodash.isEmpty(connect_error)) {
                                        notification.error({ message: 'Cập nhật thông tin hải quan không thành công, vui lòng thử lại' })
                                    } else {
                                        Object.keys(data).map(key => {
                                            notification.error({
                                                message: t(`validation:${Object.keys(data[key])[0].toLowerCase()}`, { attribute: t(`order:label.${key}`) }),
                                            })
                                        })
                                    }
                                } else {
                                    notification.error({ message: `Cập nhật thông tin hải quan sản phẩm không thành công` })
                                }
                            }
                        })
                    } else {
                        notification.success({ message: `Cập nhật thông tin hải quan sản phẩm thành công` })
                        let filters = { ...queryString.parse(window.location.search), ...match.params }
                        this.props.getPurchasingOrderDetailAction(filters)
                    }
                })
                .catch(err => {})
                .finally(() => this.setState({ isLoading: false }))

            this.setState({ isLoading: false })
        } else {
            notification.error({ message: `Nhập vào thông tin hải quan muốn cập nhật` })
        }
    }

    handleCancel = () => {
        this.setState({
            isVisible: false,
            data: { ...INIT_DATA },
        })
    }

    onChangeAdvance = checked => {
        this.setState({
            advancedEditing: checked,
        })
    }

    render() {
        let { order, items, order_item_checked, classifyProduct } = this.props

        const listItemCheckTitle = lodash.map(
            lodash.filter(items, item => order_item_checked.includes(item.id)),
            val => lodash.replace(val.title || '', /\s/g, '')
        )
        const filterProducts = lodash.filter(lodash.uniqBy(classifyProduct, 'title'), val => {
            return listItemCheckTitle.includes(lodash.replace(val.title || '', /\s/g, ''))
        })

        let { isLoading, isVisible, data, advancedEditing } = this.state

        return order.canUpdateCustomsInfo ? (
            <>
                <span className={this.props.seperate ? 'seperate-v' : ''}>
                    <a
                        href=""
                        className={order_item_checked.length && items.length ? '' : 'a-disable'}
                        onClick={this.showModal}>
                        {t('orderDetail:order_detail.edit_customs')}
                    </a>
                </span>
                {isVisible && (
                    <Modal
                        title="Cập nhật thông tin hải quan nhiều sản phẩm"
                        visible={true}
                        onOk={advancedEditing ? this.handleOK : this.handleOKMulti}
                        onCancel={this.handleCancel}
                        cancelText="Hủy"
                        okText="Cập nhật"
                        okButtonProps={{ loading: isLoading }}>
                        <div className="d-flex justify-content-end">
                            <span className="mr-2">Sửa nâng cao</span>{' '}
                            <Switch
                                onChange={this.onChangeAdvance}
                                checked={advancedEditing}
                            />
                        </div>
                        <hr className="mt-1" />
                        <Row>
                            <Col span={6}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Tên tiếng Trung
                                </label>
                            </Col>
                            <Col span={18}>
                                <AutoCompleteCategory
                                    placeholder="Tên sản phẩm khai báo hải quan tiếng Trung"
                                    type="customs_info_name"
                                    onSelect={value => this.onChangeInfo(value, 'customs_info_name')}
                                    defaultValue={data.customs_info_name}
                                    width="100%"
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col span={6}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Đơn vị T.Trung
                                </label>
                            </Col>
                            <Col span={18}>
                                <AutoCompleteCategory
                                    placeholder="Đơn vị khai báo hải quan tiếng Trung"
                                    type="unit"
                                    onSelect={value => this.onChangeInfo(value, 'unit')}
                                    defaultValue={data.unit}
                                    width="100%"
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col span={6}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Tên tiếng Việt
                                </label>
                            </Col>
                            <Col span={18}>
                                <AutoCompleteCategory
                                    placeholder="Tên sản phẩm khai báo hải quan tiếng Việt"
                                    type="customs_info_name_vn"
                                    onSelect={value => this.onChangeInfo(value, 'customs_info_name_vn')}
                                    defaultValue={data.customs_info_name_vn}
                                    width="100%"
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col span={6}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Đơn vị tiếng Việt
                                </label>
                            </Col>
                            <Col span={18}>
                                <AutoComplete
                                    dataSource={DATA_SOURCE_DEFAULT_UNIT_VN}
                                    filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                    placeholder="Đơn vị khai báo hải quan tiếng Việt"
                                    onSelect={value => this.onChangeInfo(value, 'unit_vn', DATA_SOURCE_DEFAULT_UNIT_VN)}
                                    onChange={value => this.onChangeInfo(value, 'unit_vn', DATA_SOURCE_DEFAULT_UNIT_VN)}
                                    defaultValue={data.unit_vn}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                        </Row>
                        <hr />
                        {advancedEditing && (
                            <div style={{ maxHeight: 407, overflowY: 'auto', overflowX: 'hidden' }}>
                                {filterProducts.map((item, index) => {
                                    const unitList = lodash.get(item, 'units', [])
                                    const labelList = lodash.get(item, 'labels', [])

                                    return (
                                        <div
                                            key={index}
                                            className="mb-2 pb-1"
                                            style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
                                            <span className="font-weight-bold">Sản phẩm: {item.title}</span>
                                            <div style={{ paddingLeft: 24, marginTop: 12 }}>
                                                <Row>
                                                    <Col span={8}>
                                                        <label
                                                            style={{ lineHeight: '32px' }}
                                                            className="d-inline-block pr-2">
                                                            Tên tiếng Việt
                                                        </label>
                                                    </Col>
                                                    <Col span={16}>
                                                        {/* <AutoCompleteCategory
                                                        placeholder="Tên sản phẩm khai báo hải quan tiếng Việt"
                                                        type="customs_info_name_vn"
                                                        onSelect={value => this.onChangeInfo(value, 'customs_info_name_vn')}
                                                        defaultValue={data.customs_info_name_vn}
                                                        width="100%"
                                                    /> */}
                                                        <SuggestProductName
                                                            placeholder="Tên sản phẩm khai báo hải quan tiếng Việt"
                                                            type="customs_info_name_vn"
                                                            onSelect={value => this.onChangeInfoMultiItem(value, 'customs_info_name_vn', item.title, labelList)}
                                                            // defaultValue={data.customs_info_name_vn}
                                                            width="100%"
                                                            product={item}
                                                            classifyProductOne={labelList}
                                                        />
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col span={8}>
                                                        <label
                                                            style={{ lineHeight: '32px' }}
                                                            className="d-inline-block pr-2">
                                                            Đơn vị tiếng Việt
                                                        </label>
                                                    </Col>
                                                    <Col span={16}>
                                                        <AutoComplete
                                                            dataSource={!lodash.isEmpty(unitList) ? unitList : DATA_SOURCE_DEFAULT_UNIT_VN}
                                                            filterOption={(inputValue, option) =>
                                                                option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                            }
                                                            placeholder="Đơn vị khai báo hải quan tiếng Việt"
                                                            onSelect={value => this.onChangeInfoMultiItem(value, 'unit_vn', item.title, !lodash.isEmpty(unitList) ? unitList : DATA_SOURCE_DEFAULT_UNIT_VN)}
                                                            onChange={value => this.onChangeInfoMultiItem(value, 'unit_vn', item.title, !lodash.isEmpty(unitList) ? unitList : DATA_SOURCE_DEFAULT_UNIT_VN)}
                                                            // defaultValue={data.unit_vn}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}

                        {/* <hr /> */}
                        <Row>
                            <Col
                                span={6}
                                style={{ lineHeight: '32px' }}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Thương hiệu
                                </label>
                            </Col>
                            <Col span={18}>
                                <AutoCompleteCategory
                                    placeholder="Tên thương hiệu"
                                    type="brand"
                                    onSelect={value => this.onChangeInfo(value, 'translated_brand')}
                                    defaultValue={data.translated_brand}
                                    width="100%"
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col span={6}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Chất liệu
                                </label>
                            </Col>
                            <Col span={18}>
                                <AutoCompleteCategory
                                    placeholder="Tên chất liệu"
                                    type="material"
                                    onSelect={value => this.onChangeInfo(value, 'translated_material')}
                                    defaultValue={data.translated_material}
                                    width="100%"
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col span={6}>
                                <label
                                    style={{ lineHeight: '32px' }}
                                    className="d-inline-block pr-2">
                                    Thuộc tính:{' '}
                                </label>
                            </Col>
                            <Col span={18}>
                                {data.translated_properties.map((property, index) => {
                                    return (
                                        <Row
                                            key={index}
                                            gutter={10}
                                            className={index > 0 ? 'mt-3' : ''}>
                                            <Col span={12}>
                                                <Input
                                                    value={property.name}
                                                    placeholder={'Tên thuộc tính'}
                                                    onChange={value => this.onChangeProperty(value, index, 'name')}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <Input
                                                    value={property.value}
                                                    placeholder={'Giá trị thuộc tính'}
                                                    onChange={value => this.onChangeProperty(value, index, 'value')}
                                                />
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </Col>
                        </Row>
                    </Modal>
                )}
            </>
        ) : (
            ''
        )
    }
}

ModalUpdateCustoms.defaultProps = {
    order: {},
    order_item_checked: [],
    onClearCheckAll: () => {},
    seperate: true,
    items: [],
    updateItemsOrderDetail: () => {},
}

ModalUpdateCustoms.propTypes = {
    order: PropTypes.object,
    order_item_checked: PropTypes.array,
    onClearCheckAll: PropTypes.func,
    seperate: PropTypes.bool,
    items: PropTypes.array,
    updateItemsOrderDetail: PropTypes.func,
}

export default translate('translations')(ModalUpdateCustoms)
