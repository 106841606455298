import React from 'react';
import { Icon, Row, Col, Input, Table} from "antd";
import lodash from 'lodash';
import {trans} from './../../../../systems/i18n';
import {url} from "../../../../systems/routing";
import OrderProblems from "../../OrderProblems";

class OrderTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            visibleeditcode: false
        }
    }

    collapseInfoTransaction = () => {
        this.setState({info_transaction_collapse: !this.state.info_transaction_collapse});
    };

    convertData(order) {
        return <OrderProblems order={order} hideResolve/>
    }
    render() {
        let statusOrder = this.props.status;
        let {collapse, order} = this.props;
        const columns = [
            {
                title: 'Mã giao dịch',
                dataIndex: 'codetrade',
                key: 'codetrade',
                className: "a-text--nowrap",
            }, {
                title: 'Thời gian',
                dataIndex: 'time',
                key: 'time ',

            }, {
                title: 'Số tiền',
                dataIndex: 'money',
                key: 'money',
                className: "a-text--right",
            }
        ];
        const data = [];
        for (let i = 1; i < 5; i++) {
            data.push({
                codetrade: `2019042422001169611`,
                money: `22.220.000.000đ`,
                time: `10:30 - 07/07/2019`,
            });
        }
        const column2 = [
            {
                title: 'Mã khiếu nại',
                dataIndex: 'complantseller',
                key: 'complantseller',
            }, {
                title: 'Loại khiếu nại',
                dataIndex: 'typecomplantseller',
                key: 'typecomplantseller ',

            }, {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                className: "a-text--right",
            }
        ];
        const data2 = [];
        for (let i = 1; i < 3; i++) {
            data2.push({
                codetrade: `439090903458383458`,
                typecomplantseller: `Lâu quá không thấy phát hàng :)`,
                status: `Thành công`,
            });
        }

        let orderStatusClass = lodash.get({
            success: 'a-block--notify-success',
            warning: 'a-block--notify-warning',
        },statusOrder,'a-block--notify-default');


        return (
            <div className={`a-block a-block--notify-heading  a-block--order-transaction ${orderStatusClass}` }>
                <div className="a-block__top a-block__top--order-transaction">
                    <Row gutter={{lg: 24, xl: 32}}>
                        <Col md={12} lg={5} xl={5} className="a-form__group--title-order">
                            <img src={lodash.get(order, 'item.image', '')} alt=""/>
                            <div className="a-form__group pb-0">
                                <label className="a-form__group__label">Đơn hàng </label>
                                <a
                                    href={url.to('purchase.buying.detail', {id: order.id})}
                                >
                                    {lodash.get(order, 'code', '')}
                                </a>
                            </div>
                        </Col>
                        <Col md={12} lg={5} xl={5} className="a-form__group pb-0">
                            <label className="a-form__group__label">Mã hoá đơn gốc </label>
                            {
                                order.invoiceCodes.map(invoice => (
                                    <a
                                        href={lodash.get(invoice, 'link_invoice_code', '')}
                                        target={"_blank"}
                                    >
                                        {lodash.get(invoice, 'invoice_code', '')}
                                    </a>
                                ))
                            }

                        </Col>
                        <Col md={12} lg={4} xl={4} className="a-form__group pb-0">
                            <label className="a-form__group__label">Tổng thu </label>
                            <span className="a-form__group__value a-text--w-medium a-text--black">{lodash.get(order, 'grand_total_text', '')}</span>
                        </Col>
                        <Col md={12} lg={5} xl={5} className="a-form__group pb-0">
                            <label className="a-form__group__label">Lợi nhuận mặc cả</label>
                            <span className="a-form__group__value a-text--w-medium a-text--color-warning">{lodash.get(order, 'profit_text', '')}</span>
                        </Col>
                        <Col md={12} lg={5} xl={5} className="a-form__group pb-0">
                            <label className="a-form__group__label">Lợi nhuận(%)</label>
                            <span className="a-form__group__value a-text--w-medium a-text--black">{lodash.get(order, 'profit_percent_text', '')} </span>
                        </Col>
                    </Row>
                </div>
                <div className={`a-block__body a-block__body--order-transaction ${collapse ? 'hidden' : ''}`}>
                    <Row gutter={{lg: 24, xl: 32}}>
                        <Col md={12} lg={5} xl={5} className="a-form__group">
                            <label className="a-form__group__label">Đối tác</label>
                            <span className="a-form__group__value">{lodash.get(order, 'agency.name', '')}</span>
                        </Col>
                        <Col md={12} lg={5} xl={5} className="a-form__group">
                            <label className="a-form__group__label">Nguồn hàng</label>
                            <span className="a-form__group__value"><span className={`img-logo-partner _${lodash.get(order, 'seller_source', '')}`}></span>{lodash.upperFirst(lodash.get(order, 'seller_source', ''))}</span>
                        </Col>
                        <Col md={12} lg={4} xl={4} className="a-form__group">
                            <label className="a-form__group__label">Phí vận chuyển nội địa</label>
                            <span className="a-form__group__value a-text--w-medium a-text--black">{lodash.get(order, 'total_shipping_fee_inland_text', '')}</span>
                        </Col>
                        <Col md={12} lg={5} xl={5} className="a-form__group">
                            <label className="a-form__group__label">Tiền hàng thu khách</label>
                            <span className="a-form__group__value a-text--w-medium a-text--black">{lodash.get(order, 'grand_total_bought_text', '')}</span>
                        </Col>
                        <Col md={12} lg={5} xl={5} className="a-form__group">
                            <label className="a-form__group__label">Mã vận đơn</label>
                            <ul className="a-list--bill-code">
                                {
                                    (order.freightBills.length > 0) ?
                                        order.freightBills.map(bill => {
                                                return <li className="a-list--bill-code__item">
                                                    <span className="a-list--bill-code__normal">{bill.freight_bill}</span>
                                                </li>
                                            }
                                        ) : <span className="a-list--bill-code__normal">--</span>
                                }
                            </ul>
                        </Col>
                        <Col md={12} lg={5} xl={5} className="a-form__group clear-both">
                            <label className="a-form__group__label">Tổng tiền Alipay</label>
                            <span className="a-form__group__value a-text--w-medium a-text--black">{lodash.get(order, 'total_transaction_text', '')}</span>
                        </Col>
                        <Col md={12} lg={19} xl={19} className="a-form__group">
                            <label className="a-form__group__label">Địa chỉ giao</label>
                            <span
                                className="a-form__group__value">
                                {order.user_address_decode ? (
                                    <span>
                                                               {order.user_address_decode.reciver_name ? (
                                                                   <span>
                                                                   <strong>{order.user_address_decode.reciver_name}</strong>
                                                                    / {order.user_address_decode.detail}, {order.user_address_decode.district}, {order.user_address_decode.province}
                                                                       {order.user_address_decode.reciver_phone ? ' / ' + order.user_address_decode.reciver_phone : ''}
                                                                       {order.user_address_decode.note ? ' / ' + order.user_address_decode.note : ''}
                                                                  </span>
                                                               ) : (
                                                                   <strong>{order.user_address_decode.detail}</strong>
                                                               )}
                                                        </span>
                                ) : '---'}
                            </span>
                        </Col>
                        <Col md={24} lg={24} xl={24} className="a-form__group">
                            <label className="a-form__group__label">Đơn có vấn đề</label>
                            <span className="a-form__group__value">{this.convertData(order)}</span>

                        </Col>
                    </Row>
                    {/*<p className="order-transaction--title-info">Danh sách giao dịch chi </p>*/}
                    {/*<Table className={"a-table--antd a-table--antd__small"} columns={columns} dataSource={data}*/}
                    {/*       scroll={{x: 'fit-content'}}*/}
                    {/*       pagination={false}/>*/}
                    {/*<p className="order-transaction--title-info mt-5">Danh sách khiếu nại người bán </p>*/}
                    {/*<Table className={"a-table--antd a-table--antd__small"} columns={columns} dataSource={data}*/}
                    {/*       scroll={{x: 'fit-content'}}*/}
                    {/*       pagination={false}/>*/}
                </div>
            </div>
        );
    }
}

export default OrderTransaction;
