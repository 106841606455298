import React from 'react';
import {
    Button,
    Table,
    Pagination,
    Select,
    Affix,
    Menu,
    Dropdown,
    Checkbox,
    Icon,
    Modal,
    Input,
    Popconfirm,
    message
} from "antd";
import lodash from 'lodash';

const Option = Select.Option;
const TextArea = Input.TextArea;
const menu = (
    <Menu className="a-dropdown--set-field__menu">
        <div className="close-field-menu">
            <Icon type="close"/>
        </div>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Thời gian </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Hành động</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Kho đích</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Nội dung quét </Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Đơn hàng</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Mã kiện</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Loại mã</Checkbox>
        </Menu.Item>
        <Menu.Item className="a-dropdown--set-field__item">
            <Checkbox className="a-checkbox--set-field">Người quét</Checkbox>
        </Menu.Item>
    </Menu>
);

function confirm() {
    Modal.confirm({
        className: "a-modal--confirm-trade",
        title: 'UNRESOLVE',
        content: 'Bạn có chắc chắn muốn Unresolve đơn này ?',
        cancelText: 'Hủy',
        okText: 'Unresolve',
        zIndex: 1050,
        icon: <Icon type="question-circle" theme="filled"/>,
    });
}

function CustomExpandIcon(props) {
    let text;
    if (props.expanded) {
        text = '';
    } else {
        text = '';
    }
    return (
        <a
            className="expand-row-icon"
            onClick={e => props.onExpand(props.record, e)}
            dangerouslySetInnerHTML={{__html: text}}
            style={{color: 'blue', cursor: 'pointer'}}
        />
    );
}

class ListTrade extends React.Component {
    state = {
        visible: false,
        visiblemapcode: false,
        visibleeditcode: false
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    showModalMapCode = () => {
        this.setState({
            visiblemapcode: true,
        });
    };
    showModalEidtCode = () => {
        this.setState({
            visibleeditcode: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
            visiblemapcode: false,
            visibleeditcode: false
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            visiblemapcode: false,
            visibleeditcode: false
        });
    };

    render() {

        const columns = [
            {
                title: 'Tài khoản',
                dataIndex: 'username',
                key: 'username',
                render: text => <span className="a-text--nowrap ">{text}</span>,
            },

            {
                title: 'Mã giao dịch',
                dataIndex: 'codetrade',
                key: 'codetrade',
                className: "a-text--nowrap",
            }, {
                title: 'Số tiền GD',
                dataIndex: 'moneytrade',
                key: 'moneytrade',
                render: text => <span className="a-text--nowrap">{text}</span>,
            }, {
                title: 'Thời gian giao dịch',
                dataIndex: 'timetrade',
                key: 'timetrade',
                render: text => <span className="a-text--nowrap">{text}</span>,

            }, {
                title: <div className={"d-flex justify-content-between"}><span className={"w-100 "}>Mã đơn hàng</span>
                    <span
                        className={"w-100 "}>Mã đơn gốc</span>
                </div>,
                dataIndex: 'codeorder',
                key: 'codeorder ',
                render: text => <span className="a-text--nowrap">{text}</span>,

            }, {
                title: 'Loại giao dịch',
                dataIndex: 'typetrade',
                key: 'typetrade',
                render: text => <span className="a-text--nowrap">{text}</span>,

            },
            {
                title: 'Nhà cung cấp',
                dataIndex: 'vendor',
                key: 'vendor',
                render: text => <span className="a-text--nowrap">{text}</span>,

            },
            {
                title: 'Mã GD NCC',
                dataIndex: 'codevendor',
                key: 'codevendor',
                render: text => <span className="a-text--nowrap">{text}</span>,

            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                render: text => <a href="#"
                                   className="a-text--nowrap a-text--green-new action-row-show-hover">{text}</a>,

            }];
        const data = [];
        for (let i = 1; i < 20; i++) {
            data.push({
                username: `shuranka9x`,
                codetrade: `2019042422001169611029533499_421335681258611931`,
                moneytrade: `¥22.22`,
                timetrade: `10:30 - 07/07/2019`,
                codeorder: (i < 10 ?
                    <div>
                        {i < 5 ?
                            <div className="d-flex">
                                <div className="pw-100 mr-4">
                                    <p>KO_11112019</p>
                                    <p>KO_111120190504</p>
                                </div>
                                <div className=" ml-4">
                                    <div className="a-group--icon-edit mb-2">
                                        <span className="info">HNSG_11112019</span>
                                        <a href="javascript:;" className="icon-edit"
                                           onClick={this.showModalEidtCode}><Icon
                                            type="edit"/>
                                        </a>
                                    </div>
                                    <div className="a-group--icon-edit mb-2">
                                        <span className="info">HNSG_111120191105</span>
                                        <a href="javascript:;" className="icon-edit"
                                           onClick={this.showModalEidtCode}><Icon
                                            type="edit"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={"d-flex"}>
                                <div className="w-100 mr-4">
                                </div>
                                <div className=" ml-4">
                                    <div className="a-group--icon-edit mb-2">
                                        <span className="info">HNSG_111120191105</span>
                                        <a href="javascript:;" className="icon-edit"
                                           onClick={this.showModalEidtCode}><Icon
                                            type="edit"/>
                                        </a>
                                    </div>
                                    <div className="a-group--icon-edit mb-2">
                                        <span className="info">HNSG_111120191105</span>
                                        <a href="javascript:;" className="icon-edit"
                                           onClick={this.showModalEidtCode}><Icon
                                            type="edit"/>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        }
                    </div> :
                    <Input placeholder={"Nhập mã đơn gốc"}/>),
                typetrade: (i <= 5 ? 'Thu' : ' Chi'),
                vendor: 'shoptretho',
                codevendor: 'T200P421335681255611931',
                action: `Xoá`,
                description: (i >= 10 ? <div className={"description-trade"}><Icon type="warning" theme="filled"
                                                                                   className="a-text--red  mr-1"/>
                    <span className="a-text--red a-font--12">Giao dịch này có tài chính bất thường</span>
                    <a href="javascript:;" className="a-text--green-new ml-2 a-text--decoration-underline a-font--12"
                       onClick={this.showModal}>Resolve</a>
                </div> : <div className={"description-trade"}>
                    <span className="a-font--12 a-text--gray-new a-text--bold mr-2 "><Icon type="check-circle"
                                                                                           theme="filled"
                                                                                           className="a-text--success  mr-1"/>Resolved</span>
                    <span className=" a-font--12 a-text--gray-new">Lý do: Giao dịch này đã được resolve | Nv: Nguyễn Bỉnh Khiêm | 10:30 - 24/7/2019</span>
                    <a href="javascript:;" className="a-text--green-new ml-2 a-text--decoration-underline a-font--12"
                       onClick={confirm}>Unresolve</a></div>)

            });
        }
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            columnWidth: '20px',
        };
        return (
            <div className="a-list mt-5">
                <Affix>
                    <div className="a-list__top a-flex">
                        <h2 className="a-list--title a-text--uppercase">Danh sách đơn (200)</h2>
                        <div className="ml-auto">
                            <Button className=" a-font--12 aphz-50 a-btn--primary-ant mr-4" size={"small"}
                                    disabled>Resolve</Button>
                            <Button className=" a-font--12 aphz-50 a-btn--primary-ant ghost mr-4" size={"small"}>Xuất
                                CSV</Button>
                            <Dropdown overlay={menu} trigger={['click']} overlayClassName={'a-dropdown--set-field'}>
                                <Button className=" a-font--12 aphz-50 a-btn--primary-ant ghost " size={"small"}>Thiết
                                    lập cột dữ liệu</Button>
                            </Dropdown>
                        </div>
                    </div>
                </Affix>
                <div className="a-content--is-pagination-fixed">
                    <Table className={"a-table--antd a-table--description"} columns={columns} dataSource={data}
                           expandedRowRender={record => record.description}
                           defaultExpandAllRows={true}
                           expandIcon={CustomExpandIcon}
                           expandIconAsCell={false}
                           rowSelection={rowSelection}
                           scroll={{x: 'fit-content'}}
                           pagination={false}/>
                    <div className="a-pagination a-pagination--fixed ">
                        <Pagination defaultCurrent={5} total={200} className="a-pagination--detail"/>
                        <div className="a-pagination--select-number-item">
                            <span className="text-show mr-2">Hiển thị</span>
                            <Select defaultValue="10" className="a-select--select-number-show"
                                    getPopupContainer={trigger => trigger.parentNode}
                            >
                                <Option value="10">10</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                            </Select>
                        </div>
                    </div>
                </div>

                <Modal
                    width={360}
                    title="RESOLVE"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={false}
                    centered={true}
                    className={"a-modal--list-trade"}
                >
                    <span>Lý do</span>
                    <TextArea placeholder="Nhập lý do" rows={"3"} className="mt-1" autosize={{minRows: 3, maxRows: 3}}/>
                    <div className={"amt-24"}>
                        <Button className=" a-font--12 a-btn--primary-ant aphz-24">Resolve</Button>
                        <Button className=" a-font--12 a-btn--primary-ant ghost ml-3">Huỷ</Button>
                    </div>
                </Modal>
                <Modal
                    width={360}
                    title="MAP MÃ ĐƠN GỐC"
                    visible={this.state.visiblemapcode}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={false}
                    centered={true}
                    className={"a-modal--list-trade"}
                >
                    <span>Nhập mã đơn gốc</span>
                    <Input placeholder="Nhập mã đơn" className="mt-1"/>
                    <div className={"amt-24"}>
                        <Button className=" a-font--12 a-btn--primary-ant aphz-24">Thêm</Button>
                        <Button className=" a-font--12 a-btn--primary-ant ghost ml-3">Huỷ</Button>
                    </div>
                </Modal>
                <Modal
                    width={360}
                    title=" MÃ ĐƠN GỐC"
                    visible={this.state.visibleeditcode}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={false}
                    centered={true}
                    className={"a-modal--list-trade"}
                >
                    <p>Mã đơn gốc </p>
                    <ul className="mb-5 mt-3">
                        <li className="d-flex justify-content-between mb-2">
                            <span className={"a-text--bold"}>A183949459</span>
                            <Popconfirm
                                title="Bạn có chắc chắn muốn xoá mã đơn gốc"
                                okText="Đồng ý"
                                cancelText="Huỷ bỏ"
                            >
                                <a href="javascript:;" className="a-text--green-new">Xoá mã</a>
                            </Popconfirm>
                        </li>
                        <li className="d-flex justify-content-between mb-2">
                            <span className={"a-text--bold"}>A183949459</span>
                            <Popconfirm
                                title="Bạn có chắc chắn muốn xoá mã đơn gốc"
                                okText="Đồng ý"
                                cancelText="Huỷ bỏ"
                            >
                                <a href="javascript:;" className="a-text--green-new">Xoá mã</a>
                            </Popconfirm>
                        </li>
                    </ul>
                    <span className="mt-3">Thêm mã đơn gốc</span>
                    <Input placeholder="Nhập mã đơn gốc" className="mt-1"/>
                    <div className={"amt-24"}>
                        <Button className=" a-font--12 a-btn--primary-ant aphz-24">Thêm</Button>
                        <Button className=" a-font--12 a-btn--primary-ant ghost ml-3">Huỷ</Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default ListTrade;