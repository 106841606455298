import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { trans } from '../../../systems/i18n'
import { VIOLATE_REASON_TYPE } from '../../../systems/globalContant/actionContant'
import ModalGeneral from './../../common/components/ModalGeneral'
import lodash from 'lodash'
import { Button, Select } from 'antd'
import PropTypes from 'prop-types'

class SelectViolateReason extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reasons: {},
            reasonsDataLength: 0
        }
    }

    onCancel() {
        this.props.onCancel()
    }

    onSubmit() {
        let { reasons } = this.state
        const orderCode = this.props.data.order
        if (reasons) {
            this.props.onSubmit({ confirm: 1, reasons, orderCode })
        }
    }

    onChangeReason(order, policyType, reasonId) {
        const { reasons } = this.state
        const reasonsByOrder = lodash.get(reasons, order, [])

        const reasonsData = { ...reasons, [order]: { ...reasonsByOrder, [policyType]: reasonId } };

        let reasonsDataLength = 0
        Object.values(reasonsData).map(item => (reasonsDataLength += Object.values(item).length));

        this.setState({ reasons: reasonsData, reasonsDataLength})
    }

    render() {
        const { t, visibled, violateReasons, violated} = this.props
        const { reasons, reasonsDataLength} = this.state

        let violatedDataLength = 0
        Object.values(violated).map(item => (violatedDataLength += Object.values(item).length))

        return (
            <>
                {visibled && (
                    <ModalGeneral scrollBehavior="outside">
                        <div className="a-modal__title">{t('violate:select_reason.title')}</div>
                        <div className={'a-modal--violate__body'}>
                            {!lodash.isEmpty(violated) &&
                                Object.keys(violated).map(order => {
                                    const violatePoliciesOfOrder = Object.values(violated[order])
                                    return violatePoliciesOfOrder.map((violatedPolicy, index) => {
                                        const reasonId = lodash.get(reasons, [order, violatedPolicy.policy_type])
                                        return (
                                            <div
                                                className="mt-4 mb-4"
                                                key={`${order}_${index}`}>
                                                <div className="mb-1 a-text--fz-14">
                                                    {trans('violate:select_reason.violated_order', {
                                                        policy: <b>{violatedPolicy.policy_name}</b>,
                                                        order: <b>{order}</b>,
                                                    })}
                                                </div>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    value={reasonId ? parseInt(reasonId) : undefined}
                                                    noOptionsMessage={() => t('system.no_option')}
                                                    className="single-react"
                                                    classNamePrefix="react-select--violate_modal"
                                                    placeholder={t('violate:select_reason.select_reason')}
                                                    allowClear={false}
                                                    onChange={value => this.onChangeReason(order, violatedPolicy.policy_type, value)}>
                                                    {lodash.keys(VIOLATE_REASON_TYPE).map(type => (
                                                        <Select.OptGroup key={type} label={t('violate:list.' + type)}>
                                                            {violateReasons
                                                                .filter(reason => reason.type === type)
                                                                .map(reason => (
                                                                    <Select.Option
                                                                        value={reason.id}
                                                                        key={reason.id}>
                                                                        {reason.title}
                                                                    </Select.Option>
                                                                ))}
                                                        </Select.OptGroup>
                                                    ))}
                                                </Select>
                                            </div>
                                        )
                                    })
                                })}
                        </div>
                        <div className="text-right mt-3 mb-3">
                            <Button
                                htmlType="submit"
                                disabled={reasonsDataLength === 0 || reasonsDataLength !== violatedDataLength}
                                className="a-text--uppercase a-btn--primary-ant aphz-24 mr-3 _create-payment-request-button-submit"
                                onClick={this.onSubmit.bind(this)}
                            >
                                {t('btn.continue')}
                            </Button>
                            <Button
                                className="a-text--uppercase a-btn--primary-ant aphz-24 mr-3 ghost _create-payment-request-button-cancel"
                                onClick={this.onCancel.bind(this)}>
                                {t('btn.cancel')}
                            </Button>
                        </div>
                    </ModalGeneral>
                )}
            </>
        )
    }
}

SelectViolateReason.defaultProps = {
    visibled: false,
    data: {},
    onSubmit: reasonId => {},
    onCancel: () => {},
}

SelectViolateReason.propTypes = {
    visibled: PropTypes.bool,
    data: PropTypes.object,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
}

export default translate()(SelectViolateReason)
