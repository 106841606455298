import React from 'react';
import PropTypes from 'prop-types';
import Button from '@atlaskit/button';
import lodash from 'lodash';
import Form, {FormSection, FormFooter} from '@atlaskit/form';
import {translate} from 'react-i18next';
import {ToggleStateless} from '@atlaskit/toggle';
import {Col} from 'react-grid-system';
import {router, url} from '../../../systems/routing';
import FormProcessing from '../../../modules/common/components/FormProcessing';
import { Switch } from 'antd';

/**
 * Dữ liệu mặc định của form thêm mới
 * @type {{input: {id: string, address: string, status: number, default: number}}}
 */

const defaultState = {
    input: {
        id: '',
        address: '',
        alias: '',
        status: 1,
        default: 0,
    },
    inputVersion: null,
    changedParams: [],
};

class PurchaseAddressForm extends FormProcessing {

    constructor(props) {
        super(props);
        this.state = defaultState;
    }

    /**
     * Cập nhật dữ liệu thay đổi của input
     * @param event
     */
    onHandleChange(event) {
        let input = this.state.input;
        let target = event.target;
        let value = target.value;
        let name = target.name;
        if (name === 'status') {
            value = input.status ? 0 : 1;
        }
        if (name === 'default') {
            value = input.default ? 0 : 1;
        }
        if (name === 'alias') {
            value = value.trim();
            value = value.replace(/\s/g, '');
        }
        this.changeInput({[name]: value});
        this.props.resetErrorPurchaseAddress({[name]: value});
    }

    /**
     * Cập nhật dữ liệu thay đổi của Switch
     * @param status
     * @param name
     */
    onHandleChangeSwitch(name, status) {
        let value = status ? 1 : 0;
        this.changeInput({[name]: value});
        this.props.resetErrorPurchaseAddress({[name]: value});
    }

    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        this.setState(state => ({input: {...state.input, ...input}}));
    }

    /**
     * Gọi khi submit form thêm mới, sửa dữ liệu
     * @param e
     */
    onSubmitHandler(e) {
        e.preventDefault();
        const {createPurchaseAddress, updatePurchaseAddress} = this.props;

        let input = this.state.input;
        if (lodash.get(input, 'id', null)) {
            updatePurchaseAddress(input.id, input);
        } else {
            createPurchaseAddress(input)
        }
    };

    /**
     * Thực hiện reset form
     * @param event
     */
    onResetHandler(event) {
        event.preventDefault();
        this.updateFormData(defaultState.input);
        this.props.clearPurchaseAddressDetail({});
        router.redirect(url.to('purchase-address'));
    }

    /**
     * Render dữ liệu tên nhóm ngành
     * @param props
     * @returns {XML}
     * @constructor
     */
    Description(props) {
        return (
            <div name={props.id} onClick={()=>{
                router.redirect(url.to('orderGroup.manager')+'?id='+props.id);
                props.onFetchDetailOrderGroup(props);
            }} className="description-order-group">
                {props.address}
            </div>)
    };

    /**
     * Thực hiện render lỗi thông báo ra bên ngoài
     * @param attrName
     * @returns {null}
     */
    renderError(attrName) {
        let {createErrors, t} = this.props;
        let errorItem = lodash.get(createErrors, attrName, {});

        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('purchase_address.error.' + Object.keys(errorItem)[0], {name: t('purchase_address.' + attrName)});
                case 'unique':
                    return t('purchase_address.error.' + Object.keys(errorItem)[0], {name: t('purchase_address.' + attrName)});
                case 'status_invalid':
                    return t('purchase_address.error.' + Object.keys(errorItem)[0], {name: t('order_group.' + attrName)});
                case 'CODE_USED':
                    return t('purchase_address.error.' + Object.keys(errorItem)[0], {name: t('purchase_address.' + attrName)});
                case 'max.string':
                    let objectmax = lodash.get(errorItem, 'max.string', null);
                    let overLength = lodash.get(objectmax, 'max', null);
                    return t('purchase_address.error.max_string', {
                        name: t('purchase_address.' + attrName),
                        value: overLength
                    });
                default:
                    return null;
            }
        }
    }

    render() {
        const {t, onRef, permission} = this.props;
        let {input} = this.state;
        return (
            <Col xs={12} sm={12} md={12} lg={4} xl={4} className="column-right">
              <div className="a-content--box-shadow">
                <Form name="order-group"
                      onSubmit={this.onSubmitHandler.bind(this)}
                      onReset={this.onResetHandler.bind(this)}
                >
                    <FormSection>
                        <div className='a-group'>
                            <label className="label label--required">
                                {t("purchase_address.address")}
                            </label>
                            <input type="text"
                                   name="address"
                                   autoComplete='off'
                                   placeholder={t("purchase_address.address_placeholder")}
                                   onChange={this.onHandleChange.bind(this)}
                                   value={input.address|| ""}
                                   className={"mt-2 input input--normal"}
                                   ref={(input) => {
                                       if (onRef) {
                                           onRef(input)
                                       }
                                   }}
                            />
                            <div className={"validatorMessage a--error "
                            +( this.renderError("address")? 'active-error':'inactive-error' )}>
                                {this.renderError("address")}
                            </div>
                        </div>

                        <div className='a-group'>
                            <label className="label label--required">
                                {t("purchase_address.alias")}
                            </label>
                            <input type="text"
                                   name="alias"
                                   disabled={!permission || input.id}
                                   autoComplete='off'
                                   placeholder={t("purchase_address.address_code_placeholder")}
                                   onChange={this.onHandleChange.bind(this)}
                                   value={input.alias|| ""}
                                   className={"mt-2 input input--normal"}
                            />
                            <div className={"validatorMessage a--error "
                            +( this.renderError("alias")? 'active-error':'inactive-error' )}>
                                {this.renderError("alias")}
                            </div>
                        </div>

                        <div className={'a-group '+(this.props.input.default ? "hidden":"")}>
                            <label className="label">
                                {t("order_group.status")}
                            </label>
                            <div className="order-group-status">
                                <Switch
                                    checked={input.status}
                                    onChange={this.onHandleChangeSwitch.bind(this, 'status')}
                                    className="gtoogle ml-1"
                                />
                            </div>
                            <div className={"validatorMessage a--error "
                            +( this.renderError("status")? 'active-error':'inactive-error' )}>
                                {this.renderError("status")}
                            </div>
                        </div>

                        <div className={'a-group '+(this.props.input.default ? "hidden":"")}>
                            <label className="label">
                                {t("purchase_address.default")}
                            </label>
                            <div className="order-group-status">
                                <Switch
                                    checked={input.default}
                                    name="default"
                                    onChange={this.onHandleChangeSwitch.bind(this, 'default')}
                                    className="gtoogle ml-1"
                                />
                            </div>
                            <div className={"validatorMessage a--error "
                            +( this.renderError("default")? 'active-error':'inactive-error' )}>
                                {this.renderError("default")}
                            </div>
                        </div>

                    </FormSection>
                    <FormFooter>
                        {permission &&
                        <div>
                            <Button
                                appearance="primary"
                                type="submit">
                                {t("btn.save")}
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                appearance="subtle"
                                onClick={this.onResetHandler.bind(this)}
                            >
                                {t("btn.cancel")}
                            </Button>
                        </div>
                        }
                    </FormFooter>
                </Form>
              </div>
            </Col>
        );
    }
}

PurchaseAddressForm.propTypes = {
    onRef: PropTypes.func
};

export default translate('translations')(PurchaseAddressForm);
