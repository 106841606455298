import { Button, Form, Icon, Modal, Row, Table, Tooltip, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import InputNumber from '../../../common/components/Input/InputNumber'
import apiService from '../apiService'
import { every, get, isEmpty } from 'lodash'
import notification from '../../../../systems/notification'
import { processResponse } from '../../../../systems/api'
import { dispatch } from '../../../../systems/store'
import { getPurchasingOrderDetailAction } from '../actions'

function EstimatedBatchOrderInfo(props) {
    const { form, dataOrder, isOpened } = props
    const { getFieldDecorator } = form
    const [visible, setVisible] = useState(isOpened || false)
    const [estimatePackages, setEstimatePackages] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getDetailInfo()
    }, [dataOrder])

    const getDetailInfo = () => {
        const batchOrder = get(dataOrder, 'batchOrder', {})
        const isBatchOrder = get(dataOrder, 'orderDetail.is_batch_order', false)
        if (isBatchOrder) {
            if (isEmpty(batchOrder)) {
                form.resetFields()
            } else {
                const estimatePackages = get(batchOrder, 'estimate_packages', [])
                form.setFieldsValue({
                    estimate_freight_bill_quantity: get(batchOrder, 'estimate_freight_bill_quantity'),
                    estimate_weight_order: get(batchOrder, 'estimate_weight_order'),
                    estimate_volume: get(batchOrder, 'estimate_volume'),
                })
                setEstimatePackages(!isEmpty(estimatePackages) ? estimatePackages : [])
            }
        }
    }

    const handleCancel = (cancel = true) => {
        if (cancel) {
            setVisible(false)
        }
        form.resetFields()
        setLoading(false)
        setEstimatePackages([])
        getDetailInfo()
    }

    const handleOk = () => {
        form.validateFields((err, values) => {
            const estimatePackages = get(values, 'estimate_packages', [])
            const newEstimatePackages = []
            if (!isEmpty(estimatePackages)) {
                estimatePackages.map(estimate_package => {
                    const result = every(Object.values(estimate_package), value => value === '' || value === undefined)
                    if (!result) {
                        newEstimatePackages.push(estimate_package)
                    }
                })
            }
            setLoading(true)
            const orderId = get(dataOrder, 'id')
            processResponse(
                apiService.updateBatchOrder(orderId, { ...values, estimate_packages: isEmpty(newEstimatePackages) ? undefined : newEstimatePackages })
            )
                .then(res => {
                    dispatch(getPurchasingOrderDetailAction({ id: orderId }))
                    notification.success('Cập nhật thông tin dự kiến hàng lô thành công')
                    handleCancel()
                })
                .catch(err => {
                    notification.error('Cập nhật thông tin dự kiến hàng lô thất bại')
                })
                .finally(() => setLoading(false))
        })
    }

    const columns = [
        {
            title: 'Số lượng kiện',
            dataIndex: 'quantity',
            render: (text, record, index) => (
                <Form.Item className="mb-0">
                    {getFieldDecorator(`estimate_packages.${index}.quantity`, { initialValue: text })(
                        <InputNumber
                            precision={0}
                            min={0}
                            placeholder="Nhập vào số lượng kiện"
                        />
                    )}
                </Form.Item>
            ),
        },
        {
            title: 'Chiều dài (cm)',
            dataIndex: 'length',
            render: (text, record, index) => (
                <Form.Item className="mb-0">
                    {getFieldDecorator(`estimate_packages.${index}.length`, { initialValue: text })(
                        <InputNumber
                            precision={2}
                            min={0}
                            placeholder="Nhập vào chiều dài (cm)"
                        />
                    )}
                </Form.Item>
            ),
        },
        {
            title: 'Chiều rộng (cm)',
            dataIndex: 'width',
            render: (text, record, index) => (
                <Form.Item className="mb-0">
                    {getFieldDecorator(`estimate_packages.${index}.width`, { initialValue: text })(
                        <InputNumber
                            precision={2}
                            min={0}
                            placeholder="Nhập vào chiều rộng (cm)"
                        />
                    )}
                </Form.Item>
            ),
        },
        {
            title: 'Chiều cao (cm)',
            dataIndex: 'height',
            render: (text, record, index) => (
                <Form.Item className="mb-0">
                    {getFieldDecorator(`estimate_packages.${index}.height`, { initialValue: text })(
                        <InputNumber
                            precision={2}
                            min={0}
                            placeholder="Nhập vào chiều cao (cm)"
                        />
                    )}
                </Form.Item>
            ),
        },
        {
            title: 'Cân nặng (kg)',
            dataIndex: 'weight',
            render: (text, record, index) => (
                <Form.Item className="mb-0">
                    {getFieldDecorator(`estimate_packages.${index}.weight`, { initialValue: text })(
                        <InputNumber
                            precision={2}
                            min={0}
                            placeholder="Nhập vào cân nặng (kg)"
                        />
                    )}
                </Form.Item>
            ),
        },
        {
            title: '',
            dataIndex: '',
            render: (text, record, index) => (
                <Tooltip title="Xóa hàng">
                    <Icon
                        type="delete"
                        onClick={() => handleRemoveRow(index)}
                    />
                </Tooltip>
            ),
        },
    ]

    const handleRemoveRow = index => {
        const data = [...estimatePackages]
        data.splice(index, 1)
        setEstimatePackages(data)
    }

    const handleAddRow = () => {
        setEstimatePackages([...estimatePackages, { quatity: '', weight: '', height: '', length: '', width: '' }])
    }

    return (
        <div className="ml-2">
            <Icon
                type="form"
                onClick={() => setVisible(true)}
            />
            <Modal
                title="Thông tin dự kiến hàng lô"
                visible={visible}
                width={'65%'}
                closable={true}
                onCancel={handleCancel}
                footer={[
                    <Button onClick={() => handleCancel(false)}>Nhập lại</Button>,
                    <Button onClick={handleCancel}>Hủy</Button>,
                    <Button
                        onClick={handleOk}
                        type="primary">
                        Lưu
                    </Button>,
                ]}>
                <Form layout="vertical">
                    <Row gutter={10}>
                        <Col span={8}>
                            <Form.Item label="Số lượng mã vận đơn">
                                {getFieldDecorator('estimate_freight_bill_quantity')(
                                    <InputNumber
                                        precision={0}
                                        min={0}
                                        placeholder="Nhập vào số lượng mã vận đơn"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Tổng trọng lượng dự kiến (kg)">
                                {getFieldDecorator('estimate_weight_order')(
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Nhập vào tổng trọng lượng dự kiến (kg)"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Tổng thể tích dự kiến (cm3)">
                                {getFieldDecorator('estimate_volume')(
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Nhập vào tổng thể tích dự kiến (cm3)"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Table
                        dataSource={estimatePackages}
                        columns={columns}
                        pagination={false}
                        locale={{
                            emptyText: <div></div>,
                        }}
                    />
                    <Button
                        onClick={handleAddRow}
                        type="primary">
                        Thêm mới
                    </Button>
                </Form>
            </Modal>
        </div>
    )
}
export default Form.create()(EstimatedBatchOrderInfo)
