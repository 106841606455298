import {translate} from 'react-i18next';
import ORDER from "../events";
import {Button} from "antd";
import React from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import apiService from "../apiService";
import {processResponse} from "../../../systems/api";
import {events} from "../../../systems/events";
import notification from '../../../systems/notification';

class ExportOrdersData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errors: {}
        };
    }

    getErrors(errors) {
        let {t} = this.props;
        lodash.mapValues(errors.data, (rules, param) => {
            rules = lodash.mapKeys(rules, function(value, key) {
                key = key === 'GREATER' ? 'greater_than_1_month' : key;
                return lodash.toLower(key);
            });
            let attribute = t('order:label.' + param);
            let messages = lodash.mapValues(rules, (data, rule) => {
                let messageKey = 'order:error.' + rule;
                return t(messageKey, {...data, attribute});
            });
            this.setState({
                errors: {
                    ...this.state.errors,
                    [param]: lodash.first(lodash.values(messages))
                }
            })
        });
    };
    
    exportOrdersData() {
        if (this.state.loading) {
            return;
        }

        this.setState({loading: true});

        events.dispatch(ORDER.EXPORT_DATA_REQUEST, this.state.loading);

        let {input, t, exportActualAmount} = this.props;
        input = lodash.omitBy(input, value => value === '');
        input = {
            ...input,
            time_type: 'BOUGHT',
        };
        let res = exportActualAmount ?
            apiService.exportActualAmount(input)
            .finally(() => this.setState({loading: false})) :
            apiService.exportOrder(input)
            .finally(() => this.setState({loading: false}));
        let process = processResponse(res);
        process.then(() => {
            notification.success(t('order:message.export_data_success'));
            events.dispatch(ORDER.EXPORT_DATA_SUCCESS);
        });
        process.catch(res => {
            this.setState({ errors: {} });
            this.getErrors(res);
            events.dispatch(ORDER.EXPORT_DATA_FAILED, this.state.errors);
        });
    }
    render() {
        let {t} = this.props;

        return (
            <Button
                htmlType="submit" 
                loading={this.state.loading} 
                onClick={this.exportOrdersData.bind(this)}
                className="a-text--uppercase a-btn--primary-ant aphz-24 mr-3">
                {this.props.label}
            </Button>
        )
    }
}
ExportOrdersData.defaultProps = {
    input: {},
    exportActualAmount: false
};

ExportOrdersData.propTypes = {
    input: PropTypes.object,
    exportActualAmount: PropTypes.bool
};

export default translate()(ExportOrdersData)
