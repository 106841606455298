import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Select, Icon} from "antd";
import {orderService} from '../../../../../modules/Order/services';
import {hasPermission, permissions} from "../../../../../modules/Permission";
import {dateFormatter} from "../../../../../modules/common/services/format";
import lodash from 'lodash';

const {Option} = Select;

class OrderPurchaser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDistributingOrder: false
        }
    }

    onUnassignPurchaser = event => {
        event.preventDefault();

        let {order} = this.props;
        this.props.onUnassignPurchaser(order.id);
    }

    onAssignPurchaser({value}) {
        if (!this.props.distributing) {
            this.props.onAssignPurchaser(value);
        }
    }

    render() {
        let {t, order, distributing} = this.props;
        const isSelfNegotiateOrder = lodash.get(order, "order_detail.self_negotiate", false)
        if ( hasPermission(permissions.ORDER_DISTRIBUTE) && (orderService.canAssign(order) || (isSelfNegotiateOrder && hasPermission(permissions.SELF_NEGOTIATE_ASSIGN_ORDER) && orderService.canAssignForce(order))) && order.teller === null) {
            return <Select
                    showSearch
                    optionFilterProp={"children"}
                    placeholder={t('order:distribute_order.select_purchaser')}
                    onChange={value => this.props.onAssignPurchaser(order.id, value)}
                    className="a-text--fz-12 select_teller_ddl"
                    style={{width: "230px", height: "24px", position: "relative", marginTop: "-3px"}}
                    loading={distributing === order.id ? true : false}
                >
                    {this.props.tellers.map(item => (
                        <Option
                            className="a-text--fz-12"
                            key={item.id}
                            value={item.id}
                        >
                            {item.username+" - "+item.name}
                        </Option>
                    ))}
                </Select>;
        }

        return (
            <div className="text-muted d-flex align-items-center a-item__bottom__select_purchase">
                { distributing === order.id && <Icon type="sync" spin /> }
                <span>
                    {order.teller_name || '---'} / @{order.teller_username || '---'} {dateFormatter.full(order.time_teller)}
                </span>

                { hasPermission(permissions.ORDER_DISTRIBUTE) && (orderService.canUnassign(order) || (isSelfNegotiateOrder && hasPermission(permissions.SELF_NEGOTIATE_ASSIGN_ORDER) && orderService.canUnassignForce(order) )) ? (
                    <div className={'a-item__bottom__cancel_purchase'}>
                        <i className="ml-1 mr-1 hidden-md">-</i>
                        <a className="a-text--danger a-text--pointer" onClick={this.onUnassignPurchaser}>{t('order:distribute_order.btn_unassign')}</a>
                    </div>
                ) : null}
            </div>
        );
    }
}

OrderPurchaser.defaultProps = {
    purchasers: [],
    order: {},
    distributing: 0,
    onAssignPurchaser: (purchaserId) => {},
    onUnassignPurchaser: () => {},
};

OrderPurchaser.propTypes = {
    order: PropTypes.object.isRequired,
    distributing: PropTypes.number,
    onAssign: PropTypes.func,
    onUnassignPurchaser: PropTypes.func,
};

export default translate()(OrderPurchaser);
