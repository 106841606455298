import {connect} from 'react-redux';
import * as Selector from './selectors';
import * as SelectorServices from './../../Services/selectors';
import PurchasingOrderDetail from './components/index';
import * as SelectorFeatures from './../../Features/selector';
import {fetchFeatures} from "../../Features/actions";

import {
    getPurchasingOrderDetailAction,
    updatePaidOrderDetail,
    updateOrderGroupOrder,
    updateShippingFee,
    postInvoiceCode, beginProcessPostInvoiceCode,
    deleteInvoiceCode, beginProcessDeleteInvoiceCode,
    paymentOrder, updateOrderAccountPurchaser,
    cancelPaymentOrder,
    updatePaidOrderStatus,
    changeMutipleQuantity,
    clearPurchaserBuyingChangeDataState,
    confirmCustomerOrderBuying,
    clearErrorWhileUpdatePrice,
    updateOrderItemPriceAction,
    updateOrderServiceAction, beginProcessOutOfStock,
    beginProcessChangePrice, beginProcessCancelOrder,
    clearErrorWhilePostInvoiceCode, clearErrorWhilePostCommentItem,
    approvalOrderServiceAction,
    updateOutOfStockItems,
    beginProcessUpdateOutStock,
    sortOrderItems,
    updatePriceItems,
    beginProcessUpdatePriceItems,
    beginClearSuccessUpdatePriceItems,
    beginClearErrorUpdatePriceItems,
    updateQuantityItems,
    beginProcessUpdateQuantityItems,
    addFreightBill,
    clearFreightBillDuplicate,
    cancelOrder, outOfStockOrder,
    deleteFreightBill, getShippingFeeHistory,
    changeRateDeposit, clearOrderDetailState,
    syncOrderBillNo,
    updateInvoiceCode,
    updateFragileOrderDetail,
    updateAffiliateLink,
    waitingPaymentOrder, cancelWaitingPaymentOrder,
    updateItemsOrderDetail,
    updateEcommerceShippingOrderDetail,
    updateHSCodeOrderDetail,
    updateOrderFeatureAction, updateSellerNote, updateTotalDiscount
} from './../OrderDetail/actions';
import {setBreadCrumb, setPageDescription, setPageTitle, setMenuActive} from './../../common/actions';
import {postItemLog, getPusherComment, getPusherCommentItem, changeOrderItemQuantity} from "../../LogItem/actions";
import {confirmModal} from "../../common/Modal/actions";
import lodash from "lodash";
import {getListAccountPurchaserAction} from '../../AccountPurchaser/actions'
import {getListAccountPurchaserOptions} from '../../AccountPurchaser/selectors'
import {getUserSelect} from "../../User/selectors";
import {fetchViolateReasons} from "../../ViolateReason/actions";
import {getActiveReasons} from "../../ViolateReason/selectors";
import {
    showFormSelectViolateReason,
    isPayingOrder,
    getViolatedPolicies,
    getPaymentOrder,
    showFormConfirmNotEnoughMoney, getMessageShowFormConfirmNotEnoughMoney
} from '../PaymentOrder/selectors';
import {CLEAR_FREIGHT_BILL_ERRORS} from "./constants";
import {fetchComplaintSellerReasons} from "../../ComplaintSeller/actions";
import * as SelectorComplaint from "../../ComplaintSeller/selectors";

const mapStateToProps = (state) => {
    return {
        dataOrder: Selector.purchasingOrderDetail(state),
        channels: Selector.getChannelOrderUpdate(state),
        isLoadingData: lodash.get(state, "PurchaseBuying.orderDetail.isLoadingData", {}),
        isFetchNewService: lodash.get(state, "PurchaseBuying.orderDetail.isFetchNewService", false),
        errorInvoiceCode: lodash.get(state, "PurchaseBuying.orderDetail.errorInvoiceCode", {}),
        freightBillErrors: lodash.get(state, "PurchaseBuying.orderDetail.freightBillErrors", {}),
        errorUnitPrice: lodash.get(state, "PurchaseBuying.orderDetail.errorUnitPrice", {}),
        errorCommentItem: lodash.get(state, "PurchaseBuying.orderDetail.errorCommentItem", {}),
        orderGroup: Selector.getOrderGroupLists(state),
        services: SelectorServices.services(state),
        isLoadingUpdateData: lodash.get(state, "PurchaseBuying.orderDetail.isLoadingUpdateData", {}),
        isLoading: lodash.get(state, "PurchaseBuying.orderDetail.isLoading", false),
        isLoadingConfirmCustomer: lodash.get(state, "PurchaseBuying.orderDetail.isLoadingConfirmCustomer", false),
        isLoadingWhilePostItemComment: lodash.get(state, "PurchaseBuying.orderDetail.isLoadingWhilePostItemComment", false),
        isLoadingCancelOrder: lodash.get(state, "PurchaseBuying.orderDetail.isLoadingCancelOrder", false),
        isLoadingOutOfStockOrder: lodash.get(state, "PurchaseBuying.orderDetail.isLoadingOutOfStockOrder", false),
        accountPurchasersOptions: getListAccountPurchaserOptions(state),
        me: getUserSelect(state),
        isEndProcess: lodash.get(state, "PurchaseBuying.orderDetail.isEndProcess", {
            postInvoiceCode: false,
            deleteInvoiceCode: false,
            cancelOrder: false,
            outOfStock: false
        }),
        isOpenMenuBar: lodash.get(state, "Common.isOpenMenuBar", false),
        errorUnitPriceItems: lodash.get(state, "PurchaseBuying.orderDetail.errorUnitPriceItems", {}),
        successUpdateItems: lodash.get(state, "PurchaseBuying.orderDetail.successUpdateItems", false),
        freightBillDuplicateInOrders: lodash.get(state, "PurchaseBuying.orderDetail.freightBillDuplicateInOrders", []),
        showFormSelectViolateReason: showFormSelectViolateReason(state),
        showFormConfirmNotEnoughMoney: showFormConfirmNotEnoughMoney(state),
        getPaymentOrder: getPaymentOrder(state),
        isPayingOrder: isPayingOrder(state),
        violateReasons: getActiveReasons(state),
        shippingFeeHistory: lodash.get(state, "PurchaseBuying.orderDetail.shippingFeeHistory", {}),
        violatedPolicies: getViolatedPolicies(state) || [],
        error: lodash.get(state, "PurchaseBuying.orderDetail.error", {}),
        complaintSellerReasons: SelectorComplaint.getComplaintSellerReason(state),
        messageShowFormConfirmNotEnoughMoney: getMessageShowFormConfirmNotEnoughMoney(state),
        features: SelectorFeatures.features(state),
        isFetchNewFeature: lodash.get(state, "PurchaseBuying.orderDetail.isFetchNewFeature", false),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateInvoiceCode: data => {
            dispatch(updateInvoiceCode(data));
        },
        getPurchasingOrderDetailAction: (data) => {
            dispatch(getPurchasingOrderDetailAction(data));
        },
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        onUpdatePaidOrderDetail: (data) => {
            dispatch(updatePaidOrderDetail(data));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu));
        },
        postItemLog: (logs) => {
            dispatch(postItemLog(logs));
        },
        getPusherComment: (logObject) => {
            dispatch(getPusherComment(logObject));
        },
        getPusherCommentItem: (logObject) => {
            dispatch(getPusherCommentItem(logObject));
        },
        changeOrderItemQuantity: (logObject) => {
            dispatch(changeOrderItemQuantity(logObject));
        },
        changeMutipleQuantity: (quantity) => {
            dispatch(changeMutipleQuantity(quantity));
        },
        onConfirmCustomerOrderBuying: (params) => {
            dispatch(confirmCustomerOrderBuying(params));
        },
        clearPurchaserBuyingChangeDataState: () => {
            dispatch(clearPurchaserBuyingChangeDataState());
        },
        onPostInvoiceCode: (datas) => {
            dispatch(postInvoiceCode(datas))
        },
        beginProcessPostInvoiceCode: () => {
            dispatch(beginProcessPostInvoiceCode());
        },
        beginProcessDeleteInvoiceCode: () => {
            dispatch(beginProcessDeleteInvoiceCode());
        },
        beginProcessUpdateOutStock: () => {
            dispatch(beginProcessUpdateOutStock());
        },
        beginProcessUpdatePriceItems: () => {
            dispatch(beginProcessUpdatePriceItems());
        },
        beginProcessUpdateQuantityItems: () => {
            dispatch(beginProcessUpdateQuantityItems());
        },
        beginProcessChangePrice: () => {
            dispatch(beginProcessChangePrice());
        },
        beginProcessCancelOrder: () => {
            dispatch(beginProcessCancelOrder());
        },
        beginProcessOutOfStock: () => {
            dispatch(beginProcessOutOfStock());
        },
        deleteInvoiceCode: (datas) => {
            dispatch(deleteInvoiceCode(datas));
        },
        onUpdateShippingFee: (fee) => {
            dispatch(updateShippingFee(fee));
        },
        onUpdateTotalDiscount: (data) => {
            dispatch(updateTotalDiscount(data))
        },
        onCancel: (orderId) => {
            dispatch(cancelOrder(orderId));
        },
        onOutOfStock: (orderId) => {
            dispatch(outOfStockOrder(orderId));
        },
        onPayment: (filters) => {
            dispatch(paymentOrder(filters));
        },
        onConfirmPayment: (orderId, reasons) => {
            dispatch(paymentOrder({
                id: orderId,
                confirm: 1,
                reasons,
            }));
        },
        onCancelPayment: () => {
            dispatch(cancelPaymentOrder());
        },
        fetchViolateReasons: (filter = {}) => {
            filter.scope = 'basic';
            dispatch(fetchViolateReasons(filter));
        },
        onConfirm: (name, message, data) => {
            dispatch(confirmModal(name, message, data));
        },
        onUpdateOrderGroupOrder: (params) => {
            dispatch(updateOrderGroupOrder(params));
        },
        /**
         * Get list account purchaser
         * @param filters
         */
        getListAccountPurchaserAction: (filters) => {
            filters.status = "ACTIVE";
            filters.scope = 'basic';
            dispatch(getListAccountPurchaserAction(filters))
        },
        updateOrder: (params) => {
            dispatch(updateOrderAccountPurchaser(params))
        },
        onPaid: (params) => {
            dispatch(updatePaidOrderStatus(params))
        },
        clearErrorWhileUpdatePrice: (param) => {
            dispatch(clearErrorWhileUpdatePrice(param));
        },
        clearErrorWhilePostInvoiceCode: () => {
            dispatch(clearErrorWhilePostInvoiceCode());
        },
        clearErrorWhilePostCommentItem: (idItem) => {
            dispatch(clearErrorWhilePostCommentItem(idItem));
        },
        /**
         * Update order item price
         * @param params
         */
        updateOrderItemPrice: (params) => {
            dispatch(updateOrderItemPriceAction(params))
        },
        /**
         * Update order service
         * @param params
         */
        updateOrderService: (params) => {
            dispatch(updateOrderServiceAction(params))
        },
        /**
         * Approval order service
         * @param params
         */
        approvalOrderService: (params) => {
            dispatch(approvalOrderServiceAction(params))
        },
        /**
         * Xử lý hết hàng nhiều sản phẩm
         */
        updateOutOfStockItems: (params) => {
            dispatch(updateOutOfStockItems(params))
        },
        /**
         * Xử lý sort order items
         */
        onSortOrderItems: (direction) => {
            dispatch(sortOrderItems(direction))
        },
        /**
         * Xử lý cập nhật giá của nhiều sản phẩm
         */
        updatePriceItems: (params) => {
            dispatch(updatePriceItems(params))
        },
        /**
         * Xử lý cập nhật số lượng của nhiều sản phẩm
         */
        updateQuantityItems: (params) => {
            dispatch(updateQuantityItems(params))
        },
        beginClearSuccessUpdatePriceItems: () => {
            dispatch(beginClearSuccessUpdatePriceItems());
        },
        beginClearErrorUpdatePriceItems: (params) => {
            dispatch(beginClearErrorUpdatePriceItems(params));
        },
        /**
         * Add freight bill
         * @param params
         */
        addFreightBill: (params) => {
            dispatch(addFreightBill(params));
        },
        /**
         * Delete freight bill
         * @param params
         */
        deleteFreightBill: (params) => {
            dispatch(deleteFreightBill(params))
        },

        clearFreightBillErrors: () => {
            dispatch({type: CLEAR_FREIGHT_BILL_ERRORS})
        },

        clearFreightBillDuplicate: (params) => {
            dispatch(clearFreightBillDuplicate(params))
        },

        /**
         * Change rate deposit
         * @param params
         */
        changeRateDeposit: (params) => {
            dispatch(changeRateDeposit(params));
        },
        clearOrderDetailState: () => {
            dispatch(clearOrderDetailState());
        },
        getShippingFeeHistory: (params) => {
            dispatch(getShippingFeeHistory(params));
        },
        /**
         * Sync order bill no
         * @param params
         */
        syncOrderBillNo: (params) => {
            dispatch(syncOrderBillNo(params))
        },
        updateFragileOrderDetail: (params) => {
            dispatch(updateFragileOrderDetail(params))
        },
        fetchComplaintSellerReasons: () => dispatch(fetchComplaintSellerReasons({status: 1, scope: 'basic'})),
        updateAffiliateLink: (params) => {
            dispatch(updateAffiliateLink(params))
        },
        onWaitingPayment: (filters) => {
            dispatch(waitingPaymentOrder(filters));
        },
        onCancelWaitingPayment: () => {
            dispatch(cancelWaitingPaymentOrder());
        },
        onConfirmWaitingPayment: (orderId, reasons) => {
            dispatch(waitingPaymentOrder({
                id: orderId,
                confirm: 1,
                reasons,
            }));
        },
        updateItemsOrderDetail: (data) => {
            dispatch(updateItemsOrderDetail(data))
        },
        updateEcommerceShippingOrderDetail: (params) => {
            dispatch(updateEcommerceShippingOrderDetail(params))
        },
        updateHSCodeOrderDetail: (params) => {
            dispatch(updateHSCodeOrderDetail(params))
        },
        /**
         * Update order feature
         * @param params
         */
        updateOrderFeature: (params) => {
            dispatch(updateOrderFeatureAction(params))
        },

        updateSellerNote: (data) => {
            dispatch(updateSellerNote(data))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchasingOrderDetail)
