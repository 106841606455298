import React from 'react';
import {connect} from 'react-redux';
import {MODAL_TYPE} from './constants';
import * as actions from './actions';
import Alert from './components/Alert';
import Confirm from './components/Confirm';
import lodash from "lodash";
class ModalContainer extends React.Component {
    render() {
        let {type, name, data} = this.props;

        switch (type) {
            case MODAL_TYPE.ALERT:
                return <Alert
                    isOpen={this.props.isOpenFilter}
                    message={this.props.message}
                    onClose={() => this.props.onClose(name, data)}
                />;

            case MODAL_TYPE.CONFIRM:
                return <Confirm
                    isOpen={this.props.isOpenFilter}
                    message={this.props.message}
                    onAccept={() => this.props.onAccept(name, data)}
                    onCancel={() => this.props.onCancel(name, data)}
                />;

            default:
                return null;
        }
    }
}

const mapStateToProps = (state) => lodash.get(state, 'CommonModal', {});

const mapDispatchToProps = (dispatch) => ({
    onClose: (name, data) => {
        dispatch(actions.close(name, data));
    },
    onAccept: (name, data) => {
        dispatch(actions.accept(name, data));
    },
    onCancel: (name, data) => {
        dispatch(actions.cancel(name, data));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalContainer)