import React from "react";
import {translate} from "react-i18next";

class OrderStatus extends React.Component {
    render() {
        let {status, t} = this.props;
        return <span className={`a-text--fz-12 px-3 ml-2 a-status a-status--is-new a-status--${status}`}>
            {t(`order:status.${status}`)}
        </span>;
    }
}

export default translate()(OrderStatus);