import React from "react";
import PropTypes from "prop-types";
import apiService from "../../apiService";
import {translate} from "react-i18next";
import { List, Row, Col, Divider, Dropdown, Menu } from "antd";
import {processResponse} from "../../../../../systems/api";
import Link from "../../../../../systems/routing/Link";
import {url} from "../../../../../systems/routing";
import moment from "moment";

class ComplaintSellerByOrder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount() {
        let {dataOrder, t} = this.props;
        let res = apiService.getComplaintSellerByOrder(dataOrder.id);
        let process = processResponse(res);
        process.then(res => {
            this.setState({data: res.data.complaintSellers});
        });
    }

    render() {
        let { dataOrder, t } = this.props;
        let {data} = this.state;
        if (data && data.length > 0) {
            const menu_item = this.state.data.map(item => {
                return (
                    <Menu.Item key={item.id}>
                        <Link isNewTab={true}
                              href={url.to('complaint-seller.detail', {id: item.id})}
                              className="link_item">
                            <Row className="a-text--fz-12">
                                <Col span={13} className="a-text--green-new">{item.reason.title}</Col>
                                <Col span={5} className="a-text--red">{moment(item.expire_time).isValid() ? moment(item.expire_time).format('DD/MM/YYYY') : "---"}</Col>
                                <Col span={6}>{t(`complaintSeller:status.${item.status.toLowerCase()}`)}</Col>
                            </Row>
                        </Link>
                    </Menu.Item>
                );
            });
            const menu = <Menu mode="vertical">{menu_item}</Menu>;
            return (
                <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}
                          overlayClassName="ant-dropdown-complaint">
                    <a className="ant-dropdown-link" href="#" onClick={this.toggleList}>
                        <span>{dataOrder.complaintSellerTotalFormat}</span>
                        {t('order:list.KNNB')}
                    </a>
                </Dropdown>
            );
        }

        return <a className="ant-dropdown-link" href="#" onClick={this.toggleList}>
            <span>{dataOrder.complaintSellerTotalFormat}</span>
            {t('order:list.KNNB')}
        </a>;
    }
}

ComplaintSellerByOrder.defaultProps = {
    dataOrder: {}
}

ComplaintSellerByOrder.propTypes = {
    dataOrder: PropTypes.object
}

export default translate()(ComplaintSellerByOrder);