import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import CreateProductReturn from './components/CreateProductReturn';
import * as actions from './actions';

const mapStateToProps = (state, props) => {
    let {permissions} = props;
    return {
        canProductReturn: lodash.get(permissions, 'can_create_product_return', false),
        isLoading: lodash.get(state, 'ComplaintSeller.createProductReturn.isLoading', false),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    let {complaint, order} = props;
    return {
        createProductReturn: () => {
            dispatch(actions.createProductReturn({
                id_complaint_seller: complaint.id,
                id_order: order.id,
                code_order: order.code,
            }));
        }
    };
};

let CreateProductReturnContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateProductReturn);

CreateProductReturnContainer.defaultProps = {
    complaint: {},
};

CreateProductReturnContainer.propTypes = {
    complaint: PropTypes.object.isRequired,
};

export default CreateProductReturnContainer;
