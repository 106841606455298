import React, { useState } from 'react'
import { translate } from 'react-i18next'
import { Icon, Badge, Tooltip, Modal, notification, Button } from 'antd'
import get from 'lodash/get'
import { hasPermission, permissions } from '../Permission'
import PhotoGallery from '../common/components/PhotoGallery'
import ReactFileReader from 'react-file-reader'
import apiService from './apiService'
import { processResponse } from '../../systems/api'
import { t } from '../../systems/i18n'

function ChangeQrcodeImage({ request }) {
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [qrImage, setQrImage] = useState(get(request, 'qr_code_url', ''))
    const permission = hasPermission(permissions.PAYMENT_REQUEST_QR_CODE_UPDATE)
    const handleChangeQrCode = () => {
        setVisible(true)
    }

    function handleFile(file) {
        console.log('file', file)
        setQrImage(get(file, 'base64', ''))
    }

    function handleOk() {
        setLoading(true)
        const requestId = get(request, 'id', undefined)
        const process = processResponse(apiService.updateQrCodePaymentRequest(requestId, { qr_code: qrImage }))
        process
            .then(res => {
                notification.success({ message: 'Thay đổi mã QR Code thanh toán thành công!' })
                setVisible(false)
            })
            .catch(() => {
                notification.error({ message: 'Thay đổi mã QR Code thanh toán thất bại!' })
                handleCancel()
            })
            .finally(() => setLoading(false))
    }

    function handleCancel() {
        setQrImage(get(request, 'qr_code_url', ''))
        setVisible(false)
        setLoading(false)
    }

    function removeImage() {
        Modal.confirm({
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            content: 'Bạn chắc chắn muốn xoá ảnh QR này?',
            onOk() {
                const requestId = get(request, 'id', undefined)
                const process = processResponse(apiService.deleteQrcodePaymentRequest(requestId))
                process
                    .then(res => {
                        notification.success({ message: 'Xoá mã QR Code thanh toán thành công!' })
                        setVisible(false)
                        setQrImage(undefined)
                    })
                    .catch(() => {
                        notification.error({ message: 'Xoá mã QR Code thanh toán thất bại!' })
                        handleCancel()
                    })
            },
            onCancel() {
                setVisible(false)
            },
        })
    }

    return (
        <>
            {visible && (
                <Modal
                    title="Chỉnh sửa ảnh mã QR Code thanh toán"
                    visible={visible}
                    onCancel={handleCancel}
                    onOk={handleOk}
                    cancelText="Huỷ"
                    okText="Đồng ý"
                    okButtonProps={{ loading }}>
                    <div className="d-flex justify-content-center">
                        <div>
                            <ReactFileReader
                                handleFiles={handleFile}
                                fileTypes={['image/*']}
                                multipleFiles={false}
                                base64={true}>
                                <Button>
                                    <Icon type={'upload'} /> Tải mã QR thanh toán
                                </Button>
                            </ReactFileReader>
                            {qrImage && (
                                <PhotoGallery
                                    className="mt-2 ant-upload ant-upload-select ant-upload-select-picture-card"
                                    images={[qrImage]}
                                    width={100}
                                    height={100}
                                />
                            )}
                        </div>
                    </div>
                </Modal>
            )}
            {qrImage &&
                (permission ? (
                    <Tooltip title="Xoá mã QR thanh toán">
                        <Badge
                            count={
                                <Icon
                                    style={{ color: 'red' }}
                                    type="delete"
                                    theme="filled"
                                    onClick={removeImage}
                                />
                            }>
                            <PhotoGallery images={qrImage ? [qrImage] : []} />
                        </Badge>
                    </Tooltip>
                ) : (
                    <PhotoGallery images={qrImage ? [qrImage] : []} />
                ))}

            {!visible && permission && (
                <a
                    onClick={handleChangeQrCode}
                    className="ml-1">
                    <Icon type="edit" />
                </a>
            )}
        </>
    )
}

export default translate()(ChangeQrcodeImage)
