import React, {useEffect, useState} from 'react';
import {trans} from "../../../../../systems/i18n";
import {Button, Modal, Table, notification, Empty, Checkbox, Col} from "antd";
import apiService from "../../apiService";
import lodash from "lodash";
import {processResponse} from "../../../../../systems/api";
import PaginationCustom from "../../../../common/components/Pagination";
import moment from "moment";
import Link from "../../../../../systems/routing/Link";
import {getState} from "../../../../../systems/store";
import {currentFormatter} from "../../../../common/services/format";

export default function BargainHistory({className, order}) {
    const {id, canShareNegotiationHistory} = order;
    const user = getState('User.me.user', {});
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({share:  canShareNegotiationHistory ? 1 : undefined});
    const [pagination, setPagination] = useState({});

    useEffect(() => {
        fetchBargainHistory();
    }, [filter]);

    function fetchBargainHistory() {
        setLoading(true);
        processResponse(apiService.getBargainHistory(id, filter))
            .then(res => {
                setData(lodash.get(res, "data.orders", []));
                setPagination(lodash.get(res, "data.pagination", {}));
            }).catch(err => {
            notification.error({message: trans("message.cant_fetch_data")})
        }).finally(() => setLoading(false));
    }

    const showModal = () => {
        setVisible(true);
        fetchBargainHistory();
    };


    const handleCancel = e => {
        setVisible(false);
        setLoading(false);
        setFilter({share:  canShareNegotiationHistory ? 1 : undefined})
    };

    const handleSort = (columnSort, sortBy) => {
        setFilter({
            ...filter,
            sort: columnSort,
            sortBy
        })
    };

    function onChangePage(page) {
        setFilter({
            ...filter,
            page
        })
    }

    const handleCheckbox = (e) => {
        setFilter({
            ...filter,
            share: e.target.checked ? 1 : 0,
            page: 1
        })
    };

    const columns = [
        {
            title: <>
                {trans("orderDetail:bargain_history.column.bought_at")}
                {
                    filter.sort !== "bought_at" ?
                        <i onClick={() => handleSort("bought_at", "desc")}
                           className="fas fa-sort ml-2 a--cursor-poiter"/> :
                        <i onClick={() => handleSort("bought_at", filter.sortBy === "asc" ? "desc" : "asc")}
                           className={filter.sortBy === "asc" ? "fas fa-sort-amount-up ml-2 a--cursor-poiter" : "fas fa-sort-amount-down ml-2 a--cursor-poiter"}>
                        </i>
                }
            </>,
            dataIndex: 'bought_at',
            key: 'bought_at',
            className: "_bargain-history-bought-at",
            render: (text, record) => {
                return <p>{record.bought_at ? moment(record.bought_at).format('DD/MM/YYYY H:mm') : "---"}</p>
            },
        },
        {
            title: trans("orderDetail:bargain_history.column.order_code"),
            dataIndex: 'order_code',
            key: 'order_code',
            className: "_bargain-history-order-code",
            render: (text, record) => {
                return (
                    (record.id_partner === user.id_partner) ?
                        <Link
                            className="d-flex align-items-center"
                            isNewTab={true}
                            to="purchase.buying.detail"
                            params={{id: record.id}}>
                            <img style={{height: "35px", width: "35px"}}
                                 src={record.image || require('../../../../../resources/img/default-thumbnail.jpg')}
                                 onError={(e) => {
                                     e.target.onerror = null;
                                     e.target.src = require('../../../../../resources/img/u32.png')
                                 }}
                                 alt="Avatar"/>
                            <p className="mr-2 ml-2 a-text--bold">{record.code}</p>
                        </Link>
                        :
                        <div className="d-flex align-items-center">
                            <img
                                style={{height: "35px", width: "35px"}}
                                src={record.image || require('../../../../../resources/img/default-thumbnail.jpg')}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = require('../../../../../resources/img/u32.png')
                                }}
                                alt="Avatar"
                            />
                            <p className="mr-2 ml-2 a-text--bold">******</p>
                        </div>
                )
            }
        },
        {
            title: trans("orderDetail:bargain_history.column.total_amount"),
            dataIndex: 'total_amount',
            key: 'total_amount',
            className: "_bargain-history-total-amount",
            render: (text, record) => {
                return <p>{currentFormatter.toLocaleStringCurrency(parseFloat(record.grand_total)) + "¥"}</p>
            }
        },
        {
            title: trans("orderDetail:bargain_history.column.real_pay"),
            dataIndex: 'real_pay',
            key: 'real_pay',
            className: "_bargain-history-real-pay",
            render: (text, record) => {
                const real_pay = record.grand_total - record.order_profit;
                return <p>{currentFormatter.toLocaleStringCurrency(parseFloat(real_pay)) + "¥"}</p>
            }
        },
        {
            title: <>
                {trans("orderDetail:bargain_history.column.order_profit_percent")}
                {
                    filter.sort !== "order_profit_percent" ?
                        <i onClick={() => handleSort("order_profit_percent", "desc")}
                           className="fas fa-sort ml-2 a--cursor-poiter"/> :
                        <i onClick={() => handleSort("order_profit_percent", filter.sortBy === "asc" ? "desc" : "asc")}
                           className={filter.sortBy === "asc" ? "fas fa-sort-amount-up ml-2 a--cursor-poiter" : "fas fa-sort-amount-down ml-2 a--cursor-poiter"}>
                        </i>
                }
            </>,
            dataIndex: 'order_profit_percent',
            key: 'order_profit_percent',
            className: "_bargain-history-order-profit-percent",
            render: (text, record) => {
                return <p>{record.order_profit_percent + "%"}</p>
            }
        },
        {
            title: trans("orderDetail:bargain_history.column.shipping_fee"),
            dataIndex: 'shipping_fee',
            key: 'shipping_fee',
            className: "_bargain-history-shipping-fee",
            render: (text, record) => {
                return <p>{currentFormatter.toLocaleStringCurrency(parseFloat(record.total_shipping_fee_inland)) + "¥"}</p>
            }
        },
    ];

    return (
        <div className={className}>
            <Button type="primary" onClick={showModal}
                    className="_bargain-history-button-open-modal">{trans("orderDetail:bargain_history.title")}</Button>
            {
                visible &&
                <Modal
                    title={trans("orderDetail:bargain_history.title").toUpperCase()}
                    visible={visible}
                    onCancel={handleCancel}
                    footer={null}
                    width={850}
                >
                    <div className="d-flex">
                        <div className="mr-auto p-2">
                            {
                                canShareNegotiationHistory &&
                                <Checkbox
                                    checked={!!filter.share}
                                    onChange={handleCheckbox}
                                    className={"_bargain-history-checkbox-use-data-share"}
                                >{trans("orderDetail:bargain_history.use_data_share")}</Checkbox>
                            }
                        </div>
                        <div className="p-2">
                            <Button
                                className="_bargain-history-button-order_profit_percent"
                                onClick={() => handleSort("order_profit_percent", "desc")}
                                type={ (filter.sort === "order_profit_percent" && filter.sortBy === "desc") ? "primary" : ""}
                            >
                                {trans("orderDetail:bargain_history.price_best")}
                            </Button>
                            <Button
                                className="ml-3 _bargain-history-button-bought_at"
                                onClick={() => handleSort("bought_at", "desc")}
                                type={(filter.sort === "bought_at" && filter.sortBy === "desc") ? "primary" : ""}
                            >
                                {trans("orderDetail:bargain_history.recently")}
                            </Button>
                        </div>
                    </div>
                    <div className="table-operations">

                    </div>
                    <Table
                        dataSource={data}
                        columns={columns}
                        loading={loading}
                        pagination={false}
                        rowKey={record => record.id}
                        locale={{
                            emptyText: (
                                <Empty
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                    description={<span>{trans("transaction:label.no_data")}</span>}
                                >
                                </Empty>)
                        }}
                    />
                    <div
                        className="mt-5 d-flex justify-content-end w-100">
                        {
                            (pagination && pagination.page_total > 1) &&
                            <PaginationCustom
                                className="_bargain-history-pagination"
                                value={parseInt(pagination.current_page, 10)}
                                total={pagination.page_total}
                                onChange={(page) => onChangePage(page)}
                            />
                        }
                    </div>
                </Modal>
            }
        </div>
    );
};