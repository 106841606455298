import React from 'react';
// import{Checkbox} from '@atlaskit/checkbox';
import {Checkbox} from "antd";
import PropTypes from 'prop-types';

class CheckboxComponent extends React.Component {
    render() {
        let {disabled, onChange, defaultChecked, label} = this.props;
        return (
            <Checkbox
                defaultChecked={defaultChecked}
                value={this.props.value}
                onChange={onChange}
                name={this.props.name}
                isDisabled={disabled}
            >{label}</Checkbox>

        );
    }
}

CheckboxComponent.defaultProps = {
    disabled: false,
    defaultChecked: false,
    onChange: ()=> {
    },
    label:'',
};

CheckboxComponent.propTypes = {
    disabled: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
};
export default CheckboxComponent;
