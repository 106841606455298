import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import Spinner from '@atlaskit/spinner';
import {hasPermission, permissions} from "../../../Permission";

class UserItem extends React.Component {
    onAttachPurchaser(event) {
        event.preventDefault();
        this.props.onAttachPurchaser();
    }

    onDetachPurchaser(event) {
        event.preventDefault();
        this.props.onDetachPurchaser();
    }

    renderUserAction() {
        let {t, user} = this.props;

        if (user.manage_purchaser_loading) {
            return <Spinner size={"small"}/>;
        }

        if (user.is_teller) {
            return (
                <a href=""
                   className="a-link text-muted"
                   onClick={this.onDetachPurchaser.bind(this)}
                >{t('purchaser:btn.detach')}</a>
            );
        }

        return (
            <a href=""
               className="a-link"
               onClick={this.onAttachPurchaser.bind(this)}
            >{t('purchaser:btn.attach')}</a>
        );
    }

    render() {
        let {t, user} = this.props;

        return (
            <tr key={user.id}>
                <td>{user.username}</td>
                <td>{user.is_teller ? t('purchaser:label.group_teller') : '---'}</td>
                <td className="text-center">{hasPermission(permissions.PURCHASER_MANAGE) ? this.renderUserAction(user) : ''}</td>
            </tr>
        );
    }
}

UserItem.defaultProps = {
    user: {},
    onAttachPurchaser: () => {},
    onDetachPurchaser: () => {},
};

UserItem.propTypes = {
    user: PropTypes.object,
    onAttachPurchaser: PropTypes.func,
    onDetachPurchaser: PropTypes.func,
};

export default translate()(UserItem);
