import React, {Component} from 'react';
import {Spin, Select} from "antd";
import {tellersService} from '../services';
import apiService from "../apiService";
import lodash from "lodash";
import {translate} from "react-i18next";
import {processResponse} from "../../../systems/api";
import PropTypes from "prop-types";
import loading from "../../../systems/api/loading";

const Option = Select.Option;

class SelectSearchTellers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            currentValue: "",
        };
    }

    fetchTellers(keyword) {
        processResponse(apiService.getTellersByKeyword(keyword))
            .then(res => this.setState({data: res.data, loading: false}));
    }

    fetchTellersById(id) {
        processResponse(apiService.getTellersById(id))
            .then(res => {
                console.log(res);
                this.setState({data: [{...res.data.user}], loading: false}, () => {
                    console.log(this.state.data);
                })
            });
    }

    componentDidMount() {
        if (this.state.data.length === 0 && this.props.value) {
            this.fetchTellersById(this.props.value);
        }
    }

    /*
     * keyword khác "" thì tìm kiếm
     * keyword không chứa chuỗi keyword trước thì fetchTellers
     * keyword chứa chuỗi keyword trước thì lọc dữ liệu từ state rồi trả về state mới theo keyword hiện tại
     */
    handleSearch(keyword) {
        if (keyword.length > 0) {
            const {currentValue} = this.state;
            if (currentValue !== "" && keyword.search(currentValue) === 0) {
                this.setState(state => {
                    return {
                        data: state.data.filter(item => item.username.search(keyword) > -1),
                        currentValue: keyword
                    };
                });
            } else {
                this.setState(
                    {data: [], currentValue: keyword, loading: true},
                    () => this.fetchTellers(keyword)
                );
            }
        } else
            this.setState({data: [], currentValue: "", loading: false});
    }

    /*
     * Hiển thị thông báo khi không có dữ liệu hoặc chưa có từ khóa tìm kiếm
     */
    notFoundContent = () => {
        const {loading, currentValue} = this.state;
        return currentValue ?
            (loading ?
                <div style={{textAlign: "center"}}><Spin size="small" /></div> :
                <div>Không có kết quả</div>) :
            <div>Nhập username cần tìm</div>
    }

    render() {
        let {t, showName, className} = this.props;
        let {data} = this.state;
        return (<React.Fragment>
                <Select
                    showSearch
                    placeholder={this.props.placeholder}
                    mode={this.props.multiple ? 'multiple' : 'default'}
                    value={data.length > 0 && this.props.value ? parseInt(this.props.value) : undefined}
                    style={this.props.style}
                    loading={this.state.loading}
                    showArrow={this.props.showArrow}
                    filterOption={false}
                    notFoundContent={this.notFoundContent()}
                    onChange={this.props.onChange}
                    onSearch={lodash.debounce(keyword => this.handleSearch(keyword), 300)}
                    className={className || "w-100"}>
                        {data.length > 0 && this.props.allOption && this.state.currentValue && <Option value="">{t('label.all')}</Option>}
                        {data.map(teller => (
                            <Option
                                key={teller.id}
                                value={teller.id}>
                                {(showName) ? teller.name + '(' + teller.username + ')' : teller.username}
                            </Option>
                        ))}
                </Select>
            </React.Fragment>);
    }
}

SelectSearchTellers.defaultProps = {
    value: undefined,
    allOption: false,
    multiple: false,
    onChange: value => {},
    className: '',
    placeholder: '',
};

SelectSearchTellers.propTypes = {
    value: PropTypes.any,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholer: PropTypes.string
};

export default translate()(SelectSearchTellers);
