import lodash from 'lodash';
import {processApiRequest} from '../../common/saga';
import * as ACTION from './constants';
import apiService from './apiService';
import bootbox from '../../common/components/Bootbox/bootbox';
import {t} from '../../../systems/i18n';
import {findUser} from '../selectors';

export function* attachPurchaser(action) {
    yield processApiRequest(
        ACTION.ATTACH_PURCHASER,
        () => apiService.attachPurchaser(action.payload.userId),
        action.payload
    );
}

export function* onAttachPurchaserSuccess(action) {
    let user = findUser(action.request.userId);

    yield bootbox.success(t('purchaser:message.attach_purchaser_success', {
        user: lodash.get(user, 'username'),
    }));
}

export function* onAttachPurchaserFailed(action) {
    let user = findUser(action.request.userId);

    yield bootbox.error(t('purchaser:message.attach_purchaser_failed', {
        user: lodash.get(user, 'username'),
    }));
}

export function* detachPurchaser(action) {
    yield processApiRequest(
        ACTION.DETACH_PURCHASER,
        () => apiService.detachPurchaser(action.payload.userId),
        action.payload
    );
}

export function* onDetachPurchaserSuccess(action) {
    let user = findUser(action.request.userId);

    yield bootbox.success(t('purchaser:message.detach_purchaser_success', {
        user: lodash.get(user, 'username'),
    }));
}

export function* onDetachPurchaserFailed(action) {
    let user = findUser(action.request.userId);

    yield bootbox.error(t('purchaser:message.detach_purchaser_failed', {
        user: lodash.get(user, 'username'),
    }));
}


