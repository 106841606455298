import React from 'react'
import {connect} from 'react-redux'
import PurchaseAddress from './Components'
import * as PurchaseAddressActions from '../../modules/PurchaseAddress/actions'
import * as PurchaseAddressSelector from '../../modules/PurchaseAddress/selectors'
import * as PurchaseAddressConstant from "../../modules/PurchaseAddress/constants";
import {setBreadCrumb, setPageDescription, setPageTitle, setMenuActive} from "../../modules/common/actions";
import lodash from "lodash";
import {getListAgencyAction} from '../../modules/Agency/actions';

class PurchaseAddressContainer extends React.Component {

    componentDidMount() {
        const {getPurchaseAddressList, getPurchaseAddressDetail, match} = this.props;
        getPurchaseAddressList({});
        if (match && match.params && match.params.id) {
            getPurchaseAddressDetail(match.params.id);
        }
        if (this.props.agencies.length === 0)
            this.props.onFetchAgency();
    }

    render() {
        return <PurchaseAddress
            {...this.props}
        />
    }
}

const mapStateToProps = (state) => {
    return {
        purchaseAddressList: PurchaseAddressSelector.getPurchaseAddressListSelector(state),
        createErrors: PurchaseAddressSelector.getCreateErorrsSelector(state),
        detail: PurchaseAddressSelector.getPurchaseAddressDetailSelector(state),
        permissionsList: lodash.get(state, 'User.me.user.permissions', []),
        agencies: lodash.get(state, "Agency.list", []),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchAgency: data => dispatch(getListAgencyAction(data)),

        /**
         * List purchase address
         * @param filter
         */
        getPurchaseAddressList: (filter) => {
            dispatch(PurchaseAddressActions.getPurchaseAddressList(filter))
        },
        /**
         * Create purchase address
         * @param data
         */
        createPurchaseAddress: (data) => {
            dispatch(PurchaseAddressActions.createPurchaseAddress(data))
        },
        /**
         * Update purchase address
         * @param id
         * @param data
         */
        updatePurchaseAddress: (id, data) => {
            dispatch(PurchaseAddressActions.updatePurchaseAddress(id, data))
        },

        /**
         * Get purchase address detail
         * @param id
         * @param filter
         */
        getPurchaseAddressDetail: (id, filter) => {
            dispatch(PurchaseAddressActions.getPurchaseAddress(id))
        },
        /**
         * Clear detail data
         */
        clearPurchaseAddressDetail: () => {
            dispatch({type: PurchaseAddressConstant.PURCHASE_ADDRESS_DETAIL.CLEAR})
        },

        /**
         * Clear error on form
         */
        resetErrorPurchaseAddress: (params) => {
            dispatch(PurchaseAddressActions.resetErrorPurchaseAddress(params));
        },

        /**
         * Update status
         */
        onUpdateStatusPurchaseAddress: (id, data) => {
            dispatch(PurchaseAddressActions.updateStatusPurchaseAddress(id, data));
        },

        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseAddressContainer)
