import {apiWithToken} from './../../../systems/api';
import {API_URL} from './../../../systems/globalContant';

export default {
    fetchAllConfigUser: () => apiWithToken.get(API_URL.SETTINGS_USER,
        {}, {loading: false}
    ),
    updateUserConfig: (params = {}) => apiWithToken.post(API_URL.SETTINGS_USER,
        params, {loading: false, singleRequest: true}
    ),
    updateIpphone: (params = {}) => apiWithToken.put(API_URL.DEFAULT+"/users/update-ipphone",
        params, {loading: false, singleRequest: true}
    ),
};
