import React from "react";
import {translate} from "react-i18next";
import {Button, Select, Modal} from "antd";
import lodash from "lodash";
import {ORDER_STATUS} from "../../../../../modules/Order/constants";
import SelectTellersV2 from "../../../../../modules/User/SelectTellersV2";
import Link from "../../../../../systems/routing/Link";

const {Option} = Select;

class AssignPurchaserModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            teller_id: 0,
            distributedOrders: [],
            undistributedOrders: [],
            justDistributed: false
        };
    }

    showModal = () => {
        let {orders, checkedOrders} = this.props;
        let distributedOrders = orders.filter(order => (lodash.includes(checkedOrders, order.id) && order.teller !== null));
        let undistributedOrders = orders.filter(order => (lodash.includes(checkedOrders, order.id) && order.teller === null));
        this.setState({
            visible: true,
            distributedOrders,
            undistributedOrders,
            justDistributed: false
        });
    };

    handleOk = e => {
        let orderIds = this.state.undistributedOrders.map(item => item.id);
        this.props.onAssignPurchaserOrders(orderIds, this.state.teller_id);
        this.setState({
            visible: false,
            teller_id: 0,
            distributedOrders: [],
            undistributedOrders: [],
            justDistributed: true
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            teller_id: 0,
            distributedOrders: [],
            undistributedOrders: []
        });
    };

    handleChange = val => {
        this.setState({teller_id: val});
    }

    render() {
        let {t, filter, checkedOrders, tellers} = this.props;
        let {distributedOrders, undistributedOrders, justDistributed} = this.state;
        let statusCurrent = lodash.get(filter, 'status', null);
        let disableButton = true;
        let listStatus = [ORDER_STATUS.WAITING_BUY, ORDER_STATUS.WAITING_PAI, ORDER_STATUS.PAID, ORDER_STATUS.WAITING_PAY];
        listStatus.forEach(function(status) {
            if(lodash.includes(statusCurrent, status)) {
                disableButton = false;
            }
        });

        return (
            <div>
                <Button
                    className="a-font--12 aphz-24 a-btn--primary-ant ml-3 btn-active"
                    disabled={disableButton}
                    style={{paddingTop: "1px"}}
                    onClick={this.showModal}
                    size="small"
                    type="submit">
                    <i className="fab fa-mixcloud" />&nbsp;{t("order:distribute_order.tellers_assign")}
                </Button>
                <Modal
                    title="Phân đơn"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    cancelText="Hủy"
                    okText="Phân đơn"
                    okButtonProps={{disabled: this.state.teller_id > 0 ? false : true}}
                >
                    { checkedOrders.length === 0 &&
                        <p className="text-center a-text--error">Vui lòng chọn đơn hàng</p>
                    }

                    { checkedOrders.length > 0 && undistributedOrders.length > 0 &&
                        <>
                            <p className="mb-2">Chọn tài khoản GDV để phân cho <strong>{checkedOrders.length} đơn</strong> đã chọn</p>
                            <Select
                                style={{width: "100%"}}
                                onChange={this.handleChange}
                                showSearch={true}
                                optionFilterProp="children"
                            >
                                { tellers && tellers.map(teller => {
                                        return <Option key={teller.id} value={teller.id}>{teller.username} - {teller.name}</Option>
                                    })
                                }
                            </Select>
                            { distributedOrders.length > 0 &&
                                <div className="mt-2">
                                    <strong>Trong {checkedOrders.length} đơn bạn đã chọn có {distributedOrders.length} đơn đã được phân cho GDV. Các đơn này sẽ không được phân lại khi bạn thực hiện phân đơn.</strong>
                                    <div className="a-box-quote a-box-quote--warning mt-2">
                                        { distributedOrders.map((order, index) => {
                                            return <>
                                                {index === 0 ? "" : ", "}
                                                <Link isNewTab={true} to="purchase.buying.detail" params={{id: order.id}}>{order.code}</Link>
                                            </>
                                        }) }
                                    </div>
                                </div>
                            }
                        </>
                    }

                    { checkedOrders.length > 0 && undistributedOrders.length === 0 && justDistributed !== true &&
                        <p className="text-center a-text--error">Tất cả các đơn bạn chọn được phân đơn rồi</p>
                    }
                </Modal>
            </div>
        );
    }
}

export default translate()(AssignPurchaserModal);