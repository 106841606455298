import * as Constants from './constants';
import lodash from 'lodash';
import {combineReducers} from 'redux';

const list = (state = [], action) => {
    let {payload} = action || {};
    switch (action.type) {
        case Constants.FETCH_FEATURES.SUCCESS:
            return lodash.get(payload, 'data', []);
        default:
            return state;
    }
};

const isLoading = (state = false, action) => {
    switch (action.type) {
        case Constants.FETCH_FEATURES.SUCCESS:
        case Constants.FETCH_FEATURES.FAILED:
            return false;
        case Constants.FETCH_FEATURES.REQUEST:
            return true;
        default:
            return state;
    }
}

export default combineReducers({
    list,
    isLoading
});
