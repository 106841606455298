import React from 'react';
import {Layout} from "antd";
import FormSearch from "./components/FormSearch";
import ListOrder from "./components/ListOrder";
const Content = Layout.Content;
class ListOrderProblemContainer extends React.Component{
    render() {
        return (
            <Layout>
                <Content className="a-content--ant-layout">
                    <FormSearch/>
                    <ListOrder/>
                </Content>
            </Layout>
        );
    }
}
export default ListOrderProblemContainer;