import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import lodash from 'lodash';
import * as actions from './actions';
import FormComment from './components/FormComment';

const mapStateToProps = (state, props) => {
    let loadingParam = props.isPublic ? 'public' : 'private';

    return {
        input: {
            message: '',
            images: [],
        },
        inputVersion: state.CommonLog.commentVersion,
        loading: lodash.get(state.CommonLog.commentLoading, loadingParam),
        canComment: props.canComment,
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    onSubmit: (input) => {
        dispatch(actions.comment(props.object, props.objectId, {...input, is_public: props.isPublic}));
    },
});

let Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(FormComment);

Container.defaultProps = {
    object: null,
    objectId: null,
    isPublic: false,
    canComment: true,
};

Container.propTypes = {
    object: PropTypes.string.isRequired,
    objectId: PropTypes.any.isRequired,
    isPublic: PropTypes.bool,
    canComment: PropTypes.bool,
};

export default Container;
