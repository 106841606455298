import React from 'react';
import {Table, Empty, Affix,Tooltip, Icon} from "antd";
import lodash, { forEach, get, map , flatten, flattenDeep} from 'lodash';
import Pagination from "../../../common/Pagination";
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import {TRANSACTION_COLUMN, TRANSACTION_COLUMN_DISPLAY} from "../constants";
import ListTransactionSettingContainer from "../ListTransactionSettingContainer";
import ModalManageInvoiceCodeContainer from "../../ModalManageInvoiceCode/ModalManageInvoiceCodeContainer";
import ExportCSV from "../../ExportCSV/ExportCSV";
import ResolvingTransaction from '../../ResolvingTransaction';
import MarkTransaction from '../../MarkTransaction';
import authService from '../../../Auth/authService';
import * as PERMISSION from '../../../Permission/permissions';
import {url} from "../../../../systems/routing";
import Link from "../../../../systems/routing/Link";
import {ReactComponent as NewTab} from "../../../../resources/img/newtab.svg";
import RelatedTransaction from "../../RelatedTransaction";
import UploadInvoiceCodeCSV from '../../UploadInvoiceCodeCSV/UploadFileCSVContainer';
import PusherComponent from "../../../common/components/Pusher";
import * as GlobalConstant from "../../../../systems/globalContant";
import qs from "qs";
import notification from '../../../../systems/notification';
import { t, trans} from "../../../../systems/i18n";
import RelatedTransactionScanningTool from "../../RelatedTransaction/components/RelatedTransactionScanningTool";
import UploadPopover from "../../UploadPopover";
import MultiResolveTransactionContainer from "../../MultiResolveTransaction/MultiResolveTransactionContainer";
import SynchronizingButton from "../components/SynchronizingButton";
import RefundAmount from './RefundAmount';
import UploadAutoMGDContainer from '../../UploadAutoMGD/UploadAutoMGDContainer';
import { dateFormatter } from '../../../common/services/format';

class ListData extends PusherComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeColumns: [],
            transactionId: 0,
            loading: false,
            invoiceCodes: [],
            showModal: false,
            maxInvoiceCode: ''
        };
    }

    _pusherOnMessage(text, id, channel) {
        if (channel.startsWith(GlobalConstant.CHANNELS.IMPORT_TRANSACTION_INVOICE_CODE)) {
            let response = qs.parse(text, {plaintObject: true});
            if (response.errors.hasOwnProperty('NOT_EXIST')) {
                let transactionCodes = lodash.join(response.errors.NOT_EXIST);
                notification.error(trans('transaction:message.code_not_exist', {
                    code: <span className="a-text--break-all a-text--bold">{transactionCodes}</span>
                }))
            }
            if (response.errors.hasOwnProperty('empty')) {
                this.renderMessagesError(response,'empty', 'empty_line')
            }
            if (response.errors.hasOwnProperty('ALREADY_EXIST')) {
                this.renderMessagesError(response,'ALREADY_EXIST', 'find_transaction_duplicate')
            }
            if (response.errors.hasOwnProperty('REQUIRED')) { 
                this.renderMessagesError(response,'REQUIRED', 'seri_upload_required')     
            } 

            if (response.errors.hasOwnProperty('SERI_DUPLICATE')) { 
                this.renderMessagesError(response,'SERI_DUPLICATE', 'seri_upload_duplicate_seri')     
            } 

            if (response.errors.hasOwnProperty('SERI_ALREADY_EXIST')) { 
                this.renderMessagesError(response,'SERI_ALREADY_EXIST', 'seri_already_exist')     
            } 

            if (response.errors.hasOwnProperty('SERI_REQUIRED')) { 
                this.renderMessagesError(response,'SERI_REQUIRED', 'seri_upload_required_seri')     
            } 
        }
        if (channel.startsWith(GlobalConstant.CHANNELS.IMPORT_TRANSACTION_RESOLVE)) {
            let response = qs.parse(text, {plaintObject: true});
            
            if (response.errors.hasOwnProperty('NOT_EXIST')) {
                let transactionCodes = lodash.join(response.errors.NOT_EXIST);
                notification.error(trans('transaction:message.code_not_exist', {
                    code: <span className="a-text--break-all a-text--bold">{transactionCodes}</span>
                }))
            }
            if (response.errors.hasOwnProperty('empty')) {
                let line = lodash.join(response.errors.empty);
                notification.error(trans('transaction:message.empty_line', {
                    line: <span className="a-text--break-all a-text--bold">{line}</span>
                }))
            }
            if (response.errors.hasOwnProperty('INVALID')) {
                let transaction = lodash.map(response.errors.INVALID, 'transaction');
                let transactionText = lodash.join(transaction, ', ');
                notification.error(trans('transaction:message.resolve_status_invalid', {
                    transaction: <span className="a-text--break-all a-text--bold">{transactionText}</span>
                }))
            }
            if (response.errors.hasOwnProperty('ALREADY_EXIST')) {
                let transaction = lodash.join(response.errors.ALREADY_EXIST);
                notification.error(trans('transaction:message.transaction_belong_to_many_account', {
                    transaction: <span className="a-text--break-all a-text--bold">{transaction}</span>
                }))
            }
        
        }

        if (channel.startsWith(GlobalConstant.CHANNELS.TRANSACTION_IMPORT_MANUAL)) {
            const response = qs.parse(text, {plaintObject: true});
            const messages = []

            if (response.hasOwnProperty('account_invalid')) {
               messages.push(this.renderMessagesErrorManual(response,'account_invalid', 'error_data'))
            }

            if (response.hasOwnProperty('amount_invalid')) {
                messages.push(this.renderMessagesErrorManual(response,'amount_invalid', 'error_data'))
            }

            if (response.hasOwnProperty('invoice_code_invalid')) {
                messages.push(this.renderMessagesErrorManual(response,'invoice_code_invalid', 'invoice_code'))
            }

            if (response.hasOwnProperty('service_fee_invalid')) {
                messages.push(this.renderMessagesErrorManual(response,'service_fee_invalid', ''))
            }

            if (response.hasOwnProperty('required')) {
                const errors = get(response, "required", [])

                errors.forEach(element => {
                    messages.push(Object.keys(element.error_data).map(key=>{
                       return trans(`transaction:message.line_required`, {
                            line: <span className="a-text--break-all a-text--bold">{element.line}</span>,
                            attribute: <span className="a-text--break-all a-text--bold">{t(`transaction:label.${key}`)}</span>,
                        })
                    }))
                });
            }
            
            if (response.hasOwnProperty('transaction_time_invalid')) {
                const errors = get(response, "transaction_time_invalid", [])

                messages.push(errors.map(element => {
                    return trans(`transaction:message.transaction_time_invalid_file_manual`, {
                        line: <span className="a-text--break-all a-text--bold">{element.line}</span>,
                        time: <span className="a-text--break-all a-text--bold">{dateFormatter.date(get(element, 'error_data', ''))}</span>,
                    })
                }));
            }

            notification.error(
                map(
                    flatten(messages).map((item,index) => (
                            <div style={{marginBottom: "4px"}} key={index}>- {item}</div>
                    ))
                )
            )
        }

    }

    renderMessagesErrorManual(response, key, keyData){
        const errors = get(response, key, [])

        // errors.forEach(element => {
        //     notification.error(trans(`transaction:message.${key}`, {
        //         line: <span className="a-text--break-all a-text--bold">{element.line}</span>,
        //         keyData: <span className="a-text--break-all a-text--bold">{get(element, keyData, '')}</span>,
        //     }))
        // });
        return errors.map(element => 
            trans(`transaction:message.${key}`, {
                line: <span className="a-text--break-all a-text--bold">{element.line}</span>,
                keyData: <span className="a-text--break-all a-text--bold">{get(element, keyData, '')}</span>,
            })
        );
    }
    

    renderMessagesError(response, key, keyI18n){
        let lines = lodash.map(response.errors[key], 'line');
        let linesStr = lodash.join(lines, ', ');
        notification.error(trans(`transaction:message.${keyI18n}`, {
            line: <span className="a-text--break-all a-text--bold">{linesStr}</span>
        }))
    }

    onChangePage(page, pageSize) {
        this.setState({
            visible: false,
        });
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                page,
                per_page: pageSize,
            });
        }
    }

    onChange(pagination, filters, sorter) {
        this.search({
            orderBy: sorter.field ? sorter.field.replace("_format", "") : 'transaction_time',
            sort: sorter.order === 'descend' ? 'desc' : 'asc',
        });
        this.props.setFilterCSV({
            orderBy: sorter.field ? sorter.field.replace("_format", "") : 'transaction_time',
            sort: sorter.order === 'descend' ? 'desc' : 'asc',
        });
    }

    handleDownload(id, filename) {
        this.props.downloadFile(id, filename);
    }

    search(input) {
        if (!this.props.loading) {
            this.props.onSearch({
                ...this.props.filter,
                ...input,
            });
        }
    }

    getColumns() {
        let arrSort = [
            TRANSACTION_COLUMN_DISPLAY.CREATED_AT,
            TRANSACTION_COLUMN_DISPLAY.RESOLVED_TIME,
            TRANSACTION_COLUMN_DISPLAY.TRANSACTION_TIME
        ];
        let downloadPermission = authService.can(PERMISSION.TRANSACTION_DOWNLOAD_FILE);
        return lodash.values(TRANSACTION_COLUMN_DISPLAY)
            .filter(column => this.state.activeColumns.includes(column))
            .map(column => {
                let className = 'a-text--nowrap';
                if (column === TRANSACTION_COLUMN.CODE) {
                    className = 'a-text--nowrap _transaction_column';
                } else if (lodash.includes([TRANSACTION_COLUMN.AMOUNT, TRANSACTION_COLUMN.AMOUNT_REFUND], column)) {
                    className = 'a-text--right';
                }

                let res = {
                    className: className,
                    key: column,
                    render: (text, record, index) => {
                        if (column === TRANSACTION_COLUMN.ORDER_CODE) {
                            return record.invoiceCodeForm;
                        } else if (column === TRANSACTION_COLUMN.AMOUNT) {
                            return (
                                <span className={"a-text--bold " + record.amount_class}>{record.amount_format}</span>);
                        } else if (column === TRANSACTION_COLUMN.CODE) {
                            if (authService.can(PERMISSION.SUPPLIER_TRANSACTION_VIEW)) {
                                return (
                                    <React.Fragment>
                                        <a
                                            className="link_item"
                                            href="#"
                                        >
                                            <Tooltip placement="right"
                                                     title={record.system ? this.props.t("transaction:title." + record.system) : "Giao dịch"}>
                                                {
                                                    (record.system === "alipay" || record.system === "apilay") ? <Icon type ="alipay-circle" /> : ((record.system === "alipayGlobal") ?<Icon type="global" /> : <Icon type="dollar" />)
                                                }

                                            </Tooltip>
                                        </a>
                                        <Link
                                            href={url.to('transaction.detail', {id: record.id})}
                                            className="link_item ml-2">
                                            {record.code + " "}
                                        </Link>
                                        <a
                                            className="link_item"
                                            href={url.to('transaction.detail', {id: record.id})}
                                            target={"_blank"}
                                        >
                                            <Tooltip placement="right" title="Mở trong tab mới">
                                                <NewTab className={"img-link-newtab ml-2"}/>
                                            </Tooltip>
                                        </a>
                                    </React.Fragment>
                                )
                            } else {
                                return record.code;
                            }
                        } else if (column === TRANSACTION_COLUMN.CSV_FILE) {
                            if (authService.can(PERMISSION.TRANSACTION_MANAGE_FILE)) {
                                return <span className="a-form__group__value">{downloadPermission ?
                                    <a onClick={this.handleDownload.bind(this, lodash.get(record, 'transactionRaw.id_transaction_file', 0), lodash.get(record, 'csv_file_name', ''))}
                                       className="a-text--color-primary a-text--underline">{record.hasOwnProperty('csv_file_name') ? record.csv_file_name : '--'}</a> : (record.csv_file_name)}</span>
                            }
                        } else if (column === TRANSACTION_COLUMN.RELATED_TRANSACTION) {
                            return <>
                                <RelatedTransaction transactionId={record.id} transaction={record}/>
                                {record.type === "SPEND_HELP" && record.canUpdateRelatedTransaction &&
                                <RelatedTransactionScanningTool transaction={record} />
                                }
                            </>;
                        } else if (column === TRANSACTION_COLUMN.RELATED_ACCOUNT) {
                            let account_related = (record.system === "alipayGlobal") ? "id_account_related" : "accountRelated.account";
                            return lodash.get(record, account_related, "");
                        } else if (column === TRANSACTION_COLUMN_DISPLAY.TYPE){
                            return (
                            <div>
                                <p>{text}</p>
                                {get(record, 'canCreateOrderPayment') && (
                                   <RefundAmount transactionId={record.id}/>
                                )}
                            </div>)
                        }else if( column === TRANSACTION_COLUMN.SERI_NUMBER){
                            return record.code_by_account
                        } else if( column === TRANSACTION_COLUMN.SERVICE_FEE){
                            return record.service_fee_format
                        } else {
                            return <p dangerouslySetInnerHTML={{__html: text}}/>
                        }
                    },
                    dataIndex: column,
                    title: this.props.t('transaction:column.' + column),
                };

                if (column === TRANSACTION_COLUMN.ORDER_CODE) {
                    res.title = <div className={"d-flex justify-content-between"}>
                        <span
                            className={"w-100 mr-4"}
                        >{this.props.t('transaction:column.order_code')}</span>
                        <span
                            className={"w-100 ml-4 mr-22 a-text--right"}
                        >{this.props.t('transaction:column.invoice_code')}</span>
                    </div>
                }

                if (arrSort.indexOf(column) > -1) {
                    let sortOrder = null;
                    if (column === this.props.filter.orderBy || column === this.props.filter.orderBy + "_format") {
                        sortOrder = (this.props.filter.sort === 'desc' ? 'descend' : 'ascend')
                    } else if ((column === TRANSACTION_COLUMN.TRANSACTION_TIME || column === TRANSACTION_COLUMN.TRANSACTION_TIME + "_format")) {
                        sortOrder = (!this.props.filter.sort) ? 'descend' : null;
                    }

                    res = {
                        ...res,
                        sorter: true,
                        sortOrder: sortOrder,
                    }
                }
                return res;
            });
    }

    getRows() {
        return this.props.transactions.map(transaction => {
            return {
                ...transaction,
            };
        });
    }

    render() {
        let {pagination, filter, loading, t, object, currentTransaction, errors} = this.props;
        let rows = this.getRows();

        return (
            <div className="a-list mt-5">
                <Affix>
                    <div className="a-list__top a-flex">
                        <h2 className="a-list--title a-text--uppercase">{t("transaction:label.list_transaction_desc")}
                            ({pagination.total})</h2>
                        <div className="ml-auto d-flex justify-content-center">
                            <UploadPopover object={'auto-created-mgd'} loading={loading} title={t('transaction:btn.auto_create_mgd')}
                                           uploadComponent={<UploadAutoMGDContainer/>}
                                           permission={PERMISSION.TRANSACTION_MANUAL_ALIPAY_CREATE}
                                           affix="csv"
                                           />

                            <UploadPopover object={'resolve'} loading={loading} title={'Resolve GD'}
                                           uploadComponent={<MultiResolveTransactionContainer/>}
                                           permission={PERMISSION.SUPPLIER_TRANSACTION_RESOLVE}/>
                            <UploadPopover object={'invoice-code'} loading={loading} title={'Map MHĐG'}
                                           uploadComponent={<UploadInvoiceCodeCSV/>}
                                           permission={PERMISSION.SUPPLIER_TRANSACTION_EDIT}/>
                            {
                                !filter.hasOwnProperty('id_transaction_file') && authService.can(PERMISSION.TRANSACTION_EXPORT) &&
                                <ExportCSV
                                    filter={this.props.filter}
                                    target={"transaction"}
                                    csvFilter={this.props.csvFilter}
                                    t={this.props.t}
                                    loading={this.props.loading}
                                    resetError={this.props.resetError}
                                />
                            }
                            <ListTransactionSettingContainer
                                onChange={activeColumns => this.setState({activeColumns})}
                                page={this.props.page}
                                isOpenListSetting={this.props.isOpenListSetting}
                                onOpenListSetting={this.props.onOpenListSetting}
                                isCurrentPage={this.props.isCurrentPage}
                                object={object}
                                permission={this.props.permission}
                            />
                            {
                                !lodash.isEmpty(rows) && !lodash.isEmpty(pagination) &&
                                <SynchronizingButton
                                    transactions={rows.filter(row => row.type === "SPEND_HELP")}
                                    pagination={pagination}
                                    filter={filter}
                                    loading={loading}
                                />
                            }
                        </div>
                    </div>
                </Affix>
                <div className="a-content--is-pagination-fixed">
                    <Table
                        className={"a-table--antd a-table--description"}
                        rowKey="id"
                        columns={this.getColumns()}
                        dataSource={rows}
                        rowClassName={transaction => {
                            return (transaction.type === "CANCELED") ? "opacity_60" : "";
                        }}
                        expandedRowKeys={lodash.map(rows, 'id')}
                        expandedRowRender={transaction => {
                            return (
                                <div className={transaction.type === "CANCELED" ? "opacity_60" : ""}>
                                    {
                                        transaction.note &&
                                        <p className="a-text--gray-new a-text--fz-12 mb-3">{t('transaction:label.note_transaction')}: {transaction.note}</p>
                                    }
                                    <ResolvingTransaction transaction={transaction}/>
                                    <MarkTransaction transaction={transaction}/>
                                </div>
                            );
                        }}
                        expandIcon={() => null}
                        expandIconAsCell={false}
                        scroll={{x: 'fit-content'}}
                        pagination={false}
                        loading={loading}
                        locale={{
                            emptyText: (
                                <Empty
                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                    description={<span>{t("transaction:label.no_data")}</span>}
                                >
                                </Empty>)
                        }}
                        onChange={this.onChange.bind(this)}
                    />
                    <ModalManageInvoiceCodeContainer
                        invoiceCodes={currentTransaction.invoiceCodes}
                        idTransaction={currentTransaction.idTransaction}
                        loading={this.state.loading}
                        showModal={currentTransaction.showModal}
                        canUpdateInvoiceCode={currentTransaction.canUpdateInvoiceCode}
                        canDeleteInvoiceCode={currentTransaction.canDeleteInvoiceCode}
                        maxInvoiceCode={currentTransaction.maxInvoiceCode}
                        transactionType={currentTransaction.transactionType}
                    />
                    <div
                        className={lodash.toInteger(pagination.total) > lodash.toInteger(filter.per_page) ? "a-pagination a-pagination--fixed " : "hidden"}>
                        <Pagination
                            page={lodash.toInteger(filter.page) || 1}
                            pageSize={lodash.toInteger(filter.per_page)}
                            total={pagination.total}
                            loading={this.props.loading}
                            onChange={this.onChangePage.bind(this)}
                            pageSizeOptions={[20, 50, 100, 200]}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

ListData.defaultProps = {
    filter: {page: 1, per_page: 20},
    loading: false,
    pagination: {
        total: 0
    },
    currentTransaction: {
        idTransaction: 0,
        showModal: false,
        showRelatedTransactionModal: false,
        invoiceCodes: []
    },
    permission: {}
};

ListData.propTypes = {
    filter: PropTypes.object,
    loading: PropTypes.bool,
    pagination: PropTypes.object,
    onSearch: PropTypes.func,
    currentTransaction: PropTypes.object,
    permission: PropTypes.object
};

export default translate()(ListData);
