import React, { useState } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {Affix, Table} from "antd";
import PaginationFull from "../../../modules/common/components/PaginationFull";
import lodash from "lodash";
import moment from "moment";
import ChangeStatus from "./ChangeStatus";
import ApprovalStatus from "./ApprovalStatus";
import {Link} from "../../../systems/routing";
import {CopyToClipboard} from "react-copy-to-clipboard";
import bootbox from "../../../modules/common/components/Bootbox/bootbox";
import InvoiceCodeChangeMessage from './InvoiceCodeChangeMessage';

const ListApprovals = function(props) {

	let { t, approvals, loading, pagination } = props;

	const [idShowLog, setIdShowLog] = useState(null);
	const [showLog, setShowLog] = useState(false);

	function onClickShowLogs(id) {
		setIdShowLog(id);
		handleShowLog();
	}

	function handleShowLog() {
		setShowLog(showLog => !showLog);
	}

	function onChangePage(page, pageSize) {
		if (!props.loading) {
			props.onSearch({
				...props.filter,
				page,
				per_page: pageSize,
			});
		}
	}

	function getColumns() {
		let columns = [
			{
				key: 'order_code',
				className: "a-text--nowrap",
				render: (text, approval) => {
					let order = lodash.get(approval, "order", {});
					if (lodash.isEmpty(order))
						return "";

					return (
						<div className="a-text--bold">
							<Link
								href={'/purchase-buying/' + order.id}
								className="a-text--nowrap ml-2 mr-1"
								isNewTab={true}
							>{order.code}</Link>
							<CopyToClipboard
								text={order.code}
								onCopy={() => { bootbox.success(t("message.copied") + ": " + order.code) }}>
								<i className="far fa-copy copy a-text--pointer a-text--blue a-text--color-black-45"/>
							</CopyToClipboard>
						</div>

					);
				}
			},
			{
				key: 'content_changed_from',
				className: "a-text--nowrap",
				render: (text, approval) => {
					return (<div>{Array.isArray(approval.from) ? approval.from.map(invoice_code => <p>{invoice_code}</p>) : ""}</div>);
				}
			},
			{
				key: 'content_changed_to',
				className: "a-text--nowrap",
				render: (text, approval) => {
					return (<div>{Array.isArray(approval.to) ? approval.to.map(invoice_code => <p>{invoice_code}</p>) : ""}</div>);
				}
			},
			{
				key: 'time',
				className: "a-text--nowrap",
				render: (text, approval) => {
					return (
						<div>
							<p><span className="text-black-50">Đề nghị:</span> {approval.created_at ? moment(approval.created_at).format('DD/MM/YYYY H:mm') : "--"}</p>
							{
								approval.status === "WAITTING" &&
								<p><span className="text-black-50">Duyệt:</span> {approval.processing_time ? moment(approval.processing_time).format('DD/MM/YYYY H:mm') : "--"}</p>
							}
							{
								approval.status === "SUCCESS" &&
								<p><span className="text-black-50">Duyệt:</span> {approval.processing_time ? moment(approval.processing_time).format('DD/MM/YYYY H:mm') : "--"}</p>
							}
							{
								approval.status === "CANCELLED" &&
								<p><span className="text-black-50">Từ chối:</span> {approval.processing_time ? moment(approval.processing_time).format('DD/MM/YYYY H:mm') : "--"}</p>
							}
						</div>
					);
				}
			},
			{
				key: 'reason',
				width: "250px"
			},
			{
				key: 'note',
				className: "",
				width: "250px"
			},
			{
				key: 'status',
				className: "a-text--nowrap",
				render: (text, approval) => {
					return (
						<ApprovalStatus
							approval={approval}
							updateInvoiceCodeApprovals={props.updateInvoiceCodeApprovals}
						/>
					);
				}
			},
			{
				key: 'log',
				className: "a-text--nowrap",
				render: (text, approval) => {
					return (
						<div>
							<a onClick={() => onClickShowLogs(approval.id)}>Xem log</a>
						</div>
					);
				}
			}
		];

		return columns.map(column => ({
			dataIndex: column.key,
			title: t('changeInvoiceRequest:list.' + column.key),
			...column
		}));
	}

	return (
		<div className="a-list mt-5">
			<Affix>
				<div className="a-list__top a-flex">
					<h2 className="a-list--title a-text--uppercase">{t('changeInvoiceRequest:list.title')} ({!lodash.isEmpty(pagination) ? pagination.total : 0})</h2>
				</div>
			</Affix>
			<div className="a-content--is-pagination-fixed" style={{padding: "16px 15px 0 15px"}}>
				<Table
					className="a-table--antd a-table--description a-text--color-black-65"
					columns={getColumns()}
					dataSource={approvals}
					loading={loading}
					scroll={{x: 'fit-content'}}
					pagination={false}
				/>
				<PaginationFull
					page={lodash.toInteger(pagination.current_page) || 1}
					pageSize={lodash.toInteger(pagination.per_page)}
					total={pagination.total}
					pageSizeOptions={[20, 30, 50, 80, 100]}
					onChange={onChangePage}
					className="a-pagination a-pagination--fixed"
				/>
				{
					showLog &&
						<InvoiceCodeChangeMessage id={idShowLog} show={showLog} handleShowLog={handleShowLog} />
				}
			</div>
		</div>
	);
};

ListApprovals.defaultProps = {
	t: () => {},
	invoiceCodeApprovals: []
};

ListApprovals.propTypes = {
	t: PropTypes.func,
	invoiceCodeApprovals: PropTypes.array,
};

export default translate('translations')(ListApprovals);
