import {apiWithToken} from '../../systems/api';
import {API_URL} from './../../systems/globalContant';

export default {
    getMe: () => apiWithToken.get(API_URL.GET_USER, {loading: false}),
    getTellersByKeyword: (keyword) => apiWithToken.get(API_URL.DEFAULT+`/users/suggest?scope=basic&username=${keyword}`, {loading: false}),
    getTellers: () => apiWithToken.get(API_URL.DEFAULT+`/users/suggest?scope=basic`, {loading: false}),
    getTellersById: (id) => apiWithToken.get(API_URL.DEFAULT+`/users/${id}?scope=basic`, {loading: false}),
    getListTellers: (filters = {}) => apiWithToken.get(API_URL.DEFAULT + '/users/teller?scope=BASIC&pagination=0', {params: filters}, {loading: false}),
    getPurchasers: (filters = {}) => apiWithToken.get(API_URL.DEFAULT + '/users', {params: filters, loading: false}),
    getTellersList: (filters = {}) => apiWithToken.get(API_URL.DEFAULT + '/users/account-purchasers/', {params: filters, loading: false}),
    fetchAccount: (params = {}) => apiWithToken.get(API_URL.DEFAULT + '/users/teller', {
        params: params,
        loading: false
    }),
    list: (params = {}) => apiWithToken.get(API_URL.DEFAULT+'/users', {params, loading: false}),
    logout: () => () => {
        window.location = typeof process.env.REACT_URL_LOGOUT !== "undefined" ? process.env.REACT_URL_LOGOUT : "https://id.gobiz.vn/confirm_logout";
    },
    listUsersComplaintSeller: (params = {}) => apiWithToken.get(API_URL.DEFAULT+'/users/complaint-sellers', {params, loading: false}),
};
