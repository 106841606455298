import React from 'react';
import {translate} from 'react-i18next';
import Button from '@atlaskit/button';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {trans} from '../../../../systems/i18n';
import Input from './../../../common/components/Input/Super';
import {currentFormatter} from './../../../common/services/format';
import ModalGeneral from './../../../common/components/ModalGeneral';

class ModalConfirmSellerComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            amount: null
        }
    }

    /**
     * handle action thay đổi value của các input trong form
     * @param event
     */
    onChangeInput(event) {
        let {target} = event;
        if (this.renderError(target.name) !== null) {
            this.props.clearError([target.name]);
        }
        this.setState({
            [target.name]: target.value
        });
    }

    /**
     *
     * @param event
     */
    onKeyUpAmountSellerRefund(event) {
        let amount_key = lodash.get(this.props, 'amount', 0);
        amount_key = parseFloat(amount_key);
        let amount = parseFloat(event.target.value);
        this.setState({
            amount: amount,
            errorDuplicate: amount && amount === amount_key
        });
    }

    /**
     * handle action thay đổi value của các unit price
     * @param value
     */
    onChangeAmountSellerRefund(value) {
        this.setState({
            "amount": value
        });
    }

    /**
     * handle action press enter
     * @param event
     */
    handerPressEnter(event) {
        if (event.key === 'Enter') {
            let {complaint} = this.props;
            let formData = Object.assign(this.state, {});
            formData = {...formData, ...{id: complaint.id, next_state: "SUCCESS"}};
            if (!this.props.isLoading) {
                formData = lodash.omit(formData, 'errorDuplicate');
                this.props.onSubmitForm(formData);
            }
        }
    }

    /**
     * clear error unit price
     */
    onClearPriceAmountSellerRefund() {
        this.props.clearErrorComplaintSellerSuccess();
    }

    /**
     * xử lý hành động submit form
     */
    onSubmitForm() {
        let {complaint} = this.props;
        let formData = Object.assign(this.state, {});
        formData = {...formData, ...{id: complaint.id, next_state: "SUCCESS"}};
        if (!this.props.isLoading) {
            formData = lodash.omit(formData, 'errorDuplicate');
            this.props.onSubmitForm(formData);
        }
    }

    /**
     * handle việc đóng popup form lại
     */
    closeModalForm = () => {
        this.props.closeModalChangeSuccess();
    };

    /**
     * Thực hiện render lỗi thông báo ra bên ngoài
     * @param attrName
     * @returns {null}
     */
    renderError(attrName) {
        let {errors, t} = this.props;
        let errorItem = lodash.get(errors, attrName, {});

        if (!lodash.isEmpty(errorItem)) {
            if (attrName === "transaction") {
                switch (Object.keys(errorItem)[0]) {
                    case 'required':
                        return t('complaintSeller:error.transaction.' + Object.keys(errorItem)[0]);
                    default:
                        return null;
                }
            } else {
                switch (Object.keys(errorItem)[0]) {
                    case 'required':
                        return t('complaintSeller:error.error.' + Object.keys(errorItem)[0], {name: t('complaintSeller:error.' + attrName)});
                    case 'unique':
                        return t('complaintSeller:error.error.' + Object.keys(errorItem)[0], {name: t('complaintSeller:error.' + attrName)});
                    case 'max.string':
                        let objectmax = lodash.get(errorItem, 'max.string', null);
                        let overLength = lodash.get(objectmax, 'max', null);
                        return t('complaintSeller:error.error.max_string', {
                            name: t('complaintSeller:error.' + attrName),
                            value: overLength
                        });
                    case 'min.string':
                        let objectmin = lodash.get(errorItem, 'min.string', null);
                        let length = lodash.get(objectmin, 'min', null);
                        return t('complaintSeller:error.error.min_string', {
                            name: t('complaintSeller:error.' + attrName),
                            value: length
                        });
                    case "status_invalid":
                        return t('complaintSeller:error.error.status_invalid', {});
                    case 'max.numeric':
                        let objectmaxNumber = lodash.get(errorItem, 'max.numeric', null);
                        let overLengthNumber = lodash.get(objectmaxNumber, 'max', null);
                        overLengthNumber = lodash.toInteger(overLengthNumber);
                        overLengthNumber = currentFormatter.toLocaleStringCurrency(overLengthNumber);
                        return t('complaintSeller:error.error.max_numeric', {
                            name: t('complaintSeller:error.' + attrName),
                            value: overLengthNumber + '¥'
                        });
                    default:
                        return null;
                }
                    }
        }
        return null;
    }

    render() {
        let {t, isLoading} = this.props;
        let {errorDuplicate} = this.state;
        let isNotErrors = this.renderError("amount") === null && this.renderError("next_state") === null && this.renderError("status") === null  && this.renderError("transaction") === null;
        return (
            <ModalGeneral>
                <div className={"a-modal__title"}>
                    <p>
                        {trans("complaintSeller:detail.title_seller_refund")} <span className="a-text--red">*</span>
                    </p>
                </div>
                <div className={"row"}>
                    <div className={"col col-sm-12 col-md-12 col-lg-12 a-modal__complaintseller"}>
                        <div className={'a-modal__complaintseller__detail'}>
                            <div className="w-100 pr-5">
                                <Input
                                    negative={false}
                                    onKeyUp={this.onKeyUpAmountSellerRefund.bind(this)}
                                    className={(this.renderError("amount") === null || errorDuplicate ? "" : " ") + "input input--fullwidth input--height-30"}
                                    onKeyPress={event => this.handerPressEnter(event)}
                                    name={"amount"}
                                    unit_price={true}
                                    onChange={this.onClearPriceAmountSellerRefund.bind(this)}
                                    onBlur={this.onChangeAmountSellerRefund.bind(this)}
                                    placeholder={t("complaintSeller:detail.enter_seller_refund")}
                                />
                                <p className={isNotErrors ? "a-font--12 mt-3" : "hidden" }>{t('complaintSeller:modal.warning_change_success')}</p>
                            </div>
                            <div className="a--group--inline-end  a--user-group-btn a-modal__complaintseller__action">
                                <Button appearance="primary" type="submit"
                                        className={"mr-3 a-btn--submit " + (errorDuplicate ? "a-btn--disable" : "")}
                                        onClick={this.onSubmitForm.bind(this)}
                                        isLoading={isLoading}
                                        isDisabled={errorDuplicate}
                                >
                                    {trans('account_purchaser.btn_action_confirm')}
                                </Button>
                                <a
                                    appearance="subtle"
                                    onClick={this.closeModalForm.bind(this)}
                                    className={(isLoading ? "a-disable " : "") + "a-btn d-flex align-items-center  a-btn-link a--cursor-poiter"}
                                    role="button">
                                    {trans('account_purchaser.btn_action_cancel')}
                                </a>
                            </div>
                        </div>
                        <div
                            className={isNotErrors? "hidden" : "a-text--red a-font--12 mb-3"}>
                            {this.renderError("amount")}
                            {this.renderError("status")}
                            {this.renderError("next_state")}
                            {this.renderError("transaction")}
                        </div>

                    </div>
                </div>
            </ModalGeneral>
        );
    }
}

ModalConfirmSellerComplete.defaultProps = {
    onSubmitForm: () => {
    },
    onClose: () => {
    },
    isLoading: false,
    errors: {}
};

ModalConfirmSellerComplete.propTypes = {
    onSubmitForm: PropTypes.func,
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    errors: PropTypes.object
};

export default translate('translations')(ModalConfirmSellerComplete);
