import React from "react";
import {Icon, Tooltip} from "antd";
import {t} from "../../../../../systems/i18n";

export default function CanExport({can, showText, type}) {
    if (can === null)
        return can;

    return (
        <Tooltip
            className={can ? "a-text--color-green" : "a-text--red"}
            title={t(`orderDetail:can_exported.${can === 1 ? "suitable" : `not_suitable${type === "order" ? "" : "_all"}`}`, {attribute: t(`orderDetail:can_exported.${type === "order" ? "order" : "product"}`)})}
        >
            <Icon type="global" className="mr-1 ml-2" />
            { showText ? t(`orderDetail:can_exported.${can === 1 ? "suitable_label" : "not_suitable_label"}`) : "" }
        </Tooltip>
    );
}
