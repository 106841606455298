import * as Constants from './../constants';
import * as CompleteConstants from './../CompleteComplaint/constants';
import lodash from 'lodash';
import {combineReducers} from 'redux';
import {updateCollectionItem} from '../../common/services/helps';
import {COMPLAINT_ASSIGN} from './../ComplaintAssign/constants';
import {RECEIVE_COMPLAINT} from '../ComplaintReception/constants';
import {FAILED_COMPLAINT} from '../FailedComplaint/constants';
import {COMPLAINT_UPDATE_NOTE} from '../ComplaintNote/constants';
import {COMPLAINT_MARK_IMPORTANT} from '../ComplaintMarkImportant/constants';
import {ATTACH_ALIPAY, DETACH_ALIPAY} from '../ComplaintAlipays/constants';

const complaint = (state = {}, action) => {
    let {payload} = action || {};
    let newState = state;
    switch (action.type) {
        case Constants.FETCH_COMPLAINT_DETAIL.SUCCESS:
            newState = state;
            newState = {
                ...newState,
                ...lodash.get(payload, 'data.complaintSellers', {}),
                canChangePackage: lodash.get(payload, 'data.canChangePackage', false)
            };
            return newState;
        case Constants.UPDATE_COMPLAINT_DETAIL_AGAIN:
            newState = state;
            newState = {
                ...newState,
                ...lodash.get(payload, 'data.complaintSeller', {})
            };
            return newState;
        case Constants.UPDATE_ORIGIN_CODE_COMPLAINT_DETAIL:
            return {
                ...state,
                origin_code: lodash.get(payload, 'data.complaintSeller.origin_code', ""),
                origin_delivery_code: lodash.get(payload, 'data.complaintSeller.origin_delivery_code', null),
                origin_amount: lodash.get(payload, 'data.complaintSeller.origin_amount', null),
                origin_expired_at: lodash.get(payload, 'data.complaintSeller.origin_expired_at', null),
                origin_invoice_code: lodash.get(payload, 'data.complaintSeller.origin_invoice_code', null),
                origin_status: lodash.get(payload, 'data.complaintSeller.origin_status', null)
            };

        case COMPLAINT_ASSIGN.SUCCESS: {
            return {
                ...state,
                ...{selected_receiver: lodash.get(payload, 'data.complaintSeller.receiver')},
                ...lodash.pick(lodash.get(payload, 'data.complaintSeller'), ['id_receiver','receiver'])
            };
        }

        case COMPLAINT_UPDATE_NOTE.SUCCESS:
        {
            return {
                ...state,
                ...lodash.pick(lodash.get(payload, 'data.complaintSeller'), ['note'])
            };
        }

        case COMPLAINT_MARK_IMPORTANT.REQUEST:
        {
            let is_important = lodash.get(payload, 'params.params.is_important', {});
            return {
                ...state,
                ...{is_important: is_important ? 1: 0}
            };
        }

        case COMPLAINT_MARK_IMPORTANT.SUCCESS:
        {
            return {
                ...state,
                ...lodash.pick(lodash.get(payload, 'data.complaintSeller'), ['is_important'])
            };
        }
        case COMPLAINT_MARK_IMPORTANT.FAILED:
        case COMPLAINT_MARK_IMPORTANT.ERROR:
        {
            let is_important = lodash.get(payload, 'params.is_important', {});
            return {
                ...state,
                ...{is_important: is_important ? 0 : 1}
            };
        }

        case RECEIVE_COMPLAINT.SUCCESS:
        case FAILED_COMPLAINT.SUCCESS:
            return {
                ...state,
                ...lodash.pick(lodash.get(payload, 'data.complaintSeller'), ['status']),
            };
        case Constants.CLEAR_COMPLAINT_DETAIL:
            return {};

        case Constants.EXPIRE_COMPLAINT_SELLER.FAILED:
            newState = state;
            newState = {
                ...newState,
                ...{expire_time: newState.expire_time}
            };
            return newState;
        case Constants.EXPIRE_COMPLAINT_SELLER.SUCCESS:
            newState = {
                ...newState,
                ...{expire_time: lodash.get(payload, 'data.complaintSeller.expire_time', '')},
                ...{renewal_count: lodash.get(payload, 'data.complaintSeller.renewal_count', '') ? lodash.get(payload, 'data.complaintSeller.renewal_count', '') : '---'},
            };
            return newState;

        case CompleteConstants.UPDATE_COMPLAINT_SELLER_SUCCESS.SUCCESS:
            return {
                ...state,
                ...lodash.pick(lodash.get(payload, 'data.complaintSeller'), ['status']),
                amount_earned: lodash.get(action, "payload.data.complaintSeller.amount_earned", 0),
                historyTime: [
                    ...state.historyTime,
                    {
                        complaint_seller_id: lodash.get(action, "payload.data.complaintSeller.id", 0),
                        created_at: lodash.get(action, "payload.data.complaintSeller.updated_at", ''),
                        status: "SUCCESS",
                        updated_at: lodash.get(action, "payload.data.complaintSeller.updated_at", '')
                    }
                ]
            };
        case Constants.COMPLAINT_SELLER_REJECTED:
            newState = state;
            newState = {
                ...newState,
                ...lodash.get(payload, 'data.complaint', {})
            };
            return newState;
        case Constants.COMPLAINT_SELLER_REASON_UPDATE.SUCCESS:
            newState = state;
            newState = {
                ...newState,
                reason: lodash.get(payload, 'data', {})
            };
            return newState;
        default:
            return state;
    }
};

const permissions = (state = {}, action) => {
    let {payload} = action;

    switch (action.type) {
        case Constants.FETCH_COMPLAINT_DETAIL.SUCCESS:
        case COMPLAINT_ASSIGN.SUCCESS:
            return lodash.get(payload, 'data.complaintSellerPermissions', {});

        case CompleteConstants.UPDATE_COMPLAINT_SELLER_SUCCESS.SUCCESS:
            return {
                ...state,
                can_receive: false,
                can_update_assign: false,
                can_update_failure: false,
                can_update_renew: false,
                can_update_success: false,
                can_reject: false,
            };
        case RECEIVE_COMPLAINT.SUCCESS:
            return {
                ...state,
                can_reject: false,
                can_receive: false,
            };
        case FAILED_COMPLAINT.SUCCESS:
            return {
                ...state,
                can_update_failure: false,
            };
        case Constants.COMPLAINT_SELLER_REJECTED:
            return {
                ...state,
                can_reject: false,
                can_receive: false,
            };
        case Constants.CLEAR_COMPLAINT_DETAIL:
            return {};
        default:
            return state;
    }
};

const renewReasons = (state = [], action) => {
    let {payload} = action || {};
    switch (action.type) {
        case Constants.FETCH_COMPLAINT_SELLER_RENEW_REASONS.SUCCESS:
            return lodash.get(payload, 'data', []);
        default:
            return state
    }
}

const order = (state = {}, action) => {
    let {payload} = action || {};
    switch (action.type) {
        case Constants.FETCH_COMPLAINT_DETAIL.SUCCESS:
            return lodash.get(payload, 'data.orders', {});
        case Constants.CLEAR_COMPLAINT_DETAIL:
            return {};
        case Constants.ADD_COMMENT_PRODUCT_SUCCESS:
            let {id, note} = payload.data
            return {...state, items: updateCollectionItem(state.items, id, {note})};
        case Constants.ADD_TRANSACTION_CODE_CUCCESS:
            let transaction_code = lodash.get(payload, "data.transaction_code", "");
            let {transactions} = state;
            return {...state, transactions: transactions.filter(transaction => transaction.code !== transaction_code)};
        default:
            return state;
    }
};

const orderAlipays = (state = [], action) => {
    let {payload} = action;

    switch (action.type) {
        case Constants.FETCH_COMPLAINT_DETAIL.SUCCESS:
            return lodash.get(payload, 'data.orderAlipays', []);

        case Constants.CLEAR_COMPLAINT_DETAIL:
            return [];

        case ATTACH_ALIPAY.REQUEST:
        case DETACH_ALIPAY.REQUEST:
            return updateCollectionItem(state, action.payload.alipayId, {mapping: true});

        case ATTACH_ALIPAY.SUCCESS:
            return updateCollectionItem(state, action.request.alipayId, {
                id_complaint_seller: action.request.complaintId,
                mapping: false,
            });

        case DETACH_ALIPAY.SUCCESS:
            return updateCollectionItem(state, action.request.alipayId, {
                id_complaint_seller: 0,
                mapping: false,
            });

        case ATTACH_ALIPAY.FAILED:
        case DETACH_ALIPAY.FAILED:
            return updateCollectionItem(state, action.request.alipayId, {mapping: false});

        default:
            return state;
    }
};

let loading = (state = false, action) => {
    let {type} = action;
    switch (type) {
        case Constants.FETCH_COMPLAINT_DETAIL.REQUEST:
            return true;
        case Constants.FETCH_COMPLAINT_DETAIL.SUCCESS:
        case Constants.FETCH_COMPLAINT_DETAIL.FAILED:
        case Constants.CLEAR_COMPLAINT_DETAIL:
            return false;
        default:
            return state;
    }
};

const error = (state = {}, action) => {
    switch (action.type) {
        case Constants.EXPIRE_COMPLAINT_SELLER.FAILED:
            return {expire_complaint_seller: action.payload.data};
        case Constants.EXPIRE_COMPLAINT_SELLER.REQUEST:
        case Constants.EXPIRE_COMPLAINT_SELLER.SUCCESS:
        case Constants.CLEAR_ERROR_EXPIRE_COMPLAINT_SELLER:
            return lodash.omit(state, 'expire_complaint_seller');
        default:
            return state;
    }
};

export default combineReducers({
    complaint,
    permissions,
    order,
    orderAlipays,
    error,
    loading,
    renewReasons,
});
