import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./resource/scss/style.scss";
import {Icon, Layout, notification} from 'antd';
import lodash from "lodash";
import FormSearch from './components/FormSearch';
import ListPurchases from "./components/ListPurchase";
import {dispatch} from "../../../systems/store";
import {setBreadCrumb, setMenuActive, setPageDescription, setPageTitle} from "../../../modules/common/actions";
import {translate} from "react-i18next";
import apiService from "../../../modules/Purchase/apiService";
import service from "../../../modules/PurchaseBuying/OrderDetail/apiService";
import {processResponse} from "../../../systems/api";
import SearchableComponent from "../../../modules/common/SearchableComponent";
import {ORDER_STATUS, ORDER_TIME_TYPE} from "../../../systems/globalContant/actionContant";
import {updateCollectionItem} from "../../../modules/common/services/helps";
import {events} from "../../../systems/events";
import ORDER_EVENT from "../../../modules/Order/events";
import Link from "../../../systems/routing/Link";
import {connect} from "react-redux";
import * as SelectorCustomer from "../../../modules/User/selectors";
import * as Selector from "../../../modules/Purchase/selectors";
import * as PurchaseAddressSelector from "../../../modules/PurchaseAddress/selectors";
import * as WarehouseSelector from "../../../modules/Warehouse/selectors";
import * as AgencySelector from "../../../modules/Agency/selectors";
import {
    beginDistributeOrder,
    clearAllCountStatusChanged,
    clearCountStatusChanged, clearDistributeOrder,
    fetchPurchases,
    pusherAssignOrder,
    pusherReadComment,
    pusherUnassignOrder, requestOrdersForBuying
} from "../../../modules/Purchase/actions";
import {confirmCustomerOrderBuying} from "../../../modules/PurchaseBuying/OrderDetail/actions";
import {getPurchaseAddressList} from "../../../modules/PurchaseAddress/actions";
import {getListWarehouseAction} from "../../../modules/Warehouse/actions";
import {getListAgencyAction} from "../../../modules/Agency/actions";
import SwitchScreen from "../../../modules/SwitchScreen";
import * as GlobalConstants from "../../../systems/globalContant";
import {put} from "@redux-saga/core/effects";
import * as Constants from "../../../modules/PurchaseBuying/OrderDetail/constants";
import bootbox from "../../../modules/common/components/Bootbox/bootbox";
import {t} from "../../../systems/i18n";
import * as helps from "../../../modules/common/services/helps";
import { fetchViolateReasons } from '../../../modules/ViolateReason/actions';

const Content = Layout.Content;

class ListPurchase extends SearchableComponent {

    constructor(props) {
        super(props);
        this.state = {
            statsStatus: [],
            orders: [],
            pagination: {},
            loading: false,
            distributing: 0,
            canChangeWaitingPay: "0",
            canCreatePaymentRequestSB: false,
            canViewContact: false,
            seller_source: [],
            canUsingFintech: false,
            orderDistributorRequireWarehouse:false,
            orderDistributorType: ""
        };

        this.defaultFilter = {
            // code: '',
            status: ORDER_STATUS.WAITING_PAI,
            total_item_link_operation: '>',
            time_type:ORDER_TIME_TYPE.DEPOSITED
            // id_purchase_address: [],
            // id_agency: [],
            // invoice_code: '',
            // seller_source: [],
            // complaint_seller: [],
            // wrong_process
            // sort: 'asc'
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.setPageInfo();
        this.fetchSellerSources();
        this.props.fetchViolateReasons()
    }

    fetchSellerSources = () => {
        let {t} = this.props;
        processResponse(apiService.fetchSellerSource())
            .then(res => {
                this.setState({seller_source: res.data});
            })
            .catch(() => {
                notification.error(t('message.cant_fetch_data'));
            });
    }

    fetchPurchases = filter => {
        this.setState({loading: true});
        let {t} = this.props;

        let res = apiService.fetchPurchases(filter);
        let process = processResponse(res);

        process.then(res => {
            this.setState({
                orderDistributorType: lodash.get(res, 'data.orderDistributorType'),
                orders: lodash.get(res, "data.orders", []),
                pagination: lodash.get(res, "data.pagination", {}),
                canCreatePaymentRequestSB: lodash.get(res, "data.canCreatePaymentRequestSB", false),
                canViewContact: lodash.get(res, "data.canViewContact", false),
                canUsingFintech: lodash.get(res, "data.canUsingFintech", false),
                loading: false,
                orderDistributorRequireWarehouse: lodash.get(res, "data.orderDistributorRequireWarehouse", false)
            });
        });

        process.catch(() => {
            notification.error({message: t('message.cant_fetch_data')});
            this.setState({loading: false});
        });
    }

    fetchCountPurchases = filter => {
        let {t} = this.props;

        processResponse(apiService.getPurchaserOrderStats(filter))
            .then(res => {
                this.setState({
                    statsStatus: lodash.get(res, "data.stats", []),
                    canChangeWaitingPay: lodash.get(res, "data.canChangeWaitingPay", []),
                });
            })
            .catch(() => {
                notification.error({message: t('message.cant_fetch_data')});
            });
    }

    onConfirmCustomerOrderBuying = params => {
        processResponse(service.confirmCustomerOrderBuying(params))
        .then(res => {
            let order = lodash.get(res, "data", {});
            this.setState(state => {
                let orders = helps.updateCollectionItem(state.orders, order.id, lodash.pick(order, ['buyer_confirm', 'note_buyer_confirm']));
                return {...state, orders};
            });
            bootbox.success(t('orderDetail:order_detail.confirm_success'));
        })
        .catch((err) => {
            if (err.code === 'WARNING') {
                bootbox.warning(t('orderDetail:order_detail.confirm_duplicate'));
            } else if (err.code !== 201) {
                bootbox.error(t('orderDetail:order_detail.confirm_failed'));
            }
        });
    }

    setPageInfo() {
        let {t} = this.props;
        dispatch(setPageTitle(t('layout.title.list_purchase')));
        dispatch(setPageDescription(t('layout.description.list_purchase')));
        dispatch(setMenuActive('ListPurchase'));
        dispatch(setBreadCrumb([
            {
                'label': 'breadcrumb.home',
                'link': '/'
            }, {
                'label': 'breadcrumb.list_purchase',
                'link': '/purchases-new/'
            },
        ]));
    }

    onSearch = (filter) => {
        let defaultFilter = this.getFilter();

        if (filter.reset) {
            let resetFilter = {
                ...this.defaultFilter,
                _i: parseInt(defaultFilter._i || 0, 0) + 1
            };
            this.pushFilter({
                ...this.defaultFilter,
                _i: parseInt(defaultFilter._i || 0, 0) + 1
            });
            events.dispatch(ORDER_EVENT.RESET_FILTER, resetFilter);
        }
        else if (lodash.isEmpty(filter.code) && lodash.isEmpty(filter.invoice_code) && lodash.isEmpty(filter.freight_bill))
            this.pushFilter({
                ...defaultFilter,
                ...filter,
                _i: parseInt(defaultFilter._i || 0, 0) + 1
            });
        else {
            if (!lodash.isEmpty(filter.code))
                this.pushFilter({code: filter.code, status: "ALL"});
            else if (!lodash.isEmpty(filter.invoice_code))
                this.pushFilter({invoice_code: filter.invoice_code, status: "ALL"});
            else
                this.pushFilter({freight_bill: filter.freight_bill, status: "ALL"});
        }
    }

    onChangeFilter(filter) {
        this.fetchCountPurchases(filter);
        this.fetchPurchases(filter);
    }

    render() {
        let filter = this.getFilter();
        // Báo cho biết người dùng click lại vào menu quản lý hay ko
        let searchStr = lodash.get(this.props, "location.search", "");

        return (
            <Layout>
                <Content className="a-content--ant-layout new-list-orders">
                    <FormSearch
                        filter={filter}
                        onSearch={this.onSearch}
                        resetFilter={searchStr === "" ? true : false}
                        defaultFilter={this.defaultFilter}
                        sellerSource={this.state.seller_source}
                        canUsingFintech={this.state.canUsingFintech}
                    />
                    <ListPurchases
                        {...this.props}
                        filter={filter}
                        statsStatus={this.state.statsStatus}
                        orders={this.state.orders}
                        distributing={this.state.distributing}
                        pagination={this.state.pagination}
                        loading={this.state.loading}
                        onSearch={this.onSearch}
                        onCheckOrder={this.onCheckOrder}
                        onConfirmCustomerOrderBuying={this.onConfirmCustomerOrderBuying}
                        canChangeWaitingPay={this.state.canChangeWaitingPay}
                        canCreatePaymentRequestSB={this.state.canCreatePaymentRequestSB}
                        canViewContact={this.state.canViewContact}
                        orderDistributorRequireWarehouse={this.state.orderDistributorRequireWarehouse}
                        orderDistributorType={this.state.orderDistributorType}
                    />
                </Content>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        channels: SelectorCustomer.getChannelOrder(state),
        orders: Selector.getOrders(state),
        stats: Selector.getStats(state),
        countStatusChanged: lodash.get(state, "Purchase.ListOrder.countStatusChanged", false),
        endDistributeOrder: lodash.get(state, "Purchase.ListOrder.endDistributeOrder", false),
        accountPurchase: Selector.getAccountPurchase(state),
        loading: lodash.get(state, "Purchase.ListOrder.loading", false),
        partner: lodash.get(state, "Purchase.ListOrder.partner", {id: 0, code: "", domain: ""}),
        pagination: lodash.get(state, "Purchase.ListOrder.pagination", {
            current_page: 1,
            page_total: 1,
            per_page: 20,
            total: 0
        }),
        isDistributiveWarehouseSetting: SelectorCustomer.getDistributiveWarehouseSetting(state),
        isUsingOrderDistributorSetting: SelectorCustomer.getOrderDistributorUsingSetting(state),
        user: SelectorCustomer.getMeSelector(state),
        purchaseAddresses: PurchaseAddressSelector.getListPurchaserAddressOptions(state),
        warehouses: WarehouseSelector.getListAccountPurchaser(state),
        agencies: AgencySelector.getListAgency(state),
        getLoadingRequestOrder: lodash.get(state, "Purchase.ListOrder.getLoadingRequestOrder", false),
        violateReasons: lodash.get(state, "ViolateReasons.list", []),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (value) => {
            dispatch(setPageTitle(value));
        },
        setBreadCrumb: (items) => {
            dispatch(setBreadCrumb(items));
        },
        setPageDescription: (value) => {
            dispatch(setPageDescription(value));
        },
        setMenuActive: (menu) => {
            dispatch(setMenuActive(menu))
        },
        fetchPurchases: (filter) => {
            if (lodash.isEmpty(filter)) {
                filter = {...filter, ...{status: ORDER_STATUS.WAITING_PAI}}
            }
            dispatch(fetchPurchases({
                ...filter
            }));
        },
        // onConfirmCustomerOrderBuying: (params) => {
        //     dispatch(confirmCustomerOrderBuying(params));
        // },
        pusherAssignOrder: (orderData) => {
            dispatch(pusherAssignOrder(orderData));
        },
        pusherUnassignOrder: (orderData) => {
            dispatch(pusherUnassignOrder(orderData));
        },
        pusherReadComemnt: (orderData) => {
            dispatch(pusherReadComment(orderData));
        },
        clearCountStatusChanged: (status) => {
            dispatch(clearCountStatusChanged(status));
        },
        clearAllCountStatusChanged: () => {
            dispatch(clearAllCountStatusChanged());
        },
        clearDistributeOrder: () => {
            dispatch(clearDistributeOrder());
        },
        beginDistributeOrder: () => {
            dispatch(beginDistributeOrder());
        },
        requestOrdersForBuying(params) {
            dispatch(requestOrdersForBuying(params));
        },
        getPurchaseAddressList: (filter) => {
            filter.scope = 'basic';
            dispatch(getPurchaseAddressList(filter));
        },
        getListWarehouse: (filter) => {
            filter.scope = 'basic';
            dispatch(getListWarehouseAction(filter));
        },
        getListAgency: (filter) => {
            dispatch(getListAgencyAction(filter));
        },
        fetchViolateReasons: (filter = {}) => {
            filter.scope = 'basic';
            dispatch(fetchViolateReasons({...filter, status: 'ACTIVE'}));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(translate()(ListPurchase))
