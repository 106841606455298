import {combineReducers} from 'redux';
import Home from '../modules/home/reducer'
import {loadingBarReducer} from 'react-redux-loading-bar';
import Common from '../modules/common/reducers';
import {reducer as toastrReducer} from 'react-redux-toastr';
import PurchaseAddressReducer from '../modules/PurchaseAddress/reducers';
import Order from '../modules/Order/reducer';
import Purchase from '../modules/Purchase/reducer';
import User from '../modules/User/reducer';
import orderGroup from '../modules/OrderGroupManager/reducer';
import AccountPurchaser from '../modules/AccountPurchaser/reducer';
import PurchaseBuying from '../modules/PurchaseBuying/reducer';
import ViolatePolicyOrder from '../modules/ViolatePolicyOrder/reducer';
import Services from '../modules/Services/reducer';
import CommonLog from './../modules/common/Log/reducer';
import ViolateReasons from './../modules/ViolateReason/reducers';
import ComplaintSeller from './../modules/ComplaintSeller/reducer';
import OrderBillNoReducer from './../modules/OrderBillNo/reducer'
import SettingReducer from './../modules/Settings/reducer';
import WarehouseReducer from './../modules/Warehouse/reducer';
import SalesFigures from './../modules/SalesFigures/reducer';
import ComplaintProductReturnReducer from './../modules/ComplaintProductReturn/reducer';
import AgencyReducer from './../modules/Agency/reducer';
import Transaction from './../modules/Transaction/reducer';
import Features from '../modules/Features/reducer';


const rootReducer = combineReducers({
    Home: Home,
    loadingBar: loadingBarReducer,
    Common: Common,
    toastr: toastrReducer,
    User: User,
    PurchaseAddress: PurchaseAddressReducer,
    orderGroup: orderGroup,
    AccountPurchaser: AccountPurchaser,
    ViolateReasons: ViolateReasons,
    PurchaseBuying: PurchaseBuying,
    Services: Services,
    Order: Order,
    Purchase: Purchase,
    CommonLog: CommonLog,
    SalesFigures: SalesFigures,
    ViolatePolicyOrder: ViolatePolicyOrder,
    OrderBillNo: OrderBillNoReducer,
    ComplaintSeller: ComplaintSeller,
    Setting: SettingReducer,
    Warehouse: WarehouseReducer,
    complainProductReturn: ComplaintProductReturnReducer,
    Agency: AgencyReducer,
    Transaction,
    Features
});
export default rootReducer;
