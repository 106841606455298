import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {Select} from 'antd';
import {accountBankService} from "../services";
const {Option} = Select;

class SelectAccountBanks extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: false,
        };
    }

    componentDidMount = () => {
        this.fetchAccountPurchasers();
    };

    fetchAccountPurchasers() {
        this.setState({loading: true});
        accountBankService.list(this.props.filter)
            .then(data => this.setState({data}))
            .finally(() => this.setState({loading: false}));

    }

    getValue() {
        let {value, multiple} = this.props;
        if (!value) return undefined;
        if (multiple) {
            return Array.isArray(value) ? value.map(item => parseInt(item)) : [parseInt(value)];
        } else {
            return parseInt(value);
        }
    }

    render() {
        let {t, searchByAccountName} = this.props;

        return (
            <Select
                showSearch={true}
                optionFilterProp={"children"}
                mode={this.props.multiple ? 'multiple' : 'default'}
                value={this.state.loading ? undefined : this.getValue()}
                allowClear={this.props.allowClear}
                loading={this.state.loading}
                onChange={this.props.onChange}
                className={this.props.className}
                placeholder={this.props.placeholder}
            >
                {this.props.allOption && <Option value="">{t('label.all')}</Option>}
                {this.state.data.map(bank => <Option key={bank.id}
                                                     value={bank.id}>{searchByAccountName ? bank.account_name :  bank.account_name + " - " + bank.account_number + " - " + bank.bank_name}</Option>)}
            </Select>
        );
    }
}

SelectAccountBanks.defaultProps = {
    value: undefined,
    allOption: false,
    multiple: false,
    onChange: value => {
    },
    onBlur: value => {
    },
    className: '',
    placeholder: '',
    filter: undefined,
    searchByAccountName: false
};

SelectAccountBanks.propTypes = {
    value: PropTypes.any,
    allOption: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    placeholer: PropTypes.string,
    filter: PropTypes.object,
    searchByAccountName: PropTypes.bool
};

export default translate()(SelectAccountBanks);
