import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {Select, Row, Col} from 'antd';
import Button from '@atlaskit/button';
import CompleteComplaint from '../../CompleteComplaint/CompleteComplaintContainer';
import ReceiveComplaint from '../../ComplaintReception/ReceiveComplaintContainer';
import RejectComplaint from "../../ComplaintRejection/components/RejectComplaint";
import FailedComplaint from '../../FailedComplaint/FailedComplaintContainer';
import authService from "../../../Auth/authService";
import * as PERMISSION from "../../../Permission/permissions";
import UpdateComplaintSellerReason from "./UpdateComplaintSellerReason";
import TimeLines from "./TimeLines";

const {Option} = Select;

class PerformAction extends React.Component {
    constructor() {
        super();
        this.state = {
            isOpenModalChangeSuccess: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (prevState.isOpenModalChangeSuccess === true && nextProps.isModalChangeSuccessProcessing === false) {
            nextState.isOpenModalChangeSuccess = false;
        }

        return nextState;
    }

    /**
     * handle việc mở popup change status
     */
    openModalChangeStatus() {
        this.setState({
            isOpenModalChangeSuccess: true,
        });
        this.props.openModalChangeSuccessForm();
        this.props.clearErrorComplaintSellerSuccess();
    }

    render() {
        let {order, complaint, permissions, t, complaintSellerReasons} = this.props;
        let {isOpenModalChangeSuccess} = this.state;

        return (
            <div className="a-content--box-shadow flex--column--reverse-md mb-3 p-0">
                <div className="a__complaint__detail__title p-3 pt-2 pb-2">
                    <div className="title__left d-flex">
                        <h2 className="a-text--fz-16 a-text--color-black-85">
                            THÔNG TIN KHIẾU NẠI
                        </h2>
                        <span className={"a-status ml-2 mr-2 a-status--" + (complaint.status)}>
                            {complaint.status ? t('complaintSeller:status.' + complaint.status.toLowerCase()) : null}
                        </span>
                    </div>
                    <div className="group__button">
                        {
                            permissions.can_reject &&
                            lodash.isEmpty(complaint.receiver) &&
                            authService.canAny([PERMISSION.COMPLAINT_SELLER_ASSIGN, (PERMISSION.COMPLAINT_SELLER_RECEIVE)]) &&
                            authService.canAny([PERMISSION.COMPLAINT_SELLER_CHANGE_FAILURE, (PERMISSION.COMPLAINT_SELLER_CHANGE_FAILURE_ME)]) &&
                            <RejectComplaint complaint={complaint}/>
                        }

                        {
                            permissions.can_receive ?
                                <ReceiveComplaint complaint={complaint}/> :
                                null
                        }

                        <Button
                            className={permissions.can_update_success ? "a-btn a-btn--green" : "hidden"}
                            onClick={this.openModalChangeStatus.bind(this)}
                        >
                            <i className="ion-android-checkmark-circle mr-2"/>
                            {t('complaintSeller:status.success')}
                        </Button>

                        {
                            permissions.can_update_failure ?
                                <FailedComplaint complaint={complaint}/> :
                                null
                        }

                        {
                            isOpenModalChangeSuccess ?
                                <CompleteComplaint complaint={complaint}/> :
                                null
                        }
                    </div>
                </div>
                <div className="border-top p-3 pt-1 pb-1">
                    <Row>
                        <Col span={6}>
                            <UpdateComplaintSellerReason
                                complaint={complaint}
                                complaintSellerReasons={complaintSellerReasons}
                            />
                        </Col>
                        <Col span={18}>
                            <TimeLines complaint={complaint}/>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

PerformAction.defaultProps = {
    order: {},
    complaint: {},
    permissions: {},
};

PerformAction.propTypes = {
    order: PropTypes.object,
    complaint: PropTypes.object,
    permissions: PropTypes.object,
};

export default translate()(PerformAction);
