import React from 'react';
import {translate} from 'react-i18next';
import Button from '@atlaskit/button';
import PropTypes from 'prop-types';
import {trans} from '../../../../../systems/i18n';
import ModalGeneral from './../../../../common/components/ModalGeneral';

class ModalConfirmPaidInvoiceCode extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {onClose, message, isLoading, onConfirm} = this.props;

        return (
            <ModalGeneral>
                <div className={"a-modal__title"}>
                    <p >{this.props.title}</p>
                </div>
                <div style={{wordBreak: "break-all"}}>
                    {message}
                </div>
                <div className="a--group--inline-end p-2 a--user-group-btn ">
                    <Button appearance="primary" type="submit" className=" mr-3 a-btn--submit"
                            onClick={onConfirm}
                            isLoading={isLoading}
                    >
                        {trans('order_detail.btn_action_confirm')}
                    </Button>
                    <a
                        appearance="subtle"
                        onClick={onClose}
                        className={(isLoading ? "hidden " : "") + "a-btn a-btn--none a-btn-link "}
                    >
                        {trans('order_detail.btn_action_cancel')}
                    </a>
                </div>
            </ModalGeneral>
        );
    }
}

ModalConfirmPaidInvoiceCode.defaultProps = {
    title: "",
    message: ""
};

ModalConfirmPaidInvoiceCode.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func
};

export default translate('translations')(ModalConfirmPaidInvoiceCode);
