import {createAction} from 'redux-actions';
import * as Constants from './constants';

/**
 * Hành động tạo mới yêu cầu report
 */
export const createReports = createAction(Constants.CREATE_EXPORT_REPORT.REQUEST);
/**
 * Hành động thêm mới yêu cầu thanh toán
 */
export const newRequestReport = createAction(Constants.NEW_REQUEST_REPORT.REQUEST);
/**
 * Hành động lấy danh sách report
 */
export const getListReports = createAction(Constants.GET_LIST_REPORTS.REQUEST);
/**
 * Hành động lấy report đang thực hiện
 */
export const getCurrentReports = createAction(Constants.GET_CURRENT_REPORT.REQUEST);
/**
 * Hành động hủy yêu cầu tạo report
 */
export const createCancelReports = createAction(Constants.CANCEL_EXPORT_REPORT.REQUEST);

/**
* Hành động thực hiện pusher
*/
export const getPusherReports = createAction(Constants.GET_PUSHER_REPORT, (datas) => (datas));

/**
 * Xóa dữ liệu validate
 */
export const clearErrorWhileCreate = createAction(Constants.CLEAR_ERROR_REPORT, (param) => ({param}));

