import React from 'react';
import {Form, Row, Col, Input, Select, DatePicker, Button, Icon} from 'antd';

const Search = Input.Search;
const Option = Select.Option;
const {RangePicker} = DatePicker;


class FormSearch extends React.Component {
    render() {
        return (
            <div className="a-list--form-search mr-0 ml-0">
                <Row gutter={32}>
                    <Form labelCol={{span: 12}}>
                        <Col lg={6} xl={5} xxl={4}>
                            <Form.Item label="Nhân viên" className="a-form--ant-item  mb-0">
                                <Search/>
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={4} xxl={4}>
                            <Form.Item label="Nhà cung cấp " className="a-form--ant-item  mb-0">
                                <Select placeholder="Chọn nhà cung cấp">
                                    <Option value="jack">Jack</Option>
                                    <Option value="lucy">Lucy</Option>
                                    <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={6} xl={4} xxl={4}>
                            <Form.Item label="Tài khoản" className="a-form--ant-item  mb-0">
                                <Select placeholder="Chọn tài khoản">
                                    <Option value="jack">Jack</Option>
                                    <Option value="lucy">Lucy</Option>
                                    <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={7} xl={6} xxl={5}>
                            <Form.Item label="Thời gian cấp" className="a-form--ant-item  mb-0">
                                <RangePicker placeholder={['Từ ngày', 'Đến ngày']}/>
                            </Form.Item>
                        </Col>
                        <Col xs={{span: 24}} lg={{span: 24}} xl={{span: 24}} className="mt-4 pt-5 border-top-dashed">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="a-list--action-search flex-row ml-auto">
                                    <a href="#" className="link-reset mr-5"><Icon type="sync"/>Làm mới bộ lọc</a>
                                    <Button className="a-text--uppercase a-btn--padding-large a-btn--primary-ant">Tìm
                                        kiếm</Button>
                                </div>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </div>

        )
    }
}

export default FormSearch;
