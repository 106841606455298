import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import moment from 'moment';
import lodash from 'lodash';
import {DatePicker, Divider} from 'antd';

class DateRangeCustomize extends React.PureComponent {
    onChange(name, value) {
        const {from, to} = this.props;
        const date = (name === "from") ? [this.formatValue(value), to ? to : undefined] : [from ? from : undefined, this.formatValue(value)];
        this.props.onChange(date[0], date[1])
    }

    formatValue(date) {
        return date ? date.format(this.props.valueFormat) : undefined;
    }

    render() {
        let {t, format, valueFormat, from, to, showTime, className, disabledDate} = this.props;

        return (
            <div className={`${className}`}>
                <DatePicker
                    style={{width: "48%"}}
                    disabledDate={disabledDate}
                    showTime={showTime ? {
                        ...showTime,
                        defaultValue: lodash.get(showTime, 'defaultValue.0', moment('00:00:00', 'HH:mm:ss')),
                    } : undefined}
                    placeholder={t('label.date_from')}
                    value={from ? moment(from, valueFormat) : undefined}
                    format={format}
                    onChange={(value) => this.onChange("from", value)}
                    readOnly={true}
                />
                <DatePicker
                    style={{width: "48%", marginLeft: "4%"}}
                    disabledDate={disabledDate}
                    showTime={showTime ? {
                        ...showTime,
                        defaultValue: lodash.get(showTime, 'defaultValue.1', moment('23:59:59', 'HH:mm:ss')),
                    } : undefined}
                    placeholder={t('label.date_to')}
                    value={to ? moment(to, valueFormat) : undefined}
                    format={format}
                    onChange={(value) => this.onChange("to", value)}
                />
            </div>
        );
    }
}

DateRangeCustomize.defaultProps = {
    from: null,
    to: null,
    format: 'DD/MM/YYYY',
    valueFormat: 'YYYY-MM-DD',
    placeholder: [],
    className: '',
    disabledDate: () => {},
    onChange: (from, to) => {
    },
};

DateRangeCustomize.propTypes = {
    disabledDate: PropTypes.func,
    className: PropTypes.string,
    from: PropTypes.any,
    to: PropTypes.any,
    format: PropTypes.string,
    valueFormat: PropTypes.string,
    placeholder: PropTypes.array,
    onChange: PropTypes.func,
};

export default translate()(DateRangeCustomize);
