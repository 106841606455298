import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import lodash from 'lodash';
import {Icon} from 'antd';
import {withEvent} from '../../../systems/events';
import INVOICE_EVENT from '../events';
import {CRAWL_STATUS} from '../constans';
import SourceInvoiceCrawler from '../services/SourceInvoiceCrawler';

class SyncSourceInvoice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.crawler = new SourceInvoiceCrawler();
    }

    componentDidMount() {
        this.crawler.boot();
        this.props.on([
            INVOICE_EVENT.CRAWL_START,
            INVOICE_EVENT.CRAWL_FINISH,
            INVOICE_EVENT.CRAWL_CANCEL,
        ], this.invoiceEventListener.bind(this));
    }

    componentWillUnmount() {
        this.crawler.terminate();
    }

    crawl() {
        if (!this.state.loading) {
            this.setState({loading: true});
            this.crawler.crawl([this.props.order]);
        }
    };

    invoiceEventListener(event, payload) {
        if (payload.request !== this.crawler.request) {
            return;
        }

        switch (event) {
            case INVOICE_EVENT.CRAWL_START:
                return this.setState({loading: true});

            case INVOICE_EVENT.CRAWL_FINISH:
            case INVOICE_EVENT.CRAWL_CANCEL:
                this.setState({loading: false});
        }
    }

    render() {
        let {order} = this.props;

        if (lodash.isEmpty(order.invoice_codes)) {
            return null;
        }

        switch (order.crawl_status) {
            case CRAWL_STATUS.PENDING:
                return <Icon type="minus-circle"/>;
            case CRAWL_STATUS.PROCESSING:
                return <Icon type="sync" spin/>;
            default:
                return <Icon type="sync" className="a-text--color-primary a-text--pointer" onClick={this.crawl.bind(this)}/>;
        }
    }
}

SyncSourceInvoice.defaultProps = {
    order: {},
};

SyncSourceInvoice.propTypes = {
    order: PropTypes.object,
};

export default translate()(withEvent(SyncSourceInvoice));