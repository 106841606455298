import React from 'react';
import {Layout} from "antd";
import DetailTransaction from "./components";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {getTransactionDetail, updateTransaction} from "./actions";
import {setPageTitle, setBreadCrumb, setPageDescription, setMenuActive} from './../../common/actions';
import {clearState} from "./actions";
import * as Selector from "./selectors";
import {downloadFile} from "../ListTransactionFile/actions";
import {withEvent} from "../../../systems/events";
import TRANSACTION_EVENT from "../events";
import lodash from 'lodash';

const Content = Layout.Content;

const mapStateToProps = (state, props) => {
    return {
        transaction: Selector.getDetailTransaction(state),
        orders: Selector.getOrders(state),
        loading: state.loading
    }
};

const mapDispatchToProps = (dispatch) => ({
    getTransactionDetail: (filter) => {
        dispatch(getTransactionDetail(filter));
    },
    setPageTitle: (value) => {
        dispatch(setPageTitle(value));
    },
    setBreadCrumb: (items) => {
        dispatch(setBreadCrumb(items));
    },
    setPageDescription: (value) => {
        dispatch(setPageDescription(value));
    },
    setMenuActive: (menu) => {
        dispatch(setMenuActive(menu));
    },
    updateTransaction: (id, data) => {
        dispatch(updateTransaction(id, data));
    },
    downloadFile: (id, filename) => {
        dispatch(downloadFile({id: id, filename: filename}));
    },
    clearState: () => dispatch(clearState()),
});

class DetailTransactionContainer extends React.Component{
    componentDidMount() {
        this.props.on({
            [TRANSACTION_EVENT.RESOLVED]: (event, payload) => this.props.updateTransaction(payload.transaction.id, lodash.pick(payload.transaction, ['is_resolved', 'note_resolved', 'resolved_creator', 'resolved_time', 'unusual_type', 'is_ok', 'is_ok_note'])),
            [TRANSACTION_EVENT.UNRESOLVED]: (event, payload) => this.props.updateTransaction(payload.transaction.id, lodash.pick(payload.transaction, ['is_resolved', 'note_resolved', 'resolved_creator', 'resolved_time', 'unusual_type', 'is_ok', 'is_ok_note'])),
            [TRANSACTION_EVENT.MARKED]: (event, payload) => this.props.updateTransaction(payload.transaction.id, lodash.pick(payload.transaction, ['is_ok', 'is_ok_note', 'is_ok_time', 'is_ok_creator', 'is_ok_creator_id', 'is_resolved', 'note_resolved', 'resolved_creator', 'resolved_time', 'unusual_type'])),
            [TRANSACTION_EVENT.NOTE_UPDATED]: (event, payload) => this.props.updateTransaction(payload.transaction.id, payload.transaction),
            [TRANSACTION_EVENT.ADD_RELATED_TRANSACTION]: (event, payload) => this.props.updateTransaction(payload.transaction.id, lodash.pick(payload.transaction, ['transactionRelated', 'transactionRelatedTo', 'is_ok', 'is_ok_note', 'is_ok_time', 'is_ok_creator', 'is_ok_creator_id', 'is_resolved', 'note_resolved', 'resolved_creator', 'resolved_time', 'unusual_type'])),
            [TRANSACTION_EVENT.DELETE_RELATED_TRANSACTION]: (event, payload) => this.props.updateTransaction(payload.transaction.id, lodash.pick(payload.transaction, ['transactionRelated', 'transactionRelatedTo', 'is_ok', 'is_ok_note', 'is_ok_time', 'is_ok_creator', 'is_ok_creator_id', 'is_resolved', 'note_resolved', 'resolved_creator', 'resolved_time', 'unusual_type']))
        });
    }
    render() {
        return (
            <Layout>
                <Content className="a-content--ant-layout">
                    <DetailTransaction {...this.props}/>
                </Content>
            </Layout>
        );
    }
}

DetailTransactionContainer.defaultProps = {
    loading: false,
};

DetailTransactionContainer.propTypes = {
    loading: PropTypes.bool,
    downloadFile: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withEvent(DetailTransactionContainer))

