import {call, put} from 'redux-saga/effects'
import * as Constants from './constants';
import service from './apiService';
import bootbox from "../../modules/common/components/Bootbox/bootbox";
import {t} from './../../systems/i18n/index.js';
import * as GlobalConstants from "../../systems/globalContant";

export function* fetchWarehouse(action) {
    try {
        let response = yield call(service.getListWarehouse, action.payload);
        if (response) {
            if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
                yield put({type: Constants.GET_LIST_WAREHOUSE.SUCCESS, payload: response.data});
            } else {
                bootbox.error(t('message.system_errors'));
            }
        }
    } catch (err) {
        bootbox.error(t('message.system_errors'));
    }
}