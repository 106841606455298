import {createAction} from 'redux-actions';
import * as Constants from './constants';

/**
 * Reset purchase form
 */
export const resetErrorPurchaseAddress = createAction(
    Constants.RESET_ERROR_INPUT_PURCHASE_ADDRESS.REQUEST, (params = {}) => ({params})
);

/**
 * Get purchase address list
 */
export const getPurchaseAddressList = createAction(Constants.PURCHASE_ADDRESS.REQUEST, params => params);

/**
 * Get purchase address
 */
export const getPurchaseAddress = createAction(Constants.PURCHASE_ADDRESS_DETAIL.REQUEST, (id, params = {}) => ({id, params}));

/**
 * Create purchase address list
 */
export const createPurchaseAddress = createAction(Constants.PURCHASE_ADDRESS_CREATE.REQUEST, (params = {}) => ({params}));

/**
 * Update purchase address
 */
export const updatePurchaseAddress = createAction(Constants.PURCHASE_ADDRESS_UPDATE.REQUEST, (id, params = {}) => ({id, params}));

/**
 * Update purchase address
 */
export const updateStatusPurchaseAddress = createAction(Constants.UPDATE_STATUS_ADDRESS_PURCHASE.REQUEST, (id, params = {}) => ({id, params}));

