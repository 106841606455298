import {call, put} from 'redux-saga/effects';
import lodash from 'lodash';
import * as Constants from './constants';
import * as GlobalConstants from '../../systems/globalContant';
import bootbox from './../common/components/Bootbox/bootbox';
import {t} from './../../systems/i18n/index.js';
import apiService from './apiService';
import {dispatch} from './../../systems/store';
import {fetchProductReturnLogs} from './actions';

/**
 * Hàm lấy tạo yêu cầu trả hàng
 * @param action
 */
export function* fetchProductReturn(action) {

    try {
        let response = yield call(apiService.fetchProductReturn, action.payload);
        if(!response) {
            return;
        }
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.FETCH_COMPLAINT_PRODUCT_RETURN.SUCCESS, payload: response.data});
        } else {
            bootbox.error(t('complaintSeller:product_return.message_get_product_return_failed'));
            yield put({
                type: Constants.FETCH_COMPLAINT_PRODUCT_RETURN.FAILED, payload: {...response.data, ...action.payload}
            });
        }
    } catch (err) {
        yield put({type: Constants.FETCH_COMPLAINT_PRODUCT_RETURN.FAILED, payload: {}});
        bootbox.error(t(err.message) || t('system.error'));

    }
}


/**
 * Hàm lấy danh sách yêu cầu trả hàng
 * @param action
 */
export function* fetchListProductReturns(action) {
    try {
        let response = yield call(apiService.fetchListProductReturns, action.payload);
        if(!response) {
            return;
        }
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.FETCH_LIST_PRODUCT_RETURNS.SUCCESS, payload: response.data});
        } else {
            bootbox.error(t('complaintSeller:product_return.message_get_product_return_failed'));
            yield put({type: Constants.FETCH_LIST_PRODUCT_RETURNS.FAILED, payload: response.data});
        }
    } catch (err) {
        yield put({type: Constants.FETCH_LIST_PRODUCT_RETURNS.FAILED, payload: {}});
        bootbox.error(t(err.message) || t('system.error'));
    }
}

/**
 * hàm xử lý update status của YCTH
 * @param action
 */
export function* updateStatusProductReturn(action) {
    try {
        let response = yield call(apiService.changeStatusProductReturn, action.payload);
        if(!response) {
            return;
        }
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            bootbox.success(t('productReturn:detail.message.change_status_success'));
            yield put({type: Constants.UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.SUCCESS, payload: response.data});
        } else {
            if ( response.data.code !== GlobalConstants.GLOBAL_CONTANT.PERMISSION_DENIED) {
                let status = Object.keys(lodash.get(response,'data.data.status',{}));
                if( lodash.includes(status,'info_invalid_for_change_state') ){
                    bootbox.error(t('productReturn:detail.message.change_status_fail_less_than'));
                    yield put({type: Constants.UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.FAILED, payload: response.data});
                }else{
                    yield put({type: Constants.UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.FAILED, payload: response.data});
                    bootbox.error(t('productReturn:detail.message.change_status_fail'));
                }
            }
        }
    } catch (err) {
        yield put({type: Constants.UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.FAILED, payload: err});
        bootbox.error(t(err.message) || t('system.error'));
    }
}

/**
 * Hàm cập nhật yêu cầu trả hàng
 * @param action
 */
export function* updateInfoProductReturn(action) {
    try {
        let {payload} = action;
        for (let key in payload) {
            if (!payload.hasOwnProperty(key)) continue;
            if (payload[key] === null) {
                payload[key] = key === 'paid_cost' || key === 'cost' ?   0 : '';
            }
        }

        let response = yield call(apiService.updateInfoProductReturn, payload);
        if(!response) {
            return;
        }
        if (response.data.code === GlobalConstants.GLOBAL_CONTANT.SUCCESS_CODE) {
            yield put({type: Constants.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.SUCCESS, payload: response.data});
            setTimeout(() => dispatch(fetchProductReturnLogs(action.payload.id)), 2000);
            bootbox.success(t('complaintSeller:product_return.message_update_info_product_return_success'));
        } else {
            if (response.data.code !== GlobalConstants.GLOBAL_CONTANT.PERMISSION_DENIED) {
                if (response.data.code === "INPUT_INVALID" && lodash.get(response.data, 'data.status.status_invalid', false)) {
                    bootbox.error(t('productReturn:detail.message.change_status_fail'));
                }
            }
            yield put({type: Constants.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.FAILED, payload: response.data});
        }
    } catch (err) {
        yield put({type: Constants.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.FAILED, payload: {}});
        bootbox.error(t(err.message) || t('system.error'));
    }
}