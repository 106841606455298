import {createSelector} from 'reselect';
import lodash from 'lodash';
import {dateFormatter} from '../../common/services/format';
import {getAllUser} from './../../User/selectors';
import * as helpers from './../../common/helps'
import {t} from './../../../systems/i18n';

const listTransactionFiles = state => lodash.get(state, "Transaction.ListTransactionFile.list", []);
export const pagination = state => lodash.get(state, "Transaction.ListTransactionFile.pagination", []);
export const permission = state => lodash.get(state, "Transaction.ListTransactionFile.permission", {})

export const getListTransactionFiles = createSelector(
    listTransactionFiles,
    getAllUser,
    (files, users) => lodash.orderBy(files, ['id'], ['desc']).map(file => {
        file = {...file};
        let creator = lodash.find(users, {id: file.id_creator});
        file.creatorDisplay = (creator) ? creator.name + " (" + creator.username + ")" : "--";
        file.created = dateFormatter.dateTime(file.created_at);
        file.transaction_time_start = dateFormatter.date(file.transaction_time_start);
        file.transaction_time_end = dateFormatter.date(file.transaction_time_end);
        file.errorDisplay = [];

        let errors = file.errors ? JSON.parse(file.errors) : [];
        errors.map(error => {
            try {
                var objError = JSON.parse(error);
            } catch (e) {
                file.errorDisplay.push(
                    {
                        key: '--',
                        code: '--',
                        line: '--',
                        description: 'Lỗi không xác định',
                    }
                );
                return;
            }

            lodash.mapValues(objError, (fields, transCode) => {
                if (lodash.isEmpty(fields)) {
                    file.errorDisplay.push(
                        {
                            key: transCode,
                            code: transCode,
                            line: '--',
                            description: 'Lỗi không xác định',
                        }
                    );
                    return;
                }

                lodash.mapValues(fields, (rules, field) => {
                    if (lodash.isEmpty(rules)) {
                        file.errorDisplay.push(
                            {
                                key: transCode+'.'+field,
                                code: transCode,
                                line: '--',
                                description: 'Lỗi không xác định',
                            }
                        );
                        return;
                    }

                    let attribute = t('transaction:label.' + field);
                    let messages = helpers.translateValidationErrors(attribute, rules);

                    file.errorDisplay.push(
                        {
                            key: transCode+'.'+field,
                            code: transCode,
                            line: '--',
                            description: lodash.first(lodash.values(messages))
                        }
                    );
                });
            });
        });

        return file;
    })
);

