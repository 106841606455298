import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import ResolveTransaction from './ResolveTransaction';
import UnresolveTransaction from './UnresolveTransaction';

function ResolvingTransaction(props) {
    let {transaction} = props;

    if (!transaction.id || transaction.is_ok) {
        return null;
    }

    return transaction.is_resolved
        ? <UnresolveTransaction transaction={transaction}/>
        : <ResolveTransaction transaction={transaction}/>;
}

ResolvingTransaction.defaultProps = {
    transaction: {},
};

ResolvingTransaction.propTypes = {
    transaction: PropTypes.object,
};

export default translate()(ResolvingTransaction);
