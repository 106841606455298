import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import lodash from "lodash";
import {Icon, Tooltip} from "antd";
import Payment from "./../OtherInfo/Payment";
import Paid from "./../OtherInfo/Paid";
import ActionExtend from "./ActionExtend";
import CreateExternalPaymentRequest from "../../../../PaymentRequest/CreateExternalPaymentRequestButton";
import Spinner from "@atlaskit/spinner";
import WaitingPayment from "../OtherInfo/WaitingPayment";
import { currentFormatter } from '../../../../common/services/format'

class QuickView extends React.Component {

    hasAutoPai(items) {
        let autoPaiList = items.filter(item => item.qty_bought !== 0);
        return Boolean(autoPaiList.length);
    }

    render() {
        let {
            t, order, error,
            onPaid, onPayment,
            onConfirm,
            isLoadingData,
            isFilterByPai,
            isFilterCustomerNote,
            changeFilterCustomerNote,
            changeFilterBy,
            onWaitingPayment
        } = this.props;

        let payment_status = lodash.get(isLoadingData, 'postPaymentOrder', false);
        let waiting_payment_status = lodash.get(isLoadingData, 'postWaitingPaymentOrder', false);
        const canShowDiscountAmount = lodash.get(order, "canShowDiscountAmount", false);
        const showCurrencyVND = lodash.get(order, "showCurrencyVND", false)
        return (
            <React.Fragment>
                <ul className="list_bottom">
                    <li className={"not-border-right hidden-sm action-filter"}>
                        <Tooltip title={t('orderDetail:tooltip.fillter_by_item_pai_success')}>
                            <div
                                onClick={e => changeFilterBy("pai_success", e)}
                                className={(isFilterByPai === "pai_success" ? "" : "a-text--font-b") + " flex--center pr_success a--cursor-poiter"}>
                                <i className={(isFilterByPai === "pai_success" ? "text-success " : "") + " fas fa-check mr-1  "}/>
                                <span>{order.total_item_pai_success()}</span>
                            </div>
                        </Tooltip>
                    </li>
                    <li className={"not-border-right hidden-sm action-filter"}>
                        <Tooltip title={t('orderDetail:tooltip.fillter_by_item_pai_fail')}>
                            <div
                                onClick={e => changeFilterBy("pai_fail", e)}
                                className={(isFilterByPai === "pai_fail" ? "" : "a-text--font-b") + " flex--center pr_error a--cursor-poiter"}>
                                <i className={(isFilterByPai === "pai_fail" ? "a-text--danger " : "") + " fas fa-exclamation-triangle mr-1  "}/>
                                <span>{order.total_item_pai_fail()}</span>
                            </div>
                        </Tooltip>
                    </li>

                    <li className="action-filter">
                        <Tooltip title={t('orderDetail:tooltip.total_customer_comment')}>
                            <div
                                onClick={changeFilterCustomerNote}
                                className={(isFilterCustomerNote ? "" : " a-text--font-b") + " flex--center a--cursor-poiter "}
                            >
                                <i className={(isFilterCustomerNote ? "a-text--font-blue " : " ") + " far fa-envelope mr-1  "}/>
                                {order.total_customer_comment()}
                            </div>
                        </Tooltip>
                    </li>

                    <li className={"not-border-right hidden-sm"}>
                        {
                            this.hasAutoPai(lodash.get(order, 'items', [])) ?
                                <a href="" onClick={(event) => event.preventDefault()}
                                   className="bottom__autopai">
                                    P
                                </a> : null
                        }
                    </li>
                    <li className="hidden-sm not-border-right">
                        <div className="list_bottom-block">
                            <span className="a-text color-gray">Số SP</span>
                            <span className="a-text-value">{order.total_item_pai_success()}/{order.total_link}</span>
                        </div>
                    </li>
                    <li className=" hidden-sm not-border-right">
                        <div className="list_bottom-block">
                            <span className="a-text color-gray">{t('order_detail.link')}</span>
                            <span className="a-text">{order.total_link}</span>
                        </div>
                    </li>
                    <li className="hidden-sm not-border-right">
                        <div className="list_bottom-block">
                            <span className="a-text color-gray">{t('order_detail.quantity')}</span>
                            <span className="a-text total_qty_product">
                            <Tooltip title={t('orderDetail:tooltip.total_qty_product')}>
                                <span>{order.total_qty()}/</span>
                            </Tooltip>
                            <Tooltip title={t('orderDetail:tooltip.total_qty_bought_product')}>
                                <span className="a-text--font-blue a-text--bold">{order.total_qty_bought()}/</span>
                            </Tooltip>
                            <Tooltip title={t('orderDetail:tooltip.total_qty_received_product')}>
                                <span>{((order.canShowQuantityReceived && order.is_checked)) ? order.total_item_qty_received : '---'}</span>
                            </Tooltip>
                        </span>
                        </div>
                    </li>
                    <li className=" hidden-sm not-border-right">
                        <div className="list_bottom-block">
                            <span className="a-text color-gray">{t('order_detail.product')}</span>
                            <span className="a-text">{order.grand_total_bought_text}</span>
                            {
                                showCurrencyVND &&
                                <span className="a-font--10">{currentFormatter.toLocaleStringCurrencyVND(order.grand_total_bought_vnd)}₫</span>
                            }
                        </div>
                    </li>
                    <li className="hidden-sm ">
                        <div className="list_bottom-block">
                            <Tooltip title={t("order_detail.fee_transport_tq_tooltip")} mousePosition="top">
                            <span className="a-text color-gray">
                                {t('order_detail.fee_transport_tq')}
                            </span>
                            </Tooltip>
                            <span className="a-text">{order.total_shipping_fee_inland_text}</span>
                            {
                                showCurrencyVND &&
                                <span className="a-font--10">{currentFormatter.toLocaleStringCurrencyVND(order.total_shipping_fee_inland_vnd)}₫</span>
                            }
                        </div>
                    </li>
                    {
                        canShowDiscountAmount &&
                        <li className="hidden-sm ">
                            <div className="list_bottom-block">
                                <Tooltip title={t("order_detail.fee_transport_tq_tooltip")} mousePosition="top">
                            <span className="a-text color-gray">
                                {t('order_detail.discount_amount')}
                            </span>
                                </Tooltip>
                                <span className="a-text">{order.discount_amount_text}</span>
                                {
                                    showCurrencyVND &&
                                    <span className="a-font--10">{currentFormatter.toLocaleStringCurrencyVND(order.discount_amount_vnd)}₫</span>
                                }
                            </div>
                        </li>
                    }

                    <li>
                        <div className="list_bottom-block">
                            <span className="a-text--uppercase a-font--14 color-gray">{t('order_detail.sum')}</span>
                            <span
                                className="a-text--bold a-text--font-blue a-font--14">{order.grand_total__text}</span>
                            {
                                showCurrencyVND &&
                                <span className="a-font--10">{currentFormatter.toLocaleStringCurrencyVND(order.grand_total_vnd)}₫</span>
                            }
                        </div>
                    </li>
                    <li className="not-border-right">
                        <div className="d-flex ml-2">
                            <Paid
                                confirm={lodash.get(error, 'paidOrder')}
                                canChangePaid={order.canChangePaid}
                                onPaid={onPaid}
                                isLoading={lodash.get(isLoadingData, 'postPaidOrderStatus', false)}
                                onConfirm={onConfirm}
                                id={order.id}
                            />
                            {
                                (order.canChangeWaitingPay || waiting_payment_status) ?
                                    <WaitingPayment
                                        confirm={lodash.get(error, 'paymentOrder')}
                                        canChangeWaitingPay={order.canChangeWaitingPay}
                                        onWaitingPayment={onWaitingPayment}
                                        onConfirm={onConfirm}
                                        id={order.id}
                                        isLoading={waiting_payment_status}
                                    />
                                    :
                                    (
                                        (order.canChangePay || payment_status) ?
                                            <Payment
                                                confirm={lodash.get(error, 'paymentOrder')}
                                                canChangePay={order.canChangePay}
                                                onPayment={onPayment}
                                                onConfirm={onConfirm}
                                                isLoading={payment_status}
                                                id={order.id}
                                            /> :
                                            <CreateExternalPaymentRequest order={order}>
                                                <button className={`ml-2 a-btn a-btn--payment`}>
                                                    <Icon type="plus-square" theme={"filled"}
                                                          style={{fontSize: '1em'}}/>
                                                    <span
                                                        className="font-weight-bold ml-2 a-text--white">Tạo YCTT</span>
                                                </button>
                                            </CreateExternalPaymentRequest>
                                    )
                            }
                        </div>
                    </li>
                </ul>
            </React.Fragment>
        );
    }
}

QuickView.defaultProps = {
    isLoadingData: {}
};

QuickView.propTypes = {
    isLoadingData: PropTypes.object,
};

export default translate('translations')(QuickView);
