import React from "react";
import lodash from "lodash";
import {AUTO_PAYMENT_EVENT, AUTO_PAYMENT_SYNC_EVENT} from "../constans";
import {events} from "../../../systems/events";

class handleProcessPayment {

    constructor() {
        this.handMessage = this.handMessage.bind(this);
        this.init();
        this.request = {};
    }

    init() {
        window.addEventListener("message", this.handMessage, false);
    }

    terminate() {
        window.removeEventListener("message", this.handMessage, false);
    }

    boot(request) {

        this.request = {...request};
        // Bắn message tự động thanh toán với site gốc
        window.postMessage({
            type: AUTO_PAYMENT_EVENT.START,
            payload: this.refactorRequest()
        });
    }

    cancel() {
        window.postMessage({
            type: AUTO_PAYMENT_EVENT.CANCEL
        });
    }

    handMessage(event) {
        let {data} = event;
        if (lodash.get(data, "type", "")) {
            console.log("handle message từ tool gửi tới m5", data);
        }
        switch (data.type) {
            case AUTO_PAYMENT_EVENT.DONT_LOGIN_SITE: {
                let res = lodash.get(event, 'data', {});
                events.dispatch(AUTO_PAYMENT_SYNC_EVENT.DONT_LOGIN_SITE, res);
                return;
            }
            case AUTO_PAYMENT_EVENT.NO_PIN_CODE: {
                events.dispatch(AUTO_PAYMENT_SYNC_EVENT.NO_PIN_CODE, data.payload);
                return;
            }
            case AUTO_PAYMENT_EVENT.SET_PIN_SUCCESS: {
                events.dispatch(AUTO_PAYMENT_SYNC_EVENT.SET_PIN_SUCCESS, data.payload);
                return;
            }
            case AUTO_PAYMENT_EVENT.FAILED: {
                events.dispatch(AUTO_PAYMENT_SYNC_EVENT.FAILED, data.payload);
                return;
            }
            case AUTO_PAYMENT_EVENT.SUCCESS: {
                events.dispatch(AUTO_PAYMENT_SYNC_EVENT.SUCCESS, data.payload);
                return;
            }
            case AUTO_PAYMENT_EVENT.CLOSE_PROCESSING_TAB: {
                events.dispatch(AUTO_PAYMENT_SYNC_EVENT.CLOSE_PROCESSING_TAB, data.payload);
                return;
            }
        }
    }

    handleSetPin(pin) {
        // Bắn message set pin với site gốc
        const request = this.refactorRequest();
        window.postMessage({
            type: AUTO_PAYMENT_EVENT.SET_PIN,
            payload: {
                ...request,
                pin: pin
            }
        });
    }

    refactorRequest() {
        return {
            purchaseAccount: lodash.get(this.request, 'accountPurchaser.account', ''),
            id: lodash.get(this.request, 'id', ''),
            originUrl: window.location.origin + `/payment-requests?external_order=0&id=${lodash.get(this.request, 'id', '')}`,
            link: lodash.get(this.request, "link", ""),
            amount: lodash.ceil(lodash.get(this.request, 'amount'), 2).toFixed(2),
            sellerSource: lodash.get(this.request, 'order.seller_source', lodash.get(this.request, 'invoiceCodes.[0].order.seller_source', '')),
        }
    }
    reboot() {
        this.boot(this.request);
    }
}

export default handleProcessPayment;
