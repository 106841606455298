import {createSelector} from 'reselect';
import lodash from 'lodash';

const listAccountPurchaser = state => lodash.get(state, "AccountPurchaser.list", []);

export const getListAccountPurchaser = createSelector(
    listAccountPurchaser,
    accountPurchasers => lodash.orderBy(accountPurchasers, ['id'], ['desc'])
);

/**
 * List account purchasers options
 */
export const getListAccountPurchaserOptions = createSelector(
    listAccountPurchaser,
    accountPurchasers => accountPurchasers.map(account => {

        return {
            value: account.id,
            label: account.account,
            className:'react-select--purchasebuying__option'
        }
    })
);
