import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Translator from './Translator';
import en from './locals/en';
import vi from './locals/vn';

i18n
    .use(LanguageDetector)
    .init({
        resources: {en, vi},
        fallbackLng: 'vn',
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ','
        },

        react: {
            wait: true
        }
    });

let translator = new Translator(i18n);

const t = i18n.t.bind(i18n);

const trans = translator.translate.bind(translator);

export {
    t,
    trans,
};

export default i18n;