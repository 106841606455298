import React from 'react';
import {translate} from 'react-i18next';
import Button from '@atlaskit/button';
import PropTypes from 'prop-types';
import {trans} from '../../../systems/i18n';
import ModalGeneral from './../../common/components/ModalGeneral';

class ModalConfirmChangeStatus extends React.Component {
    render() {
        let {onClose, accountPurchaser, onConfirm} = this.props;
        return (
            <ModalGeneral>
                <div className={"a-modal__title"}>
                    <p >{this.props.title}</p>
                </div>
                <div style={{wordBreak: "break-all"}}>
                    {accountPurchaser.status.toLowerCase() === 'active' ?
                        trans('account_purchaser.modal_confirm_inactive_status_text',
                            {account: <b>{accountPurchaser.account}</b>}) :
                        trans('account_purchaser.modal_confirm_active_status_text',
                            {account: <b>{accountPurchaser.account}</b>})
                    }
                </div>
                <div className="a--group--inline-end p-2 a--user-group-btn ">
                    <Button appearance="primary" type="submit" className=" mr-3 a-btn--submit"
                            onClick={onConfirm}
                            isLoading={this.props.isLoadingCancelOrder}
                    >
                        {trans('account_purchaser.btn_action_confirm')}
                    </Button>
                    <a appearance="subtle" onClick={onClose} className={"a-btn a-btn--none"}>
                        {trans('account_purchaser.btn_action_cancel')}
                    </a>
                </div>
            </ModalGeneral>
        );
    }
}

ModalConfirmChangeStatus.defaultProps = {
    title: "",
    accountPurchaser: {
        status: 'active',
        account: ''
    }
}

ModalConfirmChangeStatus.propTypes = {
    title: PropTypes.string,
    accountPurchaser: PropTypes.object,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func
}

export default translate('translations')(ModalConfirmChangeStatus);
