import {Button, notification} from "antd";
import React from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import apiService from "../apiService";
import {processResponse} from "../../../systems/api";

export default class ExportCSV extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    exportCSV() {
        if (this.state.loading) return;

        this.setState({loading: true});

        let {t, csvFilter, filter} = this.props;
        let exportFilter = {};

        if (lodash.isEmpty(csvFilter)) {
            exportFilter = {
                ...exportFilter,
                ...filter,
            };
        } else {
            exportFilter = {
                ...exportFilter,
                ...csvFilter,
            };
        }

        exportFilter = lodash.omitBy(exportFilter, value => value === '');
        let res = apiService.exportCSV({...exportFilter})
            .finally(() => this.setState({loading: false}));

        let process = processResponse(res)
            .then(res => {
                notification.success({message: t('transaction:message.export_csv_success')});
            })
            .catch((error) => {
                let {code} = error;
                if (code === "INPUT_INVALID") {
                    let bought_at = lodash.get(error, "data.bought_at", {});

                    if (bought_at.hasOwnProperty('REQUIRED'))
                        notification.error({message: 'Thời gian mua không được để trống'});

                    if (bought_at.hasOwnProperty('GREATER'))
                        notification.error({message: 'Thời gian mua không được vượt quá 01 tháng'});
                }
                else
                    notification.error({message: t('transaction:message.export_csv_failed')});
            });
    }

    render() {
        let {t} = this.props;
        return (
            <Button
                loading={this.state.loading}
                onClick={this.exportCSV.bind(this)}
                className=" a-font--12 aphz-50 a-btn--primary-ant ghost mr-4"
                size={"small"}>{t('transaction:btn.export')}</Button>
        )
    }
}

ExportCSV.defaultProps = {
    loading: false,
    csvFilter: {},
};

ExportCSV.propTypes = {
    csvFilter: PropTypes.object,
    loading: PropTypes.bool,
    t: PropTypes.object,
    resetError: PropTypes.func,
};
