import * as Constants from './constants';
import lodash from 'lodash';
import {PERMISSION_NOT_ALLOW} from './../Permission/constants';
import {combineReducers} from 'redux';
import updateShippingBill from './UpdateShippingBill/reducer';
import managePackages from './ManagerPackages/reducer';
import * as ManagerPackagesConstants from './ManagerPackages/constants';

const productReturn = (state = {}, action) => {
    let {payload} = action || {};
    let newState = state;
    let data = {};
    switch (action.type) {
        case Constants.FETCH_COMPLAINT_PRODUCT_RETURN.SUCCESS:
            return {...state, ...lodash.get(payload, 'data', {})};
        case Constants.UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.SUCCESS:
            return {...state, ...lodash.get(action, 'payload.data', {})};
        case ManagerPackagesConstants.CREATE_PACKAGE.SUCCESS:
            newState = state;
            newState = {
                ...newState,
                ...{packages: lodash.get(payload, 'data.packages', [])}
            };
            return newState;
        case ManagerPackagesConstants.DELETE_PACKAGE.SUCCESS:
            newState = state;
            newState = {
                ...newState,
                ...{packages: lodash.get(payload, 'data.packages', [])}
            };
            return newState;

        case Constants.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.SUCCESS:
            newState = state;
            data = lodash.get(payload, 'data', {});
            newState = {
                ...newState,
                ...lodash.pick(data, ['receiver', 'phone', 'address', 'note', 'return_date', 'cost', 'paid_cost'])
            };
            return newState;
        case Constants.CLEAR_PRODUCT_RETURN:
            return {};
        default:
            return state;
    }
};
const defaultIsLoading = {
    getProductReturn: false,
    updateStatus: false,
    postUpdateInfoProductReturn: false,
};
/**
 * State quản lý việc có hệ thống khi thực hiện các thao tác xử lý tạo mới cập nhật
 * @param state
 * @param action
 */
const isLoadingData = (state = defaultIsLoading, action) => {
    switch (action.type) {
        case Constants.CLEAR_PRODUCT_RETURN:
            return {
                getProductReturn: false,
                updateStatus: false
            };
        case Constants.FETCH_COMPLAINT_PRODUCT_RETURN.REQUEST:
            return {...state, ...{getProductReturn: true}};
        case Constants.FETCH_COMPLAINT_PRODUCT_RETURN.SUCCESS:
        case Constants.FETCH_COMPLAINT_PRODUCT_RETURN.FAILED:
        case Constants.FETCH_COMPLAINT_PRODUCT_RETURN.ERROR:
            return {...state, ...{getProductReturn: false}};
        case Constants.UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.REQUEST:
            return {...state, ...{updateStatus: true}};
        case Constants.UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.SUCCESS:
        case Constants.UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.FAILED:
        case Constants.UPDATE_STATUS_COMPLAINT_PRODUCT_RETURN.ERROR:
        case PERMISSION_NOT_ALLOW:
            return {...state, ...{updateStatus: false}};

        case Constants.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.REQUEST:
            return {...state, ...{postUpdateInfoProductReturn: true}};

        case Constants.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.SUCCESS:
        case Constants.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.FAILED:
        case Constants.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.ERROR:
            return {...state, ...{postUpdateInfoProductReturn: false}};

        default:
            return state;
    }
};

const listProductReturns = (state = [], action) => {
    let {payload} = action || {};
    switch (action.type) {
        case Constants.FETCH_LIST_PRODUCT_RETURNS.SUCCESS:
            return lodash.get(payload, 'data', []);
        case Constants.FETCH_LIST_PRODUCT_RETURNS.REQUEST:
        case Constants.FETCH_LIST_PRODUCT_RETURNS.FAILED:
            return [];
        default:
            return state;
    }
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
const createErrors = (state = {}, action) => {
    const {payload} = action;
    let newState = {...state};
    switch (action.type) {
        case Constants.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.REQUEST:
        case Constants.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.SUCCESS:
            return {};
        case Constants.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.FAILED:
        case Constants.UPDATE_INFO_COMPLAINT_PRODUCT_RETURN.ERROR:
            return lodash.get(payload, 'data', {});
        case Constants.CLEAR_INFO_COMPLAINT_PRODUCT_RETURN:
            let params = lodash.get(payload, 'params', {});
            let orderDataError = state;
            if ( lodash.has(orderDataError, 'receiver')
                && lodash.get(params, 'receiver', '').length > 0)
            {
                orderDataError = lodash.omit(orderDataError, 'receiver');
            }

            if ( lodash.has(orderDataError, 'phone')
                && lodash.get(params, 'phone', '').length > 0)
            {
                orderDataError = lodash.omit(orderDataError, 'phone');
            }

            if ( lodash.has(orderDataError, 'address')
                && lodash.get(params, 'address', '').length > 0)
            {
                orderDataError = lodash.omit(orderDataError, 'address');
            }
            if ( lodash.has(orderDataError, 'return_date')
                && lodash.get(params, 'return_date', '').length > 0)
            {
                orderDataError = lodash.omit(orderDataError, 'return_date');
            }

            return orderDataError;
        default:
            return state;
    }
};

export default combineReducers({
    productReturn,
    createErrors,
    listProductReturns,
    isLoadingData,
    updateShippingBill,
    managePackages,
});