import React from 'react';
import {translate} from 'react-i18next';
import Button from '@atlaskit/button';
import PropTypes from 'prop-types';
import {trans} from '../../../../systems/i18n';
import lodash from 'lodash';
import Spinner from '@atlaskit/spinner';
import Select from '@atlaskit/select';
import Input from './../../../common/components/Input/Super';
import DatetimePicker from "./../../../common/components/DatetimePicker";
import FormProcessing from './../../../common/components/FormProcessing';
import ModalGeneral from './../../../common/components/ModalGeneral';

class FormCreate extends FormProcessing {

    constructor(props) {
        super(props);
        this.state = {
            input: {
                id_order: null,
                complaint_seller_reason_id: null,
                expire_time: '',
                amount: null,
                note: null,
            },
            inputVersion: 0,
            changedParams: [],
        }
    }

    /**
     * xử lý hành động thay đổi select
     */

    onChangeSelect(param, select) {
        this.changeInput({[param]: lodash.get(select, 'value', '')});
        this.props.clearErrorCreateComplaintSeller(param);
    }

    makeValueSelect(select, options) {
        return options.filter((option) => {
            return select === option.value;
        });
    }

    /**
     * xử lý hành động submit form
     */
    onSubmitForm() {
        let {input} = this.state;
        console.log('Noi dung', input);
        //this.props.onSubmitForm(input);
    }

    /**
     * Thực hiện render lỗi thông báo ra bên ngoài
     * @param attrName
     * @returns {null}
     */
    renderError(attrName) {
        let {error, t} = this.props;
        let errorItem = lodash.get(error, attrName, {});
        if (!lodash.isEmpty(errorItem)) {
            switch (Object.keys(errorItem)[0]) {
                case 'required':
                    return t('complaintSeller:error.error.' + Object.keys(errorItem)[0], {name: t('complaintSeller:list.' + attrName)});
                case 'NOT_EXIST':
                    return t('complaintSeller:error.error.' + Object.keys(errorItem)[0], {name: t('complaintSeller:list.' + attrName)});
                case 'ALREADY_EXIST':
                    return t('complaintSeller:error.error.' + Object.keys(errorItem)[0], {name: t('complaintSeller:list.' + attrName)});
                case 'INVALID':
                    return t('complaintSeller:error.error.' + Object.keys(errorItem)[0], {name: t('complaintSeller:list.' + attrName)});
                case 'unique':
                    return t('complaintSeller:error.error.' + Object.keys(errorItem)[0], {name: t('complaintSeller:list.' + attrName)});
                case 'max.string':
                    let objectmax = lodash.get(errorItem, 'max.string', null);
                    let overLength = lodash.get(objectmax, 'max', null);
                    return t('complaintSeller:error.error.max_string', {
                        name: t('complaintSeller:list.' + attrName),
                        value: overLength
                    });
                default:
                    return t('complaintSeller:error.error.system_errors');
            }
        }
        return null;
    }

    /**
     * xử lý hành động thay đổi datepicker
     */

    onChangeDate(data) {
        this.changeInput(data);
    }

    /**
     *xử lý hành động keyup thì reset các lỗi
     * submit form
     * @param data
     */

    onKeyUp(data) {
        this.props.clearErrorCreateComplaintSeller(data);
    }

    /**
     * xử lý hành động keyup convert lại giá trị của amount
     * submit amount
     * @param event
     */

    onKeyUpAmount(event) {
        let amount = parseFloat(event.target.value);
        this.changeInput({amount: amount});
    }


    componentDidMount() {
        let {filter} = this.props;
        let orderCode = lodash.get(filter, 'orderCode', '');
        if(orderCode) {
            this.changeInput({id_order: orderCode});
        }
    }

    render() {
        let {onClose, t, complaintSellerReasons, isLoadingData} = this.props;
        let isLoading = lodash.get(isLoadingData, 'postComplaintSellerCreate', false);
        let {input} = this.state;
        
        return (
            <ModalGeneral>
                <div className={"a-modal__title"}>
                    <p >
                        {t('complaintSeller:list.created')}
                    </p>
                </div>
                <div className='a-group'>
                    <label className="label label--required a-label ">
                        {t('complaintSeller:list.id_order')}
                    </label>
                    <input
                        spellCheck="false"
                        className={"input input--fullwidth input--insert_acount mt-2 "+(this.renderError("id_order") === null || this.renderError("id") === undefined? "" : "input--error1 ")
                        }
                        type="text"
                        autoComplete="off"
                        name={"id_order"}
                        placeholder={t('complaintSeller:list.order_code_placeholder')}
                        onKeyUp={this.onKeyUp.bind(this, 'id_order')}
                        onChange={this.onChangeInput.bind(this, 'id_order')}
                        value={input.id_order || ""}
                    />
                    <div className={"a--error"}>
                        {this.renderError("id_order")}
                        {this.renderError("status")}
                    </div>
                </div>
                <div className='a-group'>
                    <label className="label label--required a-label">
                        {t('complaintSeller:list.complaint_seller_reason_id')}
                    </label>
                    <Select
                        noOptionsMessage={() => t("system.no_option")}
                        classNamePrefix={"react-select--violate-policy-crate"}
                        isClearable={true}
                        isSearchable={true}
                        value={this.makeValueSelect(input.complaint_seller_reason_id, complaintSellerReasons)}
                        placeholder={t('complaintSeller:list.choose_reason_for_refund_money')}
                        options={complaintSellerReasons}
                        onChange={this.onChangeSelect.bind(this, 'complaint_seller_reason_id')}
                    />
                    <div className={"a--error"}>
                        {this.renderError("complaint_seller_reason_id")}
                    </div>
                </div>
                <div className='a-group'>
                    <label className="label label--required a-label">
                        {t('complaintSeller:list.expire_time')}
                    </label>
                    <div className="mt-2 a-datetime--complantseller">
                        <DatetimePicker
                            isFutureOnly={true}
                            name={'expire_time'}
                            onSubmit={this.onChangeDate.bind(this)}
                            date={lodash.get(input, "expire_time") || ""}
                            placeholderText={t("complaintSeller:detail.renew_placeholder")}
                        />
                    </div>
                    <div className={"a--error"}>{this.renderError("expire_time")}</div>
                </div>
                <div className='a-group'>
                    <label className="label a-label ">
                        {t('complaintSeller:list.request_a_seller')}:
                    </label>

                    <Input
                        negative={false}
                        className={"input input--fullwidth input--insert_acount mt-2 "+
                        (this.renderError("amount") === null || this.renderError("amount") === undefined? "" : "input--error ")}
                        name={"amount"}
                        unit_price={true}
                        onKeyUp={this.onKeyUpAmount.bind(this)}
                        placeholder={t('complaintSeller:list.request_a_seller')}
                    />
                    <br/>

                    <div className={"a--error"}>{this.renderError("amount")}</div>
                </div>
                <div className='a-group'>
                    <label className="label a-label ">
                        {t('complaintSeller:list.note')}:
                    </label>
                    <textarea
                        rows="4"
                        className="input input--fullwidth mt-2 a-textarea"
                        name={"note"}
                        autoComplete="off"
                        placeholder={t('complaintSeller:list.note')}
                        onChange={this.onChangeInput.bind(this,'note')}
                        value={input.note ||""}
                    />
                    <div className={"a--error"}>{this.renderError("account")}</div>
                </div>
                <div className="a--group--inline-end p-2 a--user-group-btn">
                    <Button appearance="primary" type="submit" className=" mr-3 a-btn--submit"
                            onClick={this.onSubmitForm.bind(this)}
                            isLoading={isLoading}
                    >
                        <span className={(isLoading) ? "ml-2" : "hidden"}>
                            <Spinner size="small" isCompleting={false}/>
                        </span>
                        {trans('account_purchaser.btn_action_confirm')}
                    </Button>
                    <a appearance="subtle" onClick={onClose}
                       className={(isLoading ? "hidden " : "") + "a-btn a-btn--none a-btn-link "}>
                        {trans('account_purchaser.btn_action_cancel')}
                    </a>
                </div>
            </ModalGeneral>
        );
    }
}

FormCreate.defaultProps = {
    complaintSellerReasons: [],
    isLoadingData: {},
    onSubmitForm: ()=> {
    },
    error: {}
}

FormCreate.propTypes = {
    complaintSellerReasons: PropTypes.array,
    isLoadingData: PropTypes.object,
    onSubmitForm: PropTypes.func,
    error: PropTypes.object
}

export default translate('translations')(FormCreate);
